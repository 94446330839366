import Vue from 'vue'
import { round } from './helpers/mathHelpers'
import { formatFY, formatDate, toPercentage, formatAY } from './helpers/formatters'

Vue.filter('toPercentage', (value, decimals, appendPercentSign) => {
    return toPercentage(value, decimals, appendPercentSign)
})

Vue.filter('asPercentage', (value) => {
    if (value) return value + '%'
})

Vue.filter('date', (value, format) => {
    return formatDate(value, format)
})

Vue.filter('round', (value, decimals) => {
    if (value && !isNaN(value)) {
        return round(value, decimals || 0)
    } 
})

Vue.filter('fromFY', (value, format) => {
  return formatFY(value, format)
})

Vue.filter('schoolYear', (value) => {
  return formatAY(value)
})

Vue.filter('currency', (value) => {
  if (value != undefined) {
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    })
  }        
  return value
})

Vue.filter('phone', function (phone) {
  return phone.replace(/[^0-9]/g, '')
              .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
});

Vue.filter('fileSize', function (size) {
  if (size === 0) return '0 Bytes';
  var k = 1024;
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  var i = Math.floor(Math.log(size) / Math.log(k));
  return (size / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
});

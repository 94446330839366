<template>
  <div class="h-100 w-100">
    <highcharts class="chart" ref="chartElem" :options="chartOptions" />
  </div>
</template>

<script>
import { Types } from '@/modules/esa/stores/'
import { mapState } from 'vuex'
import { ChartMixin } from '@/components/ChartMixin'
import Highcharts from 'highcharts'
import sumBy from 'lodash.sumby'
import { LeaTypes } from '@/helpers/leaTypes'

export default {
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'pie',
          margin: 0,
          height: 300,
          spacing: [0, 0, 0, 0],
          plotBackgroundColor: "rgb(248, 249, 250)",
          plotBorderWidth: 0,
          plotShadow: false
        },
        plotOptions: {
          pie: {
            cursor: 'pointer',
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '75%'],
            borderColor: '#6c757d',
            dataLabels: {
              enabled: true,
              alignTo: 'connectors',
              crop: false,
              formatter: function() {
                return `${this.key}: $${Highcharts.numberFormat(this.y, 2).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} (${this.percentage.toFixed(2)}%)`
              }
            },
          },
        },
        title: {
          text: 'Budget vs ESA Allocation',
          floating: false,
          //verticalAlign: 'middle'
        },
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        lang: {
          thousandsSep: ','
        },
        series: [{
          name: 'Budget',
          colorByPoint: true
        }],
        drilldown: {},
        xAxis: {
          title: {
            text: 'Amount'
          },
          type: 'category'
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          formatter: function() {
            const percent = this.name == 'Budget'? this.point.percent : this.percentage
            return `${this.key}<br/><b>$${Highcharts.numberFormat(this.y, 2).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} (${percent.toFixed(2)}%)</b></br>Allocated</br><b>$${Highcharts.numberFormat(this.point.allocated, 2).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`
          }
        },
        yAxis: {
          endOnTick: false,
        },
      }
    }
  },
  props: {
    drilldown: {
      type: String
    },
    categories: {
      type: Array,
      required: true
    },
  },
  computed: {
    ...mapState(Types.path, [Types.state.planExpenses, Types.state.districtAllocations]),
    filteredExpenses() {
      if (this.currentCategory) 
        return this.planExpenses.filter(expense => this.currentCategory.programCode.includes(expense.program))
      return this.planExpenses//.map(p => {...p, categoryDescription: })
    },
    currentCategory() {
      return this.categories.find(category => category.name == this.drilldown)
    },
    allocations() {
      if (this.selectedLea.type == LeaTypes.Coop) {
        const districtLeas = this.leaDictionary[this.selectedLea.number].ownedLeas.flatMap(o => o.number)
        return this.districtAllocations.filter(d => districtLeas.includes(d.lea))
      } else if (this.selectedLea.type == LeaTypes.District) {
        return this.districtAllocations.filter(d => d.lea == this.selectedLea.number)
      }
      return this.districtAllocations
    },
  },
  mixins: [ChartMixin],
  methods: {
    populate() {
      const allocated = sumBy(this.allocations, 'total')
      const budgeted = sumBy(this.filteredExpenses, 'budgeted')
      const percent = allocated > 0 ? ((budgeted / allocated) * 100.0): 0

      this.chartOptions.series = [{
        name: 'Budgeted',
        innerSize: '50%', 
        colorByPoint: true,
        data: [{
            name: 'Budget',
            y: budgeted,
            color: budgeted ? '#dc3545' : '#28a745',
            percent: percent,
            allocated: allocated,
            dataLabels: {
              formatter: function () {
                return `${this.point.name}: ${Highcharts.numberFormat(this.y, 0)} (${Highcharts.numberFormat(this.point.percent, 2)}%)`
              }
            },
          },
          {
            name: 'Remaining Allocated',
            y: allocated - budgeted,
            color: '#e9ecef',
            percent: percent,
            allocated: allocated,
            dataLabels: {
              formatter: function () { 
                return `${this.point.name}: ${Highcharts.numberFormat(this.y, 0)} (${Highcharts.numberFormat(this.percentage, 2)}%)`
              }
            },
          }
        ]
      }]      
    },
  },
  watch: {
    planExpenses: {
      immediate: true,
      handler() {
        this.populate()
      }
    },
    drilldown: {
      handler() {
        this.populate()
      }
    }
  }
}
</script>
<template>
  <div>
    <b-row class="mb-2">
      <b-col class="text-left">
        <b-link @click="$router.go(-1)">
          Return to Academic Year {{ formatAY(academicYear) }} task list
        </b-link>
      </b-col>
    </b-row>
    <b-card :header="title"> 
      <slot>
        .
      </slot>
      <step-status-widget :task-info="{ academicYear: academicYear, name: title}" v-if="!hideStepStatus" :pre-completion-routine="preCompletionRoutine">
        <template slot="stepCompletionInstructions">
          <slot name="stepCompletionInstructions" />
        </template>
      </step-status-widget>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import StepStatusWidget from './StepStatusWidget'
import { Types } from '@/modules/cte/stores/perkinsv'
import { formatAY } from '@/helpers/formatters'

export default {
  data() {
    return {
      formatAY
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.currentTaskCompletion])
  },
  methods: {
  },
  mounted() {
    this.$store.dispatch(Types.actions.getTaskCompletion, { academicYear: this.academicYear, taskCode: this.taskCode })
  },
  components: {
    StepStatusWidget
  },
  props: {
    academicYear: {
      required: true,
      type: Number,
    },
    taskCode: {
      required: true,
      type: String,
    },
    title: String,
    hideStepStatus: {
      type: Boolean,
      default: false
    },
    preCompletionRoutine: {
      type: Function,
      required: false,
    },
  }  
}
</script>
<template>
  <div style="border: solid 3px red; background-color: white;">
    <ul style="list-style-type: none;">
      <li v-for="(obj, index) in debugMessages" :key="index">
        <span v-if="obj.log" :style="getColor(obj.log)" :class="'debug-' + obj.log">{{ obj.log }}: {{ obj.message }}</span>
        <span v-else>{{ obj }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
const ConsoleColors = {
  log: '#000',
  info: '#3234a8',
  warn: '#ab8f29',
  error: '#8a2727'
}

export default {
  name: 'debug-block-widget',
  computed: {
    debugMessages() {
      return this.$store.getters['globalModule/debugMessages']
    },
  },
  methods: {
    getColor(log) {
      return `color: ${ConsoleColors[log]};`
    }
  },
  mounted() {
    window.redirectLog = function (message) {
      var store = window.global.default.VueApp.$store
      store.dispatch('globalModule/addColorDebugMessage', { log: this, message: message })
    }

    window.console = {
      log: window.redirectLog.bind('log'),
      info: window.redirectLog.bind('info'),
      warn: window.redirectLog.bind('warn'),
      error: window.redirectLog.bind('error')
    }
  }
}
</script>

<style>
.debug-info::before {
  content: "ℹ️ "
}
.debug-warn::before {
  content: "⚠️ "
}
.debug-log::before {
  content: "✅ "
}
.debug-error::before {
  content: "🔥 "
}
</style>
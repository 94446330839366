import { LeaTypes } from './leaTypes'

const HCPTHelper = {
   
    convertLeaTypeToHCPTLevel: (type) => {
        if (type == LeaTypes.Coop) return 3;
        if (type == LeaTypes.State) return 4;
        return type;
    }
}
export {
    HCPTHelper
}
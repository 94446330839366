<template>
  <div class="rich-text-editor">
    <div v-if="editor" style="width: 100%">
      <b-button size="sm" :variant="buttonVariant" @click="editor.chain().focus().toggleBold().run()" :class="{ 'active': editor.isActive('bold') }" v-b-tooltip.hover title="Toggle Bold">
        <i class="fas fa-fw fa-bold" />
      </b-button>
      <b-button size="sm" :variant="buttonVariant" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'active': editor.isActive('italic') }">
        <i class="fas fa-fw fa-italic" />
      </b-button>
      <b-button size="sm" :variant="buttonVariant" @click="editor.chain().focus().toggleStrike().run()" :class="{ 'active': editor.isActive('strike') }">
        <i class="fas fa-fw fa-strikethrough" />
      </b-button>
      <b-button size="sm" :variant="buttonVariant" @click="editor.chain().focus().unsetAllMarks().run()">
        <i class="fas fa-fw fa-eraser" />
      </b-button>      
      <b-button size="sm" :variant="buttonVariant" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'active': editor.isActive('bulletList') }">
        <i class="fas fa-fw fa-list-ul" />
      </b-button>
      <b-button size="sm" :variant="buttonVariant" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'active': editor.isActive('orderedList') }">
        <i class="fas fa-fw fa-list-ol" />
      </b-button>
    </div>
    <editor-content :editor="editor" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'
import StarterKit from '@tiptap/starter-kit'

export default {
  components: {
    EditorContent,
  },
  data() {
    return {
      editor: null,
      buttonVariant: "light"
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      // editorProps: {},
      // editable: true,
      // autoFocus: null,
       extensions: [
         StarterKit,
         TextStyle,
         Color
       ],
      // topNode: 'doc',
      // emptyDocument: {
      //   type: 'doc',
      //   content: [{
      //     type: 'paragraph',
      //   }],
      // },
      // useBuiltInExtensions: true,
      // disableInputRules: false,
      // disablePasteRules: false,
      // dropCursor: {},
      // parseOptions: {},
       injectCSS: false,
      // onInit: () => {},
      // onTransaction: () => {},
       onUpdate: () => {
         this.$emit('input', this.editor.getHTML())
       },
      // onFocus: () => {},
      // onBlur: () => {},
      // onPaste: () => {},
      // onDrop: () => {},
    })
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  watch: {
    value(val) {
      const isSame = this.editor.getHTML() === val
      if (isSame) return
      this.editor.commands.setContent(this.value, false)
    }
  }
}
</script>

<style lang="scss">
.rich-text-editor {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,0.125);
  border-radius: .25rem;
  width: 100%;
}

 .ProseMirror {
  position: relative;
  padding: 10px;
}
.ProseMirror:focus {
  outline: none;
}
.ProseMirror {
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: break-spaces;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0; /* the above doesn't seem to work in Edge */
}
.ProseMirror [contenteditable="false"] {
  white-space: normal;
}
.ProseMirror [contenteditable="false"] [contenteditable="true"] {
  white-space: pre-wrap;
}
.ProseMirror pre {
  white-space: pre-wrap;
}
img.ProseMirror-separator {
  display: inline !important;
  border: none !important;
  margin: 0 !important;
}
.ProseMirror-gapcursor {
  display: none;
  pointer-events: none;
  position: absolute;
}
.ProseMirror-gapcursor:after {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  width: 20px;
  border-top: 1px solid black;
  animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
}
@keyframes ProseMirror-cursor-blink {
  to {
    visibility: hidden;
  }
}
.ProseMirror-hideselection *::selection {
  background: transparent;
}
.ProseMirror-hideselection *::-moz-selection {
  background: transparent;
}
.ProseMirror-hideselection * {
  caret-color: transparent;
}
.ProseMirror-focused .ProseMirror-gapcursor {
  display: block;
}
.tippy-box[data-animation=fade][data-state=hidden] {
  opacity: 0
}
</style>
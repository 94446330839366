<template>
  <div>
    <invite-user-form-coordinator />
  </div>
</template>

<script>
import InviteUserFormCoordinator from './users/InviteUserFormCoordinator.vue'

export default {
  components: {
    InviteUserFormCoordinator,
  },
}

</script>
<template>
  <div>
    <highcharts class="chart" :options="chartOptions" />
  </div>
</template>

<script>
import { VisitTypes } from '@/modules/cte/stores/careercoach/reporting'

export default {
  data() {
    return {
      monthOption: null,
      VisitTypes,
      reportData: {},
      chartOptions: {
        chart: {
          type: 'column'
        },
        title: {
          text: 'Number of Students Visited by Service Type'
        },
        series: [],
        plotOptions: {
          column: {
            //stacking: 'percent'
          }
        },
        xAxis: {
          title: {
            text: 'Grade'
          },
          categories: []
        },
        yAxis: {
          title: {
            text: '# Students Contacted'
          },
          endOnTick: false
        }
      }
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(newData) {
        const grades = [...new Set(newData.enrollment.map(item => item.grade))]
        this.chartOptions.xAxis.categories = grades
        this.chartOptions.series = this.VisitTypes.map(v => {
          return {
            name: v.label,
            data: grades.map(e => {
              var point = newData.data.find(d => d.grade == e && d.topic == v.key)
              return point ? point.uniqueStudentsVisited : 0
            })
          }
        })
      }
    }
  },
  props: {
    data: Object
  }
}
</script>
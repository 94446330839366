<template>
  <div v-if="selectedApplication && selectedApplication.applicationId">
    <div class="row">
      <div class="col-12">
        <div class="pb-2">
          <pdf-button :url="pdfUrl" />
          <excel-button :url="excelUrl" />
          <share-report-button report-type="CTE Perkins V Application" :excel-url="excelUrl" :pdf-url="pdfUrl" :output-types="[OutputTypes.Link, OutputTypes.Pdf, OutputTypes.Excel]" />
          <h2 class="d-inline">
            Application #{{ $route.params.applicationId }} |
          </h2>
          <b-button @click="$router.push('/cte/perkins-v/applications')" class="mr-1 d-print-none float-right">
            <i class="fas fa-chevron-left" /> Return to Application List
          </b-button>
          <h2 class="d-inline px-1">
            {{ leaFormatter(selectedApplication.applicantId) }} <span class="small text-muted"> (AY {{ selectedApplication.ay | schoolYear }})</span>
          </h2>
        </div>
      </div>
    </div>
    <div class="row" v-if="false">
      <div class="col-md-3">
        <table class="table table-borderless small">
          <caption style="caption-side: top !important;">
            Applicant Funding
          </caption>
          <tr>
            <th>Amount Awarded</th>
            <td class="text-right">
              {{ formatCurrency(applicantFundingLimit.fundingLimit) }}
            </td>
          </tr>
          <tr>
            <th>Amount Budgeted (Approved)</th>
            <td class="text-right">
              ({{ formatCurrency(amountBudgetedApproved) }})
            </td>
          </tr>
          <tr>
            <th>Amount Budgeted (Pending)</th>
            <td class="text-right">
              ({{ formatCurrency(amountBudgetedPending) }})
            </td>
          </tr>
          <tr class=" border-dark border-top">
            <th>Amount Remaining</th>
            <td class="text-right">
              {{ formatCurrency(amountBudgetedRemaining) }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-md-12">
        <div class="row" v-if="true">
          <div class="col-md-3">
            <strong class="mr-2">Amount awarded:</strong> {{ formatCurrency(applicantFundingLimit.fundingLimit) }}
          </div>
          <div class="col-md-3">
            <strong class="mr-2">Amount Budgeted (Approved):</strong> {{ formatCurrency(amountBudgetedApproved) }}
          </div>
          <div class="col-md-3">
            <strong class="mr-2">Amount Budgeted (Pending):</strong> {{ formatCurrency(amountBudgetedPending) }}
          </div>
          <div class="col-md-3">
            <strong class="mr-2">Amount Remaining:</strong> {{ formatCurrency(amountBudgetedRemaining) }}
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-show="proposals.length > 0">
      <div class="col-6">
        <proposal-costs-type-chart :applications="applications" :clusters="clusters" v-if="selectedApplication.ay > 22" />
        <proposal-costs-type-chart-old :applications="applications" :occupational-areas="occupationalAreas" v-else />
      </div>
      <div class="col-6">
        <applications-budget-chart :applications="applications" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-tabs card content-class="mt-3">
          <b-tab title="Proposals">
            <proposal-list :load-data="loadData" @on-add-initiated="addProposal" />
          </b-tab>
          <b-tab title="Perkins Indicators">
            <perkins-indicators-widget v-model="indicatorsLocal" :is-readonly="true" :show-focus="false" />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import ProposalList from './proposal/ProposalList'
import PerkinsIndicatorsWidget from '../common/PerkinsIndicatorsWidget'
import ExcelButton from '@/components/ExcelButton'
import PdfButton from '@/components/PdfButton'
import ShareReportButton, { OutputTypes } from '@/components/ShareReportButton.vue'
import { mapState, mapGetters } from 'vuex'
import sumBy from 'lodash.sumby'
import { Types } from '@/modules/cte/stores/perkinsv'
import { Types as CommonTypes } from '@/modules/cte/stores'
import { formatCurrency } from '@/helpers/formatters'
import { ProposalStatuses } from './enums'
import { ProposalTypes } from '../enums'
import { ProposalStateMap } from '@/modules/cte/stores/perkinsvStateMap'
import ApplicationsBudgetChart from './ApplicationsBudgetChart'
import ProposalCostsTypeChart from './ProposalCostsTypeChart'
import ProposalCostsTypeChartOld from './ProposalCostsTypeChartOld'

export default {
  data() {
    return {
      loadData: this.$route.params.loading,
      indicatorsLocal: [],
      OutputTypes,
    }
  },
  computed:{
    ...mapState(Types.path, [Types.state.selectedApplication, Types.state.applicantFundingLimit, Types.state.applicantPerkinsIndicatorPerformances]),
    ...mapState(CommonTypes.path, [CommonTypes.state.occupationalAreaMetadata]),
    ...mapGetters(Types.path, [Types.getters.activeAcademicYear]),
    occupationalAreas() {
      return (this.occupationalAreaMetadata || { occupationalAreas: [] }).occupationalAreas
    },
    clusters() {
      return (this.occupationalAreaMetadata || { clusters: [] }).clusters
    },
    proposals() {
      return (this.selectedApplication || {}).proposals || []
    },
    applications() {
      if (this.selectedApplication && this.selectedApplication.applicationId) {
        const application = {
          ...this.selectedApplication, 
          amountBudgetedPending: this.amountBudgetedPending || 0,
          amountBudgetedApproved: this.amountBudgetedApproved || 0, 
          amountAwarded: (this.applicantFundingLimit || {}).fundingLimit || 0,
        }
        return [application]
      }
      return []
    },
    amountBudgetedPending() {
      return sumBy(this.proposals.filter(p => p.status < ProposalStatuses.Approved), 'amountBudgeted') || 0
    },
    amountBudgetedApproved() {
      return sumBy(this.proposals.filter(p => p.status == ProposalStatuses.Approved), 'amountBudgeted') || 0
    },
    amountBudgetedRemaining() {
      return this.applicantFundingLimit.fundingLimit - (this.amountBudgetedApproved + this.amountBudgetedPending)
    },
    excelUrl() {
      return `/api/cte/perkinsv/applications/${this.selectedApplication.applicationId}`
    },
    pdfUrl() {
      return `/cte/perkins-v/application/${this.selectedApplication.applicationId}/print?response-format=pdf`
    }
  },
  components: {
    ProposalList,
    PerkinsIndicatorsWidget,
    ExcelButton,
    ShareReportButton,
    PdfButton,
    ApplicationsBudgetChart,
    ProposalCostsTypeChart,
    ProposalCostsTypeChartOld
  },
  methods: {
    addProposal() {
      this.$router.push({ name: 'CTE/PerkinsV/Application/Proposal/Add' })
    },
    formatCurrency,
    getProgramOfStudy(programOfStudyId) {
      if (programOfStudyId && this.occupationalAreaMetadata.programsOfStudy) {
        return (this.occupationalAreaMetadata.programsOfStudy.find(p => p.id == programOfStudyId) || {}).name
      }
      return ''
    },
    getProposalType(type) {
      return (ProposalTypes.find(p => p.value == type) || {}).text
    },
    excelExportData() {
      return {
        name: 'Application',
        items: [
          { 'Application ID': this.selectedApplication.applicationId,  'Academic Year': this.selectedApplication.ay, Applicant: this.leaFormatter(this.selectedApplication.applicantId) },
          [/* Empty row */],
          ['Proposals:'],
          'Proposal ID', 'Title', 'Status', 'Type', 'Program Of Study', 'Created Date',
          ...this.selectedApplication.proposals.map(p => {
            // console.log(p)
            return [
              p.proposalId, 
              p.title, 
              ProposalStateMap.get(p.status).status, 
              this.getProposalType(p.proposalType),
              this.getProgramOfStudy(p.programOfStudyId),
              new Date(p.createdDate).toLocaleDateString(),
            ]
          })
        ]
      }
    }
  },
  props:{
    applicationId: String
  },
  watch: {
    applicantPerkinsIndicatorPerformances(perkinsIndicators) {
      if (perkinsIndicators && perkinsIndicators.length > 0) {
        this.indicatorsLocal =  [...perkinsIndicators]
      }
    },
  },
  beforeRouteEnter: (to, from, next) => {
    next((vm) => {
      to.params.loading = vm.$store.dispatch(Types.actions.getApplication, { applicationId: to.params.applicationId })
        .then(() => vm.$store.dispatch(CommonTypes.actions.getOAMetadata, { ay: vm.$store.state.cte.perkinsv.selectedApplication.ay }))
    })
  }
}
</script>

import AcademicsOverviewPage from './overview/AcademicsOverviewPage.vue'
import AlternateMethodOfInstructionDaysPage from './amiDays/AMIDaysPage.vue'
import GrowthPage from './growth/GrowthPage.vue'
import LeaGrowthSidebar from './growth/LeaGrowthSidebar.vue'
import TeacherGrowthPage from './teacherGrowth/TeacherGrowthPage.vue'
import TeacherGrowthSidebar from './teacherGrowth/TeacherGrowthSidebar.vue'
import EsaPage from '@/modules/esa/components/EsaPage.vue'
import EsaPublicPage from '@/modules/esa/components/EsaPublicPage.vue'
import EsaSidebar from '@/modules/esa/components/EsaSidebar.vue'
import TeacherVamPage from './teacherVAM/TeacherVamPage'
import TeacherVamSidebar from './teacherVAM/TeacherVamSidebar'
import PurplePage from '@/modules/purple/components/PurplePage.vue'

const AcademicsArea = {
    AcademicsOverviewPage,
    AlternateMethodOfInstructionDaysPage,
    GrowthPage,
    LeaGrowthSidebar,
    TeacherGrowthPage,
    TeacherGrowthSidebar,
    EsaPage,
    EsaPublicPage,
    EsaSidebar,
    TeacherVamPage,
    TeacherVamSidebar,
    PurplePage,
}

export { AcademicsArea }

<template lang="html">
  <div>
    <div class="col-6">
      <b-form-group label="Search" label-cols-sm="1" label-align-sm="left" label-for="searchInput" class="mb-0">
        <b-input-group>
          <b-form-input
            v-model="searchTerm"
            type="search"
            id="searchInput"
            @keyup.enter.native="searchEmployees()"
            placeholder="Search by First Name, Last Name, Last 4 SSN, State ID, or Case ID" />
          <b-input-group-append>
            <b-button :disabled="searchTerm.length < 1" @click="searchEmployees()">
              Search
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </div>
    <div class="card bg-light mt-3">
      <div class="card-header">
        Employees
      </div>
      <div class="card-body">
        <div class="card-group">
          <loadable-chart-card :load-data="loadData" :enable-data-toggle="false" card-style="">
            <template v-slot:default="slotProps">
              <div>
                <span class="d-none">{{ slotProps }}</span>
                <employee-search-table />
              </div>
            </template>
          </loadable-chart-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadableChartCard from '../../LoadableChartCard.vue'
import EmployeeSearchTable from './EmployeeSearchTable.vue'

export default {
  components: {
    LoadableChartCard,
    EmployeeSearchTable
  },
  data() {
    return {
      loadData: Promise.resolve(true),
      searchTerm: ''
    }
  },
  computed: {
    selectedLea() {
      return this.$store.state.globalModule.leaContext.SelectedLea
    }
  },
  watch: {
    selectedLea: {
      handler(lea) {
        this.loadData = this.$store.dispatch('hcptModule/searchEmployees', { lea: lea.number, searchTerm: this.searchTerm })
      }
    },
    searchTerm: {
      handler(searchTerm) {
        if (searchTerm == '')
          this.$store.commit('hcptModule/setEmployees', [])
      }
    }
  },
  methods: {
    searchEmployees() {
      this.loadData = this.$store.dispatch('hcptModule/searchEmployees', { lea: this.selectedLea.number, searchTerm: this.searchTerm })
    }
  }
}
</script>
<template>
  <div>
    <table class="table condensed table-striped table-bordered mb-0" v-if="courseDesignationCrossTab && courseDesignationCrossTab.length > 0">
      <thead>
        <tr>
          <th>Grade</th>
          <th class="text-center" v-for="gradeLevel in gradeLevels" :key="gradeLevel">
            {{ gradeLevel }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="category in categories" :key="category.id">
          <td><b-badge>{{ category.name }}</b-badge></td>
          <td class="text-center" v-for="gradeLevel in gradeLevels" :key="gradeLevel">
            {{ getNumberStudents(category.id, gradeLevel) }}
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
        <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CardStateIcon from '@/components/CardStateIcon.vue'

export default {
  data() {
    return {
      categories: [
        { id: 'AP', name: 'AP' },
        { id: 'IB', name: 'IB' },
        { id: 'CC', name: 'Concurrent' },
        { id: 'Other', name: 'Other' }
      ]  
    }
  },
  components: {
    CardStateIcon
  },
  methods: {
    getNumberStudents(category, gradeLevel) {
      const item = this.cleanedData.find(item => item.category == category && item.gradeLevel === gradeLevel)
      // format value with thousands separator
      return item ? item.numberStudents.toLocaleString() : '-'
    },
  },
  computed: {
    ...mapState('rvsModule', ['courseDesignationCrossTab']),
    cleanedData() {
      // filter out rows that have gradeLevel = 'SS' or '13' or 'EE' or 'SM'
      // filter out rows that have subject = ''
      // format gradeLevels that have a leading zero to be just a number
      var rows = this.courseDesignationCrossTab.filter(item => item.gradeLevel !== 'SS' && item.gradeLevel !== '13' && item.gradeLevel !== 'EE' && item.gradeLevel !== 'SM' && item.gradeLevel != 'P')
      rows = rows.map(item => {
        if (item.gradeLevel.length === 2 && item.gradeLevel[0] === '0') item.gradeLevel = item.gradeLevel[1]
        return item
      })
      return rows
    },
    gradeLevels() {
      // sort the values found in this order: P, K, 01, 02, 03, 04, 05, 06, 07, 08, 09, 10, 11, 12
      var gradeLevels = [...new Set(this.cleanedData.map(item => item.gradeLevel))]
      gradeLevels.sort((a, b) => {
        if (a === 'P') return -1
        if (b === 'P') return 1
        if (a === 'K') return -1
        if (b === 'K') return 1
        return a - b
      })
      return gradeLevels
    },
  }
}
</script>
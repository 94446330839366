<template lang="html">
  <div>
    <loadable-chart-card :load-data="loadData" card-style="" card-class="p-1 inner-card justify-content-center">
      <template v-slot:default="slotProps">
        <div class="chartElem" v-show="slotProps.chartMode">
          <highcharts class="chart" :options="chartOptions" />
        </div>
        <div v-show="!slotProps.chartMode" class="m-3">
          <chart-data-table :items="dataItems()" :title="exportTitle()" />
        </div>
      </template>
    </loadable-chart-card>
    <b-modal size="xl" :id="modalId" v-model="modalShow">
      <template v-slot:modal-header>
        <h4 class="text-center" style="width:100%;">
          {{ subject }} DISTRIBUTION OF STUDENT GROWTH SCORES BY READINESS LEVEL
        </h4>
      </template>
      <readiness-level-distribution-widget :subject="subject" :selected-lea="selectedLea" :readiness-level="readinessLevel" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { LeaTypes } from '../../../../helpers/leaTypes'
import { ReadinessColors, ReadinessLevels, ReadinessLevelHash, ReadinessLevelByNameHash } from './readiness'
import ReadinessLevelDistributionWidget from './ReadinessLevelDistributionWidget'
import LoadableChartCard from '../../../LoadableChartCard.vue'
import ChartDataTable from '../../../ChartDataTable.vue'

export default {
  name: 'readiness-level-chart',
  data() {
    return {
      modalShow: false,
      readinessLevel: null,
      loadData: Promise.resolve(true),
      chartOptions: {
        credits: '',
        chart: {
          zoomType: 'xy',
          backgroundColor: 'rgba(255,255,255,0.002)'
        },
        title: {
          text: ''
        },
        xAxis: [
          {
            categories: ReadinessLevels,
            reversed: true
          }
        ],
        yAxis: {
          plotLines: [
            {
              color: 'blue',
              value: 0,
              width: 2
            }
          ],
          title: {
            text: 'Mean Growth Score'
          }
        },
        colors: ReadinessColors,
        plotOptions: {
          column: {
            colorByPoint: true,
            events: {
              click: ({ point }) => {
                if (this.selectedLea.type != LeaTypes.State && this.canViewStudentData) {
                  this.readinessLevel = point.readinessLevel
                  this.$bvModal.show(this.modalId)
                }
              }
            }
          }
        },
        series: [
          {
            borderColor: '#303030',
            name: 'seriesName',
            type: 'column',
            cursor: 'pointer',
            data: [
            ],
            tooltip: {
              pointFormat: '{series.name}: {point.y:.4f} '
            }
          },
          {
            type: 'errorbar',
            data: [],
            tooltip: {
              pointFormat:
                'Confidence Intervals For Avg Growth Score: {point.low} / {point.high}'
            }
          }
        ]
      }
    }
  },
  components: {
    ReadinessLevelDistributionWidget,
    LoadableChartCard,
    ChartDataTable
  },
  computed: {
    ...mapGetters('academicsModule/growth', [
      'readinessSummary',
      'subjectName'
    ]),
    ...mapState('globalModule', ['userContext']),
    canViewStudentData() {
      return !(this.userContext.isRecruitment || this.userContext.isTeacherCenter || this.userContext.isSuperintendentCoop)
    },
    modalId() {
      return `level-distribution-${this.subject}`.toLowerCase()
    },
    readinessLevelName() {
      return this.readinessLevel ? ReadinessLevels[this.readinessLevel - 1] : ''
    },
    chartData() {
      const readinessScores = this.scores.map(readiness => {
          if (readiness.totalNumber <= 0) return { y: null, readinessLevel: readiness.readinessLevel, confidenceLimits: [0,0] }
          return {
            y: readiness.avgGrowthScore,
            readinessLevel: readiness.readinessLevel,
            confidenceLimits: [
              readiness.lowerConfidenceLimit,
              readiness.upperConfidenceLimit
            ]
          }
        })
      const comprehensiveScores = ReadinessLevels.map((level) => {
        const readiness = readinessScores.find(r => ReadinessLevelHash[r.readinessLevel] == level)
        return readiness || { y: null, readinessLevel: ReadinessLevelByNameHash[level], confidenceLimits: [0,0] }
      })
      return {
        subject: this.subject,
        growthScores: comprehensiveScores,
        confidenceLimits: comprehensiveScores.map(s => s.confidenceLimits),
      }
    }
  },
  methods: {
    dataItems() {
      return this.chartOptions.series[0].data.map((point) => {
        const readinessLevelScore = {
          Subject: this.subject,
          ReadinessLevel: ReadinessLevelHash[point.readinessLevel], //point.readinessLevel ? ReadinessLevels[point.readinessLevel - 1] : '',
          MeanGrowthScore: point.y ? parseFloat(point.y).toFixed(2) : null
        }
        // console.log(ReadinessLevels)
        return readinessLevelScore
      })
    },
    exportTitle() {
      return `${this.subject} Mean Growth Score`
    }
  },
  watch: {
    chartData: {
      immediate: true,
      handler() {
        this.chartOptions.series[0].name = this.chartData.subject
        this.chartOptions.series[0].data = this.chartData.growthScores
        this.chartOptions.series[1].data = this.chartData.confidenceLimits
      }
    },
    selectedLea: {
      immediate: true,
      handler(lea) {
        if (lea.type != LeaTypes.State) {
          this.chartOptions.series[0].cursor = 'pointer'
        } else {
          this.chartOptions.series[0].cursor = undefined
        }
      }
    }
  },
  props: {
    subject: String,
    scores: Array,
  }
}
</script>
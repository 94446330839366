<template>
  <b-row>
    <b-col cols="1" class="text-left h4 p3">
      {{ gradeMap.get(value.grade) }}
    </b-col>
    <b-col cols="11">
      <b-form>
        <b-row>
          <b-form-group label="Cluster" class="col-md-4">
            <b-form-select :disabled="readonly" :options="clusters" v-model="value.clusterId" />
          </b-form-group>
          <b-form-group label="Pathway" class="col-md-4">
            <b-form-select :disabled="readonly" :options="pathways" v-model="value.pathwayId" />
          </b-form-group>
          <b-form-group label="Program of Study" class="col-md-4">
            <b-form-select :disabled="readonly" :options="programsOfStudy" v-model="value.posId" />
          </b-form-group>
        </b-row>
        <b-row>
          <b-form-group label="Job Family" class="col-md-6">
            <b-form-select :disabled="readonly" :options="jobFamilies" v-model="value.jobFamilyId" />
          </b-form-group>
          <b-form-group label="Desired Occupation" class="col-md-6">
            <b-form-select :disabled="readonly" :options="occupations" v-model="value.desiredOccupationId" />
          </b-form-group>
        </b-row>
        <b-row>
          <b-form-group label="Notes" class="col-md-12">
            <b-form-textarea :disabled="readonly" v-model="value.notes" />
          </b-form-group>
        </b-row>
      </b-form>  
    </b-col>
  </b-row>
</template>

<script>
import { gradeMap } from '@/helpers/gradeSpans'
import { mapGetters, mapState } from 'vuex'
import { Types as CommonTypes } from '@/modules/cte/stores'
import { Types as CareerCoachTypes } from '@/modules/cte/stores/careercoach'
import uniqBy from 'lodash.uniqby'

export default {
  computed: {
    ...mapState (CareerCoachTypes.path, [CareerCoachTypes.state.metadata]),
    ...mapGetters(CommonTypes.path, [CommonTypes.getters.occupationalAreaDrilldown]),
    clusters() {
      return uniqBy([{value: null, text: '(All Clusters)'}, ...this.occupationalAreaDrilldown
                                                             .flatMap(i => i.children)
                                                             .map(ch => {return { value: ch.id, text: ch.name}})
      ], 'value')
    },
    pathways() {
      var pathways = [{ value: null, text: '(All Pathways)' }]
      if (this.cluster) pathways = pathways.concat(this.cluster.children.map(i => { return { value: i.id, text: i.name }}))
      return pathways
    },
    programsOfStudy() {
      var pos = [{ value: null, text: '(All Programs of Study)' }]
      if (this.pathway) pos = pos.concat(this.pathway.children.map(i => { return { value: i.id, text: i.name }}))
      return pos
    },
    cluster() {
      return this.occupationalAreaDrilldown.flatMap(i => i.children).find(ch => ch.id == (this.value.clusterId))
    },
    pathway() {
      if (!this.cluster) return null
      return this.cluster.children.find(i => i.id == this.value.pathwayId)
    },
    jobFamilies() {
      return ((this.metadata || {}).occupations || []).filter(o => o.type == 1).map(o => { return { value: o.code, text: o.description }})
    },
    occupations() {
      return ((this.metadata || {}).occupations || []).filter(o => o.type == 4 && o.parentCode == this.value.jobFamilyId).map(o => { return { value: o.code, text: o.description }})
    }
  },
  props: {
    value: Object,
    readonly: Boolean
  },
  data() {
    return {
      gradeMap
    }
  },
  watch: {
    value: {
      deep: true,
      handler(newData) {
        this.$emit('input', newData)
      } 
    }
  }
}
</script>
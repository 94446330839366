<template lang="html">
  <loadable-chart-card :load-data="loadData" :enable-data-toggle="false" style="border: none" container-class="" card-class="card-body p-1 justify-content-center" card-style="">
    <div>
      <div class="row">
        <div class="col-6">
          <student-growth-chart :subject="subject" :teacher-mode="true" />
        </div>
        <div class="col-6">
          <student-growth-chart :subject="subject" :distribution-mode="true" :distribution-type="grade" />
        </div>
      </div>
    </div>
    <div class="pt-3">
      <h3>{{ teacherName }}'s Classroom Growth Summary</h3>
    </div>
    <teacher-growth-summary-table />
    <div class="pt-3">
      <h3>{{ selectedLea.name }} ({{ selectedLea.number }}) Student Growth Summary</h3>
    </div>
    <growth-summary-table />
  </loadable-chart-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { LeaTypes } from '../../../../helpers/leaTypes'
import LoadableChartCard from '../../../LoadableChartCard.vue'
import StudentGrowthChart from './StudentGrowthChart.vue'
import GrowthSummaryTable from './GrowthSummaryTable.vue'
import TeacherGrowthSummaryTable from './TeacherGrowthSummaryTable.vue'

export default {
  data() {
    return {
      distributionType: 'grade',
      LeaTypes: LeaTypes
    }
  },
  props: {
    loadData: {
      type: Promise
    }
  },
  computed: {
    ...mapState('academicsModule/teacherGrowth', ['grade', 'subject', 'teacher']),
    ...mapGetters('academicsModule/teacherGrowth', ['teacherName']),
  },
  components: {
    LoadableChartCard,
    StudentGrowthChart,
    GrowthSummaryTable,
    TeacherGrowthSummaryTable,
  },
  methods: {
    setDistribution(type) {
      this.distributionType = type
    }
  }
}
</script>
import Vue from 'vue'
import { PageMixin } from '../PageMixin'
import { DisableModulesList } from './DisableModulesList'

const ModuleRestrictionPage = Vue.extend({
    name: 'module-restriction-page',
    mixins: [PageMixin],
    data() {
      return {}
    },
    computed: {
      displayLoginButton: {
        get() {
          return this.$store.state.globalModule.displayLoginButton
        },
        set(newValue) {
          this.$store.dispatch('adminModule/setDisplayLoginButton', newValue)
        }
      }
    },
    components: {
        DisableModulesList
    }
})
export { ModuleRestrictionPage }
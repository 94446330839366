
import ApiService from '../services/ApiService'

export class BlendedLearningReadinessModule {
  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
  }

  namespaced = true

  state = {
    needsAssessments: [],
    buildingSummary: [],
    hotspotList: []
  }

  mutations = {
    setNeedsAssessments: (state, data) => state.needsAssessments = data,
    setBuildingSummary: (state, data) => state.buildingSummary = data,
    setHotspotList: (state, data) => state.hotspotList = data
  }

  actions = {
    getNeedsAssessments: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/bl/${payload.lea}/needs-assessment`)
        .then(data => {
          commit('setNeedsAssessments', data.assessments)
          commit('setBuildingSummary', data.buildingSummary)
          commit('setHotspotList', data.hotspotList)
        })
    },
    saveNeedsAssessment: ({ dispatch }, payload) => {
      var saveUrl = payload.isblr ? `${this._baseUrl}api/bl/${payload.assessment.districtLea}/needs-assessment` : `${this._baseUrl}api/bl/needs-assessment`
      return this._apiService.Put(saveUrl, payload.assessment)
        .then(() => dispatch('getNeedsAssessments', { lea: payload.assessment.districtLea }))
    },
    saveHotspotCounts:({ commit }, payload) => {
      return this._apiService.Put(`${this._baseUrl}api/bl/hotspot-counts`, { DistrictLEA: payload.districtLea, ATT: payload.att, TMobile: payload.tMobile, Verizon: payload.verizon })
        .then(data =>  commit('setHotspotList', [data]))
    } 
  }
}

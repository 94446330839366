<template>
  <div class="mt-3">
    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center" :class="{ 'bg-warning text-dark': propertyHasChangedFromSnapshot('digitalModelResponse') }">
        Digital Model
      </div>
      <div class="card-body">
        <b-alert variant="info" class="m-2" show>
          Please complete the following application with responses describing the school and district digital programming.
        </b-alert>
        <h5 class="p-3">
          <strong>Interaction / Delivery</strong>
        </h5>
        <table class="table table-striped table-sm table-condensed mt-3">
          <tbody>
            <tr>
              <td class="w-40 p-3">
                What are the ways teachers and students will interact with digital content (Synchronous or Asynchronous)?
                Please describe instructional expectations and participation for students.
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.digitalContentInteraction" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                What delivery approach will the school or district utilize to meet the needs of both teachers and students (i.e., virtual (online) / remote (distance) or blended (hybrid)? Please explain.
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.deliveryApproach" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Will teachers be dedicated to primarily remote instruction or serving in a dual role,
                teaching both remote and in-person learners? <br>&nbsp;*If teachers will serve in a dual role,
                describe whether it will be simultaneously, meaning remote and in-person at the same
                time with course/schedule specific considerations.
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.teachersServingInDualRole" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Describe the expectation for teacher instruction, interaction, and frequency with virtual students.
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.expectationForTeacherInstruction" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                If utilizing district waivers for <strong>class size</strong>, what supports will the district put in place to support the teacher and students? How will the district monitor and determine if the supports are adequate for teachers who exceed class size in digital learning settings?
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.waiversClassSize" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                If utilizing district waivers for <strong>teaching load</strong>, what supports will the district put in place to support the teacher and students? How will the district monitor and determine if the supports are adequate for teachers who exceed teaching loads in digital learning settings?
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.waiversTeachingLoad" :plaintext="!edit" />
              </td>
            </tr>
          </tbody>
        </table>

        <h5 class="p-3">
          <strong>Technology / Platforms</strong>
        </h5>
        <table class="table table-striped table-sm table-condensed mt-3">
          <tbody>
            <tr>
              <td class="w-40 p-3">
                Identify the learning management system/content management system that schools and districts will
                use? (Canvas, Buzz, Google Classroom, etc.) 
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.identifyLMS" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Describe the digital content that will be available to students, including any digital curriculum
                and supports that are available to teachers. If the district is using a content management system
                (CMS), please specify grades levels and subjects that the CMS will address.
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.describeDigitalContent" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                What video communication software is required for any remote student?  Please describe the video
                communication software the teacher will use to facilitate live conferences between two or more
                participants.
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.videoCommunicationSoftware" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Describe the technology access (device and connectivity) that will be provided to each digital
                learning student to ensure each student receives a free public education. 
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.technologyAccess" :plaintext="!edit" />
              </td>
            </tr>
          </tbody>
        </table>

        <h5 class="p-3">
          <strong>Student Supports</strong>
        </h5>
        <table class="table table-striped table-sm table-condensed mt-3">
          <tbody>
            <tr>
              <td class="w-40 p-3">
                Describe the manner and frequency the district or school will use to monitor the wellness and
                safety (food security, physical, mental health, etc.) of students in a total remote setting.
                (This may be in-person, video communication, etc.). Identify supports available to assist
                students within the district.
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.monitorWellness" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Describe the manner and frequency the district or school will use to monitor the academic
                and student engagement in a virtual setting. Describe the additional supports available
                for students struggling with engagement. 
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.monitorAcademics" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Describe the Academic Response to Intervention plan for remote (virtual) students, including
                additional supports and personnel.  
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.academicResponseIntervention" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Describe the district or school's formative assessment plan to support student learning.
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.formativeAssessmentPlan" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Describe how dyslexia screening and services will be provided to digital learning students. 
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.dyxlexiaScreening" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Describe how Gifted and Talented supports and services will be provided to digital learning students. 
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.giftedAndTalented" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Describe how English for Speakers of Other Languages (ESOL / ESL) supports and services will
                be provided to digital learning students. 
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.eslSupport" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Describe how all Special Education services and supports will be provided to digital learning 
                students, including the process for special education evaluations and conferences.
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.spedSupport" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Describe common and frequently used digital accommodations that the district or school has
                provided to general education teachers to assist students in digital learning classes. 
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.digitalAccommodations" :plaintext="!edit" />
              </td>
            </tr>
          </tbody>
        </table>

        <h5 class="p-3">
          <strong>Teacher Supports</strong>
        </h5>
        <table class="table table-striped table-sm table-condensed mt-3">
          <tbody>
            <tr>
              <td class="w-40 p-3">
                Describe district and school supports to provide on-going digital content and instructional
                supports for teachers.  
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.teacherSupports" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Identify the daily or weekly amount of time teachers will need to develop and plan for
                instruction based on options selected and digital supports provided. How will the district
                support teachers with digital content and planning? 
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.teacherPlanning" :plaintext="!edit" />
              </td>
            </tr>
          </tbody>
        </table>

        <h5 class="p-3">
          <strong>District Supports</strong>
        </h5>
        <table class="table table-striped table-sm table-condensed mt-3">
          <tbody>
            <tr>
              <td class="w-40 p-3">
                Describe how the district will ensure equitable access to opportunities for success for digital learner's needs (poverty, homelessness, migrant, foster care, and military connected students) A link (URL) to the district equity plan may be acceptable if it meets this criteria.
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.districtEquity" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                All students are expected to test (statewide summative assessments) onsite as per DESE guidance.  Provide an overview of how the district may communicate to parents and test digital learning students at a district site.
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.remoteAssessments" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Provide a detailed explanation of how the district will monitor and evaluate the effectiveness of the digital option(s) and the fidelity of implementation as described within this application.
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.monitorEffectiveness" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Describe how the teacher(s) will engage families into the digital learning process. (District may link the District Engagement Plan if digital learning is included.  The plan must address how the district will support parents/students with digital tools and resources)
              </td>
              <td class="w-60">
                <b-textarea rows="3" max-rows="200" v-model="local.engageFamilies" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Provide the URL to evidence of the local school board's approval of the waiver request(s).
              </td>
              <td class="w-60">
                <b-input type="text" v-model="local.urlBoardApproval" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Provide a URL to evidence of stakeholder feedback regarding the waiver request(s) and the district communication plan regarding digital learning program(s). 
              </td>
              <td class="w-60">
                <b-input type="text" v-model="local.urlStakeholderFeedback" :plaintext="!edit" />
              </td>
            </tr>
          </tbody>
        </table>

        <h5 class="p-3">
          <strong>Policies</strong>
        </h5>
        <table class="table table-striped table-sm table-condensed mt-3">
          <tbody>
            <tr>
              <td class="w-40 p-3">
                Please provide a link (URL) to the attendance policy for digital learning students. 
              </td>
              <td class="w-60">
                <b-input type="text" v-model="local.urlAttendancePolicy" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Please provide a link (URL) to the discipline policy for digital learning students.
              </td>
              <td class="w-60">
                <b-input type="text" v-model="local.urlDiscipline" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Please provide a link (URL) to the grading policy for digital learning students.
              </td>
              <td class="w-60">
                <b-input type="text" v-model="local.urlGradingPolicy" :plaintext="!edit" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { JsonResponseMixin } from '@/components/JsonResponseMixin'
import DetailMixin from './DetailMixin'

export default {
  mixins: [JsonResponseMixin, DetailMixin]
}
</script>

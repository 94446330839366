<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { InstructionalOptions } from './instructionalOptions'
import { credits } from '../../../helpers/credits'
import groupBy from 'lodash.groupby'
import sumBy from 'lodash.sumby'

export default {
  name: 'student-count-by-grade-chart',
  data() {
    return {
     animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'column',
          plotBackgroundColor: '#f8f9fa',
          plotShadow: false,
        },
        title: {
          text: 'Student Instructional Option By Grade'
        },          
        legend: {
          enabled: true
        },
        credits: credits.InsightsNightlyDataPulls,
        plotOptions: {
         series: {
           stacking: 'normal'
          },
          column: {
            minPointLength: 3
          }
        },
        xAxis: {
          categories: []
        }
      }
    }
  },
  mixins: [ChartMixin],
  computed: {
    instructionalOption() {
      return this.$store.state.onDemandModule.instructionalOptions
    },
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    },
    gradeLevels() {
      return [...Array(14).keys()].map(i => { return i - 1 })
    }
  },
  watch: {
    instructionalOption: {
      immediate: true,
      handler(newData) {
        if (newData) {
          this.chartOptions.series = []
          this.chartOptions.xAxis.categories = []
          var validGrades = Array(14).fill(false, 0) // create a map of all grades that have values so we can hide grades that have no values

          var fullSeries = []

          InstructionalOptions.forEach(option =>
          {
            var optionData = newData.filter(d => d.instructionalChoice === option.value)

            var grouped = groupBy(optionData, (o) => { return o.gradeLevel })
            var newSeries = {
              name: option.name,
              data: this.gradeLevels.reduce((result, grade) => { 
                var sumVal = sumBy(grouped[grade], 'studentCount')
                if (sumVal > 0 || validGrades[grade+1]) {
                  validGrades[grade+1] = true // set this grade to true in the map                
                }
                result.push({
                  name: this.getGradeLevel(grade),
                  y: sumVal || null
                })
                return result
              }, []),
              color: option.color
            }

            if (newSeries.data.length > 0) fullSeries.push(newSeries)
          })

          var indices = []
          for (var i=0; i<validGrades.length; i++) {
            if (validGrades[i]){
              this.chartOptions.xAxis.categories.push(this.getGradeLevel(i-1))
              indices.push(i)
            }
          }
          fullSeries.forEach(series => {
            series.data = indices.map(i => series.data[i])
            this.chartOptions.series.push(series)
          })
          
        }
      }
    }
  },
  methods: {
    leaFormatter(lea) {
      if (this.leaDictionary[lea])
        return `${this.leaDictionary[lea].name} (${lea})`

      return lea
    },
    dataItems() {
      var rows = []

      for(var grade = -1; grade<=12; grade++) {

        var gradeRows = {}

        this.instructionalOption.filter(row => row.gradeLevel == grade).forEach(row => {
          gradeRows[row.lea] = { ...gradeRows[row.lea] }      
          if (!gradeRows[row.lea][row.instructionalChoice]) gradeRows[row.lea][row.instructionalChoice] = 0
          gradeRows[row.lea][row.instructionalChoice] += row.studentCount
        })

        var newRows = Object.keys(gradeRows).sort().map(k => {return { 'LEA': this.leaFormatter(k), 'Grade': this.getGradeLevel(grade), 'Onsite': gradeRows[k]["1"] || 0, 'Virtual': gradeRows[k]["2"] || 0, 'Hybrid': gradeRows[k]["3"] || 0, 'N/A': gradeRows[k]["4"] || 0 }})
        rows = [...rows, ...newRows]
      }   
      
      return rows
    },
    getOption(number) {
      return InstructionalOptions.find(o => o.value === number)
    },
    getGradeLevel(number) {
      switch (parseInt(number)) {
        case -1: return "Ungraded"
        case 0: return "Kindergarten"
        default: return `Grade ${number}`
      }
    }
  }
}
</script>

const CoordinatorCodes = {
  FEDERAL_PROGRAM: "1",
  SUPERINTENDENT: "U",
  ASSISTANT_SUPERINTENDENT: "X",
  PRINCIPAL : "P",
  TECHNOLOGY : "T",
  SIS_ESCHOOL_PLUS : "S",
  SIS_EFINANCE_PLUS: "F"
}

export { CoordinatorCodes }
import ApiService from '../services/ApiService'
import * as moment from 'moment'

export class OverviewModule {
  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
  }

  namespaced = true

  state = {
    enrollment: [],
    demographics: {},
    waivers: [],
    waiverStats: [],
    includeWaiverDetails: false,
    schoolboard: [],
    freeReducedLunches: [],
    essaindex: [],
    lettergrades: [],
    giftedAndTalented: [],
    graduationRates: [],
    graduationCounts: [],
    attendanceRates: [],
    instructionalOptions: {
      onsite: [],
      virtual: [],
      hybrid: [],
    },
  }

  getters = {
    waiversByType: state => {
      var waiversByType = {}
      state.waiverStats.forEach(s => {
        waiversByType[s.type] = state.waivers.filter(w => w.type == s.type)
      })
      return waiversByType
    }
  }

  mutations = {
    setEnrollment: (state, enrollment) => (state.enrollment = enrollment),
    setDemographics: (state, demographics) => (state.demographics = demographics),
    setSchoolBoard: (state, schoolboard) => (state.schoolboard = schoolboard),
    setWaiverStats: (state, waiverStats) => {
      state.waiverStats = waiverStats
    },
    setWaivers: (state, waivers) => {
      state.waivers = waivers.map(w => {
        w.valid = `${moment(w.dateGranted).format('l')} – ${moment(w.dateExpires).format('l')}`
        delete w.dateGranted
        delete w.dateExpires
        return w
      })
    },
    setFreeReducedLunches: (state, freeReduced) => (state.freeReducedLunches = freeReduced),
    setEssaSchoolIndex: (state, essaindex) => (state.essaindex = essaindex),
    setLetterGrades: (state, lettergrades) => (state.lettergrades = lettergrades),
    setIncludeWaiverDetails: (state, includeWaiverDetails) => (state.includeWaiverDetails = includeWaiverDetails),
    setGiftedAndTalented: (state, giftedAndTalented) => (state.giftedAndTalented = giftedAndTalented),
    setGraduationRates: (state, graduationRates) => (state.graduationRates = graduationRates),
    setGraduationCounts: (state, graduationCounts) => (state.graduationCounts = graduationCounts),
    setAttendanceRates: (state, attendanceRates) => (state.attendanceRates = attendanceRates),
    setInstructionalOptions: (state, instructionalOptions) => (state.instructionalOptions = instructionalOptions),
  }

  actions = {
    getEnrollment: ({ commit }, payload) => {
      return this._apiService
        .Get(`${this._baseUrl}api/leaoverview/${payload.lea}/enrollment`)
        .then(enrollment => commit('setEnrollment', enrollment))
    },
    getDemographics: ({ commit }, payload) => {
      return this._apiService
        .Get(`${this._baseUrl}api/leaoverview/${payload.lea}/demographics`)
        .then(demographics => commit('setDemographics', demographics))
    },
    getWaivers: ({ commit }, payload) => {
      return this._apiService
        .Get(`${this._baseUrl}api/leaoverview/${payload.lea}/waivers/${payload.includeDetails}?activeOnly=${payload.activeOnly}`)
        .then(results => {
          commit('setWaiverStats', results.stats)
          commit('setWaivers', results.details)
        })
    },
    getSchoolBoard: ({ commit }, payload) => {
      return this._apiService
        .Get(`${this._baseUrl}api/leaoverview/${payload.lea}/schoolboard`)
        .then(schoolboard => commit('setSchoolBoard', schoolboard))
    },
    getFreeReducedLunches: ({ commit }, payload) => {
      return this._apiService
        .Get(`${this._baseUrl}api/leaoverview/${payload.lea}/freeReducedLunch`)
        .then(freeReduced => {
          freeReduced.map(fr => Object.assign(fr, { total: fr.free + fr.reduced }))
          return commit('setFreeReducedLunches', freeReduced)
        })
    },
    getEssaSchoolIndex: ({ commit }, payload) => {
      return this._apiService
        .Get(`${this._baseUrl}api/leaoverview/${payload.lea}/essaSchoolIndex`)
        .then(essaindex => commit('setEssaSchoolIndex', essaindex))
    },
    getLetterGrades: ({ commit }, payload) => {
      return this._apiService
        .Get(`${this._baseUrl}api/leaoverview/${payload.lea}/lettergrades`)
        .then(lettergrades => commit('setLetterGrades', lettergrades))
    },
    getGiftedAndTalented: ({ commit }, payload) => {
      return this._apiService
        .Get(`${this._baseUrl}api/leaoverview/${payload.lea}/giftedAndTalented`)
        .then(giftedAndTalented => commit('setGiftedAndTalented', giftedAndTalented))
    },
    getGraduationRates: ({ commit }, payload) => {
      return this._apiService
        .Get(`${this._baseUrl}api/leaoverview/${payload.lea}/graduationRates`)
        .then(graduationData => {
          commit('setGraduationRates', graduationData.graduationRates),
          commit('setGraduationCounts', graduationData.graduationCounts)
      })
    },
    getAttendanceRates: ({ commit }, payload) => {
      return this._apiService
        .Get(`${this._baseUrl}api/leaoverview/${payload.lea}/attendanceRates`)
        .then(attendanceRates => commit('setAttendanceRates', attendanceRates))
    },
    getInstructionalOptions: ({ commit }, payload) => {
      return this._apiService
        .Get(`${this._baseUrl}api/leaoverview/${payload.lea}/instructionalOptions/demographics`)
        .then(instructionalOptions => commit('setInstructionalOptions', instructionalOptions))
    },
  }
}

<template>
  <div>
    <b-form-row>
      <div class="card bg-light mb-2 w-100" v-if="mode == 'view'">
        <div class="card-body">
          <b-col cols="6">
            <filter-item v-model="selectedCollege" :options="collegeOptions" label="College" />
          </b-col>
          <b-col cols="6">
            <filter-item v-model="selectedDegree" :options="degreeOptions" label="Degree" />
          </b-col>
        </div>
      </div>
    </b-form-row>

    <paginated-table small :items="filteredMajors" :fields="fields" v-if="mode == 'view'" sort-by="collegeId" striped>
      <template v-slot:head(action)>
        <b-button @click="onBeginAdd" variant="primary">
          <i class="fa fa-fw fa-plus-circle" /> Add
        </b-button>
      </template>
      <template v-slot:cell(action)="data">
        <command-buttons
          :options="{showAdd: false, showEdit: true, showDelete: false, operation:'view'}"
          @on-edit="onRowEdit(data.item)"
          @on-delete="onRowDelete(data.item)"
        />
      </template>
    </paginated-table>
    <div v-if="mode != 'view'">
      <b-form>
        <b-form-group id="input-group-1" label="College:" label-for="input-college">
          <b-form-select id="input-college" v-model="editingItem.collegeId" :options="collegeOptions" :disabled="this.mode == 'edit'" />
        </b-form-group>
        <b-form-group id="input-group-2" label="Degree:" label-for="input-degree">
          <b-form-select id="input-degree" v-model="editingItem.parentId" :options="editingDegreeOptions" :disabled="this.mode == 'edit'" />
        </b-form-group>
        <b-form-group id="input-group-3" label="Name:" label-for="input-name">
          <b-form-input id="input-name" v-model="editingItem.name" />
        </b-form-group>
      </b-form>

      <b-button variant="primary" :disabled="!isValid" @click="onSave">
        Save
      </b-button>
      <b-button variant="danger" class="mx-1" @click="onCancelEdit">
        Cancel
      </b-button>
    </div>
  </div>
</template>

<script>
import { Types } from '@/modules/cte/stores/careercoach'
import { mapState } from 'vuex'
import orderBy from 'lodash.orderby'
import PaginatedTable from '@/components/PaginatedTable'
import CommandButtons from '@/components/shared/CommandButtons.vue'
import FilterItem from '../../shared/FilterItem.vue'

export default {
  data() {
    return {
      mode: 'view',
      fields: [
        { key: "collegeId", sortable: true, sortByFormatted: true, formatter: data => this.getCollegeName(data), label: "College" },
        { key: "parentId", sortable: true, sortByFormatted: true, formatter: data => this.getDegreeName(data), label: "Degree" },
        { key: "name", sortable: true },
        { key: "action", thClass: 'text-right' }
      ],
      editingItem: {},
      selectedCollege: null,
      selectedDegree: null
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.colleges, Types.state.degrees, Types.state.majors]),
    isValid() {
      return (this.editingItem?.name || '').length > 0 && (this.editingItem?.parentId != null) && (this.editingItem?.collegeId != null)
    },
    collegeOptions() {
      return orderBy(this.colleges.map(c => { return {
        text: c.name,
        value: c.parentId
      }}), (item) => (item.text || '').trim().toLowerCase())
    },
    degreeOptions() {
      return orderBy(this.degrees.filter(c => { return c.parentId == this.selectedCollege })
      .map(c => { return {
        text: c.name,
        value: c.id
      }}), (item) => (item.text || '').trim().toLowerCase())
    },
    editingDegreeOptions() {
      return this.degrees.filter(c => { return c.parentId == (this.editingItem || {}).collegeId })
      .map(c => { return {
        text: c.name,
        value: c.id
      }})
    },
    filteredMajors() {
      if (!this.selectedCollege && !this.selectedDegree) return this.majors
      
      var majors = this.majors
      if (this.selectedCollege) majors = majors.filter(m => m.collegeId == this.selectedCollege)
      if (this.selectedDegree) majors = majors.filter(m => m.parentId == this.selectedDegree)
      return majors
    }
  },
  methods: {
    getCollegeName(collegeId) {
      return (this.colleges.find(c => c.parentId == collegeId) || {}).name
    },
    getDegreeName(degreeId) {
      return (this.degrees.find(d => d.id == degreeId) || {}).name
    },
    onRowEdit(item) {
      this.selectedItem = item
      this.editingItem = Object.assign({}, item)
      this.mode = 'edit'
    },
    onBeginAdd() {
      this.editingItem = {
        name: null,
        collegeId: this.selectedCollege,
        parentId: this.selectedDegree
      }
      this.mode = 'add'
    },
    onCancelEdit() {
      this.mode = 'view'
      this.editingItem = null

      // reset filter
      var college = this.selectedCollege
      var degree = this.selectedDegree
      this.selectedCollege = null
      this.selectedDegree = null
      var self = this
      this.$nextTick(() => {
        self.selectedCollege = college
        self.$nextTick(() => {
          self.selectedDegree = degree
        })
      })
    },
    onSave() {
      var action = Promise.resolve()

      if (this.mode == 'add') action = this.$store.dispatch(Types.actions.addMajor, {
        Name: this.editingItem.name,
        ParentId: this.editingItem.parentId
      })
      else if (this.mode == 'edit') action = this.$store.dispatch(Types.actions.updateMajor, {
        CollegeDegreeMajorId: this.editingItem.collegeDegreeMajorId,
        Name: this.editingItem.name,
        ParentId: this.editingItem.parentId
      })

      action.then(() => this.$store.dispatch(Types.actions.getMajors))
            .then(() => (this.mode = 'view'))
    }
  },
  components: {
    PaginatedTable,
    CommandButtons,
    FilterItem
  },
  watch: {
    selectedCollege() {
      this.selectedDegree = null
    },
  }
}
</script>

<style>

</style>
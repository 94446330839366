<template>
  <div>
    <div class="card bg-light mt-3">
      <a class="anchor" id="licenses" />
      <div class="card-header">
        Workforce Stability Index <span v-if="getYear">({{ getYear }})</span>
      </div>
      <div class="card-body">
        <div class="card-group">
          <comparison-to-state-median-table />
        </div>
        <div class="card-group">
          <comparison-to-state-distribution-chart :load-data="loadData" />
          <comparison-of-performance-across-wsi-variables-chart :load-data="loadData" />
        </div>
      </div>
    </div>
    <!-- <div class="card bg-light mt-3">
      <a class="anchor" id="licenses" />
      <div class="card-header">
        Workforce Stability Index (2017-2021)
      </div>
      <div class="card-body">
        <div class="card-group">
          <workforce-stability-index-chart :load-data="loadData" :selected-lea="selectedLea" />
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
//import WorkforceStabilityIndexChart from './WorkforceStabilityIndexChart.vue'
import ComparisonToStateMedianTable from './ComparisonToStateMedianTable.vue'
import ComparisonToStateDistributionChart from './ComparisonToStateDistributionChart.vue'
import ComparisonOfPerformanceAcrossWsiVariablesChart from './ComparisonOfPerformanceAcrossWsiVariablesChart.vue'
import { mapState } from 'vuex'

export default {
    data() {
        return {
            loadData: null
        }
    },
    computed: {
      ...mapState('hcptModule', ['workforceStabilityIndex2']),
      getYear() {
        if (this.workforceStabilityIndex2.leaValues === undefined) return null
        return this.workforceStabilityIndex2.leaValues[0].fiscalYear + 1990
      }
    },
    components: {
        //WorkforceStabilityIndexChart,
        ComparisonToStateMedianTable,
        ComparisonToStateDistributionChart,
        ComparisonOfPerformanceAcrossWsiVariablesChart
    },
    watch: {
        selectedLea: {
        immediate: true,
            handler(newLea) {
                if (newLea && newLea.number)
                    this.loadData = Promise.all([
                        this.$store.dispatch('hcptModule/getWorkforceStabilityIndex', { lea: newLea.number }),
                        this.$store.dispatch('hcptModule/getWorkforceStabilityIndex2', { lea: newLea.number }),
                    ])
            }
        }
    }
}
</script>
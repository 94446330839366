<template>
  <paginated-table :load-data="loadData" :items="categoriesPivot" :fields="fields">
    <template v-slot:cell(action)="data">
      <div>
        <command-buttons
          :options="{showAdd: false, showEdit: !data.item.name.includes('Transfer'), showDelete: !data.item.name.includes('Transfer'), operation:'view'}"
          @on-edit="onRowEdit(data.item)"
          @on-delete="onRowDelete(data.item)"
        />
        <div v-if="data.item.name.includes('Transfer')">
          N/A
        </div>
      </div>
    </template>
    <template v-slot:head(action)>
      <b-button variant="success" size="sm" :to="{name: 'new-plan-category', params: {mode: 'new'}}">
        <i class="fa fa-fw fa-plus-circle" /> Add
      </b-button>
    </template>
    <template v-for="column in typeFields" v-slot:[cellName(column)]="scope">
      <div v-if="scope.value.enabled == false" :key="column">
        N/A
      </div>
      <div v-else :key="column">
        <li v-for="item in scope.value.items" :key="item">
          {{ item }}
        </li>
      </div>
    </template>
  </paginated-table>
</template>
<script>
import PaginatedTable from '@/components/PaginatedTable.vue'
import { mapState } from 'vuex'
import { Types } from '@/modules/esa/stores/'
import { AdminTypes } from '@/modules/esa/stores/admin.js'
import CommandButtons from '@/components/shared/CommandButtons.vue'
import { Roles } from '@/modules/esa/shared/enums'

export default {
  data(){
    return {
      loadData: null,
      typeFields: ['personnel', 'materials', 'services', 'other']
    }
  },
  computed:{
    ...mapState('globalModule', ['userContext']),
    ...mapState(Types.path, [Types.state.planCategories, Types.state.esaFiscalYear]),
    categoriesPivot(){
      if(this.planCategories){
        return this.planCategories.map(c => {
          return{
            id: c.id,
            name: c.name,
            programCode: c.programCode,
            fiscalYear: c.fiscalYear,
            description: c.description,
            sortOrder: c.sortOrder,
            personnel: c.types.filter(t => t).find(t => t.key == 1),
            materials: c.types.filter(t => t).find(t => t.key == 3),
            services: c.types.filter(t => t).find(t => t.key == 4),
            other: c.types.filter(t => t).find(t => t.key == 5),
          }
        })
      }
      return []
    },
    fields() {
      return [
        ...[
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'description',
          sortable: true
        },
        {
          key: 'programCode',
          label: 'Program',
          sortable: true
        },
        {
          key: 'personnel',
          sortable: false
        },
        {
          key: 'materials',
          label: 'Materials, Supplies, and Equipment'
        },
        {
          key: 'services',
          label: 'Services'
        },
        {
          key: 'Other',
          label: 'Other'
        }
      ],
        ...(this.isAdmin ? [{
          key: 'action',
          class: 'nowrap text-right'
        }] : [])
      ]
    },
    isAdmin() {
      const roles = this.userContext.roles || []
      return roles.includes(Roles.Admin) ||
        roles.includes(Roles.SystemAdministrator)
    }
  },
  components:{
    PaginatedTable,
    CommandButtons
  },
  beforeRouteEnter(to, from, next){
    next(vm => {
      vm.loadData = vm.$store.dispatch('ESA/getPlanCategories', {fy: vm.esaFiscalYear})
    })
  },
  methods:{
    onRowEdit(item){
      this.$store.commit(AdminTypes.mutations.setSelectedPlanCategory, item)
      this.$router.push({name: 'plan-category-edit'})
      .catch((err) => console.log(err))
    },
    onRowDelete(item){
      this.$store.dispatch(AdminTypes.actions.deletePlanCategory, {id: item.id})
      .then(() => {
        this.loadData = this.$store.dispatch('ESA/getPlanCategories', {fy: this.esaFiscalYear})
        .catch((err) => console.log(err))
      })
    },
    cellName(column){
      return `cell(${column})`
    }
  }
}
</script>
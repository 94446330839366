<template>
  <div>
    <b-form>
      <b-form-group label="Program of Study Name">
        <b-form-input v-model="name" type="text" />
      </b-form-group>
      <b-form-group label="Non-Traditional for Gender">
        <b-form-select v-model="nonTradGender" :options="Genders" />
      </b-form-group>
      
      <b-form-row>
        <b-col class="text-center">
          <b-button variant="primary" class="mr-2" @click="save">
            Save
          </b-button>
          <b-button variant="secondary" @click="cancel">
            Cancel
          </b-button>
        </b-col>
      </b-form-row>
    </b-form>
  </div>
</template>

<script>
import { clustersEditMixin } from '../clustersEditMixin'
import { Genders } from '@/modules/cte/components/careerCoach/enums'

export default {
  data() {
    return {
      nonTradGender: null,
      Genders: [{value: null, text: '(None)'}, ...Genders]
    }
  },
  methods: {
    preparePayload() {
      return {
        set: this.set,
        item: {
          name: this.name,
          nonTradGender: this.nonTradGender
        }  
      }
    }
  },
  watch: {
    currentDataItem: {
      immediate: true,
      handler(newItem) {
        this.name = newItem?.item?.name
        this.nonTradGender = newItem?.item?.nonTraditionalGender
      }
    }
  },
  mixins: [
    clustersEditMixin
  ]
}
</script>
<template>
  <div>
    <b-row v-if="mode === 'list'">
      <b-col>      
        <label class="col-md-3 mb-3 mt-1">Show Visits for Academic Year:</label>
        <div class="col-md-9 mb-3 mt-1 p-0">
          <b-select :options="visitAcademicYears" v-model="academicYear" @change="onAyChanged" />
        </div>
        <paginated-table :items="visits" :fields="fields" caption="Visits" :caption-top="true" :per-page="10">
          <template v-slot:[`head(${f.key})`]="data" v-for="f in visitTopicFields">
            <div :key="f.key">
              <span>
                {{ data.label }}
              </span>
            </div>
          </template>
          <template #head(action)>
            <b-dropdown v-if="!currentStudent.isReadOnly" variant="primary" text="Add Visit" right>
              <b-dropdown-item @click="addNew">
                <i class="fa fa-plus-circle fa-fw" />
                Add New Visit
              </b-dropdown-item>
              <b-dropdown-item @click="addToBulk">
                <i class="fa fa-link fa-fw" />
                Link to Group Visit
              </b-dropdown-item>
            </b-dropdown>
          </template>          
          <template #cell(isBulkVisit)="data">
            <pretty-checkbox :value="data.value" />
          </template>
          <template v-slot:[`cell(${f.key})`]="data" v-for="f in visitTopicFields">
            <pretty-checkbox :value="data.item.topics.filter(t=>t == data.field.key).length" :key="f.date" />
          </template>
          <template #cell(action)="data">
            <b-btn
              size="sm"
              variant="primary"
              title="Edit Visit"
              class="mr-2"
              @click="editVisit(data.item)"
              v-if="!data.item.isBulkVisit && !currentStudent.isReadOnly"
            >
              <i class="far fa-edit fa-fw" />
            </b-btn>
            <b-btn
              size="sm"
              variant="danger"
              :title="data.item.isBulkVisit ? 'Remove Student from Bulk Visit' : 'Remove Visit'"
              class="mr-2"
              @click="removeVisit(data)"
              v-if="!currentStudent.isReadOnly"
            >
              <i class="far fa-times-circle fa-fw" />
            </b-btn>
            <b-btn
              size="sm"
              variant="secondary"
              title="Show Details"
              @click="data.item._showDetails = !data.item._showDetails"
            >
              <i class="fas fa-search-plus fa-fw" />
            </b-btn>  
          </template>
          <template #row-details="row">
            <b-row>
              <b-form-group label="Location" class="col-md-2">
                <b-form-input readonly v-model="row.item.location" />
              </b-form-group>
              <b-form-group label="Type" class="col-md-2">
                <b-form-input readonly v-model="row.item.type" />
              </b-form-group>
              <b-form-group label="Notes" class="col-md-8">
                <b-form-textarea readonly v-model="row.item.notes" />
              </b-form-group>
            </b-row>
          </template>
        </paginated-table>
      </b-col>
    </b-row>
    <b-row v-else-if="mode === 'bulkVisit'">
      <paginated-table :items="bulkVisitsTransformed" :fields="fields" caption="Group Visits" :caption-top="true">        
        <template v-slot:[`cell(${f.key})`]="data" v-for="f in visitTopicFields">
          <pretty-checkbox :value="data.item.topics.filter(t=>t == data.field.key).length" :key="f.date" />
        </template>
        <template #head(action)>
          <b-button @click="mode = 'list'">
            Return to Visit List
          </b-button>
        </template> 
        <template #cell(action)="data">
          <b-btn size="sm" variant="primary" title="Edit Visit" class="mr-2" @click="addBulkLink(data)">
            <i class="fa fas fa-link" />
            Add Link
          </b-btn>          
          <b-btn size="sm" variant="secondary" title="Show Details" @click="data.item._showDetails = !data.item._showDetails">
            <i class="fas fa-search-plus fa-fw" />
          </b-btn>
        </template>        
        <template #row-details="row">
          <b-row>
            <b-form-group label="Location" class="col-md-2">
              <b-form-input readonly v-model="row.item.location" />
            </b-form-group>
            <b-form-group label="Type" class="col-md-2">
              <b-form-input readonly v-model="row.item.type" />
            </b-form-group>
            <b-form-group label="Notes" class="col-md-8">
              <b-form-textarea readonly v-model="row.item.notes" />
            </b-form-group>
          </b-row>
        </template>
      </paginated-table>
    </b-row>
    <b-row v-else>
      <b-col cols="10" offset="1">
        <h4>
          {{ this.mode == "new" ? 'Add New Visit' : 'Edit Visit' }}
        </h4>
        <b-form>
          <b-form-row>
            <b-col class="pr-4">
              <b-form-group label="Grade Level" label-cols="4" content-cols="4">
                <b-form-select v-model="gradeLevel" :options="careerCoachGrades" />
              </b-form-group>
            </b-col>
            <b-col class="pr-4">
              <b-form-group label="Date" label-cols="4" content-cols="4">
                <b-input-group class="mb-3">
                  <b-form-input
                    id="example-input"
                    type="text"
                    v-model="textDate"
                    placeholder="MM/DD/YYYY"
                    autocomplete="off"
                    @blur="onDateChanged"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="visitDate"
                      button-only
                      right
                      locale="en-US"
                      :date-format-options="{year: 'numeric', month: '2-digit', day: '2-digit'}"
                      aria-controls="example-input"
                      @context="onContext"
                    />
                  </b-input-group-append>
                </b-input-group>                
                <!-- <b-form-datepicker v-model="visitDate" /> -->
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="visitDate && (new Date(visitDate).getMonth() == 6 || new Date(visitDate).getMonth() == 7)">
            <b-col class="pr-4">
              <b-alert variant="info" show>
                You must specify the Academic Year to which July or August visits apply.
              </b-alert>
            </b-col>            
            <b-col class="pr-4">
              <b-form-group label="Academic Year" label-cols="4" content-cols="4">
                <b-form-select :options="availableAYs" v-model="visitAy" />
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col class="pr-4">
              <b-form-group label="Visit Type" label-cols="4">
                <b-form-select v-model="visitType" :options="metadata.visit.types" />
                <span class="text-danger" v-if="$v.visitType.$invalid">*Visit Type is required.</span>
              </b-form-group>
            </b-col>
            <b-col class="pr-4">
              <b-form-group label="Visit Location" label-cols="4">
                <b-form-select v-model="visitLocation" :options="metadata.visit.locations" />
                <span class="text-danger" v-if="$v.visitLocation.$invalid">*Visit Location is required.</span>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col class="d-flex flex-row flex-wrap">
              <b-form-checkbox v-model="topics" name="topics" v-for="a in metadata.visit.topics" :key="a.value" class="mx-4" style="width: 230px" :value="a.value">
                {{ a.text }}
              </b-form-checkbox>
            </b-col>
          </b-form-row>
          
          <b-form-row class="mt-2">
            <b-col class="pr-4">
              <b-form-group label="Notes">
                <b-form-textarea v-model="notes" />
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row class="mt-4">
            <b-col class="text-center">
              <b-btn variant="primary" class="mr-2" @click="save" :disabled="$v.visitType.$invalid || $v.visitLocation.$invalid">
                <i class="fa fa-save fa-fw" /> Save
              </b-btn>
              <b-btn variant="secondary" @click="cancelSave">
                <i class="fa fa-times fa-fw" /> Cancel
              </b-btn>
            </b-col>
          </b-form-row>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { gradeMap } from '@/helpers/gradeSpans'
import { metadata } from '@/modules/cte/stores/careercoach/metadata'
import { mapState } from 'vuex'
import { Types } from '@/modules/cte/stores/careercoach'
import { formatDate } from '@/helpers/formatters'
import PrettyCheckbox from '@/components/PrettyCheckbox'
import { required } from 'vuelidate/lib/validators'
import PaginatedTable from '@/components/PaginatedTable.vue'

export default {
  components: {
    PrettyCheckbox,
    PaginatedTable
  },
  computed: {
    ...mapState(Types.path, [Types.state.currentStudent, Types.state.bulkVisits, Types.state.visitAcademicYears]),    
    careerCoachGrades() {
      return [
        { value: '07', text: 'Grade 7'},
        { value: '08', text: 'Grade 8'},
        { value: '09', text: 'Grade 9'},
        { value: '10', text: 'Grade 10'},
        { value: '11', text: 'Grade 11'},
        { value: '12', text: 'Grade 12'}
      ]
    },
    fields() {
      var fields = this.mode == 'bulkVisit' ? 
      [
        { key: 'visitDate', formatter: (v)=>{ return formatDate(v, 'short-date')}, thStyle: 'width: 10%;', class: 'text-center' },
      ]:[
        { key: 'grade', thStyle: 'width:10%' },
        { key: 'isBulkVisit', label: 'Group Visit' },
        { key: 'visitDate', formatter: (v)=>{ return formatDate(v, 'short-date')}, thStyle: 'width: 10%;', class: 'text-center' },
      ];

      fields.splice(fields.length, 0, ...this.visitTopicFields)
      fields.splice(fields.length, 0, {key: 'action', class: 'text-right', thStyle: 'width: 12%' })

      return fields
    },
    visits() {
      return (this.currentStudent.visits || []).map(v=> { return { ...v.visit, ...v.studentVisit, ...JSON.parse(v.visit.visitData), _showDetails: false} })
    },    
    bulkVisitsTransformed() {      
      return (this.bulkVisits || [])
        .sort((a,b) => new Date(a.visitDate) - new Date(b.visitDate))
        .filter(bv => !(this.currentStudent.visits.flatMap(sv => sv.visit.visitId)).includes(bv.visitId)).map(v=> { return { ...v, ...JSON.parse(v.visitData), _showDetails: false} })
    },
    availableAYs() {
      if(this.visitDate){
        var dateParsed = new Date(this.visitDate)
        return [
          {text: '--Please select an option--', value: null, disabled: true},
          {text: `${dateParsed.getFullYear() -1}/${dateParsed.getFullYear()} Academic Year`, value: dateParsed.getFullYear() - 2000},
          {text: `${dateParsed.getFullYear()}/${dateParsed.getFullYear() + 1} Academic Year`, value: dateParsed.getFullYear() - 1999}
        ]
      }
      return []
    }
  },
  data() {
    return {
      gradeMap,
      metadata,
      mode: 'list',
      textDate: null,
      currentVisitId: null,
      initialVisit: false,
      visitType: null,
      visitLocation: null,
      visitDate: new Date(),
      notes: null,
      gradeLevel: null,
      topics: [],
      academicYear: this.ay,
      visitAy: this.ay,
      visitTopicFields:  [
        { key: 'careerAssessment', label: 'Career Assessment', class: 'text-center' },
        { key: 'careerPlan', label: 'Career Plan', class: 'text-center' },
        { key: 'collegeAdmissions', label: 'College Admissions', class: 'text-center' },
        { key: 'financialAid', label: 'Financial Aid', class: 'text-center' },
        { key: 'academicConcurrentCredit', label: 'Academic Concurrent Credit', class: 'text-center' },
        { key: 'cteConcurrentCredit', label: 'CTE Concurrent Credit', class: 'text-center' },
        { key: 'apprenticeship', label: 'Apprenticeship', class: 'text-center' },
        { key: 'workbasedLearning', label: 'WBL', class: 'text-center' }
      ],
    }
  },
  validations:{
    visitType:{
      required
    },
    visitLocation:{
      required
    }
  },
  methods: {
    onAyChanged(newAY){
      this.$store.dispatch(Types.actions.getCurrentStudentVisits, {studentId: this.currentStudent.studentId, lea: this.currentStudent.schoolLEA, ay: newAY})
    },
    onDateChanged() {
      console.log(this.textDate)
      this.visitDate = new Date(this.textDate)
    },
    addNew() {
      this.mode = 'new',
      this.currentVisitId = null,
      this.initialVisit = false,
      this.visitType = null,
      this.visitLocation = null,
      this.visitDate = new Date(),
      this.isBulkVisit = false,
      this.notes = null,
      this.gradeLevel = this.currentStudent.grade,
      this.topics = []
    },
    addToBulk() { 
      this.mode = 'bulkVisit'
      this.$store.dispatch(Types.actions.getBulkVisits, { lea: this.currentStudent.schoolLEA, grade: this.currentStudent.grade, ay: this.academicYear })
    },
    addBulkLink(data){
      this.$store.dispatch(Types.actions.addStudentToBulkVisit, { lea: this.currentStudent.schoolLEA, visitId: data.item.visitId, student: this.currentStudent })
        .then(this.mode = 'list')
    },
    save() {
      let visit = {}

      if (this.mode === 'new') {
        visit = {
          grade: this.currentStudent.grade, 
          visit: {
            ay: this.visitAy,
            grade: this.gradeLevel,
            lea: this.currentStudent.schoolLEA,
            isBulkVisit: false,
            visitDate: this.visitDate, 
            visitData: JSON.stringify({ location: this.visitLocation, type: this.visitType, topics: this.topics, notes: this.notes }) 
          }, 
          studentVisit: {
            studentId: this.currentStudent.studentId,
            initialVisit: this.initialVisit 
          } 
        }
        return this.$store.dispatch(Types.actions.createVisit, {studentId: this.currentStudent.studentId, lea: this.lea, visit: visit})
          .then(() => this.mode = 'list')
      } else if (this.mode === 'edit') {
        //debugger
        visit = this.currentStudent.visits.filter(v => v.visit.visitId === this.currentVisitId)[0]        
        visit.studentVisit.initialVisit = this.initialVisit
        visit.visit.ay = this.visitAy
        visit.visit.grade = this.gradeLevel
        visit.visit.visitDate = this.visitDate
        visit.visit.visitData = JSON.stringify({ location: this.visitLocation, type: this.visitType, topics: this.topics, notes: this.notes })
        return this.$store.dispatch(Types.actions.updateVisit, {studentId: this.currentStudent.studentId, lea: this.lea, visit: visit})
          .then(() => this.mode = 'list')
      }
      if (visit.initialVisit) {
        this.currentStudent.visits.filter(v=>v.id != this.currentVisitId).forEach(element => {
          element.initialVisit = false
        });
      }      
    },
    onContext(ctx) {
        // The date formatted in the locale, or the `label-no-date-selected` string
        this.textDate = ctx.selectedFormatted
        // The following will be an empty string until a valid date is entered
        this.selected = ctx.selectedYMD
    },
    cancelSave() {
      this.mode = 'list'
    },
    async removeVisit(dataItem) {
      
      let result = await this.$bvModal.msgBoxConfirm("Delete this visit?")

      if (result) {
        if(dataItem.item.isBulkVisit) 
        {
          this.$store.dispatch(Types.actions.removeStudentFromBulkVisit, { lea: this.currentStudent.schoolLEA, visitId: dataItem.item.visitId, student: this.currentStudent })
        }
        else
        {
          this.$store.dispatch(Types.actions.removeStudentVisit, { lea: this.currentStudent.schoolLEA, visitId: dataItem.item.visitId })
        }
        //this.currentStudent.removeVisit(dataItem.item.id)
      }     
    },
    editVisit(dataItem) {
      var item = this.currentStudent.visits.filter(v=> v.visit.visitId === dataItem.visitId)

      if (item) {
        var visitData = JSON.parse(item[0].visit.visitData)

        this.currentVisitId = item[0].visit.visitId
        this.visitAy = item[0].visit.ay
        this.initialVisit = item[0].studentVisit.initialVisit
        this.gradeLevel = item[0].visit.grade
        this.visitDate = item[0].visit.visitDate
        this.visitType = visitData.type
        this.visitLocation = visitData.location
        this.topics = visitData.topics
        this.notes = visitData.notes
        this.mode = 'edit'
      }
    }
  },
  props:{
    ay: {
      type: Number,
      required: true,
      default: 0
    } 
  },
  watch: {
  }
}
</script>

<style scoped>
</style>
<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script> 
  import { ChartMixin } from '../../ChartMixin'
  import { LicenseTypes } from './LicenseTypes'
  import { credits } from '../../../helpers/credits'
  export default {
    mixins: [ChartMixin],
    data() {
      return {
        animationDuration: 1000,
        chartOptions: {
          plotOptions: {
            column: {
              stacking: 'normal'
            }
          },
          series: [],
          credits: credits.AELS,
          title: {
            text: 'Number of New Licenses Issued by Year'
          },
          xAxis: {
            type: 'category',
            title: {
              text: 'School Year'
            }
          },
          yAxis: [
            {
              stackLabels: {
                enabled: true
              },
              title: {
                text: 'Number of Licenses Issued'
              }
            }
          ]
        }
      }
    },
    computed: {
      reportData() {
        return this.$store.state.hcptModule.licenseIssuedStatistics.firstTimeLicenses
      }
    },
    watch: {
      reportData(newData) {
         LicenseTypes.forEach((type, i) => {
          let data = newData
            .filter(row => row.subLicenseType == type.name)
            .map(row => {
              row.y = row.issued || 0
              row.x = row.year < 1990 ? 1990 : row.year
              if (row.x == 1990) row.name = '<= 1990'
              return row
            })

          if (!this.chartOptions.series[i]) {
            this.chartOptions.series.push({})
          }
          this.chartOptions.series[i].type = 'column'
          this.chartOptions.series[i].name = type.name
          this.chartOptions.series[i].data = data
          this.chartOptions.series[i].connectNulls = true
        })
      }
    },
    methods: {
      dataItems() {
        return LicenseTypes.flatMap((_type, i) => {
          return this.chartOptions.series[i].data.map(d => {
            return {
              Type: this.chartOptions.series[i].name,
              SchoolYear: d.x,
              ExceptionsCount: d.y
            }
          })
        })
      }
    }
  }
  
</script>
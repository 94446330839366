<template>
  <div v-if="activeAcademicYear">
    <div>
      <h2>
        Work Queue
      </h2>
      <work-queue-table :load-data="loadData" />
      <h2>
        Unread Comments
      </h2>
      <div class="row">
        <div class="col-6">
          <unread-comment-table :load-data="loadData" />
        </div>
        <div class="col-6">
          <div class="card" v-if="selectedApplication && selectedComments.length > 0">
            <div class="card-header">
              {{ commentPanelDisplayName }}
              <i class="fa fa-fw fa-times float-right cursor-pointer" title="Close comments" @click="clearState" />
            </div>
            <div class="card-body">
              <comment-question-widget :identifiers="identifiers" :comments="selectedComments" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="text-center">
    <b-alert show variant="warning">
      No Academic Years are currently open for submissions
    </b-alert>
  </div>
</template>

<script>
import WorkQueueTable from './WorkQueueTable.vue'
import UnreadCommentTable from './UnreadCommentTable.vue'
import CommentQuestionWidget from './application/proposal/workflow/CommentQuestionWidget.vue'
import { mapGetters, mapState } from 'vuex'
import { Types } from '../../stores/perkinsv'

export default {
  data() {
    return {
      loadData: Promise.resolve()
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.selectedProposal, Types.state.selectedApplication, Types.state.selectedComments]),
    ...mapGetters(Types.path, [Types.getters.activeAcademicYear]),
    commentPanelDisplayName() {
      if (this.selectedProposal.proposalId) 
        return `Proposal #${this.selectedProposal.proposalId} Comments`
      return `Application #${this.selectedApplication.applicationId} Coverpage Comments`
    },
    identifiers() {
      if (this.selectedComments.length > 0) {
        return JSON.parse(this.selectedComments[0].routeParams)
      }
      return {}
    }
  },
  methods: {
    clearState() {
      this.$store.commit(Types.mutations.setSelectedComments, [])
      this.$store.commit(Types.mutations.setSelectedProposal, {})
      this.$store.commit(Types.mutations.setSelectedApplication, {})
    }
  },
  beforeRouteEnter:(to, from, next) => {
    next(vm => {
      vm.clearState()
      var loadData = vm.$store.getters[Types.getters.activeAcademicYear] > 0 ? Promise.resolve() : vm.$store.dispatch(Types.actions.getAcademicYears)
      vm.loadData = loadData.then(() => vm.$store.dispatch(Types.actions.getInboxItems))
    })
  },
  components: {
    WorkQueueTable,
    UnreadCommentTable,
    CommentQuestionWidget,
  }
}

</script>

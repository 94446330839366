<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
  import { Subpopulations } from '../../subpopulations'
  import { ChartMixin } from '../../ChartMixin'
  import { credits } from '../../../helpers/credits'

  export default {
    data() {
      return {
        animationDuration: 1000,
        chartOptions: {
          chart: {
            type: 'pie'
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.y} ({point.percentage:.1f} %)'
              }
            }
          },
          credits: credits.SIS,
          title: {
            text: this.getTitle()
          },
          xAxis: {
            title: {
              text: 'Sub-population',
            }
          },
          yAxis: {
            min: 0,
            title: {
              text: 'Number of Teachers'
            }
          },
          series: [{
            data: []
          }]
        }
      }
    },
    mixins: [ChartMixin],
    props:{
      fiscalYear: Number
    },
    computed: {
      reportData() {
        return this.$store.state.hcptModule.demographicsHistory
      },
    },
    watch: {
      reportData(newData) {
        this.chartOptions.series = [{
          data: []
        }]
        const fyData = newData.find(d => d.fiscalYear == this.fiscalYear)
        if (fyData) {
          this.chartOptions.series[0].data = [...Subpopulations.map(subpopulation => {
            return {
              name: subpopulation.name,
              y: fyData[subpopulation.field] || 0,
              color: subpopulation.color,
            }
            })
          ]
        }
      },
    },
    methods: {
      getTitle(){
        return `Number of Teachers by Subpopulation - ${this.fiscalYear + 1990}`
      },
      dataItems() {
        return this.chartOptions.series[0].data.map(item => {
          return { Name: item.name, Count: item.y }
        })
      }
    }
  }
</script>
<template>
  <div>
    <div class="mb-2 mt-0 border-bottom border-dark pb-2" style="font-size: 1.3em;">
      <ul class="nav nav-pills flex-column mr-2">
        <li class="nav-item">
          <router-link
            active-class="active"
            class="nav-link font-weight-bold"
            :to="{path: '/facilities/work-queue'}">
            <i class="px-1 text-secondary fas fa-inbox" /> Inbox <b-badge> {{ inboxItems }}</b-badge>
          </router-link>
        </li>
      </ul>
    </div>

    <ul v-if="userContext.hasRole('ADE User')" class="nav nav-pills flex-column ml-2 mr-2">
      <li class="nav-item ml-2">
        <router-link
          class="nav-link"
          active-class="active"
          :to="{path: '/facilities/batches/all'}">
          <i class="px-1 text-secondary fas fa-search" /> All Inspections
        </router-link>
      </li>
      <li class="nav-item ml-2">
        <router-link
          class="nav-link"
          active-class="active"
          :to="{path: '/facilities/pending-district-action'}">
          <i class="px-1 text-secondary fas fa-exclamation-triangle fa-fw" /> Pending District Action
        </router-link>
      </li>
      <li class="nav-item ml-2">
        <a class="nav-link" href="/facilities/inspect"><i class="px-1 text-secondary fas fa-mobile-alt" /> iPad View</a>
      </li>
      <li class="nav-item ml-2">
        <router-link
          class="nav-link"
          active-class="active"
          :to="{path: '/facilities/buildings'}">
          <i class="px-1 text-secondary fas fa-building" /> Building Inventory 
        </router-link>
      </li>
    </ul>
    <ul v-else class="nav nav-pills flex-column ml-2 mr-2">
      <li class="nav-item ml-2 d-none">
        <router-link
          class="nav-link"
          active-class="active"
          :to="{path: `/facilities/${$route.query.lea}/dashboard`}">
          District Dashboard
        </router-link>
      </li>
      <li class="nav-item ml-2">
        <router-link
          class="nav-link"
          active-class="active"
          :to="{path: `/facilities/${$route.query.lea}/buildings`}">
          <i class="px-1 text-secondary fas fa-building" /> Building Inventory 
        </router-link>
      </li>
      <li class="nav-item ml-2">
        <router-link
          class="nav-link"
          active-class="active"
          :to="{path: `/facilities/${$route.query.lea}/batches/all`}">
          <i class="px-1 text-secondary fa fa-check-circle" aria-hidden="true" /> Completed Inspections
        </router-link>
      </li>
    </ul>
    <ul class="nav nav-pills flex-column ml-2 mr-2">
      <li class="nav-item ml-2" v-if="userContext.hasRole('ADE User')">
        <a href="/content/dpsaft/documentation/Facilities_Module_DPSAFT_User_Guide.pdf" target="_blank" class="nav-link">  <i class="px-1 text-secondary fa fa-question-circle" aria-hidden="true" />Facilities User Manual</a>
      </li>
      <li class="nav-item ml-2">
        <a href="/content/dpsaft/documentation/Facilities_Module_District_User_Guide.pdf" target="_blank" class="nav-link">  <i class="px-1 text-secondary fa fa-question-circle" aria-hidden="true" />District User Manual</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Types } from '../../../stores/dpsaft/facilities'

export default {
  name: 'sidebar',
  data() {
    return {
      refreshIntervalTimer: null
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState(Types.path, [Types.state.ModuleWorkQueue]),
    inboxItems() {
      return this.ModuleWorkQueue.filter(q=>q.routeClosedDate == null).length
    }
  },
  mounted() {
    console.log('mount sidebar',Types.actions.getModuleWorkQueue)

    this.$store.dispatch(Types.actions.getModuleWorkQueue)

    this.refreshIntervalTimer = setInterval(() => {
      this.$store.dispatch(Types.actions.getModuleWorkQueue)
    }, 10000)
  },
  beforeUnmount() {
    console.log('Clearing the refresh interval')
    clearInterval(this.refreshIntervalTimer);
  }
}
</script>
<style  scoped>
  ::v-deep .nav-link.active .text-secondary {
    color: #ffffff !important
  }
</style>

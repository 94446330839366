<template>
  <div class="mb-1">
    <div v-if="showLabel">
      Status: <span v-bind="$attrs" class="ml-2 lead">
        <b-badge :variant="getVariant(currentStatus)">
          {{ getStatusText(currentStatus) }}
        </b-badge>
      </span>
    </div>
    <span v-bind="$attrs" v-else>
      <b-badge :variant="getVariant(currentStatus)">
        {{ getStatusText(currentStatus) }}
      </b-badge>
    </span>
  </div>
</template>

<script>
export default {
  computed: {
    currentStatus() {
      return this.stateMap.get(this.status)
    }
  },
  methods: {
    getStatusText(state) {
      if (state && typeof state.status === 'function') {
        return state.status(this.document)
      }
      return state.status
    },
    getVariant(state) {
      if (state && typeof state.variant === 'function') {
        return state.variant(this.document)
      }
      return state.variant
    },
  },
  props: {
    document: {
      type: Object,
      default: () => { return {} }
    },
    status: {
      type: Number,
      required: true
    },
    stateMap: {
      type: Map,
      required: true
    },
    showLabel: {
      type: Boolean,
      default: true
    }
  }
}
</script>
    
<template>
  <div class="d-print-none mt-3">
    <b-form>
      <b-form-row>
        <div class="offset-md-2 col-md-8 justify-content-center text-center">
          <div class="input-group">
            <input type="text" class="form-control" v-model="leaContains" placeholder="Start typing to search by District LEA Name / Number...">
            <div class="input-group-append">
              <button class="btn btn-secondary" type="button" @click="leaContains = null">
                Clear
              </button>
            </div>
          </div>
        </div>
      </b-form-row>
      <b-form-row>
        <div class="col-12 justify-content-center text-center my-1">
          <b-form-radio class="d-inline mr-2" v-model="leaStartsWithType" name="type" value="name">
            Name
          </b-form-radio>
          <b-form-radio class="d-inline" v-model="leaStartsWithType" name="type" value="number">
            LEA Number
          </b-form-radio>
        </div>
      </b-form-row>
      <b-form-row v-if="leaStartsWithType == 'name'">
        <div class="col-12 justify-content-center text-center mb-3">
          <b-button variant="info" class="mr-1" v-for="character in alphabet" :key="character" @click="leaStartsWith = character">
            {{ character }}
          </b-button>
        </div>
      </b-form-row>
      <b-form-row v-if="leaStartsWithType == 'number'">
        <div class="col-12 justify-content-center text-center mb-3">
          <b-button variant="info" class="mr-1" v-for="character in numbers" :key="character" @click="leaStartsWith = character">
            {{ character }}
          </b-button>
        </div>
      </b-form-row>
      <b-form-row v-if="context != Context.District">
        <b-col>
          <b-form-group label="School Year">
            <b-select :options="fiscalYears" v-model="fiscalYear" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Status">
            <b-select :options="statusOptions" v-model="status" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <b-button :variant="filterChanged ? 'primary' : 'secondary'" @click="search" :disabled="!filterChanged" style="margin-top: 30px;">
              <i class="fa fa-search fa-fw" /> Search
            </b-button>
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-form>
  </div>
</template>

<script>
import { StateMap, Context } from '@/stores/digitalLearning'
import { mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {
      filterChanged: false,
      // coop: null,
      district: null,
      status: null,
      fiscalYear: 32,
      leaStartsWith: null,
      leaContains: null,
      leaStartsWithType: 'name',
      Context
    }
  },
  computed: {
    ...mapGetters('globalModule', ['coops', 'districts']),
    ...mapState('globalModule', ['userContext']),
    alphabet: () => ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    numbers: () => ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
    statusOptions() {
      // eslint-disable-next-line no-unused-vars
      const statuses = Array.from(StateMap, ([_name, value]) => ({ text: typeof value.status == 'function' ? value.status() : value.status, value: value.code }))
      if (this.context == Context.Public)
        return statuses.filter(s => s.value >= 11)

      return statuses.filter(s => s.value != 2).concat([{ text: 'Re-opened', value: 'Re-opened' }, { text: 'Expired', value: 'Expired' }])
    },
    fiscalYears() {
      return [{ text: 'All', value: null }, { text: '2022-2023', value: 32 }, { text: '2021-2022', value: 31 }]
    }
  },
  methods: {
    search() {
      this.filterChanged = false
      this.$emit('input', { lea: this.district || this.coop || 'AR', status: this.status, fiscalYear: this.fiscalYear })
    },
  },
  watch: {
    coop() { 
      this.district = null
      this.filterChanged = true 
    },
    district() { this.filterChanged = true },
    status() { this.filterChanged = true },
    leaStartsWith() { 
      this.filterChanged = true
      this.$emit('input', { lea: this.district || this.coop || 'AR', status: this.status, leaStartsWith: this.leaStartsWith, fiscalYear: this.fiscalYear })
    },
    leaContains() {
      this.filterChanged = true
      this.$emit('input', { lea: this.district || this.coop || 'AR', status: this.status, leaContains: this.leaContains, fiscalYear: this.fiscalYear })
    },
    fiscalYear() {
      this.filterChanged = true
    }
  },
  props: {
    value: Object,
    initialDistrict: String,
    context: {
      type: Number,
      required: true
    }
  },
  mounted() {
    this.district = this.initialDistrict
  } 
}
</script>
import Vue from "vue";

const IndividualExportOptionsWidget = Vue.extend({
    name: "individual-export-options-widget",
    data() {
        return {
        }
    },
    /* html */
    template: `

    <div class='card mb-2'>
        <div class='card-header'>
            Individual Educator Details
        </div>
        <div class='card-body'>
            <div class='row'>
                <div class='col-md-6 pr-0'>
                        <b-form-checkbox v-model="value.includeJobIndividuals" inline>Export Individuals by Job</b-form-checkbox>
                        <b-form-checkbox v-model="value.includeAreaIndividuals" inline>Export Individuals by Area</b-form-checkbox>
                </div>
                <div class='col-md-6 pr-0'>
                        <b-form-checkbox v-model="value.groupBySubgroup" :disabled="groupingDisabled" inline>Group by Subgroup</b-form-checkbox>
                        <b-form-checkbox v-model="value.groupByLicenseOrJobCode" :disabled="groupingDisabled" inline>Group by License/Course Code</b-form-checkbox>
                </div>
            </div>
        </div>
    </div>

     `,
    props: {
        title: String,
        value: Object
    },
    computed: {
        groupingDisabled() {
            return !(this.value.includeJobIndividuals || this.value.includeAreaIndividuals)
        },
    },
    components: {},
    watch: {}
});

export {
    IndividualExportOptionsWidget
};
<template>
  <form>
    <reconfiguration-code-selector :item="item" :purpose="ReconfigurationCodePurpose.NewDistrict" :operation="operation" />
    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">LEA Name:</label>
      <div class="col-md-9">
        <span class="form-control-plaintext">{{ item.leaName }}</span>
      </div>
    </div>
    <div class="form-group row mb-0">
      <div class="col-md-3 mb-3 mt-1">
        LEA Type Code:
      </div>
      <b-form-select
        required
        class="col-md-9 mb-2"
        :options="districtTypes"
        v-model="item.leaTypeCode"
        :disabled="!(userContext.hasRole('Insights_RT-SMS_Administrators') && !currentRequest.isApprovedRejectedOrCanceled)"
      />
    </div>

    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">Provision 2:</label>
      <div class="col-md-9">
        <div class="form-control-plaintext">
          {{ item.provision2 ? 'Yes' : 'No' }}
        </div>
      </div>
    </div>
    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">Address:</label>
      <div class="col-md-9">
        <div class="form-control-plaintext">
          {{ item.address }}
        </div>
      </div>
    </div>
    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">City:</label>
      <div class="col-md-9">
        <div class="form-control-plaintext">
          {{ item.city }}
        </div>
      </div>
    </div>
    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">State:</label>
      <div class="col-md-9">
        <div class="form-control-plaintext">
          {{ item.state }}
        </div>
      </div>
    </div>
    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">Zip:</label>
      <div class="col-md-9">
        <div class="form-control-plaintext">
          {{ item.zip }}
        </div>
      </div>
    </div>
    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">Additional Comments:</label>
      <div class="col-md-9">
        <div class="form-control-plaintext">
          {{ item.additionalComments }}
        </div>
      </div>
    </div>
    <div class="form-group row mb-0 mb-0">
      <label class="col-md-3 col-form-label">LEA # Assigned:</label>
      <div class="col-md-4">
        <slot name="lea">
          <span class="form-control-plaintext">{{ lea }}</span>
        </slot>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex'
import { RequestItemMixin } from '../RequestItemMixIn'

export default {
  name: 'new-district-item',
  data() {
    return {}
  },
  components: {},
  mounted() {},
  computed: {
    ...mapState('toolsModule/leaAssignment', ['schools']),
    ...mapState('toolsModule/leams', ['leaTypes']),
    ...mapState('globalModule', ['userContext']),
    districtTypes() {
      return this.leaTypes.filter(t=>t.code[0]=='D').map(t=> ({value: t.code, text: t.description}))
    },

    lea() {
      return (this.currentRequest.requestLeas.find(l => l.operationParams == this.item.key) || {}).lea || this.assignedLea
    }
  },
  props: {
    item: Object
  },
  mixins: [RequestItemMixin]
}
</script>

<template lang="html">
  <div class="growth-container">
    <a href="/Content/growth/Value-Added Growth Modules Guidance.pdf" class="btn btn-primary mb-1" target="_new">
      Download PDF
    </a>
    <div class="card outer-card" style="height: 620px;overflow-y: scroll;">
      <div class="card-body">
        <pdf
          v-for="i in numPages"
          :key="i"
          :src="src"
          :page="i"
          style="display: block; width: 70%;margin:auto;margin-top:20px;"
          @loaded="loading = false"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { PageMixin } from '@/components/PageMixin'
import pdf from 'vue-pdf'

export default {
  data() {
    return {
      numPages: undefined,
      loading: false
    }
  },
  computed: {
    fiscalYear() {
      return this.$store.state.academicsModule.growth.fiscalYear
    },
    src() {
      return pdf.createLoadingTask('/Content/growth/Value-Added Growth Modules Guidance.pdf')
    }
  },
  mounted() {
    this.src.then(pdf => {
      this.numPages = pdf.numPages
    })
  },
  components: { pdf },
  mixins: [PageMixin],
  methods: {
    evtError: function(err) {
      this.error = err
    },
  },
}
</script>
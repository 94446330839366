<template>
  <div v-show="render">
    <div>
      <h4 class="text-dark">         
        Letter Grade<i v-b-toggle.collapse-info class="ml-2 fas fa-info-circle fa-sm fa-fw text-primary info-icon d-print-none" title="Show More Information" />
      </h4>

      <b-collapse id="collapse-info" class="mb-3">
        <b-card-header class="mb-0 d-print-none">
          <b-card-title v-b-toggle.collapse-info class="mb-0">
            <b-icon-x />Information
          </b-card-title>
        </b-card-header>
        <b-card class="mb-2">
          <b-card-text>
            <h5 class="font-italic">
              Teacher Effectiveness Ratings and School Letter Grades Analysis
            </h5>
            <p>The first analysis requested is the proportion of teachers in schools based on the schools' letter grades. We ran a frequency procedure to count the total number of teachers per subject across all grades. Tables 3-5 (Count and Percentage of ELA Teachers by Letter Grade and Effectiveness Rating) indicate the number and percentage of teachers with value-added growth scores at each effectiveness level for schools with school ratings of A-F. The percentages in Tables 3-5 are row percentages and indicate the percentage of teachers for each effectiveness rating that are in schools for each letter grade.</p>
            <p>Schools with a letter grade of D or F have teachers whose mean value-added growth scores result in a classification of High Impact. Schools with letter grades of A, B, and C have teachers whose mean value-added growth scores result in a classification of Unsastisfactory and Below Effective. The differences lie in the proportions—schools with better letter grades have higher proportions of teachers of teachers classified as Effective and High Impact and vice versa for schools with letter grades of D or F. </p>
            <p>Note: There are schools that do not receive a letter grade pursuant to <a target="_blank" href="https://dese.ade.arkansas.gov/Files/20201102115047_ade_334_--_Rules_Governing_the_School_Rating_System.pdf">ADE rules</a> and Ark. Code Ann. §§ 6-15-2105 and 6-15-2106. The teachers for whom the school is missing a school letter grade belong to schools that are exempt from a school rating. </p>
            <p>To analyze the potential association of a school letter grade with teacher value-added growth scores we initially conducted a factorial analysis of variance with school letter grade and National Board Certification (NBC) status of a teacher as the two factors and the interaction of school letter grade and NBC status. The interaction of school letter grade and NBC status was non-significant. Thus, we conducted a one-factor analysis of variance to test the main effect of school letter grade and NBC status separately. </p>
            <h5 class="font-italic">
              Results
            </h5>
            <p>The results indicated significant differences in mean teacher value-added growth scores among teachers in schools with different letter grades (<a href="#table-6">Table 6: Mean Teacher Value-Added Growth Scores by Letter Grade</a> and Figures 2-4) for all subjects and grades EXCEPT Grade 10 math and Grades 9 and 10 science. The effects were largest at elementary grades compared to secondary grades except for Grades 7 and 8 math which bucked the trend.</p>
            <p>Based on R2 measures of association, school letter grades only account for 1% to 7% of the variation in teacher value-added growth scores. This suggests that school factors may explain some of the variation in teacher value-added scores across school letter grades. These analyses are not intended to be construed as causal in nature. The analytic design does not support causal inferences.</p>
            <p>If desired, we suggest a follow up analysis employing multi-level analysis of variance using a nested design of students nested within teachers/classrooms and classrooms nested within schools. This type of analysis will disentangle student, teacher, and school contributions to the variation in student value-added growth scores. The student-level growth model used in the ESSA Index does not control for school-level factors. This is intentional in that the growth scores for students are associated with school effects revealed by school-level value-added growth scores used in accountability.</p>
            <p>NOTE: For teacher-level value-added growth, school level controls may be more appropriate to mitigate the impact of school-level factors on teacher-level value-added score.</p>
          </b-card-text>
        </b-card>
      </b-collapse>
    </div>

    <div v-if="teacherVAMData">
      <div>
        <count-and-percentage-teachers-by-letter-grade-and-rating :render="render" :table="2 + 3" />
      </div>
      
      <mean-teacher-value-added-growth-scores-by-letter-grade :render="render" />

      <div>
        <div class="figure-caption">
          Figure 3: Distribution of Teacher Value-Added Growth Scores by School Letter Grade
        </div>
        <distribution-teacher-value-added-growth-scores-by-letter-grade class="figure" :render="render" :figure="1 + 2" />
      </div>
      <div class="figure">
        <div class="figure-caption">
          Figure 4: Distribution of Teacher Value-Added Scores by Subject for Schools Exempt from Letter Grades
        </div>
        <div>
          <distribution-teacher-value-added-growth-scores-by-subject-schools-exempt-from-letter-grades :render="render" />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
        <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
      </div>
    </div>
  </div>
</template>

<script>
import CountAndPercentageTeachersByLetterGradeAndRating from '../tables/CountAndPercentageTeachersByLetterGradeAndRating.vue'
import MeanTeacherValueAddedGrowthScoresByLetterGrade from '../tables/MeanTeacherValueAddedGrowthScoresByLetterGrade.vue'
import DistributionTeacherValueAddedGrowthScoresByLetterGrade from '../charts/DistributionTeacherValueAddedGrowthScoresByLetterGrade.vue'
import DistributionTeacherValueAddedGrowthScoresBySubjectSchoolsExemptFromLetterGrades from '../charts/DistributionTeacherValueAddedGrowthScoresBySubjectSchoolsExemptFromLetterGrades.vue'
import { mapState } from 'vuex'
import CardStateIcon from '../../../CardStateIcon.vue'
import { subjectCase } from '../helpers.js'

export default {
  data() {
    return {
      subjectCase
    }
  },
  components: {
    CountAndPercentageTeachersByLetterGradeAndRating,
    MeanTeacherValueAddedGrowthScoresByLetterGrade,
    DistributionTeacherValueAddedGrowthScoresByLetterGrade,
    DistributionTeacherValueAddedGrowthScoresBySubjectSchoolsExemptFromLetterGrades,
    CardStateIcon
  },
  props: {
    render: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('teacherVAMModule', ['teacherVAMData']),
  }
}
</script>
<template>
  <div style="height: 100%;">
    <div class="sidebar-scroll">
      <ul class="nav nav-pills flex-column ml-2 mr-2">
        <li class="nav-item">
          <a class="nav-link" href="#district">
            <object type="image/svg+xml" data="/Content/icons/LI-Districts.svg" class="svg-icon" /> District
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#student">
            <object type="image/svg+xml" data="/Content/icons/LI-Enrollment.svg" class="svg-icon" /> Student
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#staff">
            <object type="image/svg+xml" data="/Content/icons/LI-PublicEducators.svg" class="svg-icon" /> Staff
          </a>
        </li>
        <li v-if="selectedLea.type == LeaTypes.District" class="nav-item">
          <a class="nav-link" href="#oe-questions">
            <i class="fa fa-fw fa-question text-secondary" /> Open-Ended Questions
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#devices">
            <i class="fas fa-fw fa-desktop text-secondary" /> Devices
          </a>
        </li>
        <li class="nav-item mt-3">
          <a class="nav-link">
            <b>Specific Considerations: Technology</b>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#tech_district">
            District Considerations <b-button variant="light" pill size="sm" href="/Content/blendedlearning/tech_district.pdf" target="_new"><i class="fa fa-download" title="Download File" /></b-button>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#tech_school">
            School Considerations <b-button variant="light" pill size="sm" href="/Content/blendedlearning/tech_school.pdf" target="_new"><i class="fa fa-download" title="Download File" /></b-button>
          </a>
        </li>
        <li class="nav-item mt-3">
          <a class="nav-link">
            <b>Survey Information: Data Source &amp; Guidance</b>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#arkansas-ready-for-learning">
            Arkansas Ready for Learning <b-button variant="light" pill size="sm" href="http://dese.ade.arkansas.gov/public/userfiles/Communications/Ready/Response_Levels_for_On-Site_Learning_FINAL_Updated_8_20_20.pdf" target="_new"><i class="fa fa-download" title="Download File" /></b-button>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#rt-20-013">
            RT-20-013 <b-button variant="light" pill size="sm" href="/Content/blendedlearning/ADECM-RT-20-013.pdf" target="_new"><i class="fa fa-download" title="Download File" /></b-button>
          </a>
        </li>
        <li class="nav-item mt-3">
          <a class="nav-link" href="#hotspot">
            <i class="fas fa-wifi" /> DESE Arkansas Hotspot Project
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { LeaTypes } from '../../helpers/leaTypes'

export default {
  name: 'blended-learning-readiness-sidebar',
  computed: {
    selectedLea() { return this.$store.state.globalModule.leaContext.SelectedLea },
    ...mapState('globalModule', ['userContext'])
  },
  data() {
    return {
      LeaTypes
    }
  }
}
</script>
<style  scoped>
::v-deep .nav-link.active .text-secondary {
  color: #ffffff !important;
}
</style>
<template>
  <div class="d-print-none" v-if="selectedApplication">
    <h2 class="text-left pb-1">
      Comments / Questions
    </h2>
    <ul class="list-unstyled text-left">
      <li class="mt-2 media border-bottom" v-for="comment in commentsList" :key="comment.commentId">
        <div class="media-body p-1" :class="{ 'bg-light': comment.isUnread }">
          <span class="mr-1 font-weight-bold">
            {{ comment.createdByDisplayName }}
          </span>
          <span v-if="getAssignedGroups(comment.assignedToGroups).length > 0" class="seventy-five mr-1">
            {{ comment.commentType == 0 ? 'Posted' : 'Responded' }} to: 
          </span>
          <b-badge :title="comment.commentVisibilityLevel == 1 ? 'Visible to ADE Only' : 'Visible to everyone'" variant="info" v-for="group in getAssignedGroups(comment.assignedToGroups)" :key="group">
            {{ rolesDisplay[group] }}
            <i v-if="comment.commentVisibilityLevel == 1" class="ml-1 fas fa-lock" />
          </b-badge>
          <i class="text-primary cursor-pointer float-right mx-1 fas fa-envelope-open" title="Mark as read" v-if="comment.isUnread" @click="markAsRead(comment.routeParams)" />
          <small class="float-right">{{ comment.createdDate | date }}</small>
          <br style="clear: both;" class="mb-1">
          <div class="row">
            <div class="col-10 mb-0">
              {{ comment.content }}
            </div>
            <div class="col-2 mb-1">
              <b-button class="float-right" size="sm" variant="info" v-if="getAssignedGroups(comment.assignedToGroups).includes(myGroup) && !comment.isResponding" @click="comment.isResponding = true">
                <i class="fas fa-reply" /> Respond
              </b-button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-1">
              <div v-if="comment.isResponding">
                <b-form-group class="mb-1">                    
                  <b-form-textarea v-model="content" placeholder="Please provide a response..." rows="2" />
                </b-form-group>                    
                <b-button variant="danger" size="sm" type="button" class="float-right mx-1" @click="comment.isResponding = false; content = null">
                  Cancel
                </b-button>
                <button type="button" class="btn btn-sm btn-info float-right mx-1" @click="postResponse(comment.fromGroup, comment.commentId)" :disabled="!content">
                  <i class="fas fa-paper-plane" /> Send Response
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div v-if="commentsList.length == 0">
      There are no comments at this time
    </div>
    <div class="d-flex justify-content-center" v-if="hasMoreComments" @click="commentsList = comments">
      <b-button variant="outline-primary" size="sm" class="my-2">
        Load All <i class="ml-1 fas fa-ellipsis-h" />
      </b-button>
    </div>
    <div v-if="!isResponding && myGroup">
      <div>
        <b-form-textarea v-model="content" placeholder="Please provide a comment or question..." rows="2" />
      </div>
      <div class="text-right mt-1">
        <b-button-group variant="info" size="sm">
          <b-button v-if="isApplicant && availableGroupTargets.length == 1" right variant="info" size="sm" :disabled="!content" @click="postComment(availableGroupTargets[0])">
            <i class="fas fa-paper-plane mr-1" /> Post to {{ PerkinsVRolesDisplay[availableGroupTargets[0]] }}
          </b-button>
          <b-dropdown right variant="info" size="sm" :disabled="!content" v-else>
            <template #button-content>
              <i class="fas fa-paper-plane" /> 
            </template>
            <b-dropdown-item class="pl-0" @click="postComment('APSCN User')">
              <i class="fa-fw fas fa-user pl-0" /> Post to CTE Coordinator
            </b-dropdown-item>
            <b-dropdown-divider v-if="!isApplicant" />
            <b-dropdown-item class="pl-0" @click="postComment(group)" v-for="group in availableGroupTargets" :key="group">
              <i class="fa-fw fas fa-user-lock" /> Post to {{ PerkinsVRolesDisplay[group] }}
            </b-dropdown-item>
          </b-dropdown>
        </b-button-group>
      </div>
    </div>
  </div>
</template>

<script>
// import AnimatedButton from '@/components/AnimatedButton'
import { Types } from '@/modules/cte/stores/perkinsv'
import { PerkinsVRoles, PerkinsVADERoles, PerkinsVRolesDisplay } from '@/modules/cte/components/common/Roles'
import { mapState } from 'vuex'

export default {
  components: {
    // AnimatedButton
  },
  data() {
    return {
      content: null,
      commentsList: [],
      PerkinsVRolesDisplay,
    }
  },
  props: {
    identifiers: {
      type: Object,
      required: true,
    },
    comments: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState(Types.path, [Types.state.selectedProposal, Types.state.selectedApplication]),
    rolesDisplay() {
      return { ...PerkinsVRolesDisplay, ['APSCN User']: 'CTE Coordinator' }
    },
    isApplicant() {
      const applicantId = (this.selectedApplication || {}).applicantId
      return (this.userContext.CoordinatorRoles || []).some(c => c.SchoolLEA == applicantId)
    },
    hasMoreComments() {
      return this.comments.length > 3 && this.comments.length > this.commentsList.length 
    },
    isSingleProgram() {
      return Boolean((this.selectedProposal || {}).occupationalAreaId)
    },
    isResponding() {
      return this.commentsList.some(c => c.isResponding)
    },
    myGroup() {
      if (this.hasRole(PerkinsVRoles['Perkins Administrator'])) 
        return PerkinsVRoles['Perkins Administrator']
      else if (this.hasRole(PerkinsVRoles['Program Advisor']))
        return PerkinsVRoles['Program Advisor']
      else if (this.hasRole(PerkinsVRoles['Program Coordinator']))
        return PerkinsVRoles['Program Coordinator']
      else if (this.hasRole('APSCN User'))
        return 'APSCN User'
        
      return null
    },
    availableGroupTargets() {
      if (this.isSingleProgram) {
        if (this.isApplicant) {
          return [PerkinsVRoles['Program Coordinator']]
        }
        return PerkinsVADERoles
      }
      return [PerkinsVRoles['Perkins Administrator']]
    }
  },
  watch: {
    comments: {
      immediate: true,
      handler(comments) {
        this.commentsList = comments.slice(0, 3)
      }
    }
  },
  methods: {
    getAssignedGroups(response) {
      return JSON.parse(response || '[]')
    },
    hasRole(role) {
      return (this.userContext.roles || []).includes(role)
    },
    postComment(assignedToGroup) {
      let postedComment = { assignedToGroups: JSON.stringify([assignedToGroup])}
      return this.commentDispatch(postedComment)
    },
    postResponse(assignedToGroup, referencedCommentId) {
      let postedComment = { commentType: 2, referencedCommentId: referencedCommentId, assignedToGroups: JSON.stringify([assignedToGroup]) }
      return this.commentDispatch(postedComment)
    },
    markAsRead(routeParams) {
      return this.$store.dispatch(Types.actions.markCommentsAsRead, JSON.parse(routeParams))
    },
    commentDispatch(postedComment) {
      const self = this
      if (this.identifiers.proposalId && this.isSingleProgram) {
        postedComment.additionalData = JSON.stringify({ occupationalAreaId: this.selectedProposal.occupationalAreaId })
      }
      Object.assign(postedComment, { ...this.identifiers, routeParams: JSON.stringify(this.identifiers), content: this.content, fromGroup: this.myGroup })
      return this.$store.dispatch(Types.actions.addCommentorQuestion, postedComment)
        .then(() => {
          self.content = null
          this.$bvToast.toast('Saved', {
            title: `Comment posted`,
            variant: 'success',
            solid: true
          })
          this.$store.dispatch(Types.actions.getComments, this.identifiers)
        })
    },
  }
}
</script>

<style scoped>
  .seventy-five {
    font-size: 75%;
  }
</style>
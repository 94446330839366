<template lang="html">
  <div>
    <div v-if="loading">
      <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
        <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
      </div>
    </div>
    <div v-show="!loading" class="card outer-card" style="height: 620px;overflow-y: scroll;">
      <div class="card-body">
        <pdf
          v-for="i in 2"
          :key="i"
          :src="src"
          :page="i"
          style="display: block; width: 70%;margin:auto;margin-top:20px;"
          @loaded="loading = false"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { PageMixin } from '../../../PageMixin'
import pdf from 'vue-pdf'
import CardStateIcon from '@/components/CardStateIcon.vue'

export default {
  name: 'essa-pdf',
  data() {
    return {
      numPages: undefined,
      loading: false
    }
  },
  computed: {
    fiscalYear() {
      return this.$store.state.academicsModule.growth.fiscalYear
    },
    src() {
      if (this.fiscalYear)
        return `https://myschoolinfo.arkansas.gov/StandardReports/ESSASchoolIndexPage?format=PDF&response-format=pdf&fy=${this.fiscalYear}&lea=${this.selectedLea.number}`
      else return ''
    }
  },
  components: { pdf, CardStateIcon },
  mixins: [PageMixin],
  methods: {
    evtError: function(err) {
      this.error = err
    },
  },
  watch: {
    selectedLea: {
      immediate: false,
      handler() {
        this.loading = true
      }
    },
    fiscalYear: {
      immediate: true,
      handler() {
        this.loading = true
      }
    }
  }
}
</script>
<template>
  <div class="card">
    <div class="card-header">
      <ul class="nav nav-tabs card-header-tabs" id="filterAndDetailInfo" role="tablist">
        <li class="nav-item active">
          <a class="nav-link active" id="tab-filters" data-toggle="tab" href="#filters">Filter Information</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" id="tab-verification" data-toggle="tab" href="#verification">Roster Verification (RV)</a>
        </li> -->
        <li class="nav-item" v-if="selectedTeacher">
          <a class="nav-link" id="tab-teacher" data-toggle="tab" href="#teacher">Teacher Details</a>
        </li>                
        <li class="nav-item" v-if="selectedCourse">
          <a class="nav-link" id="tab-course" data-toggle="tab" href="#course">Course Details</a>
        </li>                
        <li class="nav-item" v-if="selectedStudent">
          <a class="nav-link" id="tab-student" data-toggle="tab" href="#student">Student Details</a>
        </li>                
      </ul>
    </div>
    <div class="card-body">                
      <div class="card-body tab-content inner-card">
        <div class="tab-pane fade show active" id="filters" role="tabpanel" aria-labelledby="tab-filters">
          <div v-if="!selectedTeacher && !selectedCourse && !selectedStudent" class="alert alert-info mt-4">
            Select Records Below to Filter
          </div>
                
          <div v-if="selectedTeacher" class="btn" :class="{&quot;btn-primary&quot;: allowClearTeacher, &quot;btn-secondary&quot;: !allowClearTeacher}" @click="clearTeacher">
            Teacher: {{ selectedTeacher.teacherName }} <span v-if="allowClearTeacher" class="fas fa-times" />
          </div>
          <div v-if="selectedCourse" class="btn btn-primary ml-1" @click="clearCourse">
            Course: {{ selectedCourse.courseTitle }} <span class="fas fa-times" />
          </div>
          <div v-if="selectedStudent" class="btn btn-primary ml-1" @click="clearStudent">
            Student: {{ selectedStudent.fullName }} <span class="fas fa-times" />
          </div>
          <b-form-checkbox v-model="hideInactive" inline switch @input="setHideInactive" class="ml-3">
            Hide Inactive Marking Periods
          </b-form-checkbox>
        </div>
        <!-- <div class="tab-pane fade" id="verification" role="tabpanel" aria-labelledby="tab-verification">
          <div class="row">
            <b-button>
              <i class="far fa-calendar-plus" /> Create Roster Snapshot
            </b-button>
          </div>
        </div> -->
        <div v-if="selectedTeacher" class="tab-pane fade" id="teacher" role="tabpanel" aria-labelledby="tab-teacher">
          <div class="row">
            <div class="col-md-2">
              <b>Teacher Name: </b>{{ selectedTeacher.teacherName }}
            </div>
            <div class="col-md-2">
              <b>Teacher Unique ID: </b>{{ selectedTeacher.uniq_emp_id }}
            </div>
            <div class="col-md-1">
              <b>Teacher Status: </b>{{ selectedTeacher.emp_status }}
            </div>                        
            <div class="col-md-3">
              <b>Teacher Degree: </b>{{ selectedTeacher.degree }}
            </div>
          </div>
        </div>
        <div v-if="selectedCourse" class="tab-pane fade" id="course" role="tabpanel" aria-labelledby="tab-course">
          <div class="row">
            <div class="col-md-2">
              <b>Title: </b>{{ selectedCourse.courseTitle }}
            </div>
            <div class="col-md-2">
              <b>Course Code: </b>{{ selectedCourse.job_code }}
            </div>
            <div class="col-md-2">
              <b>Section: </b>{{ selectedCourse.section_num }}
            </div>
            <div class="col-md-2">
              <b>Grade Range: </b>{{ selectedCourse.grade_low }} - {{ selectedCourse.grade_high }}
            </div>
            <div class="col-md-2">
              <b>Meeting Days: </b>{{ selectedCourse.meetsOnDays }}
            </div>
            <div class="col-md-2">
              <b>Enrollment: </b>{{ selectedCourse.tot_count }}
            </div>
            <div class="col-md-2">
              <b>Marking Period: </b>{{ selectedCourse.markingPeriod }}
            </div>
            <div class="col-md-2">
              <b>Alt Environment: </b>{{ selectedCourse.alt_envir }}
            </div>                        
            <div class="col-md-2">
              <b>Coding Block: </b>{{ selectedCourse.cb_flag }}
            </div>
            <div class="col-md-2">
              <b>Kingergarten Overage: </b>{{ selectedCourse.k_overage }}
            </div>
            <div class="col-md-2">
              <b>Regular Ed Self-Contained: </b>{{ selectedCourse.reg_ed_self_contained }}
            </div>
            <div class="col-md-2">
              <b>Special Ed Overage: </b>{{ selectedCourse.sped_overage }}
            </div>
            <div class="col-md-2">
              <b>AAA Coaching Certificate: </b>{{ selectedCourse.aaa_coach_cert }}
            </div>
          </div>
        </div>
        <div v-if="selectedStudent" class="tab-pane fade" id="student" role="tabpanel" aria-labelledby="tab-student">
          <div class="row">
            <div class="col-md-2">
              <b>Name: </b>{{ selectedStudent.fullName }}
            </div>
            <div class="col-md-2">
              <b>Student ID: </b>{{ selectedStudent.studentId }}
            </div>
            <div class="col-md-2">
              <b>Grade: </b>{{ selectedStudent.gradeLevel }}
            </div>
            <div class="col-md-2">
              <b>Gender: </b>{{ selectedStudent.gender }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'master-schedule-filter-panel',
    props: {
        loadData: Promise
    },
    data() {
        return {
            hideInactive: 0
        }
    },
    components: {
    },
    methods: {
        clearTeacher() {
            if(this.allowClearTeacher){
                this.$store.commit('scheduleModule/setSelectedTeacher', null)
                this.clearCourse()
            }
        },        
        clearCourse() {
            this.$store.commit('scheduleModule/setSelectedCourse', null)            
            if(this.selectedStudent){
                this.$store.dispatch('scheduleModule/getStudentCourseSections',{
                    lea: this.selectedLea.number,
                    student: this.selectedStudent
                })
            }
            else this.$store.commit('scheduleModule/setCourseEnrollment', [])
        },
        clearStudent() {
            this.$store.commit('scheduleModule/setSelectedStudent', null)
            if(!this.selectedCourse){
                this.$store.commit('scheduleModule/setCourseEnrollment', [])
            }
        },
        setHideInactive(){
            this.$store.commit('scheduleModule/setHideInactive', this.hideInactive)
        }

    },
    computed: {
        selectedLea() { return this.$store.state.globalModule.leaContext.SelectedLea },
        ...mapGetters('scheduleModule',['selectedTeacher','selectedStudent','selectedCourse', 'qsTeacherId']),
        allowClearTeacher(){
          return this.selectedLea.type == 1 && !this.qsTeacherId
        }
    },
    watch: {
    }
}
</script>
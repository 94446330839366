<template>
  <div>
    <b-alert v-if="hasDuplicateBusNumbers" variant="danger" show>
      The following Bus Number(s) are assigned to multiple buses in this inspection batch. This must be corrected before the inspection batch is finalized.
      <ul class="mb-0">
        <li v-for="dupe in SelectedBatch.duplicateBusNumbers" :key="dupe">
          {{ dupe }}
        </li>
      </ul>
    </b-alert>
    <b-card title="Notifications" v-if="!addingNotification && !hasDuplicateBusNumbers">
      <div class="mb-2">
        <b-row>
          <b-col>
            <strong>Last Incremental Inspection Notification:</strong> {{ lastIncrementalInspectionNotificationDate }}
          </b-col>
          <b-col>
            <strong>Final Inspection Notification:</strong> {{ lastFinalReportNotificationDate }}
          </b-col>
          <b-col class="text-right">
            <b-button variant="primary" @click="addingNotification=true" v-if="userContext.hasRole('ADE User')" :disabled="SelectedBatch.duplicateBusNumbers && SelectedBatch.duplicateBusNumbers.length > 0">
              <i class="fas fa-plus-circle fa-fw" /> New Notification
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table :fields="fields" :items="(SelectedBatchNotifications.createdBatchNotifications || [])">
        <template v-slot:cell(notificationType)="data">
          {{ displayNotificationtype(data.value) }}
        </template>
        <template v-slot:cell(sentDate)="data">
          <span v-if="data.value">
            {{ data.value | date }}
          </span>
          <span v-else>
            Pending
          </span>
        </template>
        <template v-slot:cell(districtAcknowledgedBy)="data">
          {{ data.value }}
        </template>
        <template v-slot:cell(districtAcknowledgedDate)="data">
          {{ data.value | date }}
        </template>
        <template v-slot:cell(reportLink)="data">
          <a :href="`/dpsaft/notification/${data.item.id}/download`" target="download"><i class="fa fa-download" /></a>
        </template>
      </b-table>
    </b-card>
    <div v-if="addingNotification">
      <b-card header="New Notification">
        <b-card-text>
          <b-form>
            <b-form-group label="Notification Type" label-class="font-weight-bold">
              <b-form-radio-group :options="typeOptions" v-model="newNotificationType" />
            </b-form-group>
            <b-form-group label="Buses Included" label-class="font-weight-bold">
              <span v-if="newNotificationType == TransportationNotificationTypes.FinalReport">
                This notification will create a report with all inspections in this batch
              </span>
              <b-list-group v-else-if="busesIncluded.length > 0">
                <b-list-group-item v-for="bus in busesIncluded" :key="bus.busNumber" :class="{'text-danger': bus.isGrounded}">
                  <span><i title="Inspection Complete" class="fas fa-fw fa-check text-primary" v-if="bus.status == 10" /> <i title="Inspection Paused" class="fas fa-pause fa-fw text-danger" v-if="bus.status == 4" /> </span>{{ busTextValue(bus.groundingInspection || bus) }} 
                </b-list-group-item>
              </b-list-group>
              <span v-else>
                No buses available for notification
              </span>
            </b-form-group>
            <b-form-group label="Additional Comments" label-class="font-weight-bold">
              <b-form-textarea v-model="additionalComments" />
            </b-form-group>
          </b-form>
        </b-card-text>
        <div>
          <b-button variant="secondary" class="mr-2" @click="addingNotification=false">
            Cancel
          </b-button>
          <animated-button button-class="btn btn-primary" :promise-resolver="addNotification" :disabled="(busesIncluded.length < 1) && !(isBatchComplete && newNotificationType == TransportationNotificationTypes.FinalReport)">
            Send
          </animated-button>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import AnimatedButton from '../../../AnimatedButton.vue'
import { InspectionBatchStatus, TransportationNotificationTypes } from '../../common/Enums'
import { Types } from '../../../../stores/dpsaft/trans'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      InspectionBatchStatus,
      TransportationNotificationTypes,
      addingNotification: false,
      additionalComments: "(none)",
      newNotificationType: TransportationNotificationTypes.IncrementalReport,
      typeOptions: [
        { text: 'Incremental Inspection Report', value: TransportationNotificationTypes.IncrementalReport },
        { text: 'Final Inspection Report', value: TransportationNotificationTypes.FinalReport, disabled: false},
      ],
      fields: [
        { key: 'id' },
        { key: 'notificationType' },
        { key: 'sentDate' },
        { key: 'districtAcknowledgedBy' },
        { key: 'districtAcknowledgedDate' },
        { key: 'reportLink', class: 'text-right' },
      ]
    }
  },
  computed:{
    ...mapState(Types.path, [Types.state.SelectedBatch, Types.state.SelectedInspection, Types.state.SelectedBatchNotifications]),
    ...mapState("globalModule", ["userContext"]),
     isBatchComplete() {
      return this.SelectedBatch && this.SelectedBatch.status == InspectionBatchStatus.Completed
    },
    busesIncluded() {
      if (this.newNotificationType == TransportationNotificationTypes.IncrementalReport)
        return (this.SelectedBatchNotifications.incrementalNotificationCandidates || [])

      return []
    },
    lastFinalReportNotificationDate() {
      const notifications = this.notificationdDatesByType(TransportationNotificationTypes.FinalReport)
      if (notifications.length < 1) 
        return 'N/A'
      return new Date(Math.max(...notifications)).toLocaleDateString()
    },
    lastIncrementalInspectionNotificationDate() {
      const notifications = this.notificationdDatesByType(TransportationNotificationTypes.IncrementalReport)
      if (notifications.length < 1) 
        return 'N/A'
      return new Date(Math.max(...notifications)).toLocaleDateString()
    },
    hasDuplicateBusNumbers(){
      return this.SelectedBatch.duplicateBusNumbers && this.SelectedBatch.duplicateBusNumbers.length > 0
    }
  },
  methods: {
    addNotification() {
      return this.$store.dispatch(Types.actions.sendBatchNotifications, { batchId: this.SelectedBatch.batchId, type: this.newNotificationType, additionalComments: this.additionalComments }).then(() => {
        this.addingNotification = false
        return true
      })
    },
    notificationdDatesByType(type) {
      return ((this.SelectedBatchNotifications || {}).createdBatchNotifications || []).filter(n => n.notificationType == type && n.sentDate).map(n => new Date(n.sentDate))
    },
    displayNotificationtype(type) {
      if (type == TransportationNotificationTypes.IncrementalReport) 
        return 'Incremental Report'
      else if (type == TransportationNotificationTypes.FinalReport) 
        return 'Final Report'
    },
    busTextValue(bus) {
      var text = ''      
      if(bus.busNumber){
        text += `| Permit Number: ${bus.busNumber} `
      }
      if(bus.stickerNumber){
        text += `| Sticker Number: ${bus.stickerNumber} `
      }
      if(bus.vin){
        text += `| VIN: ${bus.vin} `
      }
      return text.substr(2, text.length)
    }
  },
  components: {
    AnimatedButton
  },
  watch: {
    SelectedBatch: {
      immediate: true,
      handler(newBatch) {
        if (newBatch && newBatch.batchId) {
          this.$store.dispatch(Types.actions.getBatchNotifications, { batchId: newBatch.batchId })
        }
      }
    },
    isBatchComplete: {
      immediate: true,
      handler() {
        this.typeOptions[0].disabled =  this.isBatchComplete
        this.typeOptions[1].disabled = !this.isBatchComplete
        this.newNotificationType = this.isBatchComplete ? TransportationNotificationTypes.FinalReport : TransportationNotificationTypes.IncrementalReport 
      }
    }
  }
}
</script>
import { LeaTypes } from '@/helpers/leaTypes'
import sumBy from 'lodash.sumby'
import groupBy from 'lodash.groupby'

export function getVirualMealStatusSheets() {
  var items = []
  var totals = {}
  var rows = {}

  
  this.virtualStudentsByMealStatus.forEach(row => {
    var choice = row.freeReducedLunch
    rows[row.lea] = { ...rows[row.lea] }   
    if (!rows[row.lea][choice]) rows[row.lea][choice] = 0
    rows[row.lea][choice] += row.studentCount
  })
  switch (this.selectedLea.type)
  {
    case LeaTypes.State: 
      items = Object.keys(rows).sort().map(k => {
        var district = this.leaDictionary[k]?.parentLea
        var coop = district ? this.leaDictionary[district.number]?.parentLea : {}
        return {
          'CoOp LEA': coop?.number || '',
          'CoOp Name': coop?.name || 'Unknown CoOp',
          'District LEA': district?.number || '',
          'District Name': district?.name || 'Unknown District',
          'School LEA': k,
          'School Name': this.leaName(k),
          'Free / Reduced Lunch': rows[k][true] || 0,
          'Full Price Paid': rows[k][false] || 0,
        }
      })
      totals = {
        'CoOp LEA': '',
        'CoOp Name': '',
        'District LEA': '',
        'District Name': '',
        'School LEA': '',
        'School Name': 'State of Arkansas TOTAL',
        'Free / Reduced Lunch': sumBy(items, 'Free / Reduced Lunch'),
        'Full Price Paid': sumBy(items, 'Full Price Paid'),
      }
      var districts = groupBy(items, (i) => { return i['District LEA'] })
      var itemsDistrict = Object.keys(districts).sort((a,b) => a-b).map(districtLEA => {
        var districtRows = districts[districtLEA]
        var district = this.leaDictionary[districtLEA]
        var coop = district ? district.parentLea : {}
        return {
          'CoOp LEA': coop?.number || '',
          'CoOp Name': coop?.name || 'Unknown CoOp',
          'District LEA': district?.number || '',
          'District Name': district?.name || 'Unknown District',
          'Free / Reduced Lunch': sumBy(districtRows, 'Free / Reduced Lunch') || 0,
          'Full Price Paid': sumBy(districtRows, 'Full Price Paid') || 0,
        }
      })
      var totalsDistrict = {
        'CoOp LEA': '',
        'CoOp Name': '',
        'District LEA': '',
        'District Name': 'State of Arkansas TOTAL',
        'Free / Reduced Lunch': sumBy(items, 'Free / Reduced Lunch'),
        'Full Price Paid': sumBy(items, 'Full Price Paid'),
      }
      var coops = groupBy(items, (i) => { return i['CoOp LEA'] })
      var itemsCoop = Object.keys(coops).sort((a,b) => a-b).map(coopLEA => {
        var coopRows = coops[coopLEA]
        var coop = this.leaDictionary[coopLEA]
        return {
          'CoOp LEA': coop?.number || '',
          'CoOp Name': coop?.name || 'Unknown CoOp',
          'Free / Reduced Lunch': sumBy(coopRows, 'Free / Reduced Lunch') || 0,
          'Full Price Paid': sumBy(coopRows, 'Full Price Paid') || 0,
        }
      })
      var totalsCoop = {
        'CoOp LEA': '',
        'CoOp Name': 'State of Arkansas TOTAL',
        'Free / Reduced Lunch': sumBy(items, 'Free / Reduced Lunch'),
        'Full Price Paid': sumBy(items, 'Full Price Paid'),
      }
      return [
        { name: 'Virtual Meal Status School', items, totals },
        { name: 'Virtual Meal Status District', items: itemsDistrict, totals: totalsDistrict },
        { name: 'Virtual Meal Status CoOp', items: itemsCoop, totals: totalsCoop }
      ]
    case LeaTypes.Coop:
      items = Object.keys(rows).sort().map(k => {
        var district = this.leaDictionary[k]?.parentLea
        return {
          'District LEA': district?.number || '',
          'District Name': district?.name || '',
          'School LEA': k,
          'School Name': this.leaName(k),
          'Free / Reduced Lunch': rows[k][true] || 0,
          'Full Price Paid': rows[k][false] || 0,
        }
      })
      totals = {
        'District LEA': '',
        'District Name': '',
        'School LEA': '',
        'School Name': `${this.selectedLea.name} TOTAL`,
        'Free / Reduced Lunch': sumBy(items, 'Free / Reduced Lunch'),
        'Full Price Paid': sumBy(items, 'Full Price Paid'),
      }
      districts = groupBy(items, (i) => { return i['District LEA'] })
      itemsDistrict = Object.keys(districts).sort((a,b) => a-b).map(districtLEA => {
        var districtRows = districts[districtLEA]
        var district = this.leaDictionary[districtLEA]
        var coop = district ? district.parentLea : {}
        return {
          'CoOp LEA': coop?.number || '',
          'CoOp Name': coop?.name || 'Unknown CoOp',
          'District LEA': district?.number || '',
          'District Name': district?.name || 'Unknown District',
          'Free / Reduced Lunch': sumBy(districtRows, 'Free / Reduced Lunch') || 0,
          'Full Price Paid': sumBy(districtRows, 'Full Price Paid') || 0,
        }
      })
      totalsDistrict = {
        'CoOp LEA': '',
        'CoOp Name': '',
        'District LEA': '',
        'District Name': `${this.selectedLea.name} TOTAL`,
        'Free / Reduced Lunch': sumBy(items, 'Free / Reduced Lunch'),
        'Full Price Paid': sumBy(items, 'Full Price Paid'),
      }
      return [
        { name: 'Virtual Meal Status School', items, totals },
        { name: 'Virtual Meal Status District', items: itemsDistrict, totals: totalsDistrict },
      ]
    case LeaTypes.District:
      items = Object.keys(rows).sort().map(k => {
        return {
          'School LEA': k,
          'School Name': this.leaName(k),
          'Free / Reduced Lunch': rows[k][true] || 0,
          'Full Price Paid': rows[k][false] || 0,
        }
      })
      totals = {
        'School LEA': '',
        'School Name': `${this.selectedLea.name} TOTAL`,
        'Free / Reduced Lunch': sumBy(items, 'Free / Reduced Lunch'),
        'Full Price Paid': sumBy(items, 'Full Price Paid'),
      }
      return [{ name: 'Virtual Meal Status School', items, totals }]
    case LeaTypes.School:
      items = Object.keys(rows).sort().map(k => {
        return {
          'School LEA': k,
          'School Name': this.leaName(k),
          'Free / Reduced Lunch': rows[k][true] || 0,
          'Full Price Paid': rows[k][false] || 0,
        }
      })
      return [{ name: 'Virtual Meal Status School', items }]      
  }
  return []
}
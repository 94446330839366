// Do parents have access to information about classroom curriculum, required reading materials, and books?
// Do parents have input on curriculum selection committees?
// Does the school allow alternate assignments if requested by parent or guardian?
// Do parents have online access to the list of books in the school library?
// Additional Comments. (If Any) – Optional Question

export const surveyTemplate = {
  sections: [
    {
      name: 'Curriculum Transparency Survey',
      target: 'core/curriculumTransparencySurvey',
      
      questions: [
        {
          id: 'parentsHaveAccessToCurriculum',
          text: 'Do parents have access to information about classroom curriculum, required reading materials, and books?',
          component: 'QuestionYesnoDetails',
          textDetails: 'If yes, please provide a link to where parents can access this information.',
          detailsOnValues: [ 1 ]
        },

        {
          id: 'parentsHaveInputOnCurriculumSelectionCommittees',
          text: 'Do parents have input on curriculum selection committees?',
          component: 'QuestionYesno'
        },
        {
          id: 'schoolAllowsAlternateAssignments',
          text: 'Does the school allow alternate assignments if requested by parent or guardian?',
          component: 'QuestionYesno'
        },
        {
          id: 'parentsHaveOnlineAccessToLibraryBooks',
          text: 'Do parents have online access to the list of books in the school library?',
          component: 'QuestionYesno'
        },
        {
          id: 'additionalComments',
          text: 'Additional Comments (If Any)',
          component: 'QuestionFreeText',
          textDetails: 'Please provide any additional comments or suggestions for improving curriculum transparency.',

        }
      ]
    }
  ]
}
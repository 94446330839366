<template>
  <div>
    <classroom-detail />
  </div>
</template>

<script>
import ClassroomDetail from '../common/ClassroomDetail.vue'

export default {
  components: {
    ClassroomDetail
  }
}
</script>
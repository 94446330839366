import Vue from 'vue'
import { LicenseExceptionIndividualsTable } from './LicenseExceptionIndividualsTable'

const LicenseExceptionsIndividualsWidget = Vue.extend({
  name: 'license-exceptions-individuals-widget',
  /* html */
  template: `
    <div class="card bg-light" v-bind:class="{ 'mt-3': selectedLea.type != 3 }">
      <a class="anchor" id="licenses"></a>
      <div class="card-header">License Exceptions Individuals</div>
      <div class="card-body">
        <div class="card-group">
          <div class="alert alert-warning col-md-12" v-if="selectedLea.type == 3">Select a school or district to view License Exception Individuals.</div>
          <license-exception-individuals-table v-bind:loadData="loadData" v-bind:selected-lea="selectedLea" v-else></license-exception-individuals-table>
        </div>
      </div>
    </div>
    `,
  data() {
    return { loadData: null }
  },
  computed: {
  },
  components: {
    LicenseExceptionIndividualsTable,
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if (newLea && newLea.number) {
          this.loadData = Promise.all([
            this.$store.dispatch('hcptModule/getLicenseExceptionIndividualsWithALP', { lea: newLea.number }),
          ])
        }
      }
    }
  }
})

export { LicenseExceptionsIndividualsWidget }

<template>
  <div>
    <div class="card mb-2">  
      <div
        class="card-body"
      >
        <p>The Arkansas Department of Education (ADE): Division of Elementary and Secondary Education (DESE) is pleased to announce the release of the School Communication Tool on the Insights platform. The School Communication Tool is intended for Superintendents, Assistant Superintendents and, District Communication Directors to notify School Closures/Late Start/Early Release to the department using an electronic (web-based) mechanism.</p>
        <p>The reasons for School Closure and date/time of actual School Closures are the other pertinent fields mandatory for reporting this to the department. Please ensure the information is submitted at the earliest convenience to the department, closer to the actual event being happening to keep the department and other relevant stakeholders informed.</p>
        <p>Types of School Closures</p>
        <ul>
          <li v-for="reason in reasons" :key="reason.value">
            {{ reason.label }}
          </li>
        </ul>
        <p>If you have any additional clarification, please email <a href="mailto:ADE.Communications@ADE.Arkansas.Gov">ADE.Communications@ADE.Arkansas.Gov</a></p>
        <p>Note: This reporting is independent of the mandatory Calendar reporting done in eSchoolPLUS Student Management System.</p>

        <div class="text-center my-3">
          <b-button variant="primary" :to="{name: 'sct-new-closure', params: {districtLea: userContext.districtLEA}}">
            <i class="fa fa-fw fa-plus-circle" /> Add a Closure
          </b-button>
        </div>
      </div>
    </div>
    <div class="card mb-2 d-none">
      <div class="card-header">
        How should a district make a determination to close?
      </div>
      <div
        class="card-body"
      >
        DESE has provided guidance through the <a href="http://dese.ade.arkansas.gov/public/userfiles/Communications/Ready/ADE_Response_Levels_for_OnSite_Learning_FINAL_Updated_8_3_20.pdf" target="_blank">Response Level Document</a>.  A supplemental section has been added to the response level document, which provides guidance for continued onsite operations
        at each level of response.  <a href="https://docs.google.com/document/d/1gb3ohVO9VWad1YNcztRAy0kLhC_mC946Zymni7Gtm3I/edit" target="_blank">Supplemental Resource: Onsite Operation Decisions</a>  The district leadership should consult with the state health department to discuss data that can be found from the various databases such as ACHI and ADE websites in addition to internal information.  The district may also choose to consult with other area superintendents, ESC directors, and the ADE/ADH Hotline.  A factor that should be considered is the ability to appropriately and safely staff a school or district due to absences.  The second factor should be the number of student absences in a school or district related to health issues.  Modifications to onsite operations might be a better response to closure of one or more sites, especially if the number/percentage of students and staff affected are not spread throughout the district.  There are no set metrics for making this determination and should be based on consultation with the Arkansas Department of Health to make appropriate response level decisions.  
        
        <p>
          If faced with staffing issues due to required quarantines, Superintendents should first consider moderate responses to continue onsite operations to the extent possible. During any closure period that exceeds 5 days, the district shall consult with and seek approval from DESE and ADH to determine if the school district should reopen, extend a closure, or implement other types of responses that align with the moderate or critical response levels.  
        </p>

        <p>
          Extended closure of some or all district facilities may be required.  Small group on-site instruction and special services may be provided following restrictions outlined in the
          <a href="https://docs.google.com/spreadsheets/d/14lr1dci7G4k6GAaZF4IefRY9KN-2KPyZXSuJVbtbsKE/edit#gid=0" target="blank">Critical Response Level Restrictions for Onsite Instruction and Activities</a> of the <a href="http://dese.ade.arkansas.gov/public/userfiles/Communications/Ready/ADE_Response_Levels_for_OnSite_Learning_FINAL_Updated_8_3_20.pdf" target="_blank">Response Level Document</a>.  District staff may report onsite as needed to ensure continuation of academic and support services.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { ClosureReasons } from './enums'

export default {
  data() {
    return {
      ClosureReasons
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    reasons() {
      // return this.ClosureReaons where the value is > 0 and sorted by name
      return Object.values(this.ClosureReasons)
        .filter(r => r.value > 0)
        .sort((a, b) => a.label.localeCompare(b.label))
    }
  },
  props: {
    districtLea: {
      required: false,
      type: String
    }
  }
}
</script>
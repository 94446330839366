<template>
  <tr>
    <th colspan="3" class="text-center">
      {{ headerText }}
    </th>
  </tr>
</template>

<script>
export default {
  props: {
    headerText: String
  }
}
</script>
import ApiService from '../services/ApiService'

function createSelectItem(item, textField, valueField) {
  return {
    value: item[valueField],
    text: item[textField]
  }
}

function filterAreas(item, filter) {
  let matches = true
  if (matches && filter.areaGroup)
    matches = item.groupName == filter.areaGroup
  if (matches && filter.areaSubgroup)
    matches = item.subgroupName == filter.areaSubgroup
  if (matches && filter.license)
    matches = item.licenseCode == filter.license

  return matches
}

function filterJobs(item, filter) {
  let matches = true
  if (matches && filter.jobGroup)
    matches = item.groupName == filter.jobGroup
  if (matches && filter.jobSubgroup)
    matches = item.subgroupName == filter.jobSubgroup
  if (matches && filter.job)
    matches = item.jobCode == filter.job
    
  return matches
}

export class ShortageReportModule {
  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
  }

  namespaced = true

  state = {
    shortageReportAreaStatistics: {
      totals: [],
      byCoOp: [],
      byDistrict: [],
      byBuilding: []
    },
    shortageReportJobStatistics: {
      totals: [],
      byCoOp: [],
      byDistrict: [],
      byBuilding: []
    },
    filter: {
      areaGroup: null,
      areaSubgroup: null,
      jobGroup: null,
      jobSubgroup: null,
      license: null,
      job: null
    },
    exporting: false
  }

  getters = {
    shortageReportAreaStatisticsFiltered: (state) => {
      const filters = {...state.filter}
      return { 
        totals: [...state.shortageReportAreaStatistics.totals].filter(item => filterAreas(item, filters)),
        byCoOp: [...state.shortageReportAreaStatistics.byCoOp].filter(item => filterAreas(item, filters)),
        byDistrict: [...state.shortageReportAreaStatistics.byDistrict].filter(item => filterAreas(item, filters)),
        byBuilding: [...state.shortageReportAreaStatistics.byBuilding].filter(item => filterAreas(item, filters))
      }
    },
    shortageReportJobStatisticsFiltered: (state) => {
      const filters = {...state.filter}
      return { 
        totals: [...state.shortageReportJobStatistics.totals].filter(item => filterJobs(item, filters)),
        byCoOp: [...state.shortageReportJobStatistics.byCoOp].filter(item => filterJobs(item, filters)),
        byDistrict: [...state.shortageReportJobStatistics.byDistrict].filter(item => filterJobs(item, filters)),
        byBuilding: [...state.shortageReportJobStatistics.byBuilding].filter(item => filterJobs(item, filters))
      }
    },
    areaGroupList: (state) => {
      if (!state.shortageReportAreaStatistics.totals) return []

      return [{ text: 'All Groups', value: null }, ...state.shortageReportAreaStatistics.totals
        .filter((item, index, arr) => arr.findIndex(i => i.groupName == item.groupName) == index)
        .map(g => createSelectItem(g, 'groupName', 'groupName'))
      ]
    },
    areaSubgroupList: (state) => {
      if (!state.shortageReportAreaStatistics.totals) return []

      return [{ text: 'All Subgroups', value: null }, ...state.shortageReportAreaStatistics.totals
        .filter((item, index, arr) => arr.findIndex(i => i.subgroupName == item.subgroupName) == index)
        .map(g => createSelectItem(g, 'subgroupName', 'subgroupName'))
      ]
    },
    jobGroupList: (state) => {
      if (!state.shortageReportJobStatistics.totals) return []

      return [{ text: 'All Groups', value: null }, ...state.shortageReportJobStatistics.totals
        .filter((item, index, arr) => arr.findIndex(i => i.groupName == item.groupName) == index)
        .map(g => createSelectItem(g, 'groupName', 'groupName'))
      ]
    },
    jobSubgroupList: (state) => {
      if (!state.shortageReportJobStatistics.totals) return []

      return [{ text: 'All Subgroups', value: null }, ...state.shortageReportJobStatistics.totals
        .filter((item, index, arr) => arr.findIndex(i => i.subgroupName == item.subgroupName) == index)
        .map(g => createSelectItem(g, 'subgroupName', 'subgroupName'))
      ]
    },
    licenseCodeList: (state) => {
      if (!state.shortageReportAreaStatistics.totals) return []

      return [{ text: 'All License / Course Codes', value: null }, ...state.shortageReportAreaStatistics.totals
        .filter((item, index, arr) => arr.findIndex(i => i.licenseCode == item.licenseCode) == index)
        .map(g => { 
          return {
            value: g.licenseCode,
            text: `${g.licenseCode} - ${(state.shortageReportAreaStatistics.licenseCodes.find(l => l.code == g.licenseCode) || { description: g.licenseCode }).description}`
          }
        })]
    },
    jobCourseCodeList: (state) => {
      if (!state.shortageReportJobStatistics.totals) return []

      return [{ text: 'All Job / Course Codes', value: null }, ...state.shortageReportJobStatistics.totals
        .filter((item, index, arr) => arr.findIndex(i => i.jobCode == item.jobCode) == index)
        .map(g => { 
          return {
            value: g.jobCode,
            text: `${g.jobCode} - ${(state.shortageReportJobStatistics.jobAndCourseCodes.find(l => l.code == g.jobCode) || { description: g.jobCode }).description}`
          }
        })]
    }
  }

  mutations = {
    setShortageAreaStatistics: (state, data) => state.shortageReportAreaStatistics = data,
    setShortageJobStatistics: (state, data) => state.shortageReportJobStatistics = data,
    setExporting: (state, exporting) => state.exporting = exporting,
    setFilterAreaGroup: (state, areaGroup) => {
      state.filter.areaGroup = areaGroup
    },
    setFilterAreaSubgroup: (state, areaSubgroup) => {
      state.filter.areaSubgroup = areaSubgroup
    },
    setFilterJobGroup: (state, jobGroup) => {
      state.filter.jobGroup = jobGroup
    },
    setFilterJobSubgroup: (state, jobSubgroup) => {
      state.filter.jobSubgroup = jobSubgroup
    },
    setFilterLicense: (state, license) => {
      state.filter.license = license
    },
    setFilterJob: (state, job) => {
      state.filter.job = job
    }
  }

  actions = {
    getAreaEducators: (/* eslint-disable-line */ { commit }, payload) => {
      return this._apiService.Post(`${this._baseUrl}api/shortage-report/area-individuals?lea=${payload.scopeLea}`, payload)
    },
    getJobEducators: (/* eslint-disable-line */ { commit }, payload) => {
      return this._apiService.Post(`${this._baseUrl}api/shortage-report/job-individuals?lea=${payload.scopeLea}`, payload)
    },

    getShortageStatistics: ({ commit }, payload) => {
      return this._apiService
        .Post(`${this._baseUrl}api/shortage-report/statistics?lea=${payload.scopeLea}`, payload)
        .then(shortageData => commit('setShortageStatistics', shortageData))
    },
    getShortageStatisticsByJob: ({ commit }, payload) => {
      return this._apiService
        .Post(`${this._baseUrl}api/shortage-report/job-statistics?lea=${payload.scopeLea}`, payload)
        .then(shortageData => commit('setShortageJobStatistics', shortageData))
        .then(() => {
          //console.log(`job data set for ${payload.scopeLea}`)
        })
    },
    getShortageStatisticsByArea: ({ commit }, payload) => {
      return this._apiService
        .Post(`${this._baseUrl}api/shortage-report/area-statistics?lea=${payload.scopeLea}`, payload)
        .then(shortageData => commit('setShortageAreaStatistics', shortageData))
        .then(() => {
          //console.log(`area data set for ${payload.scopeLea}`)
        })
    },
    exportToExcel: ({ commit }, payload) => {
      commit('setExporting', true)
      return this._apiService.DownloadWithPost(`${this._baseUrl}api/shortage-report/export`, payload).then(() => commit('setExporting', false))

      //return this._apiService.DownloadWithPost(`${this._baseUrl}api/shortage-report/export`, payload)
    }
  }
}

<template>
  <div v-if="currentStudent.studentInformation">
    <student-information-toolbar v-if="!currentStudent.isReadOnly" :can-save="validation.$dirty" :save-method="save.bind(null, 'WorkExperienceResponse')" @cancel="cancel" />

    <b-table striped small caption="Work-based Learning Experience" :caption-top="true" :items="local.workBasedLearning" :fields="wblFields">
      <template #cell(type)="data">
        <b-form-select :disabled="currentStudent.isReadOnly" v-model="data.item.type" :options="staticMetadata.workExperience.wblOptions" />
      </template>      
      <template #cell(notes)="data">
        <b-form-textarea :disabled="currentStudent.isReadOnly" v-model="data.item.notes" />
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Types } from '@/modules/cte/stores/careercoach'
import { metadata as staticMetadata } from '@/modules/cte/stores/careercoach/metadata'
import { gradeMap } from '@/helpers/gradeSpans'
import { StudentComponentMixin } from './StudentComponentMixin'

export default {
  mixins: [StudentComponentMixin],
  computed: {
    ...mapState(Types.path, [Types.state.currentStudent])
  },
  data() {
    return {
      gradeMap,
      staticMetadata,
      workExperienceFields: [
        {key: 'grade', formatter: (d) => { return gradeMap.get(d) }, class: 'text-center', thStyle: 'width: 150px;' },
        'notes'
      ],
      wblFields: [
        {key: 'grade', formatter: (d) => { return gradeMap.get(d) }, class: 'text-center', thStyle: 'width: 150px;' },
        {key: 'type', class: 'text-center', thStyle: 'width: 250px;' },
        'notes'
        ],
    }
  }
}
</script>


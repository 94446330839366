<template>
  <div>
    <div class="d-flex flex-row">
      <a href="/transportation/batches" class="mr-2 nav-link"><i class="fas fa-desktop fa-lg" title="Return to Full View" v-if="isOnline" /></a>
      <b-input-group class="mr-2">
        <b-form-input placeholder="Search by District Name, LEA or Description..." v-model="textFilter" />
        <b-input-group-append>
          <b-button variant="info" @click="textFilter=null">
            Clear
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <a href="javascript:history.go(1)" class="pt-1">
        <i class="fas fa-arrow-alt-circle-right fa-2x" />
      </a>
    </div>

    <Promised :promise="loadData">
      <template v-slot:pending>
        <div class="align-items-center justify-content-center w-100">
          <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Downloading batches..." />
        </div>
      </template>
      <template v-slot="data">
        <div>
          <span class="d-none">
            {{ data }}
          </span>
          <b-card v-if="filteredBatches.length == 0">
            No batches found matching the entered search string.
          </b-card>

          <batch-card v-for="b in filteredBatches" :key="b.batchId" :batch="b" />
        </div>
      </template>
      <template v-slot:rejected class="align-items-center justify-content-center">
        <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Unable to retrieve Inspection Batches. It is likely your authorization has expired. Please log out and log back in'" />
      </template>
    </Promised>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Promised } from 'vue-promised'
import BatchCard from './BatchCard'
import CardStateIcon from '../../../CardStateIcon.vue'
import { Types } from '../../../../stores/dpsaft/trans'

export default {
  name: 'batch-list',
  data(){
    return {
      textFilter: null,
      loadData: Promise.resolve(true)
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext', 'isOnline']),
    ...mapState(Types.path, [Types.state.inspectionBatches]),
    filteredBatches() {
      if (this.textFilter) {
        var regex = new RegExp(this.textFilter, 'i')
        return this.inspectionBatches.filter((b) => {
          const batchString = (b.districtName || '') + '|' + (b.districtLea || '') + '|' + (b.description || '')
          return regex.test(batchString)
        })
      } else {
        return this.inspectionBatches
      }
    }
  },
  mounted() {
    if (this.isOnline) {
      this.loadData = this.$store.dispatch(Types.actions.getUserBatches)
    }
  },
  components: {
    BatchCard,
    CardStateIcon,
    Promised
  }
}
</script>

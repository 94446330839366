import ApiService from '../../../services/ApiService'
import { formatFY } from '../../../helpers/formatters'

export class leams {

  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
  }

  namespaced = true

  state = {
    leaTypes: [],
    leamsRecords: [],
    leamsFiscalYears: [],
    leamsSelectedFiscalYear: null,
    leamsSyncProcessEnabled: false,
    currentLEAMSRecord: null,
    leamsSyncRecords: [],
  }

  mutations = {
    updateLeamsSyncRecord(state, data) {
      var record = state.leamsSyncRecords.filter(r => r.request.requestId == data.requestId)
      if (record && record.length == 1) {
        record[0].sync = {
          id: data.requestId,
          synchronizationResult: 0,
          processDate: new Date()
        }
      }
    },
    setLEAMSSyncProcessEnabled(state, enabled) {
      state.leamsSyncProcessEnabled = enabled
    },
    setLeamsSyncRecords(state, data) {
      data.forEach(i => {
        i.request.responseObject = JSON.parse(i.request.response.response)
        i.sync = i.sync || {
          processDate: null,
          syncronizationResult: null,
          errorDetails: null,
          id: null
        }
      })
      state.leamsSyncRecords = data
    },
    setLeaTypes(state, data) {
      state.leaTypes = data
    },
    setLEAMSFiscalYears(state, data) {
      state.leamsFiscalYears = data
    },
    setSelectedLEAMSFiscalYear(state, fy) {
      state.leamsSelectedFiscalYear = fy
    },
    setLEAMSRecords(state, data) {
      state.leamsRecords = data
    },

    setLEAMSRecord(state, data) {
      state.currentLEAMSRecord = data
    },
  }

  getters = {
    leamsDictionary: (state) => {
      let leamsMap = new Map();

      state.leamsRecords.reduce((accum, item) => {
        accum.set(item.leaNumber, item)
        return accum
      }, leamsMap)

      return leamsMap
    },

    fiscalYear: (state) => state.leamsFiscalYears.length > 0 ? state.leamsFiscalYears[0] : null,
    fiscalYearAssignmentOptions: (state) => state.leamsFiscalYears.length ? [
      { value: state.leamsFiscalYears[0]+2, text: formatFY(state.leamsFiscalYears[0]+2) } , 
      { value: state.leamsFiscalYears[0]+1, text: formatFY(state.leamsFiscalYears[0]+1) } ,
      { value: state.leamsFiscalYears[0], text: formatFY(state.leamsFiscalYears[0]) }
    ] : [],
  }

  actions = {
    
    // eslint-disable-next-line
    copyForwardLEAMSRecords: ({commit, dispatch}, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/tools/leams/copy-forward?sourceFY=${payload.sourceFY}&destFY=${payload.destFY}`))
        .then(() => dispatch('getLEAMSFiscalYears'))
    },

    synchronizeRequest: ({
      commit
    }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/tools/leams/synchronize/${payload.requestId}`))
        .then(() => commit('updateLeamsSyncRecord', {
          requestId: payload.requestId
        }))
    },

    getLEAMSRecords: ({
      commit
    }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/tools/leams/records/${payload.fy}`))
        .then(result => commit('setLEAMSRecords', result))
    },

    getLeamsSyncRecords: ({
      commit
    }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/tools/leams/sync/${payload.fy}`))
        .then(data => commit('setLeamsSyncRecords', data))
    },

    getLEAMSFiscalYears: ({
      commit
    }) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/tools/leams/fy`))
        .then(data => commit('setLEAMSFiscalYears', data))
    },

    getLeaTypes: ({
      commit
    }) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/tools/leams/lea-types`))
        .then(result => commit('setLeaTypes', result))
    },

    getLEAMSRecord: ({
      commit
    }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/tools/leams/record/${payload.id}`))
        .then(result => commit('setLEAMSRecord', result))
    },

    addLEAMSRecord: ({
      commit
    }, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/tools/leams/record`), payload)
        .then(result => commit('setLEAMSRecord', result))
    },

    updateLEAMSRecord: ({
      commit
    }, payload) => {
      return this._apiService.Patch(encodeURI(`${this._baseUrl}api/tools/leams/record`), payload)
        .then(result => commit('setLEAMSRecord', result))
    },

    setLEAMSRecordActiveState: ({
      commit
    }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/tools/leams/record/${payload.leaId}/active-state`), payload)
        .then(result => commit('setLEAMSRecord', result))
    },

    getLEAMSSyncProcessEnabled: ({commit}) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/tools/leams/sync/enabled`))
        .then(result => commit('setLEAMSSyncProcessEnabled', result))
    },
    setLEAMSSyncProcessEnabled: ({commit}, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/tools/leams/sync/enabled?enabled=${payload.enabled}`))
        .then(() => commit('setLEAMSSyncProcessEnabled', payload.enabled))
    },
    // eslint-disable-next-line
    validateLEANumberForOperation: ({commit}, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/tools/leams/${payload.fy}/validate-lea-number?leaNumber=${payload.leaNumber}&operation=${payload.operation}&leaId=${payload.leaId}`))
        .then((validationErrors) => validationErrors)
    },
    emailLEAMSSpreadsheet: (context, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/tools/leams/email/${payload.fy}`), payload)
    },
  }
}
<template>
  <div>
    <router-view />
  </div>
</template>
<script>
import ExternalAccountHomePage from './ExternalAccountHomePage'
import ExternalAccountActivationPage from './ExternalAccountActivationPage'
import ExternalAccountResetPasswordPage from './ExternalAccountResetPasswordPage'
import { router } from '@/routes'

router.addRoutes([
  { path: '/externalAccount', component: ExternalAccountHomePage, name: 'external-account-home' },
  { path: '/externalAccount/activate', component: ExternalAccountActivationPage, name: 'external-account-activate' },
  { path: '/externalAccount/password-reset', component: ExternalAccountResetPasswordPage, name: 'external-account-password-reset' },
])
export default {
  components: {
  },
}
</script>

<template>
  <div class="mt-3 pb-0">
    <b-table striped hover small :items="items" :fields="fields" sort-by="orderBy">
      <template #thead-top>
        <b-tr>
          <b-th colspan="3" rowspan="2" class="text-center border-right">
            {{ leaFormatter(selectedLea.number) }}
          </b-th>
          <b-th class="text-center border-left" colspan="6" v-if="selectedLea.type <= LeaTypes.School">
            School
          </b-th>
          <b-th class="text-center border-left" colspan="6" v-if="selectedLea.type <= LeaTypes.District">
            District
          </b-th>
          <b-th class="text-center border-left" colspan="3" v-if="selectedLea.type <= LeaTypes.State">
            State
          </b-th>
        </b-tr>
        <b-tr>
          <div class="d-contents" v-for="type in validLeaLevels" :key="type">
            <b-th class="text-center border-left" v-for="fy in years" :colspan="type <= LeaTypes.District ? 2 : 1" :key="type + '-' + fy">
              {{ fy+1990 }}
            </b-th>
          </div>
        </b-tr>
      </template>
      <template #cell()="data">
        <span v-if="data.item.subject == 'Science' && data.item.grade == '3' && data.field.key.includes('-')">NA</span>
        <span v-else-if="data.field.key.includes('-30')">CV</span>
        <span v-else>{{ data.value }}</span>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { LeaTypes } from '../../../../helpers/leaTypes'
import groupBy from 'lodash.groupby'
import { DemographicGroups } from '../FilterOptions'


export default {
  data() {
    return {
      LeaTypes,
      DemographicGroups
    }
  },
  computed: {
    ...mapState('academicsModule/growth', ['leaGrowthTrendPivot', 'fiscalYear', 'subgroup', 'grades']),
    validLeaLevels() {
      return [LeaTypes.School, LeaTypes.District, LeaTypes.State].filter(i => i >= this.selectedLea.type)
    },
    years() {
      return [this.fiscalYear - 2, this.fiscalYear - 1, this.fiscalYear]
    },
    dynamicFields() {
      return this.validLeaLevels.flatMap(dataLevel => {
        return this.years.flatMap(fiscalYear => {
          // CAUTION: 🚩 fragile, don't touch 🚩
          var data = [{
            key: `Mean-${dataLevel}-${fiscalYear}`,
            label: 'Mean',
            class: 'text-center border-left'
          },...[dataLevel != LeaTypes.State ? {
            key: `N-${dataLevel}-${fiscalYear}`,
            label: 'N',
            class: 'text-center border-left'
          } : {}]]

          return data
        })
      }) 
    },
    items() {
      var gradenumbers = this.grades.map(g => g.value)
      const filtered = this.leaGrowthTrendPivot.filter(l => l.subGroup == this.subgroup).filter(l => gradenumbers.includes(l.grade) || l.grade == null)
      let grouped = groupBy(filtered, l => JSON.stringify({ subject: l.subject, grade: l.grade, subGroup: l.subGroup }))

      var data = Object.keys(grouped).map(group => {
        let row = {...JSON.parse(group) }

        row['orderBy'] = `${row['grade'] || "0"}_${row['subject']}`
        row['subGroup'] = (this.DemographicGroups.find(g => g.value == row['subGroup']) || { gridText: row['subGroup'] }).gridText
        if (row['grade'] == null)
          row['grade'] = 'All'

        this.validLeaLevels.forEach(dataLevel => {
          this.years.forEach(fiscalYear => {
            const leaFiscalYearScores = grouped[group].find(g => g.dataLevel == dataLevel && g.fiscalYear == fiscalYear) || { score: 'NA', n: 'NA' }
            row[`Mean-${dataLevel}-${fiscalYear}`] = leaFiscalYearScores.score
            row[`N-${dataLevel}-${fiscalYear}`] =  leaFiscalYearScores.n
          })
        })
       
        return row
      })

      // add fake row for Grade 3 Science
      if (gradenumbers.includes('3'))
        data.push({ subject: 'Science', grade: '3', subGroup: data[0].subGroup, orderBy: `3_Science` })

      return data
    },
    fields() {
      return [
        {
          key: 'subject',
          label: 'Subject',
        },
        {
          key: 'grade',
          label: 'Grade',
          class: 'text-center border-left'
        },
        {
          key: 'subGroup',
          label: 'Subgroup',
          class: 'text-center border-left'
        },
        ...this.dynamicFields
      ]
        // {
        //   key: 'Category',
        //   label: 'Name',
        //   sortable: true,
        //   //class: 'border-right'
        // },
        // {
        //   key: 'Description',
        //   label: 'Description',
        //   sortable: 'true',
        //   class: 'border-right'
        // },
        // {
        //   key: 'Personnel-Budgeted',
        //   label: 'Budgeted Amt.',
        //   sortable: true,
        //   tdClass: 'text-right',
        //   thClass: 'text-center',
        // },
        // {
        //   key: 'Personnel-Actual',
        //   label: 'Actual Amt.',
        //   sortable: true,
        //   class: 'border-right',
        //   tdClass: 'text-right',
        //   thClass: 'text-center',
        // },
        // {
        //   key: 'Materials, Supplies, and Equipment-Budgeted',
        //   label: 'Budgeted Amt.',
        //   sortable: true,
        //   tdClass: 'text-right',
        //   thClass: 'text-center',
        // },
        // {
        //   key: 'Materials, Supplies, and Equipment-Actual',
        //   label: 'Actual Amt.',
        //   sortable: true,
        //   class: 'border-right',
        //   tdClass: 'text-right',
        //   thClass: 'text-center',
        // },
        // {
        //   key: 'Services-Budgeted',
        //   label: 'Budgeted Amt.',
        //   sortable: true,
        //   tdClass: 'text-right',
        //   thClass: 'text-center',
        // },
        // {
        //   key: 'Services-Actual',
        //   label: 'Actual Amt.',
        //   sortable: true,
        //   class: 'border-right',
        //   tdClass: 'text-right',
        //   thClass: 'text-center',
        // },
        // {
        //   key: 'Budgeted',
        //   label: 'Budgeted Amt.',
        //   sortable: true,
        //   tdClass: 'text-right',
        //   thClass: 'text-center',
        // },
        // {
        //   key: 'Actual',
        //   label: 'Actual Amt.',
        //   sortable: true,
        //   class: 'border-right',
        //   tdClass: 'text-right',
        //   thClass: 'text-center',
        // },
        // {
        //   key: 'Remaining',
        //   label: 'Remaining',
        //   sortable: true,
        //   class: 'border-right',
        //   tdClass: 'text-right',
        //   thClass: 'text-center',
        // },
        // {
        //   key: 'Progress',
        //   label: 'Utilized',
        //   sortable: false,
        //   thStyle: 'width: 15%;'
        // },
    },
   
  },
  methods: {
    
  },
  props: {
    
  }
}
</script>

<style>
  .d-contents {
    display: contents !important;
  }
</style>
<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import DigitalLearningWaiverDistrictPage from './DigitalLearningWaiverDistrictPage.vue'
import DigitalLearningWaiverSnapshotPage from './DigitalLearningWaiverSnapshotPage.vue'

import SuperintendentAlternativeLogin from './auth/SuperintendentAlternativeLogin.vue'
import { router } from '@/routes'
import store from '@/stores'

router.addRoutes([
  { 
    path: '/Surveys/DigitalLearning', component: DigitalLearningWaiverDistrictPage, name: 'waiver-request-district', beforeEnter: (to, from, next) => {
      const userContextLea = (store.state.globalModule.userContext || {}).districtLEA
      if (!userContextLea) {
        next('/Surveys/DigitalLearning/AlternativeLogin')
      }
      else if (userContextLea == 'AR') {
        window.location.replace('/Surveys/DigitalLearning/Admin')
      }
      else {
        next()
      }
    }
  },
    { 
    path: '/Surveys/DigitalLearning/Snapshot', component: DigitalLearningWaiverSnapshotPage, name: 'waiver-request-snapshot', beforeEnter: (to, from, next) => {
      // const userContextLea = (store.state.globalModule.userContext || {}).districtLEA
      // if (!userContextLea) {
      //   next('/Surveys/DigitalLearning/AlternativeLogin')
      // }
      // else {
      next()
      // }
    }
  },
  { path: '/Surveys/DigitalLearning/AlternativeLogin', component: SuperintendentAlternativeLogin, name: 'district-alternative-login' },
])

export default {
  data() {
    return {}
  } 
}
</script>

<template>
  <div>    
    <loadable-chart-card :load-data="loadData" card-style="overflow-y: auto" v-if="chartVisible" :show-tooltip="true" :tooltip-content="tooltipContent" :tooltip-id="tooltipId">
      <template v-slot:default="slotProps">
        <div class="col-md-12 chartElem" v-show="slotProps.chartMode">
          <highcharts class="chart" :options="chartOptions" />
        </div>
        <div v-show="!slotProps.chartMode">
          <chart-data-table :items="dataItems()">
            <template v-slot:head(teacherDays)="data">
              <div>
                <custom-tooltip content="The product of Total Teacher Count x Instructional Days" :element-id="`${uniqueId}-rate-${data.label}`" :label="data.label" />
              </div>
            </template>
            <template v-slot:head(totalTeacherCount)="data">
              <div>
                <custom-tooltip content="Total Number of Teachers in the LEA" :element-id="`${uniqueId}-rate-${data.label}`" :label="data.label" />
              </div>
            </template>
            <template v-slot:head(numberOfTeachersTakingLeave)="data">
              <div>
                <custom-tooltip content="The number of unique teachers taking the specified type of leave" :element-id="`${uniqueId}-rate-${data.label}`" :label="data.label" />
              </div>
            </template>
            <template v-slot:head(leaveDays)="data">
              <div>
                <custom-tooltip content="The total number of days taken for the specified type of leave" :element-id="`${uniqueId}-rate-${data.label}`" :label="data.label" />
              </div>
            </template>
            <template v-slot:head(percentOfTotalLeave)="data">
              <div>
                <custom-tooltip
                  content="The weight this type of leave factored into the total amount of leave taken across the LEA"
                  :element-id="`${uniqueId}-rate-${data.label}`"
                  :label="data.label" />
              </div>
            </template>
            <template v-slot:head(absenteeRate)="data">
              <div>
                <custom-tooltip content="Leave Days / Teacher Days x 100" :element-id="`${uniqueId}-rate-${data.label}`" :label="data.label" />
              </div>
            </template>
          </chart-data-table>
        </div>
      </template>
    </loadable-chart-card>
  </div>
</template>

<script>
import LoadableChartCard from '../../LoadableChartCard'
import { ChartMixin } from '../../ChartMixin'
import { credits } from '../../../helpers/credits'
import shortid from 'shortid'

export default {
  name: 'employee-leave-rate-chart',
  data() {
    return {
      uniqueId: null,
      animationDuration: 1000,
      leaveType: null,
      tooltipContent: '<h5>Leave rate formula</h5><p>Number of Leave Days / Number of Teacher Days</p><p>Where number of Teacher Days is:<br/>Number of Teachers * Number of Instructional Days</p>',
      tooltipId: "leaveDaysFormulaTooltip",
      chartOptions: {
        chart: {
          type: 'column',
          height: this.$printMode ? 650 : 400
        },
        legend: {
          maxHeight: this.$printMode ? 450 : 200
        },
        credits: credits.SIS,
        title: {
          text: 'Employee Leave Rate'
        },
        series: [],
        xAxis: {
          title: {
            text: 'Leave Type'
          },
          type: 'category'
        },
        yAxis: {
          title: {
            text: 'Employee Leave Rate'
          },
          endOnTick: false
        },
        tooltip:{
          valueSuffix: '%'
        }
      }
    }
  },
  components: {
   LoadableChartCard
  },
  mixins: [ChartMixin],
  props: {
    loadData: Promise,
    leaveData: Array,
    stateView: String,
    charterOnlyMode: Boolean
  },
  computed: {
    chartVisible() {
      if (this.charterOnlyMode) {
        return this.stateView == '1'
      }
      return true
    }
  },
  methods: {
    dataItems() {
      var data = this.leaveData
      if (this.charterOnlyMode && this.leaveData.length > 1)
        data = [this.leaveData[1]]

      return data.flatMap(item => {
        return item.rows.map(row => {
          return this.stateView == 1
            ? {
                scopeLEA: row.scopeLEA,
                name: row.name,
                leaveCode: row.leaveCode,
                leaveDescription: row.leaveDescription,
                teacherDays: row.teacherDays,
                teacherCount: row.teacherCount,
                leaveDays: row.leaveDays,
                absenteeRate: row.absenteeRate + '%',
                leaCategory: row.leaCategory == '1' ? 'Charter' : 'Regular'
              }
            : {
                scopeLEA: row.scopeLEA,
                name: row.name,
                leaveCode: row.leaveCode,
                leaveDescription: row.leaveDescription,
                teacherDays: row.teacherDays,
                teacherCount: row.teacherCount,
                leaveDays: row.leaveDays,
                absenteeRate: row.absenteeRate + '%'
              }
        })
      })
    }
  },
  watch: {
    leaveData(newData) {
      this.chartOptions.series = []
      var seriesData = []
      var index = this.charterOnlyMode ? 1 : 0
      if (newData.length > index) {
        for (var i = 0; i < newData.length; i++) {
          seriesData = newData[i].rows
            .filter(
              r =>
                !this.charterOnlyMode ||
                (r.leaCategory == '1') == !this.charterOnlyMode
            )
            .map(row => {
              return {
                name: `(${row['leaveCode']}) - ${row['leaveDescription']}`,
                y: row['absenteeRate']
              }
            })
          this.chartOptions.series.push({
            data: seriesData,
            name: newData[i].name
          })
        }
      }
    }
  },
  mounted() {
    this.uniqueId = shortid.generate()
  }
}
</script>
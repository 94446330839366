<template>
  <div>
    <h2 class="caption">
      Plans After High School
    </h2>
    <b-form>
      <b-form-group label="Plans after high-school">
        <b-form-select :disabled="readonly" v-model="value.plan" :options="options" />
      </b-form-group>
      <b-form-group label="Other" v-if="value.plan == 7">
        <b-form-textarea :readonly="readonly" v-model="value.otherPlans" />
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Types } from '@/modules/cte/stores/careercoach'
export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState (Types.path, [Types.state.metadata]),
    options() {
      return (this.metadata.postHighSchoolPlans || []).map(o => { return { value: o.postHighSchoolPlanId, text: o.description} })
    }
  },
  props: {
    value: Object,
    readonly: Boolean
  },
  watch: {
    value: {
      deep: true,
      handler(newData) {
        this.$emit('input', newData)
      } 
    }
  }
}
</script>
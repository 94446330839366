<template>
  <div>
    <filter-widget @filter-changed="applyFilter($event)" :ay="ay" :ay-options="ayOptions" />
    <div class="report-container">
      <Promised :promise="loadData" v-show="loadData">
        <template v-slot:pending>
          <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
            <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
          </div>
        </template>
        <template v-slot:rejected="error" class="align-items-center justify-content-center">
          <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
        </template>      
        <template v-slot>
          <b-tabs pills content-class="mt-2" v-if="reportData && reportData != {}">
            <b-tab title="By Student">
              <b-alert show variant="warning" v-if="selectedLea.type > 2">
                Due to volume of data, student level reports are only available at the District or School level. Please select an appropriate LEA from the LEA Context Picker above.
              </b-alert>
              <div v-else>
                <student-service-details-report :report-data="reportData.details" v-if="reportData != {}" />
              </div>
            </b-tab>
            <b-tab title="By Month">
              <student-services-by-month-report :report-data="reportData.byMonth" v-if="reportData != {}" />
            </b-tab>
          </b-tabs>
        </template>
      </Promised>
    </div>
  </div>
</template>

<script>
import { Promised } from 'vue-promised'
import CardStateIcon from '@/components/CardStateIcon'
import StudentServiceDetailsReport from './StudentServiceDetailsReport.vue'
import StudentServicesByMonthReport from './StudentServicesByMonthReport.vue'
import FilterWidget from './FilterWidget.vue'
import { AYDependentReportMixin } from '../AYDependentReportMixin'

export default {
  data() {
    return {
      filter: {
        academicYear: null,
        lea: null,
        grade: null
      },
      loadData: null,
      reportData: null
    }
  },
  components: {
    StudentServiceDetailsReport,
    StudentServicesByMonthReport,
    Promised,
    CardStateIcon,
    FilterWidget
  },
  methods: {
    applyFilter(filter) {
      this.filter.lea = this.selectedLea.number
      this.filter.academicYear = filter.academicYear
      this.filter.grade = filter.grade
    },

    async getData() {
      if (this.filter.academicYear) {
        this.reportData = null
        this.loadData = this.$store.dispatch('cte/careercoach/reporting/studentServiceDetailsReport', this.filter)
        .then(d=>this.reportData = d)

      }
    }
  },
  watch: {
    selectedLea: {
      immediate: false,
      handler() {
        this.filter.lea = this.selectedLea.number
      }
    }, 
    filter: {
      deep: true,
      handler() {
        this.getData()
      }
    }
  },
  mixins: [
    AYDependentReportMixin
  ]
}
</script>
import Vue from 'vue'
import { Types } from '@/stores/digitalLearning'

import { mapState } from 'vuex'
const WaiverChartMixin = Vue.extend({
  computed: {
    ...mapState(Types.path, [Types.state.waiverRequests])
  },
  props: {
    loadData: Promise,
  },
  components: {
  }
})
export { WaiverChartMixin }
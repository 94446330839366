<template>
  <div class="px-2">
    <v-select @search="findStudents" :options="selectOptions" placeholder="Enter Student Name or Student Id" @input="$emit('student-selected', $event)" :filterable="false" />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import ApiService from '@/services/ApiService'
import { debounce } from '@/helpers/globalHelpers'

const apiService = new ApiService()

export default {
  components:{
    vSelect
  },
  data() {
    return {
      searchString: null,
      students: []
    }
  },
  computed: {
    selectOptions() {
      return this.students.filter(s => s.studentId).map(s=> { 
        return {
          code: s.studentId, lea: s.schoolLEA, label: `${s.lastName}, ${s.firstName} (${s.studentId}) - Grade ${s.grade}`
        }
      })
    }
  },
  methods: {
    findStudents: debounce(function (search, loading) {
      const self = this
      if (search && (search.length >= 3 || search.indexOf(',') >= 2 || search.indexOf(' ') >= 2)) {
        loading(true)
        apiService.Get(`/api/cte/career-coach/student-search?searchString=${search}`).then(students => {
          console.log(`found ${students.length} students`)
          self.students = students
          loading(false)
          console.log(`${self.selectOptions.length} optoins`)
        })
      }
    }, 300)
  }
}
</script>
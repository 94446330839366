<template>
  <div>
    <h2 class="text-dark">
      LEA Management
    </h2>
    <promised :promise="rollForwardProcess"> 
      <template>
        <div>
          <div class="row">
            <div class="col-md-12">
              <b-form>
                <b-form-row class="mb-2">
                  <label class="col-1">School Year</label>
                  <b-select class="col-1" :options="[{value: fiscalYear, text: formatFY(fiscalYear)}, {value: fiscalYear-1, text: formatFY(fiscalYear-1)}]" v-model="fy" />
                  <label class="col-2 offset-1">LEA Type Code</label>
                  <b-select class="col-md-3" :options="leaTypeOptions" v-model="leaTypeCode" />
                  <b-col>
                    <b-button-group>
                      <b-button :href="'/tools/lea-assignment/leams-listing/' + fiscalYear" variant="primary">
                        <i class="far fa-file-excel fa-fw" />Excel
                      </b-button>
                      <b-button variant="outline-primary" v-b-modal.email>
                        <i class="far fa-envelope fa-fw" />
                      </b-button>
                    </b-button-group>
                  </b-col>
                  <b-col class="text-right">
                    <b-button @click="initRoll" variant="warning">
                      Roll Forward to School Year {{ formatFY(fiscalYear + 1) }}
                    </b-button>
                  </b-col>
                </b-form-row>
              </b-form>
            </div>
          </div>
          <paginated-table :items="filteredLeamsRecords" :fields="fields" :filter="filter">
            <template v-slot:cell(gradeSpan)="data">
              <span
                v-if="data.item.gradeLow && data.item.gradeHigh"
              >{{ data.item.gradeLow }} - {{ data.item.gradeHigh }}</span>
            </template>
            <template v-slot:cell(lastUpdatedTimestamp)="data">
              <span>{{ data.value | date('short-date') }}<br>{{ data.value | date('time') }}</span>
            </template>
            <template v-slot:cell(address)="data">
              <span>{{ data.item.mailAddress }} <br>{{ data.item.mailCity }},  {{ data.item.mailState }} {{ data.item.mailZip }} {{ data.item.mailZip4 ? '-' + data.item.mailZip4 : '' }}</span>
            </template>
            <template v-slot:cell(actionColumn)="data">
              <b-button
                variant="link"
                :to="{name: 'leams-edit', params: {id: data.item.leaId}}"
                size="xs"
                class="text-decoration-none text-primary"
                title="Edit"
              >
                <span class="fas fa-pen" title="Edit" />
              </b-button>
            </template>
            <template v-slot:head(actionColumn)>
              <b-button :to="{ name: 'leams-new', params: {fy: fy}}" variant="primary">
                <i class="fa fa-plus" /> Add LEA
              </b-button>
            </template>
            <template v-slot:cell(isActive)="data">
              <pretty-checkbox :value="data.value" false-class="fas fa-times-circle text-danger" />
            </template>
            <template v-slot:cell(ale)="data">
              <pretty-checkbox :value="data.value" false-class="fas fa-times-circle text-secondary" true-class="fas fa-check-circle text-danger" />
            </template>
            <template v-slot:cell(isProvision2)="data">
              <pretty-checkbox :value="data.value" false-class="fas fa-times-circle text-secondary" />
            </template>    
          </paginated-table>
        </div>
      </template>
      <template v-slot:pending>
        <div class="text-center w-100" style="margin-top: 50px">
          <i class="fa-4x fas fa-spinner fa-spin text-secondary" />
          <h3 class="text-secondary">
            Please wait while the Roll Forward process completes ...
          </h3>
        </div>
      </template>
      <template v-slot:rejected="error">
        {{ error }}
      </template>
    </promised>

    <b-modal id="email" title="Email LEAMS Listing" @ok="sendEmail">
      <template v-slot:modal-ok>
        <i class="far fa-envelope fa-fw" />Send Email
      </template>
      <div class="card card-body">
        Enter the e-mail addresses of the recipients. Separate multiple addresses with a semi-colon (;):
        <input class="form-control" type="text" v-model.lazy="email.recipients">
        <textarea class="form-control" v-if="true" v-model.lazy="email.body" placeholder="(Optional comments)" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PaginatedTable from '../../../PaginatedTable'
import PrettyCheckbox from '../../../PrettyCheckbox'
import { formatFY } from '../../../../helpers/formatters'
import { Promised } from 'vue-promised'
export default {
  name: 'leams-browser',
  data() {
    return {
      formatFY,
      emailRecipients: '',
      email: {
        recipients: null,
        body: null
      },
      rollingForward: null,
      rollForwardProcess: Promise.resolve(true),
      fy: null,
      leaTypeCode: null,
      filter: null,
      fields: [
        { key: 'leaNumber', sortable: true, label: 'LEA #' },
        { key: 'name', sortable: true },
        { key: 'leaTypeCode', sortable: true, class: 'text-center' },
        { key: 'gradeSpan', sortable: true },
        { key: 'address', sortable: false, tdClass: 'small', tdAttr: {width: '180px;'} },
        { key: 'ale', sortable: true },
        { key: 'cspr', sortable: true },
        { key: 'isProvision2', sortable: true, label: 'Prov 2' },
        { key: 'isActive', class: 'text-center', label: 'Active', sortable: true },
        { key: 'lastUpdatedUser', sortable: true, label: 'Updated By' },
        { key: 'lastUpdatedTimestamp', sortable: true, tdAttr: {width: '120px;'}, label: 'Update Date' },
        { key: 'comment', tdClass: 'small' },
        { key: 'actionColumn', thStyle: "width:150px;", class: 'text-right' }
      ]
    }
  },
  computed: {
    ...mapState('toolsModule/leams', ['leamsRecords', 'leaTypes']),
    ...mapGetters('toolsModule/leams', ['fiscalYear']),
    ...mapState('globalModule', ['leaContext']),
    leaTypeOptions() {
      return this.leaTypes.map(t => ({ value: t.code, text: `${t.code} - ${t.description}` }))
    },
    filteredLeamsRecords() {
      return this.leamsRecords.filter(r => (this.leaTypeCode ? r.leaTypeCode == this.leaTypeCode || this.leaTypeCode == 'A' : true))
    }
  },
  methods: {
    initRoll() {
      this.$bvModal.msgBoxConfirm(`Are you sure you want to roll the LEAMS information forward to FY${this.fiscalYear + 1}?`)
      .then((result)=> {

        if (result) {
          this.rollForwardProcess = this.$store.dispatch('toolsModule/leams/copyForwardLEAMSRecords', {sourceFY: this.fiscalYear, destFY: this.fiscalYear+1})
          .then(()=>{
            this.fy = this.fiscalYear
          })
        }
      })
    },
    sendEmail() {
      return this.$store.dispatch('toolsModule/leams/emailLEAMSSpreadsheet', {fy: this.fiscalYear, recipients: this.email.recipients, body: this.email.body})
      .then(()=>this.$bvModal.hide('email'))
    }
  },
  mounted() {
    this.fy = this.$route.query.fy || this.fiscalYear
    this.filter = this.$route.query.lea
    
    return this.$store.dispatch('toolsModule/leams/getLeaTypes')


  },
  watch: {
    fy() {
      return this.$store.dispatch('toolsModule/leams/getLEAMSRecords', { fy: this.fy })
    }
  },
  components: {
    PaginatedTable,
    PrettyCheckbox,
    Promised
  }
}
</script>
<template>
  <div>
    <div v-if="isSchoolOrDistrict">
      <lea-display :lea="selectedLea" />
      {{ sanitizedEFinanceConnectionString }}
      <div class="card mt-2">
        <div class="card-header">
          Employees
        </div>
        <div class="card-body">
          <paginated-table :items="fmsEmployees" sort-by="lastName" :responsive="true" sort-asc :per-page="5" striped hover small />
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-header">
          Job Assignments
        </div>
        <div class="card-body">
          <paginated-table :items="fmsJobAssignments" sort-by="g_emplno" :responsive="true" sort-asc :per-page="5" striped hover small />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="alert alert-warning" role="alert">
        A school or district must be selected.
      </div>
    </div>
  </div>
</template>

<script>
import { LeaTypes } from '@/helpers/leaTypes';
import LeaDisplay from '@/components/LeaDisplay.vue';
import { mapState } from 'vuex';
import PaginatedTable from '@/components/PaginatedTable.vue';

export default {
  data() {
    return {
      LeaTypes,
    };
  },
  components: {
    LeaDisplay,
    PaginatedTable
  },
  methods: {
    async refreshData() {
      if (this.selectedLea) {
        await this.$store.dispatch('rvsTroubleshooting/getEFinanceConnectionString', this.selectedLea.number);
        await this.$store.dispatch('rvsTroubleshooting/getEFinanceEmployees', this.selectedLea.number);
        await this.$store.dispatch('rvsTroubleshooting/getEFinanceJobAssignments', this.selectedLea.number);
      }
    }
  },
  mounted() {

  },
  computed: {
    ...mapState('rvsTroubleshooting', ['eFinanceConnectionString', 'fmsEmployees', 'fmsJobAssignments']),
    isSchoolOrDistrict() {
      return this.selectedLea && (this.selectedLea.type == LeaTypes.School || this.selectedLea.type == LeaTypes.District);
    },
    sanitizedEFinanceConnectionString() {
      if (!this.eFinanceConnectionString.split(';').length) {
        return '';
      }
      return this.eFinanceConnectionString.split(';').slice(0, 2).join(';');
    }
  },
  watch: {
    selectedLea: {
      handler: async function() {
        await this.refreshData();
      },
      immediate: true
    }
  }
};
</script>
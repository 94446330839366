<template>
  <b-form>
    <b-form-group id="input-group-1" label="Item Name:" label-for="input-name">
      <b-form-input id="input-name" v-model="itemName" />
    </b-form-group>

    <b-form-group id="input-group-2" label="Category: " label-for="input-category">
      <b-form-select id="input-category" placeholder="Select Category" v-model="category" :options="categories" />
    </b-form-group>

    <b-button variant="primary" :disabled="!isValid" @click="save">
      Save
    </b-button>
    <b-button variant="danger" class="mx-1" @click="$emit('on-cancel')">
      Cancel
    </b-button>
  </b-form>
</template>

<script>
import { Categories } from './transInspectionCategories'

export default {
  name: 'edit-inspection-item',
  data(){
    return{
      inspectionItemId: null,
      itemName: null,
      category: null,
      orderBy: 0,
      categories: Categories
    }
  },
  computed:{
    isValid(){
      return this.itemName && this.category
    }
  },
  methods: {
    save() {
      var item = {
          inspectionItemId: this.inspectionItemId,
          itemName: this.itemName,
          category: this.category,
          isTransportation: true,
          enabled: true,
          orderby: this.orderBy
      }
      if (this.mode == 'add') {
        this.$store
          .dispatch('DPSAFT/Admin/addTransportationInspectionItem', item)
          .then(() => this.$emit('on-save'))
      } 
      else if (this.mode == 'edit') {
        this.$store.dispatch('DPSAFT/Admin/updateTransportationInspectionItem',item )
          .then(() => this.$emit('on-save'))
      }
    }
  },
  props: {
    item: Object,
    mode: String
  },
  watch: {
    item: {
      immediate: true,
      handler(newItem) {
        this.inspectionItemId = newItem.inspectionItemId,
        this.itemName = newItem.itemName,
        this.category = newItem.category,
        this.orderBy = newItem.orderBy
      }
    }
  }
}
</script>
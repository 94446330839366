<template>
  <div>
    <div class="row">
      <div class="col-6">
        <dl class="row mb-0 lead" style="height: 8rem;">
          <dt class="col-4">
            Superintendent:
          </dt>
          <dd class="col-8">
            {{ selectedLea.adminFirstName }} {{ selectedLea.adminLastName }} (<a :href="'mailto:' + selectedLea.adminEmailAddress">{{ selectedLea.adminEmailAddress }}</a>)
          </dd>
          <dt class="col-4">
            <!-- Blank for spacing -->
          </dt>
          <dd class="col-8">
            <!-- Blank for spacing -->
          </dd>
          <dt class="col-4">
            Phone:
          </dt>
          <dd class="col-8">
            <a href="'tel:' + selectedLea.adminPhoneNumber">{{ selectedLea.adminPhoneNumber }}</a>
          </dd>
        </dl>
      </div>
      <div class="col-6">
        <dl class="row mb-0 lead" style="height: 8rem;">
          <dt class="col-3" v-if="currentAssignedUserId">
            Assigned to:
          </dt>
          <dd class="col-9" v-if="currentAssignedUserId">
            <span :title="currentAssignedUserId">{{ currentAssignedUserFullName }}</span>
          </dd>
          <dt class="col-3">
            Last Update:
          </dt>
          <dd class="col-9">
            {{ plan.updatedDate | date }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>

import { PlanMixin } from '../Plan/PlanMixin'

export default {
  data() {
    return {
    }
  },
  mixins: [PlanMixin],
  computed: {
  },
  methods: {
  },
  components: {
  },
}
 
</script>
<template>
  <div>
    <div v-if="selectedLea.type < 3 && selectedLea.number != 'MYLEAS'">
      <h5 v-if="effectiveRosterDate"> 
        Roster Information as of {{ effectiveRosterDate.label }}
      </h5>
      <roster-filter v-model="rosterGradeFilter" :ay="ay" />
      <Promised :promise="loadData">
        <template v-slot:pending>
          <div class="align-items-center justify-content-center" style="height: 100%; width: 100%">
            <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading Student Roster..." />
          </div>
        </template>
        <template v-slot:default>
          <paginated-table v-if="rosterSortOption" small :items="roster" :fields="fields" :sort-by="rosterSortOption.sortBy" :sort-asc="rosterSortOption.ascending" @sort-changed="onSortChanged" :per-page="20">
            <template v-slot:cell(studentName)="data">
              <div class="text-nowrap">
                <router-link :to="{name: studentPageRoute, params: { studentId: data.item.studentId }}">
                  {{ data.value }}
                </router-link>
              </div>
            </template>
            <template #cell(schoolLEA)="data">
              <div>
                <div class="d-inline">
                  <a
                    :href="data.value"
                    class="btn-link"
                    @click.prevent="goToLea(data.value)"
                    :id="data.index + '-' + data.value">
                    {{ data.value }}
                  </a>
                &nbsp;
                  <lea-tooltip
                    :target-selector="data.index + '-' + data.value"
                    :lea-number="data.value"
                    :label="data.label" />
                </div>
              </div>
            </template>
            <template #cell(studentId)="data">
              <router-link :to="{name: studentPageRoute, params: { studentId: data.value, lea: data.item.lea } }">
                <b-badge variant="secondary">
                  {{ data.value }}
                </b-badge>
              </router-link>
            </template>
          </paginated-table>
        </template>
        <template v-slot:rejected="error">
          {{ error }}
        </template>
      </Promised>
    </div>
    <b-alert v-if="selectedLea.type >= 3" show class="text-center" variant="warning">
      Student Roster is available for District and School LEAs. Please change LEA selection to load the roster.
    </b-alert>
    <b-alert v-else-if="selectedLea.number == 'MYLEAS'" show class="text-center" variant="warning">
      Student Roster is only available for School LEAs. Please change LEA selection to load the roster.
    </b-alert>
  </div>
</template>

<script>
import PaginatedTable from '@/components/PaginatedTable.vue'
import RosterFilter from './RosterFilter.vue'
import { Types } from '@/modules/cte/stores/careercoach'
import { mapState, mapActions } from 'vuex'
import { formatDate } from '@/helpers/formatters'
import { Promised } from 'vue-promised'
import CardStateIcon from '@/components/CardStateIcon'
import LeaTooltip from '@/components/LeaTooltip.vue'

export default {
  data() {
    return {
      loadData: null,
      gradeLevelFilter: this.gradeLevel || null,
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState(Types.path, [Types.state.students, Types.state.effectiveRosterDate]),
    
    roster() {
      return this.students.map(s => Object.assign({ selected: false, ...s }))
    },
    rosterGradeFilter: {
      get() {
        return this.$store.state.cte.careercoach.rosterGradeFilter
      },
      set(value) {
        this.$store.commit(Types.mutations.setRosterGradeFilter, value)
      }
    },
    rosterSortOption: {
      get() {
        return this.$store.state.cte.careercoach.rosterSortOption
      },
      set(value) {
        this.$store.commit(Types.mutations.setRosterSortOption, value)
      }
    },
    selectedGrades() {
      return [...new Set(this.selectedStudents.map(sg => sg.grade))].sort()
    },
    fields() {
      let fields = [
        { key: 'studentId', sortable: true },
        { key: 'studentName', sortable: true, sortByFormatted: true, formatter: (v,k,item) => `${item.lastName}, ${item.firstName}` },
        { key: 'schoolLEA', sortable: true },
        { 
          key: 'grade', 
          sortable: true, 
          formatter: (g) => {
            return g==255 ? "G" : g
          }
        },
        {
          key: 'birthDate',
          label: 'DOB',
          sortable: true,
          formatter: val => {
            return formatDate(val, 'short-date')
          }
        },
        { key: 'age', sortable: true },
        { key: 'graduationYear', sortable: true, label: 'Grad Year' },
        { key: 'gender', sortable: true },
      ]
      return this.userContext.isCTEReadOnly ? fields.filter(f => f.key != 'action') : fields
    }
  },
  components: {
    PaginatedTable,
    RosterFilter,
    Promised,
    CardStateIcon,
    LeaTooltip,
  },
  methods: {
    ...mapActions(Types.path, [Types.actions.saveBulkVisit, Types.actions.getGraduationYears]),
    loadRosterData() {
      if (this.selectedLea && this.selectedLea.number && this.selectedLea.type < 3 && this.selectedLea.number != 'MYLEAS') {
        var payload = { lea: this.selectedLea.number }
        if (this.rosterGradeFilter) {
          payload.gradeLevel = this.rosterGradeFilter.grade
          payload.graduationYear = this.rosterGradeFilter.graduationYear
        }
        this.loadData = this.$store.dispatch(Types.actions.getStudents, payload)
      }
    },
    onSortChanged(value){
      this.rosterSortOption = {sortBy: value.sortBy, ascending: !value.sortDesc}
    }
  },
  props: {
    ay: {
      type: Number
    },
    gradeLevel: {
      type: String,
      default: null
    },
    studentPageRoute: {
      type: String,
      default: 'CTE/CareerCoach/Student'
    }
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler() {
        this.loadRosterData()
      }
    },
    rosterGradeFilter() {      
      this.loadRosterData()
    }
  },
  mounted() {
    this.getGraduationYears()
  }
}
</script>
<template>
  <div>
    <purple-excel-export title="UBER" v-if="(selectedLea.type == LeaTypes.District)" />
    <pdf-button v-if="(selectedLea.type == LeaTypes.District)" class="text-danger" />
    <h1>
      <object type="image/svg+xml" data="/Content/icons/LI-Waiver.svg" class="title-icon" />
      <strong>UBER</strong> - <strong>U</strong>nrestricted <strong>B</strong>udget <strong>E</strong>xpenditures and <strong>R</strong>evenue Report
    </h1>
    <h3><lea-display :lea="selectedLea" /></h3>
    <div v-if="selectedLea.type == LeaTypes.District">
      <router-view />
    </div>
    <div v-else class="alert alert-warning">
      UBER is only available at the District level.
    </div>
  </div>
</template>
<script>
import PurpleHomePage from './Pages/PurpleHomePage.vue'
import PurpleExcelExport from './PurpleExcelExport.vue'

import { PageMixin } from '@/components/PageMixin'

import { router } from '@/routes'

router.addRoutes([
  { path: '/Academics/Uber', component: PurpleHomePage, name: 'purple-home' },
])
export default {
  mixins: [PageMixin],
  components: {
    PurpleExcelExport
  },
  computed: {
  },
  methods: {
  },
  props: {
  },
  mounted() {
  }
}
</script>

<style scoped>
.title-icon {
  height: 2.5rem;
  width: 2.5rem;
  top: -0.25em;
  position: relative;
  display: inline-flex;
  align-self: center;
  vertical-align: bottom;
  fill: #6c757d;
}
</style>
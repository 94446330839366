<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>

import { ChartMixin } from '../../ChartMixin'
import { groupBy } from '../../../helpers/globalHelpers'
import { credits } from '../../../helpers/credits'
const groupBySchoolYear = groupBy('schoolYear')

export default {
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'line'
        },
        credits: credits.MSI,
        title: {
          text: 'Average School Board Training Hours'
        },
        legend: {
          enabled: false
        },
        series: [
          {
            name: 'Number of Hours'
          }
        ],
        xAxis: {
          title: {
            text: 'School Year'
          },
          type: 'category'
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Number of Hours'
          }
        },
        tooltip: {
            valueDecimals: 2
        }
      }
    };
  },
  mixins: [ChartMixin],
  computed: {
    schoolboard() {
      return (this.$store.state.overviewModule.schoolboard.schoolboard || [])
    }
  },
  watch: {
    schoolboard(newData) {
      var grouped = groupBySchoolYear(newData)
      var chartData = Object.keys(grouped)
        .map(key => ({
          x: (key % 1990) + 1990,
          y: (grouped[key].reduce((sum, item) => item.hoursOfTraining != null ? sum + item.hoursOfTraining : sum, 0) / grouped[key].length),
        }))
      this.chartOptions.series[0].data = chartData
    }
  },
  methods: {
    excelExportData() {
      return this.defaultExcelExportData()
    },
    dataItems() {
      return this.chartOptions.series[0].data.map(item => {
          return { SchoolYear: item.x, AverageHoursOfTraining: item.y }
      })
    }
  }
}
</script>

<template>
  <div>
    <growth-message />
  </div>
</template>

<script>
import GrowthMessage from './growthMessage.vue'

export default {
  components: {
    GrowthMessage
  }
}
</script>
<template lang="html">
  <loadable-chart-card :load-data="loadData" :enable-data-toggle="false" card-style="" style="border: none" container-class="" card-class="card-body p-1 justify-content-center">
    <div class="chart-container-parent row">
      <div class="lea-growth-chart" v-for="sub in subjects" :key="sub" :class="[subject ? 'col-md-12 px-0' : 'col-md-6  px-0']">
        <lea-growth-trend-chart :subject="sub" :selected-lea="selectedLea" />
      </div>
    </div>
    <div class="pt-3">
      <div>
        <lea-growth-data-table :load-data="loadData" />
      </div>
    </div>  
    <div class="pt-3" v-if="selectedLea && (selectedLea.type == LeaTypes.School || selectedLea.type == LeaTypes.District) && canViewStudentData">
      <div v-if="subjects && subjects.length == 1">
        <student-table :subject="subjects[0]" :show-trends="false" :load-data="loadData" v-if="subjects[0] != 'elp'" />
      </div>
      <div v-else>
        <student-table :show-trends="false" :load-data="loadData" />
      </div>
    </div>  
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../../ChartMixin'
import { LeaTypes } from '../../../../helpers/leaTypes'
import { mapState } from 'vuex'
import LeaGrowthTrendChart from './LeaGrowthTrendChart'
import LeaGrowthDataTable from './LeaGrowthDataTable'
import StudentTable from '../StudentTable'
export default {
  name: 'lea-growth-trend-chart-container',
  data() {
    return {
      LeaTypes: LeaTypes
    }
  },
  components: {
    LeaGrowthTrendChart,
    StudentTable,
    LeaGrowthDataTable
  },
  mixins:[ChartMixin],
  computed: {
    ...mapState('globalModule', ['userContext']),
    canViewStudentData() {
      return !(this.userContext.isRecruitment || this.userContext.isTeacherCenter || this.userContext.isSuperintendentCoop)
    },
    subjects() {
      if (this.subject) return [this.subject]
      return ['ela', 'math', 'science', 'elp']
    }
  },
  props: {
    subject: String,
    grade: String,
    subgroup: String,
    loadData: Promise
  }
}
</script>
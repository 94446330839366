<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" :first-column-sticky="true" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { credits } from '../../../helpers/credits'
import { DemographicSubpopulations } from '../../subpopulations'
import { EnrollmentChartMixin, defaultOptionMatcher } from './EnrollmentChartMixin'
import groupBy from 'lodash.groupby'
import sumBy from 'lodash.sumby'
import { unique } from '../../../helpers/globalHelpers'

export default {
  data() {
    return {
     animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'column',
          plotBackgroundColor: '#f8f9fa',
          plotShadow: false,
        },
        title: {
          text: 'Enrollment By Subpopulation'
        },          
        legend: {
          enabled: true
        },
        credits: credits.InsightsNightlyDataPulls,
        plotOptions: {
          series: {
            minPointLength: 3
          }
        },
        xAxis: {
          labels: {
            enabled: false
          }
        }
      }
    }
  },
  mixins: [ChartMixin, EnrollmentChartMixin],
  watch: {
    enrollmentCounts: {
      immediate: true,
      handler(newData) {
        if (newData && newData.bySped && this.mode == 'current') {
          this.chartOptions.series = []
          this.chartOptions.xAxis.categories = DemographicSubpopulations.map(m => m.name)
          DemographicSubpopulations.forEach(option => {
            const items = newData[option.enrollmentCollection]
            this.chartOptions.series.push({
              name: option.name,
              data: [sumBy(items, defaultOptionMatcher.bind(option))],
              color: option.color,
              visible: option.visible
            })
          })
        }
      }
    },
    metricSnapshots: {
      immediate: true,
      deep: true,
      handler(newData) {
        if (newData && newData.bySped && this.mode == 'trend') {
          this.chartOptions.plotOptions.series = {}
          this.chartOptions.series = []
          this.chartOptions.chart.type = 'line'
          this.chartOptions.xAxis.type = 'datetime'
          this.chartOptions.xAxis.labels.enabled = true
          this.chartOptions.xAxis.labels.format = '{value:%m/%e }'
          this.chartOptions.xAxis.tickPositions = []
          let dateTickPositions = []

          DemographicSubpopulations.forEach(option => {
            var groupedBySnapshotDate = groupBy(this.metricSnapshots[option.enrollmentCollection], (o) => o.snapshotDate)
            let series = {
              name: option.name,
              data: Object.keys(groupedBySnapshotDate).map(snapshotDate => {
                const x = new Date(snapshotDate)
                dateTickPositions.push(Date.parse(snapshotDate))

                return { 
                  x: x, 
                  y: sumBy(groupedBySnapshotDate[snapshotDate], defaultOptionMatcher.bind(option)) 
                }
              }),
              visible: option.visible,
              tooltip: { headerFormat: '' }
            }
            if (option.color) {
              series.color = option.color
            }
            this.chartOptions.series.push(series)
          })
          this.chartOptions.xAxis.tickPositions = dateTickPositions.filter(unique)
        }
      }
    }
  },
  methods: {
    dataItems() {
      var rows = []
      if (this.mode == 'current') {
        DemographicSubpopulations.forEach(option => {
          this.enrollmentCounts[option.enrollmentCollection].filter(c => c.group == option.value).forEach(item => {
            const index = rows.findIndex(r => r.LEA == this.leaFormatter(item.lea))
            let row = index > -1 ? rows[index] : { LEA: this.leaFormatter(item.lea) }
            row[option.name] = item.studentCount

            if (index > -1)
              rows[index] = row
            else 
              rows.push(row)
          })
        })
      } else {
        DemographicSubpopulations.forEach(option => {
          this.metricSnapshots[option.enrollmentCollection].filter(defaultOptionMatcher.bind(option)).forEach(item => {
            const index = rows.findIndex(r => r.LEA == this.leaFormatter(item.lea) && r.Date == new Date(item.snapshotDate).toLocaleDateString())
            let row = index > -1 ? rows[index] : { LEA: this.leaFormatter(item.lea), Date: new Date(item.snapshotDate).toLocaleDateString() }
            row[option.name] = item.studentCount

            if (index > -1)
              rows[index] = row
            else 
              rows.push(row)
          })
        })
      }
      return rows
    }
  }
}
</script>
<template>
  <div>
    <h4>
      Add Status Record
    </h4>
    <div class="form">
      <div class="form-group">
        <label>Select LEA</label>
        <lea-selector v-model="lea" :source-leas="leas" />
      </div>
    </div>
    <div class="form-group">
      <label>Grade Range</label>
      <b-form-input type="text" readonly v-model="gradeRange" />
    </div>
    <div class="form-group">
      <label>Select Status Code</label>
      <b-form-select v-model="code" :options="codes" />
    </div>
    <div class="form-group text-right">
      <b-button variant="primary" class="mr-2" @click="save">
        Ok
      </b-button>
      <b-button variant="secondary" @click="$router.go(-1)">
        Cancel
      </b-button>
    </div>
  </div>
</template>

<script>
import LeaSelector from '../../../shared/LeaSelector'
import { AccountabilityStatus } from '../../leaAssignment'
import { mapState } from 'vuex'

export default {
  name: 'add-status-record',
  data() {
    return {
      lea: null,
      code: null,
      codes: [{ value: null, text: 'Please select an Accountability Code'}, { value: '8A', text: `8A - ${AccountabilityStatus.get('8A')}` }, {code: '8B', text: `8B - ${AccountabilityStatus.get('8B')}` }]
    }
  },
  computed: {
    ...mapState('globalModule', ['leaContext']),

    leas() {
      var leas = new Array()
      Object.keys(this.leaContext.LeaDictionary).forEach(k => {
        var i = this.leaContext.LeaDictionary[k]

        if (i.type === 1) {
          leas.push({ leaNumber: i.number, name: i.name })
        }
      })

      return leas
    },
    gradeRange() {
      if (this.lea) {
        return this.leaContext.LeaDictionary[this.lea].gradesServed
      }

      return null
    }
  },

  methods: {
    save() {
      return this.$store
        .dispatch('toolsModule/leaAssignment/saveAccountabilityStatusRecord', {
          lea: this.lea,
          code: this.code,
          gradeRange: this.gradeRange
        })
        .then(() => this.$router.go(-1))
    }
  },
  components: {
    LeaSelector
  }
}
</script>
<template>
  <div>
    <div>
      <public-plan-widget />
    </div>
    <!-- Anticipating more widgets here at some point -->
  </div>
</template>
<script>
import PublicPlanWidget from './Public/PublicPlanWidget.vue'
import { mapState } from 'vuex'
import { Types } from '@/modules/esa/stores/'

export default {
  components: {
    PublicPlanWidget,
  },
  computed: {
    ...mapState(Types.path, [Types.state.plan])
  }
}
</script>
<template>
  <div v-if="currentStudent.studentInformation">
    <h2 class="caption">
      Student Information
    </h2>
    <b-form>
      <b-form-row>
        <b-form-group label="Student Name" class="col-md-4 col-12">
          <b-form-input readonly :value="currentStudent.fullName()" />
        </b-form-group>
        <b-form-group label="Preferred Name" class="col-md-4 col-12">
          <b-form-input readonly placeholder="Preferred Name" v-model="local.preferredName" />
        </b-form-group>
        <b-form-group label="Triand ID" class="col-md-2 col-12">
          <b-form-input readonly v-model="currentStudent.studentId" />
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group label="Current Grade" class="col-sm-2 col-4">
          <b-form-input readonly v-model="currentStudent.grade" />
        </b-form-group>
        <!-- <b-form-group label="DOB" class="col-sm-2 col-6">
          <b-form-input readonly :value="formatDate(currentStudent.birthDate, 'short-date')" />
        </b-form-group> -->
        <b-form-group label="Age" class="col-sm-2 col-4">
          <b-form-input readonly v-model="currentStudent.age" />
        </b-form-group>
        <b-form-group label="Graduation Year" class="col-sm-2 col-4">
          <b-form-input readonly v-model="currentStudent.graduationYear" />
        </b-form-group>
        <b-form-group label="Gender" class="col-sm-2 col-4">
          <b-form-select disabled v-model="currentStudent.gender" :options="staticMetadata.information.gender" />
        </b-form-group>
      </b-form-row>
    </b-form>
    
    <h2 class="caption">
      School Information
    </h2>
    <b-form-row v-if="school">
      <b-form-group label="District LEA" class="col-md-2 col-sm-6 col-12">
        <b-form-input readonly :value="district.number" />
      </b-form-group>
      <b-form-group label="District Name" class="col-md-10 col-sm-6 col-12">
        <b-form-input readonly :value="district.name" />
      </b-form-group>
      <b-form-group label="School LEA" class="col-md-2 col-sm-6 col-12">
        <b-form-input readonly :value="school.number" />
      </b-form-group>
      <b-form-group label="School Name" class="col-md-10 col-sm-6 col-12">
        <b-form-input readonly :value="school.name" />
      </b-form-group>
    </b-form-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { StudentComponentMixin } from './StudentComponentMixin'
import { formatDate, formatYN, formatPhone } from '@/helpers/formatters'
import { MealStatuses } from '@/modules/cte/components/careerCoach/enums'

export default {
  mixins: [StudentComponentMixin],
  computed: {
    ...mapGetters('globalModule', ['counties']),
    school() {
      return this.leaDictionary[this.currentStudent.schoolLEA]
    },
    district() {
      return this.school.parentLea
    },
    countyOptions() {
      return this.counties.map(o=>{ return { value: o.number, text: o.name } })
    },
    gradYearOptions() {
      var opts=[]
      for(var i = this.operativeAY - 3; i < this.operativeAY + 5; i++) { 
        opts.push(i) 
      }
      return opts

    },
    operativeAY() {
      return this.ay+2000
    }
  },
  data() {
    return {
      formatDate,
      formatYN,
      MealStatuses,
      formatPhone
    }
  },
  props: {
    ay: {
      type: Number,
      required: true,
      default: 0
    }
  },
  components: {
  }
}
</script>
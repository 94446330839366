<template>
  <div>
    <p class="font-weight-bold mt-3">
      {{ question.text }}
    </p>

    <ul class="list-group list-group-flush" v-if="!editMode">
      <li class="list-group-item bg-transparent borderless" v-for="c in question.choices" :key="c">
        <div v-if="getValue(c) == 1">
          <pretty-checkbox :value="getValue(c)" /><span class="font-italic"> {{ c }}</span>
        </div>
      </li>
      <li class="list-group-item bg-transparent borderless" v-if="noValuesSelected">
        <span class="font-italic"> No answer</span>
      </li>
    </ul>
    
    <b-form-tags v-model="$v.survey[question.id].$model" size="lg" add-on-change no-outer-focus class="mb-2" v-if="editMode" :limit="question.limit">
      <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
        <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
          <li v-for="tag in tags" :key="tag" class="list-inline-item">
            <b-form-tag
              @remove="removeTag(tag)"
              :title="tag"
              :disabled="disabled"
              variant="info"
            >
              {{ tag }}
            </b-form-tag>
          </li>
        </ul>
        <b-form-select
          v-bind="inputAttrs"
          v-on="inputHandlers"
          :disabled="disabled || availableOptions.length === 0"
          :options="availableOptions"
        >
          <template v-slot:first>
            <!-- This is required to prevent bugs with Safari -->
            <option disabled value="">
              {{ question.tagPrompt }}
            </option>
          </template>
        </b-form-select>
      </template>
    </b-form-tags>
  </div>
</template>

<script>
import PrettyCheckbox from '../../PrettyCheckbox'

/// Display the question and a tags selector for multiple selections.

export default {
  inject: ['$v'],
  name: 'question-tag-selection',
  computed: {
    options() {
      return this.question.choices.map(c => { return { text: c, value: c }})
    },
    value() {
      return this.$v.survey[this.question.id].$model
    },
    availableOptions() {
      return this.options.filter(opt => (this.$v.survey[this.question.id].$model || []).indexOf(opt) === -1)
    },
    noValuesSelected() {
      return (this.value || []).length < 1
    }
  },
  methods: {
    getValue(c) {
      return (this.value || []).includes(c)
    }
  },
  props: {
    question: {
      type: Object,
      default: () => {}
    },
    editMode: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    PrettyCheckbox
  }
}
</script>
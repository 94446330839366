import Vue from 'vue'
import TableControlWidget from './shared/TableControlWidget.vue'
import TablePaginationWidget from './shared/TablePaginationWidget.vue'
import LeaTooltip from './LeaTooltip.vue'
import AelsCaseIdLink from './AelsCaseIdLink.vue'

const TableMixin = Vue.extend({
  components: {
    TableControlWidget,
    TablePaginationWidget,
    LeaTooltip,
    AelsCaseIdLink
  },
  data() {
    return {
      tableOptions: {
        filter: null,
        pageLength: 50,
        currentPage: 1,
        totalRowCount: 0,
        filteredRowCount: 0
      }
    }
  },
  methods: {
    goToLea(leaNumber) {
      const lea = this.$store.getters['globalModule/leaDictionary'][leaNumber]
      window.scrollTo(0, 0)
      this.$store.dispatch('globalModule/changeSelectedLea', lea)
    }
  }
})

export {
  TableMixin
}
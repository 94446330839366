<template>
  <div>
    <b-alert :show="inactiveRelationships > 0" variant="warning">
      There are {{ inactiveRelationships }} relationships for Clusters, Pathways, and Programs of Study that are no longer active for this Academic Year. These relationships should be deleted
      when you are certain that the Cluster, Pathway, and Programs of Study lists are correct. 
    </b-alert>
    <paginated-table :items="listItems" :fields="fields" small>
      <template v-slot:cell(activeForAY)="data">
        <pretty-checkbox :value="data.value" false-class="fas fa-times-circle text-danger" />
      </template>
      <template v-slot:head(action)>
        <b-button variant="primary" @click="$emit('on-add-initiated')">
          <i class="fa fa-plus fa-fw" /> Add Option
        </b-button>
      </template>
      <template v-slot:cell(action)="data">
        <b-link @click="deleteRelationship(data.item)">
          <i class="fas fa-fw fa-times text-danger" title="Delete Item" />
        </b-link>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import PaginatedTable from '@/components/PaginatedTable'
import { Types as CommonTypes } from '@/modules/cte/stores'
import PrettyCheckbox from '@/components/PrettyCheckbox.vue'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      fields:[
        {key: 'id',  },
        {key: 'activeForAY', sortable: true, class: 'text-center' },
        {key: 'clusterName', sortable: true },
        {key: 'pathwayName', sortable: true },
        {key: 'programOfStudyName', sortable: true },
        {key: 'action', class: "text-right" }
      ]
    }
  },
  methods: {
    deleteRelationship(item) {
      if (confirm('Are you sure you want to remove this relationship?')) {
        this.$store.dispatch(CommonTypes.actions.deleteOAItemRelationship, {
          id: item.id
        })
      }
    }
  },
  props: {
    filter: {
      type: Object
    }
  },
  computed: {
    ...mapGetters(CommonTypes.path, [CommonTypes.getters.oaMetadataRelationshipsSynchronized]),
    inactiveRelationships() {
      return this.oaMetadataRelationshipsSynchronized(Number(this.$route.params.ay)).filter(f=>!f.activeForAY).length
    },
    listItems() {
      return (this.oaMetadataRelationshipsSynchronized(Number(this.$route.params.ay)) || []).filter(i => {
        return (
          (i.occupationalAreaId == this.filter.area || this.filter.area == null) &&
          (i.clusterId == this.filter.cluster || this.filter.cluster == null) &&
          (i.pathwayId == this.filter.pathway || this.filter.pathway == null) &&
          (i.programOfStudyId == this.filter.pos || this.filter.pos == null)
        )
      })
    }
  },
  components: {
    PaginatedTable,
    PrettyCheckbox
  }
}
</script>
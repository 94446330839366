<template>
  <b-form-checkbox switch inline v-model="showOnlyViolations">
    Show only potential {{ label }} violations
  </b-form-checkbox>
</template>

<script>
export default {
  props: {
    label: {
      type: String
    }
  },
  computed: {
    showOnlyViolations: {
      get() {
        return this.$store.state.scheduleModule.showOnlyViolations
      },
      set(value) {
        this.$store.commit('scheduleModule/setShowOnlyViolations', value)
      }
    }
  },
  methods: {
    setShowOnlyViolations(value) {
      this.$store.commit('scheduleModule/setShowOnlyViolations', value)
    }
  }
}
</script>
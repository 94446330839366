<template>
  <div>
    <h3 class="p-2 bg-dark mx-2 mt-1 rounded text-light font-weight-bold">
      Career Coach
    </h3>
    <hr class="my-1">
    <student-search @student-selected="openStudent" />
    <hr class="mt-1">
    <div class="sidebar-scroll">
      <ul class="nav nav-pills flex-column ml-2 mr-2">
        <li class="nav-item">
          <router-link :to="{name: 'CTE/CareerCoach/Dashboard'}" class="nav-link" active-class="active">
            <i class="fas fa-tachometer-alt fa-fw mr-1" />Dashboard
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{name: 'CTE/CareerCoach/StudentRoster'}" class="nav-link" active-class="active">
            <i class="far fa-id-card fa-fw mr-1" />Student Roster
          </router-link>
        </li>
        <li class="nav-item" v-if="!isCTECoordinator">
          <router-link :to="{name: 'CTE/CareerCoach/Visits'}" class="nav-link" active-class="active">
            <i class="fas fa-handshake fa-fw mr-1" />Group Visits
          </router-link>
        </li>
        <li class="nav-item" v-if="!isCTECoordinator">
          <router-link :to="{name: 'CTE/CareerCoach/Narratives'}" class="nav-link" active-class="active">
            <i class="fas fa-business-time fa-fw mr-1" />Narratives
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{name: 'CTE/CareerCoach/Reports'}" class="nav-link" active-class="active">
            <i class="fas fa-chart-bar fa-fw mr-1" />Reports
          </router-link>
        </li>
        <li class="nav-item" v-if="isAdmin || isCTECoordinator">
          <router-link :to="{name: 'CTE/CareerCoach/Admin/Users'}" class="nav-link">
            <i class="fas fa-users-cog fa-fw mr-1" />User Administration
          </router-link>
        </li>
        <li class="nav-item" v-if="isAdmin">
          <router-link :to="{name: 'CTE/CareerCoach/Admin'}" class="nav-link">
            <i class="fas fa-wrench fa-fw mr-1" />Module Administration
          </router-link>
        </li>
        <li class="nav-item" v-if="false">
          <router-link :to="{name: 'CTE/CareerCoach/Admin/CoordinatorInvite'}" class="nav-link">
            <i class="fas fa-users-cog fa-fw mr-1" />Invite User
          </router-link>          
        </li>
      </ul>
    </div>

    <ul class="nav nav-pills flex-column ml-2 mr-2" v-if="false">
      <li class="nav-item">
        <router-link :to="{name: 'CTE/CareerCoach/User/Certifications'}" class="nav-link">
          Certification Administration
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{name: 'CTE/CareerCoach/User/PostHighSchoolPlans'}" class="nav-link">
          Post High School Plan Option Administration
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{path: '/cte/career-coach/admin/standard-occupational-classification'}" class="nav-link">
          Standard Occupational Classification Administration
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{name: 'CTE/CareerCoach/Admin/WorkshopsAndActivities'}" class="nav-link">
          Workshop and Activity Administration
        </router-link>
      </li>
    </ul>
    <hr>
    <h5 class="p-2 bg-dark mx-2 mt-4 mb-0 rounded text-light small">
      <i class="fa fa-question-circle fa-fw mr-1" /> Resources
    </h5>
    <ul class="nav nav-pills flex-column ml-2 mr-2">
      <li class="nav-item">
        <a href="/content/cte/career-coach/Insights - CTE - Career Coach User Guide.pdf" class="nav-link small" download>
          <i class="fa fa-book fa-fw mr-1" />Career Coach User Guide
        </a>
      </li>     
      <li class="nav-item" v-if="isAdmin">
        <a href="/content/cte/career-coach/Insights - CTE - Career Coach Administrators Guide.pdf" class="nav-link small" download>
          <i class="fa fa-book fa-fw mr-1" />Career Coach Administrator User Guide
        </a>
      </li>     
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { CareerCoachRoles } from '@/modules/cte/components/common/Roles'
 
import StudentSearch from './widgets/StudentSearch.vue'
export default {
  components: { StudentSearch },
  computed: {
    ...mapState('globalModule', ['userContext']),
    isAdmin() {
      return (this.userContext.roles || []).includes(CareerCoachRoles['Admin'])||
        (this.userContext.roles || []).includes('Insights_System_Administrators')
    },
    isCTECoordinator() {
      return this.userContext.isCTECoordinator
    },
    academicYear() {
      return `${this.ay + 1999} / ${this.ay + 2000}`
    }
  },
  methods: {
    openStudent(studentSelection) {
      console.log(this.ay)
      if (studentSelection) {
        this.$router.push({name: 'CTE/CareerCoach/StudentPage', params: {studentId: studentSelection.code}, query: {lea: studentSelection.lea }})
      }
    },
  }
}
</script>

<template>
  <div>
    <router-view />
  </div>
</template>


<script>
import DigitalLearningAdminListPage from './DigitalLearningAdminListPage.vue'
import DigitalLearningWaiverReviewPage from './DigitalLearningWaiverReviewPage.vue'
import { router } from '@/routes'

router.addRoutes([
  { path: '/Surveys/DigitalLearning/Admin', component: DigitalLearningAdminListPage, name: 'admin-waiver-request-list' },
  { path: '/Surveys/DigitalLearning/Admin/Review', component: DigitalLearningWaiverReviewPage, name: 'admin-waiver-request-review' },
])

export default {
  data() {
    return {
    }
  } 
}

</script>

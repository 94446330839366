<template>
  <div>
    <div class="card-body p-0 bg-light">
      <div class="row no-flex-print">
        <div class="col-6 print-full-width">
          <student-count-percent-chart :load-data="loadData" :selected-lea="selectedLea" />
        </div>
        <!--
        <div class="col-4 print-full-width">
          <virtual-frl-percent-chart :load-data="virtualStudentData" :selected-lea="selectedLea" />
        </div>
        -->
        <div class="col-6 print-full-width page-break-before">
          <student-count-by-grade-chart :load-data="loadData" :selected-lea="selectedLea" />
        </div>
      </div>
      <div class="row no-flex-print">
        <div class="col-4 print-full-width page-break-before">
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="onsiteSum"
            :breakdown="onsiteByLea" 
            chart-title="On-site Students By Race"
            :sub-populations="Races" />
        </div>
        <div class="col-4 print-full-width">
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="virtualSum"
            :breakdown="virtualByLea" 
            chart-title="Virtual Students By Race"
            :sub-populations="Races" />
        </div>
        <div class="col-4 print-full-width page-break-before">
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="hybridSum"
            :breakdown="hybridByLea" 
            chart-title="Hybrid Students By Race"
            :sub-populations="Races" />
        </div>
      </div>
      <div class="row no-flex-print">
        <div class="col-4 print-full-width"> 
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="onsiteSum"
            :breakdown="onsiteByLea" 
            chart-title="On-site Students By Subpopulation"
            :sub-populations="Subpopulations" />
        </div>
        <div class="col-4 print-full-width page-break-before">
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="virtualSum"
            :breakdown="virtualByLea" 
            chart-title="Virtual Students By Subpopulation"
            :sub-populations="Subpopulations" />
        </div>
        <div class="col-4 print-full-width">
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="hybridSum"
            :breakdown="hybridByLea" 
            chart-title="Hybrid Students By Subpopulation"
            :sub-populations="Subpopulations" />
        </div>
      </div>
      <div class="row no-flex-print">
        <div class="col-4 print-full-width page-break-before">
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="onsiteSum"
            :breakdown="onsiteByLea" 
            chart-title="% On-site Students By Race"
            tooltip-suffix="%"
            :sub-populations="RacePercentages" />
        </div>
        <div class="col-4 print-full-width">
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="virtualSum" 
            :breakdown="virtualByLea" 
            chart-title="% Virtual Students By Race"
            tooltip-suffix="%"
            :sub-populations="RacePercentages" />
        </div>
        <div class="col-4 print-full-width page-break-before">
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="hybridSum"
            :breakdown="hybridByLea" 
            chart-title="% Hybrid Students By Race"
            tooltip-suffix="%"
            :sub-populations="RacePercentages" />
        </div>
      </div>
      <div class="row no-flex-print">
        <div class="col-4 print-full-width"> 
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="onsiteSum"
            :breakdown="onsiteByLea" 
            chart-title="% On-site Students By Subpopulation"
            tooltip-suffix="%"
            :sub-populations="SubpopulationPercentages" />
        </div>
        <div class="col-4 print-full-width page-break-before">
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="virtualSum"
            :breakdown="virtualByLea" 
            chart-title="% Virtual Students By Subpopulation"
            tooltip-suffix="%"
            :sub-populations="SubpopulationPercentages" />
        </div>
        <div class="col-4 print-full-width">
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="hybridSum" 
            :breakdown="hybridByLea" 
            chart-title="% Hybrid Students By Subpopulation"
            tooltip-suffix="%"
            :sub-populations="SubpopulationPercentages" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StudentCountPercentChart from './StudentCountPercentChart'
import StudentCountByGradeChart from './StudentCountByGradeChart'
import InstructionalOptionsChart from './InstructionalOptionsChart.vue'

import { mapState } from 'vuex'
import { Types } from '../../../stores/onDemandModule'
import sumBy from 'lodash.sumby'
import groupBy from 'lodash.groupby'
import { LeaTypes } from '@/helpers/leaTypes'
import { Races, Subpopulations, AllDemographics, calculatePercentages, RacePercentages, SubpopulationPercentages } from './instructionalOptionDemographics'

export default {
  data() {
    return {
      loadData: null,
      virtualStudentData: null,
      Races,
      Subpopulations,
      RacePercentages,
      SubpopulationPercentages,
    }
  },
  components: {
    StudentCountPercentChart,
    StudentCountByGradeChart,
    //VirtualFrlPercentChart
    InstructionalOptionsChart,
  },
  computed: {
    ...mapState(Types.path, [Types.state.instructionalOptionsDemographics]),
    onsiteSum() {
      return this.computeSum(this.instructionalOptionsDemographics.onsite)
    },
    virtualSum() {
      return this.computeSum(this.instructionalOptionsDemographics.virtual)
    },
    hybridSum() {
      return this.computeSum(this.instructionalOptionsDemographics.hybrid)
    },
    onsiteByLea() {
      return this.computeLeaBreakdown(this.instructionalOptionsDemographics.onsite)
    },
    virtualByLea() {
      return this.computeLeaBreakdown(this.instructionalOptionsDemographics.virtual)
    },
    hybridByLea() {
      return this.computeLeaBreakdown(this.instructionalOptionsDemographics.hybrid)
    },
  },
  methods: {
    computeLeaBreakdown(instructionalOptions) {
      const grouped = this.selectedLea.type == LeaTypes.State ? groupBy(instructionalOptions, 'districtLEA') : groupBy(instructionalOptions, 'lea')
      const breakDownByLea = Object.keys(grouped).map(lea => {
        return { 
          LEA: this.leaFormatter(lea),
          ...this.computeSum(grouped[lea])[0]
        }
      })
      return breakDownByLea
    },
    computeSum(instructionalOptions) {
      var sum = {
        fiscalYear: (instructionalOptions[0] || {}).fiscalYear
      }
      AllDemographics.map(demo => {
        sum[demo.field] = sumBy(instructionalOptions, demo.field)
      })
      sum.total = sumBy(instructionalOptions, 'total')
      return [sum].map(calculatePercentages)
    }
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if (newLea && newLea.number) {
          const payload = { lea: newLea.number }
          this.loadData = Promise.all(
            [
              this.$store.dispatch(Types.actions.getInstructionalOptions, payload), 
              this.$store.dispatch(Types.actions.getInstructionalOptionsDemographics, payload)
            ])
          this.virtualStudentData = this.$store.dispatch(Types.actions.getVirtualStudentsByMealStatus, payload)
        }
      }
    }
  }
}
</script>


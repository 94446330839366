import Vue from 'vue'
import { PageMixin } from '../../PageMixin'
import EmployeeLeaveByDaysChart from './EmployeeLeaveByDaysChart.vue'
import EmployeeLeaveByRateChart from './EmployeeLeaveDaysBreakdownChart.vue'
import { EmployeeLeaveViewSelector } from './EmployeeLeaveViewSelector'
import EmployeeLeaveRateChart from './EmployeeLeaveRateChart.vue'
import EmployeeLeaveDaysWidget from './EmployeeLeaveDaysWidget.vue'

const EmployeeLeavePage = Vue.extend({
    name: 'employee-leave-page',
    mixins: [PageMixin],
    mounted() {
        this.viewType = this.$el.getAttribute('data-view-type');
        this.leaveType = this.$el.getAttribute('data-leave-type');
        this.stateView = this.$el.getAttribute('data-state-view');
    },
    data() {
        return {
            filteredRowCount: null,
            isLoading: false,
            loadData: null,
            viewType: '0',
            leaveType: 'SUM',
            stateView: '0'
        }
    },
    components: {
        EmployeeLeaveByDaysChart,
        EmployeeLeaveByRateChart,
        EmployeeLeaveViewSelector,
        EmployeeLeaveRateChart,
        EmployeeLeaveDaysWidget
    },
    computed: {
        employeeLeave() {
            return this.$store.state.hcptModule.employeeLeave
        }
    },
    methods: {
        viewTypeSelected(viewType) {
            this.viewType = viewType
            this.update()
        },
        leaveTypeSelected(leaveType) {
            this.leaveType = leaveType
            this.update()
        },
        stateViewSelected(stateViewType) {
            this.stateView = stateViewType
            this.update()
        },
        update() {
            this.loadData = Promise.all([
                this.$store.dispatch('hcptModule/getEmployeeLeave', {
                    lea: this.selectedLea.number,
                    leaveViewType: this.viewType,
                    leaveType: this.leaveType,
                    stateView: this.stateView
                }),
                this.$store.dispatch('hcptModule/getEmployeeLeaveDays',{
                  lea: this.selectedLea.number,
                  stateView: this.stateView || 0,
                  leaveType: this.leaveType
                })
            ])
        },
        prepareExportPayload() {
            //debugger;
            return {
                viewType: this.viewType,
                leaveType: this.leaveType,
                stateView: this.stateView
            };
        }
    },
    watch: {
        selectedLea: {
            immediate: true,
            handler(newLea) {
                if (newLea && newLea.number)
                    this.update()
            }
        }
    }
})
export { EmployeeLeavePage }
<template>
  <b-card class="mb-2 outer-card">
    <div v-if="leaType == LeaTypes.School || leaType == LeaTypes.District">
      <div class="row">
        <div class="col-md-12">
          <growth-filter />
        </div>
      </div>
      <div v-if="subject && grade && teacher">
        <h5 class="text-center">
          CLASSROOM OF {{ teacherName }} {{ subjects[0].text.toUpperCase() }} GRADE {{ grade }} GROWTH AND ACHIEVEMENT
        </h5>
        <student-table
          :show-trends="false"
          :load-data="loadData"
          :per-page="10"
        />
      </div>
      <div class="alert alert-warning" v-else-if="subject">
        Please select a subject, grade level, and teacher.
      </div>
    </div>
    <div v-else class="alert alert-warning">
      No data available for selected LEA. Please select a school to view report
    </div>
  </b-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { PageMixin } from '../../../PageMixin'
import StudentTable from '../StudentTable.vue'
import GrowthFilter from '../GrowthFilter'
import { Subjects } from '../FilterOptions'

export default {
  name: 'student-data',
  data() {
    return {
      loadData: null
    }
  },
  components: {
    StudentTable,
    GrowthFilter
  },
  mixins: [PageMixin],
  computed: {
    ...mapState('academicsModule/teacherGrowth', [
      'tab',
      'grade',
      'subject',
      'teacher'
    ]),
    ...mapGetters('academicsModule/teacherGrowth', [
      'teacherName'
    ]),
    subjects() {
      var subjects = Subjects.filter(s => s.value != null && s.value != 'elp')
      if (this.subject) {
        subjects = Subjects.filter(s => {
          return s.value == this.subject
        })
      }
      return subjects
    },
    fiscalYear() {
      return this.$store.state.academicsModule.teacherGrowth.fiscalYear
    }
  },
  methods: {
    loadGrowth(lea) {
      this.loadData = Promise.all([
        this.$store.dispatch('academicsModule/teacherGrowth/getStudentGrowthCurrent', {
          lea: lea.number
        }),
        this.$store.dispatch('academicsModule/teacherGrowth/getTeacherGrowthSummary', {
          lea: lea.number
        }),
        this.$store.dispatch('academicsModule/teacherGrowth/getLeaGrowthSummary', {
          lea: lea.number
        })
      ])
    }
  },
  watch: {
    teacher() {
      this.loadGrowth(this.selectedLea)
    }
  }
}
</script>
<template>
  <div>
    <step-container :academic-year="Number($route.params.ay)" task-code="CTM" title="Perkins V Cost Type Maintenance" :hide-step-status="hideStepStatus">
      <div>
        <router-view />
      </div>
    </step-container>    
  </div>
</template>

<script>

import { mapState } from 'vuex'
import { Types } from '@/modules/cte/stores/perkinsv'
import { PageMixin } from '@/components/PageMixin'

import StepContainer from '../StepContainer.vue'

export default {
  data(){
    return {
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.name === 'Admin/CostTypes') next({ name: 'CostTypes/List' })
    else 
    next()
  },
  mixins: [PageMixin],
  components:{
    StepContainer
  },
  computed: {
    ...mapState(Types.path, [Types.state.costTypes]),
    hideStepStatus() {
      return this.$route.name != "CostTypes/List"
    }
  },
  mounted(){
    this.$store.dispatch(Types.actions.getCostTypes, {schoolYear: Number(this.$route.params.ay), includeDisabled: true})
  }
}
</script>
<template>
  <div>
    <section v-if="selectedLea.name">
      <a class="anchor" id="overview" />
      <div>
        <pdf-button />
        <excel-button title="Overview" />
        <h1>LEA Overview</h1>
        <h2><lea-display :lea="selectedLea" /></h2>

        <div class="row" v-if="selectedLea.type != LeaTypes.State">
          <div class="col-sm-6">
            <dl class="row">                
              <dt v-if="selectedLea.type == LeaTypes.School" class="col-sm-4">
                District
              </dt>
              <dt v-if="selectedLea.type == LeaTypes.District" class="col-sm-4">
                Coop
              </dt>
              <dd v-if="![LeaTypes.State, LeaTypes.Coop, LeaTypes.County].includes(selectedLea.type)" class="col-sm-8">
                <lea-icon :lea="selectedLea.parentLea" />
                <button class="btn btn-link p-0" @click="goToLea(selectedLea.parentLea.number)">
                  {{ selectedLea.parentLea.name }} ({{ selectedLea.parentLea.number }})
                </button>
              </dd>

              <dt v-if="selectedLea.county" class="col-sm-4">
                County
              </dt>
              <dd v-if="selectedLea.county" class="col-sm-8">
                <lea-icon :lea="{ type: LeaTypes.County }" />
                <button class="btn btn-link p-0" @click="goToLea(selectedLea.countyCode)">
                  {{ selectedLea.county }} ({{ selectedLea.countyCode }})
                </button>
              </dd>

              <dt v-if="selectedLea.districtsServed" class="col-sm-4 text-nowrap">
                # Districts Served
              </dt>
              <dd v-if="selectedLea.districtsServed" class="col-sm-8">
                <a href="#" v-scroll-to="'#districts'">{{ selectedLea.districtsServed }}</a>
              </dd>

              <dt v-if="selectedLea.schoolsServed && selectedLea.type != LeaTypes.State" class="col-sm-4 text-nowrap">
                # Schools Served
              </dt>
              <dd v-if="selectedLea.schoolsServed && selectedLea.type != LeaTypes.State" class="col-sm-8">
                <a href="#" v-scroll-to="'#schools'" v-if="selectedLea.type == LeaTypes.District">{{ selectedLea.schoolsServed }}</a>
                <span v-else>{{ selectedLea.schoolsServed }}</span>
              </dd>

              <dt v-if="selectedLea.adminLastName" class="col-sm-4">
                {{ selectedLea.type == 1 ? "Principal" : "Superintendent" }}
              </dt>
              <dd v-if="selectedLea.adminLastName" class="col-sm-8">
                {{ selectedLea.adminFirstName }} {{ selectedLea.adminLastName }} (<a :href="'mailto:' + selectedLea.adminEmailAddress">{{ selectedLea.adminEmailAddress }}</a>)
              </dd>

              <dt v-if="selectedLea.letterGrade" class="col-sm-4">
                Letter Grade
              </dt>
              <dd v-if="selectedLea.letterGrade" class="col-sm-8">
                <letter-grade-badge :lea="selectedLea" />
              </dd>

              <dt v-if="selectedLea.gradesServed" class="col-sm-4">
                Grades Served
              </dt>
              <dd v-if="selectedLea.gradesServed" class="col-sm-8">
                {{ selectedLea.gradesServed }}
              </dd>

              <dt v-if="selectedLea.type == LeaTypes.School" class="col-sm-4">
                Bell Schedule
              </dt>
              <dd v-if="selectedLea.type == LeaTypes.School" class="col-sm-8">
                {{ selectedLea.start || selectedLea.startTime }} - {{ selectedLea.end || selectedLea.endTime }}
              </dd>
            </dl>   
          </div>
          <div class="col-sm-6">
            <dl class="row">
              <dt v-if="selectedLea.type == LeaTypes.District" class="col-sm-4">
                <custom-tooltip element-id="schoolchoice-label" content="Indicates whether the district participates in the School Choice program. - Data Source: eSchoolPLUS | REG_USER_DISTRICT" label="School Choice" />
              </dt>
              <dd v-if="selectedLea.type == LeaTypes.District" class="col-sm-8">
                <span v-if="selectedLea.schoolChoice" class="fa fa-check text-success" />
                <span v-else class="fa fa-times text-danger" />
              </dd>
              <dt v-if="selectedLea.type == LeaTypes.District" class="col-sm-4">
                <custom-tooltip element-id="transfer-label" content="Indicates whether the district participates in transfer agreements with other districts - Data Source: SIS LEA Profile" label="Transfer Agreement" /> 
              </dt>
              <dd v-if="selectedLea.type == LeaTypes.District" class="col-sm-8">
                <span v-if="selectedLea.xferAgree || selectedLea.transferAgreement" class="fa fa-check text-success" />
                <span v-else class="fa fa-times text-danger" />
              </dd>

              <dt v-if="selectedLea.type == LeaTypes.School" class="col-sm-4">
                <custom-tooltip element-id="federalstatus-label" content="
                <strong>SCHOOLWIDE</strong> (A school that has been approved by the ADE to provide Title I services on a school wide rather than a targeted
                assistance basis. <br />A school must have at least 40% of their students identified as low income.)
                <br /><strong>TARGET ASSISTED</strong> (A school that provides Title I services to identified students eligible for the services.) 
                <br /><strong>NOT APPLICABLE</strong> (Not Applicable) - Data Source: eSchoolPLUS | Table: REG_USER_BUILDING" label="Federal Program Status" /> 
              </dt>
              <dd v-if="selectedLea.type == LeaTypes.School" class="col-sm-8">
                {{ displayProgramStatus(selectedLea.fedProgStatus || selectedLea.federalProgramStatus) }}
              </dd>
              <dt v-if="selectedLea.type == LeaTypes.School" class="col-sm-4">
                <custom-tooltip element-id="titlei-label" content="This school wishes to participate in Title I Federal Programs - Data Source: SIS LEA Profile" label="Receiving Title I Status" /> 
              </dt>
              <dd v-if="selectedLea.type == LeaTypes.School" class="col-sm-8">
                <span v-if="selectedLea.titleI" class="fa fa-check text-success" />
                <span v-else class="fa fa-times text-danger" />
              </dd>

              <dt v-if="selectedLea.type == LeaTypes.School || selectedLea.type == LeaTypes.District" class="col-sm-4">
                Address
              </dt>
              <dd v-if="selectedLea.type == LeaTypes.School || selectedLea.type == LeaTypes.District" class="col-sm-8">
                {{ selectedLea.address }}
              </dd>

              <dt v-if="selectedLea.type == LeaTypes.School || selectedLea.type == LeaTypes.District" class="col-sm-4">
                Website
              </dt>
              <dd v-if="selectedLea.type == LeaTypes.School || selectedLea.type == LeaTypes.District" class="col-sm-8">
                <a :href="selectedLea.url || selectedLea.website" target="_blank">{{ selectedLea.url || selectedLea.website }}</a>
              </dd>

              <dt v-if="selectedLea.type == LeaTypes.District" class="col-sm-4">
                <custom-tooltip element-id="calendaroption-label" content="A district can only implement one type of calendar in a school year. Districts will identify their calendar option in the Statewide Information System LEA Profile" label="Calendar Option" />
              </dt>
              <dd v-if="selectedLea.type == LeaTypes.District" class="col-sm-8">
                {{ displayCalendarOption() }}
              </dd>
            </dl>
          </div>
        </div>
      </div>

      <state-summary-dashboard-widget :selected-lea="selectedLea" v-if="selectedLea.type == 3" />
      <enrollment-dashboard-widget :selected-lea="selectedLea" />
      <instructional-options-dashboard-widget v-if="selectedLea.type != LeaTypes.Coop" :selected-lea="selectedLea" />
      <attendance-dashboard-widget v-if="selectedLea.type != LeaTypes.Coop" :selected-lea="selectedLea" />
      <gifted-and-talented-dashboard-widget :selected-lea="selectedLea" />
      <graduation-count-dashboard-widget v-if="selectedLea.type != LeaTypes.Coop" :selected-lea="selectedLea" />
      <graduation-rate-dashboard-widget v-if="selectedLea.type != LeaTypes.Coop" :selected-lea="selectedLea" />
      <school-board-dashboard-widget :selected-lea="selectedLea" v-if="selectedLea.type == 2" />
      <essa-dashboard-widget :selected-lea="selectedLea" v-if="selectedLea.type == 1" />
      <letter-grades-dashboard-widget :selected-lea="selectedLea" v-if="selectedLea.type != 1" />
      <br>
      <waivers-dashboard-widget :selected-lea="selectedLea" v-if="selectedLea.type != 20" />
      <owned-leas-list />
    </section>
    <section v-else>
      <loading-spinner />
    </section>
  </div>
</template>

<script>
import EnrollmentDashboardWidget from './enrollment/EnrollmentDashboardWidget.vue'
import GiftedAndTalentedDashboardWidget from './enrollment/GiftedAndTalentedDashboardWidget'
import GraduationRateDashboardWidget from './enrollment/GraduationDashboardWidget'
import GraduationCountDashboardWidget from './enrollment/GraduationCountDashboardWidget'
import WaiversDashboardWidget from './WaiversDashboardWidget.vue'
import StateSummaryDashboardWidget from './StateSummaryDashboardWidget.vue'
import EssaDashboardWidget from './essa/EssaDashboardWidget.vue'
import LetterGradesDashboardWidget from './lettergrades/LetterGradesDashboardWidget.vue'
import SchoolBoardDashboardWidget from './schoolboard/SchoolBoardDashboardWidget.vue'
import AttendanceDashboardWidget from './attendanceRates/AttendanceDashboardWidget.vue'
import InstructionalOptionsDashboardWidget from './instructionalOptions/InstructionalOptionsDashboardWidget.vue'

import OwnedLeasList from './OwnedLeasList.vue'
import LetterGradeBadge from './LetterGradeBadge.vue'
import LeaIcon from '../LeaIcon.vue'
import { LeaTypes } from '../../helpers/leaTypes'
import LoadingSpinner from '../LoadingSpinner.vue'
import CustomTooltip from '../CustomTooltip'
import { PageMixin } from '../PageMixin'

export default {
  mixins: [PageMixin],
  data() {
    return {
      LeaTypes
    }
  },
  components: {
    EnrollmentDashboardWidget,
    WaiversDashboardWidget,
    StateSummaryDashboardWidget,
    EssaDashboardWidget,
    LetterGradesDashboardWidget,
    SchoolBoardDashboardWidget,
    LetterGradeBadge,
    OwnedLeasList,
    LeaIcon,
    LoadingSpinner,
    CustomTooltip,
    GiftedAndTalentedDashboardWidget,
    GraduationRateDashboardWidget,
    GraduationCountDashboardWidget,
    AttendanceDashboardWidget,
    InstructionalOptionsDashboardWidget,
  },
  methods: {
    goToLea(leaNumber) {
      const lea = this.$store.state.globalModule.leaContext.LeaDictionary[leaNumber]
      window.scrollTo(0,0);
      this.$store.dispatch('globalModule/changeSelectedLea', lea)
    },
    displayProgramStatus(federalProgramStatus) {
      if (federalProgramStatus == 'SW')
        return 'School Wide'
      else if (federalProgramStatus == 'TA')
        return 'Target Assisted'
      else
        return 'Not Applicable'
    },
    displayCalendarOption() {
      const option = this.selectedLea.calOption || this.selectedLea.calendarOption

      if (option == 1) 
        return 'Traditional School Calendar'
      if (option == 2) 
        return 'Alternate School Calendar'
      if (option == 3) 
        return '4 Day Week School Calendar'
      if (option == 4) 
        return '12 Month / Year Round School Calendar'

      return 'Not specified'
    },
  }
}
</script>
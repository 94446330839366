<template lang="html">
  <div class="row mb-2 d-print-none">
    <div class="col-md-4">
      <b-form-select v-if="allowLengthChange" v-model="options.perPage" :options="options.pageLengths" required />
    </div>
    <div class="col-md-8" v-if="showFilter">
      <b-input-group>
        <b-form-input v-model="options.filter" :placeholder="placeholderText" class="text-right" debounce="500" />
        <b-input-group-append>
          <b-button :disabled="!options.filter" @click="options.filter = ''">
            Clear
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
  </div>
</template>
<script>
export default {
  name: 'table-control-widget',
  data() {
    return {
      options: {
        pageLengths: [10, 20, 50, 100],
        filter: null,
        perPage: 10,
      }
    }
  },
  watch: {
    perPage: {
      immediate: true,
      handler() {
        this.options.perPage = this.perPage || 50
      }
    },
    'options.filter': function (newValue) {
      this.$emit('filter-changed', newValue)
    },
    'options.perPage': function (newValue) {
      this.$emit('page-length-changed', newValue)
    }
  },
  props: {
    allowLengthChange: {
      type: Boolean,
      default: true
    },
    perPage: Number,
    placeholderText: {
      type: String,
      default: 'Filter...'
    },
    filter: {
      type: String
    },
    showFilter: {
      type: Boolean,
      default: true
    }
  },
  created() {
    if (this.filter) {
      this.options.filter = this.filter
    }
  }
}
</script>
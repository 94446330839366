<template>
  <div class="report-container">
    <div class="row mb-2">
      <div class="col">
        <b-form-select :options="ayOptions" v-model="academicYear" @change="getData">
          <template #first>
            <b-form-select-option :value="null" disabled>
              -- Academic Year --
            </b-form-select-option>
          </template>
        </b-form-select>  
      </div>
    </div>

    <Promised :promise="loadData" v-show="loadData">
      <template v-slot:pending>
        <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
          <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
        </div>
      </template>
      <template v-slot:rejected="error" class="align-items-center justify-content-center">
        <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
      </template>      
      <template v-slot>
        <academic-outcomes :report-data="reportData" v-if="reportData != {}" />
      </template>
    </Promised>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Promised } from 'vue-promised'
import CardStateIcon from '@/components/CardStateIcon'
import AcademicOutcomes from './AcademicOutcomes.vue'
import { AYDependentReportMixin } from '../AYDependentReportMixin'

export default {
  data() {
    return {
      academicYear: null,
      loadData: null,
      reportData: {}
    }
  },
  components: {
    AcademicOutcomes,
    Promised,
    CardStateIcon
  },
  methods: {
    getData() {
      console.log('loading academic outcomes')
      if (this.academicYear) {
        this.reportData = {}
        var payload = {
          lea: this.selectedLea.number,
          academicYear: this.academicYear
        }

        this.loadData = this.$store.dispatch('cte/careercoach/reporting/getAcademicOutcomes', payload)
        .then(d=>this.reportData = d)
      }
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext'])
  },
  watch: {   
    selectedLea: {
      handler() {
        this.getData()
      }
    }
  },
  mixins: [
    AYDependentReportMixin
  ]
}
</script>
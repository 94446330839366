<template>
  <div>
    <h2>
      Program/Object Codes
    </h2>
    <Promised :promise="loadData">
      <template v-slot:pending>
        <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
          <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
        </div>
      </template>
      <template v-slot>
        <program-codes-list />
      </template>
      <template v-slot:rejected="error" class="align-items-center justify-content-center">
        <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
      </template>
    </Promised>
  </div>
</template>

<script>
import ProgramCodesList from './ProgramCodesList'
import CardStateIcon from '@/components/CardStateIcon.vue'
import { Promised } from 'vue-promised'
import { Types } from '@/modules/esa/stores'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loadData: null
    }
  },
  components: {
    Promised,
    CardStateIcon, 
    ProgramCodesList
  },
  computed: {
    ...mapState(Types.path, [Types.state.esaFiscalYear]),
  },
  watch: {
    esaFiscalYear: {
      immediate: true,
      handler(newYear) {
        if (newYear) {
          this.loadData = this.$store.dispatch(Types.actions.getPlanCategories, {
            fy: newYear
          })
        }
      }
    },

  }
}
</script>

<style>

</style>
import { render, staticRenderFns } from "./CCDashboardPage.vue?vue&type=template&id=47cc1fff&"
import script from "./CCDashboardPage.vue?vue&type=script&lang=js&"
export * from "./CCDashboardPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
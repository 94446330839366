<template>
  <div>
    <div class="row">
      <div class="col-10" />
      <div class="col-2 text-right">
        <excel-button />
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col-12">
          <area-coop-district-filter @on-filter-changed="loadContactList" criteria-visible="acd" />
        </div>
      </div>
    </div>
   
    <div class="card card-body">
      <div class="row">
        <div class="col-12">
          <lea-letter-jumplist :leas="filteredLeas" v-model="groupBy" />

          <b-form-group label="Text search"> 
            <b-form-input type="text" placeholder="Start typing to search contacts..." v-model="searchText" />
          </b-form-group>
          <div class="text-right">
            <label class="pt-1">({{ contactListFiltered.length }} districts filtered of {{ districtCount }} total districts)</label>
          </div>
        </div>
      </div>
 
      <contact-card v-for="contact in contactListFiltered" :key="contact.district.number" :contact="contact" :group-by="groupBy" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ContactCard from './ContactCard'
import ExcelButton from '../../../components/ExcelButton'
import AreaCoopDistrictFilter from './AreaCoopDistrictFilter'
import LeaLetterJumplist from '../../LeaLetterJumplist'

var sortByName = (a, b) => (a.district.name > b.district.name) ? 1 : -1
var sortByNumber = (a, b) => (a.district.number > b.district.number) ? 1 : -1

export default {
   components: {
    ContactCard,
    ExcelButton,
    AreaCoopDistrictFilter,
    LeaLetterJumplist
  },
  data() {
    return {
      sortByName,
      sortByNumber,
      searchText: '',
      groupBy: 'name'
    }
  },
  computed: {
    ...mapState('globalModule', ['leaContext']),
    ...mapState('DPSAFT', ['filter']),
    ...mapGetters('DPSAFT', ['sortedContactList']),
    districtCount() {
      return this.leaContext.Leas.ownedLeas.flatMap(c => c.ownedLeas).length
    },
    contactListFiltered() {
      // district lea, district name, and contact names
      if (this.searchText)
        return this.contactList.filter(c => c.district.number.includes(this.searchText) || 
        c.district.name.toLowerCase().includes(this.searchText.toLowerCase()) ||         
        (c.area.facilityMaintMgrId || '').includes(this.searchText) || 
        c.contacts.some(p => p.fullName.toLowerCase().includes(this.searchText.toLowerCase()) || (p.emailAddress || '').toLowerCase().includes(this.searchText.toLowerCase())))

      return this.contactList
    },

    contactList() {
      return this.sortedContactList(this.groupBy == 'name' ? this.sortByName : this.sortByNumber)
    },
    filteredLeas() {
      return this.contactList.map(l=> l.district)
    }
  },
  methods: {
    loadContactList() {
      this.$store.dispatch('DPSAFT/getContactList')
    }
  },
  created() {
    this.$store.dispatch('DPSAFT/getAreaManagers').then(() => {
      return this.$store.dispatch('DPSAFT/getAreas')
    }).then(() => {
      this.loadContactList()
    })
  }
}
</script>
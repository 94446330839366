import ApiService from '../services/ApiService'

export class ShortageReportAdminModule {
  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
  }

  namespaced = true

  state = {
    newJobGroupId: 0,
    newAreaGroupId: 0,
    fiscalYears: [],
    maxJobGroupFY: null,
    maxAreaGroupFY: null,
    reportGroups: [],
    reportAreaGroups: [],
    reportCodeGroupAssignments: [],

    currentFiscalYear: 0,
    licenseAreas: []
  }

  getters = {
    licenseAreaDictionary: state => state.licenseAreas,
    fiscalYears: state => state.fiscalYears,
    reportJobGroups(state) {
      var cloned = state.reportGroups.slice(0)

      /* eslint-ignore-next-line */
      cloned.forEach((item, idx, array) => {
        item.children = array.filter(f => f.parentGroupId == item.id)
      })

      return cloned
    },
    reportAreaGroups(state) {
      var cloned = state.reportAreaGroups.slice(0)

      /* eslint-ignore-next-line */
      cloned.forEach((item, idx, array) => {
        item.children = array.filter(f => f.parentGroupId == item.id)
      })

      return cloned
    }
  }

  mutations = {
    setFiscalYears: (state, years) => {
      state.fiscalYears = years.allFY
      state.maxJobGroupFY = years.maxJobGroupFY
      state.maxAreaGroupFY = years.maxAreaGroupFY
    },
    setFiscalYear: (state, fiscalYear) => (state.currentFiscalYear = fiscalYear),
    setLicenseAreas: (state, areas) => {
      state.licenseAreas = areas.reduce((result, item) => {
        result[item.code] = item
        return result
      }, {})
    },
    setReportGroups: (state, reportGroups) => (state.reportGroups = reportGroups),
    setReportAreaGroups: (state, groups) => (state.reportAreaGroups = groups),

    setReportCodeGroupAssignments(state, assignments) {
      state.reportCodeGroupAssignments = assignments
    },

    setJobGroupInformation(state, reportGroup) {
      const item = state.reportGroups.find(i => i.id == reportGroup.id)

      if (item) {
        //update the item in the store
        item.groupName = reportGroup.groupName
        item.parentGroupId = reportGroup.parentGroupId
        item.jobCodes = reportGroup.jobCodes
      } else {
        //add the item to the store
        state.reportGroups.push(reportGroup)
        state.newJobGroupId = reportGroup.id
      }
    },

    setAreaGroupInformation(state, areaGroup) {
      const item = state.reportAreaGroups.find(i => i.id == areaGroup.id)

      if (item) {
        //update the item in the store
        item.groupName = areaGroup.groupName
        item.parentGroupId = areaGroup.parentGroupId
        item.licenseCodes = areaGroup.licenseCodes
      } else {
        state.reportAreaGroups.push(areaGroup)
        state.newAreaGroupId = areaGroup.id
        //setNewJobGroupId
        //add the item to the store
      }
    },

    setAreaGroupLicenseCodes: (state, payload) => {
      const item = state.reportAreaGroups.find(i => i.id == payload.reportGroupId)

      if (item) {
        item.licenseCodes = payload.codes
      }
    },

    deleteAreaGroup: (state, id) => {
      const itemIndex = state.reportAreaGroups.findIndex(i => i.id == id)

      if (itemIndex >= 0) {
        state.reportAreaGroups.splice(itemIndex, 1)
      }
    },

    deleteJobGroup: (state, id) => {
      const itemIndex = state.reportGroups.findIndex(i => i.id == id)

      if (itemIndex >= 0) {
        state.reportGroups.splice(itemIndex, 1)
      }
    }
  }

  actions = {
    getLicenseAreas: ({ commit }) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/license-areas`).then(licenseAreas => commit('setLicenseAreas', licenseAreas))
    },
    getJobGroups: ({ commit }, payload) => {
      return this._apiService
        .Get(`${this._baseUrl}api/hcpt/job-groups?fiscalYear=${payload.fiscalYear}`)
        .then(reportGroups => commit('setReportGroups', reportGroups))
    },
    getAreaGroups: ({ commit }, payload) => {
      return this._apiService
        .Get(`${this._baseUrl}api/hcpt/area-groups?fiscalYear=${payload.fiscalYear}`)
        .then(reportGroups => commit('setReportAreaGroups', reportGroups))
    },

    getJobGroupJobCodes: async ({ commit }, payload) => {
      // console.log(`getting for ${payload.reportGroupId}`)
      return this._apiService.Get(`${this._baseUrl}api/shortage-report/get-job-codes?reportGroupId=${payload.reportGroupId}`).then(jobCodes => {
        commit('setReportCodeGroupAssignments', jobCodes)
      })
    },

    saveJobGroupChanges: ({ commit }, payload) => {
      return this._apiService.Post(`${this._baseUrl}api/shortage-report/save-course-group`, payload).then(response => {
        commit('setJobGroupInformation', response)
      })
    },

    saveAreaGroupChanges: ({ commit }, payload) => {
      return this._apiService.Post(`${this._baseUrl}api/shortage-report/save-area-group`, payload).then(response => {
        // debugger;
        commit('setAreaGroupInformation', response)
      })
    },

    deleteAreaGroup: ({ commit }, payload) => {
      return this._apiService.Delete(`${this._baseUrl}api/shortage-report/delete-area-group?id=${payload.id}`).then(() => {
        commit('deleteAreaGroup', payload.id)
      })
    },

    deleteJobGroup: ({ commit }, payload) => {
      return this._apiService.Delete(`${this._baseUrl}api/shortage-report/delete-job-group?id=${payload.id}`).then(() => {
        commit('deleteJobGroup', payload.id)
      })
    },

    getFiscalYears: ({ commit }) => {
      return this._apiService.Get(`${this._baseUrl}api/shortage-report/fiscal-years`).then(response => {
        commit('setFiscalYears', response)
      })
    },

    rollGroups: ({ dispatch }, payload) => {
      return this._apiService
        .Post(`${this._baseUrl}api/shortage-report/roll-groups?type=${payload.type}&sourceFY=${payload.sourceFY}&destFY=${payload.destFY}`)
        .then(() => dispatch('getFiscalYears'))
    }
  }
}

<template>
  <div class="mt-3">
    <loadable-chart-card :load-data="loadData" :enable-data-toggle="false" card-style="overflow-y: hidden">
      <paginated-table :items="academicYears" :fields="fields">
        <template v-slot:cell(openForSubmissions)="data">
          <span class="badge" :class="{ 'badge-success': data.item.openForSubmissions, 'badge-danger': !data.item.openForSubmissions }">
            {{ data.item.openForSubmissions ? 'Yes' : 'No' }}
          </span>
        </template>
        <template v-slot:cell(schoolYear)="data">
          {{ data.item.ay | schoolYear }}
        </template>
        <template v-slot:cell(createDate)="data">
          {{ data.value | date }}
        </template>
        <template v-slot:cell(action)="data">
          <b-button variant="link primary" :to="{name: 'CTE/PerkinsV/Admin/AcademicYear', params: {ay: data.item.ay}}" title="Setup Academic Year" v-if="isLastAY(data.item.ay)">
            <i class="fas fa-play" />
          </b-button>
          <b-button v-if="canRollover(data.item)" variant="link primary" @click="rollForwardAY(data.item.ay)" title="Roll forward Academic Year">
            <i class="fas fa-redo" />
          </b-button>
        </template> 
      </paginated-table>
    </loadable-chart-card>  
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Types } from '@/modules/cte/stores/perkinsv'
import LoadableChartCard from '@/components/LoadableChartCard.vue'
import PaginatedTable from '@/components/PaginatedTable.vue'

export default {
  data() {
    return {
      loadData: null,
      fields:[
        {
          key: 'schoolYear',
          label: 'School Year',
          sortable: false
        },
        {
          key: 'setupCompletionPercent',
          label: 'Setup Completion (%)',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          formatter: value => `${value}%`
        },
        {
          key: 'openForSubmissions',
          label: 'Open for Submissions',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false
        },
        {
          key: 'createDate',
          label: 'Create Date',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          key: 'action',
          label: '',
          sortable: false
        }
      ]
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.academicYears]),
  },
  components:{
    LoadableChartCard,
    PaginatedTable
  },
  mounted() {
    this.loadData = this.$store.dispatch(Types.actions.getAcademicYears)
  },
  methods:{
    canRollover(ay){
      return this.academicYears.every(y => y.ay <= ay.ay ) && ay.setupCompletionPercent == 100
    },
    rollForwardAY(ay){
      var newAY = ay + 1
      if(confirm(`Are you sure you wish to roll Academic Year ${ay + 2000} data forward to Academic Year ${newAY+2000}?`)){      
        this.$store.dispatch(Types.actions.rollForwardAY, {ay: newAY})
          .then(function() {
            this.$router.push({path: `/cte/perkins-v/admin/academic-year/${newAY}`})
          }.bind(this)
        )
      }    
    },
    isLastAY(ay){
      return this.academicYears.every(y => y.ay <= ay)
    }
  }
}
</script>

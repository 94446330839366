import Vue from 'vue'

const AYDependentReportMixin = Vue.extend({
  data() {
    return {
      academicYear: null
    }
  },
  computed: {
    ayOptions() {
      var years = [this.ay, this.ay-1]
      return years.map(y =>  {
        return { value: y, text: `${y+1999}/${y+2000}` }
      })
    }
  },
  props: {
    ay: Number
  }
})
export { AYDependentReportMixin }
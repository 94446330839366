<template>
  <div class="col-12">
    <div class="row">
      <table class="table table-sm table-nocolor">
        <tr>
          <td>
          &nbsp;
          </td>
          <th v-for="(fy, index) in fiscalYears" :key="`${fy}-sle`" :colspan="scopes.length" class="text-center text-white" :style="{ backgroundColor: fyColors[index] }">
            {{ fy+1989 }}-{{ fy+1990 }}
          </th>
        </tr>
        <tr>
          <td>
          &nbsp;
          </td>
          <!-- <td v-for="scope in scopes" :key="`${scope}-sle-1`" class="font-weight-bold text-center">
            {{ scope }}
          </td> -->
          <td v-for="scope in scopes" :key="`${scope}-sle-2`" class="font-weight-bold text-center">
            {{ scope }}
          </td>
          <td v-for="scope in scopes" :key="`${scope}-sle-3`" class="font-weight-bold text-center">
            {{ scope }}
          </td>
        </tr>
        <tr>
          <td :colspan="colSpan" class="font-weight-bold lead">
            State and Local Expenditures
          </td>
        </tr>
        <expenditures-table-row label="State and Local Personnel Expenditures" :values="getExpenditureValues('slePersonnelGrandTotal')" />
        <expenditures-table-row label="State and Local Non-Personnel Expenditures" :values="getExpenditureValues('sleNonPersonnelGrandTotal')" />
        <expenditures-table-row label="State and Local Grand Total Expenditures" :values="getExpenditureValues('sleTotalGrandTotal')" />
        <expenditures-table-row label="State and Local Personnel Per-pupil Expenditures" :values="getExpenditureValues('slePersonnelTotalPerpupilExpenditures')" />
        <expenditures-table-row label="State and Local Non-Personnel Per-pupil Expenditures" :values="getExpenditureValues('sleNonPersonnelTotalPerpupilExpenditures')" />
        <expenditures-table-row label="State and Local Per-pupil Expenditures" :values="getExpenditureValues('sleTotalTotalPerpupilExpenditures')" />

        <tr>
          <td>
            &nbsp;
          </td>
          <th v-for="(fy, index) in fiscalYears" :key="`${fy}-fe`" :colspan="scopes.length" class="text-center text-white" :style="{ backgroundColor: fyColors[index] }">
            {{ fy+1989 }}-{{ fy+1990 }}
          </th>
        </tr>
        <tr>
          <td>
            &nbsp;
          </td>
          <!-- <td v-for="scope in scopes" :key="`${scope}-fe-1`" class="font-weight-bold text-center">
            {{ scope }}
          </td> -->
          <td v-for="scope in scopes" :key="`${scope}-fe-2`" class="font-weight-bold text-center">
            {{ scope }}
          </td>
          <td v-for="scope in scopes" :key="`${scope}-fe-3`" class="font-weight-bold text-center">
            {{ scope }}
          </td>
        </tr>
        <tr>
          <td :colspan="colSpan" class="font-weight-bold lead">
            Federal Expenditures
          </td>
        </tr>
        <expenditures-table-row label="Federal Personnel Expenditures" :values="getExpenditureValues('fePersonnelGrandTotal')" />
        <expenditures-table-row label="Federal Non-Personnel Expenditures" :values="getExpenditureValues('feNonPersonnelGrandTotal')" />
        <expenditures-table-row label="Federal Grand Total Expenditures" :values="getExpenditureValues('feTotalGrandTotal')" />
        <expenditures-table-row label="Federal Personnel Per-pupil Expenditures" :values="getExpenditureValues('fePersonnelTotalPerpupilExpenditures')" />
        <expenditures-table-row label="Federal Non-Personnel Per-pupil Expenditures" :values="getExpenditureValues('feNonPersonnelTotalPerpupilExpenditures')" />
        <expenditures-table-row label="Federal Per-pupil Expenditures" :values="getExpenditureValues('feTotalTotalPerpupilExpenditures')" />

        <tr>
          <td>
            &nbsp;
          </td>
          <th v-for="(fy, index) in fiscalYears" :key="`${fy}-te`" :colspan="scopes.length" class="text-center text-white" :style="{ backgroundColor: fyColors[index] }">
            {{ fy+1989 }}-{{ fy+1990 }}
          </th>
        </tr>
        <tr>
          <td>
            &nbsp;
          </td>
          <!-- <td v-for="scope in scopes" :key="`${scope}-te-1`" class="font-weight-bold text-center">
            {{ scope }}
          </td> -->
          <td v-for="scope in scopes" :key="`${scope}-te-2`" class="font-weight-bold text-center">
            {{ scope }}
          </td>
          <td v-for="scope in scopes" :key="`${scope}-te-3`" class="font-weight-bold text-center">
            {{ scope }}
          </td>
        </tr>
        <tr>
          <td :colspan="colSpan" class="font-weight-bold lead">
            Total Expenditures
          </td>
        </tr>
        <expenditures-table-row label="Total Personnel Expenditures" :values="getExpenditureValues('tePersonnelGrandTotal')" />
        <expenditures-table-row label="Total Non-Personnel Expenditures" :values="getExpenditureValues('teNonPersonnelGrandTotal')" />
        <expenditures-table-row label="Total Grand Total Expenditures" :values="getExpenditureValues('teTotalGrandTotal')" />
        <expenditures-table-row label="Total Personnel Per-pupil Expenditures" :values="getExpenditureValues('tePersonnelTotalPerpupilExpenditures')" />
        <expenditures-table-row label="Total Non-Personnel Per-pupil Expenditures" :values="getExpenditureValues('teNonPersonnelTotalPerpupilExpenditures')" />
        <expenditures-table-row label="Total Per-pupil Expenditures" :values="getExpenditureValues('teTotalTotalPerpupilExpenditures')" />
      </table>
      <div>
        <small>
          * Personnel Expenditures Salaries and Employee Benefits (Object Series 100 and 200)<br>
          ** Non-Personnel Expenditures = Personnel Expenditures subtracted from Total Expenditures.
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { Types } from '../../../stores/financeModule'
import ExpendituresTableRow from './ExpendituresTableRow.vue'

export default {
  data() {
    return {
    }
  },
  props: {
    group: String
  },
  computed: {
    ...mapGetters(Types.path, [Types.getters.schoolFinancesfiscalYear]),
    ...mapState(Types.path, [Types.state.expenditures]),
    colSpan() {
      return (this.scopes.length * this.fiscalYears.length) + 1
    },
    fiscalYears() {
      return [/*this.fiscalYear-2,*/ this.schoolFinancesfiscalYear-1, this.schoolFinancesfiscalYear]
    },
    fyColors() {
      return ['#e4bd64', '#e4ab64', '#e49064']
    },
    scopes() {
      return ['School', 'District', 'Co-op', 'State'].slice(4-this.expenditures.length, 4)
    }
  },
  components: {
    ExpendituresTableRow
  },
  methods: {
    excelExportData() {
      return this.expenditures.map(leaExpenditure => {
        return {
          name: leaExpenditure.lea,
          items: leaExpenditure.fiscalYearRecords.map(fy => {
            return{
              FiscalYear: fy.fiscalYear,
              StateAndLocalPersonnelExpenditures: fy.schoolFinanceRecord.slePersonnelGrandTotal,
              StateAndLocalNonPersonnelExpenditures: fy.schoolFinanceRecord.sleNonPersonnelGrandTotal,
              StateandLocalGrandTotalExpenditure: fy.schoolFinanceRecord.sleTotalGrandTotal,
              StateAndLocalPersonnelPerPupilExpenditures: fy.schoolFinanceRecord.slePersonnelTotalPerpupilExpenditures,
              StateAndLocalNonPersonnelPerPupilExpenditures: fy.schoolFinanceRecord.sleNonPersonnelTotalPerpupilExpenditures,
              StateAndLocalPerPupilExpenditures: fy.schoolFinanceRecord.sleTotalTotalPerpupilExpenditures              
            }})
        }
      })
    },
    getExpenditureValues(column) {      
      var leas = []
      for(var i = 0; i < this.expenditures.length; i++){
        leas[i] = this.expenditures[i]
      }

      if (leas[0].fiscalYearRecords.length == 2) {
        return [
        ...this.getExpenditureFyValues(column, [...leas.map(lea => lea.fiscalYearRecords[0].schoolFinanceRecord)].reverse()),
        ...this.getExpenditureFyValues(column, [...leas.map(lea => lea.fiscalYearRecords[1].schoolFinanceRecord)].reverse()),
        //...this.getExpenditureFyValues(column, [...leas.map(lea => lea.fiscalYearRecords[2].schoolFinanceRecord)].reverse())
        ]
      }
      return []
    },
    getExpenditureFyValues(column, fyValues) {
      return fyValues.map(scope => {
        if (scope)
          return scope[column]
        else
          return 'No data available'
      })
    }
  }
}
</script>

<style scoped>
  .table-nocolor {
    background-color: inherit;
  }
</style>

import ProfileMenu from './ProfileMenu.vue'
import LeaContext from './LeaContext.vue'
import AlternativeLeasModal from './AlternativeLeasModal.vue'

const GlobalArea = {
  ProfileMenu,
  LeaContext,
  AlternativeLeasModal
}

export { GlobalArea }
<template>
  <div class="card bg-light">
    <div class="card-header lead">
      Staffing (Retention and Attrition)
    </div>
    <a class="anchor" id="staffing" />
    <b-tabs class="m-3" content-class="mt-1" pills>
      <b-tab title="Current" active title-item-class="d-print-none">
        <div class="row">
          <div class="col-12">
            <single-metric-schools-chart title="Teacher Average Years Experience" field="averageYearsExperience" :report-data="currentTeacherExperience" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <single-metric-schools-chart title="Teacher Average Percent w/ Emergency Provisional Credentials" field="percentWithEmergencyProvisionalCredentials" metric-suffix="%" :report-data="currentTeacherExperience" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row page-break-before">
          <div class="col-12">
            <single-metric-schools-chart title="Teacher Average Completely Certified" field="percentCompletelyCertified" metric-suffix="%" :report-data="currentTeacherExperienceSRC" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row ">
          <div class="col-12">
            <single-metric-schools-chart title="Average Class Size" field="value" :decimal-precision="0" :report-data="currentAvgClassSize" :load-data="loadData" />
          </div>
        </div>
        <div class="row page-break-before">
          <div class="col-12">
            <single-metric-schools-chart title="Student Teacher Ratio" field="value" :decimal-precision="0" :report-data="currentStudentTeacherRatio" :load-data="loadData" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <student-teacher-diversity-chart title="Teacher Diversity vs Student Population" :subgroups="Subpopulations" metric-suffix="%" :teachers="currentTeacherDiversity" :students="currentStudentDiversity" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row page-break-before">
          <div class="col-12">
            <single-metric-schools-chart title="Attrition Rate" metric-suffix="%" :report-data="currentAttritionRate" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <single-metric-schools-chart title="Number of NBCT Teachers" :report-data="currentNBCT" :decimal-precision="0" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row" v-for="(type, index) in LicenseExceptionTypes.filter(t => t.field)" :key="`${type.field}-current`" :class="{'page-break-before': index%2 == 0}">
          <div class="col-12">
            <single-metric-schools-chart :title="`License Exceptions - ${type.name}`" :field="`${type.field.toLowerCase()}`" :report-data="currentLicenseExceptions" :load-data="loadData" :credits="AELSCredits" />
          </div>
        </div>
      </b-tab>
      <b-tab title="Trend" title-item-class="d-print-none" :credits="MSICredits">
        <div class="row page-break-before">
          <div class="col-12">
            <single-metric-schools-chart title="Teacher Average Years Experience" field="averageYearsExperience" mode="trend" :report-data="teacherExperience" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row ">
          <div class="col-12">
            <single-metric-schools-chart title="Teacher Average Percent w/ Advanced Degrees" field="percentWithAdvancedDegrees" mode="trend" metric-suffix="%" :report-data="teacherExperience" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row page-break-before">
          <div class="col-12">
            <single-metric-schools-chart title="Teacher Average Percent w/ Emergency Provisional Credentials" field="percentWithEmergencyProvisionalCredentials" mode="trend" metric-suffix="%" :report-data="teacherExperience" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <single-metric-schools-chart title="Average Class Size" field="value" :decimal-precision="0" mode="trend" :report-data="avgClassSize" :load-data="loadData" />
          </div>
        </div>
        <div class="row page-break-before">
          <div class="col-12">
            <single-metric-schools-chart title="Student Teacher Ratio" field="value" mode="trend" :decimal-precision="0" :report-data="studentTeacherRatio" :load-data="loadData" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <single-metric-schools-chart title="Attrition Rate" mode="trend" metric-suffix="%" :report-data="attritionRate" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row page-break-before">
          <div class="col-12">
            <single-metric-schools-chart title="Number of NBCT Teachers" mode="trend" :decimal-precision="0" :report-data="nbct" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row" v-for="(type, index) in LicenseExceptionTypes.filter(t => t.field)" :key="`${type.field}-trend`" :class="{'page-break-before': index%2 == 0}">
          <div class="col-12">
            <single-metric-schools-chart mode="trend" :title="`License Exceptions - ${type.name}`" :field="`${type.field.toLowerCase()}`" :report-data="licenseExceptions" :load-data="loadData" :credits="AELSCredits" />
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
// import { LeaTypes } from '@/helpers/leaTypes'
import { mapState } from 'vuex'
import { Types } from '@/modules/esa/stores/survey'
import { Subpopulations } from '@/components/subpopulations'
import { credits } from '@/helpers/credits'
import { LicenseExceptionTypes } from '@/components/hcpt/licenseException/LicenseExceptionTypes'

import SingleMetricSchoolsChart from '../SingleMetricSchoolsChart.vue'
import StudentTeacherDiversityChart from './StudentTeacherDiversityChart.vue'

export default {
  data() {
    return { 
      loadData: null,
      Subpopulations,
      MSICredits: credits.MSI,
      AELSCredits: credits.AELS,
      LicenseExceptionTypes
    }
  },
  computed: {
    ...mapState(Types.path, [
      Types.state.avgClassSize,
      Types.state.teacherExperience,
      Types.state.studentDiversity,
      Types.state.teacherDiversity,
      Types.state.licenseExceptions,
      Types.state.studentTeacherRatio,
      Types.state.nbct,
      Types.state.attritionRate,
    ]),
    currentTeacherExperienceSRC() {
      const maxFy = this.getCurrentFiscalYear(this.teacherExperience, 'percentCompletelyCertified')
      return this.teacherExperience.filter(m => m.fy == maxFy)
    },
    currentTeacherExperience() {
      const maxFy = this.getCurrentFiscalYear(this.teacherExperience, 'averageYearsExperience')
      return this.teacherExperience.filter(m => m.fy == maxFy)
    },
    currentTeacherDiversity() {
      const maxFy = this.getCurrentFiscalYear(this.teacherDiversity, 'total')
      return this.teacherDiversity.filter(m => m.fy == maxFy)
    },
    currentStudentDiversity() {
      const maxFy = this.getCurrentFiscalYear(this.studentDiversity, 'dataLevel')
      return this.studentDiversity.filter(m => m.fy == maxFy)
    },
    currentLicenseExceptions() {
      if(this.licenseExceptions && this.licenseExceptions[0]) {
        const maxFy = Math.max(...this.licenseExceptions.map(m => m.fy))
        return this.licenseExceptions.filter(le => le.fy == maxFy)
      }
      return this.licenseExceptions
    },
    currentAvgClassSize() {
      const maxFy = this.getCurrentFiscalYear(this.avgClassSize, 'value')
      return this.avgClassSize.filter(m => m.fy == maxFy)
    },
    currentStudentTeacherRatio() {
      const maxFy = this.getCurrentFiscalYear(this.studentTeacherRatio, 'value')
      return this.studentTeacherRatio.filter(m => m.fy == maxFy)
    },
    currentNBCT() {
      const maxFy = Math.max(...this.nbct.map(m => m.fy))
      return this.nbct.filter(m => m.fy == maxFy)
    },
    currentAttritionRate() {
      const maxFy = Math.max(...this.attritionRate.map(m => m.fy))
      return this.attritionRate.filter(m => m.fy == maxFy)
    },
  },
  components: {
    SingleMetricSchoolsChart,
    StudentTeacherDiversityChart,
  },
  methods: {
    getCurrentFiscalYear(values, field) {
      if (values.length == 0) return 0
      return Math.max(...values.filter(m => m.lea == 'AR' && m[field] > 0).map(m => m.fy))
    },
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if (newLea && newLea.number) {
          this.loadData = Promise.all([
            this.$store.dispatch(Types.actions.getAvgClassSize, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getStudentTeacherRatio, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getTeacherExperience, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getTeacherDiversity, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getStudentDiversity, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getLicenseExceptions, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getNBCT, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getAttritionRate, { lea: newLea.number }),
          ])
        }
      }
    }
  }
}

</script>

  
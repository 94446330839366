<template>
  <div>
    <div class="card bg-light mt-3">
      <div class="card-body">
        <loadable-chart-card :load-data="loadData" :enable-data-toggle="false">
          <div class="p-2" v-if="latestATRSFeedFile && latestATRSFeedFile.latestFileOnServer">
            <h3 v-if="!latestATRSFeedFile.importedFile">
              No file imported currently
            </h3>
            <h3 v-else-if="!latestATRSFeedFile.importedFile">
              No file imported currently
            </h3>
            <dl>
              <dt v-if="latestATRSFeedFile.importedFile">
                Last Imported ATRS Feed File
              </dt>
              <dd v-if="latestATRSFeedFile.importedFile">
                {{ latestATRSFeedFile.importedFile.name }}
                <br>
                Imported On: <i>{{ formatDate(latestATRSFeedFile.importedFile.dateTime) }}</i>
                <br>
                Generated On: <i>{{ formatDate(latestATRSFeedFile.importedFile.lastServerWriteTime) }}</i>
              </dd>
              <dt>
                Latest ATRS Feed File on Available on SFTP Server
              </dt>
              <dd>
                {{ latestATRSFeedFile.latestFileOnServer.name }}
                <br>
                Generated On: <i>{{ formatDate(latestATRSFeedFile.latestFileOnServer.dateTime) }}</i>
              </dd>
            </dl>
            <animated-button button-class="mx-1 btn btn-primary" v-if="!latestATRSFeedFile.importedFile || sftpServerFileIsNewer" :promise-resolver="getReturnFile">
              Import ATRS Feed File from SFTP Server
            </animated-button>
            &nbsp;
            <!-- <custom-tooltip element-id="checkReturnFile-info" content="Send ATRS feed file to SFTP Server" /> -->
            <animated-button v-if="false" button-class="btn btn-primary" :promise-resolver="sendFile">
              Send File
            </animated-button>
            <div class="p-1">
              <div
                v-show="atrsFileResponse.length > 0"
                class="alert alert-secondary"
                role="alert">
                {{ atrsFileResponse }}
              </div>
            </div>
          </div>
        </loadable-chart-card>
      </div>
    </div>
  </div>
</template>

<script>
import LoadableChartCard from '../../LoadableChartCard.vue'
import AnimatedButton from '../../AnimatedButton.vue'
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  components: {
    LoadableChartCard,
    AnimatedButton
  },
  data() {
    return {
      loadData: null,
      status: ''
    }
  },
  computed: {
    ...mapState('hcptAdminModule', ['latestATRSFeedFile', 'atrsFileResponse']),
    checkReturnFile() {
      return () => this.$store.dispatch('hcptAdminModule/checkReturnFile')
    },
    getReturnFile() {
      return () => this.$store.dispatch('hcptAdminModule/getReturnFile')
    },
    sendFile() {
      return () => this.$store.dispatch('hcptAdminModule/sendFile')
    },
    sftpServerFileIsNewer() {
      if (this.latestATRSFeedFile.importedFile && this.latestATRSFeedFile.importedFile.lastServerWriteTime && this.latestATRSFeedFile.latestFileOnServer && this.latestATRSFeedFile.latestFileOnServer.dateTime) {
        return moment(this.latestATRSFeedFile.importedFile.lastServerWriteTime).isBefore(moment(this.latestATRSFeedFile.latestFileOnServer.dateTime))
      }
      return false
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format('MM/DD/YYYY h:mm a')
    }
  },
  created() {
    this.loadData = this.$store.dispatch('hcptAdminModule/getLatestFile')
  }
}
</script>

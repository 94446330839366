<template>
  <div>
    <excel-button title="MasterSchedule" />
    <h1>
      <custom-tooltip element-id="master-schedule-label" :content="'Source: ' + credits.eSchoolPlus.text" /> 
      Master Schedule
    </h1>
    <h2><lea-display :lea="selectedLea" /></h2>
    <div v-if="showSchedule">
      <div class="row">
        <div class="col-md-12">
          <master-schedule-filter-panel />
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <teachers-table :load-data="loadMasterSchedule" :qs-teacher-id="qsTeacherId" />
        </div>
        <div class="col-md-8">
          <master-schedule-table :load-data="loadMasterSchedule" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <enrollments-table :qs-marking-period="qsMarkingPeriod" />
        </div>
      </div>
    </div>
    <div class="alert alert-warning mt-4" v-else>
      Select a school to view the Master Schedule.
    </div>
  </div>
</template>

<script>
import MasterScheduleTable from './masterScheduleTable.vue'
import TeachersTable from './masterScheduleTeachersTable.vue'
import { EnrollmentsTable } from './masterScheduleEnrollmentTable'
import MasterScheduleFilterPanel from './masterScheduleFilterPanel.vue'
import { PageMixin } from '../PageMixin'
import { credits } from '../../helpers/credits'

export default {
  name: 'master-schedule-page',
  mixins: [PageMixin],
  data() {
    return {
      credits,
      loadMasterSchedule: null,
      qsSection: null,
      qsTeacherId: null,
      qsMarkingPeriod: null
    }
  },
  components: {
    MasterScheduleTable,
    TeachersTable,
    EnrollmentsTable,
    MasterScheduleFilterPanel
  },
  computed: {
    showSchedule() {
      return this.selectedLea.type == 1 || (this.selectedLea.type == 2 && this.qsTeacherId)
    },
    disableClearFilter() {
      return this.selectedLea.type == 2 && this.qsTeacherId
    }
  },
  mounted() {
    var queryString = window.location.search.substring(1)
    var params = new URLSearchParams(queryString)
    if (params.has('mp')) {
      this.qsMarkingPeriod = params.get('mp')
      this.$store.commit('scheduleModule/setQsMarkingPeriod', this.qsMarkingPeriod)
    }
    if (params.has('cs')) {
      this.qsSection = params.get('cs')
      this.$store.commit('scheduleModule/setQsCourseSection', this.qsSection)
    }
    if (params.has('tid')) {
      this.qsTeacherId = params.get('tid')
      this.$store.commit('scheduleModule/setQsTeacherId', this.qsTeacherId)
    }
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler() {
        if (this.selectedLea.type == 1 && !this.qsTeacherId) {
          this.loadMasterSchedule = this.$store.dispatch('scheduleModule/getMasterSchedule', {
            lea: this.selectedLea.number
          })
        }
      }
    },
    qsTeacherId: {
      handler() {
        this.loadMasterSchedule = this.$store.dispatch('scheduleModule/getMasterSchedule', {
          lea: this.selectedLea.number,
          teacherId: this.qsTeacherId
        })
      }
    }
  }
}
</script>

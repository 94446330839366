<template>
  <div>
    <b-alert show variant="warning" v-if="selectedLea.type > 2">
      Due to volume of data, student level reports are only available at the District or School level. Please select an appropriate LEA from the LEA Context Picker above.
    </b-alert>
    <div v-else>
      <excel-button title="Student Information" style="z-index: 1000;" />
      <filter-widget @apply-filter="updateFilter($event)" class="mb-2" :ay="ay" :ay-options="ayOptions" />
      <Promised :promise="loadData" v-show="loadData">
        <template v-slot:pending>
          <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
            <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
          </div>
        </template>
        <template v-slot:rejected="error" class="align-items-center justify-content-center">
          <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
        </template>      
        <template v-slot>
          <div v-if="reportData && reportData.students" class="report-output">
            <student-list :items="reportData.students" :fields="fields" />
          </div>
        </template>
      </Promised>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import FilterWidget from './FilterWidget.vue'
import StudentList from './StudentList.vue'
import CardStateIcon from '@/components/CardStateIcon'
import ExcelButton from '@/components/ExcelButton'
import { Promised } from 'vue-promised'
import { formatDate } from "@/helpers/formatters"
import { mapState } from 'vuex'
import { mapFieldsToExcel } from '@/helpers/tableHelpers'
import { AYDependentReportMixin } from '../AYDependentReportMixin'

export default {
  components: {
    FilterWidget,
    StudentList,
    Promised,
    CardStateIcon,
    ExcelButton,
  },
  data() {
    return {
      filter: {
        academicYear: null,
        lea: null
      },
      loadData: null,
      reportData: null,
      fields: [
        { key: 'districtLEA', label: 'District LEA' },
        { key: 'schoolLEA', label: 'School LEA' }, 
        { key: 'rosterGrade', label: 'Roster Grade' }, 
        { key: 'student', sortable: true, sortByFormatted: true, formatter: (v,k,item) => `${item.lastName}, ${item.firstName}` },
        { key: 'firstVisitDateRange', label: 'First Visit', sortable: true, formatter: (v) => {return formatDate(v, 'short-date')} },
        { key: 'lastVisitDateRange', label: 'Last Visit', sortable: true, formatter: (v) => {return formatDate(v, 'short-date')} },
        { key: 'visitCountDateRange', label: '# Visits', sortable: true },
        { key: 'lastCareerPlanGrade', label: 'Grade of Last Career Plan', sortable: true, class: 'text-center' },
        { key: 'lastCareerAssessmentGrade', label: 'Grade of Last Career Assessment', sortable: true, class: 'text-center' },
        { key: 'lastProgramOfStudyWithOccupation', label: 'Grade of Last POS w/ Occupation', sortable: true, class: 'text-center' },
        { key: 'lastProgramOfStudyWithJobFamily', label: 'Grade of Last POS w/ Job Family', sortable: true, class: 'text-center' },
        { key: 'lastWBLExperienceGrade', label: 'Grade of Last WBL Experience', sortable: true, class: 'text-center' },
        { key: 'lastCertificationGrade', label: 'Grade of Last Certification', sortable: true, class: 'text-center' },
        { key: 'completedFAFSA', sortable: true, class: 'text-center' },
        { key: 'completedYOUniversal', sortable: true, class: 'text-center' },
        { key: 'collegesAppliedTo', label: '# of Colleges Applied', sortable: true, class: 'text-center' },
        { key: 'college', label: 'College Attending', sortable: true, class: 'text-center' },
        { key: 'postSecondaryPlan', label: 'Post Secondary Plan', sortable: true, class: 'text-center' }
      ]
    }
  },
  computed: {
    ...mapState('cte/careercoach/reporting', ['rosterDates']),
  },
  methods: {
    updateFilter(filter) {
      this.filter.lea = this.selectedLea.number

      Object.keys(filter).forEach(key => {
        Vue.set(this.filter, key, filter[key])
      });

      this.getData()
    },
    getData() {
      this.loadData = this.$store.dispatch('cte/careercoach/reporting/studentListingReport', this.filter)
        .then(d=> {
          if (d.students.length > 0) {
            delete d.students[0].coopLEA
            delete d.students[0].gender
            delete d.students[0].ell
            delete d.students[0].sped
            delete d.students[0].firstVisitAllTime
            delete d.students[0].lastVisitAllTime
            delete d.students[0].visitCountAllTime
          }
          this.reportData = d
        })
    },
    excelExportData() {
      let fields = [...this.fields]
      fields.splice(1, 0, { label: 'District Name', formatter: (v, k, item) => this.leaDictionary[item.districtLEA].name })
      fields.splice(3, 0, { label: 'School Name', formatter: (v, k, item) => this.leaDictionary[item.schoolLEA].name })
      return {
        name: 'Student Information',
        items: [...mapFieldsToExcel(this.reportData.students, [{key: 'studentId', label: 'Student Id'}, ...fields])]
      }
    }
  },
  watch: {
    selectedLea: {
      immediate: false,
      handler() {
        this.filter.lea = this.selectedLea.number
      }
    },
    filter: {
      deep: true,
      handler() {
        this.getData()
      }
    }
  },
  mixins: [
    AYDependentReportMixin 
  ]
}
</script>
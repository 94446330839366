<template>
  <div class="row">
    <div class="col-md-12 text-center">
      <span class="title">Employee Leave Calendar</span>
    </div>
    <div class="col-md-10">    
      <calendar-heatmap 
        :values="employeeLeaveDaysArray" 
        :end-date="beginDate"
        :max="20"
        :range-color="['#ebedf0', '#c0ddf9', '#73b3f3', '#3886e1', '#17459e']"
        tooltip-unit="% Absent"
        legend-style="none"
        day-labels="weekdays"
        :locale="chartLocale"
        @day-click="onDayClick" />
    </div>
    <div class="col-md-2">
      <div class="flex-container">
        <b-list-group>
          <b-list-group-item>
            <div>
              <svg width="20" height="20"><rect width="20" height="20" style="fill:#ebedf0;stroke-width:0;stroke:rgb(0,0,0)" /></svg> 0% Absent
            </div>
            <div>
              <svg width="20" height="20"><rect width="20" height="20" style="fill:#c0ddf9;stroke-width:0;stroke:rgb(0,0,0)" /></svg> > 0% to 5% Absent
            </div>
            <div>
              <svg width="20" height="20"><rect width="20" height="20" style="fill:#73b3f3;stroke-width:0;stroke:rgb(0,0,0)" /></svg> > 5% to 10% Absent
            </div>
            <div>
              <svg width="20" height="20"><rect width="20" height="20" style="fill:#3886e1;stroke-width:0;stroke:rgb(0,0,0)" /></svg> > 10% to 20% Absent
            </div>
            <div>
              <svg width="20" height="20"><rect width="20" height="20" style="fill:#17459e;stroke-width:0;stroke:rgb(0,0,0)" /></svg> > 20% Absent
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<style scoped>
 .flex-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .title {
    color: #333333;
    font-size: 18px;
    fill: #333333;
  }
</style>

<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import { CalendarHeatmap } from 'vue-calendar-heatmap'

export default {
  name: 'employee-leave-days-calendar-chart',
  data() {
    return {
      chartLocale:{
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        days: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        on: 'on',
        less: 'less',
        more: 'more'
      }
    }
  },
  computed: {
    ...mapState('hcptModule', ['employeeLeaveDays']),
    ...mapGetters('hcptModule', ['fiscalYear']),
    ...mapState('globalModule', ['leaContext']),
    beginDate(){
      if (this.employeeLeaveDays.all) {
        return moment(this.employeeLeaveDays.all[0].chartValues.date).add(1,'years').format("YYYY-MM-DD")
      }
      else if(this.employeeLeaveDays.regular){
        return moment(this.employeeLeaveDays.regular[0].chartValues.date).add(1,'years').format("YYYY-MM-DD")
      }
      else if(this.employeeLeaveDays.charter){
        return moment(this.employeeLeaveDays.charter[0].chartValues.date).add(1,'years').format("YYYY-MM-DD")
      }
      return `${parseInt(this.fiscalyear) + 1990}-06-30`
    },
    allDays() {
      if (this.employeeLeaveDays.all) {
        return this.employeeLeaveDays.all
      }
      return []
    },
    charterDays() {
      if (this.employeeLeaveDays.charter) {
        return this.employeeLeaveDays.charter
      }
      return []
    },
    regularDays() {
      if (this.employeeLeaveDays.regular) {
        return this.employeeLeaveDays.regular
      }
      return []
    },
    employeeLeaveDaysArray() {
      switch (this.mode) {
        case 'regular':
          return this.regularDays.map(d => d.chartValues)
        case 'charter':
          return this.charterDays.map(d => d.chartValues)
      }
      return this.allDays.map(d => d.chartValues)
    }
  },
  methods:{
    onDayClick(day){
      this.$store.commit('hcptModule/setEmployeeLeaveDayFilterDate', day.date)
      this.$emit('day-selected')
    }
  },
  props: {
    mode: {
      type: String,
      default: 'all'
    },
    chartMode: String,
    stateView: String,
    leaveType: String
  },
  components:{
    CalendarHeatmap
  }
}
</script>

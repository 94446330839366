<template>
  <div>
    <narrative-filter :user-list="userList" @user-changed="onUserChanged($event)" />
    <narrative-list @update-list="updateList" :filter-user="user" />
  </div>
</template>
<script>
import NarrativeFilter from '../widgets/narrative/NarrativeFilter.vue'
import NarrativeList from '../widgets/narrative/NarrativeList.vue'
import { Types } from '../../../stores/careercoach/index.js'
import { mapState } from 'vuex'
import { unique } from '@/helpers/globalHelpers.js'

export default {
  data() {
    return {
      user: null
    }
  },
  components: {
    NarrativeFilter,
    NarrativeList
  },
  computed: {
    ...mapState(Types.path, [Types.state.narratives]),
    userList() {
      return (this.narratives || []).map(n => n.createdBy).filter(unique)
    }
  },
  methods: {
    updateList(whichLea) {
      var lea = whichLea || this.selectedLea
      this.$store.dispatch(Types.actions.getNarratives, { lea: lea.number })
    },
    onUserChanged(user) {
      this.user = user
    }
  },
  watch: {
    selectedLea: {
      handler(lea) {
        this.updateList(lea)
      },
      immediate: true
    }
  }
}
</script>
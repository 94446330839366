<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-form-group label="Start Date">
          <b-form-datepicker v-model="start" :max="end" />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="End Date">
          <b-form-datepicker v-model="end" :min="start" />
        </b-form-group>
      </b-col>
    </b-row>    
    <paginated-table :items="feedback" :fields="fields" sort-by="createdAt" :sort-asc="false" striped :load-data="loading">
      <template v-slot:cell(districtLEA)="data">
        <p>
          <lea-icon :lea="leaDictionary[data.item.districtLEA]" v-if="data.value && data.value.length > 0" /> {{ data.value }}
        </p>
      </template>
      <template v-slot:cell(schoolLEA)="data">
        <span>
          <lea-icon :lea="leaDictionary[data.item.schoolLEA]" v-if="data.value && data.value.length > 0" /> {{ data.value }}
        </span>
      </template>
      <template #cell(createdAt)="data">
        <span :title="formatDate(data.value)">{{ formatDateAgo(data.value) }}</span>
      </template>
      <template #row-details="row">
        <b-card bg-variant="light">
          <i class="fas fa-share fa-fw mr-1" /> {{ row.item.url }}
          <hr>
          <i class="fas fa-comment fa-fw mr-1" /> {{ row.item.comment }}
        </b-card>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import PaginatedTable from '@/components/PaginatedTable'
import LeaIcon from '@/components/LeaIcon'

export default {
  data() {
    return {
      fields: [
        { key: 'feedbackId', label: 'ID', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'username', label: 'Username', sortable: true },
        { key: 'districtLEA', label: 'District', sortable: true, formatter: this.leaFormatter},
        { key: 'schoolLEA', label: 'School', sortable: true, formatter: this.leaFormatter},
        { key: 'createdAt', label: 'Created', sortable: true },
      ],
      loading: null,
      start: new Date(new Date().setDate(new Date().getDate() - 90)).toISOString().substr(0, 10),
      end: new Date().toISOString().substr(0, 10)
    }
  },
  components: {
    PaginatedTable,
    LeaIcon
  },
  computed: {
    ...mapState('rvsModule', ['feedback']),
  },
  async mounted() {
    await this.refreshData()
  },
  methods: {
    async refreshData() {
      this.loading = await this.$store.dispatch('rvsModule/getFeedback', { start: this.start, end: this.end })
    },
    formatDateAgo(date) {
      return moment(date).fromNow()
    },
    formatDate(date) {
      return moment(date).format('MMMM Do YYYY, h:mm:ss a')
    }
  },
  watch: {
    start() {
      this.refreshData()
    },
    end() {
      this.refreshData()
    }
  }
}
</script>
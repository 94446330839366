<template>
  <div>
    <div>
      <highcharts class="chart" :options="chartOptions" />
    </div>
  </div>
</template>

<script>
import { DigitalLearningWaiverStatuses } from '../enums'
import { WaiverChartMixin } from './WaiverChartMixin'
import { WaiverTypes } from '@/stores/digitalLearning'

const statuses = [
  { name: 'Approved', color: '#33cc33', statusCode: DigitalLearningWaiverStatuses.Approved },
  { name: 'Reviewed by ADE DESE', color: '#17a2b8', statusCode: DigitalLearningWaiverStatuses.Reviewed }
]

export default {
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'pie',
          height: this.$printMode ? 650 : 400
        },
        legend: {
          maxHeight: this.$printMode ? 450 : 200
        },
        credits: {
          enabled: true,
          text: "* Only Completed requests are included on this chart"
        },
        title: {
          text: `Act 1240 Waiver Requests - Approved vs. Reviewed`
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: <br/>{point.y} ({point.percentage:.1f}%)'
            },
            showInLegend: true
          }
        },
        series: [{
          name: 'Approval Status',
          colorByPoint: true,
          data: []
        }],
        xAxis: {
          title: {
            text: this.xAxis
          },
          categories: ['Status']
        },
        yAxis: {
          title: {
            text: 'Count'
          },
          endOnTick: false
        }
      },
    }
  },
  props: {
    loadData: Promise,
    selectedWaiverType: Object
  },
  computed: {
    completedRequests() {
      return this.waiverRequests
        .filter(wr => [DigitalLearningWaiverStatuses.Reviewed, DigitalLearningWaiverStatuses.Approved].includes(wr.status))
        .filter(wr => wr.waiverType == this.selectedWaiverType.value)
    }
  },
  watch: {
    selectedWaiverType(type) {
      this.$set(this.chartOptions.title, 'text', `Completed ${type.listTitle} - Approved vs. Reviewed`)
    },
    completedRequests:{
      immediate: true,
      handler() {
        var requests = this.completedRequests
        this.chartOptions.series[0].data = statuses.map(s => {
          return {
            name: this.selectedWaiverType.value == WaiverTypes.DigitalLearning.value && s.name == 'Approved' ? 'SBE Approved' : s.name,
            color: s.color,
            y: requests.filter(wr => s.statusCode == wr.status).length
          }
        })
      }
    }
  },
  mixins: [WaiverChartMixin]
}
</script>
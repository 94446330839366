<template>
  <div>
    <paginated-table small :items="colleges" :fields="fields" v-if="mode == 'view'" sort-by="name" striped>
      <template v-slot:head(action)>
        <b-button @click="onBeginAdd" variant="primary">
          <i class="fa fa-fw fa-plus-circle" /> Add
        </b-button>
      </template>
      <template v-slot:cell(action)="data">
        <command-buttons
          :options="{showAdd: false, showEdit: true, showDelete: false, operation:'view'}"
          @on-edit="onRowEdit(data.item)"
          @on-delete="onRowDelete(data.item)"
        />
      </template>
    </paginated-table>
    <div v-if="mode != 'view'">
      <b-form>
        <b-form-group id="input-group-2" label="Name:" label-for="input-name">
          <b-form-input id="input-name" v-model="editingItem.name" />
        </b-form-group>
      </b-form>

      <b-button variant="primary" :disabled="!isValid" @click="onSave">
        Save
      </b-button>
      <b-button variant="danger" class="mx-1" @click="onCancelEdit">
        Cancel
      </b-button>
    </div>
  </div>
</template>

<script>
import { Types } from '@/modules/cte/stores/careercoach'
import { mapState } from 'vuex'
import PaginatedTable from '@/components/PaginatedTable.vue'
//import moment from 'moment'
import CommandButtons from '@/components/shared/CommandButtons.vue'

export default {
  data() {
    return {
      mode: 'view',
      fields: [
        { key: "name", sortable: true },
        //{ key: "createdBy", formatter: data => (data ? data : '--') },
        //{ key: "createdDate", sortable: true, formatter: data => (data ? moment(data).format('MM/DD/YYYY h:mm:ss a') : '--'), },
        //{ key: "updatedBy", formatter: data => (data ? data : '--') },
        //{ key: "updatedDate", sortable: true, formatter: data => (data ? moment(data).format('MM/DD/YYYY h:mm:ss a') : '--'), },
        { key: "action", thClass: 'text-right' }
      ],
      editingItem: {},
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.colleges]),
    isValid() {
      return (this.editingItem?.name || '').length > 0
    }
  },
  components: {
    PaginatedTable,
    CommandButtons
  },
  methods: {
    onRowEdit(item) {
      this.selectedItem = item
      this.editingItem = Object.assign({}, item)
      this.mode = 'edit'
    },
    onBeginAdd() {
      this.editingItem = {
        name: null,
      }
      this.mode = 'add'
    },
    onCancelEdit() {
      this.mode = 'view'
      this.editingItem = null
    },
    onSave() {
      var action = Promise.resolve()

      if (this.mode == 'add') action = this.$store.dispatch(Types.actions.addCollege, { Name: this.editingItem.name })
      else if (this.mode == 'edit') action = this.$store.dispatch(Types.actions.updateCollege, {
        Id: this.editingItem.id,
        Name: this.editingItem.name
      })

      action.then(() => this.$store.dispatch(Types.actions.getColleges))
            .then(() => (this.mode = 'view'))
    }
  }
}
</script>

<style>

</style>
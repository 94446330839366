<template>
  <div style="height: 100%;">
    <div class="sidebar-scroll">
      <ul class="nav nav-pills flex-column ml-2 mr-2">
        <div v-for="section in sections" :key="section.target">
          <li class="nav-item">
            <a class="nav-link font-weight-bold" :href="'#' + section.target">
              <img :src="section.icon" v-if="section.icon" class="svg-icon"> {{ section.name }}
            </a>
          </li>
          <div v-if="section.sections">
            <li class="nav-item subsection" v-for="subsection in getSubsections(section)" :key="subsection.target">
              <a class="nav-link" :href="'#' + subsection.target">
                <img :src="subsection.icon" v-if="subsection.icon" class="svg-icon"> {{ subsection.name }}
              </a>
            </li>
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { surveyTemplate } from './surveyTemplate'

export default {
  name: 'arrfl-sidebar',
  computed: {
    selectedLea() { return this.$store.state.globalModule.leaContext.SelectedLea },
    ...mapState('globalModule', ['userContext']),
    sections() { return surveyTemplate.sections.filter(s => s.hidden === undefined || !s.hidden) }
  },
  data() {
    return {
      
    }
  },
  methods: {
    getSubsections(section) {
      if (section.sections)
      return section.sections.filter(s => s.hidden === undefined || !s.hidden)
      else return null
    }
  }
}
</script>
<style  scoped>
::v-deep .nav-link.active .text-secondary {
  color: #ffffff !important;
}

.nav-link {
  font-size: .9rem;
  padding: .25rem .5rem;
}

.svg-icon {
  height: 1rem;
  width: 1rem;
}

.subsection {
  margin-left: 1.2rem;
  margin-top: 0;
}

.nav-item .subsection {
  padding: .1rem .5rem;
}
</style>
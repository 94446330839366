<template>
  <div>
    <pdf-button />
    <excel-button title="AMIDays" />
    <h1>AMI Days</h1>
    <h2><lea-display :lea="selectedLea" /></h2>
    <div class="row d-print-none">
      <div class="col-md-3">
        <label>School Year</label>
        <b-form-select v-model="schoolYear" :options="years" inline />
      </div>
    </div>
    <alternate-method-of-instruction-days-widget :school-year="schoolYear" />
  </div>
</template>

<script>

import { PageMixin } from '../../PageMixin'
import AlternateMethodOfInstructionDaysWidget from './AMIDaysWidget.vue'

export default {
  name: 'alternate-method-of-instruction-days-page',
  data() {
    return {
      schoolYear: 30
    }
  },
  components: {
    AlternateMethodOfInstructionDaysWidget
  },
  computed: {
    years() {
      return this.$store.state.academicsModule.AMIFiscalYears
    }
  },
  created() {
    this.$store.dispatch('academicsModule/getAMIFiscalYears')
  },
  mixins: [PageMixin]
}
</script>

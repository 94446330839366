import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import store from './stores'
import PortalVue from 'portal-vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import mapsInit from 'highcharts/modules/map'
import VueScrollTo from 'vue-scrollto'
import VueGeolocation from 'vue-browser-geolocation'
import vSelect from 'vue-select'
import * as addExporting from 'highcharts/modules/exporting'
import * as addDrilldown from 'highcharts/modules/drilldown'
import * as addBellcurve from 'highcharts/modules/histogram-bellcurve'
import * as addNoData from 'highcharts/modules/no-data-to-display'

addExporting(Highcharts)
addDrilldown(Highcharts)
addBellcurve(Highcharts)
mapsInit(Highcharts)
addNoData(Highcharts)
import './filters'

import { HighchartsOptions } from './highchartsOptions'
import { GlobalArea } from './components'
import { OverviewArea } from './components/overview'
import { HCPTArea } from './components/hcpt'
import { AcademicsArea } from './components/academics'
import { CoreArea } from './components/core'
import { ScheduleArea } from './components/schedule'
import { FinancialArea } from './components/financial'
import { AdministrationArea } from './components/admin'
import { ToolsArea } from './components/tools'
import { SurveysArea } from './components/surveys'
import { FacilitiesArea } from './components/dpsaft/facilities'
import { DPSAFTHomeArea } from './components/dpsaft/home'
import { TransportationArea } from './components/dpsaft/transportation'
import { BlendedLearningReadinessArea } from './components/blendedLearningReadiness'
import { CTEArea } from './modules/cte/components'
import { OnDemandArea } from './components/onDemand'
import { ExternalAccountArea } from './modules/externalAccount'
import { RVSArea } from './modules/rvs'

import { router } from './routes'
import DebugBlockWidget from './components/shared/DebugBlockWidget.vue'
import 'vue-select/dist/vue-select.css'

// Finish initializing...
Vue.config.productionTip = false
Vue.prototype.$printMode = window.printMode //document.cookie.indexOf('PrintMode=true') > -1 || window.location.href.indexOf('output=print') >= 0
Vue.use(PortalVue)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(HighchartsVue)
Vue.use(VueScrollTo)
Vue.use(VueGeolocation)

Vue.mixin({
  computed: {
    selectedLea() { return this.$store.state.globalModule.leaContext.SelectedLea },
    leaDictionary() { return this.$store.state.globalModule.leaContext.LeaDictionary },
  },
  methods: {
    leaFormatter(lea) {
      if (this.leaDictionary[lea])
        return `${lea} - ${this.leaDictionary[lea].name}`
  
      return lea
    },
    goToLea(leaNumber) {
      const lea = this.$store.getters['globalModule/leaDictionary'][leaNumber]
      window.scrollTo(0, 0)
      this.$store.dispatch('globalModule/changeSelectedLea', lea)
    },
  }
})

Vue.component('v-select', vSelect)

class AppInitializer {
  constructor() {
    this.VueApp = new Vue({
      store,
      router,
      components: {
        ...GlobalArea,
        ...OverviewArea,
        ...AcademicsArea,
        ...CoreArea,
        ...HCPTArea,
        ...ScheduleArea,
        ...FinancialArea,
        ...AdministrationArea,
        ...AdministrationArea.DPSAFTAdminModule,
        ...ToolsArea.LeaAssignmentModule,
        ...FacilitiesArea,
        ...TransportationArea,
        ...DPSAFTHomeArea,
        ...BlendedLearningReadinessArea,
        ...CTEArea,
        ...SurveysArea,
        ...OnDemandArea,
        ...ExternalAccountArea,
        ...RVSArea,
        DebugBlockWidget
      },
      data() {
        return {
          leaSidebarActive: false,
          pageLoading: true,
        }
      },
      beforeMount() {
        window.addEventListener('beforeunload pagehide', this.preventNav)
      },
      mounted() {
        if (Vue.prototype.$printMode) 
        {
          console.log(`Print mode enabled`)
          console.log(`User Context: ${JSON.stringify(window.userContext)}`)
        }
      },
      beforeDestroy() {
        window.removeEventListener('beforeunload pagehide', this.preventNav)
      },
      methods: {
        preventNav(event) {
          if (this.$store.state.globalModule.isOnline) return 
          else {
            event.preventDefault()
            event.returnValue = ''
            //"If you leave this page you could lose any unsaved changes."
          }
        }
      }
    })

    Highcharts.setOptions(HighchartsOptions)
  }

  initialize() {
    this.VueApp.$mount('#app')
    globalStartup()
  }
}

async function globalStartup() {
  //polyfill matches and closest
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
      var el = this

      do {
        if (el.matches(s)) return el
        el = el.parentElement || el.parentNode
      } while (el !== null && el.nodeType === 1)
      return null
    }
  }
  // vanilla JS that calls into the Vue component and tells the sidebar to close whenever you click **outside** the sidebar.
  document.onclick = function(event) {
    if (
      event.target.closest('.lea-sidebar-wrapper') == null &&
      event.target.closest('#show-lea-sidebar') == null && 
      window.global.default.VueApp.$refs['lea-context-component'] != null
    ) {
      window.global.default.VueApp.$refs[
        'lea-context-component'
      ].closeLeaSidebar()
    }
  }
}

export default new AppInitializer()

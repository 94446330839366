<template>
  <div class="card contact-card">
    <a :id="'group-anchor-' + contact.district[groupBy][0]" class="group-anchor" />
    <div class="card-header text-white bg-primary">
      {{ contact.district.name }} | {{ contact.district.number }}
    </div>
    <div class="card-body">
      <h5 class="card-title">
        {{ contact.district.name }}
      </h5>
      <h6 class="card-subtitle">
        LEA #: {{ contact.district.number }}
      </h6>
      <div class="row">
        <div class="col-lg-3 col-12">
          {{ contact.district.address }}

          <div>{{ districtCountyName }}</div>
          <div>Area: {{ contact.area.name }} - {{ getManagerName(contact.area.facilityMaintMgrId) }}</div>
        </div>
        <div class="col-lg-9 col-12">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>Name</th>
                <th>Contact Type</th>
                <th>Phone #s</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="districtContact in contact.contacts" :key="districtContact.fullName + districtContact.coordinatorCode">
                <td>{{ districtContact.fullName }}</td>
                <td>{{ getCoordinatorDescription(districtContact.coordinatorCode) }}</td>
                <td>
                  {{ districtContact.phone }}
                  <div v-if="districtContact.fax && !districtContact.fax.includes('()')">
                    {{ districtContact.fax }} - Fax
                  </div>
                </td>
                <td>
                  <a :href="districtContact.emailAddress">{{ districtContact.emailAddress }}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div>Coop: {{ contact.coop.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang='scss'>
  .contact-card {
    margin-top: 1.5em;
  }
</style>
<script>
import { mapState } from 'vuex'

export default {
  name: 'contact-card',
  computed: {
    ...mapState('globalModule', ['leaContext']),
    ...mapState('DPSAFT', ['areaManagers']),
    leaDictionary() {
      return this.leaContext.LeaDictionary
    },
    counties() {
      return Object.keys(this.leaDictionary)
        .filter(k => k.length == 2)
        .map(countyLea => this.leaDictionary[countyLea])
    },
    districtCountyName() {
      const districtLea = this.contact.district.number
      return (this.counties.find(c => c.ownedLeas.some(d => d.number == districtLea)) || { name: 'Unassigned' }).name
    }
  },
  methods: {
    getCoordinatorDescription(code) {
      if(code == 'U')
        return 'Superintendent'
      else if (code == 'B') 
        return 'Bus Inspector'
      else
        return 'Facilities / Maintenance Director'
    },
    getManagerName(userId) {
      if (!userId) return 'Unassigned'
      //userId = userId.replace('ADE\\', '')
      const managers = (this.areaManagers.facilitiesMaintenanceManagers || []).concat(this.areaManagers.facilitiesPlanningAndConstructionManagers || [])
      return (managers.find(a => a.userId == userId) || { fullName: 'Unassigned' }).fullName
    }
  },
  props: {
    contact: {
      type: Object,
      required: true
    },
    groupBy: {
      type: String,
      default: 'name'
    },
  }
}
</script>

<style>
a.group-anchor:target {
    display: block;
    position: relative;
    top: -150px;
    visibility: hidden;
}
</style>
<template>
  <div>
    <table-control-widget :per-page="tableOptions.perPage" @filter-changed="tableOptions.filter=$event" @page-length-changed="tableOptions.perPage=$event" placeholder-text="Staff Name Filter..." />
    <b-table ref="shortageReportJobStaffTable" striped hover selectable select-mode="single" :fields="tableOptions.fields" :per-page="tableOptions.perPage"
             :current-page="tableOptions.currentPage" :filter="combinedFilter" :busy="tableOptions.isLoading" :items="staffDetails" @filtered="onFiltered" :filter-function="applyFilter">
      <template v-slot:cell(caseId)="data">
        <aels-case-id-link :case-id="data.value.toString()" />
      </template>
      <template v-slot:cell(leas)="data">
        <div class="d-inline">
          <div class="d-inline" v-for="lea in data.value.slice(0, 3)" :key="lea">
            <a href="#" @click="goToLea(lea)" :id="data.index + '-' + lea">
              {{ lea }}
            </a>
                        &nbsp;
            <lea-tooltip :target-selector="data.index + '-' + lea" :lea-number="lea" />
          </div>
        </div>
        <div v-if="data.value.length > 3">
          <a class="text-secondary cursor-pointer" v-b-modal="'modal-lea-' + data.item.caseId">
            <i class="fas fa-ellipsis-h" />
          </a>
        </div>
        <b-modal :id="'modal-lea-' + data.item.caseId">
          <template slot="modal-title">
            {{ data.item.fullName }} LEAs
          </template>
          <div class="d-block px-2" v-for="lea in data.value" :key="lea">
            <a href="#" @click="goToLea(lea)" :id="data.index + '-' + lea">
              {{ lea }}
            </a>
                        &nbsp;
            <lea-tooltip :target-selector="data.index + '-' + lea" :lea-number="lea" />
          </div>
        </b-modal>
      </template>
      <template v-slot:cell(licenses)="data">
        <ul style="list-style:none; padding-left: 0;">
          <li v-for="(license, index) in getUniqueLicenses(data.value).slice(0, 3)" :key="`license-${index}`">
            <strong class="text-danger" v-if="license.isALP">***</strong>{{ license.code }} &ndash; {{ license.areaName }} | {{ license.gradeLow }} - {{ license.gradeHigh }}
          </li>
        </ul>
        <a v-if="data.value.length > 3" class="text-secondary cursor-pointer" v-b-modal="'modal-' + data.item.caseId">
          <i class="fas fa-ellipsis-h" />
        </a>
        <b-modal :id="'modal-' + data.item.caseId">
          <template slot="modal-title">
            {{ data.item.fullName }} &ndash; Expiration: {{ data.item.expirationDate }}
          </template>
          <ul style="list-style:none;">
            <li v-for="(license, index) in getUniqueLicenses(data.value)" :key="`license-${index}`">
              {{ license.code }} &ndash; {{ license.areaName }} | {{ license.gradeLow }} - {{ license.gradeHigh }}
            </li>
          </ul>
        </b-modal>
      </template>
    </b-table>
    <div class="row">
      <div class="col-md-12 text-right">
        <span class="text-danger"><strong>***</strong></span> Denotes ALP
      </div>
    </div>
    <table-pagination-widget :total-rows-count="totalRowCount" :filtered-rows-count="tableOptions.filteredRowCount" :page-length="tableOptions.perPage" @page-changed="tableOptions.currentPage=$event" />
  </div>    
</template>

<script>
import { TableMixin } from '../../TableMixin'
import LeaTooltip  from '../../LeaTooltip.vue'
import { LicenseTypes } from '../../licenseTypes.js'
import * as moment from 'moment'

export default {
    name: 'educator-details-table',
    mixins: [TableMixin],
    data() {
        return {
            LicenseTypes: LicenseTypes,
            tableOptions: {
                isLoading: false,
                perPage: this.$printMode ? Number.MAX_SAFE_INTEGER : 100,
                currentPage:  1,
                filteredRowCount: null,
                filter: null,
                fields: [
                    {
                        key: 'caseId',
                        label: 'Case Id',
                        sortable: true
                    },
                    {
                        key: 'fullName',
                        label: 'Educator Name',
                        sortable: true
                    },
                    { 
                        key: 'leas', 
                        label: 'LEAs', 
                        sortable: false },
                    {
                        key: 'raceString',
                        label: 'Race',  
                        sortable: true
                    },
                    {
                        key: 'yearsOfExperience',
                        label: 'Years Experience',
                        sortable: true
                    },
                    {
                        key: 'isNoviceTeacher',
                        label: 'Novice Teacher',
                        sortable: true,
                        formatter: (d) => d ? 'Yes' : 'No'
                    },
                    {
                        key: 'degree',
                        label: 'Degree',
                        sortable: true
                    },
                    {
                        key: 'IsNbcCertified',
                        label: 'NBCT',
                        sortable: true,
                        formatter: (d) => d ? 'Yes' : 'No'
                    },
                    {
                        key: 'expirationDate',
                        sortable: true,
                        formatter: (d) => d ? moment(d).format("MM/DD/YYYY") : "N/A"
                    },
                    { 
                        key: 'licenses',
                        label: 'Licenses | Grade Low - Grade High',
                        sortable: false 
                    },
                    {
                        key: 'areaLicenseTypes',
                        label: 'Type',
                        sortable: false,
                        formatter: (d) => d?.map(t => LicenseTypes[t]?.short)?.toString()
                    },
                    {
                        key: 'career_Continuum',
                        label: 'Career Continuum',
                        sortable: true
                    },
                    {
                        key: 'lastIssue',
                        label: 'Issue Date',
                        sortable: true,
                        formatter: (d) => d ? moment(d).format("MM/DD/YYYY") : "N/A"
                    }
                ]
            }
        }
    },
    props: {
        staffDetails: Array,
        staffFilter: String,
        raceFilter: String,
        experienceFilter: String,
        licenseTypeFilter: Number,
        ccFilter: String
    },
    computed: {
        totalRowCount() {
            return this.staffDetails.length
        },
        combinedFilter() {
            return {
                race: this.raceFilter,
                experience: this.experienceFilter,
                licenseType: this.licenseTypeFilter,
                other: this.tableOptions.filter,
                cc: this.ccFilter
            }
        }
    },
    methods: {
        categoryFormatter(value, key, item) {
            return item.isCertifiedStaff ? 'Certified' : (item.isClassifiedStaff ? 'Classified' : 'Other')
        },
        nameFormatter(value, key, item) {
            return `${item.lastName}, ${item.firstName}`
        },
        onFiltered(items, count) {
            this.tableOptions.filteredRowCount = count
        },
        goToLea(leaNumber) {
            const lea = this.$store.getters['globalModule/leaDictionary'][leaNumber]
            window.scrollTo(0,0)
            this.$store.dispatch('globalModule/changeSelectedLea', lea)
        },
        getUniqueLicenses(licenses) {
            return licenses.map(l => 
                { 
                    return { code: l.code, areaName: l.areaName, isALP: l.isALP, licenseType: l.licenseType, gradeLow: l.gradeLow, gradeHigh: l.gradeHigh }
                }).filter((value, index, self) => {
                    return self.findIndex(l => l.code == value.code) == index 
                })
        },
        excelExportData() {
          return {
            name: 'Public Educators',
            items: this.staffDetails.flatMap(item => {
                return item.licenses.map(license => {
                    return { 
                      CaseId: item.caseId,
                      FullName: item.fullName,
                      Race: item.raceString,
                      YearsOfExperience: item.yearsOfExperience,
                      IsNoviceTeacher: item.isNoviceTeacher,
                      Degree: item.degree,
                      IsNbcCertified: item.isNbcCertified,
                      ExpirationDate: item.expirationDate,
                      Code: license.code, 
                      AreaName: license.areaName, 
                      LastIssue: license.lastIssue
                    }
                })
            })
          }
        },
        applyFilter(row, filter) {
            var passes = true

            if (filter.race) {
                var filterValue = null
                switch (filter.race) {
                    case "white":
                        filterValue = "White"
                        break
                    case "blackAfricanAmerican":
                        filterValue = "Black / African American"
                        break
                    case "americanIndian":
                        filterValue = "Native American / Alaska Native"
                        break
                    case "hispanicLatino":
                        filterValue = "Hispanic"
                        break
                    case "asian":
                        filterValue = "Asian"
                        break
                    case "hawaiianPacificIslander":
                        filterValue = "Pacific Islander / Native Hawaiian"
                        break
                    case "twoOrMoreRaces":
                        filterValue = "Multiethnic"
                        break
                }
                passes = row.raceString === filterValue
            }

            if (passes && filter.experience) {
                passes = (filter.experience == 'novice' && row.isNoviceTeacher) || (filter.experience == 'experienced' && !row.isNoviceTeacher)
            }

            if (passes && filter.licenseType){
                passes = row.areaLicenseTypes.includes(filter.licenseType)
            }

            if (passes && filter.other) {
                var re = new RegExp(filter.other, 'i')
                passes = re.test(row.fullName)
            }

            if (passes && filter.cc)
            {
                passes = row.career_Continuum === filter.cc
            }

            return passes
        }
    },
    components: {
      LeaTooltip
    }
}
</script>

<template>
  <div>
    <pdf-button />
    <excel-button title="School Finances" />
    <h1>School Finances</h1>
    <h2><lea-display :lea="selectedLea" /></h2>
    <expenditures-widget :selected-lea="selectedLea" />
  </div>
</template>

<script>
import { PageMixin } from '../../PageMixin'
import ExpendituresWidget from './ExpendituresWidget.vue'

export default {
  data() {
    return {
    }
  },
  mixins: [PageMixin],
  components: {
    ExpendituresWidget
  },
  computed: {
    selectedLea() { return this.$store.state.globalModule.leaContext.SelectedLea }
  },
  methods: {
  }
}
</script>

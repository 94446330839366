<template>
  <span :class="`badge ${badgeClass} mr-1`" v-if="subject">
    {{ subject }}
  </span>
</template>

<script>
export default {
  props:{
    subject: {
      type: String,
    },
  },
  computed: {
    badgeClass() {
      if (this.subject.includes('MATH') || this.subject.includes('GEO') || this.subject.includes('ALG') || this.subject.includes('Algebra') || this.subject.includes('Geometry'))
        return 'badge-info'
      else if (this.subject.includes('BIO') || this.subject.includes('SCI') || this.subject.includes('Biology'))
        return 'badge-success'
      else if (this.subject.includes('ELA'))
        return 'badge-warning'

      return 'badge-primary'
    }
  },
}
</script>
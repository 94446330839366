<template>
  <div v-bind="$attrs">
    <b-alert v-model="showError" variant="danger" dismissible>
      {{ errorMessage }}
    </b-alert>
    <b-table responsive small striped hover :items="files" :fields="columns" show-empty empty-text="No files uploaded">
      <template v-slot:head(action)>
        <div class="text-right" v-if="!readonly">
          <b-button variant="secondary" @click="onAddItem()" :disabled="busy">
            <i class="fas fa-file-upload fa-fw text-white" /> Upload a File
          </b-button>
        </div>
      </template>
      <template v-slot:cell(action)="data">
        <div class="text-right">
          <b-button size="sm" variant="info" class="mr-2" @click="onDownloadItem(data)">
            <i class="fa fa-download fa-fw text-white" /> Download
          </b-button>
          <b-button size="sm" variant="danger" @click="onRemoveItem(data)" v-if="!readonly">
            <i class="fa fa-trash fa-fw text-white" /> Delete
          </b-button>
        </div>
      </template>
    </b-table>
    <upload-modal @upload="uploadFile($event)" />
  </div>
</template>

<script>
import UploadModal from '../shared/UploadModal.vue'
import axios from 'axios'
import { Types } from '../../../../stores/careercoach/index.js'
import getMime from 'name2mime'
import moment from 'moment'
import prettyBytes from 'pretty-bytes'

export default {
  data() {
    return {
      columns: [
        {
          label: 'Name',
          key: 'filename'
        },
        {
          label: 'Type',
          key: 'mimeType',
          formatter: (_1, _2, row) => {
            var name = getMime(row.filename).name
            if (name == 'Default') name = row.filename.split('.').pop().toUpperCase() + ' file'
            return name
          }
        },
        {
          label: 'Size',
          key: 'size',
          formatter: (value) => { return prettyBytes(value) }
        },
        { 
          label: 'Upload Time',
          key: 'createdDate',
          formatter: (value) => { return moment(value).format("MM/DD/YYYY h:mm a") }
        },
        {
          key: 'action',
          thClass: 'text-right'
        },
      ],
      busy: false,
      uploadDone: false,
      result: null,
      filename: '',
      showError: false,
      errorMessage: null,
    }
  },
  methods: {
    onRemoveItem(item) {
      var self = this
      this.$bvModal.msgBoxConfirm(`Are you sure you want to delete this file?`)
        .then((result) => {
          if (result) {
            this.$store.dispatch(Types.actions.deleteNarrativeFile, { narrativeId: this.narrativeId, fileId: item.item.fileId })
              .then(() => self.$delete(self.files, item.index))
          }
        })
    },
    onAddItem() {
      this.$bvModal.show('uploadModal')
    },
    onDownloadItem(item) {
      this.$store.dispatch(Types.actions.downloadNarrativeFile, { narrativeId: this.narrativeId, fileId: item.item.fileId, mimeType: item.item.mimeType, fileName: item.item.filename })
    },
    uploadFile(file) {
      if (file) {
        this.result = null
        this.busy = true
        this.filename = file.name

        var formData = new FormData()
        formData.append('file', file, file.name)
      
        axios.post(`/api/cte/cc-reports/narrative/${this.narrativeId}/files`, formData,
          {
            headers: { 
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
              let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
              if (percentCompleted >= 100) this.uploadDone = true
            }
          }
        ).then((response) => {
            this.result = response.data
            this.files.push(this.result)
            this.busy = false
            this.uploadDone = true
        }).catch(err =>{
          if(err.response){
            this.errorMessage = err.response.data
            this.showError = true
          }
          else if(err.request){
            this.errorMessage = 'An error occurred sending your request to the server'
            this.showError = true
          }          
          this.busy = false
          this.uploadDone = false
        })
      }
    }
  },
  props: {
    files: {
      type: Array,
      default: () => []
    },
    narrativeId: {
      type: Number,
      required: true
    },
    readonly: {
      type: Boolean,
      default: () => true
    }
  },
  components: {
    UploadModal
  }
}
</script>

<style>

</style>
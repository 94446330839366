//import { animObject } from 'highcharts'
import ApiService from '@/services/ApiService'
import removeNamespace from '@/stores/removeNamespace'
import { initializeJSONResponse } from '@/helpers/globalHelpers'
import { RemoveNullsFromResponse } from '@/helpers/jsonResponseHelpers'

export const Types = {
  state: {
    users: 'users',
    costTypes: 'costTypes',
    selectedCostType: 'selectedCostType',
    indicators: 'indicators',
    academicYears: 'academicYears',
    currentTaskCompletion: 'currentTaskCompletion',
    selectedYear: 'selectedYear',
    applicantFundingLimits: 'applicantFundingLimits',
    applicantPerkinsIndicatorPerformances: 'applicantPerkinsIndicatorPerformances',
    applications: 'applications',
    selectedCostItem: 'selectedCostItem',
    selectedApplication: 'selectedApplication',
    applicantFundingLimit: 'applicantFundingLimit',
    selectedApplicationCoordinator: 'selectedApplicationCoordinator',
    selectedProposal: 'selectedProposal',
    proposalAvailableStates: 'proposalAvailableStates',
    coverPageAvailableStates: 'coverPageAvailableStates',
    consortiumLeas: 'consortiumLeas',
    inboxItems: 'inboxItems',
    unreadComments: 'unreadComments',
    selectedComments: 'selectedComments',
  },
  actions: {
    getAcademicYears: 'cte/perkinsv/getAcademicYears',
    getAcademicYear: 'cte/perkinsv/getAcademicYear',
    getCostTypes: 'cte/perkinsv/getCostTypes',
    updateCostType: 'cte/perkinsv/updateCostType',
    createCostType: 'cte/perkinsv/createCostType',
    changeEnabled: 'cte/perkinsv/setEnabled',
    getUsers: 'cte/perkinsv/getUsers',
    getIndicators: 'cte/perkinsv/getIndicators',
    updateIndicator: 'cte/perkinsv/updateIndicator',
    createIndicator: 'cte/perkinsv/createIndicator',
    deleteIndicator: 'cte/perkinsv/deleteIndicator',
    setTaskCompletion: 'cte/perkinsv/setTaskCompletion',
    getTaskCompletion: 'cte/perkinsv/getTaskCompletion',
    getApplicantFundingLimits: 'cte/perkinsv/getApplicantFundingLimits',
    getApplicantPerkinsIndicatorPerformance: 'cte/perkinsv/getApplicantPerkinsIndicatorPerformance',
    rollForwardAY: 'cte/perkinsv/rollForwardAY',
    getCostItem: 'cte/perkinsv/getCostItem',
    createCostItem: 'cte/perkinsv/createCostItem',
    updateCostItem: 'cte/perkinsv/updateCostItem',
    getConsortiumLeas: 'cte/perkinsv/getConsortiumLeas',
    enableAY: 'cte/perkinsv/enableAY',
    getApplication: 'cte/perkinsv/getApplication',
    getApplications: 'cte/perkinsv/getApplications',
    saveCoverPage: 'cte/perkinsv/saveCoverPage',
    createProposal: 'cte/perkinsv/createProposal',
    getProposal: 'cte/perkinsv/getProposal',
    saveProposalResponse: 'cte/perkinsv/saveProposalResponse',
    saveProposalDetails: 'cte/perkinsv/saveProposalDetails',
    getProposalAvailableStates: 'cte/perkinsv/getProposalAvailableStates',
    transitionProposalToState: 'cte/perkinsv/transitionProposalToState',
    getCoverPageAvailableStates: 'cte/perkinsv/getCoverPageAvailableStates',
    transitionCoverPageToState: 'cte/perkinsv/transitionCoverPageToState',
    addCommentorQuestion: 'cte/perkinsv/addCommentorQuestion',
    getInboxItems: 'cte/perkinsv/getInboxItems',
    deleteCostItem: 'cte/perkinsv/deleteCostItem',
    deleteProposal: 'cte/perkinsv/deleteProposal',
    getComments: 'cte/perkinsv/getComments',
    markCommentsAsRead: 'cte/perkinsv/markCommentsAsRead',
  },
  mutations: {
    setAcademicYears: 'cte/perkinsv/setAcademicYears',
    setSelectedYear: 'cte/perkinsv/setSelectedYear',
    setUsers: 'cte/perkinsv/setUsers',
    setCostTypes: 'cte/perkinsv/setCostTypes',
    setCurrentTaskCompletion: 'cte/perkinsv/setCurrentTaskCompletion',
    setIndicators: 'cte/perkinsv/setIndicators',
    setApplicantFundingLimits: 'cte/perkinsv/setApplicantFundingLimits',
    setApplicantPerkinsIndicatorPerformance: 'cte/perkinsv/setApplicantPerkinsIndicatorPerformance',
    setSelectedApplication: 'cte/perkinsv/setSelectedApplication',
    setSelectedProposal: 'cte/perkinsv/setSelectedProposal',
    setProposalAvailableStates: 'cte/perkinsv/setProposalAvailableStates',
    setCoverPageAvailableStates: 'cte/perkinsv/setCoverPageAvailableStates',
    setApplications: 'cte/perkinsv/setApplications',
    setSelectedCostItem: 'cte/perkinsv/setSelectedCostItem',
    setConsortiumLeas: 'cte/perkinsv/setConsortiumLeas',
    setCostItems: 'cte/perkinsv/setCostItems',
    setCoverPage: 'cte/perkinsv/setCoverPage',
    setInboxItemsWorkQueue: 'cte/perkinsv/setInboxItemsWorkQueue',
    setInboxItemsUnreadComments: 'cte/perkinsv/setInboxItemsUnreadComments',
    setSelectedComments: 'cte/perkinsv/setSelectedComments',
    saveProgramAreaToCoverPage: 'cte/perkinsv/addProgramAreaToCoverPage'
  },
  getters: {
    activeAcademicYear: 'activeAcademicYear',
    selectedProposalCostItems: 'selectedProposalCostItems',
    perkinsExtendedClaims: 'perkinsExtendedClaims',
    selectedApplicationIsForActiveAY: 'selectedApplicationIsForActiveAY',
    selectedProposalIsForActiveAY: 'selectedProposalIsForActiveAY',
  },
  path: 'cte/perkinsv'
}
const _types = removeNamespace('cte/perkinsv/', Types)
export class PerkinsV {

  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
  }

  namespaced = true

  state = {
    [Types.state.academicYears]: [],
    [Types.state.selectedYear]: {},
    [Types.state.users]: [],
    [Types.state.costTypes]: [],
    [Types.state.selectedCostType]: {},
    [Types.state.applicantFundingLimits]: [],
    [Types.state.applicantPerkinsIndicatorPerformances]: [],
    [Types.state.indicators]: [],
    [Types.state.currentTaskCompletion]: {},
    [Types.state.selectedApplication]: {},
    [Types.state.applicantFundingLimit]: {},
    [Types.state.selectedApplicationCoordinator]: {},
    [Types.state.applications]: [],
    [Types.state.selectedProposal]: {},
    [Types.state.proposalAvailableStates]: {},
    [Types.state.coverPageAvailableStates]: {},
    [Types.state.selectedCostItem]: {},
    [Types.state.consortiumLeas]: [],
    [Types.state.inboxItems]: [],
    [Types.state.selectedComments]: [],
    [Types.state.unreadComments]: [],
  }

  mutations = {
    [_types.mutations.setAcademicYears]: (state, data) => {
      console.log ('setting academic years:', data)
      state.academicYears = data
    },
    [_types.mutations.setSelectedYear]: (state, data) => state.selectedYear = data,
    [_types.mutations.setUsers]: (state, data) => state.users = data,
    [_types.mutations.setCostTypes]: (state, costTypes) => state[_types.state.costTypes] = costTypes,
    [_types.mutations.setIndicators]: (state, indicators) => state[_types.state.indicators] = indicators,
    [_types.mutations.setCurrentTaskCompletion]: (state, data) => state[_types.state.currentTaskCompletion] = data,
    [_types.mutations.setApplicantFundingLimits]: (state, applicantFundingLimits) => state[_types.state.applicantFundingLimits] = applicantFundingLimits,
    [_types.mutations.setApplicantPerkinsIndicatorPerformance]: (state, applicantPerkinsIndicatorPerformances) => state[_types.state.applicantPerkinsIndicatorPerformances] = applicantPerkinsIndicatorPerformances,
    [_types.mutations.setSelectedCostItem]: (state, data) => state[_types.state.selectedCostItem] = data,
    [_types.mutations.setConsortiumLeas]: (state, data) => state[_types.state.consortiumLeas] = data,
    [_types.mutations.setSelectedApplication]: (state, data) => {
      state[_types.state.selectedApplication] = data.application
      state[_types.state.applicantFundingLimit] = data.applicantFundingLimit
      state[_types.state.selectedApplicationCoordinator] = data.perkinsCoordinator
      state[_types.state.applicantPerkinsIndicatorPerformances] = data.perkinsIndicators
    },
    [_types.mutations.setSelectedProposal]: (state, data) => {
      initializeJSONResponse(data, 'narrativeResponse')
      initializeJSONResponse(data, 'anticipatedImpactResponse', [
        { type: 'Number of Schools', current: 0, previous: 0 },
        { type: 'Number of Teachers', current: 0, previous: 0 },
        { type: 'Number of Counselors', current:0, previous: 0 },
        { type: 'Number of CTE Admins', current: 0, previous: 0 },
        { type: 'Number of Non-CTE Admins', current: 0, previous: 0 },
        { type: 'Number of Students', current: 0, previous: 0 },
      ])
      initializeJSONResponse(data, 'perkinsIndicatorsFocusResponse', [])
      state[_types.state.selectedProposal] = data
    },
    [_types.mutations.setProposalAvailableStates]: (state, data) => state[_types.state.proposalAvailableStates] = data,
    [_types.mutations.setCoverPageAvailableStates]: (state, data) => state[_types.state.coverPageAvailableStates] = data,
    [_types.mutations.setApplications]: (state, data) => state[_types.state.applications] = data,
    [_types.mutations.setCostItems]: (state, data) => state[_types.state.selectedProposal].proposalCosts = data,
    [_types.mutations.setCoverPage]: (state, data) => state[_types.state.selectedApplication].coverPage = data,
    [_types.mutations.setInboxItemsWorkQueue]: (state, data) => state[_types.state.inboxItems] = data,
    [_types.mutations.setInboxItemsUnreadComments]: (state, data) => state[_types.state.unreadComments] = data,
    [_types.mutations.setSelectedComments]: (state, data) => state[_types.state.selectedComments] = data.map(c => { 
      return { ...c, isResponding: false }
    }),
  }

  getters = {
    [_types.getters.selectedApplicationIsForActiveAY]: (state, getters) => { 
      return state.selectedApplication.ay == (getters.activeAcademicYear || 0)
    },
    [_types.getters.activeAcademicYear]: (state) => { 
      const ay = (state.academicYears.find(a => a.openForSubmissions == true) || {}).ay
      console.log(`open ay from state.academicYear = ${ay}`)
      return ay
    },
    [_types.getters.selectedProposalCostItems]: (state) => {
      return state.selectedProposal?.proposalCosts || []
    },
    [_types.getters.perkinsExtendedClaims]: (_state, _getters, rootState) => {
      var claims = (rootState.globalModule.userContext.ExtendedClaims || []).filter(c => c.Type.startsWith("ExtendedClaim.Insights_PerkinsV")).map(c => { return {
        'type': c.Type,
        'value': JSON.parse(c.Value)
      }})
      return claims
    }
  }

  actions = {
    // eslint-disable-next-line
    [_types.actions.saveProgramAreaToCoverPage]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/perkinsv/addProgramAreaToCoverPage/${payload.occupationalAreaId}/${payload.programOfStudyId}/${payload.applicationId}`))
    },
    [_types.actions.getCostTypes]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/perkinsv/costTypes/${payload.schoolYear}${payload.includeDisabled ? '?includeDisabled=true': ''}`))
        .then(data => commit(_types.mutations.setCostTypes, data))
    },
    [_types.actions.updateCostType]: ({commit}, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/cte/perkinsv/costTypes/${payload.costTypeId}/update`), payload.editItem)
        .then(data => commit(_types.mutations.setCostTypes, data))
    },
    [_types.actions.createCostType]: ({commit}, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/perkinsv/costTypes/create`), payload.editItem)
        .then(data => commit(_types.mutations.setCostTypes, data))
    },
    [_types.actions.changeCostTypeEnabled]: ({commit}, payload) => {
      return this._apiService.Patch(encodeURI(`${this._baseUrl}api/cte/perkinsv/costTypes/setEnabled/${payload.costTypeId}?enabled=${payload.enabled}`))
        .then(data => commit(_types.mutations.setCostTypes, data))
    },
    [_types.actions.getUsers]: ({commit}) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/users`))
        .then(data => commit(_types.mutations.setUsers, data))
    },
    [_types.actions.getIndicators]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/perkinsv/indicators/${payload.academicYear}`))
        .then(data => commit(_types.mutations.setIndicators, data))
    },
    [_types.actions.updateIndicator]: ({commit}, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/indicators/${payload.indicatorId}/update`), payload.indicator )
        .then(data => commit(_types.mutations.setIndicators, data))
    },
    [_types.actions.createIndicator]: ({commit}, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/indicators/create`), payload.indicator )
        .then(data => commit(_types.mutations.setIndicators, data))
    },
    [_types.actions.deleteIndicator]: ({commit}, payload) => {
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/indicators/${payload.indicatorId}`))
        .then(data => commit(_types.mutations.setIndicators, data))
    },
    [_types.actions.getTaskCompletion]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/${payload.academicYear}/task-completion/${payload.taskCode}`), payload)
        .then(data => commit(_types.mutations.setCurrentTaskCompletion, data))
    },
    [_types.actions.setTaskCompletion]: ({commit}, payload) => {
      return this._apiService.Patch(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/task-completion`), payload)
        .then(data => commit(_types.mutations.setCurrentTaskCompletion, data.filter(t=>t.id == payload.id)[0]))
    },
    [_types.actions.setProgramAreaToCoverPage]: ({commit}, payload) => {
      return this._apiService.Patch(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/task-completion`), payload)
        .then(data => commit(_types.mutations.setCurrentTaskCompletion, data.filter(t=>t.id == payload.id)[0]))
    },
    [_types.actions.getAcademicYears]: ({commit}) => {
      console.log('loading academic years')
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/academic-years`))
        .then(data => {
          console.log('committing academic years')
          return commit(_types.mutations.setAcademicYears, data)
        })
    },
    [_types.actions.getAcademicYear]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/${payload.academicYear}`))
        .then(data => commit(_types.mutations.setSelectedYear, data))
    },
    [_types.actions.getInboxItems]: ({commit, getters}) => {
      return Promise.all([
        this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/perkinsv/${getters.activeAcademicYear}/inbox/work-queue`))
          .then(data => {
              commit(_types.mutations.setInboxItemsWorkQueue, data)
              return data
            }),
        this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/perkinsv/${getters.activeAcademicYear}/inbox/unread-comment`))
          .then(data => {
            commit(_types.mutations.setInboxItemsUnreadComments, data)
            return data
          })
      ])
    },
    [_types.actions.getApplicantFundingLimits]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/perkinsFundingLimits/${payload.ay}`))
        .then(data => commit(_types.mutations.setApplicantFundingLimits, data))
    },
    [_types.actions.getApplicantPerkinsIndicatorPerformance]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/perkinsIndicatorPerformance/${payload.ay}`))
        .then(data => commit(_types.mutations.setApplicantPerkinsIndicatorPerformance, data))
    },
    [_types.actions.getOAMetadata]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/perkinsv/${payload.ay}/oa-metadata`))
        .then(data => commit(_types.mutations.setOAMetadata, data))
    },
    // eslint-disable-next-line
    [_types.actions.getApplications]: ({commit, rootState}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/perkinsv/${payload.ay}/applications`))
        .then(data => commit(_types.mutations.setApplications, data))
    },
    // eslint-disable-next-line
    [_types.actions.rollForwardAY]: ({_commit, dispatch}, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/academicYearStartupTasks/${payload.ay}/rollforward`))
    },
    [_types.actions.getCostItem]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/perkinsv/applications/${payload.applicationId}/proposals/${payload.proposalId}/cost-items/${payload.costItemId}`))
        .then(data => commit(_types.mutations.setSelectedCostItem, data))
    },
    [_types.actions.createCostItem]: ({dispatch}, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/perkinsv/applications/${payload.applicationId}/proposals/${payload.proposalId}/cost-items`), payload.costItem)
        .then(() => dispatch(_types.actions.getProposal, payload))
    },
    [_types.actions.updateCostItem]: ({dispatch}, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/cte/perkinsv/applications/${payload.applicationId}/proposals/${payload.proposalId}/cost-items/${payload.costItemId}`), payload.costItem)
        .then(() => dispatch(_types.actions.getProposal, payload))
    },
    [_types.actions.deleteCostItem]: ({dispatch}, payload) => {
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/cte/perkinsv/applications/${payload.applicationId}/proposals/${payload.proposalId}/cost-items/${payload.costItemId}`))
        .then(() => dispatch(_types.actions.getProposal, payload))
    },
    [_types.actions.deleteProposal]: ({dispatch}, payload) => {
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/cte/perkinsv/applications/${payload.applicationId}/proposal/${payload.proposalId}`))
        .then(() => dispatch(_types.actions.getApplication, payload))
    },
    [_types.actions.getConsortiumLeas]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/perkinsv/${payload.consortiumLea}/consortiumLeas/${payload.academicYear}`))
        .then(data => commit(_types.mutations.setConsortiumLeas, data))
    },
    [_types.actions.getApplication]: ({commit, dispatch}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/perkinsv/applications/${payload.applicationId}`))
        .then(data => {
          dispatch(_types.actions.getCoverPageAvailableStates, payload)
          commit(_types.mutations.setSelectedApplication, data)
        })
    },
    [_types.actions.getProposal]: ({ commit, dispatch }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/perkinsv/applications/${payload.applicationId}/proposal/${payload.proposalId}`))
        .then(data => {
          dispatch(_types.actions.getProposalAvailableStates, payload)
          dispatch(_types.actions.getComments, payload)
          commit(_types.mutations.setSelectedProposal, data)
          return data
        })
    },
    [_types.actions.getComments]: ({ commit }, payload) => {
      let url = this.getCommentsUrl(payload)
      return this._apiService.Get(url)
        .then(data => {
          commit(_types.mutations.setSelectedComments, data)
          return data
        })
    },
    [_types.actions.markCommentsAsRead]: ({ commit }, payload) => {
      let url = this.getCommentsUrl(payload)
      return this._apiService.Put(url)
        .then(data => {
          commit(_types.mutations.setSelectedComments, data)
          return data
        })
    },
    // eslint-disable-next-line
    [_types.actions.saveProposalResponse]: ({ commit, dispatch }, payload) => {
      payload.response = JSON.stringify(payload.response, RemoveNullsFromResponse)
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/perkinsv/applications/${payload.applicationId}/proposal/${payload.proposalId}/response/${payload.responseName}`), payload.response)
        .then(data => {
          dispatch(_types.actions.getProposalAvailableStates, payload)
          dispatch(_types.actions.getComments, payload)
          commit(_types.mutations.setSelectedProposal, data)
          return data
        })
    },
    // eslint-disable-next-line
    [_types.actions.saveProposalDetails]: ({ commit, dispatch }, payload) => {
      let details = {
        proposalType: payload.proposalType,
        assuranceCertified: payload.assuranceCertified,
        clusterId: payload.clusterId,
        title: payload.title,
        pathwayId: payload.pathwayId,
        occupationalAreaId: payload.occupationalAreaId,
        programOfStudyId: payload.programOfStudyId,
      }
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/cte/perkinsv/applications/${payload.applicationId}/proposal/${payload.proposalId}`), details)
        .then(data => {
          dispatch(_types.actions.getProposalAvailableStates, payload)
          dispatch(_types.actions.getComments, payload)
          commit(_types.mutations.setSelectedProposal, data)
          return data
        })
    },
    [_types.actions.getProposalAvailableStates]: ({ commit }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/perkinsv/applications/${payload.applicationId}/proposal/${payload.proposalId}/state`))
        .then(data => {
          commit(_types.mutations.setProposalAvailableStates, data)
          return data
        })
    },
    [_types.actions.transitionProposalToState]: ({ dispatch }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/perkinsv/applications/${payload.applicationId}/proposal/${payload.proposalId}/state`), payload).then(() =>  {
        return dispatch(_types.actions.getProposal, payload)
      })
    },
    [_types.actions.getCoverPageAvailableStates]: ({ commit }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/perkinsv/applications/${payload.applicationId}/cover-page/state`))
        .then(data => {
          commit(_types.mutations.setCoverPageAvailableStates, data)
          return data
        })
    },
    [_types.actions.transitionCoverPageToState]: ({ dispatch }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/perkinsv/applications/${payload.applicationId}/cover-page/state`), payload).then(() =>  {
        return dispatch(_types.actions.getApplication, payload)
      })
    },
    [_types.actions.addCommentorQuestion]: ({ dispatch }, payload) => {
      let url = this.getCommentsUrl(payload)
      return this._apiService.Post(url, payload).then(() =>  {
        if (payload.proposalId) {
          return dispatch(_types.actions.getProposal, payload)
        }
        return dispatch(_types.actions.getApplication, payload)
      })
    },
    // eslint-disable-next-line
    [_types.actions.enableAY]: ({commit}, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/academicYearStartupTasks/${payload.ay}/enable`))
    },
    [_types.actions.saveCoverPage]: ({commit}, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/perkinsv/applications/${payload.applicationId}/cover-page`), payload.coverPage)
        .then(data => commit(_types.mutations.setCoverPage, data))
    },
    // eslint-disable-next-line
    [_types.actions.createProposal]: ({commit}, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/perkinsv/${payload.applicationId}/proposal/new`),payload.proposal)
    }
  }

  getCommentsUrl(payload) {
    if (payload.proposalId) {
      return encodeURI(`${this._baseUrl}api/cte/perkinsv/applications/${payload.applicationId}/proposal/${payload.proposalId}/comment`)
    }
    return encodeURI(`${this._baseUrl}api/cte/perkinsv/applications/${payload.applicationId}/cover-page/comment`)
  }
}
<template lang="html">
  <span>
    <select class="form-control" v-model="selectedYear" @change="onChange($event)">
      <option v-for="fy in fiscalYears" :value="fy" :key="fy">
        {{ fy + 1990 }}
      </option>
    </select>
  </span>
</template>

<script>
export default {
  name: 'fiscal-year-selector',
  data() {
    return {
      selectedYear: this.selectedYear
    } 
  },
  props: {
    fiscalYears: Array,
    initialYear: Object
  },
  methods: {
    onChange() {
      this.$emit('update:selectedYear', this.selectedYear)
    }
  }
}
</script>

import removeNamespace from '@/stores/removeNamespace'
import ApiService from '../../../services/ApiService'

export const AdminTypes = {
  state: {
    planCategories: 'planCategories',
    selectedPlanCategory: 'selectedPlanCategory'
  },
  actions: {
    getPlanCategories: 'ESA/admin/getPlanCategories',
    updatePlanCategory: 'ESA/admin/updatePlanCategory',
    createPlanCategory: 'ESA/admin/createPlanCategory',
    deletePlanCategory: 'ESA/admin/deletePlanCategory'
  },
  mutations: {
    setPlanCategories: 'ESA/admin/setPlanCategories',
    setSelectedPlanCategory: 'ESA/admin/setSelectedPlanCategory'
  },
  path: 'ESA/admin'
}

const _types = removeNamespace('ESA/admin/', AdminTypes)

export class Admin{
  constructor(){
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
    this._fiscalYear = parseInt(this._configElement.getAttribute('data-fiscal-year'))
  }

  namespaced = true

  state = {
    [_types.state.planCategories]: []
  }

  mutations = {
    [_types.mutations.setPlanCategories]: (state, planCategories) => state[_types.state.planCategories] = planCategories,
    [_types.mutations.setSelectedPlanCategory]: (state, planCategory) => state[_types.state.selectedPlanCategory] = planCategory
  }

  actions = {
    [_types.actions.updatePlanCategory]: (_, payload) => {
      return this._apiService.Patch(encodeURI(`${this._baseUrl}api/esa/admin/plan-category/${payload.planCategory.id}`), payload.planCategory)
    },
    [_types.actions.createPlanCategory]: (_, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/esa/admin/plan-category`), payload.planCategory)
    },
    [_types.actions.deletePlanCategory]: (_, payload) => {
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/esa/admin/plan-category/${payload.id}`))
    }
  }
}
<template>
  <div>
    <Promised :promise="loadData">
      <template v-slot:pending>
        <div class="align-items-center justify-content-center" style="height: 100%; width: 100%">
          <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading Student..." />
        </div>
      </template>
      <template v-slot:default>
        <b-card no-body v-if="student">
          <b-tabs card v-model="selectTabIndex">
            <b-tab title="Student Information" :disabled="$v.$dirty && selectTabIndex != 0" active>
              <information-form-teacher :ay="ay" 
                                        :value="$v.student.studentInformationResponse.$model" 
                                        :validation="$v.student.studentInformationResponse" />
            </b-tab>
            <b-tab title="Certifications" :disabled="$v.$dirty && selectTabIndex != 1">
              <certifications-form :ay="ay" 
                                   :value="$v.student.certificationsResponse.$model" 
                                   :validation="$v.student.certificationsResponse" />
            </b-tab>
            <b-tab title="Work Experience" :disabled="$v.$dirty && selectTabIndex != 2">
              <work-experience-form-teacher :ay="ay"
                                            :value="$v.student.workExperienceResponse.$model"
                                            :validation="$v.student.workExperienceResponse" 
              />
            </b-tab>
          </b-tabs>
        </b-card>
        <span v-else>No Student Found
          {{ student }}
        </span>
      </template>
      <template v-slot:rejected="error">
        {{ error }}
      </template>
    </Promised>
    <back-to-top />
  </div>
</template>

<script>
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import store from '@/stores'
import InformationFormTeacher from '../../widgets/student/details/InformationFormTeacher'
import CertificationsForm from '../../widgets/student/details/CertificationsForm'
import WorkExperienceFormTeacher from '../../widgets/student/details/WorkExperienceFormTeacher'
import { Types } from '@/modules/cte/stores/careercoach'
import { mapState } from 'vuex'
import { deepClone } from '@/helpers/globalHelpers'
import BackToTop from '@/components/shared/BackToTop'
import CardStateIcon from '@/components/CardStateIcon'
import { Promised } from 'vue-promised'

Vue.use(Vuelidate)

function loadStudent(studentId, lea, ay) {
  return store.dispatch(Types.actions.getStudent, {studentId: studentId, lea: lea })
    .then(function() {
      store.dispatch(Types.actions.getACTAspireAssessments, {studentId: studentId})
      store.dispatch(Types.actions.getACTAssessments, {studentId: studentId})
      store.dispatch(Types.actions.getVisitAcademicYears, {lea: lea})
      store.dispatch(Types.actions.getStudentGPA, {studentId: studentId, lea: lea })
      store.dispatch(Types.actions.getCurrentStudentVisits, {studentId: studentId, lea: lea, ay: ay})
  })
}

export default {
  components: {
    InformationFormTeacher,
    CertificationsForm,
    WorkExperienceFormTeacher,
    BackToTop,
    Promised,
    CardStateIcon
  },
  computed: {
    ...mapState(Types.path, [Types.state.currentStudent])
  },  
  beforeRouteEnter(to, from, next) {
    var promise = loadStudent(to.params.studentId, to.query.lea, to.params.ay)
    next(vm=>vm.loadData = promise)
  },
  methods: {
    reset() {
      this.$v.$reset()
    },
    unload(event) {
      if (this.$v.$dirty) {
        event.preventDefault();
        event.returnValue = '';
      }
    }
  },
  data() {
    return {
      loadData: Promise.resolve(true),
      student: null,
      selectTabIndex: 0,
    }
  },
  props: {
    ay: {
      type: Number,
      required: true,
      default: 0
    }
  },
  provide() {
    return {
      $v: this.$v
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.unload);
  },
  beforeRouteLeave (to, from, next) {
    if (Object.keys(to.query) < 1 && this.$v.$dirty) {
      const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
      if (answer) {
        window.removeEventListener('beforeunload', this.unload)
        next()
      } else {
        next(false)
      }
    }
    else {
      next()
    }
  },
  
  validations: {
    student: {
      studentInformationResponse: {},
      certificationsResponse: {},
      workExperienceResponse: {},
    }
  },
  watch: {
    '$route': {
      handler() {
        var that = this
        console.log(`route changed: ${this.$route.query.lea}`)
        this.loadData = loadStudent(this.$route.params.studentId, this.$route.query.lea)
        .then(function() {
          if (!that.student) {
            //there was no student found for this id/LEA, so just return to the dashboard
            that.$router.push({ name: 'CTE/CareerCoachTeacher/Home' })
          }
        })
      }
    },
    currentStudent: {
      immediate: true,
      deep: true,
      handler() {
        if (this.currentStudent && this.currentStudent.studentInformation) 
          this.student = deepClone(this.currentStudent.studentInformation)
        else 
          this.student = null
      }
    }
  }
}
</script>

<style lang="scss">
  @mixin s-caption {
    border-radius: 3px;
    background-color: #59a5e3;
    color: #f2f8fc;
    font-weight: bold;
    font-size: 1.5em; 
  }

  table > caption {
    @include s-caption;
    padding: 0px 5px !important;
  }
  
  .caption {
    @include s-caption;
    padding: 3px 5px !important;
  }
</style>
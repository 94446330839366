import axios from 'axios'
import store from '../stores'

axios.interceptors.response.use(response => {
    return response
  }, error => {
  if (error.response.status == 403) {
    const headers = {
      AccessibleLeas: JSON.parse(error.response.headers['x-accessibleleas']),
      GrantedAccess: error.response.headers['x-grantedaccess']
    }
    store.dispatch('globalModule/showUnauthorizedToast', headers)
    return {
      data: 'Not authorized to view data for this LEA',
      message: 'Not authorized to view data for this LEA'
    }
  } 

  return Promise.reject(error)

})
export default class ApiService {

  forceFileDownload(response) {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    const filenameRegex = /^attachment;\s+filename=(.+)$/
    const header = response.headers["content-disposition"]
    var filename = ""

    var match = []
    if (header) {
      match = header.match(filenameRegex)
      if (match.length > 1) {
        filename = match[1]
        // trim double quotes off the start and end of the filename
        filename = filename.replace(/^"(.+(?="$))"$/, '$1')
      }
    }

    link.href = url

    link.setAttribute('download', filename) //or any other extension
    document.body.appendChild(link)
    link.click()
  }

  async Get(url, bypassInterceptors) {
    if (bypassInterceptors) {
      var instance = axios.create();
      const response = await instance.get(url)
      return response.data
    }
    const response = await axios.get(url)
    return response.data
  }

  async Put(url, payload) {
    const response = await axios.put(url, payload)
    return response.data
  }

  async Patch(url, payload) {
    const response = await axios.patch(url, payload)
    return response.data
  }

  async Post(url, payload) {
    const response = await axios.post(url, payload)
    return response.data
  }

  async Delete(url, payload) {
    const response = await axios.delete(url, payload)
    return response.data
  }

  async DownloadWithPost(url, payload) {

    return await axios.post(url, payload, {
        responseType: 'blob'
      })
      .then(response => {
        this.forceFileDownload(response)
      })
  }

  async DownloadWithGet(url, payload) {

    return await axios.get(url, payload, {
      responseType: 'arraybuffer'
    })
      .then(response => {
        this.forceFileDownload(response)
      })
  }

  async DownloadInlineWithPost(url, payload) {
    return await axios.post(url, payload, {
      responseType: 'arraybuffer'
    })
  }

  async DownloadInlineWithGet(url) {
    return await axios.get(url, {
        responseType: 'arraybuffer'
      })
  }
}

export class ApiServiceExtd extends ApiService
{
  constructor(options) {
    super()
    options = options || {}

    this._configElement = document.getElementById('application-config')
    this._baseUrl = options.baseUrl || this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
  }

  async Get(uri) {
    return await super.Get(encodeURI(uri))
  }
}


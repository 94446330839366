<template>
  <div v-show="render">
    <img class="image-fluid pull-right" height="auto" width="240" src="/Content/ade-logo-only.png">
    <h4>Overview</h4>
    <p>Generally, the following applies to Arkansas's value-added growth scores aggregated to the teacher level.</p>
    <ul>
      <li>A mean value-added score equal to 80 indicates that, on average, a teachers' students are making expected growth in achievement over the school year.</li>
      <li>A mean value-added score less than 80 indicates that, on average, a teachers' students are losing ground in achievement relative to expected growth over the school year.</li>
      <li>A mean value-added score greater than 80 indicates that, on average a teacher's students are growing more than expected in achievement over the school year. </li>
    </ul>
    <p><img src="/Content/growth/range2.png" class="img-fluid mx-auto d-block" align="center" alt></p>
    <growth-and-vam-message class="clear-pad" />
    <div class="mt-2">
      <div class="figure-caption">
        Figure 1: Map of Districts by Percentage of High Impact Teachers
      </div>
      <school-district-chart :render="render" :figure="1" />
    </div>
  </div>
</template>

<script>
import GrowthAndVamMessage from '../../growthAndVamMessage.vue'
import SchoolDistrictChart from '../charts/SchoolDistrictChart.vue'

export default {
  props: {
    render: {
      type: Boolean,
      default: true
    }
  },
  components: {
    GrowthAndVamMessage,
    SchoolDistrictChart
  }
}
</script>

<style type="text/css">
.clear-pad {
  clear: right;
  margin-top: 2em;
}
</style>
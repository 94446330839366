<template>
  <div>
    <paginated-table :items="inboxItems" :fields="fields" :load-data="loadData" :tbody-tr-class="rowClass">
      <template v-slot:cell(id)="data">
        <div v-if="data.item.type == 'Proposal'">
          <router-link :to="{ name: 'CTE/PerkinsV/Application/Proposal/Edit', params: { ...data.item.routeParams} }">
            Proposal #{{ data.value }}
          </router-link>
        </div>
        <div v-if="data.item.type == 'Cover page'">
          <router-link :to="{ name: 'CTE/PerkinsV/Application/CoverPage/Edit', params: { ...data.item.routeParams} }">
            Cover Page #{{ data.value }}
          </router-link>
        </div>
      </template>
      <template v-slot:cell(status)="data">
        <div v-if="data.item.type == 'Proposal' && data.value">
          <workflow-status-badge :status="parseInt(data.value)" :state-map="ProposalStateMap" :show-label="false" />
        </div>
        <div v-if="data.item.type == 'Cover page'">
          <workflow-status-badge :status="parseInt(data.value || 0)" :state-map="CoverPageStateMap" :show-label="false" />
        </div>
      </template>
      <template v-slot:cell(action)="data">
        <b-link v-if="data.item.type == 'Proposal'" :to="{ name: 'CTE/PerkinsV/Application/Proposal/Edit', params: { ...data.item.routeParams} }">
          <i class="fas fas fa-search" />
        </b-link>
        <b-link v-if="data.item.type == 'Cover page'" :to="{ name: 'CTE/PerkinsV/Application/CoverPage/Edit', params: { ...data.item.routeParams} }">
          <i class="fas fas fa-search" />
        </b-link>
      </template>
      <template v-slot:cell(time)="data">
        <span v-if="data.value">
          {{ data.value | date }}
        </span>
      </template>
    </paginated-table>
  </div>
</template>
<script>
import { Types } from '../../stores/perkinsv'
import { ProposalStateMap, ProposalStates, CoverPageStateMap } from '../../stores/perkinsvStateMap'
import PaginatedTable from '@/components/PaginatedTable'
import WorkflowStatusBadge from '@/components/workflow/WorkflowStatusBadge.vue'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      ProposalStateMap,
      CoverPageStateMap,
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState(Types.path, [Types.state.inboxItems]),
    fields() {
      const optionalColumns = (this.userContext.districtLEA || '').length == 7 ? [] : [{ key: 'applicant' }]
      return [
        { key: 'id', label: 'Item' },
        ...optionalColumns,
        { key: 'title' },
        // { key: 'reason', label: 'Last Change' },
        { key: 'details', label: 'Summary' },
        { key: 'status' },
        { key: 'from' },
        { key: 'time' },
        { key: 'action' },
      ]
    },
  },
  props: {
    loadData: Promise
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.status == ProposalStates.ReturnedToApplicant) return 'table-warning'
      if (item.reason == 'Question') return 'table-info'
    },
  },
  components: {
    PaginatedTable,
    WorkflowStatusBadge
  }
}

</script>

<template>
  <div>
    <loadable-chart-card :load-data="loadData">
      <template v-slot:default="slotProps">
        <div class="chartElem" v-show="slotProps.chartMode">
          <highcharts class="chart" :options="chartOptions" />
        </div>
        <div v-show="!slotProps.chartMode">
          <chart-data-table :items="dataItems()" />
        </div>
      </template>
    </loadable-chart-card>
  </div>
</template>

<script>
import groupBy from 'lodash.groupby'
import TeacherSurveyCoops from './teacherSurveyCoops'
import LoadableChartCard from '../../LoadableChartCard'
import { ChartMixin } from '../../ChartMixin'
import { mapState } from 'vuex'

export default {
  name: 'teacher-survey-counts-chart',
  data: function () {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'column'
        },
        plotOptions: {
          series: {
            minPointLength: 3
          },
          column: {
            dataLabels: {
              enabled: true
            }
          }
        },
        credits: '',
        title: {
          text: 'Survey Counts by Co-op'
        },
        series: [],
        xAxis: {
          labels: {
            enabled: true
          },
          categories: TeacherSurveyCoops.map(c => c.text)
        },
        yAxis: {
          title: {
            text: "Number of Surveys"
          },
          endOnTick: true
        },
        legend: false
      }
    }
  },
  props: {
    loadData: Promise
  },
  computed: {
    coopCounts() {
      var grouped = groupBy(this.surveys, 'coopLea')

      var counts = TeacherSurveyCoops.map(coop => {
        var group = grouped[coop.value]
        return group?.length || 0
      })

      return counts
    },
    ...mapState('surveys/teacherSurvey', ['surveys']),
  },
  components: {
    LoadableChartCard
  },
  methods: {
    dataItems() {
      var grouped = groupBy(this.surveys, 'coopLea')

      var counts = TeacherSurveyCoops.map(coop => {
        var group = grouped[coop.value]
        return { coopLea: coop.value, coopName: coop.text, surveys: group?.length || 0}
      })

      return counts
    }
  },
  mixins: [ChartMixin],
  watch: {
    coopCounts: {    
      immediate: true,
      handler(newData) {
        if (newData) {
          this.chartOptions.series = [
            {
              name: 'Submitted Surveys',
              data: newData,
              colorByPoint: true
            }
          ]
        }
      }
    }
  }
}
</script>

<style>

</style>
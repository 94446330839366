<template>
  <div>
    <div class="row page-break-before">
      <div class="col-12">
        <b-tabs card content-class="mx-0 bg-light border">
          <b-tab no-body title="Matrix" active>
            <h3 class="lead p-3">
              {{ widgetTitle }}
              <b-form-checkbox v-model="showOverlay" name="check-button" switch size="sm" class="float-right d-print-none">
                Overlay budget amounts on expenses chart
              </b-form-checkbox>
            </h3>
            <!-- <h5 class="p-3">
              Drilldown: [{{ drilldown }}]
            </h5> -->
            <b-button v-if="drilldown" class="float-right" @click="onDrillUp">
              <i class="fa fa-fw fa-undo mr-2" />All Categories
            </b-button>
            <div class="mt-3" style="display: flex; flex-wrap: wrap; justify-content: center;">
              <div class="chart-flex" v-show="!showOverlay">
                <expenses-totals-chart :show-actual="false" :show-budget="true" chart-title="Budgeted Expenses" :drilldown="drilldown" :categories="codesPerCategory" @drill-down="onDrillDown" @drill-up="onDrillUp" />
              </div>
              <div class="chart-flex" v-show="!showOverlay">
                <expenses-totals-chart :show-actual="true" :show-budget="false" chart-title="Actual Expenses" :drilldown="drilldown" :categories="codesPerCategory" @drill-down="onDrillDown" @drill-up="onDrillUp" />
              </div>
              <div class="chart-flex" v-show="showOverlay">
                <expenses-totals-chart :show-actual="true" :show-budget="true" chart-title="Actual vs Budgeted Expenses" :drilldown="drilldown" :categories="codesPerCategory" @drill-down="onDrillDown" @drill-up="onDrillUp" />
              </div>
              <div class="chart-flex">
                <plan-expenses-remaining-chart :drilldown="drilldown" :categories="codesPerCategory" />
              </div>
              <div class="chart-flex">
                <plan-allocation-chart :drilldown="drilldown" :categories="codesPerCategory" />
              </div>
            </div>
            <plan-expenses-rollup-grid :categories="codesPerCategory" :drilldown="drilldown" @drill-down="onDrillDown" />
          </b-tab>
          <b-tab no-body title="Table" class="page-break-before">
            <b-button v-if="drilldown" class="mt-3 pull-right" @click="onDrillUp">
              <i class="fa fa-fw fa-undo mr-2" />All Categories
            </b-button>
            <h3 class="lead p-3">
              {{ widgetTitle }}
            </h3>
            <div class="mt-3" style="display: flex; flex-wrap: wrap; justify-content: center;">
              <div class="chart-flex">
                <plan-expenses-rollup-chart field="budgeted" title="Budget by Category" :categories="codesPerCategory" @drill-down="onDrillDown" @drill-up="onDrillUp" :drilldown="drilldown" />
              </div>
              <div class="chart-flex">
                <plan-expenses-rollup-chart field="actual" title="Expenditures by Category" :categories="codesPerCategory" @drill-down="onDrillDown" @drill-up="onDrillUp" :drilldown="drilldown" />
              </div>
              <div class="chart-flex">
                <plan-expenses-remaining-chart :drilldown="drilldown" :categories="codesPerCategory" />
              </div>
              <div class="chart-flex">
                <plan-allocation-chart :drilldown="drilldown" :categories="codesPerCategory" />
              </div>
            </div>
            <plan-expenses-rollup-table :categories="codesPerCategory" :drilldown="drilldown" @drill-down="onDrillDown" />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { Types } from '@/modules/esa/stores/'
import { mapState } from 'vuex'
import PlanExpensesRollupChart from './PlanExpensesRollupChart'
import PlanExpensesRollupTable from './PlanExpensesRollupTable'
import PlanExpensesRemainingChart from './PlanExpensesRemainingChart'
import PlanExpensesRollupGrid from './PlanExpensesRollupGrid'
import ExpensesTotalsChart from './ExpensesTotalsChart'
import PlanAllocationChart from './PlanAllocationChart'


export default {
  data() {
    return {
      drilldown: null,
      showOverlay: false,
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.planExpenses, Types.state.planCategories]),
    codesPerCategory() {
      if (this.planCategories)
        return this.planCategories.map(c => { 
          return {
            ...c, 
            allCodes: c.types.flatMap(t => t.codes)
          }
      })
      return []
    },
    widgetTitle() {
      if (this.drilldown) return `Expenses for ${this.drilldown} ${this.drilldownDescription}`
      else return 'All Expenses'
    },
    drilldownDescription() {
      const category = this.planCategories.find(c => c.name == this.drilldown)
      if (category.description) {
        return `(${category.description})`
      }
      return ''
    }
  },
  methods: {
    onDrillDown(name) {
      this.drilldown = name
    },
    onDrillUp() {
      this.drilldown = null
    }
  },
  components: {
    PlanExpensesRollupChart,
    PlanExpensesRollupTable,
    PlanExpensesRemainingChart,
    PlanExpensesRollupGrid,
    ExpensesTotalsChart,
    PlanAllocationChart,
  },
}

</script>

<style>
  .chart-flex {
    padding: 5px;
    flex-basis: 38rem;
  }
</style>
<template>
  <div class="course-list">
    <table class="table condensed table-striped table-bordered mb-0" v-if="subjectGradeCrossTab && subjectGradeCrossTab.length > 0">
      <thead>
        <tr>
          <th>Grade</th>
          <th class="text-center" v-for="gradeLevel in gradeLevels" :key="gradeLevel">
            {{ gradeLevel }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="subject in subjects" :key="subject">
          <td><subject-tag :subject="subject" /></td>
          <td class="text-center" v-for="gradeLevel in gradeLevels" :key="gradeLevel">
            {{ getNumberStudents(subject, gradeLevel) }}
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
        <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SubjectTag from './SubjectTag.vue'
import CardStateIcon from '@/components/CardStateIcon.vue'

export default {
  data() {
    return {     
    }
  },
  components: {
    SubjectTag,
    CardStateIcon,
  },
  methods: {
    getNumberStudents(subject, gradeLevel) {
      const item = this.cleanedData.find(item => item.subject === subject && item.gradeLevel === gradeLevel)
      // format value with thousands separator
      return item ? item.numberStudents.toLocaleString() : '-'
    },
  },
  computed: {
    ...mapState('rvsModule', ['subjectGradeCrossTab']),
    cleanedData() {
      // filter out rows that have gradeLevel = 'SS' or '13' or 'EE' or 'SM'
      // filter out rows that have subject = ''
      // format gradeLevels that have a leading zero to be just a number
      var rows = this.subjectGradeCrossTab.filter(item => item.gradeLevel !== 'SS'
                                                       && item.gradeLevel !== '13'
                                                       && item.gradeLevel !== 'EE'
                                                       && item.gradeLevel !== 'SM'
                                                       && item.gradeLevel != 'P'
                                                       && item.subject)
      rows = rows.map(item => {
        if (item.gradeLevel.length === 2 && item.gradeLevel[0] === '0') item.gradeLevel = item.gradeLevel[1]
        return item
      })
      return rows
    },
    gradeLevels() {
      // sort the values found in this order: P, K, 01, 02, 03, 04, 05, 06, 07, 08, 09, 10, 11, 12
      var gradeLevels = [...new Set(this.cleanedData.map(item => item.gradeLevel))]
      gradeLevels.sort((a, b) => {
        if (a === 'K') return -1
        if (b === 'K') return 1
        return a - b
      })
      return gradeLevels
    },
    subjects() {
      // sort the values found in this order: 'MATH', 'ALG', 'GEO', 'Algebra EOC', 'Geometry EOC', 'SCI', 'BIO', 'Biology EOC', 'ELA'
      var subjects = [...new Set(this.cleanedData.map(item => item.subject))]
      subjects.sort((a, b) => {
        if (a === 'MATH') return -1
        if (b === 'MATH') return 1
        if (a === 'ALG') return -1
        if (b === 'ALG') return 1
        if (a === 'GEO') return -1
        if (b === 'GEO') return 1
        if (a === 'Algebra EOC') return -1
        if (b === 'Algebra EOC') return 1
        if (a === 'Geometry EOC') return -1
        if (b === 'Geometry EOC') return 1
        if (a === 'SCI') return -1
        if (b === 'SCI') return 1
        if (a === 'BIO') return -1
        if (b === 'BIO') return 1
        if (a === 'Biology EOC') return -1
        if (b === 'Biology EOC') return 1
        if (a === 'ELA') return -1
        if (b === 'ELA') return 1
        return 0
      })
      return subjects
    },
  }
}
</script>
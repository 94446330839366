<template>
  <div v-if="teacherVAMData">
    <home :render="render" />
    <subject :render="render" class="page-break-before" />
    <letter-grade :render="render" class="page-break-before" />
    <n-b-c :render="render" class="page-break-before" />
    <data-and-trend :render="render" class="page-break-before" :use-filter-from-store="true" v-if="(selectedLea || {}).number != 'AR'" />
  </div>
</template>

<script>
import Home from './Home.vue'
import Subject from './Subject.vue'
import LetterGrade from './LetterGrade.vue'
import NBC from './NBC.vue'
import DataAndTrend from './DataAndTrend.vue'

import { mapState } from 'vuex';

export default {
  components: {
    Home,
    Subject,
    LetterGrade,
    NBC,
    DataAndTrend
  },
  props: {
    render: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('teacherVAMModule', ['teacherVAMData']),
  }
}
</script>
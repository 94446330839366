<template>
  <div v-if="floatVal" style="display: inline">
    <i class="fa fa-plus-circle" style="color: green" v-if="floatVal > 0" />
    <i class="fa fa-circle" style="color: black" v-else-if="floatVal == 0" />
    <i class="fa fa-minus-circle" style="color: red" v-else-if="floatVal < 0" />
  </div>
</template>

<script>
export default {
  name: 'sign-indicator',
  data() {
    return {}
  },
  computed: {
    floatVal() { return parseFloat(this.value || '') }
  },
  props: {
    value: [String, Number]
  }
}
</script>
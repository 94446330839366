<template> 
  <div v-if="timelineDates && timelineDates.length > 0">
    <b-card no-body header-class="header-padding-small">
      <template #header>
        <b-row no-gutters>
          <b-col class="header-padding-normal">
            Timeline
          </b-col>
          <b-col cols="auto" style="padding-top: 0.25rem; padding-right: 0.75rem;">
            <b-button size="sm" v-b-toggle.collapse-2 class="m-1" variant="light">
              Toggle details
            </b-button>
          </b-col>        
        </b-row>
      </template>   
      <div class="m-2">
        <b-progress height="4rem" :max="100" show-value>
          <b-progress-bar overflow-wrap: break-word :value="(100/6)" variant="success">
            <span white-space:normal>Roster Verification System Toolkit</span>
            <span white-space:normal>and Pilot Trainings</span>
          </b-progress-bar>
          <b-progress-bar :value="(100/6)" variant="info">
            <span white-space:normal>School Log-In</span>
            <span white-space:normal>required</span>
          </b-progress-bar>
          <b-progress-bar :value="(100/6)" variant="warning">
            <span white-space:normal>Teacher-Student</span>
            <span white-space:normal>Data Linkage Period</span>
          </b-progress-bar>
          <b-progress-bar :value="(100/6)" variant="danger">
            <span white-space:normal>Principal/Assistant</span>
            <span white-space:normal>Review Period</span>
          </b-progress-bar>
          <b-progress-bar :value="(100/6)" variant="primary">
            <span white-space:normal>Superintendent/Assistant</span>
            <span white-space:normal>Sign-Off Period </span>
          </b-progress-bar>
          <b-progress-bar :value="(100/6)" variant="secondary">
            <span white-space:normal>Teacher Value-Added Measures</span>
            <span white-space:normal>(TVAMs) and Growth Reports</span>
          </b-progress-bar>
        </b-progress>
        <b-card-group>
          <b-card class="mb-1 text-center">
            <div>
              {{ this.timelineDates[0].date }}
            </div>
          </b-card>
          <b-card class="mb-1 text-center">
            <b-card-text>
              {{ this.timelineDates[1].date }}
            </b-card-text>
          </b-card>
          <b-card class="mb-1 text-center">
            <b-card-text>
              {{ this.timelineDates[2].date }}
            </b-card-text>
          </b-card>
          <b-card class="mb-1 text-center">
            <b-card-text>
              {{ this.timelineDates[3].date }}
            </b-card-text>
          </b-card>
          <b-card class="mb-1 text-center">
            <b-card-text>
              {{ this.timelineDates[4].date }}
            </b-card-text>
          </b-card>
          <b-card class="mb-1 text-center">
            <b-card-text>
              {{ this.timelineDates[5].date }}
            </b-card-text>
          </b-card>
        </b-card-group>
        <b-collapse id="collapse-2">
          <b-card-group>
            <b-card class="mb-1">
              <div>
                Teachers and principals can access state prepared learning modules and user guides to support training
              </div>
            </b-card>
            <b-card class="mb-1">
              <b-card-text>
                Teachers and principals can log in to the Roster Verification System to verify staff lists and staff role assignments 
              </b-card-text>
            </b-card>
            <b-card class="mb-1">
              <b-card-text>
                Teachers can log in to review their class rosters
                <br>
                <br>Principals/support teams should:
                <br>• Monitor teacher completion
                <br>• Review submitted classes and approve them
                <br>• Monitor alerts
              </b-card-text>
            </b-card>
            <b-card class="mb-1">
              <b-card-text>
                Principals should:
                <br>• Perform final review of classes
                <br>• Review staff instructional responsibilities and alerts before giving final approval.
              </b-card-text>
            </b-card>
            <b-card class="mb-1">
              <b-card-text>
                Superintendents:
                <br>• Final Sign-Off needed for all schools within their district
              </b-card-text>
            </b-card>
            <b-card class="mb-1">
              <b-card-text>
                Superintendents, Principals and Teachers can log in to ADE's Insights Portal to access the 2023 teacher value-added reports
              </b-card-text>
            </b-card>
          </b-card-group>
        </b-collapse>
      </div>   
    </b-card>
  </div>
</template>


<script>
import { mapState } from 'vuex';
export default {
  data() {
      return {
        loading: null
      }
    },
  computed: {
    ...mapState('rvsModule', ['timelineDates']),
  }
}
</script>

<style lang="scss" scoped>
.header-padding-small {
  padding: 0rem;
}
.header-padding-normal {
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
}
</style>
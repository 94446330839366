<template>
  <div class="m-0">
    <div :class="valid ? 'card' : 'card border border-danger'">
      <div class="card-header d-flex justify-content-between align-items-center">
        Assigned to Schools:
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <b-form-group label-class="font-weight-bold" label="Assigned School(s)">
              <v-select v-model="newLea" :reduce="lea => lea.code" :options="schoolLeaOptions" placeholder="Selection of one or more school LEAs is required" />
            </b-form-group>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <b-button variant="primary" @click="addLeaRow" :disabled="!canAdd">
              <i class="fa fa-fw fa-plus-circle" /> Add LEA(s)
            </b-button>
          </div>
        </div>
        <div class="row m-1" v-show="local.length > 0">
          <b-table :items="local" :fields="fields">
            <template v-slot:cell(lea)="data">
              <span class="badge badge-info mr-1">
                {{ leaFormatter(data.value) }}
              </span>
            </template>
            <template v-slot:cell(action)="data">
              <div class="text-right">
                <b-button variant="link" @click="removeLeaRow(data.index)">
                  <i class="fas fa-fw fa-trash text-danger" />
                </b-button>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { JsonResponseMixin } from '@/components/JsonResponseMixin'
import { mapGetters } from 'vuex'

// function convertToSelectItem (key, value) {
//   return { code: key, label: value }
// }

export default {
  data() {
    return {
      newLea: '',
    }
  },
  props: {
    district: {
      type: String,
      required: false,
      default: ''
    }
  },
  mixins: [JsonResponseMixin],
  computed: {
    ...mapGetters('globalModule', ['districts']),
    fields() {
      return [
        { key: 'lea', label: 'LEA(s)' },
        { key: 'action', thClass: 'text-right' }
      ]
    },
    schoolLeaOptions() {
      const districts = this.district != '' ? this.districts().filter(d => d.number == this.district || d.parentLea.number == this.district) : this.districts()

      return districts
      .flatMap(d => d.ownedLeas)
      .filter(s => !this.local.some(l => l.lea == s.number))
      .map(school => {
        return { code: school.number, label: `${school.number} - ${school.name}` }
      })
    },
    canAdd() {
      return this.newLea && this.newLea.length > 0 
    }
  },
  methods: {
    addLeaRow() {
      if (!this.local) this.local = []

      this.local.push({ lea: this.newLea})
      this.newLea = ''
    },
    removeLeaRow(leaRowIndex) {
      if(confirm(`Are you sure you want to remove these assigned LEA(s)?`)) {
        this.local = this.local.filter((value, index) => index != leaRowIndex)
      }
    },
  },
}
</script>

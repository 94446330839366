<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '@/components/ChartMixin'
import { credits } from '@/helpers/credits'

export default {
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'column'
        },
        credits: credits.SIS,
        title: {
          text: this.chartTitle
        },
        series: [
          {
            name: this.chartTitle,
            data: [
              {
                x: 2014,
                y: 3.34
              }
            ]
          }
        ],
        xAxis: {
          title: {
            text: 'School Year'
          },
          type: 'category'
        },
        yAxis: {
          title: {
            text: '# Employed'
          },
          endOnTick: false
        }
      }
    }
  },
  mixins: [ChartMixin],
  props: {
    staffData: Array,
    chartTitle: String,
  },
  methods: {
    dataItems() {
      return this.chartOptions.series[0].data.map(data => {
        return {
          SchoolYear: data.x,
          Total: data.y
        }
      })
    },
    excelExportData() {
      return {
        name: this.chartTitle,
        items: this.dataItems()
      }
    }
  },
  watch: {
    staffData(newData) {
      this.chartOptions.series = []
      this.chartOptions.series = [{
        name: this.chartTitle,
        data: [],
      }]
      var seriesData = newData.map(row => {
        return {
          x: (row.fiscalYear % 1990) + 1990,
          y: row['total']
        }
      })
      this.chartOptions.series[0].data = seriesData
    }
  }
}
</script>

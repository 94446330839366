<template>
  <div>
    <ul v-if="userContext.hasRole('ADE User')" class="nav nav-pills flex-column ml-2 mr-2">
      <li class="nav-item ml-2">
        <router-link
          class="nav-link"
          active-class="active"
          :to="{path: '/transportation/buses'}">
          <i class="px-1 text-secondary fas fa-bus" /> Bus Inventory 
        </router-link>
      </li>
      <li class="nav-item ml-2">
        <router-link
          class="nav-link"
          active-class="active"
          :to="{path: '/transportation/batches'}">
          <i class="px-1 text-secondary fas fa-search" /> Inspections
        </router-link>
      </li>
      <li class="nav-item ml-2">
        <a class="nav-link" href="/transportation/inspect"><i class="px-1 text-secondary fas fa-mobile-alt" /> iPad View</a> 
        <!-- 
        <router-link
          class="nav-link"
          active-class="active"
          :to="{ name: 'get-mobile-transportation-link'}">
          <i class="px-1 text-secondary fas fa-mobile-alt" /> Go Mobile 
        </router-link> -->
      </li>
    </ul>

    <ul v-else class="nav nav-pills flex-column ml-2 mr-2">
      <li class="nav-item ml-2 d-none">
        <router-link
          class="nav-link"
          active-class="active"
          :to="{path: `/transportation/${$route.query.lea}/dashboard`}">
          District Dashboard
        </router-link>
      </li>
      <li class="nav-item ml-2">
        <router-link 
          class="nav-link"
          active-class="active"
          :to="{name: 'Transportation/Inspections/District/Notifications/Unacknowledged', params: { districtLea: $route.query.lea }}">
          <i class="px-1 text-secondary fa fa-envelope" aria-hidden="true" /> Unacknowledged Notifications
        </router-link>
      </li>
      <li class="nav-item ml-2">
        <router-link
          class="nav-link"
          active-class="active"
          :to="{name: 'Transportation/Inspections/District/Notifications/Acknowledged', params: { districtLea: $route.query.lea }}">
          <i class="px-1 text-secondary fa fa-envelope-open" aria-hidden="true" /> Acknowledged Notifications
        </router-link>
      </li>
      <li class="nav-item ml-2">
        <router-link
          class="nav-link"
          active-class="active"
          :to="{path: `/transportation/${$route.query.lea}/buses`}">
          <i class="px-1 text-secondary fas fa-bus" aria-hidden="true" /> District Bus Inventory 
        </router-link>
      </li>
      <li class="nav-item ml-2">
        <router-link
          class="nav-link"
          active-class="active"
          :to="{path: `/transportation/${$route.query.lea}/batches/all`}">
          <i class="px-1 text-secondary fa fa-check-circle" aria-hidden="true" /> Completed Inspections 
        </router-link>
      </li>
    </ul>
    <ul class="nav nav-pills flex-column ml-2 mr-2">
      <li class="nav-item ml-2">
        <a href="/content/dpsaft/documentation/insights_bus_inspection_module.pdf" target="_blank" class="nav-link"> <i class="px-1 text-secondary fa fa-question-circle" aria-hidden="true" />Bus Inspections User Manual</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'sidebar',
  computed: {
    ...mapState('globalModule', ['userContext'])
  }
}
</script>
<style  scoped>
  ::v-deep .nav-link.active .text-secondary {
    color: #ffffff !important
  }
</style>
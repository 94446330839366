<template>
  <div v-show="render">
    <div>
      <h4 class="text-dark">         
        Data &amp; Trend
      </h4>
    </div>

    <div v-if="teacherVAMData">
      <raw-data-table :render="render" :use-filter-from-store="useFilterFromStore" :show-filter="true" />
    </div>
    <div v-else>
      <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
        <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CardStateIcon from '../../../CardStateIcon.vue'
import RawDataTable from '../tables/RawDataTable.vue'

export default {
  data() {
    return {
      
    }
  },
  components: {
    CardStateIcon,   
    RawDataTable
  },
  props: {
    render: {
      type: Boolean,
      default: true
    },
    useFilterFromStore: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('teacherVAMModule', ['teacherVAMData']),
  }
}
</script>
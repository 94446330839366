<template>
  <div style="height: 100%;">
    <div class="sidebar-scroll">
      <ul class="nav nav-pills flex-column ml-2 mr-2">
        <li class="nav-item">
          <router-link :to="{name: 'esa-plan-dashboard'}" class="nav-link" active-class="active">
            <i class="fas fa-chart-line fa-fw mr-1" />ESA Plan Dashboard
          </router-link>
        </li>
        <li class="nav-item mt-1">
          <span class="nav-link font-weight-bold h5" :class="(!showInfo && isNeedsAssessmentRoute) ? 'cursor-pointer' : ''" @click="expandInfo">
            Needs Assessment
            <i class="text-info fas fa-info-circle " v-show="!showInfo && isNeedsAssessmentRoute" />
          </span>
        </li>
        <li class="nav-item">
          <router-link :to="{name: 'esa-survey-academicachievement'}" class="nav-link" active-class="active">
            <i class="fas fa-medal fa-fw mr-1" />Academic Achievement
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{name: 'esa-survey-academicprogress'}" class="nav-link" active-class="active">
            <i class="fas fa-chart-line fa-fw mr-1" />Academic Progress
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{name: 'esa-survey-staffing'}" class="nav-link" active-class="active">
            <i class="fas fa-chalkboard-teacher fa-fw mr-1" />Staffing
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{name: 'esa-survey-studentsupports'}" class="nav-link" active-class="active">
            <i class="fas fa-child fa-fw mr-1" />Student Supports
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{name: 'esa-survey-postsecondaryreadiness'}" class="nav-link" active-class="active">
            <i class="fas fa-graduation-cap fa-fw mr-1" />Post-secondary Readiness
          </router-link>
        </li>
        <li class="nav-item mt-1">
          <span class="nav-link font-weight-bold h5">
            Plan
          </span>
        </li>
        <li class="nav-item">
          <router-link :to="{name: 'esa-plan-district'}" class="nav-link" active-class="active">
            <i class="fas fa-file-invoice-dollar fa-fw mr-1" />District ESA Plan
          </router-link>
        </li>
        <li class="nav-item mt-1">
          <span class="nav-link font-weight-bold h5">
            Resources
          </span>
        </li>
        <li class="nav-item">
          <router-link :to="{name: 'esa-resources-program-codes'}" class="nav-link" active-class="active">
            <i class="fas fa-money-check-alt fa-fw mr-1" /> Program Codes
          </router-link>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/Content/esa/Act322.pdf" target="_blank">
            <i class="fas fa-file-pdf fa-fw mr-1" />Act 322 of 2021
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/Content/esa/Insights-ESA-FAQ.pdf" target="_blank">
            <i class="fas fa-question-circle fa-fw mr-1" />FAQ
          </a>
        </li>
        <li class="nav-item" v-if="!isAdmin">
          <router-link :to="{name: 'plan-category-list'}" class="nav-link" active-class="active">
            <i class="fas fa-tags fa-fw mr-1" /> Plan Categories
          </router-link>
        </li>
        <li class="nav-item mt-1" v-if="isESARole">
          <span class="nav-link font-weight-bold h5">
            Admin
          </span>
        </li>
        <li class="nav-item" v-if="isESARole">
          <router-link :to="{name: 'esa-plan-list'}" class="nav-link" active-class="active">
            <i class="fas fa-file-invoice-dollar fa-fw mr-1" />District ESA Plan List
          </router-link>
        </li>
        <li class="nav-item" v-if="isAdmin">
          <router-link :to="{name: 'plan-category-list'}" class="nav-link" active-class="active">
            <i class="fas fa-tags fa-fw mr-1" /> Plan Categories Management
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { LeaTypes } from '@/helpers/leaTypes'
  import { mapState } from 'vuex'
  import { Roles } from '../shared/enums'
  import { Types as SurveyTypes } from '@/modules/esa/stores/survey'

  export default {
    data() {
      return {
        LeaTypes
      }
    },
    computed:{
      ...mapState(SurveyTypes.path, [SurveyTypes.state.showInfo]),
      ...mapState('globalModule', ['userContext']),
      isESARole() {
        const roles = this.userContext.roles || []
        return roles.includes(Roles.Reviewer) 
         || roles.includes(Roles.Viewer)
         || this.isAdmin
      },
      isAdmin() {
        const roles = this.userContext.roles || []
        return roles.includes(Roles.Admin) ||
          roles.includes(Roles.SystemAdministrator)
      },
      isNeedsAssessmentRoute() {
        return this.$route.path.toLowerCase().includes('/survey/')
      },
    },
    methods: {
      expandInfo() {
        if (this.isNeedsAssessmentRoute) {
          this.$store.commit(SurveyTypes.mutations.setShowInfo, true)
        }
      },
    },
  }
</script>

<style>
  .svg-icon {
    height: 1.25rem;
    width: 1.25rem;
    top: -0.25em;
    position: relative;
    display: inline-flex;
    align-self: center;
    vertical-align: bottom;
    fill: #6c757d;
  }
</style>
<template>
  <div>
    <pdf-button :additional-query-string-params="`&fiscalYear=${selectedFiscalYear}`" />
    <on-demand-excel-button title="On-demand" />
    <b-form-select style="width: 8em" class="float-right mx-1" v-model="selectedFiscalYear" :options="fiscalYears" />
    <label class="float-right mx-1 mt-2">Trend School Year:</label>
    <h1>On-Demand</h1>
    <h2><lea-display :lea="selectedLea" /></h2>
    <b-alert variant="danger" show>
      <i class="fas fa-exclamation-triangle text-danger m-1" />
      <strong>Disclaimer:</strong> The Insights On-Demand module is only used for internal ADE Division of Elementary (DESE) leadership team and Education Service Co-operatives (Co-operative Directors) for data insights and trend analysis leading to the school year, {{ parseInt(selectedFiscalYear || 0) + 1989 }}-{{ parseInt(selectedFiscalYear || 0) + 1990 }} until ADE receives the certified data from the LEAs.
      The data from this module 
      <u>should not be used</u> to fulfil any State (Legislative), Federal, Media/Press, and Ad-hoc reporting. 
      For State (Legislative), Federal, Media/Press, and Ad-hoc reporting &ndash; please continue to use
      <a href="https://myschoolinfo.arkansas.gov/">My School Info</a>, <a href="https://adedata.arkansas.gov/statewide/">Statewide Information System Reports</a> (on ADE Data Center), 
      and <a href="https://insight.ade.arkansas.gov/">LEA &ndash; Insights</a> (Overview section) based on data certified by the Statewide Information System &ndash; Cycle submission data by the LEAs (District cycle coordinators).
    </b-alert>
    <div class="card-body p-0">
      <b-tabs card class="p-0 bg-light">
        <b-tab title="Current Data" :active="activeTab == 'currentData'">
          <div class="py-3">
            <h3>Instructional Options</h3>
          </div>
          <instructional-option-dashboard-current-widget :selected-lea="selectedLea" />
          <div class="py-3 page-break-before">
            <h3>Enrollment</h3>
          </div>
          <enrollment-dashboard-current-widget :selected-lea="selectedLea" />
        </b-tab>
        <b-tab title="Trend" :active="activeTab == 'trend'">
          <div class="py-3 page-break-before">
            <h3>Instructional Options</h3>
          </div>
          <instructional-option-dashboard-trend-widget :selected-lea="selectedLea" />
          <div class="py-3 page-break-before">
            <h3>Enrollment</h3>
          </div>
          <enrollment-dashboard-trend-widget :selected-lea="selectedLea" />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import InstructionalOptionDashboardCurrentWidget from './instructionalOption/InstructionalOptionDashboardCurrentWidget.vue'
import InstructionalOptionDashboardTrendWidget from './instructionalOption/InstructionalOptionDashboardTrendWidget.vue'

import EnrollmentDashboardCurrentWidget from './enrollment/EnrollmentDashboardCurrentWidget.vue'
import EnrollmentDashboardTrendWidget from './enrollment/EnrollmentDashboardTrendWidget.vue'

import { PageMixin } from '../PageMixin'
import OnDemandExcelButton from './OnDemandExcelButton.vue'
import { mapState } from 'vuex'
import { Types } from '@/stores/onDemandModule'
// import store from '@/stores'

export default {
  mixins: [PageMixin],
  data() {
    return {
      activeTab: 'currentData'
    }
  },
  beforeMount() {
    this.activeTab = this.$route.hash.replace('#', '')
    const beforePromise = (this.$store.state.onDemandModule.fiscalYears || []).length == 0 ? this.$store.dispatch(Types.actions.getFiscalYears) : Promise.resolve(this.$store.state.onDemandModule.fiscalYears)
    beforePromise.then(data => {
      if (!this.$route.query.fiscalYear) {
        const fixedRoute = { name: 'on-demand', query: {...this.$route.query, fiscalYear: data[0].value} }
        this.$store.commit(Types.mutations.setSelectedFiscalYear, data[0].value)
        this.$router.push(fixedRoute)
      }
      this.$store.commit(Types.mutations.setSelectedFiscalYear, this.$route.query.fiscalYear)
    })
  },
  computed: {
    ...mapState(Types.path, [Types.state.fiscalYears]),
    selectedFiscalYear: {
      set(fiscalYear) {
        this.$store.commit(Types.mutations.setSelectedFiscalYear, fiscalYear)
      },
      get() {
        return this.$store.state.onDemandModule.selectedFiscalYear || this.$route.query.fiscalYear
      }
    },
  },
  components: {
    EnrollmentDashboardCurrentWidget,
    EnrollmentDashboardTrendWidget,
    InstructionalOptionDashboardCurrentWidget,
    InstructionalOptionDashboardTrendWidget,
    OnDemandExcelButton
  },
  
}
</script>
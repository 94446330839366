import ApiService from '../../services/ApiService'

export class RequestModule {

  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
    this._fiscalYear = parseInt(this._configElement.getAttribute('data-fiscal-year'))
  }

  namespaced = true

  state = {
    metadata: {},
    requests: [],
    summary: {
      status: {
        draft: 0,
        pending: 0,
        inReview: 0,
        completed: 0
      },
      disposition: {
        approved: 0,
        rejected: 0
      },
      operation: {
        new: 0,
        inactivate: 0,
        change: 0
      }
    },
    availableStates: {}
  }

  mutations = {
    setMetadata(state, metadata) {
      state.metadata = metadata
    },
    setRequests(state, requests) {
      state.requests = requests
    },
    setRequestSummary(state, summary){
      Object.assign(state.summary, summary.summary),
      state.requests = summary.requests
    },
    setAvailableStates(state, availableStates) {
      state.availableStates = availableStates
    }
  }

  getters = {
    potentialStateCount: state => {
      return (state.availableStates.advanceStates || []).length + (state.availableStates.returnStates || []).length
    }
  }

  actions = {
    getRequestTypeMetadata: ({
      commit
    }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/request/metadata/${payload.requestTypeId}`))
        .then(data => commit('setMetadata', data))
    },
    getRequestSummary: ({ commit }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/request/summary/${payload.lea}?type=${payload.type}`))
        .then(data => commit('setRequestSummary', data))
    },    
    getAvailableStates: ({
      commit
    }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/request/${payload.requestId}/state`))
        .then(data => commit('setAvailableStates', data))
    },
    createRequestComment: (_context, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/request/${payload.requestId}/comment`), {
        text: payload.text
      })
    },
    createRequestLea: (_context, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/request/${payload.requestId}/lea`), {
        lea: payload.lea,
        operationType: payload.operationType,
        operationParams: payload.operationParams,
      })
    },
    transitionToState: (_context, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/request/${payload.requestId}/state`), {
        state: payload.toState,
        comment: payload.comment
      })
    },
    issueRecall: (_context, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/request/${payload.requestId}/recall`), {
          comment: payload.comment
      })
    },
    assignToFiscalYear: (_context, payload) => {
      return this._apiService.Patch(encodeURI(`${this._baseUrl}api/request/${payload.requestId}/apply-to-fy/${payload.fiscalYear}`))
    },
    addressRecall: (_context, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/request/${payload.requestId}/address-recall`), {
          grantRecall: payload.grantRecall,
          state: payload.targetState,
          comment: payload.comment
        }
      )
    },
  }
}
import ApiService from '@/services/ApiService'
import removeNamespace from '@/stores/removeNamespace'
import { createGetTransaction } from '@/stores/VuexTransaction'

export const Types = {
  state: {
    revenueAccountDescriptions: 'revenueAccountDescriptions',
    expenseAccountDescriptions: 'expenseAccountDescriptions',
    balances: 'balances',
    budgetSummaries: 'budgetSummaries',
    revenueSummary: 'revenueSummary',
    expenditureSummary: 'expenditureSummary',
  },
  actions: {
    getRevenueAccountDescriptions: 'Purple/getRevenueAccountDescriptions',
    getExpenseAccountDescriptions: 'Purple/getExpenseAccountDescriptions',
    getBalances: 'Purple/GetBeginningBalance',
    getBudgetSummaries: 'Purple/GetBudgetSummaries',
    getRevenueSummary: 'Purple/GetRevenuePivot',
    getExpenditureSummary: 'Purple/GetExpenditurePivot',
  },
  mutations: {
    setRevenueAccountDescriptions: 'Purple/setRevenueAccountDescriptions',
    setExpenseAccountDescriptions: 'Purple/setExpenseAccountDescriptions',
    setBalances: 'Purple/setBalances',
    setBudgetSummaries: 'Purple/setBudgetSummaries',
    setRevenueSummary: 'Purple/setRevenueSummary',
    setExpenditureSummary: 'Purple/setExpenditure',
  },
  path: 'Purple'
}
const _types = removeNamespace('Purple/', Types)

export class Purple {
  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''

    this.createGetTransaction = createGetTransaction
    
    const purpleBaseUrl = `${this._baseUrl}api/purple`//= window.userContext.isAuthenticated ? `${this._baseUrl}api/purple` : `${this._baseUrl}api/purple/public`

    this.createGetTransaction(_types.state.revenueAccountDescriptions,
      _types.mutations.setRevenueAccountDescriptions,
      _types.actions.getRevenueAccountDescriptions,
      () => `${purpleBaseUrl}/revenue-account-descriptions`
    )
    this.createGetTransaction(_types.state.expenseAccountDescriptions,
      _types.mutations.setExpenseAccountDescriptions,
      _types.actions.getExpenseAccountDescriptions,
      () => `${purpleBaseUrl}/expense-account-descriptions`
    )
    this.createGetTransaction(_types.state.balances,
      _types.mutations.setBalances,
      _types.actions.getBalances,
      (payload) => `${purpleBaseUrl}/${payload.lea}/beginning-balance`
    )
    this.createGetTransaction(_types.state.budgetSummaries,
      _types.mutations.setBudgetSummaries,
      _types.actions.getBudgetSummaries,
      (payload) => `${purpleBaseUrl}/${payload.lea}/budget-summaries`
    )
    this.createGetTransaction(_types.state.revenueSummary,
      _types.mutations.setRevenueSummary,
      _types.actions.getRevenueSummary,
      (payload) => `${purpleBaseUrl}/${payload.lea}/revenue-pivot`
    )
    this.createGetTransaction(_types.state.expenditureSummary,
      _types.mutations.setExpenditureSummary,
      _types.actions.getExpenditureSummary,
      (payload) => `${purpleBaseUrl}/${payload.lea}/expenditure-pivot`
    )
  }

  namespaced = true
  
  modules = {
  }

  state = {
  }

  mutations = {
  }

  actions = {
  }

  modules = {
  }
}
import ApiService from '../../services/ApiService'
import { Facilities } from './facilities'
import { Trans } from './trans'
import { Admin } from './admin'
import { getDPSAFTfilterQueryString } from '../../helpers/globalHelpers'

function createSelectItem(lea) {
  return {
    value: lea.number,
    text: createLeaText(lea.number, lea.name)
  }
}

function createLeaText(number, name) {
  return `${number} - ${name}`
}

function leaSortCompare(lea1, lea2) {
  if (lea1.value < lea2.value)
    return -1
  else if (lea1.value > lea2.value)
    return 1
  return 0
}

export class DPSAFT {

  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
    this._fiscalYear = parseInt(this._configElement.getAttribute('data-fiscal-year'))
    this._isMobile = this._configElement.getAttribute('data-is-mobile')
  }

  path = 'DPSAFT'

  namespaced = true

  state = {
    areaManagers: {
      facilitiesPlanningAndConstructionManagers: [],
      facilitiesMaintenanceManagers: [],
      transportationManagers: []
    },
    transportationStaff: {
      trainers: [],
      inspectors: []
    },
    districtTransportationStaff: [],
    areas: [],
    districts: [],
    buses: [],
    buildings: [],
    coopsAndBuildings: [],
    filter: {
      area: window.userContext.areaId ? window.userContext.areaId : null,
      coop: null,
      district: (window.userContext.districtLEA && window.userContext.districtLEA.trim().length == 7) ? window.userContext.districtLEA.trim() : null,
      building: null,
      fy: null
    },
    contactList: [],
    assignedDistricts: [],
    assignedAreas: [],
    selectedAreaId: null,
    stateSummary: {}
  }

  mutations = {
    setAreaManagers: (state, data) => state.areaManagers = data,
    setDistrictTransportationStaff: (state, data) => state.districtTransportationStaff = data,
    setTransportationStaff: (state, data) => state.transportationStaff = data,
    setFilterArea: (state, data) => {
      state.filter.area = data
      state.filter.coop = null
      state.filter.district = null
      state.filter.building = null
    },
    setFilterCoop: (state, data) => {
      state.filter.coop = data
      state.filter.district = null
      state.filter.building = null
    },
    setFilterDistrict: (state, data) => {
      state.filter.district = data
      state.filter.building = null
    },
    setFilterBuilding: (state, data) => state.filter.building = data,
    setFilterFiscalYear: (state, data) => state.filter.fy = data,
    setAreas: (state, data) => state.areas = data,
    setBuses: (state, data) => state.buses = data,
    setContactList: (state, data) => {
      state.contactList = data
    },
    setBuildings: (state, data) => state.buildings = data,
    setAssignedDistricts: (state, data) => state.assignedDistricts = data,
    setAssignedAreas: (state, data) => state.assignedAreas = data,
    setSelectedAreaId: (state, data) => state.selectedAreaId = data,
    setCoopsAndBuildings: (state, data) => state.coopsAndBuildings = data,
    setStateSummary: (state, data) => state.stateSummary = data
  }

  getters = {
    areas(state) {
      return [{
        value: null,
        text: 'Any Area'
      }].concat(state.areas.map(a => {
        return {
          value: a.areaId,
          text: a.name
        }
      }))
    },
    sortedContactList: (state) => (sortFunction) => state.contactList.slice().sort(sortFunction),
    facilitiesDistricts(state, getters, rootState, rootGetters){
      return [{
        value: null,
        text: 'Any District'
      }].concat(rootGetters['globalModule/districts']()
      .filter(d => d.moArea == state.filter.area)
      .map(createSelectItem)
      .sort(leaSortCompare))
    },
    facilitiesDistrictsByName(state, getters, rootState, rootGetters){
      return [{
        value: null,
        text: 'Any District'
      }].concat(rootGetters['globalModule/districts']()
      .filter(d => d.moArea == state.filter.area)
      .map(createSelectItem)
      .sort((a,b) => (a.text.localeCompare(b.text))))
    },
    transportationDistricts(state, getters, rootState, rootGetters) {
      return [{
        value: null,
        text: 'Any District'
      }].concat(rootGetters['globalModule/districts']()
      .map(createSelectItem)
      .sort(leaSortCompare))
    }, 
    transportationDistrictsByName(state, getters, rootState, rootGetters){
      return [{
        value: null,
        text: 'Any District'
      }].concat(rootGetters['globalModule/districts']()
      .sort((a,b) => (a.name.localeCompare(b.name)))
      .map(createSelectItem))
    },
    coops(state, getters, rootState, rootGetters) {
      if (state.filter.area && state.areas.length > 0) {
        return [{
          value: null,
          text: 'Any Coop'
        }].concat(state.areas.find(a => a.areaId == state.filter.area).cooperatives.map(c => {
          return {
            value: c.coopLea,
            text: createLeaText(c.coopLea, rootGetters['globalModule/leaDictionary'][c.coopLea].name)
          }
        }))
      }
      return [{
        value: null,
        text: 'Any Coop'
      }].concat(rootGetters['globalModule/leaList'].ownedLeas.map(createSelectItem).sort(leaSortCompare))
    },
    districts(state, getters, rootState, rootGetters) {
      if (state.filter.coop) {
        return [{
          value: null,
          text: 'Any District'
        }].concat(rootGetters['globalModule/leaList'].ownedLeas.filter(o => o.number == state.filter.coop).flatMap(d => d.ownedLeas).map(createSelectItem).sort(leaSortCompare))
      }
      return [{
        value: null,
        text: 'Any District'
      }].concat(getters.coops.filter(c => c.value).map(c => rootState.globalModule.leaContext.LeaDictionary[c.value]).flatMap(d => d.ownedLeas).map(createSelectItem).sort(leaSortCompare))
    },    
    districtsByName(state, getters, rootState, rootGetters) {
      if (state.filter.coop) {
        return [{
          value: null,
          text: 'Any District'
        }].concat(rootGetters['globalModule/leaList'].ownedLeas.filter(o => o.number == state.filter.coop)
          .flatMap(d => d.ownedLeas)
          .map(l => { return {value: l.number, text: `${l.name} (${l.number})`}})
          .sort((a,b) => (a.text.localeCompare(b.text)))
        )
      }
      return [{
        value: null,
        text: 'Any District'
      }].concat(state.assignedDistricts)
      .map(l => { return {value: l.number, text: `${l.name} (${l.number})`}})
      .sort((a,b) => (a.text.localeCompare(b.text)))
    },
    filterBuildings(state, getters, rootState) {
      const schools = state.filter.district ? rootState.globalModule.leaContext.LeaDictionary[state.filter.district].ownedLeas.map(createSelectItem).sort(leaSortCompare) : []
      return [
        { value: null, text: 'All Buildings' },
        { value: 'DISTRICT', text: 'District Admin Buildings' },
        { value: 'OTHER', text: 'All Other Buildings' },
      ].concat(schools)
    },
    isMobile: () => this._isMobile == 'true'
  }

  actions = {
    getAreaManagers: ({ commit }) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/metadata/area-managers`))
        .then(data => commit('setAreaManagers', data))
    },
    getDistrictTransportationStaff: ({ commit }, payload) => {
      var qs = getDPSAFTfilterQueryString(payload)

      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/metadata/district-transportation-staff?${qs}`))
        .then(data => commit('setDistrictTransportationStaff', data))
    },
    getTransportationStaff: ({ commit }) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/metadata/transportation-staff`))
        .then(data => commit('setTransportationStaff', data))
    },
    getAreas: ({ commit }) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/metadata/areas`))
        .then(data => commit('setAreas', data))
    },
    getBuses: ({ commit }, payload) => {
      var qs = getDPSAFTfilterQueryString(payload)

      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/metadata/bus?${qs}`))
        .then(data => {
         if(Array.isArray(data)){
          commit('setBuses', data)
         }else{
          commit('setStateSummary', data)
         }
        })
    },
    getContactList: ({ commit, state }) => {
      var qs = getDPSAFTfilterQueryString({ areaId: state.filter.area, coopLea: state.filter.coop, districtLea: state.filter.district })

      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/metadata/contact-list?${qs}`))
        .then(data => {
          commit('setContactList', data)
        })
    },
    getBuildings: ({ commit }, payload) => {
      var qs = getDPSAFTfilterQueryString(payload)

      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/metadata/building?${qs}`))
        .then(data => {
          commit('setBuildings', data)
        })
    },
    getCoopsAndBuildings: ({commit}) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/metadata/building/state`))
        .then(data => {
          commit('setCoopsAndBuildings', data)
        })
    },
    getAssignedDistricts:({commit, dispatch}) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/metadata/districts/assigned`))
        .then(data => {
          if(data.length > 0){
            commit('setAssignedDistricts', data)}
          else{
            dispatch('getAreaDistricts', {areaId: null})
          }
        })
    },
    getAssignedAreas:({commit}) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/metadata/areas/assigned`))
        .then(data => {          
          if(data.length > 0){
            commit('setAssignedAreas', data)
            commit('setSelectedAreaId', data[0].areaId)
          }          
        })
    },
    getAreaDistricts:({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/metadata/area-districts/${payload.areaId || 0}`))
        .then(data => {
          commit('setAssignedDistricts', data)
        })
    }
  }

  modules = {
    Facilities: new Facilities(),
    Admin: new Admin(),
    Trans: new Trans()
  }
}
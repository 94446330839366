import MasterSchedulePage from './masterSchedulePage.vue'
import DataQualityPage from './dataQuality/DataQualityPage.vue'
import TeacherLoadPage from './teacherLoad/TeacherLoadPage.vue'
import ClassSizePage from './classSize/ClassSizePage.vue'

const ScheduleArea = {
    MasterSchedulePage,
    DataQualityPage,
    TeacherLoadPage,
    ClassSizePage
}

export { ScheduleArea }
<template>
  <div class="card" style="height: 620px;overflow-y: scroll;">
    <div class="card-body" v-if="leaNumber != ''">
      <pdf
        v-for="i in numPages"
        :key="i"
        :src="src"
        :page="i"
        style="display: block; width: 70%;margin:auto;margin-top:20px;"
      />
    </div>
    <div v-else class="card-body">
      Select a school to view the report.
    </div>
  </div>
</template>

<script>
import { PageMixin } from '@/components/PageMixin'
import pdf from 'vue-pdf'

export default {
  data() {
    return {
      numPages: undefined,
    }
  },
  computed: {
    src() {
      //return pdf.createLoadingTask(`http://localhost:62986/StandardReports/GetLatestLTGReport?LEA=${this.leaNumber}&LTGType=${this.reportType}`)
      return pdf.createLoadingTask(`https://myschoolinfo.arkansas.gov/StandardReports/GetLatestLTGReport?LEA=${this.leaNumber}&LTGType=${this.reportType}`)
    },
  },
  props: {
    reportType: {
      type: String,
      default: ''
    },
    leaNumber: {
      type: String,
      default: ''
    }
  },
  components: { pdf },
  mixins: [PageMixin],
  methods: {
    evtError: function(err) {
      this.error = err
    }
  },
  mounted() {
    this.src.then(pdf => {
      this.numPages = pdf.numPages
    })
  },
  watch: { }
}
</script>

<style>

</style>
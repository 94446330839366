<template>
  <dashboard-widget />
</template>

<script>
import DashboardWidget from '../common/DashboardWidget.vue'
import { mapState } from 'vuex'

export default {
  components: {
    DashboardWidget
  },
  computed: {
    ...mapState('rvsModule', ['fiscalYear']),
  },
  methods: {
    reloadData() {
      this.$store.dispatch('rvsModule/getSubjectGradeCrossTab', { lea: this.selectedLea.number });
      this.$store.dispatch('rvsModule/getCourseDesignationCrossTab', { lea: this.selectedLea.number });
    }
  },
  watch: {
    fiscalYear() {
      this.reloadData()
    },
    selectedLea() {
      this.reloadData()
    }
  }
}
</script>
<template>
  <div class="district-closure-container">
    <h1>School Communication Tool (Public)</h1>
    <router-view />
  </div>
</template>

<script>
import { PageMixin } from '../../../PageMixin'
import { router } from '../../../../routes'
import PublicListClosuresPage from './PublicListClosuresPage'
import ViewClosurePage from './ViewClosurePage'

router.addRoutes([
  { path: '/Core/SCT/Public', name: 'sct-public', component: PublicListClosuresPage },
  { path: '/Core/SCT/Public/:id', component: ViewClosurePage, name: 'sct-public-view-closure', props: true },
])

export default {
  data() {
    return {
    }
  },
  mixins: [PageMixin],
}
</script>

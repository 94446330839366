<template>
  <div>
    <page-header :icon="$route.meta.icon" :label="$route.meta.label" />
    <router-view />
  </div>
</template>
<script>
import PageHeader from '../common/PageHeader.vue'
import { Types } from '@/modules/cte/stores/perkinsv'

export default {
  components: {
    PageHeader
  },
  mounted() {
    console.log('mounting Perkins V')
    this.$store.dispatch(Types.actions.getAcademicYears)
  }
}
</script>

<template>
  <div>
    <div class="card mt-3 bg-light">
      <div class="card-header">
        Expenditures
      </div>
      <div class="card-body">
        <loadable-chart-card :load-data="loadData" card-style="overflow-y: auto; border: 0px" :enable-data-toggle="true">
          <template v-slot:default="slotProps">
            <div class="chartElem" v-show="slotProps.chartMode">
              <div class="card-group">
                <expenditures-trend-chart :load-data="loadData" :selected-lea="selectedLea" title=" Personnel Expenditures" expenditure-type="Personnel" />
                <expenditures-trend-chart :load-data="loadData" :selected-lea="selectedLea" title="Non-Personnel Expenditures" expenditure-type="Non-Personnel" />
              </div>
              <div class="card-group">
                <expenditures-trend-chart :load-data="loadData" :selected-lea="selectedLea" title="Personnel Per-pupil Expenditures" mode="perPupil" expenditure-type="Personnel" />
                <expenditures-trend-chart :load-data="loadData" :selected-lea="selectedLea" title="Non-Personnel Per-pupil Expenditures" mode="perPupil" expenditure-type="Non-Personnel" />
              </div>
            </div>
            <div v-show="!slotProps.chartMode">
              <expenditures-table />
            </div>
          </template>
        </loadable-chart-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Types } from '../../../stores/financeModule'
import LoadableChartCard from '../../LoadableChartCard.vue'
import ExpendituresTable from './ExpendituresTable.vue'
import ExpendituresTrendChart from './ExpendituresTrendChart.vue'

export default {
  data() {
    return {
      loadData: null
    }
  },
  computed: {
    ...mapGetters(Types.path, [Types.getters.schoolFinancesfiscalYear]),
    selectedLea() { 
      return this.$store.state.globalModule.leaContext.SelectedLea 
    }
  },
  components: {
    LoadableChartCard,
    ExpendituresTable,
    ExpendituresTrendChart
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if(newLea && newLea.number){
          this.loadData = this.$store.dispatch(Types.actions.getExpenditures, {
            lea: newLea.number
          })
        }
      }
    }
  }
}
</script>

<template>
  <div>
    <waiver-page-header :edit-mode="editMode" :waiver-request-id="waiverRequestId" 
                        :lea="resolvedLea" :current-status="currentStatus" :waiver-request-title="waiverRequestTitle"
                        :request="request" :back="backToRequestList" :context="Context.Public" />
    <Promised :promise="loadData" v-show="loadData">
      <template v-slot:pending>
        <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
          <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
        </div>
      </template>
      <template v-slot>
        <div v-if="request && waiverRequestId" class="row">
          <div class="col-md-9">
            <b-alert variant="success" v-if="approvalChangeLog" show>
              <i class="fa fa-check-circle" />
              {{ approvalChangeLog.comment }} &ndash; {{ approvalChangeLog.createdDate | date }}
            </b-alert>
            <digital-learning-waiver-content :edit="false" v-if="request" v-model="request" />
          </div>
        </div>
        <div v-else>
          <program-options-widget :lea="resolvedLea" :context="Context.Public" />
        </div>
      </template>
      <template v-slot:rejected="error" class="align-items-center justify-content-center">
        <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
      </template>
    </Promised>
  </div>
</template>

<script>
import { Promised } from 'vue-promised'
import CardStateIcon from '@/components/CardStateIcon.vue'
import { PageMixin } from '@/components/PageMixin'
import { LeaTypes } from '@/helpers/leaTypes'
import { Types, Context } from '@/stores/digitalLearning'
import { DigitalLearningWaiverMixin } from './DigitalLearningWaiverMixin'
import WaiverPageHeader from './details/WaiverPageHeader.vue'
import ProgramOptionsWidget from './details/ProgramOptionsWidget.vue'

export default {
  data() {
    return {
      Context
    }
  },
  computed: {
    canEdit() {
      return Boolean(this.selectedWaiverRequest) && this.selectedWaiverRequest.status < 100 && this.selectedWaiverRequest.status > 0
    },
    approvalChangeLog() {
      const approvalChangeLog = this.request.changeLogs.find(c => c.type == "Status Change" && c.toStatus == 101)
      return this.isDigitalLearning && approvalChangeLog ? approvalChangeLog : null
    },
  },
  mixins: [PageMixin, DigitalLearningWaiverMixin],
  methods: {
    backToRequestList() {
      this.$router.push({ name: 'public-waiver-request-list' })     
    },
  },
   watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if (newLea && newLea.number) {
          if (newLea.type == LeaTypes.School) {
            this.resolvedLea = {...this.leaDictionary[newLea.number].parentLea}
          }
          else this.resolvedLea = {...newLea}
          this.loadData = this.$store.dispatch(Types.actions.getApprovedWaiverRequests, { lea: this.resolvedLea.number })
        }
      }
    },
  },
  components: {
    Promised,
    CardStateIcon,
    WaiverPageHeader,
    ProgramOptionsWidget,
  }
}
</script>

<style>

</style>
import ApiService from '@/services/ApiService'

export class RVSTroubleshootingModule {

  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
  }

  namespaced = true

  state = {
    eFinanceConnectionString: '',
    eSchoolConnectionString: '',
    fmsEmployees: [],
    fmsJobAssignments: []
  }
  getters = {
    
  }
  mutations = {
    setEFinanceConnectionString(state, data) {
      state.eFinanceConnectionString = data
    },
    setESchoolConnectionString(state, data) {
      state.eSchoolConnectionString = data
    },
    setEFinanceEmployees(state, data) {
      state.fmsEmployees = data
    },
    setEFinanceJobAssignments(state, data) {
      state.fmsJobAssignments = data
    }
  }

  actions = {
    getEFinanceConnectionString: ({ commit }, lea) => {
      commit('setEFinanceConnectionString', 'Loading...')
      return this._apiService.Get(`${this._baseUrl}api/rvs/admin/troubleshooting/db/${lea}?eFinance=true`)
        .then(data => commit('setEFinanceConnectionString', data))
    },
    getESchoolConnectionString: ({ commit }, lea) => {
      commit('setESchoolConnectionString', 'Loading...')
      return this._apiService.Get(`${this._baseUrl}api/rvs/admin/troubleshooting/db/${lea}?eFinance=false`)
        .then(data => commit('setESchoolConnectionString', data))
    },
    getEFinanceEmployees: ({ commit }, lea) => {
      return this._apiService.Get(`${this._baseUrl}api/rvs/admin/troubleshooting/db/fms/employee/${lea}`)
        .then(data => commit('setEFinanceEmployees', data))
    },
    getEFinanceJobAssignments: ({ commit }, lea) => {
      return this._apiService.Get(`${this._baseUrl}api/rvs/admin/troubleshooting/db/fms/jobassign/${lea}`)
        .then(data => commit('setEFinanceJobAssignments', data))
    }
  }
}
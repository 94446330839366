<template lang="html">
  <div class="d-inline">
    <Promised :promise="actionPromise" v-show="actionPromise">
      <template v-slot:pending>
        <button disabled="disabled" @click="exportExcel" class="btn btn-success float-right d-print-none mr-1">
          <i class="fas fa-spinner fa-spin" />&nbsp;Excel!
        </button>
      </template>
      <template v-slot="data">
        <button @click="exportExcel" class="btn btn-success float-right d-print-none mr-1">
          <i class="far fa-file-excel" />&nbsp;Excel
          <span class="d-none">{{ data }}</span>
        </button>
      </template>
      <template v-slot:rejected="error">
        <button @click="exportExcel" class="btn btn-success float-right d-print-none mr-1">
          <i class="fas fa-exclamation-circle" />&nbsp;Excel
          <span class="d-none">{{ error }}</span>
        </button>
      </template>
    </Promised>
    <button @click="exportExcel" class="btn btn-success float-right d-print-none mr-1" v-show="actionPromise == null">
      <i class="far fa-file-excel" />&nbsp;Excel
    </button>
  </div>
</template>

<script>
import { Promised } from 'vue-promised'
import ExcelService from '../../services/ExcelService'
import { Types } from '../../stores/onDemandModule'
import { mapState } from 'vuex'
import sumBy from 'lodash.sumby'
import groupBy from 'lodash.groupby'
import { EnrollmentSubpops } from '../overview/enrollment/EnrollmentSubpops'
import { Subpopulations, DemographicSubpopulations } from '../subpopulations'
import { getInstructionOptionSheets } from './instructionalOption/instructionalOptionsExport'
import { getVirualMealStatusSheets } from './instructionalOption/virtualMealStatusExport'
import { gradeLevelEnrollmentSheets } from './instructionalOption/gradeLevelEnrollmentExport'
import { getInstructionOptionBySubpopsSheets } from './instructionalOption/instructionalOptionBySubpopsExport'
import { mealStatusSheets } from './instructionalOption/mealStatusExport'
import { demographicsSheets } from './instructionalOption/demographicsExport'

export default {
  data() {
    return {
      actionPromise: null
    }
  },
  props: {
    title: String
  },
  computed: {
    ...mapState(Types.path, [Types.state.instructionalOptions, Types.state.enrollmentCounts, Types.state.instructionalOptionsDemographics]),
    virtualStudentsByMealStatus() {
      return this.$store.state.onDemandModule.virtualStudentsByMealStatus
    },
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    },
    selectedLea() {
      return this.$store.state.globalModule.leaContext.SelectedLea
    },
    gradesList() {
      return [-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    },
    getDefaultDemographics() {
      var object = {}
      EnrollmentSubpops.forEach(c => object[c.name] = 0)
      Subpopulations.forEach(c => object[c.name] = 0)
      DemographicSubpopulations.forEach(c => object[c.name] = 0)
      return object
    }
  },
  methods: {
    async exportExcel() {
      const excelService = new ExcelService()
      const lea = this.selectedLea.number || ''
      const workbookName = `${this.title}-${lea}`
      var workbook = await excelService.createWorkbook(workbookName)
      var worksheetData = [
        ...getInstructionOptionSheets.bind(this)(),
        ...getInstructionOptionBySubpopsSheets.bind(this)(),
        ...getVirualMealStatusSheets.bind(this)(),
        ...gradeLevelEnrollmentSheets.bind(this)(), 
        ...mealStatusSheets.bind(this)(), 
        ...demographicsSheets.bind(this)(), 
      ]
      worksheetData.forEach(async sheet => {
        await excelService.addWorksheet(workbook, sheet.name.replace('/', '').substr(0, 31), sheet.items, sheet.totals)
      })
      this.actionPromise = excelService.saveFile(workbook, `${workbookName}.xlsx`)
    },
    leaName(lea) {
      if (this.leaDictionary[lea])
        return this.leaDictionary[lea].name
      else return ''
    },
    getEnrollmentCountRows(data, options, transformOption)
    {
      var groupedByLea = groupBy(data, (o) => { return o.lea })
      var rows = Object.keys(groupedByLea).sort((a,b) => a-b).map(lea => {
        let row = { lea }
        options.forEach(g => {
          row[g.name] = sumBy(groupedByLea[lea], transformOption.bind(g))
        })
        return row
      })
      return rows
    },
    getGradeLevel(number) {
      switch (parseInt(number)) {
        case -1: return "Ungraded"
        case 0: return "Kindergarten"
        default: return `Grade ${number}`
      }
    },
    gradeColumns(row) {
      var total = 0
      var columns = {}
      this.gradesList.forEach(grade => {
        columns[this.getGradeLevel(grade)] = row[this.getGradeLevel(grade)]
        total += row[this.getGradeLevel(grade)]
      })
      columns['Total'] = total
      return columns
    },
    gradeTotals(rows) {
      var total = 0
      var columns = {}
      this.gradesList.forEach(grade => {
        var colTot = sumBy(rows, (o) => o[this.getGradeLevel(grade)])
        columns[this.getGradeLevel(grade)] = colTot
        total += colTot
      })
      columns['Total'] = total
      return columns
    },
    districtTotalColumns(rows) {
      var districtTotalColumns = {}
      Object.keys(rows[0]).forEach(key => {
        if (['CoOp LEA', 'CoOp Name', 'District LEA', 'District Name', 'School LEA', 'School Name'].indexOf(key) == -1)
          districtTotalColumns[key] = sumBy(rows, key)
      })
      return districtTotalColumns
    }
  },
  components: {
    Promised
  }
}
</script>
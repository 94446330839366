export default class ExcelService {
  async createWorkbook(title) {
    const XLSX = await import('xlsx')
    var workbook = XLSX.utils.book_new()
    workbook.Props = {
      Title: title,
      Author: 'Arkansas Dept. of Education'
    }
    return workbook
  }
  async addWorksheet(workbook, name, data, totals) {
    const XLSX = await import('xlsx')
    var worksheet = XLSX.utils.json_to_sheet(data, { dateNF: 'MM/DD/YYYY hh:mm:ss AM/PM' })
    if (totals) {
      XLSX.utils.sheet_add_json(worksheet, [totals], { skipHeader: true, origin: -1 });
    }
    XLSX.utils.book_append_sheet(workbook, worksheet, name)
  }
  async saveFile(workbook, name) {
    const XLSX = await import('xlsx')
    XLSX.writeFile(workbook, name)
  }
  async getBase64String(workbook) {
    const XLSX = await import('xlsx')
    return XLSX.write(workbook, "base64")
  }
}

<template>
  <div>
    <student-roster-teacher :ay="ay" student-page-route="CTE/CareerCoachTeacher/StudentPage" />
  </div>
</template>

<script>
import StudentRosterTeacher from '../../widgets/StudentRosterTeacher.vue'
export default {
  components: {
    StudentRosterTeacher
  },
  props: {
    ay: Number
  }
}
</script>
import Vue from 'vue'
import { NbctWidget } from './nbctWidget'
import { PageMixin } from '../../PageMixin'

const NbctPage = Vue.extend({
    name: 'nbct-page',
    mixins: [PageMixin],
    data() {
        return {
        }
    },
    components: {
        NbctWidget
    }
})
export { NbctPage }
<template>
  <div>
    <a id="generalQuestions" class="anchor" />
    <h1>Teacher Survey</h1>
    <p>
      Thank you for completing the Ready for Learning teacher survey.  Results from this survey will be used to guide the Division of Elementary and Secondary
      Education's next steps regarding future needs regarding statutes, rules, and professional learning. Responses will not be identifiable by name, school or district.
    </p>

    <b-form-group class="text-blue">
      <template v-slot:label>
        <span class="coop-label">Select your District's Co-op</span>&nbsp;<span class="coop-label-star">(*Required)</span>
      </template>
      <b-select v-model="coopLea" :options="coopOptions" />
      <!-- <span class="text-danger" v-if="!$v.coopLea.required">You must select a coop</span> -->
    </b-form-group>

    <div
      class="card mt-3 bg-light"
      v-for="(section, index) in sections"
      :key="section.target"
      :class="{'page-break-before': index > 0}"
    >
      <a v-if="index > 0" :id="section.target" class="anchor" />
      <div class="card-header">
        <img :src="section.icon" v-if="section.icon" class="svg-icon">
        {{ section.name }}
      </div>
      <div class="card-body">
        <span class="d-print-none" v-if="section.faq && isDistrict" v-html="section.faq" />
        <div class="row">
          <div class="col-12">
            <survey-questions :questions="section.questions" :edit-mode="true" />
            <div
              v-for="(subsection, ssidx) in getSubsections(section)"
              :key="subsection.target"
              class="subsection mt-3"
              :class="{'page-break-before': ssidx > 0}"
            >
              <a :id="subsection.target" class="anchor" />
              <div class="card mt-3 bg-white">
                <div class="card-header bg-secondary">
                  <object
                    type="image/svg+xml"
                    :data="subsection.icon"
                    v-if="subsection.icon"
                    class="svg-icon"
                  />
                  {{ subsection.name }}
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <arffl-questions
                        :questions="subsection.questions"
                        :edit-mode="editMode"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isSubmitDisabled" class="mt-2">
      <b-alert variant="warning" show v-if="!coopLea">
        <strong><i class="fa fa-exclamation-triangle mr-2" />NO CO-OP SELECTED</strong> Your Co-Op must be selected to continue.
      </b-alert>
      <b-alert variant="warning" show v-if="!$v.$anyDirty">
        <strong><i class="fa fa-exclamation-triangle mr-2" />NO ANSWERS SUPPLIED</strong> You must answer the questions before submitting your survey.
      </b-alert>
    </div>
    <b-button variant="success" class="mt-2" @click="submit()" :disabled="isSubmitDisabled" :class="{ disabled: isSubmitDisabled }">
      <i class="fas fa-paper-plane" /> Submit answers to ADE-DESE
    </b-button>    
  </div>
</template>
    
<script>

import { PageMixin } from '../../PageMixin'
import { mapGetters } from 'vuex'
import { surveyTemplate, VisibilityDirectives } from './surveyTemplate'
import SurveyQuestions from './surveyQuestions'
import TeacherSurveyCoops from './teacherSurveyCoops'

import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

export default {
  data() {
    return {
      coopLea: null,
      loadData: Promise,
      survey: {},
    }
  },
  provide() {
    return {
      $v: this.$v
    }
  },
  mixins: [PageMixin],
  computed: {
    isSubmitDisabled() {
      return !this.$v.$anyDirty || this.$v.$invalid || !this.coopLea
    },
    sections() {
      return surveyTemplate.sections.filter(s => (s.hidden & VisibilityDirectives.hideOnSurvey) != VisibilityDirectives.hideOnSurvey)
    },
    ...mapGetters('globalModule', ['leaDictionary', 'coops']),
    coopOptions() {
      return TeacherSurveyCoops
    }
  },
  methods: {
    submit() {
      this.loading = true

      this.$bvModal.msgBoxConfirm('Please confirm you have completed the survey before clicking "Confirm".  Once submitted, your survey response cannot be changed.', {
        title: 'Confirm Submit',
        okVariant: 'success',
        okTitle: 'Confirm',
        cancelTitle: 'Review Changes'
      }).then(value => {
        if (value) {
          this.loading = true
          this.$store.dispatch('surveys/teacherSurvey/submitSurvey', {
            ResponseData: JSON.stringify(this.survey),
            CoopLea: this.coopLea
          })
          .then(() => {
            window.location.href = '/surveys/teacherSurvey/ThankYou'
          })
        }
      })        
    },
    getSubsections(section) {
      if (section.sections) return section.sections.filter(s => (s.hidden & VisibilityDirectives.hideOnSurvey) != VisibilityDirectives.hideOnSurvey)
      else return null
    },
  },
  mounted() {
  },
  validations() {
    var v = {}
    var topQuestions = surveyTemplate.sections.flatMap(s => s.questions || [])
    var subQuestions = surveyTemplate.sections.flatMap(s => s.sections || []).flatMap(s => s.questions || [])
    var allQuestions = topQuestions.concat(subQuestions).flat(2)
    var allIds = allQuestions.flatMap(s => {
      if (s.component == 'QuestionYesnoDetails') return [s.id, s.id + 'Followup']
      if (s.component == 'QuestionMultipleSelection' && s.allowOther) return [s.id, s.id + 'Followup']
      else return s.id
    })
    allIds.forEach(s => (v[s] = {}))
    return { survey: v, certifyComplete: {} }
  },
  watch: {
  },
  components: {
    SurveyQuestions
  }
}
</script>

<style type="scss">
.card-header > .svg-icon {
  filter: brightness(5004%);
  vertical-align: bottom;
}

.coop-label {
  color: blue;
  font-weight: bolder;
  font-size: larger;
}

.coop-label-star {
  color: red;
  font-weight: bolder;
  font-size: larger;
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
</style>
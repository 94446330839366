<template>
  <b-card class="mb-2 outer-card">
    <div v-if="leaType == LeaTypes.School || leaType == LeaTypes.District">
      <div class="row">
        <div class="col-md-12">
          <growth-filter />
        </div>
      </div>
      <b-tabs pills content-class="mt-3" v-if="!subject">
        <b-tab v-for="subject in subjects" :key="subject.value" :title="subject.text">
          <h5 class="text-center">
            STUDENT TABLE OF {{ subject.text.toUpperCase() }} VALUE-ADDED GROWTH AND ACHIEVEMENT
          </h5>
          <strong>{{ filterString }}</strong>
          <student-table
            :subject="subject.value"
            :show-trends="false"
            :load-data="loadData"
            :per-page="10"
          />
        </b-tab>
      </b-tabs>

      <div v-if="subject && subject != 'elp'">
        <h5 class="text-center">
          STUDENT TABLE OF {{ subjects[0].text.toUpperCase() }} VALUE-ADDED GROWTH AND ACHIEVEMENT
        </h5>
        <strong>{{ filterString }}</strong>
        <student-table
          :subject="subject"
          :show-trends="false"
          :load-data="loadData"
          :per-page="10"
        />
      </div>
      <div class="alert alert-warning" v-else-if="subject">
        No Data available for ELP. Please select a different Subject.
      </div>
    </div>
    <div v-else class="alert alert-warning">
      No data available for selected LEA. Please select a school to view report
    </div>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import { PageMixin } from '../../../PageMixin'
import StudentTable from '../StudentTable.vue'
import GrowthFilter from '../GrowthFilter'
import { Subjects, DemographicGroups } from '../FilterOptions'

export default {
  name: 'student-data',
  data() {
    return {
      loadData: null
    }
  },
  components: {
    StudentTable,
    GrowthFilter
  },
  mixins: [PageMixin],
  computed: {
    ...mapState('academicsModule/growth', [
      'tab',
      'grade',
      'subject',
      'subgroup'
    ]),
    subjects() {
      var subjects = Subjects.filter(s => s.value != null && s.value != 'elp')
      if (this.subject) {
        subjects = Subjects.filter(s => {
          return s.value == this.subject
        })
      }
      return subjects
    },
    filterString() {
      var g = this.grade
      var d = DemographicGroups.find(g => g.value == this.subgroup)

      return `Selected Grade: ${g ? g : 'All Grades'}, Subgroup: ${
        d ? d.text : 'All Subgroups'
      }`
    },
    fiscalYear() {
      return this.$store.state.academicsModule.growth.fiscalYear
    }
  },
  methods: {
    refresh() {
      this.loadData = this.$store.dispatch(
        'academicsModule/growth/getStudentGrowth',
        { lea: this.selectedLea.number }
      )
    }
  },
  mounted() {
    this.refresh()
  },
  watch: {
    grade() {
      this.refresh()
    },
    subgroup() {
      this.refresh()
    },
    subject() {
      this.refresh()
      window.dispatchEvent(new Event('resize'))
    },
    selectedLea() {
      this.refresh()
    },
    fiscalYear() {
      this.refresh()
    }
  }
}
</script>
<template lang="html">
  <div class="card bg-light mt-3">
    <a class="anchor" :id="anchorId" />
    <div class="card-header">
      {{ label }}
    </div>
    <div class="card-body">
      <div class="list-group list-group-flush">
        <a v-for="lea in leas" :key="lea.number" @click="goToLea(lea.number)" class="list-group-item list-group-item-action cursor-pointer">
          <slot :lea="lea">
            <lea-icon :lea="lea" :svg-mode="false" /> {{ lea.name }} ({{ lea.number }}) 
          </slot>    
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import LeaIcon from './LeaIcon.vue'

export default {
  name: 'lea-list',
  components: { LeaIcon },
  props: {
    leas: Array,
    label: String,
    anchorId: String
  },
  methods: {
    goToLea(leaNumber) {
      const lea = this.$store.state.globalModule.leaContext.LeaDictionary[
        leaNumber
      ]
      window.scrollTo(0,0)
      this.$store.dispatch('globalModule/changeSelectedLea', lea)
    }
  }
}
</script>
<template>
  <div>
    <Promised :promise="loading">
      <template v-slot:pending>
        <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
      </template>
      <template v-slot:rejected="error">
        {{ error }}
      </template>
      <template>
        <div class="row">
          <div class="col-md-12">
            <b-form>
              <b-form-row class="mb-2">
                <b-col>
                  <b-form-checkbox v-model="enabled" switch>
                    Sync Process {{ enabled ? 'Enabled' : 'Disabled' }} 
                  </b-form-checkbox> 
                </b-col>
              </b-form-row>
            </b-form>
          </div>
        </div>
        <paginated-table :items="transformedRecords" :fields="fields">
          <template v-slot:cell(request.applyToFiscalYear)="data">
            {{ data.value | fromFY('short') }}
          </template>

          <template v-slot:cell(request.createdDate)="data">
            {{ data.value | date }}
          </template>

          <template v-slot:cell(sync.processDate)="data">
            <div v-if="data.value">
              {{ data.value | date }}
            </div>
            <div v-else>
              <sync-button v-if="data.item.request.applyToFiscalYear <= fiscalYear" :item="data.item" />
              <span v-else class="badge badge-secondary">Blocked until {{ data.item.request.applyToFiscalYear + 1990 }}</span>
            </div>
          </template>
          <template v-slot:cell(sync.synchronizationResult)="data">
            <span v-if="data.value === 0">
              <i class="fa fa-check text-success" title="LEAM Synchronization Successful" />
            </span>
            <span v-else-if="data.value > 0">
              <i class="fa fa-exclamation text-danger" title="LEAM Synchronization Failed" />
            </span>
            <span v-else-if="data.item.request.applyToFiscalYear > fiscalYear">
              <i class="fa fa-pause text-warning" title="Blocked" />
            </span>
          </template>
          <template v-slot:cell(request.responseObject.operation)="data">
            <router-link
              :to="{name: 'review-request', params: {id: data.item.request.requestId}}"
            >
              {{ requestOperationFormatter(data.value) }}
            </router-link>
          </template>

          <template v-slot:cell(request.requestLeas)="data">
            <div>
              <ul class="list-unstyled">
                <li v-for="i in data.value" :key="i.operationParams">
                  <router-link
                    v-if="data.item.sync.synchronizationResult===0"
                    :to="{name: 'leams-browser', query: {fy: data.item.request.applyToFiscalYear, lea: i.lea}}"
                  >
                    <span class="badge" :class="operationBadgeByOperationTypeCode(i.operationType).class">{{ operationBadgeByOperationTypeCode(i.operationType).label }} - {{ i.lea }}</span>
                  </router-link>
                  <span v-else><span class="badge" :class="operationBadgeByOperationTypeCode(i.operationType).class">{{ operationBadgeByOperationTypeCode(i.operationType).label }} - {{ i.lea }}</span></span>
                </li>
              </ul>
            </div>
          </template>
          <template v-slot:row-details="row">
            <b-card bg-variant="danger" text-variant="light">
              {{ row.item.sync.errorDetails }}
            </b-card>
          </template>
        </paginated-table>
      </template>
    </Promised>
  </div>
</template>

<script>
import CardStateIcon from '../../../CardStateIcon.vue'
import SyncButton from './syncButton'
import { mapState, mapGetters } from 'vuex'
import PaginatedTable from '../../../PaginatedTable'
import { Promised } from 'vue-promised'
import { requestOperationFormatter, operationBadgeByOperationTypeCode } from '../../leaAssignment'
export default {
  name: 'leams-sync',
  data() {
    return {
      loading: null,
      requestOperationFormatter,
      operationBadgeByOperationTypeCode,
      leaTypeCode: null,
      fields: [
        { key: 'request.applyToFiscalYear', sortable: true, label: 'Assigned to School Year' },
        { key: 'request.districtLEA', sortable: true, label: 'Requesting LEA #' },
        { key: 'request.responseObject.operation', label: 'Request Purpose' },
        { key: 'sync.processDate', sortable: true, label: 'Sync Date' },
        { key: 'sync.synchronizationResult', sortable: true, label: 'Sync Result', class: 'text-center' },
        { key: 'request.requestLeas', label: ' Modification(s)' },
        { key: 'request.createdBy', label: 'Created By' },
        { key: 'request.createdDate', sortable: true, label: 'Created On' }
      ]
    }
  },
  computed: {
    ...mapState('toolsModule/leams', ['leamsRecords', 'leaTypes', 'leamsSyncRecords', 'leamsSyncProcessEnabled']),
    ...mapGetters('toolsModule/leams', ['fiscalYear']),
    ...mapState('globalModule', ['leaContext']),
    leaTypeOptions() {
      return this.leaTypes.map(t => ({ value: t.code, text: `${t.code} - ${t.description}` }))
    },
    transformedRecords() {
      return this.leamsSyncRecords.map(i => Object.assign({ _showDetails: i.sync != null && i.sync.synchronizationResult > 0 }, i))
    },
    enabled: {
      get() {
        return this.leamsSyncProcessEnabled === "True" || this.leamsSyncProcessEnabled == true
      },
      set(value) {
        console.log('setting value', value)
        if (value !== '') {
          this.$store.dispatch('toolsModule/leams/setLEAMSSyncProcessEnabled', {enabled: value})
        }
      }
    }
  },
  mounted() {
    this.loading = this.$store.dispatch('toolsModule/leams/getLEAMSSyncProcessEnabled')
  },
  watch: {
    fiscalYear: {
      immediate: true,
      handler() {
        this.$store.dispatch('toolsModule/leams/getLeamsSyncRecords', { fy: this.fiscalYear })
      }
    }
  },
  components: {
    PaginatedTable,
    SyncButton,
    Promised,
    CardStateIcon
  }
}
</script>
<template>
  <div>
    <filter-widget @filter-changed="applyFilter($event)" :ay="ay" :ay-options="ayOptions" />
    <Promised :promise="loadData" v-show="loadData">
      <template v-slot:pending>
        <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
          <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
        </div>
      </template>
      <template v-slot:rejected="error" class="align-items-center justify-content-center">
        <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
      </template>      
      <template v-slot>
        <div v-if="reportData">
          <b-row>
            <b-col md="6" sm="12">
              <coverage-counts-by-grade-chart :data="reportData" />
            </b-col>
            <b-col md="6" sm="12">
              <coverage-percentage-by-grade-chart :data="reportData" />
            </b-col>
          </b-row>
          <b-table :items="reportData.data" :fields="fields" />
        </div>
      </template>
    </Promised>
  </div>
</template>

<script>
import { Promised } from 'vue-promised'
import CardStateIcon from '@/components/CardStateIcon'
import FilterWidget from './FilterWidget.vue'
import { metadata } from '@/modules/cte/stores/careercoach/metadata'
import { toPercentage } from '@/helpers/formatters'
import CoverageCountsByGradeChart from './CoverageCountsByGradeChart.vue'
import CoveragePercentageByGradeChart from './CoveragePercentageByGradeChart.vue'
import { AYDependentReportMixin } from '../AYDependentReportMixin'

export default {
  components: {
    Promised,
    CardStateIcon,
    FilterWidget,
    CoverageCountsByGradeChart,
    CoveragePercentageByGradeChart
  },
  data() {
    return {
      filter: {
        academicYear: null,
        lea: null,
        grade: null
      },
      metadata,
      loadData: null,
      reportData: null,
      fields: [
        'coopLEA',
        'districtLEA',
        'schoolLEA',
        'grade',
        { key: 'topic', formatter: v => metadata.visit.topics.find(t => t.value == v).text, label: 'Service Type' },
        'uniqueStudentsVisited',
        'uniqueStudentsWithDuplicateVisits',
        'studentsLackingVisit',
        { key: 'percentServiced', formatter: v => toPercentage(v, 1, true) },
        { key: 'percentDuplicated', formatter: v => toPercentage(v, 1, true) }
      ]
    }
  },
  props: {
    ay: Number
  },
  methods: {
    applyFilter(filter) {
      this.filter.lea = this.selectedLea.number
      this.filter.academicYear = filter.academicYear
      this.filter.grade = filter.grade
    },

    async getData() {
      if (this.filter.academicYear) {
        this.loadData = this.$store.dispatch('cte/careercoach/reporting/serviceCoverageReport', this.filter).then(d => {
          d.data.forEach(item => {
            item.percentServiced = item.totalEnrollment ? item.uniqueStudentsVisited / item.totalEnrollment : 0
            item.percentDuplicated = item.totalEnrollment ? item.uniqueStudentsWithDuplicateVisits / item.totalEnrollment : 0
          })

          this.reportData = d
        })
      }
    }
  },
  watch: {
    selectedLea: {
      immediate: false,
      handler() {
        this.filter.lea = this.selectedLea.number
      }
    }, 
    filter: {
      deep: true,
      handler() {
        this.getData()
      }
    }
  },
  mixins: [
    AYDependentReportMixin
  ]
}
</script>
<template lang="html">
  <div class="d-inline">
    <Promised :promise="actionPromise" v-show="actionPromise">
      <template v-slot:pending>
        <button disabled="disabled" v-bind="$attrs" class="btn btn-info float-right disabled d-print-none">
          <i class="fas fa-spinner fa-spin" />&nbsp;PDF
        </button>
      </template>
      <template v-slot="data">
        <button @click="goToPdf" class="btn btn-info float-right d-print-none" v-bind="$attrs">
          <i class="far fa-file-pdf" />&nbsp;PDF
          <span class="d-none">
            {{ data }}
          </span>
        </button>
      </template>
      <template v-slot:rejected="error">
        <button @click="goToPdf" class="btn btn-info float-right d-print-none">
          <i class="fas fa-exclamation-circle" />&nbsp;PDF
          <span class="d-none">
            {{ error }}
          </span>
        </button>
      </template>
    </Promised>
    <button @click="goToPdf" class="btn btn-info float-right d-print-none" v-bind="$attrs" v-show="actionPromise == null">
      <i class="far fa-file-pdf" />&nbsp;PDF
    </button>
  </div>
</template>

<script>
import { Promised } from "vue-promised";

export default {
  name: 'pdf-button',
  data() {
    return {
      actionPromise: null
    }
  },
  components: {
    Promised
  },
  methods: {
    goToPdf() {
      // debugger;
      var payload = null
      if (this.payloadFactory) {
        payload = this.payloadFactory()
      }
      var debug = window.pdfdebug ? '&pdfdebug=true' : ''
      const resolvedLea = this.lea || this.selectedLea
      //if we have a payload, then we will POST to our URL with the payload
      
      this.actionPromise = this.$store.dispatch('globalModule/downloadPdf', {
        url: this.url || (document.location.protocol + "//" + document.location.hostname + ':' + document.location.port
             + document.location.pathname + `?response-format=pdf&lea=${resolvedLea.number}${debug}${this.additionalQueryStringParams}`),
        payload: payload
      })
    }
  },
  props: {
    payloadFactory: Function,
    lea: {
      type: Object,
      default: null,
    },
    additionalQueryStringParams: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: null
    }
  }
}
</script>

import removeNamespace from './removeNamespace'
import ApiService from '../services/ApiService'
import Vue from 'vue'

export const Types = {
  path: 'onDemandModule',
  state: {
    enrollmentCounts: 'enrollmentCounts',
    instructionalOptions: 'instructionalOptions',
    metricSnapshots: 'metricSnapshots',
    virtualStudentsByMealStatus: 'virtualStudentsByMealStatus',
    instructionalOptionsDemographics: 'instructionalOptionsDemographics',
    fiscalYears: 'fiscalYears',
    selectedFiscalYear: 'selectedFiscalYear',
  },
  getters: {
    metricSnapshots: 'onDemandModule/metricSnapshots',
    virtualStudentsByMealStatus: 'onDemandModule/virtualStudentsByMealStatus',
  },
  mutations: {
    setEnrollmentCounts: 'onDemandModule/setEnrollmentCounts',
    setInstructionalOptions: 'onDemandModule/setInstructionalOptions',
    setMetricSnapshots: 'onDemandModule/setMetricSnapshots',
    setVirtualStudentsByMealStatus: 'onDemandModule/setVirtualStudentsByMealStatus',
    setInstructionalOptionsDemographics: 'onDemandModule/setInstructionalOptionsDemographics',
    setFiscalYears: 'onDemandModule/setFiscalYears',
    setSelectedFiscalYear: 'onDemandModule/setSelectedFiscalYear',
  },
  actions: {
    getEnrollmentCounts: 'onDemandModule/getEnrollmentCounts',
    getInstructionalOptions: 'onDemandModule/getInstructionalOptions',
    getMetricSnapshotsEnrollment: 'onDemandModule/getMetricSnapshotsEnrollment',
    getMetricSnapshotsInstructionalOptions: 'onDemandModule/getMetricSnapshotsInstructionalOptions',
    getVirtualStudentsByMealStatus: 'onDemandModule/getVirtualStudentsByMealStatus',
    getInstructionalOptionsDemographics: 'onDemandModule/getInstructionalOptionsDemographics',
    getFiscalYears: 'onDemandModule/getFiscalYears',
  }
}
const _types = removeNamespace('onDemandModule/', Types)

function filterByFiscalYear(data, fiscalYear) {
  if (fiscalYear) {
    if (Array.isArray(data)) {
      return data.filter(d => d.fiscalYear == fiscalYear)
    }
    const filtered = { ...data }
    Object.keys(data).map(key => {
      filtered[key] = filtered[key].filter(d => d.fiscalYear == fiscalYear)
    })
    return filtered
  }
  return data
}

export class OnDemandModule {
  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
    this._fiscalYear = parseInt(this._configElement.getAttribute('data-fiscal-year'))
  }

  namespaced = true

  state = {
    [Types.state.enrollmentCounts]: [],
    [Types.state.instructionalOptions]: [],
    [Types.state.metricSnapshots]: [],
    [Types.state.virtualStudentsByMealStatus]: [],
    [Types.state.instructionalOptionsDemographics]: {
      onsite: [],
      virtual: [],
      hybrid: [],
    },
    [Types.state.fiscalYears]: [],
    [Types.state.selectedFiscalYear]: null,
  }
 
  getters = {
    [_types.getters.metricSnapshots]: (state) => filterByFiscalYear(state[Types.state.metricSnapshots], state[Types.state.selectedFiscalYear]),
    [_types.getters.virtualStudentsByMealStatus]: (state) => filterByFiscalYear(state[Types.state.virtualStudentsByMealStatus], state[Types.state.selectedFiscalYear]),
  }

  mutations = {
    [_types.mutations.setEnrollmentCounts]: (state, enrollmentCounts) => state.enrollmentCounts = enrollmentCounts,
    [_types.mutations.setInstructionalOptions]: (state, instructionalOptions) => state.instructionalOptions = instructionalOptions,
    [_types.mutations.setMetricSnapshots]: (state, metricSnapshots) => {
      Vue.set(state, Types.state.metricSnapshots, Object.assign({}, state.metricSnapshots, metricSnapshots))
    },
    [_types.mutations.setVirtualStudentsByMealStatus]: (state, data) => state.virtualStudentsByMealStatus = data,
    [_types.mutations.setInstructionalOptionsDemographics]: (state, instructionalOptionsDemographics) => state.instructionalOptionsDemographics = instructionalOptionsDemographics,
    [_types.mutations.setFiscalYears]: (state, data) => state.fiscalYears = data,
    [_types.mutations.setSelectedFiscalYear]: (state, data) => state.selectedFiscalYear = data,
  }

  actions = {
    [_types.actions.getEnrollmentCounts]: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/ondemand/${payload.lea}/enrollment`)
        .then(data => commit(_types.mutations.setEnrollmentCounts, data))
    },
    [_types.actions.getInstructionalOptions]: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/ondemand/${payload.lea}/instructionalOptions`)
        .then(data => commit(_types.mutations.setInstructionalOptions, data))
    },
    [_types.actions.getMetricSnapshotsEnrollment]: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/ondemand/${payload.lea}/metricSnapshots/enrollment`)
        .then(data => commit(_types.mutations.setMetricSnapshots, data))
    },
    [_types.actions.getMetricSnapshotsInstructionalOptions]: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/ondemand/${payload.lea}/metricSnapshots/instructionalOptions`)
        .then(data => commit(_types.mutations.setMetricSnapshots, { byInstructionalOption: [...data] }))
    },
    [_types.actions.getVirtualStudentsByMealStatus]: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/ondemand/${payload.lea}/virtualStudentsByMealStatus`)
        .then(data => commit(_types.mutations.setVirtualStudentsByMealStatus, data))
    },
    [_types.actions.getInstructionalOptionsDemographics]: ({ commit }, payload) => {
      return this._apiService
        .Get(`${this._baseUrl}api/ondemand/${payload.lea}/instructionalOptions/demographics`)
        .then(instructionalOptions => commit(_types.mutations.setInstructionalOptionsDemographics, instructionalOptions))
    },
    [_types.actions.getFiscalYears]: ({ commit }) => {
      return this._apiService.Get(`${this._baseUrl}api/ondemand/fiscalYears`)
        .then(data => {
          const fiscalYears = [...data.map(fiscalYear => {
              return { value: fiscalYear, text: `${fiscalYear + 1989} - ${fiscalYear + 1990}`}
            }),
            //{ value: 20, text: `Test`}
          ]
          commit(_types.mutations.setFiscalYears, fiscalYears)
          return fiscalYears
        })
    },
  }
}
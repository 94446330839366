<template>
  <div class="h-100 w-100">
    <highcharts class="chart" ref="chartElem" :options="chartOptions" />
  </div>
</template>

<script>
import { Types } from '@/modules/esa/stores/'
import { mapState } from 'vuex'
import { ChartMixin } from '@/components/ChartMixin'
import Highcharts from 'highcharts'
import sumBy from 'lodash.sumby'

export default {
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'pie',
          margin: 0,
          height: 300,
          spacing: [0, 0, 0, 0],
          plotBackgroundColor: "rgb(248, 249, 250)",
          plotBorderWidth: 0,
          plotShadow: false
        },
        plotOptions: {
          pie: {
            cursor: 'pointer',
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '75%'],
            borderColor: '#6c757d',
            dataLabels: {
              enabled: true,
              alignTo: 'connectors',
              crop: false,
              formatter: function() {
                return `${this.key}: $${Highcharts.numberFormat(this.y, 2).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} (${this.percentage.toFixed(2)}%)`
              }
            },
          },
        },
        title: {
          text: 'Budget Utilization',
          floating: false,
          //verticalAlign: 'middle'
        },
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        lang: {
          thousandsSep: ','
        },
        series: [{
          name: 'Budget',
          colorByPoint: true
        }],
        drilldown: {},
        xAxis: {
          title: {
            text: 'Amount'
          },
          type: 'category'
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          formatter: function() {
            return `${this.key}<br/><b>$${Highcharts.numberFormat(this.y, 2).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} (${this.percentage.toFixed(2)}%)</b>`
          }
        },
        yAxis: {
          endOnTick: false,
        },
      }
    }
  },
  props: {
    drilldown: {
      type: String
    },
    categories: {
      type: Array,
      required: true
    },
  },
  computed: {
    ...mapState(Types.path, [ Types.state.planExpenses ]),
    filteredExpenses() {
      if (this.currentCategory) 
        return this.planExpenses.filter(expense => this.currentCategory.programCode.includes(expense.program))
      return this.planExpenses//.map(p => {...p, categoryDescription: })
    },
    currentCategory() {
      return this.categories.find(category => category.name == this.drilldown)
    },
  },
  mixins: [ChartMixin],
  methods: {
    populate() {
      const actual = sumBy(this.filteredExpenses, 'actual')
      const budgeted = sumBy(this.filteredExpenses, 'budgeted')

      this.chartOptions.series = [{
        name: 'Budgeted',
        innerSize: '50%', 
        colorByPoint: true,
        data: [{
            name: 'Used',
            y: actual,
            color: (actual > budgeted) ? '#dc3545' : '#28a745'
          },
          {
            name: 'Remaining',
            y: budgeted - actual,
            color: '#e9ecef'
          }
        ]
      }]      
    },
  },
  watch: {
    planExpenses: {
      immediate: true,
      handler() {
        this.populate()
      }
    },
    drilldown: {
      handler() {
        this.populate()
      }
    }
  }
}
</script>
<template>
  <div class="card card-body">
    <paginated-table hover
                     @pt:row-selected="onSelected"
                     :items="checkableItems"
                     :fields="operativeFields"
                     :load-data="loadData">
      <template v-slot:info-header>
        <i class="pull-right text-info">* {{ inspectionInfo }}</i>
      </template>
      <template v-slot:head(action)>
        <div>
          <div v-if="allowItemCheck && mayAddInspection">
            <div v-if="checkedItems && allowAddInspectionForCheckedItems && checkedItems.length > 0">
              <b-dropdown right variant="primary" size="sm">
                <template v-slot:button-content>
                  <i class="fa fa-fw fa-plus-circle" /> Add
                </template>
                <b-dropdown-item
                  href="#"
                  @click="$emit('add-items', {target: 'default', items: checkedItems})">
                  Add to Inspection
                </b-dropdown-item>
                <b-dropdown-item
                  href="#"
                  @click="$emit('add-items', {target: 'new-inspection', items: checkedItems})">
                  Add to New Inspection
                </b-dropdown-item>
                <b-dropdown-item
                  href="#"
                  @click="$emit('add-items', {target: 'existing-inspection', items: checkedItems})">
                  Add to Existing Inspection
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div v-else>
              Selected
            </div>
            <div>
              <b-button variant="link" title="Select All" size="xs" @click="selectAll">
                All
              </b-button>|
              <b-button variant="link" title="Select None" size="xs" @click="selectNone">
                None
              </b-button>
            </div>
          </div>
          <div v-else>
            History
          </div>
        </div>
      </template>
      <template v-slot:cell(action)="data">
        <slot name="action-column-content" v-bind="data.item" />
        <b-checkbox v-if="allowItemCheck" v-model="data.item.checked" />
      </template>
      <template v-slot:cell(latestInspection.components)="data">
        <div>
          <span class="badge badge-danger" v-if="isBusGrounded(data.item.latestInspection)">GROUND</span>
          <span class="badge badge-warning" v-else-if="data.value && data.value.some(c => c.rating == RatingTypes.Fix)">FIX</span>
          <span class="badge badge-success" v-else-if="data.value && data.value.every(c => c.rating == RatingTypes.OK || c.rating == RatingTypes.NotApplicable)">OK</span>
          <span class="badge badge-secondary" v-else-if="data.item.latestInspection && data.item.latestInspection.status == InspectionStatuses.Skipped">N/A</span>
          <span class="badge badge-secondary" v-else>N/A</span>
        </div>
      </template>
      <template v-slot:cell(components)="data">
        <div>
          <span class="badge badge-danger" v-if="isBusGrounded(data.item)">GROUND</span>
          <span class="badge badge-warning" v-else-if="data.value && data.value.some(c => c.rating == RatingTypes.Fix)">FIX</span>
          <span class="badge badge-success" v-else-if="data.value && data.value.every(c => c.rating == RatingTypes.OK || c.rating == RatingTypes.NotApplicable)">OK</span>
          <span class="badge badge-secondary" v-else-if="data.item.status == InspectionStatuses.Skipped">N/A</span>
          <span class="badge badge-secondary" v-else-if="data.value && data.value.some(c => c.rating == RatingTypes.NotChecked)">Incomplete</span>
          <span class="badge badge-secondary" v-else>N/A</span>
        </div>
      </template>
      <template v-slot:cell(latestInspection.status)="data">
        <div>
          <span class="badge" :class="inspectionStatusDisplayOptions(data.value).badgeClass"><i class="fa-fw" :class="inspectionStatusDisplayOptions(data.value).iconClass" /> {{ inspectionStatusDisplayOptions(data.value).text }}</span>
        </div>
      </template>
      <template v-slot:cell(status)="data">
        <div>
          <span class="badge" :class="inspectionStatusDisplayOptions(data.value).badgeClass"><i class="fa-fw" :class="inspectionStatusDisplayOptions(data.value).iconClass" /> {{ inspectionStatusDisplayOptions(data.value).text }}</span>
          &nbsp;
          <b-badge variant="warning" v-if="data.value == InspectionStatuses.Completed && hasFixComponents(data.item)">
            1+ Issues
          </b-badge>
          <b-badge variant="success" v-if="data.value == InspectionStatuses.Completed && !hasFixComponents(data.item)">
            No Issues
          </b-badge>
        </div>
      </template>
      <template v-slot:cell(latestInspection.completionDate)="data">
        <div>
          <span v-if="data.value">{{ formatDate(data.value) }}</span>
          <span v-else>N/A</span>
        </div>
      </template>
    </paginated-table>
  </div>
</template>

<style lang='scss'>
.contact-card {
  margin-top: 1.5em;
}
</style>
<script>
import PaginatedTable from '../../PaginatedTable'
import moment from 'moment'
import { RatingTypes } from '../common/Enums'
import { DpsaftListMixin } from './DpsaftListMixin'
import { isBusGrounded } from '../../../stores/dpsaft/trans'

export default {
  mixins: [DpsaftListMixin],
  data() {
    return {
      listItemType: 'bus',
      fields: []
    }
  },
  methods: {
    isBusGrounded(inspection) {
      return isBusGrounded(inspection)
    },
    hasFixComponents(inspection) {
      return inspection.components.some(c => c.rating == RatingTypes.Fix || isBusGrounded(inspection))
    }
  },
  computed: {
    mayAddInspection() {
      return this.userContext.hasAnyRole(['Insights_DPSAFT_Administrators', 'Insights_DPSAFT_Transportation_Inspectors', 'Insights_DPSAFT_Transportation_Trainers'])
    },
    operativeFields() {
      let fields = this.fields
      if (this.showBatchStatus) {
        fields.push({
          key: 'districtLEA',
          sortable: true
        },
        {
          key: 'busNumber',
          sortable: true,
          formatter: data => { 
            if (data){
              return data
            }
            return '(Not\xa0Assigned)'
          }
        },
        {
          key: 'stickerNumber',
          sortable: true
        },
        {
          key: 'vin',
          sortable: true
        },
        {
          key: 'body',
          sortable: true
        },
        {
          key: 'year',
          sortable: true
        },
        {
          key: 'use',
          sortable: true
        },
        {
          key: 'capacity',
          sortable: true
        },
        {
          key: 'fuel',
          sortable: true
        },
        {
          key: 'components',
          label: 'Bus Status',
        },
        {
          key: 'status',
          label: 'Inspection Status *',
          sortable: true
        })
      } 
      else {
        fields.push({
          key: 'districtLea',
          sortable: true
        },
        {
          key: 'permitNumber',
          label: 'Bus Number',
          sortable: true,
          formatter: data => { 
            if (data){
              return data
            }
            return '(Not\xa0Assigned)'
          }
        },
        {
          key: 'stickerNumber',
          sortable: true
        },
        {
          key: 'vinNumber',
          sortable: true
        },
        {
          key: 'bodyMake',
          sortable: true
        },
        {
          key: 'bodyYear',
          sortable: true
        },
        {
          key: 'primaryUsage',
          sortable: true
        },
        {
          key: 'capacity',
          sortable: true
        },
        {
          key: 'fuelType',
          sortable: true
        },
        {
          key: 'outOfServiceDate',
          formatter: data => (data ? moment(data).format('MM/DD/YYYY') : 'N/A'),
          sortable: true
        },
        {
          key: 'yearLastInspected',
          label: 'Year Last Inspected',
          sortable: true
        },)
      }
      if (this.showLatestInspection) {
        fields.push(
          {
            key: 'latestInspection.components',
            label: 'Bus Status',
          },
          {
            key: 'latestInspection.status',
            label: 'Inspection Status *',
            sortable: true
          },
          {
            key: 'latestInspection.completionDate',
            label: 'Inspection Date *',
            sortable: true
          }
        )
      }
      if (this.showActionColumn) {
        let actionColumn = {
          key: 'action',
          class: 'text-center',
          thClass: 'text-center'
        }
        if (this.allowItemCheck && this.mayAddInspection)
          actionColumn.label = 'History'

        fields.push(actionColumn)
      } 
      if (this.showInspectionDates) {
        fields.push({
          key: 'createdDate',
          formatter: data => (data ? moment(data).format('MM/DD/YYYY') : 'N/A'),
          class: 'text-center',
          thClass: 'text-center',
          sortable: true
        },
        {
          key: 'completionDate',
          formatter: data => (data ? moment(data).format('MM/DD/YYYY') : 'N/A'),
          class: 'text-center',
          thClass: 'text-center',
          sortable: true
        })
      }
      return fields
    }
  },
  components: {
    PaginatedTable
  },
  props: {
    showActionColumn: {
      type: Boolean,
      default: false
    },
    allowAddInspectionForCheckedItems: {
      type: Boolean,
      default: false
    },
    sortBy:{
      type: String,
      default: null
    },
    showInspectionDates: {
      type: Boolean,
      default: false
    },
    selectedBuses: {
      type: Array,
      default() {
        return []
      }
    },
    showLatestInspection: {
      type: Boolean,
      default: true
    },
    isNewInspection:{
      type: Boolean,
      default: false
    }
  },
  watch: {
    items: {
      immediate: true,
      handler(newItems) {
        if (newItems) {
          this.checkableItems = newItems.map(item =>
          Object.assign({ checked: this.isNewInspection || this.selectedBuses.map(b => b.id).includes(item.id) }, item))
        }
      }
    }
  }
}
</script>
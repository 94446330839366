<template>
  <div>
    <b-button class="rightButton mt-1" :to="{name: 'import-buildings'}">
      <i class="fas fa-file-import" /> Import Buildings
    </b-button>
    <div class="clearfix" />
    <facilities-filter @on-filter-changed="onFilterChanged" />
    <building-list :items="items" :allow-item-check="false" @row-selected="onSelected" @item-checked="onItemChecked" :load-data="loadData" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BuildingList from '../../../dpsaft/common/BuildingList'
import FacilitiesFilter from '../../../dpsaft/facilities/FacilitiesFilter.vue'

export default {
  name: 'buildings',
  data() {
    return{
      loadData: null
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState('DPSAFT', ['buildings']),
    items() {
      return this.buildings
    }
  },
  methods: {
    onFilterChanged(newFilter) {
      this.loadData = this.$store.dispatch('DPSAFT/getBuildings', {areaId: newFilter.area, districtLea: newFilter.district})
    },
    onSelected(rows) {
      console.log('Building selected:', rows)
    },
    onItemChecked(checkedItems) {
      console.log('Buildings checked:', checkedItems)
    }
  },
  mounted() {
    this.loadData = Promise.all([
      this.$store.dispatch('DPSAFT/getAreas'),
      this.$store.dispatch('DPSAFT/getBuildings', {areaId: null, districtLea: null, coopLea: null})
    ])
  },
  components: {
    BuildingList,
    FacilitiesFilter
  }
}
</script>

<style lang="scss">
.rightButton {
    position: relative;
    z-index: 99;
    float: right;
}
</style>

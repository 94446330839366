<template>
  <div>
    <h1>Areas</h1>
    <div class="card card-primary">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item">
            <router-link active-class="active" class="nav-link" :to="{name: 'areas'}">
              Area Maintenance
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" active-class="active" :to="{name: 'districts'}">
              Districts
            </router-link>
          </li>
        </ul>
      </div>
      <div class="card-body">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'home',
  computed: {
    ...mapState('globalModule', ['userContext'])
  }
}
</script>

<template>
  <div>
    <div>
      <plan-widget />
    </div>
    <div>
      <plan-dashboard-widget />
    </div>
    <!-- <div>
      <survey-needs-assessment-widget :class="[ (plan && plan.leaPlanId) ? 'page-break-before' : ''] " />
    </div> -->
  </div>
</template>
<script>
// import SurveyNeedsAssessmentWidget from './SurveyNeedsAssessment/SurveyNeedsAssessmentWidget.vue'
import PlanWidget from './Plan/PlanWidget.vue'
import PlanDashboardWidget from './Plan/Dashboard/PlanDashboardWidget.vue'
import { mapState } from 'vuex'
import { Types } from '@/modules/esa/stores/'

export default {
  components: {
    // SurveyNeedsAssessmentWidget,
    PlanWidget,
    PlanDashboardWidget,
  },
  computed: {
    ...mapState(Types.path, [Types.state.plan])
  }
}
</script>
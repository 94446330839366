import Vue from 'vue'
import * as moment from 'moment'
import CardStateIcon from '../CardStateIcon.vue'
import { TableMixin } from '../TableMixin'
import { mapGetters } from 'vuex'
import { Promised } from 'vue-promised'
import { LeaTypes } from '../../helpers/leaTypes'

const ModulePermissionsTable = Vue.extend({
    name: 'master-permissions-table',
    /* html */
    template: `<div class="card bg-light mt-3">
        <div class="card-header">
            Module Permissions
            <span class="float-right">
                <b-button class="btn btn-light" v-b-modal.add-modal><i class="fas fa-user-plus"></i> Add</b-button>
                <b-button class="btn btn-light" :disabled="selectedPermissions.length == 0" @click="confirmRemovePermissions()"><i class="fas fa-user-times"></i> Remove</b-button>
            </span>
        </div>
        <div class="card-body">
            <div class="card" style="overflow-y: hidden; min-height: 25em; height: auto;">
                <div class="card-body inner-card">
                    <Promised v-bind:promise="loadData">
                        <template v-slot:pending>
                          <div class="align-items-center justify-content-center" style="height: 100%; width: 100%">
                              <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
                          </div>
                        </template>
                        <template v-slot="data" :class="centerContent ? 'align-items-center justify-content-center' : ''">
                            <table-control-widget :per-page="tableControl.perPage" v-on:filter-changed="tableControl.filter=$event" v-on:page-length-changed="tableControl.perPage=$event">
                            </table-control-widget>
                            <b-table ref='courseTable' hover selectable responsive small select-mode='multi' :items="allModulePermissions" :per-page="tableControl.perPage" @row-selected="rowSelected"
                            :filter="tableControl.filter" :current-page="tableControl.currentPage" :fields="tableFields"  @filtered="onFiltered">
                            </b-table>            
                            <table-pagination-widget :total-rows-count="totalRowCount" :filtered-rows-count="tableControl.filteredRowCount" :page-length="tableControl.perPage" v-on:page-changed="tableControl.currentPage=$event">
                            </table-pagination-widget>
                        </template>
                        <template v-slot:rejected="error" class="align-items-center justify-content-center">
                            <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" v-bind:message="'Error: ' + error.message" />
                        </template>
                    </Promised>
                </div>
            </div>
        </div>
        <b-modal size="xl" id="add-modal" title="Add User Permissions" no-close-on-backdrop @ok="handleModalOk" ref="permissionsModal" @show="handleModalShow" :ok-disabled="!canAddPermissions">
            <form ref="form" @submit.stop.prevent="handleModalSubmit">    
                <b-container fluid>
                    <b-row>
                        <b-col lg="5">
                            <h4>Modules</h4>
                            <div style="margin-bottom: 10px;">
                                <b-button size="sm" variant="outline-primary" @click="selectAllModules">Select All</b-button>
                                <b-button size="sm" variant="outline-primary" @click="clearModuleSelection">Clear Selection</b-button>
                            </div>    
                            <b-form-group class="clearfix">
                                <b-form-checkbox-group v-model="selectedModules" name="selectedModules">
                                    <b-form-checkbox style="display: block;" v-for="module of pageModules" v-bind:value="module.moduleId" v-bind:key="module.moduleId">{{ module.moduleName }}</b-form-checkbox>
                                </b-form-checkbox-group>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <h4>Users</h4>
                            <table-control-widget :per-page="userTableControl.perPage" v-on:filter-changed="userTableControl.filter=$event" v-on:page-length-changed="userTableControl.perPage=$event">
                            </table-control-widget>
                            <b-table hover responsive outlined small :items="allowableUsers" :per-page="userTableControl.perPage" v-on:filtered="onUserTableFiltered"
                            :filter="userTableControl.filter" :current-page="userTableControl.currentPage" :fields="userTableFields" @row-clicked="userRowClicked" ref="usersTable">
                                <template v-slot:cell(selected)="data">
                                  <b-form-checkbox-group>
                                      <b-form-checkbox type="checkbox" v-model="data.item.selected" />
                                  </b-form-checkbox-group>
                                </template>
                            </b-table>
                            <table-pagination-widget :total-rows-count="userTotalRowCount" :filtered-rows-count="userTableControl.filteredRowCount" :page-length="userTableControl.perPage" v-on:page-changed="userTableControl.currentPage=$event">
                            </table-pagination-widget>
                        </b-col>
                    </b-row>                
                </b-container>
            </form>
            <span>{{ userPermissionsSummary }}</span>
        </b-modal>
    </div>`,
    mixins: [TableMixin],
    props: {
        loadData: Promise
    },
    data() {
        return {
            tableControl: {
                filter: null,
                perPage: 10,
                currentPage: 1,
                filteredRowCount: null
            },
            userTableControl: {
                filter: null,
                perPage: 10,
                currentPage: 1,
                filteredRowCount: null
            },
            permissionLea: {},
            permissionUser: {},
            leaState: null,
            userState: null,
            selectedModules: []
        }
    },
    components: {
        Promised,
        CardStateIcon
    },
    methods: {
        clearSelectedUsers() {
            this.$store.state.adminModule.allowableUsers.forEach(user => this.$set(user, 'selected', false))
        },
        onFiltered(items, count) {
            this.tableControl.filteredRowCount = count
        },
        onUserTableFiltered(items, count) {
            this.userTableControl.filteredRowCount = count
        },
        rows() { 
             return this.allModulePermissions.length;
        },
        moduleName(id) {
            return (this.pageModules.find(m => m.moduleId == id) || { moduleName: 'Unknown Module'}).moduleName;
        },
        rowSelected(selectedItems) {
            this.$store.dispatch('adminModule/updateSelectedPermissions', selectedItems)
        },
        handleModalOk(bvModalEvt) {
            bvModalEvt.preventDefault()

            this.$store.dispatch("adminModule/addPermissions", {
                lea: this.selectedLea.number, 
                users: this.selectedUsers.map(u => u.userId), 
                modules: this.selectedModules
            })
            .then(() => this.$nextTick(() => {
                this.$refs.permissionsModal.hide()
                this.$store.dispatch('adminModule/getAllModulePermissions', { lea: this.selectedLea.number })
            }))
        },
        handleModalShow(bvModalEvt) {
            this.clearModuleSelection()
            this.clearSelectedUsers()
            if (this.allowableUsers.length < 1 || this.pageModules.length < 1) bvModalEvt.preventDefault()
        },
        confirmRemovePermissions() {
            if (this.selectedPermissions.length == 1)
            {
                var permission = this.selectedPermissions[0]
                this.$bvModal.msgBoxConfirm(`Are you sure you want to delete the permission for ${permission.userNameDisplay}?`)
                .then((result) => { if (result) this.$store.dispatch("adminModule/deletePermission", permission.id) })
            }
            else if (this.selectedPermissions.length > 1)
            {
                this.$bvModal.msgBoxConfirm(`Are you sure you want to delete ${this.selectedPermissions.length} permissions?`)
                .then((result) => { if (result) this.$store.dispatch("adminModule/deletePermissions", this.selectedPermissions.map(p => p.id)) })
            }
        },
        selectAllModules() {
            this.selectedModules = this.pageModules.map(module => module.moduleId)
        },
        clearModuleSelection() {
            this.selectedModules = []
        },
        userRowClicked(row) {
            this.$set(row, 'selected', !row.selected)
        }
    },
    computed: {
        allowableLeas() {
            if (this.selectedLea.type === LeaTypes.School || this.selectedLea.type === LeaTypes.Coop)
            {
                return [this.selectedLea]
            }
            else {
                return [this.selectedLea].concat(this.selectedLea.ownedLeas)
            }
        },
        userPermissionsSummary() {
          return `Adding ${this.selectedUsers.length} user${this.selectedUsers.length != 1 ? 's' : ''} to ${this.selectedModules.length} module${this.selectedModules.length != 1 ? 's' : ''}`
        },
        canAddPermissions() {
            return this.selectedUsers.length > 0 && this.selectedModules.length > 0
        },
        selectedUsers() {
            return this.$store.state.adminModule.allowableUsers.filter(u => u.selected)
        },
        allowableUsers() {
            return this.$store.state.adminModule.allowableUsers
        },
        selectedLea() { return this.$store.state.globalModule.leaContext.SelectedLea },
        ...mapGetters('adminModule',['allModulePermissions','pageModules', 'selectedPermissions']),
        tableFields() {
            var fields = [
                { key: 'moduleId', sortable: true, sortDirection: 'asc', label: 'Module', tdClass: 'text-nowrap', formatter: value => this.moduleName(value) },
                { key: 'userName', sortable: true, label: "User", formatter: (value, key, item) => { 
                  if (item.userNameDisplay) 
                    return `${item.userNameDisplay} (${item.userName})` 
                  
                  return item.userName;
                }},
                { key: 'addedDate', label: 'Added Date', sortable: true, tdClass: 'text-nowrap', formatter: value => { return moment(value).format('l LT') } },
                { key: 'addedBy', label: 'Added By', sortable: true, tdClass: 'text-nowrap' }
            ]

            return fields
        },
        totalRowCount() {
            return this.allModulePermissions.length;
        },
        userTableFields() {
            var fields = [
                { key: 'selected', label: '' },
                { key: 'fullName', label: "Name", sortable: true, tdClass: 'text-nowrap' },
                { key: 'userId', label: 'User ID', sortable: true, tdClass: 'text-nowrap' }
            ]

            return fields
        },
        userTotalRowCount() {
            return this.allowableUsers.length;
        }
    },
    watch:{
        
    }
})
export { ModulePermissionsTable }
<template>    
  <loadable-chart-card :load-data="loadData" :enable-data-toggle="false" card-style="" container-class="border-0" card-class="p-1 card border-0 justify-content-center">
    <custom-tooltip
      content="Standard 1: An educator maintains a professional relationship with each student, both in and outside the classroom." 
      label="Type of violation" />
    <b-form-select v-model="selectedViolation" class="form-control mx-1">
      <option value="">
        All violations
      </option>
      <option value="Standard 1">
        Standard 1
      </option>
      <option value="Standard 1 - Pending">
        Standard 1 - Pending
      </option>
    </b-form-select>
    <div class="w-100 p-2">
      <paginated-table 
        id="employee-checks-table"
        :items="filteredEthicsViolations"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        @filtered="onFiltered"
        small striped>
        <template v-slot:cell(caseID)="data">
          <aels-case-id-link :case-id="data.value.toString()" />
        </template>
        <template v-slot:cell(standard1Violation)="data">
          <span class="fa" :class="{'text-danger fa-check-circle': data.value, '': !data.value}" />
        </template>
        <template v-slot:cell(standard1ViolationPending)="data">
          <span class="fa" :class="{'text-danger fa-check-circle': data.value, '': !data.value}" />
        </template>
        <!-- <template v-slot:cell(mostRecentProximitySatisfyingBgcGroupDate)="data">
          {{ data.value | date("MM/DD/YYYY") }}
          <span v-if="data.item.mostRecentProximitySatisfyingBgcGroupYears">&ndash; {{ data.item.mostRecentProximitySatisfyingBgcGroupYears }} year(s)</span>
        </template> -->
        <template v-slot:cell(lea)="data">
          <div>
            {{ leaFormatter(data.value) }}
          </div>
        </template>
        <template slot="empty">
          <h4>No ethics violations found at LEA</h4>
        </template>
      </paginated-table>
    </div>
  </loadable-chart-card>
</template>

<script>

import { TableMixin } from '@/components/TableMixin'
import { ChartMixin } from '../../ChartMixin'
import PaginatedTable from '../../PaginatedTable'
import { mapState } from 'vuex'
import CustomTooltip from '../../CustomTooltip.vue'

export default {
    data() {
        return {
            selectedViolation: null,
            perPage: this.$printMode ? Number.MAX_SAFE_INTEGER : 10,
            currentPage: 1,
            filter: null,
            rowCount: 1,
            animationDuration: 1000,
            fields: [
              { key: 'lea', label: 'LEA', sortable: false },
              { key: 'caseID', sortable: true },
              { key: 'firstName', sortable: true },
              { key: 'middleName', sortable: true },
              { key: 'lastName', sortable: true },
              { key: 'standard1Violation', label: 'Standard 1 Violation', sortable: true },
              { key: 'standard1ViolationPending', label: 'Standard 1 Violation - Pending', sortable: true },
            ]
        }
    },
    mixins: [ChartMixin, TableMixin],
    components: {
      PaginatedTable,
      CustomTooltip,
    },
    computed: {
        leaDictionary() {
          return this.$store.state.globalModule.leaContext.LeaDictionary
        },
        ...mapState('hcptModule', ['ethicsViolations']),
        filteredEthicsViolations() {
          const violations = this.ethicsViolations.filter(e => e.isDistrict)
          debugger
          if (this.selectedViolation && this.selectedViolation == 'Standard 1') {
            return violations.filter(e => e.standard1Violation == true)
          }
          else if (this.selectedViolation && this.selectedViolation == 'Standard 1 - Pending') {
            return violations.filter(e => e.standard1ViolationPending == true)
          }
          return violations
        },
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.rowCount = filteredItems.length
            this.currentPage = 1
        },
        goToLea(leaNumber) {
            const lea = this.leaDictionary[leaNumber]
            window.scrollTo(0,0)
            this.$store.dispatch('globalModule/changeSelectedLea', lea)
        },
    },
}
</script>
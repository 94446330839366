<template>
  <div>
    <b-form-select :options="sortedLeas" :value="value" @input="onInput" />
    <a href="#" class="small pull-right" @click="sortBy=sortBy=='leaNumber'?'name':'leaNumber'">{{ sortByCaption }}</a>
  </div>
</template>

<script>
function leaSorter(i1, i2) {
  const s1 = i1.text.toLowerCase()
  const s2 = i2.text.toLowerCase()

  if (s1 < s2) return -1
  if (s1 > s2) return 1
  return 0
}

function textFormatter(item, firstString, secondString) {
  return `${item[firstString]} - ${item[secondString]}`
}

export default {
  data() {
    return {
      sortBy: 'leaNumber'
    }
  },
  computed: {
    sortByCaption() {
      return this.sortBy == 'leaNumber' ? 'Sort by Name' : 'Sort by LEA'
    },
    sortComparisonFunction() {
      return leaSorter(this.sortBy)
    },
    sortedLeas() {
      const sortField = this.sortBy
      const otherField = this.sortBy == 'leaNumber' ? 'name' : 'leaNumber'

      return this.sourceLeas.map(i => {
        return {
          value: i.leaNumber,
          text: textFormatter(i, sortField, otherField)
        }
      }).sort(leaSorter)
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    }
  },
  props:{
    value: String,
    sourceLeas:{
      type: Array,
      default:() => []
    }
  }
}
</script>
<template lang="html">
  <div>
    <b-table :fields="tableOptions.fields" :items="leaGrowthSummary">
      <template v-slot:head()="data">
        <span v-html="data.label" />
      </template>
      <template v-slot:cell(subGroup)="data">
        <span>{{ subgroups.find(s => s.value == data.value).text }}</span>
      </template>
      <template v-slot:cell(grade)="data">
        <span>{{ data.value || 'All Grades' }}</span>
      </template>
      <template v-slot:cell(overallGrowthScore)="data">
        <span>{{ data.value }} <sign-indicator :value="data.value" /></span>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SignIndicator from '../../../SignIndicator'

export default {
  data() {
    return {
      tableOptions: {
        fields: [
          {
            key: 'subject',
            sortable: true
          },
          {
            key: 'grade',
            sortable: true
          },
          {
            label: 'Total<br />Number',
            key: 'n',
            sortable: true
          },
          {
            label: 'Average<br />Growth<br />Score',
            key: 'overallGrowthScore',
            sortable: true
          },
          {
            label: 'Growth<br />Standard<br />Deviation',
            key: 'stdDeviation',
            sortable: true
          },
          {
            label: 'Min<br />Growth<br />Score',
            key: 'minScore',
            sortable: true
          },
          {
            label: 'Max<br />Growth<br />Score',
            key: 'maxScore',
            sortable: true
          }
        ]
      }
    }
  },
  components: {
    SignIndicator
  },
  computed: {
    ...mapState('academicsModule/teacherGrowth', ['leaGrowthSummary'])
  },
  methods: {
    dataItems() {
      return this.leaGrowthSummary.map(g => {
        return {
          Subject: g.subject,
          Grade: g.grade,
          TotalNumber: g.n,
          AverageGrowthScore: g.overallGrowthScore,
          GrowthStdDev: g.stdDeviation,
          MinGrowthScore: g.minScore,
          MaxGrowthScore: g.maxScore,
        } 
      })
    },
    excelExportData() {
      return {
        name: 'Growth Summary',
        items: this.dataItems()
      }
    }
  }
}
</script>

const Categories = [
  'Unrestricted Revenue from State and Local Sources',
  'Restricted Revenue from State Sources',
  'Restricted Revenue from Federal Sources',
  'Other Sources of Funds',
  'Revenue and Other Sources of Funds from All Sources',
  'Instruction',
  'District Support Services',
  'School Support Services',
  'Non-Instructional Services',
  'Expenditures',
  'Current Expenditures',
  'Net Current Expenditures',
]
export { Categories }
import ApiService from '../../services/ApiService'
import { SCTClosures } from './sctClosures'
import { CurriculumTransparency } from './curriculumTransparency'

export class Core {

  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
  }

  namespaced = true

  state = {
  
  }

  mutations = {
  }

  getters = {
  
  }

  actions = {
  
  }

  modules = {
    sctClosures: new SCTClosures(),
    curriculumTransparency: new CurriculumTransparency()
  }
}
<template>
  <div>
    <b-modal id="security-check-editor-modal" ref="security-check-editor-modal" :title="securityCheck.buildingName">
      <b-form>
        <b-form-row>
          <b-col>
            <b-form-group label="Perimeter Doors Secured?">
              <b-checkbox v-model="securityCheck.response.perimeterDoorsSecured" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Interior Doors Secured?">
              <b-checkbox v-model="securityCheck.response.interiorDoorsSecured" />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group label="Staff Wearing Badges?">
              <b-checkbox v-model="securityCheck.response.staffWearingBadges" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Visitor Security?">
              <b-checkbox v-model="securityCheck.response.visitorSecurity" />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group label="Drills Completed?">
              <b-checkbox v-model="securityCheck.response.drillsCompleted" />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-if="securityCheck.buildingSecurityCheckId > 0">
          <b-col>
            <b-form-group label="Created By">
              <div> {{ securityCheck.createdBy }}</div>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Created Date">
              <div> {{ securityCheck.createdDate | date('short-date') }}</div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-if="securityCheck.buildingSecurityCheckId > 0">
          <b-col>
            <b-form-group label="Updated By">
              <div> {{ securityCheck.updatedBy }}</div>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Updated Date">
              <div> {{ securityCheck.updatedDate | date('short-date') }} </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-group label="Security Check Date">
          <b-form-datepicker v-model="securityCheck.securityCheckDate" />
        </b-form-group>
      </b-form>

      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button variant="secondary" class="float-right " @click="onEditingCancelled">
            Cancel
          </b-button>
          <b-button variant="outline-primary" class="float-right mr-2" @click="save" :disabled="!securityCheck.securityCheckDate">
            Save
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script type="javascript">
import ApiService from '@/services/ApiService'

export default {
  data() {
    return {
    }
  },
  methods: {
    save() {
      const apiService = new ApiService()
      const payload = { ...this.securityCheck, response: JSON.stringify(this.securityCheck.response) }
      apiService.Post(`/api/dpsaft/facilities/security/security-check`, payload).then(() => {
        this.$emit('saved')
        this.$refs['security-check-editor-modal'].hide()
      }).catch(error => {
        console.log(error)
      })
    },
    onEditingCancelled() {
      this.errors = []
      this.$refs['security-check-editor-modal'].hide()
    },
    onEditingComplete() {
    },
  },
  computed: {
  },
  props: {
    securityCheck: Object
  },
}
</script>

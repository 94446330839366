<template>
  <form :id="formId">
    <div>
      <reconfiguration-code-selector
        v-if="item"
        :item="item"
        :purpose="ReconfigurationCodePurpose.NewSchool"
        :operation="operation"
      />
      <div class="form-group row mb-0">
        <b-toast
          :id="errorsToastId"
          title="New LEA Issues"
          header-class="bg-success text-white"
          no-auto-hide
        >
          <b-list-group>
            <b-list-group-item
              v-for="error in errors.flatMap(e => e)"
              :key="JSON.stringify(error)"
            >
              {{ error.error }}
            </b-list-group-item>
          </b-list-group>
        </b-toast>
        <div class="col-md-3 mb-3 mt-1">
          New LEA Name:
        </div>
        <b-form-input
          required
          placeholder="Enter LEA Name"
          class="col-md-9 mb-2"
          v-model="item.leaName"
        />
      </div>
      <div class="form-group row mb-0">
        <div class="col-md-3 mb-3 mt-1">
          Grade Span:
        </div>
        <div class="col-md-9 mb-2 p-0">
          <b-form-select
            required
            style="width: 150px;"
            :options="gradeSpans"
            v-model="item.gradeLow"
          />
          <b-form-select
            required
            style="width: 150px;"
            :options="gradeSpans"
            v-model="item.gradeHigh"
          />
        </div>
      </div>
      <div class="form-group row mb-0">
        <div class="col-md-3 mb-3 mt-1">
          LEA Type Code:
        </div>
        <b-form-select
          required
          class="col-md-9 mb-2"
          :options="schoolTypes"
          v-model="item.leaTypeCode"
          :disabled="!userContext.hasRole('Insights_RT-SMS_Administrators')"
        />
      </div>
      <div class="form-group row mb-0">
        <div class="col-md-3 mb-3 mt-1">
          School Type:
        </div>
        <b-form-select
          required
          class="col-md-9 mb-2"
          :options="buildingTypes"
          v-model="item.buildingType"
        />
      </div>
      <div class="form-group row mb-0">
        <div class="col-md-3 mb-3 mt-1">
          This is a Feeder School
        </div>
        <b-form-checkbox required class="col-md-1 mb-2" v-model="item.isFeederSchool" />
        <div class="col-8 p-0" v-if="item.isFeederSchool">
          <div class="row">
            <div class="col-3">
              Paired School:
            </div>
            <div class="col-9">
              <v-select
                required
                :options="schoolOptions"
                v-model="item.pairedSchool"
                :reduce="lea=>lea.code"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row mb-0">
        <div class="col-md-3 mb-3 mt-1">
          This is a Paired School
        </div>
        <b-form-checkbox required class="col-md-1 mb-2" v-model="item.isPairedSchool" />
        <div class="col-8 p-0" v-if="item.isPairedSchool">
          <div class="row">
            <div class="col-3">
              Feeder School(s):
            </div>
            <div class="col-9">
              <v-select
                required
                multiple
                :options="schoolOptions"
                v-model="item.feederSchools"
                :reduce="lea=>lea.code"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row mb-0">
        <div class="col-md-3 mb-3 mt-1">
          This is an Alternative School
        </div>
        <b-form-checkbox required class="col-md-9 mb-2" v-model="item.isAlternativeSchool" />
      </div>
      <div class="form-group row mb-0">
        <div class="col-md-3 mb-3 mt-1">
          This is a Charter School
        </div>
        <b-form-checkbox
          required
          class="col-md-9 mb-2"
          v-model="item.isCharter"
          @input="charterChanged"
        />
      </div>
      <div class="form-group row mb-0" v-if="item.isCharter">
        <div class="col-md-3 mb-3 mt-1">
          This is a Conversion Charter
        </div>
        <b-form-checkbox required class="col-md-9 mb-2" v-model="item.isConversionCharter" />
      </div>
      <div class="form-group row mb-0">
        <div class="col-md-3 mb-3 mt-1">
          Address:
        </div>
        <b-form-input
          required
          placeholder="Enter address"
          class="col-md-9 mb-2"
          v-model="item.address"
        />
      </div>
      <div class="form-group row">
        <div class="col-md-3 mb-3 mt-1">
          Address continued:
        </div>
        <div class="col-3 pl-0">
          <label>City</label>
          <b-form-input required placeholder="Enter city" v-model="item.city" />
        </div>
        <div class="col-2">
          <label>State</label>
          <b-form-input required placeholder="Enter state" v-model="item.state" />
        </div>
        <div class="col-2">
          <label>Zip</label>
          <b-form-input required placeholder="Enter zip" v-model="item.zip" />
        </div>
        <div class="col-2 pr-0">
          <label>Zip+4</label>
          <b-form-input required placeholder="Enter zip+4" v-model="item.zip4" />
        </div>
      </div>

      <div class="form-group row mb-0">
        <div class="col-md-3 mb-3 mt-1">
          Additional Comments:
        </div>
        <textarea class="form-control col-md-9" v-model="item.additionalComments">Enter any additional instructions or comments here...</textarea>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { RequestItemMixin } from '../RequestItemMixIn'
import { ItemValidationMixin } from './ItemValidationMixin'
import { RequestOperationPurpose } from '../../../leaAssignment'

export default {
  name: 'new-lea-item',
  data() {
    return {
      type: 'New LEA',
      requiredFields: ['leaName', 'buildingType', 'gradeLow', 'gradeHigh', 'address', 'city', 'state', 'zip']
    }
  },
  computed: {
    ...mapState('toolsModule/leaAssignment', ['schools', 'currentRequest', 'currentResponse']),
    ...mapState('toolsModule/leams', ['leaTypes']),
    ...mapState('globalModule', ['userContext']),
    ...mapGetters('toolsModule/leaAssignment', ['newBuildingRequestsOnCurrentRequest']),
    schoolTypes() {
      return this.leaTypes.filter(t => t.code[0] == 'S').map(t => ({ value: t.code, text: t.description }))
    },

    schoolOptions() {

      var requestSchools = this.newBuildingRequestsOnCurrentRequest
            .filter(s => s.key != this.item.key) //cannot feed or pair with itself
            .map(s => ({
        code: s.key,
        label: `${s.lea || '(LEA # TBD)'} - ${s.name}`
      }))

      var existingSchools = this.schools.map(s => ({
        code: s.leaNumber,
        label: `${s.leaNumber} - ${s.name}`
      }))

      if (this.operation != RequestOperationPurpose.NewDistrict) {
        requestSchools.splice(0,0, ...existingSchools)
      }

      return requestSchools
    }
  },
  props: {
    item: Object
  },
  methods: {
    charterChanged() {
      if (!this.item.isCharter) this.item.isConversionCharter = false
    }
  },
  mixins: [RequestItemMixin, ItemValidationMixin]
}
</script>

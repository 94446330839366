import BlendedLearningReadinessSidebar from './sidebar'
import BlendedLearningReadinessPage from './blendedLearningReadinessPage'
import arrflPage from './arkansasReadyForLearning/arrflPage'
import arrflSidebar from './arkansasReadyForLearning/arrflSidebar'
import arrflRegistration from './arkansasReadyForLearning/arrflRegistration'
// import districtClosuresPage from './districtClosures/districtClosuresPage'
// import districtClosuresSidebar from './districtClosures/districtClosuresSidebar'
// import districtClosuresLogin from './districtClosures/districtClosuresLogin'

const BlendedLearningReadinessArea = {
  BlendedLearningReadinessSidebar,
  BlendedLearningReadinessPage,
  arrflPage,
  arrflSidebar,
  arrflRegistration,
  // districtClosuresLogin,
  // districtClosuresPage,
  // districtClosuresSidebar
}

export {
  BlendedLearningReadinessArea
}


<template>
  <div>
    <loadable-chart-card :load-data="loadData">
      <template v-slot:default="slotProps">
        <div class="chartElem" v-show="slotProps.chartMode">
          <highcharts class="chart" :options="chartOptions" />
        </div>
        <div v-show="!slotProps.chartMode">
          <chart-data-table :items="dataItems()" />
        </div>
      </template>
    </loadable-chart-card>
  </div>
</template>

<script>
import LoadableChartCard from '../../LoadableChartCard.vue'
import { ChartMixin } from '../../ChartMixin'
import { credits } from '../../../helpers/credits'

export default {
  name: 'alternative-method-of-instruction-days-school-chart',
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'column'
        },
        credits: credits.SIS,
        title: {
          text: this.chartTitle
        },
        series: [],
        xAxis: {
          title: {
            text: 'School Year'
          }
        },
        yAxis: {
          title: {
            text: this.chartTitle
          },
          min : 0
        }
      }
    }
  },
  components: {
    LoadableChartCard
  },
  methods: {
    dataItems() {
      return this.amiDayData
    },
    amiLeas() {
      return [...new Set(this.amiDayData.map(a => a.name))]
    }
  },
  props: {
    loadData: Promise,
    chartTitle: String,
    amiDayData: Array
  },
  mixins: [ChartMixin],
  watch: {
    amiDayData(newData) {
      this.chartOptions.series = []
      this.chartOptions.series = this.amiLeas().map(lea => {
        return { 
          name: lea,
          data: newData.filter(d => d.name == lea).map(row => {
            return {
              x: (row.fiscalYear % 1990 + 1990),
              y: row.dayCount
            }
          })
        }
      })
    }
  }
}
</script>
<template>
  <form>
    <reconfiguration-code-selector
      v-if="item"
      :item="item"
      :purpose="ReconfigurationCodePurpose.NewSchool"
      :operation="operation"
    />
    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">New LEA Name:</label>
      <div class="col-md-9">
        <span class="form-control-plaintext">{{ item.leaName }}</span>
      </div>
    </div>
    <div class="form-group row mb-0">
      <div class="col-md-3 mb-3 mt-1">
        LEA Type Code:
      </div>
      <b-form-select
        required
        class="col-md-9 mb-2"
        :options="schoolTypes"
        v-model="item.leaTypeCode"
        :disabled="!(userContext.hasRole('Insights_RT-SMS_Administrators') && !currentRequest.isApprovedRejectedOrCanceled)"
      />
    </div>
    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">Grade Span:</label>
      <div class="col-md-9">
        <span
          class="form-control-plaintext"
        >{{ gradeSpanString(item.gradeLow, item.gradeHigh) }}</span>
      </div>
    </div>
    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">School Type:</label>
      <div class="col-md-9">
        <span class="form-control-plaintext">{{ buildingTypeString(item.buildingType) }}</span>
      </div>
    </div>
    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">Alternative School:</label>
      <div class="col-md-9">
        <div class="form-control-plaintext">
          <pretty-checkbox :value="item.isAlternativeSchool" />
        </div>
      </div>
    </div>
    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">Charter:</label>
      <div class="col-md-9">
        <div class="form-control-plaintext">
          <pretty-checkbox :value="item.isCharter" />
        </div>
      </div>
    </div>
    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">Conversion Charter:</label>
      <div class="col-md-9">
        <div class="form-control-plaintext">
          <pretty-checkbox :value="item.isConversionCharter" />
        </div>
      </div>
    </div>
    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">Address:</label>
      <div class="col-md-9">
        <div class="form-control-plaintext">
          {{ item.address }}
        </div>
      </div>
    </div>
    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">City:</label>
      <div class="col-md-9">
        <div class="form-control-plaintext">
          {{ item.city }}
        </div>
      </div>
    </div>
    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">State:</label>
      <div class="col-md-9">
        <div class="form-control-plaintext">
          {{ item.state }}
        </div>
      </div>
    </div>
    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">Zip:</label>
      <div class="col-md-9">
        <div class="form-control-plaintext">
          {{ item.zip }}
        </div>
      </div>
    </div>
    <paired-feeder-information-block :paired-source="pairedSchoolInfo.request" :feeder-source="feederSchoolInfo.request" />
    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">Additional Comments:</label>
      <div class="col-md-9">
        <div class="form-control-plaintext">
          {{ item.additionalComments }}
        </div>
      </div>
    </div>
    <div class="form-group row mb-0" v-if="currentRequest">
      <label class="col-md-3 col-form-label">LEA # Assigned:</label>
      <div class="col-md-4">
        <slot name="lea">
          <span class="form-control-plaintext">{{ lea }}</span>
        </slot>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex'
import { RequestItemMixin } from '../RequestItemMixIn'
import PrettyCheckbox from '../../../../PrettyCheckbox.vue'
import PairedFeederInformationBlock from './PairedFeederInformationBlock.vue'

export default {
  name: 'new-lea-item',
  data() {
    return {
      leaInput: null,
      assignedLea: 'Unassigned'
    }
  },
  mounted() {},
  computed: {
    ...mapState('toolsModule/leams', ['leaTypes']),
    ...mapState('globalModule', ['userContext']),    
    schoolTypes() {
      return this.leaTypes.filter(t=>t.code[0]=='S').map(t=> ({value: t.code, text: t.description}))
    },
    lea() {
      return (this.currentRequest.requestLeas.find(l => l.operationParams == this.item.key) || {}).lea || this.assignedLea
    }
  },
  props: {
    item: Object
  },
  mixins: [RequestItemMixin],
  components: {
    PrettyCheckbox,
    PairedFeederInformationBlock
  }
}
</script>

<template>
  <div>
    <highcharts class="chart" :options="chartOptions" />
  </div>
</template>
<script>
import { isReopened, isExpired } from '@/stores/digitalLearning'

import { WaiverChartMixin } from './WaiverChartMixin'

export default {
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'column',
          height: this.$printMode ? 650 : 400
        },
        legend: {
          maxHeight: this.$printMode ? 450 : 200
        },
        credits: {
          enabled: false,
          text: 'LEA Insights'
        },
        title: {
          text: `Act 1240 Waiver Requests by Status`
        },
        plotOptions: {
          column: {
          }
        },
        series: [],
        xAxis: {
          title: {
            text: this.xAxis
          },
          categories: ['Status']
        },
        yAxis: {
          title: {
            text: 'Count'
          },
          endOnTick: false
        }
      },
      statuses: [
        { name: 'Not Submitted', color: '#6c757d', statusCodes: [0] },
        { name: 'Submitted to ADE', color: '#17a2b8', statusCodes: [11]},
        { name: 'Re-opened', color: '#007bff', statusCodes: ['Re-opened']},
        { name: 'Expired', color: '#dc3545', statusCodes: ['Expired']},
        { name: 'Information or Changes Requested', color: '#ffc107', statusCodes: [1]},
        { name: 'Reviewed or Approved', color: '#33cc33',  statusCodes: [100, 101]},
      ]
    }
  },
  computed:{
    requestByStatus() {
      return this.waiverRequests.filter(wr => wr.waiverType == this.selectedWaiverType.value)
    }
  },
  props: {
    selectedWaiverType: Object
  },
  watch:{
    selectedWaiverType(type) {
      this.$set(this.chartOptions.title, 'text', `${type.listTitle} by Status`)
    },
    requestByStatus:{
      immediate: true,
      handler() {
        var requests = this.requestByStatus
        this.chartOptions.series = []
        this.chartOptions.series = this.statuses.map(s => {
          let count = 0;
          if (s.statusCodes[0]  == 'Re-opened')
            count = [requests.filter(wr => isReopened(wr)).length]
          else if (s.statusCodes[0]  == 'Expired')
            count = [requests.filter(wr => isExpired(wr)).length]
          else
            count = [requests.filter(wr => s.statusCodes.indexOf(wr.status) >= 0).length]

          return {
            name: s.name,
            color: s.color,
            data: count,
          }
        })
      }
    }
  },
  mixins: [WaiverChartMixin]
}
</script>
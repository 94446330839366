import Vue from 'vue'
import { ChartMixin } from '../../ChartMixin'
import { mapGetters } from 'vuex'
import { credits } from '../../../helpers/credits'

const NbctTeacherCountChart = Vue.extend({
    name: 'nbct-teacher-count-chart',
    data() {
        return {
            animationDuration: 1000,
            chartOptions: {
                chart: {
                    type: 'column'
                },
                credits: credits.SISandAELS,
                title: {
                    text: 'Trend of NBCT Certified vs. Not NBCT Certified Teachers'
                },
                legend: {
                    enabled: true
                },
                series: [
                    {
                        name: 'NBCT Certified',
                        color: '#28a745'
                        
                    },
                    {
                        name: 'Not NBCT Certified',
                        color: '#878787'
                    }
                ],
                xAxis: {
                    title: {
                        text: 'School Year'
                    },
                    type: 'category'
                },
                yAxis: {
                    title: {
                        text: 'Number of Teachers'
                    },
                    endOnTick: false
                },
                plotOptions: {
                    series: {
                        minPointLength: 3
                    }
                }
            }
        }
    },
    /* html */
    template: `
        <loadable-chart-card v-bind:load-data="loadData">
            <template v-slot:default="slotProps">
                <div class="chartElem" v-show="slotProps.chartMode">
                    <highcharts class="chart" :options="chartOptions"></highcharts>
                </div>
                <div v-show="!slotProps.chartMode">
                    <chart-data-table v-bind:items="dataItems()">
                    </chart-data-table>
                </div>
            </template>
        </loadable-chart-card>`,
    mixins: [ChartMixin],
    computed: {
        ...mapGetters('hcptModule', ['nbctTrendData'])
    },
    watch: {
        'nbctTrendData': function(newData) {
            console.log(newData);

            this.chartOptions.series[0].data = newData.map(item => {
                return {
                    x: (item.fy % 1990) + 1990,
                    y: item.isNBCTCertified
                }
            })

            this.chartOptions.series[1].data = newData.map(item => {
                return {
                    x: (item.fy % 1990) + 1990,
                    y: item.notNBCTCertified
                }
            })
        },
        selectedLea() {
            this.chartOptions.series[0].name = this.selectedLea.name
        }
    },
    methods: {
        dataItems() {
            return this.chartOptions.series[0].data.map((data, index) => {
                return { 
                    SchoolYear: data.x,
                    NbctCertified: data.y,
                    NotNbctCertified: this.chartOptions.series[1].data[index].y
                }
            })
        }
    }
})

export { NbctTeacherCountChart }
<style>
  @media only screen and (max-width: 600px) {
    .d-none-optional {
        display: none;
     }
  }
</style>

<template>
  <div v-if="initialized">
    <div class="d-flex flex-row">
      <a href="javascript:history.go(-1)" class="pt-1">
        <i class="fas fa-arrow-alt-circle-left fa-2x" />
      </a>
      <h3 class="ml-2 pt-1">
        Bus #: {{ SelectedInspection.busNumber }}, Sticker #: {{ SelectedInspection.stickerNumber }}
      </h3>
    </div>

    <inspection :inspection="SelectedInspection" />
  </div>
</template>

<script>
import Inspection from './Inspection'
import { mapState } from 'vuex'
import { Types } from '../../../../stores/dpsaft/trans'

export default {
  name: 'mobile-inspection',
  data() {
    return {
      initialized: false
    }
  },
  components: {
    Inspection
  },
  computed: {
    ...mapState('DPSAFT/Trans', [Types.state.SelectedInspection, Types.state.SelectedBatch, Types.state.inspectionBatches]),
    ...mapState('globalModule', ['isOnline'])
  },
  created() {
    if (!this.SelectedBatch || this.SelectedBatch.batchId != this.$route.params.batchId) {
      this.$store.commit(Types.mutations.setSelectedBatch, { ...this.inspectionBatches.find(b => b.batchId == this.$route.params.batchId) })
    }
    if (this.isOnline) {
      this.$store.dispatch(Types.actions.getInspection, { batchId: this.$route.params.batchId, inspectionId: this.$route.params.inspectionId }).then(() => this.initialized = true)
    }
    else {
      this.$store.commit(Types.mutations.setSelectedInspection, { ...this.SelectedBatch.inspections.find(i => i.inspectionId == this.$route.params.inspectionId) })
      this.initialized = true
    }
  }
}
</script>

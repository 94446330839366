<template>
  <tbody>
    <tr>
      <td class="expenditure-label">
        {{ label }}
      </td>
      <td v-for="(value, index) in values" :key="index" class="text-center">
        {{ format(value) }}
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: {
    label: String,
    values: Array
  },
  methods: {
    format(value) {
      if (value) {
        return Math.round(value).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        })
      }
        
      return value
    }
  }
}
</script>

<template>
  <div v-if="reportData" class="report-output">
    <div />
    <b-table-simple caption-top>
      <caption>Students</caption>
      <b-thead>
        <b-tr>
          <b-th style="width: 375px">
            Grades
          </b-th>
          <b-th v-for="g in reportData.enrollment" :key="g.grade">
            {{ g.grade }}
            <sup>th</sup>
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-th>
            # Students
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ g.totalEnrollment }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td :colspan="captionColspan" class="caption-cell">
            Assessments
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            # Students with Assessment
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getValueByPredicate ('completedAnyAssessments', (r)=>r.grade == Number(g.grade), 'anyAssessmentCount' ) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            % Students with Assessment
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getPercentOfEnrollmentByPredicate ('completedAnyAssessments', g.grade, (r)=>r.grade == Number(g.grade), 'anyAssessmentCount' ) | toPercentage(2, true) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            # Completed ACT
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getValueByPredicate ('completedACT', (r)=>r.grade == Number(g.grade), 'actAssessments' ) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            % Completed ACT
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getPercentOfEnrollmentByPredicate ('completedACT', g.grade, (r)=>r.grade == Number(g.grade), 'actAssessments' ) | toPercentage(2, true) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            # Completed ACT Aspire
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getValueByPredicate ('completedAspire', (r)=>r.grade == Number(g.grade), 'aspireCount' ) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            % Completed ACT Aspire
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getPercentOfEnrollmentByPredicate ('completedAspire', g.grade, (r)=>r.grade == Number(g.grade), 'aspireCount' ) | toPercentage(2, true) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            # Completed Other Assessment
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getValueByPredicate ('completedOther', (r)=>r.grade == Number(g.grade), 'otherAssessmentCount' ) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            % Completed Other Assessment
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getPercentOfEnrollmentByPredicate ('completedOther', g.grade, (r)=>r.grade == Number(g.grade), 'otherAssessmentCount' ) | toPercentage(2, true) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td :colspan="captionColspan" class="caption-cell">
            # GPA Below 2.0
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            # Students GPA &lt; 2.0
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getValueByPredicate ('gpaLessThanTwo', (r)=>r.grade == Number(g.grade), 'lessThan2PointGPA' ) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            % Students GPA &lt; 2.0
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getPercentOfEnrollmentByPredicate ('gpaLessThanTwo', g.grade, (r)=>r.grade == Number(g.grade), 'lessThan2PointGPA' ) | toPercentage(2, true) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td :colspan="captionColspan" class="caption-cell">
            Certifications
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            # Certification Tests Taken
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getValueByPredicate ('certifications', (r)=>r.grade == Number(g.grade), 'numTaken' ) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            # Certification Tests Passed
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getValueByPredicate ('certifications', (r)=>r.grade == Number(g.grade), 'numPassed' ) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            % Certification Tests Passed
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ (getValueByPredicate ('certifications', (r)=>r.grade == Number(g.grade), 'numPassed' ) / getValueByPredicate ('certifications', (r)=>r.grade == Number(g.grade), 'numTaken' ) || 0) | toPercentage(2, true) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            # Students Took Certification Test
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getValueByPredicate ('certifications', (r)=>r.grade == Number(g.grade), 'numStudentsTaken' ) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            % Students Took Certification Test
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getPercentOfEnrollmentByPredicate ('certifications', g.grade, (r)=>r.grade == Number(g.grade), 'numStudentsTaken' ) | toPercentage(2, true) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            # Students Passed Certification Test
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getValueByPredicate ('certifications', (r)=>r.grade == Number(g.grade), 'numStudentsPassed' ) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            % Students Passed Certification Test
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getPercentOfEnrollmentByPredicate ('certifications', g.grade, (r)=>r.grade == Number(g.grade), 'numStudentsPassed' ) | toPercentage(2, true) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td :colspan="captionColspan" class="caption-cell">
            Remediation Based on ACT Scores (19)
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            # Students Remedial Math
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getValueByPredicate ('remediation', (r)=>r.grade == Number(g.grade), 'mathRemediationNeeded' ) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            % Students Remedial Math
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getPercentOfEnrollmentByPredicate ('remediation', g.grade, (r)=>r.grade == Number(g.grade), 'mathRemediationNeeded' ) | toPercentage(2, true) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            # Students Remedial English
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getValueByPredicate ('remediation', (r)=>r.grade == Number(g.grade), 'englishRemediationNeeded' ) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            % Students Remedial English
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getPercentOfEnrollmentByPredicate ('remediation', g.grade, (r)=>r.grade == Number(g.grade), 'englishRemediationNeeded' ) | toPercentage(2, true) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            # Students Remedial Reading
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getValueByPredicate ('remediation', (r)=>r.grade == Number(g.grade), 'readingRemediationNeeded' ) }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            % Students Remedial Reading
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getPercentOfEnrollmentByPredicate ('remediation', g.grade, (r)=>r.grade == Number(g.grade), 'readingRemediationNeeded' ) | toPercentage(2, true) }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>

export default {
  data() {
    return {
    }
  },
  computed: {
    captionColspan() {
      return this.reportData.enrollment.length + 1
    }
  },
  props: {
    reportData: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    getValueByPredicate(collection, predicate, property) {
      var gradeRow = this.reportData[collection].find(r=>predicate(r))

      return (gradeRow || {})[property] || 0
    },

    getPercentOfEnrollmentByPredicate(collection, enrollmentGrade, predicate, property) {
      var value = this.getValueByPredicate(collection, predicate, property)
      var enrollment = this.getValueByPredicate('enrollment', (r)=>r.grade == enrollmentGrade, 'totalEnrollment')
      return value / enrollment
    }
  }
}
</script>

<style>
  .caption-cell {
    background-color: #4785c8;
    color: #0f2134;
    border-radius: 3px;
    font-weight: bold;
    font-size: 1.5em;
    padding: 0px 5px !important;
    border-top: 10px solid #dadada !important;
  }
</style>

import { mapState } from 'vuex'
import { Types } from '@/modules/cte/stores/careercoach'
import { metadata as staticMetadata } from '@/modules/cte/stores/careercoach/metadata'
import { JsonResponseMixin } from '@/components/JsonResponseMixin'
import { deepClone } from '@/helpers/globalHelpers'
import StudentInformationToolbar from './StudentInformationToolbar'

export const StudentComponentMixin = {
  inject: ['$v'],
  computed: {
    ...mapState(Types.path, [Types.state.currentStudent])
  },
  mixins: [JsonResponseMixin],
  components: {
    StudentInformationToolbar,
  },
  data() {
    return {
      staticMetadata
    }
  },
  methods: {
    save(responseName) {
      this.preSave()
      return this.$store.dispatch(Types.actions.saveStudentInformation, { responseName, response: this.local })
    },
    cancel() {
      this.$store.commit(Types.mutations.setCurrentStudent, deepClone(this.currentStudent))
    },
    preSave() {
      // do nothing unless overriden
      return
    }
  },
  props: {
    validation: {
      type: Object
    }
  },
  watch: {
    local: {
      deep: true,
      handler() {
        this.$v.$touch()
      }
    },
    value: {
      handler(newData, oldData) {
        if (newData && newData != oldData) {
          this.$nextTick(() => this.$v.$reset())
        }
      },
      immediate: true
    }
  }
}

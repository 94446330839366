<template>
  <div>
    <div class="row d-print-none">
      <div class="col-12">
        <table class="w-100 table table-sm table-borderless p-0 m-0">
          <thead> 
            <th v-for="snapshotYear in fiscalYears(plan.snapshotYears || [])" :key="snapshotYear" class="text-center text-secondary">
              {{ formatSchoolYear(snapshotYear-1) }}-{{ formatSchoolYear(snapshotYear) }} Plan
            </th>
          </thead>
          <tbody>
            <td v-for="snapshotYear in fiscalYears(plan.snapshotYears || [])" :key="snapshotYear" class="text-center">
              <b-button 
                v-for="snapshot in (plan.snapshotYears || []).filter(s => s.fiscalYear == snapshotYear)"
                :key="snapshot.fiscalYear + snapshot.month"
                size="sm" 
                variant="link" 
                :href="`/api/esa/${plan.districtLea}/plan/snapshot/${snapshot.fiscalYear}/${snapshot.month}`">
                <span class="text-secondary fas fa-file-pdf" /> {{ formatSnapshotYear(snapshot) }}
              </b-button>
            </td>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card bg-light" :class="{ 'no-print': (!plan || !plan.leaPlanId) }">
      <div class="card-header lead">
        Current Plan
      </div>
      <div class="card-body">
        <Promised :promise="loadData">
          <template v-slot:pending>
            <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
              <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
            </div>
          </template>
          <template v-slot>
            <div class="chart">
              <div v-if="!unauthorized">
                <plan-header />
                <div>
                  <div v-for="category in planCategories" :key="category.id" class="page-break-before">
                    <plan-category :category="category" />
                  </div>
                </div>
                <div class="row d-print-none">
                  <div class="col-12 mt-3">
                    <div class="card">
                      <div class="card-header bg-light text-dark font-weight-bold">
                        Supporting Files
                      </div>
                      <div class="card-body">
                        <plan-files :files="files" :id="plan.leaPlanId" rows="5" max-rows="100" :readonly="!canEdit" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row d-print-none">
                  <div class="col-12">
                    <div class="card mt-3 bg-light">
                      <a class="anchor d-print-none" />
                      <div class="card-header bg-info">
                        <a class="text-white cursor-pointer" v-b-toggle.collapsehistory>
                          <span class="when-opened">
                            <i class="fa fa-chevron-down" />
                          </span>
                          <span class="when-closed">
                            <i class="fa fa-chevron-right" />
                          </span>
                          Workflow History <b-badge variant="primary">{{ (plan.changeLogs || []).length }}</b-badge>
                        </a>
                      </div>
                      <b-collapse id="collapsehistory" animation="slide">
                        <div class="card-body">
                          <change-log-history-table :change-logs="plan.changeLogs || []" :state-map="StateMap" />
                        </div>
                      </b-collapse>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:rejected="error" class="align-items-center justify-content-center">
            <div>
              <div v-if="error.message.includes('404')">
                <h4 class="text-center"> 
                  A plan does not yet exist for this district for school year {{ esaFiscalYear + 1990 }}.<br>
                  <span v-if="isDistrictUser && !toggleLockedStatus">Would you like to create one?</span>
                </h4>
                <div class="d-flex justify-content-center" v-if="isDistrictUser&& !toggleLockedStatus ">
                  <b-button size="lg" class="float-center" variant="success" title="Create" @click="create">
                    Create New Plan
                  </b-button>
                </div>
              </div>
              <card-state-icon v-else icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
            </div>
          </template>
        </Promised>
      </div>
    </div>
  </div>
</template>

<script>

import { Types } from '@/modules/esa/stores/'
import { mapState } from 'vuex'
import CardStateIcon from '@/components/CardStateIcon.vue'
import ChangeLogHistoryTable from '@/components/workflow/ChangeLogHistoryTable.vue'
import PlanCategory from './PlanCategory.vue'
import PlanHeader from './PlanHeader.vue'
import PlanFiles from './PlanFiles.vue'
import { Promised } from 'vue-promised'
import sumBy from 'lodash.sumby'
import groupBy from 'lodash.groupby'
import { PlanMixin } from './PlanMixin'

export default {
  data() {
    return {
      loadData: null,
      unauthorized: false,
    }
  },
  computed: {
    ...mapState(Types.path, [
      Types.state.esaFiscalYear, 
      Types.state.planCategories, 
      Types.state.planResponses, 
      Types.state.planExpenses,
      Types.state.plan,
    ]),
    files() {
      const response = JSON.parse(this.plan.response || "{ \"files\": [] }")
      return response.files
    }
  },
  mixins: [PlanMixin],
  components: {
    PlanHeader,
    PlanCategory,
    Promised,
    CardStateIcon,
    ChangeLogHistoryTable,
    PlanFiles,
  },
  methods: {
    fiscalYears(snapshots) {
      return Object.keys(groupBy(snapshots, snapshot => snapshot.fiscalYear))
    },
    formatSchoolYear(year) {
      return `${parseInt(year) + 1990}`
    },
    formatSnapshotYear(snapshot) {
      if (snapshot.month == 'Oct')
        return `${snapshot.fiscalYear + 1989} ${snapshot.month}`

      return `${snapshot.fiscalYear + 1990} ${snapshot.month}`
    },
    getData() {
      if (this.selectedLea && this.esaFiscalYear) {
        var self = this
        this.loadData = Promise.all([
          this.$store.dispatch(Types.actions.getLockedStatus, { lea: self.selectedLea.number }),
          this.$store.dispatch(Types.actions.getDataLockedStatus, { lea: self.selectedLea.number }),
          self.$store.dispatch(Types.actions.getPlan, { fy: this.esaFiscalYear, lea: self.selectedLea.number }),
          self.$store.dispatch(Types.actions.getPlanExpenses, { fy: this.esaFiscalYear, lea: self.selectedLea.number }),
          self.$store.dispatch(Types.actions.getPlanResponses, { fy: this.esaFiscalYear, lea: self.selectedLea.number })
            .then(() => self.$store.dispatch(Types.actions.getPlanCategories, { fy: this.esaFiscalYear }))
        ]).then(results => {
          self.unauthorized = (results || []).includes('Not authorized to view data for this LEA')
        })
      }
    },
    create() {
      var self = this
      this.$store.dispatch(Types.actions.createPlan, { fy: this.esaFiscalYear, lea: self.selectedLea.number })
        .then(() => {
          self.loadData = Promise.all([
            self.$store.dispatch(Types.actions.getPlanExpenses, { fy: this.esaFiscalYear, lea: self.selectedLea.number }),
            self.$store.dispatch(Types.actions.getPlanResponses, { fy: this.esaFiscalYear, lea: self.selectedLea.number })
              .then(() => self.$store.dispatch(Types.actions.getPlanCategories, { fy: this.esaFiscalYear }))
          ])
        })
    },
    excelExportData() {
      return {
        name: 'Plan Summary',
        items: this.dataItems()
      }
    },
    dataItems() {
      var retVal = []
      this.planCategories.forEach(category => {
        retVal.push(...category.types.map(type => {
          return {
            'Category Name': category.name,
            'Category Description': category.description,
            'Expense Type': type.description,
            ...this.getPlanExpenses(type),
            ...this.getPlanResponses(category, type)
          }
        }))
      })
      return retVal
    },
    getPlanExpenses(type) {
      let exp = this.planExpenses.filter(e => (type.codes || []).includes(e.program))
      if (exp && exp.length > 0)
        return { 'Budgeted': sumBy(exp, 'budgeted'), 'Actual': sumBy(exp, 'actual') }
      else return { 'Budgeted': 0, 'Actual': 0 }

    },
    getPlanResponses(category, type) {
      var response = JSON.parse((this.planResponses.find(r => r.categoryId == category.id && r.expenditureTypeId == type.key) || { response: "{ \"items\": [] }" }).response)
      return { 'Selected reasons': response.items.join('; '), 'Other reason': response.other }
    }
  },
  watch: {
    esaFiscalYear: {
      immediate: true,
      handler() {
        this.getData()       
      }      
    },
    selectedLea: {
      immediate: true,
      handler() {
        this.getData()
      }
    }
  },
}

</script>

<style>
@media print {
  .no-print {
    display: none !important;
  }
}

.special-title {
  font-weight: 300
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
    display: none;
}
</style>
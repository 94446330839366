<template>
  <div>
    <div class="card mt-3 bg-light">
      <a class="anchor d-print-none" id="enrollment" />
      <div class="card-header">
        Enrollment
      </div>
      <div class="card-body">
        <div class="card-group">
          <enrollment-total-chart :load-data="loadData" :selected-lea="selectedLea" :enrollment="enrollment" chart-title="Total Enrollment" />
          <enrollment-by-grade-chart :load-data="loadData" :selected-lea="selectedLea" />
        </div>
        <div class="card-group">
          <enrollment-by-race-chart :load-data="loadData" :selected-lea="selectedLea" :enrollment="enrollment" chart-title="Enrollment by Race" />
          <enrollment-by-race-chart
            :load-data="loadData"
            :selected-lea="selectedLea"
            :enrollment="demographics.percentages"
            chart-title="Enrollment by Race Percentages"
            y-axis-label="Percent of Students"
            chart-mode="percent"
            tooltip-suffix="%"
          />
        </div>
        <div class="card-group page-break-before">
          <enrollment-by-gender-chart :load-data="loadData" :selected-lea="selectedLea" :enrollment="enrollment" chart-title="Enrollment by Gender" />
        </div>
        <div class="card-group page-break-before">
          <free-reduced-lunch-chart :load-data="loadFreeReducedData" :selected-lea="selectedLea" />
          <percent-free-reduced-lunch-chart :load-data="loadFreeReducedData" :selected-lea="selectedLea" />
        </div>
      </div>
    </div>
    <div class="card mt-3 bg-light">
      <a class="anchor d-print-none" id="demographics" />
      <div class="card-header">
        Demographics
      </div>
      <div class="card-body">
        <div class="card-group">
          <enrollment-by-sub-pop-chart
            :load-data="loadDemographicData"
            :selected-lea="selectedLea"
            :enrollment="demographics.counts"
            chart-title="Demographic Counts"
          />
          <enrollment-by-sub-pop-chart
            :load-data="loadDemographicData"
            :selected-lea="selectedLea"
            :enrollment="demographics.percentages"
            chart-title="Demographic Percentages"
            y-axis-label="Percent of Students"
            chart-mode="percent"
            tooltip-suffix="%"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EnrollmentTotalChart from './EnrollmentTotalChart.vue'
import EnrollmentByRaceChart from './EnrollmentByRaceChart.vue'
import EnrollmentByGradeChart from './EnrollmentByGradeChart.vue'
import EnrollmentByGenderChart from './EnrollmentByGenderChart.vue'
import EnrollmentBySubPopChart from './EnrollmentBySubPopChart.vue'
import FreeReducedLunchChart from './FreeReducedLunchChart.vue'
import PercentFreeReducedLunchChart from './PercentFreeReducedLunchChart.vue'

export default {
  data() {
    return {
      loadData: null,
      loadFreeReducedData: null,
      loadDemographicData: null
    }
  },
  props: {
    //selectedLea: Object
  },
  computed: {
    ...mapState('overviewModule', ['enrollment', 'demographics'])
  },
  components: {
    EnrollmentTotalChart,
    EnrollmentByRaceChart,
    EnrollmentByGradeChart,
    EnrollmentByGenderChart,
    EnrollmentBySubPopChart,
    FreeReducedLunchChart,
    PercentFreeReducedLunchChart
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        this.loadData = this.$store.dispatch('overviewModule/getEnrollment', {
          lea: newLea.number
        })
        this.loadFreeReducedData = this.$store.dispatch('overviewModule/getFreeReducedLunches', {
          lea: newLea.number
        })
        this.loadDemographicData = this.$store.dispatch('overviewModule/getDemographics', {
          lea: newLea.number
        })
      }
    }
  }
}
</script>

const ReadinessColors = ['#79a6d2', '#8cd98c', '#ffb366', '#ff5050']
const ReadinessLevels = ['Exceeds', 'Ready',  'Close', 'In Need Of Support']
const ReadinessLevelHash = {
  1: 'Exceeds',
  2: 'Ready',
  3: 'Close',
  4: 'In Need Of Support'
}
const ReadinessLevelByNameHash = {
  'Exceeds': 1,
  'Ready': 2,
  'Close': 3,
  'In Need Of Support': 4
}
const ReadinessColorsHash = {
  1: '#79a6d2', 
  2: '#8cd98c', 
  3: '#ffb366', 
  4: '#ff5050'
}

//const Subgroups = ['', 'All Students', 'African American','Hispanic/Latino','White','Econ. Disadv.','EL','SPED']

const ReadinessLevelFormatter = (level) => ReadinessLevels[level-1]

export {ReadinessLevels, ReadinessColors, ReadinessLevelHash, ReadinessColorsHash, ReadinessLevelByNameHash, ReadinessLevelFormatter}
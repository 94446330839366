const credits = {
  MSI: {
    text: 'Source: My School Info',
    href: 'https://myschoolinfo.arkansas.gov/',
    style: {
      cursor: 'pointer',
      color: '#999999',
      fontSize: '12px'
    }
  },
  MSICycle2Certified: {
    text: 'Source: My School Info | SIS Cycle 2 - Certified',
    href: 'https://myschoolinfo.arkansas.gov/',
    style: {
      cursor: 'pointer',
      color: '#999999',
      fontSize: '12px'
    }
  },
  MSISRC: {
    text: 'Source: My School Info | SRC',
    href: 'https://myschoolinfo.arkansas.gov/',
    style: {
      cursor: 'pointer',
      color: '#999999',
      fontSize: '12px'
    }
  },
  SIS: {
    text: 'Source: Statewide Information System',
    href: '',
    style: {
      cursor: 'pointer',
      color: '#999999',
      fontSize: '12px'
    }
  },
  Insights: {
    text: 'Source: LEA Insights',
    href: '',
    style: {
      cursor: 'pointer',
      color: '#999999',
      fontSize: '12px'
    }
  },
  AELS: {
    text: 'Source: AELS',
    href: '',
    style: {
      cursor: 'pointer',
      color: '#999999',
      fontSize: '12px'
    }
  },
  SISandAELS: {
    text: 'Source: AELS and SIS',
    href: '',
    style: {
      cursor: 'pointer',
      color: '#999999',
      fontSize: '12px'
    }
  },
  SFA: {
    text: 'Standards for Accreditation (SFA)',
    href: 'https://sfa.ade.arkansas.gov',
    style: {
      cursor: 'pointer',
      color: '#999999',
      fontSize: '12px'
    }
  },
  eSchoolPlus: {
    text: 'eSchool Plus',
    href: '',
    style: {
      cursor: 'pointer',
      color: '#999999',
      fontSize: '12px'
    }
  },
  eFinancePlus: {
    text: 'eFinance Plus',
    href: '',
    style: {
      cursor: 'pointer',
      color: '#999999',
      fontSize: '12px'
    }
  },
  SISFacilityBuildingTable: {
    text: 'Source: SIS LEA Profile | Facility Building Table',
    href: '',
    style: {
      cursor: 'pointer',
      color: '#555555',
      fontSize: '12px'
    }
  },
  InsightsNightlyDataPulls: {
    text: 'Insights Nightly Data Pulls',
    href: '',
    style: {
      cursor: 'pointer',
      color: '#999999',
      fontSize: '12px'
    }
  }
}

export { credits }

<template lang="html">
  <div>
    <loadable-chart-card :load-data="loadData" card-style="">
      <template v-slot:default="slotProps">
        <div class="chartElem" v-show="slotProps.chartMode">
          <highcharts class="chart" :options="chartOptions" />
        </div>
        <div v-show="!slotProps.chartMode" class="m-3">
          <chart-data-table :items="dataItems()" />
        </div>
      </template>
    </loadable-chart-card>
    <span v-if="selectedIndex" class="badge badge-dark" :style="{ 'background-color': elementColor }">{{ this.chartOptions.xAxis[0].categories[this.selectedIndex] }} </span>
    <div class="small">
      <table-control-widget :per-page="tableOptions.pageLength" 
                            @filter-changed="tableOptions.filter=$event" 
                            @page-length-changed="tableOptions.pageLength=$event" />

      <b-table class="xs" striped hover selectable select-mode="single" 
               :items="readinessFiltered" 
               :per-page="tableOptions.pageLength" 
               :current-page="tableOptions.currentPage"
               :filter="tableOptions.filter"
               :fields="tableFields">
        <template v-slot:cell(residual)="data">
          <span>{{ data.value }} <sign-indicator :value="data.value" /></span>
        </template>
      </b-table>

      <table-pagination-widget 
        :total-rows-count="readinessFiltered.length" 
        :page-length="tableOptions.pageLength" 
        @page-changed="tableOptions.currentPage=$event" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { ReadinessColors, ReadinessLevels } from './readiness'
import { TableMixin } from '../../../TableMixin'
import LoadableChartCard from '../../../LoadableChartCard.vue'
import ChartDataTable from '../../../ChartDataTable.vue'
import SignIndicator from '../../../SignIndicator'

export default {
  name: 'readiness-level-distribution-widget',
  mixins: [TableMixin],
  components: {
    LoadableChartCard,
    ChartDataTable,
    SignIndicator
  },
  data() {
    return {
      loadData: null,
      selectedIndex: undefined,
      tableOptions: {
        pageLength: 10
      },
      tableFields: [
        { key: 'studentUniqueIdentifier', label: 'UID', sortable: true },
        { key: 'firstName', sortable: true },
        { key: 'lastName', sortable: true },
        { key: 'gender', sortable: true },
        { key: 'race', label: 'Federal Race', sortable: true },
        { key: 'currentGrade', label: 'Grade', sortable: true },
        { key: 'residual', label: 'Growth Score', sortable: true },
        { key: 'scaledScore', label: 'Scale Score', sortable: true }
      ],
      chartOptions: {
        chart: {
          zoomType: 'xy',
          height: '355px'
        },
        title: {
          text: ''
        },
        xAxis: [
          {
            categories: [
              '(-2 to -1.75)',
              '(-1.75 to -1.5)',
              '(-1.5 to -1.25)',
              '(-1.25 to -1)',
              '(-1 to -.75)',
              '(-.75 to -.5)',
              '(-.5 to -.25)',
              '(-.25 to 0)',
              '(0 to .25)',
              '(.25 to .50)',
              '(.5 to .75)',
              '(.75 to 1)',
              '(1 to 1.25)',
              '(1.25 to 1.5)',
              '(1.5 to 1.75)',
              '(1.75 to 2)'
            ],
            plotLines: [
              {
                color: '#000000',
                width: 2,
                value: 7.5,
                zIndex: 5,
                label: {
                  text: '0',
                  rotation: 0,
                  x: -3,
                  y: 15,
                  verticalAlign: 'bottom'
                }
              }
            ]
          }
        ],
        yAxis: {
          title: {
            text: 'Mean Growth Score'
          },
          labels: {
            formatter(point) {
              return point.value + '%'
            }
          }
        },
        plotOptions: {
          column: {
            pointPadding: 0,
            borderWidth: 0,
            groupPadding: 0,
            shadow: true,
            events: {
              click: ({ point }) => {
                this.selectedIndex = point.index
              }
            }
          },
          series: {
            color: '#454545' //ReadinesssColors[this.readinessLevel]
          }
        },
        series: [
          {
            name: 'xxLEVEL', //ReadinessLevels[this.readinessLevel],
            borderColor: '#303030',
            type: 'column',
            data: [],
            tooltip: {
              pointFormat:
                '<span style="font-weight: bold; color: {series.color}">{series.name}</span>: <b>{point.y:.2f}%</b><br><span style="font-weight: bold;">Number of Students</span>: <b>{point.count}</b>'
            }
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters('academicsModule/growth', ['subjectName']),
    ...mapState('academicsModule/growth', ['readinessLevelDetails']),
    readinessFiltered(){
      return this.readinessLevelDetails.filter(d => !this.selectedIndex || (Math.floor(d.residual/ 0.25)+8) == this.selectedIndex)
    },
    elementColor(){
      return ReadinessColors[this.readinessLevel - 1]
    },
    distributionData() {
      const distribution = this.readinessLevelDetails.filter(item => item.residual).map(item => {
        return {
          residual: item.residual,
          index: Math.floor(item.residual / 0.25) + 8
        }
      })

      const grouped = distribution.reduce((reduced, item) => {
        if (item) {
          reduced[item.index] = reduced[item.index]
            ? reduced[item.index] + 1
            : 1
        }
        return reduced
      }, Object.create(null))

      grouped.total = distribution.length
      return grouped
    },
    chartData() {
      return {
        subject: this.subject,
        growthScores: this.scores.map(readiness => {
          return { y: readiness.avgGrowthScore }
        }),
        confidenceLimits: this.scores.map(readiness => {
          return [
            readiness.lowerConfidenceLimit,
            readiness.upperConfidenceLimit
          ]
        })
      }
    }
  },
  watch: {
    distributionData: {
      immediate: true,
      handler() {
        this.chartOptions.series[0].data = new Array()
        this.chartOptions.series[0].name = ReadinessLevels[this.readinessLevel - 1]
        this.chartOptions.series[0].color = ReadinessColors[this.readinessLevel - 1]

        for (var x = 0; x <= 14; x++) {
          this.chartOptions.series[0].data.push({ y: this.distributionData[x]/this.distributionData.total * 100 || 0, count: this.distributionData[x] })
        }
      }
    }
  },
  methods: {
    dataItems() {
      return this.chartOptions.series[0].data.map((point, index) => {
        return {
          Subject: this.subject,
          ReadinessLevel: this.chartOptions.series[0].name,
          Range: this.chartOptions.xAxis[0].categories[index],
          Growth: parseFloat(point.y).toFixed(2)+"%"
        }
      })
    }
  },
  created() {
    this.loadData = this.$store.dispatch(
      'academicsModule/growth/getReadinessDetails',
      {
        lea: this.selectedLea.number,
        readinessLevel: ReadinessLevels.length - this.readinessLevel + 1,
        subject: this.subject
      }
    )
  },
  props: {
    readinessLevel: Number,
    subject: String
  }
}
</script>
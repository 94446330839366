<template>
  <div>
    <h2>Programs of Study</h2>
    <paginated-table :items="listItems" :fields="fields" small>
      <template v-slot:cell(activeForAY)="data">
        <pretty-checkbox :value="data.value" false-class="fas fa-times-circle text-danger" />
      </template>
      <template v-slot:head(action)>
        <b-button variant="primary" @click="onInitiateAdd">
          <i class="fa fa-plus fa-fw" /> Add Item
        </b-button>
      </template>
      <template v-slot:cell(action)="data">
        <b-link :to="{ path: '#'}" title="Edit Item" @click="onInitiateEdit(data.item)">
          <i class="fas fa-fw fa-edit" />
        </b-link>
        <a href="#" title="Activate Item" @click.prevent="onReactivateItem(data.item.id, 'programsOfStudy')" v-if="data.item.endAY">
          <i class="fas fa-fw fa-plus text-warning" />
        </a>
        <a v-else href="#" title="Deactivate Item" @click.prevent="onDeactivateItem(data.item.id, 'programsOfStudy')">
          <i class="fas fa-fw fa-times text-danger" />
        </a>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import PaginatedTable from '@/components/PaginatedTable'
import { clustersListMixin } from '../clustersListMixin'
export default {
  data() {
    return {
      fields: [
        {key: "id" },
        {key: "name", sortable: true },
        {key: "nonTraditionalGender", class: "text-center", sortable: true },
        {key: "startAY", class: "text-center" },
        {key: "endAY", class: "text-center" },
        {key: "activeForAY", sortable: true, class: "text-center" },
        {key: "action", class: "text-right" },
      ]
    }
  },
  components: {
    PaginatedTable
  },
  mixins: [clustersListMixin]
}
</script>
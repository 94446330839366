<template>
  <div class="card card-body">
    <paginated-table :items="items" :fields="operativeFields">
      <template v-slot:head(action)>
        <b-button
          v-if="canAddInspection"
          class="text-nowrap"
          variant="primary"
          title="New Inspection"
          to="/facilities/batch/create">
          <i class="fa fa-fw fa-plus-circle" /> Add
        </b-button>
      </template>
      <template v-slot:cell(districtName)="data">
        {{ leaDictionary[data.item.districtLea].name }}
      </template>
      <template v-slot:cell(buildingCount)="data">
        {{ data.item.inspections.length }}
      </template>

      <template v-slot:head(districtAcknowledgedBy)>
        District<br>Acknowledged<br>By
      </template>
      <template v-slot:head(districtAcknowledgedDate)>
        District<br>Acknowledged<br>Date
      </template>
      <template v-slot:cell(districtAcknowledgedDate)="data">
        <custom-date :date="data.value" />
      </template>
      <template v-slot:head(districtNotifiedBy)>
        District<br>Notified<br>By
      </template>
      <template v-slot:head(districtNotifiedDate)>
        District<br>Notified<br>Date
      </template>
      <template v-slot:cell(districtNotifiedDate)="data">
        <custom-date :date="data.value" />
      </template>

      <template v-slot:cell(createDate)="data">
        <custom-date :date="data.value" />
      </template>

      <template v-slot:cell(status)="data">
        <inspection-batch-status-badge :status="data.value" />
        <inspection-batch-notification-icon :batch="data.item" />
      </template>
      <template v-slot:cell(progress)="data">
        {{ getProgress(data.item.inspections) }}
      </template>
      <template v-slot:cell(action)="data">
        <b-form-group>
          <b-link :to="{name: 'Facilities/Batch/View', params:{ batchId: data.item.batchId}}">
            <i class="far fa-fw fa-folder-open" />
          </b-link>
          <b-link
            v-if="canAddInspection && data.item.status == 1"
            :to="{name: 'Facilities/Batch/Edit', params:{ batchId: data.item.batchId}, query: {process: 'start'}}" inline>
            <i class="fas fa-fw fa-play" />
          </b-link>
          <b-link
            v-if="canAddInspection && data.item.status != 1"
            :to="{name: 'Facilities/Batch/Edit', params:{ batchId: data.item.batchId}, query: {process: 'edit'}}">
            <i class="fas fa-fw fa-edit" />
          </b-link>
          <b-link
            v-if="canAddInspection && data.item.status <= 10" @click="remove(data.item.batchId)">
            <i class="fas fa-fw fa-trash text-danger" />
          </b-link>
        </b-form-group>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PaginatedTable from '../../../PaginatedTable'
import InspectionBatchStatusBadge from '../../common/InspectionBatchStatusBadge'
import InspectionBatchNotificationIcon from '../../common/InspectionBatchNotificationIcon'
import CustomDate from '../../../CustomDate'
import { Types } from '../../../../stores/dpsaft/facilities'

export default {
  data() {
    return {
      fields: [
        { key: 'districtLea', sortable: true },
        { key: 'districtName', sortable: true },
        { key: 'description', sortable: true },
        { key: 'status', sortable: true },
        { key: 'progress', sortable: true, thClass: 'text-center', tdClass: 'text-center' },
        { key: 'districtNotifiedBy', sortable: true },
        {
          key: 'districtNotifiedDate',
          sortable: true,
        },
        { key: 'districtAcknowledgedBy', sortable: true },
        {
          key: 'districtAcknowledgedDate',
          sortable: true,
        },
        { key: 'createdBy', sortable: true },
        {
          key: 'createDate',
          sortable: true,
        },
        {
          key: 'action',
          sortable: false,
          class: 'text-right',
          thClass: 'text-right'
        }
      ]
    }
  },
  methods: {
    onBeginAdd() {},
    getProgress(inspections) {
      const completedOrSkipped = inspections.filter(i => i.status >= 10).length
      return `${completedOrSkipped}/${inspections.length}`
    },
    remove(batchId){
      if(confirm("Are you sure you want to delete this inspection batch?")){
        this.$store.dispatch(Types.actions.deleteBatch, { batchId: batchId })
      }
    }
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    hideColumns: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    },
    canAddInspection() {
      return this.userContext.hasAnyRole([
        'Insights_DPSAFT_Administrators',
        'Insights_DPSAFT_Facilities_Administrators',
        'Insights_DPSAFT_Facilities_Area_Managers_Maintenance',
        'Insights_DPSAFT_Facilities_Area_Managers_Planning_Construction'
      ])
    },
    operativeFields() {
      return this.fields.filter(f => !this.hideColumns.includes(f.key))
    },
  },
  components: {
    PaginatedTable,
    InspectionBatchStatusBadge,
    InspectionBatchNotificationIcon,
    CustomDate
  }
}
</script>

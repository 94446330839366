import Vue from 'vue'
import LicenseExceptionsWidget from './LicenseExceptionsWidget.vue'
import { LicenseExceptionsIndividualsWidget } from './LicenseExceptionsIndividualsWidget'
import { PageMixin } from '../../PageMixin'

const LicenseExceptionsPage = Vue.extend({
    name: 'license-exceptions-page',
    mixins: [PageMixin],
    data() {
        return {
        }
    },
    components: {
        LicenseExceptionsWidget,
        LicenseExceptionsIndividualsWidget
    }
})

export { LicenseExceptionsPage }
import Vue from 'vue'
import { mapState } from 'vuex'
import { ReportGroupSelector } from './ReportGroupSelector'
import { AreaGroupSelector } from '../shortageReport/AreaGroupSelector'
import FiscalYearSelector from '../../FiscalYearSelector.vue'
import { ReportGroupsTable } from './ReportGroupsTable'
import { ReportCodeGroupAssignmentTable } from './ReportCodeGroupAssignmentTable'
import { AreaGroupEditor } from './AreaGroupEditor'
import { JobGroupEditor } from './JobGroupEditor'

const ShortageAreaAdministration = Vue.extend({
    name: 'shortage-area-administration',
    template: '#shortage-area-administration',
    data() {
        return {
            selectedYear: 0,
            tableFields: ['groupName'],
            selectedJobGroup: null,
            selectedAreaGroup: null,
            lastGroupUpdateDate: Date(),

            groupModal: {
                groupId: 0,
                groupName: null,
                parentGroupId: null,
                groupType: null
            }
        }
    },

    methods: {
        loadPageData(fiscalYear, parentGroupId) {
            this.$store.dispatch('shortageReportAdminModule/getAreaGroups', {
                fiscalYear: this.selectedYear
            })

            this.$store.dispatch('shortageReportAdminModule/getJobGroups', {
                fiscalYear: this.selectedYear,
                parentGroupId: parentGroupId
            })

            this.$store.dispatch('shortageReportAdminModule/getLicenseAreas')
        },
        groupChanged: function (group) {
            this.$store.dispatch("shortageReportAdminModule/commitReportGroupChanges", {
                item: group
            })
        },
        jobGroupItemSelected(group) {
            console.log('job group selected: ', group)
            this.selectedJobGroup = group
        },
        areaGroupItemSelected(group) {
            console.log('area group selected: ', group)
            this.selectedAreaGroup = group
        },

        onAddAreaGroup() {
            this.groupModal = {
                id: 0,
                groupName: null,
                parentGroupId: null,
                codeString: null,
                groupType: 'parent'
            };
            this.$bvModal.show('area-group-editor-modal');
        },

        onEditAreaGroup() {
            var codes = this.selectedAreaGroup.licenseCodes.reduce((result, code) => {
                return result + code + ", ";
            }, "");

            this.groupModal = {
                id: this.selectedAreaGroup.id,
                groupName: this.selectedAreaGroup.groupName,
                parentGroupId: this.selectedAreaGroup.parentGroupId,
                codeString: codes,
                groupType: this.selectedAreaGroup.parentGroupId > 0 ? 'child' : 'parent',
                licenseCodes: []
            };
            this.$bvModal.show('area-group-editor-modal');
        },

        onDeleteAreaGroup() {
            this.selectedAreaGroup = null
        },

        onAddJobGroup() {
            this.groupModal = {
                id: 0,
                groupName: null,
                parentGroupId: null,
                codeString: null,
                groupType: 'parent'
            };
            this.$bvModal.show('job-group-editor-modal');
        },

        onAddedJobGroup() {

        },

        onEditJobGroup() {
            var codes = this.selectedJobGroup.jobCodes.reduce((result, code) => {
                return result + code + ", ";
            }, "");

            this.groupModal = {
                id: this.selectedJobGroup.id,
                groupName: this.selectedJobGroup.groupName,
                parentGroupId: this.selectedJobGroup.parentGroupId,
                codeString: codes,
                groupType: this.selectedJobGroup.parentGroupId > 0 ? 'child' : 'parent'
            };
            this.$bvModal.show('job-group-editor-modal');
        },

        onDeleteJobGroup() {
            this.selectedJobGroup = null
        },

        onUpdatedAreaGroup() {
            this.lastGroupUpdateDate = Date();
        },

        onUpdatedJobGroup() {
            this.loadJobCodes()
        },

        loadJobCodes() {
            const payload = {
                reportGroupId: this.selectedJobGroup ? this.selectedJobGroup.id : 0
            };

            this.$store.dispatch('shortageReportAdminModule/getJobGroupJobCodes', payload);
        },
        rollJobGroups() {
            console.log('roll jobs')
            this.$store.dispatch('shortageReportAdminModule/rollGroups', {type: 'job', sourceFY: this.selectedYear, destFY: this.maxJobGroupFY + 1 })
        },
        rollAreaGroups() {
            console.log('roll areas')
            this.$store.dispatch('shortageReportAdminModule/rollGroups', {type: 'area', sourceFY: this.selectedYear, destFY: this.maxAreaGroupFY + 1 })
        }
        

    },
    computed: {
        ...mapState('shortageReportAdminModule', ['fiscalYears', 'maxAreaGroupFY', 'maxJobGroupFY']),
        maxYear() { 
            return this.fiscalYears ? Math.max(...this.fiscalYears) : 0
        },
        hasReportGroups() {
            return this.$store.state.shortageReportAdminModule.reportGroups.length > 0
        },
        reportGroups() {
            return this.$store.state.shortageReportAdminModule.reportGroups;
        },
        areaGroupLicenses() {

            var codes = [];
            console.log('refreshing license codes')
            const areaDictionary = this.$store.state.shortageReportAdminModule.licenseAreas;

            if (this.selectedAreaGroup) {
                var stateItem = this.$store.state.shortageReportAdminModule.reportAreaGroups.find(g=>g.id == this.selectedAreaGroup.id);

                if (stateItem) {

                    codes = stateItem.licenseCodes.map((i) => {
                        let area = areaDictionary[i]
    
                        return {
                            licenseCode: i,
                            description: area ? area.name : '(area not found)',
                            gradeRange: area ? area.gradeRange : '(area not found)'
                        }
                    })
    
                }
           }

            return codes;
        }
    },
    components: {
        ReportGroupSelector,
        AreaGroupSelector,
        FiscalYearSelector,
        ReportGroupsTable,
        ReportCodeGroupAssignmentTable,
        AreaGroupEditor,
        JobGroupEditor
    },
    watch: {
        selectedYear: {
            immediate: true,
            handler(newYear, oldYear) {
                console.log(`year changed from ${oldYear} to ${newYear}`)
                this.loadPageData(newYear, null)
            }
        },
        selectedJobGroup() {
            this.loadJobCodes()
        },
        selectedAreaGroup() {

            // if (this.s)
            // this.codeModal.codeString = this.selectedAreaGroup.licenseCodes.reduce((result, code) => {
            //     return result + code + ", ";
            // }, "");
        }
    },
    created() {
        this.$store.dispatch('shortageReportAdminModule/getFiscalYears')
    }
})

export {
    ShortageAreaAdministration
}
import { LeaTypes } from '@/helpers/leaTypes'
import { MealStatuses } from '../enrollment/MealStatus'
import sumBy from 'lodash.sumby'
import groupBy from 'lodash.groupby'
import sortBy from 'lodash.sortby'

function defaultEnrollmentTransform(item) {
  return item.group == this.value.toString() ? item.studentCount : 0 
}

export function mealStatusSheets() {
  var rows = this.getEnrollmentCountRows(this.enrollmentCounts.byMealStatus, MealStatuses, defaultEnrollmentTransform)
  var totalColumns = {}
  Object.keys(rows[0]).forEach(key => {
    if (key != 'lea')
    totalColumns[key] = sumBy(rows, key)
  })

  switch (this.selectedLea.type)
  {
    case LeaTypes.State: 
      var items = sortBy(rows, 'lea').map(k => {
        var lea = k.lea
        delete k.lea
        var district = this.leaDictionary[lea]?.parentLea
        var coop = district ? this.leaDictionary[district.number]?.parentLea : {}
        return {
          'CoOp LEA': coop?.number || '',
          'CoOp Name': coop?.name || '',
          'District LEA': district?.number || '',
          'District Name': district?.name || '',
          'School LEA': lea,
          'School Name': this.leaName(lea),
          ...k
        }
      })
      var totals = {
        'CoOp LEA': '',
        'CoOp Name': '',
        'District LEA': '',
        'District Name': '',
        'School LEA': '',
        'School Name': 'State of Arkansas TOTAL',
        ...totalColumns
      }
      var districts = groupBy(items, (i) => { return i['District LEA'] })
      var itemsDistrict = Object.keys(districts).sort((a,b) => a-b).map(districtLEA => {
        var districtRows = districts[districtLEA]
        var district = this.leaDictionary[districtLEA]
        var coop = district ? district.parentLea : {}
        return {
          'CoOp LEA': coop?.number || '',
          'CoOp Name': coop?.name || 'Unknown CoOp',
          'District LEA': district?.number || '',
          'District Name': district?.name || 'Unknown District',
          ...this.districtTotalColumns(districtRows)
        }
      })
      var totalsDistrict = {
        'CoOp LEA': '',
        'CoOp Name': '',
        'District LEA': '',
        'District Name': 'State of Arkansas TOTAL',
        ...totalColumns
      }
      var coops = groupBy(items, (i) => { return i['CoOp LEA'] })
      var itemsCoop = Object.keys(coops).sort((a,b) => a-b).map(coopLEA => {
         var coopRows = coops[coopLEA]
         var coop = this.leaDictionary[coopLEA]
         return {
          'CoOp LEA': coop?.number || '',
          'CoOp Name': coop?.name || 'Unknown CoOp',
          ...this.districtTotalColumns(coopRows)
        }
      })
      var totalsCoop = {
        'CoOp LEA': '',
        'CoOp Name': 'State of Arkansas TOTAL',
        ...totalColumns
      }
      return [
        { name: 'Meal Status School', items, totals },
        { name: 'Meal Status District', items: itemsDistrict, totals: totalsDistrict },
        { name: 'Meal Status CoOp', items: itemsCoop, totals: totalsCoop },
      ]
    case LeaTypes.Coop:
      items = sortBy(rows, 'lea').map(k => {
        var lea = k.lea
        delete k.lea
        var district = this.leaDictionary[lea]?.parentLea
        var coop = district ? this.leaDictionary[district.number]?.parentLea : {}
        return {
          'CoOp LEA': coop?.number || '',
          'CoOp Name': coop?.name || '',
          'District LEA': district?.number || '',
          'District Name': district?.name || '',
          'School LEA': lea,
          'School Name': this.leaName(lea),
          ...k
        }
      })
      totals = {
        'CoOp LEA': '',
        'CoOp Name': '',
        'District LEA': '',
        'District Name': '',
        'School LEA': '',
        'School Name': `${this.selectedLea.name} TOTAL`,
        ...totalColumns
      }
      districts = groupBy(items, (i) => { return i['District LEA'] })
      itemsDistrict = Object.keys(districts).sort((a,b) => a-b).map(districtLEA => {
        var districtRows = districts[districtLEA]
        var district = this.leaDictionary[districtLEA]
        var coop = district ? district.parentLea : {}
        return {
          'CoOp LEA': coop?.number || '',
          'CoOp Name': coop?.name || 'Unknown CoOp',
          'District LEA': district?.number || '',
          'District Name': district?.name || 'Unknown District',
          ...this.districtTotalColumns(districtRows)
        }
      })
      totalsDistrict = {
        'CoOp LEA': '',
        'CoOp Name': '',
        'District LEA': '',
        'District Name': `${this.selectedLea.name} TOTAL`,
        ...totalColumns
      }
      return [
        { name: 'Meal Status School', items, totals },
        { name: 'Meal Status District', items: itemsDistrict, totals: totalsDistrict },
      ]
    case LeaTypes.District:
      items = sortBy(rows, 'lea').map(k => {
        var lea = k.lea
        delete k.lea
        return {
          'School LEA': lea,
          'School Name': this.leaName(lea),
          ...k
        }
      })
      totals = {
        'School LEA': '',
        'School Name': `${this.selectedLea.name} TOTAL`,
        ...totalColumns
      }
      return [{ name: 'Meal Status School', items, totals }]
    case LeaTypes.School:
      items = sortBy(rows, 'lea').map(k => {
        var lea = k.lea
        delete k.lea
        return {
          'School LEA': lea,
          'School Name': this.leaName(lea),
          ...k
        }
      })
      return [{ name: 'Meal Status School', items, totals }]
  }
  
  return []
}
<template>
  <div>
    <h2>New IHE User</h2>
    <div class="row pl-2">
      <div class="col-md-12">
        <b-form-row>
          <b-form-group label-class="font-weight-bold" label="First Name">
            <b-form-input v-model="user.firstName" required />
          </b-form-group>
          <b-form-group label-class="font-weight-bold" class="pl-1" label="Last Name">
            <b-form-input v-model="user.lastName" required />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group label-class="font-weight-bold" label="Email">
            <b-form-input v-model="user.email" required type="email" />
          </b-form-group>
          <span class="mt-5 p-1 text-danger" v-if="isEmailAlreadyInUse"><i class="fas fa-exclamation-circle" /> Email address already in use</span>
        </b-form-row>
        <b-form-row>
          <b-form-group label-class="font-weight-bold" label="Phone">
            <b-form-input v-model="user.phoneNumber" required />
          </b-form-group>
        </b-form-row>
        <b-form-row>
          <b-form-group label-class="font-weight-bold" label="Role" class="col-md-6">
            <b-form-select :options="[{ text: 'Counselor', value: 'Insights_CareerCoach_Counsellors' }, { text: 'Supervisor', value: 'Insights_CareerCoach_Supervisors' }]" v-model="user.requestRole" />
          </b-form-group>
        </b-form-row>
        <div class="mt-3">
          <div class="mt-1">
            <assigned-highschools v-model="editClaims['default']" :default-value="[]" />
          </div>
        </div>
        <animated-button :promise-resolver="inviteUser.bind(null, 'default')" :button-class="buttonClass" :disabled="!isValid">
          Send User Invitation
        </animated-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Types } from '@/modules/cte/stores/careercoach'
import AnimatedButton from '@/components/AnimatedButton.vue'
import AssignedHighschools from './AssignedHighschools.vue'
import ApiService from '@/services/ApiService'
const apiService = new ApiService()

export default {
  data() {
    return {
      user: { 
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        requestRole: 'Insights_CareerCoach_Counsellors',
      },
      editClaims: {},
      claims: [],
      buttonClass: 'm-1 btn btn-primary',
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.users]),
    isValid() {
      return this.user.firstName.length > 0 && 
        this.user.lastName.length > 0 &&
        this.user.email.length > 0 &&
        this.user.phoneNumber.length > 0 &&
        this.user.requestRole.length > 0 &&
        !this.isEmailAlreadyInUse
    },
    isEmailAlreadyInUse() {
      const email = (this.user.email || '').toLowerCase()
      if (email.length == 0) 
        return false

      return (this.users || []).some(u => u.emailAddress.toLowerCase() == email)
    }
  },
  methods: {
    inviteUser(role) {
      const self = this
      const newUser = { ...this.user, requestedLEAsServed: JSON.stringify(this.editClaims[role].map(r => r.lea)) }
      return apiService.Post(`/api/cte/career-coach/admin/users/create`, newUser).then(() => {
        self.buttonClass = 'm-1 btn btn-sm btn-success'
        self.$bvModal.msgBoxOk('User Invitation Sent', {
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            centered: true
          })
          .then(() => {
            self.$router.push({ name: 'CTE/CareerCoach/Admin/Users' })
          })
      })
    },
  },
  components: {
    AnimatedButton,
    AssignedHighschools,
  },
}
</script>
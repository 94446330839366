<template>
  <div class="bg-light p-2">
    <div class="row">
      <div class="col-12">
        <div class="chartElem">
          <highcharts class="chart" :options="chartOptions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ChartMixin } from '../ChartMixin'
import Highcharts from 'highcharts'

export default {
  props: {
    inputData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'pie',
          plotBackgroundColor: '#00000000',
          plotShadow: false,
        },
        title: {
          text: 'Student Internet Speeds'
        },          
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              formatter: function() {
                if (this.y > 0)
                {
                  return this.point.name + ': ' + Highcharts.numberFormat(this.point.percentage, 1) + ' %'
                }
              }
            },
            showInLegend: true
          }
        }
      },
      chartMode: true
    }
  },
  mixins: [ChartMixin],
  watch: {
    inputData: {
      immediate: true,
      handler(newData) {
        this.chartOptions.series = [{
          data: [
            { name: 'No Access', y: newData.broadbandNoAccess },
            { name: '2-10 Mbps', y: newData.broadband_2_10 },
            { name: '11-25 Mbps', y: newData.broadband_11_25 },
            { name: '26-50 Mbps', y: newData.broadband_26_50 },
            { name: '50+ Mbps', y: newData.broadband_50plus }
          ],
          name: 'Student Internet Speeds',
          colorByPoint: true
        }]
      }
    }
  }
}
</script>

<style>

</style>
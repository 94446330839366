<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { EnrollmentGrades } from './EnrollmentGrades'
import { credits } from '../../../helpers/credits'

export default {
  name: 'enrollment-by-grade-chart',
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'line'
        },
        credits: credits.MSI,
        title: {
          text: 'Enrollment by Grade'
        },
        series: EnrollmentGrades,
        xAxis: {
          title: {
            text: 'School Year'
          },
          type: 'category'
        },
        yAxis: {
          title: {
            text: 'Number of Students'
          },
          endOnTick: false
        }
      }
    }
  },
  mixins: [ChartMixin],
  computed: {
    enrollment() {
      return this.$store.state.overviewModule.enrollment
    }
  },
  watch: {
    enrollment(newData) {
      for (var i = 0; i < EnrollmentGrades.length; i++) {
        this.chartOptions.series[i].data = newData.map(row => {
          return {
            x: (row.fiscalYear % 1990) + 1990,
            y: row[EnrollmentGrades[i].field],
            ...row
          }
        })
      }
    }
  },
  methods: {
    excelExportData() {
      return this.defaultExcelExportData()
    },
    dataItems() {
      return EnrollmentGrades.flatMap((grade, index) =>
        this.chartOptions.series[index].data.map(item => {
          return { Grade: grade.name, SchoolYear: item.x, NumberOfStudents: item.y }
        })
      )
    }
  }
}
</script>

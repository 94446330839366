<template>
  <div>
    <div>
      <paginated-table :items="bulkVisitAttendees" :fields="fields" :load-data="loadData">        
        <template #head(action)>
          <b-button variant="primary" text="Add Students to Visit" right @click="onAddToBulk" v-if="!userContext.isCTEReadOnly">
            <i class="fa fa-plus-circle fa-fw" />
            Add Students to Visit
          </b-button>
        </template>
        <template #cell(action)="data">
          <b-btn size="sm" variant="danger" title="Remove Student from Bulk Visit" class="mr-2" @click="removeVisit(data)" v-if="!userContext.isCTEReadOnly">
            <i class="far fa-times-circle fa-fw" />
          </b-btn>  
        </template>
        <template #cell(studentName)="data">
          <div class="text-nowrap">
            <router-link :to="{name: 'CTE/CareerCoach/StudentPage', params: { studentId: data.item.studentId }}">
              {{ data.value }}
            </router-link>
          </div>
        </template>
        <template #cell(studentId)="data">
          <router-link :to="{name: 'CTE/CareerCoach/StudentPage', params: { studentId: data.value, lea: data.item.lea } }">
            <b-badge variant="secondary">
              {{ data.value }}
            </b-badge>
          </router-link>
        </template>
        <template v-slot:cell(ell)="data">
          <pretty-checkbox :value="data.item.ell == 'Y'" />
        </template>            
        <template v-slot:cell(sped)="data">
          <pretty-checkbox :value="data.item.sped == 'Y'" />
        </template>
        <template #cell(schoolLEA)="data">
          <div>
            <div class="d-inline">
              <button
                type="button"
                class="btn btn-link"
                @click="goToLea(data.value)"
                :id="data.index + '-' + data.value">
                {{ data.value }}
              </button>
            &nbsp;
              <lea-tooltip
                :target-selector="data.index + '-' + data.value"
                :lea-number="data.value"
                :label="data.label" />
            </div>
          </div>
        </template>
      </paginated-table>
    </div>
    <b-btn @click="$emit('close')">
      Return to Visit List
    </b-btn>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Types } from '@/modules/cte/stores/careercoach'
import PaginatedTable from '@/components/PaginatedTable.vue'
import { formatDate } from '@/helpers/formatters'
import { MealStatuses } from '../../enums'
import PrettyCheckbox from '@/components/PrettyCheckbox.vue'
import LeaTooltip from '@/components/LeaTooltip.vue'

export default {
  data() {
    return {
      fields: [
        { key: 'schoolLEA', sortable: true },
        { key: 'studentId', sortable: true },
        { key: 'studentName', sortable: true, sortByFormatted: true, formatter: (v,k,item) => `${item.lastName}, ${item.firstName}` },
        { key: 'grade', 
        sortable: true, 
        formatter: (g)=> {
          return g==255?"G" : g}
        },
        {
          key: 'birthDate',
          label: 'DOB',
          sortable: true,
          formatter: val => {
            return formatDate(val, 'short-date')
          }
        },
        { key: 'age', sortable: true },
        { key: 'gender', sortable: true },
        { key: 'gpa', sortable: true },
        { key: 'meal', sortable: true, formatter: val => MealStatuses[val]?.text  },
        { key: 'ell', label: 'ELL', sortable: true },
        { key: 'gpa', sortable: true },
        { key: 'sped', label: 'SPED', sortable: true },
        { key: 'action', label: '', class: 'text-right' }
      ]
    }
  },
  computed:{
    ...mapState(Types.path, [Types.state.bulkVisitAttendees, Types.state.bulkVisits]),
    ...mapState('globalModule', ['userContext']),
  },
  components:{
    PaginatedTable,
    PrettyCheckbox,
    LeaTooltip
  },
  props:{
    loadData: Promise,
    visitId: Number
  },
  methods: {    
    async removeVisit(dataItem) {
      let result = await this.$bvModal.msgBoxConfirm("Delete this visit?")
      if (result) {
        this.$store.dispatch(Types.actions.removeStudentFromBulkVisit, { lea: dataItem.item.schoolLEA, visitId: this.visitId, student: dataItem.item })
        this.$store.commit(Types.mutations.removeStudentFromAttendees, {studentId: dataItem.item.studentId})
      }
    },
    onAddToBulk(){
      this.$store.commit(Types.mutations.setCurrentBulkVisit, this.bulkVisits.filter(v => v.visitId == this.visitId)[0])
      this.$router.push({name: 'CTE/CareerCoach/StudentRoster'})
    }
  }
}
</script>
import Vue from 'vue'
import AtrsFeedActions from './AtrsFeedActions.vue'

const HcptAdminPage = Vue.extend({
    name: 'hcpt-admin-page',
    data() {
        return {
            loadData: null
        }
    },
    components: {
        AtrsFeedActions
    }
})

export { HcptAdminPage }
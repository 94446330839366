<template> 
  <Promised :promise="loadData">
    <template v-slot:pending>
      <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
        <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
      </div>
    </template>
    <template :class="centerContent ? 'align-items-center justify-content-center' : ''">
      <table-control-widget
        v-if="!hideControls"
        :per-page="tableOptions.perPage"
        @filter-changed="onFilterChanged"
        @page-length-changed="tableOptions.perPage = $event"
        :placeholder-text="filterPlaceholderText"
        :allow-length-change="!hidePaging"
        :show-filter="showFilter" />
      <div>
        <slot name="info-header" />
      </div>
      <b-table
        v-bind="$attrs"
        :items="items"
        :fields="fields"
        :per-page="tableOptions.perPage"
        :current-page="tableOptions.currentPage"
        :busy="tableOptions.isLoading"
        :filter="localFilter"
        :filter-setter="filterSetter"
        @filtered="onFiltered"
        @row-selected="onSelected"
        @sort-changed="onSortChanged"
        :filter-function="filterFunction"
        :responsive="responsive"
        :sort-by="sortBy"
        :sort-desc="!sortAsc"
        ref="internalTable"
        :primary-key="primaryKey">
        <template v-for="column in fields" v-slot:[`head(${column.key})`]="columnData">
          <slot :name="`head(${column.key})`" v-bind="columnData">
            {{ columnData.label }}
          </slot>
        </template>
        <template v-for="column in fields.filter(f => typeof f['formatter'] != 'function')" v-slot:[`cell(${column.key})`]="columnData">
          <slot :name="`cell(${column.key})`" v-bind="columnData">
            <div :key="column.key">
              <span>
                {{ columnData.value }}
              </span>
            </div>
          </slot>
        </template>
        <template v-slot:empty>
          <slot name="empty">
            <h4>No records found</h4>
          </slot>
        </template>
        <template v-for="slotName in Object.keys($scopedSlots)" v-slot:[slotName]="slotScope">
          <slot :name="slotName" v-bind="slotScope" />
        </template>
      </b-table>
      <table-pagination-widget
        v-if="!hideControls"
        :total-rows-count="totalRowCount"
        :filtered-rows-count="tableOptions.filteredRowCount"
        :page-length="tableOptions.perPage"
        @page-changed="tableOptions.currentPage = $event" />
    </template>        
    <template v-slot:rejected="error" class="align-items-center justify-content-center">
      <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
    </template>
  </Promised>
</template>

<script>
import TableControlWidget from './shared/TableControlWidget.vue'
import TablePaginationWidget from './shared/TablePaginationWidget.vue'
import { Promised } from 'vue-promised'
import CardStateIcon from './CardStateIcon'

export default {
  data() {
    return {
      tableOptions: {
        isLoading: false,
        currentPage: 1,
        perPage: 100,
        filteredRowCount: null
      },
      localFilter: null
    }
  },
  components: {
    TableControlWidget,
    TablePaginationWidget,
    Promised,
    CardStateIcon
  },
  props: {
    loadData: {
      type: Promise,
      default() { return Promise.resolve() }
    },
    items: {
      type: Array,
      required: true
    },
    fields: {
      type: Array,
      default: ()=>[]
    },
    perPage: {
      type: Number,
      default: 100
    },
    filter: {
      type: [String, Object]
    },
    filterFunction: {
      type: Function,
      default: null
    },
    filterPlaceholderText: {
      type: String,
      default: 'Type to filter records...'
    },
    filterSetter: {
      type: Function,
      default: null
    },
    responsive: {
      type: [String, Boolean],
      default: false
    },
    sortBy: {
      type: String,
      default: ''
    },
    sortAsc: {
      type: Boolean,
      default: false
    },
    hideControls: {
      type: Boolean,
      default: false
    },
    hidePaging: {
      type: Boolean,
      default: false
    },
    showFilter: {
      type: Boolean,
      default: true
    },
    primaryKey: {
      type: String,
      default: null
    },
  },
  computed: {
    totalRowCount() {
      return this.items.length
    }
  },
  methods: {
    onFiltered(items, count) {
      this.tableOptions.filteredRowCount = count
    },
    onSelected(rows) {
      this.$emit('pt:row-selected', rows)
    },
    onFilterChanged(value) {
      if (this.filterSetter) {
        this.filterSetter(value)
      } 
      else if (typeof this.localFilter != 'object') {
        this.localFilter = value
      }
    },
    onSortChanged(value) {
      this.$emit('sort-changed', value)
    },
    refresh() {
      this.$refs.internalTable.refresh()
    }
  },
  watch: {
    filter(newFilterValue) {
      this.localFilter = newFilterValue
    }
  },
  created() {
    this.tableOptions.perPage = this.$printMode ? Number.MAX_SAFE_INTEGER : this.perPage,
    this.localFilter = this.filter 
  }
}
</script>

<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { credits } from '../../../helpers/credits'

var chartCredits = Object.assign({}, credits.MSICycle2Certified)
chartCredits.text = chartCredits.text + ' (Note: **2021: October 1, 2020 Snapshot was used)'

export default {
  name: 'percent-free-reduced-lunch-chart',
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'column'
        },
        credits: chartCredits,
        title: {
          text: 'Percent Free or Reduced Lunch Students'
        },
        legend: {
          enabled: false
        },
        series: [
          {
            name: 'Percent Free or Reduced Lunch Students',
            data: ''
          }
        ],
        xAxis: {
          title: {
            text: 'School Year'
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Percent of Students'
          },
          labels: {
            formatter: function() {
              return this.value + '%'
            }
          }
        },
        tooltip: {
          valueDecimals: 2,
          valueSuffix: '%'
        },
        exporting: {
          showTable: true,
          enabled: true
        }
      }
    }
  },
  mixins: [ChartMixin],
  computed: {
    freeReducedLunches() {
      return this.$store.state.overviewModule.freeReducedLunches
    }
  },
  watch: {
    freeReducedLunches(newData) {
      this.chartOptions.series[0].data = newData.map(row => {
        return {
          x: (row.fiscalYear % 1990) + 1990,
          y: row.percentFreeReduced * 100
        }
      })
    },
    selectedLea() {
      this.chartOptions.series[0].name = this.selectedLea.name
    }
  },
  methods: {
    excelExportData() {
      return this.defaultExcelExportData()
    },
    dataItems() {
      return this.chartOptions.series[0].data.map(item => {
        return { SchoolYear: item.x, PercentFreeReduced: item.y }
      })
    }
  }
}
</script>

<template>
  <form :id="formId">
    <div>
      <reconfiguration-code-selector
        :item="item"
        :purpose="ReconfigurationCodePurpose.EditSchool"
        :operation="operation"
      />
      <div class="form-group row mb-0">
        <b-toast
          :id="errorsToastId"
          title="LEA Change Issues"
          solid
          header-class="bg-primary text-white"
          no-auto-hide
        >
          <b-list-group>
            <b-list-group-item
              v-for="error in errors.flatMap(e => e)"
              :key="JSON.stringify(error)"
            >
              {{ error.error }}
            </b-list-group-item>
          </b-list-group>
        </b-toast>
        <div class="col-md-3 mb-3 mt-1">
          Select LEA to Change:
        </div>
        <b-form-select
          v-model="item.selectedLea"
          :options="schoolOptions"
          text-field="label"
          value-field="code"
          class="col-md-9 mb-2"
          @input="selectedLeaChanged"
        />
      </div>
      <div v-if="item.selectedLea">
        <div class="form-group row mb-0" v-if="item.selectedLea && item.currentValues">
          <div class="col-md-3 mb-3 mt-1">
            Current Values:
          </div>
          <div class="col-md-9 mb-3 mt-1">
            <ul>
              <li>Name: {{ item.currentValues.leaName }}</li>
              <li>Grade Span: {{ item.currentValues.gradeLow }} - {{ item.currentValues.gradeHigh }}</li>
              <li>Address: {{ `${item.currentValues.address}, ${item.currentValues.city}, ${item.currentValues.state},${item.currentValues.zip}${item.currentValues.zip4 ? '-'+item.currentValues.zip4: ''}` }}</li>
            </ul>
          </div>
        </div>

        <div class="form-group row mb-0 mt-3 mb-1">
          <div class="col-md-12">
            <h5 class="font-weight-bold">
              Changes Requested
            </h5>
          </div>
        </div>

        <div class="form-group row mb-0">
          <label class="col-md-3 mb-3 mt-1">Name:</label>
          <b-form-input
            required
            placeholder="Enter Requested LEA Name"
            class="col-md-9 mb-2"
            v-model="item.leaName"
          />
        </div>

        <div class="form-group row mb-0">
          <label class="col-md-3 mb-3 mt-1">Mailing Address:</label>
          <b-form-input
            required
            placeholder="Enter Requested LEA Mailing Address"
            class="col-md-9 mb-2"
            v-model="item.address"
          />
        </div>

        <div class="form-group row mb-0">
          <label class="col-md-3 mb-3 mt-1">City:</label>
          <b-form-input required placeholder="City" class="col-md-9 mb-2" v-model="item.city" />
        </div>

        <div class="form-group row mb-0">
          <label class="col-md-3 mb-3 mt-1">State:</label>
          <b-form-input required placeholder="State" class="col-md-9 mb-2" v-model="item.state" />
        </div>

        <div class="form-group row mb-0">
          <label class="col-md-3 mb-3 mt-1">Zip:</label>
          <b-form-input required placeholder="Zip" class="col-md-9 mb-2" v-model="item.zip" />
        </div>

        <div class="form-group row mb-0">
          <label class="col-md-3 mb-3 mt-1">Zip4:</label>
          <b-form-input placeholder="Zip4" class="col-md-9 mb-2" v-model="item.zip4" />
        </div>

        <div v-if="operation == RequestOperationPurpose.DistrictReconfiguration || operation == RequestOperationPurpose.BuildingAnnexation || true">
          <div class="form-group row mb-0">
            <div class="col-md-3 mb-3 mt-1">
              This is a Feeder School
            </div>
            <b-form-checkbox required class="col-md-1 mb-2" v-model="item.isFeederSchool" />
            <div class="col-8 p-0" v-if="item.isFeederSchool">
              <div class="row">
                <div class="col-3">
                  Paired School:
                </div>
                <div class="col-9">
                  <v-select required :options="schoolOptions" v-model="item.pairedSchool" :reduce="lea=>lea.code" />
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row mb-0">
            <div class="col-md-3 mb-3 mt-1">
              This is a Paired School
            </div>
            <b-form-checkbox required class="col-md-1 mb-2" v-model="item.isPairedSchool" />
            <div class="col-8 p-0" v-if="item.isPairedSchool">
              <div class="row">
                <div class="col-3">
                  Feeder School(s):
                </div>
                <div class="col-9">
                  <v-select required multiple :options="schoolOptions" v-model="item.feederSchools" :reduce="lea=>lea.code" />
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row mb-0">
            <label class="col-md-3 mb-3 mt-1">Grade Span:</label>
            <div class="col-md-9 mb-3 mt-1 p-0">
              <b-form-select
                required
                style="width: 150px;"
                :options="gradeSpans"
                v-model="item.gradeLow"
              />-
              <b-form-select
                required
                style="width: 150px;"
                :options="gradeSpans"
                v-model="item.gradeHigh"
              />
            </div>
          </div>

          <div class="form-group row mb-0">
            <label class="col-md-3 mb-3 mt-1">School Type:</label>
            <b-form-select
              required
              class="col-md-9 mb-3 mt-1"
              :options="buildingTypes"
              v-model="item.buildingType"
              @change="onSchoolTypeChanged"
            />
          </div>
        </div>

        <div class="form-group row mb-0">
          <label class="col-md-3 mb-3 mt-1">Additional Comments:</label>
          <textarea class="form-control col-md-9" v-model="item.additionalComments">
            Enter any additional instructions or comments here...
          </textarea>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex'
import { RequestItemMixin } from '../RequestItemMixIn'
import { ItemValidationMixin } from './ItemValidationMixin'
import { gradeStringToCode } from '../../../../../helpers/gradeSpans'
import { RequestOperationPurpose } from '../../../leaAssignment'

export default {
  name: 'change-school-lea-item',
  data() {
    return {
      RequestOperationPurpose,
      type: 'LEA Change Request',
      requiredFields: ['leaName', 'gradeLow', 'gradeHigh', 'buildingType']
    }
  },
  computed: {
    ...mapState('toolsModule/leaAssignment', ['schools', 'pairedLEARelationships']),
    schoolOptions() {
      return this.schools.map(s => ({
        code: s.leaNumber,
        label: `${s.leaNumber} - ${s.name}`
      }))
    },
    selectedLeaObject() {
      return this.schools.find(l => l.leaNumber == this.item.selectedLea) || {}
    }
  },
  mounted() {
    if (this.item.initialLea) {
      this.item.selectedLea = this.item.initialLea
      this.mapProperties()
    }
  },
  methods: {
    async selectedLeaChanged() {

      await this.$store.dispatch('toolsModule/leaAssignment/getPairedLEARelationships', { schoolLea: this.item.selectedLea })

      this.mapProperties()
    },
    mapProperties() {

      //get the feeder / paired relationships 
      var feederRecords =  this.pairedLEARelationships.filter(f=>f.feederSchoolLEA == this.item.selectedLea)
      var pairedRecords =  this.pairedLEARelationships.filter(f=>f.pairedSchoolLEA == this.item.selectedLea)

      this.item.currentValues = {
        leaName: this.selectedLeaObject.name,
        gradeLow: this.selectedLeaObject.gradeLow,
        gradeHigh: this.selectedLeaObject.gradeHigh,
        address: this.selectedLeaObject.address,
        city: this.selectedLeaObject.city,
        state: this.selectedLeaObject.state,
        zip: this.selectedLeaObject.zip,
        zip4: this.selectedLeaObject.zip4,
        isFeederSchool: feederRecords.length > 0,
        pairedSchool: feederRecords.length > 0 ? feederRecords[0].pairedSchoolLEA : null,
        isPairedSchool: pairedRecords.length > 0,
        feederSchools:  pairedRecords.length > 0 ? pairedRecords.map(p=>p.feederSchoolLEA) : []
      }

      this.item.currentLEAName = this.selectedLeaObject.name
      this.item.address = this.selectedLeaObject.address
      this.item.city = this.selectedLeaObject.city
      this.item.state = this.selectedLeaObject.state
      this.item.zip = this.selectedLeaObject.zip
      this.item.zip4 = this.selectedLeaObject.zip4
      this.item.leaName = this.selectedLeaObject.name
      this.item.gradeLow = gradeStringToCode(this.selectedLeaObject.gradeLow)
      this.item.gradeHigh = gradeStringToCode(this.selectedLeaObject.gradeHigh)

      this.item.isFeederSchool = feederRecords.length > 0
      if (this.item.isFeederSchool) this.item.pairedSchool = feederRecords[0].pairedSchoolLEA

      this.item.isPairedSchool = pairedRecords.length > 0
      if (this.item.isPairedSchool) this.item.feederSchools = pairedRecords.map(p=>p.feederSchoolLEA)
    },
    onSchoolTypeChanged(val) {
      if (this.operation == RequestOperationPurpose.DistrictReconfiguration)  //if this Change request is on a request for Distric Reconfiguration, we need to set the code based on the grade span of the school
      {
        this.$store.dispatch('toolsModule/leaAssignment/setRequestItemReconfigurationCode', { item: this.item, reconfigurationCode: val == 'ES' ? '2B' : val == 'MS' ? '2C' : val == 'HS' ? '2D' : null })
      } 
    }
  },
  mixins: [RequestItemMixin, ItemValidationMixin]
}
</script>

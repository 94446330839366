import Vue from 'vue'
import EmployeeChecksTable from './EmployeeChecksTable.vue'

const EmployabilityBackgroundChecksDetailsWidget = Vue.extend({
    name: 'employability-background-checks-details-widget',
    /* html */
    template: `
    <div class="card bg-light mt-3">
        <div class="card-header">Employees</div>
        <div class="card-body">
            <employee-checks-table v-bind:load-data="loadData" :current-range='currentRange' :current-color='currentColor'></employee-checks-table>
        </div>
    </div>
    `,
    props: {
        loadData: Promise,
        currentRange: String,
        currentColor: String,
    },
    components: {
        EmployeeChecksTable
    }
})

export { EmployabilityBackgroundChecksDetailsWidget }
<template>
  <span :title="title">{{ date | date('short-date') }}</span>
</template>
<script>
import moment from 'moment'
export default {
  data() {
    return {

    }
  },
  computed: {
    title() {
      return moment(this.date).format("MM/DD/YYYY h:mm a")
    }
  },
  props: {
    date: [String, Date]
  }
}
</script>

<template>
  <div>
    <form>
      <reconfiguration-code-selector
        v-if="item"
        :item="item"
        :purpose="ReconfigurationCodePurpose.EditSchool"
        :operation="operation"
      />

      <div class="form-group row mb-0">
        <label class="col-md-2 col-form-label">LEA:</label>
        <div class="col-md-10">
          <span class="form-control-plaintext">{{ item.selectedLea }} - {{ item.currentLEAName }}</span>
        </div>
      </div>
      <div class="form-group row mb-0">
        <label class="col-md-2 col-form-label">Additional Comments:</label>
        <div class="col-md-10">
          <div class="form-control-plaintext">
            {{ item.additionalComments }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              Current Values
            </div>
            <div class="card-body">
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">Name:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.currentValues.leaName }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">Grade Span:</label>
                <div class="col-md-9">
                  <span
                    class="form-control-plaintext"
                  >{{ gradeSpanString(item.currentValues.gradeLow, item.currentValues.gradeHigh) }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">Address:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.currentValues.address }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">City:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.currentValues.city }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">State:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.currentValues.state }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">Zip:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.currentValues.zip }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">Zip4:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.currentValues.zip4 }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">School Type:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ buildingTypeString(item.buildingType) }}</span>
                </div>
              </div>

              <paired-feeder-information-block :paired-source="pairedSchoolInfo.current" :feeder-source="feederSchoolInfo.current" />
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              Requested Values
            </div>
            <div class="card-body">
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">Name:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.leaName }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">Grade Span:</label>
                <div class="col-md-9">
                  <span
                    class="form-control-plaintext"
                  >{{ gradeSpanString(item.gradeLow, item.gradeHigh) }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">Address:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.address }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">City:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.city }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">State:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.state }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">Zip:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.zip }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">Zip4:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.zip4 }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">School Type:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ buildingTypeString(item.buildingType) }}</span>
                </div>
              </div>
              <paired-feeder-information-block :paired-source="pairedSchoolInfo.request" :feeder-source="feederSchoolInfo.request" />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { RequestItemMixin } from '../RequestItemMixIn'
import PairedFeederInformationBlock from './PairedFeederInformationBlock.vue'
export default {
  name: 'change-school-lea-item',
  data() {
    return {}
  },
  components: {
    PairedFeederInformationBlock
  },
  mounted() {},
  computed: {
    selectedLea() {
      return this.schools.find(l => l.leaNumber == this.item.selectedLea) || {}
    }
  },
  props: {
    item: Object
  },
  mixins: [RequestItemMixin]
}
</script>

<template>
  <div>
    <p class="font-weight-bold mt-3">
      {{ question.text }}
    </p>

    <div v-drag-and-drop:options="options" class="ranking-control">
      <vue-draggable-group
        v-for="group in groups"
        v-model="group.items"
        :groups="groups"
        :key="group.id"
        :data-id="group.id"
        @change="onGroupsChange"
      >
        <ul class="list-group list-group-flush">
          <li v-for="(el, index) in group.items" :key="el.id" :data-id="el.id" class="list-group-item bg-transparent borderless pl-1">
            <b-badge class="mr-2 selection-order">
              {{ index + 1 }}
            </b-badge><label v-text="el.name" />
          </li>
        </ul>
      </vue-draggable-group>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueDraggable from 'vue-draggable'
import { VueDraggableDirective } from 'vue-draggable'
import 'vue-draggable/polyfills'

Vue.use(VueDraggable)

export default {
  directives: {
    dragAndDrop: VueDraggableDirective
  },
  inject: ['$v'],
  data() {
    return {
      groups: [],
      options: {
        dropzoneSelector: 'ul',
        draggableSelector: 'li',
        handlerSelector: null,
        reactivityEnabled: true,
        multipleDropzonesItemsDraggingEnabled: false,
        showDropzoneAreas: true,
        onDrop: () => { },
        onDragstart: () => { },
        onDragenter: () => { },
        onDragover: () => { },
        onDragend: () => { }
      }
    }
  },
  name: 'question-ranking',
  computed: {
    value() {
      return this.$v.survey[this.question.id].$model
    }
  },
  methods: {
    getValue(c) {
      return this.value === c
    },
    onGroupsChange(groups) {
      groups[0].items = groups[0].items.map((i, idx) => {
        i.id = idx + 1
        return i
      })

      this.$v.survey[this.question.id].$model = this.groups = groups
    }
  },
  props: {
    question: {
      type: Object,
      default: () => {}
    },
    editMode: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
  },
  watch: {
    question: {
      immediate: true,
      handler(q) {
        this.groups = this.$v.survey[this.question.id].$model || [{ id: 1, items: q.choices.map((c, index) => { return { name: c, id: index + 1 }}) }]
      }
    }
  }
}
</script>

<style scoped>
@keyframes nodeInserted {
    from { opacity: 0.2; }
    to { opacity: 0.8; }
}

.ranking-control ::v-deep .item-dropzone-area {
    height: 2rem;
    background: #4785c8;
    opacity: 0.8;
    animation-duration: 0.5s;
    animation-name: nodeInserted;
    box-shadow:0 0 0 2px #68b, inset 0 0 0 1px #ddd;  
}

.ranking-control ::v-deep li:hover {
  box-shadow:0 0 0 2px #68b, inset 0 0 0 1px #ddd;
}

.ranking-control ::v-deep li:focus
{
  outline:none;
  box-shadow:0 0 0 2px #68b, inset 0 0 0 1px #ddd;
}

.ranking-control ::v-deep li {
  cursor: move;
}

.ranking-control ::v-deep li label {
  cursor: move;
}

.ranking-control ::v-deep .selection-order {
  background-color: #007bff
}
</style>
<template>
  <div>
    <b-card header="Academic Year Startup Task Completion Status" :title="taskInfo.name" :header-bg-variant="currentTaskCompletion.completed ? 'success' : 'warning'" :header-text-variant="currentTaskCompletion.completed ? 'white' : 'dark'"> 
      <b-form>
        <b-form-row class="mb-2">
          <b-col>
            <div v-if="currentTaskCompletion.completed">
              {{ taskInfo.name }} Completed on {{ currentTaskCompletion.completedDate | date }} by {{ currentTaskCompletion.completedBy }}
            </div>
            <slot name="stepCompletionInstructions" v-else>
              When you have completed this task, please mark it complete below.
            </slot>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col class="text-center">
            <b-button v-if="!currentTaskCompletion.completed" @click="doCompletion" variant="primary">
              Mark <em class="font-weight-bold">{{ taskInfo.name }}</em> Complete
            </b-button>
            <b-button v-else @click="doIncompletion" variant="warning">
              Mark <em class="font-weight-bold">{{ taskInfo.name }}</em> Incomplete
            </b-button>
          </b-col>
        </b-form-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Types } from '@/modules/cte/stores/perkinsv'
export default {
  data() {
    return {
    }
  },
  methods: {
    async doCompletion() {
      var result = await this.$bvModal.msgBoxConfirm("Are you sure you want to mark the task Complete?")
      if (result) {

        if (this.preCompletionRoutine) {
          await this.preCompletionRoutine()
        }
        
        await this.$store.dispatch(Types.actions.setTaskCompletion, {id: this.currentTaskCompletion.id, ay: this.currentTaskCompletion.ay, taskCode: this.currentTaskCompletion.taskCode, completed: true})
        this.$emit('onCompletionStatusChanged')
        this.$router.go(-1)
      }
    },
    async doIncompletion() {
      var result = await this.$bvModal.msgBoxConfirm("Are you sure you want to mark the task Incomplete?")
      if (result) {
        await this.$store.dispatch(Types.actions.setTaskCompletion, {id: this.currentTaskCompletion.id, ay: this.currentTaskCompletion.ay, taskCode: this.currentTaskCompletion.code, completed: false})
        this.$emit('onCompletionStatusChanged')
        this.$router.go(-1)
      }
    }
  },
  computed:{
    ...mapState(Types.path, [Types.state.currentTaskCompletion])
  },
  mounted() {
  },
  props: {
    taskInfo: {
      type: Object,
      required: true
    },
    preCompletionRoutine: {
      type: Function,
      default: ()=>console.log('No-Op Pre Completion Routine') 
    },
    onMarkComplete: {
      type: Function,
      default: ()=>alert('onMarkComplete')
    },
    onMarkIncomplete: {
      type: Function,
      default: ()=>alert('onMarkIncomplete')
    }
  }  
}
</script>
<template>
  <div>
    <b-card header="Inspection Batches pending District Acknowledgement" title="The batches below have been released to the districts but they have not yet acknowledged receipt.">
      <b-card-body>
        <paginated-table :items="PendingDistrictActionList" :fields="fields">
          <template v-slot:cell(completedDate)="data">
            {{ data.value | date }}
          </template>
          <template v-slot:cell(action)="data">
            <router-link :to="{ name: 'Facilities/Batch/View', params: {batchId: data.item.batchId }}">
              <i class="far fa-folder-open fa-fw" />
            </router-link>
          </template>
          <template v-slot:cell(districtNotifiedDate)="data">
            {{ data.value | date }}
          </template>
          <template v-slot:cell(facilitiesContact)="data">
            <a :href="`mailto:${data.item.facilitiesManagerEmail}`">{{ data.item.facilitiesManagerName }}</a>
            <br>
            {{ data.item.facilitiesManagerPhone }}
          </template>
        </paginated-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { Types } from '../../../stores/dpsaft/facilities'
import { mapState } from 'vuex'
import PaginatedTable from '../../../components/PaginatedTable'
export default {
  data() {
    return {
      fields: [
        { key: "districtLea", sortable: true },
        { key: "districtName", sortable: true },
        { key: "facilitiesContact" },
        { key: "completedDate", sortable: true },
        { key: "districtNotifiedDate", sortable: true },
        { key: "daysOld", sortable: true },
        { key: "action", class: "text-right" }
      ]
    }
  },
  mounted() {
    this.$store.dispatch(Types.actions.getPendingDistrictActionList)
  },
  computed: {
    ...mapState( Types.path, [Types.state.PendingDistrictActionList])
  },
  components: {
    PaginatedTable
  }
}
</script>
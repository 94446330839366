<template>
  <b-card header="Postsecondary Education">
    <!-- CTE Goal: Increase the percent of students who enroll in postsecondary education the fall semester immediately following graduation -->
    <!-- Not sure what to look for on this one. We will need to look at 12 grade data through August, and then swith to Graduate data after the academic year
    advances -->
    <highcharts class="chart" ref="chartRef" :callback="chartLoad" :options="chartOptions" />
  </b-card>
</template>

<script>
const loadingSpinner = '<i class="fas fa-spinner fa-spin"></i> Loading...'

import { Types } from '../../../../stores/careercoach/index.js'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loadingSpinner: loadingSpinner
    }
  },  
  computed: {
    ...mapState(Types.path, [Types.state.dashboard]),
    seriesData() {
      if (!(this.dashboard.graduates && this.dashboard.graduates.length)) return [0,0]

      return [ 
        {name: 'Attending', y: this.dashboard.graduates[0].attendingCollegeCount, total: this.dashboard.graduates[0].graduateCount}, 
        {name: 'Not Attending', y: this.dashboard.graduates[0].graduateCount - this.dashboard.graduates[0].attendingCollegeCount, total: this.dashboard.graduates[0].graduateCount}]
    },
    chartOptions() {
      return {
        chart: {
          type: 'pie',
          height: 430,
          spacing: [20, 20, 0, 20],
          marginBottom: 20,
          backgroundColor: '#fff',
          plotBackgroundColor: '#fff',
          inverted: true,
          plotShadow: false
        },
        loading: {
          style: {
            position: 'absolute',
            backgroundColor: '#ffffff',
            opacity: 0.8,
            textAlign: 'center'
          }
        },        
        title: {
          text: 'Prior Year Graduates Attending College'
        },
        exporting: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          min: 0,
          max: 6,
          title: {
            text: 'This is a placeholder'
          }
        },
        plotOptions: {
          pie: {
            colors: ['#91ed7e', '#cccccc']
          }
        },
        series: [
          {
            name: 'Graduates',
            data: this.seriesData,
            colorByPoint: true,
            dataLabels: {
              enabled: true,
              formatter() {
                var pct = Math.round(this.point.y*1000/this.point.total)/10
                if (pct >= 0 && pct <= 100) return `${this.point.name}: ${pct}% (${Math.round(this.point.y).toLocaleString()}/${this.point.total.toLocaleString()})`
                else return ''
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    dashboard: {
      immediate: true,
      handler() {
        this.chartLoad()  
      }
    }
  },
  methods: {
    chartLoad(chart) {
      if (chart) {
        if (this.dashboard.currentRoster.length == 0) {
          chart.showLoading(loadingSpinner)
        }
      }
      else if (this.$refs["chartRef"]) {
        if (this.dashboard.currentRoster.length > 0) {
          this.$refs["chartRef"].chart.hideLoading()
        }
        else
        {
          this.$refs["chartRef"].chart.showLoading(loadingSpinner)
        }
      }
    }
  }
}
</script>
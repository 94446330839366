<template>
  <div class="card mt-3">
    <span class="card-header lead">
      {{ editCategoryType.description }}
    </span>
    <div class="card-body bg-light">
      <b-form-checkbox v-model="editCategoryType.enabled" size="lg" switch @input="onInput"> 
        Enabled
      </b-form-checkbox>
      <b-form-group label="Codes">
        <b-form-tags v-model="editCategoryType.codes" separator=" ,;" placeholder="Enter new codes separated by a space, comma, or semicolon." @input="onInput" />              
      </b-form-group>
      <b-list-group flush class="col-md-8">
        <b-list-group-item v-for="(item, index) in editCategoryType.items" :key="item">
          <div class="d-flex w-100 justify-content-between">
            {{ item }}
            <b-button @click="removeItem(index)" size="xs" variant="danger" title="Remove Item">
              <span class="fas fa-times" />
            </b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
      <b-input-group v-if="editCategoryType.enabled">
        <b-form-input class="col-md-8" v-model="newItemText" placeholder="Enter Item Text" />
        <b-input-group-append>
          <b-button variant="success" size="md" inline @click="addItem">
            Add Item
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
  </div>
</template>

<script>
import { deepClone } from '@/helpers/globalHelpers'

export default {
  data(){
    return {
      newItemText: '',
      newCode: '',
      editCategoryType: deepClone({...{codes: []}, ...this.value})
    }
  },
  props:{
    value:{
      type: Object,
      default: () => {}
    }
  },
  methods:{
    addItem(){
      if(this.newItemText){
        if(!this.editCategoryType.items){
          this.$set(this.editCategoryType, 'items', [])
        }
        this.editCategoryType.items.push(this.newItemText)
        this.newItemText = null
        this.onInput()
      }
    },
    removeItem(index){
      this.editCategoryType.items.splice(index,1)
      this.onInput()
    },
    addCode(){
      if(this.newCode) {
        if(!this.editCategoryType.codes) {
          this.$set(this.editCategoryType, 'codes', [])
        }
        this.editCategoryType.codes.push(this.newCode)
        this.newCode = null
        this.onInput()
      }
    },
    removeCode(index){
      this.editCategoryType.codes.splice(index,1)
      this.onInput()
    },
    onInput(){
      this.$emit('input', this.editCategoryType)
    }
  }
}
</script>
const subjects = [
  { text: '(All Subjects)', value: null },
  { text: 'ELA', value: 'ela' },
  { text: 'Mathematics', value: 'math' },
  { text: 'Science', value: 'science' },
  { text: 'ELP', value: 'elp' }
]

const demographicGroups = [
  {
    text: '1 All',
    value: 1,
    gridText: 'All'
  },
  {
    text: '2 African American',
    subpopulationIndex: 2,
    value: 2,
    gridText: 'African American'
  },
  {
    text: '3 Hispanic/Latino',
    subpopulationIndex: 4,
    value: 3,
    gridText: 'Hispanic/Latino'
  },
  {
    text: '4 White',
    subpopulationIndex: 6,
    value: 4,
    gridText: 'White'
  },
  {
    text: '5 Econ. Disadv.',
    value: 5,
    gridText: 'Econ. Disadv.'
  },
  {
    text: '6 English Learner (EL)',
    value: 6,
    gridText: 'English Learner (EL)'
  },
  {
    text: '7 Special Education (SPED)',
    value: 7,
    gridText: 'Special Education (SPED)'
  }
]
export {
    demographicGroups as DemographicGroups, subjects as Subjects
}
import ApiService from '../services/ApiService'

export class HCPTAdminModule {

    constructor() {
        this._apiService = new ApiService()
        this._configElement = document.getElementById('application-config') 
        this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
        this._fiscalYear = this._configElement.getAttribute('data-fiscal-year')
    }

    namespaced = true

    state = {
        latestATRSFeedFile: '',
        atrsFileResponse: ''
    }

    getters = {
    }

    mutations = {
        setLatestFile: (state, latestATRSFeedFile) => state.latestATRSFeedFile = latestATRSFeedFile,
        setAtrsFileResponse: (state, atrsFileResponse) => state.atrsFileResponse = atrsFileResponse
    }

    actions = {
        getLatestFile: ({ commit }) => {
            return this._apiService.Get(`${this._baseUrl}api/atrs/latestfile`)
                .then(latestATRSFeedFile => commit('setLatestFile', latestATRSFeedFile))  
        },
        getReturnFile: ({ commit }) => {
            return this._apiService.Get(`${this._baseUrl}api/atrs/file`)
                .then(atrsFileResponse => commit('setAtrsFileResponse', atrsFileResponse))  
        },
        checkReturnFile: ({ commit }) => {
            return this._apiService.Get(`${this._baseUrl}api/atrs/file/check`)
                .then(atrsFileResponse => commit('setAtrsFileResponse', atrsFileResponse))  
        },
        sendFile: ({ commit }) => {
            return this._apiService.Post(`${this._baseUrl}api/atrs/file`)
                .then(atrsFileResponse => commit('setAtrsFileResponse', atrsFileResponse))  
        }
    }
}

<template>
  <div>
    <h3 class="p-2 bg-dark mx-2 mt-1 rounded text-light font-weight-bold">
      Career Coach
    </h3>
    <hr class="my-1">
    <student-search @student-selected="openStudent" />
    <hr class="mt-1">
    <div class="sidebar-scroll">
      <ul class="nav nav-pills flex-column ml-2 mr-2">
        <li class="nav-item">
          <router-link :to="{name: 'CTE/CareerCoachTeacher/Home'}" class="nav-link" active-class="active">
            <i class="far fa-id-card fa-fw mr-1" />Student Roster
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{name: 'CTE/CareerCoachTeacher/Reports'}" class="nav-link" active-class="active">
            <i class="fas fa-chart-bar fa-fw mr-1" />Reports
          </router-link>
        </li>
      </ul>
    </div>
    <hr>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { CareerCoachRoles } from '@/modules/cte/components/common/Roles'
 
import StudentSearch from './widgets/StudentSearch.vue'
export default {
  components: { StudentSearch },
  computed: {
    ...mapState('globalModule', ['userContext']),
    isAdmin() {
      return (this.userContext.roles || []).includes(CareerCoachRoles['Admin'])||
        (this.userContext.roles || []).includes('Insights_System_Administrators')
    },
    academicYear() {
      return `${this.ay + 1999} / ${this.ay + 2000}`
    }
  },
  methods: {
    openStudent(studentSelection) {
      console.log(this.ay)
      if (studentSelection) {
        this.$router.push({name: 'CTE/CareerCoachTeacher/StudentPage', params: {studentId: studentSelection.code}, query: {lea: studentSelection.lea }})
      }
    },
  }
}
</script>

import ApiService from '../../services/ApiService'

export class SCTClosures {
  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl =
    this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
  }

  namespaced = true

  state = {
    districtClosures: new Array(),
    monthSummaries: [],
    weekSummaries: [],
    overallSummaries: [],
    closureStatuses: [],
    closureScopes: [],
    haveClosedSummary: {},
    currentClosure: null
  }

  mutations = {
    setDistrictClosures: (state, data) => {
      state.districtClosures = data.closures
      state.monthSummaries = data.monthSummary
      state.weekSummaries = data.weekSummary
      state.closureStatuses = data.statuses
      state.closureScopes = data.scopes
      state.haveClosedSummary = data.haveClosedSummary
      state.overallSummaries = data.overallSummary
    },
    setCurrentClosure: (state, data) => {
      state.currentClosure = data
    },
    deleteClosure: (state, id) => {
      state.districtClosures = state.districtClosures.filter(c => c.id != id)
    }
  }

  getters = {
    districtClosures: (state, getters, rootState, rootGetters) => state.districtClosures.map(c => {
      const lea = rootGetters['globalModule/leaDictionary'][c.districtLea] || {}
      return { ...c, superEmail: lea.adminEmailAddress, superName: `${lea.adminFirstName} ${lea.adminLastName}` }
    })
  }

  actions = {
    //eslint-disable-next-line
    // authenticate: ({ commit }, payload) => {
    //   return this._apiService
    //     .Post(`${this._baseUrl}api/closures/district/authenticate?districtLea=${payload.districtLea}&emailAddress=${payload.emailAddress}`)
    //     .then((result)=>result)
    // },

    getDistrictClosures: ({commit}, filter) => {
      return this._apiService
      .Post(`${this._baseUrl}api/core/sct/closures/${filter.lea}`, {startDate: filter.startDate, endDate: filter.endDate})
      .then(data=>commit('setDistrictClosures', data))
    },

    getDistrictClosuresPublic: ({commit}, filter) => {
      return this._apiService
      .Post(`${this._baseUrl}api/core/sct/closures/public`, {startDate: filter.startDate, endDate: filter.endDate})
      .then(data=>commit('setDistrictClosures', data))
    },

    getDistrictClosure: ({commit}, payload) => {
      return this._apiService
      .Get(`${this._baseUrl}api/core/sct/closures/${payload.id}`)
      .then(data=>commit('setCurrentClosure', data))
    },

    getDistrictClosurePublic: ({commit}, payload) => {
      return this._apiService
      .Get(`${this._baseUrl}api/core/sct/closures/public/${payload.id}`)
      .then(data=>commit('setCurrentClosure', data))
    },

    // eslint-disable-next-line no-unused-vars
    saveClosure: ({commit}, payload) => {
      if (!payload.id) {
        return this._apiService
        .Put(`${this._baseUrl}api/core/sct/closures/new/${payload.districtLea}`, payload)
        //.then((p)=>commit('addNewClosure', p))
      } else {
        return this._apiService
        .Patch(`${this._baseUrl}api/core/sct/closures/${payload.id}/update`, payload)
        //.then((p)=>commit('updateClosure', p))
      }
    },

    deleteClosure: ({commit}, id) => {
      return this._apiService
      .Delete(`${this._baseUrl}api/core/sct/closures/${id}`)
      .then(()=>commit('deleteClosure', id))
    },
  }
}
<template>
  <div class="mt-3 pb-0">
    <b-table striped hover small :items="aggregatedExpenses" :fields="fields" sort-by="Program" head-row-variant="secondary" class="small">
      <template #thead-top>
        <b-tr>
          <b-th variant="dark text-center" colspan="2" class="border-right">
            Expense Category
          </b-th>
          <b-th variant="dark text-center" colspan="8">
            Expenditure Type
          </b-th>
          <b-th variant="dark" colspan="5" />
        </b-tr>
        <b-tr>
          <b-th variant="secondary" colspan="2" class="border-right" />
          <b-th variant="secondary border-right text-center" colspan="2">
            Personnel 
            <b-tooltip target="#personnel">
              <span>
                Expenses for Object Codes <strong>({{ getCodes(1) }})</strong>
              </span>
            </b-tooltip>
            <span class="font-weight-bold"><i class="text-info fa fa-info-circle" id="personnel" /></span>
          </b-th>
          <b-th variant="secondary border-right text-center" colspan="2">
            Materials, Supplies, and Equipment
            <b-tooltip target="#materials">
              <span>
                Expenses for Object Codes <strong>({{ getCodes(3) }})</strong>
              </span>
            </b-tooltip>
            <span class="font-weight-bold"><i class="text-info fa fa-info-circle" id="materials" /></span>
          </b-th>
          <b-th variant="secondary border-right text-center" colspan="2">
            Services
            <b-tooltip target="#services">
              <span>
                Expenses for Object Codes <strong>({{ getCodes(4) }})</strong>
              </span>
            </b-tooltip>
            <span class="font-weight-bold"><i class="text-info fa fa-info-circle" id="services" /></span>
          </b-th>
          <b-th variant="secondary border-right text-center" colspan="2">
            Other
            <b-tooltip target="#others">
              <span>
                Expenses for Object Code <strong>69320</strong> on Transfers. All codes except 61000-62999, 66000-66999, 67340-67390, 63000-65999.
              </span>
            </b-tooltip>
            <span class="font-weight-bold"><i class="text-info fa fa-info-circle" id="others" /></span>
          </b-th>
          <b-th variant="secondary border-right text-center" colspan="5">
            Total
          </b-th>
        </b-tr>
      </template> 
      <template #cell(category)="data">
        <div class="d-flex">
          <b-tooltip :target="'#' + data.item.ProgramCode">
            <span>
              Program Code(s) <strong>{{ data.item.ProgramCode }}</strong>
            </span>
          </b-tooltip>
          <div v-if="selectedLea.type == LeaTypes.District" class="d-flex flex-nowrap budget-icon-container">
            <img v-if="disabledCategories.includes(data.item.CategoryId) && data.item.Budgeted > 0" :src="USDIcon" class="svg-icon" title="Category (program) is NOT enabled on plan but program code has budget amount(s)">
            <img v-if="!disabledCategories.includes(data.item.CategoryId) && data.item.Budgeted > 0" :src="InvoiceIcon" class="svg-icon" title="Category (program) is enabled on plan and program code has budget amount(s)">
            <img v-if="!disabledCategories.includes(data.item.CategoryId) && data.item.Budgeted <= 0" :src="PageIcon" class="svg-icon" title="Category (program) is enabled on plan but program code DOES NOT have budget amount(s)">
          </div>
          <div class="d-flex flex-nowrap px-2">
            {{ data.value }}
            <i class="text-info fa fa-info-circle" :id="data.item.ProgramCode" />
          </div>
        </div>
      </template>
      <template #cell(remaining)="data">
        <div>
          <span :class="{ 'text-danger': data.value < 0 }">{{ data.value | currency }}</span>
        </div>
      </template>
      <template v-slot:[`cell(${program}-Actual)`]="data" v-for="program in programs">
        <div :key="`${program}-Actual`">
          <span :class="{ 'text-danger': data.value > data.item[`${program}-Budgeted`] }">{{ data.value | currency }}</span>
        </div>
      </template>
      <template v-slot:[`cell(${program}-Budgeted)`]="data" v-for="program in programs">
        <div :key="`${program}-Budgeted`">
          <span>{{ data.value | currency }}</span>
        </div>
      </template>
      <template #cell(Actual)="data">
        <span :class="{ 'text-danger': data.value > data.item.Actual }">{{ data.value | currency }}</span>
      </template> 
      <template #cell(Budgeted)="data">
        <span>{{ data.value | currency }}</span>
      </template> 
      <template #cell(Progress)="data">
        <div v-if="data.item.Budgeted > 0">
          <b-progress min="0" :max="data.item.Budgeted" :variant="data.item.Remaining < 0 ? 'danger' : 'success'" style="height: 1.5rem;">
            <b-progress-bar :value="data.item.Actual" />
          </b-progress>
          <div class="progress-overlay">
            {{ ((data.item.Actual / data.item.Budgeted) * 100).toFixed(2) }}%
          </div>
        </div>
        <span v-else-if="data.item.Actual > data.item.Budgeted" class="font-italic">
          (Over budget by {{ Math.abs(data.item.Remaining) | currency }})
        </span>        
      </template>
      <template #cell(Allocated)="data">
        <div v-if="data.item.AllocatedPercent > 0">
          <b-progress min="0" :max="100" :variant="data.item.RemainingAllocated < 0 ? 'danger' : 'success'" style="height: 1.5rem;">
            <b-progress-bar :value="data.item.AllocatedPercent" />
          </b-progress>
          <div class="progress-overlay">
            {{ data.item.AllocatedPercent.toFixed(2) }}%
          </div>
        </div>
      </template>
      <template v-slot:custom-foot>
        <b-tr variant="secondary">
          <b-td class="font-weight-bold" colspan="2">
            TOTAL
          </b-td>
          <template v-for="program in programs">
            <!-- eslint-disable -->
            <b-td class="font-weight-bold text-right">
              {{ sumAggregatedExpenses(`${program}-Budgeted`) | currency }}
            </b-td>
            <b-td class="font-weight-bold text-right">
              {{ sumAggregatedExpenses(`${program}-Actual`) | currency }}
            </b-td>
          </template>
          <b-td class="font-weight-bold text-right">
            {{ sumAggregatedExpenses('Budgeted') | currency }}
          </b-td>
          <b-td class="font-weight-bold text-right">
            {{ sumAggregatedExpenses('Actual') | currency }}
          </b-td>
          <b-td class="font-weight-bold text-right">
            {{ totalRemaining | currency }}
          </b-td>
          <b-td>
            <b-progress v-if="totalBudgeted > 0" min="0" :max="totalBudgeted" :variant="totalRemaining < 0 ? 'danger' : 'success'" style="height: 1.5rem;">
              <b-progress-bar :value="totalActual" />
            </b-progress>
            <div class="progress-overlay">
              {{ ((totalActual / totalBudgeted) * 100).toFixed(2) }}%
            </div>
          </b-td>
          <b-td>
            <b-progress v-if="allocationsTotal > 0" min="0" :max="100" :variant="totalRemainingAllocated < 0 ? 'danger' : 'success'" style="height: 1.5rem;">
              <b-progress-bar :value="((totalBudgeted / allocationsTotal) * 100)" />
            </b-progress>
            <div class="progress-overlay">
              {{ ((totalBudgeted / allocationsTotal) * 100).toFixed(2) }}%
            </div>
          </b-td>
        </b-tr>
      </template>
    </b-table>
    <div class="p-3">
      <p class="lead font-weight-bold">Legend</p>      
      <div class="d-flex">
        <div class="d-flex flex-nowrap budget-icon-container"><img :src="USDIcon" class="svg-icon"></div>
        <div class="d-flex flex-nowrap px-2">Category (program) is NOT enabled on plan but program code has budget amount(s)</div>
      </div>
      <div class="d-flex">
        <div class="d-flex flex-nowrap budget-icon-container"><img :src="InvoiceIcon" class="svg-icon"></div>
        <div class="d-flex flex-nowrap px-2">Category (program) is enabled on plan and program code has budget amount(s)</div>
      </div>
      <div class="d-flex">
        <div class="d-flex flex-nowrap budget-icon-container"><img :src="PageIcon" class="svg-icon"></div>
        <div class="d-flex flex-nowrap px-2">Category (program) is enabled on plan but program code DOES NOT have budget amount(s)</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Types } from '@/modules/esa/stores/'
import { mapState } from 'vuex'
import sumBy from 'lodash.sumby'
import { expandCodes } from '../helpers.js'
import { LeaTypes } from '@/helpers/leaTypes'
import USDIcon from '../usd-icon.svg'
import PageIcon from '../page-icon.svg'
import InvoiceIcon from '../invoice-icon.svg'

export default {
  data() {
    return {
      LeaTypes,
      USDIcon,
      PageIcon,
      InvoiceIcon
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.planExpenses, Types.state.plan, Types.state.districtAllocations]),
    fields() {
      return [
        {
          key: 'Category',
          label: 'Name',
          sortable: true,
          //class: 'border-right'
        },
        {
          key: 'Description',
          label: 'Description',
          sortable: 'true',
          class: 'border-right'
        },
        {
          key: 'Personnel-Budgeted',
          label: 'Budgeted Amt.',
          sortable: true,
          tdClass: 'text-right',
          thClass: 'text-center',
        },
        {
          key: 'Personnel-Actual',
          label: 'Actual Amt.',
          sortable: true,
          class: 'border-right',
          tdClass: 'text-right',
          thClass: 'text-center',
        },
        {
          key: 'Materials, Supplies, and Equipment-Budgeted',
          label: 'Budgeted Amt.',
          sortable: true,
          tdClass: 'text-right',
          thClass: 'text-center',
        },
        {
          key: 'Materials, Supplies, and Equipment-Actual',
          label: 'Actual Amt.',
          sortable: true,
          class: 'border-right',
          tdClass: 'text-right',
          thClass: 'text-center',
        },
        {
          key: 'Purchased Services-Budgeted',
          label: 'Budgeted Amt.',
          sortable: true,
          tdClass: 'text-right',
          thClass: 'text-center',
        },
        {
          key: 'Purchased Services-Actual',
          label: 'Actual Amt.',
          sortable: true,
          class: 'border-right',
          tdClass: 'text-right',
          thClass: 'text-center',
        },
        {
          key: 'Other-Budgeted',
          label: 'Budgeted Amt.',
          sortable: true,
          tdClass: 'text-right',
          thClass: 'text-center',
        },
        {
          key: 'Other-Actual',
          label: 'Actual Amt.',
          sortable: true,
          class: 'border-right',
          tdClass: 'text-right',
          thClass: 'text-center',
        },
        {
          key: 'Budgeted',
          label: 'Budgeted Amt.',
          sortable: true,
          tdClass: 'text-right',
          thClass: 'text-center',
        },
        {
          key: 'Actual',
          label: 'Actual Amt.',
          sortable: true,
          class: 'border-right',
          tdClass: 'text-right',
          thClass: 'text-center',
        },
        {
          key: 'Remaining',
          label: 'Remaining',
          sortable: true,
          class: 'border-right',
          tdClass: 'text-right',
          thClass: 'text-center',
        },
        {
          key: 'Progress',
          label: 'Utilized',
          sortable: false,
          thStyle: 'width: 10%;'
        },
        {
          key: 'Allocated',
          label: 'Percent of Allocation Budget',
          sortable: false,
          thStyle: 'width: 10%;'
        },
      ]
    },
    filteredExpenses() {
      if (this.currentCategory) 
        return this.planExpenses.filter(expense => this.currentCategory.programCode.includes(expense.program))

      return this.planExpenses
    },
    programs() {
      return ['Personnel', 'Materials, Supplies, and Equipment', 'Purchased Services', 'Other']
    },
    aggregatedExpenses() {
      const allocationsTotal = this.allocationsTotal

      return this.categories.filter(c => {
          return this.currentCategory ? this.currentCategory.id == c.id : true
        }).map(category => {
          const categoryExpenses = this.planExpenses.filter(expense => category.programCode.includes(expense.program))
          const budgeted = sumBy(categoryExpenses, 'budgeted')
          const actual = sumBy(categoryExpenses, 'actual')

          return Object.assign({
            'Category': category.name,
            'CategoryId': category.id,
            'Description': category.description,
            'Budgeted': budgeted,
            'Actual': actual,
            'Remaining':  budgeted - actual,
            'ProgramCode': category.programCode,
            'RemainingAllocated': allocationsTotal - budgeted,
            'Allocated': allocationsTotal,
            'AllocatedPercent': allocationsTotal > 0 ? (budgeted / allocationsTotal) * 100 : 0,
          }, ...category.types.map(type => {
              const typeExpenses = categoryExpenses.filter(c => type.key == this.getType(c.program, c.account).key && category.programCode.includes(c.program))
              return {
                [`${type.description}-Budgeted`]: sumBy(typeExpenses, 'budgeted'),
                [`${type.description}-Actual`]: sumBy(typeExpenses, 'actual'),
              }
            })
          )
        })
    },
    currentCategory() {
      return this.categories.find(category => category.name == this.drilldown)
    },
    totalBudgeted() {
      return sumBy(this.aggregatedExpenses, 'Budgeted')
    },
    totalActual() {
      return sumBy(this.aggregatedExpenses, 'Actual')
    },
    totalRemaining() {
      return this.totalBudgeted - this.totalActual
    },
    disabledCategories() {
      if (!this.plan)
        return []
      const response = JSON.parse(this.plan.response || "{ \"disabledCategories\": [] }")
      return response.disabledCategories || []
    },
    allocations() {
      if (this.selectedLea.type == LeaTypes.Coop) {
        const districtLeas = this.leaDictionary[this.selectedLea.number].ownedLeas.flatMap(o => o.number)
        return this.districtAllocations.filter(d => districtLeas.includes(d.lea))
      } else if (this.selectedLea.type == LeaTypes.District) {
        return this.districtAllocations.filter(d => d.lea == this.selectedLea.number)
      }
      return this.districtAllocations
    },
    allocationsTotal() {
      return sumBy(this.allocations, 'total')
    },
    totalRemainingAllocated() {
      return this.allocationsTotal - this.totalBudgeted
    },
    budgetAllocationPercent() {
      return this.allocationsTotal > 0 ? this.totalBudgeted / this.allocationsTotal : 0.0
    },
  },
  methods: {
    getCodes(key) {
      const categoryCodes = this.categories.flatMap(c => c.types.filter(t => t.key == key).map(t => t.codes.toString()))
      return [...new Set(categoryCodes)].join(', ')
    },
    sumAggregatedExpenses(key) {
      return sumBy(this.aggregatedExpenses, key)
    },
    getType(program, account) {
      var category = this.categories.find(category => category.programCode.includes(program)) || {}
      var data = category.types?.find(type => expandCodes(type.codes).includes(account)) || {}
      if (!data.codes) {
        return { key: 5, description: 'Other', codes: [], items: [] }
        //debugger
      }
      //console.log({...data})
      return data
    },
    excelExportData() {
      const displayColumns = [...this.aggregatedExpenses].map(expense => {
        // const nonDisplayKeys = Object.keys(expense).filter(key => key.includes('Enable'))
        // nonDisplayKeys.forEach(key => {
        //   delete expense[key] 
        // })
        return expense
      })
      return {
        name: 'Matrix',
        items: displayColumns
      }
    }
  },
  props: {
    drilldown: {
      type: String
    },
    categories: {
      type: Array,
      required: true
    },
  }
}
</script>

<style>
.budget-icon-container {
  vertical-align: top;
  height: 100%;
  padding-top: .5em;
}
.progress-overlay {
  position: relative;
  top: -1.4rem;
  z-index: 98;
  text-align: center;
  color: #000000;
}
</style>
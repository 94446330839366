<template>
  <div>
    <div class="row">
      <div class="col">
        <h1>Roster Verification System</h1>
        <div>
          <h2><lea-display :lea="selectedLea" /></h2>
          <!-- <a @click="goToLea(selectedLea.parentLea.number)" class="cursor-pointer btn btn-sm btn-outline-warning"><i class="fa fa-fw fa-level-up-alt" /> {{ selectedLea.parentLea.name }}</a> -->
        </div>
      </div>
      <div class="col-3 text-right" v-if="districtDetail" style="max-height: 70px;">
        <span class="h5 font-weight-bold text-right">Verification Status <span class="badge badge-lg ml-1" :class="getStatus(districtDetail.status).class">{{ getStatus(districtDetail.status).description }}</span></span>
        <div style="padding-right: .3em;" class="mt-1">
          <div v-if="isSuperintendent && hasMissingData">
            <div class="alert alert-warning text-center">
              <i class="fa fa-exclamation-triangle" /> There are schools in this district that have not yet been marked as approved.
            </div>
          </div>
          <state-change-widget
            v-else-if="(schoolList || []).length > 0"
            :status="districtDetail.status"
            :available-states="districtStates"
            :state-map="DistrictStateMap"
            :transition-action="transitionAction"
            advance-button-class="text-left"
            :identifiers="{ lea: districtDetail.districtLea, isDistrict: true }"
            :can-withdraw="false"
            :document="districtDetail"
            style="z-index: 1000;"
          />
        </div>
      </div>
    </div>

    <b-row no-gutters class="mt-3" style="margin-bottom: -2rem;" v-if="schoolList && schoolList.length > 0">
      <b-col cols="4">
        <verification-progress-chart units="schools" title="School Verification Progress" :complete="completeSchools" :total="totalSchools" />
      </b-col>
      <b-col cols="4">
        <verification-progress-chart units="teachers" title="Teacher Verification Progress" :complete="completeTeachersBySchool" :total="totalTeachersBySchool" />
      </b-col>
      <b-col cols="4" class="text-center">
        <div class="highcharts-title" style="color:#333333;font-size:18px;fill:#333333;">
          Number of Contributors
        </div>
        <div class="highcharts-data-labels highcharts-series-0 highcharts-solidgauge-series highcharts-tracker" style="font-size:20px; font-family: 'Source Sans Pro'; color: rgb(0, 0, 0); font-weight: bold;">
          {{ totalContributors }}
        </div>
      </b-col>
    </b-row>
    <div v-else>
      <loading-spinner />
    </div>
    
    <keep-alive>
      <timeline />
    </keep-alive>
    
    <div class="card mt-3">
      <div class="card-header">
        Schools
      </div>
      <div class="card-body card-body-secondary">
        <school-list :hide-lea="true" :loading="loading" />
      </div>
    </div>
    
    <crosstabs />
    <div class="mt-3" v-if="districtDetail">
      <b-card-header>
        Status History
      </b-card-header>
      <b-card>
        <div class="card-body-secondary">
          <div class="p-0">
            <change-log-history-table :change-logs="districtDetail.changeLogs || []" :state-map="DistrictStateMap" />
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import LeaDisplay from '@/components/LeaDisplay.vue'
import VerificationProgressChart from './charts/VerificationProgressChart.vue'
import SchoolList from '../common/SchoolList.vue'
import Timeline from '@/modules/rvs/components/common/Timeline.vue'
import Crosstabs from '@/modules/rvs/components/common/Crosstabs.vue'

import StateChangeWidget from '@/components/workflow/StateChangeWidget.vue'
import { DistrictStateMap } from '@/modules/rvs/rvsStateMap.js'
import ChangeLogHistoryTable from '@/components/workflow/ChangeLogHistoryTable.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    LeaDisplay,
    VerificationProgressChart,
    SchoolList,
    Timeline,
    Crosstabs,
    StateChangeWidget,
    ChangeLogHistoryTable,
    LoadingSpinner
  },
  data() {
    return {
      DistrictStateMap,
      loading: null
    }
  },
  computed: {
    ...mapGetters('rvsModule', ['completeTeachersBySchool', 'totalTeachersBySchool', 'completeSchools', 'totalSchools']),
    ...mapGetters('globalModule', ['isSuperintendent']),
    ...mapState('rvsModule', ['districtDetail', 'districtStates', 'fiscalYear', 'schoolList']),
    transitionAction: () => 'rvsModule/transitionLea',
    hasMissingData() {
      return this.completeSchools < this.totalSchools
    },
    totalContributors() {
      return this.schoolList && this.schoolList.length > 0 ? this.schoolList.find(f => f.totalContributors !== undefined).totalContributors : 0
    }
  },
  methods: {
    getStatus(status) {
      switch(status) {
        case 0:
          return { class: 'badge-secondary', description: 'Draft' }
        case 100:
          return { class: 'badge-success', description: 'Finalized' }
        default:
          return { class: 'badge-warning', description: 'In Progress' }
      }
    },
    async reloadData() {
      if (this.fiscalYear)
        this.loading = Promise.all([
          this.$store.dispatch('rvsModule/getSchoolList', this.selectedLea.number),
          this.$store.dispatch('rvsModule/getDistrictDetail', this.selectedLea.number),
          this.$store.dispatch('rvsModule/getDistrictStates', this.selectedLea.number)
        ])
    },
  },
  watch: {
    fiscalYear: {
      handler() {
        this.reloadData()
      }
    },
    selectedLea: {
      immediate: true,
      handler() {
        this.reloadData()
      }
    }
  }
}
</script>
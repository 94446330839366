<template>
  <div>
    <div class="row">
      New LEA Detail
    </div>
    <div class="row">
      <div class="col-md-12">
        <Promised :promise="initialize">
          <template v-slot:pending>
            &nbsp;
            <i class="fas fa-spinner fa-spin" />
          </template>
          <template>
            <div>
              <b-form>
                <b-form-group label-cols="2" label="LEA">
                  <div class="input-group">
                    <input class="form-control" v-model.lazy="currentLEAMSRecord.leaNumber" type="number" :formatter="formatAsLEA" aria-describedby="basic-addon1">
                    <div class="input-group-append">
                      <span v-if="leaValidationErrors.length" class="input-group-text bg-danger" id="basic-addon1"><i class="fa fa-times-circle text-white" /></span>
                      <span v-else class="input-group-text bg-success" id="basic-addon1"><i class="fa fa-check-circle text-white" /></span>
                    </div>
                  </div>
                  <ul class="list-unstyled text-danger pl-1" v-if="leaValidationErrors.length > 0">
                    <li v-for="(e,i) in leaValidationErrors" :key="i">
                      <b>{{ e }}</b>
                    </li>
                  </ul>
                </b-form-group>
                <b-form-group label-cols="2" label="Name">
                  <b-form-input v-model="currentLEAMSRecord.name" />
                </b-form-group>
                <b-form-group label-cols="2" label="LEA Type Code">
                  <b-select
                    class="col-md-10"
                    :options="leaTypeOptions"
                    v-model="currentLEAMSRecord.leaTypeCode"
                  />
                </b-form-group>
                <b-form-group label-cols="2" label="Grade Low">
                  <b-form-select v-model="iGradeLow" :options="gradeSpanOptions" />
                </b-form-group>
                <b-form-group label-cols="2" label="Grade High">
                  <b-form-select v-model="iGradeHigh" :options="gradeSpanOptions" />
                </b-form-group>
                <b-form-group label-cols="2" label="Address">
                  <b-form-input v-model="currentLEAMSRecord.mailAddress" />
                </b-form-group>
                <b-form-group label-cols="2" label="City">
                  <b-form-input v-model="currentLEAMSRecord.mailCity" />
                </b-form-group>
                <b-form-group label-cols="2" label="State">
                  <b-form-input v-model="currentLEAMSRecord.mailState" />
                </b-form-group>
                <b-form-group label-cols="2" label="Zip">
                  <b-form-input v-model="currentLEAMSRecord.mailZip" type="number" />
                </b-form-group>
                <b-form-group label-cols="2" label="Zip-4">
                  <b-form-input v-model="currentLEAMSRecord.mailZip4" type="number" />
                </b-form-group>
                <b-form-group label-cols="2" label="Cspr">
                  <b-form-input v-model="currentLEAMSRecord.cspr" />
                </b-form-group>
                <b-form-group label-cols="2" label="Is Provision 2">
                  <b-form-checkbox v-model="currentLEAMSRecord.isProvision2" />
                </b-form-group>
                <b-form-group label-cols="2" label="Is ALE">
                  <b-form-checkbox v-model="currentLEAMSRecord.ale" />
                </b-form-group>
                <b-form-group label-cols="2" label="School of Innovation">
                  <b-form-checkbox v-model="currentLEAMSRecord.schoolOfInnovation" />
                </b-form-group>
                <b-form-group label-cols="2" label="Comments">
                  <b-form-textarea v-model="currentLEAMSRecord.comment" />
                </b-form-group>
                <b-form-row class="border-top pt-2">
                  <b-col class="text-left">
                    <b-link :to="{name: 'leams-browser'}">
                      Return to LEA Listing
                    </b-link>
                  </b-col>
                  <b-col class="text-right">
                    <b-button @click="save" class="mr-2" variant="primary" :disabled="leaValidationErrors.length > 0">
                      Save
                    </b-button>
                  </b-col>
                </b-form-row>               
              </b-form>
            </div>
          </template>
          <template v-slot:rejected="error">
            <i class="fas fa-exclamation-circle">
              <span class="d-none">{{ error }}</span>
            </i>
          </template>
        </Promised>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Promised } from 'vue-promised'
import { gradeSpans } from '../../../../helpers/gradeSpans'
import { formatAsLEA } from '../../../../helpers/formatters'
export default {
  name: 'leams-new',
  data() {
    return {
      formatAsLEA,
      initialize: null,
      iGradeLow: null,
      iGradeHigh: null,
      currentLEAMSRecord: {},
      leaValidationErrors: []
    }
  },
  computed: {
    ...mapState('toolsModule/leams', ['leaTypes']),
    ...mapState('globalModule', ['leaContext']),
    gradeSpanOptions() {
      return [{ value: null, text: 'N/A' }, ...gradeSpans]
    },
    leaTypeOptions() {
      return this.leaTypes.map(t => ({ value: t.code, text: `${t.code} - ${t.description}` }))
    }
  },
  methods: {
    save() {
      this.currentLEAMSRecord.fy = this.fy
      return this.$store.dispatch("toolsModule/leams/addLEAMSRecord", this.currentLEAMSRecord)
    }

  },
  mounted() {
    this.initialize = this.$store.dispatch('toolsModule/leams/getLeaTypes')
  }
  ,
  watch: {
    "currentLEAMSRecord.leaNumber": {
      handler() {
        this.$store.dispatch("toolsModule/leams/validateLEANumberForOperation", {leaNumber: this.currentLEAMSRecord.leaNumber, operation: 1, fy: this.fy})
        .then(validationErrors => this.leaValidationErrors = validationErrors)
        }
      }
    }
  ,
  components: {
    Promised
  },
  props: {
    fy: {
      required: true,
      type: String
    }
  }
}
</script>
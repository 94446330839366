<template>
  <div>
    <div>
      <b-card :header="`${mode} Cost Item`">
        <b-form>
          <b-form-row class="my-2">
            <label class="col-sm-2 col-form-label">Object Code</label>
            <b-form-select class="col-sm-10 form-control" v-model="selectedCostItem.type" :options="costTypesMapped" value-field="code" text-field="text" :disabled="readonly" />
          </b-form-row>
          <b-form-row class="my-2">
            <label class="col-sm-2 col-form-label">Description</label>
            <b-form-input type="text" class="col-sm-10" :maxlength="descriptionLength" v-model="selectedCostItem.description" :disabled="readonly" />
          </b-form-row>
          <b-form-row class="my-2">
            <label class="col-sm-2 col-form-label">LEA</label>
            <b-form-select class="col-sm-10 form-control" v-model="selectedCostItem.lea" :options="consortiumLeaOptions" :disabled="readonly" />
          </b-form-row>
          <b-form-row class="my-2">
            <label class="col-sm-2 col-form-label">Unit Cost</label>
            <b-input-group prepend="$" class="col-sm-4">
              <b-form-input type="number" :number="true" v-model="selectedCostItem.costPerUnit" @input="calculateTotal" :disabled="readonly" />
            </b-input-group>

            <label class="col-sm-2 col-form-label">Qty</label>              
            <b-input-group class="col-sm-4">
              <b-form-input type="number" :number="true" v-model="selectedCostItem.quantity" @input="calculateTotal" :disabled="readonly" />
            </b-input-group>
          </b-form-row>
          <b-card title="Funding Sources">
            <b-form-group>
              <b-form-row>
                <label class="col-sm-2 col-form-label">Perkins Funds</label>
                <b-input-group prepend="$" class="col-sm-4">
                  <b-form-input type="number" :number="true" class="col-sm-4" v-model="selectedCostItem.perkins" :disabled="readonly" />
                </b-input-group>
              </b-form-row>
              <b-form-row>
                <label class="col-sm-2 col-form-label">Startup Funds</label>
                <b-input-group prepend="$" class="col-sm-4">
                  <b-form-input type="number" :number="true" class="col-sm-4" v-model="selectedCostItem.startup" :disabled="readonly" />
                </b-input-group>
              </b-form-row>
              <b-form-row>
                <label class="col-sm-2 col-form-label">District Funds</label>
                <b-input-group prepend="$" class="col-sm-4">
                  <b-form-input type="number" :number="true" class="col-sm-4" v-model="selectedCostItem.district" :disabled="readonly" />
                </b-input-group>
              </b-form-row>
              <b-form-row>
                <label class="col-sm-2 col-form-label">Partner Funds</label>
                <b-input-group prepend="$" class="col-sm-4">
                  <b-form-input type="number" :number="true" class="col-sm-4" v-model="selectedCostItem.partner" :disabled="readonly" />
                </b-input-group>
              </b-form-row>
              <b-form-row>
                <label class="col-sm-2 col-form-label" :class="remainingTextClass(itemCostRemaining)">Item Cost Remaining</label>
                <b-input-group class="col-sm-4">
                  <b-form-input :value="itemCostRemaining | currency" plaintext :class="remainingTextClass(itemCostRemaining)" />
                </b-input-group>
              </b-form-row>
            </b-form-group>
            <b-form-group v-if="selectedProposal.status == ProposalStates.RevisingCostItems && !readonly">
              <b-form-row>
                <label class="col-sm-2 col-form-labl">Reason for Change to Approved Cost</label>
                <b-form-input type="text" :maxlength="descriptionLength" v-model="selectedCostItem.changeReason" class="col-sm-10" />                
              </b-form-row>
            </b-form-group>
            <b-form-row class="my-2" v-if="!readonly">
              <b-col class="text-right">
                <b-button variant="primary" class="mr-2" @click="submit" :disabled="$v.$invalid">
                  Save
                </b-button>
                <b-button variant="secondary" @click="doCancel">
                  Cancel
                </b-button> 
              </b-col>
            </b-form-row>
            <b-form-row class="my-2" v-else>
              <b-col class="text-right">
                <b-button variant="secondary" @click="backToProposal">
                  Return to Proposal
                </b-button>
              </b-col>
            </b-form-row>
          </b-card>

          <b-card title="Validation" v-if="$v.$invalid">                        
            <b-form-row class="col-sm-12">
              <b-alert class="col-sm-12" variant="danger" show>
                Please correct the following:
                <ul>
                  <li v-if="$v.selectedCostItem.costPerUnit.$invalid">
                    Unit Cost is required
                  </li>
                  <li v-if="$v.selectedCostItem.quantity.$invalid">
                    Item Quantity is required
                  </li>
                  <li v-if="$v.selectedCostItem.perkins.$invalid">
                    Perkins V funds allocated to this cost item exceeds Perkins V available balance
                  </li>
                  <li v-if="$v.itemCostRemaining.$invalid">
                    Please account for exactly 100% of the item cost
                  </li>
                  <li v-if="!$v.selectedCostItem.description.maxLength">
                    Description cannot be longer than {{ descriptionLength }} characters
                  </li>
                  <li v-if="selectedProposal.status == ProposalStates.RevisingCostItems && $v.selectedCostItem.changeReason.$invalid">
                    Reason for change required after approval
                  </li>
                </ul>
              </b-alert>
            </b-form-row>
          </b-card>
          
          <b-card title="Reference">          
            <b-form-row class="my-2">
              <label class="col-sm-2 col-form-label">Total Perkins Funds Awarded</label>
              <b-input-group class="col-sm-4">
                <b-form-input :value="applicantFundingLimit.fundingLimit | currency" plaintext />
              </b-input-group>
            </b-form-row>
            <b-form-row class="my-2">
              <label class="col-sm-2 col-form-label">Other Project's Perkins Used</label>
              <b-input-group class="col-sm-4">
                <b-form-input :value="siblingProposalCost | currency" plaintext />
              </b-input-group>
            </b-form-row>
            <b-form-row class="my-2">
              <label class="col-sm-2 col-form-label">Current Project's Perkins Used</label>
              <b-input-group class="col-sm-4">              
                <b-form-input :value="proposalCost | currency" plaintext /> 
              </b-input-group>
            </b-form-row>
            <b-form-row class="my-2" :class="{ 'text-danger': (perkinsFundsMinusSelectedCostItem) < 0, 'text-success': (perkinsFundsMinusSelectedCostItem) >= 0}">
              <label class="col-sm-2 col-form-label">Perkins Funds Remaining</label>
              <b-input-group class="col-sm-4">
                <b-form-input :value="(perkinsFundsMinusSelectedCostItem) | currency" plaintext :class="{ 'text-danger': (perkinsFundsMinusSelectedCostItem) < 0, 'text-success': (perkinsFundsMinusSelectedCostItem) >= 0}" />
              </b-input-group>
            </b-form-row>
          </b-card>
        </b-form>
      </b-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { round } from '@/helpers/mathHelpers'
import { mapState, mapGetters } from 'vuex'
import { Types } from '@/modules/cte/stores/perkinsv'
import { CostTypeCategories } from '@/modules/cte/components/perkinsV/enums'
import Vuelidate from 'vuelidate'
import { requiredIf, required, maxLength, maxValue } from 'vuelidate/lib/validators'
import { ProposalStates } from '@/modules/cte/stores/perkinsvStateMap'

Vue.use(Vuelidate)

const itemCostValidator = (value, vm) => {
  return vm.itemCostRemaining == 0
}

export default {
  data() {
    return {  
      ProposalStates,
      descriptionLength: 150
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.costTypes, Types.state.consortiumLeas, Types.state.selectedApplication, Types.state.selectedProposal, Types.state.selectedCostItem, Types.state.applicantFundingLimit]), 
    ...mapGetters(Types.path, [Types.getters.selectedApplicationIsForActiveAY]),
    proposalCostItems() {
      return this.selectedProposal.proposalCosts.filter(c => c.proposalCostId != this.costItemId)
    },
    siblingProposalCostItems() {
      return this.selectedApplication.proposals.filter(p => p.proposalId != this.proposalId).flatMap(p => p.proposalCosts)
    },
    proposalCost() {
      return (this.proposalCostItems.length ? this.costItemTotal(this.proposalCostItems) : 0)
    },
    siblingProposalCost() {
      return this.siblingProposalCostItems.length ? this.costItemTotal(this.siblingProposalCostItems) : 0
    },
    perkinsFundsMinusSelectedCostItem() {
      return round(this.perkinsRemaining, 2) - this.selectedCostItem.perkins
    },
    perkinsRemaining() {
      return this.applicantFundingLimit.fundingLimit - (this.proposalCost + this.siblingProposalCost)
    },
    costTypesMapped(){
      var reduced = this.costTypes
      .map(t => { return { ...t, text: `${t.description} (${t.code})` }})
      .reduce((r,a) => {
        r[a.category] = [...r[a.category] || [], a]
        return r
      }, {})
      return Object.entries(reduced)
      .map(r =>{return { label: CostTypeCategories[r[0]].text, options: r[1] }})
    },
    consortiumLeaOptions(){
      return this.consortiumLeas.map(l => {return {text: `${l.lea} - ${l.name}`, value: l.lea}})
    },
    selectedItemCost() {
      return this.selectedCostItem.costPerUnit * this.selectedCostItem.quantity
    },
    itemCostRemaining(){
      if(this.selectedCostItem.costPerUnit && this.selectedCostItem.quantity) {
        return (this.selectedItemCost - ((this.selectedCostItem.district || 0) + (this.selectedCostItem.partner || 0) + (this.selectedCostItem.startup || 0) + (this.selectedCostItem.perkins || 0)))
      }
      return 0
    },
    readonly(){
      return this.mode == 'View'
    }
  },
  validations() {
    return {
      selectedCostItem: {
        changeReason: {
          required: requiredIf(() => this.selectedProposal.status == ProposalStates.RevisingCostItems),
          maxLength: maxLength(this.descriptionLength)
        },
        description: {
          maxLength: maxLength(this.descriptionLength)
        },
        quantity: {
          required
        },
        costPerUnit: {
          required
        },
        perkins: { maxValue: maxValue(round(this.perkinsRemaining, 2)) }
      },
      itemCostRemaining: { itemCostValidator }
    }
  },
  methods: {
    async doCancel() {
      var result = await this.$bvModal.msgBoxConfirm("Are you sure you want to cancel?", { okTitle: "Yes", cancelTitle: "No" })
      if (result) this.backToProposal()
    },
    backToProposal() {
      const params = { ...this.$route.params, selectTabIndex: 5 }
      this.$router.push({ name: 'CTE/PerkinsV/Application/Proposal/Edit', params: params, query: { selectTabIndex: 5 } })
    },
    submit() {
      if(this.mode == 'New') {
        this.$store.dispatch(Types.actions.createCostItem, { 
          applicationId: this.applicationId, proposalId: this.proposalId, costItem: this.selectedCostItem
        })
      }
      else if(this.mode == 'Edit') {
        this.$store.dispatch(Types.actions.updateCostItem, {
          applicationId: this.applicationId, proposalId: this.proposalId, costItemId: this.costItemId, costItem: this.selectedCostItem
        })
      }
      return this.backToProposal()
    },
    costItemTotal: costItems => costItems.map(ci => ci.perkins || 0).reduce((a, b) => a + b, .00),
    calculateTotal(){
      if(this.selectedCostItem.costPerUnit && this.selectedCostItem.quantity) {
        this.selectedCostItem.perkins = this.selectedCostItem.costPerUnit * this.selectedCostItem.quantity
      }
    },
    remainingTextClass(remainingValue){
      if(remainingValue == 0) {
        return "text-success"
      }
      return "text-danger"
    }
  },
  components: {
  },
  beforeRouteEnter:(to, from, next) => {
    next(vm => {
      vm.$store.dispatch(Types.actions.getConsortiumLeas, { consortiumLea: vm.$store.state.cte.perkinsv.selectedApplication.applicantId, academicYear: vm.$store.state.cte.perkinsv.selectedApplication.ay })      
      if(vm.mode == 'Edit' || vm.mode == 'View') {
        vm.$store.dispatch(Types.actions.getCostItem, { applicationId: vm.applicationId, proposalId: vm.proposalId, costItemId: vm.costItemId })
      }
      else if(vm.mode == 'New'){
        vm.$store.commit(Types.mutations.setSelectedCostItem, { type: 1, perkins: 0, district: 0, partner: 0, startup: 0 })
      }
    })
  },
  mounted() {
    this.$store.dispatch(Types.actions.getCostTypes, { schoolYear: this.selectedApplication.ay })    
  },
  props: {
    mode: String,
    applicationId: String,
    proposalId: String,
    costItemId: String,
    applicationLea: String
  }
}
</script>

<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../ChartMixin'
import { credits } from '@/helpers/credits'

export default {
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'pie'
        },
        credits: credits.MSI,
        title: {
          text: 'Waiver Totals by Type'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>:<br>{point.percentage:.1f} %<br>total: {point.total}'
            },
            innerSize: '50%'
          }
        },
        series: [
          {
            name: 'Waivers',
            colorByPoint: true
          }
        ]
      }
    }
  },
  mixins: [ChartMixin],
  computed: {
    waivers() {
      return this.$store.state.overviewModule.waiverStats
    }
  },
  watch: {
    waivers(newData) {
      this.chartOptions.series[0].data = newData.map(data => {
        return {
          name: data.type,
          y: data.count
        }
      })
    }
  },
  methods: {
    excelExportData() {
      return this.defaultExcelExportData()
    },
    dataItems() {
      return this.chartOptions.series[0].data.map(item => {
        return { Type: item.name, Count: item.y }
      })
    }
  }
}
</script>

<template>
  <b-row class="mt-3">
    <b-col cols="6">
      <b-card no-body header="Students in Courses by Grade" class="mb-3">
        <students-in-courses-by-grade class="scroll-horizontal" />
      </b-card>        
    </b-col>
    <b-col cols="6">
      <b-card no-body header="Teachers of Courses by Grade" class="mb-3">
        <teachers-in-courses-by-grade class="scroll-horizontal" />
      </b-card>        
    </b-col>
    <b-col cols="6">
      <b-card no-body header="Students in Courses by Type" class="mb-3">
        <students-in-courses-by-type class="scroll-horizontal" />
      </b-card>        
    </b-col>
    <b-col cols="6">
      <b-card no-body header="Teachers of Courses by Type" class="mb-3">
        <teachers-in-courses-by-type class="scroll-horizontal" />
      </b-card>        
    </b-col>
  </b-row>
</template>

<script>
import StudentsInCoursesByGrade from '@/modules/rvs/components/common/StudentsInCoursesByGrade.vue'
import TeachersInCoursesByGrade from '@/modules/rvs/components/common/TeachersInCoursesByGrade.vue'
import StudentsInCoursesByType from '@/modules/rvs/components/common/StudentsInCoursesByType.vue'
import TeachersInCoursesByType from '@/modules/rvs/components/common/TeachersInCoursesByType.vue'

export default {
  components: {
    StudentsInCoursesByGrade,
    TeachersInCoursesByGrade,
    StudentsInCoursesByType,
    TeachersInCoursesByType,
  }
}
</script>

<style lang="scss">
  .scroll-horizontal {
    overflow-x: auto;
  }
</style>
<template>
  <div>
    <router-view />
  </div>
</template>


<script>
import DigitalLearningPublicListPage from './DigitalLearningPublicListPage.vue'
import DigitalLearningPublicViewPage from './DigitalLearningPublicViewPage.vue'
import { router } from '@/routes'

router.addRoutes([
  { path: '/Surveys/DigitalLearning/Public', component: DigitalLearningPublicListPage, name: 'public-waiver-request-list' },
  { path: '/Surveys/DigitalLearning/Public/View', component: DigitalLearningPublicViewPage, name: 'public-waiver-request-view' },
])

export default {
  data() {
    return {
    }
  } 
}

</script>
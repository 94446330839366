<template>
  <div v-if="render">
    <table id="subject-table" class="table table-striped table-bordered" style="width:100%">
      <caption>Table {{ table }}: Count of Teachers in {{ subjectCase(subject) }} in Each Effectiveness Category</caption>
      <thead>
        <tr>
          <th rowspan="2">
            Grade Level of Teacher Score
          </th>
          <th class="text-center" colspan="5">
            {{ subjectCase(subject) }} Ratings
          </th>
        </tr>
        <tr>
          <th class="text-right" v-for="level in effectivenessLevels" :key="level.code">
            {{ `${level.code}: ${level.name}` }}
          </th>
          <th class="text-right">
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="grade in grades" :key="grade">
          <th>{{ grade }}</th>
          <td class="text-right" v-for="level in effectivenessLevels" :key="level.code">
            {{ getTableValue(grade, level.code) }}<br>
            {{ ((getTableValue(grade, level.code) / getRowTotal(grade) * 100.0) || 0).toFixed(2) }}%
          </td>
          <td class="text-right">
            {{ getRowTotal(grade) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { subjectCase } from '../helpers.js'
import { mapGetters } from 'vuex'
import { effectivenessLevels } from '../enums.js';

export default {
  props: {
    subject: {
      type: String,
      required: true
    },
    render: {
      type: Boolean,
      default: true
    },
    table: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      subjectCase,
      effectivenessLevels,
      grades: ['3', '4', '5', '6', '7', '8', '9', '10']      
    }
  },
  methods: {
    getGroupsForLetterGrade(rows) {
      return rows.reduce((acc, curr) => {
        if (curr.grade) {
          acc[curr.grade] = acc[curr.grade] || 0
          acc.total = acc.total || 0
          acc[curr.grade]++
          if (curr.grade) acc.total++
        }
        return acc
      }, {})
    },
    getTableValue(grade, level) {
      const key = `${grade}_${level}`

      if (typeof this.getTableValue.cache === 'undefined') this.getTableValue.cache = new Map()
      if (this.getTableValue.cache.has(key)) return this.getTableValue.cache.get(key)
      else {
        var value = undefined
        var rating = this.tableData[0].ratings.find(rating => rating.rating == level)
        if (rating) {
          var grades = rating.grades[grade]
          value = grades || 0
        }
        else value = 0        
        this.getTableValue.cache.set(key, value)
        return value
      } 
    },
    getRowTotal(grade) {
      const key = grade
      
      if (typeof this.getRowTotal.cache === 'undefined') this.getRowTotal.cache = new Map()
      if (this.getRowTotal.cache.has(key)) return this.getRowTotal.cache.get(key)
      else {
        const value = this.effectivenessLevels.reduce((acc, curr) => {
          var rating = this.tableData[0].ratings.find(rating => rating.rating == curr.code)
          if (rating) {
            var grades = rating.grades[grade]
            acc += grades || 0
          }
          return acc
        }, 0)

        this.getRowTotal.cache.set(key, value)
        return value
      }
    },
    excelExportData() {
      return {
        name: `T${this.table}-${subjectCase(this.subject)}-Grade-Category`,
        items: this.grades.map(grade => {
          var row = {}
          row['Grade'] = grade
          this.effectivenessLevels.forEach(level => {
            row[`${level.code}: ${level.name}`] = this.getTableValue(grade, level.code)
            
          })
          row['Total'] = this.getRowTotal(grade)
          return row
        })
      }
    }
  },
  computed: {
    ...mapGetters('teacherVAMModule', ['detailsBySubjectWithGrade']),
    tableData() {
      return Object.keys(this.detailsBySubjectWithGrade).filter(row => row == this.subject).map(subject =>
      {
        const ratings = this.detailsBySubjectWithGrade[subject].reduce((acc, curr) => {
          acc[curr.rating] = acc[curr.rating] || []
          acc.total = acc.total || 0
          acc[curr.rating].push(curr)
          acc.total++
          return acc
        }, {})
        const ratingsMap = Object.keys(ratings).filter(rating => rating != 'total').map(rating => {
          return { rating, grades: this.getGroupsForLetterGrade(ratings[rating]) }
        })

        return { subject, ratings: ratingsMap }
      })
    },
  }
}
</script>
import FinancialOverviewPage from './overview/FinancialOverviewPage.vue'
import FinancialOverviewSidebar from './overview/FinancialOverviewSidebar.vue'
import GeneralLedgerPage from './generalLedger/GeneralLedgerPage.vue'
import SchoolFinancesPage from './schoolFinances/SchoolFinancesPage.vue'

const FinancialArea = {
  FinancialOverviewPage,
  FinancialOverviewSidebar,
  GeneralLedgerPage,
  SchoolFinancesPage
}

export { FinancialArea }
<template>
  <div>
    <div class="card">
      <div class="card-header">
        District LEA Requests
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 dashboard-panel">
            <h4>Requests By Status</h4>
            <div>
              <span class="description">Draft</span>
              <span class="badge badge-primary">{{ summary.status.draft }}</span>
            </div>
            <div>
              <span class="description">Pending</span>
              <span class="badge badge-primary">{{ summary.status.pending }}</span>
            </div>
            <div>
              <span class="description">In Review</span>
              <span class="badge badge-primary">{{ summary.status.inReview }}</span>
            </div>
            <div>
              <span class="description">Completed</span>
              <span class="badge badge-primary">{{ summary.status.completed }}</span>
            </div>
          </div>

          <div class="col-md-4 dashboard-panel">
            <h4>
              Requests By Operation Type
            </h4>
            <div>
              <span class="description">Building Annexation</span>
              <span class="badge badge-primary">{{ summary.operation.buildingAnnexation || 0 }}</span>
            </div>
            <div>
              <span class="description">Building Consolidation</span>
              <span class="badge badge-primary">{{ summary.operation.buildingCombination || 0 }}</span>
            </div>
            <div>
              <span class="description">Building LEA # Change</span>
              <span class="badge badge-primary">{{ summary.operation.changeBuildingLEA || 0 }}</span>
            </div>
            <div>
              <span class="description">Other General Changes</span>
              <span class="badge badge-primary">{{ summary.operation.general || 0 }}</span>
            </div>
            <div>
              <span class="description">New District LEA Request</span>
              <span class="badge badge-primary">{{ summary.operation.newDistrict || 0 }}</span>
            </div>
            <div>
              <span class="description">District Information Change</span>
              <span class="badge badge-primary">{{ summary.operation.changeDistrict || 0 }}</span>
            </div>
            <div>
              <span class="description">District Reconfiguration</span>
              <span class="badge badge-primary">{{ summary.operation.districtReconfiguration || 0 }}</span>
            </div>
          </div>

          <div class="col-md-4 dashboard-panel">
            <h4>Requests By Disposition</h4>
            <div>
              <span class="description">Approved</span>
              <span class="badge badge-success">{{ summary.disposition.approved }}</span>
            </div>
            <div>
              <span class="description">Rejected</span>
              <span class="badge badge-danger">{{ summary.disposition.rejected }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2 offset-10 text-right mb-2">
            <b-button
              variant="success"
              to="/tools/lea-assignment/requests/new"
              v-if="(userContext.hasRole('Insights_RT-SMS_Administrators') || userContext.hasRole('Insights_CharterSchoolOffice_Administrators') || !userContext.isADEUser)"
            >
              <i class="fas fa-spinner fa-spin" v-if="navigating" /> Create New Request
            </b-button>
          </div>
        </div>
        <b-form inline class="mb-2 d-print-none">
          <label class="ml-3 mr-sm-2">Apply to School Year:</label>
          <b-form-select
            class="col-sm-3 mr-4"
            v-model="fyFilter"
            :options="filterFiscalYearAssignmentOptions"
          />
          <label class="ml-3 mr-sm-2">Request Status:</label>
          <b-form-select
            class="col-sm-3 mr-4"
            v-model="statusFilter"
            :options="stateArray"
            value-field="code"
            text-field="status"
          />
          <b-form-checkbox v-model="includeCanceled">
            Include Canceled Requests
          </b-form-checkbox>
        </b-form>
        <table-control-widget
          :per-page="tableOptions.perPage"
          :filter="tableOptions.filter"
          @page-length-changed="tableOptions.perPage = $event"
          @filter-changed="tableOptions.filter = $event"
        />
        <b-table
          small
          :items="filteredRequests"
          :fields="tableFields"
          :filter="tableOptions.filter"
          :per-page="tableOptions.perPage"
          :current-page="tableOptions.currentPage"
          sort-by="updateDate"
          :sort-desc="true"
        >
          <template v-slot:cell(requestId)="data" float-right>
            <div class="pull-right">
              <b-button
                size="sm"
                variant="link"
                :to="`/tools/lea-assignment/requests/${data.value}/edit`"
                v-if="itemEditAllowed(data.item)"
              >
                <span class="text-secondary fas fa-edit" title="Edit request" />
              </b-button>
              <b-button
                size="sm"
                variant="link"
                :to="`/tools/lea-assignment/requests/${data.value}/review`"
                v-else-if="reviewAllowed(data.item)"
              >
                <span class="text-secondary fas fa-search" title="Review request" />
              </b-button>
              <b-button
                size="sm"
                variant="link"
                :to="`/tools/lea-assignment/requests/${data.value}/view`"
                v-else
              >
                <span class="text-secondary fas fa-eye" title="View request" />
              </b-button>              
              
              <b-button
                size="sm"
                variant="link"
                :href="`/tools/lea-assignment/RequestPdf/${data.value}?response-format=pdf`"
              >
                <span class="text-secondary fas fa-file-invoice" title="Request PDF" />
              </b-button>
              <b-button
                size="sm"
                variant="link"
                :href="`/api/request/${data.value}/document/${data.item.acceptanceLetterId}`"
                v-if="data.item.requestStatus == 100 && data.item.acceptanceLetterId && data.item.acceptanceLetterId > 0"
              >
                <span class="text-secondary fas fa-file-signature" title="Acceptance Letter PDF" />
              </b-button>
              <b-button
                size="sm"
                variant="link"
                @click="cancelRequest(data.item.requestId)"
                v-if="data.item.requestStatus <= 1"
              >
                <span class="text-secondary fas fa-times" title="Cancel Request" />
              </b-button>
            </div>
          </template>
        
          <template v-slot:cell(leas)="data">
            <ul class="list-unstyled font-weight-bold">
              <li v-for="d in data.value" :key="d.lea">
                <b-badge :id="`R${data.item.requestId}-lea-${d.lea}`" :variant="d.operationType == 3 ? 'danger' : d.operationType == 1 ? 'success' : 'warning'">
                  {{ d.lea || 'Pending' }}
                </b-badge>
                <lea-tooltip :lea-number="d.lea" :target-selector="`R${data.item.requestId}-lea-${d.lea}`" />
              </li>
            </ul>
          </template>
          <template v-slot:cell(applyToFiscalYear)="data">
            {{ data.value | fromFY('short') }}
          </template>

          
          <template v-slot:cell(requestStatus)="data">
            <div>
              <i
                v-if="data.item.isRecalled"
                class="fa fas fw fa-exclamation-circle text-warning mr-2"
                title="This request has been recalled by the district"
              />
              <i
                v-if="data.item.isRecallGranted"
                class="fa fas fw fa-check-circle text-success mr-2"
                title="The recall request has been granted"
              />
              <i
                v-if="data.item.isRecallDenied"
                class="fa fas fw fa-times-circle text-danger mr-2"
                title="The recall request has been denied"
              />
              <b-badge
                :variant="mapState(data.item.requestStatus).variant || 'primary'"
              >
                {{ mapState(data.item.requestStatus).status }}
              </b-badge>

              <span>
                <router-link
                  v-if="typeof(data.item.synchronizationResult) !== 'undefined' && data.item.synchronizationResult"
                  :to="{name: 'leams-sync', query: {id: data.item.requestId}}"
                >
                  <span title="LEAMS Sync successful" class="fa-stack text-success">
                    <i class="fas fa-bolt fa-sm fa-stack-1x" />
                    <i class="far fa-circle fa-lg" />
                  </span>
                </router-link>
                <span
                  v-else-if="typeof(data.item.synchronizationResult) !== 'undefined' && !data.item.synchronizationResult"
                  title="LEAMS Sync failed"
                  class="fa-stack"
                >
                  <i class="fas fa-bolt fa-sm fa-stack-1x text-muted" />
                  <i class="fas fa-ban fa-lg text-danger" />
                </span>
                <span
                  v-else-if="typeof(data.item.synchronizationResult) == 'undefined' && data.item.requestStatus == RequestStatus.Approved"
                  title="Pending LEAMS Sync"
                  class="fa-stack text-muted"
                >
                  <i class="fas fa-stopwatch fa-sm fa-stack-1x" />
                  <i class="far fa-circle fa-lg" />
                </span>
              </span>
            </div>
          </template>
          <template v-slot:cell(districtLEA)="data">
            <b-badge :id="'request_' + data.item.requestId" :variant="data.item.requestSource == RequestSource.CharterSchoolOffice ? 'warning' : 'primary'">
              {{ data.value }}
            </b-badge>
            <lea-tooltip :lea-number="data.value" :target-selector="'request_' + data.item.requestId" />
          </template>
          <template v-slot:cell(actionTypes)="data">
            <div class="action-items">
              <i
                class="fas fa-plus-square fw fa-2x text-success mr-1"
                :title="data.item.requestsForNewLEAS + ' New Building LEA request(s)'"
                v-if="data.item.requestsForNewLEAS"
              />
              <i
                class="fas fa-plus-square fw fa-2x text-primary mr-1"
                :title="data.item.requestsForNewDistrictLEAS + ' New District LEA request'"
                v-if="data.item.requestsForNewDistrictLEAS"
              />
              <i
                class="fas fa-minus-square fw fa-2x text-danger mr-1"
                :title="data.item.requestsToInactive + ' Inactivate School LEA request'"
                v-if="data.item.requestsToInactive"
              />
              <i
                class="fas fa-pen-square fw fa-2x text-info mr-1"
                :title="data.item.requestsToChangeDistrictLea + ' Change District LEA request'"
                v-if="data.item.requestsToChangeDistrictLea"
              />
              <span v-if="data.item.requestsToChangeNameOrGradeSpan">
                <i
                  class="fas fa-pen-square fw fa-2x text-warning mr-1"
                  v-for="i in data.item.requestsToChangeNameOrGradeSpan"
                  :key="i"
                  :title="data.item.requestsToChangeNameOrGradeSpan + ' Change School LEA request(s)'"
                />
              </span>
            </div>
          </template>
        </b-table>
        <table-pagination-widget
          :per-page="tableOptions.perPage"
          :filter="tableOptions.filter"
          :current-page="tableOptions.currentPage"
          :page-length="tableOptions.perPage"
          :total-rows-count="filteredRequests.length"
          @page-changed="tableOptions.currentPage = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TablePaginationWidget from '../../shared/TablePaginationWidget'
import TableControlWidget from '../../shared/TableControlWidget'
import { mapState, mapGetters } from 'vuex'
import { RequestType, RequestStatus, requestOperationFormatter, RequestSource } from '../leaAssignment'
import { LeaAssignmentRoles } from '@/components/tools/leaAssignment'
import LeaTooltip from '../../LeaTooltip'
import { CoordinatorCodes } from '@/helpers/coordinatorCodes'

export default {
  name: 'request-list',
  data() {
    return {
      RequestSource,
      requestOperationFormatter,
      RequestStatus,
      statusFilter: null,
      fyFilter: null,
      includeCanceled: false,
      tableOptions: {
        currentPage: 1,
        perPage: 10,
        filter: null
      },
      schools: [],
      navigating: false
    }
  },
  components: {
    TablePaginationWidget,
    TableControlWidget,
    LeaTooltip
  },
  computed: {
    ...mapState('globalModule', ['userContext', 'leaContext']),
    ...mapGetters('globalModule', ['leaContext']),
    ...mapState('requestModule', ['summary', 'requests']),
    ...mapState('toolsModule/leaAssignment', ['stateMap']),
    ...mapGetters('toolsModule/leaAssignment', ['userStateMap']),
    ...mapGetters('toolsModule/leams', ['fiscalYearAssignmentOptions']),

    filterFiscalYearAssignmentOptions() {
      return [{value: null, text: '(All)'}, ...this.fiscalYearAssignmentOptions, {value: 0, text: '(Not Assigned)'}]
    },
    filteredRequests() {
      var requests = this.requests

      if (!this.includeCanceled) {
        requests = this.requests.filter(r => r.requestStatus != 255)
      }

      if (this.statusFilter != null) {
        requests = requests.filter(r => r.requestStatus == this.statusFilter)
      }

      if (this.fyFilter > 0) {
        requests = requests.filter(r => r.applyToFiscalYear == this.fyFilter)
      } else if (this.fyFilter == 0) {
        requests = requests.filter(r => r.applyToFiscalYear == null)
      }

      return requests
    },
    stateArray() {
      if (this.userStateMap) {
        var optionsArray = [{ code: null, status: 'Show All' }]
        return optionsArray.concat(
          //eslint-disable-next-line
          Array.from(this.userStateMap, ([key, value]) => value).filter(m => this.requests.map(r => r.requestStatus).includes(m.code))
        )
      }
      return []
    },
    tableFields() {
      return [
        {
          key: 'districtLEA',
          sortable: true
        },
        {
          key: 'leas',
          label: 'LEA(s)'
        },
        {
          key: 'operation',
          sortable: true,
          formatter: (v) => requestOperationFormatter(v),
          sortByFormatted: true,
          filterByFormatted: true
        },
        {
          key: 'createdBy',
          sortable: true
        },
        {
          key: 'createdDate',
          sortable: true
        },
        {
          key: 'updateDate',
          sortable: true
        },
        {
          key: 'applyToFiscalYear',
          sortable: true,
          class: 'text-center',
          label: 'Apply to School Year'
        },
        {
          key: 'requestStatus',
          label: 'Request Status',
          formatter: this.mapStateValue,
          sortable: true,
          class: 'text-center'
        },
        {
          key: 'actionTypes',
          label: ' Request Action(s)',
          class: 'text-center'
        },
        {
          key: 'requestId',
          label: '',
          thStyle: 'width: 115px;'
        }
      ]
    }
  },
  methods: {
     reviewAllowed(item) {
       console.log(item.requestStatus)
      if (this.userContext.isRTSMSAdmin) {
        return [RequestStatus.PendingADELeaCoordinator, RequestStatus.DistrictInformationProvided, RequestStatus.PSAReviewCompleted, RequestStatus.Reopen].includes(item.requestStatus)
      } else if (this.userContext.isPSAAdmin) {
        return [RequestStatus.PendingPSAReview].includes(item.requestStatus)
      }

      return false
    },

    itemEditAllowed(item) {

      //if the user is a charter school administrator, the request is for AR, and the status in in DRAFT or Request For Information
      if (this.userContext.hasRole(LeaAssignmentRoles.CharterOfficeAdmin)) {
        return [RequestStatus.Draft, RequestStatus.ReturnedForInformation].includes(item.requestStatus) && item.districtLEA === 'AR'
      } 

      //if the user is a SMS Admin user, they can edit it no matter what if it is in an acceptable status
      if (this.userContext.hasRole(LeaAssignmentRoles.SMSAdmin)) {
        return [RequestStatus.Draft, RequestStatus.ReturnedForInformation].includes(item.requestStatus) 
      }
      
      //if the user is a district superintendent, the request is for their district, and in an acceptable status
      if (this.userContext.hasCoordinatorRoleForLEA(CoordinatorCodes.SUPERINTENDENT, item.districtLEA)) {
        return [RequestStatus.Draft, RequestStatus.ReturnedForInformation, RequestStatus.PendingSuperintendent].includes(item.requestStatus)
      }

      //if the user is a district user, the request is for their district, and in an acceptable status
      if (this.userContext.hasRole("APSCN User")) {
        return [RequestStatus.Draft, RequestStatus.ReturnedForInformation].includes(item.requestStatus) && item.districtLEA === this.userContext.startLEA
      }

      

      return false;
    },
    mapState(state) {
      return this.stateMap.get(state)
    },
    mapStateValue(state) {
      return this.stateMap.get(state)?.status
    },
    cancelRequest(id) {
      if (confirm('Are you sure you want to cancel this request?')) {
        this.$store
          .dispatch('requestModule/transitionToState', {
            requestId: id,
            toState: 255,
            comment: 'Request Cancelled'
          })
          .then(() => {
            return this.refreshList()
          })
      }
    },
    refreshList() {
      return this.$store.dispatch('requestModule/getRequestSummary', {
        lea: this.userContext.districtLEA,
        type: RequestType.LEAChangeRequest
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == 'new-request') {
      this.navigating = true
    }
    next()
  },
  props: {
    districtLea: {
      type: String
    }
  },
  watch: {
    requests: {
      handler() {
        // if (this.userContext.isADELeadership && this.stateArray.some(s => s.code == 11)) {
        //   this.statusFilter = 11
        // }

        if (this.$route.query.status) {
          this.statusFilter = this.$route.query.status
        } else {
          this.statusFilter = null
        }
      }
    }
  }
}
</script>

<style lang="scss">
.dashboard-panel {
  font-size: 1.1em;
  .description {
    width: 50%;
    display: inline-block;
  }
}
</style>

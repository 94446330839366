<template>
  <div>
    <b-table sort-by="name" :fields="fields" :items="areas" v-if="mode=='view'">
      <template v-slot:head(action)>
        <b-button @click="onBeginAdd" variant="primary">
          <i class="fa fa-fw fa-plus-circle" /> Add
        </b-button>
      </template>
      <template v-slot:cell(facilityPlanMgrId)="data">
        {{ getManagerName(data.value) }}
      </template>
      <template v-slot:cell(facilityMaintMgrId)="data">
        {{ getManagerName(data.value) }}
      </template>
      <template v-slot:cell(action)="data">
        <command-buttons
          :options="{showAdd: false, showEdit: true, showDelete: true, operation:'view'}"
          @on-edit="onRowEdit(data.item)"
          @on-delete="onRowDelete(data.item)"
        />
      </template>
    </b-table>

    <edit-area
      :item="editingItem"
      v-if="mode!='view'"
      @on-cancel="onCancelEdit"
      @on-save="onCommitChange"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CommandButtons from '../../../shared/CommandButtons'
import EditArea from './edit-area'
import moment from 'moment'

export default {
  name: 'areas',
  data() {
    return {
      mode: 'view',
      selectedItem: null,
      editingItem: null,
      fields: [
        { key: 'name', sortable: true },
        { key: 'facilityPlanMgrId', label: 'Planning and Construction' },
        { key: 'facilityMaintMgrId', label: 'Maintenance Manager' },
        { key: 'inactiveDate', formatter(value) { if (value) return moment(value).format('MMMM Do YYYY, h:mm:ss a') } },
        { key: 'action', thClass: 'text-right' }
      ]
    }
  },
  mounted() { 
    this.$store.dispatch('DPSAFT/getAreaManagers').then(() => {
      this.$store.dispatch('DPSAFT/getAreas')
    })
  },
  methods: {
    onRowEdit(item) {
      this.selectedItem = item
      this.editingItem = Object.assign({}, item)
      this.mode = 'edit'
    },
    onBeginAdd() {
      this.editingItem = {
        name: null,
        facilityPlanMgrId: null,
        facilityMaintMgrId: null
      }
      this.mode = 'add'
    },
    onCancelEdit() {
      this.mode = 'view'
      this.editingItem = null
    },
    onCommitChange(areaArgs) {
      let p = null
      if (this.mode == 'add') {
        p = this.$store.dispatch('DPSAFT/Admin/addArea', areaArgs)
      } else if (this.mode == 'edit') { 
        p = this.$store.dispatch('DPSAFT/Admin/updateArea', areaArgs)
      }
      p.then(() => this.$store.dispatch('DPSAFT/getAreas')).then(() => (this.mode = 'view'))

    },
    onRowDelete(item) {
      this.$store
        .dispatch('DPSAFT/Admin/inactivateArea', item.areaId)
        .then(() => this.$store.dispatch('DPSAFT/getAreas'))
        .then(() => (this.mode = 'view'))
    },
    getManagerName(userId) {
      const managers = (this.areaManagers.facilitiesMaintenanceManagers || []).concat(this.areaManagers.facilitiesPlanningAndConstructionManagers || [])
      return (managers.find(a => a.userId == userId) || { fullName: 'Unassigned' }).fullName
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState('DPSAFT', ['areas', 'areaManagers'])
  },
  components: {
    CommandButtons,
    EditArea
  }
}
</script>

<style lang="scss" scoped>
// the ::v-deep makes it so we generate the selector ".table[data-v-5871abb4] th" instead of the selector ".table th[data-v-5871abb4]"
.table ::v-deep th { 
  border-top: none !important;
}
</style>
<template>
  <div>
    <loadable-chart-card :load-data="loadData">
      <template v-slot:default="slotProps">
        <div class="chartElem" v-show="slotProps.chartMode">
          <highcharts class="chart" :options="chartOptions" />
        </div>
        <div v-show="!slotProps.chartMode">
          <chart-data-table :items="dataItems()" />
        </div>
      </template>
    </loadable-chart-card>
  </div>
</template>

<script>
//import groupBy from 'lodash.groupby'
import orderBy from 'lodash.orderby'
//import TeacherSurveyCoops from './teacherSurveyCoops'
import LoadableChartCard from '../../LoadableChartCard'
import { ChartMixin } from '../../ChartMixin'
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'teacher-survey-dates-chart',
  data: function () {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'column'
        },
        plotOptions: {
          series: {
            minPointLength: 3
          }
        },
        credits: '',
        title: {
          text: 'Survey Counts by Submit Date'
        },
        series: [],
        xAxis: {
          labels: {
            enabled: true,
            format: '{value:%m/%e }'
          },
          type: 'datetime',
          title: {
            text: 'Submitted Date'
          }
        },
        yAxis: {
          title: {
            text: "Number of Surveys"
          },
          endOnTick: true
        },
        legend: false
      }
    }
  },
  props: {
    loadData: Promise
  },
  computed: {
    ...mapState('surveys/teacherSurvey', ['surveyDates']),
  },
  components: {
    LoadableChartCard
  },
  methods: {
    dataItems() {
      return orderBy(this.surveyDates.map(d => { return { x: new moment(d.x).format("dddd, MMMM D, YYYY"), y: d.y, date: d.x }}), (v) => v.date).map(d => { return { date: d.x, numberOfSurveys: d.y }})
    }
  },
  mixins: [ChartMixin],
  watch: {
    surveyDates: {    
      immediate: true,
      handler(newData) {
        newData = orderBy(newData.map(d => { return { x: new Date(d.x), y: d.y }}), 'x')

        if (newData) {
          this.chartOptions.series = [
            {
              name: 'Surveys',
              data: newData,
              colorByPoint: true
            }
          ]
        }
      }
    }
  }
}
</script>

<style>

</style>
<template>
  <loadable-chart-card
    :load-data="loadData"
    :enable-data-toggle="false"
    card-style="">
    <div class="w-100 p-2 chart">
      <table-control-widget
        :per-page="tableControl.pageLength"
        @filter-changed="tableControl.filter = $event"
        @page-length-changed="tableControl.pageLength = $event"
      />
      <b-table
        id="class-size-table"
        :items="classSizes"
        :fields="fields"
        :per-page="tableControl.pageLength"
        :current-page="tableControl.currentPage"
        :filter="tableControl.filter"
        :tbody-tr-class="rowClass"
        @filtered="onFiltered"
        small
      >
        <template v-slot:cell(fullName)="data">
          <a :href="'/Schedule/Home?lea=' + data.item.lea + '&tid=' + data.item.uniqueEmployeeId + '&cs=' + data.item.sectionNumber + '&mp=' + data.item.markingPeriod">
            {{ data.value }}
          </a>
        </template>
        <template v-slot:cell(lea)="data">
          <a href="#" @click="goToLea(data.value)" :id="data.index + '-' + data.value">
            {{ data.value }}
          </a>
          <lea-tooltip :target-selector="data.index + '-' + data.value" :lea-number="data.value" />
        </template>
        <template slot="empty">
          <h4>
            No class sizes found
          </h4>
        </template>
      </b-table>
      <table-pagination-widget
        :total-rows-count="rowCount" 
        :page-length="tableControl.pageLength" 
        @page-changed="tableControl.currentPage=$event" />
      <div class="row d-print-none">
        <div class="col-md-12">
          <ul style="list-style:none;" class="p-1">
            <li>
              <span style="background-color: #ffeeba">Yellow highlighted rows</span>: Indicates potential (SFA) Accreditation violations
            </li>
          </ul>
        </div>
      </div>
    </div>
  </loadable-chart-card>
</template>

<script>
import { TableMixin } from '../../TableMixin'
import { ChartMixin } from '../../ChartMixin'

export default {
  data() {
    return {
      tableControl: {
        filter: null,
        pageLength: 50,
        currentPage: 1
      },
      rowCount: 1,
      animationDuration: 1000,
      fields: [
        { key: 'lea', label: 'LEA', sortable: true },
        { key: 'fullName', sortable: true },
        { key: 'uniqueEmployeeId', label: 'Employee ID', sortable: true },
        { key: 'course', sortable: true },
        { key: 'sectionNumber', sortable: true },
        { key: 'classSize', sortable: true },
        { key: 'maxAllowedClassSize', sortable: true },
        { key: 'markingPeriod', sortable: true },
      ]
    }
  },
  mixins: [ChartMixin, TableMixin],
  computed: {
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    },
    showOnlyViolations() {
      return this.$store.state.scheduleModule.showOnlyViolations
    },
    classSizes() {
      if (this.showOnlyViolations) {
        return this.$store.state.scheduleModule.classSizeDetails.filter(t => t.result == 0)
      }
      return this.$store.state.scheduleModule.classSizeDetails
    }
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.tableControl.currentPage = 1
      this.rowCount = filteredItems.length
    },
    dataItems() {
      return this.classSizes
    },
    goToLea(leaNumber) {
      const lea = this.leaDictionary[leaNumber]
      window.scrollTo(0, 0)
      this.$store.dispatch('globalModule/changeSelectedLea', lea)
    },
    excelExportData() {
      return {
        name: 'Class Size',
        items: this.classSizes
      }
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.result == 0) return 'table-warning'
    }
  },
  watch: {
    classSizes: {
      immediate: true,
      handler(classSizes) {
        if (this.$printMode) {
          this.tableControl.pageLength = classSizes.length
          this.$root.pageLoading = false
        }
        this.rowCount = classSizes.length
      }
    }
  }
}
</script>

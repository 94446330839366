<template>
  <div>
    Bus Listing
    <bus-list :items="SelectedBatch.inspections" :show-batch-status="true" :show-latest-inspection="false" :allow-item-check="false" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BusList from '../../common/BusList'

export default {
  data() {
    return {}
  },
  methods: {},
  props: {},
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState('DPSAFT/Trans', ['SelectedBatch']),
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    }
  },
  components: {
    BusList
  }
}
</script>
<template>
  <div v-if="$v.request" class="mt-3">
    <div v-if="selectedWaiverRequest && !selectedWaiverRequest.waiverRequestSnapshotId">
      <b-alert variant="warning" v-if="newDLWaiverRequestsLockedStatus && isDigitalLearning" show>
        <i class="fa fa-exclamation-circle" />
        New Digital Learning Waiver requests can not be created after May 1st. Existing drafts and revisions will continue to be reviewed.
      </b-alert>
      <b-alert variant="warning" v-if="newAMIApplicationsLockedStatus && isAMI" show>
        <i class="fa fa-exclamation-circle" />
        New AMI Applications can not be created after August 1st. Existing drafts and revisions will continue to be reviewed.
      </b-alert>
      <b-alert variant="info" show>
        Applications will be reviewed in the order received. Incomplete applications will be returned to the district.<br>
        A notification letter will be sent to the superintendent at the email address listed above upon approval by the Arkansas State Board of Education.<br>
        NOTE: The approved application must then be posted on the district website - State Required Information.
      </b-alert>
    </div>

    <div class="border mt-3 ml-0 p-3 bg-light">
      <dl class="row" id="header">
        <dt class="col-sm-4">
          District:
        </dt>
        <dd class="col-sm-8">
          {{ leaName }}
        </dd>

        <dt class="col-sm-4">
          LEA #:
        </dt>
        <dd class="col-sm-8">
          {{ $v.request.districtLEA.$model }}
        </dd>
        <dt class="col-sm-4">
          Superintendent:
        </dt>
        <dd class="col-sm-8">
          {{ $v.request.superintendent.$model }}
        </dd>

        <dt class="col-sm-4">
          Email:
        </dt>
        <dd class="col-sm-8">
          <a :href="'mailto:' + $v.request.email.$model">{{ $v.request.email.$model }}</a>
        </dd>

        <dt class="col-sm-4">
          Phone:
        </dt>
        <dd class="col-sm-8">
          <a :href="'tel:' + $v.request.phone.$model">{{ $v.request.phone.$model }}</a>
        </dd>

        <dt class="col-sm-4">
          Duration Requested (not to exceed three years):
        </dt>
        <dd class="col-sm-8">
          <b-form-select v-if="edit" v-model="$v.request.durationSchoolYears.$model" :options="durationOptions" size="sm" />
          <span v-else>{{ durationOption }}</span>
          <i class="mx-1 fa fa-highlighter text-warning" title="Duration has changed since the last snapshot" v-if="propertyHasChangedFromSnapshot('durationSchoolYears')" />
          <div>(School year {{ durationSchoolYearRange }})</div>
        </dd>
      </dl>

      <div v-if="isDigitalLearning">
        <program-affected-leas :district-lea="selectedWaiverRequest.districtLEA" v-model="$v.request.programAffectedLeasResponse.$model" :edit="edit" :default-value="[]" :valid="!$v.request.programAffectedLeasResponse.$invalid" />
        <waiver-topics v-model="$v.request.waiverTopicsResponse.$model" @touch="touch()" :edit="edit" style="page-break-before: always;" />
        <digital-model v-model="$v.request.digitalModelResponse.$model" @touch="touch()" :edit="edit" />
      </div>
      <div v-if="isAMI">
        <ami-program v-model="$v.request.amiResponse.$model" :valid="!$v.request.amiResponse.$invalid" @touch="touch()" :edit="edit" style="page-break-before: always;" />
      </div>
    </div>

    <back-to-top />
  </div>
</template>

<script>
import { Types, WaiverTypes } from '@/stores/digitalLearning'
import { mapState } from 'vuex'
import ProgramAffectedLeas from './ProgramAffectedLeas'
import WaiverTopics from './WaiverTopics'
import DigitalModel from './DigitalModel'
import AmiProgram from './AmiProgram'
import BackToTop from '@/components/shared/BackToTop'
import DetailMixin from './DetailMixin'

const DurationOptions = [
  { text: '1 Year', value: 1 },
  { text: '2 Years', value: 2 },
  { text: '3 Years', value: 3 },
]

export default {
  data() {
    return {
      WaiverTypes
    }
  },
  mixins: [DetailMixin],
  inject: ['$v'],
  computed: {
    ...mapState(Types.path, [
      Types.state.selectedWaiverRequest, 
      Types.state.newDLWaiverRequestsLockedStatus, 
      Types.state.newAMIApplicationsLockedStatus, 
      Types.state.snapshots
    ]),
    leaName() {
      if (this.request?.districtLEA.$model)
        return this.leaDictionary[this.request.districtLEA.$model].name
      
      return null
    },
    durationOptions() {
      return this.isDigitalLearning ? DurationOptions : DurationOptions.filter(d => d.value < 2)
    },
    durationOption() {
      const option = DurationOptions.find(o => o.value == this.$v.request.durationSchoolYears.$model) || { text: `${this.$v.request.durationSchoolYears.$model} Years` }
      return option.text
    },
    durationSchoolYearRange() {
      var start = this.$v.request.$model.fiscalYear + 1990
      var end = start + this.$v.request.durationSchoolYears.$model -1
      return `${start}-${start+1} to ${end}-${end+1}`
    },
    isCharter() {
      return this.leaDictionary[this.selectedWaiverRequest.districtLEA].isCharter
    },
    isDigitalLearning() {
      return this.selectedWaiverRequest.waiverType == WaiverTypes.DigitalLearning.value
    },
    isAMI() {
      return this.selectedWaiverRequest.waiverType == WaiverTypes.AMI.value
    },
  },
  methods: {
    touch() {
      this.$v.request.$touch()
    }
  },
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'digitalLearning',
    },
  },
  components: {
    ProgramAffectedLeas,
    WaiverTopics,
    DigitalModel,
    AmiProgram,
    BackToTop
  },
}
</script>

<style>
.validation-error {
  color: red;
}
</style>
<template>
  <div>
    <h1>Teacher Survey</h1>
    <p>
      Thank you for completing the Ready for Learning teacher survey.  Results from this survey will be used to guide the Division of Elementary and Secondary
      Education's next steps regarding future needs regarding statutes, rules, and professional learning. Responses will not be identifiable by name, school or district.
    </p>
    <b-alert show variant="success">
      <i class="fas fa-thumbs-up mr-2" />Your survey has been submitted to ADE-DESE!
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'teacher-survey-thank-you'
}
</script>

<style>

</style>
<template>
  <div v-if="reportData.data" class="report-output">
    <b-table-simple caption-top>
      <caption>Student Enrollment</caption>
      <b-thead>
        <b-tr>
          <b-th style="width: 375px">
            Grades
          </b-th>
          <b-th v-for="g in reportData.enrollment" :key="g.grade">
            {{ g.grade }}
            <sup>th</sup>
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-th>
            # Students
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ g.totalEnrollment }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-table-simple caption-top fixed>
      <caption>Student Visits Beginning of Year - Report End Date ({{ reportData.stats[0].careerCoachContractStart | date('short-date') }} - {{ reportData.stats[0].reportRangeEnddate | date('short-date') }})</caption>
      <b-tbody>
        <b-tr>
          <b-th style="width: 375px">
            # Total Student Service Visits
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getGradeLevelEnrollmentProperty(g.grade, 'totalVisitsThroughEndDate') }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th style="width: 375px">
            # Student Service Visits
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getGradeLevelEnrollmentProperty(g.grade, 'distinctVisitsThroughEndDate') }}
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-table-simple caption-top fixed>
      <caption>Student Visits for Reporting Date Range ({{ reportData.stats[0].reportRangeBeginDate | date('short-date') }} - {{ reportData.stats[0].reportRangeEnddate | date('short-date') }})</caption>
      <b-tbody>
        <b-tr>
          <b-th style="width: 375px">
            # Total Student Service Visits
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getGradeLevelEnrollmentProperty(g.grade, 'totalVisitsDuringReportingPeriod') }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-th># Student Service Visits</b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            {{ getGradeLevelEnrollmentProperty(g.grade, 'distinctVisitsDuringReportingPeriod') }}
          </b-td>
        </b-tr>          
      </b-tbody>
    </b-table-simple>
    <b-table-simple caption-top fixed>
      <caption>
        Student Services by Type from {{ reportData.stats[0].careerCoachContractStart | date('short-date') }} -
        {{ reportData.stats[0].reportRangeEnddate | date('short-date') }}
      </caption>
      <b-tbody>
        <b-tr v-for="v in VisitTypes" :key="v.key">
          <b-th style="width: 375px">
            {{ v.label }} Service Visits ( # / % )
          </b-th>
          <b-td v-for="g in reportData.enrollment" :key="g.grade">
            <count-vs-percent 
              :item="getCell(g.grade, v.key)" 
              count-prop="uniqueVisitsByTypeYTReportEndDate" 
              percent-prop="percentReached" 
            />
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <visits-by-grade-chart :data="reportData" />
  </div>
</template>

<script>
import VisitsByGradeChart from './VisitsByGradeChart.vue'
import CountVsPercent from '@/modules/cte/components/careerCoach/pages/reports/components/countVsPercent.vue'
import { VisitTypes } from '@/modules/cte/stores/careercoach/reporting'

export default {
  data() {
    return {
      VisitTypes
    }
  },
  components: {
    CountVsPercent,
    VisitsByGradeChart
  },
  props: {
    reportData: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    getGradeLevelEnrollmentProperty(grade, property) {
      return this.reportData.enrollment.find(r => r.grade == grade)[property] || 0
    },
    getCell(grade, topic) {
      let data = (this.reportData.data || []).find(r => r.grade == grade && r.topic == topic) || {}

      return Object.assign(
        {
          uniqueVisitsByTypeYTReportEndDate: 0,
          totalEnrollment: 0,
          percentReached: 0
        },
        data
      )
    }
  }
}
</script>

<style>

</style>
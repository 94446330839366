<template>
  <div>
    <div v-if="narrative">
      <h3>{{ reportName }}</h3>
      <div>
        <router-link tag="button" :to="{name: 'CTE/CareerCoach/Narratives'}" class="float-right mr-2 btn btn-sm btn-info" active-class="active">
          <i class="fa fa-chevron-left mr-2" />Return to Narrative List
        </router-link>
      </div>
      <p class="font-weight-bold">
        Reporting Period: {{ formatNarrativeRange(narrative.range, narrative.reportName) }}
        <br>
        Report Saved on: {{ createdDate }}
      </p>

      <div v-if="reportData" class="report-container mb-3">
        <component :is="reportComponentType" :report-data="reportData" />
      </div>

      <div class="mb-3">
        <label for="narrativeText">Narrative &amp; Comments:</label>
        <b-form-textarea id="narrativeText" v-model="narrative.text" rows="3" max-rows="99" :readonly="readonly" @input="textDirty = true" />
      </div>

      <div>
        <label for="narrativeFiles">Attached Files:</label>
        <narrative-files :files="narrative.files" :narrative-id="narrative.narrativeId" :readonly="readonly" />
      </div>

      <div class="clearfix">
        <router-link tag="button" :to="{name: 'CTE/CareerCoach/Narratives'}" class="btn btn-info" active-class="active">
          <i class="fa fa-chevron-left mr-2" />Return to Narrative List
        </router-link>
        <animated-button class="btn btn-success float-right" :promise-resolver="saveNarrative" :disabled="!textDirty" v-if="!readonly">
          <i class="fas fa-save mr-2" />
          Save Report Narrative
        </animated-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Types } from '../../../stores/careercoach/index.js'
import { ReportTypes } from '../../common/ReportTypes.js'
import { toCamelCase } from '@/helpers/formatters.js'
import store from '@/stores'
import moment from 'moment'
import ReportMonthlyCumulativeSummary from './reports/components/monthlyCumulativeSummary/ReportMonthlyCumulativeSummary.vue'
import NarrativeFiles from '../widgets/narrative/NarrativeFiles.vue'
import AnimatedButton from '@/components/AnimatedButton.vue'

export default {
  data() {
    return {
      narrative: null,
      textDirty: false
    }
  },
  computed: {
    createdDate() {
      return moment(this.narrative.createdDate).format('MM/DD/YYYY h:mm a')
    },
    reportName() {
      return ReportTypes[this.narrative.reportName].name
    },
    reportComponentType() {
      return ReportTypes[this.narrative.reportName].component
    },
    reportData() {
      return JSON.parse(this.narrative.report, toCamelCase)
    }
  },
  props: {
    readonly: {
      type: Boolean,
      default: () => true
    }
  },
  methods: {
    formatNarrativeRange(range, reportName) {
      if (ReportTypes[reportName] && ReportTypes[reportName].rangeFormatter) return ReportTypes[reportName].rangeFormatter(range)
      else return range
    },
    saveNarrative() {
      var self = this
      return this.$store
        .dispatch(Types.actions.updateNarrative, { NarrativeId: this.narrative.narrativeId, Text: this.narrative.text })
        .then(() => (self.textDirty = false))
    }
  },
  mounted() {
    store.dispatch(Types.actions.getNarrative, this.$route.params.narrativeId).then(data => (this.narrative = data))
  },
  components: {
    ReportMonthlyCumulativeSummary,
    NarrativeFiles,
    AnimatedButton
  }
}
</script>

<style lang="scss" scoped>
$insights-standard-blue: #4785c8;

.report-container {
  padding: 10px;
  border-radius: 5px;
  background-color: #dadada;

  & ::v-deep .report-output {
    // background-color: #c34393;
    & table {
      margin-bottom: 20px;
    }
  }

  & ::v-deep table > caption {
    background-color: $insights-standard-blue;
    padding-left: 20px;
    color: darken($insights-standard-blue, 40%);
  }
}
</style>
<template>
  <div>
    <ul class="nav nav-pills flex-column ml-2 mr-2">
      <li class="nav-item ml-2">
        <router-link
          class="nav-link"
          active-class="active"
          :to="{path: '/tools/lea-assignment/schools'}"
        >
          <i class="fa fas fa-fw fa-school" />&nbsp;Schools
        </router-link>
      </li>
      <li
        class="nav-item ml-2"
        v-if="userContext.hasAnyRole(['Insights_System_Administrators','Insights_OIT_Administrators','Insights_CharterSchoolOffice_Administrators','Insights_RT-SMS_Administrators', 'APSCN User','Insights_ADE_Leadership'])"
      >
        <router-link
          class="nav-link"
          active-class="active"
          :to="{path: '/tools/lea-assignment/requests'}"
        >
          <i class="fa fas fa-fw fa-building" /> District LEA Requests
        </router-link>
      </li>
      <li
        class="nav-item ml-2"
        v-if="userContext.hasRole('Insights_PSA_Administrators')"
      >
        <router-link
          class="nav-link"
          active-class="active"
          :to="{path: '/tools/lea-assignment/psa-review', query: {status: 11 }}"
        >
          <i class="far fa-fw fa-comments mr-1" />Pending PSA Review
        </router-link>
      </li>
      <li class="nav-item ml-2" v-if="userContext.hasRole('Insights_PSA_Administrators')">
        <router-link
          class="nav-link"
          active-class="active"
          :to="{path: '/tools/lea-assignment/accountability'}"
        >
          <i class="fa fas fa-fw fa-cog mr-1" />Accountability Administration
        </router-link>
      </li>
      <li class="nav-item ml-2">
        <router-link
          class="nav-link"
          active-class="active"
          :to="{path: '/tools/lea-assignment/assignments'}"
        >
          <i class="fa fas fa-fw fa-check" /> LEA Assignments
        </router-link>
      </li>
      <li class="nav-item ml-2" v-if="userContext.hasRole('Insights_RT-SMS_Administrators')">
        <router-link
          class="nav-link"
          active-class="active"
          :to="{path: '/tools/lea-assignment/leams'}"
        >
          <i class="fa fas fa-fw fa-list" /> LEAMS
        </router-link>
      </li>
      <li class="nav-item ml-2" v-if="userContext.hasRole('Insights_RT-SMS_Administrators')">
        <router-link
          class="nav-link"
          active-class="active"
          :to="{path: '/tools/lea-assignment/sync'}"
        >
          <i class="fa fas fa-fw fa-bolt" /> LEAMS Sync
        </router-link>
      </li>
      <li class="nav-item ml-2" v-if="userContext.hasRole('ADE User')">
        <a href="/content/lea-assignment/documentation/LEA Assignment - ADE User Guide.pdf" class="nav-link" target="_blank"> <i class="fa fas fa-fw fa-question-circle" />ADE User Guide</a>
      </li>
      <li class="nav-item ml-2" v-else>
        <a href="/content/lea-assignment/documentation/LEA Assignment - District User Guide.pdf" class="nav-link" target="_blank"><i class="fa fas fa-fw fa-question-circle" />District User Guide</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'lea-assignment-sidebar',
  computed: {
    ...mapState('globalModule', ['userContext'])
  }
}
</script>

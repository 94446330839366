<template>
  <div>
    <b-table-lite :items="local" :fields="fields">
      <template v-slot:cell(perkinsIndicator)="data">
        <span>
          {{ data.item.code }}: {{ data.item.description }}
        </span>
      </template>
      <template v-slot:cell(priorAchievement)="data">
        <b-badge :variant="data.value > data.item.currentTarget ? 'success' : 'danger'">
          {{ data.value }}
        </b-badge>
      </template>
      <template v-slot:cell(currentTarget)="data">
        <b-badge variant="primary">
          {{ data.value }}
        </b-badge>
      </template>
      <template v-slot:cell(proposalFocus)="data">
        <b-form-group>
          <b-form-checkbox type="checkbox" v-model="data.item.proposalFocus" size="lg" :disabled="isReadonly" />
        </b-form-group>
      </template>
    </b-table-lite>
  </div>
</template>

<script>
import { JsonResponseMixin } from '@/components/JsonResponseMixin'

export default {
  mixins: [JsonResponseMixin],
  data() {
    return {
    }
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: true,
    },
    showFocus: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    fields() {
      let fields = this.showFocus ? [{ key: 'proposalFocus' }] : []
      return fields.concat([
        {
          key: 'perkinsIndicator', 
          class: 'text-left',
        },
        { key: 'priorAchievement',  class: 'text-center' },
        { key: 'currentTarget',  class: 'text-center', tdClass: 'text-primary' },
        { key: 'improvementPlanYear', class: 'text-center', formatter: ay => `${ay+1999}/${ay}` },
      ])
    }
  },
  methods:{
  }
}
</script>
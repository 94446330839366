<template>
  <div>
    <highcharts class="chart expenses-total-chart" ref="actualChart" :options="chartOptions" />
  </div>
</template>

<script>
import Highcharts from 'highcharts'
import { ChartMixin } from '@/components/ChartMixin'
import { mapState } from 'vuex'
import sumBy from 'lodash.sumby'
import { Types } from '@/modules/esa/stores/'
import { LeaTypes } from '@/helpers/leaTypes'

export default {
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'column',
          inverted: true,
          height: 300,
          spacing: [0, 30, 10, 30],
        },
        credits: undefined,
        title: {
          text: '',
        },
        legend: {
          enabled: this.showActual && this.showBudget
        },
        plotOptions: {
          series: {
            minPointLength: 3
          }          
        },
        yAxis: [
          {
            endOnTick: false,
            max: null,
            index: 0,
            title: undefined,
            tickWidth: 2,
            minPadding: 0.2,
            className: 'expenses-total-chart-axis-grid'
          }
        ],
        xAxis: [{
          type: 'category',
          index: 0,
        }],
        series: [],
        exporting: {
          enabled: false
        },
        drilldown: {},
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          formatter: function(e) {
            const description = this.point.description ? `<br/>(${this.point.description})` : ''
            if (e.chart.ddDupes && e.chart.ddDupes.length > 0) 
              return `${this.series.name}${description}<br/>${this.key}: <b>$${Highcharts.numberFormat(this.y, 2).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</b>`
            else
              return `${this.key}${description}<br/>${this.series.name}: <b>$${Highcharts.numberFormat(this.y, 2).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</b>`
          }
        }
      }
    }
  },
  mixins: [ChartMixin],
  props: {
    showActual: {
      type: Boolean,
      required: true
    },
    showBudget: {
      type: Boolean,
      required: true
    },
    drilldown: {
      type: String
    },
    categories: {
      type: Array,
      required: true
    },
    chartTitle: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState(Types.path, [ Types.state.planExpenses ]),
    isOverlay() {
      return (this.showActual && this.showBudget)
    },
    currentCategory() {
      return this.categories.find(category => category.name == this.drilldown)
    },
    totals() {
      return this.categories.filter(c => {
          return this.currentCategory ? this.currentCategory.id == c.id : true
        }).map(category => {
          const categoryExpenses = this.planExpenses.filter(expense => category.programCode.includes(expense.program))
          return {
            name: category.name,
            description: category.description,
            budgeted: sumBy(categoryExpenses, 'budgeted'),
            y: sumBy(categoryExpenses, 'actual'),
            //drilldown: category.id
          }
        })
    },
    isState() {
      return this.selectedLea.number == "AR"
    },
    isCoop() {
      return this.selectedLea.type == LeaTypes.Coop
    }
  },
  watch: {
    planExpenses: {
      immediate: true,
      handler() {
        this.populate()
      }
    },
    drilldown: {
      handler() {
        this.fixDrilldown()
      }
    }
  },
  methods: {
    onDrillDown: function(e) {
      if (e.seriesOptions) this.$emit('drill-down', e.seriesOptions.name)
    },
    onDrillUp: function() {
      this.$emit('drill-up')
    },
    populate() {
      this.chartOptions.series = []
      this.chartOptions.drilldown = { allowPointDrilldown: false, series: [] }
      this.chartOptions.title = { text: this.chartTitle }
      this.chartOptions.chart.events = {
            drilldown: this.onDrillDown,
            drillup: this.onDrillUp
      }
      this.chartOptions.yAxis[0].tickInterval = (this.isState) ? 1000000 : (this.isCoop) ? undefined : 10000
      this.chartOptions.yAxis[0].minTickInterval = (this.isState) ? 1000000 : (this.isCoop) ? undefined : 10000
      this.chartOptions.yAxis[0].softMax = (this.isState) ? 10000000 : (this.isCoop) ? 500000 : 104000

      if (this.showActual) {
        var actualSeries = this.getSeries('Actual', this.totals)
        this.chartOptions.series.push(actualSeries)
      }

      if (this.showBudget) {
          var budgetSeries = this.getSeries('Budget', this.totals.map(b => {
            return { name: b.name, y: b.budgeted, description: b.description, /* drilldown: b.drilldown + '-budget' */ }
          }), '#F2C500')
          this.chartOptions.series.push(budgetSeries)
      }

      this.categories.forEach(category => {
        this.populateDrilldown(category)
      })

      this.fixDrilldown()
    },
    populateDrilldown(category) {
      if (this.showActual) {
        this.chartOptions.drilldown.series.push({
          name: this.isOverlay ? 'Actual Expenses' : category.name,
          description: category.description,
          id: category.id,
          data: category.types.map(type => {
            var y = sumBy(this.planExpenses.filter(expense => type.codes.includes(expense.program)), 'actual')
            return {
              name: type.description,
              y: y
            }
          }),
          categoryName: category.name
        })
      }
      if (this.showBudget) {
        this.chartOptions.drilldown.series.push({
          name: this.isOverlay ? 'Budgeted Expenses' : category.name,
          description: category.description,
          id: category.id + '-budget',
          data: category.types.map(type => {
            var y = sumBy(this.planExpenses.filter(expense => type.codes.includes(expense.program)), 'budgeted')
            return {
              name: type.description,
              y: y
            }
          }),
          categoryName: category.name
        })
      }
    },
    getSeries(name, data, color) {
      return {
        name: name,
        data: data,
        color: color,
        dataLabels: {
          formatter: function () { return '$' + Highcharts.numberFormat(this.y, 0) }
        },
        events: {
          legendItemClick: function(e) {
            e.preventDefault()
          }
        }
      }
    },
    fixDrilldown() {
      this.$nextTick(function () {
        if (this.drilldown && this.drilldown != '') {
          (this.$refs.actualChart.series || []).forEach(series => {
            let point = series.data.find(p => p.categoryName == this.drilldown)
            if (point && point.name) { 
              point.doDrilldown()
            }
          })          
        }
        else {
          this.$refs.actualChart.chart.drillUp()
        }
      })
    },
  }
}
</script>

<style lang="scss">
.expenses-total-chart >>> .highcharts-drillup-button {
  display: none
}

.expenses-total-chart-axis-grid .highcharts-grid-line {
  stroke-width: 2px;
  stroke: #e6e6e6;
  display: inherit !important;
  stroke-dasharray: 2, 2;
}
</style>
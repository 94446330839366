<template>
  <b-tooltip :target="targetSelector">
    <span v-if="lea">
      <lea-icon :lea="lea" :svg-mode="true" /> {{ lea.name }} ({{ lea.number }})
    </span>
  </b-tooltip>
</template>

<script>
import LeaIcon from './LeaIcon.vue'

export default {
  name: 'lea-tooltip',
  computed: {
    lea() {
      return this.$store.getters['globalModule/leaDictionary'][this.leaNumber]
    }
  },
  props: {
    leaNumber: {
      type: String
    },
    targetSelector: {
      type: String
    }
  },
  components: {
    LeaIcon
  }
}
</script>
<template>
  <b-row>
    <district-extract-table-count-chart v-for="count in districtDataTableCounts" :key="count.tableName" :count="count" />
  </b-row>
</template>

<script>
import {mapState} from 'vuex'
import DistrictExtractTableCountChart from "./DistrictExtractTableCountChart.vue"
export default {
  computed:{
    ...mapState('adminModule', ['districtDataTableCounts'])
  },
  components:{
    DistrictExtractTableCountChart
  }
}
</script>
<template>
  <div>
    <b-card no-body>
      <b-tabs card content-class="mt-3">
        <b-tab title="Bus Inspection Details" active>
          <b-card-text>
            <b-alert v-if="SelectedBatch.duplicateBusNumbers && SelectedBatch.duplicateBusNumbers.length > 0" variant="danger" show>
              The following bus number(s) are assigned to multiple buses:
              <ul class="mb-0">
                <li v-for="dupe in SelectedBatch.duplicateBusNumbers" :key="dupe">
                  {{ dupe }}
                </li>
              </ul>
            </b-alert>
            <b-form>
              <div class="form-row">
                <div class="col-6">
                  <b-form-radio-group v-model="selectedType" buttons>
                    <b-form-radio button-variant="primary" value="allInspections">
                      All Buses <span class="badge bg-white text-primary">{{ allInspections.length }}</span>
                    </b-form-radio>
                    <b-form-radio button-variant="success" value="SelectedBatchBusesNoIssue">
                      No Issues <span class="badge bg-white text-success">{{ SelectedBatchBusesNoIssue.length }}</span>
                    </b-form-radio>
                    <b-form-radio button-variant="warning" value="SelectedBatchBusesIssuesInService">
                      Issues but In-Service <span class="badge bg-white text-warning">{{ SelectedBatchBusesIssuesInService.length }}</span>
                    </b-form-radio>
                    <b-form-radio button-variant="danger" value="SelectedBatchBusesIssuesGrounded">
                      Grounded Buses <span class="badge badge-secondary bg-white text-danger">{{ SelectedBatchBusesIssuesGrounded.length }}</span>
                    </b-form-radio>
                  </b-form-radio-group>
                </div>
                <div class="col-6 text-right">
                  <h3>Status: <span class="badge" :class="batchStatusDisplay.badgeClass">{{ batchStatusDisplay.text }}</span></h3>
                </div>
              </div>
              <b-form-group>
                <label>Bus</label>
                <!-- <b-form-select :options="selectedBuses" v-model="selectedInspectionId" text-field="textValue" value-field="inspectionId" />  -->
                <select class="form-control" v-model="selectedInspectionId">
                  <option v-for="i in selectedBuses" :key="i.inspectionId" :style="optionStyle(i)" :value="i.inspectionId">
                    {{ busTextValue (i) }}
                  </option>
                </select>
              </b-form-group>
            </b-form>
            <inspection v-if="selectedInspectionId" :inspection="SelectedInspection" key="bus-inspection" :readonly="readonly" />
          </b-card-text>
        </b-tab>
        <b-tab title="Listing">
          <batch-bus-listing />
        </b-tab>
        <b-tab title="Summary">
          <batch-summary />
        </b-tab>
        <b-tab title="Notifications">
          <notifications />
        </b-tab>
        <template v-slot:tabs-end>
          <div style="position: absolute; right: 10px; top: 10px;">
            <Promised :promise="actionPromise" v-show="actionPromise">
              <template v-slot:pending>
                <button disabled="disabled" @click="exportBatch" class="btn btn-success float-right d-print-none mr-1">
                  <i class="fas fa-spinner fa-spin" />&nbsp;Excel
                </button>
              </template>
              <template v-slot="data">
                <button @click="exportBatch" class="btn btn-success float-right d-print-none mr-1">
                  <i class="far fa-file-excel" />&nbsp;Excel
                  <span class="d-none">
                    {{ data }}
                  </span>
                </button>
              </template>
              <template v-slot:rejected="error">
                <button @click="exportBatch" class="btn btn-success float-right d-print-none mr-1">
                  <i class="fas fa-exclamation-circle" />&nbsp;Excel
                  <span class="d-none">
                    {{ error }}
                  </span>
                </button>
              </template>
            </Promised>
            <button @click="exportBatch" class="btn btn-success float-right d-print-none mr-1" v-show="actionPromise == null">
              <i class="far fa-file-excel" />&nbsp;Excel
            </button>
          </div>
        </template>
      </b-tabs>
    </b-card>
    <div class="alert alert-danger mt-2">
      <ul>
        <li>
          <strong>Rule 8.05</strong>: No school district shall operate any school bus declared “Out of Service” until all repairs required by the out of service notice have been completed and the bus is re-inspected by a Division school bus inspector.
        </li>
        <li>
          <strong>Rule 8.06</strong>: If a school district operates any school bus in violation of Section 8.05 of these Rules, any culpable officer or employee of the district may be subject to the sanction of <strong>Ark. Code Ann. § 6-19-111(d)</strong>.
          <ul>
            <li><strong>6-19-111(d)</strong> Any officer or employee of any school district who violates any of the regulations or fails to include an obligation to comply with the regulations in any contract executed by him on behalf of a school district shall be guilty of misconduct and subject to removal from office or employment.</li>
          </ul>
        </li>
      </ul>
    </div>
    <b-modal id="duplicate-bus-number-modal" title="Duplicate Bus Numbers Present" size="lg">
      Unable to create spreadsheet. Duplicate bus numbers are assigned on this batch.
      <br>Ensure all buses are assigned unique bus numbers to enable spreadhsheet creation.
      <br>
      Duplicate bus numbers:
      <ul>
        <li v-for="dupe in SelectedBatch.duplicateBusNumbers" :key="dupe">
          {{ dupe }}
        </li>
      </ul>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BatchBusListing from './BatchBusListing'
import BatchSummary from './BatchSummary'
import Inspection from '../inspect/Inspection'
import { Types, isBusGrounded } from '../../../../stores/dpsaft/trans'
import { Promised } from 'vue-promised'
import { InspectionBatchStatus, RatingTypes, InspectionStatuses } from '../../common/Enums'
import Notifications from './Notifications.vue'

export default {
  data() {
    return {
      comments: null,
      actionPromise: null,
      selectedType: 'allInspections'
    }
  },
  methods: {
    optionStyle(i) {
      var fw = "normal"

      if (i.status == InspectionStatuses.Completed) {
        fw="bold"
      }

      var color = "black"
      
      if (isBusGrounded(i)) {
          color="red"
      }
      else if (i.components.some(c => c.rating == RatingTypes.Fix)) {
        color="#ffc107"
      } else {
        color = "green"
      }
      
      return `font-weight:${fw};color:${color};`
    },
    busTextValue(bus) {
      var text = ''
      text += `| Bus Number: ${bus.busNumber || '(Not Assigned)'} `
      if(bus.stickerNumber){
        text += `| Sticker Number: ${bus.stickerNumber} `
      }
      if(bus.vin){
        text += `| VIN: ${bus.vin} `
      }
      return text.substr(2, text.length)
    },
    exportBatch() {
      if(this.SelectedBatch.duplicateBusNumbers && this.SelectedBatch.duplicateBusNumbers.length > 0 ){
        this.$bvModal.show('duplicate-bus-number-modal')
      }
      else {
        this.actionPromise = this.$store.dispatch(Types.actions.exportBatch, {
          
          batchId: this.$route.params.batchId,
          fileName: `${this.SelectedBatch.districtName}_transportation_${this.$route.params.batchId}.xlsx`
        })
      }
    },
    save() {
      this.$store.dispatch(Types.actions.saveBatchComment, 
        {batchId: this.$route.params.batchId, comments: this.comments} )
    },
    cancel() {
      this.comments = {...this.SelectedBatch.comments}
    },
  },
  props: {
    mode: {
      type: String,
      default: 'view'
    },
    readonly:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState('DPSAFT/Trans', [Types.state.SelectedBatch, Types.state.SelectedInspection]),
    ...mapGetters('DPSAFT/Trans', [Types.getters.SelectedBatchBusesNoIssue, Types.getters.SelectedBatchBusesIssuesInService, Types.getters.SelectedBatchBusesIssuesGrounded]),
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    },
    allInspections() {
      return this.SelectedBatch.inspections
    },
    selectedBuses() {  
      return this[this.selectedType].map(i => Object.assign(i, { textValue: this.busTextValue(i)}))
    },
    selectedInspectionId: {
      set(inspectionId) {
        this.$store.commit(Types.mutations.setSelectedInspection, this.selectedBuses.find(b => b.inspectionId == inspectionId))
      },
      get() {
        return this.SelectedInspection.inspectionId || 0
      }
    },
    isDirty() {
      return this.comments != this.SelectedBatch.comments
    },
    isBatchComplete() {
      return this.SelectedBatch.status == InspectionBatchStatus.Completed
    },
    mayUserPerformDistrictAcknowledgement() {
      console.log(this.userContext.coordinatorCodes)
      return this.userContext.hasCoordinatorCode("U") || this.userContext.hasCoordinatorCode("X")
    },
    mayUserPerformDistrictNotification() {
      return this.userContext.hasRole("Insights_DPSAFT_Administrators") || this.userContext.hasRole("Insights_DPSAFT_Transportation_Administrators") || this.userContext.hasRole("Insights_DPSAFT_Transportation_Inspectors") || this.userContext.hasRole("Insights_DPSAFT_Transportation_Trainers")
    },
    batchStatusDisplay() {
      return InspectionBatchStatus.DisplayOptions(this.SelectedBatch.status)
    }
  },
  components: {
    BatchBusListing,
    BatchSummary,
    Inspection,
    Promised,
    Notifications
  },
  mounted(){
    if (this.$route.query.process == 'start') {
      this.$store.dispatch(Types.actions.updateBatchStatus, { batchId: this.$route.params.batchId, status: InspectionBatchStatus.InProgress })
    } else {
      this.$store.dispatch(Types.actions.getBatch, { batchId: this.$route.params.batchId })
    }
    this.$store.dispatch(Types.actions.getBatchSummary, { batchId: this.$route.params.batchId })
  },
  watch:{
    SelectedBatch(batch) {
      this.comments = batch.comments
    }
  }
}
</script>
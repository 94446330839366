<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { credits } from '../../../helpers/credits'
export default {
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'spline'
        },
        plotOptions: {
          column: {
            stacking: undefined
          }
        },
        credits: credits.SIS,
        title: {
          text: this.getTitle()
        },
        xAxis: {
          categories: [],
          title: {
            text: 'Age Range',
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Number of Teachers'
          }
        }
      }
    }
  },
  mixins: [ChartMixin],
  props:{
    fiscalYear: Number
  },
  computed: {
    reportData() {
      return this.$store.state.hcptModule.overviewData.ageStatistics
    }
  },
  watch: {
    reportData(newData) {
      if (newData) 
      {
        this.chartOptions.series = []

        for (var year in newData) {
          var yearData = newData[year]
          var data = []
          yearData.forEach(a => {
            this.chartOptions.xAxis.categories.push(a.ageRange)
            data.push([a.ageRange, a.ageCount])
          })
          
          this.chartOptions.series.push({
            name: parseInt(year) + 1990,
            data: data
          })
        }
      }
    }
  },
  methods: {
    getTitle(){
      return `Teacher Age Distribution`
    },
    dataItems() {
      var items = []
      for (var year in this.reportData)
      {
        var yearData = this.reportData[year]
        for (var ageRange in yearData)
        {
          // console.log(ageRange)
          items.push({ Year: parseInt(year) + 1990, Age: yearData[ageRange].ageRange, Count: yearData[ageRange].ageCount })
        }
      } 
      return items
    }
  }
}
</script>
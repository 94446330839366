<template>
  <div>
    <h1>{{ selectedWaiverType.listTitle }}</h1>
    <h4 class="text-primary">
      School Year 2022-2023
    </h4>
    <h4>Application for Digital Learning Waivers and/or Alternative Methods of Instruction (AMI)</h4>
    <waiver-request-list-widget :context="Context.Public" @selected-waiver-type-changed="selectedWaiverTypeChanged" />
  </div>
</template>

<script>
import WaiverRequestListWidget from './list/WaiverRequestListWidget.vue'
import { PageMixin } from '@/components/PageMixin'
import { Context } from '@/stores/digitalLearning'

export default {
    data() {
    return {
      resolvedLea: Object,
      selectedWaiverType: {},
      Context
    }
  },
  methods: {
    selectedWaiverTypeChanged(waiverType) {
      this.selectedWaiverType = waiverType
    }
  },
  mixins: [PageMixin],
  components: {
    WaiverRequestListWidget,
  }
}
</script>
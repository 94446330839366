import { mapState } from 'vuex'
import { Types } from '@/modules/cte/stores/perkinsv'
import { ProposalStates } from '@/modules/cte/stores/perkinsvStateMap'
import sumBy from 'lodash.sumby'
import { round } from '@/helpers/mathHelpers'

export default {
  methods: {
    //perkinsCostItemTotal: costItems => costItems.map(ci => ci.quantity * ci.costPerUnit).reduce((a, b) => a + b, 0),
    perkinsCostItemTotal: costItems => round(sumBy(costItems, c=>c.perkins || 0), 2),
    //requiredCostItemTotal: costItems => costItems.map(ci => ci.quantity * ci.costPerUnit).reduce((a, b) => a + b, 0),
  },
  computed:{
    ...mapState(Types.path, [Types.state.selectedApplication, Types.state.selectedProposal, Types.state.applicantFundingLimit]), 
    proposalCostItems() {
      return this.selectedProposal.proposalCosts
    },
    siblingProposalCostItems() {
      return this.selectedApplication.proposals.filter(p => p.proposalId != this.selectedProposal.proposalId && this.selectedProposal.status <= ProposalStates.Approved).flatMap(p => p.proposalCosts)
    },
    otherFundsUsedThisProposal() {
      return this.proposalCostItems.length ? sumBy(this.proposalCostItems, c=> (c.partner || 0) + (c.district || 0) + (c.startup || 0)) : 0
    },
    perkinsFundsUsedThisProposal() {
      return this.proposalCostItems.length ? sumBy(this.proposalCostItems, c=> (c.perkins || 0)) : 0
    },
    totalFundsUsedThisProposal() {
      return this.otherFundsUsedThisProposal + this.perkinsFundsUsedThisProposal
    },
    proposalCost() {
      return this.proposalCostItems.length ? this.perkinsCostItemTotal(this.proposalCostItems) : 0
    },
    perkinsFundsUsedSiblingProposals() {
      return this.siblingProposalCostItems.length ? round(sumBy(this.siblingProposalCostItems, c=> (c.perkins || 0)), 2) : 0
    },
    siblingProposalCost() {
      return this.siblingProposalCostItems.length ? this.perkinsCostItemTotal(this.siblingProposalCostItems) : 0
    }
  }
}
<template lang="html">
  <div>
    <lea-growth-trend-chart-container :subject="subject" :load-data="loadData" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LeaGrowthTrendChartContainer from './LeaGrowthTrendChartContainer'

export default {
  name: 'lea-growth-trend',
  data() {
    return {
        loadData: null
    }
  },
  components: {
    LeaGrowthTrendChartContainer
  },
  computed: {
    ...mapState('academicsModule/growth', ['subject','grade', 'subgroup']),
    fiscalYear() {
      return this.$store.state.academicsModule.growth.fiscalYear
    }
  },
  methods: {
    refresh() {
      //console.log('refresh trends')
      if (this.fiscalYear > 0) {
        this.loadData = Promise.all([
          this.$store.dispatch('academicsModule/growth/getLeaGrowthTrend',{ lea: this.selectedLea.number }),
          this.$store.dispatch('academicsModule/growth/getStudentGrowth', { lea: this.selectedLea.number }),
          this.$store.dispatch('academicsModule/growth/getLeaGrowthTrendPivot', { lea: this.selectedLea.number }),
        ])
      }
      else this.loadData = Promise.resolve()
    }
  },
  mounted() {
    //console.log('LEA Growth Trend mounted')
    this.refresh()
  },
  watch: {
    grade() {
      this.refresh()
    },
    subgroup() {
      this.refresh()
    },
    subject() {
      this.refresh()
      window.dispatchEvent(new Event('resize'))
    },
    selectedLea() {
      this.refresh()
    },
    fiscalYear() {
      this.refresh()
    }
  }
}
</script>
<template>
  <div class="card bg-light">
    <div class="card-header lead">
      Student Supports
    </div>
    <a class="anchor" id="student-supports" />
    <b-tabs class="m-3" content-class="mt-1" pills>
      <b-tab title="All Students" active title-item-class="d-print-none">
        <div class="row">
          <div class="col-4 mx-0 pr-0">
            <single-metric-gauge-chart title="Special Education Enrollment" :report-data="currentSpecialEducation" metric-suffix="%" :load-data="loadData" :credits="MSICredits" />
          </div>
          <div class="col-8 mx-0 pl-0">
            <single-metric-schools-chart title="Special Education Enrollment" :report-data="currentSpecialEducation" metric-suffix="%" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row page-break-before">
          <div class="col-4 mx-0 pr-0">
            <single-metric-gauge-chart title="English as a Second or Foreign Language" :report-data="currentEnglishLearners" metric-suffix="%" :load-data="loadData" :credits="MSICredits" />
          </div>
          <div class="col-8 mx-0 pl-0">
            <single-metric-schools-chart title="English as a Second or Foreign Language" :report-data="currentEnglishLearners" metric-suffix="%" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row ">
          <div class="col-4 mx-0 pr-0">
            <single-metric-gauge-chart title="Gifted and Talented" :report-data="currentGiftedTalented" metric-suffix="%" :load-data="loadData" :credits="MSICredits" />
          </div>
          <div class="col-8 mx-o pl-0">
            <single-metric-schools-chart title="Gifted and Talented" :report-data="currentGiftedTalented" metric-suffix="%" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row page-break-before">
          <div class="col-12">
            <single-metric-schools-chart title="Students Receiving Dyslexia Therapy" :report-data="currentDislexia" :decimal-precision="0" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <single-metric-schools-chart title="Avg. Expenditure per Student" :is-currency="true" :report-data="currentExpenditurePerStudent" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row page-break-before">
          <div class="col-12">
            <single-metric-schools-chart title="Attendance Rate" subgroup="All Students" :report-data="currentAttendanceRate" metric-suffix="%" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <single-metric-schools-chart title="Student Engagement" subgroup="All Students" :report-data="currentChronicAbsence" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row" v-for="(option, index) in ['numberOfStudentsTakingAPCourses', 'numberOfAPExamsTaken', 'numberOfAPExamsScored3OrAbove']" :key="'apmetrics' + option" :class="{ 'page-break-before': index % 2 == 0 }">
          <div class="col-12">
            <single-metric-schools-chart :title="startCase(option)" :field="option" :report-data="currentApMetrics" :load-data="loadData" :decimal-precision="0" :key="'apmetrics-' + option" :credits="MSICredits" />
          </div>
        </div>
        <div class="row page-break-before">
          <div class="col-12">
            <single-metric-schools-chart title="Average Daily Membership (3Q)" :report-data="currentThirdQuarterAvgDailyMembership" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <single-metric-schools-chart chart-title="Foundation Funding" title="Amount" :is-currency="true" :report-data="foundationFunding" mode="trend" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
      </b-tab>
      <b-tab title="Trend" title-item-class="d-print-none">
        <div class="row page-break-before">
          <div class="col-12 page-break-before">
            <single-metric-schools-chart title="Special Education Enrollment" :report-data="specialEducation" mode="trend" metric-suffix="%" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <single-metric-schools-chart title="English as a Second or Foreign Language" :report-data="englishLearners" mode="trend" metric-suffix="%" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row page-break-before">
          <div class="col-12">
            <single-metric-schools-chart title="Gifted and Talented" :report-data="giftedAndTalented" mode="trend" metric-suffix="%" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>    
        <div class="row">
          <div class="col-12">
            <single-metric-schools-chart title="Students Receiving Dyslexia Therapy" :report-data="dislexia" mode="trend" :decimal-precision="0" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>    
        <div class="row page-break-before">
          <div class="col-12">
            <single-metric-schools-chart title="Avg. Expenditure per Student" :is-currency="true" :report-data="expenditurePerStudent" mode="trend" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <single-metric-schools-chart title="Attendance Rate" subgroup="All Students" :report-data="attendanceRate" mode="trend" metric-suffix="%" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row page-break-before">
          <div class="col-12">
            <single-metric-schools-chart title="Student Engagement" subgroup="All Students" :report-data="chronicAbsence" mode="trend" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row" v-for="(option, index) in ['numberOfStudentsTakingAPCourses', 'numberOfAPExamsTaken', 'numberOfAPExamsScored3OrAbove']" :key="'apmetrics' + option + 'trend'" :class="{ 'page-break-before': index % 2 == 0 }">
          <div class="col-12">
            <single-metric-schools-chart :title="startCase(option)" :field="option" :report-data="apMetrics" :load-data="loadData" :decimal-precision="0" mode="trend" :key="'apmetrics-' + option" :credits="MSICredits" />
          </div>
        </div>
        <div class="row page-break-before">
          <div class="col-12">
            <single-metric-schools-chart title="Average Daily Membership (3Q)" :report-data="thirdQuarterAvgDailyMembership" mode="trend" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <single-metric-schools-chart chart-title="Foundation Funding" title="Amount" :is-currency="true" :report-data="foundationFunding" mode="trend" :load-data="loadData" :credits="MSICredits" />
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { credits } from '@/helpers/credits'
import startCase from 'lodash.startcase'
import { Types } from '@/modules/esa/stores/survey'
import SingleMetricGaugeChart from '../SingleMetricGaugeChart.vue'
import SingleMetricSchoolsChart from '../SingleMetricSchoolsChart.vue'

export default {
  data() {
    return { 
      loadData: null,
      MSICredits: credits.MSI,
      startCase,
    }
  },
  computed: {
    ...mapState(Types.path, [
      Types.state.specialEducation, 
      Types.state.englishLearners,
      Types.state.giftedAndTalented,
      Types.state.instructionalOptionsCycle2,
      Types.state.instructionalOptionsCycle7,
      Types.state.expenditurePerStudent,
      Types.state.chronicAbsence,
      Types.state.thirdQuarterAvgDailyMembership,
      Types.state.foundationFunding,
      Types.state.attendanceRate,
      Types.state.dislexia,
      Types.state.apMetrics,
    ]),
    currentSpecialEducation() {
      const maxFy = this.getCurrentFiscalYear(this.specialEducation, 'value')
      return this.specialEducation.filter(m => m.fy == maxFy)
    },
    currentEnglishLearners() {
      const maxFy = this.getCurrentFiscalYear(this.englishLearners, 'value')
      return this.englishLearners.filter(m => m.fy == maxFy)
    },
    currentExpenditurePerStudent() {
      const maxFy = this.getCurrentFiscalYear(this.expenditurePerStudent, 'value')
      return this.expenditurePerStudent.filter(m => m.fy == maxFy)
    },
    currentDislexia() {
      const maxFy = Math.max(...this.dislexia.filter(m => m.value > 0).map(m => m.fy))
      return this.dislexia.filter(m => m.fy == maxFy)
    },
    currentApMetrics() {
      const maxFy = Math.max(...this.apMetrics.filter(m => m.numberOfStudentsTakingAPCourses > 0).map(m => m.fy))
      return this.apMetrics.filter(m => m.fy == maxFy)
    },
    currentChronicAbsence() {
      const maxFy = Math.max(...this.chronicAbsence.filter(m => m.value > 0).map(m => m.fy))
      return this.chronicAbsence.filter(m => m.fy == maxFy)
    },
    currentGiftedTalented(){
      const maxFy = Math.max(...this.giftedAndTalented.filter(m => m.value > 0).map(m => m.fy))
      return this.giftedAndTalented.filter(m => m.fy == maxFy)
    },
    currentThirdQuarterAvgDailyMembership(){
      const maxFy = Math.max(...this.thirdQuarterAvgDailyMembership.filter(m => m.value > 0).map(m => m.fy))
      return this.thirdQuarterAvgDailyMembership.filter(m => m.fy == maxFy)
    },
    currentFoundationFunding() {
      const maxFy = Math.max(...this.foundationFunding.filter(m => m.value > 0).map(m => m.fy))
      return this.foundationFunding.filter(m => m.fy == maxFy)
    },
    currentAttendanceRate() {
      const maxFy = Math.max(...this.attendanceRate.filter(m => m.value > 0).map(m => m.fy))
      return this.attendanceRate.filter(m => m.fy == maxFy)
    },
  },
  components: {
    SingleMetricGaugeChart,
    SingleMetricSchoolsChart,
  },
  methods: {
    getCurrentFiscalYear(values, field) {
      if (values.length == 0) return 0
      return Math.max(...values.filter(m => m.lea == 'AR' && m[field] >= 0).map(m => m.fy))
    }
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if (newLea && newLea.number) {
          this.loadData = Promise.all([
            this.$store.dispatch(Types.actions.getSpecialEducation, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getEnglishLearners, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getExpenditurePerStudent, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getChronicAbsence, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getGiftedAndTalented, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getThirdQuarterAvgDailyMembership, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getFoundationFunding, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getAttendanceRate, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getDislexia, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getApMetrics, { lea: newLea.number }),
          ])
        }
      }
    }
  }
}

</script>

  
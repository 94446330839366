import ApiService from '../services/ApiService'

export class ScheduleModule {
  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl =
      this._configElement == null
        ? '/'
        : this._configElement.getAttribute('data-base-url') || ''
    this._fiscalYear = this._configElement.getAttribute('data-fiscal-year')
  }

  namespaced = true

  state = {
    masterSchedule: [],
    teachers: [],
    courseEnrollment: [],
    unqualifiedCourses: [],
    selectedTeacher: null,
    selectedCourse: null,
    selectedStudent: null,
    studentCourses: [],
    studentTeachers: [],
    hideInactive: false,
    classSizeDetails: [],
    teacherLoadDetails: [],
    showOnlyViolations: true,
    markingPeriod: null,
    qsTeacherId: null,
    qsCourseSection: null,
    qsMarkingPeriod: null
  }

  getters = {
    masterSchedule(state) {
      return state.masterSchedule
    },
    teachers(state) {
      return state.teachers
    },
    courseEnrollment(state) {
      return state.courseEnrollment
    },
    unqualifiedCourses(state) {
      return state.unqualifiedCourses
    },
    selectedTeacher(state) {
      return state.selectedTeacher
    },
    selectedCourse(state) {
      return state.selectedCourse
    },
    selectedStudent(state) {
      return state.selectedStudent
    },
    studentCourses(state) {
      return state.studentCourses
    },
    studentTeachers(state) {
      return state.studentTeachers
    },
    hideInactive(state) {
      return state.hideInactive
    },
    qsTeacherId(state) {
      return state.qsTeacherId
    },
    qsCourseSection(state) {
      return state.qsCourseSection
    },
    qsMarkingPeriod(state) {
      return state.qsMarkingPeriod
    },
    displayCourses(state) {
      var returnValue = state.masterSchedule

      if (state.selectedStudent) {
        returnValue = state.studentCourses
      }

      if (state.hideInactive) {
        returnValue = returnValue.filter(c => c.markingPeriodStatus == 'A')
      }

      if (state.markingPeriod){
        returnValue = returnValue.filter(c => c.markingPeriod == state.markingPeriod)
      }

      if (state.selectedTeacher) {
        returnValue = returnValue.filter(
          row => row.uniq_emp_id == state.selectedTeacher.uniq_emp_id
        )
      }
      return returnValue
    },
    displayTeachers(state) {
      if (
        !state.selectedTeacher &&
        !state.selectedCourse &&
        state.selectedStudent
      )
        return state.studentTeachers
      return state.teachers
    }
  }

  mutations = {
    setMasterSchedule(state, data) {
      state.masterSchedule = Array.from(data)

      var teachers = state.masterSchedule.map(s => {
        return {
          teacherName: s.teacherName,
          uniq_emp_id: s.uniq_emp_id,
          degree: s.degree,
          emp_status: s.emp_status
        }
      })

      // reduce the teachers list to only distinct teachers
      state.teachers = []
      var unique = {}
      teachers.forEach(function(x) {
        if (!unique[x.uniq_emp_id]) {
          state.teachers.push(x)
          unique[x.uniq_emp_id] = true
        }
      })
    },
    setCourseEnrollment: (state, data) =>
      (state.courseEnrollment = Array.from(data)),
    setSelectedTeacher: (state, data) => (state.selectedTeacher = data),
    setSelectedCourse: (state, data) => (state.selectedCourse = data),
    setQsTeacherId: (state, data) => (state.qsTeacherId = data),
    setQsMarkingPeriod: (state, data) => (state.qsMarkingPeriod = data),
    setQsCourseSection: (state, data) => (state.qsCourseSection = data),
    setSelectedStudent: (state, data) => (state.selectedStudent = data),
    setUnqualifiedCourses: (state, data) => (state.unqualifiedCourses = data),
    setHideInactive: (state, data) => (state.hideInactive = data),
    setStudentCourses(state, data) {
      var sectionNums = Array.from(data)
      state.studentCourses = state.masterSchedule.filter(function(c) {
        return sectionNums.some(
          s =>
            s.sectionNumber == c.section_num &&
            s.markingPeriod == c.markingPeriod
        )
      })

      var studentTeachers = state.studentCourses.map(s => {
        return {
          teacherName: s.teacherName,
          uniq_emp_id: s.uniq_emp_id,
          degree: s.degree,
          emp_status: s.emp_status
        }
      })

      // reduce the teachers list to only distinct teachers
      state.studentTeachers = []
      var unique = {}
      studentTeachers.forEach(function(x) {
        if (!unique[x.uniq_emp_id]) {
          state.studentTeachers.push(x)
          unique[x.uniq_emp_id] = true
        }
      })
    },
    setClassSizeDetails: (state, data) => state.classSizeDetails = data,
    setTeacherLoadDetails: (state, data) => state.teacherLoadDetails = data,
    setShowOnlyViolations: (state, data) => state.showOnlyViolations = data
  }

  actions = {
    getMasterSchedule: ({ commit }, payload) => {
      return this._apiService
        .Get(
          encodeURI(
            `${this._baseUrl}api/masterschedule/${payload.lea}/${this._fiscalYear}/${payload.teacherId || ''}`
          )
        )
        .then(scheduleData => commit('setMasterSchedule', scheduleData))
    },
    getTeacherEnrollment: ({ commit }, payload) => {
      var url = `${this._baseUrl}api/masterschedule/enrollment/${payload.lea}/${
        this._fiscalYear
      }/${payload.teacher.uniq_emp_id.trim()}`
      if(payload.markingPeriod){
        url += `/${payload.markingPeriod}`
        this.state.markingPeriod =  payload.markingPeriod
      }
      return this._apiService
        .Get(encodeURI(url))
        .then(enrollmentData => commit('setCourseEnrollment', enrollmentData))
    },
    getUnqualifiedCourses: ({ commit }, payload) => {
      return this._apiService
        .Get(
          encodeURI(
            `${this._baseUrl}api/masterschedule/${payload.lea}/${this._fiscalYear}/unqualifiedCourses`
          )
        )
        .then(unqualifiedCourses =>
          commit('setUnqualifiedCourses', unqualifiedCourses)
        )
    },
    setSelectedStudent: ({ commit, dispatch }, payload) => {
      commit('setSelectedStudent', payload.student)
      dispatch('getStudentCourseSections', payload)
    },
    getStudentCourseSections: ({ commit }, payload) => {
      return this._apiService
        .Get(
          encodeURI(
            `${this._baseUrl}api/masterschedule/studentcourses/${payload.lea}/${this._fiscalYear}/${payload.student.studentId}`
          )
        )
        .then(studentCourses => commit('setStudentCourses', studentCourses))
    },
    getClassSizes: ({ commit }, payload) => {
      return this._apiService.Get(
          encodeURI(`${this._baseUrl}api/masterschedule/${payload.lea}/classSize`)
        )
        .then(classSizes => commit('setClassSizeDetails', classSizes))
    },
    getTeacherLoadDetails: ({ commit }, payload) => {
      return this._apiService.Get(
          encodeURI(`${this._baseUrl}api/masterschedule/${payload.lea}/teacherLoad`)
        )
        .then(teacherLoadDetails => commit('setTeacherLoadDetails', teacherLoadDetails))
    }
  }
}

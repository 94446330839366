import Vue from 'vue'
import { TableMixin } from '../../TableMixin'

const ClassesMissingCaseId = Vue.extend({
    name: 'classes-missing-case-id',
    /* html */
    template: `<div class="chart">
    <table-control-widget :per-page="tableOptions.perPage" v-on:filter-changed="tableOptions.filter=$event" v-on:page-length-changed="tableOptions.perPage=$event">
        </table-control-widget>
        <b-table striped 
        :fields="tableOptions.fields" 
        :per-page="tableOptions.perPage" 
        :items="classesWithoutCaseId"
        v-on:filtered="onFiltered"
            :current-page="tableOptions.currentPage" :filter="tableOptions.filter" :busy="tableOptions.isLoading" api-url="api/hcpt/data-integrity/classes-missing-case-ids">
            <template v-slot:cell(lea)="data">
                <a href="#" v-on:click="goToLea(data.value)" v-bind:id="data.index + '-' + data.value">{{ data.value }}</a>
                <lea-tooltip v-bind:target-selector="data.index + '-' + data.value"
                    v-bind:lea-number="data.value">
                </lea-tooltip>
            </template>
            <div slot="table-busy" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
            </div>
        </b-table>
        <table-pagination-widget :total-rows-count="totalRowCount" :filtered-rows-count="tableOptions.filteredRowCount" 
        :page-length="tableOptions.perPage" v-on:page-changed="tableOptions.currentPage=$event"></table-pagination-widget>
    </div>`,
    data() {
        return {
            tableOptions: {
                currentPage: 1,
                perPage: this.$printMode ? this.MAX_SAFE_INTEGER : 50,
                filter: null,
                fields: [
                    {
                        key: 'lea',
                        label: 'LEA',
                        sortable: true
                    },
                    {
                        key: 'teacherName',
                        label: 'Teacher Name',
                        sortable: true
                    },
                    {
                        key: 'courseNumber',
                        label: 'Course Number',
                        sortable: true
                    },
                    {
                        key: 'fullCourseDescription',
                        label: 'Course Details',
                        sortable: true
                    },
                    {
                        key: 'ssn',
                        label: 'SSN',
                        sortable: true
                    }
                ],
                filteredRowCount: null,
                isLoading: false
            }
        }
    },
    mixins: [TableMixin],
    computed: {
        classesWithoutCaseId() {
            return this.$store.state.hcptModule.dataIntegrity.classesWithoutCaseId
        },
        totalRowCount(){
            return this.$store.state.hcptModule.dataIntegrity.classesWithoutCaseId.length
        }
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.tableOptions.filteredRowCount = filteredItems.length
            this.currentPage = 1
        },
        goToLea(leaNumber) {
            const lea = this.$store.getters['globalModule/leaDictionary'][leaNumber]
            window.scrollTo(0,0)
            this.$store.dispatch('globalModule/changeSelectedLea', lea)
        },
        excelExportData() {
            return {
                name: 'Classes Missing CaseId',
                items: this.$store.state.hcptModule.dataIntegrity.classesWithoutCaseId.map(item => {
                    return { 
                        LEA: item.lea,
                        TeacherName: item.teacherName,
                        CourseNumber: item.courseNumber,
                        FullCourseDescription: item.fullCourseDescription,
                        SSN: item.ssn
                    }
                })
            }
        }
    },
    props: {
        selectedLea: Object
    }
})
export { ClassesMissingCaseId }
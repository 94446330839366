<template>
  <div class="card card-body">
    <paginated-table hover
                     selectable
                     select-mode="single"
                     @pt:row-selected="onSelected"
                     :items="checkableItems"
                     :fields="operativeFields"
                     :load-data="loadData">
      <template v-slot:info-header>
        <i class="pull-right text-info">* {{ inspectionInfo }}</i>
      </template>
      <template v-slot:head(action)>
        <div>
          <div v-if="allowItemCheck && mayAddInspection">
            <div v-if="showAddButton && checkedItems && allowAddInspectionForCheckedItems && checkedItems.length > 0">
              <b-dropdown right variant="primary" size="sm">
                <template v-slot:button-content>
                  <i class="fa fa-fw fa-plus-circle" /> Add
                </template>
                <b-dropdown-item
                  href="#"
                  @click="$emit('add-items', {target: 'default', items: checkedItems})">
                  Add to Inspection
                </b-dropdown-item>
                <b-dropdown-item
                  href="#"
                  @click="$emit('add-items', {target: 'new-inspection', items: checkedItems})">
                  Add to New Inspection
                </b-dropdown-item>
                <b-dropdown-item
                  href="#"
                  @click="$emit('add-items', {target: 'existing-inspection', items: checkedItems})">
                  Add to Existing Inspection
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div v-else>
              Selected
            </div>
            <div>
              <b-button variant="link" title="Select All" size="xs" @click="selectAll">
                All
              </b-button>|
              <b-button variant="link" title="Select None" size="xs" @click="selectNone">
                None
              </b-button>
            </div>
          </div>
          <div v-else>
            History
          </div>
        </div>
      </template>
      <template v-slot:cell(action)="data">
        <slot name="action-column-content" v-bind="data.item" />
        <b-checkbox v-if="allowItemCheck" v-model="data.item.checked" />
      </template>
      <template v-slot:head(schoolDepSqFt)>
        <div>
          <span>
            School ft
            <sup>2</sup>
          </span>
        </div>
      </template>
      <template v-slot:cell(districtLEA)="data">
        <div>
          <span :id="data.index + '-' + data.value">
            {{ data.value }}
          </span>
          <lea-tooltip :target-selector="data.index + '-' + data.value" :lea-number="data.value" />
        </div>
      </template>
      <template v-slot:cell(schoolNumber)="data">
        <div>
          <span :id="data.index + '-' + data.value">
            {{ data.value }}
          </span>
          <lea-tooltip :target-selector="data.index + '-' + data.value" :lea-number="data.value" />
        </div>
      </template>
      <template v-slot:cell(academicSpace)="data">
        <div class="text-center">
          <span class="fa" :class="{'text-success fa-check-circle': data.value, 'text-danger fa-times-circle': !data.value}" />
        </div>
      </template>
      <template v-slot:cell(latestInspection.components)="data">
        <div>
          <span class="badge badge-warning" v-if="data.value && data.value.some(c => c.rating == RatingTypes.Fix)">FIX</span>
          <span class="badge badge-success" v-else-if="data.value && data.value.every(c => c.rating == RatingTypes.OK || c.rating == RatingTypes.NotApplicable || c.rating == RatingTypes.NotChecked)">OK</span>
          <span class="badge badge-secondary" v-else-if="data.item.latestInspection.status == InspectionStatuses.Skipped">PENDING</span>
          <span class="badge badge-secondary" v-else>PENDING</span>
        </div>
      </template>
      <template v-slot:cell(components)="data">
        <div>
          <span class="badge badge-warning" v-if="data.value && data.value.some(c => c.rating == RatingTypes.Fix)">FIX</span>
          <span class="badge badge-success" v-else-if="data.value && data.value.every(c => c.rating == RatingTypes.OK || c.rating == RatingTypes.NotApplicable || c.rating == RatingTypes.NotChecked)">OK</span>
          <span class="badge badge-secondary" v-else-if="data.item.status == InspectionStatuses.Skipped">PENDING</span>
          <span class="badge badge-secondary" v-else>PENDING</span>
        </div>
      </template>
      <template v-slot:cell(latestInspection.status)="data">
        <div>
          <span class="badge" :class="inspectionStatusDisplayOptions(data.value).badgeClass"><i class="fa-fw" :class="inspectionStatusDisplayOptions(data.value).iconClass" /> {{ inspectionStatusDisplayOptions(data.value).text }}</span>
        </div>
      </template>
      <template v-slot:cell(status)="data">
        <div>
          <span class="badge" :class="inspectionStatusDisplayOptions(data.value).badgeClass"><i class="fa-fw" :class="inspectionStatusDisplayOptions(data.value).iconClass" /> {{ inspectionStatusDisplayOptions(data.value).text }}</span>

          &nbsp;
          <b-badge variant="secondary" v-if="data.value == InspectionStatuses.NotStarted">
            Not Started
          </b-badge>
          <b-badge variant="primary" v-if="data.value == InspectionStatuses.InProgress">
            In Progress
          </b-badge>

          <b-badge variant="secondary" v-if="data.value == InspectionStatuses.Completed && hasNoComponentsChecked(data.item)">
            Not Checked
          </b-badge>

          <b-badge variant="warning" v-if="data.value == InspectionStatuses.Completed && hasFixComponents(data.item)">
            1+ Issues
          </b-badge>
          
          <b-badge variant="success" v-if="data.value == InspectionStatuses.Completed && !hasFixComponents(data.item) && !hasNoComponentsChecked(data.item)">
            No Issues
          </b-badge>
        </div>
      </template>
      <template v-slot:cell(latestInspection.completionDate)="data">
        <div>
          <span v-if="data.value">{{ formatDate(data.value) }}</span>
          <span v-else>N/A</span>
        </div>
      </template>
    </paginated-table>
  </div>
</template>

<style lang='scss'>
.contact-card {
  margin-top: 1.5em;
}
</style>

<script>
import { RatingTypes } from '../common/Enums'
import { TableMixin } from '../../TableMixin'
import { DpsaftListMixin } from './DpsaftListMixin'

export default {
  mixins: [DpsaftListMixin, TableMixin],
  data() {
    return {
      listItemType: 'building',
      fields: [
        {
          key: 'districtLEA',
          sortable: true
        },
        {
          key: 'schoolNumber',
          sortable: true
        },
        {
          key: 'buildingNumber',
          sortable: true
        },
        {
          key: 'buildingName',
          sortable: true
        },
        {
          key: 'academicSpace',
          sortable: true,
          thClass: 'text-center'
        },
        {
          key: 'constructionYear',
          sortable: true
        },
        {
          key: 'schoolDepSqFt',
          sortable: true
        },
        {
          key: 'buildingDepValue',
          label: 'Depreciated Building Value',
          sortable: true,
          formatter(value) {
            return `${value}%`
          }
        },
        {
          key: 'latestInspection.status',
          label: `Inspection Status *`,
          sortable: true,
        },
        {
          key: 'latestInspection.completionDate',
          label: 'Inspection Date *',
          sortable: true
        }
      ]
    }
  },
  methods: {
    hasFixComponents(inspection) {
      return inspection.components.some(c => c.rating == RatingTypes.Fix)
    }
  },
  computed: {
    mayAddInspection() {
      return this.userContext.hasAnyRole(['Insights_DPSAFT_Administrators', 'Insights_DPSAFT_Facilities_Administrators', 'Insights_DPSAFT_Facilities_Area_Managers_Maintenance', 'Insights_DPSAFT_Facilities_Area_Managers_Planning_Construction'])
    },
    operativeFields() {
      let fields = this.fields
      if (this.showLatestInspection) {
        fields.push(
          {
            key: 'latestInspection.status',
            label: 'Inspection Status *',
            sortable: true
          },
          {
            key: 'latestInspection.completionDate',
            label: 'Inspection Date *',
            sortable: true
          }
        )
      }
      if (this.showBatchStatus) {
        fields.push({
          key: 'components',
          label: 'Building Status *',
        },
        {
          key: 'status',
          label: 'Inspection Status *',
          sortable: true
        })
      } 
      else {
        fields.push({
          key: 'yearLastInspected',
          label: 'Year Last Inspected',
          sortable: true
        })
      }

      if (this.showActionColumn) {
        let actionColumn = {
          key: 'action',
          class: 'text-center',
          thClass: 'text-center'
        }
        if (this.allowItemCheck && this.mayAddInspection)
          actionColumn.label = 'History'

        fields.push(actionColumn)
      }
      return fields.filter(f => !this.hideColumns.includes(f.key))
    }
  },
  props: {
    allowAddInspectionForCheckedItems: {
      type: Boolean,
      default: false
    },
    showActionColumn: {
      type: Boolean,
      default: false
    },
    showAddButton:{
      type: Boolean,
      default: false
    },
    hideColumns: {
      type: Array,
      default() { return [] }
    }
  },
  watch: {
    items: {
      immediate: true,
      handler(newItems) {
        this.checkableItems = newItems
          ? newItems.map(item => Object.assign({ checked: false }, item))
          : []
      }
    }
  }
}
</script>
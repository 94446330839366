import { Types } from '@/stores/digitalLearning'
import { mapState } from 'vuex'
import isEqual from 'lodash.isequal'

export default {
  computed: {
    ...mapState(Types.path, [
      Types.state.selectedWaiverRequest, 
      Types.state.snapshots
    ]),
    lastSnapshot() {
      if (this.snapshots && this.snapshots.length > 0)
        return this.snapshots[0]

      return null
    },
  },
  methods: {
    propertyHasChangedFromSnapshot(propertyName) {
      if (!this.selectedWaiverRequest || !this.selectedWaiverRequest.waiverRequestId || !this.lastSnapshot) 
        return false

      let snapshotValue = this.lastSnapshot[propertyName]
      if (propertyName == 'programAffectedLeasResponse') 
        snapshotValue = JSON.parse(snapshotValue || '[]')
      else if (propertyName.endsWith('Response')) {
        snapshotValue = JSON.parse(snapshotValue || '{}')
      }
      return !isEqual(this.selectedWaiverRequest[propertyName], snapshotValue)
    },
  }
}
<template>
  <div class="card mt-3 bg-light">
    <a class="anchor d-print-none" id="graduation-count" />
    <div class="card-header">
      Graduates / Completers
    </div>
    <div class="card-body">
      <div class="card-group">
        <enrollment-total-chart :load-data="loadData" :selected-lea="selectedLea" :enrollment="graduationCounts" chart-title="Total Graduates / Completers" />
        <enrollment-by-gender-chart
          :load-data="loadData"
          :selected-lea="selectedLea"
          :enrollment="graduationCounts"
          chart-title="Graduates / Completers by Gender"
        />
      </div>
      <div class="card-group">
        <enrollment-by-race-chart
          :load-data="loadData"
          :selected-lea="selectedLea"
          :enrollment="graduationCounts"
          chart-title="Graduates / Completers by Race"
        />
        <!-- <graduation-by-other-subpopulation-chart :load-data="loadData" :selected-lea="selectedLea" :enrollment="graduationCounts" chart-title="Graduates / Completers by Subpopulation" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EnrollmentTotalChart from './EnrollmentTotalChart'
import EnrollmentByRaceChart from './EnrollmentByRaceChart'
import EnrollmentByGenderChart from './EnrollmentByGenderChart.vue'

export default {
  data() {
    return {
      loadData: null
    }
  },
  props: {
    //selectedLea: Object
  },
  computed: {
    ...mapState('overviewModule', ['graduationCounts'])
  },
  components: {
    EnrollmentByRaceChart,
    EnrollmentByGenderChart,
    EnrollmentTotalChart
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        this.loadData = this.$store.dispatch('overviewModule/getGraduationRates', {
          lea: newLea.number
        })
      }
    }
  }
}
</script>

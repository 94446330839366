export default [
  'APEX Learning, Inc.',
  'AR River Edu Service Coop',
  'Ark Dept of Career Edu',
  'Ark Math/Science/Arts School',
  'Ark Northeastern College',
  'Ark Public School Resource Ctr',
  'Arkansas State University',
  'ASU - Mountain Home',
  'Big History Project',
  'Black River Technical College',
  'BYU Independent Study',
  'Cossatot CC of UA',
  'Crystal Bridges Museum of Art',
  'Delta YES, Inc.',
  'Edgenuity, Inc.',
  'Edmentum, Inc.',
  'eDynamic Learning',
  'Florida Virtual School Global',
  'Fuel Education',
  'Graduation Alliance',
  'Greenways Academy',
  'Henderson STEM Ctr',
  'Hill Holdings, Inc dba SLC',
  'Jupiter Edu (dba Red Comet)',
  'National Park Comm College',
  'North Arkansas College',
  'NW Ark Community College',
  'NW Ark Edu Service Cooperative',
  'Odysseyware Acad (Bridgewater)',
  'Ozarka College',
  'PCCUA-Phillips County College',
  'Pearson Online/Blended Lrn',
  'Proximity Learning',
  'Rich Mountain Comm College',
  'SE Ark Community Based Edu Ctr',
  'SkillBott, Inc.',
  'South Arkansas Comm College',
  'Southeast Arkansas College',
  'Southern Ark Univ - Magnolia',
  'Southern Ark Univ Tech College',
  'TechTrep',
  'U of A Comm College-Batesville',
  'U of A Hope Texarkana',
  'Univ of Ark at Little Rock',
  'University of Ark - Fort Smith',
  'Virtual Arkansas',
  'VLN Partners',
  'Other'
]
<template>
  <b-card :title="showAttendeesVisit ? groupVisitAttendeesTitle : 'Group Visits'">
    <div v-if="!showAttendeesVisit && selectedLea.type == 1 && !editVisit">
      <b-form-row>
        <b-col cols="4" class="pr-4">
          <b-form-group label="Academic Year" label-cols="4" content-cols="4">
            <b-form-select :options="availableAYs" v-model="selectedYear" @input="loadVisits" />
          </b-form-group>
        </b-col>
      </b-form-row>
      <paginated-table :items="visits" :fields="fields" v-if="!showAttendeesVisit && selectedLea.type == 1 && !editVisit" :load-data="loadData">
        <template v-slot:cell(visitDate)="data">
          {{ data.value | date('short-date') }}
        </template>
        <template v-slot:[`head(${f.key})`]="data" v-for="f in visitTopicFields">
          <div :key="f.key">
            <span>
              {{ data.label }}
            </span>
          </div>
        </template>
        <template v-slot:[`cell(${f.key})`]="data" v-for="f in visitTopicFields">
          <pretty-checkbox :value="data.item.topics.filter(t=>t == data.field.key).length" :key="`${f.key}${data.item.visitId}`" />
        </template>
        <template #head(action)>
          <b-btn variant="primary" @click="$router.push({name: 'CTE/CareerCoach/StudentRoster'})" v-if="selectedLea.type == 1 && !userContext.isCTEReadOnly">
            <i class="fa fa-plus-circle fa-fw" />
            Add New Visit
          </b-btn>
        </template>   
        <template #cell(action)="data">
          <b-button-group>
            <b-btn size="sm" variant="primary" title="Edit Visit" class="mr-1" @click="startVisitEdit(data)" v-if="!userContext.isCTEReadOnly && editableAYs.includes(selectedYear)">
              <i class="far fa-edit fa-fw" />
            </b-btn>
            <b-btn size="sm" class="mr-1" variant="secondary" title="Show Attendees" @click="loadAttendees(data.item)">
              <i class="fas fa-users fa-fw" />
            </b-btn>         
            <b-btn size="sm" variant="secondary" title="Show Details" class="mr-1" @click="data.item._showDetails = !data.item._showDetails">
              <i class="fas fa-search-plus fa-fw" />
            </b-btn>           
            <b-btn size="sm" variant="danger" title="Remove Visit" @click="removeVisit(data)" v-if="!userContext.isCTEReadOnly">
              <i class="far fa-times-circle fa-fw" />
            </b-btn>
          </b-button-group>
        </template>
        <template #cell(attendance)="data">
          {{ data.value }}
          <b-button variant="link" @click="onDownloadItem(data.item)" title="Download Sign-In Sheet" v-if="data.item.signInSheet">
            <i class="fas fa-download" />
          </b-button>
        </template>
        <template #row-details="row">
          <b-row>
            <b-form-group label="Location" class="col-md-2">
              <b-form-input readonly v-model="row.item.visitData.location" />
            </b-form-group>
            <b-form-group label="Type" class="col-md-2">
              <b-form-input readonly v-model="row.item.visitData.type" />
            </b-form-group>
            <b-form-group label="Notes" class="col-md-8">
              <b-form-textarea readonly v-model="row.item.visitData.notes" />
            </b-form-group>
          </b-row>
        </template>         
      </paginated-table>
    </div>
    <visit v-else-if="editVisit" :edit-visit="editVisit" :grades="[editVisit.grade]" @save="saveVisit" @cancel="cancelEdit" />
    <bulk-visit-attendee-list @close="showAttendeesVisit = null" :load-data="loadAttendeesPromise" :visit-id="showAttendeesVisit.visitId" v-else-if="showAttendeesVisit" />
    <b-alert variant="warning" show v-else>
      Group Visit maintenance is only accessible for Schools. Please select a School LEA to continue.
    </b-alert>
  </b-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { formatDate } from '@/helpers/formatters'
import { Types } from '@/modules/cte/stores/careercoach'
import PaginatedTable from '@/components/PaginatedTable.vue'
import store from '@/stores'
import BulkVisitAttendeeList from '../widgets/visits/BulkVisitAttendeeList.vue'
import Visit from '../widgets/visits/Visit.vue'
import PrettyCheckbox from '@/components/PrettyCheckbox'

export default {
  components: {
    PaginatedTable,
    BulkVisitAttendeeList,
    Visit,
    PrettyCheckbox
  },
  computed: {
    ...mapState(Types.path, [Types.state.bulkVisits]),
    ...mapState('globalModule', ['userContext']),
    ...mapGetters(Types.path, [Types.getters.visitCurrentAY]),
    visits() {
      return this.bulkVisits.map(v=>Object.assign({...v, visitData: JSON.parse(v.visitData), topics: JSON.parse(v.visitData).topics}, {_showDetails: false}))
    },
    fields(){
      return [...this.baseFields, ...this.visitTopicFields, {key: 'action', class: 'text-right'}]
    },
    availableAYs(){
      var date = new Date()
      return [        
        {text: `${date.getFullYear()}/${date.getFullYear() + 1} Academic Year`, value: date.getFullYear() - 1999},
        {text: `${date.getFullYear() -1}/${date.getFullYear()} Academic Year`, value: date.getFullYear() - 2000},        
        {text: `${date.getFullYear() -2}/${date.getFullYear()-1} Academic Year`, value: date.getFullYear() - 2001},
        {text: `${date.getFullYear() -3}/${date.getFullYear()-2} Academic Year`, value: date.getFullYear() - 2002}
      ]
    },
    editableAYs() {
      var year = new Date().getFullYear()
      return [year - 2000, year-1999]
    },
    groupVisitAttendeesTitle() {
      return this.selectedGroupVisit ? `Group Visit Attendees for ${formatDate(this.selectedGroupVisit.visitDate, 'short-date')} visit` : "Group Visit Attendees"
    }
  },
  beforeRouteEnter(to, from, next) {
    var promise = store.dispatch(Types.actions.getBulkVisits, { lea: to.query.lea, grade: null})
    store.commit(Types.mutations.setCurrentBulkVisit, undefined)
    next(vm => vm.loadData = promise)
  },
  methods:{
    onDownloadItem(item) {
      this.downloading = true
      this.$store.dispatch(Types.actions.downloadBulkVisitFile, { lea: this.selectedLea.number, visitId: item.visitId, MimeType: item.signInSheet.MimeType })
        .then(() => this.downloading = false)      
    },
    loadAttendees(data){
      this.loadAttendeesPromise = this.$store.dispatch(Types.actions.getBulkVisitAttendees, { visitId: data.visitId, lea: data.lea } ).then(() => { this.selectedGroupVisit = data })
      this.showAttendeesVisit = data
    },
    async removeVisit(data) {
      var result = await this.$bvModal.msgBoxConfirm("Delete this visit?")
      if(result){
        this.$store.dispatch(Types.actions.deleteBulkVisit, { lea: this.selectedLea.number, visitId: data.item.visitId })
      }
    },
    loadVisits(){
      this.loadData = store.dispatch(Types.actions.getBulkVisits, { lea: this.selectedLea.number, grade: null, ay: this.selectedYear })
    },
    startVisitEdit(dataItem){
      this.editVisit = dataItem.item
    },
    saveVisit(item){
      this.$store.dispatch(Types.actions.updateBulkVisit, { lea: this.selectedLea.number, visitId: item.visitId, visitData: { AcademicYear: item.ay, VisitData: JSON.stringify(item.visitData), VisitDate: item.visitDate }})
      .then(() => {
        this.loadVisits()
        this.editVisit = null
      })
    },
    cancelEdit(){
      this.editVisit = null
    }
  },
  data() {
    return {
      selectedGroupVisit: null,
      showAttendeesVisit: null,
      editVisit: null,
      baseFields: [
        {key: 'visitDate', sortable: true},
        {key: 'grade'},
        {key: 'attendance', label: 'Attendance'}
      ],      
      visitTopicFields:  [
        { key: 'careerAssessment', label: 'Career Assessment', class: 'text-center' },
        { key: 'careerPlan', label: 'Career Plan', class: 'text-center' },
        { key: 'collegeAdmissions', label: 'College Admissions', class: 'text-center' },
        { key: 'financialAid', label: 'Financial Aid', class: 'text-center' },
        { key: 'academicConcurrentCredit', label: 'Academic Concurrent Credit', class: 'text-center' },
        { key: 'cteConcurrentCredit', label: 'CTE Concurrent Credit', class: 'text-center' },
        { key: 'apprenticeship', label: 'Apprenticeship', class: 'text-center' },
        { key: 'workbasedLearning', label: 'WBL', class: 'text-center' }
      ],
      loadAttendeesPromise: null,
      selectedYear: null,
      loadData:  null
    }
  },
  watch: {
    selectedLea(newValue) {
      this.loadData = store.dispatch(Types.actions.getBulkVisits, { lea: newValue.number, grade: null, ay: this.selectedYear })
    }
  },
  mounted(){
    this.selectedYear = this.visitCurrentAY
  }
}
</script>
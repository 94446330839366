
<template>
  <div>
    <pdf-button />
    <excel-button title="Overview" />
    <h1>Financial Overview</h1>
    <h2>
      <lea-display :lea="selectedLea" />
    </h2>
    <div class="alert alert-warning mt-4" v-if="selectedLea.type == LeaTypes.School">
      Select district, co-op, or state to view the statistical report.
    </div>
    <div v-else>
      <statistical-report-widget :selected-lea="selectedLea" />
    </div>
  </div>
</template>

<script>
import StatisticalReportWidget from './StatisticalReportWidget.vue'
import { PageMixin } from '../../PageMixin'
import { LeaTypes } from '../../../helpers/leaTypes'

export default {
  data() {
    return {
      LeaTypes
    }
  },
  mixins: [PageMixin],
  computed: {
    selectedLea() { return this.$store.state.globalModule.leaContext.SelectedLea }
  },
  components: {
    StatisticalReportWidget
  }
}
</script>

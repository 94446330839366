<template>
  <div>
    <div>
      <career-coach-user-widget />
    </div>
  </div>
</template>

<script>
import CareerCoachUserWidget from './CareerCoachUserWidget.vue'

export default {
  components: {
    CareerCoachUserWidget,
  }
}
</script>

<template>
  <div>
    <info-panel />

    <div v-if="leaType == LeaTypes.School" class="card outer-card">
      <div class="card-body">
        <growth-filter :demographics-filter-enabled="false" />
        <readiness-levels :selected-lea="selectedLea" :subject="subject" :grade="grade" v-if="teacher" />
      </div>
    </div>
    <div v-else class="alert alert-warning">
      Please select a school to view report
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { PageMixin } from '../../../PageMixin'
import InfoPanel from './InfoPanel.vue'
import ReadinessLevels from './ReadinessLevels.vue'
import GrowthFilter from '../GrowthFilter.vue'

export default {
  name: 'question2',
  data() {
    return {}
  },
  mixins: [PageMixin],
  components: { 
    ReadinessLevels,
    GrowthFilter,
    InfoPanel
  },
  computed: {
    ...mapState('academicsModule/teacherGrowth', ['tab', 'grade', 'subject', 'teacher', 'readinessLevelSummary'])
  }
}
</script>
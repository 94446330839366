<template>
  <div v-if="reportData" class="report-output">
    <!-- <h3 class="caption">
      Student Contacts By Month
    </h3> -->
    <b-table-simple sticky-header="500px" class="table table-sm small">
      <b-thead class="border-left border-right" head-variant="light">
        <b-tr>
          <th style="width: 120px;">
            &nbsp;
          </th>
          <th colspan="8" class="text-center border-right">
            Initial Contacts
          </th>
          <th colspan="9" class="text-center">
            Duplicate Contact(s)
          </th>
        </b-tr>
        <b-tr>
          <th style="width: 120px;">
            Month
          </th>
          <th title="Career Plan" style="width: 65px;" class="text-center">
            CP
          </th>
          <th title="Financial Aid" style="width: 65px;" class="text-center">
            FA
          </th>
          <th title="Career Assessment" style="width: 65px;" class="text-center">
            CA
          </th>
          <th title="College Admissions" style="width: 65px;" class="text-center">
            CO
          </th>
          <th title="Academic Concurrent Credit" style="width: 65px;" class="text-center">
            ACC
          </th>
          <th title="CTE Concurrent Credit" style="width: 65px;" class="text-center">
            CTECC
          </th>
          <th title="Apprenticeship" style="width: 65px;" class="text-center">
            APP
          </th>
          <th class="border-right text-center" title="Workbase Learning" style="width: 65px;">
            WBL
          </th>

          <th title="Duplicate Contacts" style="width: 65px;" class="text-center">
            Total<br>Duplicate<br>Contacts
          </th>

          <th title="Career Plan" style="width: 65px;" class="text-center">
            CP
          </th>
          <th title="Financial Aid" style="width: 65px;" class="text-center">
            FA
          </th>
          <th title="Career Assessment" style="width: 65px;" class="text-center">
            CA
          </th>
          <th title="College Admissions" style="width: 65px;" class="text-center">
            CO
          </th>
          <th title="Academic Concurrent Credit" style="width: 65px;" class="text-center">
            ACC
          </th>
          <th title="CTE Concurrent Credit" style="width: 65px;" class="text-center">
            CTECC
          </th>
          <th title="Apprenticeship" style="width: 65px;" class="text-center">
            APP
          </th>
          <th class="border-right text-center" title="Workbase Learning" style="width: 65px;">
            WBL
          </th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="s in reportData" :key="s.month" class="border-left">
          <td>
            {{ monthName(s.calendarMonth-1) }} <span v-if="s.calendarMonth == 7 || s.calendarMonth == 8">{{ s.calendarYear }}</span>
          </td>
          <td class="text-center">
            {{ s.initialCareerPlan }}
          </td>
          <td class="text-center">
            {{ s.initialFinancialAid }}
          </td>
          <td class="text-center">
            {{ s.initialCareerAssessment }}
          </td>
          <td class="text-center">
            {{ s.initialCollegeAdmissions }}
          </td>
          <td class="text-center">
            {{ s.initialAcademicConcurrentCredit }}
          </td>
          <td class="text-center">
            {{ s.initialCTEConcurrentCredit }}
          </td>
          <td class="text-center">
            {{ s.initialApprenticeship }}
          </td>
          <td class="border-right text-center">
            {{ s.initialWorkbasedLearning }}
          </td>
          <td class="text-center border-right">
            {{ s.totalDuplicates }}
          </td>
         
          <td class="text-center">
            {{ dupCount(s.dupCareerPlan) }}
          </td>
          <td class="text-center">
            {{ dupCount(s.dupFinancialAid) }}
          </td>
          <td class="text-center">
            {{ dupCount(s.dupCareerAssessment) }}
          </td>
          <td class="text-center">
            {{ dupCount(s.dupCollegeAdmissions) }}
          </td>
          <td class="text-center">
            {{ dupCount(s.dupAcademicConcurrentCredit) }}
          </td>
          <td class="text-center">
            {{ dupCount(s.dupCTEConcurrentCredit) }}
          </td>
          <td class="text-center">
            {{ dupCount(s.dupApprenticeship) }}
          </td>
          <td class="text-center border-right">
            {{ dupCount(s.dupWorkbasedLearning) }}
          </td>
        </b-tr>
      </b-tbody>
      <b-tfoot class="bg-light">
        <b-tr class="font-weight-bold border-bottom border-left border-right">
          <td>
            Totals
          </td>

          <td class="text-center">
            {{ this.total('initialCareerPlan') }}
          </td>
          <td class="text-center">
            {{ this.total('initialFinancialAid') }}
          </td>
          <td class="text-center">
            {{ this.total('initialCareerAssessment') }}
          </td>
          <td class="text-center">
            {{ this.total('initialCollegeAdmissions') }}
          </td>
          <td class="text-center">
            {{ this.total('initialAcademicConcurrentCredit') }}
          </td>
          <td class="text-center">
            {{ this.total('initialCTEConcurrentCredit') }}
          </td>
          <td class="text-center">
            {{ this.total('initialApprenticeship') }}
          </td>
          <td class="text-center border-right">
            {{ this.total('initialWorkbasedLearning') }}
          </td>

          <td class="text-center border-right">
            {{ this.total('totalDuplicates') }}
          </td>
          
          <td class="text-center">
            {{ this.total('dupCareerPlan') }}
          </td>
          <td class="text-center">
            {{ this.total('dupFinancialAid') }}
          </td>
          <td class="text-center">
            {{ this.total('dupCareerAssessment') }}
          </td>
          <td class="text-center">
            {{ this.total('dupCollegeAdmissions') }}
          </td>
          <td class="text-center">
            {{ this.total('dupAcademicConcurrentCredit') }}
          </td>
          <td class="text-center">
            {{ this.total('dupCTEConcurrentCredit') }}
          </td>
          <td class="text-center">
            {{ this.total('dupApprenticeship') }}
          </td>
          <td class="text-center border-right">
            {{ this.total('dupWorkbasedLearning') }}
          </td>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </div>
</template>

<script>
import { VisitTypes } from '@/modules/cte/stores/careercoach/reporting'
import sumBy from 'lodash.sumby'

export default {
  data() {
    return {
      filter: null,
      VisitTypes,
      fields: [
        {key: 'month', label: 'month' },
        { key: 'initialCareerPlan' },
        { key: 'initialFinancialAid' },
        { key: 'initialCareerAssessment' },
        { key: 'initialCollegeAdmissions' },
        { key: 'initialAcademicConcurrentCredit' },
        { key: 'initialCTEConcurrentCredit' },
        { key: 'initialApprenticeship' },
        { key: 'initialWorkbasedLearning' },
        { key: 'dupCareerPlan' },
        { key: 'dupFinancialAid' },
        { key: 'dupCareerAssessment' },
        { key: 'dupCollegeAdmissions' },
        { key: 'dupAcademicConcurrentCredit' },
        { key: 'dupCTEConcurrentCredit' },
        { key: 'dupApprenticeship' },
        { key: 'dupWorkbasedLearning' },
      ]
    }
  },
  methods: {
    monthName(month) {
      const d = new Date(0, month, 1)
      return d.toLocaleString('en-us', {month: 'long'})
    },
    dupCount(count) {
      return count // && count > 1 ? count - 1 : null
    },
    total(property) {
      return sumBy(this.reportData, s=>s[property])
    },
  },
  props: {
    reportData: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style>

</style>
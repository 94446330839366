<template>
  <div class="card bg-light mt-3">
    <div class="card-header">
      Teachers
    </div>
    <div class="card-body">
      <div class="card" style="overflow-y: hidden; min-height: 25em; height: auto;">
        <div class="card-body inner-card">
          <Promised :promise="loadData">
            <template v-slot:pending>
              <div class="align-items-center justify-content-center" style="height: 100%; width: 100%">
                <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
              </div>
            </template>
            <template :class="centerContent ? 'align-items-center justify-content-center' : ''">
              <table-control-widget v-if="!selectedTeacher" :per-page="tableControl.perPage" @filter-changed="tableControl.filter=$event" @page-length-changed="tableControl.perPage=$event" />
              <b-table ref="teacherTable" striped hover selectable responsive small select-mode="single" :items="displayTeachers" :per-page="tableControl.perPage"
                       :filter="tableControl.filter" :current-page="tableControl.currentPage" :fields="tableFields"
                       @row-selected="onTeacherSelected" @filtered="onFiltered" sort-by="teacherName" />            
              <table-pagination-widget v-if="!selectedTeacher" :total-rows-count="totalRowCount" :page-length="tableControl.perPage" @page-changed="tableControl.currentPage=$event" />
            </template>
            <template v-slot:rejected="error" class="align-items-center justify-content-center">
              <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
            </template>
          </Promised>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TableMixin } from '../TableMixin'
import { mapGetters } from 'vuex'
import { Promised } from 'vue-promised'
import CardStateIcon from '../CardStateIcon.vue'

export default {
    name: 'teachers-table',
    mixins: [TableMixin],
    props: {
        loadData: Promise,
        qsTeacherId: String
    },
    data() {
        return {
            tableControl: {
                filter: null,
                perPage: 10,
                currentPage: 1
            },
            tableFields: [
                { key: 'teacherName', sortable: true, sortDirection: 'asc', tdClass: 'text-nowrap' },
                { key: 'uniq_emp_id', label: 'State ID' },
                { key: 'emp_status', sortable: true }
            ],
            filteredTeachers: null,
            qsTeacherIdValue: this.qsTeacherId
        }
    },
    components: {
        Promised,
        CardStateIcon
    },
    methods: {
        onFiltered(filteredItems){
            this.tableControl.currentPage = 1
            this.filteredTeachers = filteredItems
        },
        onTeacherSelected(item) {
            if (item.length > 0){
                this.$store.commit('scheduleModule/setSelectedTeacher', item[0] )
                this.tableControl.filter = item[0].teacherName
            }
        },
        excelExportData() {
            return {
                name: 'Teachers',
                items: (this.filteredTeachers || this.displayTeachers).map(item => {
                    return {
                        "Teacher Name": item.teacherName,
                        "State ID": item.uniq_emp_id,
                        "Employment Status": item.emp_status
                    }
                })
            }
        }
    },
    computed: {
        selectedLea() { return this.$store.state.globalModule.leaContext.SelectedLea },
        selectedTeacher() { return this.$store.state.scheduleModule.selectedTeacher },
        selectedCourse() { return this.$store.state.scheduleModule.selectedCourse },
        ...mapGetters('scheduleModule', ['displayTeachers']),
        totalRowCount() { return this.displayTeachers.length }
    },
    watch: {
        selectedTeacher: {
            handler(teacher){
                if(!teacher){
                    this.$refs.teacherTable.clearSelected()
                    this.qsTeacherIdValue = ''
                    this.tableControl.filter = ''
                }
                else{
                    this.tableControl.filter = teacher.teacherName
                    this.tableControl.currentPage = 1
                }
            }
        },
        displayTeachers:{
            immediate: true,
            handler(){
                if(this.displayTeachers && !this.selectedTeacher && this.qsTeacherIdValue){
                    var teacherToSelect = this.displayTeachers.find(t => t.uniq_emp_id.trim() == this.qsTeacherIdValue)
                    if(teacherToSelect){
                        this.onTeacherSelected([teacherToSelect])
                    }
                }
            }
        },
        selectedCourse: {
            handler(course){
                if(course && !this.selectedTeacher){
                    this.tableControl.filter = course.teacherName
                }
            }
        }
    }
}
</script>
<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { credits } from '@/helpers/credits'
const LetterGrades = ['A', 'B', 'C', 'D', 'F']

export default {
  name: 'letter-grades-count-chart',
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'column'
        },
        credits: credits.MSI,
        title: {
          text: 'Letter Grade Distribution'
        },
        legend: {
          enabled: true
        },
        series: [
          {
            name: 'A'
          },
          {
            name: 'B'
          },
          {
            name: 'C'
          },
          {
            name: 'D'
          },
          {
            name: 'F'
          }
        ],
        xAxis: {
          title: {
            text: 'School Year'
          }
        },
        yAxis: {
          title: {
            text: 'Number of Schools'
          }
        },
        plotOptions: {
          series: {
            minPointLength: 2
          }
        }
      }
    }
  },
  mixins: [ChartMixin],
  computed: {
    lettergrades() {
      return this.$store.state.overviewModule.lettergrades
    }
  },
  watch: {
    lettergrades(newData) {
      LetterGrades.forEach((grade, i) => {
        this.chartOptions.series[i].data = newData.map(row => {
          return {
            x: (row.year % 1990) + 1990,
            y: row[grade.toLowerCase()]
          }
        })
        this.chartOptions.series[i].name = `Grade ${grade.toUpperCase()}`
      })
    }
  },
  methods: {
    excelExportData() {
      return this.defaultExcelExportData()
    },
    dataItems() {
      return LetterGrades.flatMap((grade, index) =>
        this.chartOptions.series[index].data.map(item => {
          return { Grade: grade, SchoolYear: item.x, Count: item.y }
        })
      )
    }
  }
}
</script>

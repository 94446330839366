<style scoped>
  @media only screen and (max-width: 600px) {
    .custom-control-inline {
        margin-right: .5rem !important;
     }
  }
</style>

<template>
  <div>
    <div class="d-flex flex-row" v-if="showNavigation">
      <a href="javascript:history.go(-1)" class="pt-1">
        <i class="fas fa-arrow-alt-circle-left fa-2x" />
      </a>
      <h3 class="ml-2 pt-1">
        { Bus: Bus # / Sticker # }
      </h3>
    </div>

    <div class="d-none">
      <span class="font-weight-bold">General</span> | Brakes/Tires | Engine | In | Out | Under
    </div>

    <div v-if="inspection.status == InspectionStatuses.NotStarted" class="text-center">
      <b-alert :show="inspection.status == InspectionStatuses.NotStarted" variant="primary">
        Inspection for Bus # {{ inspection.busNumber }}  has not yet been started.<br>
        <b-btn class="mt-2" v-if="inspection.status == InspectionStatuses.NotStarted" variant="primary" @click="startInspection">
          <span>Start Inspection</span>
        </b-btn>
      </b-alert>
    </div>

    <div v-else-if="isPaused" class="text-center">
      <b-alert :show="isPaused" variant="danger">
        Inspection for Bus # {{ inspection.busNumber }} is paused.<br>
        <b-btn class="mt-2" variant="warning" @click="pause" v-show="inspection.status == InspectionStatuses.Paused">
          <span>Resume Inspection</span>
        </b-btn>
      </b-alert>
    </div>

    <b-card no-body v-else>
      <b-alert :show="isPaused" variant="danger">
        Inspection is paused. Resume inspection on General tab to continue.
      </b-alert>
      <b-tabs card fill pills content-class="mt-3" active-nav-item-class="font-weight-bold" nav-wrapper-class="bg-secondary" @activate-tab="tabActivationSentinel">
        <b-tab title="General" active>
          <b-form>            
            <div class="form-row" v-if="isDirty">
              <div class="col-md-12">
                <div class="pt-2 pull-right">
                  <b-btn variant="success" size="sm" @click="save">
                    Save
                  </b-btn>
                  <b-btn variant="danger" size="sm" class="mx-1" @click="cancel">
                    Cancel
                  </b-btn>
                </div>
              </div>
            </div>
            <b-form-row label-class="font-weight-bold" class="d-none d-md-flex">
              <div class="col-md-3">
                <b-form-group label-class="font-weight-bold" label="District">
                  <span>{{ leaFormatter(inspectionChanges.districtLEA) }}</span>
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group label-class="font-weight-bold" label="Bus Number">
                  <b-form-input v-model="inspectionChanges.busNumber" :disabled="readonly" :state="(inspectionChanges.busNumber || '').length > 0" />
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group label-class="font-weight-bold" label="Sticker #">
                  <b-form-input v-model="inspectionChanges.stickerNumber" :disabled="readonly" />
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group label-class="font-weight-bold" label="Capacity">
                  <span>{{ inspectionChanges.capacity }}</span>
                </b-form-group>
              </div>
            </b-form-row>

            <b-form-row>
              <div class="col-md-3 d-none d-sm-inline">
                <b-form-group label-class="font-weight-bold" label="VIN #">
                  <b-form-input v-model="inspectionChanges.vin" :disabled="readonly" />
                </b-form-group>
              </div>
              <div class="col-md-3 d-none d-sm-inline">
                <b-form-group label-class="font-weight-bold" label="Use">
                  <b-form-select v-model="inspectionChanges.use" :options="BusUses" :disabled="readonly || isPaused" />
                </b-form-group>
              </div>
              <div class="col-md-3 d-none d-sm-inline">
                <b-form-group label-class="font-weight-bold" label="Body">
                  <span>{{ inspectionChanges.body }}</span>
                </b-form-group>
              </div>              
              <div class="col-md-3">
                <b-form-group label-class="font-weight-bold" label="Fuel">
                  <span>{{ inspectionChanges.fuel }}</span>
                </b-form-group>
              </div>
            </b-form-row>

            <b-form-row class="d-md-flex">              
              <div class="col-md-3">
                <b-form-group label-class="font-weight-bold" label="Odometer Reading">
                  <span>
                    <b-form-input v-model="inspectionChanges.odometerReading" type="number" :disabled="readonly || isPaused" :state="inspectionChanges.odometerReading > 0" />
                  </span>
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group label-class="font-weight-bold" label="Transmission">
                  <b-form-select v-model="inspectionChanges.transmission" :options="TransmissionTypes" :disabled="readonly || isPaused" :state="inspectionChanges.transmission > 0" />
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group label-class="font-weight-bold" label="Brakes">
                  <b-form-select v-model="inspectionChanges.brakes" :options="BrakeTypes" :disabled="readonly || isPaused" :state="inspectionChanges.brakes > 0" />
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group label-class="font-weight-bold" label="Vehicle Type">
                  <b-form-select v-model="inspectionChanges.vehicleType" :options="VehicleTypes" :disabled="readonly || isPaused" :state="inspectionChanges.vehicleType > 0" />
                </b-form-group>
              </div>
            </b-form-row>
            <b-row class="text-center" v-if="!readonly">
              <b-col>
                <b-btn class="mr-2" variant="warning" @click="pause" v-show="inspection.status == InspectionStatuses.InProgress || inspection.status == InspectionStatuses.Paused">
                  <span>Pause Inspection</span>
                </b-btn>
                <b-btn :variant="canCompleteInspection ? 'primary' : 'secondary'" @click="complete" :disabled="!canCompleteInspection">
                  <span>{{ statusButtonText }}</span>
                </b-btn>
                <b-btn variant="danger" @click="remove" v-if="isOnline">
                  <span>Remove Inspection</span>
                </b-btn>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>
        <b-tab title="Brakes & Tires">
          <div class="form-row">
            <div v-if="!readonly && (dirtyMeasurements && dirtyMeasurements.length > 0)" class="col-12">
              <div class="pt-2 text pull-right">
                <b-btn variant="success" size="sm" @click="saveMeasurements">
                  Save
                </b-btn>
                <b-btn variant="danger" size="sm" class="mx-1" @click="cancelMeasurements">
                  Cancel
                </b-btn>
              </div>
            </div>
            <b-form-row class="col-12">
              <div class="col-12 col-lg-6 col-xl-5">
                <table class="table">
                  <caption>
                    Brakes (% of Pad Remaining)
                  </caption>
                  <thead>
                    <tr>
                      <th />
                      <th class="text-center">
                        Left
                      </th>
                      <th class="text-center">
                        Right
                      </th>
                    </tr>
                  </thead>
                  <inspection-measurement v-for="measurement in brakeMeasurements" :key="measurement.type" :measurement="measurement" @input="onMeasurementInput" :readonly="readonly || isPaused" />
                </table>
              </div>
              <div class="col-12 col-lg-6 col-xl-5 offset-xl-1">
                <table class="table">
                  <caption>
                    Tires (1/32nd Inches of Tread Remaining)
                  </caption>
                  <thead>
                    <tr>
                      <th />
                      <th class="text-center">
                        Left
                      </th>
                      <th class="text-center">
                        Right
                      </th>
                    </tr>
                  </thead>
                  <inspection-measurement v-for="measurement in tireMeasurements" :key="measurement.type" :measurement="measurement" @input="onMeasurementInput" :readonly="readonly || isPaused" />
                </table>
              </div>
            </b-form-row>
          </div>
        </b-tab>
        <b-tab title="Inside">
          <div>
            <inspection-component v-for="c in insideComponents" :component="c" mode="transportation" :key="c.id" :readonly="readonly || isPaused" />
          </div>
        </b-tab>
        <b-tab title="Outside">
          <div>
            <inspection-component v-for="c in outsideComponents" :component="c" mode="transportation" :key="c.id" :readonly="readonly || isPaused" />
          </div>
        </b-tab>
        <b-tab title="Engine">
          <inspection-component v-for="c in engineCompartmentComponents" :component="c" mode="transportation" :key="c.id" :readonly="readonly || isPaused" />
        </b-tab>
        <b-tab title="Underneath">
          <div>
            <inspection-component v-for="c in underneathComponents" :component="c" mode="transportation" :key="c.id" :readonly="readonly || isPaused" />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<style lang='scss' scoped>
  table {
    & > caption {
      text-align: center;
      caption-side: top;
      font-weight: bold;
      font-size: 1.2em;
    }
  }
  ::v-deep .form-control.is-invalid {
    background-image: none !important;
    color: red;
    padding-right: 10px
  }
  ::v-deep .form-control.is-valid {
    background-image: none !important;
    padding-right: 10px
  }
</style>

<script>
import { mapState } from 'vuex'
import { isEquivalent, deepClone } from '../../../../helpers/globalHelpers'
import InspectionComponent from '../../common/InspectionComponent'
import InspectionMeasurement from './InspectionMeasurement'
import { MeasurementTypes, InspectionComponentCategories, InspectionStatuses, TransmissionTypes, BrakeTypes, VehicleTypes, RatingTypes, BusUses } from '../../common/Enums'
import { Types } from '../../../../stores/dpsaft/trans'
import sortBy from 'lodash.sortby'

export default {
  name: 'inspection',
  data() {
    return {
      tab: null,
      MeasurementTypes,
      InspectionComponentCategories, 
      TransmissionTypes, 
      BrakeTypes, 
      VehicleTypes,
      BusUses,
      InspectionStatuses,
      inspectionChanges: {...this.inspection},
      measurementChanges: deepClone(this.inspection.measurements),
      dirtyMeasurements: []
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext', 'leaContext', 'isOnline']),
    ...mapState('DPSAFT/Trans', [Types.state.SelectedBatch]),
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    },
    brakeMeasurements() {
        return sortBy(this.measurementChanges.filter(m => [MeasurementTypes.FrontBrakes.value, MeasurementTypes.RearBrakes.value].includes(m.type) ), "type")
    },
    tireMeasurements() {
      return sortBy(this.measurementChanges.filter(m => [MeasurementTypes.FrontTires.value, MeasurementTypes.RearInsideTires.value, MeasurementTypes.RearOutsideTires.value].includes(m.type) ), "type")
    },
    safeComponents() {
      return sortBy(this.inspection.components || [], "orderBy")
    },
    insideComponents(){
      return this.safeComponents.filter(c => c.category == InspectionComponentCategories.Inside)
    },
    outsideComponents(){
      return this.safeComponents.filter(c => c.category == InspectionComponentCategories.Outside)
    },
    engineCompartmentComponents(){
      return this.safeComponents.filter(c => c.category == InspectionComponentCategories.EngineCompartment)
    },    
    underneathComponents(){
      return this.safeComponents.filter(c => c.category == InspectionComponentCategories.Underneath)
    },
    isDirty() {
      const propertiesToCompare = ['odometerReading', 'vehicleType', 'brakes', 'transmission', 'busNumber', 'stickerNumber', 'use', 'vin']
      return !isEquivalent({...this.inspectionChanges}, {...this.inspection}, propertiesToCompare)
    },
    batchId() {
      return this.$store.state.DPSAFT.Trans.SelectedBatch.batchId
    },
    statusButtonText() {
      if (this.inspection.status == InspectionStatuses.Completed) 
        return 'Reopen Inspection'

      return 'Complete Inspection'
    },
    pauseButtonText(){
      if(this.inspection.status == InspectionStatuses.Paused)
        return 'Resume Inspection'

      return 'Pause Inspection'
    },
    canCompleteInspection() {
      return this.inspection.odometerReading >= 0 
        && this.inspection.transmission > 0 
        && this.inspection.brakes > 0 
        && this.inspection.vehicleType > 0
        && (this.inspection.busNumber || '').length > 0
        && (! (this.isDirty || this.dirtyMeasurements.length > 0))
        && [...(this.inspection.components || [])].every(c => {
          return c.rating > RatingTypes.NotChecked
        })
    },
    isPaused() {
      return this.inspection.status == InspectionStatuses.Paused
    }
  },
  methods: {
    tabActivationSentinel(newTabIndex, prevTabIndex, bvEvt ) {
      if (newTabIndex != 0 && this.isDirty) bvEvt.preventDefault();
      if (newTabIndex != 1 && this.dirtyMeasurements.length > 0) bvEvt.preventDefault();
    },
    save() {
      let changes = {...this.inspectionChanges}
      delete changes.components
      delete changes.measurements
      delete changes.status
      this.$store.dispatch(Types.actions.saveInspection, { 
        batchId: this.batchId, inspectionId: this.inspectionChanges.inspectionId, update: changes 
      })
    },
    cancel() {
      this.inspectionChanges = { ...this.inspection }
    },    
    districtName(districtLea) {
      if(this.inspectionChanges){
        return (this.leaContext.LeaDictionary[districtLea] || {}).name || 'District'
      }
      return null
    },
    leaFormatter(lea) {
      if (this.leaDictionary[lea])
        return `${this.leaDictionary[lea].name} (${lea})`

      return lea
    },
    complete() {
      var status = InspectionStatuses.NotStarted
      switch(this.inspection.status) {
        case InspectionStatuses.Completed:
          status = InspectionStatuses.NotStarted
          break
        default:
          status = InspectionStatuses.Completed
          break
      }
      this.$store.dispatch(Types.actions.setInspectionStatus, { batchId: this.batchId, inspectionId: this.inspectionChanges.inspectionId, status: status })
    },
    async remove() {
      if(confirm("Are you sure you want to remove the selected inspection from the inspection batch?")){
        await this.$store.dispatch(Types.actions.deleteInspection, {batchId: this.batchId, inspectionId: this.inspectionChanges.inspectionId })
        if (this.$route.name === 'trans-mobile-inspection-page') window.history.go(-1)
      }
    },
    pause() {
      var status = InspectionStatuses.Paused
      if(this.inspection.status == InspectionStatuses.Paused){ status = InspectionStatuses.InProgress }
      this.$store.dispatch(Types.actions.setInspectionStatus, { batchId: this.batchId, inspectionId: this.inspectionChanges.inspectionId, status: status })
    },
    startInspection() {
      this.$store.dispatch(Types.actions.startInspection, { batchId: this.batchId, inspectionId: this.inspectionChanges.inspectionId })
    },
    onMeasurementInput(measurement) {
      this.dirtyMeasurements = [...this.dirtyMeasurements.filter(m => m.measurementId != measurement.measurementId)]
      if (measurement.isDirty){
        this.dirtyMeasurements.push(measurement)
      }
    },
    cancelMeasurements() {      
      this.dirtyMeasurements = []
      this.measurementChanges = deepClone(this.inspection.measurements)
    },
    saveMeasurements() {
      this.$store.dispatch(Types.actions.saveInspectionMeasurements, { batchId: this.batchId, inspectionId: this.inspectionChanges.inspectionId, updates: this.dirtyMeasurements })
        .then(() => this.cancelMeasurements())
    }
  },
  components: {
    InspectionComponent,
    InspectionMeasurement
  },
  props: {
    showNavigation: {
      type: Boolean,
      default: false
    },
    inspection:{
      type: Object,
      default: null
    },
    readonly: Boolean
  },
  watch: {
    inspection(newInspection) {
      this.inspectionChanges = {...newInspection}
      this.measurementChanges = deepClone(newInspection.measurements)
      this.cancelMeasurements()
    }
  }
}
</script>

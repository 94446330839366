import ApiService from '../services/ApiService'
//import moment from 'moment'

export class AdminModule {
  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl =
      this._configElement == null
        ? '/'
        : this._configElement.getAttribute('data-base-url') || ''
  }

  namespaced = true

  state = {
    pageModules: [],
    selectedModule: {},
    selectedPermissions: [],
    modulePermissions: [],
    allModulePermissions: [],
    allowableUsers: [],
    modules: [],
    loadAllModulePermissions: false,
    jobs: {},
    selectedJob: {},
    latestBatch: {},
    recentBatchProcesses: [],
    districtDataTableCounts: [],
    newJobId: null,
    notifications: [],
  }

  getters = {
    selectedModule: state => state.selectedModule,
    selectedPermissions: state => state.selectedPermissions,
    pageModules: state => state.pageModules,
    allModulePermissions: state => state.allModulePermissions
  }
  mutations = {
    setPageModules: (state, pageModules) => (state.pageModules = pageModules),
    setSelectedModule: (state, selectedModule) =>
      (state.selectedModule = selectedModule),
    setModulePermissions: (state, modulePermissions) =>
      (state.modulePermissions = modulePermissions),
    setAllModulePermissions: (state, modulePermissions) =>
      (state.allModulePermissions = modulePermissions),
    deletePermission: (state, id) => {
      var index = state.allModulePermissions.findIndex(p => p.id == id)
      state.allModulePermissions.splice(index, 1)
    },
    deletePermissions: (state, ids) => {
      for (var i = 0; i < ids.length; ++i) {
        var index = state.allModulePermissions.findIndex(p => p.id == ids[i])
        state.allModulePermissions.splice(index, 1)
      }
    },
    setAllowableUsers: (state, allowableUsers) =>
      (state.allowableUsers = allowableUsers),
    clearAllowableUsers: state => (state.allowableUsers = []),
    setModules: (state, modules) => (state.modules = modules),
    setSelectedPermissions: (state, permissions) => state.selectedPermissions = permissions,
    setJobs: (state, jobs) => state.jobs = jobs,
    setSelectedJob: (state, selectedJob) => state.selectedJob = selectedJob,
    setLatestBatch: (state, latestBatch) => state.latestBatch = latestBatch,
    setRecentBatchProcesses: (state, recentBatchProcesses) => state.recentBatchProcesses = recentBatchProcesses,
    setDistrictDataTableCounts: (state, tableCounts) => state.districtDataTableCounts = tableCounts,
    setNewJobId: (state, newJobId) => state.newJobId = newJobId,
    setNotifications: (state, notifications) => state.notifications = notifications,
  }

  actions = {
    getPageModules: ({ commit }, lea) => {
      return this._apiService
        .Get(`${this._baseUrl}api/admin/pagemodules/${lea}`)
        .then(pageModules => commit('setPageModules', pageModules))
    },
    updateSelectedModule: ({ commit }, selectedModule) => {
      commit('setSelectedModule', selectedModule)
    },
    getModulePermissions: ({ commit }, payload) => {
      if (payload.selectedModule && payload.selectedModule.length > 0)
        return this._apiService
          .Get(
            `${this._baseUrl}api/admin/pagemodules/${payload.selectedModule}/${payload.lea}/permissions`
          )
          .then(modulePermissions =>
            commit('setModulePermissions', modulePermissions)
          )
    },
    getAllModulePermissions: ({ commit }, payload) => {
      return this._apiService
        .Get(`${this._baseUrl}api/admin/pagemodules/${payload.lea}/permissions`)
        .then(modulePermissions =>
          commit('setAllModulePermissions', modulePermissions)
        )
    },
    deletePermission: ({ commit }, id) => {
      return this._apiService
        .Delete(`${this._baseUrl}api/admin/pagemodules/permissions/${id}`)
        .then(() => commit('deletePermission', id))
    },
    deletePermissions: ({ commit }, ids) => {
      return this._apiService
        .Post(`${this._baseUrl}api/admin/pagemodules/permissions/delete`, ids)
        .then(() => commit('deletePermissions', ids))
    },
    getAllowableUsers: ({ commit }, lea) => {
      return this._apiService
        .Get(`${this._baseUrl}api/leauser/lea/${lea}`)
        .then(allowableUsers => commit('setAllowableUsers', allowableUsers))
    },
    clearAllowableUsers: ({ commit }) => {
      commit('clearAllowableUsers')
    },
    addPermission: (_context, payload) => {
      return this._apiService.Put(
        `${this._baseUrl}api/admin/pagemodules/${payload.selectedModule}/${payload.toplea}/permissions/${payload.lea}/${payload.user}`
      )
    },
    addPermissions: (_context, payload) => {
      return this._apiService.Post(
        `${this._baseUrl}api/admin/pagemodules/${payload.lea}/permissions/add`,
        { Modules: payload.modules, Users: payload.users }
      )
    },
    getModules: ({ commit }) => {
      return this._apiService
        .Get(`${this._baseUrl}api/admin/module/list`)
        .then(modules => commit('setModules', modules))
    },
    toggleModule: ({ commit, dispatch }, payload) => {
      const url = payload.enabled
        ? `${this._baseUrl}api/admin/module/enable`
        : `${this._baseUrl}api/admin/module/disable`
      return this._apiService
        .Post(url, { ModuleId: payload.moduleId })
        .then(() => {
          dispatch('getModules', { commit })
        })
    },
    updateRestrictedTo: ({ commit, dispatch }, payload) => {
      return this._apiService
        .Post(`${this._baseUrl}api/admin/module/restrictedTo`, {
          ModuleId: payload.moduleId,
          RestrictedTo: payload.restrictedTo,
          DistrictUserRestriction: payload.districtUserRestriction,
          LEAs: payload.leas
        })
        .then(() => {
          dispatch('getModules', { commit })
        })
    },
    updateSelectedPermissions: ({ commit }, selectedPermissions) => {
      commit('setSelectedPermissions', selectedPermissions)
    },
    getJobs: ({ commit }) => {
      return this._apiService
        .Get(`${this._baseUrl}api/jobs/status`)
        .then(jobs =>
          commit('setJobs', jobs)
        )
    },
    getJobLogs: ({ commit }, payload) => {
      return this._apiService
        .Get(`${this._baseUrl}api/jobs/logs/${payload.jobId}`)
        .then(jobLogs =>
          commit('setSelectedJob', { job: payload, jobLogs })
        )
    },
     // eslint-disable-next-line
    setDisplayLoginButton:({commit}, settingValue) => {
      return this._apiService
      .Patch(`${this._baseUrl}api/admin/login-button-visible?setting=${settingValue}`)
    },
    getLatestBatch: ({commit}) => {
      return this._apiService.Get(`${this._baseUrl}api/districtExtractMonitor/latest`)
        .then(latestBatch => commit('setLatestBatch', latestBatch))
    },
    getRecentBatches: ({commit}, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/districtExtractMonitor/recent/${payload.days}`)
        .then(recentBatchProcesses => commit('setRecentBatchProcesses', recentBatchProcesses))
    },
    enqueueIndividualDistrict: ({commit}, payload) => {
      return this._apiService.Post(`${this._baseUrl}api/jobs/build/${payload.lea}`)
        .then(data => commit('setNewJobId', data))
    },
    getDistrictDataTableCounts: ({commit}) => {
      return this._apiService.Get(`${this._baseUrl}api/districtExtractMonitor/tables`)
        .then(tableCounts => commit('setDistrictDataTableCounts', tableCounts))
    },
    getNotifications: ({commit}) => {
      return this._apiService.Get(`${this._baseUrl}api/notification`)
        .then(notifications => commit('setNotifications', notifications))
    },
  }
}

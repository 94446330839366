<template>
  <div>
    <waiver-page-header :edit-mode="editMode" :waiver-request-id="waiverRequestId" 
                        :lea="resolvedLea" :current-status="currentStatus" :waiver-request-title="waiverRequestTitle"
                        :request="request" :back="backToRequestList" :context="Context.Review" />
    <Promised :promise="loadData" v-show="loadData">
      <template v-slot:pending>
        <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
          <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
        </div>
      </template>
      <template v-slot>
        <div v-if="request && waiverRequestId" class="row">
          <div class="col-md-9">
            <b-alert variant="warning" show v-if="showWithdrawalRequest">
              <i class="fas fa-exclamation-triangle mr-2" />
              <strong class="mr-2">District has requested to withdraw this submission:</strong>{{ withdrawalRequest.comment }} &ndash; {{ withdrawalRequest.createdBy }}
            </b-alert>
            <digital-learning-action-toolbar
              v-if="request"
              :request="request"
              :edit="editMode"
              :can-edit="canEdit"
              @on-edit="editMode = true"
              @on-save="save()"
              @on-submit="submit()"
              @on-cancel="cancel()"
              :can-save="$v.$anyDirty"
              :hide-submit="true"
              :is-valid="!$v.$invalid"
              :save-label="'Save'"
            />
            <digital-learning-waiver-content :edit="editMode" v-if="request" v-model="request" />
          </div>
          <div class="col-md-3">
            <div v-if="isSystemAdministrator && selectedWaiverRequest.status > 2" class="d-print-none mb-2 text-center">
              <p>
                Admin revert from <strong>{{ getStatusText(currentStatus) }}</strong> to <strong>{{ lastWorkflowStatus }}</strong>: 
              </p>
              <animated-button class="mx-1 btn btn-danger text-center" :promise-resolver="confirmRevertLastWorkflowChange">
                Revert to <strong>{{ lastWorkflowStatus }}</strong>
              </animated-button>
            </div>
            <comment-waiver-request-widget :request="request" v-if="isReviewer || isApprover" />
            <reopen-waiver-request-widget :request="request" v-if="canReopen" />
            <workflow-change-widget :request="request" v-if="isReviewer || isApprover" />
            <change-log-list-widget :request="request" />
          </div>
        </div>
        <div v-else>
          <program-options-widget :lea="resolvedLea" :context="Context.Review" />
        </div>
      </template>
      <template v-slot:rejected="error" class="align-items-center justify-content-center">
        <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
      </template>
    </Promised>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Promised } from 'vue-promised'
import CardStateIcon from '@/components/CardStateIcon.vue'
import { PageMixin } from '@/components/PageMixin'
import { LeaTypes } from '@/helpers/leaTypes'
import { Types, Context, WaiverTypes } from '@/stores/digitalLearning'
import { DigitalLearningWaiverMixin } from './DigitalLearningWaiverMixin'
import ChangeLogListWidget from './workflow/ChangeLogListWidget'
import WorkflowChangeWidget from './workflow/WorkflowChangeWidget'
import DigitalLearningActionToolbar from './workflow/DigitalLearningActionToolbar'
import CommentWaiverRequestWidget from './workflow/CommentWaiverRequestWidget.vue'
import WaiverPageHeader from './details/WaiverPageHeader.vue'
import ProgramOptionsWidget from './details/ProgramOptionsWidget.vue'
import AnimatedButton from '@/components/AnimatedButton.vue'

export default {
  data() {
    return {
      Context,
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    canEdit() {
      return Boolean(this.selectedWaiverRequest) 
        && this.selectedWaiverRequest.status < 100 
        && this.selectedWaiverRequest.status > 0 
        && this.isApprover
    },
    // Reviewers only have Comment and Return state permissions
    isReviewer() {
      if (this.selectedWaiverRequest.waiverType == WaiverTypes.AMI.value) {
        return this.userContext.roles.includes('Insights_AMI_Reviewers') || this.isSystemAdministrator
      }
      return this.userContext.roles.includes('Insights_Act1240_Reviewers') || this.isSystemAdministrator
    },
    isApprover() {
      return this.userContext.roles.includes('Insights_Act1240_Approvers') || this.isSystemAdministrator
    },
    isSystemAdministrator() {
      return this.userContext.roles.includes('Insights_System_Administrators')
    },
    lastWorkflowStatus() {
      let changeLogs = [...(this.selectedWaiverRequest.changeLogs || [])]
      let lastChange = (changeLogs.pop() || {})
      const status = this.StateMap.get(lastChange.fromStatus || 0).status
      return typeof status === 'function' ? status() : status
    }
  },
  mixins: [PageMixin, DigitalLearningWaiverMixin],
  methods: {
     getStatusText(state) {
      if (state && typeof state.status === 'function') {
        return state.status(this.selectedWaiverRequest)
      }
      return state.status
    },
    backToRequestList() {
      this.$router.push({ name: 'admin-waiver-request-list' })     
    },
    confirmRevertLastWorkflowChange() {
      return this.$bvModal.msgBoxConfirm(`Are you sure you want to remove the last workflow change? This action is not reversible.`)
        .then(result => { 
          if (result) 
            this.$store.dispatch(Types.actions.revertLastWorkflowChange, { id: this.waiverRequestId }) 
        })
    }
  },
   watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if (newLea && newLea.number) {
          if (newLea.type == LeaTypes.School) {
            this.resolvedLea = {...this.leaDictionary[newLea.number].parentLea}
          }
          else this.resolvedLea = {...newLea}
          this.loadData = this.$store.dispatch(Types.actions.getWaiverRequests, { lea: this.resolvedLea.number })
        }
      }
    },
  },
  components: {
    Promised,
    CardStateIcon,
    ChangeLogListWidget,
    WorkflowChangeWidget,
    DigitalLearningActionToolbar,
    WaiverPageHeader,
    ProgramOptionsWidget,
    CommentWaiverRequestWidget,
    AnimatedButton,
  }
}
</script>

<style>

</style>
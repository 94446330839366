<template>
  <div>
    <div ref="essaTemplate" id="essaTemplate" />

    <h5 class="text-uppercase text-dark">
      <b>Understanding Your Value-Added Growth Reports</b>
    </h5>
    <hr>
    <div class="row row-cols-md-2 gutters-sm">
      <div class="col-md-3">
        <figure>
          <img class="image-fluid" height="auto" width="240" src="/Content/ade-logo-only.png">
        </figure>
      </div>
      <div class="col-md-8">
        <p class="lead">
          <br>Your school Value-Added Growth Report provides information about your students’ growth in achievement as measured by the value-added growth model approved for use in Arkansas’s ESSA plan. This report provides several different ways to view and analyze your students’ growth scores. The following views are available:
        </p>
      </div>
    </div>
    <ul class="text-secondary font-size-md font-weight-light">
      <li>School Value-Added Growth and Weighted Achievement Scatterplot for Grade Span</li>
      <li>Student Value-Added Growth and ACT Aspire Scale Score Scatterplots for Grade, Subject, and/or Subgroup</li>
      <ul>
        <li>Summary Tables for School Growth by Grade, Subgroup and Grade/Subgroup including averages, standard deviation, minimum and maximum for each tabulation (grade or subgroup) or cross-tabulation of grade/subgroup.</li>
      </ul>
      <li>School Average Value-Added Growth by Readiness Level</li>
      <ul>
        <li>Summary Table for School Growth by Readiness Level</li>
        <li>Distribution of Student Growth by Readiness Level</li>
        <li>Student Table for Growth by Readiness Level</li>
      </ul>
      <li>School Average Value-Added Growth Trends</li>
      <li>Student Value-Added Growth and ACT Aspire Scale Score Trend Table (view and download.xlsx)</li>
    </ul>
    <p class="text-secondary font-size-md font-weight-light">
      Your students’ value-added growth scores are presented on a standard scale so they can be interpreted like a z-score[1] . Value-added growth scores at the student level are the difference between the standard score the student was expected to achieve and the actual standard score the student achieved.
    </p>

    <p class="lead">
      To understand student-level value-added growth it is more intuitive to think about a standard scale where
    </p>
    <ul class="text-secondary font-size-md font-weight-light">
      <li>0 indicates that a student met their expected growth,</li>
      <li>values greater than zero indicate a student exceeded expected their expected growth, and</li>
      <li>values less than zero indicate a student did not meet their expected growth.</li>
    </ul>
    <br>
    <img src="/Content/growth/range.png" class="img-fluid mx-auto d-block" align="center" alt>
    <br>
    <p
      class="lead"
    >
      The standard scale captures the full range of student-level value-added growth scores. The standard scale at the student level allows you to interpret the magnitude of students’ value-added growth scores relative to the expected student growth for their grade level.
    </p>
    <ul class="text-secondary font-size-md font-weight-light">
      <li>A student with a value-added growth score of 0.5000 is half a standard deviation above their expected growth.</li>
      <li>A student with a value-added growth score of -1.25 is more than one standard deviations below their expected growth.</li>
      <li>The further students are from 0 the greater the magnitude of their loss in achievement (negative values) or growth in achievement (positive values)</li>
    </ul>

    <small class="text-secondary">
      [1] The standard scale is used at the student level because the transformation for the school-level ESSA School Index score is scaled to work with school-level value-added growth scores.
    </small>
  </div>
</template>

<script>

export default {
  name: 'growth-home',
  data() {
    return { }
  },
  components: { },
  mounted() {
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
.fade:not(.show) {
    opacity: 1 !important;
}
</style>
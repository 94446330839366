import Home from './home'
import TransInspectionItems from './transInspectionItems'
import Buses from '../../../dpsaft/transportation/Buses.vue'
import Staff from './staff'

const TransportationAdmin = {
  Home,
  TransInspectionItems,
  Buses,
  Staff
}

export { TransportationAdmin }
<template>
  <div class="card bg-light">
    <div class="card-header lead">
      Academic Progress
    </div>
    <a class="anchor" id="academic-progress" />
    <b-tabs class="m-3" content-class="mt-1" pills>
      <b-tab title="All Students" active title-item-class="d-print-none">
        <div class="row" v-for="demographic in allStudents" :key="'ela' + demographic">
          <div class="col-12" v-if="hasDataForDemographic(elaGrowth, demographic)">
            <single-metric-schools-chart title="ELA Growth" :subgroup="demographic" mode="trend" :report-data="elaGrowth" :load-data="loadData" :key="'ap-ela-schools-' + demographic" />
          </div>
        </div>
        <div class="row" v-for="demographic in allStudents" :key="'math' + demographic">
          <div class="col-12" v-if="hasDataForDemographic(mathGrowth, demographic)">
            <single-metric-schools-chart title="Math Growth" :subgroup="demographic" mode="trend" :report-data="mathGrowth" :load-data="loadData" :key="'ap-math-schools-' + demographic" />
          </div>
        </div>
      </b-tab>
      <b-tab title="By Subpopulation" title-item-class="d-print-none">
        <div class="row" v-for="(demographic, index) in demographics" :key="'ela' + demographic + 'subpop'" :class="{ 'page-break-before': index % 2 == 0 }">
          <div class="col-12" v-if="hasDataForDemographic(elaGrowth, demographic)">
            <single-metric-schools-chart title="ELA Growth" :subgroup="demographic" mode="trend" :report-data="elaGrowth" :load-data="loadData" :key="'ap-ela-schools-' + demographic" />
          </div>
        </div>
        <div class="row" v-for="(demographic, index) in demographics" :key="'math' + demographic + 'subpop'" :class="{ 'page-break-before': index % 2 == 0 }">
          <div class="col-12" v-if="hasDataForDemographic(mathGrowth, demographic)">
            <single-metric-schools-chart title="Math Growth" :subgroup="demographic" mode="trend" :report-data="mathGrowth" :load-data="loadData" :key="'ap-math-schools-' + demographic" />
          </div>
        </div>
      </b-tab>
    </b-tabs>

    <div class="card-body mb-0 d-print-none">
      <h3>
        Long-term Goal Progress
      </h3>

      <b-select v-model="schoolLea" :options="schoolLeas" />
    
      <b-tabs class="" content-class="mt-1" pills>
        <b-tab title="Achievement" active title-item-class="d-print-none">
          <long-term-goals-viewer report-type="achievement" :lea-number="schoolLea" />
        </b-tab>
        <b-tab title="Graduation" title-item-class="d-print-none">
          <long-term-goals-viewer report-type="graduation" :lea-number="schoolLea" />
        </b-tab>
        <b-tab title="English Learners" title-item-class="d-print-none">
          <long-term-goals-viewer report-type="englishlearners" :lea-number="schoolLea" />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { LeaTypes } from '@/helpers/leaTypes'
import { mapState } from 'vuex'
import { Types } from '@/modules/esa/stores/survey'
import SingleMetricSchoolsChart from '../SingleMetricSchoolsChart.vue'
import LongTermGoalsViewer from './LongTermGoalsViewer'

const allStudents = ['All Students']
const demographics = [
  'African-American',
  'Caucasian',
  'Economically Disadvantaged',
  'English Learner',
  'Hispanic',
  'Special Ed',
]
export default {
  data() {
    return { 
      loadData: null,
      allStudents,
      demographics,
      schoolLea: null
    }
  },
  computed: {
    ...mapState(Types.path, [
      Types.state.elaGrowth,
      Types.state.mathGrowth,
    ]),
    schoolLeas() {
      return this.selectedLea.ownedLeas.map((lea) => { return { text: lea.name, value: lea.number }})
    }
  },
  components: {
    SingleMetricSchoolsChart,
    LongTermGoalsViewer
  },
  methods: {
    hasDataForDemographic(values, demographic) {
      return values.filter(d => d.dataLevel == LeaTypes.District && d.demographic == demographic).length > 0
    }
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if (newLea && newLea.number) {
          this.loadData = Promise.all([
            this.$store.dispatch(Types.actions.getElaGrowth, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getMathGrowth, { lea: newLea.number }),
          ])
          this.schoolLea = this.schoolLeas[0].value
        }
        else {
          this.schoolLea = null
        }
      }
    }
  }
}

</script>
<template>
  <step-container :academic-year="Number($route.params.ay)" task-code="AYENABLE" title="Enable Perkins V Academic Year" :pre-completion-routine="preAYEnableTask">
    <span />
    <template slot="stepCompletionInstructions">
      <b-alert show variant="warning">
        Please ensure that all preliminary steps have been completed successfully prior to enabling the Academic Year. Any future changes to the required startup data could potentially render 
        funding applications inaccurate and/or obsolete. 
        <p class="mt-3">
          Once you are satisfied, click the button below to enable the Academic Year. Once enabled, Consortial will be able to submit applications for the Academic Year.
        </p>
      </b-alert>
    </template>
  </step-container>
</template>

<script>
import { mapActions } from 'vuex'
import { Types } from '@/modules/cte/stores/perkinsv'
import StepContainer from './StepContainer'

export default {
  methods: {
    ...mapActions(Types.path, ['enableAY']),
    preAYEnableTask() {
      console.log('Performing pre-AYENABLE tasks')
      this.enableAY({ay: this.$route.params.ay})
    }
  },
  components: {
    StepContainer
  }
}
</script>
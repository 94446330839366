import { surveyTemplate } from '../components/blendedLearningReadiness/arkansasReadyForLearning/surveyTemplate'

function aggregateResults(responseRecords, collectedQuestions) {
  collectedQuestions.forEach(q => {
    responseRecords.forEach(response => {
      var district = response.district
      response = response.responseData

      var responseKeys = Object.keys(response)

      if (responseKeys.includes(q.id)) {
        //process the results
        if (q.component.startsWith("QuestionYesno")) {
          var result
          if (response[q.id] === 1) result = 'Yes'
          else if (response[q.id] === 10) result = 'Indeterminate'
          else result = "No"
          q.choices[result]++
          q.details.push ({ district: district, surveyResponse: result  })

        } else if (q.component.includes("Selection")) {
          if (Array.isArray(response[q.id])) {
            response[q.id].forEach(sel => {
              q.choices[sel]++
              q.details.push ({ district: district, surveyResponse: sel  })
            })
          } else if (q.component == "QuestionSingleSelection") {
            q.choices[response[q.id]]++
            q.details.push({ district: district, surveyResponse: response[q.id] })
          } else {
            q.choices[response[q.id]]++
            q.details.push ({ district: district, surveyResponse: q.id  })
          }
        }
      } else {
        //aggregate the No Answer 
        q.choices['No answer']++
        q.details.push ({ district: district, surveyResponse: "No answer"  })
      }
    })
  })
}

function acquireQuestions(sectionContainer, questionCollector) {
  collectQuestions(sectionContainer.questions, questionCollector)
  if (sectionContainer.sections) {
    sectionContainer.sections.forEach(s => {
      acquireQuestions(s, questionCollector)
    })
  }
}

function collectQuestions(questions, questionCollector) {
  if (questions) {
    questions.forEach(q => {
      if (q.component != "QuestionFreeText" && q.component != "QuestionHeader") {
        questionCollector.push({
          id: q.id,
          component: q.component,
          text: q.text,
          details: [],
          choices: q.component.startsWith("QuestionYesno") ? {
            "Yes": 0,
            "No": 0,
            "No answer": 0,
            "Indeterminate": 0
          } : q.component.includes("Selection") ? Object.fromEntries([
            [
              ["No answer"], 0
            ], ...q.choices.map((c) => {
              return [
                [c], 0
              ]
            })
          ]) : {}
        })
      }
    })
  }
}

function aggregateResponseResults(responseData) {
  var questions = [];
  acquireQuestions(surveyTemplate, questions)
  aggregateResults(responseData, questions)
  return questions
}

export {
  aggregateResponseResults
}
import Vue from 'vue'
import LeaDisplay from '../../LeaDisplay.vue'

const PredictorModelPage = Vue.extend({
    name: 'predictor-model-page',
    data() {
        return {
            
        }
    },
    components: {
      LeaDisplay  
    },
    computed: {
        selectedLea() { return this.$store.state.globalModule.leaContext.SelectedLea }
    },
    methods: {
        
    }
})
export { PredictorModelPage }
<template lang="html">
  <div class="d-inline">
    <Promised :promise="actionPromise" v-show="actionPromise">
      <template v-slot:pending>
        <button disabled="disabled" @click="exportExcel" class="btn btn-success d-print-none mr-1" :class="{ 'float-right': !noFloatRight }">
          <i class="fas fa-fw fa-spinner fa-spin" />&nbsp;Excel
        </button>
      </template>
      <template v-slot="data">
        <button @click="exportExcel" class="btn btn-success d-print-none mr-1" :class="{ 'float-right': !noFloatRight }">
          <i class="far fa-fw fa-file-excel" />&nbsp;Excel
          <span class="d-none">
            {{ data }}
          </span>
        </button>
      </template>
      <template v-slot:rejected="error">
        <button @click="exportExcel" class="btn btn-success d-print-none mr-1" :class="{ 'float-right': !noFloatRight }">
          <i class="fas fa-fw fa-exclamation-circle" />&nbsp;Excel
          <span class="">
            {{ error }}
          </span>
        </button>
      </template>
    </Promised>
    <button @click="exportExcel" class="btn btn-success d-print-none mr-1" :class="{ 'float-right': !noFloatRight }" v-show="actionPromise == null">
      <i class="far fa-fw fa-file-excel" />&nbsp;Excel
    </button>
  </div>
</template>

<script>
import { Promised } from 'vue-promised'
import { deepClone } from '../helpers/globalHelpers'
import ExcelService from '../services/ExcelService'
import ApiService from '../services/ApiService'
const apiService = new ApiService()
const chartTableClassName = 'chart-data-table'

export default {
  name: 'excel-button',
  data() {
    return {
      actionPromise: null
    }
  },
  props: {
    title: String,
    url: String,
    skipComponentCheck: {
      type: Boolean,
      default: false
    },
    payload: {
      type: Object,
      default: null
    },
    noFloatRight: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    async exportExcel() {
      if (this.url) {
        this.actionPromise = apiService.DownloadWithPost(this.url, this.payload)
      }
      else {
        const excelService = new ExcelService()
        const lea = this.$store.state.globalModule.leaContext.SelectedLea.number || ''
        const workbookName = `${this.title}-${lea}`
        var workbook = await excelService.createWorkbook(workbookName)

        var worksheetData = []

        //get any exportable data from the parent control
        this.getTableData(this.$parent, worksheetData)

        this.$parent.$children.forEach(child => {
          this.getTableData(child, worksheetData)
          this.traverseChildren(child, worksheetData)
        })

        // get unique worksheets
        const worksheets = worksheetData.reduce((acc, cur) => {
          if (!acc.find(x => x.name === cur.name)) {
            acc.push(cur)
          }
          return acc
        }, [])

        worksheets.forEach(async sheet => {
          await excelService.addWorksheet(workbook, sheet.name.replace('/', '').substr(0, 31), sheet.items)
        })

        this.actionPromise = excelService.saveFile(workbook, `${workbookName}.xlsx`)
      }
    },
    traverseChildren(component, worksheetData) {
      component.$children.forEach(child => {
        this.getTableData(child, worksheetData) 

        if ((this.skipComponentCheck && child.excelExportData === 'function') || child.constructor.options.name === chartTableClassName) {
          worksheetData.push({
            name: child.title || child.$parent.$parent.$children[0].$children[1].options.title.text,
            items: deepClone(child.items)
          })
        }
        this.traverseChildren(child, worksheetData)
      })
    },
    getTableData(component, worksheetData) {
      if (typeof component.excelExportData === 'function') {
        //console.log('Exporting ' + component.constructor.options.name)
        const data = component.excelExportData()
        if (Array.isArray(data)) {
          data.forEach(d => {
            worksheetData.push(d)
          })
        }
        else {
          worksheetData.push(data)
        }
      }
    }
  },
  components: {
    Promised
  }
}
</script>
<template>
  <div>
    <div>
      <h1>Notifications</h1>
    </div>
    <div>
      <paginated-table hover :items="notifications" :fields="fields" :load-data="loadData" :tbody-tr-class="rowClass">
        <template v-slot:cell(leas)="data">
          <div>
            <span v-for="lea in (data.value || [])" :key="lea">
              {{ leaFormatter(lea) }}
            </span>
          </div>
        </template>
        <template v-slot:cell(discriminator)="data">
          <span v-if="data.value">
            {{ data.value.split(/(?=[A-Z])/).join(' ') }}
          </span>
        </template>
        <template v-slot:cell(recipients)="data">
          <div v-if="data.value">
            <p v-for="recipient in data.value.split(';')" :key="recipient">
              {{ recipient }}
            </p>
          </div>
        </template>
        <template v-slot:cell(createdDate)="data">
          <span v-if="data.value">
            {{ data.value | date }}
          </span>
        </template>
        <template v-slot:cell(sentDate)="data">
          <span v-if="data.value">
            {{ data.value | date }}
          </span>
        </template>
        <template v-slot:cell(debugIntercepted)="data">
          <i class="fas fa-check" v-if="data.value" />
        </template>
        <template v-slot:cell(action)="data">
          <animated-button class="btn btn-sm btn-primary" :promise-resolver="viewMessage.bind(null,data.item)">
            View <i class="ml-1 fa fa-envelope-open-text" />
          </animated-button>
          <button class="mx-1 btn btn-sm btn-secondary" @click="viewJob(data.item)" v-if="data.item.hangfireJobId">
            Job Logs <i class="ml-1 fa fa-cogs" />
          </button>
        </template>
      </paginated-table>
    </div>
    <b-modal id="job-modal" size="xl" :title="(selectedJob.job || {}).jobName">
      <dl>
        <dt>Arguments:</dt>
        <dd class="col-sm-10">
          <pre>
            {{ (selectedJob.job || {}).args }}
          </pre>
        </dd>
      </dl>
      <h4>Logs:</h4>
      <div class="card card-body bg-light">
        <pre>
          <span v-for="log in selectedJob.jobLogs" :key="JSON.stringify(log)">
            {{ log.text }}
          </span>
        </pre>
      </div>
    </b-modal>
    <b-modal id="message-modal" size="xl" :title="selectedNotification.subject" scrollable>
      <div class="row">
        <div class="col-12">
          <dl>
            <dt>
              Recipients:
            </dt>
            <dd>
              {{ selectedNotification.recipients }}
            </dd>
          </dl>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12">
          <div v-html="selectedNotification.body" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { PageMixin } from '../../PageMixin'
import PaginatedTable from '@/components/PaginatedTable.vue'
import AnimatedButton from '@/components/AnimatedButton.vue'
import ApiService from '@/services/ApiService'

const apiService = new ApiService()

export default {
  mixins: [PageMixin],
  components: {
    PaginatedTable,
    AnimatedButton
  },
  data() {
    return {
      loadData: Promise.resolve(),
      selectedNotification: {},
      selectedJob: {},
    }
  },
  computed: {
    ...mapState('adminModule', ['notifications']),
    fields() {
      return [
        { key: 'genericNotificationId', label: 'Id', sortable: true },
        { key: 'discriminator', label: 'Type', sortable: true },
        { key: 'recipients', label: 'Recipient(s)', sortable: true },
        { key: 'subject', label: 'Subject', sortable: true },
        { key: 'createdBy', label: 'Created By', sortable: true },
        { key: 'createdDate', label: 'Created On', sortable: true },
        { key: 'sentDate', label: 'Sent', sortable: true },
        { key: 'debugIntercepted', label: 'Intercepted', sortable: true },
        { key: 'action', label: '' },
      ]
    },
  },
  methods: {
    setSelectedState(state) {
      this.selectedState = state
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.sentDate) return 'table-success'
    },
    viewMessage(notification) {
      const self = this
      return apiService.Get(`/api/notification/${notification.genericNotificationId}`).then(data => {
        self.selectedNotification = data
        self.$bvModal.show('message-modal')
      })
    },
    viewJob(notification) {
      const self = this
      return apiService.Get(`/api/jobs/details/${notification.hangfireJobId}`).then(data => {
        self.selectedJob = data
        self.$bvModal.show('job-modal')
      })
    }
  },
  created() {
    this.loadData = this.$store.dispatch('adminModule/getNotifications')
  }
}
</script>

<style>

</style>
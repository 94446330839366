<template>
  <div>
    <list v-if="mode=='list'" @on-edit-initiated="mode='edit'" @on-add-initiated="mode='add'" set="programsOfStudy" />
    <edit v-if="mode != 'list'" @on-action-complete="mode='list'" :mode="mode" set="programsOfStudy" />
  </div>
</template>

<script>
import Edit from './Edit'
import List  from './List'
export default {
  data() {
    return {
      mode: 'list'
    }
  },
  watch: {
    mode(newMode) {
      if (newMode != 'list') {
        this.$emit('on-editing')
      } else {
        this.$emit('on-edit-complete')
      }
    }
  },
  components: {
    Edit,
    List
  }
}
</script>
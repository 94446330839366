import ApiService from '@/services/ApiService'
import removeNamespace from '../removeNamespace'
import { deepClone } from '@/helpers/globalHelpers'
import Vue from 'vue'
import store from '@/stores'

export function isExpired(waiverRequest) {
  if (waiverRequest && waiverRequest.status == 2 && waiverRequest.durationSchoolYears < 2) 
    return true

  return false
}

export function isReopened(waiverRequest) {
  if (waiverRequest && waiverRequest.status == 2 && waiverRequest.durationSchoolYears > 1) 
    return true

  return false
}

export const StateMap = new Map();
StateMap.set(0, {
  code: 0,
  status: 'Not Submitted',
  action: 'Return to Draft',
  supportsAssignment: true,
  requiresComment: false,
  variant: 'secondary'
})
StateMap.set(1, {
  code: 1,
  status: 'Information or Changes Requested',
  action: (waiverRequest) => (waiverRequest || {}).status > 11 ? 'Unlock for District Changes' : 'Return to Applicant',
  supportsAssignment: true,
  requiresComment: true,
  variant: 'warning'
})
StateMap.set(2, {
  code: 2,
  status: (waiverRequest) => {
    if (isExpired(waiverRequest)) 
      return 'Expired'
    else if (waiverRequest)
      return 'Re-opened'

    return 'Re-opened / Expired'
  },
  action: 'Re-open',
  supportsAssignment: true,
  requiresComment: false,
  hide: true,
  variant:  (waiverRequest) => {
    if (isExpired(waiverRequest)) 
      return 'danger'

    return 'primary'
  },
})
// StateMap.set(10, {
//   code: 10,
//   action: 'Submit',
//   status: 'Submitted',
//   variant: 'primary'
// })
StateMap.set(11, {
  code: 11,
  action: 'Submit to ADE DESE',
  status: 'Submitted to ADE DESE',
  requiresComment: false,
  variant: 'primary',
})
StateMap.set(100, {
  code: 100,
  action: 'Approve',
  status: 'Reviewed',
  requiresComment: false,
  variant: 'info'
})
StateMap.set(101, {
  code: 101,
  action: (waiverRequest) => (waiverRequest || {}).waiverType == WaiverTypes.DigitalLearning.value ? 'Mark as SBE Approved' : 'Approve',
  status: (waiverRequest) => (waiverRequest || {}).waiverType == WaiverTypes.DigitalLearning.value ? 'SBE Approved' : 'Approved',
  requiresComment: true,
  variant: 'success'
})
StateMap.set(255, {
  code: 255,
  action: 'Rescind',
  status: 'Rescinded',
  requiresComment: false,
  variant: 'warning'
})

export const WaiverTypes = {
  DigitalLearning: {
    color: '#1f6f8b',
    title: 'Act 1240 Digital Learning Waiver Request',
    value: 1,
    listTitle: 'Act 1240 Digital Learning Waiver Requests'
  },
  AMI: {
    color: '#583d72',
    title: 'Alternative Methods of Instruction (AMI) Application',
    value: 2,
    listTitle: 'AMI Applications'
  }
}

export const Context = {
  District: 0,
  Review: 1,
  Public: 2,
  Snapshot: 3,
}

export const Types = {
  state: {
    waiverRequests: 'waiverRequests',
    selectedWaiverRequest: 'selectedWaiverRequest',
    availableStates: 'availableStates',
    districtUsers: 'districtUsers',
    newDLWaiverRequestsLockedStatus: 'newDLWaiverRequestsLockedStatus',
    newAMIApplicationsLockedStatus: 'newAMIApplicationsLockedStatus',
    snapshots: 'snapshots',
  },
  actions: {
    getWaiverRequests: 'digitalLearning/getWaiverRequests',
    getApprovedWaiverRequests: 'digitalLearning/getApprovedWaiverRequests',
    getWaiverRequest: 'digitalLearning/getWaiverRequest',
    getAvailableStates: 'digitalLearning/getAvailableStates',
    transitionToState: 'digitalLearning/transitionToState',
    createWaiverRequest: 'digitalLearning/createWaiverRequest',
    updateWaiverRequest: 'digitalLearning/updateWaiverRequest',
    assignOrComment: 'digitalLearning/assignOrComment',
    getDistrictUsers: 'digitalLearning/getDistrictUsers',
    requestWithdrawal: 'digitalLearning/requestWithdrawal',
    revertLastWorkflowChange: 'digitalLearning/revertLastWorkflowChange',
    getLockedStatus: 'digitalLearning/getLockedStatus',
    toggleLockedStatus: 'digitalLearning/toggleLockedStatus',
    getWaiverRequestSnapshots: 'digitalLearning/getWaiverRequestSnapshots',
    getWaiverRequestSnapshot: 'digitalLearning/getWaiverRequestSnapshot',
    reOpenWaiverRequest: 'digitalLearning/reOpenWaiverRequest',
  },
  mutations: {
    setWaiverRequests: 'digitalLearning/setWaiverRequests',
    setSelectedWaiverRequest: 'digitalLearning/setSelectedWaiverRequest',
    setAvailableStates: 'digitalLearning/setAvailableStates',
    setSelectedWaiverRequestChangeLogs: 'digitalLearning/setSelectedWaiverRequestChangeLogs',
    setDistrictUsers: 'digitalLearning/setDistrictUsers',
    setnewDLWaiverRequestsLockedStatus: 'digitalLearning/setnewDLWaiverRequestsLockedStatus',
    setnewAMIApplicationsLockedStatus: 'digitalLearning/setnewAMIApplicationsLockedStatus',
    setSnapshots: 'digitalLearning/setSnapshots',
  },
  getters: {
  },
  path: 'digitalLearning'
}
const _types = removeNamespace('digitalLearning/', Types)

function createLeaParam(payload) {
  return payload.lea || 'AR'
}

function populateSuperintendent(waiverRequest) {
  if (waiverRequest && !waiverRequest.waiverRequestSnapshotId) {
    const district = store.getters['globalModule/leaDictionary'][waiverRequest.districtLEA]
    if (district && district.adminLastName) {
      waiverRequest.superintendent = `${district.adminFirstName} ${district.adminLastName}`
      waiverRequest.email = district.adminEmailAddress
      waiverRequest.phone = district.adminPhoneNumber
    }
  }
  return waiverRequest
}

export class DigitalLearning {
  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
  }

  namespaced = true

  state = {
    [Types.state.waiverRequests]: [],
    [Types.state.selectedWaiverRequest]: null,
    [Types.state.availableStates]: {},
    [Types.state.districtUsers]: [],
    [Types.state.newDLWaiverRequestsLockedStatus]: false,
    [Types.state.newAMIApplicationsLockedStatus]: false,
    [Types.state.snapshots]: [],
  }

  mutations = {
    [_types.mutations.setWaiverRequests]: (state, data) => {
      state.waiverRequests = data.map(populateSuperintendent)
    }  /* mockWaivers */,
    [_types.mutations.setSelectedWaiverRequest]: (state, data) => {
      if (data) {
        data = populateSuperintendent(data)
        if (data.programAffectedLeasResponse) {
          data.programAffectedLeasResponse = JSON.parse(data.programAffectedLeasResponse || '[]')
        }
        if (data.waiverTopicsResponse) {
          data.waiverTopicsResponse = JSON.parse(data.waiverTopicsResponse || '{}')
        }
        if (data.digitalModelResponse) {
          data.digitalModelResponse = JSON.parse(data.digitalModelResponse || '{}')
        }
        if (data.amiResponse) {
          data.amiResponse = JSON.parse(data.amiResponse || '{}')
        }
      }
      state.selectedWaiverRequest = data
    },
    [_types.mutations.setSelectedWaiverRequestChangeLogs]: (state, data) => {
      if (data && data.changeLogs) {
        Vue.set(state.selectedWaiverRequest, 'changeLogs', data.changeLogs)
      }
    },
    [_types.mutations.setAvailableStates]: (state, data) => state.availableStates = data,
    [_types.mutations.setDistrictUsers]: (state, data) => state.districtUsers = data,
    [_types.mutations.setnewDLWaiverRequestsLockedStatus]: (state, data) => state.newDLWaiverRequestsLockedStatus = data,
    [_types.mutations.setnewAMIApplicationsLockedStatus]: (state, data) => state.newAMIApplicationsLockedStatus = data,
    [_types.mutations.setSnapshots]: (state, data) => state.snapshots = data,
  }

  getters = {
  }

  actions = {
    [_types.actions.getWaiverRequests]: ({ commit, dispatch }, payload) => {
      dispatch(_types.actions.getLockedStatus)
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/digitalLearning/waiver?lea=${createLeaParam(payload)}`))
        .then(data => commit(_types.mutations.setWaiverRequests, data))
    },
    // eslint-disable-next-line 
    [_types.actions.getApprovedWaiverRequests]: ({ commit, dispatch }, payload) => {
      dispatch(_types.actions.getLockedStatus)
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/digitalLearning/waiver/approved?lea=${createLeaParam(payload)}`))
        .then(data => commit(_types.mutations.setWaiverRequests, data))
    },
    [_types.actions.getWaiverRequest]: ({ commit, dispatch }, payload) => {
      payload.waiverRequestId = payload.id
      dispatch(_types.actions.getWaiverRequestSnapshots, payload)
      dispatch(_types.actions.getLockedStatus)
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/digitalLearning/waiver/${payload.id}?lea=${createLeaParam(payload)}`))
        .then(data => {
          if (data && data.waiverRequestId) {
            commit(_types.mutations.setSelectedWaiverRequest, data)
            return dispatch(_types.actions.getAvailableStates, { id: data.waiverRequestId, lea: data.districtLEA })
          }
          else {
            commit(_types.mutations.setSelectedWaiverRequest, data)
          }
          return data
        })
    },
    [_types.actions.getAvailableStates]: ({ commit }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/digitalLearning/waiver/${payload.id}/state?lea=${createLeaParam(payload)}`))
        .then(data => {
          commit(_types.mutations.setAvailableStates, data)
          return data
        })
    },
    [_types.actions.transitionToState]: ({ dispatch }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/digitalLearning/waiver/${payload.id}/state?lea=${createLeaParam(payload)}`), payload).then(() =>  {
        return dispatch(_types.actions.getWaiverRequest, payload)
      })
    },
    [_types.actions.createWaiverRequest]: ({ commit, dispatch }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/digitalLearning/waiver?lea=${createLeaParam(payload)}&waiverType=${payload.waiverType}`))
        .then(data => {
          dispatch(_types.actions.getAvailableStates, { id: data.waiverRequestId, lea: data.districtLEA })
          commit(_types.mutations.setSelectedWaiverRequest, data)
          return data
        })
    },
    [_types.actions.updateWaiverRequest]: ({ commit, dispatch }, payload) => {
      var cloned = deepClone(payload)
      if (cloned.programAffectedLeasResponse) cloned.programAffectedLeasResponse = JSON.stringify(cloned.programAffectedLeasResponse || [])
      if (cloned.waiverTopicsResponse) cloned.waiverTopicsResponse = JSON.stringify(cloned.waiverTopicsResponse || {})
      if (cloned.digitalModelResponse) cloned.digitalModelResponse = JSON.stringify(cloned.digitalModelResponse || {})
      if (cloned.amiResponse) cloned.amiResponse = JSON.stringify(cloned.amiResponse || {})

      return this._apiService.Put(encodeURI(`${this._baseUrl}api/digitalLearning/waiver/${cloned.waiverRequestId}?lea=${cloned.districtLEA}`), cloned)
        .then(data => {
          if (data && data.waiverRequestId) {
            dispatch(_types.actions.getWaiverRequestSnapshots, payload)
            dispatch(_types.actions.getAvailableStates, { id: data.waiverRequestId, lea: data.districtLEA })
          }
          commit(_types.mutations.setSelectedWaiverRequest, data)
        })
    },
    [_types.actions.assignOrComment]: ({ commit }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/digitalLearning/waiver/${payload.id}/comment?lea=${createLeaParam(payload)}`), {
        AssignedToName: payload.assignToName,
        AssignedToUserId: payload.assignToUserId,
        Comment: payload.comment
      }).then((data) =>  {
        commit(_types.mutations.setSelectedWaiverRequestChangeLogs, data)
        return data
      })
    },
    [_types.actions.requestWithdrawal]: ({ dispatch }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/digitalLearning/waiver/${payload.id}/withdraw?lea=${createLeaParam(payload)}`), payload.comment).then((data) =>  {
        return dispatch(_types.actions.getWaiverRequest, { id: data.waiverRequestId, lea: data.districtLEA })
      })
    },
    [_types.actions.revertLastWorkflowChange]: ({ dispatch }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/digitalLearning/waiver/${payload.id}/revert?lea=${createLeaParam(payload)}`)).then((data) =>  {
        return dispatch(_types.actions.getWaiverRequest, { id: data.waiverRequestId, lea: data.districtLEA })
      })
    },
    [_types.actions.getDistrictUsers]: ({ commit }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/leauser/lea/${createLeaParam(payload)}`)).then((data) => {
        const districtUsers =  [{
          value: null,
          text: 'Select a district user'
        }].concat(data.map(user => { 
          return { value: { assignToName: user.fullName, assignToUserId: `APSCN\\${user.userId}` }, text: `${user.fullName} (${user.userId})` }
        }))
        commit(_types.mutations.setDistrictUsers, districtUsers)
      })
    },
    [_types.actions.getLockedStatus]: ({ commit }) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/digitalLearning/locked`))
        .then(data => {
          commit(_types.mutations.setnewDLWaiverRequestsLockedStatus, data.digitalLearning)
          commit(_types.mutations.setnewAMIApplicationsLockedStatus, data.ami)
          return data
        })
    },
    [_types.actions.toggleLockedStatus]: ({ commit }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/digitalLearning/locked/${payload.waiverType}`))
        .then(data => {
          if (payload.waiverType == WaiverTypes.DigitalLearning.value)
            commit(_types.mutations.setnewDLWaiverRequestsLockedStatus, data)
          else 
            commit(_types.mutations.setnewAMIApplicationsLockedStatus, data)
          return data
        })
    },
    [_types.actions.getWaiverRequestSnapshots]: ({ commit }, payload) => {
      commit(_types.mutations.setSnapshots, [])
      if (payload && payload.waiverRequestId) {
        return this._apiService.Get(encodeURI(`${this._baseUrl}api/digitalLearning/waiver/${payload.waiverRequestId}/snapshot?lea=${createLeaParam(payload)}`))
          .then(data => {
            commit(_types.mutations.setSnapshots, data)
          })
      }
    },
    [_types.actions.getWaiverRequestSnapshot]: ({ commit }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/digitalLearning/snapshot/${payload.waiverRequestSnapshotId}?lea=${createLeaParam(payload)}`))
        .then(data => {
          commit(_types.mutations.setSelectedWaiverRequest, data)
        })
    },
    [_types.actions.reOpenWaiverRequest]: ({ dispatch }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/digitalLearning/waiver/${payload.id}/reopen?lea=${createLeaParam(payload)}`), payload.comment).then((data) =>  {
        return dispatch(_types.actions.getWaiverRequest, { id: data.waiverRequestId, lea: data.districtLEA })
      })
    },
  }
}
<template>
  <form :id="formId" class="form">
    <div>
      <reconfiguration-code-selector :item="item" :purpose="ReconfigurationCodePurpose.InactivateSchool" :operation="operation" />
      <div class="form-group row mb-0">
        <b-toast :id="errorsToastId" title="Inactive LEA Issues" solid header-class="bg-warning text-black" no-auto-hide>
          <b-list-group>
            <b-list-group-item v-for="error in errors.flatMap(e => e)" :key="JSON.stringify(error)">
              {{ error.error }}
            </b-list-group-item>
          </b-list-group>
        </b-toast>
        <div class="col-md-3 mb-3 mt-1">
          Select LEA to Inactivate:
        </div>
        <b-form-select v-model="item.selectedLea" :options="schoolItems" class="col-md-9 mb-2" @input="selectedLeaChanged" />
      </div>
      <div class="form-group row mb-0">
        <div class="col-md-3 mb-3 mt-1" v-if="item.selectedLea">
          Grade Span:
        </div>
        <div class="col-md-9 mb-3 mt-1" v-if="item.selectedLea">
          {{ item.gradeLow }} - {{ item.gradeHigh }}
        </div>
      </div>
      <div class="form-group row mb-0" v-if="item.selectedLea">
        <div class="col-md-3 mb-3 mt-1">
          ALE:
        </div>
        <div class="col-md-9 mb-3 mt-1">
          <pretty-checkbox :value="selectedLeaObject.isALE" true-class="fas fa-check-circle text-danger" false-class="fas fa-times-circle text-muted" />
        </div>
      </div>
      <div class="form-group row mb-0">
        <div class="col-md-3 mb-3 mt-1">
          Additional Comments:
        </div>
        <textarea class="form-control col-md-9" v-model="item.additionalComments">Enter any additional instructions or comments here...</textarea>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex'
import { RequestItemMixin } from '../RequestItemMixIn'
import { ItemValidationMixin } from './ItemValidationMixin'
import PrettyCheckbox from '../../../../PrettyCheckbox.vue'
import { RequestOperationPurpose } from '../../../leaAssignment'

export default {
  name: 'inactivate-lea-item',
  data() {
    return {
      requiredFields: ['selectedLea', 'gradeLow', 'gradeHigh']
    }
  },
  computed: {
    ...mapState('toolsModule/leaAssignment', ['schools']),
    schoolItems() {
      return this.schools.map(s => ({
        value: s.leaNumber,
        text: `${s.leaNumber} - ${s.name}`
      }))
    },
    selectedLeaObject() {
      return this.schools.find(l => l.leaNumber == this.item.selectedLea) || {}
    }
  },
  methods: {
    selectedLeaChanged() {
      this.item.gradeLow = this.selectedLeaObject.gradeLow
      this.item.gradeHigh = this.selectedLeaObject.gradeHigh
      this.item.selectedLeaName = this.selectedLeaObject.name
    }
  },
  watch: {
    selectedLeaObject(newSelection) {
      if (this.operation == RequestOperationPurpose.General) { // on these, they are interested in whether or not the closing school is ALE
        this.$store.dispatch('toolsModule/leaAssignment/setRequestItemReconfigurationCode', { item: this.item, reconfigurationCode: newSelection.isALE ? "1B" : "1C" })
      }
    }
  },
  props: {
    item: Object
  },
  mixins: [RequestItemMixin, ItemValidationMixin],
  components: {
    PrettyCheckbox
  }
}
</script>

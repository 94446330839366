<template>
  <div>
    <h1>{{ selectedWaiverType.listTitle }}</h1>
    <animated-button v-if="canLockNewApplications" class="mx-1 pull-right btn btn-sm" :class="{ 'btn-success': newAMIApplicationsLockedStatus, 'btn-danger': !newAMIApplicationsLockedStatus }" :promise-resolver="toggleLockedStatus.bind(null, WaiverTypes.AMI.value)">
      {{ newAMIApplicationsLockedStatus ? 'Enable New AMI applications' : 'Disable New AMI applications' }}
      <i class="ml-1 fas" :class="{ 'fa-unlock': newAMIApplicationsLockedStatus, 'fa-lock': !newAMIApplicationsLockedStatus }" /> 
    </animated-button>
    <animated-button v-if="canLockNewApplications" class="pull-right btn btn-sm" :class="{ 'btn-success': newDLWaiverRequestsLockedStatus, 'btn-danger': !newDLWaiverRequestsLockedStatus }" :promise-resolver="toggleLockedStatus.bind(null, WaiverTypes.DigitalLearning.value)">
      {{ newDLWaiverRequestsLockedStatus ? 'Enable New Digital Learning Waiver requests' : 'Disable New Digital Learning Waiver requests' }}
      <i class="ml-1 fas" :class="{ 'fa-unlock': newDLWaiverRequestsLockedStatus, 'fa-lock': !newDLWaiverRequestsLockedStatus }" /> 
    </animated-button>
    <h4 class="text-primary">
      School Year 2022-2023
    </h4>
    <h4>Application for Digital Learning Waivers and/or Alternative Methods of Instruction (AMI)</h4>
    <waiver-request-chart-container :selected-waiver-type="selectedWaiverType" />
    <waiver-request-list-widget :context="Context.Review" @selected-waiver-type-changed="selectedWaiverTypeChanged" />
  </div>
</template>

<script>
import WaiverRequestListWidget from './list/WaiverRequestListWidget.vue'
import WaiverRequestChartContainer from './admin/WaiverRequestChartContainer'
import { PageMixin } from '@/components/PageMixin'
import AnimatedButton from '@/components/AnimatedButton'
import { Context } from '@/stores/digitalLearning'
import { Types, WaiverTypes } from '@/stores/digitalLearning'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      resolvedLea: Object,
      selectedWaiverType: {},
      Context,
      WaiverTypes,
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.newDLWaiverRequestsLockedStatus, Types.state.newAMIApplicationsLockedStatus]),
    ...mapState('globalModule', ['userContext']),
    canLockNewApplications() {
      const roles = (this.userContext.roles || [])
      return roles.includes('Insights_System_Administrators') || roles.includes('Insights_OIT_Administrators')
    },
  },
  methods: {
    toggleLockedStatus(waiverType) {
      let toggleStatus = 'enable' 
      if (waiverType == WaiverTypes.AMI && !this.newAMIApplicationsLockedStatus)
        toggleStatus = 'disable'
      if (waiverType == WaiverTypes.DigitalLearning && !this.newDLWaiverRequestsLockedStatus)
        toggleStatus = 'disable'

      return this.$bvModal.msgBoxConfirm(`Are you sure you want to ${toggleStatus} new requests?`)
        .then(result => { 
          if (result) {
            this.$store.dispatch(Types.actions.toggleLockedStatus, { waiverType })
          }
        })
    },
    selectedWaiverTypeChanged(waiverType) {
      this.selectedWaiverType = waiverType
    }
  },
  mixins: [PageMixin],
  components: {
    WaiverRequestListWidget,
    WaiverRequestChartContainer,
    AnimatedButton,
  }
}
</script>
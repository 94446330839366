<template>
  <loadable-chart-card :load-data="loadData" :show-tooltip="showCardTooltip" :tooltip-content="cardTooltipContent" :tooltip-id="cardTooltipId">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '@/components/ChartMixin'
import { credits } from '@/helpers/credits'
import Highcharts from 'highcharts'

export default {
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'column'
        },
        plotOptions: {
          column: {
            minPointLength: 1
          }
        },
        credits: credits.MSI,
        title: {
          text: this.chartTitle
        },
        lang: {
          thousandsSep: ','
        },
        series: this.subPopulations,
        xAxis: {
          title: {
            text: 'School Year'
          },
          type: 'category'
        },
        tooltip:{
          enabled: true,
          formatter: this.tooltipFormatter,
          valueDecimals: 2,
        },
        yAxis: {
          title: {
            text: this.yAxisLabel
          },
          endOnTick: false,
          labels: {
            formatter: this.yAxisFormatter
          }
        },
      }
    }
  },
  mixins: [ChartMixin],
  watch: {
    instructionalOptions(newData) {
      if (this.stacking) {
        this.chartOptions.plotOptions.column.stacking = 'normal'
      }
      this.chartOptions.series = this.subPopulations.map(subpop => {
        return Object.assign(
          { ...subpop },
          {
            data: newData.filter(row => row[subpop.field]).map(row => {
              return {
                x: (row.fiscalYear % 1990) + 1990,
                y: row[subpop.field] * this.valueMultiplier,
                tooltipSuffix: this.tooltipSuffix,
                ...row,
              }
            })
          }
        )
      })
    }
  },
  props: {
    instructionalOptions: Array,
    subPopulations: Array,
    chartTitle: String,
    yAxisLabel: {
      type: String,
      default: 'Number of Students'
    },
    valueMultiplier: {
      type: Number,
      default: 1
    },
    stacking: {
      type: Boolean,
      default: false
    },
    tooltipSuffix:{
      type: String,
      default: ''
    },
    tooltipFormatter:{
      type: Function,
      default: function() {
        return `${this.x}<br/><span  style="color:${this.series.color}">${this.series.name}</span>: <b>${Highcharts.numberFormat(this.y)}${this.point.tooltipSuffix}</b>`
      }
    },
    showCardTooltip:{
      type: Boolean,
      default: false
    },
    cardTooltipContent: String,
    cardTooltipId:{
      type: String,
      defualt: 'tooltip1'
    }
  },
  methods: {
    excelExportData() {
      return this.defaultExcelExportData()
    },
    dataItems() {
      return this.subPopulations.flatMap((subpop, index) =>
        this.chartOptions.series[index].data.map(item => {
          return { Group: subpop.name, SchoolYear: item.x,  [this.yAxisLabel.split(" ").join("")]: item.y + this.tooltipSuffix }
        })
      )
    },
    yAxisFormatter(value) {
      if (value.value > 1000) {
        return value.value.toString().substring(0,value.value.toString().length-3) + "k"
      }
      return value.value + this.tooltipSuffix
    }
  }
}
</script>

<template>
  <b-form inline class="w-100 mb-4 d-print-none">
    <label class="mr-sm-2">Years since most recent 4-A.1 Background Check: </label>
    <b-form-select inline :options="filterOptions" v-model="selected" value-field="sfaField" text-field="name" class="mr-sm-3 mt-sm-1" />
  </b-form>
</template>
<script>

import { BackgroundCheckRanges } from './BackgroundCheckRanges'

export default {
  name: 'background-check-filter',
  data() {
    return {
      selected: 'fullBgcNever'
    }
  },
  computed:{    
    filterOptions() {
      return [{ name:'Show All Records', sfaField: null }].concat(BackgroundCheckRanges)
    }
  },
  watch:{
    selected: {
      handler(selectedFilter){
        this.$emit("filter-updated", selectedFilter)
      }
    }
  }
}
</script>
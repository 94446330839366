<template>
  <div>
    <b-form>
      <b-form-group label="Pathway Name">
        <b-form-input v-model="name" type="text" />
      </b-form-group>
      <b-form-row>
        <b-col class="text-center">
          <b-button variant="primary" class="mr-2" @click="save">
            Save
          </b-button>
          <b-button variant="secondary" @click="cancel">
            Cancel
          </b-button>
        </b-col>
      </b-form-row>
    </b-form>
  </div>
</template>

<script>
import { clustersEditMixin } from '../clustersEditMixin'

export default {
  mixins: [
    clustersEditMixin
  ]
}

</script>
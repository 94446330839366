import store from '../../../stores'

import { router } from '../../../routes'

import FacilitiesHomePage from './FacilitiesHomePage.vue'
import FacilitiesInspectPage from './inspect/FacilitiesInspectPage.vue'
import FacilitiesSecurityPage from './FacilitiesSecurityPage.vue'

import FacilitiesSidebar from './sidebar'

import CreateInspectionBatch from './InspectionBatch/Create.vue'

import BatchList from './inspect/BatchList.vue'

import InspectionBuildings from './inspect/InspectionBuildings.vue'
import MobileInspectionPage from './inspect/MobileInspectionPage.vue'
import Batch from './InspectionBatch/Batch'
import InspectionsPage from './InspectionsPage'
import PendingDistrictActionList from './PendingDistrictActionList'
import BuildingInventory from './BuildingInventory'
import BuildingInspectionHistory from './BuildingInspectionHistory'
import QueuedInspections from './QueuedInspections'
import ModuleWorkQueue from './ModuleWorkQueue'
import { InspectionBatchStatus } from '../common/Enums'
import MobilePrep from '../common/MobilePrep'

const FacilitiesArea = {
  FacilitiesHomePage,
  FacilitiesSidebar,
  FacilitiesInspectPage,
  FacilitiesSecurityPage,
}

router.addRoutes([{
    name: 'Facilities/Batches/Queued',
    path: '/facilities/batches/queued',
    component: QueuedInspections
  },
  {
    name: 'Facilities/Batches/All',
    path: '/facilities/batches/all',
    component: InspectionsPage,
    props: {
      batchStatus: null,
      districtNotificationStatus: null,
      districtAcknowledgementStatus: null
    }
  }, 
  {
    name: 'Facilities/ModuleWorkQueue',
    path: '/facilities/work-queue',
    component: ModuleWorkQueue
  }, 
  {
    name: 'Facilities/PendingDistrictActionList',
    path: '/facilities/pending-district-action',
    component: PendingDistrictActionList
  }, 
  {
    name: 'Facilities/BuildingList',
    path: '/facilities/buildings',
    component: BuildingInventory,
    beforeEnter(to, from, next) { 
//      console.log(store.state.globalModule.userContext)
      if (!store.state.globalModule.userContext.isADEUser) {
        // console.log('redirecting to district view')
        // debugger
        next({name: 'Facilities/BuildingList/District', params: {districtLea: store.state.globalModule.userContext.startLEA}})
      } else {
        next()
      }
    }
  },
  {
    name: 'FacilitiesSecurity/Buildings',
    path: '/facilitiessecurity',
    component: BuildingInventory,
  },


  //District routes
  {
    name: 'Facilities/BuildingList/District',
    path: '/facilities/:districtLea/buildings',
    component: BuildingInventory,
    props: {
      hideColumns: ['districtLEA'],
      criteriaVisible: 'b'
    }
  },
  {
    name: 'Facilities/Inspections/District',
    path: '/facilities/:districtLea/batches/all',
    component: InspectionsPage,
    props: {
      hideColumns: ['districtLea', 'districtName', 'createdBy', 'createDate'],
      batchStatus: InspectionBatchStatus.Completed,
      districtNotificationStatus: true,
      districtAcknowledgementStatus: null,
      criteriaVisible: "bf"
    }
  },
  {
    name: 'Facilities/Inspections/District',
    path: '/facilities/:districtLea/batches/new',
    component: InspectionsPage,
    props: {
      hideColumns: ['districtLea', 'districtName', 'createdBy', 'createDate','districtAcknowledgedBy','districtAcknowledgedDate'],
      batchStatus: InspectionBatchStatus.Completed,
      districtNotificationStatus: true,
      districtAcknowledgementStatus: false,
      showFilter: false
    }
  },
  {
    name: 'Facilities/Building/InspectionHistory',
    path: '/facilities/building/:districtLea/:schoolLea/:buildingNumber/inspections',
    component: BuildingInspectionHistory
  },
  {
    name: 'get-mobile-facilities-link',
    path: '/facilities/inspect/mobile-link',
    component: MobilePrep,
    props: {
      dpsaftArea: 'facilities'
    },
  }
  ,
  {
    name: 'facilities-inspect-page',
    path: '/facilities/inspect',
    component: BatchList
  },
  {
    name: 'Facilities/Batch/View',
    path: '/facilities/batch/:batchId/view',
    component: Batch,
    props: {
      mode: 'view',
      readonly: true
    }
  },
  {
    name: 'Facilities/Batch/Edit',
    path: '/facilities/batch/:batchId/edit',
    component: Batch,
    props: {
      mode: 'edit',
      readonly: false
    }
  },
  {
    name: 'Facilities/Batch/Inspection',
    path: '/facilities/batch/:batchId/inspection',
    component: InspectionBuildings,
    props: (route) => ({ batchId: Number(route.params.batchId)})
  },
  {
    name: 'mobile-inspection-page',
    path: '/facilities/batch/:batchId/inspection/:inspectionId',
    component: MobileInspectionPage,
    props: (route) => ({ batchId: route.params.batchId, inspectionId: route.params.inspectionId, showNavigation: true})
  },
  {
    name: 'Facilities/Batch/Create', 
    path: '/facilities/batch/create',
    component: CreateInspectionBatch
  },
  {
    path: '/facilities',
    redirect: '/facilities/work-queue'
  }
])
export { FacilitiesArea }
<template>
  <div>
    <highcharts class="chart" :options="chartOptions" />
  </div>
</template>
<script>
import { PlanMixin } from '../PlanMixin'

export default {
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'pie'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '{point.name}: <br/>{point.y} Districts ({point.percentage:.1f}%)',
            },
            innerSize: '50%'
          }
        },
        legend: {
          maxHeight: this.$printMode ? 450 : 200
        },
        credits: {
          enabled: false,
          text: 'LEA Insights'
        },
        title: {
          text: `District ESA Plans by Budget Utilization`
        },
        series: [{
          name: 'Number of Districts',
          colorByPoint: true,
        }],
        xAxis: {
          title: {
            text: this.xAxis
          },
          // categories: ['Status']
        },
        yAxis: {
          title: {
            text: 'Count'
          },
          labels: {
            formatter: this.yAxisFormatter
          },
          endOnTick: false
        }
      },
      statuses: [
        { name: 'Draft', color: '#6c757d', statusCodes: [0] },
      ]
    }
  },
  methods: {
    yAxisFormatter(value) {
      if (value.value > 1000) {
        return value.value.toString().substring(0,value.value.toString().length-3) + "k"
      }
      return value.value + this.tooltipSuffix
    },
  },
  watch:{
    districtBudgets:{
      immediate: true,
      handler(budgets) {
        this.chartOptions.series[0].data = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(upper => {
          const lower = upper-10;
          return {
            name: `${lower}-${upper}% Utilized`,
            y: budgets.filter(b => b.percentUtilized >= lower && b.percentUtilized <= upper).length
          }
        })
      }
    }
  },
  mixins: [PlanMixin]
}
</script>
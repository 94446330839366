<template>
  <div>
    <facilities-filter @on-filter-changed="onFilterChanged" :enable-building-filter="true" :criteria-visible="criteriaVisible" @on-filter-cleared="onFilterCleared" />
    <inspection-dashboard-widget mode="facilities" :load-data="loadData" v-if="showTable" :is-state-view="isStateView" />
    <building-list v-if="showTable"
                   :items="items"
                   :allow-item-check="allowItemCheck"
                   :show-add-button="true"
                   :show-action-column="showActionColumn"
                   @row-selected="onSelected"
                   @item-checked="onItemChecked"
                   @add-items="onAddItems"
                   :load-data="loadData"
                   :hide-columns="hideColumns">
      <template v-slot:action-column-content="data">
        <slot name="action-column-content" v-bind="data" />
      </template>
    </building-list>
    <div class="alert alert-warning text-center" v-else>
      <h4>Please select at least one filter option and click 'Filter'.</h4>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BuildingList from '../common/BuildingList'
import InspectionDashboardWidget from '../common/InspectionDashboardWidget'
import FacilitiesFilter from '../facilities/FacilitiesFilter.vue'

export default {
  name: 'buildings',
  data() {
    return {
      loadData: null,
      filtered: false
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState('DPSAFT', ['buildings', 'filter']),
    ...mapState('DPSAFT/Facilities', ['BatchMaintenance']),
    selectedBuildings: {
      set(buildings) {
        this.$store.commit('DPSAFT/Facilities/setSelectedBuildings', buildings)
      },
      get() {
        return this.BatchMaintenance.SelectedBuildings
      }
    },
    items() {
      if (this.showTable) {
        return this.buildings
      }
      return []
    },
    showTable() {
      return (this.filtered && (this.filter.area || this.filter.district)) || this.allowStateLevel
    },
    canFilter() {
      return (this.filter.area || this.filter.district) || this.allowStateLevel
    },
    isStateView() {
      return this.allowStateLevel && (!this.filter.area && !this.filter.district)
    }
  },
  props: {
    districtLea: String,
    showActionColumn: {
      type: Boolean,
      default: false
    },
    allowItemCheck:{
      type: Boolean,
      default: false
    },
    hideColumns: Array,
    criteriaVisible: {
      type: String,
      default: "adb"
    },
    allowStateLevel:{
      type: Boolean,
      default: false
    }
  },
  methods: {
    onFilterChanged(newFilter) {
      this.filtered = true
      this.loadData = this.$store.dispatch('DPSAFT/getBuildings', {
        areaId: newFilter.area,
        districtLea: newFilter.district,
        building: newFilter.building
      })
    },
    onFilterCleared(){
      if(this.allowStateLevel) {
        this.loadData = this.$store.dispatch('DPSAFT/getBuildings', {
          areaId: null,
          districtLea: null,
          building: null
        })
      }
      this.filtered = false
    },
    onSelected(rows) {
      console.log('Building selected:', rows)
      
    },
    onItemChecked(checkedItems) {
      console.log('Buildings checked:', checkedItems)
      this.selectedBuildings = checkedItems
    },
    onAddItems(payload) {
      this.$emit('add-items', payload)
    }
  },
  mounted() {
    this.filtered = false

    var promises = [this.$store.dispatch('DPSAFT/getAreas')]

    if (this.canFilter) {
      if(this.allowStateLevel){
        promises.push(
          this.$store.dispatch('DPSAFT/getCoopsAndBuildings')
        )
      }
      promises.push(
        this.$store.dispatch('DPSAFT/getBuildings', { areaId: this.filter.area, districtLea: this.filter.district, building: this.filter.building }).then(()=>this.filtered=true)
      )
    }

    this.loadData = Promise.all(promises)
  },
  components: {
    BuildingList,
    FacilitiesFilter,
    InspectionDashboardWidget
  }
}
</script>

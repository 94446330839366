
<template lang="html">
  <div>
    <svg class="student-trend-chart sparkline sparkline--blue sparkline--filled" width="100" height="20" stroke-width="1" ref="chart" v-if="mapped.length > 1" />
    <span class="sparkline-tooltip" hidden="true" v-if="mapped.length > 1" />
    <span v-if="mapped.length <= 1">N/A</span>
  </div>
</template>

<script>
import sparkline from "@fnando/sparkline"

function findClosest(target, tagName) {
  if (target.tagName === tagName) {
    return target;
  }

  while ((target = target.parentNode)) {
    if (target.tagName === tagName) {
      break;
    }
  }

  return target;
}

var options = {
        onmousemove(event, datapoint) {
          var svg = findClosest(event.target, "svg");
          var tooltip = svg.nextElementSibling;
          var year = datapoint.year

          tooltip.hidden = false;
          tooltip.textContent = `${year}: ${datapoint.origValue}`;
          tooltip.style.top = `${event.offsetY}px`;
          tooltip.style.left = `${event.offsetX + 20}px`;
        },

        onmouseout() {
          var svg = findClosest(event.target, "svg");
          var tooltip = svg.nextElementSibling;

          tooltip.hidden = true;
        }
      }

export default {
  name: "student-trend-line",
  data() {
    return {
      options
    }
  },
  computed: {
    mapped() {
      var mapped = []
      var minimum = Number.MAX_SAFE_INTEGER

      Object.keys(this.scores).forEach(scoreKey => {
        var value = this.scores[scoreKey][this.valueProperty]
        if (value < minimum) minimum = value
        if (value) mapped.push({ year: this.scores[scoreKey].schoolYear, value: value, origValue: value })
      }); 

      if (!mapped.every( (val, i, arr) => val.value === arr[0].value )) // not all values in array are the same
      {
        if (minimum < 0) {
          mapped.forEach(point => point.value += Math.abs(minimum))
        }
        else {
          mapped.forEach(point => point.value -= minimum)
        }
      }

      return mapped
    }
  },
  mounted() { 
    if (this.mapped.length > 1)
      sparkline(this.$refs["chart"], this.mapped, this.options)
  },
  props: {
    scores: Object,
    valueProperty: String
  }
}
</script>

<style lang="scss">
  $colors: (
    blue: #4785c8,
  );

  .sparkline {
    fill: none;
  }

  @each $name, $color in $colors {
    .sparkline--#{"" + $name} { stroke: $color; }
    .sparkline--#{"" + $name}.sparkline--filled { fill: rgba($color, .3); }
  }

  div {
    position: relative;
  }

  *[hidden] {
    display: none;
  }

  .sparkline-tooltip {
    position: absolute;
    background: rgba(0, 0, 0, .7);
    color: #fff;
    padding: 2px 5px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 9999;
  }
 
 
  .sparkline--cursor {
    stroke: orange;
  }

  .sparkline--spot {
    fill: red;
    stroke: red;
  }
</style>
<template>
  <div>
    <b-alert show variant="warning" v-if="selectedLea.type > 2">
      Due to volume of data, student level reports are only available at the District or School level. Please select an
      appropriate LEA from the LEA Context Picker above.
    </b-alert>
    <div v-else>
      <div class="report-container">
        <div class="row mb-2">
          <div class="col">
            <b-form-select :options="ayOptions" v-model="academicYear" @change="getData">
              <template #first>
                <b-form-select-option :value="null" disabled>
                  -- Academic Year --
                </b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </div>

        <Promised :promise="loadData" v-show="loadData">
          <template v-slot:pending>
            <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
              <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
            </div>
          </template>
          <template v-slot>
            <div v-if="reportData" class="report-output">
              <paginated-table :items="reportData" :fields="fields" sort-by="l" :sort-asc="true" :per-page="20" striped hover />
            </div>
          </template>
          <template v-slot:rejected="error" class="align-items-center justify-content-center">
            <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
          </template>
        </Promised>
      </div>
    </div>
  </div>
</template>

<script>
import PaginatedTable from '@/components/PaginatedTable.vue'
import { Promised } from 'vue-promised'
import CardStateIcon from '@/components/CardStateIcon'
import { AYDependentReportMixin } from '../AYDependentReportMixin'
import ApiService from '@/services/ApiService'
import { formatDate } from '@/helpers/formatters'

export default {
  components: {
    Promised,
    CardStateIcon,
    PaginatedTable,
  },
  data() {
    return {
      academicYear: null,
      loadData: null,
      reportData: null,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'districtLEA',
          label: 'District LEA',
          formatter: this.leaFormatter,
          sortable: true
        },
        {
          key: 'schoolLEA',
          label: 'School LEA',
          formatter: this.leaFormatter,
          sortable: true
        },
        {
          key: 'grade',
          sortable: true
        },
        {
          key: 'firstName',
          label: 'First Name',
          sortable: true
        },
        {
          key: 'lastName',
          label: 'Last Name',
          sortable: true
        },
        {
          key: 'name',
          label: 'Certificate Name',
          sortable: true
        },
        { key: 'lastUser', label: 'Last Updated By' },
        {
          key: 'date',
          formatter: (d) => formatDate(d, 'short-date'),
          sortable: true
        },
      ]
    }
  },
  methods: {
    async getData() {
      if (this.academicYear) {
        var payload = {
          lea: this.selectedLea.number,
          academicYear: this.academicYear
        }

        this.loadData = new ApiService().Get(`/api/cte/cc-reports/${payload.lea}/certifications?academicYear=${payload.academicYear}`)
          .then(d => this.reportData = d)
      }
    },
  },
  watch: {
    selectedLea: {
      immediate: false,
      handler() {
        this.getData()
      }
    }
  },
  mixins: [
    AYDependentReportMixin
  ]
}
</script>
<template>
  <div>
    <h2>{{ user.fullName }}</h2>
    <p class="lead" v-if="user.canDisable">
      <animated-button :promise-resolver="toggleUserEnabled" :button-class="enabledButtonClass">
        {{ !user.isEnabled ? 'Enable' : 'Disable' }} Account
      </animated-button>
      <animated-button :promise-resolver="sendConfirmationEmail" :button-class="enabledButtonClass" v-if="!user.isEnabled">
        Send Confirmation Email
      </animated-button>
      <animated-button :promise-resolver="sendPasswordResetEmail" :button-class="passwordButtonClass">
        Send Password Reset Email
      </animated-button>
    </p>
    <div v-if="!isEditing" class="card card-body bg-light col-5">
      <i class="text-right mx-2 fas fa-edit text-primary cursor-pointer" @click="startEditing" />
      <p class="lead">
        Name: {{ user.fullName }}
      </p>
      <p class="lead">
        Phone: <a :href="'tel:' + user.telephoneNumber">{{ user.telephoneNumber }}</a>
      </p>
    </div>
    <div v-if="user.canDisable && isEditing" class="card card-body bg-light col-5">
      <b-form-row>
        <b-form-group label-class="font-weight-bold" label="First Name">
          <b-form-input v-model="user.newFirstName" required />
        </b-form-group>
        <b-form-group label-class="font-weight-bold" class="pl-1" label="Last Name">
          <b-form-input v-model="user.newLastName" required />
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group label-class="font-weight-bold" label="Phone">
          <b-form-input v-model="user.newTelephoneNumber" required />
        </b-form-group>
      </b-form-row>
      <div>
        <animated-button :promise-resolver="save" :button-class="saveButtonClass">
          Save
        </animated-button>
        <b-button @click="isEditing = false" size="sm" variant="danger">
          Cancel
        </b-button>
      </div>
    </div>
    <div class="ml-3">
      <p class="lead mt-3">
        Email: <a :href="'mailto:' + user.emailAddress">{{ user.emailAddress }}</a>
      </p>
      <p class="lead">
        Account Enabled: <i class="fa-lg fas fa-check text-success" v-if="user.isEnabled" />
        <i class="fa-lg fa fa-ban text-danger" v-if="!user.isEnabled" />
      </p>
      <p class="lead">
        Account Email Confirmed: 
        <i class="fa-lg fas fa-check text-success" v-if="user.isConfirmed" />
        <i class="fa-lg fa fa-ban text-danger" v-if="!user.isConfirmed" />
      </p>
      <div class="mt-3">
        <p class="lead">
          Roles:
        </p>
        <ul class="list-group">
          <li class="list-group-item" v-for="role in user.roles" :key="role">
            <div class="d-inline">
              {{ CareerCoachRolesDisplay[role] }}
            </div>
            <div v-if="supportsExtendedClaims(role)" class="mt-1">
              <assigned-highschools v-model="editClaims[role]" :default-value="[]" />
              <animated-button :promise-resolver="assignLeas.bind(null, role)" :button-class="assignedButtonClass">
                Assign LEA(s)
              </animated-button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { CareerCoachRoles, CareerCoachRolesDisplay } from '@/modules/cte/components/common/Roles'
import AnimatedButton from '@/components/AnimatedButton.vue'
import AssignedHighschools from './AssignedHighschools.vue'
import ApiService from '@/services/ApiService'
const apiService = new ApiService()

export default {
  data() {
    return {
      user: {},
      editClaims: {},
      claims: [],
      isEditing: false,
      saveButtonClass: 'm-1 btn btn-sm btn-primary',
      assignedButtonClass: 'm-1 btn btn-sm btn-primary',
      passwordButtonClass: 'm-1 btn btn-sm btn-warning',
      CareerCoachRolesDisplay,
      userEnabled: true,
    }
  },
  computed: {
    enabledButtonClass() {
      return this.user.isEnabled ? 'm-1 btn btn-sm btn-danger' : 'm-1 btn btn-sm btn-success'
    },
  },
  watch: {
    claims: {
      immediate: true,
      handler(data) {
        if (data) {
          const self = this
          data.forEach(c => {
            if (c.claim && c.claim != 'null') {
              self.$set(self.editClaims, c.role, JSON.parse(c.claim).map(id => id))
            }
          })
        }
        else {
          this.editClaims = {}
        }
      }
    },
    user: {
      handler(data) {
        if (data) {
          this.userEnabled = data.isEnabled
        }
      }
    }
  },
  methods: {
    sendConfirmationEmail() {
      const self = this
      return apiService.Post(`/api/cte/career-coach/admin/users/${this.$route.query.userId}/send-confirmation-email`).then(() => {
        self.enabledButtonClass = 'm-1 btn btn-sm btn-success'
        this.$bvToast.toast('Sent', {
          title: `Confirmation Email Sent`,
          variant: 'success',
          solid: true
        })
      })
    },
    toggleUserEnabled() {
      const self = this
      return apiService.Post(`/api/cte/career-coach/admin/users/${this.$route.query.userId}/enabled/${!this.user.isEnabled}`, ).then(() => {
        self.user.isEnabled = !self.user.isEnabled
      })
    },
    sendPasswordResetEmail() {
      const self = this
      return apiService.Post(`/api/cte/career-coach/admin/users/${this.$route.query.userId}/send-password-reset`).then(() => {
        self.passwordButtonClass = 'm-1 btn btn-sm btn-success'
        this.$bvToast.toast('Sent', {
          title: `Password Reset Email Sent`,
          variant: 'success',
          solid: true
        })
      })
    },
    getAssignedLeas() {
      const self = this
      return apiService.Get(`/api/cte/career-coach/admin/users/${this.$route.query.userId}/leas`).then(claims => {
        self.claims = claims
      })
    },
    supportsExtendedClaims(role) {
      return role == CareerCoachRoles['Supervisor'] || role == CareerCoachRoles['Counselor']
    },
    startEditing() {
      this.isEditing = true
      this.user.newFirstName = this.user.firstName
      this.user.newLastName = this.user.lastName
      this.user.newTelephoneNumber = this.user.telephoneNumber
    },
    save() {
      const self = this
      return apiService.Put(`/api/cte/career-coach/admin/users`, {
        firstName: this.user.newFirstName,
        lastName: this.user.newLastName,
        telephoneNumber: this.user.newTelephoneNumber,
        emailAddress: this.user.emailAddress,
      }).then(() => {
        self.user.firstName = self.user.newFirstName
        self.user.lastName = self.user.newLastName
        self.user.fullName = self.user.firstName + ' ' + self.user.lastName
        self.user.telephoneNumber = self.user.newTelephoneNumber
        self.saveButtonClass = 'm-1 btn btn-sm btn-success'
        this.$bvToast.toast('Saved', {
          title: `User Information Saved`,
          variant: 'success',
          solid: true
        })
        self.isEditing = false
      })
    },
    assignLeas(role) {
      const self = this
      return apiService.Put(`/api/cte/career-coach/admin/users/${this.$route.query.userId}/leas/${role}`, this.editClaims[role]).then(() => {
        self.assignedButtonClass = 'm-1 btn btn-sm btn-success'
        this.$bvToast.toast('Saved', {
          title: `Assigned LEAs Saved`,
          variant: 'success',
          solid: true
        })
      })
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.user = to.params
      vm.getAssignedLeas()
    })
  },
  components: {
    AnimatedButton,
    AssignedHighschools,
  },
}
</script>
<template>
  <div>
    <div v-if="SelectedBatch">
      <b-button-group class="my-2" style="display:flex;">
        <b-button variant="primary" @click="statusFilter=null">
          All
          <span class="badge badge-light text-primary">{{ aggregates.all }}</span>
        </b-button>
        <b-button variant="warning" @click="statusFilter='todo'">
          ToDo
          <span class="badge badge-dark text-warning">{{ aggregates.todo }}</span>
        </b-button>
        <b-button variant="success" @click="statusFilter='done'">
          Done
          <span class="badge badge-light text-success">{{ aggregates.done }}</span>
        </b-button>
      </b-button-group>
    </div>

    <div class="d-flex flex-row" v-if="SelectedBatch">
      <a href="javascript:history.go(-1)" class="pt-1">
        <i class="fas fa-arrow-alt-circle-left fa-2x" />
      </a>
      <b-input-group class="mx-2">
        <b-form-input placeholder="Search Building Name or #..." v-model="textFilter" />
        <b-input-group-append>
          <b-button variant="info">
            Clear
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <a href="javascript:history.go(1)" class="pt-1">
        <i class="fas fa-arrow-alt-circle-right fa-2x" />
      </a>
    </div>

    <h3 class="mt-2">
      {{ SelectedBatch.districtName }} (LEA: {{ SelectedBatch.districtLea }})
    </h3>
    <h4 class="mt-2 text-secondary">
      {{ SelectedBatch.description }}
    </h4>
    <inspection-building-card
      v-for="i in filteredInspections"
      :key="i.inspectionId"
      :batch-id="batchId"
      :inspection="i" />
    <div class="alert alert-warning text-center" v-if="filteredInspections.length == 0">
      No buildings found that match the filter
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import InspectionBuildingCard from './InspectionBuildingCard'
import { InspectionStatuses, InspectionBatchStatus } from '../../common/Enums'
import { Types } from '../../../../stores/dpsaft/facilities'

export default {
  name: 'inspection-buildings',
  data() {
    return {
      statusFilter: null,
      textFilter: null
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext', 'isOnline']),
    ...mapState('DPSAFT/Facilities', [Types.state.SelectedBatch, Types.state.inspectionBatches]),
    aggregates() {
      return {
        all: this.SelectedBatch.inspections.length,
        done: this.SelectedBatch.inspections.filter(i => i.status == InspectionStatuses.Completed).length,
        todo: this.SelectedBatch.inspections.filter(i => i.status == InspectionStatuses.NonStarted || i.status == InspectionStatuses.InProgress).length
      }
    },
    filteredInspections() {
      var lambda = () => true

      switch (this.statusFilter) {
        case 'done':
          lambda = i => i.status == InspectionStatuses.Completed
          break
        case 'todo':
          lambda = i => i.status == InspectionStatuses.NonStarted || i.status == InspectionStatuses.InProgress
          break
      }

      const statusFiltered = this.SelectedBatch.inspections.filter(lambda)

      if (this.textFilter) {
        var regex = new RegExp(this.textFilter, 'i')
        return statusFiltered.filter(i => {
          const buildingString = (i.building.buildingNumber || '') + '|' + (i.building.buildingName || '')
          return regex.test(buildingString)
        })
      } else {
        return statusFiltered
      }
    }
  },
  components: {
    InspectionBuildingCard
  },
  props: {
    batchId: Number
  },
  watch: {
    batchId: {
      immediate: true,
      handler(newBatchId) {
        if (this.isOnline) {
          const self = this
          this.$store.dispatch(Types.actions.getBatch, { batchId: newBatchId }).then(function() {
            if(self.SelectedBatch.status == InspectionBatchStatus.NotStarted) {
              self.$store.dispatch(Types.actions.updateBatchStatus, { batchId: newBatchId, status: InspectionBatchStatus.InProgress })
            }
          })
        } 
        else {
          if (!this.SelectedBatch || this.SelectedBatch.batchId != newBatchId) {
            this.$store.commit(Types.mutations.setSelectedBatch, this.inspectionBatches.find(b => b.batchId == newBatchId))
          }
        }
      }
    }
  }
}
</script>

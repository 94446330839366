<template>
  <div class="mt-3">
    <paginated-table hover :items="items" :fields="fields">
      <template v-slot:cell(consortium)="data">
        <div>
          <animated-button button-class="btn btn-primary" :promise-resolver="remove.bind(null, data.item.lea)" v-if="data.value == 'Single LEA Consortium'">
            Use default co-op
          </animated-button>
          <animated-button button-class="btn btn-danger" :promise-resolver="add.bind(null, data.item.lea)" v-else>
            Opt out
          </animated-button>
          &nbsp; {{ data.value }} 
        </div>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import AnimatedButton from '@/components/AnimatedButton.vue'
import PaginatedTable from '@/components/PaginatedTable'
import { Types } from '@/modules/cte/stores/index'

export default {
  data() {
    return {
    }
  },
  props: {
    items: Array,
  },
  computed: {
    fields() {
      return [
        { key: 'lea', label: 'LEA #', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'consortium', label: 'Consortium / Coop', sortable: true },
      ]
    },
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    }
  },
  methods: {
    leaFormatter(lea) {
      if (this.leaDictionary[lea])
        return `${lea} – ${this.leaDictionary[lea].name}`

      return lea
    },
    add(districtLea) {
      return this.$store.dispatch(Types.actions.addConsortiumOptOutLeas, { ay: this.$route.params.ay, districtLea })
    },
    remove(districtLea) {
      return this.$store.dispatch(Types.actions.removeConsortiumOptOutLeas, { ay: this.$route.params.ay, districtLea })
    },
  },
  components: {
    PaginatedTable,
    AnimatedButton
  }
}
</script>

import OnDemandPage from './OnDemandPage.vue'
import { router } from '@/routes'

const OnDemandArea = {
  OnDemandPage
}

router.addRoutes([{
  name: 'on-demand',
  path: '/On-Demand',
  component: OnDemandPage,
}])

export { OnDemandArea }


import { LeaTypes } from '@/helpers/leaTypes'
import ApiService from '@/services/ApiService'
import removeNamespace from '@/stores/removeNamespace'
import { CareerCoach } from './careercoach/index.js'
import { PerkinsV } from './perkinsv'
import Vue from 'vue'

export const Types = {
  state: {
    consortiumOptOutLeas: 'consortiumOptOutLeas',
    occupationalAreaMetadata: 'occupationalAreaMetadata',
    currentDataItem: 'currentDataItem',
    currentOAFilter: 'currentOAFilter',
  },
  actions: {   
    getConsortiumOptOutLeas: 'cte/getConsortiumOptOutLeas',
    addConsortiumOptOutLeas: 'cte/addConsortiumOptOutLeas',
    removeConsortiumOptOutLeas: 'cte/removeConsortiumOptOutLeas',
    addCoverPageDropdowns: 'cte/addCoverPageDropdowns',
    removeCoverpageRows: 'cte/removeCoverpageRows',
    getOAMetadata: 'cte/getOAMetadata',
    deactivateOAItem: 'cte/deactivateOAItem',
    reactivateOAItem: 'cte/reactivateOAItem',
    addClusterItem: 'cte/addClusterItem',
    updateClusterItem: 'cte/updateClusterItem',
    setcurrentDataItem: 'cte/setcurrentDataItem',
    addClusterItemRelationship: 'cte/addClusterItemRelationship',
    deleteOAItemRelationship: 'cte/deleteOAItemRelationship',
    setOAFilter: 'cte/setOAFilter',
    saveProgramAreaToCoverPage: 'cte/perkinsv/addProgramAreaToCoverPage',
    removeProgramAreaCoverPage: 'cte/perkinsv/deleteProgramAreaCoverPage'
  },
  getters: {
    leaList: 'leaList',
    districtLeas: 'districtLeas',
    occupationalAreaDrilldown: 'occupationalAreaDrilldown',
    occupationalAreaDrilldownForAY: 'occupationalAreaDrilldownForAY',
    oaMetadataRelationshipsSynchronized: 'oaMetadataRelationshipsSynchronized'
  },
  mutations: {
    setConsortiumOptOutLeas: 'cte/setConsortiumOptOutLeas',
    setCoverPageOAPA: 'cte/setCoverPageOAPA',
    setOAMetadata: 'cte/setOAMetadata',
    setProgramOfStudyNonTraditionalGender: 'cte/setProgramOfStudyNonTraditionalGender',
    deactivateOAItem: 'cte/deactivateOAItem',
    reactivateOAItem: 'cte/reactivateOAItem',
    addClusterItem: 'cte/addClusterItem',
    updateClusterItem: 'cte/updateClusterItem',
    setcurrentDataItem: 'cte/setcurrentDataItem',
    addClusterItemRelationship: 'cte/addClusterItemRelationship',
    deleteOAItemRelationship: 'cte/deleteOAItemRelationship',
    setOAFilter: 'cte/setOAFilter',
  },
  path: 'cte'
}
const _types = removeNamespace('cte/', Types)

// function replaceOwnedLeas(lea, newOwnedLeas) {
//   return Object.assign(lea, { ownedLeas: newOwnedLeas })
// }

export class CTE {

  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
  }
  namespaced = true

  state = {
    [Types.state.consortiumOptOutLeas]: [],
    [Types.state.occupationalAreaMetadata]: {},
    [Types.state.currentDataItem]: null,
    [Types.state.currentOAFilter]: { area: null, cluster: null, pathway: null, pos: null }
  }

  mutations = {
    [_types.mutations.setOAFilter]: (state, data) => state.currentOAFilter = data,
    [_types.mutations.setConsortiumOptOutLeas]: (state, data) => state.consortiumOptOutLeas = data.map(d => d.districtLEA),
    [_types.mutations.setOAMetadata]: (state, data) => {
      Vue.set(state[_types.state.occupationalAreaMetadata], 'occupationalAreas', data.occupationalAreas)
      Vue.set(state[_types.state.occupationalAreaMetadata], 'clusters', data.clusters)
      Vue.set(state[_types.state.occupationalAreaMetadata], 'pathways', data.pathways)
      Vue.set(state[_types.state.occupationalAreaMetadata], 'programsOfStudy', data.programsOfStudy)
      Vue.set(state[_types.state.occupationalAreaMetadata], 'oaMetadataRelationships', data.oaMetadataRelationships)

      //state[_types.state.occupationalAreaMetadata] = data
    },
    [_types.mutations.setProgramOfStudyNonTraditionalGender]: (state, payload) => {
      var item = state.occupationalAreaMetadata.programsOfStudy.find(p=>p.id == payload.id)
      if (item) Vue.set(item, 'nonTraditionalGender', payload.gender)
    },
    [_types.mutations.deactivateOAItem]: (state, payload) => {
      var item = state.occupationalAreaMetadata[payload.set].find(p=>p.id == payload.itemId)
      if (item) Vue.set(item, 'endAY', payload.ay)
    },
    [_types.mutations.reactivateOAItem]: (state, payload) => {
      var item = state.occupationalAreaMetadata[payload.set].find(p=>p.id == payload.itemId)
      if (item) Vue.set(item, 'endAY', null)
    },
    [_types.mutations.setcurrentDataItem]: (state, item) => {
      state.currentDataItem = item
    },
    [_types.mutations.updateClusterItem]: (state, payload) => {
      const item = state.occupationalAreaMetadata[payload.set].find(i=>i.id == payload.item.id)
      if (item) { 
        item.name = payload.item.name
        
        if (payload.set == 'programsOfStudy') {
          Vue.set(item, 'nonTraditionalGender', payload.item.nonTradGender)
        }

        state.currentDataItem = payload
      } else {
        state.currentDataItem = null
      }
    },
    [_types.mutations.addClusterItem]: (state, payload) => {
      state.occupationalAreaMetadata[payload.set].push(payload.item)
      state.currentDataItem = payload
    },
    [_types.mutations.addClusterItemRelationship]: (state, relation) => {
      const rel = {
        id: relation.id,
        activeForAY: true,
        ay: relation.ay,
        occupationalAreaId: relation.occupationalAreaId,
        occupationalAreaName: state.occupationalAreaMetadata.occupationalAreas.find(i=>i.id == relation.occupationalAreaId)?.name,
        clusterId: relation.clusterId,
        clusterName: state.occupationalAreaMetadata.clusters.find(i=>i.id == relation.clusterId)?.name,
        programOfStudyId: relation.programOfStudyId,
        programOfStudyName: state.occupationalAreaMetadata.programsOfStudy.find(i=>i.id == relation.programOfStudyId)?.name,
        pathwayId: relation.pathwayId,
        pathwayName: state.occupationalAreaMetadata.pathways.find(i=>i.id == relation.pathwayId)?.name,
      }
      state.occupationalAreaMetadata.oaMetadataRelationships.push(rel)
    },
    [_types.mutations.deleteOAItemRelationship]: (state, payload) => {
      var idx = state.occupationalAreaMetadata.oaMetadataRelationships.findIndex(r=>r.id == payload.id)
      if (idx >= 0) {
        state.occupationalAreaMetadata.oaMetadataRelationships.splice(idx, 1)
      }
    }
  }
  getters = {
    [Types.getters.oaMetadataRelationshipsSynchronized]: (state) => (ay) => {
      return state.occupationalAreaMetadata.oaMetadataRelationships.map(relation=>{

        const oa = state.occupationalAreaMetadata.occupationalAreas.find(i=>i.id == relation.occupationalAreaId)
        const pos= state.occupationalAreaMetadata.programsOfStudy.find(i=>i.id == relation.programOfStudyId)
        const pw = state.occupationalAreaMetadata.pathways.find(i=>i.id == relation.pathwayId)
        const cl = state.occupationalAreaMetadata.clusters.find(i=>i.id == relation.clusterId)
        const active = (oa == null || (oa.startAY <= ay && (oa.endAY || ay+1) > ay)) &&
                        (pw.startAY <= ay && (pw.endAY || ay+1) > ay) &&
                        (cl.startAY <= ay && (cl.endAY || ay+1) > ay) &&
                        (pos.startAY <= ay && (pos.endAY || ay+1) > ay) 

        return {
          id: relation.id,
          activeForAY: active,
          ay: relation.ay,
          occupationalAreaId: relation.occupationalAreaId,
          occupationalAreaName: oa?.name,
          clusterId: relation.clusterId,
          clusterName: cl?.name,
          programOfStudyId: relation.programOfStudyId,
          programOfStudyName: pos?.name,
          pathwayId: relation.pathwayId,
          pathwayName: pw?.name,
        }
      })
    },
    [Types.getters.leaList]: (_state, _getters, rootState, rootGetters) => { 
      // let leaList = replaceOwnedLeas(rootGetters['globalModule/leaList'], originaLeas.map(coop => 
      //   replaceOwnedLeas(coop, coop.ownedLeas.filter(district => !state.consortiumOptOutLeas.some(optOutLea => optOutLea == district.number)))
      // ))
      if ([...(rootState.globalModule.userContext.AssignedLeas || [])].length > 0) {
        const myDistricts = { 
          name: 'My Schools', 
          number: 'MYLEAS', 
          type: LeaTypes.District, 
          ownedLeas: rootState.globalModule.userContext.AssignedLeas.map(lea => {
            return rootGetters['globalModule/leaDictionary'][lea]
          })
        }
        return myDistricts
      }
      else 
        return rootGetters['globalModule/leaList']
      // leaList.ownedLeas.push({ name: 'Single LEA Consortia', number: '0000000', type: LeaTypes.Coop, ownedLeas: state.consortiumOptOutLeas.map(lea => {
      //     return rootGetters['globalModule/leaDictionary'][lea]
      //   }) 
      // })
    },
    [Types.getters.districtLeas]: (state, _getters, _rootState, rootGetters) => {
      const districts = [...rootGetters['globalModule/leaList'].ownedLeas.flatMap(c => c.ownedLeas)]
      
      return districts.map(district => {
        return {
          lea: district.number,
          name: district.name,
          consortium: state.consortiumOptOutLeas.some(optOutLea => optOutLea == district.number) ? 'Single LEA Consortium' : `${district.parentLea.name} (${district.parentLea.number})`
        }
      }).sort((a, b) => Number(a.lea) - Number(b.lea))
    },

    [_types.getters.occupationalAreaDrilldownForAY]: (state) => (ay) => {
      var oa = (state.occupationalAreaMetadata.occupationalAreas || [])
          .filter(r=>r.startAY <= ay && (r.endAY == null || r.endAY > ay))
          .slice().map(i=>Object.assign({children: null}, i))

      oa.forEach(oaItem => {
        oaItem.children = []

        //get the records for this occupational Area
        var clusterRecords = state.occupationalAreaMetadata.oaMetadataRelationships.filter(r=> (r.occupationalAreaId == oaItem.id || oaItem.id == 1 && !r.occupationalAreaId) && r.activeForAY)

        clusterRecords.forEach((clusterRecord) => {

          var cluster = {id: clusterRecord.clusterId, name: clusterRecord.clusterName, children: [], parent: oaItem}

          if (oaItem.children.filter(c=>c.id == clusterRecord.clusterId).length == 0) {

            var pathwayRecords = state.occupationalAreaMetadata.oaMetadataRelationships.filter(r=> (r.occupationalAreaId == oaItem.id || oaItem.id == 1 && !r.occupationalAreaId) && r.clusterId == clusterRecord.clusterId && r.activeForAY)

            pathwayRecords.forEach((pathwayRecord) => {

              var pathway = {id: pathwayRecord.pathwayId, name: pathwayRecord.pathwayName, parent: cluster, children: []}

              if (cluster.children.filter(p=>p.id == pathwayRecord.pathwayId).length == 0) {

                var posRecords = state.occupationalAreaMetadata.oaMetadataRelationships.filter(r=> (r.occupationalAreaId == oaItem.id || oaItem.id == 1 && !r.occupationalAreaId) && r.clusterId == clusterRecord.clusterId && r.pathwayId == pathwayRecord.pathwayId && r.activeForAY)
    
                posRecords.forEach((posRecord) => {
                
                  var pos = {id: posRecord.programOfStudyId, name: posRecord.programOfStudyName, nonTraditionalGender: posRecord.nonTraditionalGender, parent: pathway }

                  if (pathway.children.filter(p=>p.id == posRecord.programOfStudyId).length == 0) {
                    pathway.children.push(pos)
                  }
                })

                cluster.children.push(pathway)
            }
          })

            oaItem.children.push(cluster)
          }
        })
      })

      return oa
    },

    [_types.getters.occupationalAreaDrilldown]: (state) => {

      var oa = (state.occupationalAreaMetadata.occupationalAreas || []).slice().map(i=>Object.assign({children: null}, i))

      oa.forEach(oaItem => {

        oaItem.children = []

        //get the records for this occupational Area
        var clusterRecords = state.occupationalAreaMetadata.oaMetadataRelationships.filter(r=>r.occupationalAreaId == oaItem.id)

        clusterRecords.forEach((clusterRecord) => {

          var cluster = {id: clusterRecord.clusterId, name: clusterRecord.clusterName, children: [], parent: oaItem}

          if (oaItem.children.filter(c=>c.id == clusterRecord.clusterId).length == 0) {

            var pathwayRecords = state.occupationalAreaMetadata.oaMetadataRelationships.filter(r=>r.occupationalAreaId == oaItem.id && r.clusterId == clusterRecord.clusterId)

            pathwayRecords.forEach((pathwayRecord) => {

              var pathway = {id: pathwayRecord.pathwayId, name: pathwayRecord.pathwayName, parent: cluster, children: []}

              if (cluster.children.filter(p=>p.id == pathwayRecord.pathwayId).length == 0) {

                var posRecords = state.occupationalAreaMetadata.oaMetadataRelationships.filter(r=>r.occupationalAreaId == oaItem.id && r.clusterId == clusterRecord.clusterId && r.pathwayId == pathwayRecord.pathwayId)
    
                posRecords.forEach((posRecord) => {
                
                  var pos = {id: posRecord.programOfStudyId, name: posRecord.programOfStudyName, nonTraditionalGender: posRecord.nonTraditionalGender, parent: pathway }

                  if (pathway.children.filter(p=>p.id == posRecord.programOfStudyId).length == 0) {
                    pathway.children.push(pos)
                  }
                })

                cluster.children.push(pathway)
            }
          })

            oaItem.children.push(cluster)
          }
        })
      })

      return oa
    }
  }

  actions = {
    // eslint-disable-next-line
    [_types.actions.saveProgramAreaToCoverPage]: ({commit}, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/perkinsv/applications/${payload.applicationId}/cover-page/clusters` ), payload)
    },
    // eslint-disable-next-line
    [_types.actions.removeProgramAreaCoverPage]: ({commit}, payload) => {
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/cte/perkinsv/applications/${payload.applicationId}/cover-page/${payload.clusterId}` ))
    },
    [_types.actions.getConsortiumOptOutLeas]: ({ commit }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/consortiumOptOutLeas/${payload.ay}`))
        .then(data => commit(_types.mutations.setConsortiumOptOutLeas, data))
    },
    [_types.actions.addConsortiumOptOutLeas]: ({ commit }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/consortiumOptOutLeas/${payload.ay}/${payload.districtLea}`))
        .then(data => commit(_types.mutations.setConsortiumOptOutLeas, data))
    },
    [_types.actions.removeConsortiumOptOutLeas]: ({ commit }, payload) => {
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/consortiumOptOutLeas/${payload.ay}/${payload.districtLea}`))
        .then(data => commit(_types.mutations.setConsortiumOptOutLeas, data))
    },
    [_types.actions.addCoverPageDropdowns]: ({ commit }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/addprogramareatocoverpage/${payload.ay}/${payload.districtLea}`))
        .then(data => commit(_types.mutations.setConsortiumOptOutLeas, data))
    },
    [_types.actions.removeCoverpageRows]: ({ commit }, payload) => {
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/consortiumOptOutLeas/${payload.ay}/${payload.districtLea}`))
        .then(data => commit(_types.mutations.setConsortiumOptOutLeas, data))
    },
    [_types.actions.getOAMetadata]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/perkinsv/${payload.ay}/oa-metadata`))
        .then(data => commit(_types.mutations.setOAMetadata, data))
    },
    [_types.actions.deactivateOAItem]: ({commit}, payload) => {
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/cluster/${payload.set}/${payload.itemId}/${payload.ay}`))
        .then(() => commit(_types.mutations.deactivateOAItem, payload))
    },
    [_types.actions.reactivateOAItem]: ({commit}, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/cluster/${payload.set}/${payload.itemId}/activate`))
        .then(() => commit(_types.mutations.reactivateOAItem, payload))
    },
    [_types.actions.addClusterItem]: ({commit}, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/cluster/${payload.set}`), payload.item)
        .then((item) => {
          payload.item = item
          commit(_types.mutations.addClusterItem, payload)
        })
    },
    [_types.actions.updateClusterItem]: ({commit}, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/cluster/${payload.set}/${payload.item.id}`), payload.item)
        .then(() => commit(_types.mutations.updateClusterItem, payload))
    },
    [_types.actions.setcurrentDataItem]: ({commit}, payload) => {
        return commit(_types.mutations.setcurrentDataItem, payload)
    },
    [_types.actions.addClusterItemRelationship]: ({commit}, payload) => {
      console.log(payload)
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/cluster/relationship`), payload)
        .then((item) => commit(_types.mutations.addClusterItemRelationship, item))
    },
    [_types.actions.deleteOAItemRelationship]: ({commit}, payload) => {
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/cte/perkinsv/admin/cluster/relationship/${payload.id}`))
        .then(() => commit(_types.mutations.deleteOAItemRelationship, payload))
    },
    [_types.actions.setOAFilter]: ({commit}, filter) => commit(_types.mutations.setOAFilter, filter)
    

  }

  modules = {
    perkinsv: new PerkinsV(),
    careercoach: new CareerCoach()
  }
}
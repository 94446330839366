<template>
  <div>
    <transportation-filter @on-filter-changed="onFilterChanged" :allow-unfiltered-search="true" default-sort="name" :fiscal-years="fiscalYears" :criteria-visible="criteriaVisible" />
    <batch-list :items="inspectionBatches" :load-data="loadData" :hide-columns="hideColumns" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TransportationFilter from '@/components/dpsaft/transportation/TransportationFilter.vue'
import BatchList from './InspectionBatch/BatchList'
export default {
  name: 'bus-inspections',
    data() {
    return {
      loadData: null
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState('DPSAFT/Trans', ['inspectionBatches', 'fiscalYears']),
    ...mapState('DPSAFT', ['filter'])
  },
  components: {
    TransportationFilter,
    BatchList
  },
  methods: {
    onFilterChanged() {
      this.loadData = this.refreshBatchData()
    },
    refreshBatchData() {
      return this.$store.dispatch('DPSAFT/Trans/getInspectionBatches', {
        areaId: this.filter.area,
        districtLea: this.filter.district,
        coopLea: this.filter.coop,
        batchStatus: this.batchStatus,
        districtNotificationStatus: this.districtNotificationStatus,
        districtAcknowledgementStatus: this.districtAcknowledgementStatus,
        fy: this.filter.fy || this.fiscalYears[0]
      })
    }
  },
  mounted() {
    this.loadData = Promise.all([
      this.$store.dispatch('DPSAFT/getAreas'),
      this.$store.dispatch('DPSAFT/Trans/getTransportationFiscalYears')
    ]).then(() => {
      this.refreshBatchData()
    })
  },
  props: {
    hideColumns: {
      type: Array,
      default() {
        return []
      }
    },
    criteriaVisible: {
      type: String,
      default: "acdbf"
    },
    batchStatus: Number,
    districtNotificationStatus: Boolean,
    districtAcknowledgementStatus: Boolean
  },
  watch: {
    batchStatus() {
      this.refreshBatchData()
    },
    districtNotificationStatus() {
      this.refreshBatchData()
    },
    districtAcknowledgementStatus() {
      this.refreshBatchData()
    }
  }
}
</script>

<template>
  <div class="m-0">
    <div :class="valid ? 'card' : 'card border border-danger'">
      <div class="card-header d-flex justify-content-between align-items-center" :class="{ 'bg-warning text-dark': propertyHasChangedFromSnapshot('programAffectedLeasResponse') }">
        The proposed waiver(s) will apply to the following schools:
      </div>
      <div class="card-body">
        <div class="row" v-if="edit">
          <div class="col-4">
            <b-form-group label-class="font-weight-bold" label="School LEA(s) affected">
              <v-select multiple v-model="newAffectedLeas.leas" :reduce="lea => lea.code" :options="schoolLeaOptions" placeholder="Selection of one or more school LEAs is required" />
            </b-form-group>
          </div>
          <div class="col-3">
            <b-form-group label-class="font-weight-bold" label="Grades/Courses affected">
              <b-form-textarea v-model="newAffectedLeas.courses" placeholder="Specific grades/courses..." />
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group label-class="font-weight-bold" label="Interaction">
              <b-form-checkbox v-model="newAffectedLeas.asynchronous">
                Asynchronous
              </b-form-checkbox>
              <b-form-checkbox v-model="newAffectedLeas.synchronous">
                Synchronous
              </b-form-checkbox>
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group label-class="font-weight-bold" label="Delivery">
              <b-form-radio v-model="newAffectedLeas.delivery" name="delivery" value="Virtual (Online) / Remote (Distance)">
                Virtual <small>(Online)</small> / Remote <small>(Distance)</small>
              </b-form-radio>
              <b-form-radio v-model="newAffectedLeas.delivery" name="delivery" value="Blended (Hybrid)">
                Blended <small>(Hybrid)</small>
              </b-form-radio>
            </b-form-group>
          </div>
          <div class="col-1">
            <b-form-group label-class="font-weight-bold" label="Platforms">
              <b-form-checkbox v-model="newAffectedLeas.lms">
                LMS
              </b-form-checkbox>
              <b-form-checkbox v-model="newAffectedLeas.cms">
                CMS
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>
        <div class="row" v-if="edit">
          <div class="col-3">
            <b-button variant="primary" @click="addAffectedLeaRow" :disabled="!canAdd">
              <i class="fa fa-fw fa-plus-circle" /> Add Affected LEA(s)
            </b-button>
          </div>
        </div>
        <div class="row m-1" v-show="local.length > 0">
          <b-table :items="local" :fields="fields">
            <template v-slot:cell(leas)="data">
              <div>
                <span class="badge badge-info mr-1" v-for="lea in data.value" :key="lea">
                  {{ leaFormatter(lea) }}
                </span>
              </div>
            </template>
            <template v-slot:cell(interaction)="data">
              <div>
                <span class="badge badge-secondary mr-1" v-if="data.item.asynchronous">
                  Asynchronous
                </span>
                <span class="badge badge-secondary mr-1" v-if="data.item.synchronous">
                  Synchronous
                </span>
              </div>
            </template>
            <template v-slot:cell(platforms)="data">
              <div>
                <span class="badge badge-primary mr-1" v-if="data.item.lms">
                  LMS
                </span>
                <span class="badge badge-primary mr-1" v-if="data.item.cms">
                  CMS
                </span>
              </div>
            </template>
            <template v-slot:cell(action)="data">
              <div class="text-right">
                <b-button variant="link" @click="removeAffectedLeaRow(data.index)">
                  <i class="fas fa-fw fa-trash text-danger" />
                </b-button>
              </div>
            </template>
          </b-table>
        </div>
        <div v-if="!edit && local.length == 0">
          <b-form-invalid-feedback id="programAffectedLeas-input" class="d-print-none" :state="valid">
            <strong>You must specify the affected LEAs for the Digital Learning waiver request</strong>
          </b-form-invalid-feedback>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { JsonResponseMixin } from '@/components/JsonResponseMixin'
import DetailMixin from './DetailMixin'

function convertToSelectItem (key, value) {
  return { code: key, label: value }
}

export default {
 
  data() {
    return {
      newAffectedLeas: {
        leas: [],
        courses: ''
      }
    }
  },
  mixins: [JsonResponseMixin, DetailMixin],
  computed: {
    fields() {
      return [
        { key: 'leas', label: 'LEA(s)' },
        { key: 'courses', label: 'Grades/Courses' },
        { key: 'interaction', label: 'Interaction' },
        { key: 'delivery', label: 'Delivery' },
        { key: 'platforms', label: 'Platforms' },
        this.edit ? { key: 'action', thClass: 'text-right' } : null
      ]
    },
    schoolLeaOptions() {
      if (this.districtLea && this.leaDictionary[this.districtLea]) 
        return this.leaDictionary[this.districtLea].ownedLeas.map(lea => {
          return convertToSelectItem(lea.number, `${lea.name} - (${lea.number})`)
        })

      return []
    },
    canAdd() {
      return this.newAffectedLeas.leas.length > 0 
        && this.newAffectedLeas.courses.length > 0 
        && this.newAffectedLeas.delivery
        && (this.newAffectedLeas.asynchronous || this.newAffectedLeas.synchronous)
    }
  },
  methods: {
    addAffectedLeaRow() {
      if (!this.local) this.local = []

      //TODO validation of new leas 
      this.local.push(this.newAffectedLeas)
      this.newAffectedLeas = {
        leas: [],
        courses: ''
      }
    },
    removeAffectedLeaRow(leaRowIndex) {
      if(confirm(`Are you sure you want to remove these affected LEA(s)?`)) {
        this.local = this.local.filter((value, index) => index != leaRowIndex)
      }
    },
  },
  props: {
    districtLea: {
      type: String,
      default: ''
    }
  },
}
</script>

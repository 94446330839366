export const EnrollmentGrades = [
  { name: "Kindergarten", field: "kindergarten", gradeLevel: 0 },
  { name: "Grade 1", field: "grade1", gradeLevel: 1 },
  { name: "Grade 2", field: "grade2", gradeLevel: 2 },
  { name: "Grade 3", field: "grade3", gradeLevel: 3 },
  { name: "Grade 4", field: "grade4", gradeLevel: 4 },
  { name: "Grade 5", field: "grade5", gradeLevel: 5 },
  { name: "Grade 6", field: "grade6", gradeLevel: 6 },
  { name: "Grade 7", field: "grade7", gradeLevel: 7 },
  { name: "Grade 8", field: "grade8", gradeLevel: 8 },
  { name: "Grade 9", field: "grade9", gradeLevel: 9 },
  { name: "Grade 10", field: "grade10", gradeLevel: 10 },
  { name: "Grade 11", field: "grade11", gradeLevel: 11 },
  { name: "Grade 12", field: "grade12", gradeLevel: 12 },
  { name: "Ungraded", field: "ungraded", visible: false, gradeLevel: -1 },
  { name: "GED", field: "ged", visible: false }
]
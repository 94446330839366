<template>
  <div v-if="proposal">
    <b-alert v-model="selectedProposal.needsProposalCostReApproval" variant="warning">
      This proposal was approved with a budget of <strong>{{ formatCurrency(selectedProposal.approvedBudgetAmount) }}</strong>.
      <br>
      With the current cost-item adjustments, the new budget total is <strong>{{ formatCurrency(selectedProposal.amountBudgeted) }}</strong>, which is <strong>{{ selectedProposal.percentBudgetDeviation.toFixed(2) }}%</strong> outside of the approved budget.
      <br>
      Any cost-item changes deviating +/- 10% of the approved proposal budget must go through the approval process again.
    </b-alert>    
    <b-card>
      <div class="row">
        <div class="col-12">
          <b-button @click="$router.push(`/cte/perkins-v/application/${$router.currentRoute.params.applicationId}`)" class="mr-1 d-print-none float-right">
            <i class="fas fa-chevron-left" /> Return to Application
          </b-button>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-4">
          <h2 class="caption">
            Proposal Information
          </h2>
          <b-form-row>
            <b-col>
              <b-form-group label-cols="3" content-cols="8" label="Status:" label-class="font-weight-bold" class="mb-0 mt-0">
                <legend class="col-form-label">
                  <workflow-status-badge :state-map="ProposalStateMap" :status="selectedProposal.status" :show-label="false" />
                </legend>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row v-if="latestChangeLog && latestChangeLog.assignedToName">
            <b-col>
              <b-form-group label-cols="3" content-cols="8" label="Assigned to:" label-class="font-weight-bold" class="mb-0 mt-0">
                <legend class="col-form-label">
                  {{ latestChangeLog.assignedToName }}
                </legend>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group label-cols="3" content-cols="8" label="Proposal:" label-class="font-weight-bold" class="mb-0 mt-0">
                <legend class="col-form-label">
                  {{ selectedProposal.title }}
                </legend>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group label-cols="3" content-cols="8" label="Type:" label-class="font-weight-bold text-sm" class="mb-0 mt-0">
                <legend class="col-form-label">
                  {{ proposalType }}
                </legend>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group label-cols="3" content-cols="8" label="Program:" label-class="font-weight-bold" class="mb-0 mt-0">
                <legend class="col-form-label">
                  {{ programOfStudy }}
                </legend>
              </b-form-group>
            </b-col>
          </b-form-row>
        </div>
        <div class="col-md-4">
          <div class="row">
            <div class="col">
              <h2 class="caption">
                Applicant Funding Details
              </h2>
              <table class="table table-sm table-borderless">
                <tr>
                  <th>Amount Awarded to Applicant</th>
                  <td class="text-right">
                    {{ applicantFundingLimit.fundingLimit | currency }}
                  </td>
                </tr>
                <tr v-if="selectedProposal.approvedBudgetAmount">
                  <th>Original Amount Budgeted/Approved for Proposal</th>
                  <td class="text-right">
                    {{ formatCurrency(selectedProposal.approvedBudgetAmount) }}
                  </td>
                </tr>
                <tr>
                  <th>Updated Amount Budgeted for Proposal</th>
                  <td class="text-right">
                    {{ formatCurrency(proposalCost) }}
                  </td>
                </tr>
                <tr>
                  <th>Amount Budgeted on other Proposals</th>
                  <td class="text-right">
                    {{ formatCurrency(siblingProposalCost) }}
                  </td>
                </tr>
                <tr>
                  <th>Total Amount Budgeted</th>
                  <td class="text-right">
                    {{ formatCurrency(totalAmountBudgeted) }}
                  </td>
                </tr>
                <tr class=" border-dark border-top">
                  <th>Amount Remaining</th>
                  <td class="text-right">
                    {{ formatCurrency(amountRemaining) }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div v-if="selectedProposal.status > ProposalStates.Draft || canSubmit"> 
            <h2 class="caption">
              Workflow
            </h2>
            <div class="px-3">
              <state-change-widget
                :document="selectedProposal"
                :status="selectedProposal.status"
                :available-states="proposalAvailableStates"
                :state-map="ProposalStateMap"
                :transition-action="transitionAction"
                :identifiers="{ applicationId: $route.params.applicationId, proposalId: $route.params.proposalId }"
                :can-withdraw="canWithdraw" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-7 offset-md-5" />
      </div>
      <b-tabs card content-class="mt-3" class="mt-3" v-model="selectTabIndex">
        <b-tab title="Comments and History" :disabled="$v.$dirty && selectTabIndex != 0" active>
          <div class="row">
            <div class="col-12">
              <h2 class="mt-2">
                Proposal History
              </h2>
              <change-log-history-table :document="selectedProposal" :change-logs="selectedProposal.changeLogs" :state-map="ProposalStateMap" small />
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <comment-question-widget :identifiers="{ applicationId: $route.params.applicationId, proposalId: $route.params.proposalId }" :comments="selectedComments" />
            </div>
          </div>
        </b-tab>
        <b-tab title="Proposal Info" :disabled="$v.$dirty && selectTabIndex != 1">
          <proposal-tab-toolbar :can-save="$v.$dirty" :is-valid="isProposalInfoValid" :save-method="saveDetails" @cancel="cancel" :is-readonly="isReadonly" />
          <proposal-info-widget v-model="$v.proposal.$model" :is-readonly="isReadonly" v-if="selectedApplication.ay > 23" />
          <proposal-info-widget-old v-model="$v.proposal.$model" :is-readonly="isReadonly" v-else />
        </b-tab>
        <b-tab title="Narrative" :disabled="$v.$dirty && selectTabIndex != 2">
          <b-form>
            <proposal-tab-toolbar :can-save="$v.$dirty" :save-method="saveResponse.bind(null, 'narrativeResponse')" @cancel="cancel" :is-readonly="isReadonly" />
            <b-form-group label="Description">
              <b-form-textarea v-model="$v.proposal.narrativeResponse.$model.description" :disabled="isReadonly" />
            </b-form-group>
            <b-form-group label="Justification">
              <b-form-textarea v-model="$v.proposal.narrativeResponse.$model.justification" :disabled="isReadonly" />
            </b-form-group>
            <b-form-group label="Benefit">
              <b-form-textarea v-model="$v.proposal.narrativeResponse.$model.benefit" :disabled="isReadonly" />
            </b-form-group>
          </b-form>
        </b-tab>
        <b-tab title="Anticipated Impact" :disabled="$v.$dirty && selectTabIndex != 3">
          <proposal-tab-toolbar :can-save="$v.$dirty" :save-method="saveResponse.bind(null, 'anticipatedImpactResponse')" @cancel="cancel" :is-readonly="isReadonly" />
          <anticipated-impact-widget v-model="$v.proposal.anticipatedImpactResponse.$model" @touch="touch" @reset="reset" :is-readonly="isReadonly" />
        </b-tab>
        <b-tab title="Perkins Indicators" :disabled="$v.$dirty && selectTabIndex != 4">
          <proposal-tab-toolbar :can-save="$v.$dirty" :save-method="saveResponse.bind(null, 'perkinsIndicatorsFocusResponse')" @cancel="cancel" :is-readonly="isReadonly" />
          <perkins-indicators-widget v-model="$v.proposal.perkinsIndicatorsFocusResponse.$model" @touch="touch" @reset="reset" :is-readonly="isReadonly" />
        </b-tab>
        <b-tab title="Costs" :disabled="$v.$dirty && selectTabIndex != 5">
          <cost-item-list :is-readonly="!isApplicant || isCostItemsReadonly" />
        </b-tab>
        <b-tab title="Assurances" :disabled="$v.$dirty && selectTabIndex != 6">
          <proposal-tab-toolbar :can-save="$v.$dirty" :save-method="saveDetails" @cancel="cancel" :is-readonly="isReadonly" />
          <b-form-checkbox class="font-weight-bold" v-model="$v.proposal.assuranceCertified.$model" :disabled="isReadonly">
            I certify the following statements are true to the best of my knowledge
          </b-form-checkbox>
          <ul>
            <li>
              The use of Perkins dollars for this project will not be considered supplanting.
            </li>
            <li>
              The use of Perkins dollars for this project will not be used to purchase computing devices for 1-1 use.
            </li>
            <li>
              Project proposal is supported by the Comprehensive Local Needs Assessment (CLNA)
            </li>
          </ul>
          <!-- <animated-button button-class="btn btn-primary" :promise-resolver="submit" v-if="canSubmit" :disabled="$v.$dirty">
            {{ ProposalStateMap.get(proposalAvailableStates.advanceStates[0]).action }}
          </animated-button> -->
        </b-tab>        
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import store from '@/stores'
import CostItemList from './costItem/CostItemList'
import AnticipatedImpactWidget from './AnticipatedImpactWidget'
import PerkinsIndicatorsWidget from '../../common/PerkinsIndicatorsWidget'
import ProposalInfoWidget from './ProposalInfoWidget.vue'
import ProposalInfoWidgetOld from './ProposalInfoWidgetOld.vue'
import ProposalTabToolbar from './ProposalTabToolbar'
import { formatCurrency } from '@/helpers/formatters'
import ChangeLogHistoryTable from '@/components/workflow/ChangeLogHistoryTable'
import WorkflowStatusBadge from '@/components/workflow/WorkflowStatusBadge.vue'
import StateChangeWidget from '@/components/workflow/StateChangeWidget.vue'
import CommentQuestionWidget from './workflow/CommentQuestionWidget.vue'
import { Types as CommonTypes } from '@/modules/cte/stores'
import { Types } from '@/modules/cte/stores/perkinsv'
import { ProposalStateMap, ProposalStates } from '@/modules/cte/stores/perkinsvStateMap'
import { mapState } from 'vuex'
import { deepClone } from '@/helpers/globalHelpers'
import { ProposalTypes, ProposalTypeValues } from '../../enums'
import ProvidesApplicationCostItemBreakdown from './ProvidesApplicationCostItemBreakdown'
import { round } from '@/helpers/mathHelpers'

export default {
  components: {
    PerkinsIndicatorsWidget,
    CostItemList,
    AnticipatedImpactWidget,
    ProposalInfoWidget,
    ProposalInfoWidgetOld,
    ProposalTabToolbar,
    CommentQuestionWidget,
    ChangeLogHistoryTable,
    WorkflowStatusBadge,
    StateChangeWidget,
  },
  provide() {
    return {
      $v: this.$v
    }
  },
  validations: {
    proposal: {
      proposalType: {},
      assuranceCertified: {},
      clusterId: {},
      occupationalAreaId: {},
      pathwayId: {},
      programOfStudyId: {},
      title: {},
      narrativeResponse: {},
      anticipatedImpactResponse: {},
      perkinsIndicatorsFocusResponse: {},
    }
  },
  data() {
    return { 
      formatCurrency,
      proposal: null,
      selectTabIndex: 0,
      ProposalStateMap,
      ProposalStates,
    }
  },
  computed:{
    ...mapState(CommonTypes.path, [CommonTypes.state.occupationalAreaMetadata]),
    ...mapState(Types.path, [
      Types.state.selectedApplication, 
      Types.state.selectedProposal, 
      Types.state.proposalAvailableStates,
      Types.state.selectedComments
    ]),
    ...mapState('globalModule', ['userContext']),
    totalAmountBudgeted() {
      return round(this.proposalCost, 2) + round(this.siblingProposalCost, 2)
    },
    amountRemaining() {
      return this.applicantFundingLimit.fundingLimit - this.totalAmountBudgeted
    },
    isApplicant() {
      const applicantId = (this.selectedApplication || {}).applicantId
      return (this.userContext.CoordinatorRoles || []).some(c => c.SchoolLEA == applicantId)
    },
    isNarrativeValid() {
      return Boolean(this.selectedProposal.narrativeResponse.description) && 
        Boolean(this.selectedProposal.narrativeResponse.justification) && 
        Boolean(this.selectedProposal.narrativeResponse.benefit)
    },
    isProposalInfoValid() {
      if (this.selectedProposal.proposalType == 0) return false
     
      if (this.selectedApplication.ay > 23)
      {
        return (this.proposal.proposalType == ProposalTypeValues.AllProgramAreas || this.proposal.proposalType == ProposalTypeValues.DeficientAllProgramAreas) || 
        this.proposal.clusterId > 0
      }
      else return (this.proposal.proposalType == ProposalTypeValues.AllProgramAreas || this.proposal.proposalType == ProposalTypeValues.DeficientAllProgramAreas) || 
        this.proposal.occupationalAreaId > 0
    },
    isDraftWithAdvanceStates() {
      return (this.proposalAvailableStates.advanceStates || []).length == 1 && 
        this.selectedProposal.status == ProposalStates.Draft
    },
    canSubmit() {
      return this.isNarrativeValid && 
        this.isProposalInfoValid && 
        // this.selectedProposal.perkinsIndicatorsFocusResponse.some(p => p.proposalFocus) &&
        this.selectedProposal.assuranceCertified && 
        this.isDraftWithAdvanceStates
    },
    canWithdraw() {
      return this.isApplicant && (this.proposalAvailableStates.returnStates || []).length == 1
    },
    proposalType() {
      return (ProposalTypes.find(p => p.value == this.selectedProposal.proposalType) || {}).text
    },
    programOfStudy() {
      if (this.selectedProposal.programOfStudyId && this.occupationalAreaMetadata.programsOfStudy) {
        return (this.occupationalAreaMetadata.programsOfStudy.find(p => p.id == this.selectedProposal.programOfStudyId) || {}).name
      }
      return ''
    },
    isEditableStatus() {
      return [ProposalStates.Draft, ProposalStates.Revising, ProposalStates.ReturnedToApplicant].includes(this.selectedProposal.status)
    },
    isCostItemsReadonly() {
      return !((this.isEditableStatus || this.selectedProposal.status == ProposalStates.RevisingCostItems) && (this.proposalAvailableStates.advanceStates || []).length > 0)
    },
    isReadonly() {
      return !(this.isEditableStatus && (this.proposalAvailableStates.advanceStates || []).length > 0)
    },
    latestChangeLog() {
      const length = (this.selectedProposal.changeLogs || []).length
      return length > 0 ? this.selectedProposal.changeLogs[length-1] : {}
    },
    transitionAction: () => Types.actions.transitionProposalToState,
  },
  watch: {
    proposal: {
      immediate: true,
      deep: true,
      handler() {
        this.touch()
      }
    },
    selectedProposal: {
      immediate: true,
      deep: true,
      handler() {
        this.initProposal()
      }
    }
  },
  methods: {
    reset() {
      this.$v.$reset()
    },
    cancel() {
      this.initProposal()
    },
    initProposal() {
      if (this.selectedProposal) 
        this.proposal = deepClone(this.selectedProposal)
      else 
        this.proposal = null

      this.reset()
    },
    saveDetails() {
      return this.$store.dispatch(Types.actions.saveProposalDetails, {
        applicationId: this.$route.params.applicationId,
        ...this.$v.proposal.$model
      }).then(() => { 
        this.$v.$reset() 
      })
    },
    saveResponse(responseName) {
      return this.$store.dispatch(Types.actions.saveProposalResponse, {
        applicationId: this.$route.params.applicationId,
        proposalId: this.$route.params.proposalId,
        responseName: responseName, 
        response: this.proposal[responseName]
      })
      .then(() => { 
        this.$v.$reset() 
      })
    },
    touch() {
      this.$v.proposal.$touch()
    },
  },
  props: {
    mode: String
  },
  mounted() {
    return this.selectTabIndex = this.$route.query.selectTabIndex ? parseInt(this.$route.query.selectTabIndex) : 0
  },
  beforeRouteEnter:(to, from, next) => {
    Promise.all([
      store.dispatch(Types.actions.getApplication, { applicationId: to.params.applicationId, proposalId: to.params.proposalId }),
      store.dispatch(Types.actions.getProposal, { applicationId: to.params.applicationId, proposalId: to.params.proposalId })
    ])
    .then(() => {
      next()
    })
  },
  mixins: [ProvidesApplicationCostItemBreakdown]
}
</script>

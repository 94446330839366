<template>
  <div>
    <div class="row">
      <div class="col-lg-4 offset-lg-2"> 
        <h2>Option 1 &ndash; Login using your State Active Directory credentials</h2>
        <b-card header="APSCN Login" header-bg-variant="primary">
          <b-card-text>
            <p>
              You can login using your State Active Directory credentials from the Insights Login page.
            </p>
            <a class="btn btn-lg btn-primary" href="/Account/Login?returnUrl=/Academics/ESA">
              Go to Login page
            </a>
          </b-card-text>
        </b-card>
      </div>
      <div class="col-lg-4">
        <h2>Option 2 &ndash; District Superintendent Login</h2>
        <b-card header="ESA Plan Login" header-bg-variant="primary">
          <b-card-text>
            <div v-if="accessError" class="alert bg-danger text-light">
              <i class="fa fa-exclamation-circle fa-fw fa-lg" />
              Invalid District credentials
            </div>
            <b-form>
              <div id="step1">
                <p>To begin filling out the ESA Plan for your district, please confirm your district's LEA number and your e-mail address as the Superintendent and click <strong>Continue</strong>.</p>
               
                <b-form-group label="Enter your District's 7-digit LEA Number" class="mt-3">
                  <b-input-group>
                    <template v-slot:append>
                      <b-input-group-text>
                        <i v-if="district" class="fa fa-check-circle text-success" />
                        <i v-if="!district" class="fa fa-question-circle text-primary" />
                      </b-input-group-text>
                    </template>
                    <b-input type="text" v-model="districtLea" />
                  </b-input-group>

                  <span class="text-danger" v-if="$v.districtLea.$dirty">A 7 digit LEA is required</span>
                  <h3 class="bg-success text-light p-2 rounded-bottom" v-if="district">
                    {{ district.name }}
                  </h3>
                </b-form-group>

                <b-form-group label="Enter your Email address">
                  <b-input-group>
                    <template v-slot:append>
                      <b-input-group-text>
                        <i class="far fa-envelope text-primary" />
                      </b-input-group-text>
                    </template>
                    <b-input type="text" v-model="email" />
                  </b-input-group>
                </b-form-group>
                <b-form-row class="mt-2">
                  <b-col class="text-right">
                    <b-button :disabled="!(district && email)" variant="primary" @click="confirmAccess">
                      Continue
                    </b-button>
                  </b-col>
                </b-form-row>
              </div>
            </b-form>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import { required, minLength, maxLength, email, numeric } from 'vuelidate/lib/validators'

Vue.use(Vuelidate)

export default {
  data() {
    return {
      loadData: Promise,
      districtLea: null,
      email: null,
      failureMessage: 'Unspecified error',
      accessGranted: false,
      accessError: null,
      UserName: null,
      Password: null,
    }
  },
  validations: {
    districtLea: {
      required,
      numeric,
      minLength: minLength(7), 
      maxLength: maxLength(7)
    },
    email: {
      required,
      email
    }
  },
  computed: {
    ...mapGetters('globalModule', ['leaDictionary', 'leaList']),
    district() {
      var lea = this.leaDictionary[this.districtLea]
      if (lea && lea.type == 2) return lea
      else return null
    },
  },
  methods: {
    confirmAccess() {
      const apiService = new ApiService()
      apiService.Post(`/api/digitalLearning/authenticate?districtLea=${this.districtLea}&emailAddress=${this.email}`).then(() => {
        this.accessError = null
        window.location.replace(`/Academics/ESA?lea=${this.districtLea}`)
      }).catch(error => {
        console.log(error)
        this.accessError = error
      })
    },
  },
  components: {
  },
  watch: {
    district() {
      this.accessError = null
    }
  }
}
</script>

<template>
  <b-form @submit.prevent>
    <b-form-group label="Proposal Type">
      <b-form-select v-model="local.proposalType" :options="proposalTypes" :disabled="isReadonly" :state="local.proposalType > 0" />
      <b-form-invalid-feedback id="new-lea-input">
        You must select a program type before proceeding.
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Proposal Title">
      <b-form-input v-model="local.title" :disabled="isReadonly" />
    </b-form-group>
    <b-form-row v-if="!proposalTypeIsAllPrograms">
      <b-col>
        <b-form-group label="Occupational Area">
          <b-form-select v-model="local.occupationalAreaId" :options="areas" :disabled="isReadonly || proposalTypeIsAllPrograms" :state="local.occupationalAreaId > 0" />
          <b-form-invalid-feedback>
            You must select an Occupational Area before proceeding.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Cluster">
          <b-form-select v-model="local.clusterId" :options="clusters" :disabled="isReadonly || proposalTypeIsAllPrograms" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Pathway">
          <b-form-select v-model="local.pathwayId" :options="pathways" :disabled="isReadonly || proposalTypeIsAllPrograms" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Progam of Study">
          <b-form-select v-model="local.programOfStudyId" :options="programsOfStudy" :disabled="isReadonly || proposalTypeIsAllPrograms" />
        </b-form-group>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import { Types as CommonTypes } from '@/modules/cte/stores'
import { Types } from '@/modules/cte/stores/perkinsv'
import { mapState, mapGetters } from 'vuex'
import { ProposalTypes } from '../../enums'
import { JsonResponseMixin } from '@/components/JsonResponseMixin'

export default {
  mixins: [JsonResponseMixin],
  data() {
    return {
      
    }
  },
  props: {
    mode: {
      type: String,
      default: 'edit'
    },
    isReadonly: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.selectedProposal, Types.state.selectedApplication]),
    ...mapGetters(CommonTypes.path, [CommonTypes.getters.occupationalAreaDrilldownForAY]),
    areas() { return [{value: null, text: 'All Occupational Areas'}, ...this.occupationalAreaDrilldownForAY(this.selectedApplication.ay).map(oad => {return {value: oad.id, text: oad.name}})]},
    area() { return this.occupationalAreaDrilldownForAY(this.selectedApplication.ay).find(oad => oad.id == this.local.occupationalAreaId) || null},
    clusters() { return [{value: null, text: 'All Clusters'}, ...(this.area?.children || []).map(ch => {return { value: ch.id, text: ch.name}})]},
    cluster() { return this.area ? this.area.children.find(ch => ch.id == this.local.clusterId) : null },
    pathways() { return [{value: null, text: 'All Pathways'}, ...(this.cluster?.children || []).map(ch => { return { value: ch.id, text: ch.name}})]},
    pathway() { return this.cluster ? this.cluster.children.find(ch => ch.id == this.local.pathwayId) : null},
    programsOfStudy() { return [{value: null, text: 'All Programs of Study'}, ...(this.pathway?.children || []).map(ch => {return {value: ch.id, text: ch.name}})]},
    programOfStudy() { return this.pathway ? this.pathway.children.find(ch => ch.id == this.local.programOfStudyId) : null },
    proposalType() {
      return (this.local || {}).proposalType
    },
    proposalTypeIsAllPrograms() {
      return ProposalTypes.filter(p => p.text.includes('All Program')).some(p => p.value == this.proposalType)
    },
    proposalTypes() {
      if (this.local.proposalId) {
        return ProposalTypes.filter(p => p.value > 0)
      }
      return ProposalTypes
    }
  },
  watch: {
    proposalType() {
      if (this.proposalTypeIsAllPrograms) {
        this.$set(this.local, 'occupationalAreaId', null)
        this.$set(this.local, 'clusterId', null)
        this.$set(this.local, 'pathwayId', null)
        this.$set(this.local, 'programOfStudyId', null)
      }
    },
    selectedApplication: {
      immediate: true,
      handler(newApplication) {
        if (Object.keys(newApplication).length)
          this.$store.dispatch(CommonTypes.actions.getOAMetadata, { ay: newApplication.ay })
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="card">
      <div class="card-header">
        Approval Workflow
      </div>
      <div class="card-body p-2">
        <div v-if="currentRequest.syncStatus.attempted" class="text-center">
          <div v-if="currentRequest.syncStatus.succeeded" class="alert alert-success">
            Request has been synced to LEAMS
          </div>
          <div v-else class="alert alert-danger">
            Request failed to sync to LEAMS
          </div>
        </div>

      

        <div v-if="reviewing">
          <div class="row" v-if="isSendToPSAAvailableState && !canCurrentRequestBeSentToPSA">
            <div class="col-12">
              <div
                class="alert alert-warning"
              >
                You must assign a School Year prior to Sending to PSA.
              </div>
            </div>
          </div>
          
          <div class="row" v-if="isApproveAvailableState && !canCurrentRequestBeApproved">
            <div class="col-12">
              <div
                class="alert alert-warning"
              >
                To Approve a request, all 'New LEA Request' items must have an LEA Assigned, ALL items must have a Reconfiguration Code assigned, and a Fiscal Year must be assigned.
              </div>
            </div>
          </div>
          <div
            class="row"
            v-if="isReturnToReviewerAvailableState && !allItemsAssignedReconfigurationCodes"
          >
            <div class="col-12">
              <div
                class="alert alert-warning"
              >
                Please make sure ALL items have a Reconfiguration Code assigned
              </div>
            </div>
          </div>
        </div>
        <recall-widget :request="request" />
        <div class="row">
          <div class="col-md-7">
            <label>
              Current Status:
              <b-badge
                :variant="stateMap.get(this.request.requestStatus).variant"
              >{{ stateMap.get(this.request.requestStatus).status }}</b-badge>
            </label>
          </div>
          <div class="col-md-5 text-right">
            <available-states @set-state="onSetState" v-if="!(readOnly || currentRequest.syncStatus.attempted)" />
          </div>
        </div>
        <div class="row mt-2" v-if="(currentState && potentialStateCount)">
          <div class="col-md-12" v-if="currentState && currentState.requiresComment">
            You must provide a comment related to this change in status:
            <textarea
              v-model="commentText"
              class="form-control"
              placeholder="Enter comments here..."
            />
          </div>

          <div class="col-md-12">
            <Promised :promise="actionPromise">
              <template v-slot:default>
                <b-button block class="mt-2 disabled" disabled="disabled">
                  <i class="fas fa-spinner fa-spin" />&nbsp;Processing...
                </b-button>
              </template>

              <template v-slot:pending>
                <b-button block class="mt-2 disabled" disabled="disabled">
                  <i class="fas fa-spinner fa-spin" />&nbsp;Processing...
                </b-button>
              </template>

              <template v-slot:rejected="error">
                <button @click="confirmStatusChange" class="btn btn-info float-right d-print-none">
                  <i class="fas fa-exclamation-circle" />
                  &nbsp;{{ currentState.action }}
                  <span class="d-none">{{ error }}</span>
                </button>
              </template>
            </Promised>
            <b-button
              v-if="currentState"
              block
              class="mt-2"
              :disabled="currentState.requiresComment && commentText.length==0"
              @click="confirmStatusChange"
              :variant="currentState.variant || 'primary'"
              v-show="actionPromise == null"
            >
              {{ currentState.action }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <comments-list-widget :request="request" class="mt-2" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { Promised } from 'vue-promised'
import CommentsListWidget from './commentsListWidget'
import AvailableStates from './availableStates'
import RecallWidget from './recallWidget'
export default {
  name: 'approval-widget',
  data() {
    return {
      commentText: '',
      currentState: null,
      actionPromise: null
    }
  },
  components: {
    CommentsListWidget,
    AvailableStates,
    Promised,
    RecallWidget
  },
  mounted() {
    var currentState = this.stateMap.get(this.request.requestStatus)
    if (currentState.defaultNextState) {
      this.currentState = this.stateMap.get(currentState.defaultNextState)
    }
  },
  computed: {
    ...mapState('toolsModule/leaAssignment', ['stateMap', 'currentRequest', 'currentResponse']),
    ...mapGetters('toolsModule/leaAssignment', ['canCurrentRequestBeApproved', 'allItemsAssignedReconfigurationCodes', 'canCurrentRequestBeSentToPSA']),
    ...mapState('requestModule', ['availableStates']),
    ...mapGetters('requestModule', ['potentialStateCount']),

    isApproveAvailableState() {
      return this.availableStates && this.availableStates.advanceStates && this.availableStates.advanceStates.filter(s => s == 100).length > 0
    },

    isSendToPSAAvailableState() {
      return this.availableStates && this.availableStates.advanceStates && this.availableStates.advanceStates.filter(s => s == 11).length > 0
    },

    isReturnToReviewerAvailableState() {
      return this.availableStates && this.availableStates.advanceStates && this.availableStates.advanceStates.filter(s => s == 12).length > 0
    },
    
  },
  methods: {
    onSetState(newState) {
      this.currentState = newState
    },
    confirmStatusChange() {

      var onBefore = this.onBeforeStateChange || (() => Promise.resolve(true))

      this.actionPromise = onBefore()
        .then(() => {
          return this.$store.dispatch('requestModule/transitionToState', {
            requestId: this.request.requestId,
            toState: this.currentState.code,
            comment: this.currentState.requiresComment ? this.commentText : this.currentState.action
          })
        })
        .then(() => {
          this.$emit('status-changed', { status: this.currentState.code })
        })
    },
    addComment() {
      return this.$store.dispatch('requestModule/createRequestComment', {
        requestId: this.request.requestId,
        text: this.commentText
      })
    },

    returnToList() {
      return this.$router.push('/tools/lea-assignment/requests')
    }
  },
  props: {
    request: Object,
    onBeforeStateChange: Function,
    readOnly: Boolean,
    reviewing: Boolean
  }
}
</script>

<template>
  <step-container :academic-year="Number($route.params.ay)" task-code="CM" title="Perkins V Consortia Management">
    <div class="card mt-3 bg-light">
      <div class="card-header">
        District / Consortia
      </div>
      <div class="card-body">
        <loadable-chart-card :load-data="loadData" :enable-data-toggle="false" card-style="overflow-y: hidden">
          <district-consortia-table :items="districtLeas" />
        </loadable-chart-card>
      </div>
    </div>
  </step-container>
</template>

<script>
import { mapGetters } from 'vuex'
import DistrictConsortiaTable from './DistrictConsortiaTable'
import { Types } from '@/modules/cte/stores/index'
import LoadableChartCard from '@/components/LoadableChartCard.vue'
import StepContainer from '../StepContainer'

export default {
  data() {
    return {
      loadData: Promise.resolve(true)
    }
  },
  computed: {
    ...mapGetters(Types.path, [Types.getters.districtLeas]),
  },
  components: {
    DistrictConsortiaTable,
    LoadableChartCard,
    StepContainer
  },
  mounted() {
    this.loadData = this.$store.dispatch(Types.actions.getConsortiumOptOutLeas, { ay: this.$route.params.ay })
  }
}
</script>

<template>
  <div>
    <pdf-button />
    <excel-button title="Public Educators" />
    <h1>Public Educators</h1>
    <h2><lea-display :lea="selectedLea" /></h2>
    <public-educator-widget />

    <educator-details-widget v-if="isAuthenticated" :details="staffDetails" :details-visible="staffDetailsVisible" :staff-detail-loaded="loadStaffDetail" />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import PublicEducatorWidget from './PublicEducatorWidget.vue'
import EducatorDetailsWidget from './EducatorDetailsWidget.vue'
import { PageMixin } from '../../PageMixin'

export default Vue.extend({
  name: 'public-educator-page',
  mixins: [PageMixin],
  data() {
    return {
      loadStaffDetail: null
    }
  },
  components: {
    PublicEducatorWidget,
    EducatorDetailsWidget
  },
  computed: {
    staffDetails() {
      return this.$store.state.hcptModule.additionalReporting.staffDetails
    },
    staffDetailsVisible() {
      //only show staff details at school or district level
      return (
        this.isAuthenticated && this.selectedLea && this.selectedLea.type <= 2
      )
    },
    ...mapGetters('globalModule', ['isAuthenticated'])
  },
  methods: {
    loadStaff() {
      this.loadStaffDetail = this.$store.dispatch(
        'hcptModule/getPublicEducatorDetails',
        {
          lea: this.selectedLea.number
        }
      )
    }
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if (newLea && newLea.number && this.isAuthenticated) {
          this.loadStaff()
        }
      }
    }
  }
})
</script>
<template>
  <b-row :no-gutters="true" class="w-100">
    <b-row class="w-100">
      <b-col cols="4">
        <label class="font-weight-bold">{{ categoryLetter }}. {{ category }}</label>
      </b-col>
      <b-col cols="2">
        <label class="font-weight-bold">Minor Points</label>
      </b-col>
      <b-col cols="4">
        <label class="font-weight-bold">Major Points</label>
      </b-col>
    </b-row>
    <b-row v-for="component in components" :key="component.key" class="w-100">
      <b-col cols="4">
        <span class="font-weight-bold">{{ component.key }}</span>
      </b-col>
      <b-col cols="2" class="text-center">
        <span>{{ component.value.item1 }}</span>
      </b-col>
      <b-col cols="2" class="text-center">
        <span>{{ component.value.item2 }}</span>
      </b-col>
    </b-row>
    <hr class="col-offset-4 col-4">
    <b-row class="w-100">
      <b-col cols="4">
        <label class="font-weight-bold">{{ category }} Totals</label>
      </b-col>
      <b-col cols="2" class="text-center">
        <span>{{ totalPoints(components).item1 }}</span>
      </b-col>
      <b-col cols="2" class="text-center">
        <span>{{ totalPoints(components).item2 }}</span>
      </b-col>
    </b-row>
  </b-row>
</template>

<script>
export default {
  data(){ return { } },
  props:{    
    components: {
      type: Array,
      default: () => []
    },
    category: String,
    categoryLetter: String
  },
  methods:{    
    totalPoints(components){
      return [...components.map(c => c.value),...[]].reduce(function(total,c) { 
        return {item1: c.item1 + total.item1, item2: c.item2 + total.item2} 
      },{item1:0, item2:0})
    }
  }
}
</script>

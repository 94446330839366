<template>
  <highcharts class="chart" :options="chartOptions" />
</template>

<script>
import { mapState } from 'vuex'
import startCase from 'lodash.startcase'

export default {
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'column',
          height: this.$printMode ? 650 : 400
        },
        legend: {
          maxHeight: this.$printMode ? 450 : 200
        },
        credits: {
          text: ''
        },
        title: {
          text: `${this.mode}ly District Closures`
        },
        plotOptions: {
          column: {
            stacking: 'normal'
          }
        },
        series: [],
        xAxis: {
          title: {
            text: this.xAxis
          },
          categories: this.categories
          //type: 'category'
        },
        yAxis: {
          title: {
            text: 'Notices'
          },
          endOnTick: false
        }
      }
    }
  },
  computed: {
    ...mapState('core/sctClosures', ['monthSummaries', 'weekSummaries'])
  },
  props:{
    xAxis: String,
    mode: String
  },
  watch:{
    districtClosures: {
      immediate: true,
      handler() {
        this.chartOptions.series = []
        
        var summaries = this.mode == 'Month' ? this.monthSummaries : this.weekSummaries
        const categories = [...new Set(summaries.flatMap(s => s.typeCounts.map(c => c.rangeString)))]
        this.chartOptions.xAxis.categories = categories
        this.chartOptions.series = summaries.map(s => {
          return {
            name: startCase(s.modType),
            data: categories.map(range => (s.typeCounts.find(rangeCount => rangeCount.rangeString == range) || {}).notices || 0)
          }
        })
      }
    }
  }
}
</script>
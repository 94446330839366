<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { mapState } from 'vuex'
import LoadableChartCard from '../../LoadableChartCard'
import { ChartMixin } from '../../ChartMixin'
export default {
  name: 'ct-progress-summary',
  computed: {
    ...mapState('core/curriculumTransparency', ['surveyProgress']),
    transformedItems() {
      return this.surveyProgress.map(p => {
        const link = `/Core/curriculumtransparency?lea=${p.districtLEA}`
        
        return {
          districtLea: p.districtLEA,
          districtName: p.districtName,
          status: p.surveyStatus == 0 ? 'Yet To Begin' : p.surveyStatus == 1 ? 'In Progress' : 'Completed',
          viewSurveyHTML: `<a class="btn btn-link p-0" href="${link}"><i class="fa fa-eye"></i> View Survey</a>`
        }
      })
    }
  },
  methods: {
    dataItems() {
      return this.transformedItems
    }
  },
  props: {
    loadData: Promise,
    phase: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'column'
        },
        plotOptions: {
          series: {
            minPointLength: 3
          },
          column: {
            dataLabels: {
              enabled: true
            }
          }
        },
        credits: 'Curriculum Transparency Survey',
        title: {
          text: 'Curriculum Transparency Survey'
        },
        series: [{}],
        xAxis: {
          title: {
            text: 'Survey Progress'
          },
          labels: {
            enabled: false
          },
          categories: ['Reponse Status']
        },
        yAxis: {
          title: {
            text: 'Districts'
          },
          endOnTick: false
        }
      }
    }
  },
  components: {
    LoadableChartCard
  },
  mixins: [ChartMixin],
  watch: {
    transformedItems: {
      immediate: true,
      handler(newData) {
        this.chartOptions.series = []
       
        var seriesData = {
          'Yet To Begin': { value: 0 , color: '#dc3545'},
          'In Progress': { value: 0 },
          'Completed': { value: 0 , color: '#28a745' }
        }

        newData.forEach(d => {
          seriesData[d.status].value++
        })

        Object.keys(seriesData).forEach(k => {
          this.chartOptions.series.push({ data: [seriesData[k].value], name: k, color: seriesData[k].color })
        })
      }
    }
  }
}
</script>

<template>
  <div v-bind="$attrs">
    <b-alert v-model="showError" variant="danger" dismissible>
      {{ errorMessage }}
    </b-alert>
    <b-table responsive small striped hover :items="files" :fields="columns" show-empty empty-text="No files uploaded">
      <template v-slot:head(action)>
        <div class="text-right" v-if="!readonly">
          <b-button variant="secondary" @click="onAddItem()" :disabled="busy">
            <i class="fas fa-file-upload fa-fw text-white" /> Upload a File
          </b-button>
        </div>
      </template>
      <template v-slot:cell(action)="data">
        <div class="text-right">
          <b-button size="sm" variant="info" class="mr-2" title="Download" :disabled="downloading" @click="onDownloadItem(data.item)">
            <i class="fa fa-download fa-fw text-white" />
          </b-button>
          <b-button size="sm" variant="danger" @click="onRemoveItem(data)" title="Delete" v-if="!readonly">
            <i class="fa fa-trash fa-fw text-white" />
          </b-button>
        </div>
      </template>
    </b-table>
    <b-alert variant="info" :show="downloading">
      File download in progress
    </b-alert>
    <upload-modal @upload="uploadFile($event)" />
  </div>
</template>

<script>
import UploadModal from '@/modules/cte/components/careerCoach/widgets/shared/UploadModal.vue'
import axios from 'axios'
import { Types } from '@/modules/cte/stores/careercoach/index.js'
import getMime from 'name2mime'
import moment from 'moment'
import prettyBytes from 'pretty-bytes'

export default {
  data() {
    return {
      columns: [
        {
          label: 'Name',
          key: 'filename'
        },
        {
          label: 'Type',
          key: 'mimeType',
          formatter: (_1, _2, row) => {
            var name = getMime(row.filename).name
            if (name == 'Default') name = row.filename.split('.').pop().toUpperCase() + ' file'
            return name
          }
        },
        {
          label: 'Size',
          key: 'size',
          formatter: (value) => { return prettyBytes(value) }
        },
        { 
          label: 'Upload Time',
          key: 'createdDate',
          formatter: (value) => { return moment(value).format("MM/DD/YYYY h:mm a") }
        },
        {
          key: 'action',
          thClass: 'text-right'
        },
      ],
      busy: false,
      uploadDone: false,
      result: null,
      filename: '',
      showError: false,
      errorMessage: null,
      localFiles: this.files,
      downloading: false
    }
  },
  methods: {
    onRemoveItem(item) {
      var self = this
      this.$bvModal.msgBoxConfirm(`Are you sure you want to delete this file?`)
        .then((result) => {
          if (result) {
            this.$store.dispatch(Types.actions.deleteCertificationFile, { lea: this.selectedLea.number, studentId: this.studentInfoId, certificationId: this.certificationId, fileName: item.item.filename })
              .then(() => self.$delete(self.files, item.index))
          }
        })
    },
    onAddItem() {
      this.$bvModal.show('uploadModal')
    },
    onDownloadItem(item) {
      this.downloading = true
      this.$store.dispatch(Types.actions.downloadCertificationFile, { lea: this.selectedLea.number, studentId: this.studentInfoId, certificationId: this.certificationId, fileName: item.filename })
        .then(() => this.downloading = false)
      
    },
    uploadFile(file) {
      if (file) {
        var self = this
        this.result = null
        this.busy = true
        this.filename = file.name

        var formData = new FormData()
        formData.append('file', file, file.name)
        axios.post(`/api/cte/career-coach/${this.selectedLea.number}/student/${this.studentInfoId}/certification-files/${this.certificationId}`, formData,
          {
            headers: { 
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
              let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
              if (percentCompleted >= 100) this.uploadDone = true
            }
          }
        ).then((response) => {
            self.result = response.data
            self.localFiles.push(self.result)
            self.busy = false
            self.uploadDone = true
            self.$emit('file-uploaded', this.localFiles)
        }).catch(err => {
          if(err.response) {
            self.errorMessage = err.response.data
            self.showError = true
          }
          else if(err.request) {
            self.errorMessage = 'An error occurred sending your request to the server'
            self.showError = true
          }          
          self.busy = false
          self.uploadDone = false
        })
      }
    }
  },
  props: {
    files: {
      type: Array,
      default: () => []
    },
    studentInfoId: {
      type: String,
      required: true
    },
    certificationId:{
      type: String,
      required: false
    },
    readonly: {
      type: Boolean,
      default: () => true
    }
  },
  components: {
    UploadModal
  }
}
</script>

<style>

</style>
<template>
  <div>
    <h4>
      Submitted Surveys: 
      <Promised :promise="loadData">
        <template v-slot:pending>
          <i class="fas fa-spinner fa-spin" />
        </template>
        <template v-slot>
          {{ surveys.length }}
        </template>
        <template v-slot:rejected="error">
          <i class="fas fa-exclamation-circle">
            <span class="d-none">
              {{ error }}
            </span>
          </i>
        </template>
      </Promised>
    </h4>
  </div>
</template>

<script>
import { Promised } from 'vue-promised'
import { mapState } from 'vuex'

export default {
  name: 'teacher-survey-count',
  props: {
    loadData: Promise
  },
  computed: {
    ...mapState('surveys/teacherSurvey', ['surveys']),
  },
  components: {
    Promised
  }
}
</script>

<style>

</style>
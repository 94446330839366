<template lang="html">
  <button :class="buttonClass" @click="resolvePromise" :disabled="disabled">
    <slot />
    <Promised :promise="actionPromise">
      <template v-slot:pending>
        &nbsp;<i class="fas fa-spinner fa-spin" />
      </template>
      <template v-slot="data">
        <div style="display:none">
          <span class="d-none">
            {{ data }}
          </span>
          <slot />
        </div>
      </template>
      <template v-slot:rejected="error">
        <i class="fas fa-exclamation-circle">
          <span class="d-none">
            {{ error }}
          </span>
        </i>
      </template>
    </Promised>
  </button>
</template>

<script>
import { Promised } from 'vue-promised'

export default {
  props: {
    buttonClass: String,
    promiseResolver: Function,
    disabled: Boolean
  },
  data() {
    return {
      actionPromise: null
    }
  },
  methods: {
    resolvePromise() {
      this.actionPromise = this.promiseResolver()
    }
  },
  components: {
    Promised
  }
}
</script>
<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { InstructionalOptionMixin } from './InstructionalOptionMixin'
import { credits } from '../../../helpers/credits'
import sumBy from 'lodash.sumby'
//import Highcharts from 'highcharts'
import { Types } from '@/stores/onDemandModule'

const VirtualByFRLOptions = [
  { value: 'FPP', name: 'Full Price Paid', description: 'Full Price Paid' },
  { value: 'FRL', name: 'Free/Reduced Lunch', description: 'Free/Reduced Lunch' }
]

export default {
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'column',
          plotBackgroundColor: '#f8f9fa',
          plotShadow: false
        },
        title: {
          text: 'Virtual Students by Free/Reduced Lunch'
        },
        legend: {
          enabled: true
        },
        xAxis: {
          
          labels: {
            enabled: false
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Total Virtual Students'
          }
          //,
          // stackLabels: {
          //   enabled: false,
          //   style: {
          //     fontWeight: 'bold',
          //     color:
          //       // theme
          //       (Highcharts.defaultOptions.title.style && Highcharts.defaultOptions.title.style.color) || 'gray'
          //   }
          // }
        },
        credits: credits.InsightsNightlyDataPulls,
        plotOptions: {
            "column": {
              "minPointLength":3
            },
            "series":{
              "stacking":"normal"
            }
        },
      }
    }
  },
  mixins: [ChartMixin, InstructionalOptionMixin],
  computed: {
    virtualStudentsByMealStatus() {
      return this.$store.getters[Types.getters.virtualStudentsByMealStatus]
    }
  },
  watch: {
    virtualStudentsByMealStatus: {
      immediate: true,
      handler(newData) {
        if (newData && this.mode == 'current') {
          this.chartOptions.series = [
            { name: 'Free/Reduced Lunch', value: true },
            { name: 'Full Price Paid', value: false }
          ].map(option => {
            return {
              name: option.name,
              data: [
                sumBy(newData, i => {
                  return i.freeReducedLunch == option.value ? i.studentCount : 0
                })
              ]
            }
          })
        }
      }
    },
    metricSnapshots: {
      immediate: true,
      handler(newData) {
        if (newData && newData.virtualStudentsByFRL && this.mode == 'trend') {
          this.chartOptions.plotOptions = {
            column: {
              pointWidth: 8,
              dataLabels: {
                enabled: false
              }
            }
          }

          this.chartOptions.chart.plotBackgroundColor = '#ffffff',
          this.chartOptions.chart.plotShadow = true,
          this.chartOptions.legend.enabled = true
          this.setTrendedSeries(newData.virtualStudentsByFRL, VirtualByFRLOptions)
        }
      }
    }
  },
  methods: {
    dataItems() {
      if (this.mode == 'current') {
        var rows = {}
        this.virtualStudentsByMealStatus.forEach(row => {
          rows[row.lea] = { ...rows[row.lea] }
          if (!rows[row.lea][row.freeReducedLunch ? 1 : 0]) rows[row.lea][row.freeReducedLunch ? 1 : 0] = 0
          rows[row.lea][row.freeReducedLunch ? 1 : 0] += row.studentCount
        })
        return Object.keys(rows)
          .sort()
          .map(k => {
            return {
              LEA: this.leaFormatter(k),
              ['Free/ReducedLunch']: rows[k][1] || 0,
              FullPricePaid: rows[k][0] || 0,
            }
          })
      } else return this.pivotTrended(this.metricSnapshots.virtualStudentsByFRL, VirtualByFRLOptions)
    },
    // getOption(number) {
    //   return InstructionalOptions.find(o => o.value === number)
    // }
  }
}
</script>
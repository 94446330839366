<template>
  <div class="card bg-light mt-3">
    <div class="card-header">
      Schedule for {{ selectedTeacher != null ? selectedTeacher.teacherName : selectedStudent != null ? selectedStudent.fullName : 'all teachers' }}
    </div>
    <div class="card-body">
      <div class="card" style="overflow-y: hidden; min-height: 25em; height: auto;">
        <div class="card-body inner-card">
          <Promised :promise="loadData">
            <template v-slot:pending>
              <div class="align-items-center justify-content-center" style="height: 100%; width: 100%">
                <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
              </div>
            </template>
            <template :class="centerContent ? 'align-items-center justify-content-center' : ''">
              <table-control-widget v-if="!selectedCourse" :per-page="tableControl.perPage" @filter-changed="tableControl.filter=$event" @page-length-changed="tableControl.perPage=$event" />
              <b-table ref="courseTable" hover selectable responsive small select-mode="single" :items="filteredCourses" :per-page="tableControl.perPage"
                       :filter="tableControl.filter" :tbody-tr-class="rowClass" :current-page="tableControl.currentPage" :fields="tableFields" @filtered="onFiltered" @row-selected="onCourseSelected">
                <template v-slot:cell(gradeRange)="data">
                  {{ data.item.grade_low.trim() }}-{{ data.item.grade_high.trim() }}
                </template>
                <template v-slot:cell(courseIdentifier)="data">
                  {{ data.item.section_num }}-{{ data.item.markingPeriod }}
                </template>
              </b-table>            
              <table-pagination-widget v-if="!selectedCourse" :total-rows-count="displayCourses.length" :filtered-rows-count="filteredCourses.length" :page-length="tableControl.perPage" @page-changed="tableControl.currentPage=$event" />
            </template>
            <template v-slot:rejected="error" class="align-items-center justify-content-center">
              <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
            </template>
          </Promised>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TableMixin } from '../TableMixin'
import { mapGetters } from 'vuex'
import { Promised } from 'vue-promised'
import CardStateIcon from '../CardStateIcon.vue'
export default {
    name: 'master-schedule-table',
    mixins: [TableMixin],
    props: {
        loadData: Promise
    },
    data() {
        return {
            tableControl: {
                filter: {},
                perPage: 10,
                currentPage: 1
            },
            totalRowCount: 0
        }
    },
    components: {
        Promised,
        CardStateIcon
    },
    methods: {
        onFiltered() {
            this.tableControl.currentPage = 1
        },
        onCourseSelected(item) {
            if (item.length > 0) {
                this.$store.commit('scheduleModule/setSelectedCourse', item[0])
                this.$store.commit('scheduleModule/setSelectedTeacher', item[0])
                this.tableControl.filter = `${item[0].section_num}:${item[0].markingPeriod}`
            }
        },
        rows() { 
            return this.masterSchedule.length 
        },
        rowClass(item){
            if (!item) return
            if(item.markingPeriodStatus == 'I') return 'text-secondary'
        },
        excelExportData() {
            return {
                name: 'Classes',
                items: (this.selectedCourse ? [this.selectedCourse] : this.filteredCourses).map(item => {
                    return {
                        "Teacher Name": item.teacherName,
                        "Course Code": item.courseIdentifier,
                        "Section": item.section_num,
                        "Course Desc.": item.courseTitle,
                        "Grade Range": (item.grade_low || '').trim() + '-' + (item.grade_high || '').trim(),
                        "Days": item.meetsOnDays,
                        "Enrollment": item.tot_count,
                        "Marking Period": item.markingPeriod
                    }
                })
            }
        },
    },
    computed: {
        selectedLea() { return this.$store.state.globalModule.leaContext.SelectedLea },
        ...mapGetters('scheduleModule',['displayCourses','qsTeacherId','qsCourseSection','qsMarkingPeriod','selectedCourse','selectedStudent']),
        selectedTeacher() { return this.$store.state.scheduleModule.selectedTeacher },
        filteredCourses(){
            var self = this
            if(this.displayCourses && this.tableControl.filter){
            var filtered = this.displayCourses.filter(item => {
                return Object.keys(this.tableControl.filter).every(key => {                  
                    return String(item[key]).includes(self.tableControl.filter[key])
                })
            })
            return filtered.length > 0
                ? filtered
                : this.displayCourses
            }
            return this.displayCourses
        },
        tableFields() {
            var fields = [
                { key: 'teacherName', sortable: true, sortDirection: 'asc', tdClass: 'text-nowrap' },
                { key: 'job_code', sortable: true, label: 'Course Code' },
                { key: 'section_num', sortable: true, label: "Section" },
                { key: 'courseTitle', label: 'Course Desc.', sortable: true, tdClass: 'text-nowrap' },
                { key: 'gradeRange', label: 'Grade Range' },
                { key: 'meetsOnDays', label: 'Days' },
                { key: 'tot_count', label: 'Enrollment' },
                { key: 'markingPeriod', label: 'Marking Period' },
                { key: 'courseIdentifier', thClass:'d-none', tdClass:'d-none'},
                { key: 'license_exception', label: 'License Exception', sortable: true }
            ]
            if (this.selectedTeacher != null) fields.shift()

            return fields
        }
    },
    watch:{
        selectedCourse: {
            handler(course){
                if(!course){
                    this.$refs.courseTable.clearSelected()
                    this.$store.commit('scheduleModule/setQsMarkingPeriod', null)
                    this.$store.commit('scheduleModule/setQsCourseSection', null)
                    this.tableControl.filter = ''
                }
            }
        },
        displayCourses:{
            handler(){
                if(this.filteredCourses && !this.selectedCourse){
                    if(this.qsCourseSection && this.qsMarkingPeriod){
                        var courseToSelect = this.displayCourses.find(t => t.section_num.trim() == this.qsCourseSection && t.markingPeriod == this.qsMarkingPeriod)
                        this.onCourseSelected([courseToSelect])
                    }
                    else if(this.qsCourseSection){
                        this.tableControl.filter.section_num = this.qsCourseSection
                    }
                    else if(this.qsMarkingPeriod && this.qsTeacherId){
                        this.tableControl.filter.uniq_emp_id = this.qsTeacherId
                        this.tableControl.filter.markingPeriod = this.qsMarkingPeriod
                    }
                }
            }
        }
    }
}
</script>
import { LeaTypes } from '@/helpers/leaTypes'
import sumBy from 'lodash.sumby'
import groupBy from 'lodash.groupby'
import { AllDemographics } from './instructionalOptionDemographics'
import Highcharts from 'highcharts'

function computeLEADemographics(lea, instructionalOptions, label)
{
  var options = instructionalOptions.filter(o => o.coOpLEA == lea || o.districtLEA == lea || o.lea == lea || lea == 'AR')

  var sum = {
    fiscalYear: (options[0] || {}).fiscalYear
  }
  AllDemographics.map(demo => {
    sum[`${label} ${demo.name}`] = sumBy(options, demo.field)
  })
  sum[`${label} Total`] = sumBy(options, 'total')
  sum.label = label
  var output = [sum].map(calculateDemographicPercentages)[0]

  delete output.fiscalYear
  delete output.label

  return output
}

function calculateDemographicPercentages(row) {
  let calced = { ...row }
  let label = row.label
  AllDemographics.map(demo => {
    if (row[`${label} Total`] === 0) {
      calced[`${label} ${demo.name} Percent`] = null
    }
    else {
      calced[`${label} ${demo.name} Percent`] = Highcharts.numberFormat((row[`${label} ${demo.name}`] / row[`${label} Total`]) * 100, 2) + '%'
    }
  })
  return calced
}

export function getInstructionOptionBySubpopsSheets() {
  var items = []
  var totals = {}
  var rows = {}
  var demographicData = this.instructionalOptionsDemographics

  this.instructionalOptions.forEach(row => {
    rows[row.lea] = { ...rows[row.lea] }   
  })

  switch (this.selectedLea.type)
  {
    case LeaTypes.State: 
      items = Object.keys(rows).sort().map(k => {
        var district = this.leaDictionary[k]?.parentLea
        var coop = district ? this.leaDictionary[district.number]?.parentLea : {}
        return {
          'CoOp LEA': coop?.number || '',
          'CoOp Name': coop?.name || 'Unknown CoOp',
          'District LEA': district?.number || '',
          'District Name': district?.name || 'Unknown District',
          'School LEA': k,
          'School Name': this.leaName(k),
          ...computeLEADemographics(k, demographicData.onsite, 'On-Site'),
          ...computeLEADemographics(k, demographicData.virtual, 'Virtual'),
          ...computeLEADemographics(k, demographicData.hybrid, 'Hybrid')
        }
      })
      totals = {
        'CoOp LEA': '',
        'CoOp Name': '',
        'District LEA': '',
        'District Name': '',
        'School LEA': '',
        'School Name': 'State of Arkansas TOTAL',
        ...computeLEADemographics('AR', demographicData.onsite, 'On-Site'),
        ...computeLEADemographics('AR', demographicData.virtual, 'Virtual'),
        ...computeLEADemographics('AR', demographicData.hybrid, 'Hybrid')
      }
      var districts = groupBy(items, (i) => { return i['District LEA'] })
      var itemsDistrict = Object.keys(districts).sort((a,b) => a-b).map(districtLEA => {
        var district = this.leaDictionary[districtLEA]
        var coop = district ? district.parentLea : {}
        return {
          'CoOp LEA': coop?.number || '',
          'CoOp Name': coop?.name || 'Unknown CoOp',
          'District LEA': district?.number || '',
          'District Name': district?.name || 'Unknown District',
          ...computeLEADemographics(district?.number, demographicData.onsite, 'On-Site'),
          ...computeLEADemographics(district?.number, demographicData.virtual, 'Virtual'),
          ...computeLEADemographics(district?.number, demographicData.hybrid, 'Hybrid')
        }
      })
      var totalsDistrict = {
        'CoOp LEA': '',
        'CoOp Name': '',
        'District LEA': '',
        'District Name': 'State of Arkansas TOTAL',
        ...computeLEADemographics('AR', demographicData.onsite, 'On-Site'),
        ...computeLEADemographics('AR', demographicData.virtual, 'Virtual'),
        ...computeLEADemographics('AR', demographicData.hybrid, 'Hybrid')
      }
      var coops = groupBy(items, (i) => { return i['CoOp LEA'] })
      var itemsCoop = Object.keys(coops).sort((a,b) => a-b).map(coopLEA => {
        //var coopRows = coops[coopLEA]
        var coop = this.leaDictionary[coopLEA]
        return {
          'CoOp LEA': coop?.number || '',
          'CoOp Name': coop?.name || 'Unknown CoOp',
          ...computeLEADemographics(coop?.number, demographicData.onsite, 'On-Site'),
          ...computeLEADemographics(coop?.number, demographicData.virtual, 'Virtual'),
          ...computeLEADemographics(coop?.number, demographicData.hybrid, 'Hybrid')
        }
      })
      var totalsCoop = {
        'CoOp LEA': '',
        'CoOp Name': 'State of Arkansas TOTAL',
        ...computeLEADemographics('AR', demographicData.onsite, 'On-Site'),
        ...computeLEADemographics('AR', demographicData.virtual, 'Virtual'),
        ...computeLEADemographics('AR', demographicData.hybrid, 'Hybrid')
      }
      return [
        { name: 'Instruction Option Subpops School', items, totals },
        { name: 'Instruction Option Subpops District', items: itemsDistrict, totals: totalsDistrict },
        { name: 'Instruction Option Subpops CoOp', items: itemsCoop, totals: totalsCoop }
      ]
    case LeaTypes.Coop:
      items = Object.keys(rows).sort().map(k => {
        var district = this.leaDictionary[k]?.parentLea
        return {
          'District LEA': district?.number || '',
          'District Name': district?.name || '',
          'School LEA': k,
          'School Name': this.leaName(k),
          ...computeLEADemographics(k, demographicData.onsite, 'On-Site'),
          ...computeLEADemographics(k, demographicData.virtual, 'Virtual'),
          ...computeLEADemographics(k, demographicData.hybrid, 'Hybrid')
        }
      })
      totals = {
        'District LEA': '',
        'District Name': '',
        'School LEA': '',
        'School Name': `${this.selectedLea.name} TOTAL`,
        ...computeLEADemographics('AR', demographicData.onsite, 'On-Site'),
        ...computeLEADemographics('AR', demographicData.virtual, 'Virtual'),
        ...computeLEADemographics('AR', demographicData.hybrid, 'Hybrid')

      }
      districts = groupBy(items, (i) => { return i['District LEA'] })
      itemsDistrict = Object.keys(districts).sort((a,b) => a-b).map(districtLEA => {
        var district = this.leaDictionary[districtLEA]
        var coop = district ? district.parentLea : {}
        return {
          'CoOp LEA': coop?.number || '',
          'CoOp Name': coop?.name || 'Unknown CoOp',
          'District LEA': district?.number || '',
          'District Name': district?.name || 'Unknown District',
          ...computeLEADemographics(district?.number, demographicData.onsite, 'On-Site'),
          ...computeLEADemographics(district?.number, demographicData.virtual, 'Virtual'),
          ...computeLEADemographics(district?.number, demographicData.hybrid, 'Hybrid')
        }
      })
      totalsDistrict = {
        'CoOp LEA': '',
        'CoOp Name': '',
        'District LEA': '',
        'District Name': `${this.selectedLea.name} TOTAL`,
        ...computeLEADemographics('AR', demographicData.onsite, 'On-Site'),
        ...computeLEADemographics('AR', demographicData.virtual, 'Virtual'),
        ...computeLEADemographics('AR', demographicData.hybrid, 'Hybrid')
      }
      return [
        { name: 'Instruction Option Subpops School', items, totals },
        { name: 'Instruction Option Subpops District', items: itemsDistrict, totals: totalsDistrict },
      ]
    case LeaTypes.District:
      items = Object.keys(rows).sort().map(k => {
        return {
          'School LEA': k,
          'School Name': this.leaName(k),
          ...computeLEADemographics(k, demographicData.onsite, 'On-Site'),
          ...computeLEADemographics(k, demographicData.virtual, 'Virtual'),
          ...computeLEADemographics(k, demographicData.hybrid, 'Hybrid')
        }
      })
      totals = {
        'School LEA': '',
        'School Name': `${this.selectedLea.name} TOTAL`,
        ...computeLEADemographics('AR', demographicData.onsite, 'On-Site'),
        ...computeLEADemographics('AR', demographicData.virtual, 'Virtual'),
        ...computeLEADemographics('AR', demographicData.hybrid, 'Hybrid')
      }
      return [{ name: 'Instruction Option Subpops School', items, totals }]
    case LeaTypes.School:
      items = Object.keys(rows).sort().map(k => {
        return {
          'School LEA': k,
          'School Name': this.leaName(k),
          ...computeLEADemographics(k, demographicData.onsite, 'On-Site'),
          ...computeLEADemographics(k, demographicData.virtual, 'Virtual'),
          ...computeLEADemographics(k, demographicData.hybrid, 'Hybrid')
        }
      })
      return [{ name: 'Instruction Option Subpops School', items }]      
  }
  return []
}
<template>
  <step-container :academic-year="Number($route.params.ay)" task-code="PIM" title="Perkins V Indicator Management" :hide-step-status="hideStepStatus">
    <router-view />
  </step-container>
</template>

<script>
import StepContainer from '../StepContainer.vue'

export default {
  components:{
    StepContainer
  },
  beforeRouteEnter(to, from, next) {
    console.log(to, from)
    if (to.name === 'Admin/Indicators') next({ name: 'Admin/Indicators/List' })
    else 
    next()
  },
  computed: {
    hideStepStatus() {
      return this.$route.name != "Admin/Indicators/List"
    }
  } 
}
</script>
<template>
  <loadable-chart-card :load-data="loadData" card-class="bg-white p-1 inner-card justify-content-center pt-5" :enable-data-toggle="false">
    <template v-slot:default="slotProps">
      <div class="chartElem mt-3 mb-3" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
  import { LeaTypes } from '@/helpers/leaTypes'
  import { ChartMixin } from '@/components/ChartMixin'
  import { credits } from '@/helpers/credits'
  import Highcharts from 'highcharts'
  import solidGaugeInit from 'highcharts/modules/solid-gauge'
  import { DataLevels } from '@/modules/esa/shared/enums'
  import $ from 'jquery'

  solidGaugeInit(Highcharts)

  function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0

    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians)),
    }
  }
  function gaugeHash (chart, locationValue, suffix = '') {
    
    // remove old drawing elements
    $(chart.container.parentElement).find('.hash').remove();
    var ren = chart.renderer

    var centerOffsetPctX = parseFloat(chart.pane[0].options.center[0]) / 100
    var centerOffsetPctY = parseFloat(chart.pane[0].options.center[1]) / 100
    var size = parseFloat(chart.pane[0].options.size) / 100

    var centerX = chart.plotLeft + (centerOffsetPctX * chart.plotSizeX)
    var centerY = chart.plotTop + (centerOffsetPctY * chart.plotSizeY)
    var radius = (size * chart.plotSizeY) / 2

    var p1Radius = parseFloat(chart.pane[0].options.background.innerRadius) / 100 * radius;
    var p2Radius = parseFloat(chart.pane[0].options.background.outerRadius) / 100 * radius;

    var ratio = parseFloat(locationValue) / 100
    var angle = (ratio * 180) - 90;
    
    /* x offset from -40 to +5 depending on where on the arc we fall */
    var offset = -25 + (ratio * 50)

    var xOffset = offset
    var yOffset = -18

    var p1 = polarToCartesian(centerX, centerY, p1Radius, angle);
    var p2 = polarToCartesian(centerX, centerY, p2Radius, angle);

    //debugger;
    ren.path(['M', p1.x, p1.y, 'L', p2.x, p2.y])
      .addClass('hash')
      .attr({
        'stroke-width': 2,
        stroke: DataLevels.State.color,
        dashstyle: 'shortdash',
        zIndex: 100
      })
      .add();

    var text = "State Avg.<br/>".concat(locationValue).concat(suffix);

    ren.text(text, p2.x + xOffset, p2.y + yOffset)
      .addClass('hash')
      .css({
        color: DataLevels.State.color,
        fontSize: '1.5em',
        textAnchor: 'middle'
      })
      .add();
  }

  function createChartEvent(vm) {
    return {
      render() {
        if (vm.stateAvg) {
          gaugeHash(this, vm.stateAvg, vm.metricSuffix)
        }
      }
    }
  }
  export default {
    mixins: [ChartMixin],
    data() {
      return {
        animationDuration: 1000,
        chartOptions: {
          chart: {
            type: 'solidgauge',
            margin: 0,
            spacing: [0, 0, 0, 0],
            events: createChartEvent(this),
            backgroundColor: '#FFF'
          },
          plotOptions: {
            solidgauge: {
              dataLabels: {
                y: -30,
                borderWidth: 0,
                useHTML: true
              },
            },
          },
          exporting: {
            enabled: false
          },
          pane: {
            center: ['50%', '60%'],
            // size: '170%',
            startAngle: -90,
            endAngle: 90,
            background: {
              backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
              innerRadius: '60%',
              outerRadius: '100%',
              shape: 'arc'
            }
          },
          tooltip:{
            enabled: false,
          },
          series: [
            { 
              name: this.title,
            },
          ],
          credits: this.credits,
          title: {
            text: `${this.title}${this.subgroup ? ' - ' + this.subgroup : '' }`
          },
          xAxis: {
            type: 'category',
            title: {
              text: 'School Year'
            }
          },
          yAxis: {
            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            tickAmount: 2,
            labels: {
              enabled: false
            },
            min: 0,
            max: 100,
            stops: [
              [0, DataLevels.District.color], 
            ],
          },
        },
        stateAvg: null,
      }
    },
    props: {
      title: String,
      subgroup: String,
      field: {
        default: 'value',
        type: String
      },
      reportData: Array,
      metricSuffix:{
        type: String,
        default: ''
      },
      credits: {
        type: Object,
        default: () => credits.MSISRC,
      }
    },
    watch: {
      reportData: {
        immediate: true,
        handler(data) {
          const self = this
          const stateAvgMetrics = data.filter(d => d.dataLevel == LeaTypes.State && (self.subgroup ? self.subgroup == d.demographic : true))
          if (stateAvgMetrics.length > 0) {
            this.stateAvg = Highcharts.numberFormat(stateAvgMetrics[0][self.field])
          }
          if (data.length > 0) {
            this.chartOptions.title.text = `${(data[0].fy % 1990) + 1990} ${this.title}${this.subgroup ? ' - ' + this.subgroup : '' }`
          }
          this.chartOptions.series[0].data = data
            .filter(d => d.dataLevel == LeaTypes.District && (self.subgroup ? self.subgroup == d.demographic : true))
            .filter(d => !d.grade || (d.grade && d.grade == 'All'))
            .map(row => {
              return {
                x: row.fiscalYear ? (row.fiscalYear % 1990) + 1990 : 0,
                y: row[self.field],
                ...row
              }
            })
            .slice(0,1)
          this.chartOptions.series[0].dataLabels = {
            format: `<div style="text-align:center"><span style="font-size:20px">{y:.2f}${this.metricSuffix}</span><br/></div>`
          }
        }
      }
    },
    methods: {
      dataItems() {
        return []
      }
    }
  }
</script>
import Vue from 'vue'

const AreaGroupSelector = Vue.extend({
    name: 'area-group-selector',
    template:
        /* html */
        `<select class="form-control shortage-report-select" v-model='selectedGroupId'>
    <option :value='null'>(Select Area Group)</option>
    <option  v-for='group in groups' v-bind:value='group.id' v-html="group.label" :class='group.class'></option>
</select>
    `,
    data() {
        return {
            selectedGroupId: null
        }
    },
    methods: {
        pathInfo(group) {
            const parent = group.parentGroupId > 0 ? this.$store.state.shortageReportAdminModule.reportAreaGroups.find(p => p.id == group.parentGroupId) : null;

            return {
                path: parent ? parent.groupName + " \\ " + group.groupName : group.groupName,
                label: parent ? "&#x2514; " + group.groupName : group.groupName
            }
        }
    },
    computed: {
        newId() {
            return this.$store.state.shortageReportAdminModule.newAreaGroupId;
        },
        groups() {

            let linked = this.$store.getters['shortageReportAdminModule/reportAreaGroups'].map(item => {
                const pathInfo = this.pathInfo(item)
                return {
                    id: item.id,
                    groupName: item.groupName,
                    licenseCodes: item.licenseCodes,
                    parentGroupId: item.parentGroupId,
                    path: pathInfo.path,
                    label: pathInfo.label,
                    class: item.children.length ? "parent" : item.parentGroupId ? "child" : ""
                }
            });

            linked.sort((a, b) => {
                return (a.path).localeCompare(b.path)
            });

            return linked;
        }
    },
    watch: {
        selectedGroupId(id) {
            const selectedGroup = this.$store.state.shortageReportAdminModule.reportAreaGroups.find(g=>g.id == id);
            this.$emit('on-item-selected', selectedGroup)
        },
      
        newId(id) {
            this.selectedGroupId = id;
        }
    }
})

export {
    AreaGroupSelector
}
<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { EssaScores } from './EssaScores'
import { credits } from '../../../helpers/credits'

export default {
  name: 'essa-scores-chart',
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'line'
        },
        credits: credits.MSI,
        title: {
          text: 'ESSA School Index'
        },
        legend: {
          enabled: true
        },
        series: EssaScores,
        xAxis: {
          title: {
            text: 'School Year'
          },
          type: 'category'
        },
        yAxis: {
          min: 0,
          max: 105,
          title: {
            text: 'Score'
          },
          endOnTick: false
        },
        tooltip: {
          valueDecimals: 2
        }
      }
    }
  },
  mixins: [ChartMixin],
  computed: {
    essaindex() {
      return this.$store.state.overviewModule.essaindex
    }
  },
  watch: {
    essaindex(newData) {
      for (var i = 0; i < EssaScores.length; i++) {
        this.chartOptions.series[i].data = newData.map(row => {
          return {
            x: (row.fiscalYear % 1990) + 1990,
            y: row[EssaScores[i].field],
            ...row
          }
        })
      }
    }
  },
  methods: {
    excelExportData() {
      return this.defaultExcelExportData()
    },
    dataItems() {
      return EssaScores.flatMap((essa, index) =>
        this.chartOptions.series[index].data.map(item => {
          return { EssaScoreType: essa.name, SchoolYear: item.x, Score: item.y }
        })
      )
    }
  }
}
</script>

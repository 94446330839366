import ApiService from '../../services/ApiService'
import { OfflineService } from '../../services/offlineService'
import { getDPSAFTfilterQueryString } from '../../helpers/globalHelpers'
import { RatingTypes, InspectionStatuses, InspectionBatchStatus, MeasurementTypes } from '../../components/dpsaft/common/Enums'
import { setSelectedInspection, setSelectedInspectionComponents, updateBatchHierarchy } from './common'
import axios from 'axios'
import Vue from 'vue'

export function isBusGrounded(inspection) {
  const config = document.getElementById('application-config')
  const brakePadThreshold = parseInt(config.getAttribute('data-dpsaft-brakepadthreshold'))
  const frontTiretreadthreshold = parseInt(config.getAttribute('data-dpsaft-fronttiretreadthreshold'))
  const rearTiretreadthreshold = parseInt(config.getAttribute('data-dpsaft-reartiretreadthreshold'))

  if (!inspection) inspection = {}
  if (!inspection.measurements) inspection.measurements = []
  if (!inspection.components) inspection.components = []

  var measurementSubset = inspection.measurements.filter(f=>f.left && f.right) //we require -something- entered for both values in order to evaluate grounding
  
  return inspection.status > InspectionStatuses.NotStarted &&
    inspection.status < InspectionStatuses.Skipped &&
    ( measurementSubset.filter(m => m.type == MeasurementTypes.FrontBrakes.value || m.type == MeasurementTypes.RearBrakes.value).some(m => m.left <= brakePadThreshold || m.right <= brakePadThreshold)
    || measurementSubset.filter(m => m.type == MeasurementTypes.RearInsideTires.value || m.type == MeasurementTypes.RearOutsideTires.value).some(m => m.left <= rearTiretreadthreshold || m.right <= rearTiretreadthreshold)
    || measurementSubset.filter(m => m.type == m.type == MeasurementTypes.FrontTires.value).some(m => m.left <= frontTiretreadthreshold || m.right <= frontTiretreadthreshold)
    || inspection.components.some(c => c.rating == RatingTypes.Ground))
}
const _offlineService = new OfflineService()
import removeNamespace from '../removeNamespace'

export const Types = {
  
  state: {
    InspectionItems: 'InspectionItems',
    BatchMaintenance: 'BatchMaintenance',
    inspectionBatches: 'inspectionBatches',
    SelectedBatch: 'SelectedBatch',
    SelectedInspection: 'SelectedInspection',
    SelectedBatchSummary: 'SelectedBatchSummary',
    InspectionHistory: 'InspectionHistory',
    SelectedBatchNotifications: 'SelectedBatchNotifications',
    BatchNotification: 'BatchNotification',
    DistrictNotifications: 'DistrictNotifications'
  },
  actions: {
    startInspection: 'DPSAFT/Trans/startInspection',
    saveInspection: 'DPSAFT/Trans/saveInspection',
    deleteInspection: 'DPSAFT/Trans/deleteInspection',
    saveBatchComment: 'DPSAFT/Trans/saveBatchComment',
    setInspectionSkipStatus: 'DPSAFT/Trans/setInspectionSkipStatus',
    updateBatchStatus: 'DPSAFT/Trans/updateBatchStatus',
    setInspectionStatus: 'DPSAFT/Trans/setInspectionStatus',
    saveInspectionMeasurement: 'DPSAFT/Trans/saveInspectionMeasurement',
    saveInspectionMeasurements: 'DPSAFT/Trans/saveInspectionMeasurements',
    saveInspectionComponent: 'DPSAFT/Trans/saveInspectionComponent',
    getInspectionItems: 'DPSAFT/Trans/getInspectionItems',
    initializeBatch: 'DPSAFT/Trans/initializeBatch',
    getBatch: 'DPSAFT/Trans/getBatch',
    getBatchSummary: 'DPSAFT/Trans/getBatchSummary',
    getInspection: 'DPSAFT/Trans/getInspection',
    getInspectionBatches: 'DPSAFT/Trans/getInspectionBatches',
    getUserBatches: 'DPSAFT/Trans/getUserBatches',
    getInspectionHistory: 'DPSAFT/Trans/getInspectionHistory',
    exportBatch: 'DPSAFT/Trans/exportBatch',
    districtAcknowledge: 'DPSAFT/Trans/districtAcknowledge',
    getBatchNotifications: 'DPSAFT/Trans/getBatchNotifications',
    sendBatchNotifications: 'DPSAFT/Trans/sendBatchNotifications',
    getBatchNotification: 'DPSAFT/Trans/getBatchNotification',
    deleteBatch: 'DPSAFT/Trans/deleteBatch',
    getDistrictNotifications: 'DPSAFT/Trans/getDistrictNotifications'
  },
  getters: {
    InspectionItems: 'InspectionItems',
    SelectedBatchBusesNoIssue: 'SelectedBatchBusesNoIssue',
    SelectedBatchBusesIssuesInService: 'SelectedBatchBusesIssuesInService',
    SelectedBatchBusesIssuesGrounded: 'SelectedBatchBusesIssuesGrounded'
  },
  mutations: {
    setInspectionInProgress: 'DPSAFT/Trans/setInspectionInProgress',
    setInspectionItems: 'DPSAFT/Trans/setInspectionItems',
    setBuses: 'DPSAFT/Trans/setBuses',
    setInspectionBatches: 'DPSAFT/Trans/setInspectionBatches',
    setSelectedBatch: 'DPSAFT/Trans/setSelectedBatch',
    setSelectedBatchSummary: 'DPSAFT/Trans/setSelectedBatchSummary',
    setSelectedInspection: 'DPSAFT/Trans/setSelectedInspection',
    setSelectedBatchStatus: 'DPSAFT/Trans/setSelectedBatchStatus',
    setUpdatedInspectionComponent: 'DPSAFT/Trans/setUpdatedInspectionComponent',
    setUpdatedInspectionMeasurements: 'DPSAFT/Trans/setUpdatedInspectionMeasurements',
    setUpdatedInspection: 'DPSAFT/Trans/setUpdatedInspection',
    setInspectionSkipped: 'DPSAFT/Trans/setInspectionSkipped',
    setInspectionHistory: 'DPSAFT/Trans/setInspectionHistory',
    setDistrictNotified: 'DPSAFT/Trans/setDistrictNotified',
    setDistrictAcknowledged: 'DPSAFT/Trans/setDistrictAcknowledged',
    setSelectedBatchNotifications: 'DPSAFT/Trans/setSelectedBatchNotifications',
    setBatchNotification: 'DPSAFT/Trans/setBatchNotification',
    removeDeletedInspection: 'DPSAFT/Trans/removeDeletedInspection',
    removeDeletedBatch: 'DPSAFT/Trans/removeDeletedBatch',
    setDistrictNotifications: 'DPSAFT/Trans/setDistrictNotifications'
  },
  path: 'DPSAFT/Trans'
}
const _types = removeNamespace('DPSAFT/Trans/', Types)
export class Trans {

  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
    this._fiscalYear = parseInt(this._configElement.getAttribute('data-fiscal-year'))
  }
  namespaced = true

  path = 'DPSAFT/Trans'

  state = {
    [_types.state.InspectionItems]: [],
    [_types.state.BatchMaintenance]: {
      SelectedBuses: [],
    },
    [_types.state.inspectionBatches]: [],
    [_types.state.SelectedBatch]: {
      inspections: []
    },
    [_types.state.SelectedInspection]: {},
    [_types.state.SelectedBatchSummary]: {
      totalPoints: {}
    },
    [_types.state.InspectionHistory]: { item1: {}, item2: [] },
    [_types.state.SelectedBatchNotifications]: {},
    [_types.state.BatchNotification]: {},
    [_types.state.DistrictNotifications]: [],
    fiscalYears: []
  }

  mutations = {
    [_types.mutations.setInspectionItems]: (state, data) => state.InspectionItems = data,
    [_types.mutations.setBuses]: (state, data) => state.BatchMaintenance.SelectedBuses = data,
    [_types.mutations.setInspectionBatches]: (state, data) => state.inspectionBatches = data,
    [_types.mutations.setSelectedBatch]: (state, data) => {
      let selectedBatch = {...data}
      selectedBatch.inspections = (selectedBatch.inspections || [])//.sort(inspectionSort)
      state.SelectedBatch = selectedBatch
    },
    [_types.mutations.setSelectedBatchSummary]: (state, data) => state.SelectedBatchSummary = data,
    [_types.mutations.setSelectedInspection]: (state, data) => state.SelectedInspection = data,
    [_types.mutations.setSelectedBatchStatus]: (state, data) => state.SelectedBatch = {...state.SelectedBatch, ...data},
    [_types.mutations.setUpdatedInspectionComponent]: (state, data) => {
      if ((state.SelectedInspection || {}).inspectionId == data.transportationInpectionId) {
        setSelectedInspectionComponents(state, data)
      }
    },
    [_types.mutations.setUpdatedInspectionMeasurements]: (state, data) => {
      if ((state.SelectedInspection || {}).inspectionId == data.inspectionId) {
        Vue.set(state.SelectedInspection, 'measurements', [...(state.SelectedInspection.measurements || []).filter(c => !data.measurements.map(m => m.measurementId).includes(c.measurementId)), ...data.measurements].sort((a, b) => Number(a.type) - Number(b.type)))
        state.SelectedInspection = {...state.SelectedInspection}
        updateBatchHierarchy(state)
      }
    },
    [_types.mutations.setUpdatedInspection]: (state, data) => {
      setSelectedInspection(state, data)
      state.SelectedBatch.duplicateBusNumbers = state.SelectedBatch.inspections.map(i => i.busNumber).filter(b => b).filter((e,i,a) => a.indexOf(e) !== i)
    },
    [_types.mutations.setInspectionSkipped]: (state, data) => {
      const inspection = state.SelectedBatch.inspections.find((i) => i.inspectionId == data.inspectionId);
      if (inspection) inspection.status = data.skipValue == 'Skipped' ? InspectionStatuses.Skipped : InspectionStatuses.InProgress
    },
    [_types.mutations.setInspectionHistory]: (state, data) => {
      state.InspectionHistory = data
    },
    [_types.mutations.setDistrictNotified]: (state, data) => {
      state.SelectedBatch.districtNotifiedDate = data.districtNotifiedDate
      state.SelectedBatch.districtNotifiedBy = data.districtNotifiedBy
    },
    [_types.mutations.setDistrictAcknowledged]: (state, data) => {
      state.SelectedBatch.districtAcknowledgedDate = data.districtAcknowledgedDate
      state.SelectedBatch.districtAcknowledgedBy = data.districtAcknowledgedBy
    },
    [_types.mutations.setSelectedBatchNotifications]: (state, data) => {
      state.SelectedBatchNotifications = data
    },
    [_types.mutations.setBatchNotification]: (state, data) => {
      Vue.set(state, Types.state.BatchNotification, data)
    },
    [_types.mutations.removeDeletedInspection]: (state, data) => {
      state.SelectedBatch.inspections = state.SelectedBatch.inspections.filter(i => i.inspectionId != data)
    },
    [_types.mutations.removeDeletedBatch]: (state, data) => {
      state.inspectionBatches = state.inspectionBatches.filter(i => i.batchId != data)
    },
    [_types.mutations.setInspectionInProgress]: (state, data) => {
      setSelectedInspection(state, { inspectionId: data, status: InspectionStatuses.InProgress })
    },
    [_types.mutations.setDistrictNotifications]: (state, data) => {
      state.DistrictNotifications = data
    },
    setFiscalYears: (state, data) => state.fiscalYears = data
  }

  getters = {
    [_types.getters.InspectionItems]: (state) => state.InspectionItems,
    [_types.getters.SelectedBatchBusesNoIssue]: (state) => (state.SelectedBatch.inspections || []).filter(i => (!isBusGrounded(i)) && i.components.every(c => [null, RatingTypes.NotChecked, RatingTypes.NotApplicable,RatingTypes.OK].includes(c.rating))),
    [_types.getters.SelectedBatchBusesIssuesInService]: (state) => (state.SelectedBatch.inspections || []).filter(i => i.components.some(c => c.rating == RatingTypes.Fix) && !isBusGrounded(i)),
    [_types.getters.SelectedBatchBusesIssuesGrounded]: (state) => (state.SelectedBatch.inspections || []).filter(isBusGrounded)
  }

  actions = {
    //#region Requests
    [_types.actions.getBatch]: ({ commit }, payload) => {
      commit('setSelectedInspection', {})
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/batch/${payload.batchId}`))
        .then(data => {
          commit('setSelectedBatch', data)
        })
    },
    [_types.actions.getBatchSummary]: ({ commit }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/batch/${payload.batchId}/summary`))
        .then(data => commit('setSelectedBatchSummary', data) )
    },
    [_types.actions.getInspection]: ({ commit }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/batch/${payload.batchId}/inspection/${payload.inspectionId}`))
        .then(data => commit('setSelectedInspection', data))
    },
    [_types.actions.getInspectionBatches]: ({ commit }, payload) => {
      var qs = getDPSAFTfilterQueryString(payload)

      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/batches?${qs}`))
        .then(data => commit('setInspectionBatches', data))
    },
    [_types.actions.getUserBatches]: ({ commit }) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/user-batches`))
      .then(data => commit('setInspectionBatches', data))
    },
    [_types.actions.getInspectionHistory]: ({ commit }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/history/${payload.busId}`))
        .then(data => commit('setInspectionHistory', data))
    },
    // eslint-disable-next-line no-unused-vars
    [_types.actions.exportBatch]: ({ commit }, payload) => {
      var url = encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/batch/${payload.batchId}/export`)

      return axios.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = payload.fileName
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },
    [_types.actions.getInspectionItems]: ({ commit }) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/metadata/transportation-inspection-items`))
        .then(data => commit('setInspectionItems', data))
    },
    [_types.actions.getBatchNotifications]: ({ commit }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/batch/${payload.batchId}/notifications`))
        .then(data => commit(_types.mutations.setSelectedBatchNotifications, data))
    },
    //#endregion

    //#region Commands
    [_types.actions.sendBatchNotifications]: ({ dispatch }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/batch/${payload.batchId}/notifications/${payload.type}`), payload)
      .then(() => dispatch(_types.actions.getBatchNotifications, { batchId: payload.batchId }))    
    },
    [_types.actions.saveInspection]: (context, payload) => _offlineService.CreateQueuedAction(Types.actions.saveInspection, context, payload, (context, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/batch/${payload.batchId}/inspection/${payload.inspectionId}`), payload.update)
        .then(data => context.commit(_types.mutations.setUpdatedInspection, data))
        .then(() => {
          if (!context.rootGetters['DPSAFT/isMobile']) {
            context.dispatch('getBatchSummary', { batchId: context.state.SelectedBatch.batchId })
          }
        })
    }, (context, payload) => {
      context.commit(_types.mutations.setUpdatedInspection, { ...payload.update })
    }),
    [_types.actions.deleteInspection]: (context, payload) => _offlineService.CreateQueuedAction(Types.actions.saveInspection, context, payload, (context, payload) => {
      context.commit('setSelectedInspection', {})
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/batch/${payload.batchId}/inspection/${payload.inspectionId}`))
        .then((batchStatus) => {
          context.commit(_types.mutations.removeDeletedInspection, payload.inspectionId)
          context.commit(_types.mutations.setSelectedBatchStatus, { status: batchStatus})
        })
    }),
    [_types.actions.deleteBatch]: (context, payload) => _offlineService.CreateQueuedAction(Types.actions.saveInspection, context, payload, (context, payload) => {
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/batch/${payload.batchId}`))
        .then(() => context.commit(_types.mutations.removeDeletedBatch, payload.batchId))
    }),
    [_types.actions.setInspectionSkipStatus]: (context, payload) => _offlineService.CreateQueuedAction(Types.actions.setInspectionSkipStatus, context, payload, (context, payload) => {
      return this._apiService.Patch(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/batch/${payload.batchId}/inspection/${payload.inspectionId}/status?status=${payload.skipValue}`))
        .then(() => context.commit(_types.mutations.setInspectionSkipped, { inspectionId: payload.inspectionId, skipValue: payload.skipValue }))
    }, (context, payload) => {
      context.commit(_types.mutations.setInspectionSkipped, { inspectionId: payload.inspectionId, skipValue: payload.skipValue })
    }),
    [_types.actions.setInspectionStatus]: (context, payload) => _offlineService.CreateQueuedAction(Types.actions.setInspectionStatus, context, payload, (context, payload) => {
      return this._apiService.Patch(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/batch/${payload.batchId}/inspection/${payload.inspectionId}/status?status=${payload.status}`))
        .then(data => {
          context.commit(_types.mutations.setUpdatedInspection, { ...data.item2 })
          context.commit(_types.mutations.setSelectedBatchStatus, { status: data.item1 })
        })
    }, (context, payload) => {
      context.commit(_types.mutations.setUpdatedInspection, { inspectionId: payload.inspectionId, status: payload.status })
      if (context.state.SelectedBatch.inspections.every(i => i.status == InspectionStatuses.Completed)) {
        context.commit(_types.mutations.setSelectedBatchStatus, InspectionBatchStatus.Completed)
      } 
      else if (context.state.SelectedBatch.status != InspectionBatchStatus.InProgress) {
        context.commit(_types.mutations.setSelectedBatchStatus, InspectionBatchStatus.InProgress)
      }
    }),
    [_types.actions.saveInspectionComponent]: (context, payload) => _offlineService.CreateQueuedAction(Types.actions.saveInspectionComponent, context, payload, (context, payload) => {
        return this._apiService.Put(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/batch/${payload.batchId}/inspection/${payload.inspectionId}/component/${payload.id}`), payload.update)
          .then(data => context.commit(_types.mutations.setUpdatedInspectionComponent, data))
          .then(() => {
            if (!context.rootGetters['DPSAFT/isMobile']) {
              context.dispatch('getBatchSummary', { batchId: context.state.SelectedBatch.batchId })
            }
          })
    }, (context, payload) => {
      context.commit(_types.mutations.setUpdatedInspectionComponent, { ...payload.update })
    }),
    [_types.actions.saveInspectionMeasurements]: (context, payload) => _offlineService.CreateQueuedAction(Types.actions.saveInspectionMeasurements, context, payload, (context, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/batch/${payload.batchId}/inspection/${payload.inspectionId}/measurements/`), payload.updates)
        .then(data => context.commit(_types.mutations.setUpdatedInspectionMeasurements, { inspectionId: payload.inspectionId, measurements: data}))
    }, (context, payload) => {
      let newMeasurements = [...context.state.SelectedInspection.measurements]
      payload.updates.forEach(update => {
        const index = newMeasurements.findIndex(m => m.measurementId == update.measurementId)
        newMeasurements[index] = Object.assign(newMeasurements[index], update)
      })
      context.commit(_types.mutations.setUpdatedInspectionMeasurements, { inspectionId: payload.inspectionId, measurements: newMeasurements })
    }),
    [_types.actions.updateBatchStatus]: ({ dispatch }, payload) => {
      return this._apiService.Patch(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/batch/${payload.batchId}/status/${payload.status}`))
        .then(() => dispatch('getBatch', { batchId: payload.batchId }))
    },
    [_types.actions.initializeBatch]: ({ state }, payload) =>{
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/${payload.districtLea || state.BatchMaintenance.SelectedBuses[0].districtLea}/initialize`), { BusVINs: state.BatchMaintenance.SelectedBuses.map(b => b.vinNumber), Description: payload.description })
    },    
    [_types.actions.saveBatchComment]: ({state}, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/batch/${payload.batchId}`), {Comments: payload.comments})
        .then(data => state.SelectedBatch.comments = data)
    },
    [_types.actions.getBatchNotification]: ({ commit }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/notification/${payload.notificationId}`))
        .then(data => commit('setBatchNotification', data))
    }
    , 
    [_types.actions.startInspection]: (context, payload) => _offlineService.CreateQueuedAction(Types.actions.startInspection, context, payload, (context, payload) => {
      return this._apiService.Patch(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/batch/${payload.batchId}/inspection/${payload.inspectionId}/start`))
        .then(data => {
          context.commit(_types.mutations.setInspectionInProgress, payload.inspectionId )
          context.commit(_types.mutations.setSelectedBatchStatus, { status: data.batchStatus } )
        })
    }, (context) => {
      context.commit(_types.mutations.setInspectionInProgress, payload.inspectionId )
      context.commit(_types.mutations.setSelectedBatchStatus, { status: InspectionBatchStatus.InProgress } )
    }),
    [_types.actions.getDistrictNotifications]: ({ commit }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/${payload.districtLea}/notifications`))
        .then(data => commit(_types.mutations.setDistrictNotifications, data))
    },
    getTransportationFiscalYears:({commit}) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/transportation/inspections/fiscal-years`))
      .then(data => {
        commit('setFiscalYears', data)
      })
    }
      
    //#endregion
  }
}
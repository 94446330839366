<template>
  <div>
    <div v-if="isSchoolOrDistrict">
      <lea-display :lea="selectedLea" />
      {{ sanitizedESchoolConnectionString }}
      <div class="card">
        <div class="card-header">
          Employees
        </div>
        <div class="card-body">
          <paginated-table :items="[]" sort-by="lastName" :responsive="true" sort-asc :per-page="10" striped hover small />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="alert alert-warning" role="alert">
        A school or district must be selected.
      </div>
    </div>
  </div>
</template>

<script>
import { LeaTypes } from '@/helpers/leaTypes';
import LeaDisplay from '@/components/LeaDisplay.vue';
import { mapState } from 'vuex';
import PaginatedTable from '@/components/PaginatedTable.vue';

export default {
  data() {
    return {
      LeaTypes,
    };
  },
  components: {
    LeaDisplay,
    PaginatedTable
  },
  methods: {

  },
  mounted() {

  },
  computed: {
    ...mapState('rvsTroubleshooting', ['eSchoolConnectionString']),
    isSchoolOrDistrict() {
      return this.selectedLea && (this.selectedLea.type == LeaTypes.School || this.selectedLea.type == LeaTypes.District);
    },
    sanitizedESchoolConnectionString() {
      if (!this.eSchoolConnectionString.split(';').length) {
        return '';
      }
      return this.eSchoolConnectionString.split(';').slice(0, 2).join(';');
    }
  },
  watch: {
    selectedLea: {
      handler: function() {
        this.$store.dispatch('rvsTroubleshooting/getESchoolConnectionString', this.selectedLea.number);
        this.$store.dispatch('rvsTroubleshooting/getEFinanceEmployees', this.selectedLea.number);
      },
      immediate: true
    }
  }
};
</script>

<template>
  <div>
    <student-information-toolbar v-if="!currentStudent.isReadOnly" :can-save="validation.$dirty" :save-method="save.bind(null, 'CareerPlanningResponse')" @cancel="cancel" />
    <b-tabs pills>
      <b-tab title="Career Assessment" class="pt-3">
        <b-table striped small caption="Career Assessment" :caption-top="true" :items="local.careerAssessments" :fields="careerAssessmentFields">
          <template #cell(assessmentName)="data">
            <b-form-input :readonly="currentStudent.isReadOnly" v-model="data.item.assessmentName" />
          </template>
          <template #cell(dateTaken)="data">
            <b-datepicker :disabled="currentStudent.isReadOnly" v-model="data.item.dateTaken" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric'}" />
          </template>      
          <template #cell(notes)="data">
            <b-form-textarea :readonly="currentStudent.isReadOnly" v-model="data.item.notes" />
          </template>      
        </b-table>    
      </b-tab>
      <b-tab title="Career Plan" class="pt-3">
        <b-table striped small caption="Career Plan" :caption-top="true" :items="local.careerPlans" :fields="careerPlanFields">
          <template #cell(createdOrModified)="data">
            <b-form-select :disabled="currentStudent.isReadOnly" v-model="data.item.createdOrModified" :options="staticMetadata.careerPlanning.createdModified">
              <template #first>
                <b-form-select-option :value="null" />
              </template>
            </b-form-select>
          </template>
          <template #cell(date)="data">
            <b-datepicker :disabled="currentStudent.isReadOnly" v-model="data.item.date" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric'}" />
          </template>      
          <template #cell(notes)="data">
            <b-form-textarea :disabled="currentStudent.isReadOnly" v-model="data.item.notes" />
          </template>      
        </b-table>
      </b-tab>
      <b-tab title="Program of Study" class="pt-3">
        <h2 class="caption border-bottom">
          Program of Study
        </h2>
        <program-of-study 
          v-for="(p, idx) in local.programOfStudy" 
          :key="p.grade" 
          v-model="local.programOfStudy[idx]"
          :readonly="currentStudent.isReadOnly"
        />        
      </b-tab>
      <b-tab title="Exposure to Non-Traditional Careers" class="pt-3">
        <b-table striped small caption="Exposure to Non-Traditional Careers" :caption-top="true" :items="local.exposureToNonTraditionalCareers" :fields="exposureToNonTraditionalCareersFields">
          <template #cell(nonTradProgramOfStudy)="data">
            <b-form-select :disabled="currentStudent.isReadOnly" v-model="data.item.nonTradProgramOfStudy" :options="nonTraditionalProgramsOfStudy" />
          </template>
          <template #cell(date)="data">
            <b-datepicker :disabled="currentStudent.isReadOnly" v-model="data.item.date" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric'}" />
          </template>      
          <template #cell(notes)="data">
            <b-form-textarea :disabled="currentStudent.isReadOnly" v-model="data.item.notes" />
          </template>      
        </b-table>  
      </b-tab>
    </b-tabs>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import { Types } from '@/modules/cte/stores/careercoach'
import { metadata as staticMetadata } from '@/modules/cte/stores/careercoach/metadata'
import { gradeMap } from '@/helpers/gradeSpans'
import ProgramOfStudy from './ProgramOfStudy'
import { StudentComponentMixin } from './StudentComponentMixin'

export default {
  computed: {
    ...mapState(Types.path, [Types.state.currentStudent, Types.state.metadata]),
    nonTraditionalProgramsOfStudy() {
      return (this.metadata.nonTraditionalProgramsOfStudy || []).filter(p=>p.nonTraditionalGender == this.currentStudent.gender).map(p => { return { text: p.name, value: p.id }})
    },
  },
  mixins: [StudentComponentMixin],
  components: {
    ProgramOfStudy
  },
  data() {
    return {
      gradeMap,
      staticMetadata,
      careerAssessmentFields: [
        {key: 'grade', formatter: (d) => { return gradeMap.get(d) }, class: 'text-center', thStyle: 'width: 150px;' },
        {key: 'dateTaken', class: 'text-center', thStyle: 'width: 250px;' },
        'assessmentName',
        'notes'
      ],
      careerPlanFields: [
        {key: 'grade', formatter: (d) => { return gradeMap.get(d) }, class: 'text-center', thStyle: 'width: 150px;' },
        {key: 'createdOrModified', class: 'text-center', thStyle: 'width: 150px;' },
        {key: 'date', class: 'text-center', thStyle: 'width: 250px;' },
        'notes'
      ],
      exposureToNonTraditionalCareersFields: [
        {key: 'grade', formatter: (d) => { return gradeMap.get(d) }, class: 'text-center', thStyle: 'width: 150px;' },
        {key: 'nonTradProgramOfStudy', class: 'text-center', thStyle: 'width: 300px;' },
        {key: 'date', class: 'text-center', thStyle: 'width: 250px;' },
        'notes'
      ],
    }
  }
}
</script>
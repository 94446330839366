<template lang="html">
  <div>
    <!-- <b-alert show variant="warning" v-if="selectedLea.type === 3">
      Career Coach Reports are only available at the Co-Op level and below. Please select an appropriate LEA from the LEA Context Picker above.
    </b-alert> -->
    <b-tabs card v-if="this.selectedLea.number != 'MYLEAS'">
      <b-tab title="Certifications">
        <certifications-report :ay="ay" />
      </b-tab>
    </b-tabs>
    <b-alert v-else show class="text-center" variant="warning">
      Reports are available for District and School LEAs. Please change LEA selection to generate reports.
    </b-alert>
  </div>
</template>

<script>
import CertificationsReport from './components/certifications/CertificationsReport.vue'

import store from '@/stores'

export default {
  components:{
    CertificationsReport,
  },
  props: {
    ay: Number
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('cte/careercoach/reporting/getRosterDates')
    next()
  }
}
</script>

<style lang="scss" scoped>
  $insights-standard-blue: #4785c8;

  .report-container {
    padding: 10px;
    border-radius: 5px;
    background-color: #dadada;

    & ::v-deep .report-output {
      // background-color: #c34393;
      & table {
        margin-bottom: 20px;
      }
    }

    & ::v-deep table > caption {
      background-color: $insights-standard-blue;
      padding-left: 20px;
      color: darken($insights-standard-blue, 40%)
    }

  }
</style>
<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
//import { WhiteNonWhiteCategoryFactory } from './StaffRaces'
import { credits } from '../../../helpers/credits'

export default {
  name: 'novice-teacher-percentages-chart',
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'column'
        },
        plotOptions: {
          column: {
            stacking: 'percent',
            minPointLength: 1
          }
        },
        credits: credits.SIS,
        title: {
          text: this.chartTitle
        },
        tooltip: {
          pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y:.2f}%</b><br/>'
        },
        series: [
          {
            name: 'Novice Teachers',
            data: [
              {
                x: 2018,
                y: 0.23 * 100
              },
              {
                x: 2019,
                y: 0.04 * 100
              }
            ]
          },
          {
            name: 'Non-Novice Teachers',
            data: [
              {
                x: 2018,
                y: 100 - 0.23 * 100
              },
              {
                x: 2019,
                y: 100 - 0.04 * 100
              }
            ]
          }
        ],
        xAxis: {
          title: {
            text: 'School Year'
          },
          type: 'category'
        },
        yAxis: {
          min: 0,
          max: 100,
          title: {
            text: 'Percent of Novice Teachers'
          }
        }
      }
    }
  },
  mixins: [ChartMixin],
  methods: {
    dataItems() {
      return (this.chartOptions.series || []).flatMap(series => {
        return series.data.map(d => {
          return {
            SchoolYear: d.x,
            Type: d.type,
            Percentage: d.y.toFixed(2) + '%'
          }
        })
      })
    },
    excelExportData() {
      return {
        name: this.chartTitle,
        items: this.dataItems()
      }
    },
  },
  props: {
    staffData: Array,
    chartTitle: String
  },
  watch: {
    staffData(newData) {
      var dataSet = [
        { name: 'Novice Teachers (< 3 yrs. Experience)', field: 'noviceTeacherPercentage' },
        { name: '>= 3 yrs. Experience', field: 'experiencedTeacherPercentage' }
      ]

      for (var i = 0; i < dataSet.length; i++) {
        var seriesData = newData.map(row => {
          return {
            x: (row.fiscalYear % 1990) + 1990,
            y: row[dataSet[i].field] * 100.0,
            type: dataSet[i].name
          }
        })
        dataSet[i].data = seriesData
      }
      this.chartOptions.series = dataSet
    }
  }
}
</script>

<template>
  <div>
    <area-overview />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AreaOverview from './AreaOverview'

export default {
  computed: {
    ...mapState('globalModule', ['userContext'])
  },
  components: {
    AreaOverview
  }
}
</script>

<template>
  <div>
    <paginated-table :items="dataItems()" :fields="fields" sort-by="sortIndex" striped outlined small head-variant="dark" :hide-controls="true">
      <template v-slot:[`cell(${f.key})`]="data" v-for="f in fields">
        <div v-html="data.value" :key="f.key" />
      </template>
    </paginated-table>
  </div>
</template>

<script>
import PaginatedTable from '@/components/PaginatedTable.vue'
import { Types } from '@/modules/esa/stores'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(Types.path, [Types.state.planCategories]),
    fields() {
      return [
        {
          key: 'Name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'Program Code(s)',
          label: 'Program Code(s)',
          sortable: true,
          class: 'text-center'
        },
        {
          key: 'Personnel',
          label: 'Personnel',
          sortable: false,
          class: 'text-center'
        },
        {
          key: 'Materials, Supplies, and Equipment',
          label: 'Materials, Supplies, and Equipment',
          sortable: false,
          class: 'text-center'
        },
        {
          key: 'Services',
          label: 'Services',
          sortable: false,
          class: 'text-center'
        },
        {
          key: 'Other',
          label: 'Other',
          sortable: false,
          class: 'text-center'
        }
      ]
    },
  },
  methods: {
    excelExportData() {
      return {
        name: 'Plan Summary',
        items: this.dataItems().map(d => {
          return {
            "Name": d["Name"],
            "Program Code(s)": d["Program Code(s)"],
            "Personnel": d["Personnel"].replace('<br>', ', '),
            "Materials, Supplies, and Equipment": d["Materials, Supplies, and Equipment"].replace('<br>', ', '),
            "Services":  d["Services"].replace('<br>', ', '),
            "Other":  d["Other"],
          }
        })
      }
    },
    dataItems() {
      return this.planCategories.map(pc => {
        return {
          "Name": pc.name,
          "Program Code(s)": pc.programCode,
          "Personnel": (pc.types.find(t => t.description == "Personnel") || { codes: []}).codes.join('<br>'),
          "Materials, Supplies, and Equipment": (pc.types.find(t => t.description == "Materials, Supplies, and Equipment") || { codes: []}).codes.join('<br>'),
          "Services": (pc.types.find(t => t.description == "Purchased Services") || { codes: []}).codes.join('<br>'),
          "Other": pc.types.find(t => t.description == "Other").codes[0] || "All codes except 61000-62999, 66000-66999, 67340-67390, 63000-65999.",
        }
      })
    },
  },
  components: {
    PaginatedTable
  }
}
</script>

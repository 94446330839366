<template>
  <div>
    Mobile Friendly list of inspections queued up for attention. Maybe a scrollable list of cards.  Clicking one would take you to an equally friendly inspection page. WOuld be nice if hitting this page shut off the headers and all other non-inspection cruft.
    <batch-card v-for="i in 5" :key="i" :inspection-id="i" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BatchCard from './inspect/BatchCard'
export default {
  name: 'inspection-list',
  computed: {
    ...mapState('globalModule', ['userContext'])
  },
  components: {
    BatchCard
  }
}
</script>

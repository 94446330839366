export const ProposalStateMap = new Map();
ProposalStateMap.set(0, {
  code: 0,
  status: 'Draft',
  action: 'Return to Draft',
  requiresComment: false,
  variant: 'secondary'
})
ProposalStateMap.set(1, {
  code: 1,
  action: 'Return to CTE Coordinator',
  status: 'Information or Changes Requested',
  supportsAssignment: true,
  requiresComment: true,
  variant: 'warning'
})
ProposalStateMap.set(2, {
  code: 2,
  action: 'Return for Revisions',
  status: 'Revising',
  requiresComment: true,
  variant: 'warning'
})
ProposalStateMap.set(3, {
  code: 3,
  action: 'Return for Cost-item Revisions',
  status: 'Revising Cost Items',
  requiresComment: false,
  variant: 'warning'
})
ProposalStateMap.set(10, {
  code: 10,
  action: 'Submit to Program Coordinator',
  status: 'Pending Program Coordinator Review',
  requiresComment: false,
  variant: 'primary'
})
ProposalStateMap.set(11, {
  code: 11,
  action: 'Submit to Perkins Administrator',
  status: 'Pending Perkins Administrator Review',
  requiresComment: false,
  variant: 'primary',
})
ProposalStateMap.set(12, {
  code: 12,
  action: 'Send to Program Advisor',
  status: 'Pending Program Advisor Review',
  requiresComment: false,
  variant: 'info',
})
ProposalStateMap.set(100, {
  code: 100,
  action: (proposal) => {
    if (proposal && proposal.status == ProposalStates.RevisingCostItems) {
      return 'Done Revising Cost-items'
    }
    return 'Approve'
  },
  status: (transition) => {
    if (transition.fromStatus == ProposalStates.RevisingCostItems) {
      return 'Done Revising Cost-items'
    }
    return 'Approved'
  },
  requiresComment: false,
  variant: 'success'
})
ProposalStateMap.set(101, {
  code: 101,
  action: 'Deny',
  status: 'Denied',
  requiresComment: true,
  variant: 'danger'
})
ProposalStateMap.set(255, {
  code: 255,
  action: 'Cancel',
  status: 'Canceled',
  requiresComment: true,
  variant: 'warning'
})

export const ProposalStates = {
  Draft: 0,
  ReturnedToApplicant: 1,
  Revising: 2,
  RevisingCostItems: 3,
  PendingProgramManagerApproval: 10,
  PendingProgramAdministratorApproval: 11,
  PendingProgramSupervisorApproval: 12,
  Approved: 100,
  Rejected: 101,
  Canceled: 255,
}

export const CoverPageStateMap = new Map();
CoverPageStateMap.set(0, {
  code: 0,
  status: 'Draft',
  action: 'Return to Draft',
  supportsAssignment: true,
  requiresComment: false,
  variant: 'secondary'
})
CoverPageStateMap.set(1, {
  code: 1,
  action: 'Return to CTE Coordinator',
  status: 'Information or Changes Requested',
  supportsAssignment: true,
  requiresComment: true,
  variant: 'warning'
})
CoverPageStateMap.set(2, {
  code: 2,
  action: 'Return for Revisions',
  status: 'Revising',
  requiresComment: true,
  variant: 'warning'
})
CoverPageStateMap.set(11, {
  code: 11,
  action: 'Submit to Perkins Administrator',
  status: 'Pending Perkins Administrator Review',
  requiresComment: false,
  variant: 'info',
})
CoverPageStateMap.set(100, {
  code: 100,
  action: 'Approve',
  status: 'Approved',
  requiresComment: false,
  variant: 'success'
})

export const CoverPageStates = {
  Draft: 0,
  ReturnedToApplicant: 1,
  Revising: 2,
  PendingProgramAdministratorApproval:  11,
  Approved: 100,
}
<template>
  <div>
    <step-container :academic-year="Number($route.params.ay)" task-code="FLDU" title="Perkins V Funding Limits Upload">
      <div>
        <b-row>
          <b-col class="text-right">
            <a href="/content/cte/perkins-v/ApplicantFundingLimitsTemplate.xlsx" download class="font-weight-bold text-info"><i class="fa fa-download fa-fw" /> Download blank Perkins V Funding Limits template</a>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <import-excel-widget :upload-endpoint="uploadEndpoint" @imported="getFundingLimits" worksheet="FUNDING LIMITS" :columns="worksheetColumns">
              <paginated-table :items="applicantFundingLimits" :fields="fields" :load-data="loadData" sort-by="consortiaLEA" :sort-desc="true" />
            </import-excel-widget>
          </b-col>
        </b-row>
      </div>
    </step-container>
  </div>
</template>

<script>
import { Types } from '@/modules/cte/stores/perkinsv'
import StepContainer from '../StepContainer.vue'
import PaginatedTable from '@/components/PaginatedTable.vue'
import ImportExcelWidget from '../../../common/ImportExcelWidget.vue'
import { formatCurrency } from '@/helpers/formatters'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      completionInfo: {
        completed: false, 
        completedBy: null,
        completedDate: null
      },
      loadData: Promise.resolve(true)
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.applicantFundingLimits]),
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    },
    uploadEndpoint() {
      return `/api/cte/perkinsv/admin/perkinsFundingLimits/${this.$route.params.ay}`
    },
    worksheetColumns() {
      return ['AY', 'CONSORTIA_LEA', 'District_LEA', 'FUNDING_LIMIT']
    },
    fields() {
      return  [
        // { key: 'ay', label: 'Academic Year', sortable: true },
        { key: 'consortiaLEA', label: 'Consortia LEA', sortable: true, formatter: this.leaFormatter },
        { key: 'districtLEA', label: 'District LEA', sortable: true, formatter: this.leaFormatter },
        { key: 'fundingLimit', label: 'Funding Limit', sortable: true, formatter: formatCurrency }
      ]
    }
  },
  methods: {
    getFundingLimits() {
      this.loadData = this.$store.dispatch(Types.actions.getApplicantFundingLimits, { ay: this.$route.params.ay })
    },
    leaFormatter(lea) {
      if (this.leaDictionary[lea])
        return `${lea} - ${this.leaDictionary[lea].name}`
      return lea
    }
  },
  mounted() {
    this.getFundingLimits()
  },
  components: {
    ImportExcelWidget,
    PaginatedTable,
    StepContainer
  }
}
</script>

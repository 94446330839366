<template>
  <b-card>
    <b-form-row>
      <div class="col-md-6">
        <b-form-group label-class="font-weight-bold" label="District Name">
          <span>{{ (leaContext.LeaDictionary[inspectionHeader.districtLea] || {}).name || 'District' }}</span>
        </b-form-group>
        <b-form-group label-class="font-weight-bold" label="Bus Number">
          <span>{{ inspectionHeader.permitNumber }}</span>
        </b-form-group>
        <b-form-group label-class="font-weight-bold" label="VIN">
          <span>{{ inspectionHeader.vinNumber }}</span>
        </b-form-group>
        <b-form-group label-class="font-weight-bold" label="Year">
          <span>{{ inspectionHeader.bodyYear }}</span>
        </b-form-group>
        <b-form-group label-class="font-weight-bold" label="Primary Use">
          <span>{{ inspectionHeader.primaryUsage }}</span>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group label-class="font-weight-bold" label="Sticker Number">
          <span>{{ inspectionHeader.stickerNumber }}</span>
        </b-form-group>
        <b-form-group label-class="font-weight-bold" label="Make">
          <span>{{ inspectionHeader.bodyMake }}</span>
        </b-form-group>
        <b-form-group label-class="font-weight-bold" label="Fuel Type">
          <span>{{ inspectionHeader.fuelType }}</span>
        </b-form-group>
        <b-form-group label-class="font-weight-bold" label="Capacity">
          <span>{{ inspectionHeader.capacity }}</span>
        </b-form-group>
      </div>
    </b-form-row>
    <b-table 
      hover
      :fields="fields"
      :items="inspections"
      :load-data="loadData">
      <template v-slot:cell(createdDate)="data">
        <div>
          <span v-if="data.value">{{ formatDate(data.value) }}</span>
          <span v-else>N/A</span>
        </div>
      </template>
      <template v-slot:cell(completionDate)="data">
        <div>
          <span v-if="data.value">{{ formatDate(data.value) }}</span>
          <span v-else>N/A</span>
        </div>
      </template>
      <template v-slot:cell(brakes)="data">
        <div>
          <span v-if="data.value">{{ BrakeTypes[data.value].text }}</span>
          <span v-else>N/A</span>
        </div>
      </template>
      <template v-slot:cell(transmission)="data">
        <div>
          <span v-if="data.value">{{ TransmissionTypes[data.value].text }}</span>
          <span v-else>N/A</span>
        </div>
      </template>
      <template v-slot:cell(vehicleType)="data">
        <div>
          <span v-if="data.value">{{ VehicleTypes[data.value].text }}</span>
          <span v-else>N/A</span>
        </div>
      </template>
      <template v-slot:cell(status)="data">
        <div>
          <i class="text-secondary fas fa-clock" v-if="data.value == InspectionStatuses.NotStarted" title="Not Started" />
          <i class="text-primary fas fa-stopwatch" v-else-if="data.value == InspectionStatuses.InProgress" title="In Progress" />
          <i class="text-success fas fa-check" v-else-if="data.value == InspectionStatuses.Completed" title="Completed" />
          <i class="text-secondary fas fa-forward" v-else-if="data.value == InspectionStatuses.Skipped" title="Skipped" />
          <span v-else>N/A</span>
          &nbsp;
          <b-badge variant="secondary" v-if="data.value == InspectionStatuses.NotStarted">
            Not Started
          </b-badge>
          <b-badge variant="primary" v-if="data.value == InspectionStatuses.InProgress">
            In Progress
          </b-badge>
          <b-badge variant="danger" v-if="data.value == InspectionStatuses.Completed && busGrounded(data.item)">
            GROUND
          </b-badge>
          <b-badge variant="warning" v-else-if="data.value == InspectionStatuses.Completed && hasFixComponents(data.item)">
            1+ Issues
          </b-badge>
          <b-badge variant="success" v-else-if="data.value == InspectionStatuses.Completed && !hasFixComponents(data.item)">
            No Issues
          </b-badge>
        </div>
      </template>
      <template v-slot:cell(showDetails)="data">  
        <b-button size="sm" @click="data.toggleDetails" class="mr-2">
          {{ data.detailsShowing ? 'Hide' : 'Show' }} Details
        </b-button>
      </template>
      <template v-slot:row-details="data">
        <inspection :inspection="data.item" :readonly="true" />
      </template>
    </b-table>
  </b-card>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import Inspection from './inspect/Inspection'
import { InspectionStatuses, RatingTypes, TransmissionTypes, BrakeTypes, VehicleTypes } from '../common/Enums'
import { Types, Trans, isBusGrounded } from '../../../stores/dpsaft/trans'
const transportationStore = new Trans()
export default {
  name: 'bus-inspection-history',
  data() {
    return {
      InspectionStatuses, 
      RatingTypes,
      TransmissionTypes,
      BrakeTypes,
      VehicleTypes,
      loadData: null,
      fields:[
        {
          key:'odometerReading',
          label: 'Odometer Reading'
        },
        {
          key:'vehicleType',
          label: 'Vehicle Type'
        },
        {
          key:'transmission',
          label: 'Transmission'
        },
        {
          key:'brakes',
          label: 'Brake Type'
        },
        {
          key:'status',
          label: 'Status'
        },
        {
          key:'createdDate',
          label: 'Created Date'
        },
        {
          key:'completionDate',
          label: 'Completion Date'
        },
        {
          key: 'showDetails'
        }
      ]
    }
  },
  computed:{
    ...mapState(transportationStore.path, [Types.state.InspectionHistory]),
    ...mapState('globalModule', ['leaContext']),
    safeHistory() {
      return this.InspectionHistory  ? this.InspectionHistory : { item1: {}, item2: [] }
    },
    inspectionHeader() {
      return this.safeHistory.item1
    },
    inspections(){
      return this.safeHistory.item2
    }
  },
  components: {
    Inspection
  },
  methods:{
    formatDate(date) {
      return moment(date).format('MM/DD/YYYY')
    },
    hasFixComponents(inspection) {
      return inspection.components.some(c => c.rating == RatingTypes.Fix)
    },
    busGrounded(inspection) {
      return isBusGrounded(inspection)
    }
  },
  mounted() {
    this.loadData = this.$store.dispatch(Types.actions.getInspectionHistory, { busId: this.$route.params.busId })
  }
}
</script>

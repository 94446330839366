<template>
  <div v-show="render">
    <div>
      <h4 class="text-dark">         
        Distribution<i v-b-toggle.collapse-info class="ml-2 fas fa-info-circle fa-sm fa-fw text-primary info-icon d-print-none" title="Show More Information" />
      </h4>

      <b-collapse id="collapse-info" class="mb-3">
        <b-card-header class="mb-0 d-print-none">
          <b-card-title v-b-toggle.collapse-info class="mb-0">
            <b-icon-x />Information
          </b-card-title>
        </b-card-header>
        <b-card class="mb-2">
          <b-card-text>
            <h5 class="font-italic">
              Teacher Value-Added Growth Score Summary Statistics
            </h5>
            <p>The summary statistics for teacher value-added growth scores are provided in <a href="#table-1">Table 1: Mean Teacher Value-Added Growth Scores</a>. The means and summary statistics are weighted by the number of students assigned to the teacher. Teachers with fewer than 10 students were excluded from the analysis. Note that although the student level mean is set at 80 (0 on a standard scale), the teacher-level means may be above or below 80 depending on the growth values of their groups of students.</p>

            <h5 class="font-italic">
              Classifying Teacher Value-Added Growth Scores for Effectiveness Ratings
            </h5>
            <p>
              The following procedures were used to classify teachers' mean value-added growth scores with regards to effectiveness. Please note, this is one of many possible ways to classify value-added growth scores and was selected for illustrative purposes for the requested analyses.
              <ul>
                <li>Teacher/student links were created using the cycles 2 and 6 Class and Register tables from the Statewide Information System (SIS) for 2022. </li>
              </ul>
            </p>
            <p><a href="#table-2">Table 2: Count of Teachers in Category</a> provides the number and percentage of teachers that would fall in each effectiveness range based on 2022 student value-added growth scores.</p>
          </b-card-text>
        </b-card>
      </b-collapse>
    </div>

    <div v-if="teacherVAMData">
      <mean-teacher-value-added-growth-scores-by-subject :render="render" />
      <count-teachers-in-category-by-subject :render="render" />

      <div class="figure">
        <div class="figure-caption">
          Figure 2: Distribution of Teacher Value-Added Growth Scores
        </div>
        <distribution-teacher-value-added-growth-scores-by-subject :render="render" />
      </div>
    </div>
    <div v-else>
      <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
        <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
      </div>
    </div>
  </div>
</template>

<script>
import MeanTeacherValueAddedGrowthScoresBySubject from '../tables/MeanTeacherValueAddedGrowthScoresBySubject.vue'
import DistributionTeacherValueAddedGrowthScoresBySubject from '../charts/DistributionTeacherValueAddedGrowthScoresBySubject.vue'
import CountTeachersInCategoryBySubject from '../tables/CountTeachersInCategoryBySubject.vue'
import { mapState } from 'vuex';
import CardStateIcon from '../../../CardStateIcon.vue'
import { subjectCase } from '../helpers.js'

export default {
  data() {
    return {
      subjectCase
    }
  },
  components: {
    MeanTeacherValueAddedGrowthScoresBySubject,
    DistributionTeacherValueAddedGrowthScoresBySubject,
    CountTeachersInCategoryBySubject,
    CardStateIcon
  },
  props: {
    render: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('teacherVAMModule', ['teacherVAMData']),
  }
}
</script>
<template lang="html">
  <div class="card mt-3 bg-light" id="state-summary">
    <div class="card-header">
      State Summary
    </div>
    <div class="card-body">
      <div class="card-group">
        <loadable-chart-card :load-data="loadData" card-style="" card-class="pnl-sum" :enable-data-toggle="false" class="pnl-sum">
          <div id="divSumDetails" class="sum-row">
            <div class="sum-details d-flex flex-wrap">
              <div class="details-item">
                <div id="divItemCoop" class="item-sum-icon">
                  &nbsp;
                </div>
                <div class="item-count">
                  <span class="circle-hdr">Educational Co-ops</span>
                  <span class="circle-val">{{ summary.coops.toLocaleString() }}</span>
                </div>
              </div>
              <div class="details-item">
                <div class="info-container">
                  <div class="item-summary">
                    <div id="divItemDistrict" class="item-sum-icon">
                    &nbsp;
                    </div>
                    <div class="item-count">
                      <span class="circle-hdr">Districts</span>
                      <span class="circle-val">{{ summary.districts.toLocaleString() }}</span>
                    </div>
                  </div>
                  <div class="item-info">
                    <div class="info-pnl">
                      <div class="info-row">
                        <span class="info-hdr">Regular Districts</span>
                        <span class="info-val">{{ (summary.districts - summary.charterDistricts).toLocaleString() }}</span>
                      </div>
                      <div class="info-row">
                        <span class="info-hdr">Open Enrollment Charter School Districts</span>
                        <span class="info-val">{{ summary.charterDistricts.toLocaleString() }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="details-item">
                <div id="divItemSchool" class="item-sum-icon">
                  &nbsp;
                </div>
                <div class="item-count">
                  <span class="circle-hdr">Schools</span>
                  <span class="circle-val">{{ summary.schools.toLocaleString() }}</span>
                </div>
              </div>
              <div class="details-item">
                <div id="divItemStudent" class="item-sum-icon">
                  &nbsp;
                </div>
                <div class="item-count">
                  <span class="circle-hdr">Students</span>
                  <span class="circle-val">{{ summary.students.toLocaleString() }}</span>
                </div>
                <div class="item-info">
                  <div class="info-pnl">
                    <div class="info-row">
                      <span class="info-hdr">K-12 Student Enrollment</span>
                      <span class="info-val">{{ summary.students.toLocaleString() }}</span>
                    </div>
                    <div class="info-row">
                      <span class="info-hdr">% Students qualifying for Free and Reduced Lunches</span>
                      <span class="info-val">{{ (summary.percentFreeReduced * 100.0).toFixed(2) }}%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </loadable-chart-card>
      </div>
    </div>
  </div>
</template>

<script>
import LoadableChartCard from '../LoadableChartCard.vue'

export default {
  data() {
    return {
      loadData: null
    }
  },
  props: {
    //selectedLea: Object
  },
  computed: {
    summary() {
      return this.selectedLea.stateSummary
    }
  },
  components: {
    LoadableChartCard
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler() {
        this.loadData = Promise.resolve(true)
      }
    }
  }
}
</script>

<style lang="scss">
#state-summary {
  .pnl-data {
    background-color: #fff;
  }

  .icon-coop,
  #divItemCoop {
    background-image: url('icons/icon-item-coop.png') !important;
  }

  .icon-district,
  #divItemDistrict {
    background-image: url('icons/icon-item-district.png') !important;
  }

  .icon-school,
  #divItemSchool {
    background-image: url('icons/icon-item-school.png') !important;
  }

  .icon-student,
  #divItemStudent {
    background-image: url('icons/student.png') !important;
  }

  .details-item {
    border: 1px solid #ccc;
    padding: 5px;
    margin: 5px 0;
  }

  .info-pnl span {
    vertical-align: middle;
    font-size: 16px;
    line-height: 1.2em;
  }

  span.info-hdr {
    width: 90%;
    text-align: left;
    background-color: #fff;
    color: #fa5430;
    margin-right: 10px;
    white-space: nowrap;
  }

  span.info-val {
    width: 10%;
    text-align: right;
    color: #34495d;
    white-space: nowrap;
  }

  span.circle-hdr {
    font-size: 20px;
    line-height: 1.3em;
    margin-right: 10px;
    white-space: nowrap;
  }

  span.circle-val {
    font-size: 25px;
    line-height: 1.1em;
    color: #3598db;
    white-space: nowrap;
  }

  .item-sum-icon {
    background-repeat: no-repeat;
    background-position: left;
    background-size: 50px;
    height: 60px;
    width: 60px;
    border: none;
    float: left;
  }

  .item-info {
    float: left;
  }

  .details-item {
    min-height: 85px;
    border: 0px;
    min-width: 49%;
  }
}
</style>
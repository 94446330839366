<template>
  <div>
    <purple-dashboard-widget />
  </div>
</template>
<script>
import { PageMixin } from '@/components/PageMixin'
import PurpleDashboardWidget from '../Widgets/PurpleDashboardWidget.vue'

export default {
  mixins: [PageMixin],
  components: {
    PurpleDashboardWidget
  },
  computed: {
  },
  methods: {
  },
  props: {
  },
  mounted() {
    
  }
}
</script>

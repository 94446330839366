
import StudentRosterPage from './pages/StudentRosterPage'
import CCDashboardPage from './pages/CCDashboardPage'
import StudentPage from './pages/StudentPage'
import NarrativesPage from './pages/NarrativesPage'
import ReportsPage from './pages/reports/ReportsPage'
import CareerCoachUserPage from './pages/users/CareerCoachUserPage.vue'
import CareerCoachUserEditForm from './pages/users/CareerCoachUserEditForm.vue'
import InviteUserForm from './pages/users/InviteUserForm.vue'
import BulkVisitsPage from './pages/BulkVisitsPage.vue'
import NarrativeEdit from './pages/NarrativeEdit.vue'
import AdminPage from './pages/admin/AdminPage.vue'
import store from '@/stores'

import CoordinatorInvite from './pages/CoordinatorInvite.vue'

import TeacherHome from './pages/teacher/TeacherHome.vue'
import LimitedStudentPage from './pages/teacher/LimitedStudentPage.vue'
import TeacherReportsPage from './pages/teacher/reports/ReportsPage'

export const routes = [
  {
    path: '/cte/career-coach',
    name: 'CTE/CareerCoach/Home',
    redirect: '/cte/career-coach/dashboard',
  },  
  {
    name: 'CTE/CareerCoach/Dashboard',
    path: '/cte/career-coach/dashboard',
    meta: { label() { return `Career & Technical Education Career Coach Portal Dashboard - ${this.ay + 1999}/${this.ay + 2000} Academic Year` }, icon: 'fa-tachometer-alt'},
    component: CCDashboardPage
  },  
  {
    name: 'CTE/CareerCoach/Narratives',
    path: '/cte/career-coach/narratives',
    meta: { label: 'Narratives', icon: 'fa-business-time'},
    component: NarrativesPage
  },
  {
    name: 'CTE/CareerCoach/Narratives/View',
    path: '/cte/career-coach/narratives/:narrativeId',
    props: { readonly: true },
    meta: { label: 'View Narrative', icon: 'fa-business-time'},
    component: NarrativeEdit
  },

  {
    name: 'CTE/CareerCoach/Narratives/Edit',
    path: '/cte/career-coach/narratives/edit/:narrativeId',
    props: { readonly: false },
    meta: { label: 'Edit Narrative', icon: 'fa-business-time'},
    component: NarrativeEdit
  },
  {
    name: 'CTE/CareerCoach/Reports',
    path: '/cte/career-coach/reports',
    meta: { label: 'Reports', icon: 'fa-chart-bar'},
    component: ReportsPage
  },      
  {
    name: 'CTE/CareerCoach/Visits',
    path: '/cte/career-coach/visits',
    meta: { label: 'Group Visits', icon: 'fa-handshake'},
    component: BulkVisitsPage
  },        
  {
    name: 'CTE/CareerCoach/StudentRoster',
    path: '/cte/career-coach/student-roster',
    meta: { label: 'Student Roster', icon: 'fa-id-card'},
    component: StudentRosterPage
  },
  {
    name: 'CTE/CareerCoach/StudentPage',
    path: '/cte/career-coach/student-page/:studentId',
    meta: { label() { 
      return `Student Information - ${store.state.cte.careercoach.isLoadingStudent ? "Loading..." : store.state.cte.careercoach.currentStudent.fullName()}` 
    }, icon: 'fa-user'},
    component: StudentPage,
    beforeEnter(to, from, next) {
      if (window.userContext.isCTECoordinator) {
        next({ name: 'CTE/CareerCoachTeacher/StudentPage', params: to.params })
      } else {
        next()
      }
    }
  },
  {
    name: 'CTE/CareerCoach/User/Certifications',
    path: '/cte/career-coach/admin/certifications',
    component: StudentRosterPage
  },
  {
    name: 'CTE/CareerCoach/User/PostHighSchoolPlans',
    path: '/cte/career-coach/admin/post-high-school-plans',
    component: StudentRosterPage
  },
  {
    name: 'CTE/CareerCoach/Admin/WorkshopsAndActivities',
    path: '/cte/career-coach/admin/workshop-and-activity',
    component: StudentRosterPage
  },
  {
    name: 'CTE/CareerCoach/Admin/Users',
    path: '/cte/career-coach/admin/users',
    meta: { label: 'User Administration', icon: 'fa-users-cog'},
    component: CareerCoachUserPage
  },
  {
    name: 'CTE/CareerCoach/Admin/Users/Edit',
    path: '/cte/career-coach/admin/users/edit',
    component: CareerCoachUserEditForm
  },
  {
    name: 'CTE/CareerCoach/Admin/Users/Invite',
    path: '/cte/career-coach/admin/users/invite',
    component: InviteUserForm
  },
  {
    name: 'CTE/CareerCoach/Admin',
    path: '/cte/career-coach/admin',
    meta: { 
      label: 'Career Coach Module Administration',
      icon: 'fa-school'
    },
    component: AdminPage
  },
  {
    name: 'CTE/CareerCoach/Admin/CoordinatorInvite',
    path: '/cte/career-coach/coordinator-invite',
    meta: { label: 'Invite User', icon: 'fa-user-plus'},
    component: CoordinatorInvite
  },
  {
    name: 'CTE/CareerCoachTeacher',
    path: '/cte/career-coach-teacher',
    redirect: { name: 'CTE/CareerCoachTeacher/Home' },
  },
  {
    name: 'CTE/CareerCoachTeacher/Home',
    path: '/cte/career-coach-teacher/home',
    meta: { label: 'Student Roster', icon: 'fa-id-card'},
    component: TeacherHome
  },
  {
    name: 'CTE/CareerCoachTeacher/Reports',
    path: '/cte/career-coach-teacher/reports',
    meta: { label: 'Student Roster', icon: 'fa-chart-bar'},
    component: TeacherReportsPage
  },
  {
    name: 'CTE/CareerCoachTeacher/StudentPage',
     path: '/cte/career-coach-teacher/student-page/:studentId',
    meta: { label() { 
      return `Student Information - ${store.state.cte.careercoach.isLoadingStudent ? "Loading..." : store.state.cte.careercoach.currentStudent.fullName()}` 
    }, icon: 'fa-user'},
    component: LimitedStudentPage
  }
]
import ApiService from '../../services/ApiService'
import Moment from 'moment'
import { ESSA } from './essa'
import { Growth } from './growth'
import { TeacherGrowth } from './teacherGrowth'

export class AcademicsModule {
  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl =
      this._configElement == null
        ? '/'
        : this._configElement.getAttribute('data-base-url') || ''
    this._fiscalYear = this._configElement.getAttribute('data-fiscal-year')
  }

  namespaced = true

  state = {
    AMIFiscalYears: [],
    amiDayData: {
      dayCounts: [],
      dayAverages: [],
      days: [],
      approvals: []
    }
  }

  mutations = {
    setAMIDayData(state, data) {
      state.amiDayData = {
        dayCounts: data.dayCounts,
        dayAverages: data.dayAverages,
        days: data.days.map(d => {
          return {
            cycle: d.cycle,
            districtLEA: d.districtLEA,
            lea: d.lea,
            name: d.name,
            quarter: d.quarter,
            semester: d.semester,
            calendarDate: Moment(d.calendarDate).format('l'),
            membershipValue: d.membershipValue,
            dayType: d.dayType
          }
        }),
        buildingLevelSummary: data.buildingLevelSummary,
        districtLevelSummary: data.districtLevelSummary,
        approvals: data.districtAMIApprovals
      }
    },
    setAMIFiscalYears(state, data) {
      state.AMIFiscalYears = Array.from(data)
    }
  }

  actions = {
    getAMIDaysInformation: ({ commit }, payload) => {
      return this._apiService
        .Get(
          encodeURI(
            `${this._baseUrl}api/academics/${payload.lea}/ami/${payload.schoolYear}`
          )
        )
        .then(data => commit('setAMIDayData', data))
    },
    getAMIFiscalYears: ({ commit }) => {
      this._apiService
        .Get(encodeURI(`${this._baseUrl}api/academics/amiYears`))
        .then(data => commit('setAMIFiscalYears', data))
    }
  }

  modules = {
    essa: new ESSA(),
    growth: new Growth(),
    teacherGrowth: new TeacherGrowth()
  }
}

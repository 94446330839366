<template>
  <div class="container h-100">
    <div>
      <highcharts class="chart" :options="chartOptions" />
    </div>
  </div>
</template>

<script>
import { WaiverChartMixin } from './WaiverChartMixin'
const durationOptions = [
  { text: '1 Year', value: 1 },
  { text: '2 Years', value: 2 },
  { text: '3 Years', value: 3 },
]

export default {
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'column',
          height: this.$printMode ? 650 : 400
        },
        legend: {
          maxHeight: this.$printMode ? 450 : 200
        },
        credits: {
          text: "* Program totals may exceed Waiver Request totals because waivers may include multiple programs."
        },
        title: {
          text: `Approved Act 1240 Waiver Requests by Duration`
        },
        plotOptions: {
          column: {
          }
        },
        series: [],
        xAxis: {
          title: {
            text: this.xAxis
          },
          categories: ['Duration (School Years)']
        },
        yAxis: {
          title: {
            text: 'Count'
          },
          endOnTick: false
        }
      },
    }
  },
  props: {
    loadData: Promise,
    selectedWaiverType: Object
  },
  computed:{
    requestByStatus() {
      return this.waiverRequests.filter(wr => wr.waiverType == this.selectedWaiverType.value)
    },
    completedRequests() {
      return this.requestByStatus.filter(wr => wr.status == 101)
    },
  },
  watch: {
    selectedWaiverType(type) {
      this.$set(this.chartOptions.title, 'text', `Approved ${type.listTitle} by Duration`)
    },
    completedRequests: {
      immediate: true,
      handler() {
        const requests = (this.completedRequests || [])
        this.chartOptions.series = []
        this.chartOptions.series = durationOptions.map(duration => {
          return {
            name: duration.text,
            data: [requests.filter(wr => wr.durationSchoolYears == duration.value).length],
          }
        })
      }
    }
  },
  mixins: [WaiverChartMixin]
}
</script>
<template>
  <loadable-chart-card
    :load-data="loadData"
    :show-tooltip="type == 'sfa'"
    :tooltip-content="tooltipContent">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { BackgroundCheckRanges } from './BackgroundCheckRanges'
import { ChartMixin } from '../../ChartMixin'
import { mapGetters } from 'vuex'
import { credits } from '../../../helpers/credits'

function createPlotOptions(vm) {
  return {
    series: {
      cursor: 'pointer',
      events: {
        click() {
          vm.setCurrentRange(this.userOptions.field, this.color)
        }
      },
      marker: {
        enabled: true
      },
      minPointLength: 3
    }
  }
}

export default {
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'column'
        },
        credits: credits.SISandAELS,
        title: {
          text: this.getTitle()
        },
        subtitle: {
          text: this.getSubtitle(),
          style: {
            color: 'red'
          }
        },
        series: BackgroundCheckRanges,
        plotOptions: createPlotOptions(this),
        tooltip:{
          headerFormat: ''
        },
        xAxis: {
          title: {
            text: 'Last background check'
          },
          labels: {
            enabled: false
          }
        },
        yAxis: {
          title: {
            text: 'Number of background checks'
          },
          endOnTick: false
        }
      }
    }
  },
  mixins: [ChartMixin],
  computed: {
    ...mapGetters('hcptModule', ['filteredEmployabilityBackgroundChecks']),
    tooltipContent() {
      return this.type == 'sfa'
        ? '<p>Accreditation requires that district employees undergo all three (FBI, Arkansas State Police, and Central Registry) background checks within the space of one year.</p><p> This does not mean that the background checks had to occur within the last year, but that all three checks occurred within one year of all other checks.</p>'
        : ''
    }
  },
  props: {
    type: {
      type: String,
      default: 'normal'
    }
  },
  methods: {
    dataItems() {
      return BackgroundCheckRanges.map((range, i) => {
        return {
          Range: range.name,
          NumberBackgroundChecks: this.chartOptions.series[i].data[0]
        }
      })
    },
    fieldValue(item, range) {
      if (this.type == 'sfa') {
        return item[range.sfaField]
      }
      return item[range.field]
    },
    getTitle() {
      if (this.type == 'sfa') {
        return 'Years since most recent background check satisfying Accreditation Standard 4-A.1'
      }
      return 'Years since most recent background check'
    },
    getSubtitle() {
      if (this.type == 'sfa') {
        return 'Staff who have never had SFA background checks will fail the Accreditation Standard check'
      }
      return ''
    },
    setCurrentRange(selectedRange, currentColor) {
      this.$emit('range-selection-changed', selectedRange, currentColor)
      this.currentRange = selectedRange
      this.currentColor = currentColor
    },
    clearCurrentRange() {
      this.currentRange = ''
    }
  },
  watch: {
    filteredEmployabilityBackgroundChecks(checks) {
      var chartData = []
      BackgroundCheckRanges.forEach(range => {
        chartData.push({
          name: range.name,
          color: range.name == 'Never' ? 'red': undefined,
          field: this.type == 'sfa' ? range.sfaField : range.field,
          data: [
            checks
              .map(bgc => this.fieldValue(bgc, range))
              .reduce((acc, value) => (acc || 0) + (value || 0), 0)
          ]
        })
      })
      this.chartOptions.series = chartData
    }
  }
}
</script>

<template>
  <div>
    <a id="generalQuestions" class="anchor" />
    <pdf-button />
    <teacher-survey-excel-button title="Teacher Survey" />
    <h1>Teacher Survey</h1>
    <h2>
      <lea-display :lea="resolvedLea" />
    </h2>

    <teacher-survey-count :load-data="loadData" />

    <teacher-survey-counts-chart :load-data="loadData" v-if="resolvedLea.type == LeaTypes.State" />
    <teacher-survey-dates-chart :load-data="loadData" v-if="resolvedLea.type == LeaTypes.State" />

    <div>
      <!-- loop through sections -->
      <div
        class="card mt-3 bg-light page-break-before"
        v-for="(section, index) in getSections()"
        :key="section.target"
      >
        <a v-if="index > 0" :id="section.target" class="anchor" />
        <div class="card-header">
          <img :src="section.icon" v-if="section.icon" class="svg-icon">
          {{ section.name }}
        </div>
        <div class="card-body">
          <span v-if="section.faq && isDistrict" v-html="section.faq" />
          <div class="row">
            <div class="col-12">
              <survey-charts :aggregated-data="aggregateData" :questions="section.questions" :load-data="loadData" />
              <div
                v-for="(subsection, ssidx) in getSubsections(section)"
                :key="subsection.target"
                class="subsection mt-3"
              >
                <a :id="subsection.target" class="anchor" />
                <div class="card mt-3 bg-white" :class="{'page-break-before': ssidx > 0}">
                  <div class="card-header bg-secondary">
                    <object
                      type="image/svg+xml"
                      :data="subsection.icon"
                      v-if="subsection.icon"
                      class="svg-icon"
                    />
                    {{ subsection.name }}
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-12">
                        <survey-charts
                          :aggregated-data="aggregateData"
                          :questions="subsection.questions"
                          :load-data="loadData"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>

import { PageMixin } from '../../PageMixin'
import { mapGetters, mapState } from 'vuex'
import { surveyTemplate, VisibilityDirectives } from './surveyTemplate'
import { LeaTypes } from '../../../helpers/leaTypes'
import SurveyCharts from './surveyCharts'
import TeacherSurveyExcelButton from './teacherSurveyExcelButton'
import TeacherSurveyCountsChart from './teacherSurveyCountsChart'
import TeacherSurveyDatesChart from './teacherSurveyDatesChart'
import TeacherSurveyCount from './teacherSurveyCount'

import Vue from 'vue'
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)

export default {
  data() {
    return {
      loadData: Promise
    }
  },
  mixins: [PageMixin],
  computed: {
    sections() {
      return surveyTemplate.sections.filter(s => (s.hidden & VisibilityDirectives.hideOnAdmin) != VisibilityDirectives.hideOnAdmin)
    },
    ...mapGetters('globalModule', ['leaDictionary', 'coops']),
    ...mapGetters('surveys/teacherSurvey', ['aggregateData']),
    ...mapState('surveys/teacherSurvey', ['surveys']),
  },
  methods: {
    getSubsections(section) {
      if (section.sections) return section.sections.filter(s => (s.hidden & VisibilityDirectives.hideOnAdmin) != VisibilityDirectives.hideOnAdmin)
      else return null
    },
    getSections() {
      return this.sections.filter(s => !s.hideOnCharts)
    },
    excelExportData() {
      return {
        name: 'Teacher Survey',
        items: this.getSurveyExcel()
      }
    },
    getSurveyExcel() {
      return this.surveys.sort(this.compareSurveyForSort).map(s => this.mapSurvey(s))
    },
    compareSurveyForSort(a, b)
    {
      // sort by coop LEA and then by submit date
      let c = 0;
      if (a.coopLea > b.coopLea) c = 1;
      else if (a.coopLea < b.coopLea) c = -1
      else if (a.coopLea == b.coopLea) {
        if (Date(a.createdDate) > Date(b.createdDate)) c = 1;
        else if (Date(a.createdDate) < Date(b.createdDate)) c = -1
      }

      return c;
    },
    mapSurvey(s) {
      var lea = this.leaDictionary[s.coopLea]

      var survey = {
        'CoOp LEA': lea.number,
        'CoOp Name': lea.name,
        'Date': s.createdDate
      }

      surveyTemplate.sections.forEach(section => {
        // get questions and then get questions from each subsection
        if (section.questions) section.questions.filter(s => (s.hidden & VisibilityDirectives.hideOnExport) != VisibilityDirectives.hideOnExport).forEach(question => {
          this.appendSurveyValue(survey, question, s.responseData)
        })

        var subsections = section.sections
        if (subsections) subsections.forEach(subsection => {
          if (subsection.questions) subsection.questions.filter(s => (s.hidden & VisibilityDirectives.hideOnExport) != VisibilityDirectives.hideOnExport).forEach(question => {
            this.appendSurveyValue(survey, question, s.responseData)
          })
        })
      })

      return survey
    },
    appendSurveyValue(survey, question, response)
    {
      if (question.id == 'anyAdditionalComments') console.log(survey, question, response[question.id])

      if (question.component == "QuestionYesno" || question.component == "QuestionYesnoDetails")
      {       
        survey[question.text] = this.translateYesNoValue(response[question.id], question.indeterminateAnswerText)
        if (question.detailsOnValues) survey[question.textDetails] = response[question.id + 'Followup']
      }
      else if (question.component == "QuestionMultipleSelection" || question.component == "QuestionTagSelection")
      {
        survey[question.text] = (response[question.id] || []).join('; ')
        if (question.allowOther) {
          if (question.otherText) survey[question.otherText] = response[question.id + 'Followup']
          else survey[question.text + ' Other (please explain):'] = response[question.id + 'Followup']
        }
      }
      else if (question.component == 'QuestionRanking')
      {
        survey[question.text] = ((response[question.id] || [])[0]?.items.map(i => `${i.id}: ${i.name}`))?.join('; ')
      }
      else
      {
        survey[question.text] = response[question.id]
      } 
    },
    translateYesNoValue(response, indeterminateAnswerText) 
    {
      switch(response)
      {
        case 0: return "No"
        case 1: return "Yes"
        case 10: return indeterminateAnswerText
      }
      return "No answer"
    }
  },
  mounted() {
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        this.loading = true
        location.hash = ""
        if (newLea && newLea.number) {
          if (newLea.type == LeaTypes.School) {
            this.resolvedLea = this.leaDictionary[newLea.number].parentLea.parentLea
          }
          else if (newLea.type == LeaTypes.District) {
            this.resolvedLea = this.leaDictionary[newLea.number].parentLea
          }
          else this.resolvedLea = newLea
        
          var surveyPromise = this.$store.dispatch('surveys/teacherSurvey/getSurveys', { lea: this.resolvedLea.number }).then(() => {
            this.loading = false
          })

          this.loadData = Promise.all([surveyPromise])
        }
      }
    }
  },
  components: {
    SurveyCharts,
    TeacherSurveyExcelButton,
    TeacherSurveyCountsChart,
    TeacherSurveyCount,
    TeacherSurveyDatesChart
  }
}
</script>

<style type="scss">
.card-header > .svg-icon {
  filter: brightness(5004%);
  vertical-align: bottom;
}
</style>
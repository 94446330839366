<template>
  <div>
    <b-form>
      <b-form-row>
        <b-col cols="6">
          <b-form-group label-cols="4" label="Academic Year">
            <b-form-select :options="ayOptions" v-model="academicYear">
              <template #first>
                <b-form-select-option :value="null" disabled>
                  -- Academic Year --
                </b-form-select-option>
              </template>
            </b-form-select>    
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="6">
          <b-form-group label-cols="4" label="Grade" content-cols="2">
            <grade-selector @grade-changed="grade = $event.grade" />
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-form>
  </div>
</template>

<script>
import GradeSelector from '@/modules/cte/components/careerCoach/widgets/GradeSelector.vue'
import { metadata } from '@/modules/cte/stores/careercoach/metadata'

export default {
  components: { GradeSelector },
  data() {
    return {
      metadata,
      academicYear: null,
      topic: null,
      grade: null,
    }
  },
  computed: {
    topics() {
      var topics = metadata.visit.topics.slice()
      topics.splice(0, 0, {text: 'All Topics', value: null} )
      return topics
    }
  },
  methods: {
    onFilterChanged() {
      this.$emit('filter-changed', {grade: this.grade, topic: this.topic, academicYear: this.academicYear})      
    }
  },
  watch: {
    academicYear() {
      this.onFilterChanged()
    },
    topic() {
      this.onFilterChanged()
    },
    grade() {
      this.onFilterChanged()
    }
  },
  props: {
    ayOptions: Array
  }
}
</script>
<template>
  <div>
    <buses :show-action-column="true" :show-filter="showFilter">
      <template v-slot:action-column-content="data">
        <router-link :to="{name: 'Transportation/Bus/InspectionHistory', params: {districtLea: data.districtLea, busId: data.id }}" title="Inspection History" v-if="data.yearLastInspected">
          <i class="fa fa-clock" />
        </router-link>
      </template>
    </buses>
  </div>
</template>

<script>
import Buses from './Buses'

export default {
  name: 'bus-inventory',
  data() {
    return {
    }
  },
  props: {
    showFilter: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Buses
  }
}
</script>

export const gradeSpans = [{
    value: -1,
    text: 'Pre-K'
  },
  {
    value: 0,
    text: 'Kindergarten'
  },
]

for (var g = 1; g <= 12; g++) {
  gradeSpans.push({
    value: g,
    text: 'Grade ' + g.toString()
  })
}

export const gradeMap = new Map()
gradeSpans.forEach(item=>gradeMap.set(item.value, item.text))

export function gradeSpanFormatter(gradeLow, gradeHigh) {

  if (isNaN(gradeLow)) gradeLow = gradeStringToCode(gradeLow);
  if (isNaN(gradeHigh)) gradeHigh = gradeStringToCode(gradeHigh);
  
  const low = gradeSpans.find(t => t.value == gradeLow)
  const high = gradeSpans.find(t => t.value == gradeHigh)

  if (low && high) {
    return `${low.text} - ${high.text}`
  } else {
    return 'N/A'
  }

}

export function gradeCodeToString(code) {
  if (code == 255) return "All Grades"
  else return gradeMap.get(code)
}

export function gradeStringToCode(gradeString) {

    if (!gradeString) return null;

  if (gradeString.search(/pre-k/i) == 0) return -1;
  if (gradeString.match(/^p$/i)) return -1;
  if (gradeString.search(/k/i) == 0) return 0;

  var num = Number(gradeString);

  return isNaN(num) ? null : num
}
<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { SubpopulationsFactory } from '../../subpopulations'
import { credits } from '../../../helpers/credits'

export default {
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'line'
        },
        credits: credits.SIS,
        title: {
          text: this.chartTitle
        },
        series: SubpopulationsFactory(),
        xAxis: {
          title: {
            text: 'School Year'
          },
          type: 'category'
        },
        yAxis: {
          title: {
            text: '# Employeed'
          },
          endOnTick: false
        }
      }
    }
  },

  mixins: [ChartMixin],
  props: {
    staffData: Array,
    chartTitle: String
  },
  methods: {
    dataItems() {
      return (this.chartOptions.series || []).flatMap(series => {
        return series.data.map(d => {
          return {
            Type: d.type,
            SchoolYear: d.x,
            NumberEmployed: d.y
          }
        })
      })
    },
    excelExportData() {
      return {
        name: this.chartTitle,
        items: this.dataItems()
      }
    },
  },
  watch: {
    staffData(newData) {
      var dataSet = SubpopulationsFactory()

      for (var i = 0; i < dataSet.length; i++) {
        var seriesData = newData.map(row => {
          return {
            x: (row.fiscalYear % 1990) + 1990,
            y: row[dataSet[i].field],
            color: dataSet[i].color,
            type: dataSet[i].name
          }
        })
        dataSet[i].data = seriesData
      }
      this.chartOptions.series = dataSet
    }
  }
}
</script>

<template>
  <div class="card bg-light">
    <div class="card-header lead">
      Post-secondary Readiness
    </div>
    <b-tabs class="m-3" content-class="mt-1" pills>
      <b-tab title="All Students" active title-item-class="d-print-none">
        <div class="row">
          <div class="col-4 mx-0 pr-0">
            <single-metric-gauge-chart title="College Going Rate" metric-suffix="%" subgroup="All Students" :report-data="currentCollegeGoingRate" :load-data="loadData" />
          </div>
          <div class="col-8 mx-0 pl-0">
            <single-metric-schools-chart title="College Going Rate" metric-suffix="%" subgroup="All Students" :report-data="currentCollegeGoingRate" :load-data="loadData" />
          </div>
        </div>
        <div class="row page-break-before">
          <div class="col-4 mx-0 pr-0">
            <single-metric-gauge-chart title="College Credit Accumulation Rate" metric-suffix="%" subgroup="All Students" :report-data="currentCollegeCreditAccumulationRate" :load-data="loadData" />
          </div>
          <div class="col-8 mx-0 pl-0">
            <single-metric-schools-chart title="College Credit Accumulation Rate" metric-suffix="%" subgroup="All Students" :report-data="currentCollegeCreditAccumulationRate" :load-data="loadData" />
          </div>
        </div>
        <div class="row">
          <div class="col-4 mx-0 pr-0">
            <single-metric-gauge-chart title="Remediation Rate" metric-suffix="%" :report-data="currentRemediationRate" :load-data="loadData" />
          </div>
          <div class="col-8 mx-0 pl-0">
            <single-metric-schools-chart title="Remediation Rate" metric-suffix="%" :report-data="currentRemediationRate" :load-data="loadData" />
          </div>
        </div>
      </b-tab>
      <b-tab title="By Subpopulation" title-item-class="d-print-none">
        <div class="row" v-for="(demographic, index) in demographics" :key="'college' + demographic" :class="{ 'page-break-before': index % 2 == 0 }">
          <div class="col-4 mx-0 pr-0">
            <single-metric-gauge-chart title="College Going Rate" metric-suffix="%" :subgroup="demographic" :report-data="currentCollegeGoingRate" :load-data="loadData" :key="'college-' + demographic" />
          </div>
          <div class="col-8 mx-0 pl-0">
            <single-metric-schools-chart title="College Going Rate" metric-suffix="%" :subgroup="demographic" :report-data="currentCollegeGoingRate" :load-data="loadData" :key="'college-schools-' + demographic" />
          </div>
        </div>
        <div class="row" v-for="(demographic, index) in demographics" :key="'college-credit' + demographic" :class="{ 'page-break-before': index % 2 == 0 }">
          <div class="col-4 mx-0 pr-0">
            <single-metric-gauge-chart title="College Credit Accumulation Rate" metric-suffix="%" :subgroup="demographic" :report-data="currentCollegeCreditAccumulationRate" :load-data="loadData" :key="'college-credit-' + demographic" />
          </div>
          <div class="col-8 mx-0 pl-0">
            <single-metric-schools-chart title="College Credit Accumulation Rate" metric-suffix="%" :subgroup="demographic" :report-data="currentCollegeCreditAccumulationRate" :load-data="loadData" :key="'college-credit-schools-' + demographic" />
          </div>
        </div>
      </b-tab>
      <b-tab title="Trend" title-item-class="d-print-none">
        <div class="row page-break-before">
          <div class="col-12">
            <single-metric-schools-chart title="College Going Rate Trend" metric-suffix="%" subgroup="All Students" mode="trend" :report-data="collegeGoingRate" :load-data="loadData" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <single-metric-schools-chart title="College Credit Accumulation Rate Trend" metric-suffix="%" subgroup="All Students" mode="trend" :report-data="collegeCreditAccumulationRate" :load-data="loadData" />
          </div>
        </div>
        <div class="row page-break-before">
          <div class="col-12">
            <single-metric-schools-chart title="Remediation Rate Trend" metric-suffix="%" mode="trend" :report-data="remediationRate" :load-data="loadData" />
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Types } from '@/modules/esa/stores/survey'
import SingleMetricGaugeChart from '../SingleMetricGaugeChart.vue'
import SingleMetricSchoolsChart from '../SingleMetricSchoolsChart.vue'

const allStudents = ['All Students']
const demographics = [
  'African-American',
  'Caucasian',
  'Economically Disadvantaged',
  'English Learner',
  'Hispanic',
  // 'Migrant',
]
export default {
  data() {
    return { 
      loadData: Promise.resolve(),
      allStudents,
      demographics,
    }
  },
  computed: {
    ...mapState(Types.path, [
      Types.state.collegeGoingRate,
      Types.state.collegeCreditAccumulationRate,
      Types.state.remediationRate,
    ]),
    currentCollegeGoingRate() {
      const maxFy = this.getCurrentFiscalYear(this.collegeGoingRate)
      return this.collegeGoingRate.filter(d => d.fy == maxFy)
    },
    currentCollegeCreditAccumulationRate() {
      const maxFy = this.getCurrentFiscalYear(this.collegeCreditAccumulationRate)
      return this.collegeCreditAccumulationRate.filter(d => d.fy == maxFy)
    },
    currentRemediationRate() {
      const maxFy = this.getCurrentFiscalYear(this.remediationRate)
      return this.remediationRate.filter(d => d.fy == maxFy)
    },
  },
  components: {
    SingleMetricGaugeChart,
    SingleMetricSchoolsChart,
  },
  methods: {
    getCurrentFiscalYear(values) {
      if (values.length == 0) return 0
      return Math.max(...values.filter(m => m.lea == 'AR' && m.value > 0).map(m => m.fy))
    },
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if (newLea && newLea.number) {
          this.loadData = Promise.all([
            this.$store.dispatch(Types.actions.getCollegeGoingRate, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getCollegeCreditAccumulationRate, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getRememediationRate, { lea: newLea.number }),
          ])  
        }
      }
    }
  }
}

</script>

  
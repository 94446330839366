import Vue from 'vue'
import { InspectionStatuses } from '../../components/dpsaft/common/Enums'

export function batchSort (a, b) { 
  return Number(a.batchId) - Number(b.batchId)
}

export function inspectionSort (a, b) { 
  return Number(a.inspectionId) - Number(b.inspectionId)
}

export function buildingSort (a, b) { 

  const aBuildindNumber = a.building?.buildingNumber || ''
  const bBuildindNumber = b.building?.buildingNumber || ''

  console.log (aBuildindNumber, bBuildindNumber)

  if (isNaN(aBuildindNumber) || isNaN(bBuildindNumber)) {
    return aBuildindNumber.localeCompare(bBuildindNumber)
  } else {
    return Number(aBuildindNumber) - Number(bBuildindNumber)
  }
}

export function updateBatchHierarchy(state) {
  Vue.set(state.SelectedBatch, 'inspections', [...state.SelectedBatch.inspections.filter(i => i.inspectionId != state.SelectedInspection.inspectionId), state.SelectedInspection].sort(inspectionSort))
  Vue.set(state.SelectedBatch, 'totalInspections', state.SelectedBatch.inspections.length)
  Vue.set(state.SelectedBatch, 'completedInspections', state.SelectedBatch.inspections.filter(i => i.status == InspectionStatuses.Completed || i.status == InspectionStatuses.Skipped).length)
  state.inspectionBatches = [...state.inspectionBatches.filter(b => b.batchId != state.SelectedBatch.batchId), state.SelectedBatch].sort(batchSort)
}

export function setSelectedInspectionComponents(state, data) {
  const updatedComponents = [...(state.SelectedInspection.components || []).filter(c => c.id != data.id), data].sort((a, b) => Number(a.orderBy) - Number(b.orderBy))
  Vue.set(state.SelectedInspection, 'components', updatedComponents)
  updateBatchHierarchy(state)
}

export function setSelectedInspection(state, data) {
  if ((state.SelectedInspection || {}).inspectionId == data.inspectionId) {
    state.SelectedInspection = Object.assign(state.SelectedInspection, data)
    updateBatchHierarchy(state)
  }
}

<template lang="html">
  <div class="my-2">
    <div class="row">
      <div class="col-12">
        <div class="card bg-light">
          <div class="card-body">
            <b-form inline>
              <b-form-group label="School Federal Program Status" class="font-weight-bold">
                <b-form-select :options="status" class="w-100" v-model="selectedStatus" />
              </b-form-group> 
              <b-form-group label="Source of funds" class="mx-1 font-weight-bold">
                <b-form-select :options="funds" class="w-100" v-model="selectedFundSource" />
              </b-form-group>
              <b-form-group label="Paraprofessional Qualifications" class="mx-1 font-weight-bold">
                <b-form-select :options="qualifications" class="w-100" v-model="selectedQualification" />
              </b-form-group>
              <b-form-group label="Job Code" class="mx-1 font-weight-bold">
                <b-form-select :options="jobCodes" class="w-100" v-model="selectedJobCode" />
              </b-form-group>
              <b-form-group label="Criteria">
                <b-form-checkbox-group class="font-weight-bold">
                  <b-form-checkbox value="1" v-model="criteria">
                    1
                  </b-form-checkbox> 
                  <b-form-checkbox value="2" v-model="criteria">
                    2
                  </b-form-checkbox> 
                  <b-form-checkbox value="3" v-model="criteria">
                    3
                  </b-form-checkbox> 
                  <b-form-checkbox value="4" v-model="criteria">
                    4
                  </b-form-checkbox> 
                  <b-form-checkbox value="5" v-model="criteria">
                    5
                  </b-form-checkbox> 
                </b-form-checkbox-group>
              </b-form-group>
              <b-form-group class="mx-1" style="margin-top: 28px;">
                <b-button-group>
                  <b-button variant="primary" @click="setFilter">
                    <i class="fa fa-filter" aria-hidden="true" /> Filter
                  </b-button>
                  <b-button variant="warning" title="Clear Filter" @click="clearFilter">
                    <i class="fa fa-undo" aria-hidden="true" />
                  </b-button>
                </b-button-group>
              </b-form-group>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Types } from '@/stores/paraprofessionalModule.js'

const defaultFilter = {
  selectedStatus: null,
  selectedFundSource: null,
  selectedQualification: null,
  selecetedJobCode: null,
  criteria: [],
}

export default {
  data() {
    return {
      selectedStatus: null,
      selectedFundSource: null,
      selectedQualification: null,
      selectedJobCode: null,
      criteria: [],
      status: [
        {value: null, text: 'All Statuses'},
        {value: 1, text: 'Title I Schoolwide (SW)'},
        {value: 2, text: 'Title I Targeted Assitance (TA)'},
        {value: 3, text: 'Not Applicable (NA)'}
      ],
      funds: [
        {value: null, text: 'All Fund Sources'},
        {value: 0, text: 'Other'},
        {value: 1, text: 'Teacher Salary'},
        {value: 2, text: 'Operating'},
        {value: 3, text: 'Building'},
        {value: 6, text: 'Federal'},
        {value: 7, text: 'Activity'},
        {value: 8, text: 'Food Service'}
      ],
      qualifications: [
        {value: null, text: 'All Qualification Levels'},
        {value: 2, text: '60 college hours'},
        {value: 3, text: 'Associate Degree'},
        {value: 4, text: 'Bachelor Degree'},
        {value: 7, text: 'CDA only works in preschool'},
        {value: 8, text: 'Certificate of Proficiency'},
        {value: 6, text: 'In the process of becoming a highly qualified paraprofessional'},
        {value: 5, text: 'Not a highly qualified paraprofessional'},
        {value: 1, text: 'Praxis Examination'}
      ],
      jobCodes: [
        {value: null, text: 'All Job Codes'},
        {value: 758, text: '758: Parapro Instr Title I SW'},
        {value: 759, text: '759: Parapro Instr Title I TA'},
        {value: 760, text: '760: Parapro Non Instructional'},
        {value: 761, text: '761: Personal Care Aide'},
        {value: 762, text: '762: Parapro Instr Special EDU'},
        {value: 763, text: '763: Parapro Instr No Title I'}
      ]
    }
  },
  methods: {
    setFilter() {
      this.$store.commit(Types.mutations.setFilter, {
        selectedStatus: this.selectedStatus,
        selectedFundSource: this.selectedFundSource,
        selectedQualification: this.selectedQualification,
        selectedJobCode: this.selectedJobCode,
        criteria: this.criteria,
      })
    },
    clearFilter() {
      this.$store.commit(Types.mutations.setFilter, defaultFilter)
      this.selectedStatus = null
      this.selectedFundSource = null
      this.selectedQualification = null
      this.selecetedJobCode = null
      this.criteria = []
    }
  }
}
</script>

<style>
.w-10 {
  width: 10%;
}
</style>

<template>
  <div>
    <b-row>
      <b-col md="4">
        <waiver-requests-by-status-chart v-bind="$attrs" />
      </b-col>
      <b-col md="4">
        <waiver-requests-approved-vs-reviewed-chart v-bind="$attrs" />
      </b-col>
      <b-col md="4">
        <waiver-requests-approved-by-duration-chart v-bind="$attrs" />
      </b-col>
    </b-row>    
  </div>
</template>
<script>

import WaiverRequestsByStatusChart from './WaiverRequestsByStatusChart'
import WaiverRequestsApprovedVsReviewedChart from './WaiverRequestsApprovedVsReviewedChart'
import WaiverRequestsApprovedByDurationChart from './WaiverRequestsApprovedByDurationChart'

export default {
  data() {
    return {}
  },
  components: {
    WaiverRequestsByStatusChart,
    WaiverRequestsApprovedVsReviewedChart,
    WaiverRequestsApprovedByDurationChart,
  }
}
</script>
import ApiService from '../services/ApiService'

export class TeacherVAMModule {

  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
  }

  namespaced = true

  state = {
    loadedYears: [],
    fiscalYear: null,
    teacherVAMData: null,
    stateSummary: null,
    districtSummary: [],
    filter: null
  }
  getters = {
    districtSummaries: (state) => {
      return state.districtSummary.map(d => {
        return [d.lea, d.percentHighImpact]
      })
    },
    detailsBySubject: (state) => {
      return state.teacherVAMData != null ?
        state.teacherVAMData
          .filter(d => d.grade == null)
          .reduce((acc, detail) => {
          const subject = detail.subject
          if (!acc[subject]) {
            acc[subject] = []
          }
          acc[subject].push(detail)
          return acc
        }, {})
      : []
    },
    detailsBySubjectWithGrade: (state) => {
      return state.teacherVAMData != null ?
        state.teacherVAMData
          .reduce((acc, detail) => {
          const subject = detail.subject
          if (!acc[subject]) {
            acc[subject] = []
          }
          acc[subject].push(detail)
          return acc
        }, {})
      : []
    },
    summaryBySubjectAndGrade: (state, getters) => {
      return Object.keys(getters.detailsBySubjectWithGrade).map(subject =>
      {
        const grades = getters.detailsBySubjectWithGrade[subject].filter(r => r.numberStudents >= 10).reduce((acc, curr) => {
          acc[curr.grade] = acc[curr.grade] || []
          acc.total = acc.total || 0
          acc[curr.grade].push(curr)
          acc.total++
          return acc
        }, {})
        const gradesMap = Object.keys(grades).filter(grade => grade != 'total').map(grade => {
          return { grade: grade == 'undefined' ? 'All' : grade, scores: grades[grade].map(g => g.valueAddedScore) }
        })

        gradesMap.forEach(grade => {
          grade.n = grade.scores.length
          grade.mean = grade.scores.reduce((acc, curr) => acc + curr, 0) / grade.n
          grade.stdDev = Math.sqrt(grade.scores.reduce((acc, curr) => acc + Math.pow(curr - grade.mean, 2), 0) / grade.n)
          grade.stdErr = grade.stdDev / Math.sqrt(grade.n)
          grade.oneStdDevBelow = grade.mean - grade.stdDev
          grade.oneStdDevAbove = grade.mean + grade.stdDev
          grade.lowerBoundOf80 = 80 - (1.96 * grade.stdErr)
          grade.scores = undefined
        })

        return { subject, grades: gradesMap }
      })
    },
    cutScores: (state) => (subject, grade) => {
      console.log(subject, grade)
      const data = (state.stateSummary || [{}])[0]

      // using gradeData, calculate the cut scores.  An array with objects, each objcect has a rating a min and a max.
      // Rating: '1' or 'Well Below Effective' is from the lowest value to one standard deviation below the mean
      // Rating: '2' or 'Below Effective' is from one standard deviation below the mean to the lower bound of 80% of the scores
      // Rating: '3' or 'Effective' is from the lower bound of 80% of the scores to one standard deviation above the mean
      // Rating: '4' or 'High Impact' is from one standard deviation above the mean to the highest value
      return [
        { rating: '1', min: 0, max: data.percentile95 },
        { rating: '2', min: data.percentile95, max: 200 },
        // { rating: '3', min: data.lowerBoundOf80, max: data.oneStdDevAbove },
        // { rating: '4', min: data.oneStdDevAbove, max: 200 }
      ]
    }
  }
  mutations = {
    setLoadedYears: (state, payload) => {
      state.loadedYears = payload
    },
    setTeacherVAMData: (state, payload) => {
      state.teacherVAMData = payload
    },
    setFiscalYear: (state, payload) => {
      state.fiscalYear = payload
    },
    setStateSummary: (state, payload) => {
      state.stateSummary = payload
    },
    setDistrictSummary: (state, payload) => {
      state.districtSummary = payload
    },
    setFilter: (state, payload) => {
      state.filter = payload
    }
  }

  actions = {
    getFiscalYears: ({ commit }) => {
      return this._apiService.Get(`${this._baseUrl}api/teacher-vam/fiscalYears`)
        .then(data => commit('setLoadedYears', data))
    },
    getTeacherVAMData: ({ commit }, payload) => {
      commit('setTeacherVAMData', null)
      commit('setFiscalYear', payload.fiscalYear)
      return this._apiService.Get(`${this._baseUrl}api/teacher-vam/${payload.fiscalYear}/${payload.lea}`)
        .then(data => {
          if (data != "Not authorized to view data for this LEA")
            commit('setTeacherVAMData', data)
        })
    },
    getStateSummary: ({ commit }, payload) => {
      commit('setStateSummary', null)
      return this._apiService.Get(`${this._baseUrl}api/teacher-vam/${payload.fiscalYear}`)
        .then(data => commit('setStateSummary', data))
    },
    getDistrictSummary: ({ commit }, payload) => {
      commit('setDistrictSummary', [])
      return this._apiService.Get(`${this._baseUrl}api/teacher-vam/district/${payload.fiscalYear}`)
        .then(data => commit('setDistrictSummary', data))
    }
  }
}
<template>
  <div class="classroom-detail">
    <Promised :promise="loading">
      <template v-slot:pending>
        <loading-spinner />
      </template>
      <template v-slot>
        <div v-if="classroomDetail && teacherDetail">
          <div class="row">
            <div class="col">
              <div>
                <h1>{{ classroomDetail.teacherSummary.firstName }} {{ classroomDetail.teacherSummary.lastName }}</h1>
              </div>
              <h2><lea-display :lea="getLEA" /></h2>
            </div>
            <div class="col-auto font-weight-bold h5">
              <div v-if="!teacherDetail.canEdit || !subject || !anyVerifiableStudents">
                <i class="fas fa-lock" />
              </div>
            </div>
          </div>
        
          <div class="card mt-3">
            <div class="card-body card-body-secondary">
              <div class="row">
                <div class="col-6">
                  <dl class="row">
                    <dt class="col-sm-3">
                      Course Description
                    </dt>
                    <dd class="col-sm-9">
                      {{ courseDescription }}
                    </dd>
                    <dt class="col-sm-3">
                      Course Code
                    </dt>
                    <dd class="col-sm-9">
                      {{ courseCode }}
                    </dd>
                    <dt class="col-sm-3">
                      Course Section
                    </dt>
                    <dd class="col-sm-9">
                      {{ courseSection }}
                    </dd>
                    <dt class="col-sm-3" v-if="subject">
                      Subject
                    </dt>
                    <dd class="col-sm-9" v-if="subject">
                      <subject-tag :subject="subject" />
                    </dd>
                  </dl>
                </div>
                <div class="col-6">
                  <dl class="row">
                    <dt class="col-sm-3">
                      Primary Teacher
                    </dt>
                    <dd class="col-sm-9">
                      {{ primaryTeacher }}
                    </dd>
                    <!-- <dt class="col-sm-3">
                      <span class="ml-3">Rating</span>
                    </dt>
                    <dd class="col-sm-9">
                      {{ primaryTeacherRating }} <span v-if="primaryTeacherRating != '-'">({{ primaryTeacherRatingYear }})</span>
                    </dd> -->
                    <dt class="col-sm-3">
                      Secondary Teacher
                    </dt>
                    <dd class="col-sm-9">
                      {{ secondaryTeacher }}
                    </dd>
                    <!-- <dt class="col-sm-3">
                      <span class="ml-3">Rating</span>
                    </dt>
                    <dd class="col-sm-9">
                      {{ secondaryTeacherRating }} <span v-if="secondaryTeacherRating != '-'">({{ secondaryTeacherRatingYear }})</span>
                    </dd> -->
                  </dl>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <b-card no-body header-class="header-padding-small">
              <template #header>
                <b-row no-gutters>
                  <b-col class="header-padding-normal">
                    Students
                  </b-col>
                  <b-col cols="auto" style="padding-top: 0.25rem; padding-right: 0.75rem;" v-if="teacherDetail.canEdit && subject && anyVerifiableStudents">
                    <b-dropdown right size="sm" class="m-1" variant="light">
                      <template #button-content>
                        Actions
                      </template>
                      <!-- <b-dropdown-item href="#" @click="setRoleIdentificationForAll">
                        Set <b>Role Assignment Identification</b> for all Students
                      </b-dropdown-item> -->
                      <!-- <b-dropdown-item href="#" @click="setPercentageOfInstructionForAll">
                            Set Percentage of Instruction for all Students
                          </b-dropdown-item> -->
                      <b-dropdown-item href="#" @click="setIncludeForVAMForAll">
                        Set <b>Provided Instruction in Subject Area</b> for all Students
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>        
                </b-row>
              </template>  
              <div class="card-body card-body-secondary">
                <paginated-table :items="students" :fields="studentFields" sort-by="lastName" :responsive="true" sort-asc :per-page="30" striped hover small :load-data="loading">
                  <template v-slot:cell(percentageOfInstruction)="row">
                    <b-form-select
                      v-if="teacherDetail.canEdit"
                      v-model="row.item.percentageOfInstruction"
                      :options="percentageOfInstructionOptions"
                      @change="row.item.isComplete = -1; valueChanged('percentageOfInstruction', row.item)" />
                    <span v-else>
                      {{ getPercentageOfInstructionValue(row.item.percentageOfInstruction) }}
                    </span>
                  </template>
                  <template v-slot:cell(roleIdentification)="row">
                    <!-- <b-form-select
                      v-if="teacherDetail.canEdit"
                      v-model="row.item.roleIdentification"
                      :options="roleIdentificationOptions"
                      @change="row.item.isComplete = -1; valueChanged('roleIdentification', row.item)" /> -->
                    <!-- <span v-else> -->
                    <span>
                      {{ getRoleIdentificationValue(row.item.roleIdentification) }}
                    </span>
                  </template>
                  <template v-slot:cell(notes)="row">
                    <div v-if="teacherDetail.canEdit">
                      <b-textarea
                        v-model="row.item.notes"
                        @update="row.item.isComplete = -1; notesChanged(row.item)"
                        :placeholder="isNoteMissing(row.item)"
                        class="dangerPlaceholder" />
                    </div>                    
                    <span v-else>
                      {{ row.item.notes }}
                    </span>
                  </template>
                  <template v-slot:cell(includeForVAM)="row">
                    <b-form-select
                      v-if="teacherDetail.canEdit"
                      v-model="row.item.includeForVAM"
                      :options="trueFalseOptions"
                      @change="row.item.isComplete = -1; valueChanged('includeForVAM', row.item)" />
                    <span v-else>
                      {{ getTrueFalseValue(row.item.includeForVAM) }}
                    </span>
                  </template>
                  <!-- <template v-slot:cell(gt)="row">
                    <span v-if="row.item.gt == 1">
                      <i class="fas fa-check text-success" title="True" />
                    </span>
                    <span v-else>
                      <i class="fas fa-times text-secondary" title="False" />
                    </span>
                  </template>
                  <template v-slot:cell(sped)="row">
                    <span v-if="row.item.sped == 1">
                      <i class="fas fa-check text-success" title="True" />
                    </span>
                    <span v-else>
                      <i class="fas fa-times text-secondary" title="False" />
                    </span>
                  </template>
                  <template v-slot:cell(handicap)="row">
                    <span v-if="row.item.handicap == 1">
                      <i class="fas fa-check text-success" title="True" />
                    </span>
                    <span v-else>
                      <i class="fas fa-times text-secondary" title="False" />
                    </span>
                  </template> -->
                  <template v-slot:cell(subject)="data">
                    <subject-tag :subject="data.value" />
                  </template>
                  <template v-slot:cell(isComplete)="row">
                    <span v-if="row.item.isComplete == 1">
                      <i class="fas fa-fw fa-check text-success" v-b-tooltip.hover.left="'Complete'" />
                    </span>
                    <span v-else-if="row.item.isComplete == 0">
                      <i class="fas fa-fw fa-times text-secondary" v-b-tooltip.hover.left="'Incomplete'" />
                    </span>
                    <span v-else-if="row.item.isComplete == -1">
                      <i class="fas fa-fw fa-spinner fa-spin" v-b-tooltip.hover.left="'Please wait...'" />
                    </span>
                  </template>
                </paginated-table>
              </div>
            </b-card>
          </div>
        </div>
        <div v-else>
          <loading-spinner />
        </div>
      </template>
      <template v-slot:rejected="error">
        <div class="alert alert-danger" role="alert">
          {{ error }}
        </div>
      </template>
    </Promised>

    <!-- <b-modal id="setRoleIdentification" @shown="$refs.roleIdentification.focus()" @ok="doSetRoleIdentificationAll" v-if="subject && anyVerifiableStudents">
      <template #modal-title>
        Set Role Assignment Identification for All Students
      </template>
      <template #default>
        <div class="d-block text-center">
          <div class="mb-3">
            <b-form-select
              ref="roleIdentification"
              v-model="modalRoleIdentification"
              :options="roleIdentificationOptions"
            />
          </div>
        </div>
      </template>
    </b-modal> -->

    <!-- <b-modal id="setPercentageOfInstruction" @shown="$refs.percentageOfInstruction.focus()" @ok="doSetPercentageOfInstructionAll" v-if="subject && anyVerifiableStudents">
      <template #modal-title>
        Set Percentage of Instruction for All Students
      </template>
      <template #default>
        <div class="d-block text-center">
          <div class="mb-3">
            <b-form-select
              ref="percentageOfInstruction"
              v-model="modalPercentageOfInstruction"
              :options="percentageOfInstructionOptions"
            />
          </div>
        </div>
      </template>
    </b-modal> -->

    <b-modal id="setIncludeForVAM" @shown="$refs.includeForVAM.focus()" @ok="doSetIncludeForVAMForAll" v-if="subject && anyVerifiableStudents">
      <template #modal-title>
        Set <b>Provided Instruction in Subject Area</b> for All Students
      </template>
      <template #default>
        <div class="d-block text-center">
          <div class="mb-3">
            <b-form-select
              ref="includeForVAM"
              v-model="modalIncludeForVAM"
              :options="trueFalseOptions"
            />
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Promised } from 'vue-promised';
import LoadingSpinner from '@/components/LoadingSpinner'
import PaginatedTable from '@/components/PaginatedTable'
import LeaDisplay from '@/components/LeaDisplay'
import SubjectTag from './SubjectTag.vue'
import { debounce } from '@/helpers/globalHelpers'

//import { parseRaceData, parseMealStatus, parseInstructionalChoice } from '@/modules/rvs/helper.js'
import { parseRaceData, parseInstructionalChoice } from '@/modules/rvs/helper.js'

async function notesChangedDebounced(item) {
  this.$nextTick().then(async () => {
    await this.$store.dispatch('rvsModule/updateStudentValues', { snapshotIds: [item.snapshotId], key: 'Notes', value: item.notes })
    .then(async () => {
          await this.$store.dispatch('rvsModule/getClassroomDetail', { noClear: true, teacherId: this.$route.params.teacherId, lea: this.$route.params.lea, id: this.$route.params.id, section: this.$route.params.section, teacherType: this.$route.params.teacherType, sectionKey: this.$route.params.sk })
        })
  })
}

export default {
  data() {
    return {
      loading: Promise.resolve(true),
      percentageOfInstructionOptions: [
        { value: null, text: '(no value selected)' },
        { value: 999, text: '0%'},
        { value: 20, text: '20%' },
        { value: 30, text: '30%' },
        { value: 40, text: '40%' },
        { value: 50, text: '50%' },
        { value: 60, text: '60%' },
        { value: 70, text: '70%' },
        { value: 80, text: '80%' },
        { value: 90, text: '90%' },
        { value: 100, text: '100%' }
      ],
      roleIdentificationOptions: [
        { value: null, text: '(no value selected)' },
        { value: 1, text: 'Primary Teacher' },
        { value: 2, text: 'Secondary Teacher' },
        { value: 3, text: 'Contributing Teacher' },
        { value: 999, text: 'None - (Remove from Roster)'}
      ],
      trueFalseOptions: [
        { value: null, text: '(no value)' },
        { value: false, text: 'No' },
        { value: true, text: 'Yes' }
      ],
      students: [],
      modalRoleIdentification: null,
      modalPercentageOfInstruction: null,
      modalIncludeForVAM: null,
    }
  },
  components: {
    Promised,
    LoadingSpinner,
    PaginatedTable,
    LeaDisplay,
    SubjectTag,
  },
  computed: {   
    ...mapState('rvsModule', ['classroomDetail', 'teacherDetail', 'fiscalYear']),
    studentFields() {
      var fields = [
        { key: 'studentId', label: 'Student ID', stickyColumn: true },
        { key: 'lastName', label: 'Last', sortable: true, stickyColumn: true },
        { key: 'firstName', label: 'First', sortable: true, stickyColumn: true },
        { key: 'middleName', label: 'Middle' },
        { key: 'gradeLevel', label: 'Grade Level' },
        { key: 'subject', label: 'Subject', sortable: true },
        { key: 'raceEthnicity', label: 'Race/Ethnicity', formatter: (value) => parseRaceData(value) },
        //{ key: 'mealStatus', label: 'Meal Status', sortable: true, formatter: (value) => parseMealStatus(value) },
        { key: 'instructionalChoice', label: 'Instructional Choice', sortable: true, formatter: (value) => parseInstructionalChoice(value) },
        // { key: 'gt', label: 'GT', sortable: true },
        // { key: 'sped', label: 'SPED', sortable: true },
        // { key: 'handicap', label: '504', sortable: true },
      ]

      if (this.subject && this.anyVerifiableStudents){
        fields = fields.concat([
          { key: 'roleIdentification', label: 'Role Assignment Identification', sortable: true },
          //{ key: 'percentageOfInstruction', label: 'Percentage of Instruction', sortable: true },
          { key: 'includeForVAM', label: 'Provided Instruction in Subject Area', sortable: true },
          { key: 'notes', label: 'Notes' },
          { key: 'isComplete', label: '', sortable: false }
        ])
      }

      return fields
    },
    getLEA() {
      return this.leaDictionary[this.classroomDetail.teacherSummary.schoolLEA]
    },
    courseDescription() {
      return this.classroomDetail?.classSummaries ? this.classroomDetail?.classSummaries[0].courseDescription : ''
    },
    courseCode() {
      return this.classroomDetail?.classSummaries ? this.classroomDetail?.classSummaries[0].courseCode : ''
    },
    courseSection() {
      return this.classroomDetail?.classSummaries ? this.classroomDetail?.classSummaries[0].courseSection : ''
    },
    subject() {
      return this.classroomDetail?.classSummaries ? this.classroomDetail?.classSummaries[0].subject : ''
    },
    primaryTeacher() {
      var teacher = this.classroomDetail.classSummaries ? this.classroomDetail?.classSummaries.filter(s => s.teacherType == 1) : null
      return teacher && teacher[0] ? `${teacher[0].firstName} ${teacher[0].lastName}` : '(none)'
    },
    secondaryTeacher() {
      var teacher = this.classroomDetail.classSummaries ? this.classroomDetail?.classSummaries.filter(s => s.teacherType == 2) : null
      return teacher && teacher[0] ? `${teacher[0].firstName} ${teacher[0].lastName}` : '(none)'
    },
    primaryTeacherRating() {
      var teacher = this.classroomDetail.classSummaries ? this.classroomDetail?.classSummaries.filter(s => s.teacherType == 1) : null
      return teacher && teacher[0] ? teacher[0].effectivenessRating || '-' : '-'
    },
    secondaryTeacherRating() {
      var teacher = this.classroomDetail.classSummaries ? this.classroomDetail?.classSummaries.filter(s => s.teacherType == 2) : null
      return teacher && teacher[0] ? teacher[0].effectivenessRating || '-' : '-'
    },
    primaryTeacherRatingYear() {
      var teacher = this.classroomDetail.classSummaries ? this.classroomDetail?.classSummaries.filter(s => s.teacherType == 1) : null
      return teacher && teacher[0] ? teacher[0].effectivenessYear || '-' : '-'
    },
    secondaryTeacherRatingYear() {
      var teacher = this.classroomDetail.classSummaries ? this.classroomDetail?.classSummaries.filter(s => s.teacherType == 2) : null
      return teacher && teacher[0] ? teacher[0].effectivenessYear || '-' : '-'
    },
    anyVerifiableStudents() {
      return this.classroomDetail?.classSummaries ? this.classroomDetail.classSummaries[0].totalVerifiableStudents > 0 : false
    }
  },
  props: {
    
  },
  methods: {
    async reloadData() {
      if (this.fiscalYear)
        this.loading = Promise.all([
          this.$store.dispatch('rvsModule/getClassroomDetail', { teacherId: this.$route.params.teacherId, lea: this.$route.params.lea, id: this.$route.params.id, section: this.$route.params.section, teacherType: this.$route.params.teacherType, sectionKey: this.$route.params.sk }),
          this.$store.dispatch('rvsModule/getTeacherDetail', { id: this.$route.params.teacherId, lea: this.$route.params.lea })
        ])
    },
    async valueChanged(key, item) {
      this.$nextTick().then(async () => {
        await this.$store.dispatch('rvsModule/updateStudentValues', { snapshotIds: [item.snapshotId], key: key, value: item[key] })
        .then(async () => {
          await this.$store.dispatch('rvsModule/getClassroomDetail', { noClear: true, teacherId: this.$route.params.teacherId, lea: this.$route.params.lea, id: this.$route.params.id, section: this.$route.params.section, teacherType: this.$route.params.teacherType, sectionKey: this.$route.params.sk })
        })
      })
    },
    notesChanged: debounce(notesChangedDebounced, 500),
    getPercentageOfInstructionValue(value) {
      return this.percentageOfInstructionOptions.find(x => x.value === value).text
    },
    getRoleIdentificationValue(value) {
      return this.roleIdentificationOptions.find(x => x.value === value).text
    },
    setRoleIdentificationForAll() {
      this.modalRoleIdentification = null
      this.$bvModal.show('setRoleIdentification')
    },
    setPercentageOfInstructionForAll() {
      this.modalPercentageOfInstruction = null
      this.$bvModal.show('setPercentageOfInstruction')
    },
    setIncludeForVAMForAll() {
      this.modalIncludeForVAM = null
      this.$bvModal.show('setIncludeForVAM')
    },
    setAllStudentsWait() {
      this.students.forEach(async student => {
        student.isComplete = -1
      })
    },
    async doSetRoleIdentificationAll() {
      this.setAllStudentsWait()
      await this.$store.dispatch('rvsModule/updateStudentValues', { snapshotIds: this.students.map(s => s.snapshotId), key: 'roleIdentification', value: this.modalRoleIdentification })
      .then(async () => {
          await this.$store.dispatch('rvsModule/getClassroomDetail', { noClear: true, teacherId: this.$route.params.teacherId, lea: this.$route.params.lea, id: this.$route.params.id, section: this.$route.params.section, teacherType: this.$route.params.teacherType, sectionKey: this.$route.params.sk })
        })
    },
    async doSetPercentageOfInstructionAll() {
      this.setAllStudentsWait()
      await this.$store.dispatch('rvsModule/updateStudentValues', { snapshotIds: this.students.map(s => s.snapshotId), key: 'percentageOfInstruction', value: this.modalPercentageOfInstruction })
      .then(async () => {
          await this.$store.dispatch('rvsModule/getClassroomDetail', { noClear: true, teacherId: this.$route.params.teacherId, lea: this.$route.params.lea, id: this.$route.params.id, section: this.$route.params.section, teacherType: this.$route.params.teacherType, sectionKey: this.$route.params.sk })
        })
    },
    async doSetIncludeForVAMForAll() {
      this.setAllStudentsWait()
      await this.$store.dispatch('rvsModule/updateStudentValues', { snapshotIds: this.students.map(s => s.snapshotId), key: 'includeForVAM', value: this.modalIncludeForVAM })
      .then(async () => {
          await this.$store.dispatch('rvsModule/getClassroomDetail', { noClear: true, teacherId: this.$route.params.teacherId, lea: this.$route.params.lea, id: this.$route.params.id, section: this.$route.params.section, teacherType: this.$route.params.teacherType, sectionKey: this.$route.params.sk })
        })
    },
    getTrueFalseValue(value) {
      switch(value) {
        case false:
        case 0:
          return 'No'
        case true:
        case 1:
          return 'Yes'
        default:
          return '(no value)'
      }
    },
    isNoteMissing(row)
    {
      if ((row.roleIdentification === 999) && (!row.notes || row.notes.trim() === ""))
        return "(A note is required for removed students.)"
    }
  },
  watch: {
    fiscalYear: {
      handler() {
        this.reloadData()
      }
    },
    selectedLea: {
      immediate: true,
      handler() {
        this.reloadData()
      }
    },
    classroomDetail: {
      immediate: true,
      handler() {
        if (this.classroomDetail) {
          this.students = this.classroomDetail.students.map(student => {
            return {
              ...student,
              percentageOfInstruction: student.percentageOfInstruction || null,
              roleIdentification: student.roleIdentification || null,
              includeForVAM: student.includeForVAM === undefined ? null : student.includeForVAM,
            }
          })
        }
      }
    },
  }
}

</script>

<style lang="scss" scoped>
.header-padding-small {
  padding: 0rem;
}
.header-padding-normal {
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
}
.dangerPlaceholder::placeholder {
  color: #dc3545;
  font-style: italic;
  font-size: smaller;
}
</style>
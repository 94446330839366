<template>
  <div class="d-print-none">
    <b-form>
      <b-form-row>
        <b-col v-if="userContext.isADEUser">
          <b-form-group label="Co-op">
            <b-select :options="coopOptions" v-model="coop" />
          </b-form-group>
        </b-col>
        <b-col v-if="userContext.isADEUser || userContext.hasRole('CoOp Director')">
          <b-form-group label="District">
            <b-select :options="districtOptions" v-model="district" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Start Date">
            <b-form-datepicker v-model="startDate" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="End Date">
            <b-form-datepicker v-model="endDate" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <b-button :variant="filterChanged ? 'primary' : 'secondary'" @click="search" :disabled="!filterChanged" style="margin-top: 30px;">
              <i class="fa fa-search fa-fw" /> Search
            </b-button>
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-form>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'closures-filter',

  data() {
    return {
      filterChanged: false,
      coop: null,
      district: null,
      startDate: null,
      endDate: null
    }
  },
  computed: {
    ...mapGetters('globalModule', ['coops', 'districts']),
    ...mapState('globalModule', ['userContext']),
    coopOptions() {
      return [
        { value: null, text: '(All Coops)' },
        ...this.coops.map(c => {
          return { value: c.number, text: c.number == '9999000' ? 'Pulaski County Schools / Charter Schools (9999000)' : `${c.name} (${c.number})` }
        })
      ]
    },
    districtOptions() {
      return [
        { value: null, text: '(All Districts)' },
        ...this.districts(this.coop || this.coopDirector).map(c => {
          return { value: c.number, text: `${c.name} (${c.number})` }
        })
      ]
    },
    coopDirector() {
      return this.userContext.coopDirector
    }
  },
  methods: {
    search() {
      this.filterChanged = false
      this.$emit('input', { lea: this.district || this.coop || 'AR', startDate: this.startDate, endDate: this.endDate })
    }
  },
  watch: {
    coop() { 
      this.district = null
      this.filterChanged = true 
    },
    district() { this.filterChanged = true },
    startDate() { this.filterChanged = true },
    endDate() { this.filterChanged = true },
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        this.startDate = val.startDate,
        this.endDate = val.endDate
      }
    }
  },
  props: {
    value: Object,
    initialDistrict: String
  },
  mounted() {
    this.district = this.initialDistrict
  }
}
</script>

<style>
</style>
<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { FreeReducedLunchFields } from './FreeReducedLunchFields'
import { credits } from '../../../helpers/credits'

var chartCredits = Object.assign({}, credits.MSICycle2Certified)
chartCredits.text = chartCredits.text + ' (Note: **2021: October 1, 2020 Snapshot was used)'

export default {
  name: 'free-reduced-lunch-chart',
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'line'
        },
        credits: chartCredits,
        title: {
          text: 'Number of Free or Reduced Lunch Students'
        },
        legend: {
          enabled: true
        },
        series: FreeReducedLunchFields,
        xAxis: {
          title: {
            text: 'School Year'
          },
          type: 'category'
        },
        yAxis: {
          title: {
            text: 'Number of Students'
          },
          endOnTick: false
        }
      }
    }
  },
  mixins: [ChartMixin],
  computed: {
    freeReducedLunches() {
      return this.$store.state.overviewModule.freeReducedLunches
    }
  },
  watch: {
    freeReducedLunches(newData) {
      for (var i = 0; i < FreeReducedLunchFields.length; i++) {
        this.chartOptions.series[i].data = newData.map(row => {
          return {
            x: (row.fiscalYear % 1990) + 1990,
            y: row[FreeReducedLunchFields[i].field],
            ...row
          }
        })
      }
    }
  },
  methods: {
    excelExportData() {
      return this.defaultExcelExportData()
    },
    dataItems() {
      return FreeReducedLunchFields.flatMap((frl, index) =>
        this.chartOptions.series[index].data.map(item => {
          return { type: frl.name, SchoolYear: item.x, NumberOfStudents: item.y }
        })
      )
    }
  }
}
</script>

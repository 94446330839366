<template>
  <div>
    <student-roster :ay="ay" />
  </div>
</template>

<script>

import StudentRoster from '../widgets/StudentRoster.vue'
export default {
  components: {
    StudentRoster
  },
  props: {
    ay: Number
  }
}
</script>
import Vue from 'vue'
import { Types as CommonTypes } from '@/modules/cte/stores'
import { mapState } from 'vuex'
import PrettyCheckbox from '@/components/PrettyCheckbox.vue'

const clustersListMixin = Vue.extend({

  methods: {
    isItemActiveForAY(item, ay) {
      ay = Number(ay)
      return (item.startAY <= ay && (item.endAY || ay+1) > ay)
    },
    onDeactivateItem(id, set) {
      if (window.confirm('Are you sure you want to deactivate this item?')) {
        this.$store.dispatch(CommonTypes.actions.deactivateOAItem, {set: set, itemId: id, ay: this.$route.params.ay})
      }
    },
    onReactivateItem(id, set) {
      if (window.confirm('Are you sure you want to reactivate this item?')) {
        this.$store.dispatch(CommonTypes.actions.reactivateOAItem, {set: set, itemId: id})
      }
    },
    onInitiateEdit(item) {
      this.$store.dispatch(CommonTypes.actions.setcurrentDataItem, { set: this.set, item: item })
      this.$emit('on-edit-initiated')
    },
    onInitiateAdd() {
      this.$store.dispatch(CommonTypes.actions.setcurrentDataItem, { set: this.set })
      this.$emit('on-add-initiated')
    }
  },
  computed: {
    ...mapState(CommonTypes.path, [CommonTypes.state.occupationalAreaMetadata]),

    listItems() {
      return (this.occupationalAreaMetadata[this.set] || [])
      .map(i=>Object.assign({}, i, {activeForAY: this.isItemActiveForAY(i, this.$route.params.ay)}))
    }
  },
  components: {
    PrettyCheckbox
  },
  props: {
    set: {
      type: String,
      required: true
    }
  }
})
export { clustersListMixin }

<template>
  <tbody>
    <tr v-for="row in fields" :key="row.field.field">
      <td :class="{ 'font-weight-bold': row.field.isBold }">
        <span :id="row.field.field + '-label'">{{ row.metadata[0].label }}</span>
        <b-tooltip :target="row.field.field + '-label'" custom-class="wideTooltip">
          <div v-html="row.metadata[0].description" />
        </b-tooltip>
      </td>
      <td :class="{ 'font-weight-bold': row.field.isBold, 'text-right': true }">
        {{ format(row.value, row.field.fieldNumber) }}
      </td>
      <td :class="{ 'font-weight-bold': row.field.isBold, 'text-right': true }">
        {{ format(row.budgetValue) }}
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: {
    fields: Array
  },
  methods: {
    format(value, field) {
      if (value) {
        return field != 1.0 ? Math.round(value).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }) : value.toLocaleString('en-US', { minimumFractionDigits: 0,  maximumFractionDigits: 0 })
      }
        
      return value
    }
  }
}
</script>
<template lang="html">
  <div class="justify-content-center d-print-none">
    <div class="btn-group" role="group" aria-label="Chart Data toggle">
      <button type="button" class="btn btn-xs btn-teal-dark" :class="{ active: chartMode }" @click="$emit('input', true)">
        Chart
      </button>
      <button type="button" class="btn btn-xs btn-msi-blue" :class="{ active: !chartMode }" @click="$emit('input', false)">
        Data
      </button>
      <slot name="additionalButton" />      
    </div>
  </div>
</template>

<script>
export default {
  name: 'chart-data-toggle',
  model: {
    prop: 'chartMode'
  },
  props: {
    chartMode: {
      type: Boolean
    }
  },
  methods: {
    setChartMode(mode) {
      this.chartMode = mode
    }
  }
}
</script>
<template>
  <loadable-chart-card :load-data="loadData" card-style="overflow-y: auto" :enable-data-toggle="false">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { credits } from '../../../helpers/credits'
import { mapGetters, mapState } from 'vuex'
import { Types } from '../../../stores/financeModule'

export default {
  data() {
    return {
      showOverlay: false,
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'column',
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            dataLabels: {
              enabled: false
            },
            minPointLength: 4
          }
        },
        credits: credits.MSISRC,
        title: {
          text: this.title
        },
        legend: {
          enabled: true
        },
        tooltip: {
          pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>${point.y}</b><br/>'
        },
        yAxis: [
          {
            endOnTick: false,
            max: null,
            title: 'Amount',
            tickPositions: null,
            index: 0,      
            stackLabels: {
              enabled: true,
              style: {
                fontWeight: 'bold'
              },
              formatter:
                function () {
                  return `Total: ${Math.round(this.total).toLocaleString('en-US', {style: 'currency',currency: 'USD',minimumFractionDigits: 0,maximumFractionDigits: 0})}`;
              }
            },
          }
        ],
        xAxis: [{
          categories: [],
          index: 0
        }],
        series: [],
        exporting: {
          showTable: true,
          enabled: true
        }
      }
    }
  },
  props: {
    title: String,
    mode:{
      type: String,
      default: 'total'
    },
    expenditureType:{
      type: String,
      default: 'Personnel'
    }
  },
  computed: {
    ...mapGetters(Types.path, [Types.getters.schoolFinancesfiscalYear]),
    ...mapState(Types.path, [Types.state.expenditures]),
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    },
    scopes() {
      if (this.expenditures.length != 3) {
        return []
      }
      return ['School', 'District', 'Co-op', 'State'].slice(4-this.expenditures[2].value.length, 4)
    }, 
    columns(){
      if(this.mode == 'total') {
        return [
          { name: `State and Local - ${this.expenditureType}`, column: `sle${this.expenditureType.replace('-','')}GrandTotal` },
          { name: `Federal - ${this.expenditureType}`, column: `fe${this.expenditureType.replace('-','')}GrandTotal` }
        ]
      }
      else{
        return [
          { name: `State and Local - ${this.expenditureType}`, column: `sle${this.expenditureType.replace('-','')}TotalPerpupilExpenditures` },
          { name: `Federal - ${this.expenditureType}`, column: `fe${this.expenditureType.replace('-','')}TotalPerpupilExpenditures` }
        ]
      }
    }   
  },
  mixins: [ChartMixin],
  components: {
  },
  watch: {
    expenditures: {
      immediate: true,
      handler() {
        this.setData()
        this.chartOptions.xAxis[0].categories = this.getCategories()
      }
    }
  },
  methods: {
    getCategories() {
      return [
        //`${1989+this.fiscalYear-2}-${1990+this.fiscalYear-2}`, 
        `${1989+this.schoolFinancesfiscalYear-1}-${1990+this.schoolFinancesfiscalYear-1}`, 
        `${1989+this.schoolFinancesfiscalYear}-${1990+this.schoolFinancesfiscalYear}`
      ]
    },
    setData() {
      this.chartOptions.series = []
      
      var leas = []

      if(this.mode == 'total'){
        leas[0] = this.expenditures[this.expenditures.length -1]
      }
      else{
        for(var i = 0; i < this.expenditures.length; i++){
          leas[i] = this.expenditures[i]
        }
      }

      leas = leas.reverse()

      var results = []
     
      leas.forEach(lea => {
        this.columns.map(c => {
          results.push({
            name: this.mode == 'total' ? c.name : `${this.leaFormatter(lea.lea)} - ${c.name}`,
            data: lea.fiscalYearRecords.map(r => r.schoolFinanceRecord[c.column]),
            stack: this.leaFormatter(lea.lea)
          });
        })
      })
      this.chartOptions.series = results
    },
    leaFormatter(lea) {
      if (this.leaDictionary[lea])
        return `(${lea}) ${this.leaDictionary[lea].name}`
      return lea
    }
  }
}
</script>

<template>
  <div>
    <h1>Activate your account</h1>

    <b-card header="Account Activation" header-bg-variant="primary">
      <b-card-text>
        <div v-if="activationError" class="alert bg-danger text-light">
          <i class="fa fa-exclamation-circle fa-fw fa-lg" />
          Could not activate account
        </div>
        <b-form>
          <div>
            <dl class="lead">
              <dt class="font-weight-bold">
                Username:
              </dt>
              <dd>{{ $route.query.userName }}</dd>
            </dl>
            <p>To activate your account, please set a password and click <strong>Continue</strong>.</p>
            <b-alert variant="info" show>
              <strong>Password must be 8 characters or more and have one or more of each of the following: uppercase letter, lowercase letter, number, and special character ($%#@!* etc.)</strong>
            </b-alert>
            <b-form-group label="Enter your a password">
              <b-input type="password" v-model="password" :state="isPasswordValid" />
              <b-form-invalid-feedback id="password-input" class="d-print-none" :state="isPasswordValid">
                <strong>Password must be 8 characters or more and have one or more of each of the following: uppercase letter, lowercase letter, number, and special character ($%#@!* etc.)</strong>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Confirm password">
              <b-input type="password" v-model="confirmPassword" :state="passwordsMatch" />
              <b-form-invalid-feedback id="confirmPassword-input" class="d-print-none" :state="passwordsMatch">
                <strong>Does not match password</strong>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-row class="mt-2">
              <b-col class="text-right">
                <b-button :disabled="!isValid" variant="primary" @click="activateAccount">
                  Continue
                </b-button>
              </b-col>
            </b-form-row>
          </div>
        </b-form>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService'

const apiService = new ApiService()

export default {
  data() {
    return {
      password: '',
      confirmPassword: '',
      activationError: null,
    }
  },
  computed: {
    passwordsMatch() {
      return this.confirmPassword == this.password
    },
    isPasswordValid() {
      return this.password.length > 7   // 8 or more
        && /[A-Z]/.test(this.password)  // Uppercase
        && /[a-z]/.test(this.password)  // Lowercase
        && /\d/.test(this.password)     // Numeric
        && /\W/.test(this.password)     // Special char
    },
    isValid() {
      return this.isPasswordValid && this.passwordsMatch
    }
  },
  methods: {
    activateAccount() {
      const self = this
      apiService.Post(`/api/external-auth/activate?userId=${this.$route.query.userId}&emailConfirmationToken=${this.$route.query.emailConfirmationToken}`, { password: this.password })
        .then(() => {
          self.$bvModal.msgBoxOk('Activation complete', {
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            centered: true
          })
          .then(() => {
            window.location = '/Account/Login'
          })
        })
        .catch(error => {
          this.activationError = error
        })
    }
  },
}
</script>

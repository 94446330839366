<template>
  <div>
    <b-tabs card>
      <b-tab title="Colleges, Degrees, & Majors">
        <college-admin-widget />        
      </b-tab>
      <b-tab title="Non Traditional Program of Study">
        <non-traditional-pos-widget :ay="$attrs.ay" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import CollegeAdminWidget from '../../widgets/admin/collegesDegreesMajors/CollegeAdminWidget.vue'
import NonTraditionalPosWidget from '../../widgets/admin/nonTraditionalPOS/NonTraditionalPosWidget.vue'
export default {
  components: {
    CollegeAdminWidget,
    NonTraditionalPosWidget
  },
  data() {
    return {}
  }
}
</script>
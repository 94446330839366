<template>
  <div>
    <h5 class="p-2 bg-dark mx-2 mt-1 rounded text-light font-weight-bold">
      Roster Verification System
    </h5>
    <hr class="mt-1">
    <ul class="nav nav-pills d-block mb-3">
      <li class="mr-2">
        <a class="nav-link ml-1">
          <i class="fa-fw fas fa-filter" /> <span class="ml-1 font-weight-bold">Filter</span>
        </a>
        <ul class="nav nav-pills">
          <li class="nav-item ml-2">
            <div class="nav-link">
              Fiscal Year
              <b-form-select v-model="fiscalYear">
                <b-form-select-option v-for="year in loadedYears" :key="year" :value="year">
                  {{ year + 1990 }}
                </b-form-select-option>
              </b-form-select>
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <hr class="mt-1">
    <div class="sidebar-scroll">
      <ul class="nav nav-pills flex-column ml-2 mr-2" v-if="!isTeacher">
        <li class="nav-item">
          <router-link :to="{ name: 'Schedule/RVS/Home', query: { lea: selectedLea.number } }" class="nav-link" active-class="active">
            <i class="fas fa-home fa-fw mr-1" />Home
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'Schedule/RVS/CourseTeachers', query: { lea: selectedLea.number } }" class="nav-link" active-class="active">
            <i class="fas fa-user fa-fw mr-1" />Teachers of Record
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'Schedule/RVS/CoTeachers', query: { lea: selectedLea.number } }" class="nav-link" active-class="active">
            <i class="fas fa-user fa-fw mr-1" />Potential Contributors
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'Schedule/RVS/Teachers/List', query: { lea: selectedLea.number } }" class="nav-link" active-class="active">
            <i class="fas fa-list fa-fw mr-1" />Staff Listing
          </router-link>
        </li>
      </ul>
      <ul class="nav nav-pills flex-column ml-2 mr-2" v-else>
        <li class="nav-item">
          <router-link :to="{ name: 'Schedule/RVS/Teacher', query: { lea: selectedLea.number, teacher: userContext.username } }" class="nav-link" active-class="active">
            <i class="fas fa-home fa-fw mr-1" />Home
          </router-link>
        </li>
      </ul>
    </div>
    <hr>
    <h5 class="p-2 bg-dark mx-2 mt-4 mb-0 rounded text-light small">
      <i class="fa fa-question-circle fa-fw mr-1" /> Resources
    </h5>
    <ul class="nav nav-pills flex-column ml-2 mr-2">
      <li class="nav-item">
        <router-link :to="{ name: 'Schedule/RVS/Resources/UsersGuide' }" class="nav-link" active-class="active">
          <i class="fa fa-book fa-fw mr-1" />Users's Guide
        </router-link>
      </li>
      <li class="nav-item">
        <a href="/Content/rvs/overview.pdf" class="nav-link" active-class="active" target="_new">
          <i class="fa fa-desktop fa-fw mr-1" />Overview
        </a>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'Schedule/RVS/Resources/ParticipatingAudience' }" class="nav-link" active-class="active">
          <i class="fa fa-info-circle fa-fw mr-1" />Participating Audience
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'Schedule/RVS/Resources/RoleIdentification' }" class="nav-link" active-class="active">
          <i class="fa fa-info-circle fa-fw mr-1" />Role Identification
        </router-link>
      </li>
      
      <li class="nav-item">
        <router-link :to="{ name: 'Schedule/RVS/Resources/VerificationCourses' }" class="nav-link" active-class="active">
          <i class="fa fa-list fa-fw mr-1" />Verification Courses
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'Schedule/RVS/Resources/ExcludedCourses' }" class="nav-link" active-class="active">
          <i class="fa fa-list fa-fw mr-1" />Excluded Courses
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="{ name: 'Schedule/RVS/Resources/CourseCategories' }" class="nav-link" active-class="active">
          <i class="fa fa-list fa-fw mr-1" />Course Categories
        </router-link>
      </li>
      <li class="nav-item" v-if="userContext.isADEUser">
        <a href="#" class="nav-link" @click="submitFeedback">
          <i class="fa fa-comment fa-fw mr-1" />Submit Comments or Feedback
        </a>
      </li> 
    </ul>

    <!-- <ul class="nav nav-pills flex-column ml-2 mr-2">
      <li class="nav-item">
        <a href="/content/cte/career-coach/Insights - CTE - Career Coach User Guide.pdf" class="nav-link small" download>
          <i class="fa fa-book fa-fw mr-1" />Career Coach User Guide
        </a>
      </li>     
      <li class="nav-item" v-if="isAdmin">
        <a href="/content/cte/career-coach/Insights - CTE - Career Coach Administrators Guide.pdf" class="nav-link small" download>
          <i class="fa fa-book fa-fw mr-1" />Career Coach Administrator User Guide
        </a>
      </li>     
    </ul> -->

    <template v-if="isAdmin">
      <hr>
      <h5 class="p-2 bg-dark mx-2 mt-4 mb-0 rounded text-light small">
        <i class="fa fa-toolbox fa-fw mr-1" /> Administration
      </h5>
      <ul class="nav nav-pills flex-column ml-2 mr-2">
        <li class="nav-item">
          <router-link :to="{ name: 'Schedule/RVS/Admin/Feedback' }" class="nav-link" active-class="active">
            <i class="fas fa-comment fa-fw mr-1" />View Feedback
          </router-link>
        </li> 
        <li class="nav-item">
          <router-link :to="{ name: 'Schedule/RVS/Admin/Configuration' }" class="nav-link" active-class="active">
            <i class="fas fa-cog fa-fw mr-1" />Configuration
          </router-link>
        </li> 
        <li>
          <router-link :to="{ name: 'Schedule/RVS/Admin/Troubleshooting' }" class="nav-link" active-class="active">
            <i class="fas fa-exclamation-triangle fa-fw mr-1" />Troubleshooting
          </router-link>
        </li>
      </ul>
    </template>
    
    <div class="mb-4" />

    <feedback-modal @interface="getModalInterface" />
  </div>  
</template>

<script>
import { mapState } from 'vuex';
import FeedbackModal from '@/modules/rvs/components/common/FeedbackModal.vue'

export default {
  modalInterface: {
    showModal: () => {}
  },
  data() {
    return {
      lea: null,
      fiscalYear: null
    }
  },  
  components: {
    FeedbackModal
  },
  props: {
    isTeacher: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState('rvsModule', ['loadedYears']),
    ...mapState('globalModule', ['userContext']),
    isAdmin() {
      return this.userContext.isInsightsAdmin || this.userContext.isADELeadership
    }
  },
  async mounted() {
    await this.$store.dispatch('rvsModule/getFiscalYears')
    
    if (this.$route.query.fy) {
      // if we have a fy in the query string, use that one
      this.fiscalYear = this.$route.query.fy
    }
    else {
      // else just set to the latest one
      this.fiscalYear = this.loadedYears[0]
    }
  },
  methods: {
    getModalInterface(modalInterface) {
      this.$options.modalInterface = modalInterface
    },
    submitFeedback() {
      this.$options.modalInterface.showModal()
    }
  },
  watch: {
    fiscalYear: {
      handler() {
        this.$store.commit('rvsModule/setFiscalYear', this.fiscalYear)
      }
    }
  },
}
</script>
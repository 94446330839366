<template>
  <div>
    <b-table :fields="fields" :items="changeLogsReversed" v-bind="$attrs">
      <template v-slot:cell(createdBy)="data">
        {{ data.item.createdByName || data.value }}
      </template>
      <template v-slot:cell(toStatus)="data">
        <div>
          <workflow-status-badge v-if="!data.item.assignedToName" :document="data.item" :state-map="StateMap" :status="data.value" :show-label="false" />
          <span v-if="data.item.assignedToName">
            Assigned to: {{ data.item.assignedToName }}
          </span>
        </div>
      </template>
      <template v-slot:cell(createdDate)="data">
        {{ data.value | date }}
      </template>
    </b-table>
  </div>
</template>

<script>
import WorkflowStatusBadge from '@/components/workflow/WorkflowStatusBadge.vue'

export default {
  data() {
    return {
      StateMap: this.stateMap
    }
  },
  components: {
    WorkflowStatusBadge,
  },
  computed: {
    fields() {
      return [
        { key: 'createdBy', label: 'Who' },
        { key: 'type', label: 'Change Type' },
        { key: 'comment', label: 'Summary' },
        { key: 'toStatus', label: 'Status' },
        { key: 'createdDate', label: 'When' },
      ]
    },
    changeLogsReversed() {
      return [...this.changeLogs].reverse()
    }
  },
  props: {
    document: {
      type: Object,
      default: () => { return {} }
    },
    stateMap: {
      type: Map,
      required: true
    },
    changeLogs: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>
import { render, staticRenderFns } from "./StatisticalReportData.vue?vue&type=template&id=62707ff8&scoped=true&"
import script from "./StatisticalReportData.vue?vue&type=script&lang=js&"
export * from "./StatisticalReportData.vue?vue&type=script&lang=js&"
import style0 from "./StatisticalReportData.vue?vue&type=style&index=0&id=62707ff8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62707ff8",
  null
  
)

export default component.exports
<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
  import { ChartMixin } from '../../ChartMixin'
  import { Subpopulations } from '../../subpopulations'
  import { credits } from '../../../helpers/credits'

  export default {
    mixins: [ChartMixin],
    data() {
      return {
        animationDuration: 1000,
        chartOptions: {
          chart: {
            type: 'line'
          },
          credits: credits.MSI,
          title: {
            text: 'Teachers by Race'
          },
          series: Subpopulations,
          xAxis: {
            title: {
              text: 'School Year'
            },
            type: 'category'
          },
          yAxis: {
            title: {
              text: 'Number of Teachers'
            },
            endOnTick: false
          },
          plotOptions: {
            series: {
              minPointLength: 1
            }
          }
        }
      }
    },
    computed: {
      demographicsHistory() {
        return this.$store.state.hcptModule.demographicsHistory
      }
    },
    watch: {
      demographicsHistory(newData) {
        for (var i = 0; i < Subpopulations.length; i++) {
          this.chartOptions.series[i].data = newData.map(row => {
            return {
              x: (row.fiscalYear % 1990) + 1990,
              y: row[Subpopulations[i].field],
              color: Subpopulations[i].color,
              ...row
            }
          })
        }
      }
    },
    methods: {
      dataItems() {
        return Subpopulations.flatMap((race, index) => this.chartOptions.series[index].data.map(item => {
          return { Race: race.name, SchoolYear: item.x, NumberOfTeachers: item.y }
          })
        )
      }
    }
  }
</script>
<template lang="html">
  <div class="card bg-light mt-3 page-break-before">
    <a class="anchor" id="waivers" />
    <div class="card-header">
      Waivers
    </div>
    <div class="card-group">
      <div class="card"> 
        <div class="card-body">
          <div class="text-center">
            <b-form-checkbox switch inline v-model="activeOnly" :disabled="!isAuthenticated">
              {{ activeExpiredText }}
            </b-form-checkbox>
          </div>
          <waivers-pie-chart :load-data="loadData" :selected-lea="selectedLea" />
        </div>
      </div>
    </div>
    <loadable-chart-card :load-data="loadData" :enable-data-toggle="false" card-style="">
      <div class="card-body">
        <div class="justify-content-center text-center d-print-none" v-show="!includeWaiverDetails">
          <div class="card-body inner-card pt-6">
            <button @click="loadWaiverDetails" class="btn btn-outline-primary">
              Load Waiver Details
            </button>
          </div>
        </div>
        <div class="row" v-show="includeWaiverDetails">
          <div class="col-md-2 col-sm-2 d-print-none">
            <ul class="list-group cursor-pointer">
              <li v-for="(values, propertyName) in waivers" @click="setSelected(propertyName)" :key="propertyName" :class="['list-group-item', 'd-flex', 'justify-content-between', 'align-items-center', { active: propertyName == selectedType }]">
                {{ propertyName }}
                <span class="badge badge-warning badge-pill">{{ values.length }}</span>
              </li>
            </ul>
          </div>
          <div class="col-md-10 col-sm-12">
            <b-form-group label-cols-sm="3" label="Search" class="mb-2">
              <b-input-group>
                <b-form-input v-model="filter" placeholder="Type to Search" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-table id="waivers-table" :items="waiversRecords" :fields="fields" :per-page="perPage" :current-page="currentPage" :filter="filter" @filtered="onFiltered" small striped>
              <template v-slot:cell(schoolLEA)="data">
                <a href="#" @click="goToLea(data.value)" :id="data.index + '-' + data.value">{{ data.value }}</a>
                <lea-tooltip :target-selector="data.index + '-' + data.value" :lea-number="data.value" />
              </template>
              <template v-slot:cell(districtLEA)="data">
                <a href="#" @click="goToLea(data.value)" :id="data.index + '-' + data.value">{{ data.value }}</a>
                <lea-tooltip :target-selector="data.index + '-' + data.value" :lea-number="data.value" />
              </template>
            </b-table>
            <b-pagination v-model="currentPage" :total-rows="selectedWaiverCount" :per-page="perPage" aria-controls="waivers-table" small align="center" />
          </div>
        </div>
      </div>
    </loadable-chart-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { LeaTypes } from '../../helpers/leaTypes'
import LeaTooltip from '../LeaTooltip.vue'
import WaiversPieChart from './WaiversPieChart.vue'
import LoadableChartCard from '../LoadableChartCard.vue'
import { deepClone } from '../../helpers/globalHelpers'

export default {
  data() {
    return {
      loadData: null,
      selected: '',
      perPage: 10,
      currentPage: 1,
      filter: null,
      selectedWaiverCount: 1,
      activeOnly: true
    }
  },
  computed: {
    ...mapGetters('globalModule', ['isAuthenticated']),
    ...mapState('overviewModule', ['includeWaiverDetails']),
    selectedType() {
      const keys = Object.keys(this.$store.getters['overviewModule/waiversByType'])
      if (this.selected && keys.includes(this.selected))
        return this.selected

      return keys.length ? keys[0] : ''
    },
    activeExpiredText() {
      return this.activeOnly ? 'Active waivers' : 'Expired waivers'
    },
    fields() {
      const districtField = {
        key: 'districtLEA',
        sortable: true
      }
      const fields = [
        {
          key: 'schoolLEA',
          sortable: true
        },
        {
          key: 'path',
          sortable: true
        },
        {
          key: 'rule',
          sortable: true
        },
        {
          key: 'topic',
          sortable: false
        },
        {
          key: 'valid',
          sortable: false
        }
      ]
      if (this.selectedLea.type != LeaTypes.District)
        fields.unshift(districtField)

      return fields
    },
    waivers() {
      return this.$store.getters['overviewModule/waiversByType']
    },
    waiversRecords() {
      if (this.$printMode) {
        return this.$store.state.overviewModule.waivers
      }
      return this.waivers[this.selectedType]
    }
  },
  components: {
    LeaTooltip,
    LoadableChartCard,
    WaiversPieChart
  },
  mounted() {
    if (this.$route.hash == '#waivers') {
      const self = this
      setTimeout(function() {
        self.$scrollTo(`#waivers`, 10,{
          easing: 'ease-in',
          force: true,
          cancelable: true,
          x: false,
          y: true
        })
      }, 300)
    }
  },
  methods: {
    excelExportData() {
      return {
        name: 'Waivers',
        items: this.$store.state.overviewModule.waivers
      }
    },
    setSelected(type) {
      this.selected = type
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.selectedWaiverCount = filteredItems.length
      this.currentPage = 1
    },
    goToLea(leaNumber) {
      const lea = this.$store.getters['globalModule/leaDictionary'][leaNumber]
      window.scrollTo(0,0)
      this.$store.dispatch('globalModule/changeSelectedLea', lea)
    },
    loadWaiverDetails() {
      this.$store.commit('overviewModule/setIncludeWaiverDetails', true)
      this.getWaivers(this.selectedLea)
    },
    getWaivers(lea) {
      var self = this
      this.loadData = this.$store.dispatch('overviewModule/getWaivers', {
        lea: lea.number || 'AR',
        includeDetails: this.includeWaiverDetails || false,
        activeOnly: this.activeOnly
      })
      .then(function() {
        if (self.$printMode) {
          self.perPage = self.$store.state.overviewModule.waivers.length
          self.selectedWaiverCount = self.$store.state.overviewModule.waivers.length
        }
      })
    }
  },
  watch: {
    activeOnly: {
      handler() {
        const lea = deepClone(this.selectedLea)
        this.getWaivers(lea)
      }
    },
    selectedLea: {
      immediate: true,
      handler(newLea) {
        const lea = deepClone(newLea)
        if (this.$printMode && lea.number != 'AR') {
          this.$store.commit('overviewModule/setIncludeWaiverDetails', true)
        }
        this.getWaivers(lea)
      }
    },
    selectedType: {
      immediate: true,
      handler(newSelectedType) {
        if (self.$printMode) {
          self.selectedWaiverCount = self.$store.state.overviewModule.waivers.length
        }
        this.selectedWaiverCount = newSelectedType ? this.waivers[newSelectedType].length : 1
      }
    }
  }
}
</script>
<template>
  <div v-if="currentClosure && reasonType">
    <div>
      <h3>
        {{ district.name }} ({{ district.number }})
      </h3>
      <p>{{ district.adminFirstName }} {{ district.adminLastName }} - <a :href="`mailto:${district.adminEmailAddress}`">{{ district.adminEmailAddress }}</a></p>
    </div>
    <b-card header="View Closure" header-bg-variant="primary">
      <b-form>
        <b-form-group label="Start Date">
          {{ startDate | date('dddd, MMMM DD, YYYY') }} {{ startTime != '12:00 am' ? startTime : '' }}
        </b-form-group>
        <b-form-group label="End Date">
          {{ endDate | date('dddd, MMMM DD, YYYY') }} {{ endTime != '12:00 am' ? endTime : '' }}
        </b-form-group>
        <b-form-group label="Closure Type" id="reasonTypeFormGroup">
          {{ (reasonType || { label: '(not selected)' }).label }}
        </b-form-group>
        <b-form-group label="Select schools(s) affected">
          <ul class="list-unstyled mb-0">
            <li v-for="i in selectedSchools" :key="i.code">
              {{ i.label }}
            </li>
          </ul>
        </b-form-group>
        <b-form-group label="Select Grade Level(s) affected">
          <ul class="list-unstyled mb-0">
            <li v-for="i in grades" :key="i.code">
              {{ i.label }}
            </li>
          </ul>
        </b-form-group>
        <b-form-group label="Number Students Affected">
          {{ numberStudentsAffected }}
        </b-form-group>
        <b-form-group label="Number Staff Affected">
          {{ numberStaffAffected }}
        </b-form-group>
        <b-form-group label="Is this closure marked as an AMI Day?">
          {{ isAMIDay ? 'Yes' : 'No' }}
        </b-form-group>
        <b-form-group label="If not an AMI Day will the day(s) be made up at a later date?" v-if="!isAMIDay">
          {{ willBeMadeUp ? 'Yes' : 'No' }}
        </b-form-group>
        <b-form-group label="District Phone Number">
          {{ districtPhone }}
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            Additional Information and Comments<i> (Any information added here may be subject to FOIA)</i>
          </template>
          {{ superintendentComments }}
        </b-form-group>
        <b-form-row>
          <b-col class="text-right">
            <b-button variant="secondary" @click="$router.go(-1)">
              Go Back
            </b-button>
          </b-col>
        </b-form-row>
      </b-form>  
    </b-card>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import { ClosureReasons } from '../enums'
import { gradeSpans } from '@/helpers/gradeSpans'
import moment from 'moment'

function convertToSelectItem (key, value) {
  return { code: key, label: value }
}

export default {
  data() {
    return {
      intendedClosureDuration: null,
      selectedSchools: [],
      reasonType: null,
      districtPhone: null,
      superintendentComments: null,
      adeExternalComments: null,
      adeInternalComments: null,
      grades: [],
      numberStudentsAffected: null,
      numberStaffAffected: null,
      errorMessage: null,
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
      isAMIDay: false,
      willBeMadeUp: false,
      districtName: null,
      districtNumber: null
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState('core/sctClosures', ['currentClosure']),
    district() { 
      return this.currentClosure != null ? this.leaDictionary[this.currentClosure.districtLea] : null
    },
    gradeOptions() {
      const grades = gradeSpans.map(g => convertToSelectItem(g.value, g.text))
      return [{ code: 255, label: '(All Grades)'}, ...grades]
    },
    schools() {
      const schools = this.district.ownedLeas.map(s => convertToSelectItem(s.number, `${s.name} (${s.number})`))
      return [{ code: 'ALL', label: '(Entire District)' }, ...schools]
    },
  },
  methods: {
    formatPhone(value) {
      if (!value) return value
      const numbers = value.replace(/\D/g, '')
      const numberLength = numbers.length
      if (numberLength < 1) return ''
      if (numberLength < 4) return numbers
      if (numberLength < 7) return `(${numbers.slice(0, 3)}) ${numbers.slice(3)}`
      if (numberLength < 11) return `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(6, 10)}`
      return `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(6, 10)} x${numbers.slice(10, 15)}`
    }
  },
  props: {
    districtLea: String,
    id: String
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newDistrict) {
        this.reasonType = null

        if (this.id) this.$store.dispatch('core/sctClosures/getDistrictClosurePublic', {
          id: this.id
        })
        .then(() => {
          this.reasonType = ClosureReasons.find(co => co.value == this.currentClosure.closureType)
          this.selectedSchools = (this.currentClosure.schoolLeas || []).map(s => this.schools.find(sc => sc.code == s))
          this.grades = (this.currentClosure.gradeLevels || []).map(s => this.gradeOptions.find(go => go.code == s))
          this.districtPhone = this.currentClosure.districtPhone.length > 0 ? this.currentClosure.districtPhone : newDistrict.adminPhoneNumber
          this.superintendentComments = this.currentClosure.superintendentComments
          this.numberStudentsAffected = this.currentClosure.numberStudentsAffected,
          this.numberStaffAffected = this.currentClosure.numberStaffAffected,
          this.isAMIDay = this.currentClosure.isAMIDay,
          this.willBeMadeUp = this.currentClosure.willBeMadeUp,
          this.startDate = new moment(this.currentClosure.startDate + 'Z').format('YYYY-MM-DD')
          this.startTime = new moment(this.currentClosure.startDate + 'Z').format('h:mm a')
          this.endDate = new moment(this.currentClosure.endDate + 'Z').format('YYYY-MM-DD')
          this.endTime = new moment(this.currentClosure.endDate + 'Z').format('h:mm a')
        })
      }
    },
  }
}
</script>

<style>
  .col-form-label {
    font-weight: bold;
  }
</style>
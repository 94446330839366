<template>
  <paginated-table :items="costTypes" :fields="fields">
    <template v-slot:head(action)>
      <b-button variant="primary" :to="{ name: 'CostTypes/Create'}" class="float-right">
        <i class="fas fa-fw fa-plus" /> Add Cost Type
      </b-button>
    </template>
    <template v-slot:cell(enabled)="data">
      <pretty-checkbox :value="data.item.enabled" false-class="fas fa-times-circle text-danger" />
    </template>
    <template v-slot:cell(action)="data">
      <b-link :to="{ name: 'CostTypes/Edit', params: { editItemId: data.item.costTypeId }}">
        <i class="fas fa-fw fa-edit" />
      </b-link>
    </template>
  </paginated-table>
</template>

<script>
import PaginatedTable from '@/components/PaginatedTable'
import { mapState } from 'vuex'
import { Types } from '@/modules/cte/stores/perkinsv'
import { CostTypeCategories } from '../../enums'
import PrettyCheckbox from '@/components/PrettyCheckbox.vue'

export default {
  data(){
    return {
      fields:[
        {
          key: 'code',
          label: 'Cost Type',
          sortable: true
        },
        {
          key: 'description',
          sortable: true
        },
        {
          key: 'explanation',
          sortable: true,
          thClass: 'w-50'
        },
        {
          key: 'category',
          sortable: true,
          formatter: value => CostTypeCategories[value].text
        },
        {
          key: 'enabled',
          sortable: true
        },
        {
          key: 'action',
          label: '',
          sortable: false,
          class: 'text-right'
        }
      ]

    }
  },
  computed:{
    ...mapState(Types.path, [Types.state.costTypes])
  },
  components:{
    PaginatedTable,
    PrettyCheckbox
  },
  methods:{
  }
}
</script>
<template>
  <b-form-select :options="careerCoachGrades" v-model="gradeFilter" @change="$emit('grade-changed', {grade: gradeFilter})" />
</template>

<script>
import { gradeSpans } from '@/helpers/gradeSpans'

export default {
  data() {
    return {
      gradeFilter: null,
    }
  },
  computed: {
    careerCoachGrades() {
      var grades = gradeSpans.filter(g=>g.value >= 7 && g.value <= 12);
      grades.push({value: 'G', text: 'Previous AY - 12th Grade Students'})
      grades.splice(0, 0, {value: null, text: 'All Grades'})

      return grades
    }
  },
  components: {}
}
</script>
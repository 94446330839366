<template>
  <span :class="letterGradeClass">{{ lea.letterGrade }}</span>
</template>

<script>
export default {
  props: {
    lea: Object
  },
  computed: {
    letterGradeClass() {
      if (['A', 'B'].includes(this.lea.letterGrade))
        return 'badge badge-success badge-letter-grade'
      else if (this.lea.letterGrade == 'C')
        return 'badge badge-warning badge-letter-grade'

      return 'badge badge-danger badge-letter-grade'
    }
  }
}
</script>

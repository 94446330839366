<template>
  <div class="bottom-actions" v-if="isMobile">
    <button :class="buttonClass" :disabled="!canSyncChanges" @click="resumeActions">
      <i :class="iconClass" /> {{ buttonText }}
    </button>
  </div>
</template>

<script>
import { Types } from '../../../stores/offlineModule'
import { mapState, mapGetters } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState('globalModule', ['isOnline']),
    ...mapState('offline', [Types.state.queuedActions, Types.state.isProcessing]),
    ...mapGetters('DPSAFT', ['isMobile']),
    onlineWithQueuedActions() {
      return this.isOnline && this.queuedActions.length > 0
    },
    buttonText() {
      if (this.isProcessing) 
        return 'Syncing Changes'
      else if (this.onlineWithQueuedActions)
        return 'Sync Changes'
      else if (this.isOnline)
        return 'Online'
      return 'Working Offline'
    },
    buttonClass() {
      return this.isOnline ? 'btn btn-primary' : 'btn btn-warning'
    },
    iconClass() {
      if (this.isProcessing) 
        return 'fa fa-spin fa-sync-alt'
      else if (this.onlineWithQueuedActions)
        return 'fas fa-sync-alt'
      else if (this.isOnline)
        return 'fas fa-link'
      return 'fas fa-unlink'
    },
    show() {
      if (!this.isOnline) 
        return true
      
      return this.onlineWithQueuedActions
    },
    canSyncChanges() {
      return this.onlineWithQueuedActions && !this.isProcessing
    }
  },
  methods: { 
    resumeActions() {
      this.$store.dispatch(Types.actions.processQueuedActions)
    }
  }
}
</script>

<style>
  .bottom-actions { 
    position: fixed; 
    bottom: -8px; 
    width: 100%; 
    text-align: center;
    padding-right: 50px;
    padding-bottom: 4px;
  }
</style>
<template>
  <div class="dashboard-widget">
    <div>
      <div v-if="selectedLea.type == LeaTypes.State">
        <state-dashboard />
      </div>
      <div v-else-if="selectedLea.type == LeaTypes.District">
        <district-dashboard />
      </div>
      <div v-else-if="selectedLea.type == LeaTypes.School">
        <school-dashboard />
      </div>
      <div v-else-if="selectedLea.type == LeaTypes.Coop">
        <coop-dashboard />
      </div>
    </div>
  </div>
</template>

<script>
import { LeaTypes } from '@/helpers/leaTypes'

import StateDashboard from '../dashboards/StateDashboard.vue'
import DistrictDashboard from '../dashboards/DistrictDashboard.vue'
import SchoolDashboard from '../dashboards/SchoolDashboard.vue'
import CoopDashboard from '../dashboards/CoopDashboard.vue'

export default {
  data() {
    return {
      LeaTypes
    }
  },
  components: {
    StateDashboard,
    DistrictDashboard,
    SchoolDashboard,
    CoopDashboard
  }
}

</script>
<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" :first-column-sticky="true" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { EnrollmentChartMixin, defaultOptionMatcher } from './EnrollmentChartMixin'
import { EnrollmentGrades } from '../../overview/enrollment/EnrollmentGrades'
import sumBy from 'lodash.sumby'
import { credits } from '../../../helpers/credits'

export default {
  data() {
    return {
     animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'column',
          plotBackgroundColor: '#f8f9fa',
          plotShadow: false
        },
        title: {
          text: 'Enrollment By Grade'
        },          
        legend: {
          enabled: true
        },
        credits: credits.InsightsNightlyDataPulls,
        plotOptions: {
          column: {
           tooltip: {
             headerFormat: ''
           },
           minPointLength: 3
          },
        },
        xAxis: {
          title: {
            text: 'Grade Level'
          },
          labels: {
            enabled: false
          }
        }
      }
    }
  },
  mixins: [ChartMixin, EnrollmentChartMixin],
  computed: {
    gradesList() {
      return [-2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    },
    gradeOptions() {
      return this.gradesList.map(gradeValue => {
        return {
          name: this.getGradeLevel(gradeValue),
          value: gradeValue.toString()
        }
      })
    }
  },
  watch: {
    enrollmentCounts: {
      immediate: true,
      handler(newData) {
        if (newData && newData.byGradeLevel && this.mode == 'current') {
          this.chartOptions.series = []
          this.chartOptions.xAxis.categories = EnrollmentGrades.map(g => g.name)
          
          const filteredGradeOptions = this.gradeOptions.filter(option => newData.byGradeLevel.some(defaultOptionMatcher.bind(option)))
          filteredGradeOptions.forEach(option => {
            this.chartOptions.series.push({
              name: option.name,
              data: [{ y: sumBy(newData.byGradeLevel, defaultOptionMatcher.bind(option)) }]
            })
          })
        }
      }
    },
    metricSnapshots: {
      immediate: true,
      handler(newData) {
        if (newData && newData.byGradeLevel && this.mode == 'trend') {
          this.chartOptions.plotOptions = {
          }
          this.chartOptions.chart.type = 'line'
          const filteredGradeOptions = this.gradeOptions.filter(option => newData.byGradeLevel.some(defaultOptionMatcher.bind(option)))
          this.setTrendedSeries(newData.byGradeLevel, filteredGradeOptions)
        }
      }
    }
  },
  methods: {
    dataItems() {
      if (this.mode == 'current') {
        const filteredGradeOptions = this.gradeOptions.filter(option => this.enrollmentCounts.byGradeLevel.some(defaultOptionMatcher.bind(option)))
        return this.pivotCurrent(this.enrollmentCounts.byGradeLevel, filteredGradeOptions)
      }
      else {
        const filteredGradeOptions = this.gradeOptions.filter(option => this.metricSnapshots.byGradeLevel.some(defaultOptionMatcher.bind(option)))
        return this.pivotTrended(this.metricSnapshots.byGradeLevel, filteredGradeOptions)
      }
    },
    getGradeLevel(number) {
      switch (parseInt(number)) {
        case -2: return "Ungraded"
        case -1: return "Pre-K"
        case 0: return "Kindergarten"
        default: return `Grade ${number}`
      }
    }
  }
}
</script>
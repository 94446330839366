import Vue from 'vue'
import { mapGetters } from 'vuex'
import LoadableChartCard from '../../LoadableChartCard.vue'
import { TableMixin } from '../../TableMixin'

const NbctConfirmationTable = Vue.extend({
    name: 'nbct-confirmations-table',
    /* html */
    template: `
    <div>
        <table-control-widget :per-page="perPage" v-on:filter-changed="filter=$event" v-on:page-length-changed="perPage=$event">        
        </table-control-widget>
        <loadable-chart-card card-style='' v-bind:load-data='loadData' :enableDataToggle='false'>
            <b-table id='nbct-confirmation-table' v-bind:items='nbctConfirms' :fields='fields'
            :per-page="perPage" :current-page="currentPage" :filter="filter" small striped>
            <template v-slot:cell(isConfirmed)='data'>
                <div class='d-inline'>
                    <span class='fa fa-check-circle' v-bind:class="{'text-success': data.value, 'text-secondary': !data.value}"></span>
                </div>
            </template>
            </b-table>
        </loadable-chart-card>
        <table-pagination-widget v-bind:total-rows-count="rowCount" :page-length="perPage" v-on:page-changed="currentPage=$event">
        </table-pagination-widget>
    </div>
    `,
    mixins: [TableMixin],
    data() {
        return {
            perPage: 20,
            currentPage: 1,
            filter: null,
            fields: [{
                key: 'districtLea',
                sortable: true,
                label: 'LEA',                
                thStyle: { width: '10%'}
            },
            {
                key: 'districtName',
                sortable: true,
                label: 'District Name',
                thStyle: { width: '20%'}
            },
            {
                key: 'isConfirmed',
                sortable: true,
                label: 'NBCT Data Confirmed'
            }
            ]
        }
    },
    components: {
        LoadableChartCard
    },
    computed:{
        ...mapGetters('hcptModule',['nbctConfirms']),
        rowCount(){
            return this.nbctConfirms ? this.nbctConfirms.length : 0
        }
    },
    props: {
        selectedLea: Object,
        loadData: Promise
    }
})
export { NbctConfirmationTable }
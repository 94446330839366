<template lang="html">
  <div>
    <b-modal id="alternative-leas-modal" size="lg" hide-footer>
      <template slot="modal-title">
        Not allowed to access this LEA on the current dashboard
      </template>
      <div class="d-block text-center">
        <div v-if="accessHeaders.AccessibleLeas && accessHeaders.AccessibleLeas.length > 0">
          <h5>
            Here are some LEAs you do have access to on this dashboard:
          </h5>
          <ul class="list-group">
            <a v-for="lea in accessHeaders.AccessibleLeas" :key="lea" @click="goToLea(lea)" class="list-group-item list-group-item-action cursor-pointer">
              <lea-icon :lea="leaDictionary[lea]" /> {{ leaDictionary[lea].name }} ({{ lea }}) 
            </a>
          </ul>
        </div>
      </div>
      <b-button class="mt-3" block @click="back">
        Back
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import LeaIcon from './LeaIcon.vue'
import { mapState } from 'vuex'

export default {
  name: 'alternative-leas-modal',
  components: { LeaIcon },
  computed: {
    ...mapState('globalModule', ['accessHeaders']),
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    }
  },
  methods: {
    back() {
      window.history.back()
    },
    goToLea(leaNumber) {
      const lea = this.leaDictionary[leaNumber]
      window.scrollTo(0,0)
      this.$store.dispatch('globalModule/changeSelectedLea', lea)
      this.$bvModal.hide('alternative-leas-modal')
    }
  }
}
</script>

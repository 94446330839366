import Vue from 'vue'
import { TableMixin } from '../../TableMixin'
import LeaTooltip from '../../LeaTooltip.vue'
import CustomTooltip from '../../CustomTooltip.vue'

const StaffWithNoAelsRecord = Vue.extend({
    name: 'staff-with-no-aels-record',
    /* html */
    template: `
    <div class="chart">
       <table-control-widget :per-page="tableOptions.perPage" v-on:filter-changed="tableOptions.filter=$event" v-on:page-length-changed="tableOptions.perPage=$event">
       </table-control-widget>
       <b-table 
            :fields="tableOptions.fields" 
            :per-page="tableOptions.perPage" 
            :items="staffWithNoAelsRecord"
            :filtered="onFiltered"
            :current-page="tableOptions.currentPage" :filter="tableOptions.filter" :busy="tableOptions.isLoading" api-url="api/hcpt/data-integrity/classes-missing-case-ids">
            <template v-slot:head(caseId)="data">
                <custom-tooltip content="The Case Id associated with the class section in eSchool" element-id="staffNotInAELSCaseIdTooltip" :label="data.label" />
            </template>
            <template v-slot:cell(caseId)="data">
              <aels-case-id-link :case-id="data.value.toString()" />
            </template>
            <template v-slot:head(employeeSSN)="data">
                <custom-tooltip content="The SSN registered in eFinance for the employee" element-id="staffNotInAELSSSNTooltip" :label="data.label" />
            </template>
            <template v-slot:head(aelsSSN)="data">
                <custom-tooltip content="The SSN registered in AELS for the record matching the Case Id associated with the class section in eSchool" element-id="staffNotInAELSSSNTooltip2" :label="data.label" />
            </template>
            <template v-slot:cell(lea)="data">
                <a href="#" v-on:click="goToLea(data.value)" v-bind:id="data.index + '-' + data.value">{{ data.value }}</a>
                <lea-tooltip v-bind:target-selector="data.index + '-' + data.value"
                    v-bind:lea-number="data.value">
                </lea-tooltip>
            </template>
            <div slot="table-busy" class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
            </div>
       </b-table>
       <table-pagination-widget :total-rows-count="totalRowCount" :filtered-rows-count="tableOptions.filteredRowCount" :page-length="tableOptions.perPage" v-on:page-changed="tableOptions.currentPage=$event"></table-pagination-widget>
    </div>`,

    data() {
        return {
            tableOptions: {
                currentPage: 1,
                perPage: this.$printMode ? this.MAX_SAFE_INTEGER : 50,
                filter: null,
                filteredRowCount: null,
                isLoading: false,
                fields: [
                    {
                        key: 'lea',
                        label: 'LEA',
                        sortable: true,
                        class: 'col-bg'
                    },
                    {
                        key: 'courseNumber',
                        label: 'Course Number',
                        sortable: true,
                        class: 'col-bg'
                    },
                    {
                        key: 'fullCourseDescription',
                        label: 'Course Details',
                        sortable: true,
                        class: 'col-bg'
                    },
                    {
                        key: 'caseId',
                        label: 'Case Id',
                        sortable: true,
                        class: 'col-bg'
                    },
                    {
                        key: 'employeeName',
                        label: 'Employee Name',
                        sortable: true,
                        class: 'col-bg'
                    },
                    {
                        key: 'employeeSSN',
                        label: 'Employee SSN',
                        sortable: true,
                        class: 'col-bg'
                    },
                    {
                        key: 'aelsName',
                        label: 'AELS Name',
                        sortable: true,
                        class: 'col-bg alt'
                    },
                    {
                        key: 'aelsSSN',
                        label: 'AELS SSN',
                        sortable: true,
                        class: 'col-bg alt'
                    }
                ]
            }
        }
    },
    mixins: [TableMixin],
    components: {
        LeaTooltip,
        CustomTooltip
    },
    computed: {
        staffWithNoAelsRecord(){
            return this.$store.state.hcptModule.dataIntegrity.staffWithNoAelsRecord
        },
        totalRowCount(){
            return this.$store.state.hcptModule.dataIntegrity.staffWithNoAelsRecord.length
        }
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.tableOptions.filteredRowCount = filteredItems.length
            this.currentPage = 1
        },
        goToLea(leaNumber) {
            const lea = this.$store.getters['globalModule/leaDictionary'][leaNumber]
            window.scrollTo(0,0)
            this.$store.dispatch('globalModule/changeSelectedLea', lea)
        },
        excelExportData() {
            return {
                name: 'Staff With No AELS Record',
                items: this.$store.state.hcptModule.dataIntegrity.staffWithNoAelsRecord.map(item => {
                    return { 
                        LEA: item.lea,
                        CourseNumber: item.courseNumber,
                        FullCourseDescription: item.fullCourseDescription,
                        EmployeeName: item.employeeName,
                        SSN: item.employeeSSN,
                        AelsName: item.aelsName,
                        AelsSSN: item.aelsSSN
                    }
                })
            }
        }
    },
    props: {
        selectedLea: Object
    }
})
export { StaffWithNoAelsRecord }
export const GrowthChartMarkers ={
    1:{
        name: 'School LEA',
        marker: {
            symbol: 'square'
        },
        color: '#000000',
        index: 1
    },
    2:{
        name: 'District LEA',
        marker: {
            symbol: 'circle'
        },
        color: '#33FFDD',
        index: 2
    },
    3:{
        name: 'State Of Arkansas',
        marker: {
            symbol: 'triangle'
        },
        color: '#F00808',
        index: 3
    }
}
import { surveyTemplate } from '../components/surveys/teacherSurvey/surveyTemplate'

function aggregateResults(responseRecords, collectedQuestions) {
  collectedQuestions.forEach(q => {

    responseRecords.forEach(response => {
      var coop = response.coop
      response = response.responseData

      var responseKeys = Object.keys(response)


      if (responseKeys.includes(q.id)) {
        //process the results
        if (q.component.startsWith("QuestionYesno")) {
          var result
          if (response[q.id] === 1) result = 'Yes'
          else if (response[q.id] === 10) result = 'Indeterminate'
          else result = "No"
          q.choices[result + '\u200B']++
          q.details.push ({ coop: coop, surveyResponse: result  })

        } else if (q.component.includes("Selection")) {
          if (Array.isArray(response[q.id])) {
            response[q.id].forEach(sel => {
              q.choices[sel + '\u200B']++
              q.details.push ({ coop: coop, surveyResponse: sel  })
            })
          } else if (q.component == "QuestionSingleSelection") {
            q.choices[response[q.id] + '\u200B']++
            q.details.push({ coop: coop, surveyResponse: response[q.id] })
          } else {
            q.choices[response[q.id] + '\u200B']++
            q.details.push ({ coop: coop, surveyResponse: q.id  })
          }
        }
      } else {
        //aggregate the No Answer 
        q.choices['No answer\u200B']++
        q.details.push ({ coop: coop, surveyResponse: "No answer\u200B"  })
      }
    })
  })
}

function acquireQuestions(sectionContainer, questionCollector) {
  collectQuestions(sectionContainer.questions, questionCollector)
  if (sectionContainer.sections) {
    sectionContainer.sections.forEach(s => {
      acquireQuestions(s, questionCollector)
    })
  }
}

function collectQuestions(questions, questionCollector) {
  if (questions) {
    questions.forEach(q => {
      if (q.component != "QuestionFreeText" && q.component != "QuestionHeader") {

        var newQuestion = {
          id: q.id,
          component: q.component,
          text: q.text,
          details: [],
          choices: q.component.startsWith("QuestionYesno") ? {
            "Yes\u200B": 0,
            "No\u200B": 0,
            "No answer\u200B": 0,
            "Indeterminate\u200B": 0
          } : q.component.includes("Selection") ? Object.fromEntries([
            ...q.choices.map((c) => {
              return [
                [c + '\u200B'], 0
              ]
            }),
            [
              ["No answer" + '\u200B'], 0
            ], 
          ]) : {}
        }

        questionCollector.push(newQuestion)
      }
    })
  }
}

function aggregateResponseResults(responseData) {
  var questions = [];
  acquireQuestions(surveyTemplate, questions)
  aggregateResults(responseData, questions)
  return questions
}

export {
  aggregateResponseResults
}
<template>
  <div class="card mt-3 bg-light">
    <a class="anchor d-print-none" id="graduation-rate" />
    <div class="card-header">
      Graduation Rates
    </div>
    <div class="card-body">
      <div class="card-group">
        <enrollment-total-chart
          :load-data="loadData"
          :selected-lea="selectedLea"
          :enrollment="graduationRates"
          chart-title="Combined Graduation Rate"
          y-axis-label="Rate"
          :value-multiplier="100"
          :tooltip-formatter="chartFormatter"
        />
        <enrollment-by-gender-chart
          :load-data="loadData"
          :selected-lea="selectedLea"
          :enrollment="graduationRates"
          chart-title="Graduation Rate by Gender"
          y-axis-label="Rate"
          :value-multiplier="100"
          :tooltip-formatter="chartFormatter"
          :show-card-tooltip="true"
          card-tooltip-content="Graduation Rates were not tracked by gender prior to 2018"
          card-tooltip-id="gradRateByGenderCardTooltip1"
        />
      </div>
      <div class="card-group">
        <enrollment-by-race-chart
          :load-data="loadData"
          :selected-lea="selectedLea"
          :enrollment="graduationRates"
          chart-title="Graduation Rate by Race"
          y-axis-label="Rate"
          :value-multiplier="100"
          :tooltip-formatter="chartFormatter"
        />
        <graduation-by-other-subpopulation-chart
          :load-data="loadData"
          :selected-lea="selectedLea"
          :enrollment="graduationRates"
          chart-title="Graduation Rate by Subpopulation"
          y-axis-label="Rate"
          :value-multiplier="100"
          :tooltip-formatter="chartFormatter"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EnrollmentTotalChart from './EnrollmentTotalChart'
import EnrollmentByRaceChart from './EnrollmentByRaceChart'
import EnrollmentByGenderChart from './EnrollmentByGenderChart.vue'
import GraduationByOtherSubpopulationChart from './GraduationByOtherSubpopulation'

export default {
  data() {
    return {
      loadData: null,
      chartFormatter: function () {
        return `${this.x}<br/><span  style="color:${this.series.color}">${this.series.name}</span>: <b>${this.y.toFixed(2)}%</b>`
      }
    }
  },
  props: {
    //selectedLea: Object
  },
  computed: {
    ...mapState('overviewModule', ['graduationRates'])
  },
  methods: {},
  components: {
    EnrollmentByRaceChart,
    EnrollmentByGenderChart,
    EnrollmentTotalChart,
    GraduationByOtherSubpopulationChart
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        this.loadData = this.$store.dispatch('overviewModule/getGraduationRates', {
          lea: newLea.number
        })
      }
    }
  }
}
</script>

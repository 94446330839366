<template>  
  <div class="card bg-light mt-3">
    <a class="anchor" id="licenses" />
    <div class="card-header">
      Unqualified Course Teachers
    </div>
    <div class="card-body">
      <div class="card-group">
        <teacher-unqualified-course-table :load-data="loadData" :selected-lea="selectedLea" />
      </div>
    </div>
  </div>
</template>


<script>
import TeacherUnqualifiedCourseTable from './TeacherUnqualifiedCourseTable.vue'

export default {
  name: 'teacher-unqualified-course-widget',
  data() {
    return { loadData: null }
  },
  computed: {
    selectedLea() { return this.$store.state.globalModule.leaContext.SelectedLea }
  },
  components: {
    TeacherUnqualifiedCourseTable
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler() {
        if (this.selectedLea && (this.selectedLea.type == 1 || this.selectedLea.type == 2)) {
            this.loadData = this.$store.dispatch('scheduleModule/getUnqualifiedCourses', {
                lea: this.selectedLea.number
            }).then(() => this.$root.pageLoading = false);
        }
      }
    }
  }
}
</script>

<template>
  <div class="troubleshooting"> 
    <div class="card mt-3">
      <b-tabs card>
        <b-tab active>
          <template v-slot:title>
            RVS Transformed Data
          </template>
          <div class="card-body-secondary">
            <RVS />
          </div>
        </b-tab>
        <b-tab>
          <template v-slot:title>
            Insights District Data
          </template>
          <div class="card-body-secondary">
            <Insights />
          </div>
        </b-tab>
        <b-tab>
          <template v-slot:title>
            eSchool
          </template>
          <div class="card-body-secondary">
            <ESchool />
          </div>
        </b-tab>
        <b-tab>
          <template v-slot:title>
            eFinance
          </template>
          <div class="card-body-secondary">
            <EFinance />
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>

import EFinance from './troubleshooting/EFinanceTroubleshooting.vue';
import ESchool from './troubleshooting/ESchoolTroubleshooting.vue';
import Insights from './troubleshooting/InsightsTroubleshooting.vue';
import RVS from './troubleshooting/RVSTroubleshooting.vue';

export default {
  data() {
    return {
      
    }
  },
  components: {
    EFinance,
    ESchool,
    Insights,
    RVS
  },
  computed: {   
    
  },
  props: {
    
  },
  methods: {

  },
  watch: {
    fiscalYear: {
      handler() {
        
      }
    },
    selectedLea: {
      handler() {
        
      }
    },
    
  },
  beforeMount() {
    
  },
  mounted() {
    
  }
}

</script>
<template>
  <form :id="formId">
    <div>
      <reconfiguration-code-selector :item="item" :purpose="ReconfigurationCodePurpose.NewDistrict" :operation="operation" />
      <div class="form-group row mb-0">
        <b-toast :id="errorsToastId" title="New District Issues" header-class="bg-success text-white" no-auto-hide>
          <b-list-group>
            <b-list-group-item v-for="error in errors.flatMap(e => e)" :key="JSON.stringify(error)">
              {{ error.error }}
            </b-list-group-item>
          </b-list-group>
        </b-toast>
        <div class="col-md-3 mb-3 mt-1">
          LEA Name:
        </div>
        <b-form-input required placeholder="Enter LEA Name" class="col-md-9 mb-2" v-model="item.leaName" />
      </div>
      <div class="form-group row mb-0">
        <div class="col-md-3 mb-3 mt-1">
          LEA Type Code:
        </div>
        <b-form-select required class="col-md-9 mb-2" :options="districtTypes" v-model="item.leaTypeCode" :disabled="!userContext.hasRole('Insights_RT-SMS_Administrators')" />
      </div>
      <div class="form-group row mb-0">
        <div class="col-md-3 mb-3 mt-1">
          Provision 2:
        </div>
        <b-form-checkbox required class="col-md-9 mb-2" v-model="item.provision2" />
      </div>
      <div class="form-group row mb-0">
        <div class="col-md-3 mb-3 mt-1">
          Address:
        </div>
        <b-form-input required placeholder="Enter address" class="col-md-9 mb-2" v-model="item.address" />
        <div class="col-md-3 mb-3 mt-1">
          Address continued:
        </div>
        <div class="form-group px-1">
          <label>City</label>
          <b-form-input required placeholder="Enter city" v-model="item.city" />
        </div>
        <div class="form-group px-1">
          <label>State</label>
          <b-form-input required placeholder="Enter state" v-model="item.state" />
        </div>
        <div class="form-group px-1">
          <label>Zip</label>
          <b-form-input required placeholder="Enter zip" v-model="item.zip" />
        </div>
      </div>
      <div class="form-group row mb-0">
        <div class="col-md-3 mb-3 mt-1">
          Additional Comments:
        </div>
        <textarea class="form-control col-md-9" v-model="item.additionalComments">Enter any additional instructions or comments here...</textarea>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex'
import { RequestItemMixin } from '../RequestItemMixIn'
import { ItemValidationMixin } from './ItemValidationMixin'

export default {
  name: 'new-district-item',
  data() {
    return {
      type: 'New LEA',
      requiredFields: ['leaName', 'leaTypeCode', 'address', 'city', 'state', 'zip']
    }
  },
  computed: {
    ...mapState('toolsModule/leaAssignment', ['schools']),
    ...mapState('toolsModule/leams', ['leaTypes']),
    ...mapState('globalModule', ['userContext']),
    districtTypes() {
      return this.leaTypes.filter(t=>t.code[0]=='D').map(t=> ({value: t.code, text: t.description}))
    }
  },
  props: {
    item: Object
  },
  methods: {
    charterChanged()
    {
      if (!this.item.isCharter) this.item.isConversionCharter = false
    }
  },
  mixins: [RequestItemMixin, ItemValidationMixin]
}
</script>

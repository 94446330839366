<template>
  <div v-if="currentStudent.studentInformation">
    <student-information-toolbar v-if="!currentStudent.isReadOnly" :can-save="validation.$dirty" :save-method="save.bind(null, 'StudentInformationResponse')" @cancel="cancel" />
    <h2 class="caption">
      Student Information
    </h2>
    <b-form>
      <b-form-row>
        <b-form-group label="Student Name" class="col-md-5 col-12">
          <b-form-input readonly :value="currentStudent.fullName()" />
        </b-form-group>
        <b-form-group label="Preferred Name" class="col-md-5 col-12">
          <b-form-input :readonly="currentStudent.isReadOnly" placeholder="Preferred Name" v-model="local.preferredName" />
        </b-form-group>
        <b-form-group label="Triand ID" class="col-md-2 col-12">
          <b-form-input readonly v-model="currentStudent.studentId" />
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group label="Current Grade" class="col-sm-2 col-4">
          <b-form-input readonly v-model="currentStudent.grade" />
        </b-form-group>
        <b-form-group label="DOB" class="col-sm-2 col-6">
          <b-form-input readonly :value="formatDate(currentStudent.birthDate, 'short-date')" />
        </b-form-group>
        <b-form-group label="Age" class="col-sm-2 col-4">
          <b-form-input readonly v-model="currentStudent.age" />
        </b-form-group>
        <b-form-group label="Graduation Year" class="col-sm-2 col-4">
          <b-form-input readonly v-model="currentStudent.graduationYear" />
        </b-form-group>
        <b-form-group label="Gender" class="col-sm-2 col-4">
          <b-form-select disabled v-model="currentStudent.gender" :options="staticMetadata.information.gender" />
        </b-form-group>
        <b-form-group label="GPA" class="col-sm-2 col-4">
          <b-form-input readonly v-model="currentStudent.gpa" />
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group label="Special Education" class="col-sm-2 col-4">
          <b-form-input readonly :value="formatYN(currentStudent.sped)" />
        </b-form-group>
        <b-form-group label="ELL" class="col-sm-2 col-4">
          <b-form-input readonly :value="formatYN(currentStudent.ell)" />
        </b-form-group>
        <b-form-group label="Meal" class="col-sm-2 col-4">
          <b-form-input readonly :value="MealStatuses[currentStudent.meal].text" />
        </b-form-group>
        <b-form-group label="Race" class="col-sm-2 col-6">
          <b-form-input readonly v-model="currentStudent.race" />
        </b-form-group>
        <b-form-group label="Ethnicity" class="col-sm-2 col-6">
          <b-form-input readonly :value="currentStudent.race === 'Hispanic' ? 'Hispanic' : 'Non-Hispanic'" />
        </b-form-group>
        <b-form-group label="First Generation in family" class="col-sm-2 col-6">
          <b-form-select :disabled="currentStudent.isReadOnly" v-model="local.firstGeneration" :options="staticMetadata.common.yesNo">
            <template #first>
              <b-form-select-option first :value="null" />
            </template>
          </b-form-select>
        </b-form-group>
      </b-form-row>
    </b-form>
    
    <h2 class="caption">
      Addresses / Contact Info
    </h2>
    <b-form>
      <b-form-row>
        <b-form-group label="Physical Address" class="col-md-6">
          <div class="form-control" disabled readonly style="height: auto;">
            {{ currentStudent.physicalAddress }}<br>
            {{ currentStudent.physicalCity }}, {{ currentStudent.physicalState }} {{ currentStudent.physicalZip }}<br>
          </div>
        </b-form-group>
        <b-form-group label="Mailing Address" class="col-md-6">
          <div class="form-control" disabled readonly style="height: auto;">
            {{ currentStudent.mailAddress }}<br>
            {{ currentStudent.mailCity }}, {{ currentStudent.mailState }} {{ currentStudent.mailZip }}<br>
          </div>
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group label="County of Residence" class="col-md-6">
          <b-form-select :disabled="currentStudent.isReadOnly" :options="countyOptions" v-model="local.countyResidence" />
        </b-form-group>
        <b-form-group label="Mailing County" class="col-md-6">
          <b-form-select :disabled="currentStudent.isReadOnly" :options="countyOptions" v-model="local.mailingCounty" />
        </b-form-group>
      </b-form-row>
      <b-form-row>
        <b-form-group label="Primary Phone" class="col-md-6">
          <b-form-input readonly :value="formatPhone(currentStudent.primaryPhone) || 'N/A'" />
        </b-form-group>
        <b-form-group label="Primary Email" class="col-md-6">
          <b-form-input readonly v-model="currentStudent.primaryEmail" />
        </b-form-group>
      </b-form-row>      
      <b-form-row>
        <b-form-group label="Alternate Phone" class="col-md-6">
          <b-form-input readonly :value="formatPhone(currentStudent.altPhone)" :formatter="formatPhone" />
        </b-form-group>
        <b-form-group label="Alternate Email" class="col-md-6">
          <b-form-input :readonly="currentStudent.isReadOnly" v-model="local.altEmail" />
        </b-form-group>
      </b-form-row>      
      <b-form-row>
        <b-form-group label="Parent/Guardian" class="col-md-6">
          <b-form-input readonly :value="currentStudent.parentName" />
        </b-form-group>
        <b-form-group label="Student Status" class="col-md-6">
          <b-form-select :disabled="currentStudent.isReadOnly" v-model="local.dependentStatus" :options="staticMetadata.information.dependentOptions" />
        </b-form-group>
      </b-form-row>            
    </b-form>
    <h2 class="caption">
      School Information
    </h2>
    <b-form-row v-if="school">
      <b-form-group label="District LEA" class="col-md-2 col-sm-6 col-12">
        <b-form-input readonly :value="district.number" />
      </b-form-group>
      <b-form-group label="District Name" class="col-md-10 col-sm-6 col-12">
        <b-form-input readonly :value="district.name" />
      </b-form-group>
      <b-form-group label="School LEA" class="col-md-2 col-sm-6 col-12">
        <b-form-input readonly :value="school.number" />
      </b-form-group>
      <b-form-group label="School Name" class="col-md-10 col-sm-6 col-12">
        <b-form-input readonly :value="school.name" />
      </b-form-group>
    </b-form-row>
    <h2 class="caption">
      Additional Notes
    </h2>
    <b-form-textarea :readonly="currentStudent.isReadOnly" v-model="local.additionalNotes" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { StudentComponentMixin } from './StudentComponentMixin'
import { formatDate, formatYN, formatPhone } from '@/helpers/formatters'
import { MealStatuses } from '@/modules/cte/components/careerCoach/enums'

export default {
  mixins: [StudentComponentMixin],
  computed: {
    ...mapGetters('globalModule', ['counties']),
    school() {
      return this.leaDictionary[this.currentStudent.schoolLEA]
    },
    district() {
      return this.school.parentLea
    },
    countyOptions() {
      return this.counties.map(o=>{ return { value: o.number, text: o.name } })
    },
    gradYearOptions() {
      var opts=[]
      for(var i = this.operativeAY - 3; i < this.operativeAY + 5; i++) { 
        opts.push(i) 
      }
      return opts

    },
    operativeAY() {
      return this.ay+2000
    }
  },
  data() {
    return {
      formatDate,
      formatYN,
      MealStatuses,
      formatPhone
    }
  },
  props: {
    ay: {
      type: Number,
      required: true,
      default: 0
    }
  },
  components: {
  }
}
</script>
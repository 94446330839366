<template>
  <div v-if="render">
    <div class="card mb-2" v-if="showFilter">
      <div class="card-header">
        Filter
      </div>
      <div class="card-body bg-light" v-if="filterOptions">
        <!-- add labels to each of the filters -->
        <div class="row">
          <div class="col-md-4">
            <label for="lastName">Teacher Last Name</label>
            <b-form-input id="lastName" v-model="filter.lastName" />
          </div>
          <div class="col-md-4">
            <label for="firstName">Teacher First Name</label>
            <b-form-input id="firstName" v-model="filter.firstName" />
          </div>
          <div class="col-md-4">
            <label for="rating">Impact Level</label>
            <b-form-select id="rating" v-model="filter.rating" :options="ratingOptions" />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-4">
            <label for="isNBC">Is NBC</label>
            <b-form-select id="isNBC" v-model="filter.isNBC" :options="nbcOptions" />
          </div>
          <div class="col-md-4">
            <label for="schoolLetterGrade">School Letter Grade</label>
            <b-form-select id="schoolLetterGrade" v-model="filter.schoolLetterGrade" :options="schoolLetterGradeOptions" />
          </div>
          <div class="col-md-4 text-right align-items-start">
            <br>
            <b-button variant="primary" @click="resetFilter">
              Reset Filter
            </b-button>
          </div>
        </div>        
      </div>
    </div>
    <paginated-table :items="filteredData" :fields="fields" sort-by="l" :sort-asc="true" :per-page="20" striped hover :show-filter="showFilter">
      <template v-slot:cell(rating)="data">
        <span class="fa text-success fa-check-circle" v-if="data.value == 2" />
      </template>
    </paginated-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PaginatedTable from '../../../PaginatedTable.vue'

export default {
  data() {
    return {
      filter: null,
      ratingOptions: [
        { text: '(select)', value: null },
        { text: 'Not high impact', value: '1' },
        { text: 'High Impact', value: '2' }
      ],
      nbcOptions: [
        { text: '(select)', value: null },
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ],
      schoolLetterGradeOptions: [
        { text: '(select)', value: null },
        { text: '(exempt)', value: -1 },
        { text: 'A', value: 'A' },
        { text: 'B', value: 'B' },
        { text: 'C', value: 'C' },
        { text: 'D', value: 'D' },
        { text: 'F', value: 'F' }
      ],
      fields: [
      {
        key: 'l',
        sortable: true,
        label: 'Teacher Last Name',
      },
      {
        key: 'f',
        sortable: true,
        label: 'Teacher First Name'
      },
      {
        key: 'numberStudents',
        sortable: true,
        label: 'Number of Students'
      },
      {
        key: 'valueAddedScore',
        sortable: true,
        label: 'Value-Added Score'
      },
      {
        key: 'rating',
        sortable: true,
        label: 'High Impact',
        sortByFormatted: true,
        filterByFormatted: true
      },
      {
        key: 'isNBC',
        sortable: true,
        label: 'NBC',
        formatter: (value) => {
          return value ? 'Yes' : 'No'
        },
        sortByFormatted: true,
        filterByFormatted: true
      },
      {
        key: 'schoolLetterGrade',
        sortable: true,
        label: 'School Letter Grade',
        formatter: (value) => {
          return value == null ? '(exempt)' : value
        },
        sortByFormatted: true,
        filterByFormatted: true
      }
    ]
    }
  },
  props: {
    showFilter: {
      type: Boolean,
      default: false
    },
    score: {
      type: Number,
      required: false,
      default: null
    },
    useFilterFromStore: {
      type: Boolean,
      required: false,
      default: false
    },
    render: {
      type: Boolean,
      default: true
    },
  },
  components: {
    PaginatedTable
  },
  methods: {
    getEffectivenessLevelName(level) {
      return level == 2 ? 'High Impact' : ''
    },
    excelExportData() {  
      return {
        name: 'Teacher Data',
        items: this.filteredData.map(item => {
            return {
              'Teacher Last Name': item.l,
              'Teacher First Name': item.f,
              'Grade': item.grade ?? '(All)',
              'Number of Students': item.numberStudents,
              'Value-Added Score': item.valueAddedScore,
              'Rating': this.getEffectivenessLevelName(item.rating),
              'Is NBC': item.isNBC ? 'Yes' : 'No',
              'School Letter Grade': item.schoolLetterGrade
            }
          }
        )
      }
    },
    resetFilter() {
      this.filter = {
        grade: null,
        rating: null,
        isNBC: null,
        schoolLetterGrade: null,
        lastName: null,
        firstName: null
      }
    }
  },
  mounted() {
    this.resetFilter()
  },
  computed: {
    ...mapState('teacherVAMModule', ['teacherVAMData']),
    filterOptions() {
      return this.useFilterFromStore ? this.$store.state.teacherVAMModule.filter : this.filter
    },
    filteredData() {
      return this.teacherVAMData.filter(item => {
        // when we have a score passed in, we want to filter by the floor of the valueAddedScore
        // passess if floor(valueAddedScore) >= this.score && floor(valueAddedScore) < this.score + 1
        if (this.score && (Math.floor(item.valueAddedScore) < this.score
            || Math.floor(item.valueAddedScore) >= this.score + 1)) {
          return false;
        }

        // if this.score has a value, we only want rows that don't have a grade
        if (this.score && item.grade) {
          return false;
        }       

        if (this.filterOptions) {
          if (this.filterOptions.grade) {
            const grade = item.grade || null
            if (grade && grade != this.filterOptions.grade) {
              return false;
            }
            else if (grade == null && this.filterOptions.grade != '-1') {
              return false;
            } 
          }

          if (this.filterOptions.rating && item.rating != this.filterOptions.rating) {
            return false;
          }

          if (this.filterOptions.schoolLetterGrade) {
            const schoolLetterGrade = item.schoolLetterGrade || null
            if (schoolLetterGrade && schoolLetterGrade != this.filterOptions.schoolLetterGrade) {
              return false;
            }
            else if (schoolLetterGrade == null && this.filterOptions.schoolLetterGrade != '-1') {
              return false;
            }
          }

          if (this.filterOptions.lastName && !item.l.toLowerCase().includes(this.filterOptions.lastName.toLowerCase())) {
            return false;
          }

          if (this.filterOptions.firstName && !item.f.toLowerCase().includes(this.filterOptions.firstName.toLowerCase())) {
            return false;
          }

          if (this.filterOptions.isNBC != null && item.isNBC != this.filterOptions.isNBC) {
            return false;
          }
        }
        
        return true;
      })
    }
  },
  watch: {
    filter: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.$store.commit('teacherVAMModule/setFilter', val)
      },
    }
  }
}
</script>
<template>
  <loadable-chart-card
    :load-data="loadData"
    class="page-break-before"
    card-style="overflow-y:auto"
    v-if="chartVisible"
    :show-tooltip="true"
    :tooltip-content="tooltipContent"
    :tooltip-id="tooltipId">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()">
          <template v-slot:head(teacherDays)="data">
            <div>
              <custom-tooltip
                content="The product of Total Teacher Count x Instructional Days"
                :element-id="`${uniqueId}-breakdown-${data.label}`" :label="data.label" />
            </div>
          </template>
          <template v-slot:head(totalTeacherCount)="data">
            <div>
              <custom-tooltip
                content="Total Number of Teachers in the LEA"
                :element-id="`${uniqueId}-breakdown-${data.label}`" :label="data.label" />
            </div>
          </template>
          <template v-slot:head(numberOfTeachersTakingLeave)="data">
            <div>
              <custom-tooltip
                content="The number of unique teachers taking the specified type of leave"
                :element-id="`${uniqueId}-breakdown-${data.label}`" :label="data.label" />
            </div>
          </template>
          <template v-slot:head(percentOfTotalLeave)="data">
            <div>
              <custom-tooltip
                content="The weight this type of leave factored into the total amount of leave taken across the LEA"
                :element-id="`${uniqueId}-breakdown-${data.label}`" :label="data.label" />
            </div>
          </template>
          <template v-slot:head(leaveDays)="data">
            <div>
              <custom-tooltip
                content="The total number of days taken for the specified type of leave"
                :element-id="`${uniqueId}-breakdown-${data.label}`" :label="data.label" />
            </div>
          </template>
          <template v-slot:head(absenteeRate)="data">
            <div>
              <custom-tooltip
                content="Leave Days / Teacher Days x 100"
                :element-id="`${uniqueId}-breakdown-${data.label}`" :label="data.label" />
            </div>
          </template>
        </chart-data-table>
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import LoadableChartCard from '../../LoadableChartCard'
import { ChartMixin } from '../../ChartMixin'
import { credits } from '../../../helpers/credits'
import shortid from 'shortid'

export default {
  name: 'employee-leave-by-breakdown-chart',
  data() {
    return {
      uniqueId: null,
      animationDuration: this.$printMode ? 0 : 1000,
      leaveType: null,
      tooltipContent: '<h5>Leave rate formula</h5><p>Number of Leave Days/Number of Teachers Days</p><p>Where number Teacher Days is:<br/> Number of Teachers * Number of Instructional Days</p>',
      tooltipId: 'leaveDaysFormulaTooltip',
      chartOptions: {
        chart: {
          type: 'pie',
          paddingBottom: 50,
          height: this.$printMode ? 675 : 400
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              format:
                '{point.name}: <br/>{point.y} days - {point.percentage:.2f} %'
            },
            showInLegend: true
          }
        },
        legend: this.$printMode
          ? {
              maxHeight: 450
            }
          : {
              layout: 'vertical',
              backgroundColor: '#FFFFFF',
              align: 'right',
              verticalAlign: 'top',
              floating: true,
              rtl: true,
              x: -40,
              y: 60
            },
        credits: credits.SIS,
        title: {
          text: this.chartTitle
        },
        series: [],
        xAxis: {
          title: {
            text: 'Leave Type'
          },
          type: 'category'
        },
        yAxis: {
          title: {
            text: 'Leave Type Breakdown'
          },
          endOnTick: false,
          minPadding: 5
        },
        tooltip: {
          pointFormat: '{point.y} days - {point.percentage:.2f} %<br>'
        }
      }
    }
  },
  components: {
    LoadableChartCard
  },
  mixins: [ChartMixin],
  methods: {
    dataItems() {
      var data = this.leaveData
      if (this.charterOnlyMode && this.leaveData.length > 1) return []

      return data.flatMap(item => {
        return item.rows.map(row => {
          return this.stateView == 1
            ? {
                scopeLEA: row.scopeLEA,
                name: row.name,
                leaveCode: row.leaveCode,
                leaveDescription: row.leaveDescription,
                teacherDays: row.teacherDays,
                totalTeacherCount: row.totalLEATeacherCount,
                numberOfTeachersTakingLeave: row.numberOfTeachersTakingLeave,
                leaveDays: row.leaveDays,
                percentOfTotalLeave:
                  (
                    (row.leaveDays /
                      item.rows
                        .map(r => r.leaveDays)
                        .reduce((sum, m) => sum + m)) *
                    100
                  ).toFixed(2) + '%',
                absenteeRate: row.absenteeRate + '%',
                leaCategory: row.leaCategory == '1' ? 'Charter' : 'Regular'
              }
            : {
                scopeLEA: row.scopeLEA,
                name: row.name,
                leaveCode: row.leaveCode,
                leaveDescription: row.leaveDescription,
                teacherDays: row.teacherDays,
                totalTeacherCount: row.totalLEATeacherCount,
                numberOfTeachersTakingLeave: row.numberOfTeachersTakingLeave,
                leaveDays: row.leaveDays,
                percentOfTotalLeave:
                  (
                    (row.leaveDays /
                      item.rows
                        .map(r => r.leaveDays)
                        .reduce((sum, m) => sum + m)) *
                    100
                  ).toFixed(2) + '%',
                absenteeRate: row.absenteeRate + '%'
              }
        })
      })
    }
  },
  computed: {
    chartVisible() {
      if (this.charterOnlyMode) {
        return this.stateView == '1'
      }
      return true
    }
  },
  props: {
    loadData: Promise,
    leaveData: Array,
    viewType: String,
    chartTitle: String,
    stateView: String,
    charterOnlyMode: Boolean
  },
  watch: {
    leaveData(newData) {
      this.chartOptions.series = []
      var seriesData = []
      var index = this.charterOnlyMode ? 1 : 0
      if (newData.length > index) {
        seriesData = newData[index].rows.map(row => {
          return {
            name: `(${row['leaveCode']}) - ${row['leaveDescription']}`,
            y: row['leaveDays']
          }
        })
        this.chartOptions.series.push({
          data: seriesData,
          name: `${newData[index].name} ${
            this.viewType == '0' && this.stateView == 1
              ? newData[index].rows[0].leaCategory == 1
                ? ' - Charter'
                : ' - Regular'
              : ''
          }`
        })
      }
    }
  },
  mounted() {
    this.uniqueId = shortid.generate()
  }
}
</script>

import Vue from 'vue'
import { ChartMixin } from '../../ChartMixin'
import { TableMixin } from '../../TableMixin'
import CustomTooltip from '../../CustomTooltip.vue'
import { LicenseExceptionTypes } from './LicenseExceptionTypes'

const LicenseExceptionIndividualsTable = Vue.extend({
    name: 'license-exceptions-individuals-widget',
    data() {
        return {
            perPage: this.$printMode ? Number.MAX_SAFE_INTEGER : 10,
            currentPage: 1,
            filter: null,
            rowCount: 1,
            showUnverifiedALPOnly: false,
            selectedExceptionType: null,
            animationDuration: 1000,
            fields: [
                { key: 'employeeId', sortable: true },
                { key: 'fullName', sortable: true },
                { key: 'applicantId', sortable: true, label: "Class Case Id" },
                { key: 'schoolLEA', sortable: true },
                { key: 'courseNumber', sortable: true },
                { key: 'sectionNumber', sortable: true },
                { key: 'fullCourseDescription', sortable: true },
                { key: 'exceptionType', sortable: false },
                { key: 'alpVerfifiedInAELS', label: 'ALP Verified in AELS', sortable: false },
                { key: 'alpCaseId', label: 'ALP Case Id'}
            ]
        }
    },
    /* html */
    template: `
    <div class="w-100 p-2">
        <loadable-chart-card v-bind:load-data="loadData" v-bind:enableDataToggle="false" card-style="">
            <table-control-widget :per-page="perPage" v-on:filter-changed="filter=$event" v-on:page-length-changed="perPage=$event">
            </table-control-widget>
            <div class='row'>
                <div class='col-md-6 d-print-none'>
                    <b-form-checkbox v-model="showUnverifiedALPOnly" name="check-button" switch>
                        Show only ALP exceptions with Missing Case IDs or Unverified ALP Exceptions
                    </b-form-checkbox>
                </div>
                <div class='col-md-6'>
                    <b-form-select v-model="selectedExceptionType" :options='licenseExceptionTypes' value-field='field' text-field='name' style="margin-bottom: 10px"/>
                </div>
            </div>
            <b-table id="license-exceptions-table"
                :items="licenseExceptionIndividuals"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                :filter="combinedFilter"
                :filter-function="applyFilter"
                @filtered="onFiltered"
                :tbody-tr-class='rowClass'
                small>
                <template v-slot:cell(schoolLEA)="data">
                    <div class="d-inline">
                        <a href="#" v-on:click="goToLea(data.value)" 
                            v-bind:id="data.index + '-' + data.value">{{ data.value }}</a>
                        &nbsp;
                        <lea-tooltip v-bind:target-selector="data.index + '-' + data.value" v-bind:lea-number="data.value">
                        </lea-tooltip>
                    </div>
                </template>
                <template v-slot:head(applicantId)="data">
                  <custom-tooltip :elementId="data.field.key + '-tooltip'" content="The Case Id specified for the course section in eSchool" :label="data.label" />
                </template>
                <template v-slot:head(alpCaseId)="data">
                  <custom-tooltip :elementId="data.field.key + '-tooltip'" content="The Case Id associated with the educator's Alternative Learning Plan (if any) in AELS" :label="data.label" />
                </template>
                <template v-slot:cell(applicantId)="data">
                  <aels-case-id-link :case-id="data.value.toString()" />
                </template>
                <template v-slot:cell(alpCaseId)="data">
                  <aels-case-id-link :case-id="data.value.toString()" />
                </template>
                <template v-slot:head(alpVerfifiedInAELS)="data">
                    <custom-tooltip :elementId="data.field.key + '-tooltip'" 
                      content="The educator has an Alternative Learning Plan on-file in AELS. The ALP is referenced by the educator's SSN and/or Case Id. This field is only applicable to ALP Exception Types"
                      :label="data.label" />
                </template>
                <template v-slot:cell(alpVerfifiedInAELS)="data">
                    <div v-if="data.item.exceptionType == 'ALP'">
                        <span class="fa" v-bind:class="{'text-success fa-check-circle': data.value, 'text-danger fa-times-circle': !data.value}"></span>
                    </div>
                    <div v-else>
                        <span class="text-secondary">N/A</span>
                    </div>
                </template>
                <template slot="empty" slot-scope="scope">
                    <h4>No individuals found</h4>
                </template>
            </b-table>            
            <table-pagination-widget 
                v-bind:total-rows-count="rowCount" 
                v-bind:page-length="perPage" 
                v-on:page-changed="currentPage=$event">
            </table-pagination-widget>
        </loadable-chart-card>
        <div class='row d-print-none'>
            <div class='col-md-12'>
                <div class='card m-3'>
                    <div class='card-body'>
                        <ul style="list-style:none;" class="p-1">
                            <li><span class='bg-danger text-white'>Red highlighted rows</span>: Indicate discrepancy
                                between SIS and AELS Case Id for the educator, which is a symptom of the educator's SSN in eFinance matching a different educator's SSN in AELS.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>`,
    mixins: [ChartMixin, TableMixin],
    components:{
        CustomTooltip,
        LicenseExceptionTypes
    },
    computed: {
        leaDictionary() {
            return this.$store.state.globalModule.leaContext.LeaDictionary
        },
        combinedFilter() {
            return {
                alpFilter: this.showUnverifiedALPOnly,
                exceptionType: this.selectedExceptionType,
                textFilter: this.filter
            }
        },
        licenseExceptionIndividuals() {
            return this.$store.state.hcptModule.licenseExceptionIndividualsWithALP
        },
        licenseExceptionTypes(){
            return LicenseExceptionTypes
        }
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.currentPage = 1
            this.rowCount = filteredItems.length
        },
        applyFilter(row, filter) {
            var passes = true;

            if (filter.alpFilter) {
                passes = (row.exceptionType == 'ALP' && (!row.alpVerfifiedInAELS || !row.applicantId || row.applicantId == ''))
            }
            
            if(passes && filter.exceptionType){
                passes = row.exceptionType == filter.exceptionType
            }

            if (passes && filter.textFilter) {
                var re = new RegExp(filter.textFilter, "i");
                passes = re.test(row.fullName);
            }

            return passes;
        },
        rowClass(item) {
            if (!item) return;

            if (item.alpVerfifiedInAELS && item.applicantId != item.alpCaseId){
                return 'bg-danger text-white'
            }
        },
        goToLea(leaNumber) {
            const lea = this.leaDictionary[leaNumber]
            window.scrollTo(0, 0)
            this.$store.dispatch('globalModule/changeSelectedLea', lea)
        },
        excelExportData() {
            return {
                name: 'License Exception Individuals',
                items: this.licenseExceptionIndividuals.map(item => {
                    return {
                        'Employee ID': item.employeeId,
                        'Case ID': item.applicantId,
                        'School LEA': item.schoolLEA,
                        'Course Number': item.courseNumber,
                        'Section Number': item.sectionNumber,
                        'Full Course Description': item.fullCourseDescription,
                        'Exception Type': item.exceptionType,
                        'ALP Verified In AELS': item.exceptionType == 'ALP' ? (item.alpVerfifiedInAELS ? 'YES' : 'NO') :'N/A'
                    }
                })                
            }
        }
    },
    watch: {
        licenseExceptionIndividuals: {
            immediate: true,
            handler(individuals) {
                this.rowCount = individuals.length
            }
        }
    }
})

export { LicenseExceptionIndividualsTable }
<template>
  <div>
    <div v-if="!bulkAdding && selectedLea.type < 3 && selectedLea.number != 'MYLEAS'">
      <h5 v-if="effectiveRosterDate"> 
        Roster Information as of {{ effectiveRosterDate.label }}
      </h5>
      <roster-filter v-model="rosterGradeFilter" :ay="ay" />
      <Promised :promise="loadData">
        <template v-slot:pending>
          <div class="align-items-center justify-content-center" style="height: 100%; width: 100%">
            <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading Student Roster..." />
          </div>
        </template>
        <template v-slot:default>
          <b-form-row class="mb-2">
            <b-col>
              <b-btn size="xs" v-if="currentBulkVisit != undefined && selectedStudents.length > 0" @click="addStudentsToBulk">
                Add {{ selectedStudents.length }} Students to Group Visit
              </b-btn>
              <b-btn size="xs" v-if="currentBulkVisit == undefined && selectedStudents.length > 1" @click="bulkAdding=true">
                Add {{ selectedStudents.length }} Students to Group Visit
              </b-btn>              
              <span class="text-danger" v-if="currentBulkVisit != undefined">
                * select students to add to group visit
              </span>
              <span class="text-danger" v-else-if="selectedStudents.length < 2">
                * select two or more students from the list to enable group visit creation
              </span>
              <b-alert v-if="bulkAddedMessage" variant="success" dismissible>
                {{ bulkAddedMessage }}
              </b-alert>
            </b-col>
          </b-form-row>
          <paginated-table v-if="rosterSortOption" small :items="roster" :fields="fields" :sort-by="rosterSortOption.sortBy" :sort-asc="rosterSortOption.ascending" @sort-changed="onSortChanged" :per-page="50">
            <template #head(action)>
              <div>
                <b-form-checkbox @change="multiselect" />
              </div>
            </template>
            <template v-slot:cell(ell)="data">
              <pretty-checkbox :value="data.item.ell == 'Y'" />
            </template>            
            <template v-slot:cell(sped)="data">
              <pretty-checkbox :value="data.item.sped == 'Y'" />
            </template>
            <template #cell(action)="data">
              <b-form-checkbox name="student-select" v-model="selectedStudents" :value="{studentId: data.item.studentId, grade: data.item.grade}" />
            </template>
            <template v-slot:cell(studentName)="data">
              <div class="text-nowrap">
                <router-link :to="{name: 'CTE/CareerCoach/StudentPage', params: { studentId: data.item.studentId }}">
                  {{ data.value }}
                </router-link>
              </div>
            </template>
            <template #cell(schoolLEA)="data">
              <div>
                <div class="d-inline">
                  <a
                    :href="data.value"
                    class="btn-link"
                    @click.prevent="goToLea(data.value)"
                    :id="data.index + '-' + data.value">
                    {{ data.value }}
                  </a>
                &nbsp;
                  <lea-tooltip
                    :target-selector="data.index + '-' + data.value"
                    :lea-number="data.value"
                    :label="data.label" />
                </div>
              </div>
            </template>
            <template #cell(studentId)="data">
              <router-link :to="{name: 'CTE/CareerCoach/StudentPage', params: { studentId: data.value, lea: data.item.lea } }">
                <b-badge variant="secondary">
                  {{ data.value }}
                </b-badge>
              </router-link>
            </template>
          </paginated-table>
        </template>
        <template v-slot:rejected="error">
          {{ error }}
        </template>
      </Promised>
    </div>
    <b-alert v-if="selectedLea.type >= 3" show class="text-center" variant="warning">
      Student Roster is available for District and School LEAs. Please change LEA selection to load the roster.
    </b-alert>
    <b-alert v-else-if="selectedLea.number == 'MYLEAS'" show class="text-center" variant="warning">
      Student Roster is only available for School LEAs. Please change LEA selection to load the roster.
    </b-alert>
    <b-card v-else-if="bulkAdding" title="Add Visit for Selected Students" :sub-title="`${selectedStudents.length} students selected for this visit.`">
      <visit @cancel="bulkAdding=false" @save="saveVisit" :grades="selectedGrades" />
    </b-card>
  </div>
</template>

<script>
import PaginatedTable from '@/components/PaginatedTable.vue'
import RosterFilter from './RosterFilter.vue'
import { Types } from '@/modules/cte/stores/careercoach'
import { mapState, mapActions } from 'vuex'
import { formatDate } from '@/helpers/formatters'
import Visit from './visits/Visit'
import { Promised } from 'vue-promised'
import CardStateIcon from '@/components/CardStateIcon'
import LeaTooltip from '@/components/LeaTooltip.vue'
import PrettyCheckbox from '@/components/PrettyCheckbox.vue'
import { MealStatuses } from '../enums'

export default {
  data() {
    return {
      loadData: null,
      bulkAddedMessage: '',
      bulkAdding: false,
      selectedStudents: [],
      gradeLevelFilter: this.gradeLevel || null,
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState(Types.path, [Types.state.students, Types.state.effectiveRosterDate, Types.state.currentBulkVisit]),
    
    roster() {
      return this.students.map(s => Object.assign({ selected: false, ...s }))
    },
    rosterGradeFilter: {
      get() {
        return this.$store.state.cte.careercoach.rosterGradeFilter
      },
      set(value) {
        this.$store.commit(Types.mutations.setRosterGradeFilter, value)
      }
    },
    rosterSortOption: {
      get() {
        return this.$store.state.cte.careercoach.rosterSortOption
      },
      set(value) {
        this.$store.commit(Types.mutations.setRosterSortOption, value)
      }
    },
    selectedGrades() {
      return [...new Set(this.selectedStudents.map(sg => sg.grade))].sort()
    },
    fields() {
      let fields = [
        { key: 'action' },
        { key: 'studentId', sortable: true },
        { key: 'studentName', sortable: true, sortByFormatted: true, formatter: (v,k,item) => `${item.lastName}, ${item.firstName}` },
        { key: 'schoolLEA', sortable: true },
        { 
          key: 'grade', 
          sortable: true, 
          formatter: (g) => {
            return g==255 ? "G" : g
          }
        },
        {
          key: 'birthDate',
          label: 'DOB',
          sortable: true,
          formatter: val => {
            return formatDate(val, 'short-date')
          }
        },
        { key: 'age', sortable: true },
        { key: 'graduationYear', sortable: true, label: 'Grad Year' },
        { key: 'gender', sortable: true },
        { key: 'gpa', label: 'GPA', sortable: true },
        { key: 'ell', label: 'ELL', sortable: true },
        { key: 'meal', sortable: true, formatter: val => MealStatuses[val]?.text },
        { key: 'sped', label: 'SPED', sortable: true },
        { key: 'initialContact', 
          sortable: true,
          formatter: val => {
            return formatDate(val, 'short-date')
          } 
        },
        { 
          key: 'lastVisit', 
          sortable: true,
          formatter: val => {
            return formatDate(val, 'short-date')
          }
        },
        { key: 'action', label: '' }
      ]
      fields = this.userContext.isCTEReadOnly ? fields.filter(f => f.key != 'action') : fields
      
      if (this.userContext.isCTECoordinator) {
        fields = fields.filter(f => f.key != 'birthDate')
          .filter(f => f.key != 'sped')
          .filter(f => f.key != 'meal')
          .filter(f => f.key != 'ell')
          .filter(f => f.key != 'gpa')
      }

      return fields
    }
  },
  components: {
    PaginatedTable,
    RosterFilter,
    Visit,
    Promised,
    CardStateIcon,
    LeaTooltip,
    PrettyCheckbox,
  },
  methods: {
    ...mapActions(Types.path, [Types.actions.saveBulkVisit, Types.actions.getGraduationYears]),
    loadRosterData() {
      if (this.selectedLea && this.selectedLea.number && this.selectedLea.type < 3 && this.selectedLea.number != 'MYLEAS') {
        var payload = { lea: this.selectedLea.number }
        if (this.rosterGradeFilter) {
          payload.gradeLevel = this.rosterGradeFilter.grade
          payload.graduationYear = this.rosterGradeFilter.graduationYear
        }
        this.loadData = this.$store.dispatch(Types.actions.getStudents, payload)
      }
    },
    multiselect(value) {
      this.selectedStudents = []
      if (value) {
        this.roster.forEach(r => this.selectedStudents.push({ studentId: r.studentId, grade: r.grade }))
      }
    },
    async saveVisit(visit) {
      await this.saveBulkVisit(Object.assign(visit, { lea: this.$route.query.lea, studentsAndGrades: this.selectedStudents }))
      this.$router.push({name: 'CTE/CareerCoach/Visits'})

      this.bulkAdding = false
      var count = this.selectedStudents.length
      this.bulkAddedMessage = `Group Visits Added to ${count} students.`
      this.selectedStudents = []

    },
    addStudentsToBulk() {
      this.$store.dispatch(Types.actions.addStudentsToBulkVisit, { students: this.selectedStudents, lea: this.$route.query.lea })
      this.$router.replace({ name: 'CTE/CareerCoach/Visits' })
    },
    onSortChanged(value){
      this.rosterSortOption = {sortBy: value.sortBy, ascending: !value.sortDesc}
    }
  },
  props: {
    ay: {
      type: Number
    },
    gradeLevel: {
      type: String,
      default: null
    }
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler() {
        this.loadRosterData()
      }
    },
    rosterGradeFilter() {      
      this.loadRosterData()
    }
  },
  mounted() {
    this.getGraduationYears()
    
    if (this.currentBulkVisit) {
      this.rosterGradeFilter = this.currentBulkVisit.grade
    }
    else this.loadRosterData()
  }
}
</script>
<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { InstructionalOptionMixin } from './InstructionalOptionMixin'
import { InstructionalOptions } from './instructionalOptions'
import { credits } from '../../../helpers/credits'
import sumBy from 'lodash.sumby'
import Highcharts from 'highcharts'

export default {
  name: 'student-count-percent-chart',
  data() {
    return {
     animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'pie',
          plotBackgroundColor: '#f8f9fa',
          plotShadow: false,
        },
        title: {
          text: 'Student Instructional Option'
        },          
        legend: {
          enabled: false
        },
        credits: credits.InsightsNightlyDataPulls,
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              formatter: function() {
                if (this.y > 0) {
                  return this.point.name + ': ' + this.point.y + ' (' + Highcharts.numberFormat(this.point.percentage, 1) + ' %)'
                }
              }
            },
            showInLegend: true
          }
        },
        xAxis: {
          labels: {
            enabled: false
          }
        }
      }
    }
  },
  mixins: [ChartMixin, InstructionalOptionMixin],
  computed: {
    instructionalOption() {
      return this.$store.state.onDemandModule.instructionalOptions
    }
  },
  watch: {
    instructionalOption: {
      immediate: true,
      handler(newData) {
        if (newData && this.mode == 'current') {
          this.chartOptions.series = [{
            data: InstructionalOptions.map(option => {
              return {
                name: option.name,
                y: sumBy(newData, (i) => {
                  return i.instructionalChoice == option.value ? i.studentCount : 0
                }),
                color: option.color
              }
            }),         
            name: 'Student Instructional Option',
          }]
        }
      }
    },
    metricSnapshots: {
      immediate: true,
      handler(newData) {
        if (newData && newData.byInstructionalOption && this.mode == 'trend') {
          this.chartOptions.plotOptions = {
            column: {
              minPointLength: 3
            },
            series: {
              stacking: 'normal'
            }
          }
          this.chartOptions.chart = { type: 'column' }
          this.chartOptions.legend.enabled = true
          this.setTrendedSeries(newData.byInstructionalOption, InstructionalOptions)
        }
      }
    }
  },
  methods: {
    dataItems() {
      if (this.mode == 'current') {
        var rows = {}
        this.instructionalOption.forEach(row => {
          rows[row.lea] = { ...rows[row.lea] }      
          if (!rows[row.lea][row.instructionalChoice]) rows[row.lea][row.instructionalChoice] = 0
          rows[row.lea][row.instructionalChoice] += row.studentCount
        })
        return Object.keys(rows).sort().map(k => {return { 'LEA': this.leaFormatter(k), 'Onsite': rows[k]["1"] || 0, 'Virtual': rows[k]["2"] || 0, 'Hybrid': rows[k]["3"] || 0, 'N/A': rows[k]["4"] || 0, 'No Value': rows[k]["0"] || 0  }})
      }
      else
        return this.pivotTrended(this.metricSnapshots.byInstructionalOption, InstructionalOptions)
    },
    getOption(number) {
      return InstructionalOptions.find(o => o.value === number)
    }
  }
}
</script>
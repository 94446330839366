<template>
  <div v-if="local">
    <student-information-toolbar v-if="!currentStudent.isReadOnly" :can-save="validation.$dirty" :save-method="save.bind(null, 'InitialNeedsAssessmentResponse')" @cancel="cancel" />
    <workshop-and-activities class="mb-4" v-model="local.workshopAndActivities" :readonly="currentStudent.isReadOnly" />
    <plans-after-high-school v-model="local.plansAfterHighSchool" :readonly="currentStudent.isReadOnly" />
  </div>
</template>

<script>
import WorkshopAndActivities from './WorkshopAndActivities.vue'
import PlansAfterHighSchool from './PlansAfterHighSchool.vue'
import { StudentComponentMixin } from './StudentComponentMixin'

export default {
  mixins: [StudentComponentMixin],
  components: {
    WorkshopAndActivities,
    PlansAfterHighSchool,
  },
  data() {
    return {}
  }
}
</script>
import {
  LeaAssignment
} from './leaAssignment';

import { leams } from './leams'

export class ToolsModule {

  constructor() {
  }

  namespaced = true

  state = {}

  modules = {
    leaAssignment: new LeaAssignment(),
    leams: new leams()
  }
}
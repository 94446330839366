<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { credits } from '../../../helpers/credits'

const LetterGrades = ['F', 'D', 'C', 'B', 'A']

export default {
  name: 'essa-letter-grade-chart',
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'line'
        },
        credits: credits.MSI,
        title: {
          text: 'School Letter Grade'
        },
        legend: {
          enabled: true
        },
        series: [
          {
            name: 'Letter Grade'
          }
        ],
        xAxis: {
          title: {
            text: 'School Year'
          },
          type: 'category'
        },
        yAxis: {
          title: {
            text: 'Score'
          },
          type: 'category',
          categories: LetterGrades
        },
        tooltip: {
          pointFormatter: function() {
            return this.series.name + ': <b>' + LetterGrades[this.y] + ' <br/>'
          }
        }
      }
    }
  },
  mixins: [ChartMixin],
  computed: {
    essaindex() {
      return this.$store.state.overviewModule.essaindex
    }
  },
  watch: {
    essaindex(newData) {
      this.chartOptions.series[0].data = newData.map(row => {
        return {
          x: (row.fiscalYear % 1990) + 1990,
          y: LetterGrades.indexOf(row.schoolLetterGrade)
        }
      })
    }
  },
  methods: {
    excelExportData() {
      return this.defaultExcelExportData()
    },
    dataItems() {
      return this.chartOptions.series[0].data.map(item => {
        return { SchoolYear: item.x, Grade: LetterGrades[item.y] }
      })
    }
  }
}
</script>

<template>
  <div>
    <info-panel />

    <div v-if="leaType != LeaTypes.Coop" class="card outer-card">
      <div class="card-body">
        <growth-filter />
        <lea-growth-trend />
      </div>      
    </div>
    <div v-else class="alert alert-warning">
      No data available at Coop level. Please select a school or district to view report
    </div>
  </div>
</template>

<script>
import { PageMixin } from '../../../PageMixin'
import InfoPanel from './InfoPanel.vue'
import GrowthFilter from '../GrowthFilter.vue'
import LeaGrowthTrend from './LeaGrowthTrend'

export default {
  name: 'question4',
  data() {
    return {}
  },
  components: {
    LeaGrowthTrend,
    GrowthFilter,
    InfoPanel
  },
  
  mixins: [PageMixin],
  computed: {},
  methods: {}
}
</script>
const ProposalStatuses =
{
  Draft: 0,
  ReturnedToApplicant: 1,
  PendingProgramManagerApproval: 10,
  PendingProgramAdministratorApproval: 11,
  PendingProgramSupervisorApproval: 12,
  Approved: 100,
  Rejected: 101,
  Canceled: 255,
},
CostTypeCategories = {
  None: 0,
  Salaries: 1,
  Benefits: 2,
  PurchasedProfessionalAndTechnicalServices: 3,
  OtherPurchasedServices: 4,
  SuppliesMaterials: 5,
  PropertyOrEquipment: 6,
  OtherEquipment: 7,
  AdministrativeIndirectCosts: 8
}
export { ProposalStatuses, CostTypeCategories }
<template>
  <div>
    <loadable-chart-card :load-data="loadData" card-style="overflow-y: auto" @chart-toggled="chartMode = $event">
      <template>
        <div class="chartElem" v-show="chartMode">
          <employee-leave-days-calendar-chart :state-view="stateView" @day-selected="chartMode = false" />
        </div>
        <div v-show="!chartMode">
          <employee-leave-days-details-table />
        </div>
      </template>
    </loadable-chart-card>
  </div>
</template>

<script>
import EmployeeLeaveDaysCalendarChart from './EmployeeLeaveDaysCalendarChart'
import EmployeeLeaveDaysDetailsTable from './EmployeeLeaveDaysDetailsTable'
import LoadableChartCard from '../../LoadableChartCard'
import { mapState } from 'vuex'

export default {
  data(){
    return {
      chartMode: true
    }
  },
  computed:{
    ...mapState('hcptModule', ['employeeLeaveDays']),
    ...mapState('globalModule',['leaContext'])
  },
  components:{
    EmployeeLeaveDaysCalendarChart,
    EmployeeLeaveDaysDetailsTable,
    LoadableChartCard
  },
  props:{
    mode: {
      type: String,
      default: 'all'
    },
    stateView: String,
    leaveType: String,
    loadData: Promise
  },
  watch:{
    leaveType() {
      this.getData()
    }
  }

}
</script>

import Vue from 'vue'
import { mapState } from 'vuex'

const EmployeeClassificationSelector = Vue.extend({
    name: 'employee-classification-selector',
    /* html */
    template: `
    <div class="row mt-4">
        <div class="col-sm-12">    
            <div class="card bg-light pt-3">
                <b-form-group class="ml-3 mr-3">
                    <b-form-radio-group id="classification"
                        v-model="classification"
                        buttons
                        button-variant='outline-primary'
                        name="classification"
                        :disabled="false">
                        <b-form-radio value="all">All Employees</b-form-radio>
                        <b-form-radio value="isClassifiedStaff">Classified Employees</b-form-radio>
                        <b-form-radio value="isCertifiedStaff">Certified Employees</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>
        </div>
        <div class="ml-3">
          <blockquote>
            <p class="blockquote">
              <i>Reference: Act 1040 of 2019</i>
            </p>
          </blockquote>
        </div>
    </div>
    `,
    data() {
        return {
            classification: 'all'
        }
    },
    computed: {
        ...mapState('hcptModule', ['selectedEmployeeClassification'])
    },
    watch: {
        classification(value) {
            this.$store.commit('hcptModule/setSelectedEmployeeClassification', value)
        }
    }
})

export { EmployeeClassificationSelector }
        
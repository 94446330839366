import Vue from 'vue'

const EmployeeLeaveViewSelector = Vue.extend({
    name: 'employee-leave-view-selector',
    template:
        /* html */
        `<b-form-group class="ml-3 mr-3 d-print-none">
            <b-form-radio-group id="grouping"
                v-model="selectedViewType"
                buttons
                name="selectedViewType"
                button-variant="outline-primary"
                :disabled="leaLevel == 1">
                <b-form-radio value="0">Overall</b-form-radio>
                <b-form-radio v-show='leaLevel > 1' value="1">Comparison</b-form-radio>
            </b-form-radio-group>
            <b-form-checkbox v-show='showStateViewToggle' v-model='stateView' value='1' unchecked-value='0' inline switch>Compare rates of Regular and Charter LEAs</b-form-checkbox>
            <b-form-select v-show='selectedViewType == 1' v-model='selectedLeaveType' :options='leaveTypes' inline></b-form-select>
        </b-form-group>`,
    data() {
        return {
            selectedViewType: '0',
            selectedLeaveType: 'SUM',
            stateView: '0',
            leaveTypes: [
                { value: 'SUM', text: 'Total of All Leave Types' },
                { value: 100, text: '(100) - Sick' },
                { value: 200, text: '(200) - Personal' },
                { value: 300, text: '(300) - Vacation' },
                { value: 400, text: '(400) - Bereavement' },
                { value: 500, text: '(500) - School Business' },
                { value: 600, text: '(600) - Professional Development' },
                { value: 700, text: '(700) - Military' },
                { value: 800, text: '(800) - Jury' },
                { value: 900, text: '(900) - Leave Without Pay' },
                { value: 999, text: '(999) - Other' }
            ]
        }
    },
    computed: {
        user() {
            return this.$store.state.globalModule.userContext
        },
        stateUser() {
            return this.user.isADEUser
        },
        leaLevel() {
            return this.$store.state.globalModule.leaContext.SelectedLea.type
        },
        showStateViewToggle() {
            return this.selectedViewType == 0 && this.stateUser && (this.leaLevel == 3 || this.leaLevel == 10)
        },
        selectedLea() {
            return this.$store.state.globalModule.leaContext.SelectedLea
        }
    },
    watch: {
        selectedViewType(selectedView) {
            this.$emit('on-view-type-selected', selectedView)
        },
        selectedLeaveType(selectedView) {
            this.$emit('on-leave-type-selected', selectedView)
        },
        stateView(selectedStateView) {
            this.$emit('on-state-view-selected', selectedStateView)
        },
        selectedLea(selectedLea) {
            if (selectedLea.type == 1) {
                this.selectedViewType = '0'
            }
        }
    }
})

export { EmployeeLeaveViewSelector }
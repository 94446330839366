<template>
  <div>
    <p class="font-weight-bold mt-3">
      {{ question.text }}
    </p>
  </div>
</template>

<script>

/// Display a question without any answer space, used as a header.

export default {
  name: 'question-header',
  props: {
    question: {
      type: Object,
      default: () => {}
    }
  },
}
</script>
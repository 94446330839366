<template lang="html">
  <i>
    <img v-if="useSvg" class="inline-svg" :src="svgData">
    <img v-else :src="imgPath" style="height: 1.25rem; width: 1.25rem;">
  </i>
</template>

<script>
import { LeaTypes } from '../helpers/leaTypes'

export default {
  name: 'lea-icon',
  props: {
    lea: Object,
    svgMode: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    useSvg() {
      return this.lea.type == LeaTypes.State || this.svgMode
    },
    svgData() {
      if (this.lea.type == LeaTypes.School) 
        return '/Content/icons/LI-Schools.svg'
      else if (this.lea.type == LeaTypes.District) 
        return '/Content/icons/LI-Districts.svg'
      else if (this.lea.type == LeaTypes.Coop)
        return '/Content/icons/LI-Coops.svg'
      else if (this.lea.type == LeaTypes.State)
        return '/Content/state.svg'
      else if (this.lea.type == LeaTypes.County)
        return '/Content/icons/LI-Counties.svg'
      return ''
    },
    imgPath() {
      if (this.lea.type == LeaTypes.School) 
        return '/Content/icons/LI-Schools-dark.png'
      else if (this.lea.type == LeaTypes.District) 
        return '/Content/icons/LI-Districts-dark.png'
      else if (this.lea.type == LeaTypes.Coop)
        return '/Content/icons/LI-Coops-dark.png'
      else if (this.lea.type == LeaTypes.County)
        return '/Content/icons/LI-Counties-dark.png'
      return ''
    }
  }
}
</script>
<template>
  <div>
    <h2>
      District ESA Plans
    </h2>
    <Promised :promise="loadData">
      <template v-slot:pending>
        <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
          <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
        </div>
      </template>
      <template v-slot>
        <div class="chart">
          <public-plan-list :load-data="loadData" />
        </div>
      </template>
      <template v-slot:rejected="error" class="align-items-center justify-content-center">
        <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
      </template>
    </Promised>
  </div>
</template>

<script>

import { Types } from '@/modules/esa/stores/'
import { mapState } from 'vuex'
import CardStateIcon from '@/components/CardStateIcon.vue'
import PublicPlanList from './PublicPlanList.vue'
import { Promised } from 'vue-promised'

export default {
  data() {
    return {
      loadData: null,
      loaded: false,
    }
  },
  computed: {
    ...mapState(Types.path, [
      Types.state.plans,
      Types.state.esaFiscalYear,
    ]),
    ...mapState('globalModule', ['userContext']),
  },
  components: {
    Promised,
    CardStateIcon,
    PublicPlanList,
  },
  methods: {
    getData() {
      if (this.selectedLea && this.esaFiscalYear) {
        this.loadData = Promise.all([
          this.$store.dispatch(Types.actions.getPlans, { fy: this.esaFiscalYear, lea: this.selectedLea.number }),
        ]).then(() => this.loaded = true)
      }
    },
  },
  watch: {
    esaFiscalYear: {
      immediate: true,
      handler() {
        this.getData()       
      }      
    },
    selectedLea: {
      immediate: true,
      handler() {
        this.getData()
      }
    }
  },
}

</script>

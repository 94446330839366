<template>
  <div>
    <loadable-chart-card :load-data="loadData">
      <template v-slot:default="slotProps">
        <div class="chartElem" v-show="slotProps.chartMode">
          <highcharts class="chart" :options="chartOptions" />
        </div>
        <div v-show="!slotProps.chartMode">
          <chart-data-table :items="dataItems()" />
        </div>
      </template>
    </loadable-chart-card>
  </div>
</template>

<script>
import LoadableChartCard from '../../LoadableChartCard.vue'
import { ChartMixin } from '../../ChartMixin'
import { credits } from '@/helpers/credits'

export default {
  name: 'alternative-method-of-instruction-days-count-chart',
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'line'
        },
        credits: credits.SIS,
        title: {
          text: this.chartTitle
        },
        series: [],
        plotOptions: {},
        xAxis: {
          title: {
            text: 'School Year'
          },
          type: 'category'
        },
        yAxis: {
          title: {
            text: this.chartTitle
          },
          endOnTick: false
        }
      }
    }
  },
  components: {
    LoadableChartCard,
  },
  methods: {
    dataItems() {
      return this.amiDayData
    }
  },
  props: {
    loadData: Promise,
    chartTitle: String,
    amiDayData: Array
  },
  mixins: [ChartMixin],
  watch: {
    amiDayData(newData) {
      this.chartOptions.series = []
      for (var i = 0; i < newData.length; i++)
        var seriesData = newData.map(row => {
          return {
            name: row['fiscalYear'] + 1990,
            y: row['dayCount']
          }
        })
      this.chartOptions.series.push({ data: seriesData, name: this.selectedLea.name })
    }
  }
}
</script>

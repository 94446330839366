<template>
  <div class="d-print-none">
    <div class="mb-2 card" v-if="filteredChangeLogs.length > 0">
      <div class="card-header">
        Activity History
      </div>
      <div class="card-body">
        <div v-for="log in filteredChangeLogs" :key="log.createdDate" class="mb-2 comment-container">
          <!-- {{ log }} -->
          <div class="row small">
            <div class="col-md-3 strong text-right">
              From:
            </div>
            <div class="col-md-9 text-left">
              {{ log.createdBy }}
            </div>
          </div>

          <div class="row small">
            <div class="col-md-3 strong text-right" />
            <div class="col-md-9 text-left">
              {{ log.createdDate | date }}
            </div>
          </div>

          <div v-if="log.toStatus == log.fromStatus" v-show="log.assignedToName">
            <div class="row small">
              <div class="col-md-3 text-right text-nowrap" v-if="log.assignedToName">
                Assigned to:
              </div>
              <div class="col-md-9" v-if="log.assignedToName">
                <strong>{{ log.assignedToName }}</strong> 
              </div>
              <div class="offset-md-3 col-md-9" v-if="log.assignedToUserId">
                <strong>{{ log.assignedToUserId }}</strong> 
              </div>
            </div>
          </div>
          <div class="row small" v-else>
            <div class="col-md-3 text-right text-nowrap">
              Status:
            </div>
            <div class="col-md-9 strong">
              <workflow-status-badge :state-map="StateMap" :status="log.toStatus" :show-label="false" :document="request" />
            </div>
          </div>
          <div class="row small" v-if="log.comment">
            <div class="col-md-3 text-right text-nowrap">
              Comment:
            </div>
            <div class="col-md-9">
              {{ log.comment }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WorkflowStatusBadge from '@/components/workflow/WorkflowStatusBadge.vue'
import { StateMap } from '@/stores/digitalLearning'

export default {
  data() {
    return {
      StateMap
    }
  },
  components: {
    WorkflowStatusBadge,
  },
  computed: {
    filteredChangeLogs() {
      if (this.request && this.request.changeLogs)
        return [...this.request.changeLogs].reverse()

      return []
    }
  },
  props: {
    request: Object
  }
}
</script>

<style lang="scss">
.comment-container:not(:nth-child(1)) {
  border-top: solid 1px darkgray;
}
</style>
import RvsPage from './components/RvsPage.vue'
import RvsSidebar from './components/RvsSidebar.vue'

import RvsHome from './components/pages/RvsHome.vue'
//import CoursesPage from './components/pages/CoursesPage.vue'
import TeachersPage from './components/pages/TeachersPage.vue'
import CoTeachersPage from './components/pages/CoTeachersPage.vue'
import TeacherDetailPage from './components/pages/TeacherDetailPage.vue'
import ClassroomDetailPage from './components/pages/ClassroomDetailPage.vue'

import StaffListingPage from './components/pages/StaffListingPage.vue'

import UsersGuidePage from './components/pages/resources/UsersGuidePage.vue'
import ParticipatingAudiencePage from './components/pages/resources/ParticipatingAudiencePage.vue'
import RoleIdentificationPage from './components/pages/resources/RoleIdentificationPage.vue'
import VerificationCourses from './components/pages/resources/VerificationCourses.vue'
import ExcludedCourses from './components/pages/resources/ExcludedCourses.vue'
import CourseCategories from './components/pages/resources/CourseCategories.vue'

import FeedbackPage from './components/pages/admin/FeedbackPage.vue'
import ConfigurationPage from './components/pages/admin/ConfigurationPage.vue'
import TroubleshootingPage from './components/pages/admin/TroubleshootingPage.vue'

import { router } from '@/routes'

const RVSArea = {
  RvsPage,
  RvsSidebar,
}

router.addRoutes([
  {
    // home route
    path: '/Schedule/RVS',
    name: 'Schedule/RVS',
    redirect: 'Schedule/RVS/Home'
  },
  {
    path: '/Schedule/RVS/Home',
    name: 'Schedule/RVS/Home',
    component: RvsHome,
    meta: { label: 'Home', icon: 'fa-home' },
  },
  // {
  //   path: '/Schedule/RVS/Courses',
  //   name: 'Schedule/RVS/Courses',
  //   component: CoursesPage,
  //   meta: { label: 'Courses', icon: 'fa-book' },
  // },
  {
    path: '/Schedule/RVS/CourseTeachers',
    name: 'Schedule/RVS/CourseTeachers',
    component: TeachersPage,
    meta: { label: 'Teachers of Record', icon: 'fa-user' },
  },
  {
    path: '/Schedule/RVS/CoTeachers',
    name: 'Schedule/RVS/CoTeachers',
    component: CoTeachersPage,
    meta: { label: 'Potential Contributors', icon: 'fa-user' },
  },
  {
    path: '/Schedule/RVS/Teachers/:id/:lea',
    name: 'Schedule/RVS/Teachers/Teacher',
    component: TeacherDetailPage,
    meta: { icon: 'fa-user', isTeacher: false },
  },
  {
    path: '/Schedule/RVS/Teachers/:teacherId/Classroom/:id/:section/:teacherType/:lea/:sk?',
    name: 'Schedule/RVS/Teachers/Classroom',
    component: ClassroomDetailPage,
    meta: { label: 'Classroom', icon: 'fa-school' },
  },
  {
    path: '/Schedule/RVS/Teachers/list',
    name: 'Schedule/RVS/Teachers/List',
    component: StaffListingPage,
    meta: { label: 'Staff Listing', icon: 'fa-list' },
  },
  /// TEACHER ROUTES
  {
    path: '/Schedule/RVS/Teacher',
    name: 'Schedule/RVS/Teacher',
    redirect: 'Schedule/RVS/Teacher/Home'
  },
  {
    path: '/Schedule/RVS/Teacher/Teachers/:id/:lea',
    name: 'Schedule/RVS/Teachers/Teacher Redirect',
    redirect: window.userContext.isRVSTeacher ? 'Schedule/RVS/Teacher/Home' : 'Schedule/RVS/Teachers/Teacher'
  },
  {
    path: '/Schedule/RVS/Teacher/:teacherId/Classroom/:id/:section/:teacherType/:lea/:sk?',
    name: 'Schedule/RVS/Teacher/Classroom',
    component: ClassroomDetailPage,
    meta: { label: 'Classroom', icon: 'fa-school' },
  },
  {
    path: '/Schedule/RVS/Teacher/Home',
    name: 'Schedule/RVS/Teacher/Home',
    component: TeacherDetailPage,
    meta: { label: 'Teacher', icon: 'fa-user', isTeacher: true },
  },
  {
    path: '/Schedule/RVS/Resources/UsersGuide',
    name: 'Schedule/RVS/Resources/UsersGuide',
    component: UsersGuidePage,
    meta: { label: 'Users Guide', icon: 'fa-book' },
  },
  {
    path: '/Schedule/RVS/Resources/ParticipatingAudience',
    name: 'Schedule/RVS/Resources/ParticipatingAudience',
    component: ParticipatingAudiencePage,
    meta: { label: 'Participating Audience', icon: 'fa-info-circle' },
  },
  {
    path: '/Schedule/RVS/Resources/RoleIdentification',
    name: 'Schedule/RVS/Resources/RoleIdentification',
    component: RoleIdentificationPage,
    meta: { label: 'Role Identification', icon: 'fa-info-circle' },
  },
  {
    path: '/Schedule/RVS/Resources/VerificationCourses',
    name: 'Schedule/RVS/Resources/VerificationCourses',
    component: VerificationCourses,
    meta: { label: 'Verification Courses', icon: 'fa-list' },
  },
  {
    path: '/Schedule/RVS/Resources/ExcludedCourses',
    name: 'Schedule/RVS/Resources/ExcludedCourses',
    component: ExcludedCourses,
    meta: { label: 'Excluded Courses', icon: 'fa-list' },
  },
  {
    path: '/Schedule/RVS/Resources/CourseCategories',
    name: 'Schedule/RVS/Resources/CourseCategories',
    component: CourseCategories,
    meta: { label: 'Course Categories', icon: 'fa-list' },
  },
  {
    path: '/Schedule/RVS/Admin/Feedback',
    name: 'Schedule/RVS/Admin/Feedback',
    component: FeedbackPage,
    meta: { label: 'Feedback', icon: 'fa-comment' },
  },
  {
    path: '/Schedule/RVS/Admin/Configuration',
    name: 'Schedule/RVS/Admin/Configuration',
    component: ConfigurationPage,
    meta: { label: 'Configuration', icon: 'fa-cog' },
  },
  {
    path: '/Schedule/RVS/Admin/Troubleshooting',
    name: 'Schedule/RVS/Admin/Troubleshooting',
    component: TroubleshootingPage,
    meta: { label: 'Troubleshooting', icon: 'fa-exclamation-triangle' },
  }
])

export { RVSArea }

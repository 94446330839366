<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div>
        <div class="chartElem" v-show="slotProps.chartMode">
          <highcharts class="chart" :options="chartOptions" />
        </div>
        <div v-show="!slotProps.chartMode">
          <chart-data-table :items="dataItems()" />
        </div>
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { OtherSubpopulations } from '../../subpopulations'
import { credits } from '../../../helpers/credits'

export default {
  mixins: [ChartMixin],
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'line'
        },
        credits: credits.MSI,
        title: {
          text: this.chartTitle
        },
        series: OtherSubpopulations,
        xAxis: {
          title: {
            text: 'School Year'
          },
          type: 'category'
        },
        tooltip:{
          enabled: true,
          formatter: this.tooltipFormatter
        },
        yAxis: {
          title: {
            text: this.yAxisLabel
          },
          endOnTick: false
        },
        plotOptions: {
          series: {
            minPointLength: 1
          }
        }
      }
    }
  },
  watch: {
    enrollment(newData) {
      this.chartOptions.series = OtherSubpopulations.map(subpop => {
        return Object.assign(
          { ...subpop },
          {
            data: newData.filter(row => row[subpop.field]).map(row => {
              return {
                x: (row.fiscalYear % 1990) + 1990,
                y: row[subpop.field] * this.valueMultiplier,
                color: subpop.color
              }
            })
          }
        )
      })
    }
  },
  props: {
    enrollment: Array,
    chartTitle: String,
    yAxisLabel:{
      type: String,
      default: 'Number Of Students'
    },
    valueMultiplier:{
      type: Number,
      default: 1
    },
    tooltipFormatter:{
      type: Function,
      default: function() {
        return `${this.x}<br/><span  style="color:${this.series.color}">${this.series.name}</span>: <b>${this.y}</b>`
      }
    }
  },
  methods: {
    excelExportData() {
      return this.defaultExcelExportData()
    },
    dataItems() {
      return OtherSubpopulations.flatMap((race, index) =>
        this.chartOptions.series[index].data.map(item => {
          return { Race: race.name, SchoolYear: item.x, [this.yAxisLabel.split(" ").join("")]: item.y }
        })
      )
    }
  }
}
</script>

<template>
  <div>
    <div class="card" v-if="fiscalYear">
      <div class="card-header">
        Approved LEA Assignments
      </div>
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-3">
            Assigned to Fiscal Year:
            <b-select :options="fiscalYearAssignmentOptions" v-model="fy" />
            Operation Type:
            <b-select :options="operationArray" v-model="selectedOperation" />
          </div>
          <div class="col-md-4 dashboard-panel">
            <h4>
              District Requests By Operation Type
            </h4>
            <div>
              <span class="description">New District LEA Request</span>
              <span class="badge badge-primary">{{ summary.newDistrict || 0 }}</span>
            </div>
            <div>
              <span class="description">District Information Change</span>
              <span class="badge badge-primary">{{ summary.changeDistrict || 0 }}</span>
            </div>
          </div>
          <div class="col-md-5 dashboard-panel">
            <h4>
              School Requests By Operation Type
            </h4>
            <div>
              <span class="description">Building Annexation</span>
              <span class="badge badge-primary">{{ summary.buildingAnnexation || 0 }}</span>
            </div>
            <div>
              <span class="description">Building Consolidation</span>
              <span class="badge badge-primary">{{ summary.buildingCombination || 0 }}</span>
            </div>
            <div>
              <span class="description">Building LEA # Change</span>
              <span class="badge badge-primary">{{ summary.changeBuildingLEA || 0 }}</span>
            </div>
            <div>
              <span class="description">Other General Changes</span>
              <span class="badge badge-primary">{{ summary.general || 0 }}</span>
            </div>
            <div>
              <span class="description">District Reconfiguration</span>
              <span class="badge badge-primary">{{ summary.districtReconfiguration || 0 }}</span>
            </div>
          </div>
        </div>

        <b-tabs>
          <b-tab title="Default LEA Assignments" class="p-2">
            <table-control-widget
              :per-page="tableOptions.perPage"
              :filter="tableOptions.filter"
              @page-length-changed="tableOptions.perPage = $event"
              @filter-changed="tableOptions.filter = $event"
            />

            <b-table
              small
              :fields="tableFields"
              :items="filteredApprovedAssignments"
              :per-page="tableOptions.perPage"
              :current-page="tableOptions.currentPage"
              :filter="tableOptions.filter"
            >
              <template v-slot:cell(responseObject.operation)="data">
                <span class="badge badge-info">{{ requestOperationFormatter(data.value) }}</span>
              </template>

              <template v-slot:cell(responseObject.items)="data">
                <approved-assignment-item-detail
                  v-for="i in data.value"
                  :key="i.key"
                  :item="i"
                  :lea-operation="pluckLeaOperation(i.key, data.item.leaOperations)"
                />
              </template>

              <template v-slot:cell(requestId)="data">
                <router-link :to="{name: 'review-request', params: {id: data.value}}">
                  <i class="fa fa-search-plus fa-fw" /> View Details
                </router-link>
              </template>

              <template v-slot:cell(originalValues)="data">
                <div v-html="formatChanges(data.item.schoolLea, data.item.operationData)" />
              </template>
            </b-table>

            <table-pagination-widget
              :per-page="tableOptions.perPage"
              :filter="tableOptions.filter"
              :current-page="tableOptions.currentPage"
              :page-length="tableOptions.perPage"
              :total-rows-count="filteredApprovedAssignments.length"
              @page-changed="tableOptions.currentPage = $event"
            />
          </b-tab>
          <b-tab title="Districts" class="p-2">
            <district-requests />
          </b-tab>
          <b-tab title="Schools" class="p-2">
            <school-requests />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
ul.request-href {
  list-style-type: none;

  a,
  a:hover {
    cursor: pointer !important;
    text-decoration: none !important;
    color: #444444 !important;

    .edited & {
      color: #ffffff !important;
    }
  }
}
</style>

<script>
import { mapState, mapGetters } from 'vuex'
import { gradeSpanFormatter } from '@/helpers/gradeSpans'
import TablePaginationWidget from '@/components/shared/TablePaginationWidget'
import TableControlWidget from '@/components/shared/TableControlWidget'
// import ExcelButton from '../../ExcelButton'
import { RequestOperationPurpose, requestOperationFormatter } from '..'
import ApprovedAssignmentItemDetail from './approvedAssignmentItemDetail'
import DistrictRequests from './districtRequests.vue'
import SchoolRequests from './schoolRequests.vue'

export default {
  name: 'approved-assignments',
  data() {
    return {
      RequestOperationPurpose,
      requestOperationFormatter,
      fy: null,
      tableOptions: {
        currentPage: 1,
        perPage: 10,
        filter: null
      }
    }
  },
  components: {
    TablePaginationWidget,
    TableControlWidget,
    DistrictRequests,
    SchoolRequests,
    ApprovedAssignmentItemDetail
  },
  mounted() {
  },
  computed: {
    ...mapState('toolsModule/leaAssignment', ['approvedAssignments']),
    ...mapGetters('toolsModule/leams', ['fiscalYearAssignmentOptions', 'fiscalYear']),
    ...mapState('globalModule', ['leaContext', 'userContext']),
    ...mapGetters('globalModule', ['leaDictionary']),
    selectedLea() {
      return this.leaContext.SelectedLea
    },
    selectedOperation: {
      get() {
        return this.$store.getters["toolsModule/leaAssignment/selectedOperation"]
      },
      set(newValue) {
        return this.$store.dispatch("toolsModule/leaAssignment/setSelectedOperation", newValue)
      }
    },
    summary() {
      return {
        newDistrict: this.approvedAssignments.filter(a=>a.responseObject.operation==RequestOperationPurpose.NewDistrict).length,
        changeDistrict: this.approvedAssignments.filter(a=>a.responseObject.operation==RequestOperationPurpose.ChangeDistrict).length,
        buildingAnnexation: this.approvedAssignments.filter(a=>a.responseObject.operation==RequestOperationPurpose.BuildingAnnexation).length,
        buildingCombination: this.approvedAssignments.filter(a=>a.responseObject.operation==RequestOperationPurpose.BuildingCombination).length,
        changeBuildingLEA: this.approvedAssignments.filter(a=>a.responseObject.operation==RequestOperationPurpose.ChangeBuildingLEA).length,
        general: this.approvedAssignments.filter(a=>a.responseObject.operation==RequestOperationPurpose.General).length,
        districtReconfiguration: this.approvedAssignments.filter(a=>a.responseObject.operation==RequestOperationPurpose.DistrictReconfiguration).length,
      }
    },
    tableFields() {
      return [
        { key: 'districtLEA', sortable: true, label: 'Requesting LEA' },
        { key: 'requestingParty', sortable: true, label: 'District / Party' },
        { key: 'createdBy', sortable: true },
        { key: 'responseObject.operation', sortable: true, label: 'Purpose' },
        { key: 'responseObject.items', sortable: true, label: 'Request Details' },
        { key: 'requestId', sortable: false, label: '', class: 'text-right' }
      ]
    },
    listItems() {
      return this.approvedAssignments.map(item => {
        return {
          requestingParty: item.districtLEA == 'AR' ? 'ADE / Charter School Office' : this.leaDictionary[item.districtLEA]?.name || 'Not Found',
          ...item
        }
      })
    },
    operationArray(){
      var array = [{value: null, text: "Filter by Operation Type"}]
      return [...array, ...Object.entries(this.RequestOperationPurpose)
        .filter(o => o[1] != 255)
        .map(p => ({ value: p[1], text: requestOperationFormatter(p[1])}))
        .sort((a,b) =>{
          if(a.text < b.text){
            return -1
          }
          if(a.text > b.text){
            return 1
          }
          return 0
        })]
    },
    filteredApprovedAssignments(){
      if(this.selectedOperation) {
        return this.listItems.filter(a => a.responseObject.operation == this.selectedOperation)
      }
      return this.listItems
    }
  },
  methods: {
    pluckLeaOperation(key, leaOperations) {
      var ops = leaOperations.filter(o => o.operationKey === key)
      return ops && ops.length ? ops[0] : null
    },
    getApprovedAssignments() {
      var lea = this.userContext.districtLEA

      if (this.userContext.isADEUser) {
        lea = this.selectedLea.number
      }

      if (this.fy) {
        this.$store.dispatch('toolsModule/leaAssignment/getApprovedAssignments', {
          lea: lea,
          fy: this.fy
        })
      }
    },

    formatChanges(lea, item) {
      if (item.itemType == 'edit-school') {
        return `${item.currentValues.leaName}, ${gradeSpanFormatter(item.currentValues.gradeLow, item.currentValues.gradeHigh)}`
      } else if (item.itemType == 'edit-district') {
        return `${item.currentValues.leaName}`
      }

      return null
    },
   
    extractSchoolName(item) {
      switch (item.itemType) {
        case 'edit-school':
        case 'edit-district':
          return item.leaName
        case 'new':
        case 'new-district':
          return item.leaName
        case 'inactivate':
          return item.selectedLeaName
      }
    },

    extractGradeSpan(item) {
      switch (item.itemType) {
        case 'edit-school':
          return gradeSpanFormatter(item.gradeLow, item.gradeHigh)
        case 'new':
          return gradeSpanFormatter(item.gradeLow, item.gradeHigh)
        case 'inactivate':
          return gradeSpanFormatter(item.gradeLow, item.gradeHigh)
      }
    },

    assignRowClass(item) {
      if (item.inactivates && item.inactivates.length > 0) return 'inactivated bg-warning'
      if (item.edits && item.edits.length > 0) return 'edited bg-primary text-white'
      return ''
    },
    iconClass(operationType) {
      switch (operationType) {
        case 'new':
        case 'new-district':
          return 'fa-plus-square text-success'
        case 'inactivate':
          return 'fa-minus-square text-danger'
        case 'edit-school':
        case 'edit-district':
          return 'fa-pen-square text-primary'
      }
    },
    hasRequests(item) {
      return (item.inactivates && item.inactivates.length > 0) || (item.edits && item.edits.length > 0)
    },
    excelExportData() {
      return {
        name: 'LEA Assignment Requests by LEA',
        items: this.listItems.map(m=> { return {...m, operation: requestOperationFormatter(m.responseObject.operation)}})
      }
    }
  },
  watch: {
    selectedLea() {
      this.getApprovedAssignments()
    },
    fy: {
      immediate: false,
      handler() {
        this.getApprovedAssignments()
      }
    },
    fiscalYear: {
      immediate: true,
      handler() {
        if (this.fiscalYear && !this.fy) {
          this.fy = this.fiscalYear + 1
        }
      }        
    }
  }
}
</script>

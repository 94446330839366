<template>
  <div>
    <span class="badge" :class="itemBadgeClass(item)" v-html="operationBadge(item)" />
    <div v-if="item.itemType=='new-district'">
      {{ item.leaName }} ({{ leaOperation.lea }})
      <br>
      {{ item.address }}
      <br>
      {{ item.city }}, {{ item.state }} {{ item.zip }}
    </div>
    <div v-else-if="item.itemType=='new'">
      {{ item.leaName }} ({{ leaOperation.lea }})
      <br>
      Principal: TBD
      <br>
      {{ gradeSpanFormatter(item.gradeLow, item.gradeHigh) }}
      <br>
      {{ item.address }}
      <br>
      {{ item.city }}, {{ item.state }} {{ item.zip }}
    </div>
    <div v-else-if="item.itemType=='edit-school'">
      <div class="row no-gutters">
        <div class="col-6">
          <h5>New Values</h5>
          {{ item.leaName }} ({{ item.selectedLea }})
          <br>
          Principal: <admin-span :admin="leaAdmin(item.selectedLea)" />
          <br>
          {{ gradeSpanFormatter(item.gradeLow, item.gradeHigh) }}
          <br>
          {{ item.address }}
          <br>
          {{ item.city }}, {{ item.state }} {{ item.zip }}
        </div>
        <div class="col-6">
          <h5>Original Values</h5>
          {{ item.currentValues.leaName }}
          <br>
          {{ gradeSpanFormatter(item.currentValues.gradeLow, item.currentValues.gradeHigh) }}
          <br>
          {{ item.currentValues.address }}
          <br>
          {{ item.currentValues.city }}, {{ item.currentValues.state }} {{ item.currentValues.zip }}
        </div>
      </div>
    </div>
    <div v-else-if="item.itemType=='edit-district'">
      <div class="row no-gutters">
        <div class="col-6">
          <h5>New Values</h5>
          {{ item.leaName }} ({{ item.selectedLea }})
          <br>
          {{ item.address }}
          <br>
          {{ item.city }}, {{ item.state }} {{ item.zip }}
        </div>
        <div class="col-6">
          <h5>Original Values</h5>
          {{ item.currentValues.leaName }}
          <br>
          {{ item.currentValues.address }}
          <br>
          {{ item.currentValues.city }}, {{ item.currentValues.state }} {{ item.currentValues.zip }}
        </div>
      </div>
    </div>
    <div v-else-if="item.itemType=='inactivate'">
      {{ item.selectedLeaName }} ({{ item.selectedLea }})
      <br>
      Principal: <admin-span :admin="leaAdmin(item.selectedLea)" />
      <br>
      {{ gradeSpanFormatter(item.gradeLow, item.gradeHigh) }}
    </div>
    <div v-else>
      {{ formatDetails(item) }}
    </div>
  </div>
</template>

<script>
import { gradeSpanFormatter } from '@/helpers/gradeSpans'
import AdminSpan from './adminSpan.vue'

export default {
  name: 'approved-assignment-item-detail',
  data() {
    return {
      gradeSpanFormatter
    }
  },
  props: {
    item: Object,
    leaOperation: Object
  },
  components: {
    AdminSpan
  },
  methods: {
    leaAdmin(selectedLea) {
      let lea = this.leaDictionary[selectedLea];
      if (lea && lea.adminLastName) return {
        name: `${lea.adminFirstName} ${lea.adminLastName}`,
        email: lea.adminEmailAddress
      }
      return {
        name: 'Not Found',
        email: 'Not Found'
      }
    },
    formatDetails(item) {
      switch (item.itemType) {
        case 'edit-school':
          return `New Name: ${item.leaName}, ${gradeSpanFormatter(item.currentValues.gradeLow, item.currentValues.gradeHigh)}`
        case 'edit-district':
          return `New Name: ${item.leaName}`
        case 'new':
          return `School Name: ${item.leaName}, ${gradeSpanFormatter(item.gradeLow, item.gradeHigh)}`
        case 'new-district':
          return `District Name: ${item.leaName}, Address: ${item.address}, ${item.city}, ${item.state}`
        case 'inactivate':
          return `School: ${item.selectedLeaName}, ${gradeSpanFormatter(item.gradeLow, item.gradeHigh)}`
      }
    },

    itemBadgeClass(item) {
      switch (item.itemType) {
        case 'edit-school':
        case 'edit-district':
          return `badge-primary`
        case 'new':
        case 'new-district':
          return `badge-success`
        case 'inactivate':
          return `badge-danger`
      }
    },

    operationBadge(item) {
      switch (item.itemType) {
        case 'edit-school':
          return `Change Building (${item.reconfigurationCode})`
        case 'edit-district':
          return `Change District (${item.reconfigurationCode})`
        case 'new':
          return `New Building (${item.reconfigurationCode})`
        case 'new-district':
          return `New District (${item.reconfigurationCode})`
        case 'inactivate':
          return `Inactivate Building (${item.reconfigurationCode})`
        default:
          return `Other`
      }
    }
  }
}
</script>
<template>
  <div>
    <Promised :promise="syncing">
      <template v-slot:pending>
        <b-button size="sm" variant="primary" disabled="disabled">
          <i class="fas fa-spinner fa-spin fa-fw" />Synchronizing...
        </b-button>
      </template>
      <template v-slot:default>
        <b-button size="sm" variant="primary" @click="doSync">
          <i class="fas fa-bolt fa-fw" />
          Sync Now
        </b-button>
      </template>
      <template v-slot:rejected="error">
        <span class="badge badge-danger">{{ error }} </span>
      </template>
    </Promised>
  </div>
</template>

<script>
import { Promised } from 'vue-promised'
export default {
  name: 'sync-button',
  data() {
    return {
      syncing: Promise.resolve(true)
    }
  },
  methods: {
    doSync() {
      this.syncing = this.$store.dispatch("toolsModule/leams/synchronizeRequest", {requestId: this.item.request.requestId})
    }
  },
  components: {
    Promised
  },
  props: {
    item: Object
  }
}
</script>
<template lang="html">
  <div>
    <ul class="nav nav-pills d-block">
      <li class="mr-2">
        <a class="nav-link ml-1">
          <i class="fa-fw fas fa-filter" /> <span class="ml-1 font-weight-bold">Filter</span>
        </a>
        <ul class="nav nav-pills">
          <li class="nav-item ml-2">
            <div class="nav-link">
              Student Cohort
              <b-form-select v-model="fiscalYear">
                <b-form-select-option v-for="year in loadedYears" :key="year" :value="year">
                  {{ year + 1990 }}
                </b-form-select-option>
              </b-form-select>
            </div>
          </li>
        </ul>
      </li>

      <li class="mr-2 ml-0">
        <a class="nav-link ml-0 pl-1">
          <router-link class="font-weight-bold nav-link" :to="{ path: '/Academics/TeacherGrowth' }">Home</router-link>
        </a>
      </li>

      <li class="mr-2">
        <a class="nav-link ml-1">
          <span class="font-weight-bold">Reports</span>
        </a>

        <ol class="nav-pills">
          <li class="nav-item">
            <router-link :to="{ path: '/Academics/TeacherGrowth/question-1', query: { lea: selectedLea.number } }" active-class="active" class="nav-link small">
              <i class="fas fa-fw fa-chart-bar" /> Are my students growing as much as they were expected to grow and how does this relate to their achievement?
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/Academics/TeacherGrowth/question-2', query: { lea: selectedLea.number } }" active-class="active" class="nav-link small">
              <i class="fas fa-fw fa-chart-bar" /> Are students in each readiness level growing as expected?
            </router-link>
          </li>
        </ol>
      </li>

      <li class="mr-2" v-if="canViewStudentData">
        <a class="nav-link ml-1">
          <span class="font-weight-bold">Data & Trend</span>
        </a>
        <ul class="nav nav-pills d-block">
          <li class="nav-item ml-2">
            <router-link style="font-size: .9em" :to="{ path: '/Academics/TeacherGrowth/studentdata', query: { lea: selectedLea.number } }" active-class="active" class="nav-link">
              <i class="fas fa-fw fa-table mr-1" /> Student Data
            </router-link>
          </li>
          <!-- <li class="nav-item ml-2">
            <router-link style="font-size: .9em" :to="{ path: '/Academics/TeacherGrowth/student-trend-lines', query: { lea: selectedLea.number } }" active-class="active" class="nav-link">
              <i class="fas fa-fw fa-chart-line mr-1" /> Student Trend Lines
            </router-link>
          </li> -->
        </ul>
      </li>

      <li class="mr-2">
        <a class="nav-link ml-1">
          <span class="font-weight-bold">Resources</span>
        </a>
        <ul class="nav nav-pills d-block">
          <li class="nav-item ml-2">
            <router-link style="font-size: .9em" :to="{ path: '/Academics/TeacherGrowth/tvampdf' }" active-class="active" class="nav-link">
              <i class="fas fa-fw fa-file-pdf mr-1" /> Guidance Document
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { LeaTypes } from '../../../helpers/leaTypes'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      LeaTypes: LeaTypes,
    }
  },
  components: {
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    canViewStudentData() {
      return !(this.userContext.isRecruitment || this.userContext.isTeacherCenter || this.userContext.isSuperintendentCoop)
    },
    loadedYears() { return this.$store.state.academicsModule.teacherGrowth.loadedyears },
    fiscalYear: {
      set(year) {
        this.$store.commit('academicsModule/teacherGrowth/setSelectedYear', year)
        this.$store.commit('academicsModule/essa/setFiscalYear', year)
      },
      get() {
        return this.$store.state.academicsModule.teacherGrowth.fiscalYear
      }
    }
  },
  methods: {
  }
}
</script>
<template>
  <div id="comment-modal">
    <b-modal id="comment-modal" title="Submit Comments or Feedback" hide-footer v-model="showModal" size="xl">
      <b-form>
        <b-form-group id="input-group-1" label="Name:" label-for="input-1">
          <b-form-input readonly v-model="fullName" />
        </b-form-group>
        <b-form-group id="input-group-2" label="Email:" label-for="input-2">
          <b-form-input readonly v-model="emailAddress" />
        </b-form-group>
        <b-form-group id="input-group-3" label="Comments:" label-for="input-3">
          <b-form-textarea id="input-3" required rows="6" max-rows="10" v-model="comments" />
        </b-form-group>
        <b-button @click="submitComments" variant="primary">
          Submit
        </b-button>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      showModal: false,
      comments: ''
    }
  },
  computed: {
    ...mapGetters('globalModule', ['fullName', 'emailAddress']),
  },
  mounted() {
    this.$root.$on('bv::modal::show', () => {
      this.comments = ''
    })
    this.$emit('interface', {
      showModal: () => this.showModal = true
    })
  },
  methods: {
    async submitComments() {
      await this.$store.dispatch('rvsModule/submitFeedback', { Comment: this.comments, Url: window.location.href })
      this.showModal = false;
      this.$bvToast.toast('Thanks for your feedback!', {
          title: `Your Feedback Has Been Sent`,
          variant: 'success',
          solid: true
        })
    }
  }
}
</script>
<template>
  <div class="card bg-light">
    <div class="card-header lead">
      Academic Achievement and Identification of Gaps
    </div>
    <a class="anchor" id="academic-achievement" />
    <b-tabs class="m-3" content-class="mt-1" pills>
      <b-tab title="All Students" active title-item-class="d-print-none">
        <div class="row" v-for="demographic in allStudents" :key="'sqssReadingSupport' + demographic">
          <div class="col-12 mx-0">
            <single-metric-schools-chart title="Reading by Grade: In Need of Support" :grade-span-options="grades3To8" :subgroup="demographic" metric-suffix="%" :report-data="currentReadingInNeedOfSupport" :load-data="loadDataReading" :key="'aa-sqss-schools-reading-support-' + demographic" />
          </div>
        </div>

        <div class="row" v-for="demographic in allStudents" :key="'sqssReading' + demographic">
          <div class="col-12 mx-0">
            <single-metric-schools-chart title="Reading by Grade: At Grade Level" :grade-span-options="grades3To8" :subgroup="demographic" metric-suffix="%" :report-data="currentSqssScoresReading" :load-data="loadDataReading" :key="'aa-sqss-schools-reading-' + demographic" />
          </div>
        </div>

        <div class="row" v-for="demographic in allStudents" :key="'ela-support' + demographic">
          <!-- <div class="col-4 mx-0 pr-0" v-if="hasDataForDemographic(currentELAInNeedOfSupport, demographic)">
            <single-metric-gauge-chart title="ELA by Grade: In Need of Support" :subgroup="demographic" metric-suffix="%" :report-data="currentELAInNeedOfSupport" :load-data="loadDataInNeedofSupportMathAndELA" :key="'aa-ela-support-' + demographic" />
          </div> -->
          <div class="col-12 mx-0" v-if="hasDataForDemographic(currentELAInNeedOfSupport, demographic)">
            <single-metric-schools-chart title="ELA by Grade: In Need of Support" :grade-span-options="grades3To8" :subgroup="demographic" metric-suffix="%" :report-data="currentELAInNeedOfSupport" :load-data="loadDataInNeedofSupportMathAndELA" :key="'aa-ela-support-schools-' + demographic" />
          </div>
        </div>

        <div class="row page-break-before" v-for="demographic in allStudents" :key="'ela' + demographic">
          <div class="col-4 mx-0 pr-0">
            <single-metric-gauge-chart title="ELA by Grade: Ready or Exceeds" :subgroup="demographic" metric-suffix="%" :report-data="currentElaPercentReadyOrExceeds" :load-data="loadDataPRoE" :key="'aa-ela-' + demographic" />
          </div>
          <div class="col-8 mx-0 pl-0">
            <single-metric-schools-chart title="ELA by Grade: Ready or Exceeds" :grade-span-options="grades3To8" :subgroup="demographic" metric-suffix="%" :report-data="currentElaPercentReadyOrExceeds" :load-data="loadDataPRoE" :key="'aa-ela-schools-' + demographic" />
          </div>
        </div>

        <div class="row" v-for="demographic in allStudents" :key="'math-support' + demographic">
          <!-- <div class="col-4 mx-0 pr-0" v-if="hasDataForDemographic(currentMathInNeedOfSupport, demographic)">
            <single-metric-gauge-chart title="Math by Grade: In Need of Support" :subgroup="demographic" metric-suffix="%" :report-data="currentMathInNeedOfSupport" :load-data="loadDataInNeedofSupportMathAndELA" :key="'aa-math-support-' + demographic" />
          </div> -->
          <div class="col-12 mx-0" v-if="hasDataForDemographic(currentMathInNeedOfSupport, demographic)">
            <single-metric-schools-chart title="Math by Grade: In Need of Support" :grade-span-options="grades3To8" :subgroup="demographic" metric-suffix="%" :report-data="currentMathInNeedOfSupport" :load-data="loadDataInNeedofSupportMathAndELA" :key="'aa-math-support-schools-' + demographic" />
          </div>
        </div>
        
        <div class="row" v-for="demographic in allStudents" :key="'math' + demographic">
          <div class="col-4 mx-0 pr-0">
            <single-metric-gauge-chart title="Math by Grade: Ready or Exceeds" :grade-span-options="grades3To8" :subgroup="demographic" metric-suffix="%" :report-data="currentMathPercentReadyOrExceeds" :load-data="loadDataPRoE" :key="'aa-math-' + demographic" />
          </div>
          <div class="col-8 mx-0 pl-0">
            <single-metric-schools-chart title="Math by Grade: Ready or Exceeds" :grade-span-options="grades3To8" :subgroup="demographic" metric-suffix="%" :report-data="currentMathPercentReadyOrExceeds" :load-data="loadDataPRoE" :key="'aa-math-schools-' + demographic" />
          </div>
        </div>

        <div class="row page-break-before" v-for="demographic in allStudents" :key="'sqss' + demographic">
          <div class="col-4 mx-0 pr-0">
            <single-metric-gauge-chart title="SQSS Score" :subgroup="demographic" :report-data="currentSqssScores" :load-data="loadDataSqss" :key="'aa-sqss-' + demographic" />
          </div>
          <div class="col-8 mx-0 pl-0">
            <single-metric-schools-chart title="SQSS Score" :subgroup="demographic" :report-data="currentSqssScores" :load-data="loadDataSqss" :key="'aa-sqss-schools-' + demographic" :x-axis-formatter="SQSSChartXAxisFormatter" custom-label="Click School Name to view SQSS report in MySchoolInfo" />
          </div>
        </div>

        <div class="row" v-for="demographic in allStudents" :key="'4year' + demographic">
          <div class="col-4 mx-0 pr-0">
            <single-metric-gauge-chart title="Four Year Graduation Rates" :subgroup="demographic" metric-suffix="%" :report-data="currentFourYearGraduationRates" :load-data="loadDataGrad" :key="'aa-4year-' + demographic" />
          </div>
          <div class="col-8 mx-0 pl-0">
            <single-metric-schools-chart title="Four Year Graduation Rates" :subgroup="demographic" metric-suffix="%" :report-data="currentFourYearGraduationRates" :load-data="loadDataGrad" :key="'aa-4year-schools-' + demographic" />
          </div>
        </div>

        <div class="row page-break-before" v-for="demographic in allStudents" :key="'5year' + demographic">
          <div class="col-4 mx-0 pr-0">
            <single-metric-gauge-chart title="Five Year Graduation Rates" :subgroup="demographic" metric-suffix="%" :report-data="currentFiveYearGraduationRates" :load-data="loadDataGrad" :key="'aa-5year-' + demographic" />
          </div>
          <div class="col-8 mx-0 pl-0">
            <single-metric-schools-chart title="Five Year Graduation Rates" :subgroup="demographic" metric-suffix="%" :report-data="currentFiveYearGraduationRates" :load-data="loadDataGrad" :key="'aa-5year-schools-' + demographic" />
          </div>
        </div>
      </b-tab>
      <b-tab title="By Subpopulation" title-item-class="d-print-none">
        <div class="row" v-for="(demographic, index) in readingDemographics" :key="'sqssReadingSupport' + demographic" :class="{ 'page-break-before': index % 2 == 0 }">
          <div class="col-12 mx-0" v-if="hasDataForDemographic(currentReadingInNeedOfSupport, demographic)">
            <single-metric-schools-chart title="Reading by Grade: In Need Of Support" :grade-span-options="grades3To8" :subgroup="demographic" metric-suffix="%" :report-data="currentReadingInNeedOfSupport" :load-data="loadDataReading" :key="'aa-sqss-schools-reading-' + demographic" />
          </div>
        </div>

        <div class="row" v-for="(demographic, index) in readingDemographics" :key="'sqssReading' + demographic" :class="{ 'page-break-before': index % 2 == 0 }">
          <div class="col-12 mx-0" v-if="hasDataForDemographic(currentSqssScoresReading, demographic)">
            <single-metric-schools-chart title="Reading by Grade: At Grade Level" :grade-span-options="grades3To8" :subgroup="demographic" metric-suffix="%" :report-data="currentSqssScoresReading" :load-data="loadDataReading" :key="'aa-sqss-schools-reading-' + demographic" />
          </div>
        </div>

        <div class="row" v-for="(demographic, index) in demographics" :key="'ela-support' + demographic" :class="{ 'page-break-before': index % 2 == 0 }">
          <!-- <div class="col-4 mx-0 pr-0" v-if="hasDataForDemographic(currentELAInNeedOfSupport, demographic)">
            <single-metric-gauge-chart title="ELA by Grade: In Need of Support" :subgroup="demographic" metric-suffix="%" :report-data="currentELAInNeedOfSupport" :load-data="loadDataInNeedofSupportMathAndELA" :key="'aa-ela-support-' + demographic" />
          </div> -->
          <div class="col-12 mx-0" v-if="hasDataForDemographic(currentELAInNeedOfSupport, demographic)">
            <single-metric-schools-chart title="ELA by Grade: In Need of Support" :grade-span-options="grades3To8" :subgroup="demographic" metric-suffix="%" :report-data="currentELAInNeedOfSupport" :load-data="loadDataInNeedofSupportMathAndELA" :key="'aa-ela-support-schools-' + demographic" />
          </div>
        </div>

        <div class="row" v-for="(demographic, index) in demographics" :key="'ela' + demographic" :class="{ 'page-break-before': index % 2 == 0 }">
          <div class="col-4 mx-0 pr-0" v-if="hasDataForDemographic(currentElaPercentReadyOrExceeds, demographic)">
            <single-metric-gauge-chart title="ELA by Grade: Ready or Exceeds" :subgroup="demographic" metric-suffix="%" :report-data="currentElaPercentReadyOrExceeds" :load-data="loadDataPRoE" :key="'aa-ela-' + demographic" />
          </div>
          <div class="col-8 mx-0 pl-0" v-if="hasDataForDemographic(currentElaPercentReadyOrExceeds, demographic)">
            <single-metric-schools-chart title="ELA by Grade: Ready or Exceeds" :grade-span-options="grades3To8" :subgroup="demographic" metric-suffix="%" :report-data="currentElaPercentReadyOrExceeds" :load-data="loadDataPRoE" :key="'aa-ela-schools-' + demographic" />
          </div>
        </div>

        <div class="row" v-for="(demographic, index) in demographics" :key="'math-support' + demographic" :class="{ 'page-break-before': index % 2 == 0 }">
          <!-- <div class="col-4 mx-0 pr-0" v-if="hasDataForDemographic(currentMathInNeedOfSupport, demographic)">
            <single-metric-gauge-chart title="Math by Grade: In Need of Support" :subgroup="demographic" metric-suffix="%" :report-data="currentMathInNeedOfSupport" :load-data="loadDataInNeedofSupportMathAndELA" :key="'aa-math-support-' + demographic" />
          </div> -->
          <div class="col-12 mx-0" v-if="hasDataForDemographic(currentMathInNeedOfSupport, demographic)">
            <single-metric-schools-chart title="Math by Grade: In Need of Support" :grade-span-options="grades3To8" :subgroup="demographic" metric-suffix="%" :report-data="currentMathInNeedOfSupport" :load-data="loadDataInNeedofSupportMathAndELA" :key="'aa-math-support-schools-' + demographic" />
          </div>
        </div>
        
        <div class="row" v-for="(demographic, index) in demographics" :key="'math' + demographic" :class="{ 'page-break-before': index % 2 == 0 }">
          <div class="col-4 mx-0 pr-0" v-if="hasDataForDemographic(currentMathPercentReadyOrExceeds, demographic)">
            <single-metric-gauge-chart title="Math by Grade: Ready or Exceeds" :subgroup="demographic" metric-suffix="%" :report-data="currentMathPercentReadyOrExceeds" :load-data="loadDataPRoE" :key="'aa-math-' + demographic" />
          </div>
          <div class="col-8 mx-0 pl-0" v-if="hasDataForDemographic(currentMathPercentReadyOrExceeds, demographic)">
            <single-metric-schools-chart title="Math by Grade: Ready or Exceeds" :grade-span-options="grades3To8" :subgroup="demographic" metric-suffix="%" :report-data="currentMathPercentReadyOrExceeds" :load-data="loadDataPRoE" :key="'aa-math-schools-' + demographic" />
          </div>
        </div>

        <div class="row" v-for="(demographic, index) in demographics" :key="'sqss' + demographic" :class="{ 'page-break-before': index % 2 == 0 }">
          <div class="col-4 mx-0 pr-0" v-if="hasDataForDemographic(currentSqssScores, demographic)">
            <single-metric-gauge-chart title="SQSS Score" :subgroup="demographic" :report-data="currentSqssScores" :load-data="loadDataSqss" :key="'aa-sqss-' + demographic" />
          </div>
          <div class="col-8 mx-0 pl-0" v-if="hasDataForDemographic(currentSqssScores, demographic)">
            <single-metric-schools-chart title="SQSS Score" :subgroup="demographic" :report-data="currentSqssScores" :load-data="loadDataSqss" :key="'aa-sqss-schools-' + demographic" />
          </div>
        </div>

        <div class="row" v-for="(demographic, index) in demographics" :key="'4year' + demographic" :class="{ 'page-break-before': index % 2 == 0 }">
          <div class="col-4 mx-0 pr-0" v-if="hasDataForDemographic(currentFourYearGraduationRates, demographic)">
            <single-metric-gauge-chart title="Four Year Graduation Rates" :subgroup="demographic" metric-suffix="%" :report-data="currentFourYearGraduationRates" :load-data="loadDataGrad" :key="'aa-4year-' + demographic" />
          </div>
          <div class="col-8 mx-0 pl-0" v-if="hasDataForDemographic(currentFourYearGraduationRates, demographic)">
            <single-metric-schools-chart title="Four Year Graduation Rates" :subgroup="demographic" metric-suffix="%" :report-data="currentFourYearGraduationRates" :load-data="loadDataGrad" :key="'aa-4year-schools-' + demographic" />
          </div>
        </div>
        <div class="row" v-for="(demographic, index) in demographics" :key="'5year' + demographic" :class="{ 'page-break-before': index % 2 == 0 }">
          <div class="col-4 mx-0 pr-0" v-if="hasDataForDemographic(currentFiveYearGraduationRates, demographic)">
            <single-metric-gauge-chart title="Five Year Graduation Rates" :subgroup="demographic" metric-suffix="%" :report-data="currentFiveYearGraduationRates" :load-data="loadDataGrad" :key="'aa-5year-' + demographic" />
          </div>
          <div class="col-8 mx-0 pl-0" v-if="hasDataForDemographic(currentFiveYearGraduationRates, demographic)">
            <single-metric-schools-chart title="Five Year Graduation Rates" :subgroup="demographic" metric-suffix="%" :report-data="currentFiveYearGraduationRates" :load-data="loadDataGrad" :key="'aa-5year-schools-' + demographic" />
          </div>
        </div>
      </b-tab>
      <b-tab title="Trend" title-item-class="d-print-none">
        <div class="row page-break-before" v-for="demographic in allStudents" :key="'sqssReadingSupportTrend' + demographic">
          <div class="col-12 mx-0">
            <single-metric-schools-chart title="Reading by Grade: In Need Of Support" :grade-span-options="grades3To8" :subgroup="demographic" mode="trend" :report-data="readingPercentInNeedOfSupport" :load-data="loadDataReading" :key="'aa-sqss-schools-support-' + demographic + '-trend'" :x-axis-formatter="TrendXAxisFormatter" />
          </div>
        </div>

        <div class="row" v-for="demographic in allStudents" :key="'sqssReading' + demographic">
          <div class="col-12 mx-0" v-if="hasDataForDemographic(currentSqssScoresReading, demographic)">
            <single-metric-schools-chart title="Reading by Grade: At Grade Level" :grade-span-options="grades3To8" :subgroup="demographic" mode="trend" :report-data="sqssScoresReading" :load-data="loadDataReading" :key="'aa-sqss-schools-reading-' + demographic + '-trend'" :x-axis-formatter="TrendXAxisFormatter" />
          </div>
        </div>

        <div class="row page-break-before" v-for="demographic in allStudents" :key="'elaSupportTrend' + demographic">
          <div class="col-12 mx-0">
            <single-metric-schools-chart title="ELA by Grade: In Need Of Support" :grade-span-options="grades3To8" :subgroup="demographic" mode="trend" :report-data="elaPercentInNeedOfSupport" :load-data="loadDataInNeedofSupportMathAndELA" :key="'aa-ela-schools-support-' + demographic + '-trend'" :x-axis-formatter="TrendXAxisFormatter" />
          </div>
        </div>

        <div class="row" v-for="demographic in allStudents" :key="'ela' + demographic + 'trend'">
          <div class="col-12 mx-0" v-if="hasDataForDemographic(currentElaPercentReadyOrExceeds, demographic)">
            <single-metric-schools-chart title="ELA by Grade: Ready or Exceeds" :grade-span-options="grades3To8" :subgroup="demographic" mode="trend" metric-suffix="%" :report-data="elaPercentReadyOrExceeds" :load-data="loadDataPRoE" :key="'aa-ela-schools-' + demographic + '-trend'" :x-axis-formatter="TrendXAxisFormatter" />
          </div>
        </div>

        <div class="row" v-for="demographic in allStudents" :key="'mathSupportTrend' + demographic">
          <div class="col-12 mx-0">
            <single-metric-schools-chart title="Math by Grade: In Need Of Support" :grade-span-options="grades3To8" :subgroup="demographic" mode="trend" :report-data="mathPercentInNeedOfSupport" :load-data="loadDataInNeedofSupportMathAndELA" :key="'aa-math-schools-support-' + demographic + '-trend'" :x-axis-formatter="TrendXAxisFormatter" />
          </div>
        </div>

        <div class="row page-break-before" v-for="demographic in allStudents" :key="'math' + demographic + 'trend'">
          <div class="col-12 mx-0" v-if="hasDataForDemographic(currentMathPercentReadyOrExceeds, demographic)">
            <single-metric-schools-chart title="Math by Grade: Ready or Exceeds" :grade-span-options="grades3To8" :subgroup="demographic" mode="trend" metric-suffix="%" :report-data="mathPercentReadyOrExceeds" :load-data="loadDataPRoE" :key="'aa-math-schools-' + demographic + '-trend'" :x-axis-formatter="TrendXAxisFormatter" />
          </div>
        </div>

        <div class="row" v-for="demographic in allStudents" :key="'sqss' + demographic + 'trend'">
          <div class="col-12 mx-0" v-if="hasDataForDemographic(currentSqssScores, demographic)">
            <single-metric-schools-chart title="SQSS Score" :subgroup="demographic" mode="trend" :report-data="sqssScores" :load-data="loadDataSqss" :key="'aa-sqss-schools-' + demographic + '-trend'" :x-axis-formatter="TrendXAxisFormatter" />
          </div>
        </div>

        <div class="row page-break-before" v-for="demographic in allStudents" :key="'4year' + demographic + 'trend'">
          <div class="col-12 mx-0" v-if="hasDataForDemographic(currentFourYearGraduationRates, demographic)">
            <single-metric-schools-chart title="Four Year Graduation Rates" :subgroup="demographic" mode="trend" metric-suffix="%" :report-data="fourYearGraduationRates" :load-data="loadDataGrad" :key="'aa-4year-schools-' + demographic + '-trend'" :x-axis-formatter="TrendXAxisFormatter" />
          </div>
        </div>

        <div class="row" v-for="demographic in allStudents" :key="'5year' + demographic + 'trend'">
          <div class="col-12 mx-0" v-if="hasDataForDemographic(currentFiveYearGraduationRates, demographic)">
            <single-metric-schools-chart title="Five Year Graduation Rates" :subgroup="demographic" mode="trend" metric-suffix="%" :report-data="fiveYearGraduationRates" :load-data="loadDataGrad" :key="'aa-5year-schools-' + demographic + '-trend'" :x-axis-formatter="TrendXAxisFormatter" />
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { LeaTypes } from '@/helpers/leaTypes'
import { mapState } from 'vuex'
import { Types } from '@/modules/esa/stores/survey'
import SingleMetricGaugeChart from '../SingleMetricGaugeChart.vue'
import SingleMetricSchoolsChart from '../SingleMetricSchoolsChart.vue'

// 'Foster Care',
// 'Gifted and Talented',
// 'Homeless',
// 'Female',
// 'Male',
// 'Students with Disabilities',
// 'Military Dependent'
// 'LEP',
const allStudents = ['All Students']
const demographics = [
  'African-American',
  'Caucasian',
  'Economically Disadvantaged',
  'English Learner',
  'Hispanic',
  'Migrant',
]
const readingDemographics = [
  'African-American',
  'Caucasian',
  'Economically Disadvantaged',
  'Special Education',
  'English Learner',
  'Hispanic'
]
export default {
  data() {
    return { 
      loadDataPRoE: Promise.resolve(),
      loadDataSqss: Promise.resolve(),
      loadDataReading: Promise.resolve(),
      loadDataGrad: Promise.resolve(),
      loadDataInNeedofSupportMathAndELA: Promise.resolve(),
      allStudents,
      demographics,
      readingDemographics,
      grades3To8: [
        { value: null, text: 'All Grades' },
        { value: '03', text: 'Grade 3' },
        { value: '04', text: 'Grade 4' },
        { value: '05', text: 'Grade 5' },
        { value: '06', text: 'Grade 6' },
        { value: '07', text: 'Grade 7' },
        { value: '08', text: 'Grade 8' },
      ],
    }
  },
  computed: {
    ...mapState(Types.path, [
      Types.state.elaPercentReadyOrExceeds, 
      Types.state.mathPercentReadyOrExceeds, 
      Types.state.mathPercentInNeedOfSupport, 
      Types.state.elaPercentInNeedOfSupport, 
      Types.state.sqssScores,
      Types.state.sqssScoresReading,
      Types.state.readingPercentInNeedOfSupport,
      Types.state.fourYearGraduationRates,
      Types.state.fiveYearGraduationRates,
    ]),
    currentElaFiscalYear() {
      return this.getCurrentFiscalYear(this.elaPercentReadyOrExceeds)
    },
    currentMathFiscalYear() {
      return this.getCurrentFiscalYear(this.mathPercentReadyOrExceeds)
    },
    currentSqssFiscalYear() {
      return this.getCurrentFiscalYear(this.sqssScores)
    },
    currentSqssReadingFiscalYear() {
      return this.getCurrentFiscalYearAnyLea(this.sqssScoresReading)
    },
    currentFiveYearGraduationRatesFiscalYear() {
      return this.getCurrentFiscalYear(this.fiveYearGraduationRates)
    },
    currentFourYearGraduationRatesFiscalYear() {
      return this.getCurrentFiscalYear(this.fourYearGraduationRates)
    },
    currentMathPercentReadyOrExceeds() {
      return this.mathPercentReadyOrExceeds.filter(m => m.fy == this.currentMathFiscalYear)
    },
    currentElaPercentReadyOrExceeds() {
      return this.elaPercentReadyOrExceeds.filter(m => m.fy == this.currentElaFiscalYear)
    },
    currentSqssScores() {
      return this.sqssScores.filter(m => m.fy == this.currentSqssFiscalYear)
    },
    currentSqssScoresReading() {
      return this.sqssScoresReading.filter(m => m.fy == this.currentSqssReadingFiscalYear)
    },
    currentFourYearGraduationRates() {
      return this.fourYearGraduationRates.filter(m => m.fy == this.currentFourYearGraduationRatesFiscalYear)
    },
    currentFiveYearGraduationRates() {
      return this.fiveYearGraduationRates.filter(m => m.fy == this.currentFiveYearGraduationRatesFiscalYear)
    },
    currentReadingInNeedOfSupport() {
      return this.readingPercentInNeedOfSupport.filter(m => m.fy == this.currentElaFiscalYear)
    },
    currentMathInNeedOfSupport() {
      return this.mathPercentInNeedOfSupport.filter(m => m.fy == this.currentMathFiscalYear)
    },
    currentELAInNeedOfSupport() {
      return this.elaPercentInNeedOfSupport.filter(m => m.fy == this.currentElaFiscalYear)
    },
  },
  components: {
    SingleMetricGaugeChart,
    SingleMetricSchoolsChart,
  },
  methods: {
    getCurrentFiscalYear(values) {
      if (values.length == 0) return 0
      return Math.max(...values.filter(m => m.lea == 'AR' && m.demographic == 'All Students' && m.value > 0).map(m => m.fy))
    },
    getCurrentFiscalYearAnyLea(values) {
      if (values.length == 0) return 0
      return Math.max(...values.filter(m => m.demographic == 'All Students' && m.value > 0).map(m => m.fy))
    },
    hasDataForDemographic(values, demographic) {
      return values.filter(d => (d.dataLevel == LeaTypes.District || d.dataLevel == LeaTypes.School) && d.demographic == demographic).length > 0
    },
    SQSSChartXAxisFormatter(value) {
      if(this.getLeaType(value) == 1) {
        return `<a target='_blank' href='https://myschoolinfo.arkansas.gov/Schools/Detail/${value}?FY=${this.currentSqssFiscalYear}&tab=reports&openreport=ESSASchoolIndex&subreport=sqss'>${this.getLeaName(value)}</a>`
      }
      return this.getLeaName(value)
    },
    TrendXAxisFormatter(value) {
      return value;
    },
    getLeaName(lea) {
      return (this.leaDictionary[lea] || { name: lea }).name
    },
    getLeaType(lea){
      return (this.leaDictionary[lea] || { type: lea}).type
    }
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if (newLea && newLea.number) {
          var self = this
          self.$nextTick(function () {
            this.loadDataPRoE = Promise.all([
              this.$store.dispatch(Types.actions.getElaPercentReadyOrExceeds, { lea: newLea.number }),
              this.$store.dispatch(Types.actions.getMathPercentReadyOrExceeds, { lea: newLea.number })
            ])
            this.loadDataSqss = this.$store.dispatch(Types.actions.getSqssScores, { lea: newLea.number })
            this.loadDataInNeedofSupportMathAndELA = Promise.all([this.$store.dispatch(Types.actions.getMathPercentInNeedOfSupport, { lea: newLea.number }),
              this.$store.dispatch(Types.actions.getELAPercentInNeedOfSupport, { lea: newLea.number }), 
            ])
            this.loadDataReading = Promise.all([this.$store.dispatch(Types.actions.getReadingPercentInNeedOfSupport, { lea: newLea.number }), 
              this.$store.dispatch(Types.actions.getSqssScoresReading, { lea: newLea.number })])
            this.loadDataGrad = Promise.all([
              this.$store.dispatch(Types.actions.getFourYearGraduationRates, { lea: newLea.number }),
              this.$store.dispatch(Types.actions.getFiveYearGraduationRates, { lea: newLea.number })
            ])
          })
        }      
      }
    }
  }
}

</script>
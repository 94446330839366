<template>
  <div>
    <p class="font-weight-bold mt-3">
      {{ question.text }}
    </p>
    <ul class="list-group list-group-flush" v-if="!editMode">
      <li class="list-group-item bg-transparent borderless">
        <span class="font-italic" v-if="value == 1"><i class="fa fas fa-check text-success" /> Yes</span>
        <span class="font-italic" v-else-if="value == 0"><i class="fa fas fa-times text-danger" /> No</span>
        <span class="font-italic" v-else-if="value == 10" v-html="question.indeterminateAnswer" />
        <span class="font-italic" v-else><i class="fa-lg fas fa-question text-warning" />  No answer</span>
      </li>
    </ul>
    <b-form-radio-group v-model="$v.survey[question.id].$model" v-if="editMode" @input="updatedValue" stacked>
      <b-form-radio :value="1" class="font-italic">
        <i class="fa-fw fas fa-check text-success" /> Yes
      </b-form-radio>
      <b-form-radio :value="0" class="font-italic">
        <i class="fa-fw fas fa-times text-danger" /> No
      </b-form-radio>
      <b-form-radio :value="10" v-if="question.indeterminateAnswer">
        <span v-html="question.indeterminateAnswer" />
      </b-form-radio>
      <b-form-radio :value="null" class="font-italic">
        <i class="fa-fw fas fa-question text-warning" /> No answer
      </b-form-radio>
    </b-form-radio-group>

    <p class="font-weight-bold mt-3">
      {{ question.textDetails }}
    </p>

    <div v-if="followUpApplicable">
      <ul class="list-group list-group-flush" v-if="!editMode">
        <li class="list-group-item bg-transparent borderless font-italic">
          {{ followUpValue || '(no response provided)' }}
        </li>
      </ul> 
              
      <span v-if="editMode">
        <b-form-textarea rows="1" max-rows="10" type="text" class="d-inline font-italic" v-model.trim="$v.survey[question.id + 'Followup'].$model" />
      </span>
    </div>
    <div v-else>
      <ul class="list-group list-group-flush">
        <li class="list-group-item bg-transparent borderless font-italic">
          (not applicable)
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

/// Display the question and radio buttons for yes or no.  If the selected value is in detailsOnValues, also display the followup question as a free text.

export default {
  inject: ['$v'],
  data: function () {
    return {
      followUpApplicable: false
    }
  },
  name: 'question-yesno-details',
  computed: {
    value() {
      return this.$v.survey[this.question.id].$model
    },
    followUpValue() {
      return this.$v.survey[this.question.id + 'Followup'].$model
    },
  },
  methods: {
    updatedValue() {
      this.followUpApplicable = this.$v.survey[this.question.id].$model !== undefined && this.question.detailsOnValues.includes(this.$v.survey[this.question.id].$model)
      if (!this.followUpApplicable) this.$v.survey[this.question.id + 'Followup'].$model = ''
    }
  },
  props: {
    question: {
      type: Object,
      default: () => {}
    },
    editMode: {
      type: Boolean,
      default: () => false
    }
  },
  beforeMount()
  {
    this.updatedValue()
  }
}
</script>

<style>

</style>
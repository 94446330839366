<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-lg-6">
        <table class="table table-sm table-nocolor">
          <tr>
            <th>&nbsp;</th>
            <th class="text-right">
              {{ asrFiscalYear + 1989 }} / {{ asrFiscalYear + 1990 }} Actual
            </th>
            <th class="text-right">
              {{ asrFiscalYear + 1990 }} / {{ asrFiscalYear + 1991 }} Budget
            </th>
          </tr>

          <statistical-report-data-row :fields="overview.filter(f => f.field.fieldNumber >= 1 & f.field.fieldNumber <= 13)" />
          <statistical-report-data-header header-text="State and Local Revenue" />
          <statistical-report-data-row :fields="overview.filter(f => f.field.fieldNumber >= 14 & f.field.fieldNumber <= 24)" />
          <statistical-report-data-header header-text="Restricted Revenue from State Sources" />
          <statistical-report-data-row :fields="overview.filter(f => f.field.fieldNumber == 25)" />
          <statistical-report-data-header header-text="Regular Education" />
          <statistical-report-data-row :fields="overview.filter(f => f.field.fieldNumber >= 26 & f.field.fieldNumber <= 27)" />
          <statistical-report-data-header header-text="Special Education" />
          <statistical-report-data-row :fields="overview.filter(f => f.field.fieldNumber >= 28 & f.field.fieldNumber <= 40)" />
          <statistical-report-data-header header-text="Other Sources of Funds" />
          <statistical-report-data-row :fields="overview.filter(f => f.field.fieldNumber >= 41 & f.field.fieldNumber <= 48)" />
        </table>
      </div>
      <div class="col-md-12 col-lg-6">
        <table class="table table-sm table-nocolor">
          <tr>
            <th>&nbsp;</th>
            <th class="text-right">
              {{ asrFiscalYear + 1989 }} / {{ asrFiscalYear + 1990 }} Actual
            </th>
            <th class="text-right">
              {{ asrFiscalYear + 1990 }} / {{ asrFiscalYear + 1991 }} Budget
            </th>
          </tr>

          <statistical-report-data-header header-text="CURRENT EXPENDITURES" />
          <statistical-report-data-header header-text="Instruction" />
          <statistical-report-data-row :fields="overview.filter(f => f.field.fieldNumber >= 49 & f.field.fieldNumber <= 55)" />
          <statistical-report-data-header header-text="District Level Support" />
          <statistical-report-data-row :fields="overview.filter(f => f.field.fieldNumber >= 56 & f.field.fieldNumber <= 61)" />
          <statistical-report-data-header header-text="School Level Support" />
          <statistical-report-data-row :fields="overview.filter(f => f.field.fieldNumber >= 62 & f.field.fieldNumber <= 65)" />
          <statistical-report-data-header header-text="Non-Instructional Services" />
          <statistical-report-data-row :fields="overview.filter(f => f.field.fieldNumber >= 66 & f.field.fieldNumber <= 89)" />
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { Types } from '../../../stores/financeModule'
import StatisticalReportDataHeader from './StatisticalReportDataHeader.vue'
import StatisticalReportDataRow from './StatisticalReportDataRow.vue'

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(Types.path, [Types.getters.asrFiscalYear]),
    ...mapState(Types.path, [Types.state.overview]),
    fields() {
      return this.overview || []
    }
  },
  components: {
    StatisticalReportDataHeader,
    StatisticalReportDataRow
  },
  methods: {
    excelExportData() {
      return {
        name: 'Statistics',
        items: this.fields.map(f => {
          return { 
            Field: f.metadata[0].label,
            Amount: f.value,
            'Budget Amount': f.budgetValue
          }
        })
      }
    }
  }
}
</script>

<style scoped>
  .table-nocolor {
    background-color: inherit;
  }
</style>

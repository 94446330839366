import Vue from 'vue'
import { ChartMixin } from '../../ChartMixin'
import { credits } from '../../../helpers/credits'

const AttritionReportChart = Vue.extend({
  name: 'attrition-report-chart',
  data() {
    return {
      animationDuration: 0,
      chartOptions: {
        chart: {
          type: 'line'
        },
        plotOptions: {
          line: {
            pointInterval: 1
          }
        },
        credits: credits.SIS,
        title: {
          text: 'Attrition Chart'
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          layout: 'vertical',
          x: 0,
          y: 20
        },
        series: [],
        xAxis: {
          title: {
            text: 'School Year',
          },
          allowDecimals: false,
          tickInterval: 1
        },
        yAxis: {
          min: 0,
          max: 100,
          allowDecimals: true,
          title: {
            text: 'Attrition Rate'
          }
        }
      }
    };
  },
  /* html */
  template: `
    <div>
        <div class='card bg-light mt-3'>
          <div class='card-body'>
              <loadable-chart-card v-bind:load-data="attritionReportLoader" v-bind:enableDataToggle="false">
                  <template v-slot:default="slotProps">
                      <div class="chartElem" v-show="slotProps.chartMode">
                          <highcharts class="chart" :options="chartOptions"></highcharts>
                      </div>
                  </template>
              </loadable-chart-card>
          </div>
        </div>
    </div>
  `,
  mixins: [ChartMixin],
  computed: {
    attritionStatistics() {
      return this.$store.state.attritionReportModule.attritionStatistics
    },
    position() {
      return this.$store.state.attritionReportModule.position
    },
    chartTitle() {
        var upperPosition = this.position.charAt(0).toUpperCase() + this.position.slice(1)
        return `${upperPosition} Attrition by ${this.getTrendLevelString}`
    },
    getTrendLevelString()
    {
      var trendLevel = this.$store.state.attritionReportModule.trendLevel || 4
      switch (trendLevel.toString())
      {
          case '1': return 'School'
          case '2': return 'District'
          case '3': return 'CoOp'
          case '4': return 'State'
          default:  return ''
      }
    },
    attritionReportLoader() {
      return this.$store.state.attritionReportModule.loadData
    },
    chartData() {
      return this.$store.state.attritionReportModule.chartData
    }
  },
  watch: {
    chartData(newData) {
      this.chartOptions.series = newData
      this.chartOptions.title.text = this.chartTitle
    }
  }
})

export { AttritionReportChart }
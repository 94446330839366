<template>
  <div>
    <pdf-button />
    <excel-button title="Workforce Stability Index" />
    <h1>Workforce Stability Index | WSI</h1>
    <h2><lea-display :lea="selectedLea" /></h2>
    <workforce-stability-index-widget :selected-lea="selectedLea" />
  </div>
</template>

<script>
import WorkforceStabilityIndexWidget from './WorkforceStabilityIndexWidget.vue'
import { PageMixin } from '../../PageMixin'

export default {
    mixins: [PageMixin],
    data() {
        return {
        }
    },
    components: {
        WorkforceStabilityIndexWidget
    }
}
</script>
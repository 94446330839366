<template>
  <div>
    <div v-for="(question, index) in filteredQuestions" :key="question.id" style="page-break-inside: avoid;" :style="{'page-break-before': index > 0 && index % 2 == 0}">
      <component class="mb-1" :is="getChartComponent(question)" :question="question" :aggregated-data="aggregatedData.filter(d=>d.id == question.id)" :load-data="loadData" />
    </div>
  </div>
</template>

<script>
import QuestionYesnoChart from './charts/questionYesnoChart'
import QuestionTagSelectionChart from './charts/questionTagSelectionChart'
import QuestionSingleSelectionChart from './charts/questionSingleSelectionChart'
import QuestionMultipleSelectionChart from './charts/questionMultipleSelectionChart'

export default {
  name: 'ct-charts',
  computed: {
    filteredQuestions() {
      return this.questions.filter(s => this.getChartComponent(s) && (s.hidden === undefined || !s.hidden))
    },
  },
  props: {
    questions: {
      type: Array,
      default: () => []
    },
    aggregatedData: {
      type: Array,
      default: () => []
    },
    loadData: Promise
  },
  data() {
    return {
      
    }
  },
  methods: {
    getChartComponent(question) {
      switch(question.component) {
        case 'QuestionMultipleSelection': return 'QuestionMultipleSelectionChart';
        case 'QuestionSingleSelection': return 'QuestionSingleSelectionChart';
        case 'QuestionTagSelection': return 'QuestionTagSelectionChart';
        case 'QuestionYesno': return 'QuestionYesnoChart';
        case 'QuestionYesnoDetails': return 'QuestionYesnoChart';
        default: return null;
      }
    }
  },
  components: {
    QuestionYesnoChart,
    QuestionTagSelectionChart,
    QuestionSingleSelectionChart,
    QuestionMultipleSelectionChart
  }
}
</script>

<style>
  li.borderless {
    border: 0 none !important;
    padding-top: 0;
    padding-bottom: 0;
  }
</style>
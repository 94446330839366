<template>
  <div class="mt-3">
    <loadable-chart-card :load-data="loadData" :enable-data-toggle="false" card-style="overflow-y: hidden">
      <paginated-table :items="indicators" :fields="fields">
        <template v-slot:head(action)>
          <b-button variant="primary" :to="{name: 'Admin/Indicators/Create'}" class="float-right">
            <i class="fas fa-fw fa-plus" />
            Add Indicator
          </b-button>
        </template>
        <template v-slot:cell(action)="data">
          <div class="float-right">
            <b-link :to="{ name: 'Admin/Indicators/Edit', params: { indicatorId: data.item.perkinsIndicatorId }}">
              <i class="fas fa-fw fa-edit" />
            </b-link>
            <b-button variant="link" @click="removeIndicator(data.item)">
              <i class="fas fa-fw fa-trash text-danger" />
            </b-button>
          </div>
        </template>
      </paginated-table>
    </loadable-chart-card>
  </div>
</template>

<script>
import PaginatedTable from '@/components/PaginatedTable'
import { mapState } from 'vuex'
import { Types } from '@/modules/cte/stores/perkinsv'
import LoadableChartCard from '@/components/LoadableChartCard'

export default {
  data(){
    return {
      loadData: null,
      fields: [
        {
          key: 'indicatorCode',
          label: 'Indicator Code',
          sortable: true
        },
        // {
        //   key: 'name',
        //   label: 'Name',
        //   sortable: true
        // },
        {
          key: 'description',
          label: 'Description',
          sortable: true
        },
        {
          key: 'action',
          label: '',
          sortable: false,
          class: "text-right"
        }
      ]
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.indicators])
  },
  components: {
    PaginatedTable,
    LoadableChartCard
  },
  methods: {
    removeIndicator(indicator) {
      if(confirm(`Are you sure you want to delete Indicator: ${indicator.indicatorCode}?`)){
        this.$store.dispatch(Types.actions.deleteIndicator, { indicatorId: indicator.perkinsIndicatorId })
      }
    }
  },
  mounted() {
    this.loadData = this.$store.dispatch(Types.actions.getIndicators, { academicYear: this.$route.params.ay })
  }
}
</script>
<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'facilities-inspect-page',
  computed: {
    ...mapState('globalModule', ['userContext'])
  },
  components: {
  }
}
</script>

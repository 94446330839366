<template>
  <div>
    <div class="row">
      <div class="col">
        <h1>Roster Verification System</h1>
        <div>
          <h2><lea-display :lea="selectedLea" /></h2>
          <!-- <a @click="goToLea(selectedLea.parentLea.number)" class="cursor-pointer btn btn-sm btn-outline-warning"><i class="fa fa-fw fa-level-up-alt" /> {{ selectedLea.parentLea.name }}</a> -->
        </div>
      </div>
      <div class="col-3 text-right" v-if="schoolDetail" style="max-height: 70px;">
        <span class="h5 font-weight-bold text-right">Verification Status <span class="badge badge-lg ml-1" :class="getStatus(schoolDetail.status).class">{{ getStatus(schoolDetail.status).description }}</span></span>
        <div style="padding-right: .3em;" class="mt-1">
          <div v-if="isPrincipal && hasMissingData">
            <div class="alert alert-warning text-center">
              <i class="fa fa-exclamation-triangle" /> There are teachers in this school that have not yet been marked as approved or exempted.
            </div>
          </div>
          <state-change-widget
            v-else-if="(teacherList || []).length > 0"
            :status="schoolDetail.status"
            :available-states="schoolStates"
            :state-map="SchoolStateMap"
            :transition-action="transitionAction"
            advance-button-class="text-left"
            :identifiers="{ lea: schoolDetail.schoolLea, isDistrict: false }"
            :can-withdraw="false"
            :document="schoolDetail"
            style="z-index: 1000;"
          />
        </div>
      </div>
    </div>

    <b-row no-gutters class="mt-3" style="margin-bottom: -2rem;" v-if="(teacherList || []).length > 0">
      <b-col cols="4">
        <verification-progress-chart units="teachers" title="Teacher Verification Progress" :complete="completeTeachers" :total="totalTeachers" />
      </b-col>
      <b-col cols="4" class="text-center">
        <div class="highcharts-title" style="color:#333333;font-size:18px;fill:#333333;">
          Number of Contributors
        </div>
        <div class="highcharts-data-labels highcharts-series-0 highcharts-solidgauge-series highcharts-tracker" style="font-size:20px; font-family: 'Source Sans Pro'; color: rgb(0, 0, 0); font-weight: bold;">
          {{ totalContributors }}
        </div>
      </b-col>        
    </b-row>
    <div v-else>
      <loading-spinner />
    </div>

    <keep-alive>
      <timeline />
    </keep-alive>
    
    <div class="mt-3">
      <b-card no-body v-if="(teacherList || []).length > 0">
        <b-tabs card>
          <b-tab no-body active>
            <template v-slot:title>
              Teachers with Verifiable Classrooms <b-badge pill>
                {{ (teachersWithVerifiableCourses || []).length }}
              </b-badge>
            </template>
            <div class="card-body card-body-secondary">
              <teacher-list :hide-lea="true" :teacher-list="teachersWithVerifiableCourses" :verifiable="true" />
            </div>
          </b-tab>
          <b-tab no-body>
            <template v-slot:title>
              Teachers with no Verifiable Classrooms <b-badge pill>
                {{ (teachersWithoutVerifiableCourses || []).length }}
              </b-badge>
            </template>
            <div class="card-body card-body-secondary">
              <teacher-list :hide-lea="true" :teacher-list="teachersWithoutVerifiableCourses" :verifiable="false" />
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
      <b-card v-else>
        <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
          <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
        </div>
      </b-card>
    </div>

    <div class="mt-3">
      <b-card-header>
        Contributing Teachers
      </b-card-header>
      <b-card v-if="(coTeacherList || []).length > 0">
        <co-teacher-list :hide-lea="true" :teacher-list="coTeacherList" />
      </b-card>
      <b-card v-else>
        <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
          <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
        </div>
      </b-card>
    </div>
  
    <crosstabs />
    <div class="mt-3" v-if="schoolDetail">
      <b-card-header>
        Status History
      </b-card-header>
      <b-card>
        <div class="card-body-secondary">
          <div class="p-0">
            <change-log-history-table :change-logs="schoolDetail.changeLogs || []" :state-map="SchoolStateMap" />
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';

import LeaDisplay from '@/components/LeaDisplay.vue'
import VerificationProgressChart from './charts/VerificationProgressChart.vue'
import TeacherList from '../common/TeacherList.vue'
import CoTeacherList from '../common/CoTeacherList.vue'
import Timeline from '@/modules/rvs/components/common/Timeline.vue'
import Crosstabs from '@/modules/rvs/components/common/Crosstabs.vue'
import CardStateIcon from '@/components/CardStateIcon.vue'

import StateChangeWidget from '@/components/workflow/StateChangeWidget.vue'
import { SchoolStateMap } from '@/modules/rvs/rvsStateMap.js'
import ChangeLogHistoryTable from '@/components/workflow/ChangeLogHistoryTable.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default {
  components: {
    LeaDisplay,
    VerificationProgressChart,
    TeacherList,
    CoTeacherList,
    Timeline,
    Crosstabs,
    CardStateIcon,
    StateChangeWidget,
    ChangeLogHistoryTable,
    LoadingSpinner
  },
  data() {
    return {
      SchoolStateMap,
      loading: null
    }
  },
  computed: {
    ...mapGetters('rvsModule', ['completeTeachers', 'totalTeachers']),
    ...mapGetters('globalModule', ['isPrincipal']),
    ...mapState('rvsModule', ['teacherList', 'coTeacherList', 'fiscalYear', 'schoolDetail', 'schoolStates']),
    teachersWithVerifiableCourses() {
      return (this.teacherList || []).filter(teacher => (teacher.subjects || []).length > 0 && teacher.totalVerifiableStudents > 0)
    },
    teachersWithoutVerifiableCourses() {
      return (this.teacherList || []).filter(teacher => (teacher.subjects || []).length === 0 || teacher.totalVerifiableStudents <= 0)
    },
    hasMissingData() {
      return this.teachersWithVerifiableCourses.some(t => t.totalVerifiableStudents > 0 && t.verificationStatus != 999 && t.verificationStatus != 101)
    },
    transitionAction: () => 'rvsModule/transitionLea',
    totalContributors() {
      return this.teacherList && this.teacherList.length > 0 ? this.teacherList.find(f => f.totalContributors !== undefined).totalContributors : 0
    }
  },
  methods: {
    getStatus(status) {
      switch(status) {
        case 0:
          return { class: 'badge-secondary', description: 'Draft' }
        case 1:
          return { class: 'badge-warning', description: 'Returned to Principal' }
        case 100:
          return { class: 'badge-primary', description: 'Pending Review' }
        case 101:
          return { class: 'badge-success', description: 'Accepted' }
        default:
          return { class: 'badge-secondary', description: 'Unknown' }
      }
    },
    async reloadData() {
      if (this.fiscalYear)
        this.loading = Promise.all([
          this.$store.dispatch('rvsModule/getTeacherList', this.selectedLea.number),
          this.$store.dispatch('rvsModule/getCoTeacherList', this.selectedLea.number),
          this.$store.dispatch('rvsModule/getSchoolDetail', this.selectedLea.number),
          this.$store.dispatch('rvsModule/getSchoolStates', this.selectedLea.number)
        ])
    },
  },
  watch: {
    fiscalYear: {
      handler() {
        this.reloadData()
      }
    },
    selectedLea: {
      immediate: true,
      handler() {
        this.reloadData()
      }
    }
  }
}
</script>
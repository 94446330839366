<template>
  <div class="card">
    <div class="card-body">
      <div class="overflow-auto">
        <table-control-widget :per-page="perPage" @filter-changed="debounceInput($event)" @page-length-changed="perPage = $event" />
        <b-table ref="shortageReportAreaStaffTable"
                 striped
                 hover
                 selectable
                 select-mode="single"
                 :items="getAreaEducators"
                 :fields="tableColumns"
                 :per-page="perPage"
                 :filter="filter"
                 :current-page="currentPage"
                 :busy="isLoading">
          <div slot="table-busy" class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong>Loading...</strong>
          </div>
          <template v-slot:cell(retireDropDate)="data">
            {{ data.value | date("MM/DD/YYYY") }}
          </template>
          <template v-slot:head(veteranTeacher)="data">
            <custom-tooltip element-id="veteranTeacherDetailsTooltip2" :label="data.label">
              A teacher is considered a Veteran Teacher if:
              <ul class="list-unstyled">
                <li>
                  The sum of their age plus years of service >= 84, or
                </li>
                <li>
                  They are retired, or
                </li>
                <li>
                  They are identified as a Working Retiree
                </li>
              </ul>
            </custom-tooltip>
          </template>
          <template v-slot:head(onALP)="data">
            <custom-tooltip element-id="onALPJobTooltip2" :label="data.label">
              Sourced from AELS
            </custom-tooltip>
          </template>
          <template v-slot:head(isNewLicense)="data">
            <custom-tooltip element-id="newLicensesJobTooltip2" :label="data.label">
              Educators whose licenses were first attained this school year
            </custom-tooltip>
          </template>
        </b-table>
        <table-pagination-widget
          :total-rows-count="totalRowCount"
          :filtered-rows-count="filteredRowCount"
          :page-length="perPage"
          @page-changed="currentPage = $event" />
      </div>
    </div>
  </div>
</template>

<script>
import { TableMixin } from '../../TableMixin'
import { debounce } from '../../../helpers/globalHelpers'
import CustomTooltip from '../../CustomTooltip'

export default {
  mixins: [TableMixin],
    components: {
    CustomTooltip
  },
  data() {
    return {
      isLoading: false,
      perPage: 100,
      currentPage: 1,
      totalRowCount: 0,
      filteredRowCount: 0,
      filter: null,
      fields: [
        {
          key: 'groupName',
          label: 'Group Name',
          sortable: true
        },
        {
          key: 'subgroupName',
          label: 'Subgroup',
          sortable: true,
          tdClass: 'subgroup',
          formatter: d => {
            return d === '|SELF|' ? null : d
          }
        },
        {
          key: 'teacherName',
          label: 'Teacher Name',
          sortable: true
        },
        {
          key: 'schoolLEA',
          label: 'School LEA',
          sortable: true
        },
        {
          key: 'schoolName',
          label: 'School Name',
          sortable: true
        },
        {
          key: 'districtLEA',
          label: 'District LEA',
          sortable: true
        },
        {
          key: 'districtName',
          label: 'District Name',
          sortable: true
        },
        {
          key: 'coopLEA',
          label: 'Coop LEA',
          sortable: true
        },
        {
          key: 'coopName',
          label: 'Coop Name',
          sortable: true
        },
        {
          key: 'licenseCode',
          label: 'License Code',
          tdClass: 'licenses',
          sortable: true
        },
        {
          key: 'serviceYears',
          label: 'Service Years',
          sortable: true
        },
        {
          key: 'onALP',
          label: 'On ALP',
          sortable: true,
          formatter: function(d) {
            return d ? 'Yes' : 'No'
          }
        },
        {
          key: 'isNewLicense',
          label: 'New License',
          sortable: true,
          formatter: function(d) {
            return d ? 'Yes' : 'No'
          }
        },
        {
          key: 'veteranTeacher',
          label: 'Veteran Teacher',
          sortable: true,
          formatter: function(d) {
            return d ? 'Yes' : 'No'
          }
        },
        {
          key: 'age',
          label: 'Age',
          sortable: true
        },
        {
          key: 'serviceYears',
          label: 'Service Years',
          sortable: true
        },
        {
          key: 'retireDropDate',
          label: 'Retirement Date',
          sortable: true
        }
      ]
    }
  },
  props: {
    scopeLea: Object,
    scopeLevel: Number,
    grouping: String,
    parameters: Object,
    restrictToRetirees: Boolean
  },
  computed: {
    selectFilterAreaGroup() {
      return this.$store.state.shortageReportModule.filter.areaGroup
    },
    selectFilterAreaSubgroup() {
      return this.$store.state.shortageReportModule.filter.areaSubgroup
    },
    selectFilterLicense() {
      return this.$store.state.shortageReportModule.filter.license
    },
    tableColumns() {
      return this.fields.filter(f => {
        if (f.key === 'subgroupName') return this.parameters.groupBySubgroup
        if (f.key === 'licenseCode') return this.parameters.groupByJobOrLicenseCode

        if (f.dataLevel) {
          return f.dataLevel >= this.minDataLevel
        }
        return true
      })
    }
  },
  watch: {
    scopeLea() {
      this.$refs.shortageReportAreaStaffTable.refresh()
    },
    grouping() {
      this.$refs.shortageReportAreaStaffTable.refresh()
    },
    selectFilterAreaGroup() {
      this.$refs.shortageReportAreaStaffTable.refresh()
    },
    selectFilterAreaSubgroup() {
      this.$refs.shortageReportAreaStaffTable.refresh()
    },
    selectFilterLicense() {
      this.$refs.shortageReportAreaStaffTable.refresh()
    },
    restrictToRetirees(){
      this.$refs.shortageReportAreaStaffTable.refresh()
    }
  },
  methods: {
    filterAreas(item) {
      let matches = true
      if (matches && this.selectFilterAreaGroup)
        matches = item.groupName == this.selectFilterAreaGroup
      if (matches && this.selectFilterAreaSubgroup)
        matches = item.subgroupName == this.selectFilterAreaSubgroup
      if (matches && this.selectFilterLicense)
        matches = item.licenseCode == this.selectFilterLicense

      return matches
    },
    getAreaEducators(context) {
      const params = Object.assign(
        {
          FY: 29,
          scopeLea: this.$store.state.globalModule.leaContext.SelectedLea ? this.$store.state.globalModule.leaContext.SelectedLea.number : null,
          scopeLevel: this.$store.state.globalModule.leaContext.SelectedLea ? this.$store.state.globalModule.leaContext.SelectedLea.type : null,
          groupBySubgroup: this.grouping == 1 || this.grouping == 3,
          groupByJobOrLicenseCode: this.grouping == 2 || this.grouping == 3
        },
        context
      )

      //console.log('calling get area educators', params)
      return this.$store.dispatch('shortageReportModule/getAreaEducators', {...params, retiredPersonnelOnly: this.restrictToRetirees} ).then(response => {
        this.totalRowCount = response.totalRowsAvailable
        this.filteredRowCount = response.totalRowsFiltered
        return response.data.filter(this.filterAreas)
      })
    },
    debounceInput: debounce(function(e) {
      this.filter = e
    }, 750)
  }
}
</script>

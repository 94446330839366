<template>
  <b-col cols="3">
    <b-card>
      <h3>{{ count.tableName.slice(count.tableName.indexOf('.') + 1) }}</h3>
      {{ count.recordCount.toLocaleString() }} Records
    </b-card>
  </b-col>
</template>

<script>
export default {
  props:{
    count: Object
  }
}
</script>
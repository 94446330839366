<template>
  <div>
    <b-form>
      <b-form-group label="Cluster">
        <b-form-select v-model="cluster" :options="clusters" value-field="id" text-field="name" />
      </b-form-group>
      <b-form-group label="Pathway">
        <b-form-select v-model="pathway" :options="pathways" value-field="id" text-field="name" />
      </b-form-group>
      <b-form-group label="Program of Study">
        <b-form-select v-model="programOfStudy" :options="programsOfStudy" value-field="id" text-field="name" />
      </b-form-group>
      <b-form-row>
        <b-col class="text-center">
          <b-button variant="primary" class="mr-2" @click="save">
            Save
          </b-button>
          <b-button variant="secondary" @click="cancel">
            Cancel
          </b-button>
        </b-col>
      </b-form-row>
    </b-form>
  </div>
</template>

<script>
import { Types as CommonTypes } from '@/modules/cte/stores'
import { mapState } from 'vuex'
function sortByName(a,b) {
  if (a.name.toLowerCase() == b.name.toLowerCase()) return 0
  return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
}

export default {
  data() {
    return {
      cluster: null,
      pathway: null,
      programOfStudy: null
    }
  },
  methods: {
    save() {
      this.$store.dispatch(CommonTypes.actions.addClusterItemRelationship, {
        ay: this.$route.params.ay,
        clusterId: this.cluster,
        pathwayId: this.pathway,
        programOfStudyId: this.programOfStudy
      })

      this.$emit('on-action-complete')
    },
    cancel() {
      this.$emit('on-action-complete')
    }
  },
  mounted() {
    console.log(this.initialSelections)
    this.cluster = this.initialSelections.cluster
    this.pathway = this.initialSelections.pathway
  },
  computed: {
    ...mapState(CommonTypes.path, [CommonTypes.state.occupationalAreaMetadata]),
    clusters() {
      return this.occupationalAreaMetadata.clusters.slice().sort(sortByName)
    },
    pathways() {
      return this.occupationalAreaMetadata.pathways.slice().sort(sortByName)
    },
    programsOfStudy() {
      return this.occupationalAreaMetadata.programsOfStudy.slice().sort(sortByName)
    }    
  },
  components: {
  },
  props: {
    initialSelections: Object
  }
}
</script>
<template lang="html">
  <div class="d-inline mx-2">
    <Promised :promise="actionPromise" v-show="actionPromise">
      <template v-slot:pending>
        <button disabled="disabled" @click="exportExcel" class="btn btn-success float-right d-print-none mx-1">
          <i class="fas fa-spinner fa-spin" />&nbsp;Excel
        </button>
      </template>
      <template v-slot="data">
        <button @click="exportExcel" class="btn btn-success float-right d-print-none mx-1">
          <i class="far fa-file-excel" />&nbsp;Excel
          <span class="d-none">{{ data }}</span>
        </button>
      </template>
      <template v-slot:rejected="error">
        <button @click="exportExcel" class="btn btn-success float-right d-print-none mx-1">
          <i class="fas fa-exclamation-circle" />&nbsp;Excel
          <span class="d-none">{{ error }}</span>
        </button>
      </template>
    </Promised>
    <button @click="exportExcel" class="btn btn-success float-right d-print-none mx-1" v-show="actionPromise == null">
      <i class="far fa-file-excel" />&nbsp;Excel
    </button>
  </div>
</template>

<script>
import { Promised } from 'vue-promised'
import ApiService from '@/services/ApiService'

export default {
  data() {
    return {
      actionPromise: null
    }
  },
  props: {
    title: String,
  },
  computed:{
    selectedLea() { return this.$store.state.globalModule.leaContext.SelectedLea },
  },
  methods: {
    exportExcel() {
      const apiService = new ApiService()
      this.actionPromise = apiService.DownloadWithPost(`/api/purple/${this.selectedLea.number}/excel`, {})
    },
  },
  components: {
    Promised
  }
}
</script>
<template lang="html">
  <div>
    <span v-if="lea && lea.number">
      <lea-icon :lea="lea" /> {{ lea.name }} ({{ lea.number }})
    </span>
    <div v-else style="line-height: 38.4px;" />
  </div>
</template>

<script>
import LeaIcon from './LeaIcon.vue'

export default {
  name: 'lea-display',
  props: {
    lea: Object
  },
  components: {
    LeaIcon
  }
}
</script>

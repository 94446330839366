<template>
  <highcharts class="chart" :options="chartOptions" />
</template>

<script>
import { mapState } from 'vuex'
import startCase from 'lodash.startcase'

export default {
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'pie',
          height: this.$printMode ? 650 : 400
        },
        legend: {
          maxHeight: this.$printMode ? 450 : 200
        },
        credits: {
          text: ''
        },
        title: {
          text: `District Closures by Type`
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
          }
        },
        series: []
      }
    }
  },
  computed: {
    ...mapState('core/sctClosures', ['overallSummaries'])
  },
  props:{
    xAxis: String,
    mode: String
  },
  watch:{
    districtClosures: {
      immediate: true,
      handler() {
        this.chartOptions.series = []
        this.chartOptions.series.push({
          name: 'Closures',
          data: this.overallSummaries.map(s => ({ name: startCase(s.modType), y: s.typeCounts[0].notices }))
        })
      }
    }
  }
}
</script>
import Vue from 'vue'
import CardStateIcon from '../CardStateIcon.vue'
import { TableMixin } from '../TableMixin'
import { Promised } from 'vue-promised'

const EnrollmentsTable = Vue.extend({
    name: 'enrollments-table',
    mixins: [TableMixin],
    /* html */
    template: `<div class='card bg-light mt-3'>
        <div class='card-header'>
        Enrollment <span v-if='selectedCourse'>for {{ selectedCourse.courseTitle }} Section Number: {{ selectedCourse.section_num }} MP: {{ selectedCourse.markingPeriod }}</span>
        </div>
        <div class='card-body'>
            <div class='card' style='overflow-y: hidden; min-height: 25em; height: auto;'>
                <div class='card-body inner-card'>
                    <div v-if='!selectedStudent && enrollments && enrollments.length == 0' class='alert alert-info mt-4'>
                        Select a teacher or course to load enrollment data
                    </div>
                    <Promised v-bind:promise="loadEnrollments">
                        <template v-slot:pending>
                            <div class="align-items-center justify-content-center" style="height: 100%; width: 100%">
                                <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
                            </div>
                        </template>
                        <template v-slot="data" :class="centerContent ? 'align-items-center justify-content-center' : ''">                            
                            <b-table v-if='selectedStudent || (enrollments && enrollments.length > 0)' ref='studentTable' hover selectable responsive small show-empty select-mode='single' :items='enrollments' :per-page='tableControl.pageLength'
                            :filter='tableControl.filter' :tbody-tr-class='rowClass' :current-page='tableControl.currentPage' :fields='tableFields' @filtered='onFiltered'
                            @row-selected='onStudentSelected'>
                            </b-table>            
                            <table-pagination-widget v-if='!selectedStudent && enrollments && enrollments.length > 0' :total-rows-count='totalRowCount' :page-length='tableControl.pageLength' v-on:page-changed='tableControl.currentPage=$event'></table-pagination-widget>
                        </template>
                        <template v-slot:rejected="error" class="align-items-center justify-content-center">
                            <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" v-bind:message="'Error: ' + error.message" />
                        </template>
                    </Promised>
                </div>
            </div>
        </div>
    </div>`,
    data() {
        return {
            loadEnrollments: null,
            tableControl: {
                filter: null,
                pageLength: 10,
                currentPage: 1
            },
            tableFields: [
                { key: 'fullName', sortable: true, sortDirection: 'asc', tdClass: 'text-nowrap' },
                { key: 'studentId', label: 'State ID' },
                { key: 'gradeLevel', sortable: true, tdClass: 'text-nowrap' },
                { key: 'gender', sortable: true },
                { key: 'courseNum', sortable: true },
                { key: 'sectionNumber', sortable: true },
                { key: 'courseTitle', sortable: true },
                { key: 'markingPeriod', sortable: true },
                { key: 'meetsOnDays', sortable: false }
            ],
            filteredEnrollments: null
        }
    },
    components: {
        Promised,
        CardStateIcon
    },
    props:{
        qsMarkingPeriod: String
    },
    methods: {
        onFiltered(filteredItems) {
            this.tableControl.currentPage = 1,
            this.filteredEnrollments = filteredItems
        },
        onStudentSelected(item) {
            if (item.length > 0) {
                this.$store.dispatch('scheduleModule/setSelectedStudent', { student: item[0], lea: this.selectedLea.number })
                this.tableControl.filter = item[0].fullName
            }
            else {
                this.filteredEnrollments = null
            }
            this.tableControl.currentPage = 1
        },
        excelExportData() {
            if (!this.selectedCourse && !this.selectedTeacher) return null
            return {
                name: 'Enrollments',
                items: (this.filteredEnrollments || this.enrollments).map(item => {
                    return {
                        "Full Name": (item.firstName || '').trim() + ' ' + (item.lastName || '').trim(),
                        "State ID": item.studentId,
                        "Grade Level": item.gradeLevel,
                        "Gender": item.gender,
                        "Course Num": item.courseNum,
                        "Section Number": item.sectionNum,
                        "Course Title": item.courseTitle,
                        "Marking Period": item.markingPeriod,
                        "Meets On Days": item.meetsOnDays
                    }
                })
            }
        },
        rowClass(item) {
            if (!item) return
            if (item.markingPeriodStatus == 'I') return 'text-secondary'
        }
    },
    computed: {
        selectedLea() { return this.$store.state.globalModule.leaContext.SelectedLea },
        selectedStudent() { return this.$store.state.scheduleModule.selectedStudent },
        selectedCourse() { return this.$store.state.scheduleModule.selectedCourse },
        selectedTeacher() { return this.$store.state.scheduleModule.selectedTeacher },
        hideInactive() { return this.$store.state.scheduleModule.hideInactive },
        enrollments() {
            //debugger
            var enrollments = this.$store.state.scheduleModule.courseEnrollment 
            if (this.selectedCourse) {
                enrollments = enrollments.filter(
                    e => e.sectionNumber == this.selectedCourse.section_num && e.markingPeriod == this.selectedCourse.markingPeriod
                )
            }
            if(this.hideInactive){
                enrollments = enrollments.filter(
                    e => e.markingPeriodStatus == 'A'
                )
            }
            return enrollments
        },
        totalRowCount() { return this.enrollments.length }
    },
    watch: {
        selectedTeacher: {
            handler(teacher) {
                //debugger
                if (teacher) {
                    this.loadEnrollments = this.$store.dispatch('scheduleModule/getTeacherEnrollment', {
                        lea: this.selectedLea.number,
                        teacher: teacher,
                        markingPeriod: this.qsMarkingPeriod
                    })
                }
                else if(!teacher && !this.selectedStudent){
                    this.$store.commit('scheduleModule/setCourseEnrollment',[])
                }
            }
        },
        selectedCourse:{
            handler(course){
                //debugger
                if(course && (!this.selectedTeacher || !this.selectedStudent)){
                    this.loadEnrollments = this.$store.dispatch('scheduleModule/getTeacherEnrollment',{
                        lea: this.selectedCourse.lea,
                        teacher: course,
                        markingPeriod: this.qsMarkingPeriod
                    })
                }
            }
        },
        selectedStudent: {
            handler(student) {
                if (!student) {
                    this.$refs.studentTable.clearSelected()
                    this.tableControl.filter = ''
                }
                else if(this.selectedTeacher){
                    this.tableControl.filter = student.fullName
                }
            }
        }
    }
})
export { EnrollmentsTable }
<template>
  <div style="height: 100%;">
    <div class="sidebar-scroll">
      <ul class="nav nav-pills flex-column ml-2 mr-2">
        <li class="nav-item">
          <a class="nav-link" href="#overview">
            <object type="image/svg+xml" data="/Content/icons/LI-Overview.svg" class="svg-icon" />
            Overview
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#enrollment">
            <object type="image/svg+xml" data="/Content/icons/LI-Enrollment.svg" class="svg-icon" />
            Enrollment
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#demographics">
            <object type="image/svg+xml" data="/Content/icons/student-group-gs.svg" class="svg-icon" />
            Demographics
          </a>
        </li>
        <li class="nav-item" v-if="selectedLea.type != LeaTypes.Coop">
          <a class="nav-link" href="#instructional-options">
            <object type="image/svg+xml" data="/Content/icons/instructional-option.svg" class="svg-icon" />
            Instructional Options
          </a>
        </li>
        <li class="nav-item" v-if="selectedLea.type != LeaTypes.Coop">
          <a class="nav-link" href="#attendance-rates">
            <object type="image/svg+xml" data="/Content/icons/attendance-rate.svg" class="svg-icon" />
            Attendance Rates
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#gifted-talented">
            <span class="fas fa-fw fa-star text-secondary" />
            Gifted and Talented
          </a>
        </li>
        <li class="nav-item" v-if="selectedLea.type != LeaTypes.Coop">
          <a class="nav-link" href="#graduation-count">
            <span class="fas fa-fw fa-graduation-cap text-secondary" />
            Graduates / Completers
          </a>
        </li>
        <li class="nav-item" v-if="selectedLea.type != LeaTypes.Coop">
          <a class="nav-link" href="#graduation-rate">
            <span class="fas fa-fw fa-percent text-secondary" />
            Graduation Rate
          </a>
        </li>        
        <li v-if="selectedLea.type == LeaTypes.District" class="nav-item">
          <a class="nav-link" href="#schoolboard">
            <object type="image/svg+xml" data="/Content/icons/LI-SchoolBoard.svg" class="svg-icon" />
            School Board
          </a>
        </li>
        <li v-if="selectedLea.type == LeaTypes.School" class="nav-item">
          <a class="nav-link" href="#essa">
            <object type="image/svg+xml" data="/Content/icons/LI-LetterGrades.svg" class="svg-icon" />
            ESSA School Index
          </a>
        </li> 
        <li v-if="selectedLea.type != LeaTypes.School" class="nav-item">
          <a class="nav-link" href="#lettergrades">
            <object type="image/svg+xml" data="/Content/icons/LI-LetterGrades.svg" class="svg-icon" />
            Letter Grades
          </a>
        </li>
        <li class="nav-item" v-if="selectedLea.type != LeaTypes.County">
          <a class="nav-link" href="#waivers">
            <object type="image/svg+xml" data="/Content/icons/LI-Waiver.svg" class="svg-icon" />
            Waivers
          </a>
        </li>
        <li v-if="selectedLea.type == LeaTypes.State" class="nav-item">
          <a class="nav-link" href="#coops">
            <object type="image/svg+xml" data="/Content/icons/LI-Coops.svg" class="svg-icon" />
            Co-ops
          </a>
          <a class="nav-link" href="#counties">
            <object type="image/svg+xml" data="/Content/icons/LI-Counties.svg" class="svg-icon" />
            Counties
          </a>
        </li>
        <li v-if="selectedLea.type == LeaTypes.State || selectedLea.type == LeaTypes.Coop || selectedLea.type == LeaTypes.County" class="nav-item">
          <a class="nav-link" href="#districts">
            <object type="image/svg+xml" data="/Content/icons/LI-Districts.svg" class="svg-icon" />
            Districts
          </a>
          <a class="nav-link" href="#charterDistricts">
            <object type="image/svg+xml" data="/Content/icons/LI-DistrictCharter.svg" class="svg-icon" />
            Charter Districts
          </a>
        </li>
        <li v-if="selectedLea.type == LeaTypes.District" class="nav-item">
          <a class="nav-link" href="#schools">
            <object type="image/svg+xml" data="/Content/icons/LI-Schools.svg" class="svg-icon" />
            Schools
          </a>
        </li>
      </ul>
    </div>
    <pageviews-chart />
    <b-tooltip target="pageviews-tooltip-target" triggers="hover" placement="auto">
      Pageviews is the total number of pages viewed. Repeated views of a single page are counted.
    </b-tooltip>
  </div>
</template>

<script>
  import { LeaTypes } from '@/helpers/leaTypes'
  import PageviewsChart from '../PageviewsChart'

  export default {
    data() {
      return {
        LeaTypes
      }
    },
    components: { 
      PageviewsChart
    },
    computed: {
      selectedLea() { return this.$store.state.globalModule.leaContext.SelectedLea }
    },
  }
</script>

<style>
  .svg-icon {
    height: 1.25rem;
    width: 1.25rem;
    top: -0.25em;
    position: relative;
    display: inline-flex;
    align-self: center;
    vertical-align: bottom;
    fill: #6c757d;
  }
</style>
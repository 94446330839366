<template>
  <div class="report-container">
    <div class="row mb-2">
      <div class="col">
        <b-form-select :options="ayOptions" v-model="academicYear" @change="getFilteredMonth">
          <template #first>
            <b-form-select-option :value="null" disabled>
              -- Academic Year --
            </b-form-select-option>
          </template>
        </b-form-select>  
        <b-form-select :options="monthOptions" v-model="monthOption" @change="getData">
          <template #first>
            <b-form-select-option :value="null" disabled>
              -- Please select a month --
            </b-form-select-option>
          </template>
        </b-form-select>    
      </div>
      <div class="col-sm-auto" v-if="!userContext.isCTEReadOnly">
        <animated-button class="btn btn-success" :disabled="!canSaveNarrative" :promise-resolver="createNarrative">
          <i class="fas fa-save mr-2" /> Save Report &amp; Add Narrative
        </animated-button>
      </div>
    </div>

    <Promised :promise="loadData" v-show="loadData">
      <template v-slot:pending>
        <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
          <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
        </div>
      </template>
      <template v-slot:rejected="error" class="align-items-center justify-content-center">
        <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
      </template>      
      <template v-slot>
        <report-monthly-cumulative-summary :report-data="reportData" v-if="reportData != {}" />
      </template>
    </Promised>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Promised } from 'vue-promised'
import CardStateIcon from '@/components/CardStateIcon'
import ReportMonthlyCumulativeSummary from './ReportMonthlyCumulativeSummary.vue'
import { Types } from '@/modules/cte/stores/careercoach'
import AnimatedButton from '@/components/AnimatedButton.vue'
import { AYDependentReportMixin } from '../AYDependentReportMixin'
export default {
  data() {
    return {
      loadData: null,
      monthOption: null,
      yearOption: null,
      reportData: {}
    }
  },
  components: {
    ReportMonthlyCumulativeSummary,
    Promised,
    CardStateIcon,
    AnimatedButton
  },
  methods: {
    async getData() {
      this.reportData = {}

      if (this.academicYear && this.monthOption) {
        var date = new Date(this.monthOption)
        var payload = {
          lea: this.selectedLea.number,
          academicYear: this.academicYear,
          calendarYear: date.getFullYear(),
          month: date.getMonth()+1
        }

        this.loadData = this.$store.dispatch('cte/careercoach/reporting/getMonthlyCumulativeSummary', payload)
        .then(d=>this.reportData = d)
      }
    },
    async getFilteredMonth() {
      this.monthOptions
    },
    createNarrative() {
      var lea = this.selectedLea.number
      var date = new Date(this.monthOption)
      return this.$store.dispatch(Types.actions.createNarrative, {
        ReportName: 'MonthlyCumulativeSummary',
        Range: JSON.stringify({
          academicYear: this.academicYear,
          calendarYear: date.getFullYear(),
          month: date.getMonth()+1,
          lea: lea }),
        LEA: lea
      }).then((narrativeId) => {
        this.$router.push({ name: 'CTE/CareerCoach/Narratives/Edit', params: { narrativeId: narrativeId } })
      })      
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    monthOptions() {
      var options = []
      var date = new Date(new Date().getFullYear(), new Date().getMonth())
      var currentMonth = new Date(new Date().getFullYear(), new Date().getMonth())
      if(this.academicYear ){
        date.setFullYear(this.academicYear + 1999)
        date.setMonth(7)
        for(var i=0; i <=11; i++ ) {
          options.push({ value: date.toString(), text: date.toLocaleDateString('en-US', {year: 'numeric', month: 'long'}) })
          if(currentMonth.toLocaleDateString('en-US', {year: 'numeric', month: 'long'})  == date.toLocaleDateString('en-US', {year: 'numeric', month: 'long'}) ){
            return options
          }
          date.setMonth(date.getMonth() + 1)
        }
      }
      else{
        for(var k=0; k <=24; k++ ) {
          options.push({ value: date.toString(), text: date.toLocaleDateString('en-US', {year: 'numeric', month: 'long'}) })
          date.setMonth(date.getMonth() - 1)
        }
      }
      return options
    },
    canSaveNarrative() {
      return Object.keys(this.reportData).length > 0
    }
  },
  watch: {
    selectedLea() {
      this.getData()
    }
  },
  mixins: [AYDependentReportMixin]
}
</script>
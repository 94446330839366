<template>
  <div>
    <pdf-button />
    <arrfl-excel-button title="Arkansas Ready for Learning" />
    <a :id="sections[0].target" class="anchor" />
    <h1>Arkansas Ready for Learning</h1>
    <h2>
      <lea-display :lea="resolvedLea" />
    </h2>

    <div class="card mt-3 bg-light" v-if="isAggregate">
      <div class="card-body">
        <div class="card-group">
          <arrfl-progress-summary :load-data="loadData" v-if="isAggregate" :phase="1" />
          <arrfl-progress-summary :load-data="loadData" v-if="isAggregate" :phase="2" />
        </div>
      </div>
    </div>

    <div v-if="initialSurveyResponse.attempting && !initialSurveyResponse.valid && !loading">
      <span class="alert alert-danger">Unauthorized Survey Access</span>
    </div>
    <div v-else>
      <b-alert variant="danger" show v-if="!userCanEdit && isDistrict && !editMode">
        <strong>Note: If a District is looking to update their AR-RFL Survey, please use the original customized link sent by the ADE DESE or email 
          <a href="mailto:ADEITServices@Arkansas.Gov">ADEITServices@Arkansas.Gov</a> to obtain their customized link in order to complete the updates.
        </strong>
      </b-alert>

      <b-button @click="startEdit" v-if="userCanEdit && isDistrict && !editMode" variant="primary">
        <i class="fas fa-edit" /> Edit Survey
      </b-button>

      <div v-if="userCanEdit && isDistrict && editMode" class="row">
        <div class="col-7">
          <b-button variant="info" @click="save()" id="saveButton" :disabled="!$v.$anyDirty || $v.$invalid">
            <i class="fas fa-save" /> Save as Draft
          </b-button>
          <b-button variant="success" class="ml-2" @click="submit()" :disabled="!$v.$anyDirty || $v.$invalid">
            <i class="fas fa-paper-plane" /> Submit to ADE-DESE
          </b-button>
          <b-button variant="danger" class="ml-2" @click="cancel()">
            <i class="fas fa-trash" /> Discard Changes
          </b-button>
        </div>
        <div class="col-5 text-right text-muted">
          Please contact
          <a href="mailto:ADEITServices@arkansas.gov">ADEITServices@arkansas.gov</a> for any technical support needs related to this survey
        </div>
      </div>

      <div v-if="isAggregate">
        <div>
          <!-- loop through sections -->
          <div
            class="card mt-3 bg-light page-break-before"
            v-for="(section, index) in sections"
            :key="section.target"
          >
            <a v-if="index > 0" :id="section.target" class="anchor" />
            <div class="card-header">
              <img :src="section.icon" v-if="section.icon" class="svg-icon">
              {{ section.name }}
            </div>
            <div class="card-body">
              <span v-if="section.faq && isDistrict" v-html="section.faq" />
              <div class="row">
                <div class="col-12">
                  <arrfl-charts :aggregated-data="aggregateData" :questions="section.questions" :load-data="loadData" />
                  <div
                    v-for="(subsection, ssidx) in getSubsections(section)"
                    :key="subsection.target"
                    class="subsection mt-3"
                  >
                    <a :id="subsection.target" class="anchor" />
                    <div class="card mt-3 bg-white" :class="{'page-break-before': ssidx > 0}">
                      <div class="card-header bg-secondary">
                        <object
                          type="image/svg+xml"
                          :data="subsection.icon"
                          v-if="subsection.icon"
                          class="svg-icon"
                        />
                        {{ subsection.name }}
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-12">
                            <arrfl-charts
                              :aggregated-data="aggregateData"
                              :questions="subsection.questions"
                              :load-data="loadData"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="alert alert-warning mt-3" v-if="!isDistrict && !isAggregate">
        <strong>Choose a school district</strong>- A school district must be selected to continue.
      </div>

      <Promised :promise="loadData" v-show="loadData" v-if="isDistrict">
        <template v-slot:pending>
          <div
            class="align-items-center justify-content-center loading-card"
            style="height: 100%; width: 100%"
          >
            <card-state-icon
              icon-class="fa-4x fas fa-spinner fa-spin text-secondary"
              message="Loading..."
            />
          </div>
        </template>
        <template v-slot>
          <div v-if="isDistrict && !isFormFilled && !editMode">
            <div class="alert alert-warning mt-3">
              <strong>Missing data</strong>- The selected district has not completed the Arkansas Ready for Learning Survey.
            </div>
          </div>
          <div v-else>
            <!-- loop through sections -->
            <div
              class="card mt-3 bg-light"
              v-for="(section, index) in sections"
              :key="section.target"
              :class="{'page-break-before': index > 0}"
            >
              <a v-if="index > 0" :id="section.target" class="anchor" />
              <div class="card-header">
                <img :src="section.icon" v-if="section.icon" class="svg-icon">
                {{ section.name }}
              </div>
              <div class="card-body">
                <span class="d-print-none" v-if="section.faq && isDistrict" v-html="section.faq" />
                <div class="row">
                  <div class="col-12">
                    <arffl-questions :questions="section.questions" :edit-mode="editMode" />
                    <div
                      v-for="(subsection, ssidx) in getSubsections(section)"
                      :key="subsection.target"
                      class="subsection mt-3"
                      :class="{'page-break-before': ssidx > 0}"
                    >
                      <a :id="subsection.target" class="anchor" />
                      <div class="card mt-3 bg-white">
                        <div class="card-header bg-secondary">
                          <object
                            type="image/svg+xml"
                            :data="subsection.icon"
                            v-if="subsection.icon"
                            class="svg-icon"
                          />
                          {{ subsection.name }}
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-12">
                              <arffl-questions
                                :questions="subsection.questions"
                                :edit-mode="editMode"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <b-button
              @click="startEdit"
              v-if="userCanEdit && isDistrict && !editMode"
              variant="primary"
              class="mt-3"
            >
              <i class="fas fa-edit" /> Edit Survey
            </b-button>

            <div v-if="userCanEdit && isDistrict && editMode" class="row mt-3">
              <div class="col-12">
                <b-button variant="info" @click="save()" :disabled="!$v.$anyDirty || $v.$invalid">
                  <i class="fas fa-save" /> Save as Draft
                </b-button>
                <b-button variant="success" class="ml-2" @click="submit()" :disabled="!$v.$anyDirty || $v.$invalid">
                  <i class="fas fa-paper-plane" /> Submit to ADE-DESE
                </b-button>
                <b-button variant="danger" class="ml-2" @click="cancel()">
                  <i class="fas fa-trash" /> Discard Changes
                </b-button>
              </div>              
            </div>
          </div>
        </template>
        <template v-slot:rejected="error" class="align-items-center justify-content-center">
          <card-state-icon
            icon-class="fa-4x fas fa-exclamation-triangle text-danger"
            :message="'Error: ' + error.message"
          />
        </template>
      </Promised>
    </div>
  </div>
</template>


    
<script>
window.scrollToPhase2 = function() {
  location.hash = ""
   
  var checkExist = setInterval(function() {
    if (document.getElementById("saveButton")) {
      clearInterval(checkExist);
      location.hash = "#phase2"
    }
  }, 100)
}

import { Promised } from 'vue-promised'
import { PageMixin } from '../../PageMixin'
import { mapState, mapGetters } from 'vuex'
import { surveyTemplate } from './surveyTemplate'
import { LeaTypes } from '../../../helpers/leaTypes'
import CardStateIcon from '../../CardStateIcon.vue'
import ArfflQuestions from './arrflQuestions'
import ArrflCharts from './arrflCharts'
//import PrettyCheckbox from '../../PrettyCheckbox'
import ArrflProgressSummary from './arrflProgressSummary'
import arrflExcelButton from './arrflExcelButton'

import Vue from 'vue'
import Vuelidate from 'vuelidate'

Vue.use(Vuelidate)

export default {
  data() {
    return {
      loadData: Promise,
      resolvedLea: Object,
      editMode: false,
      survey: {},
      loading: true,
      initialSurveyResponse: {
        attempting: false,
        id: null,
        valid: false,
        evaluated: false
      }
    }
  },
  provide() {
    return {
      $v: this.$v
    }
  },
  mixins: [PageMixin],
  computed: {
    sections() {
      return surveyTemplate.sections.filter(s => s.hidden === undefined || !s.hidden)
    },
    selectedLea() {
      return this.$store.state.globalModule.leaContext.SelectedLea
    },
    isDistrict() {
      return this.resolvedLea.type === 2
    },
    isAggregate() {
      return [3, 10, 20].includes(this.selectedLea.type)
    },
    isFormFilled() {
      return Object.keys(this.survey).length !== 0
    },
    userCanEdit() {
      return this.userContext.isRTTIRAdmin || (this.initialSurveyResponse.attempting && this.initialSurveyResponse.valid)
    },
    ...mapState('globalModule', ['userContext']),
    ...mapGetters('globalModule', ['leaDictionary']),
    ...mapState('arkansasReadyForLearningModule', ['surveys']),
    ...mapGetters('arkansasReadyForLearningModule', ['aggregateData'])
  },
  methods: {
    excelExportData() {
      return {
        name: 'Arkansas Ready for Learning',
        items: this.getSurveyExcel()
      }
    },
    save() {
      this.loading = true
      this.doSave(false)
    },
    submit() {
      this.loading = true
      this.doSave(true)
    },
    doSave(isComplete) {
        this.$store.dispatch('arkansasReadyForLearningModule/saveSurvey', {
          response: this.survey,
          districtLea: this.selectedLea.number,
          formComplete: isComplete
        })
        .then(() => {
          this.survey = this.isDistrict ? { ...this.surveys[0].responseData } : null
          this.loading = false
          this.editMode = false
        })
    },
    cancel() {
      this.survey = this.isDistrict ? { ...this.surveys[0].responseData } : null
      this.editMode = false
      this.$v.$reset()
    },
    startEdit() {
      this.editMode = true     
      window.scrollToPhase2()
    },
    getSubsections(section) {
      if (section.sections) return section.sections.filter(s => s.hidden === undefined || !s.hidden)
      else return null
    },
    getSurveyExcel() {
      return this.surveys.sort(this.compareSurveyForSort).map(s => this.mapSurvey(s))
    },
    mapSurvey(s) {
      var lea = this.leaDictionary[s.districtLEA]

      var survey = {
        'CoOp LEA': lea.parentLea.number,
        'CoOp Name': lea.parentLea.name,
        'LEA': s.districtLEA,
        'District Name': lea.name,
        'Form Complete': s.formComplete ? 'Yes' : 'No',
      }

      this.sections.forEach(section => {
        // get questions and then get questions from each subsection
        if (section.questions) section.questions.filter(s => s.hidden === undefined || !s.hidden).forEach(question => {
          this.appendSurveyValue(survey, question, s.responseData)
        })

        var subsections = this.getSubsections(section)
        if (subsections) subsections.forEach(subsection => {
          if (subsection.questions) subsection.questions.filter(s => s.hidden === undefined || !s.hidden).forEach(question => {
            this.appendSurveyValue(survey, question, s.responseData)
          })
        })
      })

      return survey
    },
    compareSurveyForSort(a, b)
    {
      // sort by coop LEA and then sort by district LEA

      var alea = this.leaDictionary[a.districtLEA]
      var blea = this.leaDictionary[b.districtLEA]

      let c = 0;
      if (alea.parentLea.number > blea.parentLea.number) c = 1;
      else if (alea.parentLea.number < blea.parentLea.number) c = -1
      else if (alea.parentLea.number == blea.parentLea.number) {
        if (alea.number > blea.number) c = 1;
        else if (alea.number < blea.number) c = -1
      }

      return c;
    },
    appendSurveyValue(survey, question, response)
    {
      if (question.component == "QuestionYesno" || question.component == "QuestionYesnoDetails")
      {       
        survey[question.text] = this.translateYesNoValue(response[question.id], question.indeterminateAnswerText)
        if (question.detailsOnValues) survey[question.textDetails] = response[question.id + 'Followup']
      }
      else if (question.component == "QuestionMultipleSelection" || question.component == "QuestionTagSelection")
      {
        survey[question.text] = (response[question.id] || []).join('; ')
        if (question.allowOther) {
          if (question.otherText) survey[question.otherText] = response[question.id + 'Followup']
          else survey[question.text + ' Other (please explain):'] = response[question.id + 'Followup']
        }
      }
      else survey[question.text] = response[question.id]
    },
    translateYesNoValue(response, indeterminateAnswerText) 
    {
      switch(response)
      {
        case 0: return "No"
        case 1: return "Yes"
        case 10: return indeterminateAnswerText
      }
      return "No answer"
    }
  },
  mounted() {
    //console.log('page mounted', this.$route)
    //below values should only be present in query string when a user is first responding to the survey
  },

  validations() {
    var v = {}
    var topQuestions = surveyTemplate.sections.flatMap(s => s.questions || [])
    var subQuestions = surveyTemplate.sections.flatMap(s => s.sections || []).flatMap(s => s.questions || [])
    var allQuestions = topQuestions.concat(subQuestions).flat(2)
    var allIds = allQuestions.flatMap(s => {
      if (s.component == 'QuestionYesnoDetails') return [s.id, s.id + 'Followup']
      if (s.component == 'QuestionMultipleSelection' && s.allowOther) return [s.id, s.id + 'Followup']
      else return s.id
    })
    allIds.forEach(s => (v[s] = {}))
    return { survey: v, certifyComplete: {} }
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        this.loading = true
        location.hash = ""
        //window.scrollTo(0, 0)
        this.editMode = false
        if (newLea && newLea.number) {
          if (newLea.type == LeaTypes.School) {
            this.resolvedLea = this.leaDictionary[newLea.number].parentLea
          } else this.resolvedLea = newLea
          var summaryPromise = this.$store.dispatch('arkansasReadyForLearningModule/getSurveyProgress', { lea: this.resolvedLea.number })

          var surveyPromise = this.$store.dispatch('arkansasReadyForLearningModule/getSurveys', { lea: this.resolvedLea.number }).then(() => {
            //console.log('survey loaded', this.$route)

            var surveyId = this.surveys[0]?.id
            this.survey = this.isDistrict ? { ...this.surveys[0]?.responseData } : null
            //this.certifyComplete = this.surveys[0]?.formCompletePhase2

            this.initialSurveyResponse.attempting = this.$route.query.initial === 'true'
            this.initialSurveyResponse.id = this.$route.query.id
            this.initialSurveyResponse.valid = this.initialSurveyResponse.attempting && this.initialSurveyResponse.id.toLowerCase() == surveyId.toLowerCase()

            if(this.initialSurveyResponse.valid) this.startEdit()
            this.loading = false
          })

          this.loadData = Promise.all([surveyPromise, summaryPromise])
        }
      }
    }
  },
  components: {
    Promised,
    CardStateIcon,
    ArfflQuestions,
    ArrflCharts,
    //PrettyCheckbox,
    ArrflProgressSummary,
    arrflExcelButton
  }
}
</script>

<style type="scss">
.card-header > .svg-icon {
  filter: brightness(5004%);
  vertical-align: bottom;
}
</style>
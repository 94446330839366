import Vue from 'vue'
import EthicsViolationTable from './EthicsViolationTable.vue'
import EthicsViolationApplicantsTable from './EthicsViolationApplicantsTable.vue';
import EthicsViolationOutsideTable from './EthicsViolationOutsideTable.vue';
import { LeaTypes } from '@/helpers/leaTypes';
import EthicsViolationAllTable from './EthicsViolationAllTable.vue';

const EthicsViolationDetailsWidget = Vue.extend({
  name: 'ethics-violation-details-widget',
  /* html */
  template: `
    <div>
      <b-tabs card v-if="isDistrict || isState" class="border">
        <b-tab title="District Violations" class="p-2" v-if="isDistrict">
          <div>
            <div class="m-2">
              <ethics-violation-table v-bind:load-data="loadData"></ethics-violation-table>
            </div>
          </div>
        </b-tab>
        <b-tab :title="isState ? 'Violations' : 'Outside District Violations'" class="p-2">
          <div>
            <div class="m-2">
              <ethics-violation-outside-table v-bind:load-data="loadData"></ethics-violation-outside-table>
            </div>
          </div>
        </b-tab>
        <b-tab title="Applicant Pool" class="p-2">
          <div>
            <div class="m-2">
              <ethics-violation-applicants-table v-bind:load-data="loadData"></ethics-violation-applicants-table>
            </div>
          </div>
        </b-tab>
        <b-tab title="All Records" class="p-2">
          <div>
            <div class="m-2">
              <ethics-violation-all-table v-bind:load-data="loadData"></ethics-violation-all-table>
            </div>
          </div>
        </b-tab>
      </b-tabs>
      <div v-else class="alert alert-warning">
        Ethics Violations are only available at the District or State level.
      </div>
      <div class="m-3">
        <blockquote>
          <p class="blockquote">
            <i>Reference: Act 237 of 2023 | L.E.A.R.N.S. Act</i>
          </p>
        </blockquote>
        <p>
          <i class="fas fa-file-word mr-1"></i><a href="/Content/Rules for Standard 1 Violation View vs AELS Public.docx">Rules for Standard 1 Violation View vs AELS Public</a>
        </p>
      </div>
    </div>
    `,
  props: {
    loadData: Promise,
  },
  computed: {
    selectedLea() {
      return this.$store.state.globalModule.leaContext.SelectedLea || {}
    },
    isDistrict() {
      return this.selectedLea.type === LeaTypes.District
    },
    isState() {
      return this.selectedLea.type == LeaTypes.State
    }
  },
  components: {
    EthicsViolationTable,
    EthicsViolationApplicantsTable,
    EthicsViolationOutsideTable,
    EthicsViolationAllTable,
  }
})

export { EthicsViolationDetailsWidget }

<template>
  <div>
    <h2>{{ user.fullName }}</h2>
    <p class="lead">
      Phone: <a :href="'tel:' + user.telephoneNumber">{{ user.telephoneNumber }}</a>
    </p>
    <p class="lead">
      Email: <a :href="'mailto:' + user.emailAddress">{{ user.emailAddress }}</a>
    </p>
    <div class="mt-3">
      <p class="lead">
        Roles:
      </p>
      <ul class="list-group">
        <li class="list-group-item" v-for="role in user.roles" :key="role">
          <div class="d-inline">
            {{ PerkinsVRolesDisplay[role] }}
          </div>
          <div v-if="supportsExtendedClaims(role)" class="mt-1">
            <v-select multiple v-model="editClaims[role]" :reduce="oa => oa.code" :options="clusterOptions" placeholder="Select one or more clusters" />
            <animated-button :promise-resolver="assignClusters.bind(null, role, editClaims[role])" :button-class="buttonClass">
              Set Clusters
            </animated-button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Types as CommonTypes } from '@/modules/cte/stores'
import { Types } from '@/modules/cte/stores/perkinsv'
import { PerkinsVRoles, PerkinsVRolesDisplay } from '@/modules/cte/components/common/Roles'
import AnimatedButton from '@/components/AnimatedButton.vue'
import ApiService from '@/services/ApiService'
import { mapGetters, mapState } from 'vuex'
const apiService = new ApiService()

export default {
  data() {
    return {
      user: {},
      editClaims: {},
      claims: [],
      buttonClass: 'm-1 btn btn-sm btn-primary',
      PerkinsVRolesDisplay,
    }
  },
  computed: {
    ...mapState(CommonTypes.path, [CommonTypes.state.occupationalAreaMetadata]),
    ...mapGetters(Types.path, [Types.getters.activeAcademicYear]),
    clusterOptions() {
      return (this.occupationalAreaMetadata.clusters || []).map(cluster => {
        return { code: cluster.id, label: cluster.name }
      })
    },
    
  },
  watch: {
    claims: {
      immediate: true,
      handler(data) {
        const self = this
        data.forEach(c => {
          self.$set(self.editClaims, c.role, JSON.parse(c.claim).map(id => Number(id)))
        })
      }
    }
  },
  methods: {
    getClusters() {
      const self = this
      return apiService.Get(`/api/cte/perkinsv/admin/users/${this.$route.query.userId}/clusters`).then(claims => {
        self.claims = claims
      })
    },
    supportsExtendedClaims(role) {
      return role == PerkinsVRoles['Program Advisor'] || role == PerkinsVRoles['Program Coordinator']
    },
    assignClusters(role, claims) {
      const self = this
      return apiService.Put(`/api/cte/perkinsv/admin/users/${this.$route.query.userId}/clusters/${role}`, claims).then(() => {
        self.getClusters()
        self.buttonClass = 'm-1 btn btn-sm btn-success'
        this.$bvToast.toast('Saved', {
          title: `Clusters saved`,
          variant: 'success',
          solid: true
        })
      })
    }
  },
  components: {
    AnimatedButton,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.user = to.params
      let ay = vm.activeAcademicYear
      if (!ay) {
        vm.$store.dispatch(Types.actions.getAcademicYears).then(() => {
          vm.$store.dispatch(CommonTypes.actions.getOAMetadata, { ay: vm.activeAcademicYear })
          vm.getClusters()
        })
      } else {
        vm.$store.dispatch(CommonTypes.actions.getOAMetadata, { ay: ay })
        vm.getClusters()
      }
    })
  },
}
</script>
<template>
  <div>
    <highcharts class="chart" :options="chartOptions" />
  </div>
</template>
<script>
import { PlanMixin } from '../PlanMixin'

export default {
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'pie'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '{point.name}: <br/>{point.y} ({point.percentage:.1f}%)',
            },
            innerSize: '50%'
          }
        },
        legend: {
          maxHeight: this.$printMode ? 450 : 200
        },
        credits: {
          enabled: false,
          text: 'LEA Insights'
        },
        title: {
          text: `District ESA Plans by Status`
        },
        series: [{
          name: 'District ESA Plans by Status',
          colorByPoint: true,
        }],
        xAxis: {
          title: {
            text: this.xAxis
          },
          // categories: ['Status']
        },
        yAxis: {
          title: {
            text: 'Count'
          },
          labels: {
            formatter: this.yAxisFormatter
          },
          endOnTick: false
        }
      },
      statuses: [
        { name: 'Draft', color: '#6c757d', statusCodes: [0] },
        { name: 'Submitted to ADE', color: '#17a2b8', statusCodes: [11]},
        { name: 'Information or Changes Requested', color: '#ffc107', statusCodes: [1]},
        { name: 'Reviewed or Approved', color: '#33cc33',  statusCodes: [100, 101]},
      ]
    }
  },
  methods: {
    yAxisFormatter(value) {
      if (value.value > 1000) {
        return value.value.toString().substring(0,value.value.toString().length-3) + "k"
      }
      return value.value + this.tooltipSuffix
    },
  },
  watch:{
    plans:{
      immediate: true,
      handler(plans) {
        this.chartOptions.series[0].data = []
        if (plans) {
          this.chartOptions.series[0].data = this.statuses.map(s => {
            return {
              name: s.name,
              color: s.color,
              y: plans.filter(p => s.statusCodes.includes(p.status)).length,
            }
          })
        }
      }
    }
  },
  mixins: [PlanMixin]
}
</script>
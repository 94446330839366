const InspectionBatchStatus = {
  NotStarted: 1,
  Queued: 2,
  InProgress: 3,
  Completed: 10,
  DisplayOptions (value) {
    switch(value){
      case InspectionBatchStatus.NotStarted: return {text: "Not Started", badgeClass: "badge-secondary", iconClass: "fas fa-clock"}
      case InspectionBatchStatus.Queued: return  {text: "Queued", badgeClass: "badge-info"}
      case InspectionBatchStatus.InProgress: return  {text: "In Progress", badgeClass: "badge-primary", iconClass: "fas fa-stopwatch"}
      case InspectionBatchStatus.Completed: return {text:  "Completed", badgeClass: "badge-success", iconClass: "fas fa-check"}
      default: return {text: "N/A", badgeClass: "badge-secondary"}
    }
  }
}

const InspectionStatuses = {
  NotStarted: 1,
  InProgress: 2,
  Paused: 4,
  Completed: 10,
  Skipped: 255,
  DisplayOptions (value) {
    switch(value){
      case InspectionStatuses.NotStarted: return {text: "Not Started", badgeClass: "badge-secondary", iconClass: "fas fa-clock"}
      case InspectionStatuses.InProgress: return  {text: "In Progress", badgeClass: "badge-primary", iconClass: "fas fa-stopwatch"}
      case InspectionStatuses.Paused: return  {text: "Paused", badgeClass: "badge-warning", iconClass: "fas fa-pause"}
      case InspectionStatuses.Completed: return {text:  "Completed", badgeClass: "badge-success", iconClass: "fas fa-check"}
      case InspectionStatuses.Skipped: return  {text: "Skipped", badgeClass: "badge-secondary", iconClass: "fas fa-forward"}
      default: return {text: "N/A", badgeClass: "badge-secondary"}
    }
  }
}

const RatingTypes = {
  NotRecorded: -1,
  NotChecked: 0,
  OK: 1,
  Fix: 2,
  Ground: 3,
  NotApplicable: 255
}
const MeasurementTypes = {
  FrontBrakes: { value: 0, display: 'Front Brakes' },
  RearBrakes: { value: 1, display: 'Rear Brakes' },
  FrontTires: { value: 2, display: 'Front Tires' },
  RearInsideTires: { value: 3, display: 'Rear/Inside Tires' },
  RearOutsideTires: { value: 4, display: 'Rear/Outside Tires' }
}
const InspectionComponentCategories = {
  None: 0,
  CorrectiveMaintenance: 1,
  Custodial: 2,
  Inside: 3,
  Outside: 4,
  EngineCompartment: 5,
  Underneath: 6
}
const TransmissionTypes = [
  { text: 'Unknown', value: 0 },  
  { text: 'Automatic', value: 2 },
  { text: 'Manual', value: 1 }
]
const BrakeTypes = [
  { text: 'Unknown', value: 0 },
  { text: 'Air', value: 2 },
  { text: 'Hydraulic', value: 1 }
]
const BusUses = [
  { text: 'Route', value: 'Route' },
  { text: 'Activity', value: 'Activity' },
  { text: 'Spare', value: 'Spare' }
]
const VehicleTypes = [
  { value: 0, text: 'Unknown' },
  { value: 1, text: 'A' },
  { value: 2, text: 'A-Lift' },
  { value: 3, text: 'B' },
  { value: 4, text: 'B-Lift' },
  { value: 5, text: 'C' },
  { value: 6, text: 'C-Lift' },
  { value: 7, text: 'D' },
  { value: 8, text: 'D-Lift' }
]

const TransportationNotificationTypes = {
  IncrementalReport: 15,
  FinalReport: 30,
}

const FacilitiesBatchReviewStatus = {
  NotSet : 0,
  PendingSeniorProjectManagerReview : 1,
  PendingAreaProjectManagerAction : 10,
  PendingDistrictFacilitiesManagerReview : 20,
  AcknowlegedByDistrict : 100,
  DisplayOptions (value) {
    switch(value){
      case FacilitiesBatchReviewStatus.PendingSeniorProjectManagerReview: return {text: "SPM-M Review Required", badgeClass: "badge-danger", iconClass: "fas fa-clock"}
      case FacilitiesBatchReviewStatus.PendingAreaProjectManagerAction: return  {text: "APM-M Input Needed", badgeClass: "badge-warning", iconClass: "fas fa-stopwatch"}
      case FacilitiesBatchReviewStatus.PendingDistrictFacilitiesManagerReview: return  {text: "Released to District", badgeClass: "badge-primary", iconClass: "fas fa-pause"}
      case FacilitiesBatchReviewStatus.AcknowlegedByDistrict: return {text:  "Acknowledged by District", badgeClass: "badge-success", iconClass: "fas fa-check"}
      default: return {text: "N/A", badgeClass: "badge-secondary"}
    }
  }
}

export { InspectionStatuses, RatingTypes, MeasurementTypes, InspectionComponentCategories, TransmissionTypes, BrakeTypes, VehicleTypes, InspectionBatchStatus, TransportationNotificationTypes, FacilitiesBatchReviewStatus, BusUses }
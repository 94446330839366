<template>
  <div class="report-container">
    <div class="row mb-2">
      <div class="col">
        <b-form-select :options="ayOptions" v-model="academicYear" @change="getData">
          <template #first>
            <b-form-select-option :value="null" disabled>
              -- Academic Year --
            </b-form-select-option>
          </template>
        </b-form-select>  
      </div>
    </div>
    
    <Promised :promise="loadData" v-show="loadData">
      <template v-slot:pending>
        <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
          <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
        </div>
      </template>
      <template v-slot>
        <div v-if="reportData" class="report-output">
          <b-table-simple caption-top>
            <caption>Student Enrollment</caption>
            <b-thead>
              <b-tr>
                <b-th style="width: 375px">
                  Grades
                </b-th>
                <b-th v-for="g in reportData.enrollment" :key="g.grade">
                  {{ g.grade }}
                  <sup>th</sup>
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-th>
                  # Students
                </b-th>
                <b-td v-for="g in reportData.enrollment" :key="g.grade">
                  {{ g.totalEnrollment }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-table-simple caption-top>
            <caption>Student Initial Contact Visit</caption>
            <b-tbody>
              <b-tr>
                <b-th style="width: 375px">
                  # Students with Initial Contact Visit
                </b-th>
                <b-td v-for="g in reportData.enrollment" :key="g.grade">
                  {{ getValueByPredicate ('initialStudentVisitsByGrade', (r)=>r.visitGrade == Number(g.grade), 'initialContactCount' ) }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  % Students Initial Contact 
                </b-th>
                <b-td v-for="g in reportData.enrollment" :key="g.grade">
                  {{ getPercentOfEnrollmentByPredicate ('initialStudentVisitsByGrade', g.grade, (r)=>(r.visitGrade == Number(g.grade)), 'initialContactCount') | toPercentage(2, true) }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-table-simple caption-top>
            <caption>Student Initial Visit by Contact Type</caption>
            <b-tbody>
              <template v-for="location in locations.filter(l=>l.inPerson)">
                <b-tr :key="'siv-ip-count-'+location.key">
                  <b-th style="width: 375px">
                    # Students In-Person {{ location.label }}
                  </b-th>
                  <b-td v-for="g in reportData.enrollment" :key="g.grade">
                    {{ getValueByPredicate ('initialStudentVisitsByGradeAndType', (r)=>(r.visitGrade == Number(g.grade) && r.location == location.key), 'initialContactCount') }}
                  </b-td>
                </b-tr>
                <b-tr :key="'siv-ip-percent-'+location.key">
                  <b-th>
                    % Students In-Person {{ location.label }}
                  </b-th>
                  <b-td v-for="g in reportData.enrollment" :key="g.grade">
                    {{ getPercentOfEnrollmentByPredicate ('initialStudentVisitsByGradeAndType', g.grade, (r)=>(r.visitGrade == Number(g.grade) && r.location == location.key), 'initialContactCount') | toPercentage(2, true) }}
                  </b-td>
                </b-tr>
              </template>
              <template v-for="location in locations.filter(l=>!l.inPerson)">
                <b-tr :key="'siv-remote-count'+location.key">
                  <b-th style="width: 375px">
                    # Students via {{ location.label }}
                  </b-th>
                  <b-td v-for="g in reportData.enrollment" :key="g.grade">
                    {{ getValueByPredicate ('initialStudentVisitsByGradeAndType', (r)=>(r.visitGrade == Number(g.grade) && r.location == location.key), 'initialContactCount') }}
                  </b-td>
                </b-tr>
                <b-tr :key="'siv-remote-percent'+location.key">
                  <b-th>
                    % Students via {{ location.label }}
                  </b-th>
                  <b-td v-for="g in reportData.enrollment" :key="g.grade">
                    {{ getPercentOfEnrollmentByPredicate ('initialStudentVisitsByGradeAndType', g.grade, (r)=>(r.visitGrade == Number(g.grade) && r.location == location.key), 'initialContactCount') | toPercentage(2, true) }}
                  </b-td>
                </b-tr>
              </template>          
            </b-tbody>
          </b-table-simple>
          <b-table-simple caption-top>
            <caption>Visit Contacts</caption>
            <b-tbody>
              <b-tr>
                <b-th style="width: 375px">
                  # Students Contacted
                </b-th>
                <b-td v-for="g in reportData.enrollment" :key="g.grade">
                  {{ getValueByPredicate ('totalStudentContactsByGrade', (r)=>(r.visitGrade == Number(g.grade)), 'distinctStudentContacts') }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  % Students Contacted 
                </b-th>
                <b-td v-for="g in reportData.enrollment" :key="g.grade">
                  {{ getPercentOfEnrollmentByPredicate ('totalStudentContactsByGrade', g.grade, (r)=>(r.visitGrade == Number(g.grade)), 'distinctStudentContacts') | toPercentage(2, true) }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  # Contacts w/ Students
                </b-th>
                <b-td v-for="g in reportData.enrollment" :key="g.grade">
                  {{ getValueByPredicate ('totalStudentContactsByGrade', (r)=>(r.visitGrade == Number(g.grade)), 'totalStudentContacts') }}
                </b-td>
              </b-tr>          
            </b-tbody>
          </b-table-simple>    
          <b-table-simple caption-top>
            <caption>Student Visit by Contact Type</caption>
            <b-tbody>
              <template v-for="location in locations.filter(l=>l.inPerson)">
                <b-tr :key="'svct-ip-count'+location.key">
                  <b-th style="width: 375px">
                    # Contacts {{ location.label }}
                  </b-th>
                  <b-td v-for="g in reportData.enrollment" :key="g.grade">
                    {{ getValueByPredicate ('studentVisitDetailsByGradeAndType', (r)=>(r.visitGrade == Number(g.grade) && r.location == location.key), 'numberOfContacts') }}
                  </b-td>
                </b-tr>
                <b-tr :key="'svct-ip-dup-count'+location.key">
                  <b-th>
                    # Students > 1 {{ location.label }} 
                  </b-th>
                  <b-td v-for="g in reportData.enrollment" :key="g.grade">
                    {{ getValueByPredicate ('studentVisitDetailsByGradeAndType', (r)=>(r.visitGrade == Number(g.grade) && r.location == location.key), 'duplicateContacts') }}
                  </b-td>
                </b-tr>
                <b-tr :key="'svct-ip-dup-pct'+location.key">
                  <b-th>
                    % Students > 1 {{ location.label }} 
                  </b-th>
                  <b-td v-for="g in reportData.enrollment" :key="g.grade">
                    {{ getPercentOfEnrollmentByPredicate ('studentVisitDetailsByGradeAndType', g.grade, (r)=>(r.visitGrade == Number(g.grade) && r.location == location.key), 'duplicateContacts') | toPercentage(2, true) }}
                  </b-td>
                </b-tr>
              </template>
              <template v-for="location in locations.filter(l=>!l.inPerson)">
                <b-tr :key="'svct-remote-count'+location.key">
                  <b-th style="width: 375px">
                    # Contacts via {{ location.label }}
                  </b-th>
                  <b-td v-for="g in reportData.enrollment" :key="g.grade">
                    {{ getValueByPredicate ('studentVisitDetailsByGradeAndType', (r)=>(r.visitGrade == Number(g.grade) && r.location == location.key), 'numberOfContacts') }}
                  </b-td>
                </b-tr>
                <b-tr :key="'svct-remote-dup-count'+location.key">
                  <b-th>
                    # Students > 1 via {{ location.label }} 
                  </b-th>
                  <b-td v-for="g in reportData.enrollment" :key="g.grade">
                    {{ getValueByPredicate ('studentVisitDetailsByGradeAndType', (r)=>(r.visitGrade == Number(g.grade) && r.location == location.key), 'duplicateContacts') }}
                  </b-td>
                </b-tr>
                <b-tr :key="'svct-remote-dup-pct'+location.key">
                  <b-th>
                    % Students > 1 via {{ location.label }} 
                  </b-th>
                  <b-td v-for="g in reportData.enrollment" :key="g.grade">
                    {{ getPercentOfEnrollmentByPredicate ('studentVisitDetailsByGradeAndType', g.grade, (r)=>(r.visitGrade == Number(g.grade) && r.location == location.key), 'duplicateContacts') | toPercentage(2, true) }}
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>   
          <b-table-simple caption-top>
            <caption>Parent/Guardian and Student Visits by Contact Type</caption>
            <b-tbody>
              <b-tr>
                <b-th style="width: 375px">
                  # In-Person Visits
                </b-th>
                <b-td v-for="g in reportData.enrollment" :key="g.grade">
                  {{ getValueByPredicate ('inPersonVsRemoteVisitCountsByGrade', (r)=>(r.visitGrade == Number(g.grade) && r.inPerson), 'studentParentGuardianVisitCount') }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  # Phone / Email Visits
                </b-th>
                <b-td v-for="g in reportData.enrollment" :key="g.grade">
                  {{ getValueByPredicate ('inPersonVsRemoteVisitCountsByGrade', (r)=>(r.visitGrade == Number(g.grade) && !r.inPerson), 'studentParentGuardianVisitCount') }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple> 
          <b-table-simple caption-top>
            <caption>Parent/Guardian Visits by Contact Type</caption>
            <b-tbody>
              <b-tr>
                <b-th style="width: 375px">
                  # In-Person Visits
                </b-th>
                <b-td v-for="g in reportData.enrollment" :key="g.grade">
                  {{ getValueByPredicate ('inPersonVsRemoteVisitCountsByGrade', (r)=>(r.visitGrade == Number(g.grade) && r.inPerson), 'parentGuardianVisitCount') }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  # Phone / Email Visits
                </b-th>
                <b-td v-for="g in reportData.enrollment" :key="g.grade">
                  {{ getValueByPredicate ('inPersonVsRemoteVisitCountsByGrade', (r)=>(r.visitGrade == Number(g.grade) && !r.inPerson), 'parentGuardianVisitCount') }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>                     
        </div>
      </template>
      <template v-slot:rejected="error" class="align-items-center justify-content-center">
        <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
      </template>
    </Promised>
  </div>
</template>

<script>
import { Promised } from 'vue-promised'
import CardStateIcon from '@/components/CardStateIcon'
import { AYDependentReportMixin } from '../AYDependentReportMixin'

export default {
  components: {
    Promised,
    CardStateIcon
  },
  data() {
    return {
      academicYear: null,
      loadData: null,
      reportData: null,
      locations: [
        {key: 'One-on-one', label: 'One-on-One', inPerson: true },
        {key: 'Classroom Presentation', label: 'Classroom', inPerson: true },
        {key: 'Small Group', label: 'Small Group', inPerson: true },
        {key: 'Large Group Assembly', label: 'Large Group Assembly', inPerson: true },
        {key: 'Phone (Not Counted)', label: 'Phone (Not Counted)', inPerson: false },
        {key: 'Email (Not Counted)', label: 'Email (Not Counted)', inPerson: false }]
    }
  },
  methods: {
    async getData() {

      if (this.academicYear) {
        var payload = {
          lea: this.selectedLea.number,
          academicYear: this.academicYear
        }

        this.loadData = this.$store.dispatch('cte/careercoach/reporting/contactDetailsReport', payload)
        .then(d => this.reportData = d)
      }
    },

    getValue(collection, visitGrade, property) {
      var gradeRow = this.reportData[collection].find(r=>r.visitGrade == visitGrade)
      return (gradeRow || {})[property] || 0
    },

    getValueByPredicate(collection, predicate, property) {
      var gradeRow = this.reportData[collection].find(r=>predicate(r))

      return (gradeRow || {})[property] || 0
    },

    getPercentOfEnrollmentByPredicate(collection, enrollmentGrade, predicate, property) {
      var value = this.getValueByPredicate(collection, predicate, property)
      var enrollment = this.getValueByPredicate('enrollment', (r)=>r.grade == enrollmentGrade, 'totalEnrollment')
      return value / enrollment
    }

  },
  watch: {
    selectedLea: {
      immediate: false,
      handler() {
        this.getData()
      }
    }
  },
  mixins: [
    AYDependentReportMixin
  ]
}
</script>
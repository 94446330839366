<template>
  <div>
    <paginated-table :items="proposals" :fields="fields">
      <template v-slot:head(action)>
        <b-button variant="primary" @click="$emit('on-add-initiated')" v-if="isApplicant && isActiveAcademicYear">
          <i class="fa fa-plus fa-fw" />
          New Proposal
        </b-button>
        <b-button variant="primary" size="sm" @click="cloneProposals" v-if="isApplicant && !isActiveAcademicYear" :disabled="selectedProposals.length < 1">
          <i class="fa fa-copy fa-fw" />
          Clone {{ selectedProposals.length > 0 ? selectedProposals.length.toString() : '' }} Proposal(s) to Active AY
        </b-button>
      </template>
      <template v-slot:cell(selected)="data">
        <b-form-group v-if="!data.item.isCoverPage">
          <b-form-checkbox type="checkbox" v-model="data.item.selected" />
        </b-form-group>
      </template>
      <template v-slot:cell(proposalId)="data">
        {{ data.value || '--' }}
      </template>
      <template v-slot:cell(occupationalAreaId)="data">
        <b-badge variant="warning" v-if="isAreaClaimed(data.value)">
          {{ getOccupationalArea(data.value) }}
        </b-badge>
        <span v-else>{{ getOccupationalArea(data.value) }}</span>
      </template>
      <template v-slot:cell(clusterId)="data">
        <b-badge variant="warning" v-if="isAreaClaimed(data.value)">
          {{ getCluster(data.value) }}
        </b-badge>
        <span v-else>{{ getCluster(data.value) }}</span>
      </template>
      <template v-slot:cell(status)="data">
        <workflow-status-badge :state-map="ProposalStateMap" :status="data.value" :show-label="false" />
      </template>
      <template v-slot:cell(amountBudgeted)="data">
        <div>
          <span v-if="data.item.isCoverPage">--</span>
          <span v-else>{{ data.value | currency }}</span>
        </div>
      </template>
      <template v-slot:cell(action)="data">
        <div v-if="data.item.isCoverPage">
          <b-link 
            :to="{ name: 'CTE/PerkinsV/Application/CoverPage/Edit', params: { applicationId: $route.params.applicationId } }"
            title="Edit Cover Page"
            @click="$emit('on-edit-initiated')">
            <i class="fas fa-fw fa-edit" />
          </b-link>
        </div>
        <div v-else>
          <b-link
            v-if="canEdit(data.item)"
            :to="{ name: 'CTE/PerkinsV/Application/Proposal/Edit', params: { applicationId: $route.params.applicationId, proposalId: data.item.proposalId } }"
            title="Edit Proposal"
            @click="$emit('on-edit-initiated')">
            <i class="fas fa-fw fa-edit" />
          </b-link>
          <b-link
            v-else
            :to="{ name: 'CTE/PerkinsV/Application/Proposal/Edit', params: { applicationId: $route.params.applicationId, proposalId: data.item.proposalId } }"
            title="Review Proposal"
            @click="$emit('on-edit-initiated')">
            <i class="fas fas fa-search" />
          </b-link>
          <b-link @click="doDelete({applicationId: $route.params.applicationId, proposalId: data.item.proposalId })" v-if="isActiveAcademicYear && (canEdit(data.item) || isAdmin)">
            <i class="fas fa-fw fa-times text-danger" title="Delete Item" />
          </b-link>
        </div>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import PaginatedTable from '@/components/PaginatedTable'
import { PerkinsVRoles } from '@/modules/cte/components/common/Roles'
import { Types } from '@/modules/cte/stores/perkinsv'
import { Types as CommonTypes } from '@/modules/cte/stores'
import { ProposalStateMap, ProposalStates } from '@/modules/cte/stores/perkinsvStateMap'
import { mapGetters, mapState } from 'vuex'
import WorkflowStatusBadge from '@/components/workflow/WorkflowStatusBadge'
import ApiService from '@/services/ApiService'
const apiService = new ApiService()

export default {
  data() {
    return {
      ProposalStateMap,
      ProposalStates
    }
  },
  computed:{
    ...mapState('globalModule', ['userContext']),
    ...mapState(Types.path, [Types.state.selectedApplication]),
    ...mapGetters(Types.path, [Types.getters.activeAcademicYear, Types.getters.perkinsExtendedClaims]),
    ...mapState(CommonTypes.path, [CommonTypes.state.occupationalAreaMetadata]),
    proposals() {
      if (!this.selectedApplication) return []
      return [
        { ...this.selectedApplication.coverPage, occupationalArea: null, title: 'Cover Page', isCoverPage: true },
        ...(this.selectedApplication.proposals || [])
      ]
    },
    selectedProposals() {
      return this.proposals.filter(p => p.selected)
    },
    fields() {
      const fields = [
        { key: 'proposalId' },
        { key: this.selectedApplication.ay > 23 ? 'clusterId' : 'occupationalAreaId', class: 'text-center', label: this.selectedApplication.ay > 23 ? 'Cluster' : 'Occupational Area' },
        { key: 'title', sortable: true },
        { key: 'status', sortable: true },
        { key: 'amountBudgeted', sortable: true, class: 'text-right' },
        { key: 'action', class: 'text-right' }
      ]
      return (this.isApplicant && !this.isActiveAcademicYear) ? [{ key: 'selected' }, ...fields] : fields
    },
    isApplicant() {
      const applicantId = (this.selectedApplication || {}).applicantId
      return (this.userContext.CoordinatorRoles || []).some(c => c.SchoolLEA == applicantId)
    },
    isActiveAcademicYear() {
      return this.activeAcademicYear == this.selectedApplication.ay
    },
    claimedAreas() {
      return this.perkinsExtendedClaims.filter(c => c.type == 'ExtendedClaim.Insights_PerkinsV_Program_Coordinators' || c.type == 'ExtendedClaim.Insights_PerkinsV_Program_Advisors').map(c => c.value).flat()
    },
    isAdmin() {
      return (this.userContext.roles || []).includes(PerkinsVRoles['Perkins Administrator']) || (this.userContext.roles || []).includes('Insights_System_Administrators')
    },
  },
  methods: {
    canEdit(proposal) {
      return this.isApplicant && proposal.status <= ProposalStates.Revising
    },
    getOccupationalArea(id) {
      return ((this.occupationalAreaMetadata.occupationalAreas || []).find(p => p.id == id) || {}).name || '--'
    },
    getCluster(id) {
      return ((this.occupationalAreaMetadata.clusters || []).find(p => p.id == id) || {}).name || '--'
    },
    isAreaClaimed(id) {
      return this.claimedAreas.includes(id.toString())
    },
    async cloneProposals() {
      const result = await this.$bvModal.msgBoxConfirm(`Clone ${this.selectedProposals.length} selected proposal(s) into the active AY?`)
      if (result) {
        const proposalIds = this.selectedProposals.map(p => p.proposalId)
        return apiService.Post(`/api/cte/perkinsv/applications/${this.selectedApplication.applicationId}/clone`, proposalIds).then(routeParams => {
          this.$store.commit('cte/perkinsv/setSelectedApplication', {})
          this.$store.dispatch(Types.actions.getApplication, {...routeParams})
          return this.$router.push({ name: 'CTE/PerkinsV/Application', params: {...routeParams} })
        })
      }
    },
    async doDelete(payload) {
      var result = await this.$bvModal.msgBoxConfirm("Are you sure you want to delete this proposal?", { okTitle: "Yes", cancelTitle: "No" })
      if (result) this.$store.dispatch(Types.actions.deleteProposal, payload)
    },
  },
  components: {
    PaginatedTable,
    WorkflowStatusBadge,
  },
  props: {
    loadData: Promise
  }
}
</script>

const ReportTypes = {
  'MonthlyCumulativeSummary': {
    name: 'Monthly Cumulative Summary',
    component: 'ReportMonthlyCumulativeSummary',
    rangeFormatter: (range) => {
      var r = JSON.parse(range)
      var month = new Date(r.academicYear + 2000, r.month-1, 1).toLocaleString('default', { month: 'long' })
      return `${r.academicYear + 2000} - ${r.month} (${month})`
    }
  }
}

export { ReportTypes }
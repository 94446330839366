import { Types } from '../stores/offlineModule'

export class OfflineService {
  constructor() {
  }

  CreateQueuedAction (action, context, payload, onlineAction, offlineAction) {
    if (context.rootState.globalModule.isOnline) {
      return onlineAction(context, payload)
    }
    console.log(`Queueing offline action ${action}`)
    const enqueuedAction = { action, payload, timestamp: new Date() }
    context.commit(Types.mutations.enqueueAction, enqueuedAction, { root: true })
    return offlineAction(context, payload)
  }
}
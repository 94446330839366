import ApiService from '../../services/ApiService'
import { OfflineService } from '../../services/offlineService'
import { getDPSAFTfilterQueryString } from '../../helpers/globalHelpers'
import { RatingTypes, InspectionStatuses, InspectionBatchStatus } from '../../components/dpsaft/common/Enums'
import { buildingSort, setSelectedInspection, setSelectedInspectionComponents } from './common'
import axios from 'axios'

function flattenBuildingInspection (i) {
  return Object.assign(i, i.building)
}

const _offlineService = new OfflineService()
import removeNamespace from '../removeNamespace'

export const Roles= {
  DistrictFacilitesCoordinatorCode: "0",
  SPM_M: "Insights_DPSAFT_Senior_Project_Manager_Maintenance",
  APM_M: "Insights_DPSAFT_Facilities_Area_Managers_Maintenance",
}

export const Types = {
  path: 'DPSAFT/Facilities',
  state: {
    InspectionItems: 'InspectionItems',
    inspectionBatches: 'inspectionBatches',
    BatchMaintenance: 'BatchMaintenance',
    SelectedBatch: 'SelectedBatch',
    SelectedInspection: 'SelectedInspection',
    InspectionHistory: 'InspectionHistory',
    SelectedBatchRouteHistory: 'SelectedBatchRouteHistory',
    ModuleWorkQueue: 'ModuleWorkQueue',
    PendingDistrictActionList: 'PendingDistrictActionList',
    ActiveRoute: 'ActiveRoute',
    FiscalYears: 'FiscalYears'
  },
  actions: {
    saveInspection: 'DPSAFT/Facilities/saveInspection',
    deleteInspection: 'DPSAFT/Facilities/deleteInspection',
    setInspectionStatus: 'DPSAFT/Facilities/setInspectionStatus',
    saveBatchComment: 'DPSAFT/Facilities/saveBatchComment',
    saveInspectionComponent: 'DPSAFT/Facilities/saveInspectionComponent',
    getInspectionItems: 'DPSAFT/Facilities/getInspectionItems',
    getInspectionBatches: 'DPSAFT/Facilities/getInspectionBatches',
    getBatch: 'DPSAFT/Facilities/getBatch',
    getBatchWorkflowHistory: 'DPSAFT/Facilities/getBatchWorkflowHistory',
    getInspection: 'DPSAFT/Facilities/getInspection',
    getInspectionHistory: 'DPSAFT/Facilities/getInspectionHistory',
    initializeBatch: 'DPSAFT/Facilities/initializeBatch',
    exportBatch: 'DPSAFT/Facilities/exportBatch',
    notifyDistrict: 'DPSAFT/Facilities/notifyDistrict',
    districtAcknowledge: 'DPSAFT/Facilities/districtAcknowledge',
    getUserBatches: 'DPSAFT/Facilities/getUserBatches',
    updateBatchStatus: 'DPSAFT/Facilities/updateBatchStatus',
    deleteBatch: 'DPSAFT/Facilities/deleteBatch',
    routeInspectionBatch: 'DPSAFT/Facilities/route',
    getModuleWorkQueue: 'DPSAFT/Facilities/getModuleWorkQueue',
    closeRoute: 'DPSAFT/Facilities/closeRoute',
    getPendingDistrictActionList: 'DPSAFT/Facilities/getPendingDistrictActionList',
    setActiveRoute: 'DPSAFT/Facilities/setActiveRoute',
    clearActiveRoute: 'DPSAFT/Facilities/clearActiveRoute',
    getFiscalYears: 'DPSAFT/Facilities/getFiscalYears'
  },
  getters: {
    InspectionItems: 'InspectionItems',
    SelectedBatchBuildingList: 'SelectedBatchBuildingList',
    SelectedBatchBuildingsNoIssues: 'SelectedBatchBuildingsNoIssues',
    SelectedBatchBuildingsWithIssues: 'SelectedBatchBuildingsWithIssues',
    ModuleWorkQueue: 'ModuleWorkQueue',
  },
  mutations: {
    setInspectionItems: 'DPSAFT/Facilities/setInspectionItems',
    setInspectionBatches: 'DPSAFT/Facilities/setInspectionBatches',
    setSelectedBuildings: 'DPSAFT/Facilities/setSelectedBuildings',
    setSelectedBatch: 'DPSAFT/Facilities/setSelectedBatch',
    setSelectedInspection: 'DPSAFT/Facilities/setSelectedInspection',
    setUpdatedInspectionComponent: 'DPSAFT/Facilities/setUpdatedInspectionComponent',
    setUpdatedInspection: 'DPSAFT/Facilities/setUpdatedInspection',
    setInspectionHistory: 'DPSAFT/Facilities/setInspectionHistory',
    setDistrictNotified: 'DPSAFT/Facilities/setDistrictNotified',
    setDistrictAcknowledged: 'DPSAFT/Facilities/setDistrictAcknowledged',
    setSelectedBatchStatus: 'DPSAFT/Facilities/setSelectedBatchStatus',
    removeDeletedInspection: 'DPSAFT/Facilities/removeDeletedInspection',
    removeDeletedBatch: 'DPSAFT/Facilities/removeDeletedBatch',
    setModuleWorkQueue:  'DPSAFT/Facilities/setModuleWorkQueue',
    setPendingDistrictActionList: 'DPSAFT/Facilities/setPendingDistrictActionList',
    setActiveRoute: 'DPSAFT/Facilities/setActiveRoute',
    markLocalRouteClosed: 'DPSAFT/Facilities/markLocalRouteClosed',
    setSelectedBatchRouteHistory: 'DPSAFT/Facilities/setSelectedBatchRouteHistory',
    setFiscalYears: 'DPSAFT/Facilities/setFiscalYears'
  }
}

const _types = removeNamespace('DPSAFT/Facilities/', Types)
export class Facilities {

  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
    this._fiscalYear = parseInt(this._configElement.getAttribute('data-fiscal-year'))
  }

  namespaced = true

  path = 'DPSAFT/Facilities'

  state = {
    [_types.state.InspectionItems]: [],
    [_types.state.inspectionBatches]: [],
    [_types.state.BatchMaintenance]:{
      SelectedBuildings: []
    },
    [_types.state.SelectedBatch]: {
      inspections: [],
      workLog: []
    },
    [_types.state.SelectedInspection]: {},
    [_types.state.InspectionHistory]: { item1: {}, item2: [] },
    [_types.state.ModuleWorkQueue]: [],
    [_types.state.PendingDistrictActionList]: [],
    [_types.state.ActiveRoute]: null,
    [_types.state.SelectedBatchRouteHistory]: [],
    [_types.state.FiscalYears]: []
  }

  mutations = {
    [_types.mutations.setSelectedBatchStatus]: (state, data) => state.SelectedBatch.status = data,
    [_types.mutations.setInspectionItems]: (state, data) => state.InspectionItems = data,
    [_types.mutations.setInspectionBatches]: (state, data) => state.inspectionBatches = data,
    [_types.mutations.setSelectedBuildings]: (state, data) => state.BatchMaintenance.SelectedBuildings = data,
    [_types.mutations.setSelectedBatch]: (state, data) => {
      let selectedBatch = {...data}
      selectedBatch.inspections = (selectedBatch.inspections || []).sort(buildingSort)
      state.SelectedBatch = selectedBatch
    },
    [_types.mutations.setSelectedInspection]: (state, data) => state.SelectedInspection = data,
    [_types.mutations.setUpdatedInspectionComponent]: (state, data) => {
      if ((state.SelectedInspection || {}).inspectionId == data.facilitiesInpectionId) {
        setSelectedInspectionComponents(state, data)
      }
    },
    [_types.mutations.setUpdatedInspection]: (state, data) => {
      setSelectedInspection(state, data)
    },
    [_types.mutations.setInspectionHistory]: (state, data) => state.InspectionHistory = data,
    [_types.mutations.setDistrictNotified]: (state, data) => {
      state.SelectedBatch.districtNotifiedDate = data.districtNotifiedDate
      state.SelectedBatch.districtNotifiedBy = data.districtNotifiedBy
    },
    [_types.mutations.setDistrictAcknowledged]: (state, data) => {
      state.SelectedBatch.districtAcknowledgedDate = data.districtAcknowledgedDate
      state.SelectedBatch.districtAcknowledgedBy = data.districtAcknowledgedBy
    },
    [_types.mutations.removeDeletedInspection]: (state, data) => {
      state.SelectedBatch.inspections = state.SelectedBatch.inspections.filter(i => i.inspectionId != data)
    },
    [_types.mutations.removeDeletedBatch]: (state, data) => {
      state.inspectionBatches = state.inspectionBatches.filter(i => i.batchId != data)
    },
    [_types.mutations.setModuleWorkQueue]: (state, data) => {
      state.ModuleWorkQueue = data
    },
    [_types.mutations.setPendingDistrictActionList]: (state, data) => {
      state.PendingDistrictActionList = data
    },
    [_types.mutations.markLocalRouteClosed]: (state, routeId) => {
      if (routeId) {
        var routes = state.ModuleWorkQueue.filter(q=>q.id == routeId)

        if (routes && routes.length > 0) {
          routes[0].routeClosedDate = new Date()
          console.log(routes[0])
        }
      }
    },

    [_types.mutations.setActiveRoute]: (state, routeId) => {

      if (routeId) {
        var routes = state.ModuleWorkQueue.filter(q=>q.id == routeId)

        if (routes && routes.length > 0) {
          state.ActiveRoute = routes[0]
        }
      } else {
        state.ActiveRoute = null
      }
    },
    [_types.mutations.setSelectedBatchRouteHistory]: (state, data) => {
      state.SelectedBatchRouteHistory = data
    },
    [_types.mutations.setFiscalYears]: (state, data) => {
      state.FiscalYears = data
    }
  }

  getters = {
    [_types.getters.InspectionItems]: (state) => state.InspectionItems,
    [_types.getters.SelectedBatchBuildingList]: (state) => (state.SelectedBatch.inspections || []).map(flattenBuildingInspection),
    [_types.getters.SelectedBatchBuildingsNoIssues]: (state) => (state.SelectedBatch.inspections || []).filter(i => i.components.every(c => c.rating == RatingTypes.OK || c.rating == RatingTypes.NotApplicable || c.rating == RatingTypes.NotChecked)),
    [_types.getters.SelectedBatchBuildingsWithIssues]: (state) => (state.SelectedBatch.inspections || []).filter(i => i.components.some(c => c.rating == RatingTypes.Fix)),
    [_types.getters.ModuleWorkQueue]: (state) => state.ModuleWorkQueue
  }

  actions = {
    //#region Requests
    [_types.actions.getInspectionItems]: ({commit}) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/metadata/facilities-inspection-items`))
        .then(data => commit('setInspectionItems', data))
    },
    [_types.actions.getInspectionBatches]: ({commit}, payload) => {
      var qs = getDPSAFTfilterQueryString(payload)

      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/batches?${qs}`))
        .then(data => commit('setInspectionBatches', data))
    },
    [_types.actions.getUserBatches]: ({ commit }) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/user-batches`))
        .then(data => commit('setInspectionBatches', data))
    },
    [_types.actions.getBatch]: ({dispatch, commit}, payload) => {
      commit('setSelectedInspection', {})
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/batch/${payload.batchId}`))
        .then(data => commit('setSelectedBatch', data))
        .then(() => dispatch(_types.actions.getBatchWorkflowHistory, payload))
    },
    [_types.actions.getBatchWorkflowHistory]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/${payload.batchId}/work-queue-history`))
        .then(data => commit('setSelectedBatchRouteHistory', data))
    },
    [_types.actions.getInspectionHistory]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/history?buildingNumber=${payload.buildingNumber}&schoolNumber=${payload.schoolNumber}`))
        .then(data => commit('setInspectionHistory', data))
    },
    [_types.actions.getInspection]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/batch/${payload.batchId}/inspection/${payload.inspectionId}`))
        .then(data => commit('setSelectedInspection', data))
    },
    [_types.actions.getInspection]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/batch/${payload.batchId}/inspection/${payload.inspectionId}`))
        .then(data => commit('setSelectedInspection', data))
    },
    [_types.actions.getModuleWorkQueue]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/work-queue`), payload)
        .then(data => commit(_types.mutations.setModuleWorkQueue, data))
    },
    [_types.actions.getPendingDistrictActionList]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/pending-district-action`), payload)
        .then(data => commit(_types.mutations.setPendingDistrictActionList, data))
    },
    [_types.actions.getFiscalYears]: ({commit}) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/fiscal-years`))
        .then(data => commit(_types.mutations.setFiscalYears, data))
    },
    //#endregion

    //#region Commands
    [_types.actions.saveInspection]: (context, payload) => _offlineService.CreateQueuedAction(Types.actions.saveInspection, context, payload, (context, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/batch/${payload.batchId}/inspection/${payload.inspectionId}`), payload.update)
        .then(data => context.commit(_types.mutations.setUpdatedInspection, data))
    }, (context, payload) => {
      context.commit(_types.mutations.setUpdatedInspection, { ...payload.update })
    }),
    [_types.actions.deleteInspection]: (context, payload) => _offlineService.CreateQueuedAction(Types.actions.deleteInspection, context, payload, (context, payload) => {
      context.commit('setSelectedInspection', {})
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/batch/${payload.batchId}/inspection/${payload.inspectionId}`))
        .then((batchStatus) => {
          context.commit('removeDeletedInspection', payload.inspectionId)
          context.commit(_types.mutations.setSelectedBatchStatus, batchStatus)
        }) 
    }, (context, payload) => {
        context.commit('setSelectedInspection', {})
        context.commit('removeDeletedInspection', payload.inspectionId)
    }),
    [_types.actions.deleteBatch]: (context, payload) => _offlineService.CreateQueuedAction(Types.actions.saveInspection, context, payload, (context, payload) => {
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/batch/${payload.batchId}`))
        .then(() => context.commit('removeDeletedBatch', payload.batchId))
    }),
    [_types.actions.setInspectionStatus]: (context, payload) => _offlineService.CreateQueuedAction(Types.actions.setInspectionStatus, context, payload, (context, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/batch/${payload.batchId}/inspection/${payload.inspectionId}/status?status=${payload.status}`))
        .then(data => {
          context.commit(_types.mutations.setUpdatedInspection,  { ...data.item2 })
          context.commit(_types.mutations.setSelectedBatchStatus, data.item1)
        })
    }, (context, payload) => {
      context.commit(_types.mutations.setUpdatedInspection, { inspectionId: payload.inspectionId, status: payload.status })
      if (context.state.SelectedBatch.inspections.every(i => i.status == InspectionStatuses.Completed)) {
        context.commit(_types.mutations.setSelectedBatchStatus, InspectionBatchStatus.Completed)
      }
      else if (context.state.SelectedBatch.status != InspectionBatchStatus.InProgress) {
        context.commit(_types.mutations.setSelectedBatchStatus, InspectionBatchStatus.InProgress)
      }
    }),
    [_types.actions.saveInspectionComponent]: (context, payload) => _offlineService.CreateQueuedAction(Types.actions.saveInspectionComponent, context, payload, (context, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/batch/${payload.batchId}/inspection/${payload.inspectionId}/component/${payload.id}`), payload.update)
        .then(data => context.commit(_types.mutations.setUpdatedInspectionComponent, data))
      }, (context, payload) => {
        context.commit(_types.mutations.setUpdatedInspectionComponent, { ...payload.update })
      }
    ),
    [_types.actions.updateBatchStatus]: ({ dispatch }, payload) => {
      return this._apiService.Patch(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/batch/${payload.batchId}/status/${payload.status}`))
        .then(() => dispatch(_types.actions.getBatch, { batchId: payload.batchId }))
    },
    // eslint-disable-next-line
    [_types.actions.saveBatchComment]: ({commit, state}, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/batch/${payload.batchId}`), {Comments: payload.comments})
        .then(data => state.SelectedBatch.comments = data)
    },
    [_types.actions.initializeBatch]: ({state}, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/${payload.district || state.BatchMaintenance.SelectedBuildings[0].districtLea}/initialize`), { buildingNumbers: state.BatchMaintenance.SelectedBuildings.map(b => { return `${b.schoolNumber}:${b.buildingNumber}`}), Description: payload.description, schoolLea: payload.schoolLea })
    },
    // eslint-disable-next-line no-unused-vars
    [_types.actions.exportBatch]: ({ _commit }, payload) => {
      var url = encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/batch/${payload.batchId}/export`)

      return axios.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = payload.fileName
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },
    [_types.actions.notifyDistrict]: ({ commit }, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/batch/${payload.batchId}/notify`), payload)
      .then(data => commit('setDistrictNotified', data))
    },
    [_types.actions.districtAcknowledge]: ({ commit }, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/batch/${payload.batchId}/acknowledge`))
      .then(data => commit('setDistrictAcknowledged', data))
    },
    [_types.actions.routeInspectionBatch]: ({ dispatch }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/route-batch`), payload)
        .then(() => dispatch(_types.actions.getBatchWorkflowHistory, payload))
    },

    // eslint-disable-next-line
    [_types.actions.closeRoute]: ({commit}, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/dpsaft/facilities/inspections/close-route/${payload.routeId}`))
        .then(()=>commit(_types.mutations.markLocalRouteClosed, payload.routeId ))
    },
    [_types.actions.setActiveRoute]: ({commit}, payload) => {
      return commit(_types.mutations.setActiveRoute, payload.routeId)
    },
    [_types.actions.clearActiveRoute]: ({commit}) => {
      return commit(_types.mutations.setActiveRoute, null)
    }
    //#endregion
  }
}

<template>
  <div class="card bg-light mt-3 page-break-before">
    <a class="anchor" id="lettergrades" />
    <div class="card-header">
      Letter Grades
    </div>
    <div class="card-body">
      <div class="card-group">
        <letter-grades-count-chart :load-data="loadData" :selected-lea="selectedLea" />
      </div>       
    </div>
  </div>
</template>

<script>
import LetterGradesCountChart from './LetterGradesCountChart.vue'

export default {
  name: 'letter-grades-dashboard-widget',
  data() {
    return { 
      loadData: null 
    }
  },
  computed: {
    lettergrades() {
      return this.$store.state.overviewModule.lettergrades;
    }
  },
  components: {
    LetterGradesCountChart
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        this.loadData = this.$store.dispatch('overviewModule/getLetterGrades', {
          lea: newLea.number
        })
      }
    }
  }
}
</script>

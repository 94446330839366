<template>
  <div class="overflow-auto">
    <table-control-widget :per-page="perPage" @filter-changed="filter = $event" @page-length-changed="perPage = $event" />
    <b-table
      striped
      hover
      selectable
      select-mode="single"
      :items="dataItems"
      :fields="tableColumns"
      :per-page="perPage"
      :filter="filter"
      @filtered="onFiltered"
      :current-page="currentPage"
      :busy="parameters.isLoading">
      <div slot="table-busy" class="text-center text-danger my-2">
        <strong>
          <i class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
          Loading...
        </strong>
      </div>
      <template v-slot:head(veteranTeachers)="data">
        <custom-tooltip :element-id="'veteranTeacherJobTooltip' + area" :label="data.label">
          A teacher is considered a Veteran Teacher if:
          <ul class="list-unstyled">
            <li>
              The sum of their age plus years of service >= 84, or
            </li>
            <li>
              They are retired, or
            </li>
            <li>
              They are identified as a Working Retiree
            </li>
          </ul>
        </custom-tooltip>
      </template>
      <template v-slot:head(retiredThisFY)="data">
        <custom-tooltip :element-id="'retireThisFYJobTooltip' + area" :label="data.label">
          Fiscal year is considered July 1 - June 30
        </custom-tooltip>
      </template>
      <template v-slot:head(onALP)="data">
        <custom-tooltip :element-id="'onALPJobTooltip' + area" :label="data.label">
          Sourced from AELS
        </custom-tooltip>
      </template>
      <template v-slot:head(newLicenses)="data">
        <custom-tooltip :element-id="'newLicensesJobTooltip' + area" :label="data.label">
          Educators whose licenses were first attained this school year
        </custom-tooltip>
      </template>
    </b-table>
    <table-pagination-widget :total-rows-count="rows" :filtered-rows-count="filteredRowCount" :page-length="perPage" @page-changed="currentPage = $event" />
  </div>
</template>

<script>
import { TableMixin } from '../../TableMixin'
import CustomTooltip from '../../CustomTooltip'

export default {
  mixins: [TableMixin],
  components: {
    CustomTooltip
  },
  data() {
    return {
      perPage: 20,
      pageLengths: [10, 20, 50, 100],
      filter: null,
      filteredRowCount: null,
      currentPage: 1,
      fields: [
        {
          key: 'groupName',
          label: 'Group',
          sortable: true
        },
        {
          key: 'subgroupName',
          label: 'Subgroup',
          sortable: true,
          tdClass: 'subgroup',
          formatter: value => {
            return value === '|SELF|' ? null : value
          }
        },
        {
          key: 'coopLEA',
          label: 'CoOp LEA',
          sortable: true,
          dataLevel: 10
        },
        {
          key: 'coopName',
          label: 'Co-op Name',
          sortable: true,
          dataLevel: 10
        },
        {
          key: 'districtLEA',
          label: 'District LEA',
          sortable: true,
          dataLevel: 2
        },
        {
          key: 'districtName',
          label: 'District Name',
          sortable: true,
          dataLevel: 2
        },
        {
          key: 'schoolLEA',
          label: 'School LEA',
          sortable: true,
          dataLevel: 1
        },
        {
          key: 'schoolName',
          label: 'School Name',
          sortable: true,
          dataLevel: 1
        },
        {
          key: 'jobCode',
          label: 'Course Code',
          tdClass: 'licenses',
          sortable: true
        },
        {
          key: 'educatorCount',
          label: '# of Educators',
          sortable: true
        },
        {
          key: 'retiredThisFY',
          label: 'Retired this FY',
          sortable: true
        },
        {
          key: 'veteranTeachers',
          label: 'Veteran Teachers',
          sortable: true
        },
        {
          key: 'avgServiceYears',
          label: 'Avg. Service Years',
          sortable: true
        }
      ]
    }
  },
  props: {
    scopeLea: Object,
    scopeLevel: Number,
    dataItems: Array,
    parameters: Object,
    minDataLevel: Number,
    isBusy: Boolean,
    area: String
  },
  computed: {
    rows() {
      return this.data ? this.data.length : 0
    },
    tableColumns() {
      return this.fields.filter(f => {
        if (f.key === 'jobCode') return this.parameters.groupByJobOrLicenseCode
        if (f.key === 'subgroupName') return this.parameters.groupBySubgroup

        if (f.dataLevel) {
          return f.dataLevel >= this.minDataLevel
        }

        return true
      })
    }
  },
  watch: {
    scopeLea: {
      immediate: true,
      handler() {
        //console.log(`scope lea changed to ${newData ? newData.number : '<not set>'}`);
      }
    }
  },
  methods: {
    onFiltered(items, count) {
      this.filteredRowCount = count || this.rows
    }
  }
}
</script>

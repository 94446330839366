<template>
  <div>
    <clusters-filter @on-filter-changed="filterChanged" @on-filter-cleared="filterCleared" v-if="mode=='list'" />
    <list v-if="mode=='list'" @on-edit-initiated="mode='edit'" @on-add-initiated="mode='add'" :filter="listFilter" />
    <edit v-if="mode != 'list'" @on-action-complete="mode='list'" :initial-selections="listFilter" />
  </div>
</template>

<script>
import ClustersFilter from '../../common/ClustersFilter.vue'
import Edit from './Edit'
import List  from './List'
export default {
  data() {
    return {
      mode: 'list',
      listFilter: {}
    }
  },
  methods: {
    filterCleared(filter) {
      this.listFilter = filter
    },
    filterChanged(filter) {
      this.listFilter = filter
    }
  },
  watch: {
    mode(newMode) {
      if (newMode != 'list') {
        this.$emit('on-editing')
      } else {
        this.$emit('on-edit-complete')
      }
    }
  },
  components: {
    Edit,
    List,
    ClustersFilter
  }
}
</script>
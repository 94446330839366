const metadata = {
  common: {
    yesNo: ['Yes', 'No']
  },
  information: {
    dependentOptions: ['Independent', 'Dependent', 'In Foster Care', 'Ward of the Court'],
    gender: [
      { value: 'M', text: 'Male' },
      { value: 'F', text: 'Female' }
    ]
  },
  visit: {
    types: ['Student', 'Parent/Guardian', 'Student and Parent/Guardian', 'One on one visit in Zoom, Google meet, and emails among other video chat platforms'],
    topics: [
      { value: 'careerAssessment', text: 'Career Assessment' },
      { value: 'careerPlan', text: 'Career Plan' },
      { value: 'collegeAdmissions', text: 'College Admissions' },
      { value: 'financialAid', text: 'Financial Aid' },
      { value: 'academicConcurrentCredit', text: 'Academic Concurrent Credit' },
      { value: 'cteConcurrentCredit', text: 'CTE Concurrent Credit' },
      { value: 'apprenticeship', text: 'Apprenticeship' },
      { value: 'workbasedLearning', text: 'Work-Based Learning' }
    ],
    locations: ['One-on-one', 'Classroom Presentation', 'Small Group', 'Large Group Assembly', 'Phone (Not Counted)', 'Email (Not Counted)']
  },
  certification: {
    passFail: [      
      { value: null, text: 'N/A' },
      { value: true, text: 'Pass' },
      { value: false, text: 'Fail' }
    ]
  },
  workExperience: {
    wblOptions: ['', 'Apprenticeship', 'Career Exploration Camp', 'Industry Visit', 'Internship', 'Job Shadowing']
  },
  careerPlanning: {
    createdModified: ['CREATED', 'MODIFIED']
  },
  postSecondaryPlanning: {
    collegeAcceptanceStatus: ['Accepted', 'Not Accepted'],
    scholarshipAcceptanceStatus: ['Offered', 'Accepted', 'Unknown'],
    yesNoPending: ['Yes', 'No', 'Pending'],
    otherScholarshipAcceptanceStatus: ['Applied', 'Offered', 'Accepted', 'Unknown'],
    overallPlan: [
      { text: 'College',        value: 'college' },
      { text: 'Military',       value: 'military' },
      { text: 'Apprenticeship', value: 'apprenticeship' },
      { text: 'Work',          value: 'work' },
      { text: 'Out of State',   value: 'outofstate' }
    ]
  }
}

export { metadata }

<template>
  <div>
    <a id="district" class="anchor" />
    <pdf-button />
    <blended-learning-excel-button title="Blended Learning Needs Assessment" />
    <h1>Blended Learning Needs Assessment</h1>
    <h2><lea-display :lea="resolvedLea" /></h2>

    <b-button @click="startEdit" v-if="userCanEdit && isDistrict && !editMode" variant="primary">
      <i class="fas fa-edit" /> Edit Needs Assessment
    </b-button>

    <div v-if="userCanEdit && isDistrict && editMode">
      <b-button variant="success" @click="save()" :disabled="!$v.$anyDirty || $v.$invalid">
        <i class="fas fa-save" /> Save
      </b-button>
      <b-button variant="danger" class="ml-2" @click="cancel()">
        <i class="fas fa-ban" /> Cancel
      </b-button>
    </div>

    <Promised :promise="loadData" v-show="loadData">
      <template v-slot:pending>
        <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
          <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
        </div>
      </template>
      <template v-slot>
        <div v-if="isDistrict && !isFormFilled">
          <div class="alert alert-warning">
            <strong>Missing data </strong>- The selected district has not completed the Blended Learning Needs Assessment.
          </div>
        </div>
        <div v-else>
          <div class="card mt-3">
            <div class="card-header">
              District
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-6">
                      <div class="card" v-if="isDistrict">
                        <div class="card-header bg-secondary">
                          District Contact for Survey Data-Sets
                        </div>
                        <div class="card-body bg-light">
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item bg-light">
                              <span class="font-weight-bold col-3"><i class="fas fa-fw fa-user" /> Name:</span>
                              <span v-if="!editMode">
                                {{ needsAssessment.formContactName }}
                              </span>
                              <span v-if="editMode">
                                <b-form-input type="text" class="col-9 d-inline" v-model.trim="$v.needsAssessment.formContactName.$model" />
                              </span>
                            </li>
                            <li class="list-group-item bg-light">
                              <span class="font-weight-bold col-3"><i class="fas fa-fw fa-envelope" /> Email:</span>
                              <span v-if="!editMode">
                                <a :href="'mailto:' + needsAssessment.formContactEmail">{{ needsAssessment.formContactEmail }}</a>
                              </span>
                              <span v-if="editMode">
                                <b-form-input type="text" class="col-9 d-inline" v-model.trim="$v.needsAssessment.formContactEmail.$model" />
                              </span>
                            </li>
                            <li class="list-group-item bg-light">
                              <span class="font-weight-bold col-3"><i class="fas fa-fw fa-phone" />Phone:</span> 
                              <span v-if="!editMode">
                                <a :href="'tel:' + formatPhoneNumber(needsAssessment.formContactPhone)">{{ formatPhoneNumber(needsAssessment.formContactPhone) }}</a>
                              </span>
                              <span v-if="editMode">
                                <b-form-input type="text" class="col-9 d-inline" v-model.trim="$v.needsAssessment.formContactPhone.$model" />
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="card" v-if="isDistrict">
                        <div class="card-header bg-secondary">
                          Blended/Remote Learning Contact
                        </div>
                        <div class="card-body bg-light">
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item bg-light">
                              <span class="font-weight-bold col-3"><i class="fas fa-fw fa-user" /> Name:</span>
                              <span v-if="!editMode">
                                {{ needsAssessment.blendedContactName }}
                              </span>
                              <span v-if="editMode">
                                <b-form-input type="text" class="col-9 d-inline" v-model.trim="$v.needsAssessment.blendedContactName.$model" />
                              </span>
                            </li>
                            <li class="list-group-item bg-light">
                              <span class="font-weight-bold col-3"><i class="fas fa-fw fa-envelope" /> Email:</span>
                              <span v-if="!editMode || userIsBLRContact">
                                <a :href="'mailto:' + needsAssessment.blendedContactEmail">{{ needsAssessment.blendedContactEmail }}</a>
                              </span>
                              <span v-if="editMode && !userIsBLRContact">
                                <b-form-input type="text" class="col-9 d-inline" v-model.trim="$v.needsAssessment.blendedContactEmail.$model" />
                              </span>
                            </li>
                            <li class="list-group-item bg-light">
                              <span class="font-weight-bold col-3"><i class="fas fa-fw fa-phone" />Phone:</span> 
                              <span v-if="!editMode">
                                <a :href="'tel:' + formatPhoneNumber(needsAssessment.blendedContactPhone)">{{ formatPhoneNumber(needsAssessment.blendedContactPhone) }}</a>
                              </span>
                              <span v-if="editMode">
                                <b-form-input type="text" class="col-9 d-inline" v-model.trim="$v.needsAssessment.blendedContactPhone.$model" />
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-12">
                      <p class="font-weight-bold mt-3">
                        Please provide information on the Learning Management System(s) (LMS) you plan to use during the 2020-21 school year.
                        An LMS is a tool that allows users to create and manage virtual classrooms, courses, and
                        components for instruction. If you are using different tools for different grade levels or schools in your district
                        please provide that information.
                      </p>
                      <div v-if="isDistrict">
                        <div class="card bg-light p-2">
                          <div class="row">
                            <div class="col-6">
                              <ul class="list-group list-group-flush">
                                <li class="list-group-item bg-light">
                                  <pretty-checkbox :value="needsAssessment.lmsUseDESEProvided" v-if="!editMode" />
                                  <b-form-checkbox @change="$v.needsAssessment.lmsUseDESEProvided.$touch()" :value="1" :unchecked-value="0" v-model="needsAssessment.lmsUseDESEProvided" size="lg" class="d-inline" v-if="editMode" />
                                  DESE Provided LMS
                                </li>
                                <li class="list-group-item bg-light">
                                  <pretty-checkbox :value="needsAssessment.lmsUseEdmodo" v-if="!editMode" />
                                  <b-form-checkbox @change="$v.needsAssessment.lmsUseEdmodo.$touch()" :value="1" :unchecked-value="0" v-model="needsAssessment.lmsUseEdmodo" size="lg" class="d-inline" v-if="editMode" />
                                  Edmodo
                                </li>
                                <li class="list-group-item bg-light">
                                  <pretty-checkbox :value="needsAssessment.lmsUseCanvas" v-if="!editMode" />
                                  <b-form-checkbox @change="$v.needsAssessment.lmsUseCanvas.$touch()" :value="1" :unchecked-value="0" v-model="needsAssessment.lmsUseCanvas" size="lg" class="d-inline" v-if="editMode" />
                                  Canvas
                                </li>
                              </ul>
                            </div>
                            <div class="col-6">
                              <ul class="list-group list-group-flush">
                                <li class="list-group-item bg-light">
                                  <pretty-checkbox :value="needsAssessment.lmsUseSchoology" v-if="!editMode" />
                                  <b-form-checkbox @change="$v.needsAssessment.lmsUseSchoology.$touch()" :value="1" :unchecked-value="0" v-model="needsAssessment.lmsUseSchoology" size="lg" class="d-inline" v-if="editMode" />
                                  Schoology
                                </li>
                                <li class="list-group-item bg-light">
                                  <pretty-checkbox :value="needsAssessment.lmsUseGoogleClassroom" v-if="!editMode" />
                                  <b-form-checkbox @change="$v.needsAssessment.lmsUseGoogleClassroom.$touch()" :value="1" :unchecked-value="0" v-model="needsAssessment.lmsUseGoogleClassroom" size="lg" class="d-inline" v-if="editMode" />
                                  Google Classroom
                                </li>
                                <li class="list-group-item bg-light">
                                  <pretty-checkbox :value="needsAssessment.lmsUseOther" v-if="!editMode" />
                                  <b-form-checkbox @change="$v.needsAssessment.lmsUseOther.$touch()" :value="1" :unchecked-value="0" v-model="needsAssessment.lmsUseOther" size="lg" class="d-inline" v-if="editMode" />
                                  Other (explain below)
                                </li>
                              </ul>
                            </div>
                          </div>
                          <p class="mt-3 font-weight-bold">
                            Please specify any system(s) used that are not listed and/or provide additional details if more than 1 box is checked.
                          </p>                        
                          <p class="mt-1" v-if="!editMode">
                            {{ needsAssessment.lmsComments || '(no response provided)' }}
                          </p>
                          <span v-if="editMode">
                            <b-form-textarea rows="1" max-rows="10" type="text" class="d-inline" v-model.trim="$v.needsAssessment.lmsComments.$model" />
                          </span><br>
                        </div>
                      </div>
                      <div v-else>
                        <chart-lms :input-data="needsAssessments" />
                      </div>
                    </div>
                    <div class="col-12">
                      <p class="font-weight-bold mt-3">
                        What digital content provider(s) will you be using to create content for blended learning for your students?
                        You may be using multiple providers, please check all that you use or plan to use.
                      </p>
                      <div v-if="isDistrict">
                        <div class="card bg-light p-2">
                          <div class="row">
                            <div class="col-12">
                              <ul class="list-group list-group-flush">
                                <li class="list-group-item bg-light">
                                  <pretty-checkbox :value="needsAssessment.dcpUseAPSRC" v-if="!editMode" />
                                  <b-form-checkbox @change="$v.needsAssessment.dcpUseAPSRC.$touch()" :value="1" :unchecked-value="0" v-model="needsAssessment.dcpUseAPSRC" size="lg" class="d-inline" v-if="editMode" />
                                  Arkansas Public School Resource Center (APSRC)
                                </li>
                                <li class="list-group-item bg-light">
                                  <pretty-checkbox :value="needsAssessment.dcpUseVirtualArkansas" v-if="!editMode" />
                                  <b-form-checkbox @change="$v.needsAssessment.dcpUseVirtualArkansas.$touch()" :value="1" :unchecked-value="0" v-model="needsAssessment.dcpUseVirtualArkansas" size="lg" class="d-inline" v-if="editMode" />
                                  Virtual Arkansas
                                </li>
                                <li class="list-group-item bg-light">
                                  <pretty-checkbox :value="needsAssessment.dcpUseHomeGrown" v-if="!editMode" />
                                  <b-form-checkbox @change="$v.needsAssessment.dcpUseHomeGrown.$touch()" :value="1" :unchecked-value="0" v-model="needsAssessment.dcpUseHomeGrown" size="lg" class="d-inline" v-if="editMode" />
                                  Home Grown (developed in-house within your district)
                                </li>
                                <li class="list-group-item bg-light">
                                  <pretty-checkbox :value="needsAssessment.dcpComments != null && needsAssessment.dcpComments.length > 0" v-if="!editMode" />
                                  Other (please specify any digital content provider(s) used that are not listed and/or any additional insight)
                                </li>
                              </ul>
                            </div>
                          </div>                     
                          <p class="mt-1" v-if="!editMode">
                            {{ needsAssessment.dcpComments || '(no response provided)' }}
                          </p>
                          <span v-if="editMode">
                            <b-form-textarea rows="1" max-rows="10" type="text" class="d-inline" :value="needsAssessment.dcpComments"
                                             @input="setdcpComments($event)" />
                          </span>
                        </div>
                      </div>
                      <div v-else>
                        <chart-dcp :input-data="needsAssessments" />
                      </div>
                    </div>
                    <div class="col-12">
                      <p class="font-weight-bold mt-3">
                        If the DESE provided statewide digital content for all K-12 would your district be interested in participating?
                      </p>
                      <div v-if="isDistrict">
                        <div class="card bg-light p-2">
                          <div class="row">
                            <div class="col-12">
                              <ul class="list-group list-group-flush" v-if="!editMode">
                                <li class="list-group-item bg-light">
                                  <span v-if="needsAssessment.deseContentResponse == 1"><i class="fa-lg fas fa-check text-success" /> Yes</span>
                                  <span v-else><i class="fa-lg fas fa-times text-danger" />  No</span>
                                </li>
                              </ul>
                              <b-form-radio-group v-model="$v.needsAssessment.deseContentResponse.$model" v-if="editMode">
                                <b-form-radio :value="1">
                                  <i class="fa-lg fas fa-check text-success" /> Yes
                                </b-form-radio>
                                <b-form-radio :value="0">
                                  <i class="fa-lg fas fa-times text-danger" /> No
                                </b-form-radio>
                              </b-form-radio-group>
                            </div>
                          </div>     
                          <p class="font-weight-bold mt-3">
                            Please provide any additional comments relevant to adopting statewide digital content in your district.
                          </p>                
                          <p class="mt-1" v-if="!editMode">
                            {{ needsAssessment.deseComments || '(no response provided)' }}
                          </p>
                          <span v-if="editMode">
                            <b-form-textarea rows="1" max-rows="10" type="text" class="d-inline" v-model.trim="$v.needsAssessment.deseComments.$model" />
                          </span>
                        </div>
                      </div>
                      <div v-else>
                        <chart-use-dese-content :input-data="needsAssessments" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-3 page-break-before">
            <a id="student" class="anchor" />
            <div class="card-header">
              Student
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <p class="font-weight-bold mt-3">
                    Will <i>all students</i> have access to devices, such as tablets, Chromebooks, laptops, etc., capable of
                    continuing instruction during blended/remote learning days?
                  </p>
                  <div v-if="isDistrict">
                    <div class="card bg-light p-2">
                      <div class="row">
                        <div class="col-12">
                          <ul class="list-group list-group-flush" v-if="!editMode">
                            <li class="list-group-item bg-light">
                              <span v-if="needsAssessment.deviceAccessResponse == 1"><i class="fa-lg fas fa-check text-success" /> Yes</span>
                              <span v-else><i class="fa-lg fas fa-times text-danger" />  No</span>
                            </li>
                          </ul>
                          <b-form-radio-group v-model="$v.needsAssessment.deviceAccessResponse.$model" v-if="editMode">
                            <b-form-radio :value="1">
                              <i class="fa-lg fas fa-check text-success" /> Yes
                            </b-form-radio>
                            <b-form-radio :value="0">
                              <i class="fa-lg fas fa-times text-danger" /> No
                            </b-form-radio>
                          </b-form-radio-group>
                        </div>
                      </div>
  
                      <p class="font-weight-bold mt-3">
                        Please provide any additional comments relevant to providing access to blended/remote instruction.
                      </p>                
                      <p class="mt-1" v-if="!editMode">
                        {{ needsAssessment.deviceAccessComments || '(no response provided)' }}
                      </p>
                      <span v-if="editMode">
                        <b-form-textarea rows="1" max-rows="10" type="text" class="d-inline" v-model.trim="$v.needsAssessment.deviceAccessComments.$model" />
                      </span>
                    </div>
                  </div>  
                  <div v-else>
                    <chart-student-device-access :input-data="needsAssessments" />
                  </div>
                </div>
              </div>
              <div class="row" v-if="isDistrict">
                <div class="col-12">
                  <p class="font-weight-bold mt-3">
                    Please list the <i>type(s) of devices</i> (iPads, Chromebooks, laptops, etc.) your students will have access to
                    use during blended/remote learning opportunities at various grade spans.
                  </p>
                  <div v-if="isDistrict">
                    <div class="card bg-light p-2">
                      <div class="row">
                        <div class="col-12">
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item bg-light">
                              <div class="row">
                                <div class="font-weight-bold col-3">
                                  Elementary
                                </div>
                                <div class="col-9" v-if="!editMode">
                                  {{ needsAssessment.deviceTypesElementary }}
                                </div>
                                <div v-if="editMode" class="col-9">
                                  <b-form-input type="text" v-model.trim="$v.needsAssessment.deviceTypesElementary.$model" />
                                </div>
                              </div>
                            </li>
                            <li class="list-group-item bg-light">
                              <div class="row">
                                <div class="font-weight-bold col-3">
                                  Middle Level
                                </div>
                                <div class="col-9" v-if="!editMode">
                                  {{ needsAssessment.deviceTypesMiddle }}
                                </div>
                                <div v-if="editMode" class="col-9">
                                  <b-form-input type="text" v-model.trim="$v.needsAssessment.deviceTypesMiddle.$model" />
                                </div>
                              </div>
                            </li>
                            <li class="list-group-item bg-light">
                              <div class="row">
                                <div class="font-weight-bold col-3">
                                  High School
                                </div>
                                <div class="col-9" v-if="!editMode">
                                  {{ needsAssessment.deviceTypesHigh }}
                                </div>
                                <div v-if="editMode" class="col-9">
                                  <b-form-input type="text" v-model.trim="$v.needsAssessment.deviceTypesHigh.$model" />
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>                     
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="isDistrict">
                <div class="col-12">
                  <p class="font-weight-bold mt-3">
                    Please provide the <i>percentage</i> of broadband internet access for <u>each category</u> that depicts students'
                    actual internet speeds at home.
                  </p>
                  <div v-if="isDistrict">
                    <div class="card bg-light p-2">
                      <div class="row">
                        <div :class="showStudentInternetSpeeds ? 'col-6' : 'col-12'">
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item bg-light">
                              <div class="row">
                                <div class="font-weight-bold col-3">
                                  No Access
                                </div>
                                <div class="col-9" v-if="!editMode">
                                  {{ needsAssessment.broadbandNoAccess }}
                                </div>
                                <div v-if="editMode" class="col-9">
                                  <b-form-input type="text" v-model.number="$v.needsAssessment.broadbandNoAccess.$model" :state="$v.needsAssessment.broadbandNoAccess.$error != '' ? false : null" />
                                  <div class="error" v-if="!$v.needsAssessment.broadbandNoAccess.numeric">
                                    Field must be numeric.
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li class="list-group-item bg-light">
                              <div class="row">
                                <div class="font-weight-bold col-3">
                                  2-10 Mbps
                                </div>
                                <div class="col-9" v-if="!editMode">
                                  {{ needsAssessment.broadband_2_10 }}
                                </div>
                                <div v-if="editMode" class="col-9">
                                  <b-form-input type="text" v-model.number="$v.needsAssessment.broadband_2_10.$model" :state="$v.needsAssessment.broadband_2_10.$error != '' ? false : null" />
                                  <div class="error" v-if="!$v.needsAssessment.broadband_2_10.numeric">
                                    Field must be numeric.
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li class="list-group-item bg-light">
                              <div class="row">
                                <div class="font-weight-bold col-3">
                                  11-25 Mbps
                                </div>
                                <div class="col-9" v-if="!editMode">
                                  {{ needsAssessment.broadband_11_25 }}
                                </div>
                                <div v-if="editMode" class="col-9">
                                  <b-form-input type="text" v-model.number="$v.needsAssessment.broadband_11_25.$model" :state="$v.needsAssessment.broadband_11_25.$error != '' ? false : null" />
                                  <div class="error" v-if="!$v.needsAssessment.broadband_11_25.numeric">
                                    Field must be numeric.
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li class="list-group-item bg-light">
                              <div class="row">
                                <div class="font-weight-bold col-3">
                                  26-50 Mbps
                                </div>
                                <div class="col-9" v-if="!editMode">
                                  {{ needsAssessment.broadband_26_50 }}
                                </div>
                                <div v-if="editMode" class="col-9">
                                  <b-form-input type="text" v-model.number="$v.needsAssessment.broadband_26_50.$model" :state="$v.needsAssessment.broadband_26_50.$error != '' ? false : null" />
                                  <div class="error" v-if="!$v.needsAssessment.broadband_26_50.numeric">
                                    Field must be numeric.
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li class="list-group-item bg-light">
                              <div class="row">
                                <div class="font-weight-bold col-3">
                                  50+ Mbps
                                </div>
                                <div class="col-9" v-if="!editMode">
                                  {{ needsAssessment.broadband_50plus }}
                                </div>
                                <div v-if="editMode" class="col-9">
                                  <b-form-input type="text" v-model.number="$v.needsAssessment.broadband_50plus.$model" :state="$v.needsAssessment.broadband_50plus.$error != '' ? false : null" />
                                  <div class="error" v-if="!$v.needsAssessment.broadband_50plus.numeric">
                                    Field must be numeric.
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div class="col-6" v-if="showStudentInternetSpeeds">
                          <chart-student-internet-speed :input-data="needsAssessment" />
                        </div>
                      </div>                     
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p class="font-weight-bold mt-3">
                    What type(s) of internet access will be provided to students during blending/remote learning opportunities?
                  </p>
                  <div v-if="isDistrict">
                    <div class="card bg-light p-2">
                      <div class="row">
                        <div class="col-12">
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item bg-light">
                              <pretty-checkbox :value="needsAssessment.internetProvidedSchool" v-if="!editMode" />
                              <b-form-checkbox @change="$v.needsAssessment.internetProvidedSchool.$touch()" :value="1" :unchecked-value="0" v-model="needsAssessment.internetProvidedSchool" size="lg" class="d-inline" v-if="editMode" />
                              School provided internet access
                            </li>
                            <li class="list-group-item bg-light">
                              <pretty-checkbox :value="needsAssessment.internetProvidedSelf" v-if="!editMode" />
                              <b-form-checkbox @change="$v.needsAssessment.internetProvidedSelf.$touch()" :value="1" :unchecked-value="0" v-model="needsAssessment.internetProvidedSelf" size="lg" class="d-inline" v-if="editMode" />
                              Home/Self-provided internet access
                            </li>
                            <li class="list-group-item bg-light">
                              <pretty-checkbox :value="needsAssessment.internetProvidedOther != null && needsAssessment.internetProvidedOther.length > 0" v-if="!editMode" />
                              Other (please specify)
                            </li>
                          </ul>
                        </div>
                      </div>                     
                      <p class="mt-1" v-if="!editMode">
                        {{ needsAssessment.internetProvidedOther || '(no response provided)' }}
                      </p>
                      <span v-if="editMode">
                        <b-form-textarea rows="1" max-rows="10" type="text" class="d-inline" :value="needsAssessment.internetProvidedOther"
                                         @input="setinternetProvidedOther($event)" />
                      </span>
                    </div>
                  </div>
                  <div v-else>
                    <chart-student-internet-types :input-data="needsAssessments" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-3 page-break-before">
            <a id="staff" class="anchor" />
            <div class="card-header">
              Staff
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <p class="font-weight-bold mt-3">
                    Will <i>all educators</i> have access to devices such as tablets, Chromebooks, laptops, etc. for the continuation of teaching during blended/remote learning days?
                  </p>
                  <div v-if="isDistrict">
                    <div class="card bg-light p-2">
                      <div class="row">
                        <div class="col-12">
                          <ul class="list-group list-group-flush" v-if="!editMode">
                            <li class="list-group-item bg-light">
                              <span v-if="needsAssessment.educatorDeviceAccessResponse == 1"><i class="fa-lg fas fa-check text-success" /> Yes</span>
                              <span v-else><i class="fa-lg fas fa-times text-danger" />  No</span>
                            </li>
                          </ul>
                          <b-form-radio-group v-model="$v.needsAssessment.educatorDeviceAccessResponse.$model" v-if="editMode">
                            <b-form-radio :value="1">
                              <i class="fa-lg fas fa-check text-success" /> Yes
                            </b-form-radio>
                            <b-form-radio :value="0">
                              <i class="fa-lg fas fa-times text-danger" /> No
                            </b-form-radio>
                          </b-form-radio-group>
                        </div>
                      </div>     
                      <p class="font-weight-bold mt-3">
                        Please provide any additional comments relevant to providing teachers' access to devices.
                      </p>                
                      <p class="mt-1" v-if="!editMode">
                        {{ needsAssessment.educatorDeviceAccessComment || '(no response provided)' }}
                      </p>
                      <span v-if="editMode">
                        <b-form-textarea rows="1" max-rows="10" type="text" class="d-inline" v-model.trim="$v.needsAssessment.educatorDeviceAccessComment.$model" />
                      </span>
                    </div>
                  </div>
                  <div v-else>
                    <chart-educator-device-access :input-data="needsAssessments" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p class="font-weight-bold mt-3">
                    Select the percentage (to the best of your knowledge) of educators in your district that have <i>devices</i> capable of delivering education remotely.
                  </p>
                  <div v-if="isDistrict">
                    <div class="card bg-light p-2">
                      <div class="row">
                        <div class="col-6" v-if="!editMode">
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item bg-light">
                              <pretty-checkbox :value="needsAssessment.educatorDeviceCapabilityPercentage == '0-20%'" /> 0-20%
                            </li>
                            <li class="list-group-item bg-light">
                              <pretty-checkbox :value="needsAssessment.educatorDeviceCapabilityPercentage == '21-40%'" /> 21-40%
                            </li>
                            <li class="list-group-item bg-light">
                              <pretty-checkbox :value="needsAssessment.educatorDeviceCapabilityPercentage == '41-60%'" /> 41-60%
                            </li>
                          </ul>
                        </div>
                        <div class="col-6" v-if="!editMode">
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item bg-light">
                              <pretty-checkbox :value="needsAssessment.educatorDeviceCapabilityPercentage == '61-80%'" /> 61-80%
                            </li>
                            <li class="list-group-item bg-light">
                              <pretty-checkbox :value="needsAssessment.educatorDeviceCapabilityPercentage == '81-95%'" /> 81-95%
                            </li>
                            <li class="list-group-item bg-light">
                              <pretty-checkbox :value="needsAssessment.educatorDeviceCapabilityPercentage == '96-100%'" /> 96-100%
                            </li>
                          </ul>
                        </div>
                        <div class="col-12" v-if="editMode">
                          <b-form-select v-model="$v.needsAssessment.educatorDeviceCapabilityPercentage.$model" :options="devicePercentages" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <chart-educator-device-capability :input-data="needsAssessments" />
                  </div>
                </div>
              </div>
              <div class="row" v-if="isDistrict">
                <div class="col-12">
                  <p class="font-weight-bold mt-3">
                    Please list the type(s) of devices (iPads, Chromebooks, laptops, etc.) your educators will have access to use during blended/remote learning opportunities
                  </p>
                  <div v-if="isDistrict">
                    <div class="card bg-light p-2">  
                      <p class="mt-1" v-if="!editMode">
                        {{ needsAssessment.educatorDeviceTypes || '(no response provided)' }}
                      </p>
                      <span v-if="editMode">
                        <b-form-textarea rows="1" max-rows="10" type="text" class="d-inline" v-model.trim="$v.needsAssessment.educatorDeviceTypes.$model" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p class="font-weight-bold mt-3">
                    Select the percentage (to the best of your knowledge) of educators in your district that have <i>broadband connectivity</i> capable of delivering education remotely.
                  </p>
                  <div v-if="isDistrict">
                    <div class="card bg-light p-2">
                      <div class="row">
                        <div class="col-6" v-if="!editMode">
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item bg-light">
                              <pretty-checkbox :value="needsAssessment.educatorBroadbandPercentage == '0-20%'" /> 0-20%
                            </li>
                            <li class="list-group-item bg-light">
                              <pretty-checkbox :value="needsAssessment.educatorBroadbandPercentage == '21-40%'" /> 21-40%
                            </li>
                            <li class="list-group-item bg-light">
                              <pretty-checkbox :value="needsAssessment.educatorBroadbandPercentage == '41-60%'" /> 41-60%
                            </li>
                          </ul>
                        </div>
                        <div class="col-6" v-if="!editMode">
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item bg-light">
                              <pretty-checkbox :value="needsAssessment.educatorBroadbandPercentage == '61-80%'" /> 61-80%
                            </li>
                            <li class="list-group-item bg-light">
                              <pretty-checkbox :value="needsAssessment.educatorBroadbandPercentage == '81-95%'" /> 81-95%
                            </li>
                            <li class="list-group-item bg-light">
                              <pretty-checkbox :value="needsAssessment.educatorBroadbandPercentage == '96-100%'" /> 96-100%
                            </li>
                          </ul>
                        </div>
                        <div class="col-12" v-if="editMode">
                          <b-form-select v-model="$v.needsAssessment.educatorBroadbandPercentage.$model" :options="devicePercentages" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <chart-educator-broadband :input-data="needsAssessments" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p class="font-weight-bold mt-3">
                    What type(s) of internet access will be provided by the district to educators during blending/remote learning opportunities?
                  </p>
                  <div v-if="isDistrict">
                    <div class="card bg-light p-2">
                      <div class="row">
                        <div class="col-12">
                          <ul class="list-group list-group-flush">
                            <li class="list-group-item bg-light">
                              <pretty-checkbox :value="needsAssessment.educatorBroadbandProvidedSchool" v-if="!editMode" />
                              <b-form-checkbox @change="$v.needsAssessment.educatorBroadbandProvidedSchool.$touch()" :value="1" :unchecked-value="0" v-model="needsAssessment.educatorBroadbandProvidedSchool" size="lg" class="d-inline" v-if="editMode" />
                              School provided internet access
                            </li>
                            <li class="list-group-item bg-light">
                              <pretty-checkbox :value="needsAssessment.educatorBroadbandProvidedSelf" v-if="!editMode" />
                              <b-form-checkbox @change="$v.needsAssessment.educatorBroadbandProvidedSelf.$touch()" :value="1" :unchecked-value="0" v-model="needsAssessment.educatorBroadbandProvidedSelf" size="lg" class="d-inline" v-if="editMode" />
                              Home/Self-provided internet access
                            </li>
                            <li class="list-group-item bg-light">
                              <pretty-checkbox :value="needsAssessment.educatorBroadbandProvidedOther != null && needsAssessment.educatorBroadbandProvidedOther.length > 0" v-if="!editMode" />
                              Other (please specify)
                            </li>
                          </ul>
                        </div>
                      </div>                     
                      <p class="mt-1" v-if="!editMode">
                        {{ needsAssessment.educatorBroadbandProvidedOther || '(no response provided)' }}
                      </p>
                      <span v-if="editMode">
                        <b-form-textarea rows="1" max-rows="10" type="text" class="d-inline" :value="needsAssessment.educatorBroadbandProvidedOther"
                                         @input="seteducatorBroadbandProvidedOther($event)" />
                      </span>
                    </div>
                  </div>
                  <div v-else>
                    <chart-educator-internet-types :input-data="needsAssessments" />
                  </div>
                </div>
              </div>
              <div class="row" v-if="isDistrict">
                <div class="col-12">
                  <p class="font-weight-bold mt-3">
                    Give a brief description of your blended/remote learning plan and/or include a link to your plan.
                  </p>
                  <div v-if="isDistrict">
                    <div class="card bg-light p-2">  
                      <p class="mt-1" v-if="!editMode">
                        {{ needsAssessment.commentLearningPlan || '(no response provided)' }}
                      </p>
                      <span v-if="editMode">
                        <b-form-textarea rows="1" max-rows="10" type="text" class="d-inline" v-model.trim="$v.needsAssessment.commentLearningPlan.$model" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-3 page-break-before" v-if="selectedLea.type == LeaTypes.District">
            <a id="oe-questions" class="anchor" />
            <div class="card-header">
              Open-Ended Questions
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <p class="font-weight-bold mt-3">
                    What are your district's professional development needs for conducting blended/remote instruction?
                  </p>
                  <div v-if="isDistrict">
                    <div class="card bg-light p-2">  
                      <p class="mt-1" v-if="!editMode">
                        {{ needsAssessment.commentPDNeeds || '(no response provided)' }}
                      </p>
                      <span v-if="editMode">
                        <b-form-textarea rows="1" max-rows="10" type="text" class="d-inline" v-model.trim="$v.needsAssessment.commentPDNeeds.$model" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p class="font-weight-bold mt-3">
                    How can the DESE assist with providing resources and training for your district for continuity of teaching
                    and learning for all with blended/remote opportunities?
                  </p>
                  <div v-if="isDistrict">
                    <div class="card bg-light p-2">  
                      <p class="mt-1" v-if="!editMode">
                        {{ needsAssessment.commentDESEResources || '(no response provided)' }}
                      </p>
                      <span v-if="editMode">
                        <b-form-textarea rows="1" max-rows="10" type="text" class="d-inline" v-model.trim="$v.needsAssessment.commentDESEResources.$model" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p class="font-weight-bold mt-3">
                    How will student and staff participation in remote instruction be monitored and tracked?
                  </p>
                  <div v-if="isDistrict">
                    <div class="card bg-light p-2">  
                      <p class="mt-1" v-if="!editMode">
                        {{ needsAssessment.commentMonitoring || '(no response provided)' }}
                      </p>
                      <span v-if="editMode">
                        <b-form-textarea rows="1" max-rows="10" type="text" class="d-inline" v-model.trim="$v.needsAssessment.commentMonitoring.$model" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p class="font-weight-bold mt-3">
                    How will parents and families be notified of where to find and access blended/remote instruction resources
                    provided by the school/district?
                  </p>
                  <div v-if="isDistrict">
                    <div class="card bg-light p-2">  
                      <p class="mt-1" v-if="!editMode">
                        {{ needsAssessment.commentNotification || '(no response provided)' }}
                      </p>
                      <span v-if="editMode">
                        <b-form-textarea rows="1" max-rows="10" type="text" class="d-inline" v-model.trim="$v.needsAssessment.commentNotification.$model" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p class="font-weight-bold mt-3">
                    What are other ways that the DESE and Team Digital can help your district with blended/remote teaching
                    and learning opportunities?
                  </p>
                  <div v-if="isDistrict">
                    <div class="card bg-light p-2">  
                      <p class="mt-1" v-if="!editMode">
                        {{ needsAssessment.commentOtherHelp || '(no response provided)' }}
                      </p>
                      <span v-if="editMode">
                        <b-form-textarea rows="1" max-rows="10" type="text" class="d-inline" v-model.trim="$v.needsAssessment.commentOtherHelp.$model" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-3 page-break-before">
            <a id="devices" class="anchor" />
            <div class="card-header">
              Devices
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <chart-device-building-summary :input-data="buildingSummary" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:rejected="error" class="align-items-center justify-content-center">
        <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
      </template>
    </Promised>
    <div class="d-print-none">
      <div class="card mt-3">
        <a id="tech_district" class="anchor" />
        <div class="card-header">
          Specific Considerations: Technology
        </div>
        <div class="card-body p-0">
          <pdf
            v-for="i in 2"
            :key="'technology_district_' + i"
            src="/Content/blendedlearning/tech_district.pdf"
            :page="i"
            style="display: block; width: 100%;margin:auto;"
          />
          <a id="tech_school" class="anchor" />
          <pdf
            key="technology_school"
            src="/Content/blendedlearning/tech_school.pdf"
            style="display: block; width: 100%;margin:auto;"
          />
        </div>
      </div>
    </div>
    <div class="d-print-none">
      <div class="card mt-3">
        <div class="card-header">
          Survey Information: Data Source &amp; Guidance
        </div>
        <div class="card-body p-0">  
          <a id="arkansas-ready-for-learning" class="anchor" />       
          <div class="card m-3">
            <div class="card-header bg-secondary">
              Arkansas Ready for Learning
            </div>
            <div class="card-body bg-light">
              <a class="h5" href="http://dese.ade.arkansas.gov/public/userfiles/Communications/Ready/Response_Levels_for_On-Site_Learning_FINAL_Updated_8_20_20.pdf" target="_new"><i class="fa fa-file-pdf" /> Download the Arkansas Ready for Learning Guide</a>              
            </div>
          </div>
          <a id="rt-20-013" class="anchor" />
          <div class="card m-3">
            <div class="card-header bg-secondary">
              Commissioner's Memo RT-20-013
            </div>
            <div class="card-body p-0">
              <pdf
                v-for="i in 2"
                :key="'rt-20-013_' + i"
                src="/Content/blendedlearning/ADECM-RT-20-013.pdf"
                :page="i"
                style="display: block; width: 100%;margin:auto;"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <a id="hotspot" class="anchor" />
    <div class="card mt-3 page-break-before">
      <div class="card-header">
        DESE Arkansas Hotspot Project
      </div>
      <div class="card-body">
        Hotspot Allocation<br>
        RT-21-004: <a target="_blank" href="http://adecm.arkansas.gov/ViewApprovedMemo.aspx?Id=4490">http://adecm.arkansas.gov/ViewApprovedMemo.aspx?Id=4490</a><br><br>
        <chart-hotspot :user-can-edit="isDistrict && userContext.isRTTIRAdmin" />
      </div>
    </div>
  </div>
</template>

<script>
import { Promised } from 'vue-promised'
import { PageMixin } from '../PageMixin'
import { mapState, mapGetters } from 'vuex'
import { LeaTypes } from '../../helpers/leaTypes'
import pdf from 'vue-pdf'
import CardStateIcon from '../CardStateIcon.vue'
import PrettyCheckbox from '../PrettyCheckbox'
import BlendedLearningExcelButton from './blendedLearningExcelButton'

import Vue from 'vue'
import Vuelidate from 'vuelidate'
import { numeric } from 'vuelidate/lib/validators'

import ChartStudentInternetSpeed from './ChartStudentInternetSpeed'

import ChartLms from './ChartLms'
import ChartDcp from './ChartDcp'
import ChartUseDeseContent from './ChartUseDeseContent'
import ChartStudentDeviceAccess from './ChartStudentDeviceAccess'
import ChartStudentInternetTypes from './ChartStudentInternetTypes'
import ChartEducatorDeviceAccess from './ChartEducatorDeviceAccess'
import ChartEducatorDeviceCapability from './ChartEducatorDeviceCapability'
import ChartEducatorBroadband from './ChartEducatorBroadband'
import ChartEducatorInternetTypes from './ChartEducatorInternetTypes'
import ChartDeviceBuildingSummary from './ChartDeviceBuildingSummary'
import ChartHotspot from './ChartHotspot'

function sum(prev, next){
  return prev + next;
}

Vue.use(Vuelidate)

export default {
  data() {
    return {
      loadData: Promise,
      resolvedLea: Object,
      editMode: false,
      needsAssessment: {},
      loading: true,
      devicePercentages: [
        { value: null, text: '(no selection)' },
        { value: '0-20%', text: '0-20%' },
        { value: '21-40%', text: '21-40%' },
        { value: '41-60%', text: '41-60%' },
        { value: '61-80%', text: '61-80%' },
        { value: '81-95%', text: '81-95%' },
        { value: '96-100%', text: '96-100%' }
      ]
    }
  },
  mixins: [PageMixin],
  computed: {
    selectedLea() {
      return this.$store.state.globalModule.leaContext.SelectedLea
    },
    isDistrict() {
      return (this.needsAssessments || []).length == 1
    },
    isFormFilled() {
      return this.needsAssessment.submitDate != null
    },
    showStudentInternetSpeeds() {
      return (this.needsAssessment.broadbandNoAccess + this.needsAssessment.broadband_2_10 + this.needsAssessment.broadband_11_25 + this.needsAssessment.broadband_26_50 + this.needsAssessment.broadband_50plus) == 100
    },
    userIsBLRContact() {
      if (!this.needsAssessments || !this.userContext?.emailAddress || !this.userContext.userId) return false
      // logged in user's email address is the same as the email address on the blended/remote learning contact, and APSCN user ID has the same first four numbers as the selected LEA
      return (this.needsAssessments[0]?.blendedContactEmail.toLowerCase().trim() == this.userContext.emailAddress.toLowerCase().trim())
          && this.userContext.userId.toLowerCase().substr(0,10) == 'apscn\\' + this.selectedLea.number.substr(0, 4)
    },
    userCanEdit() {
      return this.userContext.isRTTIRAdmin || this.userIsBLRContact
    },
    hotspots() {
      return this.hotspotList[0] || {}
    },
    ...mapState('globalModule', ['userContext']),
    ...mapGetters('globalModule', ['leaDictionary']),
    ...mapState('blendedLearningReadinessModule', ['needsAssessments', 'buildingSummary', 'hotspotList'])
  },
  methods: {
    formatPhoneNumber(phoneNumberString) {
      var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        var intlCode = (match[1] ? '' : '')
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
      }
      return phoneNumberString
    },
    excelExportData() {
      return {
        name: 'Needs Assessment',
        items: this.getNeedsAssessmentsExcel()
      }
    },
    getNeedsAssessmentsExcel() {
      var inputData = this.needsAssessments || []

      if (this.isDistrict) {
        return this.needsAssessments.map(a => {
          if (a.submitDate != null) {
            return {
              'LEA': this.selectedLea.number,
              'District': this.selectedLea.name,
              'District Contact': a.formContactName,
              'District Contact Email': a.formContactEmail,
              'District Contact Phone': a.formContactPhone,
              'Blended/Remote Learning Contact': a.blendedContactName,
              'Blended/Remote Learning Contact Email': a.blendedContactEmail,
              'Blended/Remote Learning Contact Phone': this.formatPhoneNumber(a.blendedContactPhone),
              'Use DESE Provided LMS?': a.lmsUseDESEProvided == 1,
              'Use Edmodo?': a.lmsUseEdmodo == 1,
              'Use Canvas?': a.lmsUseCanvas == 1,
              'Use Schoology?': a.lmsUseSchoology == 1,
              'Use Google Classroom?': a.lmsUseGoogleClassroom == 1,
              'Use Other LMS?': a.lmsUseOther == 1,
              'LMS Additional Details': a.lmsComments,
              'Use APSRC?': a.dcpUseAPSRC == 1,
              'Use Virtual Arkansas?': a.dcpUseVirtualArkansas == 1,
              'Use Home Grown DCP?': a.dcpUseHomeGrown == 1,
              'Use Other DCP?': a.dcpComments != null,
              'DCP Additional Details': a.dcpComments,
              'Would use DESE digital content?': a.deseContentResponse == 1,
              'DESE Digital Content Additional Details': a.deseComments,
              'All Students Have Devices?': a.deviceAccessResponse == 1,
              'Student Device Access Additional Details': a.deviceAccessComments,
              'Student Device Types Elementary': a.deviceTypesElementary,
              'Student Device Types Middle Level': a.deviceTypesMiddle,
              'Student Device Types High School': a.deviceTypesHigh,
              'Student Percentage Broadband No Access': a.broadbandNoAccess,
              'Student Percentage Broadband 2-10 Mbps': a.broadband_2_10,
              'Student Percentage Broadband 11-25 Mbps': a.broadband_11_25,
              'Student Percentage Broadband 26-50 Mbps': a.broadband_26_50,
              'Student Percentage Broadband 50+ Mbps': a.broadband_50plus,
              'Student Internet Type School Provided': a.internetProvidedSchool == 1,
              'Student Internet Type Self Provided': a.internetProvidedSelf == 1,
              'Student Internet Type Other': a.internetProvidedOther != null,
              'Student Internet Additional Comments': a.internetProvidedOther,
              'All Educators Have Devices?': a.educatorDeviceAccessResponse == 1,
              'Educator Device Access Additional Details': a.educatorDeviceAccessComment,
              'Percentage of Educators Capable Devices': a.educatorDeviceCapabilityPercentage,
              'Educator Device Types': a.educatorDeviceTypes,
              'Educator Percentage Broadband': a.educatorBroadbandPercentage,
              'Educator Internet Type School Provided': a.educatorBroadbandProvidedSchool == 1,
              'Educator Internet Type Self Provided': a.educatorBroadbandProvidedSelf == 1,
              'Educator Internet Type Other': a.educatorBroadbandProvidedOther != null,
              'Educator Internet Additional Comments': a.educatorBroadbandProvidedOther,
              'Learning Plan': a.commentLearningPlan,
              'PD Needs': a.commentPDNeeds,
              'DESE Resources': a.commentDESEResources,
              'Participation Monitoring': a.commentMonitoring,
              'Parent Notification': a.commentNotification,
              'DESE Other Help': a.commentOtherHelp,
              'Computer Stations': this.buildingSummary.map(r => r.computerStations).reduce(sum),
              'Multimedia Computer Stations': this.buildingSummary.map(r => r.multimediaStations).reduce(sum),
              'Other Devices': this.buildingSummary.map(r => r.otherDevices).reduce(sum),
              'WiFi Hotspots AT&T': this.hotspotList.map(r => r.att).reduce(sum, 0),
              'WiFi Hotspots T-Mobile': this.hotspotList.map(r => r.tMobile).reduce(sum, 0),
              'WiFi Hotspots Verizon': this.hotspotList.map(r => r.verizon).reduce(sum, 0),
              'WiFi Hotspots Total': this.hotspotList.map(r => r.total).reduce(sum, 0)
            }
          }
          else return null
        })
      }
      else return [{
        'LEA': this.selectedLea.number,
        'LEA Name': this.selectedLea.name,
        'Use DESE Provided LMS?': inputData.map(r => r.lmsUseDESEProvided).reduce(sum),
        'Use Edmodo?': inputData.map(r => r.lmsUseEdmodo).reduce(sum),
        'Use Canvas?': inputData.map(r => r.lmsUseCanvas).reduce(sum),
        'Use Schoology?': inputData.map(r => r.lmsUseSchoology).reduce(sum),
        'Use Google Classroom?': inputData.map(r => r.lmsUseGoogleClassroom).reduce(sum),
        'Use Other LMS?': inputData.map(r => r.lmsUseOther).reduce(sum),
        'Use APSRC?': inputData.map(r => r.dcpUseAPSRC).reduce(sum),
        'Use Virtual Arkansas?': inputData.map(r => r.dcpUseVirtualArkansas).reduce(sum),
        'Use Home Grown DCP?': inputData.map(r => r.dcpUseHomeGrown).reduce(sum),
        'Use Other DCP?': inputData.map(r => r.dcpComments != null ? 1 : 0).reduce(sum),
        'Would use DESE digital content - YES': inputData.map(r => r.deseContentResponse).reduce(sum),
        'Would use DESE digital content - NO': inputData.map(r => r.deseContentResponse == 0 && r.submitDate != null ? 1 : 0).reduce(sum),
        'Would use DESE digital content - No response': inputData.map(r => r.deseContentResponse == 0 && r.submitDate == null ? 1 : 0).reduce(sum),
        'All Students Have Devices - YES': inputData.map(r => r.deviceAccessResponse).reduce(sum),
        'All Students Have Devices - NO': inputData.map(r => r.deviceAccessResponse == 0 && r.submitDate != null ? 1 : 0).reduce(sum),
        'All Students Have Devices - No response': inputData.map(r => r.deviceAccessResponse == 0 && r.submitDate == null ? 1 : 0).reduce(sum),
        'Student Internet Type School Provided': inputData.map(r => r.internetProvidedSchool).reduce(sum),
        'Student Internet Type Self Provided': inputData.map(r => r.internetProvidedSelf).reduce(sum),
        'Student Internet Type Other': inputData.map(r => r.internetProvidedOther != null ? 1 : 0).reduce(sum),
        'All Educators Have Devices - YES': inputData.map(r => r.educatorDeviceAccessResponse).reduce(sum),
        'All Educators Have Devices - NO': inputData.map(r => r.educatorDeviceAccessResponse == 0 && r.submitDate != null ? 1 : 0).reduce(sum),
        'All Educators Have Devices - No response': inputData.map(r => r.educatorDeviceAccessResponse == 0 && r.submitDate == null ? 1 : 0).reduce(sum),
        'Percentage of Educators Capable Devices - 0-20%': inputData.map(r => r.educatorDeviceCapabilityPercentage == '0-20%' ? 1 : 0).reduce(sum),
        'Percentage of Educators Capable Devices - 21-40%': inputData.map(r => r.educatorDeviceCapabilityPercentage == '21-40%' ? 1 : 0).reduce(sum),
        'Percentage of Educators Capable Devices - 41-60%': inputData.map(r => r.educatorDeviceCapabilityPercentage == '41-60%' ? 1 : 0).reduce(sum),
        'Percentage of Educators Capable Devices - 61-80%': inputData.map(r => r.educatorDeviceCapabilityPercentage == '61-80%' ? 1 : 0).reduce(sum),
        'Percentage of Educators Capable Devices - 81-95%': inputData.map(r => r.educatorDeviceCapabilityPercentage == '81-95%' ? 1 : 0).reduce(sum),
        'Percentage of Educators Capable Devices - 96-100%': inputData.map(r => r.educatorDeviceCapabilityPercentage == '96-100%' ? 1 : 0).reduce(sum),
        'Percentage of Educators with Broadband - 0-20%': inputData.map(r => r.educatorBroadbandPercentage == '0-20%' ? 1 : 0).reduce(sum),
        'Percentage of Educators with Broadband - 21-40%': inputData.map(r => r.educatorBroadbandPercentage == '21-40%' ? 1 : 0).reduce(sum),
        'Percentage of Educators with Broadband - 41-60%': inputData.map(r => r.educatorBroadbandPercentage == '41-60%' ? 1 : 0).reduce(sum),
        'Percentage of Educators with Broadband - 61-80%': inputData.map(r => r.educatorBroadbandPercentage == '61-80%' ? 1 : 0).reduce(sum),
        'Percentage of Educators with Broadband - 81-95%': inputData.map(r => r.educatorBroadbandPercentage == '81-95%' ? 1 : 0).reduce(sum),
        'Percentage of Educators with Broadband - 96-100%': inputData.map(r => r.educatorBroadbandPercentage == '96-100%' ? 1 : 0).reduce(sum),
        'Educator Internet Type School Provided': inputData.map(r => r.educatorBroadbandProvidedSchool).reduce(sum),
        'Educator Internet Type Self Provided': inputData.map(r => r.educatorBroadbandProvidedSelf).reduce(sum),
        'Educator Internet Type Other': inputData.map(r => r.educatorBroadbandProvidedOther != null ? 1 : 0).reduce(sum),
        'Computer Stations': this.buildingSummary.map(r => r.computerStations).reduce(sum),
        'Multimedia Computer Stations': this.buildingSummary.map(r => r.multimediaStations).reduce(sum),
        'Other Devices': this.buildingSummary.map(r => r.otherDevices).reduce(sum),
        'WiFi Hotspots AT&T': this.hotspotList.map(r => r.att).reduce(sum, 0),
        'WiFi Hotspots T-Mobile': this.hotspotList.map(r => r.tMobile).reduce(sum, 0),
        'WiFi Hotspots Verizon': this.hotspotList.map(r => r.verizon).reduce(sum, 0),
        'WiFi Hotspots Total': this.hotspotList.map(r => r.total).reduce(sum, 0)
      }]     
    },
    save() {
      this.loading = true
      this.$store.dispatch('blendedLearningReadinessModule/saveNeedsAssessment', { assessment: this.needsAssessment, isblr: this.userIsBLRContact })
      .then(() => {
        this.needsAssessment = this.isDistrict ? { ...this.needsAssessments[0] } : null
        this.loading = false
        this.editMode = false
      })
      
    },
    cancel() {
      this.needsAssessment = this.isDistrict ? { ...this.needsAssessments[0] } : null
      this.editMode = false
      this.$v.$reset()
    },
    startEdit() {
      this.editMode = true
    },
    setdcpComments(value) {
      this.needsAssessment.dcpComments = value
      this.$v.needsAssessment.dcpComments.$touch()
    },
    setinternetProvidedOther(value) {
      this.needsAssessment.internetProvidedOther = value
      this.$v.needsAssessment.internetProvidedOther.$touch()
    },
    seteducatorBroadbandProvidedOther(value) {
      this.needsAssessment.educatorBroadbandProvidedOther = value
      this.$v.needsAssessment.educatorBroadbandProvidedOther.$touch()
    }
  },
  validations: {
    needsAssessment: {
      formContactName: {},
      formContactEmail: {},
      formContactPhone: {},
      blendedContactName: {},
      blendedContactEmail: {},
      blendedContactPhone: {},
      lmsUseDESEProvided: {},
      lmsUseEdmodo: {},
      lmsUseCanvas: {},
      lmsUseSchoology: {},
      lmsUseGoogleClassroom: {},
      lmsUseOther: {},
      lmsComments: {},
      dcpUseAPSRC: {},
      dcpUseVirtualArkansas: {},
      dcpUseHomeGrown: {},
      dcpComments: {},
      deseContentResponse: {},
      deseComments: {},
      deviceAccessResponse: {},
      deviceAccessComments: {},
      deviceTypesElementary: {},
      deviceTypesMiddle: {},
      deviceTypesHigh: {},
      broadbandNoAccess: { numeric },
      broadband_2_10: { numeric },
      broadband_11_25: { numeric },
      broadband_26_50: { numeric },
      broadband_50plus: { numeric },
      internetProvidedSchool: {},
      internetProvidedSelf: {},
      internetProvidedOther: {},
      educatorDeviceAccessResponse: {},
      educatorDeviceAccessComment: {},
      educatorDeviceCapabilityPercentage: {},
      educatorDeviceTypes: {},
      educatorBroadbandPercentage: {},
      educatorBroadbandProvidedSchool: {},
      educatorBroadbandProvidedSelf: {},
      educatorBroadbandProvidedOther: {},
      commentLearningPlan: {},
      commentPDNeeds: {},
      commentDESEResources: {},
      commentMonitoring: {},
      commentNotification: {},
      commentOtherHelp: {}
    }
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        this.loading = true
        window.scrollTo(0, 0);
        this.editMode = false
        if (newLea && newLea.number) {
            if (newLea.type == LeaTypes.School) {
              this.resolvedLea = this.leaDictionary[newLea.number].parentLea
            }
            else this.resolvedLea = newLea
            this.loadData = this.$store.dispatch('blendedLearningReadinessModule/getNeedsAssessments', { lea: this.resolvedLea.number })
            .then(() => {
              this.needsAssessment = this.isDistrict ? { ...this.needsAssessments[0] } : null
              this.loading = false
            })
        }
      }
    }
  },
  components: {
    Promised,
    pdf,
    CardStateIcon,
    BlendedLearningExcelButton,
    PrettyCheckbox,
    ChartStudentInternetSpeed,
    ChartLms,
    ChartDcp,
    ChartUseDeseContent,
    ChartStudentDeviceAccess,
    ChartStudentInternetTypes,
    ChartEducatorDeviceAccess,
    ChartEducatorDeviceCapability,
    ChartEducatorBroadband,
    ChartEducatorInternetTypes,
    ChartDeviceBuildingSummary,
    ChartHotspot
  }
}
</script>
import ApiService from '../../services/ApiService'
import { RequestStatus } from '@/components/tools/leaAssignment'

const stateMap = new Map();
stateMap.set(0, {
  code: 0,
  status: 'Draft',
  action: 'Return to Draft',
  requiresComment: false,
  variant: "primary"
})
stateMap.set(1, {
  code: 1,
  action: 'Return for Information',
  status: 'Information Requested',
  requiresComment: true,
  variant: "dark",
  defaultNextState: 3
})
stateMap.set(2, {
  code: 2,
  action: 'Send to Superintendent',
  status: 'Pending Superintendent Approval'
})
stateMap.set(3, {
  code: 3,
  action: 'Return to Reviewer',
  status: 'Returned from District',
  requiresComment: true,
  variant: "info"
})
stateMap.set(10, {
  code: 10,
  action: 'Submit',
  status: 'Submitted'
})
stateMap.set(11, {
  code: 11,
  action: 'Send to PSA',
  status: 'Pending PSA Review',
  requiresComment: true,
  variant: "info"
  //defaultNextState: 12
})
stateMap.set(12, {
  code: 12,
  action: 'Return to Reviewer',
  status: 'PSA Review Completed',
  requiresComment: true,
})
stateMap.set(13, {
  code: 13,
  action: 'Reopen Request',
  status: 'Reopened for Review',
  requiresComment: true,
  variant: "warning"
})
stateMap.set(100, {
  code: 100,
  action: 'Approve',
  status: 'Approved',
  requiresComment: false,
  variant: "success"
})
stateMap.set(101, {
  code: 101,
  action: 'Reject',
  status: 'Rejected',
  requiresComment: true,
  variant: "danger"
})
stateMap.set(255, {
  code: 255,
  action: 'Cancel',
  status: 'Canceled',
  requiresComment: true,
  variant: "secondary"
})

class Request {
  constructor(data) {
    Object.assign(this, data)
  }

  get isDraftOrPendingInformation() {
    return [RequestStatus.Draft, RequestStatus.ReturnedForInformation].includes(this.requestStatus)
  }

  get isSubmitted() {
    return RequestStatus.PendingLEACoordinator == this.requestStatus
  }

  get isApprovedRejectedOrCanceled() {
    return [RequestStatus.Approved, RequestStatus.Rejected, RequestStatus.Canceled].includes(this.requestStatus)
  }
  get syncStatus() {
    return {
      attempted: this.synchronizationResult != null,
      succeeded: this.synchronizationResult
    }
  }
}

export class LeaAssignment {

  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._moduleConfigElement = document.getElementById('lea-assignment-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''

    if (this._moduleConfigElement) {
      this._fiscalYear = parseInt(this._moduleConfigElement.getAttribute('data-fiscal-year'))
    }
  }

  namespaced = true

  state = {
    stateMap: stateMap,
    district: {},
    schools: [],
    approvedAssignments: [],
    districtManagementRequests: [],
    leamsSyncRecords: [],
    pairedLEARelationships: [],
    summary: {
      status: {
        draft: 0,
        pending: 1,
        inReview: 2,
        completed: 5
      },
      disposition: {
        approved: 4,
        rejected: 1
      },
      type: {
        new: 4,
        inactivate: 1,
        change: 4
      }
    },
    currentRequest: {},
    currentResponse: {
      operation: null,
      items: []
    },
    responseDocument: new Uint8Array(),
    reconfigurationCodes: [],
    feederPairs: [],
    leaAccountabilityStatusRecords: [],
    selectedOperation: null
  }

  mutations = {

    setPairedLEARelationships(state, data) {
      state.pairedLEARelationships = Array.from(data)
    },
    setReconfigurationCodes(state, data) {
      state.reconfigurationCodes = data
    },
    setDistrict(state, data) {
      state.district = data;
    },
    setSchools(state, data) {
      state.schools = Array.from(data)
    },
    setApprovedAssignments(state, data) {
      state.approvedAssignments = Array.from(data).map(i=>{return {...i, responseObject: JSON.parse(i.responseData) }})
    },
    setCurrentRequest(state, currentRequest) {
      state.currentRequest = new Request(currentRequest)

      try {
        var o = JSON.parse(currentRequest.response.response)
        //make sure all items have a reconfigurationCode property, even if it is null
        var items = o.items.map(i => Object.assign({
          reconfigurationCode: null
        }, i))
        state.currentResponse.operation = o.operation
        state.currentResponse.items = items
      } catch {
        state.currentResponse = {
          operation: null,
          items: []
        }
      }
    },
    setRequestDocuments(state, requestDocuments) {
      state.currentRequest.requestDocuments = requestDocuments
    },
    setResponseDocument(state, data) {
      state.responseDocument = data;
    },
    setDistrictManagementRequests(state, data) {
      state.districtManagementRequests = data;
    },
    setFeederPairs(state, data) {
      state.feederPairs = data;
    },
    setLeaAccountabilityStatusRecords(state, data) {
      state.leaAccountabilityStatusRecords = data;
    },
    setCurrentResponseItemConfigurationCode(state, data) {
      var items = state.currentResponse.items.filter(i => i.key === data.item.key)
      if (items.length > 0) items[0].reconfigurationCode = data.reconfigurationCode
    },
    setResponseOperationType(state, type) {
      state.currentResponse.operation = type
    },
    addRequestItem: (state, item) => {
      state.currentResponse.items.push(item)
    },
    removeRequestItem: (state, key) => {
      var item = state.currentResponse.items.find(i => i.key == key)

      if (item) {
        state.currentResponse.items = state.currentResponse.items.filter(i => i != item)
      }

    },
    setSelectedOperation: (state, value) => {
      state.selectedOperation = value
    }
  }

  getters = {
    moduleFiscalYear: () => {
      return this._fiscalYear
    },
    selectedOperation: (state) => {
      return state.selectedOperation
    },

    fiscalYear: (state, rootState, getters, rootGetters) => {
      return rootGetters['toolsModule/leams/fiscalYear']
    },
    userStateMap: (state, getters, rootState) => {
      var userContext = rootState.globalModule.userContext
      if (userContext.isInsightsAdmin) {
        return state.stateMap
      } else if (userContext.isInsightsPSAAdmin) {
        return new Map([...state.stateMap].filter(s => s[0] == 11))
      } else if (userContext.InsightsRTSMSAdmin) {
        var rtsmsStates = [10, 12, 3, 100, 101]
        return new Map([...state.stateMap].filter(s => rtsmsStates.includes(s[0])))
      }
      return state.stateMap
    },
    currentRequest: (state) => {
      return state.currentRequest
    },
    approvedAssignmentsByLea: (state, getters, rootState, rootGetters) => {

      var leaArray = new Array();

      if (state.approvedAssignments && state.approvedAssignments.length > 0) {
        leaArray = state.approvedAssignments.reduce((leaArray, item) => {

          var response = JSON.parse(item.responseData)
          var districtLea = item.districtLEA
          var district = rootGetters['globalModule/leaDictionary'][item.districtLEA]

          return leaArray.concat(item.leaOperations.map(i => {

            const operationData = response.items.find(f => f.key == i.operationKey) || {};

            return {
              districtLea: districtLea,
              districtName: district ? district.name : '',
              schoolLea: i.lea,
              operationKey: i.operationKey,
              operationData: operationData,
              leaName: operationData.currentLEAName || operationData.selectedLeaName || operationData.leaName,
              requestId: item.requestId
            }
          }))

        }, leaArray)

      }

      return leaArray
    },
    canCurrentRequestBeApproved: (state, getters) => {
      var newBuidlingItemRequestsWithoutAssignedLEA = state.currentRequest.requestLeas.filter((l) => l.operationType == 1 && (l.lea || '').length == 0).length
      var newDistrictItemRequestsWithoutAssignedLEA = state.currentRequest.requestLeas.filter((l) => l.operationType == 5 && (l.lea || '').length == 0).length

      return state.currentRequest.applyToFiscalYear && //have to have a fiscal year assigned
        newBuidlingItemRequestsWithoutAssignedLEA == 0 && //can't have any new building requests w/out the LEA being assigned
        newDistrictItemRequestsWithoutAssignedLEA == 0 && //can't have any new district requests w/out the LEA being assigned
        getters.allItemsAssignedReconfigurationCodes //can't have any items w/out a reconfiguration code
    },
    canCurrentRequestBeSentToPSA: (state, getters) => {
      const canSendToPSAStatuses = [RequestStatus.DistrictInformationProvided, RequestStatus.Reopen, RequestStatus.PendingADELeaCoordinator]
      return canSendToPSAStatuses.includes(state.currentRequest.requestStatus) && state.currentRequest.applyToFiscalYear && getters.allItemsAssignedReconfigurationCodes
    },
    newBuildingRequestsOnCurrentRequest(state) {

      var newSchools = state.currentResponse.items.filter((r) => r.itemType == 'new').map((i) => {
        var leaRecords = state.currentRequest.requestLeas.filter((l) => l.operationParams == i.key)
        return {
          lea: leaRecords && leaRecords.length > 0 ? leaRecords[0].lea : null,
          key: i.key,
          name: i.leaName || '(Name TBD)'
        }
      })

      return newSchools
    },
    allItemsAssignedReconfigurationCodes: (state) => {
      return state.currentResponse.items.filter((i) => !i.reconfigurationCode).length == 0
    },
    reconfigurationOptions: (state) => (purpose, operation) => {
      return state.reconfigurationCodes
      .filter(i => i.codePurpose == purpose && i.operationPurpose == operation)
      .map(i => {
        return { text: `${i.code} - ${i.description}`, value: i.code }
      })
    }
  }

  actions = {
    setSelectedOperation({commit}, newValue){
      commit("setSelectedOperation", newValue)
    },
    getSchools: ({
      commit
    }, payload) => {

      return this._apiService.Get(encodeURI(`${this._baseUrl}api/tools/lea-assignment/${payload.lea}/${payload.fy}/schools`))
        .then(data => {
          commit('setSchools', data)
          console.log('schools committed')
        })
    },
    getDistrict: ({
      commit
    }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/tools/lea-assignment/${payload.lea}/${payload.fy}`))
        .then(data => commit('setDistrict', data))
    },
    getApprovedAssignments: ({
      commit
    }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/tools/lea-assignment/${payload.lea}/${payload.fy}/approved-assignments`))
        .then(data => commit('setApprovedAssignments', data))
    },
    getRequest: ({
      commit
    }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/request/${payload.requestId}`))
        .then(data => commit('setCurrentRequest', data))
    },
    createNewRequest: ({
      commit, getters
    }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/request`), {
        FY: getters.moduleFiscalYear,
        RequestType: payload.requestType,
        DistrictLEA: payload.districtLEA,
        JsonResponse: (payload && payload.initialResponse) ? JSON.stringify(payload.initialResponse) : null
      }).then(data => {
        commit('setCurrentRequest', data)
        return data
      })
    },
    updateRequest: ({
      state
    }) => {
      return this._apiService.Patch(encodeURI(`${this._baseUrl}api/request/${state.currentRequest.requestId}`), {
        JsonResponse: JSON.stringify(state.currentResponse)
      })
    },
    getRequestDocuments: ({
      commit
    }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/request/${payload.requestId}/document`), {
        requestId: payload.requestId
      }).then(data => commit('setRequestDocuments', data))
    },
    removeRequestDocument: ({
      dispatch
    }, payload) => {
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/request/${payload.requestId}/document/${payload.requestDocumentId}`))
        .then(() => {
          dispatch('getRequestDocuments', payload)
        })
    },
    assignLea: ({
      dispatch
    }, payload) => {
      return this._apiService.Patch(encodeURI(`${this._baseUrl}api/request/${payload.requestId}/lea/${payload.key}`), {
          lea: payload.lea
        })
        .then(() => {
          dispatch('getRequest', payload)
        })
    },

    generateResponseDocument: ({
      commit
    }, payload) => {
      return this._apiService.DownloadInlineWithPost(encodeURI(`${this._baseUrl}api/tools/lea-assignment/${payload.requestId}/generate-response`))
        .then((data) => {
          commit('setResponseDocument', data)
        })
    },

    // eslint-disable-next-line
    sendApprovalNotification: ({commit}, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/tools/lea-assignment/${payload.requestId}/send-approval-notification`))
    },

    getResponseDocument: ({
      commit,
      dispatch
    }, payload) => {
      return this._apiService.DownloadInlineWithGet(encodeURI(`${this._baseUrl}api/tools/lea-assignment/${payload.requestId}/get-document/${payload.documentType}?inline=${payload.inline}`))
        .then((data) => {
          commit('setResponseDocument', data)
        })
        .catch(error => {
          if (error.request.status == 404 && payload.createIfMissing) {
            return dispatch('generateResponseDocument', {
              requestId: payload.requestId
            });
          } else {
            return Promise.reject(error);
          }

        })
    },

    getReconfigurationCodes: ({
      commit
    }) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/tools/lea-assignment/reconfiguration-codes`))
        .then(data => commit('setReconfigurationCodes', data))
    },

    getFeederPairs: ({
      commit,
      getters
    }) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/tools/lea-assignment/accountability/feeder-pairs/${getters.fiscalYear}`))
        .then(data => commit('setFeederPairs', data))
    },

    getPairedLEARelationships: ({
      commit,
      getters
    }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/tools/lea-assignment/accountability/feeder-pairs/${getters.fiscalYear}/${payload.schoolLea}`))
        .then(data => commit('setPairedLEARelationships', data))
    },

    getLeaAccountabilityStatusRecords: ({
      commit,
      getters
    }) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/tools/lea-assignment/accountability/lea-accountability-status/${getters.fiscalYear}`))
        .then(data => commit('setLeaAccountabilityStatusRecords', data))
    },

    saveFeederPair: ({
      commit,
      getters
    }, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/tools/lea-assignment/accountability/feeder-pairs/${getters.fiscalYear}`), payload)
        .then(data => commit('setFeederPairs', data))

    },

    deleteFeederPair: ({
      commit,
      getters
    }, payload) => {
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/tools/lea-assignment/accountability/feeder-pairs/${getters.fiscalYear}/${payload.id}`))
        .then(data => commit('setFeederPairs', data))
    },

    saveAccountabilityStatusRecord: ({
      commit,
      getters
    }, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/tools/lea-assignment/accountability/lea-accountability-status/${getters.fiscalYear}`), payload)
        .then(data => commit('setLeaAccountabilityStatusRecords', data))
    },

    deleteAccountabilityStatusRecord: ({
      commit,
      getters
    }, payload) => {
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/tools/lea-assignment/accountability/lea-accountability-status/${getters.fiscalYear}/${payload.id}`))
        .then(data => commit('setLeaAccountabilityStatusRecords', data))
    },

    setRequestItemReconfigurationCode: ({
      commit
    }, payload) => {
      return commit('setCurrentResponseItemConfigurationCode', payload)
    },

    // eslint-disable-next-line
    getLeaPrefixInUse: ({commit, getters}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/tools/lea-assignment/prefix-in-use/${getters.fiscalYear}/${payload.lea}`))
        .then((result) => result.inUse)
    },
 
    setResponseOperationType: ({commit}, type) => {
      return commit('setResponseOperationType', type)
    },

    addRequestItem: ({commit}, item) => {
      return commit('addRequestItem', item)
    },

    removeRequestItem: ({
      commit
    }, key) => {
      return commit('removeRequestItem', key)
    },

  }
}
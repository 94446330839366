<template>
  <span v-if="v">
    <i class="fa-lg" :class="trueClass" />
  </span>
  <span v-else>
    <i class="fa-lg" :class="falseClass" />
  </span>
</template>

<script>
export default {
  name: 'pretty-checkbox',
  props: {
    value: [Boolean, Number],
    trueClass: {
      type: String,
      default: 'fas fa-check-circle text-success'
    },
    falseClass: {
      type: String,
      default: 'far fa-circle text-muted'
    }
  },
  computed: {
    v() { return (this.value || this.value == 1) && this.value != 0; }
  }
}
</script>

<style>

</style>
<template>
  <div class="mt-3">
    <area-coop-district-filter @on-filter-changed="onFilterChanged" v-if="mode=='view'" />
    <paginated-table hover :items="districtTransportationStaff" :fields="fields" v-if="mode=='view'">
      <template v-slot:cell(districtLea)="data">
        <div>
          <span :id="data.index + '-' + data.value">
            {{ data.value }}
          </span>
          <lea-tooltip :target-selector="data.index + '-' + data.value" :lea-number="data.value" />
        </div>
      </template>
      <template v-slot:cell(districtLEA)="data">
        <div>
          <span :id="data.index + '-' + data.value">
            {{ data.value }}
          </span>
          <lea-tooltip :target-selector="data.index + '-' + data.value" :lea-number="data.value" />
        </div>
      </template>
      <template v-slot:cell(inspectorId)="data">
        {{ getInspectorName(data.value) }}
      </template>
      <template v-slot:cell(trainerId)="data">
        {{ getTrainerName(data.value) }}
      </template>
      <template v-slot:cell(action)="data">
        <command-buttons
          :options="{showAdd: false, showEdit: true, showDelete: false, operation:'view'}"
          @on-edit="onRowEdit(data.item)"
        />
      </template>
    </paginated-table>
    
    <edit-staff
      :item="editingItem"
      v-if="mode!='view'"
      @on-cancel="onCancelEdit"
      @on-save="onCommitChange"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CommandButtons from '../../../shared/CommandButtons'
import AreaCoopDistrictFilter from '../../../dpsaft/common/AreaCoopDistrictFilter'
import PaginatedTable from '../../../PaginatedTable'
import EditStaff from './edit-staff'
import { TableMixin } from '../../../TableMixin'

export default {
  name: 'staff',
  mixins: [TableMixin],
  data() {
    return {
      mode: 'view',
      editingItem: null,
      fields: [
        {
          key: 'districtLEA',
          sortable: true
        },
        {
          key: 'districtName',
          sortable: true,
          formatter: this.getDistrictName
        },
        {
          key: 'inspectorId',
          sortable: true
        },
        {
          key: 'trainerId',
          sortable: true
        },
        { key: 'action', thClass: 'text-right' }
      ],
      lastFilters: {
        areaId: null,
        districtLea: null,
        coopLea: null
      }
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState('DPSAFT', ['districtTransportationStaff', 'transportationStaff'])
  },
  methods: {
    onFilterChanged(newFilter) {
      this.lastFilters = {areaId: newFilter.area, districtLea: newFilter.district, coopLea: newFilter.coop}
      this.$store.dispatch('DPSAFT/getDistrictTransportationStaff', this.lastFilters)
    },
    // eslint-disable-next-line no-unused-vars
    getDistrictName(value, key, item) {
      return this.$store.getters['globalModule/leaDictionary'][item.districtLEA]?.name
    },
    getInspectorName(userId) {
      return ((this.transportationStaff.inspectors || []).find(a => a.userId == userId) || { fullName: 'Unassigned' }).fullName
    },
    getTrainerName(userId) {
      return ((this.transportationStaff.trainers || []).find(a => a.userId == userId) || { fullName: 'Unassigned' }).fullName
    },
    onRowEdit(item) {
      this.selectedItem = item
      this.editingItem = Object.assign({}, item)
      this.mode = 'edit'
    },
    onCancelEdit() {
      this.mode = 'view'
      this.editingItem = null
    },
    onCommitChange() {
      if (this.mode == 'edit') {
        this.$store.dispatch('DPSAFT/getDistrictTransportationStaff', this.lastFilters).then(() => (this.mode = 'view'))
      }
    },
  },
  mounted() {
    this.$store.dispatch('DPSAFT/getAreas')
    this.$store.dispatch('DPSAFT/getTransportationStaff')
    this.$store.dispatch('DPSAFT/getDistrictTransportationStaff', {areaId: null, districtLea: null, coopLea: null})
  },
  components: {
    AreaCoopDistrictFilter,
    PaginatedTable,
    CommandButtons,
    EditStaff
  }
}
</script>

<style lang="scss" scoped>
// the ::v-deep makes it so we generate the selector ".table[data-v-5871abb4] th" instead of the selector ".table th[data-v-5871abb4]"
.table ::v-deep th { 
  border-top: none !important;
}
</style>

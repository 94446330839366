<template>
  <div :class="iconClass" />
</template>

<script>
import { PlanCategoryTypes } from '@/modules/esa/shared/enums.js'

export default {
  data() {
    return {
      planCategoryType: PlanCategoryTypes
    }
  },
  computed: {
    iconClass() {
      return (this.planCategoryType.find(t => t.key == this.type) || {}).icon
    }
  },
  props: {
    type: {
      type: Number,
      required: true
    }
  }
}
</script>

<style>

</style>
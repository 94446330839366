<template>
  <paginated-table :items="filteredClosures" :fields="fields" sort-by="createdDate" :sort-desc="true" responsive="lg">
    <template v-slot:cell(districtLea)="data">
      <div>
        <span :id="data.index + '-' + data.value">
          {{ data.value }}
        </span>
        <lea-tooltip :target-selector="data.index + '-' + data.value" :lea-number="data.value" />
      </div>
    </template>
    <template v-slot:cell(createdDate)="data">
      {{ data.value | date }}
    </template>
    <template v-slot:cell(incidentDate)="data">
      {{ data.value | date('MM/DD/YYYY') }}
    </template>
    <template v-slot:cell(updatedDate)="data">
      {{ data.value | date }}
    </template>
    <template v-slot:cell(fullSchoolNames)="data">
      <ul class="list-unstyled">
        <li v-for="i in data.value" :key="i">
          {{ i }}
        </li>
      </ul>
    </template>
    <template v-slot:cell(gradeDisplay)="data">
      <span class="badge badge-primary mr-1" v-for="i in data.value" :key="i">{{ i }}</span>
    </template>
    <template v-slot:cell(superName)="data">
      <a :href="`mailto:${data.item.superEmail}`">
        {{ data.value }}
      </a>
    </template>
    <template v-slot:cell(modificationLevel)="data">
      <span class="badge" :class="data.value.variant">{{ data.value.short }}</span>
    </template>
    <template v-slot:cell(actionColumn)="data">
      <b-button size="sm" variant="primary" :to="{name: 'sct-public-view-closure', params: { id: data.item.id }}">
        <i class="fa fa-fw fa-eye" title="View Closure" />
      </b-button>
    </template>
  </paginated-table>
</template>

<script>
import { mapGetters } from 'vuex'
import PaginatedTable from '../../../PaginatedTable'
import { gradeMap } from '../../../../helpers/gradeSpans'
import { ClosureReasons } from '../enums'
import LeaTooltip from '../../../LeaTooltip'
import moment from 'moment'
import { ChartMixin } from '../../../ChartMixin'

export default {
  data() {
    return {
    }
  },
  mixins: [ChartMixin],
  props: {
    districtLea: String
  },  
  computed: {
    ...mapGetters('core/sctClosures', ['districtClosures']),
    ...mapGetters('globalModule', ['leaDictionary']),
    fields() { 
      let fields = [
        { key: 'districtLea', label: 'District' },
        { key: 'fullSchoolNames', label: 'School(s) Affected' },
        { key: 'modificationReason', label: "Type"},
        { key: 'start', label: 'Start', sortable: true },
        { key: 'end', label: 'End', sortable: true },
        { key: 'gradeDisplay', label: 'Grade(s) Affected' },
        { key: 'numberStudentsAffected', label: 'Students Affected' },
        { key: 'numberStaffAffected', label: 'Staff Affected' },
        { key: 'createdDate', label: 'Reported', sortable: true },
        { key: 'updatedDate', label: 'Modified', sortable: true },
        { key: 'actionColumn', class: 'text-right nowrap', label:'' }
      ]

      return fields
    },
    filteredClosures() {
      let closures = this.districtClosures

      return closures.map(item=> {
        var transformed = Object.assign({}, item)
        const start = moment(item.startDate + 'Z')
        const end = moment(item.endDate + 'Z')

        transformed.schoolLeas = transformed.schoolLeas || []
        transformed.gradeLevels = transformed.gradeLevels || []
        transformed.fullSchoolNames = transformed.schoolLeas.map(this.getSchoolName)
        transformed.gradeDisplay = transformed.gradeLevels.map(g => this.gradeDisplay(g)) 
        transformed.modificationReason = (ClosureReasons[transformed.closureType] || { label: 'Unknown' }).label
        transformed.start = start.format('MM/DD/YYYY')
        if (start.format('HH:mm') != '00:00')
          transformed.start += ' ' + start.format('h:mm a')
        transformed.end = end.format('MM/DD/YYYY')
        if (end.format('HH:mm') != '00:00')
          transformed.end += ' ' + end.format('h:mm a')
        return transformed
      })
    },
  },
  methods: {
    gradeDisplay(code) {
      if (code == 255) return 'All'
      else return gradeMap.get(code)
    },
    getSchoolName(lea) {
      if (lea === 'ALL') return 'Entire District'
      if (this.leaDictionary[lea]) return `${this.leaDictionary[lea].name} (${lea})`
      return lea
    }, 
    // excelExportData() {
    //   return { name: 'Closures', items: this.filteredClosures.map(c => { 
    //     const fields = {
    //       'ID': c.id,
    //       'District': this.leaDictionary[c.districtLea] ? this.leaDictionary[c.districtLea].name + ' (' + c.districtLea + ')' : c.districtLea,
    //       'School(s) Affected': c.fullSchoolNames.join('; '),
    //       'Type': ClosureReasons[c.closureType].label,
    //       'Superintendent Name': c.superName,
    //       'Superintendent E-mail': c.superEmail,
    //       'Incident Date': new Date(c.incidentDate).toLocaleDateString('en-US', {month: '2-digit', day: '2-digit', year: 'numeric'}),
    //       'Grade(s) Affected': c.gradeDisplay.join('; '),
    //       '# Students Affected': c.numberStudentsAffected,
    //       '# Staff Affected': c.numberStaffAffected,
    //       'Length': c.intendedClosureLength,
    //       'Is AMI Day': c.isAMIDay,
    //       'Will Be Made Up': c.willBeMadeUp,
    //       'District Phone': c.districtPhone,
    //       'Reported Date': new Date(c.createdDate),
    //       'Updated Date': new Date(c.updatedDate),
    //       'Updated By': c.updatedBy,
    //       'Superintendent Comments': c.superintendentComments,
    //       'ADE Comments': c.adeExternalComments,
    //     }

    //     if (this.mode == 'admin') {
    //       fields['ADE Knowledge Base'] = c.adeInternalComments
    //     }

    //     return fields
    //   })}
    // },
  },
  components: {
    PaginatedTable,
    LeaTooltip,
  },    
}
</script>

<style>
  .nowrap {
    white-space: nowrap;
  }
</style>
<template>
  <div class="card">
    <div class="card-header">
      Comment / Activity History
    </div>
    <div class="card-body">
      <div
        v-for="c in filteredComments"
        :key="c.requestCommentId"
        class="text-info mb-2 comment-container"
      >
        <div class="row small">
          <div class="col-md-6 strong">
            From: {{ c.createdBy }}
          </div>
          <div class="col-md-6 text-right">
            {{ formatDate(c.createdDate) }}
          </div>
        </div>
        {{ c.text }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  name: 'comments-list-widget',
  data() {
    return {
      comments: []
    }
  },
  components: {},
  mounted() {},
  computed: {
    ...mapState('globalModule', ['userContext']),
    filteredComments() {
      if (this.userContext.isADEUser) {
        return this.request.requestComments
      } else {
        return this.request.requestComments.filter(c=>c.visibility==0)
      }
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format('MM/DD/YYYY h:mm a')
    }
  },
  props: {
    request: Object
  }
}
</script>

<style lang="scss">
.comment-container:not(:nth-child(1)) {
  border-top: solid 1px darkgray;
}
</style>
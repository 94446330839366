<template>
  <div>
    <b-alert :show="ActiveRoute != null" v-if="ActiveRoute">
      <b-form v-if="ActiveRoute">
        <b-form-row>
          <b-col>
            <label class="font-weight-bold">Routed By</label>
            <div v-if="ActiveRoute.routedByUserInfo">
              {{ ActiveRoute.routedByUserInfo.fullName }}<br>
              <a :href="`mailto:${ActiveRoute.routedByUserInfo.emailAddress}`">{{ ActiveRoute.routedByUserInfo.emailAddress }}</a><br>
              {{ ActiveRoute.routedByUserInfo.telephoneNumber }}
            </div>
            <div v-else>
              {{ ActiveRoute.routedBy }}
            </div>
          </b-col>
          <b-col cols="8">
            <label class="font-weight-bold">Comment</label>
            <div>
              {{ ActiveRoute.comment || "(no comment provided)" }}
            </div>
          </b-col>
          <b-col class="text-right">
            <b-button @click="closeRoute" variant="primary">
              <i class="fa fa-times fa-fw" />Close Route
            </b-button>
          </b-col>
        </b-form-row>
      </b-form>
    </b-alert>

    <h3>{{ SelectedBatch.districtName }} ({{ SelectedBatch.districtLea }}) </h3>
    <h4>Inspection Batch: {{ SelectedBatch.batchId }}</h4>

    <b-card no-body>
      <b-tabs card content-class="mt-3">
        <b-tab title="Building Inspection Details" active>
          <b-card-text>
            <b-form>
              <div class="form-row">
                <div class="col-6">
                  <b-form-radio-group v-model="selectedType" buttons>
                    <b-form-radio button-variant="primary" value="allInspections">
                      All Buildings <span class="badge bg-white text-primary">{{ allInspections.length }}</span>
                    </b-form-radio>
                    <b-form-radio button-variant="success" value="SelectedBatchBuildingsNoIssues">
                      No Issues <span class="badge bg-white text-success">{{ SelectedBatchBuildingsNoIssues.length }}</span>
                    </b-form-radio>
                    <b-form-radio button-variant="warning" value="SelectedBatchBuildingsWithIssues">
                      With Issues <span class="badge bg-white text-warning"> {{ SelectedBatchBuildingsWithIssues.length }}</span>
                    </b-form-radio>
                  </b-form-radio-group>
                </div>
                <div class="col-6 text-right">
                  <h3>Status: <span class="badge" :class="batchStatusDisplay.badgeClass">{{ batchStatusDisplay.text }}</span></h3>
                </div>
              </div>
              <b-form-group>
                <label class="font-weight-bold">Building</label>
                <select class="form-control" v-model="selectedInspectionId">
                  <option v-for="i in selectedInspections" :key="i.inspectionId" :style="optionStyle(i)" :value="i.inspectionId">
                    {{ optionTextValue (i) }}
                  </option>
                </select>
              </b-form-group>
            </b-form>
            <div v-if="SelectedInspection && SelectedInspection.inspectionId">
              <inspection :inspection="SelectedInspection" key="facilities-inspection" :readonly="readonly" />
            </div>
          </b-card-text>
        </b-tab>
        <b-tab title="Listing">
          <batch-building-listing />
        </b-tab>
        <b-tab title="Workflow / History">
          <div>
            <div class="text-right mb-2">
              <animated-button button-class="btn btn-success" :promise-resolver="exportBatch" :disabled="false" v-if="SelectedBatch.reviewStatus < FacilitiesBatchReviewStatus.PendingDistrictFacilitiesManagerReview">
                <i class="far fa-file-excel" />&nbsp; {{ downloadButtonCaption }}
              </animated-button>
              <b-button variant="success" :href="'/facilities/' + SelectedBatch.batchId + '/final-report'" class="mr-2" v-else>
                <i class="fas fa-download fa-fw" /> {{ downloadButtonCaption }}
              </b-button>
            </div>

            <div v-if="SelectedBatch.status == InspectionBatchStatus.Completed">
              <div v-if="SelectedBatch.reviewStatus == FacilitiesBatchReviewStatus.NotSet">
                <b-card header="SPM-M Review Required" title="'Fix' issues require SPM-M review" v-if="SelectedBatchBuildingsWithIssues.length > 0" header-bg-variant="danger" header-text-variant="white">
                  <b-form>
                    <b-form-row>
                      All bullding inspections are completed, but there are one or more issues noted so the SPM-M must review the inspection prior to releasing it to the district.
                    </b-form-row>
                    <b-form-row>
                      <label class="mt-2 font-weight-bold">Enter any additional comments or information about the inspection below:</label>
                      <b-textarea v-model="workflowComments" class="my-1" />
                    </b-form-row>
                    <b-form-row class="text-center">
                      <b-button @click="routeBatch(FacilitiesBatchReviewStatus.PendingSeniorProjectManagerReview)" variant="primary">
                        Route to SPM-M for Review
                      </b-button>
                    </b-form-row>
                  </b-form>
                </b-card> 

                <b-card header="Ready for District Review" v-if="SelectedBatchBuildingsWithIssues.length == 0" header-bg-variant="success" header-text-variant="white">
                  <b-form>
                    <b-form-row>
                      All bullding inspections are completed. No issues were noted so you may now route it to the district for review.
                    </b-form-row>
                    <b-form-row>
                      <label class="mt-2 font-weight-bold">Enter any additional comments or information about the inspection below:</label>
                      <b-textarea v-model="workflowComments" class="my-1" />
                    </b-form-row>
                    <b-form-row class="text-center">
                      <b-button @click="routeBatch(FacilitiesBatchReviewStatus.PendingDistrictFacilitiesManagerReview)" variant="primary">
                        Route to District for Review
                      </b-button>
                    </b-form-row>
                  </b-form>
                </b-card>
              </div>

              <b-card header="Pending SPM-M Review" title="Inspection release pending SPM-M review of 'Fix' issues" v-if="SelectedBatch.reviewStatus == FacilitiesBatchReviewStatus.PendingSeniorProjectManagerReview" header-bg-variant="danger" header-text-variant="white">
                <b-form v-if="userContext.hasRole('Insights_DPSAFT_Senior_Project_Manager_Maintenance')">
                  <b-form-row>
                    All bullding inspections are completed, but there are one or more issues noted.  You may either release the batch to the district or request additional actions/input from the APM-M.
                  </b-form-row>
                  <b-form-row>
                    <label class="mt-2 font-weight-bold">Enter any additional comments or information about the inspection below:</label>
                    <b-textarea v-model="workflowComments" class="my-1" />
                  </b-form-row>
                  <b-form-row>
                    <b-col class="text-right">
                      <b-button @click="routeBatch(FacilitiesBatchReviewStatus.PendingDistrictFacilitiesManagerReview)" variant="primary" class="mr-2">
                        Release to District
                      </b-button>
                      <b-button @click="routeBatch(FacilitiesBatchReviewStatus.PendingAreaProjectManagerAction)" variant="warning">
                        Return to APM-M for Information
                      </b-button>
                    </b-col>
                  </b-form-row>
                </b-form>
                <div v-else>
                  This batch is currently pending review by the Supervisor Project Manager - Maintenance
                </div>
              </b-card> 

              <b-card header="Additional Information/Actions Requested" title="Additional information requested/instructions given by the SPM-M" header-bg-variant="warning" header-text-variant="dark" v-if="SelectedBatch.reviewStatus == FacilitiesBatchReviewStatus.PendingAreaProjectManagerAction">
                <b-form v-if="userContext.hasRole('Insights_DPSAFT_Facilities_Area_Managers_Maintenance')">
                  <b-form-row>
                    The batch has been returned to the APM-M for more information. 
                  </b-form-row>
                  <b-form-row>
                    <b-col>
                      <label class="mt-2 font-weight-bold">Enter any additional comments or information below:</label>
                      <b-textarea v-model="workflowComments" class="my-1" />
                    </b-col>
                  </b-form-row>
                  <b-form-row>
                    <b-col class="text-right">
                      <b-button @click="routeBatch(FacilitiesBatchReviewStatus.PendingSeniorProjectManagerReview)" variant="primary">
                        Return to SPM-M
                      </b-button>
                    </b-col>
                  </b-form-row>
                </b-form>
                <div v-else>
                  This batch is currently pending additional information from the Area Project Manager - Maintenance
                </div>
              </b-card> 

              <b-card header="Inspection(s) Complete - Pending District Acknowledgement" title="Inspection Complete - Please Acknowledge" header-bg-variant="warning" header-text-variant="dark" v-if="SelectedBatch.reviewStatus == FacilitiesBatchReviewStatus.PendingDistrictFacilitiesManagerReview">
                <b-form v-if="userContext.hasCoordinatorRoleForLEA('0', SelectedBatch.districtLea) || userContext.hasCoordinatorRoleForLEA('U', SelectedBatch.districtLea)">
                  <b-form-row>
                    The associated building inspections have been completed for the district.  Please acknowledge your receipt of the inspections below. 
                  </b-form-row>
                  <b-form-row>
                    <b-col class="text-right">
                      <b-button @click="routeBatch(FacilitiesBatchReviewStatus.AcknowlegedByDistrict)" variant="primary">
                        Acknowledge Receipt
                      </b-button>
                    </b-col>
                  </b-form-row>
                </b-form>
                <div v-else>
                  This batch is currently pending acknowledgement by the District Facilities Manager
                </div>
              </b-card> 

              <b-card header="Inspection(s) Completed and Acknowledged" title="Inspection Acknowledged" sub-title="No further actions required" header-bg-variant="success" header-text-variant="white" v-if="SelectedBatch.reviewStatus == FacilitiesBatchReviewStatus.AcknowlegedByDistrict">
                <div>
                  <b-row>
                    <b-col cols="8">
                      The associated building inspections have been completed for the district.
                    </b-col>
                  </b-row>
                </div>
              </b-card> 
            </div>
            <b-alert variant="warning" v-else show>
              The building inspections on this batch have not yet been completed.  After all buldings have been completed, you willbe able to either release the batch to the district (provided no issues are noted) or send the inspection to the SPM-M for review.
            </b-alert>
          </div>

          <h3 class="mt-3">
            Workflow History
          </h3>
          <div v-if="routeHistoryItems.length > 0">
            <b-table-lite small :items="routeHistoryItems" :fields="routeHistoryFields">
              <template v-slot:cell(reviewStatus)="data">
                <span class="badge" :class="data.item.rowBadge.badgeClass">{{ data.item.rowBadge.text }}</span>
              </template>
              <template v-slot:cell(routedBy)="data">
                <span v-if="data.item.routedByUserInfo" :title="`Phone: ${data.item.routedByUserInfo.telephoneNumber}`"><a :href="`mailto:${data.item.routedByUserInfo.emailAddress}`">{{ data.item.routedByUserInfo.fullName }}</a></span>
                <span v-else>{{ data.value }}</span>
              </template>
              <template v-slot:cell(routedDate)="data">
                {{ data.value | date }}
              </template>
              <template v-slot:cell(routeClosedDate)="data">
                {{ data.value | date }}
              </template> -->
            </b-table-lite>
          </div>
          <div v-else>
            This batch has not yet undergone any workflow transitions
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BatchBuildingListing from './BatchBuildingListing'
import Inspection from '../inspect/Inspection'
import { Types } from '../../../../stores/dpsaft/facilities'
import AnimatedButton from '../../../AnimatedButton'
import { InspectionBatchStatus, InspectionStatuses, RatingTypes, FacilitiesBatchReviewStatus } from '../../common/Enums'
import store from '../../../../stores'

export default {
  beforeRouteEnter(to, from, next) {

    store.dispatch(Types.actions.clearActiveRoute)

    if (to.query.routeId) {
      store.dispatch(Types.actions.setActiveRoute, { routeId: to.query.routeId})
    }
    
    next()
  },
  data() {
    return {
      InspectionBatchStatus,
      FacilitiesBatchReviewStatus,
      selectedType: 'allInspections',
      comments: null,
      actionPromise: null,
      workflowComments: null,
      routeHistoryFields: [
        {key: 'routedBy' },
        {key: 'routedDate' },
        {key: 'reviewStatus' },
        {key: 'comment' },
        {key: 'routeClosedDate' },
        {key: 'routeClosedBy' },
      ]
    }
  },
  props: {
    mode: {
      type: String,
      default: 'view'
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapGetters('globalModule', ['leaDictionary']),
    ...mapState(Types.path, [Types.state.SelectedBatch, Types.state.SelectedInspection, Types.state.ActiveRoute, Types.state.SelectedBatchRouteHistory]),
    ...mapGetters(Types.path, [Types.getters.SelectedBatchBuildingList, Types.getters.SelectedBatchBuildingsNoIssues, Types.getters.SelectedBatchBuildingsWithIssues]),
    allInspections() {
      return this.SelectedBatch.inspections
    },
    selectedInspections() {
      return this[this.selectedType].map(i => Object.assign(i, { textValue: `${i.building.buildingNumber} - ${i.building.buildingName}`}))
    },
    selectedInspectionId: {
      set(inspectionId) {
        this.$store.commit(Types.mutations.setSelectedInspection, this.selectedInspections.find(f => f.inspectionId == inspectionId))
      },
      get() {
        return this.SelectedInspection.inspectionId || 0
      }
    },
    isDirty() {
      return this.comments != this.SelectedBatch.comments
    },
    batchStatusDisplay() {
      return InspectionBatchStatus.DisplayOptions(this.SelectedBatch.status)
    },
    isBatchComplete() {
      return this.SelectedBatch.status == InspectionBatchStatus.Completed
    },
    mayUserPerformDistrictAcknowledgement() {
      console.log(this.userContext.coordinatorCodes)
      return this.userContext.hasCoordinatorCode("U") || this.userContext.hasCoordinatorCode("X")
    },
    mayUserPerformDistrictNotification() {
      return this.userContext.hasRole("Insights_DPSAFT_Administrators") || this.userContext.hasRole("Insights_DPSAFT_Facilities_Area_Managers_Maintenance")
    },
    statusTransitionHistory() {
      return [
        { action: 'Created', by: this.SelectedBatch.createdBy, date: this.SelectedBatch.createDate },
        { action: 'Completed', by: this.SelectedBatch.completedBy, date: this.SelectedBatch.completedDate },
        { action: 'Released to District', by: this.SelectedBatch.districtNotifiedBy, date: this.SelectedBatch.districtNotifiedDate },
        { action: 'Acknowledged by District', by: this.SelectedBatch.districtAcknowledgedBy, date: this.SelectedBatch.districtAcknowledgedDate },
      ]
    },
    downloadButtonCaption() {
      return this.SelectedBatch.reviewStatus >= FacilitiesBatchReviewStatus.PendingDistrictFacilitiesManagerReview ? "Download Final Report" : "Download Preliminary Report"
    },
    routeHistoryItems() {
      return this.SelectedBatchRouteHistory.map((i)=>Object.assign({...i}, {rowBadge: this.FacilitiesBatchReviewStatus.DisplayOptions(i.reviewStatus)}))
    }
  },
  methods: {
    optionTextValue(i) {
      return `${i.building.buildingNumber} - ${i.building.buildingName}`
    },
    optionStyle(i) {
      var fw = "normal"
      var color = "black"
      
      if (i.status == InspectionStatuses.Completed) {
        fw="bold"
        if (i.components.some(c => c.rating == RatingTypes.Fix)) {
          color="#ffc107"
        } else {
          color = "green"
        }
      }

      return `font-weight:${fw};color:${color};`
    },
    sendNotification() {
      this.$store.dispatch(Types.actions.notifyDistrict, { batchId: this.$route.params.batchId, comments: this.comments })
    },

    acknowledgeInspection() {
      this.$store.dispatch(Types.actions.districtAcknowledge, { batchId: this.$route.params.batchId })
    },

    setSelectedInspectionGroup(inspectionGroup) {
      this.selectedInspectionGroup = inspectionGroup
      if (this.selectedInspections && this.selectedInspections.length > 0)
        this.selectedInspection = this.selectedInspections[0]
    },
    exportBatch() {
      this.actionPromise = this.$store.dispatch(Types.actions.exportBatch, {
        batchId: this.$route.params.batchId,
        fileName: `${this.SelectedBatch.districtName}_facilities_${this.$route.params.batchId}.xlsx`
      })

      return this.actionPromise
    },
    save() {
      this.$store.dispatch(Types.actions.saveBatchComment, {
        batchId: this.$route.params.batchId, comments: this.comments
      })
    },
    cancel() {
      this.comments = {...this.SelectedBatch.comments}
    },
    routeBatch(targetReviewStatus) {
      if (targetReviewStatus == FacilitiesBatchReviewStatus.PendingDistrictFacilitiesManagerReview) {
        this.$bvModal.msgBoxConfirm('Are you sure you want to release this batch to the district?')
        .then(result=>{
          if (result) {
            this.$store.dispatch(Types.actions.routeInspectionBatch, { batchId: this.SelectedBatch.batchId, targetReviewStatus: targetReviewStatus, comment: this.workflowComments })
            .then(() => this.SelectedBatch.reviewStatus = targetReviewStatus)
          }
        })
      } else {
        this.$store.dispatch(Types.actions.routeInspectionBatch, { batchId: this.SelectedBatch.batchId, targetReviewStatus: targetReviewStatus, comment: this.workflowComments })
        .then(() => this.SelectedBatch.reviewStatus = targetReviewStatus)
      }
    },
    closeRoute() {
      this.$bvModal.msgBoxConfirm('This will remove the route from your Inbox. Please confirm.')
      .then(result => {
        if (result) {
          this.$store.dispatch(Types.actions.closeRoute, { routeId: this.ActiveRoute.id })
          .then(() => {
              this.$store.dispatch(Types.actions.clearActiveRoute)
            })
          }
      }) 
    }   
  },
  watch: {
    selectedInspection(inspection) {
      this.$store.commit(Types.mutations.setSelectedInspection, inspection)
    },
    SelectedBatch(batch){
      this.comments = batch.comments
    }
  },
  mounted() {
    if (this.$route.query.process == 'start') {
      this.$store.dispatch(Types.actions.updateBatchStatus, { batchId: this.$route.params.batchId, status: InspectionBatchStatus.InProgress })
    } else {
      this.$store.dispatch(Types.actions.getBatch, { batchId: this.$route.params.batchId })
    }
  },
  components: {
    BatchBuildingListing,
    Inspection,
    AnimatedButton
  }
}
</script>
<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
  import { mapState } from 'vuex';
  import { ChartMixin } from '@/components/ChartMixin'
  import { credits } from '@/helpers/credits'

  export default {
    mixins: [ChartMixin],
    data() {
      return {
      }
    },
    computed: {
      ...mapState('hcptModule', ['workforceStabilityIndex2']),
      ...mapState('globalModule', ['leaContext']),
      stateAverageYOE() {
        return this.workforceStabilityIndex2.averageYearsOfExperience.reduce((a, b) => a + b.avg, 0) / this.workforceStabilityIndex2.averageYearsOfExperience.length
      },
      stateAveragePct() {
        return (this.workforceStabilityIndex2.averageYearsOfExperience.reduce((a, b) => a + b.pct, 0) / this.workforceStabilityIndex2.averageYearsOfExperience.length) * 100.0
      },
      chartOptions() {
        return {
          plotOptions: {
            series: {
              turboThreshold: 1500
            }
          },
          credits: credits.MSI,
          title: {
            text: 'Average YOE vs Percent of Teachers >1 YOE'
          },
          xAxis: [
            {
              title: {
                text: 'Average Years of Experience'
              },
              min: 0,
              tickInterval: 3,
              plotLines: [{
                color: '#008000',
                width: 2,
                dashStyle: 'shortdot',
                value: this.stateAverageYOE,
                zIndex: 1,
              }],
              max: 24
            }
          ],
          yAxis: {
            title: {
              text: 'Percent of Teachers >1 Year of Experience'
            },
            min: 0,
            max: 100,
            labels: {
              formatter: function () {
                return this.value + '%';
              }
            },
            plotLines: [{
                color: '#008000',
                width: 2,
                dashStyle: 'shortdot',
                value: this.stateAveragePct,
                zIndex: 1,
              }]
          },
          tooltip: {
            enabled: true,
            crosshairs: [true, true],
            formatter: function () {
              return '<b>' + this.point.lea + '</b><br/>' +
                this.x.toFixed(1) + ' years of experience<br/>' +
                this.y.toFixed(1) + '% of teachers have 1 or more years of experience';
            }
          },
          chart: {
            marginTop: 70,
            marginRight: 20,
            marginBottom: 120,
            marginLeft: 90
          },
          series: this.chartData,
        }   
      },
      chartData() {
        const schools = []

        const data = (this.workforceStabilityIndex2.averageYearsOfExperience || []).map((lea) => {
          const foundLea = this.workforceStabilityIndex2.leaValues.find((l) => l.lea === lea.lea)         
          
          if (foundLea !== undefined) {
            schools.push({
              x: lea.avg,
              y: lea.pct * 100.0,
              lea: this.formatLea(lea.lea),
              leaNumber: lea.lea,
            })
            return null
          }
          else {
            return {
              x: lea.avg,
              y: lea.pct * 100.0,
              lea: this.formatLea(lea.lea),
              leaNumber: lea.lea              
            }
          }
        })

        return [
          {
            name: 'All Schools in Arkansas',
            type: 'scatter',          
            data: data,
            showInLegend: true,
            marker: {
              fillColor: 'rgba(124, 181, 236, 0.5)',
              lineWidth: 1,
              lineColor: 'rgba(124, 181, 236, 0.75)',
              zIndex: 0
            },
          },
          {
            name: 'Selected Schools',
            type: 'scatter',
            data: schools,
            showInLegend: true,
            marker: {
              fillColor: 'rgba(255, 0, 0, 0.5)',
              lineWidth: 1,
              lineColor: 'rgba(255, 0, 0, 0.75)',
              zIndex: 100
            },
          },
          {
            name: 'State Average',
            type: 'scatter',
            data: [{
              x: this.stateAverageYOE,
              y: this.stateAveragePct,
              lea: 'State Average',
              leaNumber: 0
            }],
            showInLegend: true,
            marker: {
              fillColor: '#008000',
              lineWidth: 1,
              lineColor: 'rgba(0, 0, 0, 0.75)',
              zIndex: 100
            },
          }
        ]
      },
    },
    methods: {
      dataItems() {
        const data = this.chartData[0].data.concat(this.chartData[1].data).filter((item) => { return item !== null })
        return data.sort((a, b) => { return a.leaNumber - b.leaNumber }).map((item) => {
          return {
            'LEA': item.lea,
            'Average Years of Experience': item.x.toFixed(1),
            'Percent of Teachers >1 Year of Experience': item.y.toFixed(1) + '%'
          }
        })
      },
      formatLea(leaNumber) {
        var lea = this.leaContext.LeaDictionary[leaNumber]
        return `${lea.name} (${lea.number})`
      },
    }
  }
</script>
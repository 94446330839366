<template lang="html">
  <div class="growth-trended-scores">
    <div><student-trend-line :scores="scores" :value-property="valueProperty" /></div>
    <ul>
      <li v-for="score in scores" :key="score.schoolYear">
        <strong>{{ score.schoolYear }}:</strong> {{ score[valueProperty] }} <sign-indicator :value="score[valueProperty]" v-if="showSignIndicator" />
      </li>
    </ul>
  </div>
</template>

<script>
import StudentTrendLine from './StudentTrendLine'
import SignIndicator from '../../SignIndicator'

export default {
  name: 'trended-scores',
  data() {
    return {}
  },
  components: {
    StudentTrendLine,
    SignIndicator
  },
  props: {
    scores: Object,
    valueProperty: String,
    showSignIndicator: Boolean
  }
}
</script>
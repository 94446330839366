// school communication tool
import schoolCommunicationToolPage from './sct/schoolCommunicationToolPage.vue'
import schoolCommunicationToolPublicPage from './sct/public/schoolCommunicationToolPublicPage.vue'
import schoolCommunicationToolSidebar from './sct/schoolCommunicationToolSidebar.vue'

// curriculum transparency
import ctPage from './curriculumTransparency/ctPage.vue'
import ctSidebar from './curriculumTransparency/ctSidebar.vue'

const CoreArea = {
    schoolCommunicationToolPage,
    schoolCommunicationToolPublicPage,
    schoolCommunicationToolSidebar,

    ctPage,
    ctSidebar
}

export { CoreArea }

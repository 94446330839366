<template>
  <div>
    <loadable-chart-card :load-data="loadData" card-style="overflow-y: auto">
      <template v-slot:default="slotProps">
        <div class="chartElem" v-show="slotProps.chartMode">
          <highcharts class="chart" :options="chartOptions" />
        </div>
        <div v-show="!slotProps.chartMode">
          <chart-data-table :items="dataItems()">
            <template v-slot:head(teacherDays)="data">
              <div>
                <custom-tooltip content="The product of Total Teacher Count x Instructional Days" :element-id="`days-${data.label}`" :label="data.label" />
              </div>
            </template>
            <template v-slot:head(totalTeacherCount)="data">
              <div>
                <custom-tooltip content="Total Number of Teachers in the LEA" :element-id="`days-${data.label}`" :label="data.label" />
              </div>
            </template>
            <template v-slot:head(numberOfTeachersTakingLeave)="data">
              <div>
                <custom-tooltip
                  content="The number of unique teachers taking the specified type of leave"
                  :element-id="`days-${data.label}`"
                  :label="data.label"
                />
              </div>
            </template>
            <template v-slot:head(leaveDays)="data">
              <div>
                <custom-tooltip
                  content="The total number of days taken for the specified type of leave"
                  :element-id="`days-${data.label}`"
                  :label="data.label"
                />
              </div>
            </template>
            <template v-slot:head(percentOfTotalLeave)="data">
              <div>
                <custom-tooltip
                  content="The weight this type of leave factored into the total amount of leave taken across the LEA"
                  :element-id="`days-${data.label}`"
                  :label="data.label"
                />
              </div>
            </template>
            <template v-slot:head(absenteeRate)="data">
              <div>
                <custom-tooltip content="Leave Days / Teacher Days x 100" :element-id="`days-${data.label}`" :label="data.label" />
              </div>
            </template>
          </chart-data-table>
        </div>
      </template>
    </loadable-chart-card>
  </div>
</template>

<script>
import LoadableChartCard from '../../LoadableChartCard'
import { ChartMixin } from '../../ChartMixin'
import { credits } from '../../../helpers/credits'

export default {
  name: 'employee-leave-by-days-chart',
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'column',
          height: this.$printMode ? 650 : 400
        },
        legend: {
          maxHeight: this.$printMode ? 450 : 200
        },
        credits: credits.SIS,
        title: {
          text: 'Employee Leave Days'
        },
        plotOptions: {
          column: {
            stacking: 'normal'
          }
        },
        series: [],
        xAxis: {
          title: {
            text: 'Leave Type'
          },
          type: 'category'
        },
        yAxis: {
          title: {
            text: 'Leave Days'
          },
          endOnTick: false
        }
      }
    }
  },
  components: {
    LoadableChartCard
  },
  mixins: [ChartMixin],
  methods: {
    dataItems() {
      return this.leaveData.flatMap(item => {
        return item.rows.map(row => {
          return this.stateView == 1
            ? {
                scopeLEA: row.scopeLEA,
                name: row.name,
                leaveCode: row.leaveCode,
                leaveDescription: row.leaveDescription,
                teacherDays: row.teacherDays,
                totalTeacherCount: row.totalLEATeacherCount,
                numberOfTeachersTakingLeave: row.numberOfTeachersTakingLeave,
                leaveDays: row.leaveDays,
                leaCategory: row.leaCategory == 1 ? 'Charter' : 'Regular'
              }
            : {
                scopeLEA: row.scopeLEA,
                name: row.name,
                leaveCode: row.leaveCode,
                leaveDescription: row.leaveDescription,
                teacherDays: row.teacherDays,
                totalTeacherCount: row.totalLEATeacherCount,
                numberOfTeachersTakingLeave: row.numberOfTeachersTakingLeave,
                leaveDays: row.leaveDays
              }
        })
      })
    }
  },
  props: {
    loadData: Promise,
    leaveData: Array,
    chartTitle: String,
    viewType: String,
    stateView: String,
    leaveType: String
  },
  watch: {
    leaveData(newData) {
      this.chartOptions.series = []
      for (var i = 0; i < newData.length; i++) {
        var seriesData = newData[i].rows.map(row => {
          return {
            name: `(${row['leaveCode']}) - ${row['leaveDescription']}`,
            y: row['leaveDays']
          }
        })
        this.chartOptions.series.push({
          data: seriesData,
          name: `${newData[i].name} ${this.viewType == 0 && this.stateView == 1 ? (newData[i].rows[0].leaCategory == 1 ? ' - Charter' : ' - Regular') : ''}`,
          stack: this.viewType == 1 ? newData[i].name : newData[i].rows[0].leaCategory == 1 ? 'Charter' : 'Regular'
        })
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="card mt-3 bg-light">
      <div class="card-header">
        Users
      </div>
      <div class="card-body">
        <loadable-chart-card :load-data="loadData" :enable-data-toggle="false" card-style="overflow-y: hidden">
          <career-coach-user-table :items="users" />
        </loadable-chart-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CareerCoachUserTable from './CareerCoachUserTable.vue'
import { Types } from '@/modules/cte/stores/careercoach'
import LoadableChartCard from '@/components/LoadableChartCard.vue'

export default {
  data() {
    return {
      loadData: Promise.resolve(true)
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.users]),
  },
  components: {
    CareerCoachUserTable,
    LoadableChartCard,
  },
  mounted() {
    this.loadData = this.$store.dispatch(Types.actions.getUsers, {})
  }
}
</script>

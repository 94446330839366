import Vue from 'vue'
import { PageMixin } from '../../PageMixin'
import { EthicsViolationDetailsWidget } from './EthicsViolationDetailsWidget'

const EthicsViolationPage = Vue.extend({
  name: 'ethics-violation-page',
  mixins: [PageMixin],
  components: {
    EthicsViolationDetailsWidget
  },
  data() {
    return {
      loadData: null
    }
  },
  methods: {
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if (newLea && newLea.number) {
          this.loadData = this.$store.dispatch('hcptModule/getEthicsViolations', { lea: newLea.number })
        }
      }
    }
  }
})
export { EthicsViolationPage }

<template>
  <b-form>
    <b-form-group label="Indicator Code:" :disabled="mode == 'edit'">
      <b-form-input v-model="editItem.indicatorCode" maxlength="5" />
    </b-form-group>
    <!-- <b-form-group label="Name:">
      <b-form-input v-model="editItem.name" />
    </b-form-group> -->
    <b-form-group label="Description:">
      <b-form-input v-model="editItem.description" />
    </b-form-group>
    <b-button type="button" @click.prevent="save" variant="primary" class="mr-2">
      Save
    </b-button>
    <b-button type="button" @click.prevent="cancel" variant="danger">
      Cancel
    </b-button>
  </b-form>
</template>

<script>
import { mapState } from 'vuex'
import { Types } from '@/modules/cte/stores/perkinsv'

export default {
  computed: {
    ...mapState(Types.path, [Types.state.indicators]),
    editItem() {
      return this.indicatorId ?
        this.indicators.filter(i => i.perkinsIndicatorId == this.indicatorId)[0] :
        {
          academicYear: 0,
          indicatorCode: null,
          name: null,
          description: null
        }
    }
  },
  props: {
    indicatorId: {
      type: String,
      default: null
    },
    mode: {
      type: String,
      default: 'edit'
    }
  },
  methods:{
    save(){
      if(this.mode == 'edit'){
        this.$store.dispatch(Types.actions.updateIndicator, { indicatorId: this.indicatorId, indicator: this.editItem })
      }
      else{
        this.$store.dispatch(Types.actions.createIndicator, { indicator: this.editItem })
      }
      this.$router.go(-1)
    },
    cancel(){
      this.$router.go(-1)
    }
  }
}
</script>
<template>
  <div class="form-group row" v-if="userContext.hasRole('ADE User')">
    <label class="col-3 mb-3 mt-1">Reconfiguration Code:</label>
    <div class="col-md-9 p-0">
      <b-form-select
        class="form-control"
        v-model="selectedCode"
        required
        :options="codeOptions"
        :disabled="!canChangeCodeOption"
        @change="onChangeReconfigurationCode" />
      <div class="d-none alert bg-info">
        {{ crosswalkRule }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'reconfiguration-code-selector',
  data() {
    return {
      mounted: false,
      selectedCode: null
    }
  },
  mounted() {
    console.log(`mounting with item.reconfigurationCode = ${this.item.reconfigurationCode}`)
    this.selectedCode = this.item.reconfigurationCode

    if ((!this.selectedCode) && this.codeOptions.length == 1) {
      this.selectedCode = this.codeOptions[0].value //a code wasn't provided, but if there is only one to choose from based on the options available, default to it
      this.onChangeReconfigurationCode()
    }
    
    console.log(`mounting with this.selectedCode = ${this.selectedCode}`)
  },
  methods: {
    onChangeReconfigurationCode() {
      this.$store.dispatch('toolsModule/leaAssignment/setRequestItemReconfigurationCode', { item: this.item, reconfigurationCode: this.selectedCode })
    }
  },
  computed: {
    ...mapState('globalModule', ['leaContext', 'userContext']),
    ...mapState('toolsModule/leaAssignment', ['reconfigurationCodes']),
    ...mapState('toolsModule/leaAssignment', ['currentRequest']),
    ...mapGetters('toolsModule/leaAssignment', ['reconfigurationOptions']),
    codeOptions() {
      return this.reconfigurationOptions(this.purpose, this.operation)
    },
    crosswalkRule() {
      var item = this.reconfigurationCodes.find(i => i.code === this.selectedCode)
      return item ? item.crosswalkRule : 'N/A'
    },
    canChangeCodeOption() {
      if (this.currentRequest.isApprovedRejectedOrCanceled) return false
      if (
        this.currentRequest.isSubmitted &&
        (this.userContext.hasRole('Insights_RT-SMS_Administrators') || this.userContext.hasRole('Insights_PSA_Administrators'))
      ) {
        return true
      } else {
        return this.currentRequest.isDraftOrPendingInformation
      }
    }
  },
  watch: {
    "item.reconfigurationCode": {
      handler(newCode) {
        this.selectedCode = newCode
      }
    }
  },
  props: {
    request: Object,
    purpose: Number,
    item: Object,
    operation: Number
  }
}
</script>

<template>
  <div>
    <div v-if="pageState.mode == 'main'">
      <Promised :promise="loadData">
        <template v-slot:pending>
          <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
            <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
          </div>
        </template>
        <template v-slot="data">
          <span class="d-none">{{ data }}</span>
          <form-wizard @on-complete="postBatch" title="New Facilities Inspection Batch" subtitle="" color="#930f2e">
            <tab-content title="Select District" :before-change="loadBuildings">
              <b-form class="mb-4">
                <b-form-row>
                  <div class="col-md-12">
                    <b-form-row class="mb-3">
                      <label class="col-md-2 col-form-label">Area:</label>
                      <b-form-select v-model="selectedArea" text-field="name" value-field="areaId" class="col-md-3" :options="areaOptions" />
                    </b-form-row>
                    <b-form-row>
                      <label class="col-md-2 col-form-label">District LEA:</label>
                      <my-districts :districts="assignedDistricts" v-model="district" />
                    </b-form-row>
                    <b-form-row>
                      <label class="col-md-2 col-form-label">Description:</label>
                      <b-form-input v-model="description" class="col-md-8" placeholder="Enter a description of the batch, or leave blank to use a default description." />
                    </b-form-row>
                  </div>
                </b-form-row>
              </b-form>
            </tab-content>
            <tab-content title="Select Buildings">
              <buildings :allow-item-check="true" :show-action-column="true" :criteria-visible="'b'" />
            </tab-content>
            <tab-content title="Review">
              <b-form class="mb-4">
                <div class="col-md-8">
                  <b-form-row>
                    <label class="col-md-2 col-form-label">District LEA:</label>
                    <b-form-input v-model="district" class="col-md-4" plaintext />
                  </b-form-row>
                  <b-form-row>
                    <label class="col-md-2 col-form-label">District Name:</label>
                    <b-form-input class="col-md-8" plaintext id="input-id" :value="districtName" />
                  </b-form-row>
                  <b-form-row>
                    <label class="col-md-2 col-form-label">Description:</label>
                    <b-form-input v-model="description" class="col-md-8" id="input-id" :placeholder="description || defaultDescription" />
                  </b-form-row>
                </div>
              </b-form>
              <paginated-table small :fields="tableFields" :items="BatchMaintenance.SelectedBuildings" />
            </tab-content>
          </form-wizard>
        </template>
        <template v-slot:rejected="error" class="align-items-center justify-content-center">
          <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
        </template>
      </Promised>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Promised } from 'vue-promised'
import CardStateIcon from '../../../CardStateIcon'
import PaginatedTable from '../../../PaginatedTable'
import Buildings from '../Buildings'
import { FormWizard, TabContent } from 'vue-form-wizard'
import MyDistricts from '../../common/MyDistricts'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { DPSAFT } from '../../../../stores/dpsaft/index'
const dpsaftStore = new DPSAFT()

export default {
  name: 'create-batch',
  data() {
    return {
      description: null,
      loadData: null,
      tableFields: [
        { key: 'schoolNumber', sortable: true },
        { key: 'buildingNumber', sortable: true },
        { key: 'buildingName', sortable: true },
        //{ key: 'academicSpace', sortable: true },
        { key: 'constructionYear', sortable: true }
        // {
        //   key: 'action',
        //   sortable: false,
        //   thClass: 'text-right',
        //   class: 'text-right'
        // }
      ],
      pageState: {
        mode: 'main',
        itemSelections: []
      }
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext', 'leaContext']),
    ...mapState('DPSAFT', ['filter', 'assignedDistricts', 'areas', 'assignedAreas', 'selectedAreaId']),
    ...mapState('DPSAFT/Facilities', ['BatchMaintenance']),
    district: {
      set(district) {
        this.$store.commit('DPSAFT/setFilterDistrict', district)
      },
      get() {
        return this.filter.district
      }
    },
    selectedArea: {
      set(area) {
        this.$store.commit('DPSAFT/setSelectedAreaId', area)
      },
      get() {
        return this.selectedAreaId
      }
    },
    areaOptions() { return [{areaId: null, name: 'All Areas'}, ...this.areas] },
    districtName() {
      if (this.district) {
        return this.leaContext.LeaDictionary[this.district].name
      }
      return 'Not Selected'
    },
    defaultDescription(){
      if(this.district){
        if(this.BatchMaintenance.SelectedBuildings.length > 0){
          if(this.filter.building && this.filter.building != "DISTRICT" && this.filter.building != "OTHER"){
            return `${this.districtName}: ${this.BatchMaintenance.SelectedBuildings.length} Building Inspections at ${this.buildingName(this.filter.building)}`
          } 
          return `${this.districtName}: ${this.BatchMaintenance.SelectedBuildings.length} Building Inspections`
        }
        return `${this.districtName} Building Inspection`  
      }
      return "Building Inspection"
    }
  },
  created() {
    this.loadData = Promise.all([
      this.$store.dispatch(`${dpsaftStore.path}/getAssignedDistricts`),
      this.$store.dispatch(`${dpsaftStore.path}/getAssignedAreas`)
    ])
  },
  methods: {
    postBatch() {
      return this.$store
        .dispatch('DPSAFT/Facilities/initializeBatch', {
          description: this.description || this.defaultDescription,
          district: this.district,
          schoolLea: null
        })
        .then(this.$router.push({ name: 'Facilities/Batches/All' }))
    },
    loadBuildings() {
      return this.$store
        .dispatch('DPSAFT/getBuildings', {
          areaId: null,
          districtLea: this.district,
          coopLea: null,
          building: null
        })
        .then(() => true)
    },
    buildingName(buildingLea){
      return this.leaContext.LeaDictionary[buildingLea].name
    }
  },
  watch:{
    selectedAreaId(newAreaId){
      this.$store.dispatch('DPSAFT/getAreaDistricts', {areaId: newAreaId})
      .then(() => true)
    }
  },
  components: {
    PaginatedTable,
    Buildings,
    FormWizard,
    TabContent,
    MyDistricts,
    Promised,
    CardStateIcon
  }
}
</script>

<template>
  <div>
    <router-view />
    <offline-button />
  </div>
</template>

<script>
import OfflineButton from '../common/OfflineButton'
import { PageMixin } from '../../PageMixin'

export default {
  mixins: [PageMixin],
  components: {
    OfflineButton
  },
  data() {
    return {}
  },
  created() {
  }
}
</script>

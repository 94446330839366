<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div>
        <div class="chartElem" v-show="slotProps.chartMode">
          <highcharts class="chart" :options="chartOptions" />
        </div>
        <div v-show="!slotProps.chartMode">
          <chart-data-table :items="dataItems()" />
        </div>
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { Subpopulations } from '../../subpopulations'
import { ChartMixin } from '../../ChartMixin'
import { credits } from '../../../helpers/credits'

export default {
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'column'
        },
        plotOptions: {
          column: {
            stacking: 'normal'
          }
        },
        credits: credits.SIS,
        title: {
          text: this.getTitle()
        },
        xAxis: {
          title: {
            text: 'Years of Experience',
          },
          categories: ['< 3', '3-5', '5-10', '10-15', '15-20', '20-25', '25-30', '> 30']
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Number of Teachers'
          }
        },
        series: [{
            name: 'Number of Teachers',
          }
        ]
      }
    };
  },
  mixins: [ChartMixin],
  computed: {
    reportData() {
      return this.$store.state.hcptModule.overviewData.demographicExperienceStatistics
    }
  },
  props:{
    fiscalYear: Number
  },
  watch: {
    reportData(newData) {
      let grouped = newData.reduce((r,a) => {
        var rs = a.race.replace(/(\s|\/)/g, '_')
        r[rs] = r[rs] || { name: a.race, data: [0,0,0,0,0,0,0] }
        r[rs].data[a.experienceGroup] = a.groupCount
        return r
      }, {})

      var data = []
      for(var k in grouped) {
        data.push({ 
          name: grouped[k].name, 
          data: grouped[k].data, 
          color: (Subpopulations.find(s => s.name == grouped[k].name || s.altName == grouped[k].name) || {}).color 
        })
      }
      this.chartOptions.series = data
    }
  },
  methods: {
    getTitle(){
       return `Experience by Teacher Subpopulation - ${this.fiscalYear + 1990}`
    },
    dataItems() {
      return this.chartOptions.series.flatMap(item => {
        return item.data.map((count, index) => {
          return { Series: item.name, Experience: this.chartOptions.xAxis.categories[index], Count: count } 
        })        
      })
    }
  }
}
</script>
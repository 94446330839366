<template>
  <b-form>
    <b-form-row>
      <b-col class="pr-4">
        <b-form-group label="Grade Level" label-cols="4" content-cols="4">
          <b-badge v-for="studentGrade in grades" :key="studentGrade">
            Grade {{ studentGrade }}
          </b-badge>
        </b-form-group>
      </b-col>
      <b-col class="pr-4">
        <b-form-group label="Date" label-cols="4" content-cols="4">
          <b-form-datepicker
            v-model="date"
            right
            locale="en-US"
            :date-format-options="{year: 'numeric', month: '2-digit', day: '2-digit'}"
            :value-as-date="true"
            aria-controls="example-input"
          />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row v-if="date && (date.getMonth() == 6 || date.getMonth() == 7)">
      <b-col class="pr-4">
        <b-alert variant="info" show>
          You must specify the Academic Year to which July or August visits apply.
        </b-alert>
      </b-col>
      <b-col class="pr-4">
        <b-form-group label="Academic Year" label-cols="4" content-cols="4">
          <b-form-select :options="availableAYs" v-model="ay" />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col class="pr-4">
        <b-form-group label="Visit Type" label-cols="4">
          <b-form-select v-model="type" :options="metadata.visit.types" />
          <span class="text-danger" v-if="$v.type.$invalid">*Visit Type is required.</span>
        </b-form-group>
      </b-col>
      <b-col class="pr-4">
        <b-form-group label="Visit Location" label-cols="4">
          <b-form-select v-model="location" :options="metadata.visit.locations" />
          <span class="text-danger" v-if="$v.location.$invalid">*Visit Location is required.</span>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col class="d-flex flex-row flex-wrap">
        <b-form-checkbox v-model="topics" name="topics" v-for="a in metadata.visit.topics" :key="a.value" class="mx-4" style="width: 230px" :value="a.value">
          {{ a.text }}
        </b-form-checkbox>
      </b-col>
    </b-form-row>
    
    <b-form-row class="mt-8">
      <b-col class="pr-4">
        <b-form-group label="Notes">
          <b-form-textarea v-model="notes" />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row v-if="editVisit.visitId">
      <b-col class="pr-4">
        <bulk-visit-sign-in-sheet :file="editVisit.signInSheet" :visit-id="editVisit.visitId" :readonly="false" />
      </b-col>
    </b-form-row>

    <b-form-row class="mt-4">
      <b-col class="text-center">
        <b-btn variant="primary" class="mr-2" @click="save" :disabled="$v.$invalid">
          <i class="fa fa-save fa-fw" /> Save
        </b-btn>
        <b-btn variant="secondary" @click="$emit('cancel')">
          <i class="fa fa-times fa-fw" /> Cancel
        </b-btn>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import { gradeSpans } from '@/helpers/gradeSpans'
import { metadata } from '@/modules/cte/stores/careercoach/metadata'
import { required } from 'vuelidate/lib/validators'
import BulkVisitSignInSheet from './BulkVisitSignInSheet.vue'
import moment from 'moment'

export default {
  computed: {
     careerCoachGrades() {
      return gradeSpans.filter(g => g.value >= 7 && g.value <= 12)
    },
    availableAYs(){
      if(this.date){
        return [
          {text: '--Please select an option--', value: null, disabled: true},
          {text: `${this.date.getFullYear() -1}/${this.date.getFullYear()} Academic Year`, value: this.date.getFullYear() - 2000},
          {text: `${this.date.getFullYear()}/${this.date.getFullYear() + 1} Academic Year`, value: this.date.getFullYear() - 1999}
        ]
      }
      return []
    }
  },
  data() {
    return {
      metadata,
      date: new Date(this.editVisit.visitDate),
      grade: this.editVisit.grade,
      type: this.editVisit.visitData.type,
      location: this.editVisit.visitData.location,
      notes: this.editVisit.visitData.notes,
      topics: this.editVisit.topics,
      ay: this.editVisit.ay,
      file: this.editVisit.file
    }
  },
  validations:{
    type:{
      required
    },
    location:{
      required
    },
    date:{
      required
    }
  },
  methods: {
    save() {
      this.$emit('save', {
        ay: this.ay,
        visitDate: moment(this.date).format("YYYY-MM-DD"),
        grade: this.grade,
        visitId: this.editVisit.visitId,
        visitData: {
          type: this.type,
          location: this.location,
          topics: this.topics,
          notes: this.notes
        }
      })
    },
    cancel() {
      this.$emit('cancel')
    }
  },
  components:{
    BulkVisitSignInSheet
  },
  props:{
    grades:{
      type: Array,
      default: () => [],
      required: true
    },
    editVisit:{
      type: Object,
      default: () => {
        return {
          metadata,
          visitDate: new Date(),
          grade: null,
          topics: [],
          visitData:{
            type: null,
            location: null,
            notes: null,
            topics: [],
            file: null
          }
        }
      }
    }
  }  
}
</script>
<template>
  <div>
    <table-control-widget
      :per-page="tableOptions.perPage"
      :filter="tableOptions.filter"
      @page-length-changed="tableOptions.perPage = $event"
      @filter-changed="tableOptions.filter = $event"
    />
    <b-table
      small
      :fields="tableFields"
      :items="filteredListItems"
      :per-page="tableOptions.perPage"
      :current-page="tableOptions.currentPage"
      :filter="tableOptions.filter"
    >
      <template v-slot:cell(responseObject.operation)="data">
        <span class="badge badge-info">{{ requestOperationFormatter(data.value) }}</span>
      </template>

      <template v-slot:cell(responseObject.items)="data">
        <approved-assignment-item-detail
          v-for="i in data.value"
          :key="i.key"
          :item="i"
          :lea-operation="pluckLeaOperation(i.key, data.item.leaOperations)"
        />
      </template>

      <template v-slot:cell(requestId)="data">
        <router-link :to="{name: 'review-request', params: {id: data.value}}">
          <i class="fa fa-search-plus fa-fw" /> View Details
        </router-link>
      </template>

      <template v-slot:cell(originalValues)="data">
        <div v-html="formatChanges(data.item.schoolLea, data.item.operationData)" />
      </template>
    </b-table>

    <table-pagination-widget
      :per-page="tableOptions.perPage"
      :filter="tableOptions.filter"
      :current-page="tableOptions.currentPage"
      :page-length="tableOptions.perPage"
      :total-rows-count="filteredListItems.length"
      @page-changed="tableOptions.currentPage = $event"
    />
  </div>
</template>


<script>
import { mapState, mapGetters } from 'vuex'
import { gradeSpanFormatter } from '@/helpers/gradeSpans'
import TablePaginationWidget from '@/components/shared/TablePaginationWidget'
import TableControlWidget from '@/components/shared/TableControlWidget'
// import ExcelButton from '../../ExcelButton'
import { RequestOperationPurpose, requestOperationFormatter } from '..'
import ApprovedAssignmentItemDetail from './approvedAssignmentItemDetail'
export default {
  name: 'approved-assignments',
  data() {
    return {
      RequestOperationPurpose,
      requestOperationFormatter,
      fy: null,
      tableOptions: {
        currentPage: 1,
        perPage: 10,
        filter: null
      }
    }
  },
  components: {
    TablePaginationWidget,
    TableControlWidget,
    ApprovedAssignmentItemDetail
  },
  mounted() {
  },
  computed: {
    ...mapState('toolsModule/leaAssignment', ['approvedAssignments']),
    ...mapGetters('toolsModule/leams', ['fiscalYearAssignmentOptions', 'fiscalYear']),
    ...mapState('globalModule', ['leaContext', 'userContext']),
    ...mapGetters('globalModule', ['leaDictionary']),
    ...mapGetters('toolsModule/leaAssignment', ['selectedOperation']),

    tableFields() {
      return [
        { key: 'districtLEA', sortable: true, label: 'Distict LEA' },
       { key: 'requestingParty', sortable: true, label: 'District' },
        { key: 'responseObject.operation', sortable: true, label: 'Purpose' },
        { key: 'responseObject.items', sortable: true, label: 'School Request Details' },
        { key: 'requestId', sortable: false, label: '', class: 'text-right' }
      ]
    },
    listItems() {
      return this.approvedAssignments.filter(f=>![RequestOperationPurpose.NewDistrict,RequestOperationPurpose.ChangeDistrict].includes(f.responseObject.operation)).map(item => {
        const admins = []
        item.leaOperations.forEach(o=>{
          let lea = this.leaDictionary[o.lea]
          if (lea && lea.adminLastName) {
            admins.push({name: `${lea.adminFirstName} ${lea.adminLastName}`, email: lea.adminEmailAddress })
          }
        });

        return {
          requestingParty: item.districtLEA == 'AR' ? 'ADE / Charter School Office' : this.leaDictionary[item.districtLEA]?.name || 'Not Found',
          principals: admins,
          ...item
        }
      })
    },
    filteredListItems(){
      if(this.selectedOperation){
        return this.listItems.filter(a => a.responseObject.operation == this.selectedOperation)
      }
      return this.listItems
    }
  },
  methods: {
    pluckLeaOperation(key, leaOperations) {
      var ops = leaOperations.filter(o => o.operationKey === key)
      return ops && ops.length ? ops[0] : null
    },
    formatChanges(lea, item) {
      if (item.itemType == 'edit-school') {
        return `${item.currentValues.leaName}, ${gradeSpanFormatter(item.currentValues.gradeLow, item.currentValues.gradeHigh)}`
      } else if (item.itemType == 'edit-district') {
        return `${item.currentValues.leaName}`
      }

      return null
    }
   
    // extractSchoolName(item) {
    //   switch (item.itemType) {
    //     case 'edit-school':
    //     case 'edit-district':
    //       return item.leaName
    //     case 'new':
    //     case 'new-district':
    //       return item.leaName
    //     case 'inactivate':
    //       return item.selectedLeaName
    //   }
    // },

    // extractGradeSpan(item) {
    //   switch (item.itemType) {
    //     case 'edit-school':
    //       return gradeSpanFormatter(item.gradeLow, item.gradeHigh)
    //     case 'new':
    //       return gradeSpanFormatter(item.gradeLow, item.gradeHigh)
    //     case 'inactivate':
    //       return gradeSpanFormatter(item.gradeLow, item.gradeHigh)
    //   }
    // }
  }
}
</script>

<template>
  <div>
    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">Is Paired School:</label>
      <div class="col-md-9">
        <div class="form-control-plaintext">
          <pretty-checkbox :value="pairedSource.is" />
        </div>
      </div>
    </div>

    <div class="form-group row mb-0" v-if="pairedSource.is && pairedSource.feeders && pairedSource.feeders.length > 0">
      <label class="col-md-3 col-form-label">Feeder School(s):</label>
      <div class="col-md-9">
        <div class="form-control-plaintext">
          <ul class="list-unstyled">
            <li v-for="f in pairedSource.feeders" :key="f.key">
              {{ f.lea || '(LEA # TBD)' }} - {{ f.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
                
    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">Is Feeder School:</label>
      <div class="col-md-9">
        <div class="form-control-plaintext">
          <pretty-checkbox :value="feederSource.is" />
        </div>
      </div>
    </div>
    <div class="form-group row mb-0" v-if="feederSource.is && feederSource.paired">
      <label class="col-md-3 col-form-label">Paired With:</label>
      <div class="col-md-9">
        <div
          class="form-control-plaintext"
        >
          {{ feederSource.paired.lea || '(LEA # TBD)' }} - {{ feederSource.paired.name }}
        </div>
      </div>
    </div>          
  </div>
</template>

<script>
import PrettyCheckbox from '../../../../PrettyCheckbox.vue'

export default {
  data() {
    return {}
  },
  components: {
    PrettyCheckbox
  },
  props: {
    pairedSource: Object,
    feederSource: Object
  }
}
</script>
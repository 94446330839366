<template>
  <highcharts id="pageViewsContainer" :options="chartOptions" 
              style="height: 90px; max-width: 240px; width: 99%; position: absolute; bottom: 20px;"
              ref="chart" />
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'pageviews-chart',
    data() {
      return {
        chartOptions: {
            title: {
                text: "Pageviews <i class=\"fa fa-question-circle\" id=\"pageviews-tooltip-target\"></i>",
                align: "left",
                style: {
                    "fontSize": "14px",
                    "fontWeight": "normal"
                },
                margin: 8,
                useHTML: true
            },
            subtitle: {
                text: '',
                align: "left",
                style: {
                    "fontSize": "24px",
                    "maxWidth": "160px",
                    "minWidth": "65px",
                    "wordWrap": "break-word"
                },
                floating: true
            },
            chart: {
                events: {
                  load() {
                    this.showLoading()
                  }
                },
                style: {
                    fontFamily: '"Source Sans Pro", sans-serif'
                },
                type: 'area',
                plotBackgroundColor: '#f8f9fa',
                plotShadow: false,
                spacing: [10, 10, 15, 10],
                backgroundColor: '#f8f9fa'
            },
            lang: {
                noData: ''
            },
            loading: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#058dc7'
                }
            },
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px',
                    color: '#058dc7'
                },
                position: {
                    verticalAlign: 'top'
                }
            },
            credits: {
                enabled: false
            },
            navigation: {
                buttonOptions: {
                    enabled: false
                }
            },
            series: [{
                animation: false,
                enableMouseTracking: false,
                marker: {
                    enabled: false
                },
                lineWidth: 2,
                color: '#058dc7'
            }],
            xAxis: {
                visible: false,
                type: 'datetime'
            },
            yAxis: {
                visible: false
            },
            legend: {
                enabled: false
            },
            tooltip: {
                enabled: false
            }
        }
      }
    },
    mounted() {
      this.updatePageViews()
    },
    computed: {
      ...mapGetters('globalModule',['pageViews'])
    },
    methods: {
      updatePageViews() {
        this.$store.dispatch('globalModule/getPageviews')
        .then(() => this.$refs.chart.chart.hideLoading())        
      },
      numberWithCommas(x) {
        x = x.toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x))
            x = x.replace(pattern, "$1,$2");
        return x;
      }
    },
    watch: {
      pageViews(newData) {
        if (newData) {
          var chart = this.$refs.chart.chart
          chart.setTitle(null, { text: this.numberWithCommas(newData.totalsForAllResults["ga:pageviews"]) })
          var data = newData.dataTable.rows.map((element) => [new Date(parseInt(element.c[0].v)), parseInt(element.c[1].v)])
          chart.series[0].setData(data, true);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
#pageViewsContainer ::v-deep .highcharts-container {
    width:100% !important;
    height:100% !important;
}</style>
<template>
  <div>
    <b-table :fields="fields" :items="districts">
      <template v-slot:cell(maintenanceOperationsArea)="data">
        <b-form-select placeholder="Select Area" :options="areas" value-field="areaId" text-field="name" :value="data.value.areaId" @change="moAreaChanged($event, data.item.districtLEA)">
          <template #first>
            <b-form-select-option :value="undefined" disabled>
              -- Select Area --
            </b-form-select-option>
          </template>
        </b-form-select>
      </template>
      <template v-slot:cell(planningConstructionArea)="data">
        <b-form-select placeholder="Select Area" :options="areas" value-field="areaId" text-field="name" :value="data.value.areaId" @change="pcAreaChanged($event, data.item.districtLEA)">
          <template #first>
            <b-form-select-option :value="undefined" disabled>
              -- Select Area --
            </b-form-select-option>
          </template>
        </b-form-select>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'districts',
  data() {
    return {
      fields: [
        { key: 'districtLEA', sortable: true },
        { key: 'name', sortable: true },
        { key: 'maintenanceOperationsArea', label: 'Maintenance & Operations Area', sortable: true },
        { key: 'planningConstructionArea', label: 'Planning & Construction Area', sortable: true }
      ]
    } 
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState('DPSAFT/Admin', ['districts']),
    ...mapState('DPSAFT', ['areas'])
  },
  methods: {
    getDistricts() {
      this.$store.dispatch('DPSAFT/Admin/getDistricts')
    },
    moAreaChanged(selectedValue, districtLEA) {
      this.$store.dispatch('DPSAFT/Admin/assignDistrictArea', { districtLEA, moAreaId: selectedValue } )
    },
    pcAreaChanged(selectedValue, districtLEA) {
      this.$store.dispatch('DPSAFT/Admin/assignDistrictArea', { districtLEA, pcAreaId: selectedValue })
    }
  },
  created() {
    this.$store.dispatch('DPSAFT/getAreas').then(() => {
      this.getDistricts()
    })
  }
}

</script>
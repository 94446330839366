<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode" :key="selectedLea.number">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { mapGetters, mapState } from 'vuex'

export default {
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: this.chartType()
        },
        title: {
          text: this.title()
        },
        legend: {
          enabled: true
        },
        credits: {
          text: ''
        },
        plotOptions: {
          series: {
            minPointLength: 4
          },
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.percentage:.1f} %'
            }
          }
        },
        series: this.series(),
        xAxis: {
          title: {
            text: ''
          },
          categories: ['Inspection Result'],
          tickInterval: 1
        },
        tooltip: {
          enabled: true
        },
        yAxis: {
          title: {
            text: 'Counts'
          },
          endOnTick: false
        }
      }
    }
  },
  mixins: [ChartMixin],
  computed: {
    ...mapGetters('globalModule', ['leaDictionary']),
    ...mapState('DPSAFT', ['stateSummary']),
  },
  watch: {
    stateSummary: {
      immediate: true,
      handler(summary) {
        if (!this.statusBreakdown) {
          this.chartOptions.series[0].data = [{
            name: 'Inspected',
            y: summary.inspectedCount
          },
          {
            name: 'Uninspected',
            y: summary.uninspectedCount
          }]
        } 
        else {
          this.chartOptions.series[0].data = [{
            y: summary.okCount
          }]
          this.chartOptions.series[1].data = [{
            y: summary.fixCount
          }]
          this.chartOptions.series[2].data = [{
            y: summary.groundCount
          }]

          this.chartOptions.series[0].color = "#28a745"
          this.chartOptions.series[1].color = "#ffc107"
          this.chartOptions.series[2].color = "#dc3545"
        }
      }
    },
    selectedLea() {
    }
  },
  props: {
    statusBreakdown: Boolean,
  },
  methods: {
    chartType() {
      return this.statusBreakdown ? 'column' : 'pie'
    },
    series() {
      if (!this.statusBreakdown) {
        return [
          { name: 'Count' }
        ]
      } else {
        return [
          { name: 'OK' },
          { name: 'Fix' },
          { name: 'Ground' }
        ]
      }
    },
    title() {
      if (!this.statusBreakdown) {
        return `Inspected / Uninspected Buses this School Year`
      } else {
        return `Current School Year Inspection Results`
      }
    },
    dataItems() {
      if (!this.statusBreakdown) {
        return [{
          Status: 'Inspected',
          Count: this.chartOptions.series[0].data[0].y
        },
        {
          Status: 'Uninspected',
          Count: this.chartOptions.series[0].data[1].y
        }]
      } else {
        return this.chartOptions.series.map(s => {
          return {
            Status: s.name,
            Count: (s.data || []).length == 1 ? s.data[0].y : 0
          }
        })
      }
    }
  }
}
</script>

import Vue from 'vue'

const ReportGroupsTable = Vue.extend({
    name: 'report-groups-table',
    /* html */
    template: `
    <div>
    <span>FY: {{ fiscalYear }}</span>
    <b-table striped hover selectable select-mode='single' :items="reportGroups" :fields="tableFields" @row-selected='rowSelected'>
    <template slot="actions" slot-scope="row">
        <a href='#' @click="initEditing(row.item, row.index, $event.target)"><i class='fas fa-fw fa-pen'></i>Edit</a>
    </template>

    <!-- A custom formatted header cell for field 'name' -->
    <template v-slot:cell(HEAD_actions)="data">
        <button class='btn btn-primary' @click='initAdding($event.target)'><i class='fas fa-fw fa-plus'></i>Add Group</button>
    </template>

</b-table>
    <b-modal id='group-modal' :title="editingItem.groupName" ok-only @hide="resetInfoModal" @ok='saveChanges'>
    <template slot="default" v-if='editingItem'>
    <p>Modal Body with button</p>
        <input  type='text' v-model='editingItem.groupName' />
    <pre>{{ editingItem.groupName }}</pre>
  </template>

  </b-modal>
    </div>`,
    data() {
        return {
            tableFields: [{
                    key: 'groupName',
                    sortable: true,
                    sortDirection: 'asc'
                },
                {
                    key: 'actions',
                    label: 'Actions',
                    class: 'text-right'
                }
            ],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            selectedItem: { groupName: '(not selected)'},
            editingItem: { id: 0, fy: 0, groupName: '(not selected}'}
        }
    },

    methods: {
        rowSelected(items) {
            Object.assign(this.editingItem, items[0]);
        },
        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveChanges() {

            //need to copy the edited information back to the store item
            console.log('saving', this.editingItem)
            this.$emit('item-changed', this.editingItem);

        },
        initEditing(item, index, eventTarget) {
            
            this.selectedItem = item;

            Object.assign(this.editingItem, item);
            this.$root.$emit('bv::show::modal', 'group-modal', eventTarget)
        },
        initAdding(eventTarget) {
            Object.assign(this.editingItem, {id: 0, groupName: null, fy: this.fiscalYear});
            this.$root.$emit('bv::show::modal', 'group-modal', eventTarget)
        }
    },
    computed: {
        reportGroups() {
            return this.$store.state.hcptModule.reportGroups;
        }
    },
    props: {
        fiscalYear: String
    }
})

export {
    ReportGroupsTable
}
<template>
  <div>
    <b-tabs card content-class="mt-3" active-nav-item-class="font-weight-bold">
      <b-tab title="Upload" :active="selectedTab == 'upload'" v-if="!(currentTaskCompletion || {}).completed">
        <div>
          <p class="lead">
            The excel file should be in the following format:
          </p>
          <dl class="row">
            <dt class="col-md-2">
              Worksheet name
            </dt>
            <dd class="col-sm-10">
              {{ worksheet }}
            </dd>
            <dt class="col-md-2">
              Columns
            </dt>
            <dd class="col-sm-10">
              <span v-for="column in columns" :key="column">{{ column }},&nbsp;</span>
            </dd>
          </dl>
        </div>
        <b-form-file
          v-model="file"
          :state="isValidFile"
          placeholder="Choose an excel file or drop it here..."
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          drop-placeholder="Drop file here..."
          @change="fileSelected" />
        <div class="mt-3">
          Selected file: {{ file ? file.name : '' }}
        </div>
        <div v-if="file && file.name && isValidFile">
          <animated-button :button-class="importButtonClass" :promise-resolver="importFile">
            {{ importButtonText }}
          </animated-button>
        </div>
        <div class="mt-2 p-2 card" v-if="(rowResultsIssuesOnly && rowResultsIssuesOnly.length > 0) || fileValidationIssues.length > 0">
          <h3 clas="lead">
            File issues:
          </h3>
          <b-alert show variant="danger" v-for="(validationMessage, index) in fileValidationIssues" :key="index">
            {{ validationMessage }}
          </b-alert>
          <b-alert show :variant="resultStateVariant(result.state)" v-for="(result, index) in rowResultsIssuesOnly" :key="index">
            <span class="lead">
              {{ result.message }}
            </span>
            <dl class="row">                
              <dt v-if="result.excelRowIndex > 0" class="col-sm-4">
                Row: {{ result.excelRowIndex }}
              </dt>
            </dl>
            <b-button v-b-toggle="`collapse-${index}-details`" size="sm" v-if="result.excelRowData">
              Show details
            </b-button>
            <b-collapse :id="`collapse-${index}-details`" class="mt-2" v-if="result.excelRowData">
              <b-card>
                <pre>
                  {{ result.excelRowData }}
                </pre>
              </b-card>
            </b-collapse>
          </b-alert>
        </div>
      </b-tab>
      <b-tab title="Data" :active="selectedTab == 'data'">
        <slot />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import axios from 'axios'
import { Types } from '@/modules/cte/stores/perkinsv'
import { ExcelImportRowState } from './ExcelImportRowState'
import AnimatedButton from '@/components/AnimatedButton.vue'
import { mapState } from 'vuex'
import uniqBy from 'lodash.uniqby'

export default {
  data() {
    return {
      file: [],
      fileValidationIssues: [],
      fileValidated: false,
      rowResults: [],
      selectedTab: 'upload'
    }
  },
  props: {
    uploadEndpoint: {
      type: String,
      required: true
    },
    worksheet: {
      type: String,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
  },
  computed: {
    ...mapState(Types.path, [Types.state.currentTaskCompletion]),
    rowResultsIssuesOnly() {
      return uniqBy(this.rowResults.filter(r => r.state > ExcelImportRowState.Success), 'message')
    },
    canSave() {
      return this.rowResults.length > 0 && !this.rowResults.some(r => r.state == ExcelImportRowState.Failure)
    },
    importButtonClass() {
      return this.canSave ? 'btn btn-success' : 'btn btn-primary'
    },
    importButtonText() {
      return this.canSave ? 'Save ' : 'Upload Records' 
    },
    isValidFile() {
      return this.file && this.fileValidated && this.fileValidationIssues.length < 1
    }
  },
  methods: {
    resultStateVariant(state) {
      if (state == ExcelImportRowState.Success)
        return 'success'
      else if (state == ExcelImportRowState.Warning)
        return 'warning'
      else
        return 'danger'
    },
    async fileSelected(event) {
      const vm = this
      vm.fileValidated = false
      vm.fileValidationIssues = []
      const XLSX = await import('xlsx')
      var input = event.target
      var reader = new FileReader()
      reader.onload = () => {
        var fileData = reader.result
        var workbook = XLSX.read(fileData, { type : 'binary' })
        if (!workbook.SheetNames.some(sheetname => sheetname == vm.worksheet)) {
          vm.fileValidationIssues.push(`Could not find required worksheet '${vm.worksheet}' in the selected file`)
        }
        else {
          const headers = []
          const columnCount = XLSX.utils.decode_range(workbook.Sheets[vm.worksheet]['!ref']).e.c + 1
          for (let i = 0; i < columnCount; ++i) {
            headers[i] = (workbook.Sheets[vm.worksheet][`${XLSX.utils.encode_col(i)}1`] || {}).v
          }
          for(var column of vm.columns) {
            if (!headers.some(header => header == column)) {
              vm.fileValidationIssues.push(`Could not find required column '${column}' in the selected file headers under worksheet '${vm.worksheet}'`)
            }
          }
        }
        vm.fileValidated = true
      }
      try {
        reader.readAsBinaryString(input.files[0])
      }
      catch (err) {
        // Suppress read exceptions here
      }
    },
    importFile() {
      if (this.file) {
        let vm = this
        var formData = new FormData()
        formData.append('file', this.file, this.file.name)

        let endpoint = this.uploadEndpoint
        let saving = false
        if (this.canSave) {
          endpoint += '?save=true'
          saving = true
        }
        this.rowResults = []
        return axios.post(endpoint,
          formData,
          {
            headers: { 
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          }).then(result => {
            this.rowResults = result.data
            if (saving) {
              vm.$bvToast.toast(`${result.data.length} records saved`, {
                title: `Imported records saved`,
                toaster: 'b-toaster-bottom-center',
                variant: 'success'
              })
              vm.selectedTab = 'data'
            }
            this.$emit('imported')
          })
      }
    }
  },
  components: {
    AnimatedButton
  }
}
</script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { NbctEmployeeTable } from './nbctEmployeeTable'
import { NbctTeacherCountChart } from './nbctTeacherCountChart'
import { NbctPieChart } from './nbctPieChart'
import { NbctConfirmationTable } from './nbctConfirmationsTable'


const NbctWidget = Vue.extend({
  name: 'nbct-widget',
  /* html */
  template: `
  <div class="card bg-light mt-3 print-border-none">
  <a class="anchor" id="licenses"></a>
  <div class="card-header">
      NBCT Certified / Uncertified Teachers
  </div>
  <div class="card-body print-p0">
    <ul class='nav nav-tabs d-print-none' id='nbctTabControl' role='tablist'>
      <li class='nav-item'>
        <a class='nav-link active' id='nbct-data-tab' data-toggle='tab' href='#data-tab' role='tab' aria-controls='nbct-data-tab'>
          NBCT Data
        </a>
      </li>
      <li v-if="nbctConfirms.length > 1" class='nav-item'>
        <a class='nav-link' id='nbct-confirmation-tab' data-toggle='tab' href='#confirmation-tab' role='tab' aria-controls='nbct-confirmation-tab'>
          NBCT Confirmation Statuses
        </a>
      </li>
    </ul>
    <div class='tab-content' id='nbctTabContent'>
      <div class='tab-pane fade show active' id='data-tab' role='tabpanel' aria-labelledby='nbct-data-tab'>
        <div class='row'>
            <div class='col-md-6 print-full-width'>
                <nbct-pie-chart v-bind:loadData="loadData" v-bind:selected-lea="selectedLea" system="SIS"
                    :nbc-count="nbctSystemCounts.nbcInSIS" :nbc-percentage="nbctSystemCounts.sisPercentage"
                        :total-teachers="nbctSystemCounts.totalTeachers" :nbctConfirms="nbctConfirms"></nbct-pie-chart>
            </div>
            <div class='col-md-6 print-full-width'>
                <nbct-pie-chart v-bind:loadData="loadData" v-bind:selected-lea="selectedLea" system="AELS"
                    :nbc-count="nbctSystemCounts.nbcInAELS" :nbc-percentage="nbctSystemCounts.aelsPercentage"
                    :total-teachers="nbctSystemCounts.totalTeachers"></nbct-pie-chart>
            </div>
        </div>
        <div class='row page-break-before'>
            <div class='col-md-12'>
                <nbct-teacher-count-chart v-bind:loadData="loadData" v-bind:selected-lea="selectedLea">
                </nbct-teacher-count-chart>
            </div>
        </div>
        <div class='row page-break-before'>
            <div class='col-md-12'>
                <nbct-employee-table v-bind:loadData="loadData" v-bind:selected-lea="selectedLea"></nbct-employee-table>
            </div>
        </div>
    </div>
      <div v-if='nbctConfirms.length > 1' class='tab-pane fade show page-break-before' id='confirmation-tab'>
        <div class='d-none d-print-block'>
          <h2>NBCT Confirmation Statuses</h2>
        </div>
        <nbct-confirmation-table v-bind:loadData="loadData"></nbct-confirmation-table>
      </div>
    </div>
  </div>
</div>
    `,
  data() {
    return { 
      loadData: null
    }
  },
  props: {
    selectedLea: Object
  },
  computed: {
    ...mapGetters('hcptModule',['nbctConfirms','nbctTrendData','nbctDetailData','nbctSystemCounts'])
  },
  components: {
    NbctEmployeeTable,
    NbctTeacherCountChart,
    NbctPieChart,
    NbctConfirmationTable
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if (newLea && newLea.number)
            this.loadData = this.$store.dispatch('hcptModule/getNBCTData', { lea: newLea.number })
      }
    }
  }
})

export { NbctWidget }

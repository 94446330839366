<template>
  <div>
    <p class="font-weight-bold mt-3">
      {{ question.text }}
    </p>
    <ul class="list-group list-group-flush" v-if="!editMode">
      <li class="list-group-item bg-transparent borderless">
        <span class="font-italic" v-if="value == 1"><i class="fa fas fa-check text-success" /> Yes</span>
        <span class="font-italic" v-else-if="value == 0"><i class="fa fas fa-times text-danger" /> No</span>
        <span class="font-italic" v-else-if="value == 10" v-html="question.indeterminateAnswer" />
        <span class="font-italic" v-else><i class="fa-lg fas fa-question text-warning" />  No answer</span>
      </li>
    </ul>
    <b-form-radio-group v-model="$v.survey[question.id].$model" v-if="editMode" stacked>
      <b-form-radio :value="1" class="font-italic">
        <i class="fa-fw fas fa-check text-success" /> Yes
      </b-form-radio>
      <b-form-radio :value="0" class="font-italic">
        <i class="fa-fw fas fa-times text-danger" /> No
      </b-form-radio>
      <b-form-radio :value="10" v-if="question.indeterminateAnswer">
        <span v-html="question.indeterminateAnswer" />
      </b-form-radio>
      <b-form-radio :value="null" class="font-italic">
        <i class="fa-fw fas fa-question text-warning" /> No answer
      </b-form-radio>
    </b-form-radio-group>
  </div>
</template>

<script>

/// Display the question and radio buttons for yes or no.

export default {
  inject: ['$v'],
  data: function () {
    return {

    }
  },
  name: 'question-yesno',
  computed: {
    value() {
      return this.$v.survey[this.question.id].$model
    }
  },
  props: {
    question: {
      type: Object,
      default: () => {}
    },
    editMode: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>
import Vue from 'vue'
import { gradeSpans, gradeSpanFormatter } from '../../../../helpers/gradeSpans'
import { buildingTypes, buildingTypeFormatter } from '../../../../helpers/buildingTypes'
import ReconfigurationCodeSelector from '../ReconfigurationCodeSelector.vue'
import { ReconfigurationCodePurpose } from '../../leaAssignment'

import { mapState, mapGetters } from 'vuex'

const RequestItemMixin = Vue.extend({
  data() {
    return {
      gradeSpans,
      buildingTypes,
      ReconfigurationCodePurpose
    }
  },
  methods: {
    gradeSpanString(gradeLow, gradeHigh) {
      return gradeSpanFormatter(gradeLow, gradeHigh)
    },

    buildingTypeString(buildingType) {
      return buildingTypeFormatter(buildingType)
    },

    parsePairedSchool(pairedSchool) {
      if (pairedSchool) {
        var paired = this.schoolList.filter(k => k.key == pairedSchool)
        return paired && paired.length > 0 ? paired[0] : null
      } else {
        return null
      }
    },

    parseFeederSchools(feeders) {
      if (!feeders) return null
      return feeders.map(f => {
        return this.schoolList.filter(k => k.key == f)[0]
      })
    }
  },
  computed: {
    ...mapState('toolsModule/leaAssignment', ['currentRequest']),
    ...mapState('toolsModule/leaAssignment', ['schools']),
    ...mapGetters('toolsModule/leaAssignment', ['newBuildingRequestsOnCurrentRequest']),

    pairedSchoolInfo() {
      return {
        request: {
          is: this.item.isPairedSchool,
          feeders: this.parseFeederSchools(this.item.feederSchools)
        },
        current: {
          is: this.item.currentValues?.isPairedSchool,
          feeders: this.parseFeederSchools(this.item.currentValues?.feederSchools)
        } 
      }
    },

    feederSchoolInfo() {
      return {
        request: {
          is: this.item.isFeederSchool,
          paired: this.parsePairedSchool(this.item.pairedSchool)
        },
        current: {
          is: this.item.currentValues?.isFeederSchool,
          paired: this.parsePairedSchool(this.item.currentValues?.pairedSchool)
        } 
      }
    },

    pairedSchool() {
      return this.parsePairedSchool(this.item.pairedSchool)
    },
    feederSchools() {
      return this.parseFeederSchools(this.item.feederSchools)
    },
    schoolList() {
      var schools = this.schools.map(s => {
        return { lea: s.leaNumber, key: s.leaNumber, name: s.name }
      })
      schools.splice(0, 0, ...this.newSchools)
      return schools
    },
    newSchools() {
      return this.newBuildingRequestsOnCurrentRequest
    }
  },
  components: {
    ReconfigurationCodeSelector
  },
  props: {
    operation: Number,
  }  
})

export { RequestItemMixin }

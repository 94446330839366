<template>
  <div>
    <h3 class="p-2 bg-dark mx-2 mt-1 rounded text-light font-weight-bold">
      Perkins V
    </h3>
    <hr>
    <div class="sidebar-scroll">
      <ul class="nav nav-pills flex-column ml-2 mr-2">
        <li class="nav-item">
          <router-link :to="{path: '/cte/perkins-v/inbox'}" class="nav-link" active-class="active">
            <i class="fas fa-envelope fa-fw mr-1" />Inbox 
            <b-badge v-if="(inboxItems.length + unreadComments.length) > 0">
              {{ (inboxItems.length + unreadComments.length) }}
            </b-badge>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{path: '/cte/perkins-v/applications'}" class="nav-link" active-class="active">
            <i class="fas fa-clipboard-list fa-fw mr-1" />Applications
          </router-link>
        </li>
        <li class="nav-item" v-if="isAdmin">
          <router-link :to="{path: '/cte/perkins-v/admin/users'}" class="nav-link" active-class="active">
            <i class="fas fa-users fa-fw mr-1" />User Administration
          </router-link>
        </li>        
        <li class="nav-item" v-if="isAdmin">
          <router-link :to="{path: '/cte/perkins-v/admin/academic-years'}" class="nav-link" active-class="active">
            <i class="fas fa-calendar fa-fw mr-1" />Academic Years
          </router-link>
        </li>     
      </ul>
      <hr>
      <h5 class="p-2 bg-dark mx-2 mt-4 mb-0 rounded text-light small">
        <i class="fa fa-question-circle fa-fw mr-1" /> Resources
      </h5>
      <ul class="nav nav-pills flex-column ml-2 mr-2">
        <li class="nav-item">
          <a href="/content/cte/perkins-v/Insights - CTE - Perkins V - CTE Coordinator User Guide.pdf" class="nav-link small" download>
            <i class="fa fa-book fa-fw mr-1" />CTE Coordinator / Reviewer User Guide
          </a>
        </li>     
        <li class="nav-item" v-if="isAdmin">
          <a href="/content/cte/perkins-v/Insights - CTE - Perkins V - Administrators User Guide.pdf" class="nav-link small" download>
            <i class="fa fa-book fa-fw mr-1" />Perkins V Administrator User Guide
          </a>
        </li>     
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { PerkinsVRoles } from '@/modules/cte/components/common/Roles'
import { Types } from '../../stores/perkinsv'
  
export default {
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState(Types.path, [Types.state.inboxItems, Types.state.unreadComments]),
    isAdmin() {
      return (this.userContext.roles || []).includes(PerkinsVRoles['Perkins Administrator']) ||
        (this.userContext.roles || []).includes('Insights_System_Administrators')
    },
  }
}
</script>

<template lang="html">
  <div class="text-center w-100" style="margin-top: 50px">
    <i class="fa-4x fas fa-spinner fa-spin text-secondary" />
    <h3 class="text-secondary">
      Loading...
    </h3>
  </div>
</template>

<script>
export default {
  name: 'loading-spinner'
}
</script>
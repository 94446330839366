<template>
  <div class="card bg-light mt-3">
    <div class="card-header">
      Educators
    </div>
    <div class="card-body">
      <div class="alert alert-warning text-center m-4" v-if="!detailsVisible">
        Educator Details are only available in the context of a District or School
      </div>
      <template v-if="detailsVisible">
        <b-form inline class="mb-3 d-print-none">
          <label class="ml-3 mr-sm-2">Race: </label>
          <b-form-select v-model="raceSelect" :options="raceOptions" value-field="field" text-field="name" size="sm" class="mr-sm-3 mt-sm-1" />
          <label class="mr-sm-2">Experience Level (Teachers only): </label>
          <b-form-select v-model="experienceSelect" :options="experienceOptions" size="sm" class="mr-sm-3 mt-sm-1" />
          <label class="mr-sm-2">License Type: </label>
          <b-form-select v-model="licenseTypeSelect" :options="licenseTypes" text-field="name" size="sm" class="mr-sm-3 mt-sm-1" />
          <label class="mr-sm-2">Career Continuum: </label>
          <b-form-select v-model="ccDesignationSelect" :options="ccDesignations" size="sm" class="mr-sm-3 mt-sm-1" />
        </b-form>
        <Promised :promise="staffDetailLoaded">
          <template v-slot:pending>
            <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
              <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
            </div>
          </template>
          <template v-slot>
            <slot>
              <educator-details-table :staff-details="details" :race-filter="raceSelect" :experience-filter="experienceSelect" :license-type-filter="licenseTypeSelect" :cc-filter="ccDesignationSelect" />
            </slot>
          </template>
          <template v-slot:rejected="error" class="align-items-center justify-content-center">
            <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" 
                             :message="'Error: ' + error.message" />
          </template>
        </Promised>
      </template>
    </div>
  </div>
</template>

<script>
import CardStateIcon from '../../CardStateIcon.vue'
import { Promised } from 'vue-promised'
import EducatorDetailsTable from './EducatorDetailsTable.vue'
import { Subpopulations } from '../../subpopulations'
import { LicenseTypes } from '../../licenseTypes'

export default {
    data() {
        var raceOpts = Subpopulations

        return {
            raceSelect: null,
            raceOptions: raceOpts,
            licenseTypes: LicenseTypes,
            experienceSelect: null,
            licenseTypeSelect: null,
            ccDesignationSelect: null,
            experienceOptions: [
                { text: "(Novice and Experienced)", value: null},
                { text: "Novice Teachers", value: "novice"},
                { text: "Experienced Teachers", value: "experienced"},
            ],
            ccDesignations: [
              { text: '(any)', value: null },
              { text: 'None', value: 'None' },
              { text: 'Early Career Professional Educator', value: 'Early Career Professional Educator' },
              { text: 'Master Professional Educator', value: 'Master Professional Educator' },
              { text: 'Career Professional Educator', value: 'Career Professional Educator' },
              { text: 'Lead Professional Educator', value: 'Lead Professional Educator' },
              { text: 'Master Professional Educator and Lead Professional Educator', value: 'Master Professional Educator and Lead Professional Educator' }
            ]
        }
    },
    components: {
        EducatorDetailsTable,
        Promised,
        CardStateIcon
    },
    props: {
        details: Array,
        detailsVisible: Boolean,
        staffDetailLoaded: Promise
    }
}
</script>
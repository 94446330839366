<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <h5 class="card-header">
            Staff Demographic Statistics
          </h5>
          <div class="card-body">
            <ul class="nav nav-tabs mb-1 d-print-none" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="certified-staff-tab"
                  data-toggle="tab"
                  href="#certified-staff"
                  role="tab"
                  aria-controls="certified-staff-tab"
                  aria-selected="true">
                  Certified Teaching Staff
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="certfied-non-teaching-staff"
                  data-toggle="tab"
                  href="#certified-non-teachers"
                  role="tab"
                  aria-controls="certfied-non-teaching-staff"
                  aria-selected="false">
                  Certified Non-Teaching Staff
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="classified-staff-tab"
                  data-toggle="tab"
                  href="#classified-staff"
                  role="tab"
                  aria-controls="classified-staff-tab"
                  aria-selected="false">
                  Classified Staff
                </a>
              </li>
              <li v-if="isAuthenticated" class="nav-item">
                <a
                  class="nav-link"
                  id="staff-details-tab"
                  data-toggle="tab"
                  href="#staff-details"
                  role="tab"
                  aria-controls="staff-details-tab"
                  aria-selected="false">
                  Staff Details
                </a>
              </li>
            </ul>

            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="certified-staff" role="tabpanel" aria-labelledby="home-tab">
                <h2>Certified Teaching Staff</h2>
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6 print-full-width">
                        <staff-demographics-chart :load-data="loadData" :staff-data="certifiedTeacherStats" chart-title="Teaching Staff Counts by Race" />
                      </div>

                      <div class="col-md-6 print-full-width">
                        <staff-demographic-percentages-chart
                          :load-data="loadData"
                          :staff-data="certifiedTeacherStats"
                          chart-title="White/Non-White Ratio (Teachers)"
                        />
                      </div>
                    </div>

                    <div class="row page-break-before">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6 print-full-width" v-if="selectedLea && selectedLea.type != 10">
                            <average-years-teaching-experience-chart
                              :load-data="loadData"
                              :staff-data="certifiedTeacherStats"
                              chart-title="Average Years of Teaching Experience"
                            />
                          </div>

                          <div class="col-md-6 print-full-width">
                            <novice-teacher-percentages-chart :load-data="loadData" :staff-data="noviceTeacherStats" chart-title="Novice Teacher Percentages" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row page-break-before">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6 print-full-width">
                            <total-teacher-chart
                              :load-data="loadData"
                              :staff-data="normalizeFiscalYear(noviceTeachersByRace)"
                              chart-title="Novice Teachers Count"
                            />
                          </div>

                          <div class="col-md-6 print-full-width">
                            <staff-demographics-chart
                              :load-data="loadData"
                              :staff-data="normalizeFiscalYear(noviceTeachersByRace)"
                              chart-title="Novice Teachers Count by Race"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="row page-break-before">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-6 print-full-width">
                            <total-teacher-chart
                              :load-data="loadData"
                              :staff-data="normalizeFiscalYear(newTeachersByRace)"
                              chart-title="New Teachers Count"
                            />
                          </div>

                          <div class="col-md-6 print-full-width">
                            <staff-demographics-chart
                              :load-data="loadData"
                              :staff-data="normalizeFiscalYear(newTeachersByRace)"
                              chart-title="New Teachers Count by Race"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row page-break-before">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12 print-full-width" v-if="selectedLea">
                            <employment-by-gender-chart :load-data="loadData" :staff-data="genderStats" :record-type="0" chart-title="Employment By Gender" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade page-break-before" id="certified-non-teachers" role="tabpanel" aria-labelledby="profile-tab">
                <h2>Certified Non-Teaching Staff</h2>
                <div class="row">
                  <div class="col-md-6 print-full-width">
                    <staff-demographics-chart
                      :load-data="loadData"
                      :staff-data="certifiedNonTeacherStats"
                      chart-title="Certified Non-Teaching Staff Counts by Race"
                    />
                  </div>

                  <div class="col-md-6 print-full-width">
                    <staff-demographic-percentages-chart
                      :load-data="loadData"
                      :staff-data="certifiedNonTeacherStats"
                      chart-title="White/Non-White Ratio (Certified Non-teaching Staff)"
                    />
                  </div>
                </div>

                <div class="row  page-break-before">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6 print-full-width" v-if="selectedLea">
                        <employment-by-gender-chart :load-data="loadData" :staff-data="genderStats" :record-type="1" chart-title="Employment By Gender" />
                      </div>

                      <div class="col-md-6 print-full-width d-none" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade page-break-before" id="classified-staff" role="tabpanel" aria-labelledby="profile-tab">
                <h2>Classified Staff</h2>
                <div class="row">
                  <div class="col-md-6 print-full-width">
                    <staff-demographics-chart :load-data="loadData" :staff-data="classifiedStaffStats" chart-title="Classified Staff Counts by Race" />
                  </div>

                  <div class="col-md-6 print-full-width">
                    <staff-demographic-percentages-chart
                      :load-data="loadData"
                      :staff-data="classifiedStaffStats"
                      chart-title="White/Non-White Ratio (Classified Staff)"
                    />
                  </div>
                </div>

                <div class="row  page-break-before">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6 print-full-width" v-if="selectedLea">
                        <employment-by-gender-chart :load-data="loadData" :staff-data="genderStats" :record-type="2" chart-title="Employment By Gender" />
                      </div>

                      <div class="col-md-6 print-full-width d-none" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade page-break-before" id="staff-details" role="tabpanel" aria-labelledby="staff-details">
                <div class="row">
                  <div class="col-md-12">
                    <staff-details-widget :details="staffDetails" :details-visible="staffDetailsVisible" :staff-detail-loaded="loadStaffDetail" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import StaffDemographicsChart from './StaffDemographicsChart.vue'
import StaffDemographicPercentagesChart from './StaffDemographicPercentagesChart.vue'
import EmploymentByGenderChart from './EmploymentByGenderChart.vue'
import AverageYearsTeachingExperienceChart from './AverageYearsTeachingExperienceChart.vue'
import NoviceTeacherPercentagesChart from './NoviceTeacherPercentageChart.vue'
import StaffDetailsWidget from './StaffDetailsWidget.vue'
import TotalTeacherChart from './TotalTeacherChart.vue'

export default {
  data() {
    return {
      loadData: null,
      loadStaffDetail: null,
      staffType: '0'
    }
  },
  computed: {
    ...mapState('hcptModule', ['noviceTeachersByRace', 'newTeachersByRace']),
    certifiedTeacherStats() {
      return this.$store.state.hcptModule.additionalReporting.staffDemographics.teachingStaff
    },
    certifiedNonTeacherStats() {
      return this.$store.state.hcptModule.additionalReporting.staffDemographics.certifiedNonTeachingStaff
    },
    classifiedStaffStats() {
      return this.$store.state.hcptModule.additionalReporting.staffDemographics.classifiedStaff
    },
    genderStats() {
      return this.$store.state.hcptModule.additionalReporting.staffDemographics.genderStatistics
    },
    noviceTeacherStats() {
      return this.$store.state.hcptModule.additionalReporting.staffDemographics.noviceTeachers
    },
    staffDetails() {
      return this.$store.state.hcptModule.additionalReporting.staffDetails
    },
    staffDetailsVisible() {
      //only show staff details at school or district level
      return this.isAuthenticated && this.selectedLea && this.selectedLea.type <= 2
    },
    ...mapGetters('globalModule', ['isAuthenticated'])
  },
  components: {
    StaffDemographicsChart,
    StaffDemographicPercentagesChart,
    AverageYearsTeachingExperienceChart,
    NoviceTeacherPercentagesChart,
    StaffDetailsWidget,
    EmploymentByGenderChart,
    TotalTeacherChart,
  },
  methods: {
    normalizeFiscalYear(data) {
      return data.map(d => {
        return {...d, fiscalYear: d.fy}
      })
    },
    loadStaff() {
      if (this.isAuthenticated) {
        this.loadStaffDetail = this.$store.dispatch('hcptModule/getStaffDetails', {
          lea: this.selectedLea.number
        })
      }
    }
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if (newLea && newLea.number) {
          this.loadData = Promise.all([
            this.$store.dispatch('hcptModule/getStaffDemographics', { lea: newLea.number }),
            this.$store.dispatch('hcptModule/getNewTeachersByRace', { lea: newLea.number }),
            this.$store.dispatch('hcptModule/getNoviceTeachersByRace', { lea: newLea.number }),
          ])

          this.loadStaff()
        }
      }
    }
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <district-info-section :request-lea="currentRequestDistrictInfo" />
        <div class="row mb-2" v-if="editMode">
          <div class="col-md-9" v-if="allowFormItemModification">
            <div>
              <b-button-group class="mr-2" v-if="false">
                <b-dropdown right text="District Changes" :disabled="this.operation != null">
                  <b-dropdown-item
                    @click="initiateOperation(RequestOperationPurpose.ChangeDistrict)"
                  >
                    <i class="fa fa-fw fa-edit" />Change Existing District
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="initiateOperation(RequestOperationPurpose.NewDistrict)"
                    v-if="userContext.hasRole('Insights_CharterSchoolOffice_Administrators') || userContext.hasRole('Insights_RT-SMS_Administrators')"
                  >
                    <i class="fa fa-fw fa-plus-circle" />New District
                  </b-dropdown-item>
                </b-dropdown>
              </b-button-group>

              <b-button-group class="mr-2" v-if="false">
                <b-dropdown right text="Building Level Changes" :disabled="this.operation != null">
                  <b-dropdown-item
                    @click="initiateOperation(RequestOperationPurpose.BuildingAnnexation)"
                  >
                    Annexation
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="initiateOperation(RequestOperationPurpose.BuildingCombination)"
                  >
                    Consolidation
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="initiateOperation(RequestOperationPurpose.DistrictReconfiguration)"
                  >
                    Reconfiguration
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="initiateOperation(RequestOperationPurpose.ChangeBuildingLEA)"
                  >
                    Change Building LEA
                  </b-dropdown-item>
                  <b-dropdown-item @click="initiateOperation(RequestOperationPurpose.General)">
                    Other
                  </b-dropdown-item>
                </b-dropdown>
              </b-button-group>

              <b-button
                variant="success"
                class="mr-2"
                @click="addItem('new')"
                :disabled="!operationAllowed('new')"
                v-if="operationVisible('new')"
              >
                <i class="fa fa-fw fa-plus-circle" />New Building
              </b-button>
              <b-button
                variant="primary"
                class="mr-2"
                @click="addItem('edit-school')"
                title="Changes allowed to School Name, Address, or Grade Span"
                :disabled="!operationAllowed('edit-school')"
                v-if="operationVisible('edit-school')"
              >
                <i class="fa fa-fw fa-edit" />Change Building
              </b-button>
              <b-button
                variant="warning"
                class="mr-2"
                @click="addItem('inactivate')"
                :disabled="!operationAllowed('inactivate')"
                v-if="operationVisible('inactivate')"
              >
                <i class="fa fa-fw fa-times-circle" />Close Building
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="requestTitle">
      <h3 class="col-md-8">
        {{ requestTitle }}
      </h3>
      <div class="col-md-1 text-right" v-if="false">
        <b-button variant="danger" title="Reset Form" @click="resetForm">
          <i class="fa fa-undo" />
        </b-button> 
      </div>
      <div class="col-md-4 text-right">
        <b-button class="btn btn-info mr-2" @click="savePDF">
          <i class="fa-fw far fa-file-pdf" />PDF
        </b-button>
        <b-button 
          variant="primary" 
          @click="saveRequest"
          v-if="!readOnly"
        >
          <i :class="saveIconClass" />Save
        </b-button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-8">
        <div v-for="i in newDistrictRequestItems" :key="i.key">
          <request-item
            :item="i"
            @remove="removeItem($event)"
            title="New District LEA Information"
            :default-options="{allowRemove: false}"
            header-class="bg-primary"
          >
            <new-district-item-edit v-if="editMode" :item="i" :operation="operation" />
            <new-district-item-view v-if="!editMode" :item="i" :operation="operation" />
          </request-item>
        </div>
        <div v-for="i in editDistrictRequestItems" :key="i.key">
          <request-item
            :item="i"
            @remove="removeItem($event)"
            title="Change District LEA Request"
            :default-options="{allowRemove: false}"
            header-class="bg-primary"
          >
            <change-district-lea-item-edit v-if="editMode" :item="i" :operation="operation" />
            <change-district-lea-item-view v-if="!editMode" :item="i" :operation="operation" />
          </request-item>
        </div>
        <div v-for="i in newLeaRequestItems" :key="i.key">
          <request-item
            :item="i"
            @remove="removeItem($event)"
            title="New Building LEA Information"
            header-class="bg-success"
            :default-options="{allowRemove: editMode && allowFormItemModification}"
          >
            <new-lea-item-edit v-if="editMode" :item="i" :operation="operation" />
            <new-lea-item-view v-if="!editMode" :item="i" :operation="operation" />
          </request-item>
        </div>
        <div v-for="i in inactivateRequestItems" :key="i.key">
          <request-item
            :item="i"
            @remove="removeItem($event)"
            title="Inactivate LEA Request"
            :default-options="{allowRemove: editMode && allowFormItemModification}"
            header-class="bg-warning text-dark"
          >
            <inactivate-lea-item-edit v-if="editMode" :item="i" :operation="operation" />
            <inactivate-lea-item-view v-if="!editMode" :item="i" :operation="operation" />
          </request-item>
        </div>
        <div v-for="i in editSchoolRequestItems" :key="i.key">
          <request-item
            :item="i"
            @remove="removeItem($event)"
            title="Change School LEA Request"
            :default-options="{allowRemove: editMode && allowFormItemModification}"
            header-class="bg-primary"
          >
            <change-school-lea-item-edit v-if="editMode" :item="i" :operation="operation" />
            <change-school-lea-item-view v-if="!editMode" :item="i" :operation="operation" />
          </request-item>
        </div>
        <div v-if="currentRequest && currentRequest.requestId">
          <request-documents-widget :request-id="currentRequest.requestId" />
        </div>
      </div>
      <div class="col-md-4">
        <approval-widget
          :read-only="readOnly"
          :request="currentRequest"
          :on-before-state-change="saveRequest"
          @status-changed="onStatusChanged"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NewLeaItemEdit from '../requestItemComponents/edit/newLeaItem'
import NewDistrictItemEdit from '../requestItemComponents/edit/newDistrictItem'
import ChangeSchoolLeaItemEdit from '../requestItemComponents/edit/changeSchoolLeaItem'
import ChangeDistrictLeaItemEdit from '../requestItemComponents/edit/changeDistrictLeaItem'
import InactivateLeaItemEdit from '../requestItemComponents/edit/inactivateLeaItem'

import NewLeaItemView from '../requestItemComponents/view/newLeaItem'
import NewDistrictItemView from '../requestItemComponents/view/newDistrictItem'
import ChangeSchoolLeaItemView from '../requestItemComponents/view/changeSchoolLeaItem'
import ChangeDistrictLeaItemView from '../requestItemComponents/view/changeDistrictLeaItem'
import InactivateLeaItemView from '../requestItemComponents/view/inactivateLeaItem'

import ApprovalWidget from '../approvalWidget'
import RequestItem from '../requestItemComponents/requestItem'
import RequestDocumentsWidget from '../requestDocuments/RequestDocumentsWidget'
import DistrictInfoSection from '../districtInfoSection'
import store from '../../../../stores'
import shortid from 'shortid'
import { mapState, mapGetters } from 'vuex'
import { RequestType, LeaAssignmentRoles, RequestOperationPurpose, requestOperationFormatter } from '../../leaAssignment'

const editableStates = [0, 1, 3]

function RequestItemObject(type, options) {

  options = options || {} 
  
  var typeCode= null;

  if (type == 'new') {
    typeCode = options.charter ? "SC" : "S"
  } else if (type == 'new-district') {
    typeCode = options.charter ? "DC" : "D"
  }
  
  return {
          itemType: type,
          key: shortid.generate(),
          initialLea: options.lea,
          reconfigurationCode: options.reconfigurationCode || null,
          selectedLea: null,
          leaTypeCode: typeCode,
          isFeederSchool: false,
          pairedSchool: null,
          isPairedSchool: false,
          feederSchools: [],
          currentValues: {}
    }
}

export default {
  name: 'edit-request',
  props: {
    readOnly: Boolean
  },
  data() {
    return {
      RequestOperationPurpose,
      operation: null,
      busy: false,
      errors: []
    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.name == 'create-new-request') {
      // create new request and redirect to edit
      store
        .dispatch('toolsModule/leaAssignment/createNewRequest', {
          districtLEA: to.query.lea,
          requestType: RequestType.LEAChangeRequest,
          initialResponse: {
            operation: to.params.purpose,
            items: []
          }
        })
        .then(response => {
          //console.log('routing next')
          next({
            name: 'edit-request',
            replace: true,
            params: { id: response.requestId, initializeFor: to.params.purpose },
            query: to.query
          })
        })
    } else {
      store
        .dispatch('toolsModule/leaAssignment/getRequest', {
          requestId: to.params.id
        })
        .then(() => {
          store.dispatch('toolsModule/leaAssignment/getReconfigurationCodes')
        })
        .then(() =>
          store.dispatch('requestModule/getAvailableStates', {
            requestId: to.params.id
          })
        )
        .then(() => {
          store.dispatch('toolsModule/leaAssignment/getDistrict', {
            lea: store.getters['toolsModule/leaAssignment/currentRequest'].districtLEA,
            fy: store.getters['toolsModule/leaAssignment/currentRequest'].fiscalYear
          })
        })
        .then(() => {
          store.dispatch('toolsModule/leaAssignment/getSchools', {
            lea: store.getters['toolsModule/leaAssignment/currentRequest'].districtLEA,
            fy: store.getters['toolsModule/leaAssignment/currentRequest'].fiscalYear
          })
        })
        .then(() => next())
    }
  },
  mounted() {
    const op = this.$router.currentRoute.query.op
    if (op) {
      this.addItem(op, { lea: this.$router.currentRoute.query.lea })
    }

    this.operation = this.currentResponse.operation

    this.$store.dispatch('toolsModule/leams/getLeaTypes')

    if (this.$route.params.initializeFor) {
      this.initiateOperation(Number(this.$route.params.initializeFor))
    }
  },
  computed: {
    ...mapState('toolsModule/leaAssignment', ['currentRequest', 'currentResponse', 'district']),
    ...mapGetters('toolsModule/leaAssignment', ['moduleFiscalYear']),
    ...mapState('globalModule', ['leaContext', 'userContext']),

    charterMode() {
      return this.$route.query.charter
    },

    requestTitle() {
      return requestOperationFormatter(this.operation)
    },
    allowFormItemModification() {
      return this.operation == RequestOperationPurpose.General || this.operation == RequestOperationPurpose.DistrictReconfiguration || this.operation == RequestOperationPurpose.NewDistrict
    },
    operationRules() {
      switch (this.operation) {
        case RequestOperationPurpose.BuildingAnnexation:
          return {
            inactivate: 1,
            'edit-school': 1
          }
        case RequestOperationPurpose.BuildingCombination:
          return {
            inactivate: 2,
            new: 1
          }
        case RequestOperationPurpose.ChangeDistrict:
          return {
            'edit-district': 1
          }
        case RequestOperationPurpose.ChangeBuildingLEA:
          return {
            inactivate: 1,
            new: 1
          }
        case RequestOperationPurpose.NewDistrict:
          return {
            new: 0
          }
        default:
          return {
            inactivate: 1,
            new: 1,
            'edit-school': 1
          }
      }
    },
    editMode() {
      return editableStates.includes(this.currentRequest.requestStatus)
    },
    saveIconClass() {
      return this.busy ? 'fas fa-spinner fa-spin' : 'fa fa-fw far fa-save'
    },
    hasAnyRequests() {
      return this.currentResponse.items?.length > 0
    },
    editSchoolRequestItems() {
      return this.currentResponse.items?.filter(i => i.itemType == 'edit-school') || []
    },
    editDistrictRequestItems() {
      return this.currentResponse.items?.filter(i => i.itemType == 'edit-district') || []
    },
    newLeaRequestItems() {
      return this.currentResponse.items?.filter(i => i.itemType == 'new') || []
    },
    inactivateRequestItems() {
      return this.currentResponse.items?.filter(i => i.itemType == 'inactivate') || []
    },
    newDistrictRequestItems() {
      return this.currentResponse.items?.filter(i => i.itemType == 'new-district') || []
    },
    hasNewRequest() {
      return this.newLeaRequestItems.length > 0
    },
    hasInactivateRequest() {
      return this.inactivateRequestItems.length > 0
    },
    hasNewDistrictRequest() {
      return this.newDistrictRequestItems.length > 0
    },
    hasDistrictChangeRequest() {
      return this.editDistrictRequestItems.length > 0
    },
    currentRequestDistrictInfo() {
      return this.leaContext.LeaDictionary[this.currentRequest.districtLEA]
    }
  },
  methods: {
    requestCount(requestType) {
      return (this.currentResponse.items?.filter(i => i.itemType == requestType) || []).length
    },
    operationVisible(itemOperation) {
      switch (this.operation) {
        case RequestOperationPurpose.BuildingAnnexation:
          return false
        case RequestOperationPurpose.BuildingCombination:
          return false
        case RequestOperationPurpose.ChangeBuildingLEA:
          return false;
        case RequestOperationPurpose.ChangeDistrict:
          return false;
        case RequestOperationPurpose.NewDistrict: 
          return itemOperation == 'new' //if we are adding a new district, the only thing that makes sense to do is to add a new school
        case RequestOperationPurpose.General:
          if (this.charterMode) {
            return itemOperation == 'new' 
          } 
          else {
            return true
          }
        default:
          return true
        }
    },
    operationAllowed(operation) {
      return (
        this.operation != null &&
        this.operationRules[operation] != null &&
        (this.operationRules[operation] === 0 || this.operationRules[operation] > this.requestCount(operation))
      )
    },

    initiateOperation(operation) {
      this.operation = operation
      this.$store.dispatch('toolsModule/leaAssignment/setResponseOperationType', this.operation)

      switch (this.operation) {
        case RequestOperationPurpose.BuildingAnnexation:
          this.addItem('edit-school', { reconfigurationCode: '5A' })
          this.addItem('inactivate', { reconfigurationCode: '5B' })
          break
        case RequestOperationPurpose.BuildingCombination:
          this.addItem('new', { reconfigurationCode: '6A' })
          this.addItem('inactivate', { reconfigurationCode: '6B' })
          this.addItem('inactivate', { reconfigurationCode: '6B' })
          break
        case RequestOperationPurpose.ChangeBuildingLEA:
          this.addItem('new', { reconfigurationCode: '4A' })
          this.addItem('inactivate', { reconfigurationCode: '4B' })
          break
        case RequestOperationPurpose.ChangeDistrict:
          this.addItem('edit-district', { reconfigurationCode: '10A' })
          break
        case RequestOperationPurpose.NewDistrict:
          this.addItem('new-district', { reconfigurationCode: '9A' })
          this.addItem('new', { reconfigurationCode: '9C' })
          break
      }
    },

    async addItem(type, options) {
      options = options || {}

      options.charter = this.userContext.hasRole(LeaAssignmentRoles.CharterOfficeAdmin)

      if (!this.currentResponse.items) {
        this.currentResponse.items = []
      }

      var item = new RequestItemObject(type, options)

      if (type === 'edit-district') {

        await store.dispatch('toolsModule/leaAssignment/getDistrict', {
            lea: this.currentRequest.districtLEA,
            fy: this.currentRequest.fiscalYear
          })

        var district = this.district || {}

        item = Object.assign(item, {
          leaName: district.name,
          address: district.address,
          city: district.city,
          state: district.state,
          zip: district.zip,
          zip4: district.zip4,
          selectedLea: district.leaNumber,
          currentValues: {
            leaName: district.name,
            address: district.address,
            city: district.city,
            state: district.state,
            zip: district.zip,
            zip4: district.zip4
          }
        })
      }

      this.$store.dispatch('toolsModule/leaAssignment/addRequestItem', item)
    },
    removeItem(key) {
      this.$store.dispatch('toolsModule/leaAssignment/removeRequestItem', key)
    },
    async resetForm() {

      var result = await this.$bvModal.msgBoxConfirm('Are you sure you want to reset the form?')

      if (result) {
        this.currentResponse.items.forEach(i=>this.removeItem(i.key))
        this.operation = null
      }
    },
    validateItems() {
      var self = this
      self.errors = []
      self.$children
        .flatMap(c => c.$children)
        .forEach(item => {
          if (typeof item.validate === 'function') {
            self.errors = self.errors.concat(item.validate())
          }
        })
    },
    saveRequest() {
      if (this.editMode) {
        this.validateItems()
        if (this.errors.length > 0) {
          return
        }
        this.busy = true
        return this.$store.dispatch('toolsModule/leaAssignment/updateRequest').then(() => {
          this.busy = false
          this.$bvToast.toast('Saved', {
            title: `Request saved`,
            variant: 'success',
            solid: true
          })
        })
      } else {
        return Promise.resolve(true)
      }
    },
    savePDF() {
      window.location.href = `/tools/lea-assignment/requestpdf/${this.currentRequest.requestId}?response-format=pdf`
    },
    onStatusChanged(event) {
      if (event.status == 100) {
        return this.$router.push(`/tools/lea-assignment/requests/${this.currentRequest.requestId}/finalize`)
      } else {
        return this.$router.push('/tools/lea-assignment/requests')
      }
    }
  },
  components: {
    NewLeaItemEdit,
    NewDistrictItemEdit,
    ChangeSchoolLeaItemEdit,
    ChangeDistrictLeaItemEdit,
    InactivateLeaItemEdit,

    NewLeaItemView,
    NewDistrictItemView,
    ChangeSchoolLeaItemView,
    ChangeDistrictLeaItemView,
    InactivateLeaItemView,

    ApprovalWidget,
    RequestItem,
    RequestDocumentsWidget,
    DistrictInfoSection
  }
}
</script>

<template>
  <div class="mt-3">
    <div>
      <router-link :to="{name: 'CTE/CareerCoach/Admin/Users/Invite'}" class="m-2 btn btn-primary float-right">
        Invite IHE User
      </router-link>
    </div>
    <b-form inline class="my-2">
      <b-form-group label="Role">
        <b-form-select v-model="selectedRole" :options="roleOptions" />
      </b-form-group>
    </b-form>
    <paginated-table hover :items="filteredItems" :fields="fields">
      <template v-slot:cell(roles)="data">
        <div>
          <span v-for="role in (data.value || [])" :key="role">
            {{ CareerCoachRolesDisplay[role] }}
          </span>
        </div>
      </template>
      <template v-slot:cell(isEnabled)="data">
        <div v-if="data.value">
          <i class="fa-lg fas fa-check text-success" />
        </div>
      </template>
      <template v-slot:cell(isConfirmed)="data">
        <div v-if="data.value">
          <i class="fa-lg fas fa-check text-success" />
        </div>
      </template>
      <template v-slot:cell(leas)="data">
        <div>
          <span v-for="lea in (data.value || [])" :key="lea">
            {{ leaFormatter(lea) }}
          </span>
        </div>
      </template>
      <template v-slot:cell(action)="data">
        <div v-if="supportsExtendedClaims(data.item)">
          <router-link :to="{ name: 'CTE/CareerCoach/Admin/Users/Edit', query: { lea: selectedLea.number, userId: data.item.userId }, params: { ...data.item } }">
            <i class="fas fa-edit" />
          </router-link>
        </div>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import PaginatedTable from '@/components/PaginatedTable'
import { CareerCoachRolesDisplay, CareerCoachRoles } from '@/modules/cte/components/common/Roles'

export default {
  data() {
    return {
      CareerCoachRolesDisplay: CareerCoachRolesDisplay,
      selectedRole: null
    }
  },
  props: {
    items: Array,
  },
  computed: {
    filteredItems() {
      if (this.selectedRole) {
        return this.items.filter(i => i.roles.some(r => r == this.selectedRole))
      }
      return this.items
    },
    fields() {
      let fields = [
        { key: 'fullName', label: 'Name', sortable: true },
        { key: 'userId', label: 'Username', sortable: true },
        { key: 'emailAddress', label: 'Email', sortable: true },
        { key: 'telephoneNumber', label: 'Phone', sortable: true },
        { key: 'isEnabled', label: 'Enabled', sortable: true },
        { key: 'isConfirmed', label: 'Confirmed', sortable: true },
        { key: 'roles', label: 'Role(s)' },
      ]
      return [...fields, { key: 'action', label: '' }]
    },
    roleOptions() {
      return [{ text: 'All roles', value: null }, ...Object.keys(CareerCoachRolesDisplay).map(key => { 
        return {
          text: CareerCoachRolesDisplay[key],
          value: key
        }
      })]
    } 
  },
  methods: {
    supportsExtendedClaims(user) {
      return user.roles.some(role => role == CareerCoachRoles['Supervisor'] || role == CareerCoachRoles['Counselor'])
    },
  },
  components: {
    PaginatedTable
  }
}
</script>

import Vue from 'vue'
import LoadableChartCard from './LoadableChartCard.vue'
import ChartDataTable from './ChartDataTable.vue'
import CustomTooltip from './CustomTooltip.vue'

const ChartMixin = Vue.extend({
  props: {
    loadData: Promise,
    //selectedLea: Object
  },
  methods: {
    defaultExcelExportData() {
      return {
        name: this.chartTitle || this.chartOptions.title.text,
        items: this.dataItems()
      }
    }
  },
  components: {
    LoadableChartCard,
    ChartDataTable,
    CustomTooltip
  }
})
export { ChartMixin }
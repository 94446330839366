<template>
  <b-card header="Work-based Learning Experiences">
    <!-- CTE Goal: Increase the percent of students grade 8 – 12 who complete a work-based learning experience -->
    <!-- Bar chart of % of students who have one or more WBL items logged for their current grade range Bar chart of % of students who have one or more WBL items
    logged for any previous grade range -->
    <highcharts class="chart" ref="chartRef" :callback="chartLoad" :options="chartOptions" />
  </b-card>
</template>

<script>
const loadingSpinner = '<i class="fas fa-spinner fa-spin"></i> Loading...'

import { Types } from '../../../../stores/careercoach/index.js'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loadingSpinner: loadingSpinner
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.dashboard]),
    exposure() {
      return this.dashboard['exposure']
    },
    grade8() {
      return this.exposure.find(v => v['grade'] == '08') || {}
    },
    grade9() {
      return this.exposure.find(v => v['grade'] == '09') || {}
    },
    grade10() {
      return this.exposure.find(v => v['grade'] == '10') || {}
    },
    grade11() {
      return this.exposure.find(v => v['grade'] == '11') || {}
    },
    grade12() {
      return this.exposure.find(v => v['grade'] == '12') || {}
    },

    seriesCurrentGrade() {
      return [
        {
          y: (this.grade8['countOfStudentsWithExposureAtCurrentGradeLevel'] / this.grade8['totalEnrollment']) * 100.0,
          with: this.grade8['countOfStudentsWithExposureAtCurrentGradeLevel'],
          total: this.grade8['totalEnrollment']
        },
        {
          y: (this.grade9['countOfStudentsWithExposureAtCurrentGradeLevel'] / this.grade9['totalEnrollment']) * 100.0,
          with: this.grade9['countOfStudentsWithExposureAtCurrentGradeLevel'],
          total: this.grade9['totalEnrollment']
        },
        {
          y: (this.grade10['countOfStudentsWithExposureAtCurrentGradeLevel'] / this.grade10['totalEnrollment']) * 100.0,
          with: this.grade10['countOfStudentsWithExposureAtCurrentGradeLevel'],
          total: this.grade10['totalEnrollment']
        },
        {
          y: (this.grade11['countOfStudentsWithExposureAtCurrentGradeLevel'] / this.grade11['totalEnrollment']) * 100.0,
          with: this.grade11['countOfStudentsWithExposureAtCurrentGradeLevel'],
          total: this.grade11['totalEnrollment']
        },
        {
          y: (this.grade12['countOfStudentsWithExposureAtCurrentGradeLevel'] / this.grade12['totalEnrollment']) * 100.0,
          with: this.grade12['countOfStudentsWithExposureAtCurrentGradeLevel'],
          total: this.grade12['totalEnrollment']
        }
      ]
    },
    seriesAnyGrade() {
      return [
        {
          y: (this.grade8['countOfStudentsWithAnyExposure'] / this.grade8['totalEnrollment']) * 100.0,
          with: this.grade8['countOfStudentsWithAnyExposure'],
          total: this.grade8['totalEnrollment']
        },
        {
          y: (this.grade9['countOfStudentsWithAnyExposure'] / this.grade9['totalEnrollment']) * 100.0,
          with: this.grade9['countOfStudentsWithAnyExposure'],
          total: this.grade9['totalEnrollment']
        },
        {
          y: (this.grade10['countOfStudentsWithAnyExposure'] / this.grade10['totalEnrollment']) * 100.0,
          with: this.grade10['countOfStudentsWithAnyExposure'],
          total: this.grade10['totalEnrollment']
        },
        {
          y: (this.grade11['countOfStudentsWithAnyExposure'] / this.grade11['totalEnrollment']) * 100.0,
          with: this.grade11['countOfStudentsWithAnyExposure'],
          total: this.grade11['totalEnrollment']
        },
        {
          y: (this.grade12['countOfStudentsWithAnyExposure'] / this.grade12['totalEnrollment']) * 100.0,
          with: this.grade12['countOfStudentsWithAnyExposure'],
          total: this.grade12['totalEnrollment']
        }
      ]
    },
    chartOptions() {
      return {
        chart: {
          type: 'column',
          height: 430,
          spacing: [20, 20, 20, 20],
          backgroundColor: '#fff',
          plotBackgroundColor: '#eee',
          inverted: true
        },
        loading: {
          style: {
            position: 'absolute',
            backgroundColor: '#ffffff',
            opacity: 0.8,
            textAlign: 'center'
          }
        },
        title: {
          text: 'Percent of Students with Work-Based Learning Experiences'
        },
        exporting: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        legend: {
          enabled: true
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: ['Grade 8', 'Grade 9', 'Grade 10', 'Grade 11', 'Grade 12']
        },
        yAxis: {
          min: 0,
          max: 100,
          title: {
            text: 'Percent of Students'
          }
        },
        plotOptions: {
          column: {
            minPointLength: 2
          }
        },
        series: [
          {
            name: 'Experiences in Current Grade',
            data: this.seriesCurrentGrade,
            dataLabels: {
              enabled: true,
              formatter() {
                return `${Math.round(this.point.y)}% (${this.point.with.toLocaleString()}/${this.point.total.toLocaleString()})`
              }
            }
          },
          {
            name: 'Experiences in Any Grade',
            data: this.seriesAnyGrade,
            dataLabels: {
              enabled: true,
              formatter() {
                return `${Math.round(this.point.y)}% (${this.point.with.toLocaleString()}/${this.point.total.toLocaleString()})`
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    dashboard: {
      immediate: true,
      handler() {
        this.chartLoad()  
      }
    }
  },
  methods: {
    chartLoad(chart) {
      if (chart) {
        if (this.dashboard.currentRoster.length == 0) {
          chart.showLoading(loadingSpinner)
        }
      }
      else if (this.$refs["chartRef"]) {
        if (this.dashboard.currentRoster.length > 0) {
          this.$refs["chartRef"].chart.hideLoading()
        }
        else
        {
          this.$refs["chartRef"].chart.showLoading(loadingSpinner)
        }
      }
    }
  }
}
</script>
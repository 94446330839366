
const PerkinsVRoles = {
  'CTE Coordinator': 'Insights_PerkinsV_Application_Coordinators',
  'Director': 'Insights_PerkinsV_Director',
  'Perkins Administrator': 'Insights_PerkinsV_Administrators',
  'Program Advisor': 'Insights_PerkinsV_Program_Advisors',
  'Program Coordinator': 'Insights_PerkinsV_Program_Coordinators',
  // 'Account Manager': 'Insights_PerkinsV_Account_Managers',
}

const PerkinsVRolesDisplay = Object.assign(...Object.entries(PerkinsVRoles).map(([a,b]) => ({ [b]: `${a}s` })))
// eslint-disable-next-line no-unused-vars
const PerkinsVADERoles = ['Insights_PerkinsV_Program_Coordinators', 'Insights_PerkinsV_Program_Advisors', 'Insights_PerkinsV_Administrators']

const CareerCoachRoles = {
  'Admin': 'Insights_CareerCoach_Admin',
  'Read Only': 'Insights_CareerCoach_ReadOnly',
  'Counselor': 'Insights_CareerCoach_Counsellors',
  'Supervisor': 'Insights_CareerCoach_Supervisors',
}
const CareerCoachRolesDisplay = Object.assign({}, ...Object.entries(CareerCoachRoles).map(([a,b]) => ({ [b]: a })))

export { PerkinsVRoles, PerkinsVRolesDisplay, PerkinsVADERoles, CareerCoachRoles, CareerCoachRolesDisplay }
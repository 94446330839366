<template>
  <div>  
    <b-table :fields="fields" :items="InspectionItems" v-if="mode=='view'">
      <template v-slot:head(action)="">
        <b-button @click="onBeginAdd" variant="primary">
          <i class="fa fa-fw fa-plus-circle" /> Add
        </b-button>
      </template>
      <template v-slot:cell(action)="data">
        <command-buttons
          :options="{showAdd: false, showEdit: true, showDelete: true, operation:'view'}"
          @on-edit="onRowEdit(data.item)"
          @on-delete="onRowDelete(data.item)"
        />
      </template>
    </b-table>

    <edit-inspection-item :item="editingItem" v-if="mode != 'view'" :mode="mode" @on-cancel="onCancelEdit" @on-save="onCommitChange" />    
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CommandButtons from '../../../shared/CommandButtons'
import EditInspectionItem from './editInspectionItem'
import { Categories } from './inspectionCategories'

export default {
  name: 'inspection-items',
  data(){
    return {
      mode: 'view',
      selectedItem: null,
      editingItem: null,
      fields:[
        { key: 'itemName', sortable: true },
        { key: 'category', formatter: value => this.formatCategory(value), sortable: true},
        { key: 'action', thClass: 'text-right'}
      ]
    }
  },
  mounted(){
    this.$store.dispatch('DPSAFT/Facilities/getInspectionItems')
  },
  methods: {
    onRowEdit(item) {
      this.selectedItem = item
      this.editingItem = Object.assign({}, item)
      this.mode = 'edit'
    },
    onBeginAdd() {
      this.editingItem = {
        itemName: null,
        category: null,
        inspectionItemId: 0,
        isTransportation: false,
        enabled: true,
        orderBy: this.InspectionItems.length
      }
      this.mode = 'add'
    },
    onCancelEdit() {
      this.mode = 'view'
      this.editingItem = null
    },
    onCommitChange() {      
      this.$store
        .dispatch('DPSAFT/Facilities/getInspectionItems')
        .then(() => this.mode = 'view')
    },
    onRowDelete(item) {
      this.$store
        .dispatch('DPSAFT/Admin/inactivateFacilitiesInspectionItem', item.inspectionItemId)
        .then(() => this.$store.dispatch('DPSAFT/Facilities/getInspectionItems'))
        .then(() => (this.mode = 'view'))
    },
    formatCategory(item){
      return Categories[item-1].text
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState('DPSAFT/Facilities', ['InspectionItems'])
  },
  components: {
    CommandButtons,
    EditInspectionItem
  }
}
</script>

import Vue from 'vue'
import LeaDisplay from './LeaDisplay.vue'
import LeaIcon from './LeaIcon.vue'
import PdfButton from './PdfButton.vue'
import ExcelButton from './ExcelButton.vue'
import CustomTooltip from './CustomTooltip.vue'
import { LeaTypes } from '../helpers/leaTypes'

const PageMixin = Vue.extend({
  data() {
    return {
      LeaTypes: LeaTypes
    }
  },
  computed: {
    selectedLea() { return this.$store.state.globalModule.leaContext.SelectedLea },
    leaType() { return this.selectedLea.type },
    debugMessages () { return this.$store.state.globalModule.debugMessages }
  },
  components: {
    PdfButton,
    ExcelButton,
    LeaIcon,
    LeaDisplay,
    CustomTooltip
  }
})
export { PageMixin }
<template>
  <div>
    <h2>Colleges, Degrees, &amp; Majors</h2>
    
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Colleges" active>
          <b-card-text>
            <colleges-list />
          </b-card-text>
        </b-tab>
        <b-tab title="Degrees">
          <b-card-text>
            <degrees-list />
          </b-card-text>
        </b-tab>
        <b-tab title="Majors">
          <b-card-text>
            <majors-list />
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { Types } from '@/modules/cte/stores/careercoach'
import CollegesList from './CollegesList.vue'
import DegreesList from './DegreesList.vue'
import MajorsList from './MajorsList.vue'

export default {
  data() {
    return {
    }
  },
  components: {
    CollegesList,
    DegreesList,
    MajorsList
  },
  mounted() {
    Promise.all([
      this.$store.dispatch(Types.actions.getColleges),
      this.$store.dispatch(Types.actions.getDegrees),
      this.$store.dispatch(Types.actions.getMajors)
    ])
  }
}
</script>
<template>
  <div class="row">
    <div class="col-md-12 text-center">
      <label style="margin-right: 8px"><input type="radio" id="by-name" name="search-by" value="name" checked="" v-model="value" @input="$emit('input', $event.target.value)"> Name</label>
      <label style="margin-right: 8px" class="mr-1"><input type="radio" id="by-lea" name="search-by" value="number" v-model="value" @input="$emit('input', $event.target.value)"> LEA Number</label>
    </div>
    <div class="col-md-12 text-center">
      <div id="navs-by-name" v-if="value == 'name'">
        <a class="btn btn-sm btn-warning" :href="'#group-anchor-' + letter" v-for="letter in firstLetters" :key="letter">{{ letter }}</a>
      </div>
      <div id="navs-by-lea" v-if="value == 'number'">
        <a class="btn btn-sm btn-warning" :href="'#group-anchor-' + number" v-for="number in firstNumbers" :key="number">{{ number }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'lea-letter-jumplist',
  data() {
    return {
      
    }
  },
  props: {
    leas: Array,
    value: String
  },
  computed: {
    firstLetters() {
      return [...new Set(this.leas.map(l => l.name[0].toUpperCase()))].sort()
    },
    firstNumbers() {
      return [...new Set(this.leas.map(l => l.number[0].toUpperCase()))].sort()
    }
  }
}
</script>

<style lang="scss" scoped>
  #navs-by-name > a.btn, #navs-by-lea > a.btn {
    margin: 0 .5em .5em 0;
    color: white;
  }
</style>
<template>
  <div v-show="render">
    <div>
      <h4 class="text-dark">         
        Appendix<!-- <i v-b-toggle.collapse-info class="ml-2 fas fa-info-circle fa-sm fa-fw text-primary info-icon" title="Show More Information" /> -->
      </h4>

      <!-- <b-collapse id="collapse-info" class="mb-3" visible>
        <b-card-header class="mb-0">
          <b-card-title v-b-toggle.collapse-info class="mb-0">
            <b-icon-x />Information
          </b-card-title>
        </b-card-header>
        <b-card class="mb-2">
          <b-card-text>
            
          </b-card-text>
        </b-card>
      </b-collapse> -->
    </div>

    <div v-if="teacherVAMData">
      <summary-by-subject-and-grade-level :render="render" />

      <div v-for="(subject, index) in ['ELA', 'MATH', 'SCI']" :key="subject + '-t-' + index">
        <count-and-percentage-teachers-by-subject-and-grade-level :subject="subject" :render="render" :table="index + 10" />
      </div>

      <div class="figure">
        <div class="figure-caption">
          Figure 8: Distribution of Teacher Value-Added Scores by Subject for Schools Exempt from Letter Grades
        </div>
        <div v-for="(subject, index) in ['ELA', 'MATH', 'SCI']" :key="subject + '-f-' + index">
          <distribution-teacher-value-added-growth-scores-by-subject-schools-exempt-from-letter-grades :subject="subject" :render="render" />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
        <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
      </div>
    </div>
  </div>
</template>

<script>
import SummaryBySubjectAndGradeLevel from '../tables/SummaryBySubjectAndGradeLevel.vue'
import CountAndPercentageTeachersBySubjectAndGradeLevel from '../tables/CountAndPercentageTeachersBySubjectAndGradeLevel.vue'
import DistributionTeacherValueAddedGrowthScoresBySubjectSchoolsExemptFromLetterGrades from '../charts/DistributionTeacherValueAddedGrowthScoresBySubjectSchoolsExemptFromLetterGrades.vue'
import { mapState } from 'vuex';
import CardStateIcon from '../../../CardStateIcon.vue'

export default {
  components: {
    SummaryBySubjectAndGradeLevel,
    CountAndPercentageTeachersBySubjectAndGradeLevel,
    DistributionTeacherValueAddedGrowthScoresBySubjectSchoolsExemptFromLetterGrades,
    CardStateIcon
  },
  props: {
    render: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('teacherVAMModule', ['teacherVAMData']),
  }
}
</script>
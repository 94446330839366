<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div>
        <div class="chartElem" v-show="slotProps.chartMode">
          <highcharts class="chart" :options="chartOptions" />
        </div>
        <div v-show="!slotProps.chartMode">
          <chart-data-table :items="dataItems()" />
        </div>
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { DemographicSubpopulations } from '../../subpopulations'
import { credits } from '../../../helpers/credits'

export default {
  mixins: [ChartMixin],
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'line'
        },
        credits: credits.MSI,
        title: {
          text: this.chartTitle
        },
        series: DemographicSubpopulations,
        xAxis: {
          title: {
            text: 'School Year'
          },
          type: 'category'
        },
        tooltip:{
          enabled: true,
          formatter: this.tooltipFormatter
        },
        yAxis: {
          title: {
            text: this.yAxisLabel
          },
          endOnTick: false,
          labels: {
            formatter: this.yAxisFormatter
          }
        },
        plotOptions: {
          series: {
            minPointLength: 1
          }
        }
      }
    }
  },
  watch: {
    enrollment(newData) {
      this.chartOptions.series = DemographicSubpopulations.map(subpop => {
        return Object.assign(
          { ...subpop },
          {
            data: newData.map(row => {
              return {
                x: (row.fiscalYear % 1990) + 1990,
                y: row[subpop.field] * this.valueMultiplier,
                color: subpop.color,
                tooltipSuffix: this.tooltipSuffix,
                ...row
              }
            })
          }
        )
      })
    }
  },
  props: {
    enrollment: Array,
    chartTitle: String,
    yAxisLabel:{
      type: String,
      default: 'Number Of Students'
    },
    chartMode: {
      type: String,
      default: 'count'
    },
    valueMultiplier:{
      type: Number,
      default: 1
    },
    tooltipFormatter:{
      type: Function,
      default: function() {
        return `${this.x}<br/><span  style="color:${this.series.color}">${this.series.name}</span>: <b>${this.y}${this.point.tooltipSuffix}</b>`
      }
    },
    tooltipSuffix:{
      type: String,
      default: ''
    }
  },
  methods: {
    excelExportData() {
      return this.defaultExcelExportData()
    },
    dataItems() {
      return DemographicSubpopulations.flatMap((subpop, index) =>
        this.chartOptions.series[index].data.map(item => {
          return { Subpopulation: subpop.name, SchoolYear: item.x, [this.yAxisLabel.split(" ").join("")]: item.y + this.tooltipSuffix }
        })
      )
    },
    yAxisFormatter(value){
      if(this.chartMode == 'percent'){
        return value.value + '%'
      }
      else if(value.value > 1000){
        return value.value.toString().substring(0,value.value.toString().length-3) + "k"
      }
      else return value.value
      }
    }
  }
</script>

<template>
  <div class="page-break-before">
    <paginated-table
      ref="shortageReportJobStaffTable"
      striped
      hover
      selectable
      select-mode="single"
      :fields="tableOptions.fields"
      :per-page="tableOptions.perPage"
      filter-placeholder-text="Staff Name Filter..."
      :items="staffDetails"
      :filter="combinedFilter"
      :filter-setter="filterSetter"
      :filter-function="applyFilter">
      <template v-slot:cell(lea)="data">
        <a href="#" @click="goToLea(data.value)" :id="data.index + '-' + data.value">{{ data.value }}</a>
        <lea-tooltip
          :target-selector="data.index + '-' + data.value"
          :lea-number="data.value" />
      </template>
    </paginated-table>
  </div>
</template>

<script>
import PaginatedTable from '../../PaginatedTable.vue'
import LeaTooltip from '../../LeaTooltip.vue'

export default {
  data() {
    return {
      tableOptions: {
        filter: null,
        fields: [
          {
            key: 'fullName',
            label: 'Staff Name',
            sortable: true
          },
          {
            key: 'lea',
            label: 'LEA',
            sortable: true
          },
          {
            key: 'category',
            label: 'Category',
            sortable: true,
            formatter: this.categoryFormatter,
            sortByFormatted: true
          },
          {
            key: 'jobCodeTitle',
            label: 'Job Code and Title',
            sortable: true,
            formatter: this.jobCodeFormatter,
            sortByFormatted: true
          },
          {
            key: 'raceString',
            label: 'Race',
            sortable: true
          },
          {
            key: 'isAssignedCourses',
            label: 'Is Teacher',
            sortable: true,
            formatter: d => {
              return d ? 'Yes' : 'No'
            },
            sortByFormatted: true
          },
          {
            key: 'isNoviceTeacher',
            label: 'Novice Teacher',
            sortable: true,
            formatter: d => {
              return d ? 'Yes' : 'No'
            },
            sortByFormatted: true
          }
        ]
      }
    }
  },
  components: {
    PaginatedTable,
    LeaTooltip
  },
  props: {
    staffDetails: Array,
    staffFilter: String,
    raceFilter: String,
    experienceFilter: String,
    jobCodeFilter: Number
  },
  computed: {
    combinedFilter() {
      return {
        race: this.raceFilter,
        experience: this.experienceFilter,
        staffType: this.staffFilter,
        jobCode: this.jobCodeFilter?.toString(),
        other: this.tableOptions.filter
      }
    }
  },
  methods: {
    categoryFormatter(value, key, item) {
      return item.isCertifiedStaff
        ? 'Certified'
        : item.isClassifiedStaff
        ? 'Classified'
        : 'Other'
    },
    nameFormatter(value, key, item) {
      return `${item.lastName}, ${item.firstName}`
    },
    jobCodeFormatter(value, key, item) {
      if (item.jobCode) return `${item.jobCode} - ${item.jobTitle}`
      else if (item.isAssignedCourses == 1) return 'Teacher'
      else return 'Not Specified'
    },
    goToLea(leaNumber) {
      const lea = this.$store.getters['globalModule/leaDictionary'][leaNumber]
      window.scrollTo(0, 0)
      this.$store.dispatch('globalModule/changeSelectedLea', lea)
    },
    filterSetter(filterText) {
      this.tableOptions.filter = filterText
    },
    applyFilter(row, filter) {
      var passes = true

      if (filter.race) {
        var filterValue = null
        switch (filter.race) {
          case 'white':
            filterValue = 'White'
            break
          case 'blackAfricanAmerican':
            filterValue = 'Black / African American'
            break
          case 'americanIndian':
            filterValue = 'Native American / Alaska Native'
            break
          case 'hispanicLatino':
            filterValue = 'Hispanic'
            break
          case 'asian':
            filterValue = 'Asian'
            break
          case 'hawaiianPacificIslander':
            filterValue = 'Pacific Islander / Native Hawaiian'
            break
          case 'twoOrMoreRaces':
            filterValue = 'Multiethnic'
            break
        }

        passes = row.raceString === filterValue
      }

      if (passes && filter.experience) {
        passes =
          (filter.experience == 'novice' && row.isNoviceTeacher) ||
          (filter.experience == 'experienced' && !row.isNoviceTeacher)
      }

      if (passes && filter.staffType) {
        if (filter.staffType.indexOf('certified') >= 0) {
          passes = row.isCertifiedStaff

          if (passes) {
            if (filter.staffType.indexOf('teaching') > 0) {
              passes = row.isAssignedCourses
            } else {
              passes = !row.isAssignedCourses
            }
          }
        } else if (filter.staffType == 'classified') {
          passes = row.isClassifiedStaff
        }
      }

      if (passes && filter.jobCode) {
        passes =
          row.jobCode == filter.jobCode || // All or specific one
          (filter.jobCode == -1 && !row.jobCode && row.isAssignedCourses != 1) // just the "Not Specified"
      }

      if (passes && filter.other) {
        var re = new RegExp(filter.other, 'i')
        passes = re.test(row.fullName)
      }

      return passes
    }
  }
}
</script>

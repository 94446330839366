<template>
  <div>
    <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
      <b-input-group size="sm">
        <b-form-input
          v-model="filter"
          type="search"
          id="filterInput"
          placeholder="Type to Search" />
        <b-input-group-append>
          <b-button :disabled="!filter" @click="filter = ''">
            Clear
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-table :items="jobs" :fields="fields" :filter="filter" sort-by="createdAt">
      <template v-slot:head()="data">
        <span v-html="data.label" />
      </template>
      <template v-slot:cell(jobId)="data">
        <a href="#" @click="viewLogs(data.item)">
          {{ data.value }}
        </a>
      </template>
    </b-table>
    <b-modal id="modal" size="lg" :title="(selectedJob.job || {}).jobName">
      <dl>
        <dt>Arguments:</dt>
        <dd class="col-sm-10">
          <pre>
            {{ (selectedJob.job || {}).args }}
          </pre>
        </dd>
      </dl>
      <h4>Logs:</h4>
      <div class="card card-body bg-light">
        <pre>
          <span v-for="log in selectedJob.jobLogs" :key="JSON.stringify(log)">
            {{ log.text }}
          </span>
        </pre>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  data() {
    return {
      filter: null,
      fields: [
        {
          key: 'jobId',
          sortable: true
        },
        {
          label: 'Job Name',
          key: 'jobName',
          sortable: true
        },
        {
          key: 'createdAt',
          sortable: true,
          formatter: value => {
            return moment(value).format('llll')
          }
        },
        {
          key: 'args',
          sortable: true
        }
      ]
    }
  },
  props: {
    jobs: {
      type: Array
    }
  },
  computed: {
    ...mapState('adminModule', ['selectedJob'])
  },
  methods: {
    viewLogs(job) {
      this.$store.dispatch('adminModule/getJobLogs', job)
      this.$bvModal.show('modal')
    }
  }
}
</script>
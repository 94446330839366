const MealStatuses = [
  { text: 'Unknown', value: null},
  { text: 'Free', value: 1 },
  { text: 'Reduced', value: 2 },
  { text: 'Full-Price', value: 3 },
  { text: 'Direct Cert', value: 4 }
]

const Genders = [
  { text: 'Female', value: 'F'},
  { text: 'Male', value: 'M'},
]

export { MealStatuses, Genders }
<template>
  <div>
    <b-table :fields="fields" :items="coops">
      <template v-slot:cell(coopLea)="data">
        {{ data.item.coopLea }} - {{ data.item.name }}
      </template>
      <template v-slot:cell(area)="data">
        <b-form-select placeholder="Select Area" @change="coopAreaChanged($event, data.item.coopLea)" v-model="data.value.areaId" value-field="areaId" text-field="name" :options="areas" />
      </template>
    </b-table>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'coops',
  data() {
    return {
      fields: [
        { key: 'coopLea', sortable: true },
        { key: 'area', sortable: true }
      ]
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState('DPSAFT/Admin', ['coops']),
    ...mapState('DPSAFT', ['areas'])
  }, 
  methods: {
    getCoops() {
      this.$store.dispatch('DPSAFT/Admin/getCoops')
    },
    coopAreaChanged(selectedValue, coopLea) {
      this.$store.dispatch('DPSAFT/Admin/assignAreaCoop', { areaId: selectedValue, coopLea })
    }
  },
  created() {
    this.$store.dispatch('DPSAFT/getAreas').then(() => {
      this.getCoops()
    })
  }
}
</script>

<style lang="scss" scoped>
// the ::v-deep makes it so we generate the selector ".table[data-v-5871abb4] th" instead of the selector ".table th[data-v-5871abb4]"
.table ::v-deep th { 
  border-top: none !important;
}
</style>
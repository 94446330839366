const CostTypeCategories = [
    { text: 'Select a Category', value: null },
    { text: 'Salaries', value: 1 },
    { text: 'Benefits', value: 2 },
    { text: 'Purchased Professional and Technical Services', value: 3 },
    { text: 'Other Purchased Services', value: 4 },
    { text: 'Supplies and Materials', value: 5},
    { text: 'Property/Equipment', value: 6 },
    { text: 'Other Equipment', value: 7 },
    { text: 'Administrative Indirect Costs', value: 8}
]

const ProposalTypeValues = {
  AllProgramAreas: 23,
  DeficientAllProgramAreas: 21,
  DeficientSingleProgramArea: 20,
  SingleProgramArea: 22,
}

const ProposalTypes = [
  { text: 'Select a Proposal Type', value: 0 },
  { text: 'All Program Areas', value: ProposalTypeValues.AllProgramAreas },
  { text: 'Deficient All Program Areas', value: ProposalTypeValues.DeficientAllProgramAreas },
  { text: 'Deficient Single Program Area', value: ProposalTypeValues.DeficientSingleProgramArea },
  { text: 'Single Program Area', value: ProposalTypeValues.SingleProgramArea }
]

export { CostTypeCategories, ProposalTypes, ProposalTypeValues }
<template>
  <div class="my-2">
    <div class="row">
      <div class="col-12">
        <div class="card bg-light">
          <div class="card-body">
            <b-form inline>
              <b-form-group label="Created User" class="mx-1 font-weight-bold">
                <b-form-select v-model="user" :options="userList" class="w-100" @change="$emit('user-changed', user)">
                  <template #first>
                    <b-form-select-option :value="null">
                      (Any User)
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>          
            </b-form>
          </div>
        </div>        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: null
    }
  },
  props: {
    userList: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style>

</style>

<template>
  <div>
    <excel-button title="General Ledger" />
    <h1>General Ledger</h1>
    <h2><lea-display :lea="selectedLea" /></h2>
    <div v-if="selectedLea.type != LeaTypes.School" class="chart">
      <b-form v-if="!$printMode" class="form-inline p-3">
        <b-form-group label="Fund" class="font-weight-bold">
          <b-form-select v-model="fund" :options="funds" class="w-100" />
        </b-form-group> 
        <b-form-group label="Account" class="px-2 font-weight-bold">
          <b-form-select v-model="account" :options="accounts" class="w-100" />
        </b-form-group> 
      </b-form>
      <general-ledger-widget :fund="fund" :account="account" />
    </div>
    <div v-else class="alert alert-warning">
      No data available at School level. Please select a district, co-op, or the state to view the General Ledger
    </div>
  </div>
</template>

<script>
import { PageMixin } from '../../PageMixin'
import { LeaTypes } from '../../../helpers/leaTypes'
import { mapState, mapGetters } from 'vuex'
import { Types } from '../../../stores/financeModule'
import GeneralLedgerWidget from './GeneralLedgerWidget.vue'

export default {
  data() {
    return {
      fund: null,
      account: null,
      LeaTypes
    }
  },
  mixins: [PageMixin],
  computed: {
    selectedLea() { return this.$store.state.globalModule.leaContext.SelectedLea },
    ...mapState(Types.path, [Types.state.fundsAndAccounts]),
    ...mapGetters(Types.path, [Types.getters.funds, Types.getters.accounts])
  },
  components: {
    GeneralLedgerWidget
  },
  created() {
    if(this.selectedLea && this.selectedLea.number) {
      this.loadData = this.$store.dispatch(Types.actions.getFundsAndAccounts, {
        lea: this.selectedLea.number
      })
    }
  }
}
</script>

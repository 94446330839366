<template>
  <div>
    <paginated-table :items="transformedFeederPairs" :fields="fields">
      <template v-slot:head(actionColumn)>
        <b-button variant="primary" :to="{name: 'add-feeder-pair'}">
          Add Pair
        </b-button>
      </template>
      <template v-slot:cell(actionColumn)="data">
        <b-button
          variant="link"
          size="xs"
          class="text-decoration-none text-danger"
          title="Remove Pair"
          @click="onDeleteItem(data.item)"
        >
          <span class="fas fa-times" title="Remove Pair" />
        </b-button>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PaginatedTable from '../../../PaginatedTable'

export default {
  name: 'feeder-pairs',
  data() {
    return {
      fields: [
        { key: 'feederSchoolLEA', sortable: true, label: 'Feeder School LEA (7A)' },
        { key: 'feederSchoolName', sortable: true },
        { key: 'feederGradeRange', sortable: true, class: 'text-center' },
        { key: 'pairedSchoolLEA', sortable: true, label: 'Paired School LEA (7B)' },
        { key: 'pairedSchoolName', sortable: true },
        { key: 'actionColumn', class: 'text-right' }
      ]
    }
  },
  computed: {
    ...mapState('toolsModule/leaAssignment', ['feederPairs']),
    ...mapState('globalModule', ['leaContext']),
    ...mapGetters('toolsModule/leams', ['leamsDictionary', 'fiscalYear']),
    transformedFeederPairs() {
      let vm = this;

      return this.feederPairs.map(i => {
        var f = vm.leamsDictionary.get(i.feederSchoolLEA)
        var p = vm.leamsDictionary.get(i.pairedSchoolLEA)
        return {
          ...i,
          feederSchoolName: f ? f.name : '(Invalid LEA)',
          pairedSchoolName: p ? p.name : '(Invalid LEA)'
        }
      })
    }
  },
  methods: {
    onDeleteItem(item) {
      this.$bvModal.msgBoxConfirm(`Are you sure you want to delete the pairing for ${item.feederSchoolLEA} and ${item.pairedSchoolLEA}?`).then(result => {
        if (result) this.$store.dispatch('toolsModule/leaAssignment/deleteFeederPair', {
          id: item.id
        })
      })
    }
  },
  components: {
    PaginatedTable
  }
}
</script>
<template>
  <div>
    <p class="font-weight-bold mt-3">
      {{ question.text }}
    </p>

    <ul class="list-group list-group-flush" v-if="!editMode">
      <li class="list-group-item bg-transparent borderless" v-for="c in question.choices" :key="c">
        <pretty-checkbox :value="getValue(c)" /><span class="font-italic"> {{ c }}</span>
      </li>
    </ul>
    
    <b-form-group v-if="editMode">
      <b-form-checkbox-group 
        :id="'question_' + question.id"
        v-model="$v.survey[question.id].$model"
        :options="options"
        :name="'question_' + question.id"
        stacked
        class="font-italic"
        @input="updatedValue"
      />
    </b-form-group>

    <div v-if="question.allowOther" class="ml-3">
      <p class="font-weight-bold mt-3">
        {{ otherText }}
      </p>

      <div v-if="followUpApplicable">
        <ul class="list-group list-group-flush" v-if="!editMode">
          <li class="list-group-item bg-transparent borderless font-italic">
            {{ followUpValue || '(no response provided)' }}
          </li>
        </ul> 
                
        <span v-if="editMode">
          <b-form-textarea rows="1" max-rows="10" type="text" class="d-inline font-italic" v-model.trim="$v.survey[question.id + 'Followup'].$model" />
        </span>
      </div>
      <div v-else>
        <ul class="list-group list-group-flush">
          <li class="list-group-item bg-transparent borderless font-italic">
            (not applicable)
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

/// Display the question and checkboxes for multiple selections.

import PrettyCheckbox from '../../PrettyCheckbox'

export default {
  inject: ['$v'],
  name: 'question-multiple-selection',
  data: function () {
    return {
      followUpApplicable: false
    }
  },
  computed: {
    options() {
      return this.question.choices.map(c => { return { text: c, value: c }})
    },
    value() {
      return this.$v.survey[this.question.id].$model
    },
    followUpValue() {
      return this.$v.survey[this.question.id + 'Followup'].$model
    },
    otherText() {
      return this.question.otherText ? this.question.otherText : 'Other (please explain):'
    }
  },
  methods: {
    getValue(c) {
      return (this.value || []).includes(c)
    },
    updatedValue() {
      this.followUpApplicable = this.question.allowOther && this.$v.survey[this.question.id].$model !== undefined && (this.$v.survey[this.question.id].$model || []).includes(this.question.choices.slice(-1)[0])
      if (!this.followUpApplicable && this.question.allowOther) this.$v.survey[this.question.id + 'Followup'].$model = ''
    }
  },
  props: {
    question: {
      type: Object,
      default: () => {}
    },
    editMode: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    PrettyCheckbox
  },
  beforeMount()
  {
    this.updatedValue()
  }
}
</script>
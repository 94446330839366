import Vue from 'vue'
import { StaffWithNoAelsRecord } from './StaffWithNoAelsRecord'
import { ClassesMissingCaseId } from './ClassesMissingCaseId'
import { CrossLinkedCaseIds } from './CaseIdCrosslinked'
import LoadableChartCard from '../../LoadableChartCard.vue'

const DataIntegrityWidget = Vue.extend({
    name: 'data-integrity-widget',
    /* html */
    template: `
    <div>
        <div class='card'>
            <h5 class='card-header'>Staff Data Integrity / Quality Checks</h5>
            <div class='card-body'>
                <ul class='nav nav-tabs d-print-none' id='myTab' role='tablist'>
                    <li class='nav-item'>
                        <a class='nav-link active' id='staff-missing-case-id-tab' data-toggle='tab' href='#staff-missing-case-id' role='tab' aria-controls='staff-missing-case-id' aria-selected='true'>
                            <span class='badge badge-secondary'>{{ classesMissingCaseIdTotalRowCount }}</span>
                            Class Staff missing Case IDs
                        </a>
                    </li>
                    <li class='nav-item'>
                        <a class='nav-link' id='staff-not-in-aels-tab' data-toggle='tab' href='#staff-not-in-aels' role='tab' aria-controls='staff-not-in-aels' aria-selected='false'>
                            <span class='badge badge-secondary'>{{ staffNotInAelsTotalRowCount }}</span>
                            Staff not found in AELS
                        </a>
                    </li>
                    <li class='nav-item'>
                        <a class='nav-link' id='crosslinked-tab' data-toggle='tab' href='#case-ids-crosslinked' role='tab' aria-controls='case-ids-crosslinked'aria-selected='false'>
                            <span class='badge badge-secondary'>{{ caseIdsCrosslinkedCount }}</span>
                            Case Ids Cross Linked
                        </a>
                    </li>
                </ul>
                <div class='tab-content' id='myTabContent'>
                    <div class='tab-pane fade show active' id='staff-missing-case-id' role='tabpanel' aria-labelledby='home-tab'>
                        <div class='d-none d-print-block'>
                          <h2>Class Staff missing Case IDs</h2>
                        </div>
                        The following table lists eSchoolPlus class sections that have staff assigned with virtual SSNs(e.g. 99X-XX-XXXX) but do NOT have AELS Case IDs assigned to the class.  Classes taught by staff with 
                        99X-XX-XXXX SSNs should also have Case IDs associated in order to verify that the educator is sufficiently licensed to teach the course.
                        <loadable-chart-card v-bind:load-data="loadData" v-bind:enableDataToggle="false" card-style=''>
                          <classes-missing-case-id></classes-missing-case-id>
                        </loadable-chart-card>
                    </div>
                    <div class='tab-pane fade' id='staff-not-in-aels' role='tabpanel' aria-labelledby='profile-tab'>
                        <div class='d-none d-print-block'>
                          <h2>Staff not found in AELS</h2>
                        </div>
                        The following table lists employees that were not found in AELS using the SSN as a cross-reference.  This often means that the SSN entered in eFinance does not match the SSN entered in AELS.
                        <loadable-chart-card v-bind:load-data="loadData" v-bind:enableDataToggle="false" card-style=''>
                          <staff-with-no-aels-record></staff-with-no-aels-record>
                        </loadable-chart-card>
                    </div>
                    <div class='tab-pane fade' id='case-ids-crosslinked' role='tabpanel' aria-labelledby='crosslinked-tab'>
                        <div class='d-none d-print-block'>
                          <h2>Case Ids Cross Linked</h2>
                        </div>
                        The following table lists employees who are entered as teaching a course, but the AELS Case Id does not match between the course and the teacher records. 
                        <loadable-chart-card v-bind:load-data="loadData" v-bind:enableDataToggle="false" card-style=''>
                          <cross-linked-case-ids></cross-linked-case-ids>
                        </loadable-chart-card>
                    </div>
                </div>
            </div>
        </div>       
    </div>`,
    components: {
        StaffWithNoAelsRecord,
        ClassesMissingCaseId,
        CrossLinkedCaseIds,
        LoadableChartCard
    },
    data() {
      return {
        loadData: null
      }
    },
    props: {
        selectedLea: Object
    },
    computed: {
        staffNotInAelsTotalRowCount() {
            return this.$store.state.hcptModule.dataIntegrity.staffWithNoAelsRecord.length
        },
        classesMissingCaseIdTotalRowCount() {
            return this.$store.state.hcptModule.dataIntegrity.classesWithoutCaseId.length
        },
        caseIdsCrosslinkedCount() {
            return this.$store.state.hcptModule.dataIntegrity.caseIdCrosslink.length
        }
    },
    watch: {
        selectedLea: {
            immediate: true,
            handler(newLea) {
                if (newLea && newLea.number)
                    this.loadData = Promise.all([
                        this.$store.dispatch('hcptModule/getClassesMissingCaseId', { lea: newLea.number }),
                        this.$store.dispatch('hcptModule/getStaffWithNoAelsRecords', { lea: newLea.number }),
                        this.$store.dispatch('hcptModule/getCaseIdCrosslink', { lea: newLea.number })
                    ])
            }
        }
    }
})
export { DataIntegrityWidget }
<template>
  <div>
    <div v-for="question in filteredQuestions" :key="question.id" style="page-break-inside: avoid;">
      <component :is="question.component" :question="question" :edit-mode="editMode" />
    </div>
  </div>
</template>

<script>
import QuestionYesno from '../questions/questionYesno'
import QuestionMultipleSelection from '../questions/questionMultipleSelection'
import QuestionFreeText from '../questions/questionFreeText'
import QuestionYesnoDetails from '../questions/questionYesnoDetails'
import QuestionSingleSelection from '../questions/questionSingleSelection'
import QuestionTagSelection from '../questions/questionTagSelection'
import QuestionHeader from '../questions/questionHeader'
import QuestionRanking from '../questions/questionRanking'
import { VisibilityDirectives } from './surveyTemplate'

export default {
  name: 'survey-questions',
  inject: ['$v'],
  provide() {
    return {
      $v: this.$v
    }
  },
  computed: {
    filteredQuestions() {
      return this.questions.filter(s => (s.hidden & VisibilityDirectives.hideOnSurvey) != VisibilityDirectives.hideOnSurvey)
    }
  },
  props: {
    questions: {
      type: Array,
      default: () => []
    },
    editMode: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      
    }
  },
  components: {
    QuestionYesno,
    QuestionMultipleSelection,
    QuestionFreeText,
    QuestionYesnoDetails,
    QuestionSingleSelection,
    QuestionTagSelection,
    QuestionHeader,
    QuestionRanking
  }
}
</script>

<style>
  li.borderless {
    border: 0 none !important;
    padding-top: 0;
    padding-bottom: 0;
  }
</style>
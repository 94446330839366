<template>
  <div>
    <waiver-request-filter v-model="filter" :initial-district="selectedLeaNumber" :context="context" />    
    <b-card no-body>
      <b-tabs card v-model="selectedWaiverIndex">
        <b-tab :title="WaiverTypes.DigitalLearning.listTitle" active>
          <waiver-request-list :district-lea="selectedLeaNumber" :filter="filter" :context="context" :waiver-type="WaiverTypes.DigitalLearning" :load-data="loadData" />        
        </b-tab>
        <b-tab :title="WaiverTypes.AMI.listTitle">
          <waiver-request-list :district-lea="selectedLeaNumber" :filter="filter" :context="context" :waiver-type="WaiverTypes.AMI" :load-data="loadData" />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import WaiverRequestList from './WaiverRequestList.vue'
import WaiverRequestFilter from './WaiverRequestFilter.vue'
import { PageMixin } from '@/components/PageMixin'
import { Types, WaiverTypes, Context } from '@/stores/digitalLearning'

export default {
  data() {
    return {
      filter: { 
        lea: null,
        leaStartsWith: null,
        leaContains: null,
        fiscalYear: 32,
      },
      selectedWaiverIndex: 0,
      loadData: Promise.resolve(),
      WaiverTypes,
      Context
    }
  },
  computed: {
    selectedLeaNumber() {
      return (this.selectedLea || {}).number
    }
  },
  mixins:[PageMixin],
  mounted() {
    this.filter.lea = 'AR' 

    if (this.context == Context.Review)
      this.loadData = this.$store.dispatch(Types.actions.getWaiverRequests, this.filter)
    else
      this.loadData = this.$store.dispatch(Types.actions.getApprovedWaiverRequests, this.filter)
  },
  watch: {
    selectedWaiverIndex: {
      immediate: true,
      handler(newIndex) {
        this.$emit('selected-waiver-type-changed', WaiverTypes[Object.keys(WaiverTypes)[newIndex]])
      }
    }
  },
  props: {
    context: {
      type: Number,
      required: true
    },
  },
  components: {
    WaiverRequestList,
    WaiverRequestFilter
  },
}
</script>

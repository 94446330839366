<template>
  <div>
    <pdf-button />
    <excel-button title="Overview" />
    <h1>Overview</h1>
    <h2><lea-display :lea="selectedLea" /></h2>
    <additional-reporting-widget />
  </div>
</template>

<script>
import { PageMixin } from '../../PageMixin'
import AdditionalReportingWidget from './AdditionalReportingWidget.vue'

export default {
  data() {
    return {}
  },
  components: {
    AdditionalReportingWidget
  },
  mixins: [PageMixin]
}
</script>

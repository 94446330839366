<template>
  <loadable-chart-card :load-data="loadData" card-class="bg-white p-1 inner-card justify-content-center">
    <template v-slot:default="slotProps">
      <div class="float-right d-inline d-print-none p-0" style="width: 10rem; margin-top: -1.5rem;">
        <b-form-select v-model="selectedGradeSpan" v-show="slotProps.chartMode" size="sm" :options="gradeSpanOptions" />
      </div>
      <div class="clearfix m-0" />
      <div class="chartElem mt-0" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
  import Highcharts from 'highcharts'
  import { LeaTypes } from '@/helpers/leaTypes'
  import { ChartMixin } from '@/components/ChartMixin'
  import { credits } from '@/helpers/credits'
  import groupBy from 'lodash.groupby'
  import orderBy from 'lodash.orderby'
  import { DataLevels } from '@/modules/esa/shared/enums'

  function parseGradeSpan(gradeSpan) {
    if (gradeSpan.includes('-')) {
      const split = gradeSpan.split('-')

      return { low: parseGrade(split[0]), high: parseGrade(split[1]) }
    } 
    else if (gradeSpan) {
      return { low: parseGrade(gradeSpan), high: parseGrade(gradeSpan) }
    }
    return null
  }
  function parseGrade(grade) {
    if (grade == 'P') 
      return -1
    else if (grade == 'K') 
      return 0

    return parseInt(grade)
  }

  export default {
    mixins: [ChartMixin],
    data() {
      return {
        animationDuration: 1000,
        selectedGradeSpan: null,
        chartOptions: {
          exporting: {
            enabled: false,
          },
          chart: {
            type: 'column',
            backgroundColor: '#fff',
            plotBackgroundColor: '#eee',
          },
          plotOptions: {
            series: {
              minPointLength: 3
            },
            column: {
              dataLabels: {
                enabled: true,
                formatter: this.dataLabelsFormatter
              }
            }
          },
          legend: {
            enabled: false,
            layout: 'vertical',
            verticalAlign: 'top',
            align: 'right',
            y: 12
          },
          series: [
            { 
              name: this.title
            },
          ],
          credits: Object.assign(this.credits, { position: { y: -20 } }),
          title: {
          },
          subtitle:{
            text: this.subtitle || ''
          },
          tooltip:{
            enabled: true,
            formatter: this.tooltipFormatter,
            valueDecimals: 2,
          },
          xAxis: {
            labels: {   
              useHTML: true           
            }
          },
          yAxis: {
            title: {
              enabled: false,
            },           
          }
        },
      }
    },
    computed: {
      isGradeLevelData() {
        return this.gradeSpanOptions.filter(g => g.value == '03').length > 0
      }
    },
    props: {
      title: String,
      subtitle: String,
      chartTitle: String,
      customLabel: String,
      field: {
        default: 'value',
        type: String
      },
      decimalPrecision: {
        type: Number,
        default: 2,
      },
      subgroup: String,
      reportData: Array,
      isCurrency: Boolean,
      metricSuffix:{
        type: String,
        default: ''
      },
      tooltipFormatter:{
        type: Function,
        default: function() {
          let countHtml = ''
          let additional = ''
          if (this.point.n) {
            additional += `<br/><span style="color:${this.series.color}">Number: </span><b>${this.point.n}</b>`
          }
          if (this.point.x > 2000) {
            additional += `<br/><span>Fiscal Year: </span><b>${this.point.x}</b>`
          }
          if (this.point.isCurrency) {
            return `${this.point.lea}<br/><span style="color:${this.series.color}">${this.point.title}</span>: <b>$${Highcharts.numberFormat(this.y, this.point.decimalPrecision).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</b>${countHtml}`
          }
          return `${this.point.lea}<br/><span style="color:${this.series.color}">${this.point.title}</span>: <b>${Highcharts.numberFormat(this.y, this.point.decimalPrecision)}${this.point.metricSuffix}</b>${additional}`
        }
      },
      dataLabelsFormatter: {
        type: Function,
        default: function() {
          if (this.point.isCurrency) {
            return `$${Highcharts.numberFormat(this.point.y, this.point.decimalPrecision).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`
          }
          return `${Highcharts.numberFormat(this.point.y, this.point.decimalPrecision)}${this.point.metricSuffix ?? ''}`
        },
      },
      xAxisFormatter:{
        type: Function,
        default: function(value) {
          return this.getLeaName(value)
        }
      },
      range: {
        type: Array,
        default: () => []
      },
      mode: {
        default: 'current',
        type: String
      },
      credits: {
        type: Object,
        default: () => credits.MSISRC,
      },
      gradeSpanOptions: {
        type: Array,
        default: () => [
          { value: null, text: 'All Grades' },
          { value: 'K-5', text: 'K-5' },
          { value: '6-8', text: '6-8' },
          { value: '9-12', text: '9-12' },
        ]
      },
    },
    watch: {
      selectedGradeSpan: {
        handler() {
          this.populate(this.reportData)
        }
      },
      reportData: {
        immediate: true,
        handler(data) {
          this.populate(data)
        }
      },
    },
    methods: {
      populate(data) {
        const self = this
        this.chartOptions.credits = this.credits
        this.chartOptions.xAxis.categories = []
        this.chartOptions.title.text = this.chartTitle || `${this.title} by School${this.subgroup ? ' - ' + this.subgroup : '' }`
        this.chartOptions.chart.events = { 
          render: function () {
            let chart = this
            let credits = chart.credits
            let creditsBB = credits.getBBox()

            if (chart.customLabel) {
              chart.customLabel.destroy();
            }
            chart.customLabel = chart.renderer.label(self.customLabel, 0, creditsBB.y - 5).css(credits.styles).add()
          }
        }
        this.chartOptions.xAxis.labels.formatter = function() {
          return self.xAxisFormatter(this.value)
        }
        this.chartOptions.series = []

        if (!data || data.length < 1) {
          return
        }
        if (this.mode == 'current') {
          this.setSeriesCurrent(data)
        } else {
          this.setSeriesTrend(data)
        }
      },
      isLeaInGradeSpan(lea) {
        const school = this.leaDictionary[lea]
        // Only apply gradespan filter for schools and when it's selected
        if (!school || school.type != 1 || !this.selectedGradeSpan) 
          return true
        
        const schoolGradeSpan = parseGradeSpan(school.gradesServed || `${school.gradeLow}-${school.gradeHigh}`)
        const filterGradeSpan = parseGradeSpan(this.selectedGradeSpan)
        if (schoolGradeSpan.high < filterGradeSpan.low) {
          return false
        }
        if (schoolGradeSpan.low > filterGradeSpan.high) {
          return false
        }
        return true 
      },
      isInGradeSpan(item) {
        if (typeof item === 'object' && this.isGradeLevelData) {
          if (item.grade && this.selectedGradeSpan == null) {
            return item.grade == 'All'
          }
          return item.grade == this.selectedGradeSpan
        } 
        const lea = typeof item === 'object' ? item.lea : item
        return this.isLeaInGradeSpan(lea)
      },
      setSeriesCurrent(data) {
        const self = this
        this.chartOptions.legend.enabled = false
        this.chartOptions.yAxis.plotLines = []
        if (this.range.length > 0) {
          this.chartOptions.yAxis.min = this.range[0]
          this.chartOptions.yAxis.max = this.range[1]
        }
        this.chartOptions.series = [{ name: this.title, colorByPoint: true }]
        this.chartOptions.chart.type = 'column'
        const stateAvgMetrics = data
          .filter(d => d.dataLevel == LeaTypes.State && (self.subgroup ? self.subgroup == d.demographic : true))
          .filter(d => self.isInGradeSpan(d))

        if (stateAvgMetrics.length > 0) {
          this.chartOptions.yAxis.plotLines.push({
            width: 2,
            dashStyle: 'shortdash',
            label: {
            },
            value: stateAvgMetrics[0][self.field],
            ...self.getPointMetadata(DataLevels.State.color),
          })
          this.chartOptions.series.push({
            showInLegend: false,
            type: 'scatter',
            marker: {
              enabled: false
            },
            enableMouseTracking: false,
            data: [{
              lea: self.leaFormatter('AR'),
              y: stateAvgMetrics[0][self.field],
              n: stateAvgMetrics[0].n,
              grade: stateAvgMetrics[0].grade,
              ...self.getPointMetadata()
            }]
          })
        }

        const districtAvgMetrics = data
          .filter(d => d.dataLevel == LeaTypes.District && (self.subgroup ? self.subgroup == d.demographic : true))
          .filter(d => self.isInGradeSpan(d))

        if (districtAvgMetrics.length > 0) {   
          this.chartOptions.title.text = `${(districtAvgMetrics[0].fy % 1990) + 1990} ${this.title} by School${this.subgroup ? ' - ' + this.subgroup : '' }`
          this.chartOptions.xAxis.categories = [districtAvgMetrics[0].lea]
          this.chartOptions.series[0].data = [{
            lea: self.leaFormatter(districtAvgMetrics[0].lea),
            y: districtAvgMetrics[0][self.field], 
            n: districtAvgMetrics[0].n,
            grade: districtAvgMetrics[0].grade,
            ...self.getPointMetadata(DataLevels.District.color),
          }]
        }
        else {
          this.chartOptions.series[0].data = []
          const metrics = (data || [])
            .filter(d => d.dataLevel == LeaTypes.School && (self.subgroup ? self.subgroup == d.demographic : true))
            .filter(d => self.isInGradeSpan(d))

          if (metrics.length > 0) {
            this.chartOptions.title.text = `${(metrics[0].fy % 1990) + 1990} ${this.title} by School${this.subgroup ? ' - ' + this.subgroup : '' }`
          }
        }
        let series = [...this.chartOptions.series]
        series[0] = Object.assign(series[0], {
          data: [
            ...series[0].data,
            ...data
              .filter(d => d.dataLevel == LeaTypes.School && (self.subgroup ? self.subgroup == d.demographic : true))
              .filter(d => self.isInGradeSpan(d))
              .map(row => {
                this.chartOptions.xAxis.categories.push(row.lea)
                return { 
                  lea: self.leaFormatter(row.lea),
                  y: row[self.field], 
                  n: row.n,
                  grade: row.grade,
                  ...self.getPointMetadata(),
                }
              })
          ]
        })
        this.chartOptions.series = series
        this.chartOptions = {...this.chartOptions}
      },
      setSeriesTrend(data) {
        const self = this
        this.chartOptions.chart.type = 'line'
        this.chartOptions.xAxis.labels.useHTML = false
        this.chartOptions.xAxis.categories = null
        this.chartOptions.xAxis.tickInterval = 1
        
        this.chartOptions.series = []
        const subGroupDataByLea = groupBy(orderBy(data.filter(d => self.subgroup ? self.subgroup == d.demographic : true), 'dataLevel', 'desc'), 'lea')

        const newSeries = Object.keys(subGroupDataByLea)
          .filter(lea => self.isInGradeSpan(lea))
          .map(lea => {
            var series =  { 
              name: self.getLeaName(lea),
              data: subGroupDataByLea[lea]
                .filter(d => self.isInGradeSpan(d))
                .map(row => {
                  return {
                    lea: self.leaFormatter(lea),
                    x: (row.fy % 1990) + 1990,
                    y: row[self.field],
                    n: row.n,
                    grade: row.grade,
                    ...self.getPointMetadata(),
                  }
                }),
            }
            if (subGroupDataByLea[lea][0].dataLevel == DataLevels.State.id)
              series.color = DataLevels.State.color
            else if (subGroupDataByLea[lea][0].dataLevel == DataLevels.District.id)
              series.color = DataLevels.District.color

            return series
          })
        this.chartOptions.series = newSeries
        this.chartOptions.legend.enabled = true
      },
      getPointMetadata(color) {
        return {
          metricSuffix: this.metricSuffix,
          isCurrency: this.isCurrency,
          decimalPrecision: this.decimalPrecision,
          color: color, 
          title: this.title,
        }
      },
      getLeaName(lea) {
        return (this.leaDictionary[lea] || { name: lea }).name
      },
      excelExportData() {
        return {
          name: this.chartOptions.title.text.replace(' by School', ''),
          items: this.dataItems()
        }
      },
      dataItems() {
        const self = this
        return (this.chartOptions.series || []).flatMap(series => {
          return (series.data || []).map(item => {
            const demographic = self.subgroup ? { Demographic: self.subgroup } : {}
            if (this.mode == 'trend') {
              return {
                LEA: item.lea,
                FiscalYear: item.x,
                Grade: item.grade,
                ...demographic,
                [this.title]: Highcharts.numberFormat(item.y, self.decimalPrecision),
              }
            }
            return {
              LEA: item.lea,
              Grade: item.grade,
              ...demographic,
              [this.title]: Highcharts.numberFormat(item.y, self.decimalPrecision),
            }
          })
        })
      },
    }
  }
  
</script>
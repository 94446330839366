import Vue from 'vue'
import LoadableChartCard from '../../LoadableChartCard.vue'
import { LeaTypes } from '../../../helpers/leaTypes'
import LeaIcon from '../../LeaIcon.vue'

const AttritionReportTable = Vue.extend({
  name: 'attrition-report-table',
  data() {
    return {
      tableData: null,
      focus: 'attrition'
    };
  },
  /* html */
  template: `
  <div class="card bg-light mt-3">
    <div class="card-body">
      <loadable-chart-card v-bind:load-data="loadData" v-bind:enableDataToggle="false" card-style=''>
        <div class="w-100 m-3">
          <b-form-group>
            <b-form-radio-group id="focus"
                              buttons
                              button-variant='outline-primary'
                              v-model="focus"
                              v-if="!$printMode"
                              name="focus">
                              <b-form-radio value="attrition">Attrition Rates</b-form-radio>
                              <b-form-radio value="employment">Employment Statistics</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <table class="table table-striped table-sm" v-if="tableData && (focus == 'attrition' || $printMode)">
            <thead>
              <tr>
                <th :colspan="yearsArray.length" style="text-align: center">{{ position }} Attrition</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>LEA</th>
                <th v-for="year in yearsArray">{{ year }}</th>
              </tr>  
              <tr v-for="lea in tableData">
                <td>
                  <a href="#" v-on:click="goToLea(lea.name)"><lea-icon v-bind:lea="{ type: getLeaType(lea.data[0].scope) }"></lea-icon> {{ lea.name }}</a>
                </td>
                <td v-for="year in yearsArray">{{ formatDataY(lea.data.find(e => e.x == year)) }}</td>
              </tr>
            </tbody>
          </table>
          <br/>
          <table class="table table-striped table-sm w-100" v-if="tableData && (focus == 'employment' || $printMode)">
            <thead>
              <tr>
                <th :colspan="yearsArray.length" style="text-align: center">{{ position }} Employment Statistics</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>LEA</th>
                <th></th>
                <th v-for="year in yearsArray.slice(1, yearsArray.length)">{{ year }}</th>
              </tr>
              <template v-for="lea in tableData">
                <tr>
                  <th colspan="2">
                    <lea-icon v-bind:lea="{ type: getLeaType(lea.data[0].scope) }"></lea-icon> {{ lea.name }}
                  </th>
                  <td v-for="year in yearsArray.slice(1, yearsArray.length)"></td>
                </tr>
                <tr>
                  <td></td>
                  <td><i>Total Employed</i></td>
                  <td v-for="year in yearsArray.slice(1, yearsArray.length)">{{ formatDataTotalEmployed(lea.data.find(e => e.x == year)) }}</td>
                </tr>
                <tr>
                  <td></td>
                  <td><i>Leaving</i></td>
                  <td v-for="year in yearsArray.slice(1, yearsArray.length)">{{ formatDataLeaving(lea.data.find(e => e.x == year)) }}</td>
                </tr>
                <tr>
                  <td></td>
                  <td><i>Returning</i></td>
                  <td v-for="year in yearsArray.slice(1, yearsArray.length)">{{ formatDataReturning(lea.data.find(e => e.x == year)) }}</td>
                </tr>
              </template>
            </tbody>
          </table>     
        </div>  
      </loadable-chart-card>
    </div>
  </div>
    `,
  computed: {
    chartData() {
      return this.$store.state.attritionReportModule.chartData
    },
    loadData() {
      return this.$store.state.attritionReportModule.loadData
    },
    minYear() {
      return this.$store.state.attritionReportModule.minYear
    },
    maxYear() {
      return this.$store.state.attritionReportModule.maxYear
    },
    yearsArray() {
      return this.$store.getters['attritionReportModule/yearsArray']
    },
    position() {
      var position = this.$store.getters['attritionReportModule/position']
      return position.charAt(0).toUpperCase() + position.slice(1)
    },
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    }
  },
  components: {
    LoadableChartCard,
    LeaIcon
  },
  methods: {
    getLeaType(scope) {
        switch (scope) {
        case 'Building': return LeaTypes.School
        case 'CoOp': return LeaTypes.Coop
        case 'District': return LeaTypes.District
        case 'State': return LeaTypes.State
        default: return LeaTypes.State
        }
    },
    formatDataY(data) {
        var output = (data || {y: 100}).y || 0
        return output == '--' ? '--' : output.toPrecision(3) + ' %'
    },
    formatDataTotalEmployed(data) {
        var output = (data || {totalEmployed: '0'}).totalEmployed || 0
        return output.toLocaleString()
    },
    formatDataLeaving(data)
    {
        var output = (data || {numberDepartedNextFY: '0'}).numberDepartedNextFY || 0
        return output.toLocaleString()
    },
    formatDataReturning(data)
    {
        var output = (data || {numberReturnedNextFY: '0'}).numberReturnedNextFY || 0
        return output.toLocaleString()
    },
    goToLea(leaName) {
        // eslint-disable-next-line
        const regex = new RegExp(/\(([^\)]+)\)/)
        const leaNumber = leaName.match(regex)[1]
        const lea = this.leaDictionary[leaNumber]
        window.scrollTo(0,0)
        this.$store.dispatch('globalModule/changeSelectedLea', lea)
    },
    attritionTableExport() {
      var excelData = []
        this.$store.state.attritionReportModule.attritionStatistics.forEach(a => {
          if(this.leaDictionary[a.scopeLEA]){
            let index = excelData.findIndex(e => e.LEA == a.scopeLEA)
            
            if (index == -1) {
              excelData.push({ Name: this.leaDictionary[a.scopeLEA].name, LEA: a.scopeLEA })
              index = excelData.length - 1
            }
            excelData[index][`${a.fy + 1990} Attrition Rate`] = this.formatDataY({ y: a.attritionRate * 100 })
          }
        })
        return {
            name: 'Attrition Rates',
            items: excelData
        }
    },
    employementStatsTableExport() {
      var excelData = []
        this.$store.state.attritionReportModule.attritionStatistics.forEach(a => {
          if(this.leaDictionary[a.scopeLEA]){
            let index = excelData.findIndex(e => e.LEA == a.scopeLEA)
            
            if (index == -1) {
              excelData.push({ Name: this.leaDictionary[a.scopeLEA].name, LEA: a.scopeLEA, Type: 'Total Employed' })
              excelData.push({ Name: this.leaDictionary[a.scopeLEA].name, LEA: a.scopeLEA, Type: 'Leaving' })
              excelData.push({ Name: this.leaDictionary[a.scopeLEA].name, LEA: a.scopeLEA, Type: 'Returning' })
            }
            
            excelData[excelData.findIndex(e => e.LEA == a.scopeLEA && e.Type == 'Total Employed')][` ${a.fy + 1990}`] = this.formatDataTotalEmployed(a)
            excelData[excelData.findIndex(e => e.LEA == a.scopeLEA && e.Type == 'Leaving')][` ${a.fy + 1990}`] = this.formatDataLeaving(a)
            excelData[excelData.findIndex(e => e.LEA == a.scopeLEA && e.Type == 'Returning')][` ${a.fy + 1990}`] = this.formatDataReturning(a)
          }
        })
        return {
            name: 'Employment Statistics',
            items: excelData
        }
    },
    excelExportData() {
        return [this.attritionTableExport(), this.employementStatsTableExport()]
    }
  },
  watch: {
    chartData(newData) {     
    this.tableData = newData
    }
  }
})

export { AttritionReportTable }
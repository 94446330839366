<template lang="html">
  <div class="d-inline">
    <Promised :promise="actionPromise" v-show="actionPromise">
      <template v-slot:pending>
        <button disabled="disabled" @click="exportExcel" class="btn btn-success float-right d-print-none mr-1">
          <i class="fas fa-spinner fa-spin" />&nbsp;Excel!
        </button>
      </template>
      <template v-slot="data">
        <button @click="exportExcel" class="btn btn-success float-right d-print-none mr-1">
          <i class="far fa-file-excel" />&nbsp;Excel
          <span class="d-none">{{ data }}</span>
        </button>
      </template>
      <template v-slot:rejected="error">
        <button @click="exportExcel" class="btn btn-success float-right d-print-none mr-1">
          <i class="fas fa-exclamation-circle" />&nbsp;Excel
          <span class="d-none">{{ error }}</span>
        </button>
      </template>
    </Promised>
    <button @click="exportExcel" class="btn btn-success float-right d-print-none mr-1" v-show="actionPromise == null">
      <i class="far fa-file-excel" />&nbsp;Excel
    </button>
  </div>
</template>

<script>
import { Promised } from 'vue-promised'
import ExcelService from '../../../services/ExcelService'

//const chartTableClassName = 'chart-data-table'

export default {
  name: 'ct-excel-button',
  data() {
    return {
      actionPromise: null
    }
  },
  props: {
    title: String
  },
  methods: {
    async exportExcel() {
      const excelService = new ExcelService()
      const lea = this.$store.state.globalModule.leaContext.SelectedLea.number || ''
      const workbookName = `${this.title}-${lea}`
      var workbook = await excelService.createWorkbook(workbookName)
      var worksheetData = []

      //get any exportable data from the parent control
      this.getTableData(this.$parent, worksheetData)

      worksheetData.forEach(async sheet => {
        await excelService.addWorksheet(workbook, sheet.name.replace('/', '').substr(0, 31), sheet.items)
      })
      this.actionPromise = excelService.saveFile(workbook, `${workbookName}.xlsx`)
    },

    getTableData(component, worksheetData) {
      if (typeof component.excelExportData === 'function') {
        //console.log('Exporting ' + component.constructor.options.name)
        const data = component.excelExportData()
        if (Array.isArray(data)) {
          data.forEach(d => {
            worksheetData.push(d)
          })
        } else worksheetData.push(data)
      }
    }
  },
  components: {
    Promised
  }
}
</script>
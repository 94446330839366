<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { round } from '../../../helpers/mathHelpers'
import { credits } from '../../../helpers/credits'

export default {
  name: 'average-years-teaching-experience-chart',
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'column'
        },
        credits: credits.SIS,
        title: {
          text: 'Average Years of Teaching Experience'
        },
        series: [
          {
            name: 'Average Years of Teaching Experience',
            data: [
              {
                x: 2014,
                y: 3.34
              }
            ]
          }
        ],
        xAxis: {
          title: {
            text: 'School Year'
          },
          type: 'category'
        },
        yAxis: {
          title: {
            text: 'Avg. Years Experience'
          },
          endOnTick: false
        }
      }
    }
  },
  mixins: [ChartMixin],
  props: {
    staffData: Array
  },
  methods: {
    dataItems() {
      const series = (this.chartOptions.series || [])
      if (series.length < 0) return []
      return series[0].data.map(data => {
        return {
          SchoolYear: data.x,
          AverageYearsTeacherExperience: data.y
        }
      })
    },
    excelExportData() {
      return {
        name: 'Average Years of Teaching Experience',
        items: this.dataItems()
      }
    },
  },
  watch: {
    staffData(newData) {
      var seriesData = newData.map(row => {
        return {
          x: (row.fiscalYear % 1990) + 1990,
          y: round(row['averageYearsTeacherExperience'], 2)
        }
      })
      this.chartOptions.series[0].data = seriesData
    }
  }
}
</script>

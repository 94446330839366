<template>
  <div v-if="render">
    <a :name="'table-' + table" class="jumptarget" />
    <table id="subject-table" class="table table-striped table-bordered" style="width:100%">
      <caption>Table 3: Count and Percentage of {{ subjectCase(subject) }} Teachers by Letter Grade and Effectiveness Rating</caption>
      <thead>
        <tr>
          <th rowspan="2">
            Rating for {{ subjectCase(subject) }}
          </th>
          <th class="text-center" colspan="6">
            School Letter Grade
          </th>
        </tr>
        <tr>
          <th class="text-right" v-for="letterGrade in letterGrades" :key="letterGrade">
            {{ letterGrade }}
          </th>
          <th class="text-right">
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="level in effectivenessLevels" :key="level.code">
          <th>{{ `${level.code}: ${level.name}` }}</th>
          <td class="text-right" v-for="letterGrade in letterGrades" :key="letterGrade">
            {{ getTableValue(level.code, letterGrade).toLocaleString("en-US") }}<br>
            {{ ((getTableValue(level.code, letterGrade) / getRowTotal(level.code) * 100.0) || 0).toFixed(2) }}%
          </td>
          <td class="text-right">
            {{ getRowTotal(level.code).toLocaleString("en-US") }}
          </td>
        </tr>
        <tr>
          <th>Total</th>
          <td class="text-right" v-for="letterGrade in letterGrades" :key="letterGrade">
            {{ getColumnTotal(letterGrade).toLocaleString("en-US") }}
          </td>
          <td class="text-right">
            {{ grandTotal.toLocaleString("en-US") }}
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="7" class="text-right">
            Number of teachers whose school is missing a school letter grade = {{ teachersMissingLetterGrade }}
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { subjectCase } from '../helpers.js'
import { mapGetters } from 'vuex'
import { effectivenessLevels } from '../enums.js';

export default {
  props: {
    render: {
      type: Boolean,
      default: true
    },
    table: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      subject: undefined,
      subjectCase,
      effectivenessLevels,
      letterGrades: ['A', 'B', 'C', 'D', 'F']
    }
  },
  methods: {
    getRating(level) {
      for (var i = 0; i < this.tableData[0].ratings.length; i++) {
        if (this.tableData[0].ratings[i].rating == level) {
          return this.tableData[0].ratings[i];
        }
      }
    },
    getGroupsForLetterGrade(rows) {
      return rows.reduce((acc, curr) => {
        acc[curr.schoolLetterGrade] = acc[curr.schoolLetterGrade] || 0
        acc.total = acc.total || 0
        acc[curr.schoolLetterGrade]++
        if (curr.schoolLetterGrade) acc.total++
        return acc
      }, {})
    },
    getTableValue(level, grade) {
      const key = `${level}_${grade}`

      if (typeof this.getTableValue.cache === 'undefined') this.getTableValue.cache = new Map()
      if (this.getTableValue.cache.has(key)) return this.getTableValue.cache.get(key)
      else {
        var value = undefined
        var rating = this.tableData[0].ratings.find(rating => rating.rating == level)
        if (rating) {
          var grades = rating.grades[grade]
          value = grades || 0
        }
        else value = 0        
        this.getTableValue.cache.set(key, value)
        return value
      } 
    },
    getRowTotal(level) {
      const key = level

      if (typeof this.getRowTotal.cache === 'undefined') this.getRowTotal.cache = new Map()
      if (this.getRowTotal.cache.has(key)) return this.getRowTotal.cache.get(key)
      else {
        var value = undefined
        var rating = this.getRating(level)
        if (rating) {
          value = rating.grades.total || 0
        }
        else value = 0

        this.getRowTotal.cache.set(key, value)
        return value
      }
    },
    getColumnTotal(grade) {
      var total = 0
      this.tableData[0].ratings.forEach(rating => {
        total += rating.grades[grade] || 0
      })
      return total
    },
    excelExportData() {
      var data = []
      this.effectivenessLevels.forEach(level => {
        var row = {}
        row['Rating for '] = `${level.code}: ${level.name}`
        this.letterGrades.forEach(letterGrade => {
          row[letterGrade] = this.getTableValue(level.code, letterGrade)
        })
        row['Total'] = this.getRowTotal(level.code)
        data.push(row)
      })

      // add a total row
      var row = {}

      row['Total'] = this.grandTotal
      data.push(row)

      // add Number of teachers whose school is missing a school letter grade
      row = {}
      row['Rating for '] = 'Number of teachers whose school is missing a school letter grade = ' + this.teachersMissingLetterGrade
      data.push(row)
      
      return {
        name: 'LetterGrade-Category',
        items: data
      }
    }
  },
  computed: {
    ...mapGetters('teacherVAMModule', ['detailsBySubject']),
    tableData() {
      return Object.keys(this.detailsBySubject).map(subject =>
      {
        const ratings = this.detailsBySubject[undefined].reduce((acc, curr) => {
          acc[curr.rating] = acc[curr.rating] || []
          acc.total = acc.total || 0
          acc[curr.rating].push(curr)
          acc.total++
          return acc
        }, {})
        const ratingsMap = Object.keys(ratings).filter(rating => rating != 'total').map(rating => {
          return { rating, grades: this.getGroupsForLetterGrade(ratings[rating]) }
        })

        return { subject, ratings: ratingsMap }
      })
    },
    teachersMissingLetterGrade() {
      var gradeData = this.tableData[0].ratings.flatMap(rating => rating.grades)
      return gradeData.flatMap(grade => grade.undefined).reduce((acc, curr) => acc + curr, 0) || 0
    },
    grandTotal() {
      var total = 0
      this.tableData[0].ratings.forEach(rating => {
        total += rating.grades.total || 0
      })
      return total
    },
  }
}
</script>
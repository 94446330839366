<template>
  <div>
    <div class="row">
      <div class="col">
        <h1>Roster Verification System</h1>
        <div>
          <h2><lea-display :lea="selectedLea" /></h2>
          <!-- <a @click="goToLea(selectedLea.parentLea.number)" class="cursor-pointer btn btn-sm btn-outline-warning"><i class="fa fa-fw fa-level-up-alt" /> {{ selectedLea.parentLea.name }}</a> -->
        </div>
      </div>
      <div class="col-auto" />
    </div>

    <b-row no-gutters class="mt-3" style="margin-bottom: -2rem;" v-if="districtInfo.length > 0">
      <b-col cols="3">
        <verification-progress-chart units="districts" title="District Verification Progress" :complete="completeDistricts" :total="totalDistricts" />
      </b-col>
      <b-col cols="3">
        <verification-progress-chart units="schools" title="School Verification Progress" :complete="completeSchoolsByDistrict" :total="totalSchoolsByDistrict" />
      </b-col>
      <b-col cols="3">
        <verification-progress-chart units="teachers" title="Teacher Verification Progress" :complete="completeTeachersByDistrict" :total="totalTeachersByDistrict" />
      </b-col>
      <b-col cols="3" class="text-center">
        <div class="highcharts-title" style="color:#333333;font-size:18px;fill:#333333;">
          Number of Contributors
        </div>
        <div class="highcharts-data-labels highcharts-series-0 highcharts-solidgauge-series highcharts-tracker" style="font-size:20px; font-family: 'Source Sans Pro'; color: rgb(0, 0, 0); font-weight: bold;">
          {{ totalContributors }}
        </div>
      </b-col>
    </b-row>
    <div v-else>
      <loading-spinner />
    </div>

    <keep-alive>
      <timeline />
    </keep-alive>
    
    <div class="card mt-3">
      <div class="card-header">
        Districts
      </div>
      <div class="card-body card-body-secondary">
        <district-list />
      </div>
    </div>

    <crosstabs />
  </div>
</template>

<script>
import LeaDisplay from '@/components/LeaDisplay.vue'
import VerificationProgressChart from './charts/VerificationProgressChart.vue'
import DistrictList from '../common/DistrictList.vue'
import Timeline from '@/modules/rvs/components/common/Timeline.vue'
import Crosstabs from '@/modules/rvs/components/common/Crosstabs.vue'

import { mapGetters } from 'vuex';
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default {
  data() {
      return {
      }
    },
    methods: {
    },
  components: {
    LeaDisplay,
    VerificationProgressChart,
    DistrictList,
    Timeline,
    Crosstabs,
    LoadingSpinner
  },
  computed: {
    ...mapGetters('rvsModule', ['districtInfo', 'completeDistricts', 'totalDistricts', 'completeTeachersByDistrict', 'totalTeachersByDistrict', 'completeSchoolsByDistrict', 'totalSchoolsByDistrict','timelineDates']),
    totalContributors() {
      return this.districtInfo && this.districtInfo.length > 0 ? this.districtInfo.find(f => f.totalContributors !== undefined).totalContributors : 0
    }

  }
}
</script>
<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { credits } from '../../../helpers/credits'

export default {
  name: 'employment-by-gender-chart',
  props: {
    staffData: Array,
    recordType: Number
  },
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'line'
        },
        credits: credits.SIS,
        title: {
          text: 'Employment by Gender'
        },
        xAxis: {
          title: {
            text: 'School Year'
          },
          type: 'category'
        },
        yAxis: {
          title: {
            text: '# Employed'
          },
          endOnTick: false
        }
      }
    }
  },
  computed: {
    filteredData() {
      if (!this.staffData) return []
      return this.staffData.filter(d => d.recType == this.recordType)
    }
  },
  mixins: [ChartMixin],
  methods: {
    dataItems() {
      return (this.filteredData || []).map(row => {
        return {
          SchoolYear: (row.fy % 1990) + 1990,
          Male: row.male,
          Female: row.female
        }
      })
    },
    excelExportData() {
      let typeName = 'Certified Teaching'
      if (this.recordType == 1) 
        typeName = 'Non-Teaching'
      else if (this.recordType == 2) 
        typeName = 'Classified'
      return {
        name: `${typeName} Employment by Gender`,
        items: this.dataItems()
      }
    },
  },
  watch: {
    filteredData(newData) {
      var dataSet = [
        { name: 'Male', field: 'male' },
        { name: 'Female', field: 'female' }
      ]
      this.chartOptions.series = []
      for (var i = 0; i < dataSet.length; i++) {
        var seriesData = newData.map(row => {
          return {
            x: (row.fy % 1990) + 1990,
            y: row[dataSet[i].field],
            ...row
          }
        })
        dataSet[i].data = seriesData
      }
      this.chartOptions.series = dataSet
    }
  }
}
</script>

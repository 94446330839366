<template>
  <div>
    <b-form-group>
      <b-row>
        <b-col cols="5">
          <b-form-radio-group id="status-buttons" v-model="selectedStatus" text-field="name" buttons name="status-buttons">
            <b-form-radio :value="0" button-variant="secondary">
              Pending <span class="badge badge-light">{{ pendingCount }}</span>
            </b-form-radio>
            <b-form-radio :value="1" button-variant="primary">
              Processing <span class="badge badge-light">{{ processingCount }}</span>
            </b-form-radio>
            <b-form-radio :value="2" button-variant="success">
              Completed <span class="badge badge-light">{{ completedCount }}</span>
            </b-form-radio>
            <b-form-radio :value="3" button-variant="danger">
              Failure <span class="badge badge-light">{{ failedCount }}</span>
            </b-form-radio>
            <b-form-radio :value="4" button-variant="warning">
              Aborted <span class="badge badge-light">{{ abortedCount }}</span>
            </b-form-radio>
          </b-form-radio-group>
        </b-col>
        <b-col>
          <b-input-group>
            <b-form-input inline v-model="individualLea" class="col-md-3" />
            <b-button inline @click="queueIndividualBuild" class="col-md-4 ml-3">
              Enqueue Build
            </b-button>
          </b-input-group>
        </b-col>
        <b-col>
          <label inline>Number of days to display</label>
          <b-form-select v-model="days" :options="dayOptions" @change="daysChanged" class="col-md-3 ml-3" size="small" />
        </b-col>
      </b-row>
    </b-form-group>
    <district-extract-monitor-chart :load-data="loadData" :selected-status="selectedStatus" />
  </div>
</template>

<script>
import DistrictExtractMonitorChart from './DistrictExtractMonitorChart'
import { ProcessStatuses } from './extractEnums'
import { mapState } from 'vuex'

export default {
  components: {
    DistrictExtractMonitorChart
  },
  data(){
    return {
      ProcessStatuses: ProcessStatuses,
      selectedStatus: null,
      individualLea: null,
      days: 1,
      dayOptions:[
        {value: 1, text: 'One'},
        {value: 2, text: 'Two'},
        {value: 3, text: 'Three'}
      ]
    }
  },
  computed:{
    ...mapState('adminModule', ['recentBatchProcesses']),
    districts() { 
      return this.recentBatchProcesses || []
    },
    pendingCount() {
      return this.districts.filter(d => d.processStatus == 0).length
    },
    processingCount() {
      return this.districts.filter(d => d.processStatus == 1).length
    },
    completedCount() {
      return this.districts.filter(d => d.processStatus == 2).length
    },
    failedCount() {
      return this.districts.filter(d => d.processStatus == 3).length
    },
    abortedCount() {
      return this.districts.filter(d => d.processStatus == 4).length
    }
  },
  props:{
    loadData: Promise
  },
  methods:{
    daysChanged(days){
      this.$emit('days-changed', days)
    },
    queueIndividualBuild(){
      this.$store.dispatch('adminModule/enqueueIndividualDistrict', { lea: this.individualLea })
      this.$emit('build-queued')
      this.individualLea = null
    }
  }
}
</script>
import ApiService from '@/services/ApiService'
import removeNamespace from '@/stores/removeNamespace'
import { Student } from './student'
import { RemoveNullsFromResponse } from '@/helpers/jsonResponseHelpers'
import Vue from 'vue'
import { Reporting } from './reporting'
import axios from 'axios'
//import { Statuses } from '@/components/dpsaft/facilities/inspectionStatus'
export const Types = {
  state: {
    isLoadingStudent: 'isLoadingStudent',
    currentStudent: 'currentStudent',
    metadata: 'metadata',
    students: 'students',
    effectiveRosterDate: 'effectiveRosterDate',
    users: 'users',
    gpa: 'gpa',
    narratives: 'narratives',
    bulkVisits: 'bulkVisits',
    currentBulkVisit: 'currentBulkVisit',
    bulkVisitAttendees: 'bulkVisitAttendees',
    visitAcademicYears: 'visitAcademicYears',
    dashboard: 'dashboard',
    dashboardACT: 'dashboardACT',
    colleges: 'colleges',
    degrees: 'degrees',
    majors: 'majors',
    rosterGradeFilter: 'rosterGradeFilter',
    rosterSortOption: 'rosterSortOption',
    graduationYears: 'graduationYears',
  },
  getters: {
    visitCurrentAY: 'visitCurrentAY',
  },
  actions: {
    getDashboard: 'cte/careercoach/getDashboard',
    getDashboardACT: 'cte/careercoach/getDashboardACT',
    getMetadata: 'cte/careercoach/getMetadata',
    getStudents: 'cte/careercoach/getStudents',
    getStudent: 'cte/careercoach/getStudent',
    saveStudentInformation: 'cte/careercoach/saveStudentInformation',
    getUsers: 'cte/careercoach/getUsers',
    setCurrentStudent: 'cte/careercoach/setCurrentStudent',
    saveBulkVisit: 'saveBulkVisit',
    deleteBulkVisit: 'cte/careercoach/deleteBulkVisit',
    updateBulkVisit: 'cte/careercoach/updateBulkVisit',
    deleteBulkVisitFile: 'cte/careercoach/deleteBulkVisitFile',
    downloadBulkVisitFile: 'cte/careercoach/downloadBulkVisitFile',
    getACTAspireAssessments: 'cte/careercoach/getACTAspireAssessments',    
    getACTAssessments: 'cte/careercoach/getACTAssessments',
    getCurrentStudentVisits: 'cte/careercoach/getCurrentStudentVisits',
    createVisit: 'cte/careercoach/createVisit',
    updateVisit: 'cte/careercoach/updateVisit',
    getBulkVisits: 'cte/careercoach/getBulkVisits',
    getBulkVisitAttendees: 'cte/careercoach/getBulkVisitAttendees',
    removeStudentFromAttendees: 'cte/careercoach/removeStudentFromAttendees',
    getVisitAcademicYears: 'cte/careercoach/getVisitAcademicYears',
    addStudentToBulkVisit: 'cte/careercoach/addStudentToBulkVisit',
    addStudentsToBulkVisit: 'cte/careercoach/addStudentsToBulkVisit',
    removeStudentFromBulkVisit: 'cte/careercoach/removeStudentFromBulkVisit',
    removeStudentVisit: 'cte/careercoach/removeStudentVisit',
    getStudentGPA: 'cte/careercoach/getStudentGPA',
    getNarratives: 'cte/careercoach/getNarratives',
    getNarrative: 'cte/careercoach/getNarrative',
    createNarrative: 'cte/careercoach/createNarrative',
    deleteNarrative: 'cte/careercoach/deleteNarrative',
    updateNarrative: 'cte/careercoach/updateNarrative',
    deleteNarrativeFile: 'cte/careercoach/deleteNarrativeFile',
    downloadNarrativeFile: 'cte/careercoach/downloadNarrativeFile',
    deleteCertificationFile: 'cte/careercoach/deleteCertificationFile',
    downloadCertificationFile: 'cte/careercoach/downloadCertificationFile',
    setProgramOfStudyNonTraditionalGender: 'cte/careercoach/setProgramOfStudyNonTraditionalGender',
    getColleges: 'cte/careercoach/getColleges',
    getDegrees: 'cte/careercoach/getDegrees',
    getMajors: 'cte/careercoach/getMajors',
    updateCollege: 'cte/careercoach/updateCollege',
    updateDegree: 'cte/careercoach/updateDegree',
    updateMajor: 'cte/careercoach/updateMajor',
    addCollege: 'cte/careercoach/addCollege',
    addDegree: 'cte/careercoach/addDegree',
    addMajor: 'cte/careercoach/addMajor',
    getGraduationYears: 'getGraduationYears',
    getLeas: 'cte/careercoach/getLeas',
  },
  mutations: {
    setDashboard: 'cte/careercoach/setDashboard',
    setDashboardACT: 'cte/careercoach/setDashboardACT',
    setMetadata: 'cte/careercoach/setMetadata',
    setStudents: 'cte/careercoach/setStudents',
    setEffectiveRosterDate: 'cte/careercoach/setEffectiveRosterDate',
    setCurrentStudent: 'cte/careercoach/setCurrentStudent',
    setIsLoadingStudent: 'cte/careercoach/setIsLoadingStudent',
    setCurrentStudentInfo: 'cte/careercoach/setCurrentStudentInfo',
    setUsers: 'cte/careercoach/setUsers',
    setACTAspireAssessments: 'cte/careercoach/setACTAspireAssessments',
    setACTAssessments: 'cte/careercoach/setACTAssessments',
    setStudentGPA: 'cte/careercoach/setStudentGPA',
    setNarratives: 'cte/careercoach/setNarratives',
    setCurrentStudentVisits: 'cte/careercoach/setCurrentStudentVisits',
    setLeaBulkVisits: 'cte/careercoach/setLeaBulkVisits',
    removeBulkVisitFromList: 'cte/careercoach/removeBulkVisitFromList',
    addFileToBulkVisit: 'cte/careercoach/addFileToBulkVisit',
    removeFileFromBulkVisit: 'cte/careercoach/removeFileFromBulkVisit',
    removeStudentFromAttendees: 'cte/careercoach/removeStudentFromAttendees',
    setCurrentBulkVisit: 'cte/careercoach/setCurrentBulkVisit',
    setBulkVisitAttendees: 'cte/careercoach/setBulkVisitAttendees',
    setVisitAcademicYears: 'cte/careercoach/setVisitAcademicYears',
    addVisitToList: 'cte/careercoach/addVisitToList',
    removeVisitFromList: 'cte/careercoach/removeVisitFromList',
    setColleges: 'cte/careercoach/setColleges',
    setDegrees: 'cte/careercoach/setDegrees',
    setMajors: 'cte/careercoach/setMajors',
    setRosterGradeFilter: 'cte/careercoach/setRosterGradeFilter',
    setRosterSortOption: 'cte/careercoach/setRosterSortOption',
    setGraduationYears: 'cte/careercoach/setGraduationYears'
  },
  path: 'cte/careercoach'
}
const _types = removeNamespace('cte/careercoach/', Types)

const responseFields = [
  'studentInformationResponse',
  'initialNeedsAssessmentResponse',
  'academicOutcomesResponse',
  'certificationsResponse',
  'careerPlanningResponse',
  'workExperienceResponse',
  'postsecondaryPlanningResponse',
]

export class CareerCoach {

  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
  }
  namespaced = true

  state = {
    [Types.state.currentStudent]: new Student(),
    [Types.state.isLoadingStudent]: false,
    [Types.state.students]: [],
    [Types.state.metadata]: {
      collegesAndDegrees: {}
    },
    [Types.state.users]: [],
    [Types.state.gpa]: [],
    [Types.state.narratives]: [],
    [Types.state.visitAcademicYears]: [],
    [Types.state.bulkVisits]: [],
    [Types.state.effectiveRosterDate]: '',
    [Types.state.dashboard]: {
      currentRoster: [],
      gradeCounts: [],
      visitsByGrade: [],
      attendingCollege: [],
      certs: [],
      exposure: []
    },
    [Types.state.dashboardACT]: [],
    [Types.state.currentBulkVisit]: undefined,
    [Types.state.bulkVisitAttendees]: [],
    [Types.state.colleges]: [],
    [Types.state.degrees]: [],
    [Types.state.majors]: [],
    [Types.state.graduationYears]: [],
    [Types.state.rosterGradeFilter]: {
      grade: null,
      graduationYear: null
    },
    [Types.state.rosterSortOption]: { sortBy: 'student', ascending: true }
  }

  mutations = {
    [_types.mutations.setDashboard]: (state, data) => state.dashboard = data,
    [_types.mutations.setDashboardACT]: (state, data) => state.dashboardACT = data,
    [_types.mutations.setStudents]: (state, data) => {
      state.students = data.roster,
      state.effectiveRosterDate = data.effectiveDate
    },
    [_types.mutations.setCurrentStudent]: (state, data) => state.currentStudent = new Student(data),
    [_types.mutations.setIsLoadingStudent]: (state, data) => state.isLoadingStudent = data,
    [_types.mutations.setCurrentStudentInfo]: (state, data) => {
      responseFields.forEach(f => {
        if (data[f]) {
          if (typeof data[f] == 'string') {
            data[f] = JSON.parse(data[f])
          }
          Vue.set(state.currentStudent.studentInformation, f, data[f])
        }
      })
    },
    [_types.mutations.setMetadata]: (state, data) => state.metadata = data,
    [_types.mutations.setUsers]: (state, data) => state.users = data,
    [_types.mutations.setACTAspireAssessments]: (state, data) => state.currentStudent.academicOutcomes.ACTAspireAssessments = data,    
    [_types.mutations.setACTAssessments]: (state, data) => state.currentStudent.academicOutcomes.ACTAssessments = data,
    [_types.mutations.setStudentGPA]: (state, data) => state.gpa = data,
    [_types.mutations.setNarratives]: (state, data) => state.narratives = data,
    [_types.mutations.setCurrentStudentVisits]: (state, data) => state.currentStudent.visits = data.sort((a,b) => new Date(a.visit.visitDate) - new Date(b.visit.visitDate)),
    [_types.mutations.setLeaBulkVisits]: (state, data) => state.bulkVisits = data,
    [_types.mutations.removeBulkVisitFromList]: (state, data) =>{
      var index = state.bulkVisits.findIndex(bv => bv.visitId == data)
      if(index > -1) {
       state.bulkVisits.splice(index, 1)
      }
    },
    [_types.mutations.addFileToBulkVisit]: (state, data) => {
      var index = state.bulkVisits.findIndex(bv => bv.visitId == data.visitId )
      state.bulkVisits[index].signInSheet = data.file
    },
    [_types.mutations.removeFileFromBulkVisit]: (state, data) => {
      var index = state.bulkVisits.findIndex(bv => bv.visitId == data.visitId )
      state.bulkVisits[index].signInSheet = null
    },
    [_types.mutations.setCurrentBulkVisit]: (state, data) => state.currentBulkVisit = data,
    [_types.mutations.setBulkVisitAttendees]: (state, data) => state.bulkVisitAttendees = data,
    [_types.mutations.setVisitAcademicYears]: (state, data) => state.visitAcademicYears = data,
    [_types.mutations.addVisitToList]: (state, data) =>{ 
      state.currentStudent.visits.push(data)
      state.currentStudent.visits = state.currentStudent.visits.slice().sort((a,b) => new Date(a.visit.visitDate) - new Date(b.visit.visitDate))
    },
    [_types.mutations.removeVisitFromList]: (state, data) => {
      var index = state.currentStudent.visits.findIndex(v => v.visit.visitId == data.visitId)
      if(index >= 0) {
        state.currentStudent.visits.splice(index, 1)
      }
    },    
    [_types.mutations.removeStudentFromAttendees]: (state, data) => {
      var index = state.bulkVisitAttendees.findIndex(a => a.studentId == data.studentId)
      if(index >= 0) {
        state.bulkVisitAttendees.splice(index, 1)
      }
    },
    [_types.mutations.setStudentGPA]: (state, data) => state.gpa = data,
    [_types.mutations.setColleges]: (state, data) => state.colleges = data,
    [_types.mutations.setDegrees]: (state, data) => state.degrees = data,
    [_types.mutations.setMajors]: (state, data) => state.majors = data,
    [_types.mutations.setRosterGradeFilter]: (state, data) => state.rosterGradeFilter = data,
    [_types.mutations.setRosterSortOption]: (state, data) => state.rosterSortOption = data,
    [_types.mutations.setGraduationYears]: (state, data) => state.graduationYears = data

  }

  getters = {
    currentStudentLea: (state) => state.currentStudent.lea || state.currentStudent.schoolLEA,
    [Types.getters.visitCurrentAY]: () => {
      var today = new Date()
      var month = today.getMonth()
      if ([8, 9, 10, 11].includes(month))
      {
          return today.getFullYear() - 1999;
      }
      else
      {
          return today.getFullYear() - 2000;
      }
    }
  }

  actions = {
    [_types.actions.getLeas]: context => {
      return this._apiService
        .Get(`${this._baseUrl}api/cte/career-coach/lea`)
        .then(leas => {
          return context.commit('globalModule/setLeas', { leas: leas, source: 'globalModule/getLeas' }, { root: true })
        })
    },
    [_types.actions.getDashboard]: ({ commit }, payload) => {
      commit(_types.mutations.setDashboard, {
        currentRoster: [],
        gradeCounts: [],
        visitsByGrade: [],
        attendingCollege: [],
        certs: [],
        exposure: []
      })

      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/career-coach/dashboard/${payload.ay}/${payload.lea}`))
        .then(data => commit(_types.mutations.setDashboard, data))
    },
    [_types.actions.getDashboardACT]: ({ commit }, payload) => {
      commit(_types.mutations.setDashboardACT, [])
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/career-coach/dashboard/act/${payload.lea}`))
        .then(data => commit(_types.mutations.setDashboardACT, data))
    },    
    [_types.actions.getStudents]: ({ commit }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/career-coach/${payload.lea}/student-roster?gradeLevel=${payload.gradeLevel || ''}&graduationYear=${payload.graduationYear || ''}`))
        .then(data => {
           commit(_types.mutations.setStudents, data)
        })
    },
    [_types.actions.getStudent]: ({ commit }, payload) => {
      commit(_types.mutations.setIsLoadingStudent, true)

      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/career-coach/${payload.lea}/student/${payload.studentId}`))
        .then(data => {
          commit(_types.mutations.setCurrentStudent, data)
          commit(_types.mutations.setIsLoadingStudent, false)
        })
    },
    [_types.actions.getMetadata]: ({ commit }) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/career-coach/metadata`))
        .then(data => commit(_types.mutations.setMetadata, data))
    },
    [_types.actions.saveStudentInformation]: ({ commit, state }, payload) => {
      payload.lea = state.currentStudent.schoolLEA
      payload.studentId = state.currentStudent.studentId
      payload.response = JSON.stringify(payload.response, RemoveNullsFromResponse)
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/career-coach/${payload.lea}/student/${payload.studentId}/${payload.responseName}`), payload.response)
         .then(data => commit(_types.mutations.setCurrentStudentInfo, data))
    },
    [_types.actions.saveBulkVisit]: (_, payload) => {
      payload.visitData = JSON.stringify(payload.visitData)
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/cte/career-coach/${payload.lea}/visit`), payload)
    },
    [_types.actions.getUsers]: ({ commit }) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/career-coach/admin/users`))
        .then(data => commit(_types.mutations.setUsers, data))
    },
    [_types.actions.getACTAspireAssessments]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/assessment/${payload.studentId}/act-aspire`))
        .then(data => commit(_types.mutations.setACTAspireAssessments, data))
    },
    [_types.actions.getACTAssessments]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/assessment/${payload.studentId}/act`))
        .then(data => commit(_types.mutations.setACTAssessments, data))
    },
    [_types.actions.getStudentGPA]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/career-coach/${payload.lea}/student/${payload.studentId}/gpa`))
        .then(data => commit(_types.mutations.setStudentGPA, data))
    },
    [_types.actions.getNarratives]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/cc-reports/narratives?lea=${payload.lea}`))
        .then(data => {
          if (!Array.isArray(data)) 
            data = []
          commit(_types.mutations.setNarratives, data)
        })
    },
    [_types.actions.getNarrative]: (_, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/cc-reports/narrative/${payload}`))
    },
    [_types.actions.createNarrative]: (_, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/cc-reports/narrative`), payload)
    },
    [_types.actions.deleteNarrative]: (_, payload) => {
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/cte/cc-reports/narrative/${payload}`))
    },
    [_types.actions.updateNarrative]: (_, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/cte/cc-reports/narrative`), payload)
    },
    [_types.actions.deleteNarrativeFile]: (_, payload) => {
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/cte/cc-reports/narrative/${payload.narrativeId}/files/${payload.fileId}`))
    },
    [_types.actions.deleteCertificationFile]: (_, payload) => {
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/cte/career-coach/${payload.lea}/student/${payload.studentId}/certification-files/${payload.certificationId}?filename=${payload.fileName}`))
    },
    [_types.actions.downloadNarrativeFile]: (_, payload) => {
      var url = encodeURI(`${this._baseUrl}api/cte/cc-reports/narrative/${payload.narrativeId}/files/${payload.fileId}/download`)

      return axios.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: payload.mimeType })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = payload.fileName
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },
    [_types.actions.downloadCertificationFile]: (_, payload) => {
      var url = encodeURI(`${this._baseUrl}api/cte/career-coach/${payload.lea}/student/${payload.studentId}/certification-files/${payload.certificationId}?filename=${payload.fileName}`)

      return axios.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: payload.mimeType })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = payload.fileName
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },
    [_types.actions.getCurrentStudentVisits]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/career-coach/${payload.lea}/student/${payload.studentId}/visits?ay=${payload.ay || 0}`))
        .then(data => commit(_types.mutations.setCurrentStudentVisits, data))
    },
    [_types.actions.createVisit]: ({ getters, state }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/career-coach/${getters.currentStudentLea}/student/${payload.studentId}/visit`), payload.visit)
        .then(data => state.currentStudent.addVisit(data))
    },
    [_types.actions.updateVisit]: ({ getters }, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/cte/career-coach/${getters.currentStudentLea}/student/${payload.studentId}/visit/${payload.visit.visit.visitId}`), payload.visit)
    },
    [_types.actions.getBulkVisits]: ({commit, getters}, payload) => {
      payload.grade = payload.grade || ''
      payload.ay = payload.ay || getters.visitCurrentAY

      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/career-coach/${payload.lea}/bulk-visits?grade=${payload.grade}&ay=${payload.ay}`))
        .then(data => commit(_types.mutations.setLeaBulkVisits, data))
    },
    [_types.actions.deleteBulkVisit]: ({commit}, payload) => {
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/cte/career-coach/${payload.lea}/visit/${payload.visitId}`))
        .then(data => commit(_types.mutations.removeBulkVisitFromList, data))
    },
    [_types.actions.downloadBulkVisitFile]: (_, payload) => {
      var url = encodeURI(`${this._baseUrl}api/cte/career-coach/${payload.lea}/bulk-visits/${payload.visitId}/sign-in-sheet`)
      
       return axios.get(url, { responseType: 'blob' })
        .then(response => {
          var cd = response.headers['content-disposition']
          var fileName = cd.substring(cd.indexOf("=")+1)
          const blob = new Blob([response.data], { type: payload.mimeType })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },
    [_types.actions.deleteBulkVisitFile]: ({commit}, payload) => {
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/cte/career-coach/${payload.lea}/bulk-visits/${payload.visitId}/sign-in-sheet`))
        .then(() => commit(_types.mutations.removeFileFromBulkVisit, {visitId: payload.visitId}))
    },
    [_types.actions.getVisitAcademicYears]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/career-coach/${payload.lea}/visit-academic-years`))
        .then(data => commit(_types.mutations.setVisitAcademicYears, data))
    },
    [_types.actions.addStudentToBulkVisit]: ({commit, getters, state}, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/cte/career-coach/${getters.currentStudentLea}/student/${state.currentStudent.studentId}/bulk-visits/${payload.visitId}`))
        .then(data => commit(_types.mutations.addVisitToList, data))
    },
    [_types.actions.updateBulkVisit]: (_, payload) => {
      return this._apiService.Patch(encodeURI(`${this._baseUrl}api/cte/career-coach/${payload.lea}/bulk-visits/${payload.visitId}`), payload.visitData)
    },
    [_types.actions.addStudentsToBulkVisit]: ({commit, state}, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/cte/career-coach/${payload.lea}/bulk-visits/${state.currentBulkVisit.visitId}/attendees`), payload.students.map(s => s.studentId))
        .then(data => commit(_types.mutations.setBulkVisitAttendees, data))
    },
    [_types.actions.removeStudentFromBulkVisit]: ({commit,dispatch}, payload) => {
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/cte/career-coach/${payload.lea}/student/${payload.student.studentId}/bulk-visits/${payload.visitId}`))
        .then(data => {
           commit(_types.mutations.removeVisitFromList, data)
           dispatch(_types.actions.getBulkVisits, payload)
        })
    },
    [_types.actions.removeStudentVisit]: ({commit, getters, state}, payload) => {
      return this._apiService.Delete(encodeURI(`${this._baseUrl}api/cte/career-coach/${getters.currentStudentLea}/student/${state.currentStudent.studentId}/visit/${payload.visitId}`))
        .then(data => commit(_types.mutations.removeVisitFromList, data))
    },
    [_types.actions.getBulkVisitAttendees]: ({commit}, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/career-coach/${payload.lea}/bulk-visits/${payload.visitId}/attendees`))
        .then(data => commit(_types.mutations.setBulkVisitAttendees, data))
    },
    [_types.actions.setProgramOfStudyNonTraditionalGender]: ({commit}, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/career-coach/admin/pos-non-trad-gender/${payload.id}?gender=${payload.gender}`))
        .then(() => commit('cte/setProgramOfStudyNonTraditionalGender', payload, { root: true }))
    },
    [_types.actions.getColleges]: ({commit}) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/career-coach/admin/colleges`))
        .then(data => commit(_types.mutations.setColleges, data))
    },
    [_types.actions.getDegrees]: ({commit}) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/career-coach/admin/degrees`))
        .then(data => commit(_types.mutations.setDegrees, data))
    },
    [_types.actions.getMajors]: ({commit}) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/career-coach/admin/majors`))
        .then(data => commit(_types.mutations.setMajors, data))
    },
    [_types.actions.updateCollege]: (_, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/cte/career-coach/admin/colleges/${payload.Id}`), payload)
    },
    [_types.actions.updateDegree]: (_, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/cte/career-coach/admin/degrees/${payload.Id}`), payload)
    },
    [_types.actions.updateMajor]: (_, payload) => {
      return this._apiService.Put(encodeURI(`${this._baseUrl}api/cte/career-coach/admin/majors/${payload.Id}`), payload)
    },
    [_types.actions.addCollege]: (_, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/career-coach/admin/colleges`), payload)
    },
    [_types.actions.addDegree]: (_, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/career-coach/admin/degrees`), payload)
    },
    [_types.actions.addMajor]: (_, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/career-coach/admin/majors`), payload)
    },
    [_types.actions.getGraduationYears]: ({commit}) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/career-coach/graduation-years`))
        .then(data => commit(_types.mutations.setGraduationYears, data))
    },
  }

  modules = {
    reporting: new Reporting()
  }
}
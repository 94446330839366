<template>
  <div> 
    <paginated-table :items="filteredPlans" :fields="fields" sort-by="createDate" :sort-desc="true" :filter-function="applyFilter">
      <template v-slot:cell(districtLea)="data">
        <div>
          {{ leaFormatter(data.value) }} 
        </div>
      </template>
      <template v-slot:cell(createdDate)="data">
        {{ data.value | date }}
      </template>
      <template v-slot:cell(updatedDate)="data">
        {{ data.value | date }}
      </template>
      <template v-slot:cell(superintendent)="data">
        <a :href="`mailto:${data.item.email}`">
          {{ data.value }}
        </a>
      </template>
      <template v-slot:cell(phone)="data">
        <a :href="`tel:${data.item.phone}`">
          {{ data.value }}
        </a>
      </template>
      <template v-slot:cell(snapshotYears)="data">
        <table class="w-100 table table-sm table-borderless p-0 m-0">
          <thead> 
            <th v-for="snapshotYear in fiscalYears(data.value || [])" :key="snapshotYear" class="text-center text-secondary">
              {{ formatSchoolYear(snapshotYear-1) }}-{{ formatSchoolYear(snapshotYear) }} Plan
            </th>
          </thead>
          <tbody>
            <td v-for="snapshotYear in fiscalYears(data.value || [])" :key="snapshotYear" class="text-center">
              <b-button 
                v-for="snapshot in (data.value || []).filter(s => s.fiscalYear == snapshotYear)"
                :key="snapshot.fiscalYear + snapshot.month"
                size="sm" 
                variant="link" 
                :href="`/api/esa/${data.item.districtLea}/plan/snapshot/${snapshot.fiscalYear}/${snapshot.month}`">
                <span class="text-secondary fas fa-file-pdf" /> {{ formatSnapshotYear(snapshot) }}
              </b-button>
            </td>
          </tbody>
        </table>
      </template>
      <template v-slot:cell(actionColumn)="data">
        <b-button size="sm" variant="primary" :to="{name: 'esa-plan-public', query: { lea: data.item.districtLea, id: data.item.leaPlanId }}" class="mr-1">
          <i class="fa fa-fw fa-eye" title="View Plan" />
        </b-button>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PaginatedTable from '@/components/PaginatedTable'
import { Types, StateMap } from '@/modules/esa/stores/'
import { formatDate } from '@/helpers/formatters'
import groupBy from 'lodash.groupby'

export default {
  data() {
    return {
      StateMap,
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.plans]),
    ...mapState('globalModule', ['userContext']),
    fields() { 
      let fields = [
        { key: 'districtLea', label: 'District', sortable: true, },
        { key: 'superintendent' },
        { key: 'phone' },
        { key: 'createdDate', label: 'Created', sortable: true },
        { key: 'updatedDate', label: 'Latest Modified', sortable: true },
        // { key: 'status', label: 'Status', sortable: true },
        { key: 'snapshotYears', label: 'Snapshots', class: 'text-center nowrap p-0' },
        { key: 'actionColumn', class: 'text-right nowrap', label: '' },
      ]

      return fields
    },
    filteredPlans() {
      let plans = this.plans.map(plan => {
        const district = this.leaDictionary[plan.districtLea]
        return {
          ...plan,
          superintendent: district ? `${district.adminFirstName} ${district.adminLastName}` : '',
          phone: district ? district.adminPhoneNumber : '',
          email: district ? district.adminEmailAddress : '',
        }
      })
      return plans
    },
  },
  methods: {
    fiscalYears(snapshots) {
      return Object.keys(groupBy(snapshots, snapshot => snapshot.fiscalYear))
    },
    formatSchoolYear(year) {
      return `${parseInt(year) + 1990}`
    },
    formatSnapshotYear(snapshot) {
      if (snapshot.month == 'Oct')
        return `${snapshot.fiscalYear + 1989} ${snapshot.month}`

      return `${snapshot.fiscalYear + 1990} ${snapshot.month}`
    },
    applyFilter(row, filter) {
      // Ensure that all rows pass for empty filter
      if (!filter) return true

      if (this.leaFormatter(row.districtLea).includes(filter)) 
        return true
      if (row.superintendent.includes(filter)) 
        return true
      if (row.phone.includes(filter)) 
        return true
      if (formatDate(row.createdDate).includes(filter))
        return true
      if (formatDate(row.updatedDate).includes(filter))
        return true

      return false
    },
  },
  components: {
    PaginatedTable,
  },
  props: {
    districtLea: String,
    filter: {
      type: Object
    },
  },
}
</script>

<style>
  .nowrap {
    white-space: nowrap;
  }
</style>
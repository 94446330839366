<template lang="html">
  <li v-if="lea" style="white-space: nowrap; width: 100%; overflow-x: hidden;">
    <lea-icon :lea="lea" :svg-mode="true" />&nbsp;<button :id="linkId" class="btn btn-link lead" v-html="leaNameFormatted" @click="changeSelectedLea" />
    <ul v-if="lea.ownedLeas && lea.ownedLeas.length > 0" v-for="(childLea, index) in lea.ownedLeas" :key="index">
      <lea-template v-if="childLea != null && childLea.number" :lea="childLea" :search-term="searchTerm" />
    </ul>
  </li>
</template>

<script>
import LeaIcon from './LeaIcon.vue'

export default {
  name: 'lea-template',
  props: {
    lea: Object,
    searchTerm: String
  },
  computed: {
    linkId() {
      return `lea-${this.lea.number}`
    },
    accordionId() {
      return `accordion-${this.lea.number}`
    },
    headingId() {
      return `heading-${this.lea.number}`
    },
    bodyId() {
      return `collapse-${this.lea.number}`
    },
    leaNameFormatted() {
      if (this.selectedLea.number == this.lea.number)
        return `<strong>${this.lea.name}</strong>`

      if (this.searchTerm) {
        var regex = new RegExp(this.searchTerm, 'ig')
        return this.lea.name.replace(regex, match => `<strong>${match}</strong>`)
      } else {
        return this.lea.name;
      }
    },
    leaNumberFormatted() {
      if (this.selectedLea.number == this.lea.number)
        return `(<strong>${this.selectedLea.number}</strong>)`

      if (this.lea.number && this.searchTerm) {
        var regex = new RegExp(this.searchTerm, 'ig')
        return `(${this.lea.number.replace(regex, match =>`<strong>${match}</strong>`)})`
      }
      return ''
    },
    selectedLea() {
      return this.$store.state.globalModule.leaContext.SelectedLea
    }
  },
  components: {
    LeaIcon
  },
  methods: {
    changeSelectedLea() {
      this.$root.$data.leaSidebarActive = false
      this.goToLea(this.lea.number)
    }
  }
}
</script>
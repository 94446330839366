<template>
  <loadable-chart-card :load-data="loadData" card-class="bg-white p-1 inner-card justify-content-center">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
  import Highcharts from 'highcharts'
  import { LeaTypes } from '@/helpers/leaTypes'
  import { ChartMixin } from '@/components/ChartMixin'
  import { credits } from '@/helpers/credits'
  // import groupBy from 'lodash.groupby'
  import { DataLevels } from '@/modules/esa/shared/enums'

  export default {
    mixins: [ChartMixin],
    data() {
      return {
        animationDuration: 1000,
        chartOptions: {
          chart: {
            type: 'column',
            backgroundColor: '#fff',
            plotBackgroundColor: '#eee',
          },
          plotOptions: {
            series: {
              minPointLength: 3
            },
            column: {
              dataLabels: {
                enabled: true,
                useHTML: true,
                format: '{series.name}<br />{point.y:.2f}{point.metricSuffix}'
              }
            }
          },
          legend: {
            enabled: false,
          },
          series: [],
          credits: credits.MSI,
          title: {
            text: `${this.title}`
          },
          tooltip:{
            enabled: false,
            formatter: this.tooltipFormatter,
            valueDecimals: 2,
          },
          xAxis: {
            categories: this.subgroups.map(s => s.name),
            type: 'category',
            title: {
              // text: 'School'
            }
          },
          yAxis: {
            stackLabels: {
              enabled: true
            },
            title: {
              enabled: false,
            },
            plotLines: [],
            labels: {
            }
          }
        },
      }
    },
    props: {
      title: String,
      subgroups: Array,
      students: Array,
      teachers: Array,
      metricSuffix: {
        type: String,
        default: ''
      },
      tooltipFormatter: {
        type: Function,
        default: function() {
          return `${this.x}<br/><span style="color:${this.series.color}">${this.series.name}</span>: <b>${Highcharts.numberFormat(this.y, 2)}${this.point.metricSuffix}</b>`
        }
      },
      range: {
        type: Array,
        default: () => []
      },
      mode: {
        default: 'current',
        type: String
      }
    },
    watch: {
      students: {
        immediate: true,
        handler() {
          this.populate(this.students, this.teachers)
        }
      },
      teachers: {
        immediate: true,
        handler() {
          this.populate(this.students, this.teachers)
        }
      },
    },
    methods: {
      populate(students, teachers) {
        this.chartOptions.series = []
        if (students && students.length > 0 && teachers && teachers.length > 0) {
          this.chartOptions.series = [
            { 
              name: 'Teachers', 
              colorByPoint: true,
              data: this.setSeriesCurrent(teachers),
            },
            { 
              name: 'Students', 
              colorByPoint: true,
              data: this.setSeriesCurrent(students),
            },
          ]
        }
      },
      setSeriesCurrent(data) {
        const self = this
        this.chartOptions.legend.enabled = false
        this.chartOptions.yAxis.plotLines = []
        //debugger
        this.chartOptions.chart.type = 'column'
        const stateAvgMetrics = data.filter(d => d.dataLevel == LeaTypes.State)
        if (stateAvgMetrics.length > 0) {
          this.chartOptions.yAxis.plotLines.push({
            width: 2,
            dashStyle: 'shortdash',
            color: DataLevels.State.color,
            label: {
            },
            value: stateAvgMetrics[0][self.field]
          })
        }
        const districtAvgMetrics = data.filter(d => d.dataLevel == LeaTypes.District)
        if (districtAvgMetrics.length > 0) {   
          this.chartOptions.title.text = `${(districtAvgMetrics[0].fy % 1990) + 1990} ${this.title}`
        }
        return [
          ...this.subgroups.map(subgroup => {
            return { 
              y: districtAvgMetrics[0][subgroup.field], 
              color: subgroup.color, 
              metricSuffix: self.metricSuffix 
            }
          })
        ]
      },
      dataItems() {
        return []
      },
    }
  }
  
</script>
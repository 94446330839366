<template>
  <div>
    <div class="row" v-if="currentRequest">
      <district-info-section
        v-if="currentRequestDistrictInfo"
        :request-lea="currentRequestDistrictInfo"
      />
    </div>
    <div class="row">
      <div class="col-md-4 offset-8 text-right">
        <b-button variant="primary" @click="regenerate" v-if="(false)">
          Regenerate
        </b-button>
        <b-button variant="primary" @click="sendNotification">
          Send District Notification
        </b-button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12" style="background-color: gray">
        <div class="row">
          <div class="col-md-8 offset-2">
            <div class="card outer-card my-4" style="height: 620px;overflow-y: scroll;">
              <div class="card-body">
                <Promised :promise="loading">
                  <template v-slot:default>
                    <pdf :src="responseDocument" />
                  </template>

                  <template v-slot:pending>
                    <i class="fas fa-spinner fa-spin" />&nbsp;Please wait while the response PDF is generated...
                  </template>

                  <template v-slot:rejected="error">
                    THERE WAS AN ERROR
                    <i class="fas fa-exclamation-circle" />
                    &nbsp; {{ error }}
                  </template>
                </Promised>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import DistrictInfoSection from './districtInfoSection'
import { mapState } from 'vuex'
import { Promised } from 'vue-promised'

export default {
  name: 'finalize-review',
  data() {
    return {
      assignedLea: null,
      leaInput: null,
      busy: false,
      loading: Promise.resolve(true),
      pdfSource: [],
      pdfProgressNotification: null
    }
  },

  mounted() {
    this.loading = this.$store
      .dispatch('toolsModule/leaAssignment/getRequest', {
        requestId: this.$route.params.id
      })
      .then(() => this.$store.dispatch('toolsModule/leaAssignment/getResponseDocument', {
          requestId: this.$route.params.id,
          inline: true,
          documentType: 1,
          createIfMissing: true })
      )
  },
  computed: {
    ...mapState('toolsModule/leaAssignment', [
      'currentRequest',
      'currentResponse',
      'responseDocument'
    ]),
    ...mapState('toolsModule/leaAssignment', ['stateMap']),
    ...mapState('globalModule', ['leaContext', 'userContext']),
    currentRequestDistrictInfo() {
      return this.leaContext.LeaDictionary[this.currentRequest.districtLEA]
    }
  },
  methods: {
    sendNotification() {
      return this.$store
        .dispatch('toolsModule/leaAssignment/sendApprovalNotification', {
          requestId: this.$route.params.id
        })
        .then(() => this.$router.push({ name: 'requests' }))
    },
    regenerate() {
      this.loading = this.$store
        .dispatch('toolsModule/leaAssignment/generateResponseDocument', {
          requestId: this.$route.params.id
        })
    }
  },
  components: {
    pdf,
    DistrictInfoSection,
    Promised
  },
  props: {}
}
</script>

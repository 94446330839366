<template>
  <div class="d-print-none">
    <div class="mb-2 card" v-if="request && StateMap.get(request.status).supportsAssignment && (isSuperIntendent || isAssignedToCurrentAPSCNUser)">
      <div class="card-header">
        Assign Waiver Request
      </div>
      <div class="card-body p-2">
        <div class="row">
          <div class="col-md-12">
            <b-form-group label="Assign to:">
              <div class="loadingSpinner" v-if="usersLoading">
                <i class="fas fa-spinner fa-spin" /> Loading users list...
              </div>
              <b-form-select v-model="assignTo" :options="districtUsers" />
            </b-form-group>
            <b-form-group label="Comment:">
              <b-form-textarea v-model="comment" placeholder="Please provide a comment..." />
            </b-form-group>
            <animated-button button-class="m-1 btn btn-sm btn-primary" :promise-resolver="returnToSuperintendent" v-if="currentAssignedUserId" :disabled="disableReturnToSuperintendent">
              <i class="fas fa-backward" />&nbsp; {{ this.isSuperIntendent ? 'Re-Assign to Me' : 'Return to Superintendent' }}
            </animated-button>
            <animated-button button-class="btn btn-sm btn-primary" :promise-resolver="assignOrComment" :disabled="!comment || !assignTo">
              <i class="fas fa-user" />&nbsp; Assign Waiver Request
            </animated-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Types, StateMap } from '@/stores/digitalLearning'
import AnimatedButton from '@/components/AnimatedButton'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      currentState: null,
      assignTo: null,
      comment: null,
      StateMap
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState(Types.path, [Types.state.districtUsers, Types.state.availableStates]),
    isAPSCNUser() {
      return this.availableStates && this.availableStates.advanceStates && this.availableStates.advanceStates.length == 0
    },
    disableReturnToSuperintendent() {
      if (this.isSuperIntendent) {
        return this.isAssignedToCurrentUser
      }
      return !this.comment || !this.isAssignedToCurrentAPSCNUser || Boolean(this.assignTo)
    },
    isSuperIntendent() {
      return this.availableStates && this.availableStates.advanceStates && this.availableStates.advanceStates.length > 0
    },
    currentAssignedUserId() {
      if (this.request && (this.request.changeLogs || []).some(l => l.assignedToUserId && l.assignedToUserId.length > 0)) {
        let changeLogs = [...this.request.changeLogs].reverse()
        return changeLogs.find(l => l.assignedToUserId && l.assignedToUserId.length > 0).assignedToUserId
      }
      return null
    },
    usersLoading() {
      return this.districtUsers.length < 1
    },
    userId() {
      return (this.userContext.userId || '')
    },
    isAssignedToCurrentUser() {
      return this.currentAssignedUserId && (this.currentAssignedUserId.includes(this.userId) || this.currentAssignedUserId == this.userContext.emailAddress)
    },
    isAssignedToCurrentAPSCNUser() {
      if (this.isAPSCNUser && this.isAssignedToCurrentUser) {
        return true
      }
      return false
    },
  },
  props: {
    request: Object
  },
  watch: {
    request: {
      immediate: true,
      handler(newRequest) {
        if (newRequest && newRequest.districtLEA && StateMap.get(newRequest.status).supportsAssignment) {
          this.$store.dispatch(Types.actions.getDistrictUsers, { lea: newRequest.districtLEA })
        }
      }
    }
  },
  methods: {
    assignOrComment() {
      const self = this
      return this.$store.dispatch(Types.actions.assignOrComment, {
        id: this.request.waiverRequestId,
        assignToName: this.assignTo.assignToName,
        assignToUserId: this.assignTo.assignToUserId,
        comment: this.comment
      }).then(() => {
        self.assignTo = null
        self.comment = null
      })
    },
    returnToSuperintendent() {
      const self = this
      return this.$store.dispatch(Types.actions.assignOrComment, {
        id: this.request.waiverRequestId,
        assignToName: this.request.superintendent,
        assignToUserId: this.request.email,
        comment: this.comment
      }).then(() => {
        self.assignTo = null
        self.comment = null
      })
    },
  },
  components: {
    AnimatedButton,
  }
}
</script>

<style>
.loadingSpinner {
  display: inline;
  position: absolute;
  top: 8px;
  left: 10px;
}
</style>
<template>
  <loadable-chart-card :load-data="loadData" :enable-data-toggle="false" card-style="">
    <div class="w-100 p-2">
      <table-control-widget :per-page="tableControl.perPage" 
                            @filter-changed="tableControl.filter=$event" 
                            @page-length-changed="tableControl.perPage=$event" />
      <b-table id="unqualified-course-table"
               :items="unqualifiedCourses"
               :fields="fields"
               :per-page="tableControl.perPage"
               :current-page="tableControl.currentPage"
               :filter="tableControl.filter"
               @filtered="onFiltered"
               small>
        <template v-slot:cell(schoolLEA)="data">
          <a href="#" @click="goToLea(data.value)" :id="data.index + '-' + data.value">
            {{ data.value }}
          </a>
          <lea-tooltip :target-selector="data.index + '-' + data.value" :lea-number="data.value" />
        </template>
        <template v-slot:cell(fullName)="data">
          <a :href="'mailto:' + data.item.emailAddress">{{ data.value }}</a>
        </template>
        <template slot="empty">
          <h4>No teachers found</h4>
        </template>
      </b-table>
      <table-pagination-widget small :total-rows-count="rowCount" :page-length="tableControl.perPage" @page-changed="tableControl.currentPage=$event" />
    </div>
  </loadable-chart-card>
</template>



 
<script>
import { ChartMixin } from '../../ChartMixin'
import { TableMixin } from '../../TableMixin'

export default {
    name: 'teacher-unqualified-course-table',
    data() {
        return {
            tableControl: {
                filter: null,
                perPage: this.$printMode ? Number.MAX_SAFE_INTEGER : 50,
                currentPage: 1
            },
            rowCount: 1,
            animationDuration: 1000,
            fields: [
                { key: 'employeeId', title: 'State Id', sortable: true },
                { key: 'fullName', sortable: true },
                { key: 'fullCourseDescription', sortable: true },
                { key: 'schoolLEA', sortable: true },
                { key: 'result', 
                    sortable: true, 
                    formatter: function(d) {return d ? "Pass" : "Failed";} 
                }
            ]
        }
    },
    mixins: [ChartMixin, TableMixin],
    computed: {
        leaDictionary() {
            return this.$store.state.globalModule.leaContext.LeaDictionary
        },
        showOnlyViolations() {
          return this.$store.state.scheduleModule.showOnlyViolations
        },
        unqualifiedCourses() {
            if(this.showOnlyViolations){
                return this.$store.state.scheduleModule.unqualifiedCourses
                .filter(c => !c.result)
            }
            return this.$store.state.scheduleModule.unqualifiedCourses
        }
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.currentPage = 1
            this.rowCount = filteredItems.length
        },
        dataItems() {
            return this.unqualifiedCourses
        },
        goToLea(leaNumber) {
            const lea = this.leaDictionary[leaNumber]
            window.scrollTo(0,0)
            this.$store.dispatch('globalModule/changeSelectedLea', lea)
        },
        excelExportData() {
            return {
                name: 'Unqualified Courses',
                items: this.unqualifiedCourses
            }
        }
    },
    watch: {
        unqualifiedCourses: {
            immediate: true,
            handler(courses) {
                this.rowCount = courses.length
            }
        }
    }
}
</script>
<template>
  <div>
    <highcharts class="chart" :options="chartOptions" />
  </div>
</template>

<script>
import { VisitTypes } from '@/modules/cte/stores/careercoach/reporting'

export default {
  data() {
    return {
      monthOption: null,
      VisitTypes,
      reportData: {},
      chartOptions: {
        chart: {
          type: 'column'
        },
        title: {
          text: 'Percent of Students Visited by Visit Topic'
        },
        series: [],
        plotOptions: {
          column: {
            //stacking: 'percent'
          }
        },
        exporting: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        xAxis: {
          title: {
            text: 'Grade'
          },
          gridLineWidth: 1,
          categories: []
        },
        yAxis: {
          title: {
            text: 'Percent of Students'
          },
          endOnTick: false
        },
        tooltip: {
        formatter: function () {
            return this.points.reduce(function (s, point) {
                return s + '<br/><span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': ' +
                    point.y + '%';
            }, '<b>Grade ' + parseInt(this.x).toString() + '</b>');
        },
        shared: true
    },
      }
    }
  },
  computed: {
    getChartData() {
      return this.data.data
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(newData) {
        this.chartOptions.xAxis.categories = newData.enrollment.map(e => parseInt(e.grade).toString())
        this.chartOptions.series = this.VisitTypes.map(v => {
          return {
            name: v.label,
            data: newData.enrollment.map(e => {
              var point = newData.data.find(d => d.grade == e.grade && d.topic == v.key)
              return point && point.totalEnrollment ? Math.round((point.uniqueVisitsByTypeYTReportEndDate / point.totalEnrollment) * 10000) / 100 : 0
            })
          }
        })
      }
    }
  },
  props: {
    data: Object
  }
}
</script>
<template>
  <div>
    <div class="card my-2">
      <b-card>
        <template v-slot:header>
          <b-row>
            <b-col cols="9" lg="10">
              <h4 class="mb-0">
                {{ `District: ${batch.districtName || 'Not Specified'}` }}
              </h4>
              <h6 class="mb-0">
                LEA: {{ batch.districtLea }}
              </h6>
            </b-col>
            <b-col cols="1" lg="2" class="text-right">
              <span class="fas fa-check fa-2x text-success rounded-lg p-2 bg-white" v-if="batch.status == InspectionBatchStatus.Completed" title="Completed" />
              <span class="fas fa-stopwatch fa-2x text-info rounded-lg p-2 bg-white" v-else-if="batch.status == InspectionBatchStatus.InProgress" />
              <span class="fas fa-clock fa-2x text-primary rounded-lg p-2 bg-white" v-else title="Pending" />
            </b-col>
          </b-row>
        </template>
        <b-card-text class="lead">
          {{ batch.description }}
        </b-card-text>
        <b-form>
          <b-form-row>
            <label class="col-form-label col-8 col-md-2">Inspections Completed</label>
            <label class="col-form-label col-4 col-md-1 text-right">{{ completedInspections }} / {{ totalInspections }}</label>
          </b-form-row>
        </b-form>
        <div class="row">
          <div class="col-sm-12 text-right">
            <router-link :to="{name: 'Facilities/Batch/Inspection', params: {batchId: batch.batchId}}" exact>
              <i class="far fa-play-circle fa-2x" />
            </router-link>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { InspectionBatchStatus, InspectionStatuses } from '../../common/Enums'

export default {
  name: 'inspection-card',
  data(){
    return {
      InspectionBatchStatus
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    totalInspections() {
      return (this.batch.inspections || []).length
    },
    completedInspections() {
      return (this.batch.inspections || []).filter(i => i.status == InspectionStatuses.Completed || i.status == InspectionStatuses.Skipped).length
    }
  },
  props: {
    batch: Object
  }
}
</script>

<template>
  <div>
    <table class="table table-xs chart">
      <thead>
        <tr>
          <th rowspan="2" class="text-center col-bg alt d-none" role="columnheader" aria-label="Click to sort or something" aria-sort="none">
            CSI/ATS
          </th>
          <th rowspan="2" class="col-bg" style="width: 275px;">
            School Name
          </th>
          <th rowspan="2" class="text-center col-bg alt">
            Enrl
          </th>
          <th rowspan="2" class="text-center col-bg">
            Pov.
            <br>
            (%)
          </th>
          <th rowspan="2" class="text-center col-bg alt">
            SPED
            <br>
            (%)
          </th>
          <th rowspan="2" class="text-center col-bg">
            Range of
            <br>
            Grades
            <br>
            Served by
            <br>
            School
          </th>
          <th rowspan="2" class="text-center col-bg alt">
            Grade
            <br>
            Span
            <br>
            Assigned
            <br>
            based on
            <br>
            School
            <br>
            Grade
            <br>
            Range
          </th>
          <th rowspan="2" class="d-none">
            Subgroup
          </th>
          <th class="text-center col-bg">
            {{ currentSchoolYear }}
            <br>
            Letter
            <br>
            Grade
          </th>
          <th :colspan="displayYears" class="text-center col-bg alt">
            ESSA
            <br>
            School
            <br>
            Index
          </th>
          <th :colspan="displayYears" class="text-center col-bg">
            Adjusted
            <br>
            Weighted ELA
            <br>
            &amp; Math Score
          </th>
          <th :colspan="displayYears" class="text-center col-bg alt">
            Content with
            <br>
            ELP
            <br>
            Growth Score
          </th>
          <th :colspan="displayYears" class="text-center col-bg">
            SQSS
            <br>
            % Score
          </th>
          <th :colspan="displayYears" class="text-center col-bg alt">
            Graduation
            <br>
            Rate (4Yr)
          </th>
          <th :colspan="displayYears" class="text-center col-bg">
            Graduation
            <br>
            Rate (5Yr)
          </th>
          <th rowspan="2" class="text-center col-bg alt">
            Reading at
            <br>
            Grade Level
          </th>
          <th rowspan="2" class="text-center col-bg">
            Avg of
            <br>
            Reading %
            <br>
            In Need
            <br>
            of Support
            <br>
            by District
          </th>
        </tr>
        <tr>
          <th class="col-bg text-center">
            {{ currentSchoolYear }}
          </th>
          <template v-for="year in schoolYears">
            <th class="col-bg alt text-center" :key="'a' + year">
              {{ year }}
            </th>
          </template>
          <template v-for="year in schoolYears">
            <th class="col-bg text-center" :key="'b' + year">
              {{ year }}
            </th>
          </template>
          <template v-for="year in schoolYears">
            <th class="col-bg alt text-center" :key="'c' + year">
              {{ year }}
            </th>
          </template>
          <template v-for="year in schoolYears">
            <th class="col-bg text-center" :key="'d' + year">
              {{ year }}
            </th>
          </template>
          <template v-for="year in schoolYears">
            <th class="col-bg alt text-center" :key="'e' + year">
              {{ year - 1 }}
            </th>
          </template>
          <template v-for="year in schoolYears">
            <th class="col-bg text-center" :key="'f' + year">
              {{ year - 1 }}
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr class="filter xd-none d-print-none">
          <td class="d-none" />
          <td><input v-model="tableFilters.name" class="form-control" placeholder="School Name filter..."></td>
          <td colspan="4" />
          <td>
            <select v-model="tableFilters.gradeSpan" class="form-control text-right">
              <option />
              <option class="text-right">
                ELEM
              </option>
              <option>MS</option>
              <option>HS</option>
            </select>
          </td>
          <td>
            <select v-model="tableFilters.letterGrade" class="form-control text-right">
              <option />
              <option>A</option>
              <option>B</option>
              <option>C</option>
              <option>D</option>
              <option>F</option>
            </select>
          </td>
          <td :colspan="schoolYears.length * 6 + 2" />
        </tr>
        <template v-for="(row, index) in tableData">
          <tr :key="index"> 
            <td class="text-center col-bg alt p-0 d-none">
              <h5 class="m-1" v-html="csiAtsBadge(null)" />
            </td>
            <td class="col-bg" style="width: 275px;">
              <a href="#" :id="row.schoolLEA" @click="goToLea(row.schoolLEA)">{{ row.schoolName }}</a>
              <lea-tooltip :target-selector="row.schoolLEA" :lea-number="row.schoolLEA" />
            </td>
            <td class="text-center col-bg alt">
              {{ row.enrollment }}
            </td>
            <td class="text-center col-bg">
              {{ row.povertyRate | toPercentage(2, false) }}
            </td>
            <td class="text-center col-bg alt">
              {{ row.spedPct | toPercentage(2, false) }}
            </td>
            <td class="text-center col-bg">
              {{ row.gradeRange }}
            </td>
            <td class="text-center col-bg alt">
              {{ row.gradeSpan }}
            </td>
            <td class="d-none">
              SG
            </td>
            <td class="col-bg text-center p-0">
              <h5 class="m-1" v-html="letterGradeBadge(row.schoolLetterGrade)" />
            </td>
            <template v-for="(year, i) in schoolYears">
              <td v-if="i < schoolYears.length - 1" class="col-bg alt text-center" :key="'essaindex' + year + 'a'">
                {{ row.essaIndex[translateIndex(i)] | round(2) }}
              </td>
              <td
                v-if="i == schoolYears.length - 1"
                class="col-bg alt text-center trend"
                :class="trendClass(row.essaIndex[translateIndex(0)], row.essaIndex[translateIndex(schoolYears.length - 1)])" :key="'essaindex' + year + 'b'">
                {{ row.essaIndex[translateIndex(i)] | round(2) }}
              </td>
            </template>
            <template v-for="(year, i) in schoolYears">
              <td v-if="i < schoolYears.length - 1" class="col-bg text-center" :key="'wgtPerfScore' + year + 'a'">
                {{ row.wgtPerfScore[translateIndex(i)] | round(2) }}
              </td>
              <td
                v-if="i == schoolYears.length - 1"
                class="col-bg text-center trend"
                :class="trendClass(row.wgtPerfScore[translateIndex(0)], row.wgtPerfScore[translateIndex(schoolYears.length - 1)])" :key="'wgtPerfScore' + year + 'b'">
                {{ row.wgtPerfScore[translateIndex(i)] | round(2) }}
                {{ row.wgtPerfScore[translateIndex(i)] | round(2) }}
              </td>
            </template>
            <template v-for="(year, i) in schoolYears">
              <td v-if="i < schoolYears.length - 1" class="col-bg alt text-center" :key="'growthScore' + year + 'a'">
                {{ row.growthScore[translateIndex(i)] | round(2) }}
              </td>
              <td
                v-if="i == schoolYears.length - 1"
                class="col-bg alt text-center trend"
                :class="trendClass(row.growthScore[translateIndex(0)], row.growthScore[translateIndex(schoolYears.length - 1)])" :key="'growthScore' + year + 'b'">
                {{ row.growthScore[translateIndex(i)] | round(2) }}
              </td>
            </template>
            <template v-for="(year, i) in schoolYears">
              <td v-if="i < schoolYears.length - 1" class="col-bg text-center" :key="'sqssScore' + year + 'a'">
                {{ row.sqssScore[translateIndex(i)] | round(2) }}
              </td>
              <td
                v-if="i == schoolYears.length - 1"
                class="col-bg text-center trend"
                :class="trendClass(row.sqssScore[translateIndex(0)], row.sqssScore[translateIndex(schoolYears.length - 1)])" :key="'sqssScore' + year + 'a'">
                {{ row.sqssScore[translateIndex(i)] | round(2) }}
              </td>
            </template>
            <template v-for="(year, i) in schoolYears">
              <td v-if="i < schoolYears.length - 1" class="col-bg alt text-center" :title="format4YearGraduationRateTitle(row, translateIndex(i))" :key="'gradRate4YrPct' + year + 'a'">
                {{ (row.gradRate4YrPct[translateIndex(i)] | round(2)) || 'N/A' }}
              </td>
              <td
                v-if="i == schoolYears.length - 1"
                class="col-bg alt text-center trend"
                :title="format4YearGraduationRateTitle(row, translateIndex(i))"
                :class="trendClass(row.gradRate4YrPct[translateIndex(0)], row.gradRate4YrPct[translateIndex(schoolYears.length - 1)])" :key="'gradRate4YrPct' + year + 'b'">
                {{ (row.gradRate4YrPct[translateIndex(i)] | round(2)) || 'N/A' }}
              </td>
            </template>
            <template v-for="(year, i) in schoolYears">
              <td v-if="i < schoolYears.length - 1" class="col-bg text-center" :title="format5YearGraduationRateTitle(row, translateIndex(i))" :key="'gradRate5YrPct' + year + 'a'">
                {{ (row.gradRate5YrPct[translateIndex(i)] | round(2)) || 'N/A' }}
              </td>
              <td
                v-if="i == schoolYears.length - 1"
                class="col-bg text-center trend"
                :title="format5YearGraduationRateTitle(row, translateIndex(i))"
                :class="trendClass(row.gradRate5YrPct[translateIndex(0)], row.gradRate5YrPct[translateIndex(schoolYears.length - 1)])" :key="'gradRate5YrPct' + year + 'b'">
                {{ (row.gradRate5YrPct[translateIndex(i)] | round(2)) || 'N/A' }}
              </td>
            </template>
            <td class="col-bg alt" />
            <td class="col-bg" />
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import LeaTooltip from '../../LeaTooltip.vue'
import { mapGetters } from 'vuex'
import { round as roundFunc } from '@/helpers/mathHelpers'

export default {
  name: 'academics-overview-table',
  data() {
    return {
      filters: {
        gradeSpan: null,
        letterGrade: null,
        name: null
      }
    }
  },
  components: {
    LeaTooltip
  },
  props: {
    fy: Number,
    displayYears: Number
  },
  methods: {
    round(number, decimals) {
      return roundFunc(number, decimals)
    },
    letterGradeBadge(value) {
      const template = "<span class='badge badge-{CLASS}'>{LETTER_GRADE}</span>"
      var kindClass = value == 'D' || value == 'F' ? 'danger' : 'secondary'
      return template.replace(/{CLASS}/, kindClass).replace(/{LETTER_GRADE}/, value || 'N/A')
    },
    csiAtsBadge(value) {
      const template = "<span class='badge badge-{CLASS}'>{CSI_ATS}</span>"
      var kindClass = value ? 'danger' : 'secondary'
      return template.replace(/{CLASS}/, kindClass).replace(/{CSI_ATS}/, value || '')
    },
    trendClass(firstValue, secondValue) {
      if (firstValue && secondValue) {
        if (!(isNaN(firstValue) || isNaN(secondValue))) {
          if (firstValue > secondValue) return 'trend-down'
          if (firstValue < secondValue) return 'trend-up'
        }
      }

      return ''
    },
    goToLea(leaNumber) {
      const lea = this.$store.getters['globalModule/leaDictionary'][leaNumber]
      window.scrollTo(0, 0)
      this.$store.dispatch('globalModule/changeSelectedLea', lea)
    },
    translateIndex(yearIdx) {
      if (this.displayYears == 3) return yearIdx
      else return yearIdx + 1 //if we re only showing two years of data, then we need to increase the index by one because the underlying data model always has three years worth of data, so we need to make sure we ignore the first year
    },
    format5YearGraduationRateTitle(row, idx) {
      if (row.gradRate5YrExpectedGraduates[idx] && row.gradRate5YrActualGraduates[idx])
        return `${this.gradRate(row.gradRate5YrActualGraduates[idx], row.gradRate5YrExpectedGraduates[idx])}`
      return ''
    },
    format4YearGraduationRateTitle(row, idx) {
      if (row.gradRate4YrExpectedGraduates[idx] && row.gradRate4YrActualGraduates[idx])
        return `${this.gradRate(row.gradRate4YrActualGraduates[idx], row.gradRate4YrExpectedGraduates[idx])}`
      return ''
    },

    gradRate(actual, expected) {
      if (actual && expected) return actual / expected
      return null
    },
    excelExportData() {
      return {
        name: 'Academics',
        items: this.$store.getters['academicsModule/essa/filteredData'](this.filters).map(e => {
          return {
            SchoolName: e.schoolName,
            Enrollment: e.enrollment,
            PovertyRate: e.povertyRate * 100,
            SpEdPercent: e.spedPct * 100,
            GradeRange: e.gradeRange,
            GradeSpan: e.gradeSpan,
            SchoolLetterGrade: e.schoolLetterGrade,
            ...{
              [`ESSASchoolIndex${this.schoolYears[0]}`]: e.essaIndex[0],
              [`ESSASchoolIndex${this.schoolYears[1]}`]: e.essaIndex[1],
              [`ESSASchoolIndex${this.schoolYears[2]}`]: e.essaIndex[2]
            },
            ...{
              [`ESSAWeightedScore${this.schoolYears[0]}`]: e.wgtPerfScore[0],
              [`ESSAWeightedScore${this.schoolYears[1]}`]: e.wgtPerfScore[1],
              [`ESSAWeightedScore${this.schoolYears[2]}`]: e.wgtPerfScore[2]
            },
            ...{
              [`ESSAContentGrowthScore${this.schoolYears[0]}`]: e.growthScore[0],
              [`ESSAContentGrowthScore${this.schoolYears[1]}`]: e.growthScore[1],
              [`ESSAContentGrowthScore${this.schoolYears[2]}`]: e.growthScore[2]
            },
            ...{
              [`ESSASQSSScore${this.schoolYears[0]}`]: e.sqssScore[0],
              [`ESSASQSSScore${this.schoolYears[1]}`]: e.sqssScore[1],
              [`ESSASQSSScore${this.schoolYears[2]}`]: e.sqssScore[2]
            },
            ...{
              [`GraduationRate4Year${this.schoolYears[0]}`]: e.gradRate4YrPct[0],
              [`GraduationRate4Year${this.schoolYears[1]}`]: e.gradRate4YrPct[1],
              [`GraduationRate4Year${this.schoolYears[2]}`]: e.gradRate4YrPct[2]
            },
            ...{
              [`GraduationRate5Year${this.schoolYears[0]}`]: e.gradRate5YrPct[0],
              [`GraduationRate5Year${this.schoolYears[1]}`]: e.gradRate5YrPct[1],
              [`GraduationRate5Year${this.schoolYears[2]}`]: e.gradRate5YrPct[2]
            }
          }
        })
      }
    }
  },
  computed: {
    tableData() {
      if (this.tableFilters) {
        return this.$store.getters['academicsModule/essa/filteredData'](this.filters)
      }
      return []
    },
    schoolYears() {
      var schoolYears = new Array(this.displayYears)
      var idx = 0
      for (var i = this.fy - (this.displayYears - 1); i <= this.fy; i++) {
        schoolYears[idx++] = i + 1990
      }
      return schoolYears
    },
    currentSchoolYear() {
      return this.fy + 1990
    },
    tableFilters() {
      return {
        gradeSpan: this.overviewFilters?.gradeSpan,
        letterGrade: this.overviewFilters?.letterGrade,
        name: this.overviewFilters?.name
      }
    },
    ...mapGetters('academicsModule/essa', ['overviewFilters'])
  },
  mounted() {
    this.filters = this.tableFilters
  },
  watch: {
    filters: {
      handler(newFilters) {
        this.$emit('filter-updated', newFilters)
      },
      deep: true
    }
  }
}
</script>

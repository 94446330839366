<template>
  <div>
    <ul class="nav nav-pills flex-column ml-2 mr-2">
      <li class="nav-item ml-2">
        <router-link class="nav-link" active-class="active" :to="{ path: '/administration/dpsaft/areas' }">
          Areas
        </router-link>
      </li>
      <li class="nav-item ml-2" v-if="!userContext.isADEViewer">
        <router-link class="nav-link" active-class="active" :to="{ path: '/administration/dpsaft/facilities' }">
          Facilities
        </router-link>
      </li>
      <li class="nav-item ml-2">
        <router-link class="nav-link" active-class="active" :to="{ path: '/administration/dpsaft/transportation' }">
          Transportation
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'sidebar',
  computed: {
    ...mapState('globalModule', ['userContext']),
  }
}
</script>
<style  scoped>
  ::v-deep .nav-link.active .text-secondary {
    color: #ffffff !important
  }
</style>
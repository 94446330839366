<template>
  <div>
    <div class="card-body p-0 bg-light">
      <div class="row no-flex-print">
        <div class="col-12 print-full-width">
          <enrolled-grades-chart :load-data="loadData" :selected-lea="selectedLea" />
        </div>
      </div>
      <div class="row mt-4 no-flex-print page-break-before">
        <div class="col-6 print-full-width">
          <enrolled-race-chart :load-data="loadData" :selected-lea="selectedLea" />
        </div>
        <div class="col-6 print-full-width">
          <enrolled-subpopulation-chart :load-data="loadData" :selected-lea="selectedLea" />
        </div>
      </div>
      <div class="row mt-4 no-flex-print page-break-before">
        <div class="col-6 print-full-width">
          <enrolled-meal-status-chart :load-data="loadData" :selected-lea="selectedLea" />
        </div>
        <div class="col-6 print-full-width">
          <enrolled-gender-chart :load-data="loadData" :selected-lea="selectedLea" /> 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EnrolledMealStatusChart from './EnrolledMealStatusChart.vue'
import EnrolledGenderChart from './EnrolledGenderChart.vue'
import EnrolledRaceChart from './EnrolledRaceChart.vue'
import EnrolledGradesChart from './EnrolledGradesChart.vue'
import EnrolledSubpopulationChart from './EnrolledSubpopulationChart.vue'

import { Types } from '../../../stores/onDemandModule'

export default {
  data() {
    return {
      loadData: null
    }
  },
  props: {
  },
  computed: {
  },
  methods:{
  },
  components: {
    EnrolledGradesChart,
    EnrolledGenderChart,
    EnrolledMealStatusChart,
    EnrolledRaceChart,
    EnrolledSubpopulationChart
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if (newLea && newLea.number) {
          const payload = { lea: newLea.number }
          this.loadData = this.$store.dispatch(Types.actions.getEnrollmentCounts, payload)
        }
      }
    }
  }
}
</script>

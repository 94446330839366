<template>
  <loadable-chart-card :load-data="loadData" :show-tooltip="showCardTooltip" :tooltip-content="cardTooltipContent" :tooltip-id="cardTooltipId">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '@/components/ChartMixin'
import { credits } from '@/helpers/credits'
import Highcharts from 'highcharts'

export default {
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'pie'
        },
        plotOptions: {
           pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                formatter: function() {
                  return `<b>${this.point.name}</b>:<br>${Highcharts.numberFormat(this.point.percentage)} %<br>Total: ${Highcharts.numberFormat(this.point.y, 0)}`
                }
              },
              innerSize: '50%'
          }
        },
        credits: credits.MSI,
        title: {
          text: this.chartTitle
        },
        lang: {
          thousandsSep: ','
        },
        series: [{
          name: 'Student Count by Instructional Option',
          colorByPoint: true,
        }],
        xAxis: {
          title: {
            text: 'School Year'
          },
          type: 'category'
        },
        tooltip:{
          enabled: true,
          formatter: this.tooltipFormatter,
          valueDecimals: 2,
        },
        yAxis: {
          title: {
            text: this.yAxisLabel
          },
          endOnTick: false,
          labels: {
            formatter: this.yAxisFormatter
          }
        },
      }
    }
  },
  mixins: [ChartMixin],
  watch: {
    instructionalOptions(newData) {
      // latest fy data
        const maxFy = Math.max(...newData.map(m => m.fiscalYear))
        debugger
        const index = newData.findIndex(le => le.fiscalYear == maxFy)      
        this.chartOptions.series[0].data = this.fields.map(field => {
        return {
          name: field.name,
          y: (newData[index] || {})[field.field]
        }
      })
    }
  },
  props: {
    instructionalOptions: Array,
    fields: Array,
    chartTitle: String,
    yAxisLabel: {
      type: String,
      default: 'Number of Students'
    },
    valueMultiplier: {
      type: Number,
      default: 1
    },
    stacking: {
      type: Boolean,
      default: false
    },
    tooltipSuffix:{
      type: String,
      default: ''
    },
    tooltipFormatter:{
      type: Function,
      default: function() {
        return `${this.series.name}<br/><span style="color:${this.series.color}">${this.point.name}</span>: <b>${Highcharts.numberFormat(this.point.y, 0)}</b>`
      }
    },
    showCardTooltip:{
      type: Boolean,
      default: false
    },
    cardTooltipContent: String,
    cardTooltipId:{
      type: String,
      defualt: 'tooltip1'
    }
  },
  methods: {
    excelExportData() {
      return this.defaultExcelExportData()
    },
    dataItems() {
      return this.chartOptions.series[0].data.map(item => {
        return { Type: item.name, Count: item.y }
      })
    },
    yAxisFormatter(value) {
      if (value.value > 1000) {
        return value.value.toString().substring(0,value.value.toString().length-3) + "k"
      }
      return value.value + this.tooltipSuffix
    }
  }
}
</script>

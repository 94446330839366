export function getStatsByKey(o)
{
  return Object.keys(o).map(rowKey => {
          const rowDetails = o[rowKey]
          const n = rowDetails.length
          const mean = rowDetails.reduce((acc, detail) => acc + detail.valueAddedScore, 0) / n
          return {
            key: rowKey,
            n,
            mean,
            stdDev: Math.sqrt(rowDetails.reduce((acc, detail) => acc + Math.pow(detail.valueAddedScore - mean, 2), 0) / n),
            min: Math.min(...rowDetails.map(detail => detail.valueAddedScore)),
            max: Math.max(...rowDetails.map(detail => detail.valueAddedScore))
          }
      })
}

// a javascript function that calculates an array of histogram bins showing percentage for each bin for an array of data
export function histogram(data, step) {
  const min = Math.floor(Math.min(...data))
  const max = Math.floor(Math.max(...data))
  const bins = []
  for (let i = min; i <= max; i += step) {
    bins.push({
      count: data.filter(d => Math.floor(d) >= i && Math.floor(d) < i + step).length,
      x: Math.floor((i + i + step) / 2.0),
      y: 0
    })
  }
  const total = bins.reduce((acc, bin) => acc + bin.count, 0)
  bins.forEach(bin => bin.y = (bin.count / total) * 100.0)
  return bins
}

const SUBJECT_CASES = {
  "MATH": "Math",
  "SCI": "Science",
  "ELA": "ELA"
}

export function subjectCase(subject) {
  if (!subject)
    return 'All'
  return SUBJECT_CASES[subject] || subject
}
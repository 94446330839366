<template>
  <div>
    <student-information-toolbar v-if="!currentStudent.isReadOnly" :can-save="validation.$dirty" :save-method="save.bind(null, 'AcademicOutcomesResponse')" @cancel="cancel" />
    <b-row>
      <b-col cols="2">
        <b-table striped small caption="Cumulative GPA" :caption-top="true" :items="gpa" :fields="gpaFields">
          <template #cell(cumulativeGPA)="data">
            <span v-if="!gpaWarning(data.item)">
              {{ data.value }}
            </span>
            <b-badge v-else variant="warning">
              {{ data.value }}
            </b-badge>
          </template>
        </b-table>
      </b-col>
      <b-col cols="10">
        <b-table striped small show-empty :caption-top="true" :items="outcomes.ACTAspireAssessments" :fields="actAsireAssessmentFields" empty-text="No ACT Aspire assessments found for selected student">
          <template #table-caption>
            <div class="text-center">
              ACT Aspire
            </div>
          </template>          
          <template #empty="scope">
            <div class="text-center">
              {{ scope.emptyText }}
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table striped small show-empty :caption-top="true" :items="actAssessmentsWithSuperscore" :fields="actAssessmentFields" empty-text="No ACT assessments found for selected student">
          <template #table-caption>
            <div class="text-center">
              ACT
            </div>
          </template>
          <template #empty="scope">
            <div class="text-center">
              {{ scope.emptyText }}
            </div>
          </template>
          <template v-for="subject in ['math','science','english','reading','composite']" v-slot:[`cell(${subject})`]="data">
            <b-badge v-if="Number(data.value) < 19" :key="subject" variant="warning">
              {{ data.value }}
            </b-badge>
            <b-badge v-else-if="Number(data.value) == getHighestActScore(subject)" :key="subject" variant="primary">
              {{ data.value }}
            </b-badge>
            <span v-else :key="subject">
              {{ data.value }}
            </span>
          </template>
          <template #cell(assessedGradeLevelTypeId)="data">
            <span :class="{ 'font-weight-bold': data.value == 'Superscore' }">
              {{ data.value }}
            </span>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-table striped small caption="Other Assessments" :caption-top="true" :items="local.otherAssessments" :fields="otherAssessmentFields">
      <template #cell(assessmentName)="data">
        <b-form-input :readonly="currentStudent.isReadOnly" v-model="data.item.assessmentName" />
      </template>
      <template #cell(math)="data">
        <b-form-input :readonly="currentStudent.isReadOnly" type="number" v-model="data.item.math" />
      </template>      
      <template #cell(science)="data">
        <b-form-input :readonly="currentStudent.isReadOnly" type="number" v-model="data.item.science" />
      </template>  
      <template #cell(english)="data">
        <b-form-input :readonly="currentStudent.isReadOnly" type="number" v-model="data.item.english" />
      </template>           
      <template #cell(reading)="data">
        <b-form-input :readonly="currentStudent.isReadOnly" type="number" v-model="data.item.reading" />
      </template>      
      <template #cell(writing)="data">
        <b-form-input :readonly="currentStudent.isReadOnly" type="number" v-model="data.item.writing" />
      </template>   
      <template #cell(composite)="data">
        <b-form-input :readonly="currentStudent.isReadOnly" type="number" v-model="data.item.composite" />
      </template>                     
    </b-table>
    <h2 class="caption">
      Notes
    </h2>
    <b-form-textarea :readonly="currentStudent.isReadOnly" v-model="local.notes" />
  </div>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import { Types } from '@/modules/cte/stores/careercoach'
import { metadata as staticMetadata } from '@/modules/cte/stores/careercoach/metadata'
import { StudentComponentMixin } from './StudentComponentMixin'
import { gradeMap } from '@/helpers/gradeSpans'

export default {
  computed: {
    ...mapState(Types.path, [Types.state.currentStudent, Types.state.gpa]),
    outcomes() {
      return this.currentStudent.academicOutcomes
    },
    actAssessmentsWithSuperscore() {
      const assessments = [...(this.outcomes.ACTAssessments || [])]
      if (assessments.length > 1) {
        return [...assessments, {
          assessedGradeLevelTypeId: 'Superscore',
          math: this.getHighestActScore('math'),
          science: this.getHighestActScore('science'),
          english: this.getHighestActScore('english'),
          reading: this.getHighestActScore('reading'),
          composite: this.getHighestActScore('composite')
        }]
      }
      return assessments
    }
  },
  methods: {
    gpaWarning(item) {
      return (item === this.gpa[this.gpa.length-1] && item.cumulativeGPA < 2)
    },
    getHighestActScore(subject) {
      const scores = [...(this.outcomes.ACTAssessments || [])].map(a => Number(a[subject]))
      return Math.max(...scores)
    }
  },
  mixins: [StudentComponentMixin],
  data() {
    return {
      gradeMap,
      staticMetadata,
      gpaFields: [
        'grade', 
        {key: 'cumulativeGPA', class: 'text-right', formatter:(v) => Number(v || 0).toFixed(2) } 
      ],
      otherAssessmentFields: [
        { key: 'grade', formatter: (d) => { return gradeMap.get(d) }, class: 'text-center', thStyle: 'width: 150px;' },
        { key: 'assessmentName' },
        { key: 'math', class: 'text-center' },
        { key: 'science', class: 'text-center' },
        { key: 'english', class: 'text-center' },
        { key: 'reading', class: 'text-center' },
        { key: 'composite', class: 'text-center' }
      ],
      actAsireAssessmentFields: [
        { key: 'assessedGradeLevelTypeId', label: 'Grade', formatter: (d) => { return this.gradeMap.get(d) }, class: 'text-left'  },
        { key: 'math', label: 'Math', class: 'text-center' },
        { key: 'science', label: 'Science', class: 'text-center' },
        { key: 'english', label: 'English', class: 'text-center' },
        { key: 'reading', label: 'Reading', class: 'text-center' },
        { key: 'composite', label: 'Composite', class: 'text-center' }
      ],
      actAssessmentFields: [
        { key: 'assessedGradeLevelTypeId', label: 'Grade', formatter: (d) => { return d == 'Superscore' ? 'Superscore' : this.gradeMap.get(d) }, class: 'text-left'  },
        { key: 'assessmentDate', label: 'Assessment Date', formatter: d => (d ? moment(d).format('MMM YYYY') : '') },
        { key: 'math', label: 'Math', class: 'text-center' },
        { key: 'science', label: 'Science', class: 'text-center' },
        { key: 'english', label: 'English', class: 'text-center' },
        { key: 'reading', label: 'Reading', class: 'text-center' },
        { key: 'composite', label: 'Composite', class: 'text-center' }
      ],
      GradeMap: gradeMap
    }
  }
}
</script>
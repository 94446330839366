import PerkinsVPage from './perkinsV/PerkinsVPage.vue'

import PerkinsVUserAdmin from './perkinsV/admin/users/PerkinsVUserPage.vue'
import PerkinsVUserEditForm from './perkinsV/admin/users/PerkinsVUserEditForm.vue'

import PerkinsVAcademicYears from './perkinsV/admin/AcademicYearsList.vue'
import PerkinsVAcademicYear from './perkinsV/admin/AcademicYearAdmin.vue'
import AcademicYearEnable from './perkinsV/admin/AcademicYearEnable.vue'

import PerkinsVSidebar from './perkinsV/PerkinsVSidebar.vue'
import PerkinsInbox from './perkinsV/InboxPage.vue'
import ApplicationList from './perkinsV/application/ApplicationList.vue'
import Application from './perkinsV/application/Application.vue'
import CoverPage from './perkinsV/application/CoverPage.vue'

import ProposalDetails from './perkinsV/application/proposal/ProposalDetails.vue'
import CostItem from './perkinsV/application/proposal/costItem/CostItemEdit.vue'
import CreateProposalPage from './perkinsV/application/proposal/CreateProposalPage.vue'

import DistrictConsortiaPage from './perkinsV/admin/consortia/DistrictConsortiaPage.vue'

import CostTypesPage from './perkinsV/admin/costTypes/CostTypesPage.vue'
import CostTypesWidget from './perkinsV/admin/costTypes/CostTypesWidget.vue'
import CostTypeEdit from './perkinsV/admin/costTypes/CostTypeEdit.vue'
import FundingLimitsPage from './perkinsV/admin/fundingLimits/FundingLimitsPage.vue'
import IndicatorPerformancePage from './perkinsV/admin/indicatorPerformance/IndicatorPerformancePage.vue'

import IndicatorsPage from './perkinsV/admin/indicators/IndicatorsPage.vue'
import IndicatorsTable from './perkinsV/admin/indicators/IndicatorsTable.vue'
import IndicatorEdit from './perkinsV/admin/indicators/IndicatorEdit.vue'

import ClustersManagementPage from './perkinsV/admin/clusters/ClustersManagementPage.vue'

import CareerCoachPage from './careerCoach/CareerCoachPage.vue'
import CareerCoachSidebar from './careerCoach/CareerCoachSidebar.vue'

import CareerCoachTeacherPage from './careerCoach/CareerCoachTeacherPage.vue'
import CareerCoachTeacherSidebar from './careerCoach/CareerCoachTeacherSidebar.vue'

import { routes as cteRoutes } from './careerCoach/routes'
import { router } from '@/routes'

const CTEArea = {
  PerkinsVPage,
  PerkinsVSidebar,
  CareerCoachPage,
  CareerCoachSidebar,
  CareerCoachTeacherPage,
  CareerCoachTeacherSidebar,
}

router.addRoutes(cteRoutes)

router.addRoutes([{
  name: 'CTE/PerkinsV/Admin/User',
  path: '/cte/perkins-v/admin/users',
  component: PerkinsVUserAdmin,
  meta: { label: 'User Administration', icon: 'fa-users' }
},
{
  name: 'CTE/PerkinsV/Admin/User/Edit',
  path: '/cte/perkins-v/admin/users/edit',
  component: PerkinsVUserEditForm
},
{
  name: 'CTE/PerkinsV/Admin/AcademicYears',
  path: '/cte/perkins-v/admin/academic-years',
  component: PerkinsVAcademicYears,
  meta: { label: 'Academic Year Administration', icon: 'fa-calendar' }
},
{
  name: 'CTE/PerkinsV/Admin/AcademicYear',
  path: '/cte/perkins-v/admin/academic-year/:ay(\\d+)',
  meta: { label: 'Academic Year Administration', icon: 'fa-calendar' },
  component: PerkinsVAcademicYear,
},
{
  name: 'CTE/PerkinsV/Admin/AcademicYear/Enable',
  path: '/cte/perkins-v/admin/academic-year/:ay(\\d+)/enable',
  meta: { label: 'Academic Year Administration - Enable Year', icon: 'fa-calendar' },
  component: AcademicYearEnable,
},
{
  name: 'CTE/PerkinsV/Admin/AcademicYear/Clusters',
  path: '/cte/perkins-v/admin/academic-year/:ay(\\d+)/clusters',
  meta: { label: 'Academic Year Administration - Clusters', icon: 'fa-calendar' },
  component: ClustersManagementPage,
},
{ 
  name: 'Admin/CostTypes', 
  path: '/cte/perkins-v/admin/academic-year/:ay(\\d+)/cost-types', 
  component: CostTypesPage,
  children: [
    {
      path: 'list',
      name: 'CostTypes/List', 
      meta: { label: 'Academic Year Administration - Cost Types', icon: 'fa-calendar' },
      component: CostTypesWidget
    },
    {
      path: 'create',
      name: 'CostTypes/Create', 
      component: CostTypeEdit,
      meta: { label: 'Academic Year Administration - Cost Types', icon: 'fa-calendar' },
      props: { mode: 'create'}
    },
    {
      name: 'CostTypes/Edit', 
      path: 'edit/:editItemId',
      component: CostTypeEdit, 
      meta: { label: 'Academic Year Administration - Cost Types', icon: 'fa-calendar' },
      props(route) { 
        return  { mode: 'edit', editItemId: route.params.editItemId } 
      }
    }
  ] 
},
{ 
  name: 'CTE/PerkinsV/Admin/AcademicYear/FundingLimits', 
  path: '/cte/perkins-v/admin/academic-year/:ay(\\d+)/funding-limits', 
  meta: { label: 'Academic Year Administration - Funding Limits', icon: 'fa-calendar' },
  component: FundingLimitsPage 
},
{ 
  name: 'CTE/PerkinsV/Admin/AcademicYear/IndicatorPerformance', 
  path: '/cte/perkins-v/admin/academic-year/:ay(\\d+)/indicator-performance', 
  meta: { label: 'Academic Year Administration - Perkins Indicator Performance', icon: 'fa-calendar' },
  component: IndicatorPerformancePage 
},
{ 
  name: 'CTE/PerkinsV/Admin/AcademicYear/Consortia', 
  path: '/cte/perkins-v/admin/academic-year/:ay(\\d+)/consortia', 
  meta: { label: 'Academic Year Administration - Consortia', icon: 'fa-calendar' },
  component: DistrictConsortiaPage 
},
{
  name: 'CTE/PerkinsV',
  path: '/cte/perkins-v',
  redirect: { name: 'CTE/PerkinsV/Inbox'}
},
{
  name: 'CTE/PerkinsV/Inbox',
  path: '/cte/perkins-v/inbox',
  meta: { label: 'Inbox', icon: 'fa-envelope' },
  component: PerkinsInbox
},
{
  name: 'CTE/PerkinsV/ApplicationList',
  path: '/cte/perkins-v/applications',
  meta: { label: 'Applications', icon: 'fa-clipboard-list' },
  component: ApplicationList,
},
{
  name: 'CTE/PerkinsV/Application',
  path: '/cte/perkins-v/application/:applicationId',
  meta: { label() { return `Application ${this.applicationId}` }, icon: 'fa-clipboard' },
  component: Application,
  props: true
},
{
  name: 'CTE/PerkinsV/Application/CoverPage/Edit',
  path: '/cte/perkins-v/application/:applicationId/cover-page',
  meta: { label() { return `Application ${this.applicationId} | Cover Page` }, icon: 'fa-file-alt' },
  component: CoverPage,
  props: {
    mode: 'edit'
  }
},
{
  name: 'CTE/PerkinsV/Application/Proposal/Add',
  path: '/cte/perkins-v/application/:applicationId/proposal/add',
  meta: { label() {return `Application ${this.applicationId} | New Proposal` }, icon: 'fa-plus-square' },
  component: CreateProposalPage,
  props(route) {
    return {
      mode: 'add',
      applicationId: route.params.applicationId
    }
  }
},
{
  name: 'CTE/PerkinsV/Application/Proposal/Edit',
  path: '/cte/perkins-v/application/:applicationId/proposal/:proposalId',
  meta: { label() {return `Application ${this.applicationId} | Proposal ${this.proposalId}` }, icon: 'fa-pen-square' },
  component: ProposalDetails,
  props: {
    mode: 'edit'
  }
},
{
  name: 'CTE/PerkinsV/Application/Proposal/CostItem/Create',
  path: '/cte/perkins-v/application/:applicationId/proposal/:proposalId/cost-item/new/',
  meta: { label() {return `Application ${this.applicationId} | Proposal ${this.proposalId} | New Cost Item` }, icon: 'fa-file-invoice-dollar' },
  component: CostItem,
  props(route) {
    return {
      mode: 'New',
      applicationId: route.params.applicationId, 
      proposalId: route.params.proposalId
    }
  }
},
{
  name: 'CTE/PerkinsV/Application/Proposal/CostItem/Edit',
  path: '/cte/perkins-v/application/:applicationId/proposal/:proposalId/cost-item/:costItemId',
  meta: { label() {return `Application ${this.applicationId} | Proposal ${this.proposalId} | Edit Cost Item` }, icon: 'fa-file-invoice-dollar' },
  component: CostItem,
  props(route) {
    return {
      mode: 'Edit',
      applicationId: route.params.applicationId, 
      proposalId: route.params.proposalId,
      costItemId: route.params.costItemId
    }
  }
},
{
  name: 'CTE/PerkinsV/Application/Proposal/CostItem/View',
  path: '/cte/perkins-v/application/:applicationId/proposal/:proposalId/cost-item/:costItemId/view',
  meta: { label() {return `Application ${this.applicationId} | Proposal ${this.proposalId} | View Cost Item` }, icon: 'fa-file-invoice-dollar' },
  component: CostItem,
  props(route) {
    return {
      mode: 'View',
      applicationId: route.params.applicationId, 
      proposalId: route.params.proposalId,
      costItemId: route.params.costItemId
    }
  }
},
{ 
  name: 'Admin/Indicators', 
  path: '/cte/perkins-v/admin/academic-year/:ay(\\d+)/indicators', 
  component: IndicatorsPage,
  children: [
    {
      meta: { label: 'Academic Year Administration - Perkins Indicators', icon: 'fa-calendar' },
      name: 'Admin/Indicators/List', 
      path: 'list',
      component: IndicatorsTable
    },
    {
      name: 'Admin/Indicators/Edit', 
      path: ':indicatorId/edit',
      component: IndicatorEdit, 
      meta: { label: 'Academic Year Administration - Perkins Indicators', icon: 'fa-calendar' },
      props(route) { 
        return  { mode: 'edit', indicatorId: route.params.indicatorId } 
      }
    },
    { 
      meta: { label: 'Academic Year Administration - Perkins Indicators', icon: 'fa-calendar' },
      name: 'Admin/Indicators/Create', 
      path: 'create',
      component: IndicatorEdit,
      props: { mode: 'create'}
    }
  ] 
}

])

export { CTEArea }

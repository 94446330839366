<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" :first-column-sticky="true" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { credits } from '../../../helpers/credits'
import { Subpopulations } from '../../subpopulations'
import { EnrollmentChartMixin, defaultOptionMatcher } from './EnrollmentChartMixin'
import sumBy from 'lodash.sumby'

export default {
  data() {
    return {
     animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'column',
          plotBackgroundColor: '#f8f9fa',
          plotShadow: false,
        },
        title: {
          text: 'Enrollment By Race'
        },          
        legend: {
          enabled: true
        },
        credits: credits.InsightsNightlyDataPulls,
        plotOptions: {
          column: {
            minPointLength: 3
          },
          series: {
            stacking: 'normal'
          }
        },
        xAxis: {
          labels: {
            enabled: false
          }
        }
      }
    }
  },
  mixins: [ChartMixin, EnrollmentChartMixin],
  watch: {
    enrollmentCounts: {
      immediate: true,
      handler(newData) {
        if (newData && newData.byRace && this.mode == 'current') {
          this.chartOptions.series = []
          this.chartOptions.xAxis.categories = Subpopulations.map(m => m.name)
          Subpopulations.forEach(option => {
            this.chartOptions.series.push({
              name: option.name,
              data: [sumBy(newData.byRace, defaultOptionMatcher.bind(option))],
              color: option.color
            })
          })
        }
      }
    },
    metricSnapshots: {
      immediate: true,
      handler(newData) {
        if (newData && newData.byRace && this.mode == 'trend') {
          this.chartOptions.chart = { type: 'line' }
          this.chartOptions.plotOptions.series = {}
          this.setTrendedSeries(newData.byRace, Subpopulations)
        }
      }
    }
  },
  methods: {
    dataItems() {
      if (this.mode == 'current') 
        return this.pivotCurrent(this.enrollmentCounts.byRace, Subpopulations)
      else
        return this.pivotTrended(this.metricSnapshots.byRace, Subpopulations)
    }
  }
}
</script>
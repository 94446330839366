
import Vue from 'vue'
import { Types, StateMap } from '@/modules/esa/stores/'
import { Roles } from '@/modules/esa/shared/enums'
import { mapState } from 'vuex'

const PlanMixin = Vue.extend({
  data() {
    return {
      StateMap,
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState(Types.path, [Types.state.plan, 
      Types.state.availableStates, 
      Types.state.plans, 
      Types.state.lockedStatus, 
      Types.state.dataLockedStatus, 
      Types.state.districtBudgets, 
      Types.state.planExpenses,
      Types.state.planCategories,
      Types.state.planResponses]),
    canWithdraw() {
      return true
    },
    transitionAction() {
      return Types.actions.transitionToState
    },
    anyStatesRequireComment() {
      return this.allStateOptions.some(s => s.requiresComment)
    },
    allStateOptions() {
      return this.returnStateOptions.concat(this.advanceStateOptions)
    },
    returnStateOptions() {
      return (this.availableStates.returnStates || []).map(code => StateMap.get(code))
    },
    advanceStateOptions() {
      return (this.availableStates.advanceStates || []).map(code => StateMap.get(code))
    },
    canComment() {
      return this.plan.status >= 10 ? (this.isReviewer || this.isApprover) : 
        (this.isDistrictUser && this.userContext.DelegatedModules.includes('afba8757-aa0f-4610-a64e-c81e7188aef4'))
    },
    isReviewer() {
      return this.userContext.roles.includes(Roles.Reviewer) || this.isApprover || this.isSystemAdministrator
    },
    isApprover() {
      return this.userContext.roles.includes(Roles.Admin) || this.isSystemAdministrator
    },
    isSystemAdministrator() {
      return this.userContext.roles.includes(Roles.SystemAdministrator)
    },
    isAssignedToCurrentUser() {
      return this.currentAssignedUserId && this.currentAssignedUserId.includes(this.userId.replace(/APSCN\\/gi, '').toLowerCase())
    },
    isDistrictUser() {
      return (this.userContext.districtLEA || '').length == 7
    },
    userId() {
      return ((this.userContext || {}).userId || '')
    },
    currentAssignedUserId() {
      if (this.plan && (this.plan.changeLogs || []).some(l => l.assignedToUserId && l.assignedToUserId.length > 0)) {
        let changeLogs = [...this.plan.changeLogs].reverse()
        if ((changeLogs[0] || {}).assignedToName) {
          return changeLogs.find(l => l.assignedToUserId && l.assignedToUserId.length > 0).assignedToUserId
        }
      }
      return null
    },
    currentAssignedUserFullName() {
      if (this.plan && (this.plan.changeLogs || []).some(l => l.assignedToName && l.assignedToName.length > 0)) {
        let changeLogs = [...this.plan.changeLogs].reverse()
        return changeLogs.find(l => l.assignedToName && l.assignedToName.length > 0).assignedToName
      }
      return null
    },
    canEdit() {
      if (this.$route.path.toLowerCase().includes('/public')) 
        return false
      if (this.dataLockedStatus)
        return false

      return (this.plan.status < 10 && (this.advanceStateOptions.length > 0 || this.isAssignedToCurrentUser || this.isSuperIntendent)) || this.isApprover
    },
    isSuperIntendent() {
      return this.userContext.isSuperintendent
    },
    transferCategory() {
      return this.planCategories.find(p => p.name.includes('Transfer'))
    },
    hasTransferExpenses() {
      const transfer = this.transferCategory
      if (transfer)
        return this.planExpenses.some(e => transfer.programCode.includes(e.program) && e.account == '69320')
      return false
    },
    requiresTransferJustification() {
      const transfer = this.transferCategory
      const planResponse = this.planResponses.find(r => r.categoryId == transfer.id)
      return this.advanceStateOptions.length > 0 && this.hasTransferExpenses && (!planResponse || !planResponse.response || !JSON.parse(planResponse.response).other)
    }
  },
})

export { PlanMixin }
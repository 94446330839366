<template>
  <div>
    <ul class="nav nav-pills flex-column ml-2 mr-2">
      <li class="nav-item ml-2" v-if="mode !== 'admin'">
        <router-link class="nav-link" active-class="active" :to="{path: `/Core/SCT/guidance`}">
          <img src="/Content/icons/guidance.png" class="svg-icon"> Guidance
        </router-link>
      </li>
      <li class="nav-item ml-2">
        <router-link class="nav-link" active-class="active" :to="{path: `/Core/SCT/Admin/list`}" v-if="mode ==='admin'">
          <img src="/Content/icons/closure.png" class="svg-icon"> Closure List
        </router-link>
        <router-link class="nav-link" active-class="active" :to="{path: `/Core/SCT/${cookieLEA}/list`}" v-else>
          <img src="/Content/icons/closure.png" class="svg-icon"> Closure List
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'district-closures-sidebar',
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapGetters('globalModule', ['leaContext']),
    cookieLEA(){
      if(this.userContext.isAuthenticated && !this.userContext.hasRole("APSCN User")) {
        return this.leaContext.SelectedLea.number
      }

      return this.userContext.districtLEA
    }
  },
  props: {
    mode: {
      type: String,
      default: 'unset'
    }
  }
}
</script>

<template>
  <div class="district-closure-container">
    <h1>School Communication Tool {{ mode ==='admin' ? '(Admin)' : '' }}</h1>
    <b-alert variant="danger" :show="cookieLEA && leaContext.SelectedLea.number != cookieLEA">
      Selected LEA does not match currently logged in user LEA. Please use the LEA List to select the appropriate LEA for the logged in user.
    </b-alert>
    <div v-if="($route.name || '').includes('list') && !(cookieLEA && leaContext.SelectedLea.number != cookieLEA)">
      <pdf-button v-if="this.userContext.districtLEA == 'AR'" :additional-query-string-params="`&startDate=${$route.query.startDate}&endDate=${$route.query.endDate}`" />
      <excel-button title="School Communication Tool" />
      <br>
    </div>
    <h2><lea-display :lea="userLea" v-if="this.$router.currentRoute.name == 'list'" /></h2>    
    <router-view v-if="shouldDisplay" />
  </div>
</template>

<script>
import { PageMixin } from '../../PageMixin'
import { router } from '../../../routes'
import { mapState, mapGetters } from 'vuex'
import ListClosuresPage from './ListClosuresPage'
import EditClosurePage from './EditClosurePage'
import Guidance from './Guidance'

router.addRoutes([
  { path: '/Core/SCT/Admin', redirect: {name: 'sct-admin-closure-list'}},
  { path: '/Core/SCT/Admin/list', component: ListClosuresPage, name: 'sct-admin-closure-list', props: {mode: 'admin'}},
  { path: '/Core/SCT', redirect: { name: 'sct-guidance' }},
  { path: '/Core/SCT/guidance', component: Guidance, name: 'sct-guidance' },
  { path: '/Core/SCT/:districtLea/list', component: ListClosuresPage, name: 'sct-closure-list', props: true},
  { path: '/Core/SCT/:districtLea/new', component: EditClosurePage, name: 'sct-new-closure', props: true },
  { path: '/Core/SCT/:districtLea/:id/edit', component: EditClosurePage, name: 'sct-edit-closure', props: true },
])

export default {
  data() {
    return {
      displayLea: this.selectedLea
    }
  },
  props: {
    mode: {
      type: String,
      default: 'unset'
    }
  },
  mixins: [PageMixin],
  computed: {
    ...mapState('globalModule', ['userContext', 'leaDictionary', 'leaContext']),
    ...mapGetters('globalModule', ['leaDictionary']),
    userLea() {
      return this.leaDictionary[this.userContext.districtLEA]
    },
    shouldDisplay() {
      return (this.cookieLEA && this.selectedLea.number == this.cookieLEA) || (this.userContext.isAuthenticated && !(this.userContext.hasRole('APSCN User') || this.cookieLEA))
    },
    cookieLEA() {
      if(this.userContext.isAuthenticated && !this.userContext.hasRole("APSCN User")) {
        return this.leaContext.SelectedLea.number
      }

      return this.userContext.districtLEA
    }
  }
}
</script>

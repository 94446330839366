<template>    
  <div class="col-sm-8">
    <h2><lea-display :lea="requestLea" /></h2>
    <dl class="row" v-if="requestLea.adminLastName">
      <dt class="col-sm-3">
        Superintendent
      </dt>
      <dd class="col-sm-9">
        {{ requestLea.adminFirstName }} {{ requestLea.adminLastName }} (<a :href="'mailto:' + requestLea.adminEmailAddress">{{ requestLea.adminEmailAddress }}</a>)
        <span class="fas fa-phone" /> {{ requestLea.adminPhoneNumber }}
      </dd>
    </dl>
  </div>
</template>

<script>

import LeaDisplay from '../../LeaDisplay'

export default {
  props:{
    requestLea: Object
  },
  components:{
    LeaDisplay
  }
}
</script>
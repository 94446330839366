<template>
  <div>
    <div class="card">
      <div class="card-header">
        {{ fy + 1989 }}/{{ fy-10 }} LEA Management System School Listing
      </div>
      <div class="card-body">
        <p>The school information below is from the LEA Management System (LEAMS)</p>

        <p>
          District Users: To request changes for building(s), you must
          <router-link :to="{name: 'new-request'}">
            Create a New Request
          </router-link>on the Requests screen and add the building(s) needing changes to the request.
          You may request to add a new LEA, to change the name or grade span of an existing LEA, or to inactivate an LEA entirely.
        </p>
      </div>

      <div class="card-body">
        <!-- <b-select :options="leamsFiscalYears" v-model="fy" /> -->
        <table-control-widget
          :per-page="tableOptions.perPage"
          :filter="tableOptions.filter"
          @page-length-changed="tableOptions.perPage = $event"
          @filter-changed="tableOptions.filter = $event"
        />

        <b-table
          small
          :items="schools"
          :fields="tableFields"
          :per-page="tableOptions.perPage"
          :current-page="tableOptions.currentPage"
          :filter="tableOptions.filter"
          :tbody-tr-class="assignRowClass"
        >
          <template v-slot:cell(principal)="data">
            <div v-if="data.value">
              Name: {{ data.value.fullName }}
              <br>Email:
              <a :href="'mailto: ' + data.value.emailAddress">{{ data.value.emailAddress }}</a>
              <br>
              Phone: {{ data.value.phone }}
              <br>
              Fax: {{ data.value.fax }}
            </div>
            <div v-else>
              (Pending LEA's addition to SIS LEA Profile)
            </div>
          </template>
          <template
            v-slot:cell(gradeSpan)="data"
          >
            {{ data.item.gradeLow }} - {{ data.item.gradeHigh }}
          </template>

          <template v-slot:cell(leaTypeCode)="data">
            <span class="badge badge-secondary">{{ getLeaType(data.value) }}</span>
          </template>

          <template v-slot:cell(requests)="data">
            <div>
              <ul class="request-href m-0 p-0" v-if="hasRequests(data.item)">
                <li v-for="r in data.item.inactivates" :key="r" class="inactivate">
                  <router-link :to="{name: 'edit-request', params: {id: r}}">
                    <i class="fas fa-minus-square fw" /> Inactivate LEA Request
                  </router-link>
                </li>
                <li v-for="r in data.item.edits" :key="r" class="edit">
                  <router-link :to="{name: 'edit-request', params: {id: r}}">
                    <i class="fas fa-pen-square fw" /> Edit LEA Request
                  </router-link>
                </li>
                <li v-for="r in data.item.creates" :key="r" class="create">
                  <router-link :to="{name: 'edit-request', params: {id: r}}">
                    <span class="badge badge-primary">
                      <i class="fas fa-plus fw" /> New LEA Request
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </template>

          <template v-slot:cell(address)="data">
            {{ data.item.address }}
            <br>
            {{ data.item.city }}, {{ data.item.state }} {{ data.item.zip }}
          </template>
        </b-table>
        <table-pagination-widget
          :per-page="tableOptions.perPage"
          :filter="tableOptions.filter"
          :current-page="tableOptions.currentPage"
          :page-length="tableOptions.perPage"
          :total-rows-count="schools.length"
          @page-changed="tableOptions.currentPage = $event"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
ul.request-href {
  list-style-type: none;

  a,
  a:hover {
    cursor: pointer !important;
    text-decoration: none !important;
    color: #444444 !important;

    .edited & {
      color: #ffffff !important;
    }
  }
}
</style>
<script>
import { mapState } from 'vuex'
import { LeaCodes } from '../../../helpers/leaCodes'
import TablePaginationWidget from '../../shared/TablePaginationWidget'
import TableControlWidget from '../../shared/TableControlWidget'
export default {
  name: 'school-list',
  data() {
    return {
      fy: null,
      tableOptions: {
        currentPage: 1,
        perPage: 10,
        filter: null
      }
    }
  },
  components: {
    TablePaginationWidget,
    TableControlWidget
  },
  mounted() {
    this.$store.dispatch('toolsModule/leams/getLEAMSFiscalYears').then(() => {
      this.fy = this.leamsSelectedFiscalYear || this.leamsFiscalYears[0]
    })
  },
  computed: {
    ...mapState('toolsModule/leaAssignment', ['schools']),
    ...mapState('toolsModule/leams', ['leamsFiscalYears', 'leamsSelectedFiscalYear', 'fiscalYear']),
    ...mapState('globalModule', ['leaContext', 'userContext']),
    selectedLea() {
      return this.leaContext.SelectedLea
    },
    tableFields() {
      return [
        { key: 'leaNumber', sortable: true, label: "LEA #" },
        { key: 'name', sortable: true },
        { key: 'gradeSpan' },
        { key: 'leaTypeCode', sortable: true, label: 'Type' },
        { key: 'address' },
        'principal',
        { key: 'requests', label: 'Approved Requests', class: 'text-center' }
      ]
    }
  },
  methods: {
    getLeaType(value) {
      return LeaCodes[value] || value
    },
    getSchools() {
      var lea = this.userContext.districtLEA

      if (this.userContext.isADEUser) {
        lea = this.selectedLea.number
      }

      if (this.fy) {
        this.$store.dispatch('toolsModule/leaAssignment/getSchools', {
          lea: lea,
          fy: this.fy
        })
      }
    },
    assignRowClass(item) {
      if (item.creates && item.creates.length > 0) return 'created bg-warning'
      if (item.inactivates && item.inactivates.length > 0) return 'inactivated bg-warning'
      if (item.edits && item.edits.length > 0) return 'edited bg-primary text-white'
      return ''
    },
    hasRequests(item) {
      return (item.inactivates && item.inactivates.length > 0) || (item.edits && item.edits.length > 0) || (item.creates && item.creates.length > 0)
    }
  },
  watch: {
    selectedLea() {
      this.getSchools()
    },
    fy() {
      this.$store.commit('toolsModule/leams/setSelectedLEAMSFiscalYear', this.fy)
      this.getSchools()
    },
    fiscalYear() {
      this.fy = this.fy || this.fiscalYear
    }
  }
}
</script>

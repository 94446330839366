<template>
  <div>
    <div class="card mt-3 bg-light">
      <a class="anchor d-print-none" id="attendance-rates" />
      <div class="card-header">
        Attendence Rates
      </div>
      <div class="card-body">
        <div class="card-group">
          <attendance-rate-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :attendance="attendanceRates" 
            chart-title="Attendance Rate by Race"
            :sub-populations="Races"
            :value-multiplier="100"
            tooltip-suffix="%" />
          <attendance-rate-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :attendance="attendanceRates" 
            chart-title="Attendance Rate by Gender"
            :sub-populations="Genders"
            :value-multiplier="100"
            tooltip-suffix="%" />
        </div>
        <div class="card-group">
          <attendance-rate-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :attendance="attendanceRates" 
            chart-title="Attendance Rate by Subpopulation"
            :sub-populations="Subpopulations"
            :value-multiplier="100"
            tooltip-suffix="%" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AttendanceRateChart from './AttendanceRateChart.vue'
import { EnrollmentSubpops as Genders } from '../enrollment/EnrollmentSubpops'

const Races = [
  { name: 'Black / African American', altName: '', field: 'africanAmerican', color: '#2c97de', value: 4 },
  { name: 'Hispanic / Latino', altName: 'Hispanic', field: 'hispanic', color: '#90ed7d', value: 1 },
  { name: 'White', altName: '', field: 'caucasian', color: '#e87e04', value: 6 },
  { name: 'All Students', altName: '', field: 'allStudents', color: '#e94b35' },
]

const Subpopulations = [
  { name: 'Economically Disadvantaged', altName: '', field: 'economicallyDisadvantaged', color: '#9c56b8' },
  // { name: 'Special Education', altName: '', field: 'sped', color: '#2c97de' },
  { name: 'Migrant', altName: '', field: 'migrant', color: '#90ed7d' },
  { name: 'Limited English Proficiency', altName: '', field: 'limitedEnglishProficient', color: '#e87e04' },
  { name: 'Targeted Achievement Gap Group', altName: '', field: 'tagg', color: '#e94b35' },
  { name: 'Homeless', altName: '', field: 'homeless', color: '#f2c500' },
  { name: 'Gifted and Talented', altName: '', field: 'giftedTalented', color: '#00bd9c' },
  { name: 'Students with Disabilities', altName: '', field: 'studentsWithDisabilities' },
  { name: 'Military', altName: '', field: 'military' },
  { name: 'Foster Care', altName: '', field: 'fosterCare' },
  { name: 'English Learners', altName: '', field: 'englishLearners' },
]

export default {
  data() {
    return {
      loadData: null,
      Genders,
      Races,
      Subpopulations,
    }
  },
  computed: {
    ...mapState('overviewModule', ['attendanceRates'])
  },
  components: {
    AttendanceRateChart,
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        this.loadData = this.$store.dispatch('overviewModule/getAttendanceRates', {
          lea: newLea.number
        })
      }
    }
  }
}
</script>

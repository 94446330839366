<template>
  <div>
    <public-district-closures-filter v-model="filter" />    
    <public-district-closures-list :district-lea="selectedLea.number" :load-data="loadData" />
  </div>
</template>

<script>
import publicDistrictClosuresFilter from './publicDistrictClosuresFilter'
import publicDistrictClosuresList from './publicDistrictClosuresList'
import { PageMixin } from '../../../PageMixin'
import moment from 'moment'

export default {
  data() {
    return {
      filter: { 
        startDate: null,
        endDate: null
      },
      loadData: null
    }
  },
  mixins:[PageMixin],
  mounted() {
    if (this.$route.query.startDate) this.filter.startDate = moment.unix(this.$route.query.startDate).toDate()
      else this.filter.startDate = moment().startOf('week').toDate()
    if (this.$route.query.endDate) this.filter.endDate = moment.unix(this.$route.query.endDate).toDate()
      else this.filter.endDate = moment().endOf('week').toDate()
    this.getData(this.filter)
  },
  methods: {
    getData(filter) {
      this.loadData = this.$store.dispatch('core/sctClosures/getDistrictClosuresPublic', {
        startDate: filter.startDate,
        endDate: filter.endDate
      })
    }
  },
  watch: {
    filter: {
      deep: true,
      handler(newFilter) {
        if (newFilter) {
          this.$router.replace({ name: this.$route.name, query: {
            startDate: moment(newFilter.startDate).unix(),
            endDate: moment(newFilter.endDate).unix()
          }})

          this.getData(newFilter)
        }
      }
    }
  },
  components: {
    publicDistrictClosuresFilter,
    publicDistrictClosuresList
  }
}
</script>

<style>

</style>
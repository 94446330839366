<template>
  <div>
    <page-header :icon="$route.meta.icon" :label="getPageLabel" />
    <router-view />
  </div>
</template>

<script>
import PageHeader from './common/PageHeader'
import { mapState } from 'vuex'

export default {
  components:{
    PageHeader
  },
  mounted() {
    this.$store.dispatch('rvsModule/getTimelineDates')
  },
  computed: {
    ...mapState('globalModule', ['pageLabel']),
    getPageLabel() {
      return this.pageLabel || this.$route.meta.label
    }
  }
}
</script>

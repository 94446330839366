<template>
  <div>
    <div class="card">
      <h5 class="card-header">
        Licenses
      </h5>
      <div class="card-body">
        <ul class="nav nav-tabs" id="licenseTabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="licenses-tab" data-toggle="tab" href="#licenses" roles="tab" aria-controls="licenses" aria-selected="true">
              Licenses
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="notifications-tab" data-toggle="tab" href="#notifications" roles="tab" aria-controls="notifications" aria-selected="false">
              Notifications
            </a>
          </li>
        </ul>
        <div class="tab-content" id="licenseTabContent">
          <div class="tab-pane fade show active" id="licenses" role="tabpanel" aria-labelledby="licenses-tab">
            <expiring-license-chart :load-data="loadData" :selected-lea="selectedLea" />
          </div>
          <div class="tab-pane fade" v-if="!$printMode" id="notifications" role="tabpanel" aria-labelledby="notifications-tab">
            <expiring-license-notification-table :selected-lea="selectedLea" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExpiringLicenseChart from './ExpiringLicenseChart.vue'
import ExpiringLicenseNotificationTable from './ExpiringLicenseNotificationTable.vue'

export default {
  data() {
    return { 
      loadData: null
    }
  },
  props: {
    selectedLea: Object
  },
  computed: {
    expiredLicensesByRange() {
      return this.$store.getters.expiredLicensesByRange
    }
  },
  components: {
    ExpiringLicenseChart,
    ExpiringLicenseNotificationTable
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if (newLea && newLea.number)
          this.loadData = this.$store.dispatch('hcptModule/getExpiringLicenses', { lea: newLea.number })
      }
    }
  }
}
</script>
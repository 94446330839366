<template>
  <div>
    <br> 
    <div class="row" v-if="employeeLeaveDayFilterDate">
      <div class="col-md-5">
        <h4>
          Leave Day Details for: {{ dateString }}
        </h4>
      </div>
      <div class="col-md-2 offset-5 text-right">
        <b-button size="sm" @click="$store.commit('hcptModule/setEmployeeLeaveDayFilterDate', null)">
          Show All Days
        </b-button> 
      </div>
    </div> 
    <chart-data-table :items="dataItems" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import ChartDataTable from '../../ChartDataTable'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState('hcptModule', ['employeeLeaveDayDetails', 'employeeLeaveDayFilterDate']),
    dataItems() {
      var filterMoment = moment(this.employeeLeaveDayFilterDate)
      if (this.employeeLeaveDayFilterDate) {
        return this.employeeLeaveDayDetails.filter(d => {
          var leaveMoment = moment(d.leaveDate)
          return filterMoment.isSame(leaveMoment, 'day')
        }, this)
      }
      return this.employeeLeaveDayDetails
    },
    dateString(){
      return moment(this.employeeLeaveDayFilterDate).format('MM/DD/YYYY')
    }
  },
  components: {
    ChartDataTable
  },
  methods: {}
}
</script>

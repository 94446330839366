<template>
  <div class="row no-gutters">
    <div class="col-md-2">
      <ul class="essa-stats">
        <li class="no-gutters">
          <label class="col-md-5">Grade Range:</label>
          <span class="col-md-4">{{ essaStats.gradeRange }}</span>
        </li>
        <li class="no-gutters">
          <label class="col-md-5">Grade Span:</label>
          <span class="col-md-4">{{ gradeSpan(essaStats.gradeSpan) }}</span>
        </li>
      </ul>
    </div>
    <div class="col-md-4">
      <ul class="essa-stats">
        <li class="no-gutters">
          <label class="col-md-6 text-right">Value-Added Growth Score:</label>
          <span class="col-md-6 text-right pl-3">{{ essaStats.valueAddedGrowth }}</span>
        </li>
        <li class="no-gutters">
          <label class="col-md-6 text-right">Weighted Achievement Score:</label>
          <span class="col-md-6 text-right pl-3">{{ essaStats.weightedAchievement }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { PageMixin } from '../../PageMixin'

export default {
  data() {
    return {}
  },
  components: {},
  mixins: [PageMixin],
  computed: {
    ...mapState('academicsModule/teacherGrowth', [
      'tab',
      'grade',
      'subject',
      'subgroup'
    ]),
    ...mapGetters('academicsModule/teacherGrowth', ['fiscalYear']),
    ...mapState('academicsModule/essa', ['essaData']),
    fiscalYear() {
      return this.$store.state.academicsModule.teacherGrowth.fiscalYear
    },
    essaStats() {
      var stats = {
        gradeRange: 'RANGE',
        gradeSpan: 'SPAN',
        weightedAchievement: 0.1,
        valueAddedGrowth: 0.2
      }

      if (this.essaData && this.essaData.length > 0) {
        stats.gradeRange = this.essaData[0].gradeRange
        stats.gradeSpan = this.essaData[0].gradeSpan
        stats.weightedAchievement = this.essaData[0].wgtPerfScore[2]
        stats.valueAddedGrowth = this.essaData[0].growthScore[2]
      }
      return stats
    }
  },
  methods: {
    gradeSpan(span) {
      if (span === 'HS') return 'High School'
      if (span === 'ELEM') return 'Elementary School'
      if (span === 'MS') return 'Middle School'
      return span
    },
    loadData() {
      if (this.selectedLea && this.selectedLea.number)
          if (this.fiscalYear) {
            this.$store
              .dispatch('academicsModule/essa/getEssaAcademicsInformation', {
                lea: this.selectedLea.number,
                fy: this.fiscalYear,
                filters: null
              })
              .then(() => {
                return this.$store.dispatch(
                  'globalModule/addDebugMessage',
                  'Data loaded in watcher'
                )
              })
          }
    }
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler() {
        this.loadData()
      }
    },
    fiscalYear: {
      immediate: true,
      handler() {
        this.loadData()
      }
    }
  }
}
</script>
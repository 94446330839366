import Vue from 'vue'
import LoadableChartCard from '../../LoadableChartCard.vue'
import { mapGetters } from 'vuex'
import { TableMixin } from '../../TableMixin'

const NbctEmployeeTable = Vue.extend({
    name: 'nbct-employee-table',
    /* html */
    template: `
    <div>
        <table-control-widget v-bind:per-page="perPage" v-on:filter-changed="filter=$event"
            v-on:page-length-changed="perPage=$event"></table-control-widget>

        <div class='row d-print-none'>
            <div class='col-md-12'>
                <b-form-checkbox v-model="showDiscrepanciesOnly" name="check-button" switch>
                    Show only records with discrepancies
                </b-form-checkbox>
            </div>
        </div>
        <div class='row'>
            <div class='col-md-12'>
                <loadable-chart-card card-style='' v-bind:load-data="loadDetailData" v-bind:enableDataToggle="false">
                    <b-table id="nbct-teachers-table" v-bind:items="nbctDetailData" v-bind:fields="fields"
                        v-bind:per-page="perPage" v-bind:current-page="currentPage" v-bind:filter="filter"
                        @filtered="onFiltered" small striped :tbody-tr-class='rowClass'
                        empty-filtered-text='No rows found that satisfy the filter' :show-empty='true'
                        empty-text='No rows found'>

                        <template v-slot:cell(thead-top)="data">
                            <tr>
                                <th></th>
                                <th colspan="4" class='text-center'>SIS</th>
                                <th colspan="4" class='text-center'>AELS</th>
                            </tr>
                        </template>

                        <template v-slot:cell(lea)="data">
                            <div>
                                <div class="d-inline">
                                        <a href="#" v-on:click="goToLea(data.value)"
                                            v-bind:id="data.index + '-' + data.value">{{ data.value }}</a>
                                        &nbsp;
                                        <lea-tooltip v-bind:target-selector="data.index + '-' + data.value"
                                            v-bind:lea-number="data.value">
                                        </lea-tooltip>
                                </div>
                            </div>
                        </template>
                        <template v-slot:cell(siS_IsNbctCertified)="data">
                            <span v-if='data.value' class='fa fa-check text-success'></span>
                            <span v-else='data.value' class='fa fa-times' style="color:#878787"></span>
                            {{ data.value ? "Yes" : "No" }}
                        </template>
                        <template v-slot:cell(siS_NbcIssued)="data">
                            {{ data.value | date("MM/DD/YYYY") }}
                        </template>
                        <template v-slot:cell(siS_NbcExpiration)="data">
                            {{ data.value | date("MM/DD/YYYY") }}
                        </template>
                        <template v-slot:cell(aelS_IsNbctCertified)="data">
                            <span v-if='data.value' class='fa fa-check text-success'></span>
                            <span v-else='data.value' class='fa fa-times' style="color:#878787"></span>
                            {{ data.value ? "Yes" : "No" }}
                        </template>
                        <template v-slot:cell(aelS_NbcIssued)="data">
                            {{ data.value | date("MM/DD/YYYY") }}
                        </template>
                        <template v-slot:cell(aelS_NbcExpiration)="data">
                            {{ data.value | date("MM/DD/YYYY") }}
                        </template>

                    </b-table>
                    <div id='render-complete'></div>
                </loadable-chart-card>
            </div>
        </div>
        <table-pagination-widget :total-rows-count="selectedItemsCount" :filtered-rows-count="selectedItemsCount"
            :page-length="perPage" v-on:page-changed="currentPage=$event"></table-pagination-widget>

        <div class='row'>
            <div class='col-md-12'>
                <div class='card m-3'>
                    <div class='card-body'>
                        <ul style="list-style:none;" class="p-1">
                            <li><span class='bg-danger text-white'>Red highlighted rows</span>: Indicate discrepancy
                                between SIS and AELS NBCT Status for the teacher</li>
                            <li><span class='bg-warning'>Yellow highlighted rows</span>: Indicate discrepancy between
                                SIS and AELS NBCT Issue or Expire date for the NBC teacher</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `,
    data() {
        return {
            perPage: this.$printMode ? Number.MAX_SAFE_INTEGER : 100,
            currentPage: 1,
            filter: null,
            selectedItemsCount: 1,
            showDiscrepanciesOnly: false,
            loadDetailData: null,
            fields: [{
                    key: 'lea',
                    sortable: true,
                    label: "LEA"
                },
                {
                    key: 'siS_FullName',
                    sortable: true,
                    label: "Name (SIS)"
                },
                {
                    key: 'siS_IsNbctCertified',
                    label: "Is NBCT (SIS)",
                    sortable: true
                },
                {
                    key: 'siS_NbcIssued',
                    label: "Issued (SIS)",
                    sortable: true
                },
                {
                    key: 'siS_NbcExpiration',
                    label: "Expiration (SIS)",
                    sortable: true
                },
                {
                    key: 'aelS_FullName',
                    sortable: true,
                    label: "Name (AELS)"
                },
                {
                    key: 'aelS_IsNbctCertified',
                    label: "Is NBCT (AELS)",
                    sortable: true
                },
                {
                    key: 'aelS_NbcIssued',
                    label: "Issued (AELS)",
                    sortable: true
                },
                {
                    key: 'aelS_NbcExpiration',
                    label: "Expiration (AELS)",
                    sortable: true
                }
            ]
        }
    },
    mixins: [TableMixin],
    computed: {
        leaDictionary() {
            return this.$store.state.globalModule.leaContext.LeaDictionary
        },
        ...mapGetters('hcptModule', ['nbctDetailData'])
    },
    methods: {
        // eslint-disable-next-line
        aelsFullNameString(value, key, item) {
            return `${item.aelS_FirstName || ''} ${item.aelS_LastName || ''}`
        },
        // eslint-disable-next-line
        sisFullNameString(value, key, item) {
            return `${item.siS_FirstName} ${item.siS_LastName}`
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.selectedItemsCount = filteredItems.length
            this.currentPage = 1
        },
        goToLea(leaNumber) {
            const lea = this.leaDictionary[leaNumber]
            window.scrollTo(0, 0)
            this.$store.dispatch('globalModule/changeSelectedLea', lea)
        },
        excelExportData() {
            return {
                name: 'NBCT Teachers',
                items: this.$store.state.hcptModule.nbctDetailData
            }
        },
        rowClass(item) {
            if (!item) return;

            if (item.aelS_IsNbctCertified != item.siS_IsNbctCertified)
                return 'bg-danger text-white'
            else {
                if (item.aelS_NbcIssued != item.siS_NbcIssued || item.aelS_NbcExpiration != item.siS_NbcExpiration) {
                    return 'bg-warning';
                }
            }
        },
        refreshData() {

            this.$store.dispatch('globalModule/addDebugMessage', 'Refreshing detail data')
            this.loadDetailData = this.$store.dispatch('hcptModule/getNBCTDetailData', {
                lea: this.selectedLea.number,
                showDiscrepanciesOnly: this.showDiscrepanciesOnly
            }).then(()=>{
                this.$store.dispatch('globalModule/addDebugMessage', 'Detail data refreshed')
            })
        }
    },
    components: {
        LoadableChartCard
    },
    watch: {
        nbctDetailData(teachers) {
            this.selectedItemsCount = teachers.length
        },
        showDiscrepanciesOnly() {
            this.refreshData();
        },
        selectedLea: {
            immediate: true,
            handler() {
                this.refreshData();
            }
        }
    },
    props: {
        selectedLea: Object
    }
})
export { NbctEmployeeTable }
import ApiService from '../services/ApiService'
import { LeaTypes } from '../helpers/leaTypes'
import { router } from '../routes'
import { UserContext } from './UserContext'
class GlobalModule {

  constructor() {
    this._apiService = new ApiService()
    this._router = router
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
    this._fiscalYear = this._configElement.getAttribute('data-fiscal-year')
    this._isPdfExport = this._configElement.getAttribute('data-pdf-export') === 'true'

    this.state.displayLoginButton = this._configElement == null ? true : this._configElement.getAttribute('data-display-login-button') === 'true'
  }

  namespaced = true

  state = {
    appInitialized: false,
    userContext: {
      canContextSwitch: false,
      isContextSwitched: false,
      userId: ''
    },
    leaContext: {
      source: null,
      SelectedLea: {
        name: ''
      },
      Leas: {
        name: ''
      },
      LeaDictionary: {},
      dictionaryReady: false
    },
    CacheTimestamp: null,
    baseUrl: this._baseUrl,
    accessHeaders: {
      AccessibleLeas: [],
      GrantedAccess: 'None'
    },
    pdfExportPageState: null,
    pageViews: {},
    isOnline: true,
    debugMessages: [],
    displayLoginButton: true,
    pageLabel: null
  }

  determineLeaSource() {
    console.log("Getting LEA Source for " + window.location.pathname)
    const isCareerCoach = window.location.pathname.toLowerCase().startsWith('/cte/career-coach');

    if (isCareerCoach) return 'cte/careercoach/getLeas'

    return 'globalModule/getLeas'
  }

  getters = {
    fullName: state => state.userContext.fullName || '',
    emailAddress: state => state.userContext.emailAddress || '',
    employeeId: state => state.userContext.employeeId || '',
    schoolIds: state => state.userContext.schoolIds || [],
    isPdfExport: state => state.response.isPdfExport,
    isAuthenticated: state => state.userContext.isAuthenticated || false,
    isPrincipal: state => state.userContext.isPrincipal || false,
    isSuperintendent: state => state.userContext.isDistrictSuperOrAssistant || false,
    isRVSTeacher: state => state.userContext.isRVSTeacher || false,
    canContextSwitch: state => state.userContext.canContextSwitch || false,
    isContextSwitched: state => state.userContext.isContextSwitched || false,
    selectedLeaName: state => {
      if (state.leaContext && state.leaContext.SelectedLea) {
        return state.leaContext.SelectedLea.name
      } else {
        return 'State of Arkansas'
      }
    },
    leaDictionary: state => state.leaContext.LeaDictionary,
    leaList: state => state.leaContext.Leas,
    leaContext: state => state.leaContext,
    debugMessages: state => state.debugMessages,
    pdfExportPageState: state => state.pdfExportPageState,
    pageViews: state => state.pageViews,
    coops: (_state, getters) => getters.leaList.ownedLeas,
    districts: (_state, getters) => (parentCoop) => {
      var districts = new Array()

      for (const prop in getters.leaDictionary) {
        var lea = getters.leaDictionary[prop]

        if (lea.type == 2 && lea.parentLea.number == (parentCoop || lea.parentLea.number)) districts.push(getters.leaDictionary[prop])
      }

      districts.sort((a,b) => (a.name > b.name) ? 1 : -1)
      return districts
    },
    counties(_state) {
      return Object.keys(
        _state.leaContext.LeaDictionary
      )
        .filter(l => l.length == 2 && l != 'AR')
        .sort()
        .map(l => _state.leaContext.LeaDictionary[l])
    },
    extendedClaims: (state) => {
      return state.userContext.extendedClaims
    }
  }

  mutations = {
    setPageLabel(state, label) {
      state.pageLabel = label
    },
    setLeas(state, leaInfoPayload ) {
      const leas = leaInfoPayload.leas
      state.leaContext.source = leaInfoPayload.source

      const leaMap = function (lea) {
        lea.parentLea = {
          name: this.name,
          number: this.number,
          type: this.type
        }
        if (lea.ownedLeas) {
          lea.ownedLeas = lea.ownedLeas.map(leaMap, lea)
          lea.schoolsServed = (lea.type == LeaTypes.Coop ? lea.ownedLeas.flatMap(l => l.ownedLeas) : lea.ownedLeas).filter(l => l && l.type == LeaTypes.School).length
          lea.districtsServed = (lea.type == LeaTypes.Coop || lea.type == LeaTypes.County) ? lea.ownedLeas.length : 0
        }
        return lea
      }
      leas.ownedLeas = leas.ownedLeas.map(leaMap, leas)
      leas.schoolsServed = leas.ownedLeas
        .map(l => l.schoolsServed)
        .reduce((total, schoolCount) => total + schoolCount)
      leas.districtsServed = leas.ownedLeas
        .map(l => l.districtsServed)
        .reduce((total, districtCount) => total + districtCount)

      state.leaContext.Leas = leas
      const map = {}
      map['AR'] = state.leaContext.Leas

      leas.inactiveSchools.forEach(inactiveLea => {
        let trimmedLea = { ...inactiveLea }
        delete trimmedLea.calOption
        delete trimmedLea.hasChildren
        delete trimmedLea.xferAgreement
        delete trimmedLea.schoolChoice
        delete trimmedLea.url

        map[inactiveLea.number] = trimmedLea
      })

      const children = lea => {
        map[lea.number] = lea
        if (lea.type == LeaTypes.District) {
          const existing = map[lea.countyCode]
          map[lea.countyCode] = {
            name: `${lea.county} County`,
            number: lea.countyCode,
            type: LeaTypes.County,
            ownedLeas: existing ? [lea, ...existing.ownedLeas] : [lea],
            schoolsServed: (existing ? [lea, ...existing.ownedLeas] : [lea])
              .map(l => l.schoolsServed)
              .reduce((total, schoolCount) => total + schoolCount),
            districtsServed: (existing ? [lea, ...existing.ownedLeas] : [lea])
              .map(l => l.districtsServed)
              .reduce((total, districtCount) => total + districtCount),
          }
        }
        if (lea.ownedLeas) lea.ownedLeas.forEach(children)
      }
      leas.ownedLeas.forEach(children)

      leas.inactiveSchools.forEach(inactiveLea => {
        const district = map[inactiveLea.parentLea] || {
            name: `Closed district: ${inactiveLea.parentLea}`,
            number: inactiveLea.parentLea,
            type: LeaTypes.District
          }
               
        map[inactiveLea.number].parentLea = {
          name: district.name,
          number: district.number,
          type: district.type,
        }
      })

      state.leaContext.LeaDictionary = map
      
      // add special lookup for APSRC
      state.leaContext.LeaDictionary['9999999'] = {
        number: '9999999',
        type: LeaTypes.Coop,
        name: 'Arkansas Public School Resource Center',
        ownedLeas: [],
        schoolsServed: 0,
        districtsServed: 0
      }
      state.leaContext.LeaDictionary['MYLEAS'] = { 
        name: 'My Schools', 
        number: 'MYLEAS', 
        type: LeaTypes.District,
        parentLea: {},
        gradesServed: "9-12",
        ownedLeas: []
      }
      
      state.leaContext.dictionaryReady = true

      state.CacheTimestamp = Date.now()
    },
    setAppInitialized: (state, appInitialized) => state.appInitialized = appInitialized,
    setSelectedLea: (state, lea) => {
      state.leaContext.SelectedLea = { ...lea }
    },
    setUserContext: (state, context) => state.userContext = Object.assign(new UserContext(), context),
    setAccessHeaders: (state, headers) => state.accessHeaders = headers,
    //setPdfExportPageState: (state, pageState) => state.pdfExportPageState = pageState,
    setAddDebugMessage: (state, message) => state.debugMessages.push(message),
    setAddColorDebugMessage: (state, payload) => state.debugMessages.push(payload),
    setPageviews: (state, pageViews) => state.pageViews = pageViews,
    setIsOnline: (state, isOnline) => state.isOnline = isOnline
  }
  actions = {
    changeContext: ({
      commit
    }) => {
      const newContext = window.prompt('Enter User Id')
      if (newContext != null) {
        const newUserElement = document.getElementById('newUserId')
        if (newUserElement != null) {
          newUserElement.setAttribute('value', newContext)
          var form = document.getElementById('switch-context')
          if (form != null) {
            // DPSAFT state rehydration issues while developing
            commit('DPSAFT/setFilterArea', null, {
              root: true
            })
            commit('DPSAFT/setBuses', [], {
              root: true
            })
            form.submit()
          }
        }
      }
    },
    resetContext: () => {
      var form = document.getElementById('reset-context')
      if (form != null) {
        form.submit()
      }
    },
    initialize: (context) => {

      if (context.state.appInitialized) {
        return Promise.resolve(true)
      }

      context.commit('setUserContext', window.userContext)

      // this should only happen for PDFs when we send down the lea list as part of the response body
      if (window.leas) {
        context.commit('setAppInitialized', true)
        context.commit('setLeas', { leas: window.leas, source: 'window' } )
        // console.log('Initializing application')
      }

      //to determine need for module-specific LEA lists
      const leaSource = this.determineLeaSource()

      //if the LEA source for this module is different than what is cached, and we're not feeding the LEAs down win the window (e.g. for the purpose of generating a PDF)
      if (context.state.leaContext.source !== leaSource && context.state.leaContext.source != 'window') {
        // console.log('Getting LEAs From Server')
        context.commit('setAppInitialized', true)
        return context.dispatch(leaSource, null, { root: true })
      } else {
        // console.log('LEA list already hydrated')
        context.commit('setAppInitialized', true)
        return Promise.resolve(true)
      }
    },
    getLeas: context => {
      return this._apiService
        .Get(`${this._baseUrl}api/lea?fiscalYear=${this._fiscalYear}`)
        .then(leas => {
          return context.commit('globalModule/setLeas', { leas: leas, source: 'globalModule/getLeas' }, { root: true })
        })
    },
    showUnauthorizedToast: ({
      commit
    }, headers) => {
      commit('setAccessHeaders', headers)
      const app = window.global.default.VueApp
      app.$bvModal.show('alternative-leas-modal')
    },
    changeSelectedLea: (_context, lea) => {
      this._router.push({
        query: {
          lea: lea.number
        }
      })
    },
    testLoadingIndicator: (_context, success) => {
      if (success) return Promise.delay(7000)
      else return Promise.delay(7000).then(() => this.BREAKMYPROMISE())
    },
    addDebugMessage: ({ commit }, message) => {
      commit('setAddDebugMessage', message)
    },
    addColorDebugMessage: ({ commit }, payload) => {
      commit('setAddColorDebugMessage', payload)
    },
    // eslint-disable-next-line
    downloadPdf: ({}, payload) => {
      if (payload.url.includes('shared-report-id')) {
        return this._apiService.Post(payload.url, payload.payload);
      }
      return this._apiService.DownloadWithPost(payload.url, payload.payload);
    },
    getPageviews: ({
      commit
    }) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/pageviews`))
        .then(data => commit('setPageviews', data))
    }
  }
}

export default GlobalModule
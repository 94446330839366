<template lang="html">
  <div>
    <div>
      <span v-if="!caseId" />
      <a v-else :href="'https://aels.ade.arkansas.gov/AELS/View/PLView.aspx?CID=' + caseId" target="_blank">{{ caseId }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'aels-case-id-link',
  props: { 
    caseId: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>

<template lang="html">
  <div class="d-print-none">
    <div class="row">
      <div class="col-md-6 text-left">
        <span v-if="lastRecord > 0">
          Showing {{ firstRecord }}-{{ lastRecord }} of {{ displayedRowCount }} records <span v-if="filteredRowsCount && filteredRowsCount < totalRowsCount">(filtered from {{ totalRowsCount }})</span>
        </span>
        <span v-else>
          No records found to display
        </span>
      </div>
      <div class="col-md-6 text-right">
        <b-pagination v-model="currentPage" :total-rows="displayedRowCount" :per-page="pageLength" aria-controls="my-table" class="pull-right" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'table-pagination-widget',
  data() {
    return {
      currentPage: 1
    }
  },
  props: {
    totalRowsCount: Number,
    filteredRowsCount: Number,
    pageLength: Number,
    filter: String
  },
  computed: {
    firstRecord() {
      return ((this.currentPage - 1) * this.pageLength) + 1
    },
    lastRecord() {
      let potential = this.currentPage * this.pageLength
      return potential < this.displayedRowCount ? potential : this.displayedRowCount
    },
    displayedRowCount() {
      return (this.filteredRowsCount == undefined || this.filteredRowsCount == null) ? this.totalRowsCount : this.filteredRowsCount
    }
  },
  watch: {
    currentPage(newValue) {
      this.$emit('page-changed', newValue)
    }
  }
}
</script>
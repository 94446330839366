import ApiService from '../../services/ApiService'
import { aggregateResponseResults } from '../../helpers/teacherSurveyAggregator'
import groupBy from 'lodash.groupby'

export class TeacherSurvey {
  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl =
    this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
  }

  namespaced = true

  state = {
    surveys: [],
    surveyDates: []
  }

  mutations = {
    setSurveys: (state, data) => {
      state.surveys = data

      // calculate date groupings
      var grouped = groupBy(state.surveys, (s) => { 
        var createdDate = new Date(s.createdDate)
        return new Date(createdDate.getFullYear(), createdDate.getMonth(), createdDate.getDate());
       })      
      state.surveyDates = Object.keys(grouped).map(k => { return { x: k, y: grouped[k].length }})
    },
  }

  getters = {
    aggregateData(state, getters, rootState, rootGetters) {      
      return aggregateResponseResults(state.surveys.map(r=>{
        var lea = rootGetters['globalModule/leaDictionary'][r.coopLea]
        return { 
          coop: {number: lea.number, name: lea.name},
          responseData: r.responseData }
        }))
    }
  }

  actions = {
    submitSurvey: ({commit}, payload) => {
      return this._apiService
      .Post(`${this._baseUrl}api/surveys/teacherSurvey/submit`, payload)
      .then((data)=>commit('submitSurvey', data))
    },
    getSurveys: ({ commit }, payload) => {     
      return this._apiService.Get(`${this._baseUrl}api/surveys/teacherSurvey/${payload.lea}`)
        .then(data => {
          if (!data) throw new Error("The LEA does not have any surveys")

          data.forEach(d=>{
            d.responseData = JSON.parse(d.responseData)
          })
          
          commit('setSurveys', data)
        })
      
    },
  }
}
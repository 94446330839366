<template>
  <div>
    <teacher-detail />
  </div>
</template>

<script>
import TeacherDetail from '../common/TeacherDetail.vue'

export default {
  components: {
    TeacherDetail
  }
}
</script>
<template>
  <div class="lea-assignment-container">
    <h2><lea-display :lea="userLea" v-if="this.$router.currentRoute.name == 'requests'" /></h2>    
    <router-view />
  </div>
</template>

<script>
import { PageMixin } from '../../PageMixin'
import { router } from '../../../routes'
import store from '../../../stores'
import NewRequest from './requests/new'
import EditRequest from './requests/edit'
import ReviewRequest from './requests/review'
import FinalizeReview from './finalizeReview'
import RequestList from './requestList'
import SchoolList from './schoolList'
import AccountabilityAdministration from './accountability/accountabilityAdministration.vue'
import AddFeederPair from './accountability/addFeederPair.vue'
import AddStatusRecord from './accountability/addStatusRecord.vue'
import ApprovedAssignments from './approvedAssignments/approvedAssignments.vue'
import LEAMSBrowser from './leams/browse'
import LEAMSEdit from './leams/edit'
import LEAMSNew from './leams/new'
import LEAMSSync from './leams/sync'
import { mapState, mapGetters } from 'vuex'
import { RequestType } from '../leaAssignment'

router.addRoutes([
   { path: '/tools/lea-assignment/', beforeEnter(to, from, next) {
      var userContext = store.state.globalModule.userContext
      var isStateUser = userContext.isPSAAdmin || userContext.isRTSMSAdmin || userContext.isInsightsAdmin || userContext.isADELeadership
      var isViewUser = userContext.isADEViewer

      if (isViewUser) 
        next({ name: 'approved-assignments' })
      else if (isStateUser)
        next({ name: 'requests' })
      else 
        next({ name: 'schools' })
     }
   },//redirect: { name: store.state.globalModule.userContext.isRTSMSAdmin ? 'requests' : 'schools' }},

   { path: '/tools/lea-assignment/leams', component: LEAMSBrowser, name: 'leams-browser'},
   { path: '/tools/lea-assignment/sync', component: LEAMSSync, name: 'leams-sync'},
   { path: '/tools/lea-assignment/leams/:fy/new', component: LEAMSNew, name: 'leams-new', props: true},
   { path: '/tools/lea-assignment/leams/:id', component: LEAMSEdit, name: 'leams-edit'},

   { path: '/tools/lea-assignment/assignments', component: ApprovedAssignments, name: 'approved-assignments'},
   { path: '/tools/lea-assignment/requests', component: RequestList, name: 'requests'},
   { path: '/tools/lea-assignment/psa-review', component: RequestList, name: 'requests'},

   { path: '/tools/lea-assignment/accountability/status-records/add', component: AddStatusRecord, name: 'add-status-record' },
   { path: '/tools/lea-assignment/accountability/feeder-pairs/add', component: AddFeederPair, name: 'add-feeder-pair' },
   { path: '/tools/lea-assignment/accountability', component: AccountabilityAdministration, name: 'accountability' },

   { path: '/tools/lea-assignment/requests/new', component: NewRequest, name: 'new-request' },
   { path: '/tools/lea-assignment/requests/create-new/:purpose', component: EditRequest, name: 'create-new-request' },
   { path: '/tools/lea-assignment/requests/:id/edit', component: EditRequest, name: 'edit-request', beforeEnter(to, from, next) {
       //the only roles that should be allowed to edit are people in ASPCN Users, RT_SMS_Admins, or Charter School Admins
       var allowedEdit = store.state.globalModule.userContext.hasAnyRole(['Insights_CharterSchoolOffice_Administrators', 'Insights_RT-SMS_Administrators', 'APSCN User']) 
       if (allowedEdit) { next() }
       else { 
         next({ name: 'view-request', params: to.params }) 
       }
     }
   },
   { path: '/tools/lea-assignment/requests/:id/view', component: EditRequest, name: 'view-request', props: { readOnly: true } },
   {
     path: '/tools/lea-assignment/requests/:id/review', component: ReviewRequest, name: 'review-request', beforeEnter(to, from, next) {
       console.log('route beforeEnter')
       var userContext = store.state.globalModule.userContext
       var canReview = userContext.isPSAAdmin || userContext.isRTSMSAdmin || userContext.isInsightsAdmin
       if (canReview) { next() }
       else { 
         next({ name: 'view-request', params: to.params }) 
       }
     }
   },
   {
      path: '/tools/lea-assignment/requests/:id/finalize', component: FinalizeReview, name: 'finalize', beforeEnter(to, from, next) {
        //console.log('in finalize route')
        var userContext = store.state.globalModule.userContext
        var canReview = userContext.isPSAAdmin || userContext.isRTSMSAdmin || userContext.isInsightsAdmin
        if (canReview) { next() }
        else { next({ name: 'requests' }) }
     }
   },
   { path: '/tools/lea-assignment/schools', component: SchoolList, name: 'schools' },
])

export default {
  name: 'lea-assignment-page',
  data() {
    return {
      displayLea: this.selectedLea
    }
  },
  mixins: [PageMixin],
  components: {
  },
  computed: {
    ...mapState('globalModule', ['userContext', 'leaDictionary']),
    ...mapGetters('globalModule', ['leaDictionary']),
    userLea() {
      return this.leaDictionary[this.userContext.districtLEA]
    }
  },
  mounted() {
    this.$store.dispatch("toolsModule/leams/getLEAMSFiscalYears")
    },
  methods: {
    getSummary() {
      return this.$store.dispatch('requestModule/getRequestSummary', { 
        lea: this.userContext.districtLEA,
        type: RequestType.LEAChangeRequest 
      })
    }
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler() {
        this.getSummary()
      }
    }
  }
}
</script>

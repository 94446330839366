<template>
  <div class="mt-3">
    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center" :class="{ 'bg-warning text-dark': propertyHasChangedFromSnapshot('waiverTopicsResponse') }">
        Waivers
      </div>
      <div class="card-body">
        <table class="table table-striped table-sm table-condensed mt-3">
          <thead>
            <tr>
              <th class="p-3 w-15">
                Waiver Topic
              </th>
              <th class="p-3 w-10">
                Standard for Accreditation
              </th>
              <th class="p-3 w-15">
                Division Rules
              </th>
              <th class="p-3 w-10">
                Arkansas Statutes
              </th>
              <th class="p-3 w-40">
                Indicate if the district is applying for the waiver and explain how the district waiver will be utilized.
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="p-3">
                <strong>Attendance</strong>
              </td>
              <td />
              <td />
              <td class="p-3">
                6-18-213(a)(2)
              </td>
              <td class="p-3">
                <b-textarea max-rows="200" rows="3" v-model="local.topicAttendance" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="p-3">
                <strong>Class Size</strong><br>
                <b-form inline>
                  <label for="class-size-number-of-students">Number of students:</label>
                  <b-input type="number" id="class-size-number-of-students" class="ml-2 number-input-sm d-print-none" :max="9999" number :plaintext="!edit" v-model="local.numClassSize" />
                </b-form>
              </td>
              <td class="p-3">
                1-A.5
              </td>
              <td class="p-3">
                DESE Rules Governing Class Size and Teaching Load
              </td>
              <td class="p-3">
                6-17-812(a)(2)
              </td>
              <td class="p-3">
                <b-textarea max-rows="200" rows="3" v-model="local.topicClassSize" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="p-3">
                <strong>Teaching Load</strong><br>
                <b-form inline>
                  <label for="class-size-number-teaching-load">Number of students:</label>
                  <b-input type="number" id="class-size-number-teaching-load" class="ml-2 number-input-sm" :max="9999" number :plaintext="!edit" v-model="local.numTeachingLoad" />
                </b-form>
                <span class="small">Teacher of Record provides virtual instruction to only students who are remote.
                  Please note that 100% virtual (online) or remote (distance) classes are considered
                  large group instruction courses as per
                  <a href="http://dese.ade.arkansas.gov/public/userfiles/Legal/Legal-Current%20Rules/2020/FINAL%20Class%20Size%20and%20Teaching%20Load.pdf" target="_blank">
                    DESE Rules for Distance and Digital Learning</a> and teaching load would not apply as per
                  <a href="http://dese.ade.arkansas.gov/public/userfiles/Legal/Legal-Current%20Rules/2020/FINAL%20Class%20Size%20and%20Teaching%20Load.pdf" target="_blank">
                    DESE Rules Governing Class Size and Teaching Load.</a>
                </span>
              </td>
              <td class="p-3">
                1-A.5
              </td>
              <td class="p-3">
                DESE Rules Governing Class Size and Teaching Load
              </td>
              <td class="p-3">
                6-17-812
              </td>
              <td class="p-3">
                <b-textarea max-rows="200" rows="3" v-model="local.topicTeachingLoad" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="p-3">
                <strong>Six Hour Instructional Day</strong> (Waiver applies to virtual/remote students only)
              </td>
              <td class="p-3">
                1-A.4.2
              </td>
              <td />
              <td class="p-3">
                6-16-102; 6-10-126
              </td>
              <td class="p-3">
                <b-textarea max-rows="200" rows="3" v-model="local.topicSixHourDay" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="p-3">
                <strong>Clock Hours</strong>
              </td>
              <td class="p-3">
                1-A.2
              </td>
              <td />
              <td />
              <td class="p-3">
                <b-textarea max-rows="200" rows="3" v-model="local.topicClockHours" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="p-3">
                <strong>Recess</strong> (Waiver applies to virtual/remote students only)
              </td>
              <td class="p-3">
                1-A.4.3
              </td>
              <td class="p-3">
                DESE Rules Governing Nutrition and Physical Activity Standards and Body Mass Index, Section 7.11
              </td>
              <td class="p-3">
                6-6-102(a)(5)
              </td>
              <td class="p-3">
                <b-textarea max-rows="200" rows="3" v-model="local.topicRecess" :plaintext="!edit" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { JsonResponseMixin } from '@/components/JsonResponseMixin'
import DetailMixin from './DetailMixin'

export default {
  mixins: [JsonResponseMixin, DetailMixin]
}
</script>

<style>
.number-input-sm {
  max-width: 100px;
}
</style>
<template>
  <div v-if="loaded && currentRequest && stateMap.get(currentRequest.requestStatus)">
    <div class="row">
      <district-info-section :request-lea="currentRequestDistrictInfo" />
      <div class="col-4">
        <div class="card card-body bg-light">
          <div class="form">
            <label class="font-weight-bold col-8">Apply Request to School Year:</label>
            <b-select
              class="col-4"
              :options="fiscalYearAssignmentOptions"
              :disabled="!canAssignFY"
              v-model="currentRequest.applyToFiscalYear"
              v-if="userContext.hasRole('Insights_RT-SMS_Administrators')"
            />
            <span v-else class="col-4">{{ currentRequest.applyToFiscalYear ? formatFY(currentRequest.applyToFiscalYear) : '(Not Assigned)' }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h3>{{ requestTitle }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div v-for="i in newDistrictRequestItems" :key="i.key">
          <request-item
            :item="i"
            title="New District LEA Information"
            header-class="bg-primary"
            :default-options="{allowRemove: false}"
          >
            <new-district-item :item="i" :operation="operation">
              <template v-slot:lea>
                <div>
                  <div v-if="canAssignLea">
                    <div class="input-group mb-3" v-if="i.lea">
                      <input
                        readonly
                        text-muted
                        v-model="i.lea"
                        type="text"
                        class="form-control"
                        placeholder
                        aria-label
                        aria-describedby="basic-addon1"
                      >
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-danger"
                          type="button"
                          @click="assignLea(i.key, null)"
                        >
                          Reset LEA
                          <i class="px-1 fas fa-spinner fa-spin" v-if="busy" />
                        </button>
                      </div>
                    </div>
                    <div class="input-group mb-3" v-if="!i.lea">
                      <b-input
                        v-model="newDistrictLeaInput"
                        id="new-lea-input"
                        :state="districtLeaInputValid"
                        type="number"
                        class="form-control"
                        placeholder
                        aria-label
                        aria-describedby="basic-addon1"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-primary"
                          type="button"
                          :disabled="!districtLeaInputValid"
                          @click="assignLea(i.key, newDistrictLeaInput)"
                        >
                          Assign District LEA
                          <i class="px-1 fas fa-spinner fa-spin" v-if="busy" />
                        </button>
                      </div>
                      <b-form-invalid-feedback
                        id="new-lea-input"
                      >
                        {{ assignedDistrictLeaValidationMessage(newDistrictLeaInput) }}
                      </b-form-invalid-feedback>
                    </div>
                    <div class="alert alert-primary" v-if="!i.lea">
                      <i class="fas fa-info-circle" /> LEA must be assigned before approval
                    </div>
                  </div>
                  <div v-else>
                    {{ i.lea || 'N/A' }}
                  </div>
                </div>
              </template>
            </new-district-item>
          </request-item>
        </div>
        <div v-for="i in newLeaRequestItems" :key="i.key">
          <request-item
            :item="i"
            title="New Building LEA Information"
            header-class="bg-success"
            :default-options="{allowRemove: false}"
          >
            <new-lea-item :item="i" :operation="operation">
              <template v-slot:lea="{}">
                <div>
                  <div v-if="canAssignLea">
                    <div class="input-group mb-3" v-if="i.lea">
                      <input
                        readonly
                        text-muted
                        v-model="i.lea"
                        type="text"
                        class="form-control"
                        placeholder
                        aria-label
                        aria-describedby="basic-addon1"
                      >
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-danger"
                          type="button"
                          @click="assignLea(i.key, null)"
                        >
                          Reset LEA
                          <i class="px-1 fas fa-spinner fa-spin" v-if="busy" />
                        </button>
                      </div>
                    </div>
                    <div class="input-group mb-3" v-if="!i.lea">
                      <b-input
                        v-model="leaInput[i.key]"
                        id="new-lea-input"
                        :state="leaInputValid(leaInput[i.key])"
                        type="number"
                        class="form-control"
                        placeholder
                        aria-label
                        aria-describedby="basic-addon1"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-primary"
                          type="button"
                          :disabled="!leaInputValid"
                          @click="assignLea(i.key, leaInput[i.key])"
                        >
                          Assign LEA
                          <i class="px-1 fas fa-spinner fa-spin" v-if="busy" />
                        </button>
                      </div>
                      <b-form-invalid-feedback
                        id="new-lea-input"
                      >
                        {{ assignedLeaValidationMessage(leaInput[i.key]) }}
                      </b-form-invalid-feedback>
                    </div>
                    <div class="alert alert-primary" v-if="!i.lea">
                      <i class="fas fa-info-circle" /> LEA must be assigned before approval
                    </div>
                  </div>
                  <div v-else>
                    {{ i.lea || 'N/A' }}
                  </div>
                </div>
              </template>
            </new-lea-item>
          </request-item>
        </div>
        <div v-for="i in inactivateRequestItems" :key="i.key">
          <request-item
            :item="i"
            title="Inactivate LEA Request"
            header-class="bg-warning text-dark"
            :default-options="{allowRemove: false}"
          >
            <inactivate-lea-item :item="i" :operation="operation" />
          </request-item>
        </div>
        <div v-for="i in editDistrictLeaRequestItems" :key="i.key">
          <request-item
            :item="i"
            title="Change District LEA Request"
            header-class="bg-primary"
            :default-options="{allowRemove: false}"
          >
            <change-district-lea-item :item="i" :operation="operation" />
          </request-item>
        </div>
        <div v-for="i in editSchoolLeaRequestItems" :key="i.key">
          <request-item
            :item="i"
            title="Change School LEA Request"
            header-class="bg-primary"
            :default-options="{allowRemove: false}"
          >
            <change-school-lea-item :item="i" :operation="operation" />
          </request-item>
        </div>
        <request-documents-widget :request-id="currentRequest.requestId" :display-only="true" />
      </div>
      <div class="col-md-4">
        <approval-widget
          :reviewing="true"
          :request="currentRequest"
          :on-before-state-change="saveRequest"
          @status-changed="onStatusChanged"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { LeaAssignmentRoles } from '@/components/tools/leaAssignment'
import NewLeaItem from '../requestItemComponents/view/newLeaItem'
import NewDistrictItem from '../requestItemComponents/view/newDistrictItem'
import ChangeSchoolLeaItem from '../requestItemComponents/view/changeSchoolLeaItem'
import ChangeDistrictLeaItem from '../requestItemComponents/view/changeDistrictLeaItem'
import InactivateLeaItem from '../requestItemComponents/view/inactivateLeaItem'
import RequestItem from '../requestItemComponents/requestItem'
import ApprovalWidget from '../approvalWidget'
import RequestDocumentsWidget from '../requestDocuments/RequestDocumentsWidget'
import DistrictInfoSection from '../districtInfoSection'
import { RequestOperationPurpose, requestOperationFormatter, RequestStatus } from '../../leaAssignment'
import { formatFY } from '../../../../helpers/formatters'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'review-request',
  data() {
    return {
      formatFY,
      RequestStatus,
      RequestOperationPurpose,
      assignedLea: null,
      leaInput: {},
      newDistrictLeaInput: null,
      districtPrefixInUse: false,
      busy: false, 
      loaded: false
    }
  },
  mounted() {
    this.$store
      .dispatch('toolsModule/leaAssignment/getRequest', {
        requestId: this.$route.params.id
      })
      .then(() => {
        this.$store.dispatch('toolsModule/leaAssignment/getReconfigurationCodes')
      })
      .then(() =>
        this.$store.dispatch('requestModule/getAvailableStates', {
          requestId: this.$route.params.id
        })
      )
      .then(() => 
        this.$store.dispatch('toolsModule/leaAssignment/getSchools', {
          lea: this.$store.getters['toolsModule/leaAssignment/currentRequest'].districtLEA,
          fy: this.$store.getters['toolsModule/leaAssignment/currentRequest'].fiscalYear
        }))
      .then(() => this.$store.dispatch('toolsModule/leams/getLeaTypes'))
      .then(() => {
        //if the fiscal year is not already set, default it to the current fiscal year
        if (this.userContext.hasRole(LeaAssignmentRoles.SMSAdmin) && !this.currentRequest.applyToFiscalYear) {
          this.currentRequest.applyToFiscalYear = this.fiscalYear + 1
        }
        console.log('request page loaded')
        this.loaded = true
      })
  },
  computed: {
    ...mapState('toolsModule/leaAssignment', ['currentRequest', 'currentResponse']),
    ...mapState('toolsModule/leaAssignment', ['stateMap', 'schools']),
    ...mapGetters('toolsModule/leams', ['fiscalYearAssignmentOptions', 'fiscalYear']),
    ...mapState('globalModule', ['leaContext', 'userContext']),
    
    operation() {
      return this.currentResponse.operation
    },
    requestTitle() {
      return requestOperationFormatter(this.operation)
    },
    districtLeaPrefix() {
      return (this.newDistrictLeaInput || '').substr(0, 4)
    },
    editSchoolLeaRequestItems() {
      return this.currentResponse.items.filter(i => i.itemType == 'edit-school')
    },
    editDistrictLeaRequestItems() {
      return this.currentResponse.items.filter(i => i.itemType == 'edit-district')
    },
    reviewAllowed() {
      return this.userContext.isPSAAdmin || this.userContext.isRTSMSAdmin || this.userContext.isInsightsAdmin
    },
    newLeaRequestItems() {
      return this.currentResponse.items
        .filter(i => i.itemType == 'new')
        .map(i => {
          return {
            ...i,
            lea: (this.currentRequest.requestLeas.find(l => l.operationParams == i.key) || {}).lea
          }
        })
    },
    newDistrictRequestItems() {
      return this.currentResponse.items
        .filter(i => i.itemType == 'new-district')
        .map(i => {
          return {
            ...i,
            lea: (this.currentRequest.requestLeas.find(l => l.operationParams == i.key) || {}).lea
          }
        })
    },
    hasNewRequest() {
      return this.newLeaRequestItems.length > 0
    },
    inactivateRequestItems() {
      return this.currentResponse.items.filter(i => i.itemType == 'inactivate')
    },
    hasInactivateRequest() {
      return this.inactivateRequestItems.length > 0
    },
    currentRequestDistrictInfo() {
      return this.leaContext.LeaDictionary[this.currentRequest.districtLEA]
    },
    newlyAssignedDistrictLea() {
      return this.newDistrictRequestItems.length > 0 ? this.newDistrictLeaInput || this.newDistrictRequestItems[0].lea : null
    },
    districtLeaInputValid() {
      return this.isSevenDigits(this.newDistrictLeaInput) && !this.isLeaNumberInUse(this.newDistrictLeaInput) && !this.districtPrefixInUse
    },
    canAssignLea() {
      return this.userContext.isRTSMSAdmin && this.currentRequest && this.currentRequest.requestStatus < 100
    },
    canAssignFY() {
      var lockedStatuses = [this.RequestStatus.Approved, this.RequestStatus.Rejected, this.RequestStatus.Canceled]
      return ! (lockedStatuses.includes(this.currentRequest.requestStatus) || this.currentRequest.syncStatus.attempted)
    }

  },
  methods: {
    assignedLeaValidationMessage(lea) {
      if (!this.isSevenDigits(lea)) return 'Assigned LEA must be 7 digits'
      else if (!this.matchesDistrictPrefix(lea)) return 'Assigned LEA must have the same district prefix'
      else if (!this.noLeaConflicts(lea)) return 'Assigned LEA must not conflict with existing LEA numbers'
      else if (lea == this.newlyAssignedDistrictLea) return 'Cannot be the same as the district LEA'
      return ''
    },
    noLeaConflicts(lea) {
      var assignedCount = 0

      // eslint-disable-next-line
      for (const [k, val] of Object.entries(this.leaInput)) {
        if (val === lea) assignedCount++
      }

      return assignedCount == 1 && this.schools.every(s => lea != s.leaNumber)
    },
    matchesDistrictPrefix(lea) {
      var districtPrefix = (this.newlyAssignedDistrictLea || this.currentRequest.districtLEA || '').substr(0, 4)

      return lea && lea.substr(0, 4) == districtPrefix
    },
    leaInputValid(value) {
      return this.isSevenDigits(value) && this.matchesDistrictPrefix(value) && this.noLeaConflicts(value) && value !== this.newlyAssignedDistrictLea
    },
    isLeaNumberInUse(lea) {
      return this.leaContext.LeaDictionary[lea] != null
    },
    assignedDistrictLeaValidationMessage(value) {
      if (this.districtPrefixInUse) {
        return 'LEA prefix is already in use by another district'
      }

      if (!this.isSevenDigits(value)) return 'Assigned LEA must be 7 digits'

      if (this.isLeaNumberInUse(value)) {
        return 'LEA cannot alrady be in use by another district/school'
      }

      return ''
    },
    isSevenDigits(value) {
      return value && (value || '').length == 7
    },

    onChangeReconfigurationCode(item, newCode) {
      console.log('RC', item, newCode)
      item.reconfigurationCode = newCode
    },
    assignLea(key, lea) {
      this.assignedLea = lea
      if (lea == null) {
        this.leaInput = null
      }
      this.busy = true
      this.$store.dispatch('toolsModule/leaAssignment/assignLea', { requestId: this.currentRequest.requestId, key: key, lea: lea }).then(() => {
        this.busy = false
      })
    },
    onStatusChanged(event) {
      if (event.status == 100) {
        return this.$router.push(`/tools/lea-assignment/requests/${this.currentRequest.requestId}/finalize`)
      } else {
        return this.$router.push('/tools/lea-assignment/requests')
      }
    },
    async saveRequest() {
      this.busy = true

      //set the fiscal year
      if (this.userContext.hasRole(LeaAssignmentRoles.SMSAdmin) && this.currentRequest.applyToFiscalYear) {
        await this.$store.dispatch('requestModule/assignToFiscalYear', {
          requestId: this.currentRequest.requestId,
          fiscalYear: this.currentRequest.applyToFiscalYear
        })
      }

      return this.$store.dispatch('toolsModule/leaAssignment/updateRequest').then(() => {
        this.busy = false
        this.$bvToast.toast('Saved', {
          title: `Request saved`,
          variant: 'success',
          solid: true
        })
      })
    }
  },
  components: {
    NewLeaItem,
    NewDistrictItem,
    ChangeSchoolLeaItem,
    ChangeDistrictLeaItem,
    InactivateLeaItem,
    RequestItem,
    ApprovalWidget,
    DistrictInfoSection,
    RequestDocumentsWidget
  },
  props: {},
  watch: {
    districtLeaPrefix(newValue) {
      if ((newValue || '').length >= 4) {
        this.$store.dispatch('toolsModule/leaAssignment/getLeaPrefixInUse', { lea: newValue }).then(result => {
          this.districtPrefixInUse = result
        })
      } else {
        this.districtPrefixInUse = false
      }
    }
  }
}
</script>

<template lang="html">
  <div> 
    <div class="form-group">   
      <datepicker :input-class="{'form-control col-md-3':true}" :highlighted="highlightedDates" placeholder="Select Date" v-model="selectedDate" />
    </div>
    <loadable-chart-card :load-data="dataLoading" :enable-data-toggle="false" card-style="">    
      <paginated-table striped hover selectable show-empty select-mode="single" 
                       :fields="tableOptions.fields" 
                       :items="expiringLicenseNotifications">
        <template v-slot:cell(lea)="data">
          <a href="#" @click="goToLea(data.value)" :id="data.index + '-' + data.value">{{ data.value }}</a>
          <lea-tooltip :target-selector="data.index + '-' + data.value" :lea-number="data.value" />
        </template>
        <template v-slot:cell(licenseInformation)="data">
          <div class="d-inline">
            <ul>
              <!-- eslint-disable-next-line -->
              <li v-for="license in data.value">
                License Area {{ license.notificationKey.split('|')[1] }} ({{ license.areaDescription }}) expiring on {{ license.notificationKey.split('|')[2] | date("MM/DD/YYYY") }}
              </li>
            </ul>
          </div>
        </template>
        <template v-slot:empty="{}">
          <div class="alert alert-warning">
            No notifications found for selected date
          </div>
        </template>
      </paginated-table>
    </loadable-chart-card>
  </div>
</template>

<script>
import PaginatedTable from '../../PaginatedTable.vue'
import { ChartMixin } from "../../ChartMixin"
import { mapGetters } from "vuex"
import * as moment from "moment"
import Datepicker from "vuejs-datepicker"

export default {
  mixins: [ChartMixin],
  data() {
    return {
      selectedDate: new Date(),
      dataLoading: this.loadData,
      tableOptions: {
        fields: [
          {
            key: "fullName",
            label: "Licensee Name",
            sortable: true
          },
          {
            key: "recipientAddress",
            label: "Email Address",
            sortable: true
          },
          {
            key: "licenseInformation",
            label: "Expiring License Areas",
            sortable: false
          },
          {
            key: "sendDate",
            label: "Send Date",
            sortable: true,
            formatter: d => moment(d).format("MM/DD/YYYY")
          },
          {
            key: "sendStatus",
            label: "Send Status",
            formatter: d =>
              d == 1 ? "Sent" : d == -1 ? "Error Sending" : "Not Sent"
          },
          {
            key: "acknowledgeDate",
            label: "Acknowledge Date",
            sortable: true,
            formatter: d => (d ? moment(d).format("MM/DD/YYYY") : "N/A")
          }
        ],
        filteredRowCount: null,
        isLoading: false
      }
    };
  },
  components: {
    Datepicker,
    PaginatedTable
  },
  props: {
    selectedLea: Object
  },
  methods: {
    onFiltered(filteredItems) {
      this.filteredRowCount = filteredItems.count;
    },
    loadNotifications() {
      this.dataLoading = this.$store.dispatch(
        "hcptModule/getExpiringLicenseNotifications",
        {
          lea: this.selectedLea.number,
          sendDate: moment(this.selectedDate).format("YYYY-MM-DD")
        }
      );
    }
  },
  computed: {
    ...mapGetters("hcptModule", ["expiringLicenseNotifications","expiringLicenseProcessDates"]),
    totalRowCount() {
      return this.expiringLicenseNotifications
        ? this.expiringLicenseNotifications.length
        : 0;
    },
    highlightedDates() {
      return { dates: this.expiringLicenseProcessDates };
    }
  },
  created() {
    this.$store.dispatch(
      "hcptModule/getExpiringLicenseNotificationProcessDates"
    );
  },
  watch: {
    selectedLea: {
      handler() {
        this.loadNotifications()
      }
    },
    selectedDate: {
      immediate: true,
      handler(){
        if(this.selectedLea && this.selectedLea.number && this.selectedDate){
          this.loadNotifications()
        }
      }
    }
  }
};
</script>
<template>
  <div>
    <pdf-button :lea="lea" :disabled="editMode" v-if="lea && lea.number && identifier" :additional-query-string-params="pdfQueryString" />
    <a v-if="lea && lea.number && context != Context.Snapshot" :href="`/?lea=${lea.number}#waivers`" class="btn btn-primary float-right mr-2 d-print-none" target="_blank">
      Active Waivers
    </a>
    <b-button @click="back" v-if="identifier" :disabled="editMode" class="float-right mr-2 d-print-none">
      <i class="fas fa-chevron-left" /> {{ backButtonText }}
    </b-button>
    <div class="d-none d-print-block" v-if="request">
      <div class="float-right pt-2">
        <workflow-status-badge :state-map="StateMap" :status="request.status" :document="request" />
      </div>
    </div>
    <h1>{{ waiverRequestTitle }}</h1>
    <h2><lea-display :lea="lea" v-if="lea && lea.number" /></h2>
    <h4 class="text-primary" v-if="request && request.fiscalYear">
      School Year {{ request.fiscalYear + 1990 }}-{{ request.fiscalYear + 1991 }}
    </h4>
    <div v-if="context != Context.Snapshot && waiverRequestId" class="d-print-none px-0 col-9 mb-0">
      <b-alert variant="secondary" v-for="snapshot in snapshots" class="mb-1" :key="snapshot.snapshotDate" show>
        <router-link :to="{ path: '/Surveys/DigitalLearning/Snapshot', query: { lea: snapshot.districtLEA, waiverRequestSnapshotId: snapshot.waiverRequestSnapshotId } }">
          <i class="fa fa-camera" />
          Snapshot ({{ snapshot.fiscalYear + 1990 }}-{{ snapshot.fiscalYear + 1991 }}) &ndash; {{ snapshot.snapshotDate | date }}
        </router-link>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { PageMixin } from '@/components/PageMixin'
import { Types, Context, StateMap } from '@/stores/digitalLearning'
import WorkflowStatusBadge from '@/components/workflow/WorkflowStatusBadge.vue'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      Context,
      StateMap,
    }
  },
  computed: {
    ...mapState(Types.path, [
      Types.state.snapshots,
    ]),
    backButtonText() {
      if (this.context == Context.Snapshot)
        return 'Back'

      return this.context == Context.District ? 'Back to District Summary' : 'Back to Request List'
    },
    pdfQueryString() {
      return this.waiverRequestSnapshotId ? `&waiverRequestSnapshotId=${this.waiverRequestSnapshotId}`: `&waiverRequestId=${this.waiverRequestId}`
    },
    identifier() {
      return this.waiverRequestSnapshotId || this.waiverRequestId
    }
  },
  components: {
    WorkflowStatusBadge,
  },
  mixins: [PageMixin],
  methods: {
  },
  props: {
    editMode: Boolean,
    waiverRequestId: {
      type: [String, Number],
      required: false,
    },
    waiverRequestSnapshotId: {
      type: [String, Number],
      required: false,
    },
    lea: Object,
    currentStatus: Object,
    waiverRequestTitle: String,
    request: Object,
    back: Function,
    context: Number
  },
}
</script>
<template>
  <div class="district-list">
    <paginated-table :items="districtInfo" :fields="fields" sort-by="districtLea" :sort-asc="true" :per-page="20" striped hover :load-data="loading">
      <!-- <template v-slot:cell(action)="data">
        <router-link class="btn btn-sm btn-primary" title="View" :to="{ name: 'Schedule/RVS/Teachers/Teacher', params: { id: data.item.uniqueEmployeeId, lea: data.item.districtLEA }}">
          <i class="fas fa-eye" />
        </router-link>
      </template> -->
      <template v-slot:cell(districtLea)="data">
        <a href="#" @click="goToLea(data.item.districtLea)" class="cursor-pointer">
          <lea-icon :lea="leaDictionary[data.item.districtLea]" /> {{ data.value }}
        </a>
      </template>
      <template v-slot:cell(verificationProgress)="data">
        <b-progress min="0" :max="100" style="height: 1rem;">
          <b-progress-bar :value="data.value" />
        </b-progress>
        <div class="progress-overlay">
          {{ data.value.toFixed(0) }}% ({{ data.item.completeSchools }} of {{ data.item.totalSchools }})
        </div>
      </template>
      <template v-slot:cell(status)="data">
        <!-- a badge with a calculated class and the text inside is a calculated string -->
        <span class="badge badge-lg" :class="getStatus(data.value).class">{{ getStatus(data.value).description }}</span>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import PaginatedTable from '@/components/PaginatedTable'
import LeaIcon from '@/components/LeaIcon'

export default {
  data() {
    return {
      fields: [
        { key: 'districtLea', filterByFormatted: true, sortByFormatted: true, label: 'District', sortable: true, formatter: this.leaFormatter },
        { key: 'status', filterByFormatted: true, label: 'Status', sortable: true },
        { key: 'verificationProgress', tdClass: 'progress-bar-cell', label: 'Verification Progress (Schools)', sortable: true }
      ],
      loading: null
    }
  },  
  components: {
    PaginatedTable,
    LeaIcon
  },
  computed: {
    ...mapState('rvsModule', ['districtList', 'fiscalYear']),
    ...mapGetters('rvsModule', ['districtInfo'])
  },
  props: {
    
  },
  methods: {
    async reloadData() {
      if (this.fiscalYear)
        this.loading = this.$store.dispatch('rvsModule/getDistrictList', this.selectedLea.number)
    },
    getStatus(status) {
      // Draft = 0,
      // Finalized = 100,
      switch(status) {
        case 0:
          return {
            class: 'badge-secondary',
            description: 'Draft'
          }
        case 100:
          return {
            class: 'badge-success',
            description: 'Finalized'
          }
        default:
          return {
            class: 'badge-warning',
            description: 'In Progress'
          }
      }
    }
  },
  watch: {
    fiscalYear: {
      handler() {
        this.reloadData()
      }
    },
    selectedLea: {
      immediate: true,
      handler() {
        this.reloadData()
      }
    }
  }
}
</script>

<style>
  .progress-bar-cell {
    padding-bottom: 0px !important;
  }
</style>
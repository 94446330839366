<template>
  <div>
    <b-alert v-model="showError" variant="danger" dismissible>
      {{ errorMessage }}
    </b-alert>
    <b-form-group label="Sign-In Sheet">      
      <div v-if="!readonly && !localFile">
        <b-button variant="secondary" @click="onAddItem()" :disabled="busy">
          <i class="fas fa-file-upload fa-fw text-white" /> Upload Sign-In Sheet
        </b-button>
      </div>
      <div v-if="localFile && localFile.Filename">
        <b-button size="sm" variant="info" class="mr-2" title="Download" :disabled="downloading" @click="onDownloadItem()">
          <i class="fa fa-download fa-fw text-white" /> Download Sign-In Sheet
        </b-button>
        <b-button size="sm" variant="danger" @click="onRemoveItem()" title="Delete" v-if="!readonly">
          <i class="fa fa-trash fa-fw text-white" /> Delete Sign-In Sheet
        </b-button>
      </div>
      <b-alert variant="info" :show="downloading">
        File download in progress
      </b-alert>
    </b-form-group>
    <upload-modal @upload="uploadFile($event)" />
  </div>
</template>

<script>
import UploadModal from '@/modules/cte/components/careerCoach/widgets/shared/UploadModal.vue'
import axios from 'axios'
import { Types } from '@/modules/cte/stores/careercoach/index.js'
// import getMime from 'name2mime'
// import moment from 'moment'
// import prettyBytes from 'pretty-bytes'


export default {
  data(){
    return {
      busy: false,
      uploadDone: false,
      result: null,
      filename: '',
      showError: false,
      errorMessage: null,
      localFile: this.file,
      downloading: false,
      types: Types
    }
  },
  components:{
    UploadModal
  },
  props: {
    file: {
      type: String,
      default: null
    },
    visitId:{
      type: Number,
      default: null
    },
    readonly: {
      type: Boolean, 
      default: () => true
    }
  },
  methods: {
    onRemoveItem() {
      var self = this
      this.$bvModal.msgBoxConfirm(`Are you sure you want to delete this file?`)
        .then((result) => {
          if (result) {
            this.$store.dispatch(Types.actions.deleteBulkVisitFile, { lea: this.selectedLea.number, visitId: this.visitId })
              .then(() => self.localFile = null)
          }
        })
    },
    onAddItem() {
      this.$bvModal.show('uploadModal')
    },
    onDownloadItem() {
      this.downloading = true
      this.$store.dispatch(Types.actions.downloadBulkVisitFile, { lea: this.selectedLea.number, visitId: this.visitId, MimeType: this.localFile.MimeType })
        .then(() => this.downloading = false)      
    },
    uploadFile(file) {
      var self = this
      this.result = null
      this.busy = true
      this.filename = file.name

      var formData = new FormData()
      formData.append('file', file, file.name)
      axios.post(`/api/cte/career-coach/${self.selectedLea.number}/bulk-visits/${self.visitId}/documents`, formData,
      {
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          this.uploadDone = percentCompleted < 100
        }
      })
      .then((response) => {
        self.result = response.data
        self.localFile = JSON.parse(self.result)
        self.busy = false
        self.uploadDone = true
        self.$store.commit(self.types.mutations.addFileToBulkVisit, {visitId: self.visitId, file: self.result})
        self.$emit('file-uploaded', this.file)
      })
      .catch(err => {
        if(err.response){
          self.errorMessage = err.response.data
          self.showError = true
        }
        else if(err.request) {
          self.errorMessage = 'An error occurred sending your request to the server'
          self.showError = true
        }
        self.busy = false
        self.uploadDone = true
      })
    }
  },
  mounted() {
    this.localFile = JSON.parse(this.file)
    console.log(this.file)
  }
}
</script>

<template>
  <div>
    <transportation-filter v-if="showFilter" @on-filter-changed="onFilterChanged" @on-filter-cleared="filtered-false" default-sort="name" criteria-visible="acd" :allow-unfiltered-search="true" />
    <inspection-dashboard-widget mode="transportation" :load-data="loadData" :is-state-view="isStateView" v-if="filtered" />
    <bus-list v-if="showTable"
              :items="items"
              :selected-buses="selectedBuses"
              :show-latest-inspection="true"
              :allow-item-check="allowItemCheck"
              :show-action-column="showActionColumn"
              @row-selected="onSelected"
              @item-checked="onItemChecked"
              @add-items="onAddItems"
              :load-data="loadData">
      <template v-slot:action-column-content="data">
        <slot name="action-column-content" v-bind="data" />
      </template>
    </bus-list>
    
    <div class="alert alert-warning text-center" v-if="isStateView && !filtered">
      <h4>Please click 'Filter' to view the state level bus inspection summary.</h4>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BusList from '../common/BusList'
import TransportationFilter from '@/components/dpsaft/transportation/TransportationFilter.vue'
import InspectionDashboardWidget from '../common/InspectionDashboardWidget.vue'

export default {
  data() {
    return {
      loadData: null,
      filtered: false
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState('DPSAFT', ['buses', 'filter']),
    ...mapState('DPSAFT/Trans', ['BatchMaintenance']),
    items() {
      if (this.showTable) {
        return this.buses
      }
      return []
    },
    showTable() {
      return this.filtered && !this.isStateView
    },
    selectedBuses: {
      set(buses) {
        this.$store.commit('DPSAFT/Trans/setSelectedBuses', buses)
      },
      get() {
        return this.BatchMaintenance.buses
      }
    },
    canFilter() {
      return (this.filter.district)
    },
    isStateView() {
      return !this.filter.district
    }
  },
  methods: {
    onFilterChanged(newFilter) {
      if (this.isStateView && !this.filtered) {
        this.filtered = true
      }
      this.loadData = this.$store.dispatch('DPSAFT/getBuses', {
        districtLea: newFilter.district,
        includeLatestInspection: this.includeLatestInspection
      }).then(()=>
      {
        this.filtered = true
        //this.loadData = null
      })
    },
    onSelected(rows) {
      console.log('Bus selected:', rows)
    },
    onItemChecked(checkedItems) {
      this.BatchMaintenance.SelectedBuses = checkedItems
      this.$emit('item-checked', checkedItems)
    },
    onAddItems(payload) {
      this.$emit('add-items', payload)
    }
  },
  props: {
    includeLatestInspection: {
      type: Boolean,
      default: true
    },
    allowItemCheck: {
      type: Boolean,
      default: false
    },
    showActionColumn: {
      type: Boolean,
      default: false
    },
    showFilter: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.filtered = false
    if (this.canFilter) {
      this.loadData = Promise.all([
        this.$store.dispatch('DPSAFT/getAreas'),
        this.$store.dispatch('DPSAFT/getBuses', {
          districtLea: this.filter.district,
          building: this.filter.building,
          includeLatestInspection: true
      })]).then(() => this.filtered=true)
    }
    else {
      this.$store.commit('DPSAFT/setBuses', [])
      this.loadData = this.$store.dispatch('DPSAFT/getAreas')
    }
  },
  components: {
    BusList,
    TransportationFilter,
    InspectionDashboardWidget
  }
}
</script>

export function parseRaceData(raceString) {
  if (!raceString || raceString === '') {
    return 'Unknown';
  }
  
  const races = [
    'Hispanic',
    'Native American/Alaskan Native',
    'Asian',
    'Black/African American',
    'Native Hawaiian/Pacific Islander',
    'White'
  ];
  
  const raceFlags = raceString.split('');
  const foundRaces = raceFlags.reduce((acc, flag, index) => {
    if (flag === 'Y') {
      acc.push(races[index]);
    }
    return acc;
  }, []);
  
  if (foundRaces.length === 0) {
    return 'Unknown';
  } else if (foundRaces.length === 1) {
    return foundRaces[0];
  } else {
    return 'Two or more races';
  }
}

export function parseMealStatus(mealStatus) {
  switch (mealStatus) {
    case 1:
      return 'Free';
    case 2:
      return 'Reduced';
    case 3:
      return 'Full-price Paid';
    case 4:
      return 'Direct Certification';
    default:
      return 'Unknown';
    }
}

export function parseInstructionalChoice(instructionalChoice) {
  switch (instructionalChoice) {
    case 1:
      return 'Onsite';
    case 2:
      return 'Virtual/Remote Learning';
    case 3:
      return 'Hybrid/Blended Learning';
    case 4:
      return 'N/A (No Show/No Activity)';
    default:
      return 'Unknown';
  }
}
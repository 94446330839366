<template>
  <div class="my-2">
    <div class="row">
      <div class="col-12">
        <div class="card bg-light">
          <div class="card-body">
            <b-form inline>
              <b-form-group label="District" v-if="criteriaVisible.includes('d') && !isSuperintendent" class="w-25 mx-1 font-weight-bold">
                <b-form-select v-model="district" :options="districtsSorted" class="w-100" />
              </b-form-group>
              <b-form-group label="Building" v-if="criteriaVisible.includes('b') && enableBuildingFilter" :disabled="buildingDisabled" class="w-25 mx-1 font-weight-bold">
                <b-form-select v-model="building" :options="filterBuildings" class="w-100" />
              </b-form-group>              
              <b-form-group label="Fiscal Year" v-if="criteriaVisible.includes('f')" class="mx-1 font-weight-bold">
                <b-form-select v-model="fy" :options="fyOptions" class="w-100" />
              </b-form-group>
              <b-form-group class="mx-1" v-if="!isSuperintendent || enableBuildingFilter" style="margin-top: 28px;">
                <b-button-group>
                  <b-button @click="applyFilter" variant="primary" :disabled="!canFilter">
                    <i class="fa fa-filter" aria-hidden="true" /> Filter
                  </b-button>
                  <b-button @click="clear" variant="warning" v-if="isCriteriaSelected" title="Clear Filter">
                    <i class="fa fa-undo" aria-hidden="true" />
                  </b-button>
                </b-button-group>
              </b-form-group>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { CoordinatorCodes } from '../../../helpers/coordinatorCodes'

export default {
  data() {
    return {
      currentFilter: {},
      userAreas: [],
      sortBy: this.defaultSort
    }
  },
  props: {
    enableBuildingFilter: {
      type: Boolean,
      default: false
    },
    criteriaVisible: {
      type: String,
      default: "dbf"
    },
    allowUnfilteredSearch: {
      type: Boolean,
      default: false
    },
    defaultSort: {
      type: String,
      default: 'leaNumber'
    },
    fiscalYears: {
      type: Array,
      required: false
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState('DPSAFT', ['filter']),
    ...mapGetters('DPSAFT', ['districts', 'transportationDistrictsByName', 'transportationDistricts']),
    district: {
      set(district) {
        this.$store.commit('DPSAFT/setFilterDistrict', district)
      },
      get() {
        return this.filter.district
      }
    },
    building: {
      set(building) {
        this.$store.commit('DPSAFT/setFilterBuilding', building)
      },
      get() {
        return this.filter.building
      }
    },    
    fy: {
      set(fy) {
        this.$store.commit('DPSAFT/setFilterFiscalYear', fy)
      },
      get() {
        return this.filter.fy
      }
    },
    buildingDisabled() {
      return ![this.filter.district].some(a => a)
    },
    canFilter() {
      return (this.filter.district || this.allowUnfilteredSearch) && this.currentFilter != JSON.stringify(this.filter)
    },
    isCriteriaSelected() {
      return (this.filter.district || this.building) != null
    },
    isSuperintendent() {
      const codes = this.userContext.CoordinatorCodes || ''
      return codes.includes(CoordinatorCodes.SUPERINTENDENT) || codes.includes(CoordinatorCodes.ASSISTANT_SUPERINTENDENT)
    },
    districtsSorted() {
      return this.sortBy == 'name' ? this.transportationDistrictsByName : this.transportationDistricts
    },
    fyOptions() {
      if(this.fiscalYears) {
        return this.fiscalYears.map(fy => { return {text: fy + 1990, value: fy}})
      }
      return []
    }
  },
  created(){
  },
  methods: {
    applyFilter() {
      this.currentFilter = JSON.stringify({...this.filter, fy: this.fy})
      this.$emit('on-filter-changed', this.filter)
    },
    compareAreas(a, b) {
      if (a.name > b.name) {
        return 1
      }
      if (a.name < b.name) {
        return -1
      }
      return 0
    },
    clear() {
      this.district = null
      this.building = null
      this.$emit('on-filter-cleared', this.filter)
    },
  },
  watch: {
      fiscalYears: {
        immediate: true,
        handler() {
          console.log('fiscalYears handler')
          if (this.fiscalYears && this.fiscalYears.length > 0) {
            this.fy = this.fiscalYears[0]
          }
        }
      }
    }
}
</script>
<style>
.w-10 {
  width: 10%;
}
</style>

<template>
  <div class="mt-3">
    <paginated-table hover :items="items" :fields="fields">
      <template v-slot:cell(fiscalYear)="data">
        <div>
          {{ data.value + 1990 }}
        </div>
      </template>
      <template v-slot:cell(lea)="data">
        <div>
          <div class="d-inline">
            <button type="button" class="btn btn-link" @click="goToLea(data.value)" :id="data.index + '-' + data.value">
              {{ leaFormatter(data.value) }}
            </button>
            &nbsp;
            <lea-tooltip :target-selector="data.index + '-' + data.value" :lea-number="data.value" />
          </div>
        </div>
      </template>
      <template v-slot:cell(districtLEA)="data">
        <div>
          <div class="d-inline">
            <button type="button" class="btn btn-link" @click="goToLea(data.value)" :id="data.index + '-' + data.value">
              {{ leaFormatter(data.value) }}
            </button>
            &nbsp;
            <lea-tooltip :target-selector="data.index + '-' + data.value" :lea-number="data.value" />
          </div>
        </div>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import PaginatedTable from '../../PaginatedTable'
import { TableMixin } from '../../TableMixin'

export default {
  mixins: [TableMixin],
  data() {
    return {
    }
  },
  props: {
    items: Array,
    fields: Array,
    name: String
  },
  computed: {
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    }
  },
  methods: {
    leaFormatter(lea) {
      if (this.leaDictionary[lea])
        return `${lea} – ${this.leaDictionary[lea].name}`

      return lea
    },
    goToLea(leaNumber) {
      const lea = this.leaDictionary[leaNumber]
      window.scrollTo(0,0)
      this.$store.dispatch('globalModule/changeSelectedLea', lea)
    }, 
    excelExportData() {
      return {
        name: this.name,
        items: this.items
      }
    }
  },
  components: {
    PaginatedTable
  }
}
</script>

<template lang="html">
  <div>
    <ul class="nav nav-pills d-block">
      <li class="ml-1 mr-1">
        <router-link :to="{ path: '/Academics/Growth/', query: { lea: selectedLea.number } }" :class="$route.name === 'growth-home' ? 'active' : ''" class="nav-link">
          <i class="fas fa-fw fa-home" /> <span class="font-weight-bold ml-1">Home</span>
        </router-link>
      </li>

      <li class="mr-2">
        <a class="nav-link ml-1">
          <i class="fa-fw fas fa-filter" /> <span class="ml-1 font-weight-bold">Filter</span>
        </a>
        <ul class="nav nav-pills">
          <li class="nav-item ml-2">
            <div class="nav-link">
              Student Cohort
              <b-form-select v-model="fiscalYear">
                <b-form-select-option v-for="year in loadedYears" :key="year" :value="year">
                  {{ year + 1990 }}
                </b-form-select-option>
              </b-form-select>
            </div>
          </li>
        </ul>
      </li>

      <li class="mr-2" v-if="selectedLea.type == LeaTypes.School">
        <a class="nav-link ml-1">
          <span class="font-weight-bold">Overview</span>
        </a>
        <ul class="nav nav-pills">
          <li class="nav-item ml-2">
            <a target="_blank" style="font-size: .9em" :href="'https://myschoolinfo.arkansas.gov/Schools/Detail/' + selectedLea.number + '?tab=reports&openreport=ESSASchoolIndex&subreport=growth&fy=' + fiscalYear" class="nav-link">
              <i class="fas fa-fw fa-external-link-alt mr-1" /> ESSA School Index
            </a>
            <router-link style="font-size: .9em" :to="{ path: '/Academics/Growth/essa-pdf', query: { lea: selectedLea.number } }" active-class="active" class="nav-link">
              <i class="fas fa-fw fa-file-pdf mr-1" /> ESSA School Index (PDF)
            </router-link>
          </li>
        </ul>
      </li>

      <li class="mr-2">
        <a class="nav-link ml-1">
          <span class="font-weight-bold">Reports</span>
        </a>
        
        <ol class="nav-pills">
          <li class="nav-item">
            <router-link :to="{ path: '/Academics/Growth/question-1', query: { lea: selectedLea.number } }" active-class="active" class="nav-link small">
              <i class="fas fa-fw fa-chart-bar" /> How does my school's growth and achievement compare with other schools in the same grade span in Arkansas?
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/Academics/Growth/question-2', query: { lea: selectedLea.number } }" active-class="active" class="nav-link small">
              <i class="fas fa-fw fa-chart-bar" /> Are my students growing as much as they were expected to grow and how does this relate to their achievement?
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/Academics/Growth/question-3', query: { lea: selectedLea.number } }" active-class="active" class="nav-link small">
              <i class="fas fa-fw fa-chart-bar" /> Are students in each readiness level growing as expected?
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ path: '/Academics/Growth/question-4', query: { lea: selectedLea.number } }" active-class="active" class="nav-link small">
              <i class="fas fa-fw fa-chart-bar" /> How have my school's average value-added growth scores changed over time?
            </router-link>
          </li>
        </ol>
      </li>

      <li class="mr-2" v-if="canViewStudentData">
        <a class="nav-link ml-1">
          <span class="font-weight-bold">Data & Trend</span>
        </a>
        <ul class="nav nav-pills d-block">
          <li class="nav-item ml-2">
            <router-link style="font-size: .9em" :to="{ path: '/Academics/Growth/studentdata', query: { lea: selectedLea.number } }" active-class="active" class="nav-link">
              <i class="fas fa-fw fa-table mr-1" /> Student Data
            </router-link>
          </li>
          <li class="nav-item ml-2">
            <router-link style="font-size: .9em" :to="{ path: '/Academics/Growth/student-trend-lines', query: { lea: selectedLea.number } }" active-class="active" class="nav-link">
              <i class="fas fa-fw fa-chart-line mr-1" /> Student Trend Lines
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { LeaTypes } from '../../../helpers/leaTypes'
import { mapState } from 'vuex'
export default {
  name: 'lea-growth-sidebar',
  data() {
    return {
      LeaTypes: LeaTypes,
    }
  },
  components: {
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    canViewStudentData() {
      return !(this.userContext.isRecruitment || this.userContext.isTeacherCenter || this.userContext.isSuperintendentCoop)
    },
    loadedYears() { return this.$store.state.academicsModule.growth.loadedyears },
    fiscalYear: {
      set(year) {
        this.$store.commit('academicsModule/growth/setSelectedYear', year)
        this.$store.commit('academicsModule/essa/setFiscalYear', year)
      },
      get() {
        return this.$store.state.academicsModule.growth.fiscalYear
      }
    }
  },
  methods: {
  }
}
</script>
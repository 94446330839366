<template>
  <div>
    <div class="card mt-3 bg-light" id="state-summary">
      <div class="card-header">
        Transportation Inspection Notification
      </div>
      <div class="card-body">
        <Promised :promise="loadData">
          <template v-slot:pending>
            <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
              <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
            </div>
          </template>
          <template v-slot="data" :class="centerContent ? 'align-items-center justify-content-center' : ''">
            <div class="row" style="margin-left:auto; margin-right:auto;">
              <span class="d-none">
                {{ data }}
              </span>
              <div v-if="BatchNotification">
                <p>Click the button below to download the {{ reportType }} report.</p>
                <b-button size="md" :href="getButtonHref">
                  Download Report
                </b-button>
              </div>
              <b-alert show variant="danger" v-else>
                <i class="fas fa-exclamation-triangle" /> This report is not available for download.  Either you do not have access, or it does not exist.
              </b-alert>
            </div>
          </template>
          <template v-slot:rejected="error" class="align-items-center justify-content-center">
            <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
          </template>
        </Promised>
      </div>
    </div>  
  </div>
</template>

<script>
import CardStateIcon from '../../CardStateIcon.vue'
import { Types } from '../../../stores/dpsaft/trans'
import { Promised } from 'vue-promised'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loadData: null
    }
  },
  props: {
    notificationId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.BatchNotification]),
    getButtonHref() {
      return `/DPSAFT/Notification/${this.notificationId}/download` 
    },
    reportType() {
      switch (this.BatchNotification.notificationType) {
        case 10: return 'Grounded Buses'
        case 20: return 'Newly Completed Inspections'
        case 30: return 'Final'
        default: return ''
      }
    }
  },
  components: {
    Promised,
    CardStateIcon
  },
  mounted() {
    this.loadData = this.$store.dispatch(Types.actions.getBatchNotification, { notificationId: this.notificationId })
  }
}
</script>

<style>

</style>
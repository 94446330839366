<template>
  <loadable-chart-card :load-data="loadData" card-style="overflow-y: auto">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { credits } from '../../../helpers/credits'
import { mapGetters } from 'vuex'
import { Types } from '../../../stores/financeModule'
import Highcharts from 'highcharts'
import sortBy from 'lodash.sortby'

export default {
  data() {
    return {
      animationDuration: 1000,
      chartOptions:  {
        chart: {
          type: 'column'
        },
        credits: credits.MSI,
        title: {
          text: ''
        },
        legend: {
          enabled: true
        },
        yAxis: [
          {
            endOnTick: false,
            max: null,
            title: {
              text: 'Amount'
            },
            tickPositions: null,
            index: 0,
          },
        ],
        xAxis: [{
          categories: [],
          index: 0,
          labels: {
            formatter: function () { return `${this.value + 1989} / ${this.value + 1990}` }
          }
        }],
        series: []
      }
    }
  },
  props: {
    category: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(Types.path, [Types.getters.asrTrendRows]),
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    },
    title() {
      return `${this.category} Trend`
    }
  },
  mixins: [ChartMixin],
  watch: {
    asrTrendRows: {
      immediate: true,
      handler(asrTrendRows) {
        if (!this.asrTrendRows || this.asrTrendRows.length == 0) 
          return
        
        this.chartOptions.series = []
        this.chartOptions.title.text = this.title
        const data = sortBy(asrTrendRows.filter(a => a.name == this.category).map(a => { 
          return {
            ...a,
            x: a.fiscalYear
          }
        }), 'x')
        this.chartOptions.series.push({ ...this.getSeries('Actual', data) })
        this.chartOptions.series.push({ ...this.getSeries('Budget', data.map(d => { 
            return { ...d, y: d.budgetValue } 
          })), 
          color: '#F2C500' })
      }
    }
  },
  methods: {
    getSeries(name, data) {
      return {
        name: name,
        data: data,
        dataLabels: {
          formatter: function () { return `$${Highcharts.numberFormat(this.y, 0)}` }
        }
      }
    },
    getFiscalYearDisplay: (fiscalYear) => `${parseInt(fiscalYear) + 1989} / ${parseInt(fiscalYear) + 1990}`,
    dataItems() {
      return this.asrTrendRows.filter(a => a.name == this.category).map(a => {
        return {
          Name: a.name,
          Actual: `$${Highcharts.numberFormat(a.y, 0)}`,
          Budget: `$${Highcharts.numberFormat(a.budgetValue, 0)}`,
          SchoolYear: this.getFiscalYearDisplay(a.fiscalYear)
        }
      })
    },
    leaFormatter(lea) {
      if (this.leaDictionary[lea])
        return `(${lea}) ${this.leaDictionary[lea].name}`

      return lea
    }
  }
}
</script>

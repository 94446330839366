<template>
  <div>
    <div class="card-body p-0 bg-light">
      <div class="row no-flex-print">
        <div class="col-6 print-full-width">
          <student-count-percent-chart :load-data="loadData" :selected-lea="selectedLea" mode="trend" key="student-count-percent-chart-trend" />
        </div>
        <div class="col-6 print-full-width">
          <virtual-frl-percent-chart :load-data="loadData" :selected-lea="selectedLea" mode="trend" key="virtual-frl-percent-chart-trend" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StudentCountPercentChart from './StudentCountPercentChart'
import VirtualFrlPercentChart from './VirtualFRLPercentChart'

import { Types } from '@/stores/onDemandModule'

export default {
  data() {
    return {
      loadData: null,
    }
  },
  props: {
  },
  components: {
    StudentCountPercentChart,
    VirtualFrlPercentChart
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if (newLea && newLea.number) {
          const payload = { lea: newLea.number }
          this.loadData = this.$store.dispatch(Types.actions.getMetricSnapshotsInstructionalOptions, payload)
        }
      }
    }
  }
}
</script>

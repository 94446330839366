<template>
  <div>
    <h2>Non-Traditional Programs of Study for Academic Year {{ academicYearDisplay }} </h2>
    <b-form-row>
      <div class="card bg-light mb-2 w-100">
        <div class="card-body">
          <b-col cols="4">
            <filter-item v-model="genderFilter" :options="Genders" label="Non Traditional Gender" />
          </b-col>
        </div>
      </div>
    </b-form-row>
    <paginated-table small :items="listItems" :fields="fields">
      <template v-slot:cell(nonTraditionalGender)="data">
        <b-form-select v-model="data.item.nonTraditionalGender" :options="[{value: null, text: '(none)'}, ...Genders]" @change="onUpdateNTG(data.item)" />
      </template>
    </paginated-table>
  </div>
</template>

<script>
import FilterItem from '../../shared/FilterItem.vue'
import PaginatedTable from '@/components/PaginatedTable'
import { Types as CommonTypes } from '@/modules/cte/stores'
import { Types as CareerCoachTypes } from '@/modules/cte/stores/careercoach'
import { mapGetters, mapState } from 'vuex'
import { Genders } from '@/modules/cte/components/careerCoach/enums'

export default {
  data() {
    return {
      genderFilter: null,
      Genders,
      fields: [
        {key: "id" },
        {key: "occupationalArea", sortable: true} ,
        {key: "cluster", sortable: true },
        {key: "pathway", sortable: true} , 
        {key: "name", sortable: true },
        {key: "nonTraditionalGender", class: "text-right" },
      ]
    }
  },
  computed: {
    academicYearDisplay() {
      const ay = Number(this.$attrs.ay)
      return `${ay+1999}-${ay+2000}`
    },
    ...mapGetters(CommonTypes.path, [CommonTypes.getters.occupationalAreaDrilldownForAY]),
    ...mapState(CommonTypes.path, [CommonTypes.state.occupationalAreaMetadata]),
    listItems() {
      var allPos = []
      const ay = Number(this.$attrs.ay)

      this.occupationalAreaDrilldownForAY(ay).forEach(oa => {
          oa.children.forEach(cluster=>{
              cluster.children.forEach(pathway=>{
                  pathway.children.forEach(pos=>{
                      var posItem = this.occupationalAreaMetadata.programsOfStudy.find(p=>p.id == pos.id)
                      if (posItem && ((!this.genderFilter) || (this.genderFilter == posItem.nonTraditionalGender)))
                      allPos.push({id: posItem.id, occupationalArea: oa.name, cluster: cluster.name, pathway: pathway.name, name: posItem.name, nonTraditionalGender: posItem.nonTraditionalGender || null })
                  })
              })
          })
      });

      return allPos
    }
  },
  methods: {
    
    onUpdateNTG(item) {
      this.$store.dispatch(CareerCoachTypes.actions.setProgramOfStudyNonTraditionalGender, {id: item.id, gender: item.nonTraditionalGender || ''})
    }
  },
  components: {
    PaginatedTable,
    FilterItem
  }
}
</script>
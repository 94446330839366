<template>
  <div>
    <info-panel />
    <div class="card outer-card">
      <lea-growth-comparison-chart v-if="leaType == LeaTypes.School" :load-data="loadData" :selected-lea="selectedLea" />
      <div class="m-3" v-else>
        <div class="alert alert-warning">
          No data available for selected LEA. Please select a school to view report
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { PageMixin } from '../../../PageMixin'
import LeaGrowthComparisonChart from './LeaGrowthComparisonChart'
import InfoPanel from './InfoPanel.vue'

export default {
  name: 'question1',
  data() {
    return {
      loadData: null
    }
  },
  components: {
    LeaGrowthComparisonChart,
    InfoPanel
  },
  mixins: [PageMixin],
  computed: {
    ...mapState('academicsModule/growth', ['growthVsAchievement']),
    fiscalYear() {
      return this.$store.state.academicsModule.growth.fiscalYear
    }
  },
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.loadData = this.$store.dispatch(
        'academicsModule/growth/getGrowthVsAchievement',
        { lea: this.selectedLea.number }
      )
    }
  },
  watch: {
    grade() {
      this.refresh()
    },
    subgroup() {
      this.refresh()
    },
    subject() {
      this.refresh()
      window.dispatchEvent(new Event('resize'))
    },
    selectedLea() {
      this.refresh()
    },
    fiscalYear() {
      this.refresh()
    }
  }
}
</script>
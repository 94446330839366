<template>
  <div class="card bg-light p-2">
    <div class="row">
      <div class="col-12">
        <div class="row" style="margin-left:auto; margin-right:auto;">
          <div class="col-md-11">
            <chart-data-toggle v-model="chartMode" @input="chartMode = $event" />
          </div>  
        </div>
        <div class="chartElem" v-show="chartMode">
          <highcharts class="chart" :options="chartOptions" />
        </div>
        <div v-show="!chartMode">
          <chart-data-table :items="dataItems()" />
        </div>
        <div class="pl-3 pr-3 pt-1">
          <dl class="row">
            <dt class="col-md-3">
              Computer Stations
            </dt>
            <dd class="col-sm-9">
              The total number of instructional computer stations. The unduplicated number of computer stations available to
              students for instruction (classrooms, media center, media cart computers moved from one class to another).
            </dd>
            <dt class="col-md-3">
              Multimedia Computer Stations
            </dt>
            <dd class="col-sm-9">
              Enter the number of instructional computer stations located within the building that have multimedia computers,
              i.e. computers that have sound cards, speakers, and CD-ROMS.
            </dd>
            <dt class="col-md-3">
              Other Devices
            </dt>
            <dd class="col-sm-9">
              The total number of equipment such as smart phones, portable media players, and other Internet mobile devices;
              interactive tablets; netbooks; e-book tools; and all other devices that can connect to the Internet for instructional
              purposes.
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ChartMixin } from '../ChartMixin'
import ChartDataToggle from '../ChartDataToggle.vue'
import { credits } from '../../helpers/credits'

function sum(prev, next){
  return prev + next;
}

const typeCategories = ['Computer Stations', 'Multimedia Computer Stations', 'Other Devices']

export default {
  components: {
    ChartDataToggle
  },
  props: {
    inputData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'bar'
        },
        title: {
          text: 'Device Building Summary'
        },
        plotOptions: {
          bar: {
            minPointLength: 1
          }
        }, 
        lang: {
          thousandsSep: ','
        },         
        yAxis: {
          title: {
            text: 'Count'
          },
          allowDecimals: false
        },
        legend: {
          enabled: false
        },
        xAxis: {
          categories: typeCategories
        },
        credits: credits.SISFacilityBuildingTable
      },
      chartMode: true
    }
  },
  mixins: [ChartMixin],
  methods: {
    dataItems() {
      return [
        { 'Device Type': 'Computer Stations', Count: this.inputData.map(r => r.computerStations).reduce(sum) },
        { 'Device Type': 'Multimedia Computer Stations', Count: this.inputData.map(r => r.multimediaStations).reduce(sum) },
        { 'Device Type': 'Other Devices', Count: this.inputData.map(r => r.otherDevices).reduce(sum) }
      ]
    }
  },
  watch: {
    inputData: {
      immediate: true,
      handler(newData) {
        this.chartOptions.series = [{
          data: [
            newData.map(r => r.computerStations).reduce(sum),
            newData.map(r => r.multimediaStations).reduce(sum),
            newData.map(r => r.otherDevices).reduce(sum)
          ],
          name: 'Computer Stations',
          colorByPoint: true
        }]
      }
    }
  }
}
</script>

<style>

</style>
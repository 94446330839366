<template>
  <div class="d-print-none">
    <b-form>
      <b-form-row>
        <b-col>
          <b-form-group label="Start Date">
            <b-form-datepicker v-model="startDate" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="End Date">
            <b-form-datepicker v-model="endDate" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <b-button :variant="filterChanged ? 'primary' : 'secondary'" @click="search" :disabled="!filterChanged" style="margin-top: 30px;">
              <i class="fa fa-search fa-fw" /> Search
            </b-button>
          </b-form-group>
        </b-col>
      </b-form-row>
    </b-form>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'closures-filter',

  data() {
    return {
      filterChanged: false,
      startDate: null,
      endDate: null
    }
  },
  computed: {
    ...mapGetters('globalModule', ['coops', 'districts']),
    ...mapState('globalModule', ['userContext']),
  },
  methods: {
    search() {
      this.filterChanged = false
      this.$emit('input', { startDate: this.startDate, endDate: this.endDate })
    }
  },
  watch: {
    startDate() { this.filterChanged = true },
    endDate() { this.filterChanged = true },
    value: {
      immediate: true,
      deep: true,
      handler(val) {
        this.startDate = val.startDate,
        this.endDate = val.endDate
      }
    }
  },
  props: {
    value: Object,
  },
}
</script>

<style>
</style>
<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode" :key="selectedLea.number">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { credits } from '../../../helpers/credits'

export default {
  name: 'enrollment-total-chart',
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'line'
        },
        credits: credits.MSI,
        title: {
          text: this.chartTitle
        },
        legend: {
          enabled: false
        },
        series: [
          {
            name: 'Total'
          }
        ],
        xAxis: {
          title: {
            text: 'School Year'
          },
          tickInterval: 1
        },
        tooltip: {
          enabled: true,
          formatter: this.tooltipFormatter,
        },
        yAxis: {
          title: {
            text: this.yAxisLabel
          },
          endOnTick: false
        }
      }
    }
  },
  mixins: [ChartMixin],
  watch: {
    enrollment(newData) {
      this.chartOptions.series[0].data = newData.map(row => {
        return {
          x: (row.fiscalYear % 1990) + 1990,
          y: row.total * this.valueMultiplier,
          ...row
        }
      })
    },
    selectedLea() {
      this.chartOptions.series[0].name = this.selectedLea.name
    }
  },
  props: {
    enrollment: Array,
    chartTitle: String,
    yAxisLabel: {
      type: String,
      default: 'Number Of Students'
    },
    valueMultiplier: {
      type: Number,
      default: 1
    },
    tooltipFormatter: {
      type: Function,
      default: function() {
        return `${this.x}<br/><span style="color:${this.series.color}">${this.series.name}</span>: <b>${this.y}</b>`
      }
    }
  },
  methods: {
    excelExportData() {
      return this.defaultExcelExportData()
    },
    dataItems() {
      return this.chartOptions.series[0].data.map(item => {
        return { SchoolYear: item.x, [this.yAxisLabel.split(' ').join('')]: item.y }
      })
    }
  }
}
</script>



import Vue from 'vue'
import { Types } from '../../../stores/onDemandModule'
import { mapState, mapGetters } from 'vuex'
import groupBy from 'lodash.groupby'
import sumBy from 'lodash.sumby'
import { unique } from '../../../helpers/globalHelpers'

const InstructionalOptionMixin = Vue.extend({
  props: {
    mode: {
      default: 'current',
      type: String
    }
  },
  methods: {
    leaFormatter(lea) {
      if (this.leaDictionary[lea])
        return `${this.leaDictionary[lea].name} (${lea})`

      return lea
    },
    setTrendedSeries(data, options) {
      this.chartOptions.series = []
      this.chartOptions.xAxis.type = 'datetime'
      this.chartOptions.xAxis.labels.enabled = true
      this.chartOptions.xAxis.labels.format = '{value:%m/%e}'
      this.chartOptions.xAxis.tickPositions = []
      let dateTickPositions = []
      options.forEach(option => {
        var groupedBySnapshotDate = groupBy(data, (o) => o.snapshotDate)
        let series = {
          name: option.name,
          data: Object.keys(groupedBySnapshotDate).map(snapshotDate => {
            const x = new Date(snapshotDate)
            dateTickPositions.push(Date.parse(snapshotDate))

            return { 
              x: x, 
              y: sumBy(groupedBySnapshotDate[snapshotDate], defaultOptionMatcher.bind(option))
            }
          }),
          tooltip: { headerFormat: '' }
        }
        if (option.color) {
          series.color = option.color
        }
        this.chartOptions.series.push(series)
      })
      this.chartOptions.xAxis.tickPositions = dateTickPositions.filter(unique)
    },
    pivotCurrent(data, options) {
      var groupedByLea = groupBy(data, (o) => { return o.lea })
      return Object.keys(groupedByLea).map(lea => {
        let row = {
          LEA: this.leaFormatter(lea)
        }
        options.forEach(option => {
          row[option.name] = sumBy(groupedByLea[lea], defaultOptionMatcher.bind(option))
        })
        return row
      })
    },
    pivotTrended(data, options) {
      var groupedByLeaDate = groupBy(data, (o) => `${o.lea}|${new Date(o.snapshotDate).toLocaleDateString()}`)
      return Object.keys(groupedByLeaDate).map(key => {
        let row = {
          LEA: this.leaFormatter(key.split('|')[0]),
          Date: key.split('|')[1]
        }
        options.forEach(option => {
          row[option.name] = sumBy(groupedByLeaDate[key], defaultOptionMatcher.bind(option))
        })
        return row
      })
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.enrollmentCounts]),
    ...mapGetters(Types.path, [Types.state.metricSnapshots]),
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    }
  }
})

function defaultOptionMatcher(item) {
  return item.group == this.value.toString() ? item.studentCount : 0 
}
export { InstructionalOptionMixin, defaultOptionMatcher }
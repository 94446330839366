<template>
  <promised :promise="loadingDashboardPromise">
    <template v-slot:pending>
      <loading-spinner />
    </template>
    <template>
      <div>
        <contact-progress class="mb-2" />
        <b-row v-if="dashboard.currentRoster.length > 0">
          <b-col lg="6" md="12">
            <act-averages md="6" class="mb-2" :show-error="!hasACTScores" />
          </b-col>
          <b-col lg="6" md="12">
            <remedial-course-enrollments md="6" class="mb-2" />
          </b-col>
        </b-row>
        <b-row v-if="dashboard.currentRoster.length > 0">
          <b-col lg="4" md="12" class="mb-2">
            <postsecondary-enrollments />
          </b-col>
          <b-col lg="4" md="12" class="mb-2">
            <industry-credentials />
          </b-col>
          <b-col lg="4" md="12" class="mb-2">
            <wbl-chart />
          </b-col>
        </b-row>
      </div>
    </template>
    <template v-slot:rejected="error">
      <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
    </template>
  </promised>
</template>

<script>
import { Types } from '../../../stores/careercoach/index.js'
import { mapState } from 'vuex'
import IndustryCredentials from '../widgets/dashboard/IndustryCredentials.vue'
import PostsecondaryEnrollments from '../widgets/dashboard/PostsecondaryEnrollments.vue'
import WblChart from '../widgets/dashboard/WblChart.vue'
import ActAverages from '../widgets/dashboard/ACTAverages.vue'
import RemedialCourseEnrollments from '../widgets/dashboard/RemedialCourseEnrollments.vue'
import ContactProgress from '../widgets/dashboard/ContactProgress.vue'
import { PageMixin } from '@/components/PageMixin'
import { LeaTypes } from '@/helpers/leaTypes'
import { Promised } from 'vue-promised'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default {
  data() {
    return {
      loadingDashboardPromise: null 
     }
  },  
  mixins: [PageMixin],
  components: {
    ActAverages,
    IndustryCredentials,
    PostsecondaryEnrollments,
    WblChart,
    RemedialCourseEnrollments,
    ContactProgress,
    Promised,
    LoadingSpinner
  },
  computed: {
    ...mapState(Types.path, [Types.state.dashboard]),
    hasACTScores() {
      return (this.selectedLea.type == LeaTypes.School || this.selectedLea.type == LeaTypes.District || this.selectedLea.type == LeaTypes.State) && this.selectedLea.number != 'MYLEAS'
    }
  },
  props: {
    ay: Number
  },
  watch: {
    selectedLea: {
      handler() {
        this.loadingDashboardPromise = this.$store.dispatch(Types.actions.getDashboard, { ay: this.ay, lea: this.selectedLea.number })
        if (this.selectedLea.number != 'MYLEAS') {
          this.$store.dispatch(Types.actions.getDashboardACT, { lea: this.selectedLea.number })
        }
      },
      immediate: true
    }
  }
}
</script>
import { LeaTypes } from '@/helpers/leaTypes'
import sumBy from 'lodash.sumby'
import groupBy from 'lodash.groupby'
import sortBy from 'lodash.sortby'

export function gradeLevelEnrollmentSheets() {
  var items = []
  var totals = {}

  var groupedByLea = groupBy(this.instructionalOptions, (o) => { return o.lea })
  var rows = Object.keys(groupedByLea).map(lea => {
    let row = { lea }
    this.gradesList.forEach(g => {
      row[this.getGradeLevel(g)] = sumBy(groupedByLea[lea], item => {
        return item.gradeLevel == g ? item.studentCount : 0 
      })
    })
    return row
  })

  switch (this.selectedLea.type)
  {
    case LeaTypes.State: 
      items = sortBy(rows, 'lea').map(k => {
        var district = this.leaDictionary[k.lea]?.parentLea
        var coop = district ? this.leaDictionary[district.number]?.parentLea : {}
        return {
          'CoOp LEA': coop?.number || '',
          'CoOp Name': coop?.name || '',
          'District LEA': district?.number || '',
          'District Name': district?.name || '',
          'School LEA': k.lea,
          'School Name': this.leaName(k.lea),
          ...this.gradeColumns(k)
        }
      })
      totals = {
        'CoOp LEA': '',
        'CoOp Name': '',
        'District LEA': '',
        'District Name': '',
        'School LEA': '',
        'School Name': 'State of Arkansas TOTAL',
        ...this.gradeTotals(rows)
      }
      var districts = groupBy(items, (i) => { return i['District LEA'] })
      var itemsDistrict = Object.keys(districts).sort((a,b) => a-b).map(districtLEA => {
        var districtRows = districts[districtLEA]
        var district = this.leaDictionary[districtLEA]
        var coop = district ? district.parentLea : {}
        return {
          'CoOp LEA': coop?.number || '',
          'CoOp Name': coop?.name || 'Unknown CoOp',
          'District LEA': district?.number || '',
          'District Name': district?.name || 'Unknown District',
          ...this.gradeTotals(districtRows)
        }
      })
      var totalsDistrict = {
        'CoOp LEA': '',
        'CoOp Name': '',
        'District LEA': '',
        'District Name': 'State of Arkansas TOTAL',
        ...this.gradeTotals(itemsDistrict)
      }
      var coops = groupBy(items, (i) => { return i['CoOp LEA'] })
      var itemsCoop = Object.keys(coops).sort((a,b) => a-b).map(coopLEA => {
         var coopRows = coops[coopLEA]
         var coop = this.leaDictionary[coopLEA]
         return {
          'CoOp LEA': coop?.number || '',
          'CoOp Name': coop?.name || 'Unknown CoOp',
          ...this.gradeTotals(coopRows)
        }
      })
      var totalsCoop = {
        'CoOp LEA': '',
        'CoOp Name': 'State of Arkansas TOTAL',
        ...this.gradeTotals(itemsCoop)
      }
      return [
        { name: 'Grade Level Enrollment School', items, totals },
        { name: 'Grade Level Enrollment District', items: itemsDistrict, totals: totalsDistrict },
        { name: 'Grade Level Enrollment CoOp', items: itemsCoop, totals: totalsCoop },
      ]
    case LeaTypes.Coop:
      items = sortBy(rows, 'lea').map(k => {
        var district = this.leaDictionary[k.lea]?.parentLea
        return {
          'District LEA': district?.number || '',
          'District Name': district?.name || '',
          'School LEA': k.lea,
          'School Name': this.leaName(k.lea),
          ...this.gradeColumns(k)
        }
      })
      totals = {
        'District LEA': '',
        'District Name': '',
        'School LEA': '',
        'School Name': `${this.selectedLea.name} TOTAL`,
        ...this.gradeTotals(rows)
      }
      districts = groupBy(items, (i) => { return i['District LEA'] })
      itemsDistrict = Object.keys(districts).sort((a,b) => a-b).map(districtLEA => {
        var districtRows = districts[districtLEA]
        var district = this.leaDictionary[districtLEA]
        var coop = district ? district.parentLea : {}
        return {
          'CoOp LEA': coop?.number || '',
          'CoOp Name': coop?.name || 'Unknown CoOp',
          'District LEA': district?.number || '',
          'District Name': district?.name || 'Unknown District',
          ...this.gradeTotals(districtRows)
        }
      })
      totalsDistrict = {
        'CoOp LEA': '',
        'CoOp Name': '',
        'District LEA': '',
        'District Name': `${this.selectedLea.name} TOTAL`,
        ...this.gradeTotals(itemsDistrict)
      }
      return [
        { name: 'Grade Level Enrollment School', items, totals },
        { name: 'Grade Level Enrollment District', items: itemsDistrict, totals: totalsDistrict }
      ]
    case LeaTypes.District:
      items = sortBy(rows, 'lea').map(k => {
        return {
          'School LEA': k.lea,
          'School Name': this.leaName(k.lea),
          ...this.gradeColumns(k)
        }
      })
      totals = {
        'School LEA': '',
        'School Name': `${this.selectedLea.name} TOTAL`,
        ...this.gradeTotals(rows)
      }
      return [{ name: 'Grade Level Enrollment School', items, totals }]
    case LeaTypes.School:
      items = sortBy(rows, 'lea').map(k => {
        return {
          'School LEA': k.lea,
          'School Name': this.leaName(k.lea),
          ...this.gradeColumns(k)
        }
      })
      return [{ name: 'Grade Level Enrollment School', items, totals }]
  }
  return []
}
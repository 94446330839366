<template>
  <div class="d-print-none">
    <div class="mb-2 card" v-if="request">
      <div class="card-header bg-primary text-white">
        Re-open for renewal / revision
      </div>
      <div class="card-body p-2">
        <div class="row">
          <div class="col-md-12">
            <!-- <b-alert variant="secondary" show>
              <small>
                <i class="fas fa-info-circle mr-2" />If you have submitted by mistake or you need to make changes before ADE DESE review, 
                you may request to withdraw your submission for modification.
              </small>
            </b-alert> -->
            <b-form-group label="Comment:">
              <b-form-textarea v-model="comment" placeholder="Please provide a comment..." />
            </b-form-group>
            <animated-button button-class="btn btn-sm btn-secondary" :promise-resolver="reopen" :disabled="!comment">
              <i class="fa fa-folder-open" />&nbsp; Re-Open
            </animated-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Types, StateMap } from '@/stores/digitalLearning'
import AnimatedButton from '@/components/AnimatedButton'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      currentState: null,
      comment: null,
      StateMap
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
  },
  props: {
    request: Object
  },
  watch: {
  },
  methods: {
    reopen() {
      const self = this
      return this.$store.dispatch(Types.actions.reOpenWaiverRequest, {
        id: this.request.waiverRequestId,
        lea: this.request.districtLEA,
        comment: this.comment
      }).then(() => {
        self.comment = null
      })
    },
  },
  components: {
    AnimatedButton,
  }
}
</script>

import { AttritionReport } from './attrition/AttritionReport'
import { AttritionReportSidebar } from './attrition/AttritionReportSidebar'
import PublicEducatorPage from './publicEducator/PublicEducatorPage.vue'
import { ExpiringLicensesPage } from './expiringLicense/ExpiringLicensesPage'
import { LicenseExceptionsPage } from './licenseException/LicenseExceptionsPage'
import TeacherEffectivenessPage from './teacherEffectiveness/TeacherEffectivenessPage.vue'
import AdditionalReportingPage from './additionalReporting/AdditionalReportingPage.vue'
import { DataIntegrityPage } from './dataIntegrity/DataIntegrityPage'
import { EmployeeLeavePage } from './employeeLeave/EmployeeLeavePage'
import { EmployabilityCheckPage } from './employabilityCheck/EmployabilityCheckPage'
import { NbctPage } from './nbct/nbctPage'
import { PredictorModelPage } from './predictorModel/predictorModel'
import { ShortageAreaAdministration } from './shortageAreas/ShortageAreaAdministration'
import { ShortageReport } from './shortageReport/ShortageReport'
import { HcptAdminPage } from './admin/HcptAdminPage'
import EmployeeSearchPage from './employeeSearch/EmployeeSearchPage.vue'
import ParaprofessionalPage from './paraprofessional/ParaprofessionalPage.vue'
import { EthicsViolationPage } from './ethicsViolation/EthicsViolationPage'


const HCPTArea = {
    ShortageAreaAdministration,
    AttritionReport,
    AttritionReportSidebar,
    PublicEducatorPage,
    ExpiringLicensesPage,
    LicenseExceptionsPage,
    TeacherEffectivenessPage,
    AdditionalReportingPage,
    DataIntegrityPage,
    EmployeeLeavePage,
    EmployabilityCheckPage,
    NbctPage,
    PredictorModelPage,
    ShortageReport,
    HcptAdminPage,
    EmployeeSearchPage,
    ParaprofessionalPage,
    EthicsViolationPage,
}

export { HCPTArea }

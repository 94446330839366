
import store from '../../../stores'
import TransportationHomePage from './TransportationHomePage.vue'
import TransportationSidebar from './sidebar'
import NotificationLandingPage from './NotificationLandingPage.vue'
import Batch from './InspectionBatch/Batch'
import {
  router
} from '../../../routes'

import InspectionsPage from './InspectionsPage'
import CreateBatch from './InspectionBatch/Create.vue'
import BusInventory from './BusInventory'

import BusInspectionHistory from './BusInspectionHistory'

import BatchList from './inspect/BatchList.vue'
import InspectionBuses from './inspect/InspectionBuses.vue'
import MobileInspectionPage from './inspect/MobileInspectionPage.vue'
import {
  InspectionBatchStatus
} from '../common/Enums'
import MobilePrep from '../common/MobilePrep'

import DistrictNotifications from './district/Notifications.vue'

router.addRoutes([
  {
    name: 'Transportation/Batches',
    path: '/transportation/batches',
    component: InspectionsPage,
    props: {
      batchStatus: null,
      districtNotificationStatus: null,
      districtAcknowledgementStatus: null,
      hideColumns: ['fiscalYear'],
      criteriaVisible: "acdf"
    }
  },
  {
    name: 'Transportation/BusList',
    path: '/transportation/buses',
    component: BusInventory,
    beforeEnter(to, from, next) {
      if (!store.state.globalModule.userContext.isADEUser) {
        next({
          name: 'Transportation/BusList/District',
          params: {
            districtLea: store.state.globalModule.userContext.startLEA
          }
        })
      } else {
        next()
      }
    }
  },
  {
    name: 'Transportation/Batch/Create',
    path: '/transportation/batch/create',
    component: CreateBatch
  },
  {
    name: 'Transportation/Bus/InspectionHistory',
    path: '/transportation/bus/:districtLea/:busId/inspections',
    component: BusInspectionHistory
  },
  {
    name: 'Transporation/InspectionPage',
    path: '/transportation/inspect',
    component: BatchList
  },
  {
    name: 'Transportation/Batch/View',
    path: '/transportation/batch/:batchId/view',
    component: Batch,
    props: {
      mode: 'view',
      readonly: true
    }
  },
  {
    name: 'get-mobile-transportation-link',
    path: '/transportation/inspect/mobile-link',
    component: MobilePrep,
    props: {
      dpsaftArea: 'transportation'
    },
  },
  {
    /* Route to the non-mobile component that allows modifications of the inspections on the batch */
    name: 'Transportation/Batch/Edit',
    path: '/transportation/batch/:batchId/edit',
    component: Batch,
    props: {
      mode: 'edit',
      readonly: false
    }
  },
  {
    /* Route to the mobile-optimized component that lists the bus inspections on the batch */
    name: 'Transportation/Batch/Inspections',
    path: '/transportation/batch/:batchId/inspections',
    component: InspectionBuses,
    props: (route) => {
      return {
        batchId: Number(route.params.batchId)
      }
    }
  },
  {
    name: 'trans-mobile-inspection-page',
    path: '/transportation/batch/:batchId/inspection/:inspectionId',
    component: MobileInspectionPage,
    props: (route) => {
      return {
        batchId: route.params.batchId,
        inspectionId: route.params.inspectionId
      }
    }
  },
  {
    path: '/transportation',
    redirect: () => { 
      if (window.userContext.isADEUser)
        return { name: 'Transportation/Batches' } 
      else
        return { name: 'Transportation/Inspections/District/Notifications/Unacknowledged', params: {districtLea: window.userContext.startLEA } }
    }
  },   
 
  //District routes
  {
    name: 'Transportation/BusList/District',
    path: '/transportation/:districtLea/buses',
    component: BusInventory,
    props: {
      hideColumns: ['districtLEA'],
      showFilter: false
    }
  },
  {
    name: 'Transportation/Inspections/District',
    path: '/transportation/:districtLea/batches/all',
    component: InspectionsPage,
    props: {
      hideColumns: ['districtLea', 'districtName', 'createdBy'],
      batchStatus: InspectionBatchStatus.Completed,
      districtNotificationStatus: true,
      districtAcknowledgementStatus: null,
      criteriaVisible: "f"
    }
  },
  {
    name: 'Transportation/Inspections/District',
    path: '/transportation/:districtLea/batches/in-progress',
    component: InspectionsPage,
    props: {
      hideColumns: ['districtLea', 'districtName', 'createdBy', 'createDate'],
      batchStatus: InspectionBatchStatus.InProgress,
      districtNotificationStatus: null,
      districtAcknowledgementStatus: null,
      criteriaVisible: "f"
    }
  },
  {
    name: 'Transportation/Inspections/District',
    path: '/transportation/:districtLea/batches/new',
    component: InspectionsPage,
    props: {
      hideColumns: ['districtLea', 'districtName', 'createdBy', 'createDate', 'districtAcknowledgedBy', 'districtAcknowledgedDate'],
      batchStatus: InspectionBatchStatus.Completed,
      districtNotificationStatus: true,
      districtAcknowledgementStatus: false,
      criteriaVisible: "f"
    }
  },
  {
    name: 'Transportation/Inspections/District/Notifications/Unacknowledged',
    path: '/transportation/:districtLea/notifications/unacknowledged',
    component: DistrictNotifications,
    props: {
      mode: 'Unacknowledged'
    }
  },  
  {
    name: 'Transportation/Inspections/District/Notifications/Acknowledged',
    path: '/transportation/:districtLea/notifications/acknowledged',
    component: DistrictNotifications,
    props: {
      mode: 'Acknowledged'
    }
  },  
])

const TransportationArea = {
  TransportationHomePage,
  TransportationSidebar,
  NotificationLandingPage
}

export {
  TransportationArea
}
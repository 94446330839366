import removeNamespace from './removeNamespace'

export const Types = {
  path: 'offline',
  state: {
    queuedActions: 'queuedActions',
    isProcessing: 'isProcessing'
  },
  mutations: {
    setIsProcessing: 'offline/setIsProcessing',
    enqueueAction: 'offline/enqueueAction',
    dequeueAction: 'offline/dequeueAction'
  },
  actions: {
    processQueuedActions: 'offline/processQueuedActions'
  }
}
const _types = removeNamespace('offline/', Types)

export class OfflineModule {
  constructor() {
  }

  namespaced = true

  state = {
    [Types.state.queuedActions]: [],
    [Types.state.isProcessing]: false
  }

  mutations = {
    [_types.mutations.setIsProcessing]: (state, isProcessing) => state.isProcessing = isProcessing,
    [_types.mutations.dequeueAction]: (state) => state.queuedActions = state.queuedActions.slice(1, state.queuedActions.length - 1),
    [_types.mutations.enqueueAction]: (state, queuedAction) => {
      if (!Array.isArray(state.queuedActions)) {
        state.queuedActions = [queuedAction]
      }
      else {
        state.queuedActions = [queuedAction, ...state.queuedActions]
      }
    }
  }

  actions = {
     [_types.actions.processQueuedActions]: async ({ commit, dispatch, state }) => {
      console.log('Resuming queued actions...')
      const resumePath = state.queuedActions.some(a => a.action.startsWith('DPSAFT/Trans')) ? 'DPSAFT/Trans' : 'DPSAFT/Facilities'
      commit(_types.mutations.setIsProcessing, true)
      try {
        for (const queuedAction of state.queuedActions.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))) {
          console.log(`Resuming queued action ${queuedAction.action} originally recorded at ${queuedAction.timestamp}`)
          await dispatch(queuedAction.action, queuedAction.payload, { root: true })
          commit(_types.mutations.dequeueAction)
        }
      }
      catch (err) {
        console.log(err)
      }
      finally {
        await dispatch(`${resumePath}/getUserBatches`, {}, { root: true }) 
        commit(_types.mutations.setIsProcessing, false)
      }
    }
  }
}
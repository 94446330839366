<template>
  <div>
    <div class="row" :class="{ 'bumpup-2': advanceStateOptions.length > 0 }" v-if="!requiresTransferJustification">
      <div class="col-12">
        <state-change-widget
          v-if="!anyStatesRequireComment && !lockedStatus"
          class="d-inline" 
          :document="plan"
          :status="plan.status"
          :available-states="availableStates"
          :state-map="StateMap"
          :transition-action="transitionAction"
          :identifiers="{ id: plan.leaPlanId, lea: plan.districtLea}"
          :use-card="false"
          :can-withdraw="canWithdraw" />
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <dl class="row mb-0 lead" style="height: 8rem;">
          <dt class="col-4">
            Superintendent:
          </dt>
          <dd class="col-8">
            {{ selectedLea.adminFirstName }} {{ selectedLea.adminLastName }} (<a :href="'mailto:' + selectedLea.adminEmailAddress">{{ selectedLea.adminEmailAddress }}</a>)
          </dd>
          <dt class="col-4">
            <!-- Blank for spacing -->
          </dt>
          <dd class="col-8">
            <!-- Blank for spacing -->
          </dd>
          <dt class="col-4">
            Phone:
          </dt>
          <dd class="col-8">
            <a href="'tel:' + selectedLea.adminPhoneNumber">{{ selectedLea.adminPhoneNumber }}</a>
          </dd>
        </dl>
      </div>
      <div class="col-6">
        <dl class="row mb-0 lead" style="height: 8rem;">
          <dt class="col-3">
            Status:
          </dt>
          <dd class="col-9 d-inline">
            <workflow-status-badge :state-map="StateMap" :status="plan.status" :document="plan" :show-label="false" />
          </dd>
          <dt class="col-3 d-print-none" v-if="currentAssignedUserId">
            Assigned to:
          </dt>
          <dd class="col-9 d-print-none" v-if="currentAssignedUserId">
            <span :title="currentAssignedUserId">{{ currentAssignedUserFullName }}</span>
          </dd>
          <dt class="col-3">
            Last Update:
          </dt>
          <dd class="col-9">
            {{ plan.updatedDate | date }}
          </dd>
        </dl>
      </div>
    </div>
    <div class="row d-print-none">
      <div class="col-12">
        <div class="w-100" :class="{ 'card-deck': (anyStatesRequireComment || canEdit) }" v-if="!requiresTransferJustification">
          <div class="card" v-if="anyStatesRequireComment">
            <div class="card-header bg-info">
              Workflow
            </div>
            <div class="card-body">
              <state-change-widget
                :document="plan"
                :status="plan.status"
                :available-states="availableStates"
                :state-map="StateMap"
                :transition-action="transitionAction"
                :identifiers="{ id: plan.leaPlanId, lea: plan.districtLea}"
                :use-card="false"
                :can-withdraw="canWithdraw" />
            </div>
          </div>
          <assign-plan-widget :request="plan" v-if="canEdit" :container-class="assignmentContainerClass" />
          <comment-plan-widget :request="plan" v-if="canComment" />
        </div>
      </div>
    </div>
    <div class="col-12" v-if="requiresTransferJustification">
      <div class="w-100">
        <div class="alert alert-warning">
          Requires justification for <a href="#transfers-section">Transfer</a> expensese before submission.
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import WorkflowStatusBadge from '@/components/workflow/WorkflowStatusBadge.vue'
import StateChangeWidget from '@/components/workflow/StateChangeWidget.vue'
import AssignPlanWidget from './AssignPlanWidget.vue'
import CommentPlanWidget from './CommentPlanWidget.vue'
import { PlanMixin } from './PlanMixin'

export default {
  data() {
    return {
    }
  },
  mixins: [PlanMixin],
  computed: {
    assignmentContainerClass() {
      return this.anyStatesRequireComment ? '' : 'w-100'
    },
  },
  methods: {
  },
  components: {
    WorkflowStatusBadge,
    StateChangeWidget,
    AssignPlanWidget,
    CommentPlanWidget,
  },
}
 
</script>

<style>
.bumpup-2{
  margin-top: -2em
}
</style>
import Vue from 'vue'

const JsonResponseMixin = Vue.extend({
  data() {
    return {
      local: this.defaultValue
    }
  },
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    edit: {
      type: Boolean,
      default: null
    },
    defaultValue: {
      type: [Object, Array],
      default: () => {}
    },    
    valid: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    local: {
      handler(newData) {
        this.$emit('input', newData)
        this.$emit('touch')
      },
      deep: true
    },
    value: {
      handler(newData) {
        if (newData) {
          const self = this
          this.local = newData
          this.$nextTick(() => self.$emit('reset'))
        }
      },
      immediate: true
    },
  }
})

export { JsonResponseMixin }
<template>
  <form>
    <reconfiguration-code-selector v-if="item" :item="item" :purpose="ReconfigurationCodePurpose.InactivateSchool" :operation="operation" />
    <div class="form-group row mb-0">
      <label class="col-3 col-form-label">Inactivating LEA:</label>
      <div class="col-md-9">
        <span class="form-control-plaintext">{{ item.selectedLea }} - {{ selectedLeaObject.name }}</span>
      </div>
    </div>
    <div class="form-group row mb-0">
      <label class="col-md-3 col-form-label">Grade Span:</label>
      <div class="col-md-9">
        <span class="form-control-plaintext">{{ gradeSpanString(item.gradeLow, item.gradeHigh) }}</span>
      </div>
    </div>
    <!-- <div class="form-group row mb-0 mb-0">
      <label class="col-md-2 col-form-label">School Type:</label>
      <div class="col-md-10">
        <span class="form-control-plaintext">{{ buildingTypeString(item) }}</span>
      </div>
    </div> -->
    <div class="form-group row mb-0">
      <label class="col-3 col-form-label">Additional Comments:</label>
      <div class="col-9">
        <div class="form-control-plaintext">
          {{ item.additionalComments }}
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex'
import { RequestItemMixin } from '../RequestItemMixIn'

export default {
  name: 'inactivate-lea-item',
  data() {
    return {
    }
  },
  components: {},
  mounted() {},
  computed: {
    ...mapState('toolsModule/leaAssignment', ['schools']),
    selectedLeaObject() {
      return this.schools.find(l => l.leaNumber == this.item.selectedLea) || {}
    }
  },
  props: {
    item: Object
  },
  mixins: [RequestItemMixin]
}
</script>

<template>
  <div v-if="selectedLea.type != LeaTypes.School">
    <div v-if="showCountiesAndCoops">
      <lea-list label="Co-ops" :leas="selectedLea.ownedLeas" anchor-id="coops" />
      <lea-list label="Counties" :leas="counties" anchor-id="counties" />
    </div>
    <div v-if="showSchools">
      <lea-list label="Schools" :leas="selectedLea.ownedLeas" anchor-id="schools">
        <template v-slot:default="slotProps">
          <lea-icon :lea="slotProps.lea" :svg-mode="false" />
          {{ slotProps.lea.name }}
          ({{ slotProps.lea.number }})
          <letter-grade-badge :lea="slotProps.lea" />
          <span style="float:right" v-if="slotProps.lea.type == LeaTypes.School">
            Serves grades {{ slotProps.lea.gradesServed || 'undefined' }},
            Principal
            {{ slotProps.lea.adminFirstName + ' ' + slotProps.lea.adminLastName || 'undefined' }}
          </span>
        </template>
      </lea-list>
    </div>
    <div v-if="showDistricts">
      <lea-list label="Districts" :leas="nonCharterDistricts" anchor-id="districts" />
      <lea-list label="Charter Districts" :leas="charterDistricts" anchor-id="charterDistricts" />
    </div>
  </div>
</template>

<script>
import { LeaTypes } from '../../helpers/leaTypes'
import LeaList from '../LeaList.vue'
import LeaIcon from '../LeaIcon.vue'
import LetterGradeBadge from './LetterGradeBadge.vue'

export default {
  data() {
    return { LeaTypes }
  },
  components: { LetterGradeBadge, LeaIcon, LeaList },
  computed: {
    selectedLea() {
      return this.$store.state.globalModule.leaContext.SelectedLea
    },
    showCountiesAndCoops() {
      return this.selectedLea.type == LeaTypes.State
    },
    showSchools() {
      return this.selectedLea.type == LeaTypes.District
    },
    showDistricts() {
      return [LeaTypes.State, LeaTypes.Coop, LeaTypes.County].includes(
        this.selectedLea.type
      )
    },
    counties() {
      return Object.keys(
        this.$store.state.globalModule.leaContext.LeaDictionary
      )
        .filter(l => l.length == 2 && l != 'AR')
        .sort()
        .map(l => this.$store.state.globalModule.leaContext.LeaDictionary[l])
    },
    districts() {
      return (this.selectedLea.ownedLeas &&
      this.selectedLea.ownedLeas.some(l => l.type == LeaTypes.District)
        ? this.selectedLea.ownedLeas.map(l => l.number)
        : Object.keys(this.$store.state.globalModule.leaContext.LeaDictionary)
      )

        .map(l => this.$store.state.globalModule.leaContext.LeaDictionary[l])
        .filter(l => l.type == LeaTypes.District)
        .sort(function(a, b) {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
    },
    charterDistricts() {
      return this.districts.filter(l => l.isCharter)
    },
    nonCharterDistricts() {
      return this.districts.filter(l => !l.isCharter)
    }
  },
  methods: {
    goToLea(leaNumber) {
      const lea = this.$store.state.globalModule.leaContext.LeaDictionary[
        leaNumber
      ]
      window.scrollTo(0, 0)
      this.$store.dispatch('globalModule/changeSelectedLea', lea)
    }
  }
}
</script>

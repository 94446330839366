const HighchartsOptions = {
    chart: {
      style: {
        fontFamily: 'Source Sans Pro'
      },
      plotBackgroundColor: '#FFFFFF',
      plotShadow: true,
      spacing: [40, 30, 45, 30],
      backgroundColor: '#f8f9fa'
    },
    lang: {
      thousandsSep: ',',
      numericSymbols:  ['k', 'M', 'B', 'T', 'P', 'E']
    },
    time: {
      useUTC: false
    },
    /* http://colorbrewer2.org/#type=qualitative&scheme=Paired&n=12 */
    //colors: ['#a6cee3','#1f78b4','#b2df8a','#33a02c','#fb9a99','#e31a1c','#fdbf6f','#ff7f00','#cab2d6','#6a3d9a','#ffff99','#b15928'],
    /* highcharts 5.x */
    colors: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'],
    navigation: {
      buttonOptions: {
        theme: {
          fill: '#f8f9fa'
        }
      }
    },
    exporting: {
      chartOptions: {
        chart: {
          backgroundColor: '#ffffff'
        }
      }
    }
}

export { HighchartsOptions }
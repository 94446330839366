<template>
  <div>
    Building Listing
    <building-list :items="SelectedBatchBuildingList" :allow-item-check="false" :show-batch-status="true" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BuildingList from '../../common/BuildingList'

export default {
  data() {
    return {}
  },
  methods: {},
  props: {},
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapGetters('DPSAFT/Facilities', ['SelectedBatchBuildingList']),
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    }
  },
  components: {
    BuildingList
  }
}
</script>
<template>
  <span>{{ item[countProp] }} ( {{ item[percentProp] | toPercentage(1, true) }}) </span>
</template>

<script>
export default {
  computed: {
    percentReached() {
      return this.enrollment
    }
  },
  props: {
    item: Object,
    countProp: String,
    percentProp: String
  }
}
</script>
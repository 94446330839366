<template>
  <div>
    <b-row v-if="mode === 'list'">
      <b-col>
        <b-table 
          :fields="fields"
          caption="Certifications" 
          :caption-top="true" 
          :items="local.certifications">
          <template #head(action)>
            <b-btn v-if="!currentStudent.isReadOnly" variant="primary" @click="addNew">
              <i class="fa fa-plus-circle fa-fw" />
              New Certification
            </b-btn>
          </template>
          <template #cell(action)="data">
            <b-btn v-if="!currentStudent.isReadOnly" variant="primary" size="xs" @click="editCertification(data.item)" class="mr-2">
              <i class="far fa-edit fa-fw" />
            </b-btn>
            <b-btn v-if="!currentStudent.isReadOnly" variant="danger" size="xs" @click="removeItem(data)">
              <i class="far fa-times-circle fa-fw" />
            </b-btn>
          </template>          
          <template #cell(date)="data">
            {{ data.value | date('short-date') }}
          </template>          
        </b-table>
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col cols="8" offset="2">
        <b-alert variant="success" :show="certSaved" dismissible @dismissed="certSaved = false">
          Certification Saved
        </b-alert>
        <b-form>
          <b-form-row>
            <b-col class="pr-3">
              <b-form-group label="School Year">
                <b-form-select :options="availableAYs" v-model="schoolYear" />
              </b-form-group>
            </b-col>            
            <b-col class="pr-3">
              <b-form-group label="Grade Level Taken">
                <b-form-select v-model="gradeLevel" :options="careerCoachGrades" />
              </b-form-group>
            </b-col>
            <b-col class="pr-3">
              <b-form-group label="Date Attempted">
                <!-- <b-form-datepicker v-model="dateAttempted" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric'}" /> -->
                <b-input-group class="mb-3">
                  <b-form-input
                    id="date-input"
                    type="text"
                    v-model="textDate"
                    placeholder="MM/DD/YYYY"
                    autocomplete="off"
                    @blur="onDateChanged"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="dateAttempted"
                      button-only
                      right
                      locale="en-US"
                      :date-format-options="{year: 'numeric', month: '2-digit', day: '2-digit'}"
                      aria-controls="date-input"
                      @context="onContext"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-col cols="6">
              <b-form-group label="Certification Name">
                <b-form-select v-model="certificationId" :options="certificationOptions" />
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col v-if="this.mode == 'edit'">
              <certification-files :files="files" :student-info-id="currentStudent.studentId" :certification-id="currentCertificationId" rows="5" max-rows="100" :readonly="false" @file-uploaded="onFileUploaded" />
            </b-col>
          </b-form-row>

          <b-form-row class="mt-4">
            <b-col class="text-center">
              <b-btn variant="primary" class="mr-2" @click="saveCertification">
                <i class="fa fa-save fa-fw" /> Save
              </b-btn>
              <b-btn variant="secondary" @click="cancelSave">
                <i class="fa fa-times fa-fw" /> Return to List
              </b-btn>
            </b-col>
          </b-form-row>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import shortid from 'shortid'
import { gradeSpans } from '@/helpers/gradeSpans'
import { metadata as staticMetadata } from '@/modules/cte/stores/careercoach/metadata'
import { mapState } from 'vuex'
import { Types } from '@/modules/cte/stores/careercoach'
import { StudentComponentMixin } from './StudentComponentMixin'
import CertificationFiles from './CertificationFiles.vue'

export default {
  components: { CertificationFiles },
  mixins: [StudentComponentMixin],
  computed: {
    ...mapState (Types.path, [Types.state.metadata, Types.state.currentStudent]),
    ...mapState('globalModule', ['userContext']),
    careerCoachGrades() {
      return gradeSpans.filter(g => g.value >= 7 && g.value <= 12)
    },
    certificationOptions() {
      // sort alphabetically ascending, but make sure '(' comes last.
      var data = this.metadata.certifications.map(c=> { return {value: c.certificationId, text: c.name}}).sort((a,b)=>a.text.replace('(','z').localeCompare(b.text.replace('(','z')))
      
      // only return values that don't start with '(Deleted)'.  Keep the deleted ones in the list if the certificationId matches this.certificationId
      data = data.filter(c=>!c.text.startsWith('(Deleted)') || c.value == this.certificationId)

      return data
    },
    localFiles(){
      if(this.local && this.local.files){
        return this.local.files
      }
      return []
    },    
    availableAYs(){
      var currentYear = this.metadata.currentRosterAcademicYear
      return [
        {text: '--Please select an option--', value: null, disabled: true},
        {text: `${currentYear+1999}/${currentYear+2000}`, value: currentYear},
        {text: `${currentYear+1998}/${currentYear+1999}`, value: currentYear-1},
        {text: `${currentYear+1997}/${currentYear+1998}`, value: currentYear-2},
        {text: `${currentYear+1996}/${currentYear+1997}`, value: currentYear-3},
        {text: `${currentYear+1995}/${currentYear+1996}`, value: currentYear-4}
      ]
    }
  },
  data() {
    return {
      staticMetadata,
      mode: 'list',
      currentCertificationId: null,
      certificationId: null,
      passFail: null,
      textDate: null,
      dateAttempted: new Date(),
      gradeLevel: null,
      schoolYear: null,
      score: null,
      files: [],
      fields: [
        { key: 'schoolYear', formatter: (v)=>Number(v)+2000},
        'grade',
        { key: 'certificationId', label: 'Certification', formatter: (f) => { return (this.metadata.certifications || []).find(c=>c.certificationId == f) ? this.metadata.certifications.find(c=>c.certificationId == f).name : 'N/A' } },
        'date',
        { key: 'lastUser', class: 'text-center' },
        { key: 'action', class: 'text-right' },
      ],
      certSaved: false
    }
  },
  methods: {
    onDateChanged() {
      console.log(this.textDate)
      this.dateAttempted = new Date(this.textDate)
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.textDate = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD
    },
    addNew() {
      this.currentCertificationId = null
      this.certificationId = null
      this.dateAttempted= new Date()
      this.gradeLevel= null
      this.schoolYear= null
      this.files = []
      this.certSaved = false      
      this.mode = 'new'
    },
    editCertification(dataItem) {
      var item = this.local.certifications.find(v => v.id === dataItem.id)

      if (item) {
        this.currentCertificationId = item.id
        this.schoolYear = item.schoolYear
        this.gradeLevel = item.grade
        this.dateAttempted = item.date
        this.certificationId = item.certificationId
        this.notes = item.notes
        this.mode = 'edit'
        this.certSaved = false
        this.files = item.files
        this.studentCertId = item.id
      }
    },
    onFileUploaded(files){
      //debugger
      this.local.files = files
      this.local.certifications.find(v => v.id === this.currentCertificationId).files = files
    },
    removeCertification(id) {
      var index = this.local.certifications.findIndex(v => v.id == id)
      if (index >= 0) this.local.certifications.splice(index, 1)
      return this.save('CertificationsResponse')
    },
    saveCertification() {
      let certification = { lastUser: (this.userContext || {}).fullName, lastUserId: (this.userContext || {}).userId }

      if (this.mode === 'new') {
        certification = { grade: this.gradeLevel, date: this.dateAttempted, certificationId: this.certificationId, schoolYear: this.schoolYear, pass: this.passFail, score: this.score, files: this.files, lastUser: (this.userContext || {}).fullName, lastUserId: (this.userContext || {}).userId }
        this.addCertification(certification)
        this.currentCertificationId = certification.id
        this.mode = 'edit'
      } 
      else if (this.mode === 'edit') {
        certification = this.local.certifications.find(v=>v.id === this.currentCertificationId)
        certification.grade = this.gradeLevel
        certification.date = this.dateAttempted
        certification.certificationId = this.certificationId
        certification.schoolYear = this.schoolYear
        certification.score = this.score
      }
      this.certSaved = true
      return this.save('CertificationsResponse')
    },
    addCertification(item) {
      item.id = shortid.generate()
      this.local.certifications.push(item)
    },
    cancelSave() {
      this.mode = 'list'
    },
    async removeItem(dataItem) {
      let result = await this.$bvModal.msgBoxConfirm("Delete this certification?")

      if (result) {
        this.removeCertification(dataItem.item.id)
      }
     
    },
  }
}
</script>
<template>
  <step-container :academic-year="Number($route.params.ay)" task-code="OAM" title="Cluster Management" :hide-step-status="mode != 'view'">
    <promised :promise="loadData">
      <template v-slot:pending>
        <div class="text-center">
          &nbsp;<i class="fa-4x fas fa-spinner fa-spin text-secondary" />
        </div>
      </template>
      <template>
        <b-tabs card>
          <b-tab title="Clusters">
            <clusters-widget @on-editing="mode='edit'" @on-edit-complete="mode='view'" />
          </b-tab>
          <b-tab title="Pathways">
            <pathways-widget @on-editing="mode='edit'" @on-edit-complete="mode='view'" />
          </b-tab>
          <b-tab title="Programs of Study">
            <pos-widget @on-editing="mode='edit'" @on-edit-complete="mode='view'" />
          </b-tab>
          <b-tab title="Relationships">
            <relationships-widget @on-editing="mode='edit'" @on-edit-complete="mode='view'" />
          </b-tab>
        </b-tabs>
      </template>
    </promised>
  </step-container>
</template>

<script>
import StepContainer from '../StepContainer'
import ClustersWidget from './clustersTab/Widget'
import PathwaysWidget from './pathwaysTab/Widget'
import PosWidget from './posTab/Widget'
import RelationshipsWidget from './relationshipsTab/Widget'
import { Promised } from 'vue-promised'
import { Types as CommonTypes } from '@/modules/cte/stores'

export default {
  data() {
    return {
      mode: 'view',
      loadData: null
    }
  },
  mounted() {
    this.loadData = this.$store.dispatch(CommonTypes.actions.getOAMetadata, { ay: this.$route.params.ay })
  },
  components: {
    StepContainer,
    ClustersWidget,
    PathwaysWidget,
    PosWidget,
    RelationshipsWidget,
    Promised
  }
}
</script>
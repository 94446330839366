<template>
  <div>
    <h2 class="caption">
      Initial Needs Assessment
    </h2>
    <b-form>
      <b-form-row>
        <b-col>
          Please check all of the workshops and activities you believe would be helpful for you
        </b-col>
      </b-form-row>
      <b-form-row v-if="metadata.workshopAndActivities">
        <b-form-group>
          <b-form-checkbox-group 
            v-model="value.selectedActivities"
            :options="options"
            name="selectedActivities"
            class="p-1"
            style="column-count:4"
            stacked
            disabled-field="notEnabled"
          />
        </b-form-group>
      </b-form-row>    
    </b-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Types } from '@/modules/cte/stores/careercoach'
export default {
  data() {
    return {
      itemsPerColumn: 5
    }
  },
  computed: {
    ...mapState (Types.path, [Types.state.metadata]),
    options() {
      return this.metadata.workshopAndActivities.map(d => {
        return { value: d.workshopAndActivityId, text: d.description, notEnabled: this.readonly }
      })
    }
  },
  props: {
    value: Object,
    readonly: Boolean
  },
  watch: {
    value: {
      deep: true,
      handler(newData) {
        this.$emit('input', newData)
      } 
    }
  }
}
</script>
<template>
  <div class="h-100 w-100">
    <highcharts class="chart" :options="chartOptions" />
  </div>
</template>

<script>
import { ChartMixin } from '@/components/ChartMixin'
import Highcharts from 'highcharts'
import sumBy from 'lodash.sumby'

export default {
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'pie',
          margin: 0,
          height: 300,
          spacing: [0, 0, 0, 0],
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '75%'],
            // size: '130%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              // distance: -50,
              formatter: function() {
                return `<b>${this.point.name}</b>:<br>${Highcharts.numberFormat(this.point.percentage)} %<br>Total: $${Highcharts.numberFormat(this.point.y, 2)}`
              }
            },
            borderColor: '#6c757d'
          },
        },
        title: {
          text: null
        },
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        lang: {
          thousandsSep: ','
        },
        series: [{
          name: 'Applications Budget Breakdown',
          colorByPoint: true,
        }],
        xAxis: {
          title: {
            text: 'Amount'
          },
          type: 'category'
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          formatter: function() {
            return `<b>${this.point.name}</b>:<br>${Highcharts.numberFormat(this.point.percentage)} %<br>Total: $${Highcharts.numberFormat(this.point.y, 2)}`
          }
        },
        yAxis: {
          title: {
            text: this.yAxisLabel
          },
          endOnTick: false,
          labels: {
            formatter: this.yAxisFormatter
          }
        },
      }
    }
  },
  mixins: [ChartMixin],
  watch: {
    applications: {
      immediate: true,
      handler() {
        this.chartOptions.series = []
        this.chartOptions.series = [{ 
          innerSize: '50%', 
          colorByPoint: true, 
          data: [
            {
              name: 'Budgeted Approved',
              y: this.budgetedApproved || 0,
              color: '#28a745'
            },
            {
              name: 'Budgeted Pending',
              y: this.budgetedPending || 0,
              color: '#6c757d'
            },
            {
              name: 'Amount Remaining',
              y: this.amountRemaining || 0,
              color: '#EDEDED'
            }
          ]
        }]
      }
    }
  },
  props: {
    yAxisLabel: {
      type: String,
      default: 'Amount'
    },
    applications: Array,
  },
  computed: {
    amountAwarded() { return this.applications.length > 0 ? sumBy(this.applications, 'amountAwarded') : 0 },
    budgetedPending() { return this.applications.length > 0 ? sumBy(this.applications, 'amountBudgetedPending') : 0 },
    budgetedApproved() { return this.applications.length > 0 ? sumBy(this.applications, 'amountBudgetedApproved') : 0 },
    amountRemaining() { return this.amountAwarded - (this.budgetedPending + this.budgetedApproved) }
  },
}
</script>

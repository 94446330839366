<template>
  <div>
    <b-card variant="primary" no-body>
      <b-tabs content-class="mt-3" tabs card>
        <b-tab title="Areas">
          <div class="d-flex">
            <div class="flex-grow-1 px-4">
              <div class="map-container">
                <img src="/Content/dpsaft/areas.png">
              </div>
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th>Area</th>
                      <th>Planning / Construction</th>
                      <th>Maintenance</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="area in activeAreas" :key="area.areaId">
                      <td>{{ area.name }}</td>
                      <td>{{ getManagerName(area.facilityPlanMgrId) }}</td>
                      <td>{{ getManagerName(area.facilityMaintMgrId) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="District Contacts">
          <contact-list />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<style lang="scss">
.map-container {
  height: 300px;
  text-align: center;

  img {
    height: 280px;
  }
}
</style>

<script>
import { mapState, mapGetters } from 'vuex'
import ContactList from '../../dpsaft/common/ContactList'
export default {
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState('DPSAFT', ['areas', 'areaManagers']),
    ...mapGetters('globalModule', ['leaList']),
    activeAreas() {
      return this.areas.filter(a => !a.inactiveDate)
    }
  },
  components: {
    ContactList
  },
  created() {
    this.$store.dispatch('DPSAFT/getAreaManagers').then(() => {
      this.$store.dispatch('DPSAFT/getAreas')
    })
  },
  methods: {
    getManagerName(userId) {
      const managers = (this.areaManagers.facilitiesMaintenanceManagers || []).concat(this.areaManagers.facilitiesPlanningAndConstructionManagers || [])
      return (managers.find(a => a.userId == userId) || { fullName: 'Unassigned' }).fullName
    },
    getCoopName(lea) {
      return this.leaList.ownedLeas.filter(l => l.number == lea)[0]?.name || 'Unknown CoOp'
    }
  }
}
</script>


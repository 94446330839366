import ApiService from '../../services/ApiService'

export class ESSA {

  constructor() {
    this._apiService = new ApiService()
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
  }

  namespaced = true

  state = {
    essaData: [],
    overviewFilterState: {
        gradeSpan: null,
        letterGrade: null,
        name: null
    },
    overviewFiltersReady: false,
    fiscalYear: null
  }

  mutations = {
    setEssaData(state, data) {
      state.essaData = Array.from(data)
    },
    setOverviewFilterState(state, data){
        state.overviewFilterState = data
        state.overviewFiltersReady = true
    },
    setFiscalYear(state, data) {
      state.fiscalYear = data
    }
  }

  getters = {
    overviewFilters: (state) => {
      return state.overviewFilterState 
    },
    filteredData: (state) => (filter) => {
      const nameRegex = new RegExp(`^.*${filter.name}.*$`, 'i')
      return state.essaData.filter(row => {
        var passes = true

        if (passes && filter.name) {
          passes = nameRegex.test(row.schoolName)
        }

        if (passes && filter.letterGrade) {
          passes = row.schoolLetterGrade === filter.letterGrade
        }

        if (passes && filter.gradeSpan) {
          passes = row.gradeSpan === filter.gradeSpan
        }

        return passes
      })
    }
  }

  actions = {
    getEssaAcademicsInformation: ({
      commit
    }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/academics/${payload.lea}/${payload.fy}`, payload.filters))
        .then(data => commit('setEssaData', data))
    },
  }
}


<template>
  <b-form @submit.prevent>
    <b-form-group label="Proposal Type">
      <b-form-select v-model="local.proposalType" :options="proposalTypes" :disabled="isReadonly" :state="local.proposalType > 0" @input="proposalTypeChanged" />
      <b-form-invalid-feedback id="new-lea-input">
        You must select a program type before proceeding.
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Proposal Title">
      <b-form-input v-model="local.title" :disabled="isReadonly" />
    </b-form-group>
    <div class="alert alert-warning" v-if="!proposalTypeIsAllPrograms && selectedApplication.coverPage.applicationCoverPageClusters.length < 1">
      No programs of study have been added to the cover page.  Please add at least one program of study to the cover page before proceeding.
    </div>
    <b-form-row v-else-if="!proposalTypeIsAllPrograms">
      <b-col>
        <b-form-group label="Cluster">
          <b-form-select v-model="local.clusterId" :options="clusters" :disabled="isReadonly || proposalTypeIsAllPrograms" @input="clusterChanged" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Pathway">
          <b-form-select v-model="local.pathwayId" :options="pathways" :disabled="isReadonly || proposalTypeIsAllPrograms" @input="pathwayChanged" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Program of Study">
          <b-form-select v-model="local.programOfStudyId" :options="programsOfStudy" :disabled="isReadonly || proposalTypeIsAllPrograms" />
        </b-form-group>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import { Types as CommonTypes } from '@/modules/cte/stores'
import { Types } from '@/modules/cte/stores/perkinsv'
import { mapState, mapGetters } from 'vuex'
import { ProposalTypes } from '../../enums'
import { JsonResponseMixin } from '@/components/JsonResponseMixin'
import uniqBy from 'lodash.uniqby'

export default {
  mixins: [JsonResponseMixin],
  data() {
    return {
      
    }
  },
  props: {
    mode: {
      type: String,
      default: 'edit'
    },
    isReadonly: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.selectedProposal, Types.state.selectedApplication]),
    ...mapGetters(CommonTypes.path, [CommonTypes.getters.occupationalAreaDrilldownForAY]),
    clusters() {
      var filteredClusters = this.selectedApplication.coverPage.applicationCoverPageClusters.map(c => c.clusterId)
      return uniqBy([{value: null, text: '(All Clusters)'}, ...this.occupationalAreaDrilldownForAY(this.selectedApplication.ay)
                                                             .flatMap(i => i.children)
                                                             .filter(i => filteredClusters.includes(i.id))
                                                             .map(ch => {return { value: ch.id, text: ch.name}})
      ], 'value')
    },
    cluster() { return this.occupationalAreaDrilldownForAY(this.selectedApplication.ay).flatMap(i => i.children).find(ch => ch.id == this.local.clusterId) },
    pathways() {
      var filteredPathways = [...new Set(this.selectedApplication.coverPage.applicationCoverPageClusters.map(c => c.pathwayId))]
      return uniqBy([{value: null, text: '(All Pathways)'}, ...(this.cluster?.children || [])
                                                          .filter(i => filteredPathways.includes(i.id))
                                                          .map(ch => { return { value: ch.id, text: ch.name}})
      ], 'value')
    },
    pathway() { return this.cluster ? this.cluster.children.find(ch => ch.id == this.local.pathwayId) : null},
    programsOfStudy() {
      var filteredProgramsOfStudy = [...new Set(this.selectedApplication.coverPage.applicationCoverPageClusters.map(c => c.programOfStudyId))]
      return uniqBy([{value: null, text: '(All Programs of Study)'}, ...(this.pathway?.children || [])
                                                          .filter(i => filteredProgramsOfStudy.includes(i.id))
                                                          .map(ch => {return {value: ch.id, text: ch.name}})
      ], 'value')                                                        
    },
    programOfStudy() { return this.pathway ? this.pathway.children.find(ch => ch.id == this.local.programOfStudyId) : null },
    proposalType() {
      return (this.local || {}).proposalType
    },
    proposalTypeIsAllPrograms() {
      if (this.proposalType == 0) return true
      return ProposalTypes.filter(p => p.text.includes('All Program')).some(p => p.value == this.proposalType)
    },
    proposalTypes() {
      if (this.local.proposalId) {
        return ProposalTypes.filter(p => p.value > 0)
      }
      return ProposalTypes
    }
  },
  methods: {
    proposalTypeChanged() {
      this.$set(this.local, 'clusterId', null)
      this.clusterChanged()
    },
    clusterChanged() {
      this.$set(this.local, 'pathwayId', null)
      this.pathwayChanged()
    },
    pathwayChanged() {
      this.$set(this.local, 'programOfStudyId', null)
    }
  },
  watch: {
    proposalType() {
      if (this.proposalTypeIsAllPrograms) {
        this.proposalTypeChanged()
      }
    },
    selectedApplication: {
      immediate: true,
      handler(newApplication) {
        if (Object.keys(newApplication).length)
          this.$store.dispatch(CommonTypes.actions.getOAMetadata, { ay: newApplication.ay })
      }
    }
  }
}
</script>

<template>
  <div>
    <div v-if="activeAcademicYear">
      <perkins-v-user-widget />
    </div>
    <div v-else class="text-center">
      <b-alert show variant="warning">
        No Academic Years are currently open for User Administration
      </b-alert>
    </div>
  </div>
</template>

<script>
import PerkinsVUserWidget from './PerkinsVUserWidget.vue'
import { Types } from '@/modules/cte/stores/perkinsv'
import { mapGetters } from 'vuex'

export default {
  components: {
    PerkinsVUserWidget
  }, 
  computed: {
    ...mapGetters(Types.path, [Types.getters.activeAcademicYear]),
  }
}
</script>

import Vue from 'vue'
import { DataIntegrityWidget } from './DataIntegrityWidget'
import { PageMixin } from '../../PageMixin'

const DataIntegrityPage = Vue.extend({
    name: 'data-integrity-page',
    data() {
        return {
        }
    },
    mixins: [PageMixin],
    components: {
        DataIntegrityWidget,
    }   
})
export { DataIntegrityPage }
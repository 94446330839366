<template>
  <div>
    <div v-if="!validContext">
      <b-alert variant="warning" show>
        You are not authorized to create LEA Assignment requests for <em>{{ leaContext.SelectedLea.name }}</em>.  <span v-if="leaContext.SelectedLea.type == 3"> Only Charter Office Administrators can create requests at the State level.</span>
        
        <p class="mt-2">
          Please select an LEA for which you are authorized to create LEA Assignment requests from the Context Chooser to continue.
        </p>
      </b-alert>
    </div>
    <div v-else>
      <h2>
        What type of change would you like to request?
      </h2>
      <b-alert variant="warning" show v-if="userContext.hasRole(LeaAssignmentRoles.CharterOfficeAdmin)">
        As a Charter Office Administrator, you can request additions of new Open Enrollment Charter School Districts, or you can request changes to existing Open Enrollment Charter School Districts.
        <ul class="list-unstyled mt-2">
          <li>To add an OECS district, you must select 'State of Arkansas' in the top-right LEA context chooser.</li>
          <li>To request changes to existing districts, first select an OECS district from the LEA Chooser.</li>
        </ul>
      </b-alert>
      <b-card header="District Level Changes" class="mb-3">
        <b-row>
          <b-col cols="3" class="text-right">
            <b-btn variant="primary" :to="{name: 'create-new-request', params: {purpose: RequestOperationPurpose.ChangeDistrict }}" :disabled="!allowDistrictOperations">
              Change District Name or Address
            </b-btn>
          </b-col>
          <b-col>
            Choose this option to request changes to your district's address or name. No building-level changes are included here.
          </b-col>
        </b-row>
        <b-row v-if="userContext.hasRole(LeaAssignmentRoles.CharterOfficeAdmin)" class="mt-2">
          <b-col cols="3" class="text-right">
            <b-btn variant="primary" :to="{name: 'create-new-request', params: {purpose: RequestOperationPurpose.NewDistrict }, query: {charter: true}}" :disabled="!allowNewCharterDistrict">
              New Charter School District
            </b-btn>
          </b-col>
          <b-col>
            Choose this option to create a new Charter School District.  The new request will have initial items to create the new district and one new school, but you can add as many schools as necessary to complete the request.
          </b-col>
        </b-row>
      </b-card>
      <b-card header="Building Level Changes">
        <b-row>
          <b-col cols="3" class="text-right">
            <b-btn variant="primary" :to="{name: 'create-new-request', params: {purpose: RequestOperationPurpose.BuildingAnnexation}}" :disabled="!allowDistrictOperations">
              Annexation
            </b-btn>
          </b-col>
          <b-col>
            Choose this option when one existing building is absorbing another building and the building being absorbed is slated to close. 
            You will be required to select the LEA to close and you will have the opportunity to specify any necessary changes to the LEA taking over the closed LEA's enrollment. (e.g Grade Span Changes, etc.)
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="3" class="text-right">
            <b-btn variant="primary" :to="{name: 'create-new-request', params: {purpose: RequestOperationPurpose.BuildingCombination}}" :disabled="!allowDistrictOperations">
              Consolidation
            </b-btn>
          </b-col>
          <b-col>
            Choose this option when two existing building are being combined into a <i>new</i> LEA.  The original LEAs will be closed and a new building will be created to take over the enrollment for the closed LEAs.
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="3" class="text-right">
            <b-btn variant="primary" :to="{name: 'create-new-request', params: {purpose: RequestOperationPurpose.DistrictReconfiguration}}" :disabled="!allowDistrictOperations">
              District Reconfiguration
            </b-btn>
          </b-col>
          <b-col>
            Choose this option when you need to reconfigure buildings in your district. You will have the opportunity to open new LEAs, close existing LEAs, or request changes to LEAs.  You can request changes to multiple LEAs on one request, but you are limited to opening one LEA and/or closing one LEA per request.
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="3" class="text-right">
            <b-btn variant="primary" :to="{name: 'create-new-request', params: {purpose: RequestOperationPurpose.ChangeBuildingLEA}}" :disabled="!allowDistrictOperations">
              Change Building LEA
            </b-btn>
          </b-col>
          <b-col>
            Choose this option when you need to change the LEA number of an existing LEA.  This will require you to specify the LEA being closed as well as enter updated information for the LEA being opened.
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="3" class="text-right">
            <b-btn variant="primary" :to="{name: 'create-new-request', params: {purpose: RequestOperationPurpose.General}}" :disabled="!allowDistrictOperations">
              Other Changes
            </b-btn>
          </b-col>
          <b-col>
            Choose this option when the options above do not apply to your needs, and you need to add, change, or close LEAs for any reason NOT related to reconfiguring your district.
          </b-col>
        </b-row>  
      </b-card>
    </div>
  </div>
</template>
<script>

import { RequestOperationPurpose, LeaAssignmentRoles } from '../../leaAssignment'
import { mapState, mapGetters } from 'vuex'
export default {
  data() {
    return {
      RequestOperationPurpose,
      LeaAssignmentRoles
    }
  },
  computed: {
    ...mapGetters('globalModule', ['leaContext']),
    ...mapState('globalModule', ['userContext']),
    validContext() {
      return this.leaContext.SelectedLea.type == 2 || 
              (this.leaContext.SelectedLea.type == 3 && this.userContext.hasRole(LeaAssignmentRoles.CharterOfficeAdmin))
    },
    allowDistrictOperations() {
      
        //to operate on a district the user must be a state charter school office administrator and the district must be a charter district OR
        //the user is a district user FOR THE SELECTE DISTRICT and the district is NOT a charter school district
        return (this.userContext.hasRole(LeaAssignmentRoles.CharterOfficeAdmin) && this.leaContext.SelectedLea.type == 2 && this.leaContext.SelectedLea.isCharter) ||
              (this.userContext.startLEA == this.leaContext.SelectedLea.number && this.leaContext.SelectedLea.type == 2) ||
              (this.userContext.hasRole(LeaAssignmentRoles.SMSAdmin) && this.leaContext.SelectedLea.type == 2)
    },
    allowNewCharterDistrict() {
        return this.userContext.hasRole(LeaAssignmentRoles.CharterOfficeAdmin) && this.leaContext.SelectedLea.type == 3
    }
  },
}
</script>
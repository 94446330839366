<template>  
  <span>
    <span v-if="label" :id="elementId">
      <span v-html="label" :class="labelClass" />&nbsp;
      <i class="fas fa-info-circle" :class="iconClass" />
    </span>
    <span v-else :id="elementId" class="fas fa-info-circle" :class="iconClass" />
    <b-tooltip :target="elementId" custom-class="wideTooltip" :variant="variant">
      <slot>
        <div v-html="content" />
      </slot>
    </b-tooltip>
  </span>
</template>

<script>
export default {
  name: 'custom-tooltip',
  props:{
    iconClass: {
      type: String,
      default: 'fa text-info'
    },
    elementId: {
      type: String,
      default: 'tooltip1'
    },
    variant: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: 'Tooltip Default Text'
    },
    label: {
      type: String
    },
    labelClass: {
      type: String
    }
  }
}
</script>
<style>
  .tooltip-inner{
    max-width: 100% !important;
  }
</style>
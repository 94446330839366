<template>
  <div>
    <paginated-table :items="selectedProposalCostItems" :fields="fields">
      <template v-slot:head(action)>
        <b-button :to="{ name: 'CTE/PerkinsV/Application/Proposal/CostItem/Create' }" variant="primary" v-if="selectedApplicationIsForActiveAY && !isReadonly">
          <i class="fa fa-plus fa-fw" /> New Item
        </b-button>
      </template>
      <template v-slot:cell(type)="data">
        {{ costTypeDescription(data.item.type) }} ({{ data.item.type }})
      </template>
      <template v-slot:cell(item)="data">
        {{ data.item.quantity }} x {{ data.item.costPerUnit | currency }} 
      </template>
      <template v-slot:cell(total)="data">
        {{ data.item.quantity * data.item.costPerUnit | currency }} 
      </template>
      <template v-slot:cell(other)="data">
        {{ data.value | currency }} 
      </template>
      <template v-slot:cell(perkins)="data">
        {{ data.item.perkins | currency }}
      </template>
      <template v-slot:cell(action)="data" v-if="!isReadonly">
        <b-link :to="{ name: 'CTE/PerkinsV/Application/Proposal/CostItem/Edit', params: {applicationId: $route.params.applicationId, proposalId: data.item.proposalId, costItemId: data.item.proposalCostId, applicationLea: data.item.lea }}" title="Edit Item" @click="$emit('on-edit-initiated')">
          <i class="fas fa-fw fa-edit" />
        </b-link>
        <b-link @click="doDelete({applicationId: $route.params.applicationId, proposalId: data.item.proposalId, costItemId: data.item.proposalCostId })" v-if="selectedApplicationIsForActiveAY && !isReadonly">
          <i class="fas fa-fw fa-times text-danger" title="Delete Item" />
        </b-link>
      </template>
      <template v-slot:cell(action)="data" v-else>
        <b-link :to="{ name: 'CTE/PerkinsV/Application/Proposal/CostItem/View', params: {applicationId: $route.params.applicationId, proposalId: data.item.proposalId, costItemId: data.item.proposalCostId, applicationLea: data.item.lea, mode: 'view' }}" title="View Item">
          <i class="fas fa-fw fa-search" />
        </b-link>
      </template>
      <template v-slot:custom-foot>
        <b-tr>
          <b-th colspan="3">
            Perkins Remaining: {{ (applicantFundingLimit.fundingLimit - (perkinsFundsUsedThisProposal + perkinsFundsUsedSiblingProposals)) | currency }}
          </b-th>
          <b-th>
            Totals:
          </b-th>
          <b-th class="text-right">
            {{ totalFundsUsedThisProposal | currency }}
          </b-th>
          <b-th class="text-right">
            {{ perkinsFundsUsedThisProposal | currency }}
          </b-th>
          <b-th class="text-right">
            {{ otherFundsUsedThisProposal | currency }}
          </b-th>
        </b-tr>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import PaginatedTable from '@/components/PaginatedTable'
import { Types } from '@/modules/cte/stores/perkinsv'
import { mapGetters, mapState } from 'vuex'
import ProvidesApplicationCostItemBreakdown from '../ProvidesApplicationCostItemBreakdown'
export default {
  computed: {    
    ...mapGetters(Types.path, [Types.getters.selectedProposalCostItems, Types.getters.selectedApplicationIsForActiveAY, Types.getters.activeAcademicYear]),
    ...mapState(Types.path, [Types.state.costTypes, Types.state.selectedApplication]),
    fields() {
      return [
        { key: "lea", sortable: true, label: "LEA", formatter: this.leaFormatter },
        { key: "type", label: "Object Code", sortable: true  },
        { key: "description", sortable: false},
        { key: "item", sortable: true, label: "Qty @ Price" },
        { key: "total", sortable: true, class: "text-right" },
        { key: "perkins", sortable: true, class: "text-right" },
        { key: "other", class: "text-right", sortable: true, formatter: (v, k, i) => (i.district || 0) + (i.partner || 0) + (i.startup || 0) },
        { key: "action", class: "text-right" }
      ]
    },
    selectedApplicationAY() {
      return this.selectedApplication.ay
    }
  },
  methods: {
    async doDelete(payload) {
      var result = await this.$bvModal.msgBoxConfirm("Are you sure you want to delete this cost item?", { okTitle: "Yes", cancelTitle: "No" })
      if (result) this.$store.dispatch(Types.actions.deleteCostItem, payload)
    },
    costTypeDescription(typeCode){
      return this.costTypes.find(t => t.code == typeCode)?.description || "Not Found"
    }
  },
  props: {
    isReadonly: {
      type: Boolean,
      required: true,
    }
  },
  components: {
    PaginatedTable
  },
  watch: {
    selectedApplicationAY: {
      immediate: true,
      handler (newAY) {
        this.$store.dispatch(Types.actions.getCostTypes, { schoolYear: newAY, includeDisabled: true})
      }
    }
  },
  mixins: [ProvidesApplicationCostItemBreakdown]
}
</script>
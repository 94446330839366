<template>
  <div>
    <h1>Employee Lookup</h1>
    <h2>
      <lea-display :lea="selectedLea" />
    </h2>
    <employee-search-widget :selected-lea="selectedLea" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { PageMixin } from '../../PageMixin'
import EmployeeSearchWidget from './EmployeeSearchWidget.vue'

export default {
  mixins: [PageMixin],
  components: {
    EmployeeSearchWidget
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState('hcptModule', ['employees'])
  },
  methods: {
  }
}
</script>

<template>
  <div>
    <div>
      <h1>Recent Jobs</h1>
    </div>
    <div class="card bg-light mt-3">
      <a class="anchor" id="teacherload" />
      <div class="card-header">
        Job Results
      </div>
      <div class="card-body">
        <b-button-group size="sm">
          <b-button variant="outline-danger" :pressed="failed" @click="setSelectedState('failed')">
            Failed <span class="badge badge-light">{{ (jobs.failed || []).length }}</span>
          </b-button>
          <b-button variant="outline-info" :pressed="processing" @click="setSelectedState('processing')">
            Processing <span class="badge badge-light">{{ (jobs.processing || []).length }}</span>
          </b-button>
          <b-button variant="outline-primary" :pressed="succeeded" @click="setSelectedState('succeeded')">
            Succeeded <span class="badge badge-light">{{ (jobs.succeeded || []).length }}</span>
          </b-button>
        </b-button-group>
        <jobs-table :jobs="selectedStateJobs" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { PageMixin } from '../../PageMixin'
import JobsTable from './JobsTable.vue'

export default {
  mixins: [PageMixin],
  components: {
    JobsTable
  },
  data() {
    return {
      selectedState: 'succeeded'
    }
  },
  computed: {
    ...mapState('adminModule', ['jobs']),
    failed() {
      return this.selectedState == 'failed'
    },
    succeeded() {
      return this.selectedState == 'succeeded'
    },
    processing() {
      return this.selectedState == 'processing'
    },
    selectedStateJobs() {
      if (this.jobs) {
        return this.jobs[this.selectedState]
      }
      return []
    }
  },
  methods: {
    setSelectedState(state) {
      this.selectedState = state
    }
  },
  created() {
    this.$store.dispatch('adminModule/getJobs')
  }
}
</script>

<style>

</style>
<template>
  <div v-if="true || (currentRequest && currentRequest.isRecalled) || (canRecall && mayRecall)">
    <div class="row mb-1" v-if="isRecalling || isGrantingRecall || isDenyingRecall">
      <div class="col-md-12">
        <span v-if="isRecalling">You must provide a reason for this recall request:</span>
        <span v-if="isDenyingRecall">You must provide a reason for denying this recall request:</span>
        <span
          v-if="isGrantingRecall"
        >
          You may provide a comment related to granting this recall request:</span>
        <textarea v-model="commentText" class="form-control" placeholder="Enter comments here..." />
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-md-6">
        <span v-if="currentRequest.isRecalled" class="badge badge-warning">
          The district has requested to recall this document.
        </span>
        <span v-if="currentRequest.isRecallGranted" class="badge badge-success">
          This request has been successfully recalled by the district.
        </span>
        <span v-if="currentRequest.isRecallDenied" class="badge badge-danger">
          ADE has denied the request to recall this document.
        </span>
      </div>
      
      <div class="col-md-6 small text-right">
        <b-button
          size="xs"
          @click="isRecalling=true;confirmationAction=confirmRecall"
          v-if="canRecall && mayRecall && !(isRecalling || currentRequest.isRecalled)"
          variant="danger"
        >
          Recall Request
        </b-button>

        <b-button
          size="xs"
          @click="isGrantingRecall=true;confirmationAction=grantRecall"
          class="mr-1"
          v-if="currentRequest && currentRequest.isRecalled && mayApproveRecall && !(isGrantingRecall || isDenyingRecall)"
          variant="success"
        >
          Grant Recall
        </b-button>
        <b-button
          size="xs"
          @click="isDenyingRecall = true;confirmationAction=denyRecall"
          v-if="currentRequest && currentRequest.isRecalled && mayApproveRecall && !(isGrantingRecall || isDenyingRecall)"
          variant="warning"
        >
          Deny Recall
        </b-button>
        <b-button
          size="xs"
          @click="performConfirmationAction"
          class="mr-1"
          :disabled="!ableToConfirm"
          v-if="isGrantingRecall || isDenyingRecall || isRecalling"
          variant="primary"
        >
          Confirm
        </b-button>
        <b-button
          size="xs"
          @click="isDenyingRecall = false; isGrantingRecall = false; isRecalling=false;"
          class="mr-1"
          v-if="isGrantingRecall || isDenyingRecall || isRecalling"
          variant="secondary"
        >
          Cancel
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'recall-widget',
  props: {
    request: Object
  },
  data() {
    return {
      isRecalling: false,
      isDenyingRecall: false,
      isGrantingRecall: false,
      commentText: '',
      confirmationAction: null
    }
  },
  computed: {
    ...mapState('toolsModule/leaAssignment', ['currentRequest']),
    ...mapState('globalModule', ['leaContext', 'userContext']),
    canRecall() {
      return (
        this.currentRequest.requestStatus >= 3 &&
        this.currentRequest.requestStatus < 13 &&
        !this.currentRequest.isRecallDenied
      )
    },
    ableToConfirm() {
      return  ((this.isRecalling || this.isDenyingRecall) && this.commentText.length > 0) || this.isGrantingRecall
    },
    mayRecall() {
      return !this.userContext.isADEUser
    },
    mayApproveRecall() {
      return this.userContext.isRTSMSAdmin
    }
  },
  methods: {
    confirmRecall() {
      return this.actionPromise = this.$store.dispatch('requestModule/issueRecall', {
        requestId: this.request.requestId,
        comment: this.commentText
      })

    },
    grantRecall() {
      return this.actionPromise = this.$store.dispatch('requestModule/addressRecall', {
        state: 0,
        grantRecall: true,
        requestId: this.request.requestId,
        comment: this.commentText
      })

    },
    denyRecall() {
      return this.actionPromise = this.$store.dispatch('requestModule/addressRecall', {
        grantRecall: false,
        requestId: this.request.requestId,
        comment: this.commentText
      })
    },
    performConfirmationAction() {
      return this.confirmationAction()   
      .then(() => this.returnToList())

    },
    returnToList() {
      return this.$router.push('/tools/lea-assignment/requests')
    }
  }
}
</script>

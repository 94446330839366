<template>
  <div>
    <info-panel />
    <div v-if="leaType == LeaTypes.District || leaType == LeaTypes.School" class="card outer-card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <growth-filter />
          </div>
        </div>
        <student-growth :load-data="loadData" v-if="teacher" />
      </div>        
    </div>
    <div v-else class="card outer-card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="alert alert-warning">
              Please select a school or district to view report
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { PageMixin } from '../../../PageMixin'
import InfoPanel from './InfoPanel.vue'
import GrowthFilter from '../GrowthFilter.vue'
import StudentGrowth from './StudentGrowth.vue'

export default {
  name: 'question2',
  mixins: [PageMixin],
  data() {
    return {
      loadData: Promise.resolve(false),
    }
  },
  components: {
    GrowthFilter,
    StudentGrowth,
    InfoPanel
  },
  computed: {
    ...mapState('academicsModule/teacherGrowth', ['grade', 'subject', 'subgroup', 'teacher']),
    fiscalYear() {
      return this.$store.state.academicsModule.teacherGrowth.fiscalYear
    }
  },
  methods: {
    loadGrowth(lea) {
      this.loadData = Promise.all([
        this.$store.dispatch('academicsModule/teacherGrowth/getStudentGrowthCurrent', {
          lea: lea.number
        }),
        this.$store.dispatch('academicsModule/teacherGrowth/getTeacherGrowthSummary', {
          lea: lea.number
        }),
        this.$store.dispatch('academicsModule/teacherGrowth/getLeaGrowthSummary', {
          lea: lea.number
        })
      ])
    }
  },
  watch: {
    teacher() {
      this.loadGrowth(this.selectedLea)
    }
  }
}
</script>

<style>
.card-body {
  background-color: inherit;
}
</style>
<template>
  <loadable-chart-card :chart-mode="$printMode == true" :load-data="loadData" :initial-chart-mode="mode == 'admin'" card-style="" :enable-data-toggle="mode == 'admin'">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <div class="row">
          <div class="col-6">
            <district-closures-pie />
          </div>
          <div class="col-6">
            <district-closures-chart x-axis="Month" mode="Month" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <district-closures-chart x-axis="Week" mode="Week" />
          </div>
        </div>
      </div>
      <div v-show="!slotProps.chartMode">
        <paginated-table :items="filteredClosures" :fields="fields" sort-by="createdDate" :sort-desc="true" responsive="lg">
          <template v-slot:cell(districtLea)="data">
            <div>
              <span :id="data.index + '-' + data.value">
                {{ data.value }}
              </span>
              <lea-tooltip :target-selector="data.index + '-' + data.value" :lea-number="data.value" />
            </div>
          </template>
          <template v-slot:cell(createdDate)="data">
            {{ data.value | date }}
          </template>
          <template v-slot:cell(incidentDate)="data">
            {{ data.value | date('MM/DD/YYYY') }}
          </template>
          <template v-slot:cell(updatedDate)="data">
            {{ data.value | date }}
          </template>
          <template v-slot:cell(districtPhone)="data">
            <a :href="`tel:${data.value}`">
              {{ data.value }}
            </a>
          </template>
          <template v-slot:cell(fullSchoolNames)="data">
            <ul class="list-unstyled">
              <li v-for="i in data.value" :key="i">
                {{ i }}
              </li>
            </ul>
          </template>
          <template v-slot:cell(gradeDisplay)="data">
            <span class="badge badge-primary mr-1" v-for="i in data.value" :key="i">{{ i }}</span>
          </template>
          <template v-slot:head(actionColumn)>
            <b-button variant="primary" :to="{name: 'sct-new-closure', params: {districtLea: districtLea}}" v-if="mode==='district'">
              <i class="fa fa-fw fa-plus-circle" /> Add a Closure
            </b-button>
          </template>
          <template v-slot:cell(superName)="data">
            <a :href="`mailto:${data.item.superEmail}`">
              {{ data.value }}
            </a>
          </template>
          <template v-slot:cell(status)="data">
            <span>
              <i class="fa fa-circle text-danger" title="Active" v-if="data.value === true" />
              <i class="fa fa-circle text-success" title="Inactive" v-else-if="data.value === false" />
              <i class="fa fa-circle text-secondary" title="No Value" v-else />
            </span>
          </template>
          <template v-slot:cell(modificationLevel)="data">
            <span class="badge" :class="data.value.variant">{{ data.value.short }}</span>
          </template>
          <template v-slot:cell(actionColumn)="data">
            <b-button v-if="!isViewer" size="sm" variant="info" :to="{name: 'sct-edit-closure', params: {id: data.item.id, districtLea: data.item.districtLea}}" class="mr-1">
              <i class="fa fa-fw fa-pen" title="Edit Closure" />
            </b-button>
            <b-button v-if="!isViewer" size="sm" variant="danger" class="mr-1" @click="deleteConfirm(data.item.id)">
              <i class="fa fa-fw fa-times" title="Delete Closure" />
            </b-button>
            <b-button v-if="isViewer" size="sm" variant="primary" :to="{name: 'sct-edit-closure', params: {id: data.item.id, districtLea: data.item.districtLea}}">
              <i class="fa fa-fw fa-eye" title="View Closure" />
            </b-button>
          </template>
        </paginated-table>
      </div>
    </template>    
  </loadable-chart-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import PaginatedTable from '../../PaginatedTable'
import { gradeMap } from '../../../helpers/gradeSpans'
import { ClosureReasons } from './enums'
import LeaTooltip from '../../LeaTooltip'
import DistrictClosuresPie from './DistrictClosuresPie'
import DistrictClosuresChart from './DistrictClosuresChart'
import moment from 'moment'
import { ChartMixin } from '../../ChartMixin'

export default {
  data() {
    return {
    }
  },
  mixins: [ChartMixin],
  props: {
    districtLea: String,
    mode: String
  },  
  computed: {
    ...mapGetters('core/sctClosures', ['districtClosures']),
    ...mapGetters('globalModule', ['leaDictionary']),
    ...mapState('globalModule', ['userContext']),
    isViewer() {
      return this.userContext.isSurveyViewer
    },
    fields() { 
      let fields = [
        { key: 'districtLea', label: 'District' },
        { key: 'fullSchoolNames', label: 'School(s) Affected' },
        { key: 'modificationReason', label: 'Type' },
        { key: 'start', label: 'Start', sortable: true },
        { key: 'end', label: 'End', sortable: true },
        { key: 'gradeDisplay', label: 'Grade(s) Affected' },
        { key: 'numberStudentsAffected', label: 'Students Affected' },
        { key: 'numberStaffAffected', label: 'Staff Affected' },
        { key: 'districtPhone' },
        { key: 'createdDate', label: 'Reported', sortable: true },
        { key: 'updatedDate', label: 'Modified', sortable: true },
        { key: 'actionColumn', class: 'text-right nowrap', label:'' }
      ]
      if (this.mode == 'admin')
        fields.splice(3, 0, { key: 'superName', label: 'Superintendent' })

      return fields
    },
    filteredClosures() {
      let closures = this.districtClosures
      if (this.coopDirector) {
        closures = closures.filter(c => this.leaDictionary[this.coopDirector].ownedLeas.some(d => d.number == c.districtLea))
      }

      return closures.map(item=> {
        var transformed = Object.assign({}, item)
        const start = moment(item.startDate + 'Z')
        const end = moment(item.endDate + 'Z')

        transformed.schoolLeas = transformed.schoolLeas || []
        transformed.gradeLevels = transformed.gradeLevels || []
        transformed.fullSchoolNames = transformed.schoolLeas.map(this.getSchoolName)
        transformed.gradeDisplay = transformed.gradeLevels.map(g => this.gradeDisplay(g)) 
        transformed.modificationReason = (ClosureReasons[transformed.closureType] || { label: 'Unknown' }).label
        transformed.start = start.format('MM/DD/YYYY')
        if (start.format('HH:mm') != '00:00')
          transformed.start += ' ' + start.format('h:mm a')
        transformed.end = end.format('MM/DD/YYYY')
        if (end.format('HH:mm') != '00:00')
          transformed.end += ' ' + end.format('h:mm a')
        return transformed
      })
    },
    coopDirector() {
      return this.userContext.coopDirector
    }
  },
  methods: {
    gradeDisplay(code) {
      if (code == 255) return 'All'
      else return gradeMap.get(code)
    },
    deleteConfirm(id) {
      this.$bvModal.msgBoxConfirm(`Are you sure you want to delete this closure?`)
      .then((result) => {
        if (result) {
          this.$store.dispatch('core/sctClosures/deleteClosure', id)
        }
      })
    },
    parseLongTermDetails(details) {
      if(details) {
        return JSON.parse(details)
      }
      else{
        return {}
      }
    },
    getSchoolName(lea) {
      if (lea === 'ALL') return 'Entire District'
      if (this.leaDictionary[lea]) return `${this.leaDictionary[lea].name} (${lea})`
      return lea
    }, 
    excelExportData() {
      return { name: 'Closures', items: this.filteredClosures.map(c => { 
        const fields = {
          'ID': c.id,
          'District': this.leaDictionary[c.districtLea] ? this.leaDictionary[c.districtLea].name + ' (' + c.districtLea + ')' : c.districtLea,
          'School(s) Affected': c.fullSchoolNames.join('; '),
          'Type': ClosureReasons[c.closureType].label,
          'Start': new moment(c.startDate + 'Z').format('MM/DD/YYYY h:mm a'),
          'End': new moment(c.endDate + 'Z').format('MM/DD/YYYY h:mm a'),
          'Superintendent Name': c.superName,
          'Superintendent E-mail': c.superEmail,
          'Grade(s) Affected': c.gradeDisplay.join('; '),
          '# Students Affected': c.numberStudentsAffected,
          '# Staff Affected': c.numberStaffAffected,
          'Is AMI Day': c.isAMIDay,
          'Will Be Made Up': c.willBeMadeUp,
          'District Phone': c.districtPhone,
          'Reported Date': new Date(c.createdDate),
          'Updated Date': new Date(c.updatedDate),
          'Updated By': c.updatedBy,
          'Superintendent Comments': c.superintendentComments,
          'ADE Comments': c.adeExternalComments,
        }

        if (this.mode == 'admin') {
          fields['ADE Knowledge Base'] = c.adeInternalComments
        }

        return fields
      })}
    },
  },
  components: {
    PaginatedTable,
    LeaTooltip,
    DistrictClosuresPie,
    DistrictClosuresChart,
  },    
}
</script>

<style>
  .nowrap {
    white-space: nowrap;
  }
</style>
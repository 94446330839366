<template>
  <div class="course-list">
    <paginated-table :items="excludedCourses" :fields="fields" sort-by="courseCode" :sort-asc="true" :per-page="50" striped hover :load-data="loading">
      <template v-slot:cell(subject)="data">
        <subject-tag :subject="data.value" />
      </template>
    </paginated-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PaginatedTable from '@/components/PaginatedTable'
import SubjectTag from '../../common/SubjectTag.vue'

export default {
  data() {
    return {
      fields: [
        { key: 'courseCode', sortable: true },
        { key: 'name', sortable: true },
      ],
      loading: null
    }
  },  
  components: {
    PaginatedTable,
    SubjectTag,
  },
  computed: {
    ...mapState('rvsModule', ['excludedCourses', 'fiscalYear']),
  },
  props: {
    
  },
  methods: {
    async reloadData() {
      if (this.fiscalYear)
        this.loading = this.$store.dispatch('rvsModule/getExcludedCourses')
    }
  },
  watch: {
    fiscalYear: {
      immediate: true,
      handler() {
        this.reloadData()
      }
    },
  }
}
</script>
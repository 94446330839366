<template>
  <div class="card card-body">
    <paginated-table :items="items" :fields="operativeFields"> 
      <template v-slot:head(action)>
        <b-button class="text-nowrap" v-if="canAddInspection" variant="primary" title="New Inspection" to="/transportation/batch/create">
          <i class="fa fa-fw fa-plus-circle" /> Add
        </b-button>
      </template>
      <template v-slot:cell(districtName)="data">
        {{ leaDictionary[data.item.districtLea].name }}
      </template>
      <template v-slot:cell(busCount)="data">
        {{ data.item.inspections.length }}
      </template>
      
      <template v-slot:cell(createDate)="data">
        <custom-date :date="data.value" />
      </template>

      <template v-slot:cell(status)="data">
        <inspection-batch-status-badge :status="data.value" />
        <inspection-batch-notification-icon :batch="data.item" />
      </template>
      <template v-slot:cell(progress)="data">
        {{ getProgress(data.item.inspections) }}
      </template>
      <template v-slot:cell(action)="data">
        <b-link :to="{name: 'Transportation/Batch/View', params:{ batchId: data.item.batchId}}" title="View Batch">
          <i class="far fa-fw fa-folder-open" />
        </b-link>
        <b-link v-if="canAddInspection && data.item.status == 1" :to="{name: 'Transportation/Batch/Edit', params:{ batchId: data.item.batchId}, query: { process: 'start'}}" title="Start Batch">
          <i class="fas fa-fw fa-play" />
        </b-link>
        <b-link v-if="canAddInspection && data.item.status != 1" :to="{name: 'Transportation/Batch/Edit', params:{ batchId: data.item.batchId}, query: { process: 'edit'}}" title="Edit Batch">
          <i class="fas fa-fw fa-edit" />
        </b-link>        
        <b-link
          v-if="canAddInspection && data.item.status <= 3" @click="remove(data.item.batchId)">
          <i class="fas fa-fw fa-trash text-danger" />
        </b-link>
      </template>
      <template v-slot:cell(fiscalYear)="data">
        {{ data.value + 1989 }} / {{ data.value + 1990 }}
      </template>
    </paginated-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PaginatedTable from '../../../PaginatedTable'
import InspectionBatchStatusBadge from '../../common/InspectionBatchStatusBadge'
import InspectionBatchNotificationIcon from './InspectionBatchNotificationIcon'
import CustomDate from '../../../CustomDate'
import { Types } from '../../../../stores/dpsaft/trans'

export default {
  data() {
    return {
      fields: [
        { key: 'fiscalYear', sortable: true },
        { key: 'districtLea', sortable: true },
        { key: 'districtName', sortable: true },
        { key: 'description', sortable: true },
        { key: 'status', sortable: true },
        // { key: 'busCount', sortable: true },
        { key: 'progress', sortable: true, thClass: "text-center", tdClass: "text-center" },
        { key: 'createdBy', sortable: true },
        { key: 'createDate', sortable: true },
        {
          key: 'action',
          sortable: false,
          class: 'text-right',
          thClass: 'text-right'
        }
      ]
    }
  },
  methods: {
    onBeginAdd() {},
    getProgress(inspections) {
      const completedOrSkipped = inspections.filter(i => i.status >= 10).length
      return `${completedOrSkipped}/${inspections.length}`
    },
    remove(batchId){
      if(confirm("Are you sure you want to delete this inspection batch?")){
        this.$store.dispatch(Types.actions.deleteBatch, { batchId: batchId })
      }
    }
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    hideColumns: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    },
    canAddInspection() {
      return this.userContext.hasAnyRole(['Insights_DPSAFT_Administrators', 'Insights_DPSAFT_Transportation_Administrators', 'Insights_DPSAFT_Transportation_Inspectors', 'Insights_DPSAFT_Transportation_Trainers'])
    },
    operativeFields() {
      return this.fields.filter(f => !this.hideColumns.includes(f.key))
    },
  },
  components: {
    PaginatedTable,
    InspectionBatchStatusBadge,
    InspectionBatchNotificationIcon,
    CustomDate
  }
}
</script>
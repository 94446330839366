<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { credits } from '../../../helpers/credits'
import sortBy from 'lodash.sortby'

export default {
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        plotOptions: {},
        series: [
          {
            type: 'column',
            name: 'Total ALPs'
          }
        ],
        credits: credits.AELS,
        title: {
          text: 'Alternative Learning Plan Count by Educator'
        },
        xAxis: {
          type: 'category',
          title: {
            text: 'School Year'
          }
        },
        yAxis: [
          {
            title: {
              text: 'Total ALPs'
            }
          }
        ]
      }
    }
  },
  mixins: [ChartMixin],
  computed: {
    reportData() {
      return this.$store.state.hcptModule.employeeALPs
    }
  },
  watch: {
    reportData(newData) {
      this.chartOptions.series[0].data = sortBy(newData.map(row => {
        return {
          x: (row.fiscalYear % 1990) + 1990,
          y: row.count
        }
      }), 'x')
    }
  },
  methods: {
    dataItems() {
      return this.chartOptions.series[0].data.map(data => {
        return {
          SchoolYear: data.x,
          ALPsCount: data.y
        }
      })
    }
  }
}
</script>

<template>
  <div class="school-list">
    <paginated-table :items="schoolList" :fields="fields" sort-by="schoolLEA" :sort-asc="true" :per-page="20" striped hover :load-data="loading">
      <!-- <template v-slot:cell(action)="data">
        <router-link class="btn btn-sm btn-primary" title="View" :to="{ name: 'Schedule/RVS/Teachers/Teacher', params: { id: data.item.uniqueEmployeeId, lea: data.item.schoolLEA }}">
          <i class="fas fa-eye" />
        </router-link>
      </template> -->
      <template v-slot:cell(schoolLea)="data">
        <a href="#" @click="goToLea(data.item.schoolLea)" class="cursor-pointer">
          <lea-icon :lea="leaDictionary[data.item.schoolLea]" /> {{ data.value }}
        </a>
      </template>
      <template v-slot:cell(verificationProgress)="data">
        <b-progress min="0" :max="100" style="height: 1rem;">
          <b-progress-bar :value="data.value" />
        </b-progress>
        <div class="progress-overlay">
          {{ data.value.toFixed(0) }}% ({{ data.item.completeStudents }} of {{ data.item.totalVerifiableStudents }})
        </div>
      </template>
      <template v-slot:cell(verificationProgressTeachers)="data">
        <b-progress min="0" :max="100" style="height: 1rem;">
          <b-progress-bar :value="data.value" />
        </b-progress>
        <div class="progress-overlay">
          {{ data.value.toFixed(0) }}% ({{ data.item.completeTeachers }} of {{ data.item.verifiableTeachers }})
        </div>
      </template>
      <template v-slot:cell(status)="data">
        <!-- a badge with a calculated class and the text inside is a calculated string -->
        <span class="badge badge-lg" :class="getStatus(data.value).class">{{ getStatus(data.value).description }}</span>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import PaginatedTable from '@/components/PaginatedTable'
import LeaIcon from '@/components/LeaIcon'

export default {
  data() {
    return {
      fields: [
        { key: 'schoolLea', filterByFormatted: true, sortByFormatted: true, label: 'School', sortable: true, formatter: this.leaFormatter },
        { key: 'grades', filterByFormatted: true, label: 'Grades', sortable: true, formatter: (value, key, item) => this.leaDictionary[item.schoolLea].gradesServed },
        { key: 'status', filterByFormatted: true, label: 'Status', sortable: true },
        { key: 'verificationProgressTeachers', sortByFormatted: true, tdClass: 'progress-bar-cell', label: 'Verification Progress (Teachers)', sortable: true, formatter: (value, key, item) => (item.completeTeachers / item.verifiableTeachers) * 100},
        { key: 'verificationProgress', sortByFormatted: true, tdClass: 'progress-bar-cell', label: 'Verification Progress (Students)', sortable: true, formatter: (value, key, item) => (item.completeStudents / item.totalVerifiableStudents) * 100 }
      ]
    }
  },  
  components: {
    PaginatedTable,
    LeaIcon
  },
  computed: {
    ...mapState('rvsModule', ['schoolList', 'fiscalYear']),
    ...mapGetters('globalModule', ['leaDictionary']),
  },
  props: {
    loading: {
      type: Promise,
      default: null
    }
  },
  methods: {
    getStatus(status) {
      switch(status) {
        case 0:
          return { class: 'badge-secondary', description: 'Draft' }
        case 1:
          return { class: 'badge-warning', description: 'Returned to Principal' }
        case 100:
          return { class: 'badge-primary', description: 'Submitted' }
        case 101:
          return { class: 'badge-success', description: 'Accepted' }
        default:
          return { class: 'badge-secondary', description: 'Unknown' }
      }
    },
  },
}
</script>
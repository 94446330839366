<template>
  <div>
    <br>
    <div class="alert alert-info col-md-4 col-sm-12" role="alert">
      <i class="fas fa-info-circle pr-2" />
      The following information is based on SIS Cycle {{ latestCycle }} data.
    </div>
    <div v-if="isSchoolOrDistrict">
      <div v-if="overusedAMIDays" class="alert alert-danger d-print-none">
        <b>AMI Days Usage Exceeded</b>
        <br>District usage has exceeded the alloted number of AMI days
      </div>
      <div class="row">
        <div class="col-md-3">
          <label
            class="form-label"
          >Number of AMI Days Approved for District for {{ schoolYear + 1990 }}:</label>
        </div>
        <div class="col-md-1 text-right">
          {{ (amiApprovals && amiApprovals[0]) ? amiApprovals[0].amiDaysAllowed : "N/A" }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          Total AMI Days Used for District Schools for {{ schoolYear + 1990 }}:
        </div>
        <div class="col-md-1 text-right">
          {{ ((districtLevelSummary && districtLevelSummary[0]) || {}).dayCount }}
        </div>
      </div>
    </div>
    <div class="card bg-light mt-3">
      <div class="card-header">
        AMI Days
      </div>
      <div class="card-body">
        <div class="card-group">
          <div class="card d-flex">
            <AlternateMethodOfIntructionDaysCountChart
              :ami-day-data="amiDayCounts"
              :load-data="loadData"
              :chart-title="'Total AMI Days'"
              :selected-lea="selectedLea"
            />
          </div>
          <div v-if="selectedLea.type != 1" class="card d-flex">
            <AlternateMethodOfIntructionDaysCountChart
              v-show="selectedLea.type != 2"
              :ami-day-data="amiDayAverages"
              :load-data="loadData"
              :chart-title="'Average AMI Days Per Building'"
              :selected-lea="selectedLea"
            />
            <AlternateMethodOfIntructionDaysSchoolChart
              v-show="selectedLea.type == 2"
              :ami-day-data="amiDayAverages"
              :load-data="loadData"
              :chart-title="'AMI Days By Building'"
              :selected-lea="selectedLea"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="card bg-light mt-3" v-if="!isSchoolOrDistrict">
      <div class="card-header">
        District Level Summary
      </div>
      <div class="card-body">
        <div class="text-info">
          Highlight and select a row below to filter the Building Summary by the District
        </div>

        <table-control-widget
          :per-page="districtSummaryTableOptions.perPage"
          @filter-changed="districtSummaryTableOptions.filter=$event"
          @page-length-changed="districtSummaryTableOptions.perPage=$event"
        />
        <b-table
          :items="districtLevelSummary"
          :fields="districtSummaryTableOptions.fields"
          @row-selected="onDistrictSelected"
          hover
          selectable
          select-mode="single"
          :per-page="districtSummaryTableOptions.perPage"
          :current-page="districtSummaryTableOptions.currentPage"
          :filter="districtSummaryTableOptions.filter"
          @filtered="onDistrictSummaryFiltered"
        >
          <template v-slot:cell(districtLEA)="data">
            <div>
              <div class="d-inline">
                <button
                  type="button"
                  class="btn btn-link"
                  @click="goToLea(data.value)"
                  :id="data.index + '-' + data.value">
                  {{ leaFormatter(data.value) }}
                </button>
                &nbsp;
                <lea-tooltip
                  :target-selector="data.index + '-' + data.value"
                  :lea-number="data.value"
                  :label="data.label" />
              </div>
            </div>
          </template>
          <template v-slot:head(indicator)="data">
            <div>
              <custom-tooltip
                content="Alerts if [AMI Days Allowed] - [AMI Days Used] < 0"
                :element-id="`ami-${data.label}`"
                :label="data.label" />
            </div>
          </template>
          <template v-slot:head(amiDaysAllowed)="data">
            <div>
              <custom-tooltip
                content="[AMI Days Allowed for District] X [# of Schools In District]"
                :element-id="`ami-${data.label}`"
                :label="data.label" />
            </div>
          </template>
          <template v-slot:cell(indicator)="data">
            <div class="text-center">
              <span
                class="fa"
                :class="{'text-success fa-check-circle': data.value, 'text-danger fa-times-circle': !data.value}"
              />
            </div>
          </template>
        </b-table>
        <table-pagination-widget
          :total-rows-count="districtLevelSummaryRowCount"
          :filtered-rows-count="districtSummaryTableOptions.filteredRowCount"
          :page-length="districtSummaryTableOptions.perPage"
          @page-changed="districtSummaryTableOptions.currentPage=$event"
        />
      </div>
    </div>

    <span v-if="selectedDistrict" class="badge badge-primary text-white">
      {{ leaFormatter(selectedDistrict) }}
      <i
        class="far fa-times-circle cursor-pointer"
        @click="selectedDistrict = null"
      />
    </span>
    <div class="card bg-light mt-3">
      <div class="card-header">
        <a id="building-level-summary" />
        Building Level Summary
      </div>
      <div class="card-body">
        <div class="text-info">
          Highlight and select a row below to filter the AMI Day Details by the Building
        </div>
        <table-control-widget
          :per-page="buildingSummaryTableOptions.perPage"
          @filter-changed="buildingSummaryTableOptions.filter=$event"
          @page-length-changed="buildingSummaryTableOptions.perPage=$event"
        />
        <b-table
          :items="buildingLevelSummary"
          :fields="buildingSummaryTableOptions.fields"
          hover
          selectable
          @row-selected="onSchoolSelected"
          select-mode="single"
          :tbody-tr-class="rowClass"
          :per-page="buildingSummaryTableOptions.perPage"
          :current-page="buildingSummaryTableOptions.currentPage"
          :filter="buildingSummaryTableOptions.filter"
          @filtered="onBuildingSummaryFiltered"
        >
          <template v-slot:cell(districtLEA)="data">
            <div>
              <div class="d-inline">
                <button
                  type="button"
                  class="btn btn-link"
                  @click="goToLea(data.value)"
                  :id="data.index + '-' + data.value">
                  {{ leaFormatter(data.value) }}
                </button>
                &nbsp;
                <lea-tooltip
                  :target-selector="data.index + '-' + data.value"
                  :lea-number="data.value"
                />
              </div>
            </div>
          </template>
          <template v-slot:cell(schoolLEA)="data">
            <div>
              <div class="d-inline">
                <button
                  type="button"
                  class="btn btn-link"
                  @click="goToLea(data.value)"
                  :id="data.index + '-' + data.value">
                  {{ leaFormatter(data.value) }}
                </button>
                &nbsp;
                <lea-tooltip
                  :target-selector="data.index + '-' + data.value"
                  :lea-number="data.value"
                />
              </div>
            </div>
          </template>
          <template v-slot:head(indicator)="data">
            <div>
              <span :id="`amibls-${data.label}`">{{ data.label }}</span>
              <custom-tooltip
                content="Alerts if [AMI Days Allowed] - [AMI Days Used] < 0"
                :element-id="`amibls-${data.label}`"
                :label="data.label" />
            </div>
          </template>
          <template v-slot:cell(indicator)="data">
            <div class="text-center">
              <span
                class="fa"
                :class="{'text-success fa-check-circle': data.value, 'text-danger fa-times-circle': !data.value}"
              />
            </div>
          </template>
        </b-table>
        <table-pagination-widget
          :total-rows-count="buildingLevelSummaryRowCount"
          :filtered-rows-count="buildingSummaryTableOptions.filteredRowCount"
          :page-length="buildingSummaryTableOptions.perPage"
          @page-changed="buildingSummaryTableOptions.currentPage=$event"
        />
      </div>
    </div>

    <span v-if="selectedDistrict" class="badge badge-primary text-white">
      {{ leaFormatter(selectedDistrict) }}
      <i
        class="far fa-times-circle cursor-pointer"
        @click="selectedDistrict = null"
      />
    </span>
    <span v-if="selectedSchool" class="ml-1 badge badge-info text-white">
      {{ leaFormatter(selectedSchool) }}
      <i
        class="far fa-times-circle cursor-pointer"
        @click="selectedSchool = null"
      />
    </span>
    <div class="card bg-light mt-3">
      <div class="card-header">
        <a id="ami-day-details" />
        AMI Day Details
      </div>
      <div class="card-body">
        <table-control-widget
          :per-page="detailsTableOptions.perPage"
          @filter-changed="detailsTableOptions.filter=$event"
          @page-length-changed="detailsTableOptions.perPage=$event"
        />
        <b-table
          :items="amiDays"
          :per-page="detailsTableOptions.perPage"
          :fields="detailsTableOptions.fields"
          :current-page="detailsTableOptions.currentPage"
          :filter="detailsTableOptions.filter"
          @filtered="onDetailsFiltered">
          <template v-slot:cell(districtLEA)="data">
            <div>
              <div class="d-inline">
                <button
                  type="button"
                  class="btn btn-link"
                  @click="goToLea(data.value)"
                  :id="data.index + '-' + data.value">
                  {{ leaFormatter(data.value) }}
                </button>
                &nbsp;
                <lea-tooltip
                  :target-selector="data.index + '-' + data.value"
                  :lea-number="data.value"
                />
              </div>
            </div>
          </template>
          <template v-slot:cell(lea)="data">
            <div>
              <div class="d-inline">
                <button
                  type="button"
                  class="btn btn-link"
                  @click="goToLea(data.value)"
                  :id="data.index + '-' + data.value">
                  {{ leaFormatter(data.value) }}
                </button>
                &nbsp;
                <lea-tooltip
                  :target-selector="data.index + '-' + data.value"
                  :lea-number="data.value"
                />
              </div>
            </div>
          </template>
        </b-table>
        <table-pagination-widget
          :total-rows-count="detailRowCount"
          :filtered-rows-count="detailsTableOptions.filteredRowCount"
          :page-length="detailsTableOptions.perPage"
          @page-changed="detailsTableOptions.currentPage=$event"
        />
      </div>
    </div>

    <span v-if="selectedDistrict" class="badge badge-primary text-white">
      {{ leaFormatter(selectedDistrict) }}
      <i
        class="far fa-times-circle cursor-pointer"
        @click="selectedDistrict = null"
      />
    </span>
    <div class="card bg-light mt-3" v-if="!isSchoolOrDistrict">
      <div class="card-header">
        District AMI Day Approvals
      </div>
      <div class="card-body">
        <AMIApprovalsTable :approvals="amiApprovals" />
      </div>
    </div>
  </div>
</template>

<script>
import AlternateMethodOfIntructionDaysCountChart from './AMIDaysCountChart.vue'
import AlternateMethodOfIntructionDaysSchoolChart from './AMIDaysSchoolChart.vue'
import AMIApprovalsTable from './AMIApprovalsTable.vue'
import TableControlWidget from '../../shared/TableControlWidget'
import TablePaginationWidget from '../../shared/TablePaginationWidget'
import { mapState } from 'vuex'
import { LeaTypes } from '../../../helpers/leaTypes'
import LeaTooltip from '../../LeaTooltip'
import CustomTooltip from '../../CustomTooltip'

// const scrolltToOptions = {
//     easing: 'ease-in',
//     offset: 150,
//     force: true,
//     cancelable: true,
//     x: false,
//     y: true
// }

export default {
  name: 'alternate-method-of-instruction-days-widget',
  data() {
    return {
      loadData: null,
      LeaTypes: LeaTypes,
      selectedDistrict: null,
      selectedSchool: null,
      detailsTableOptions: {
        perPage: 10,
        currentPage: 1,
        filter: null,
        filteredRowCount: null,
        fields: [
          {
            key: 'districtLEA',
            label: 'District LEA',
            sortable: true
          },
          {
            key: 'lea',
            label: 'School LEA',
            sortable: true
          },
          {
            key: 'dayType',
            sortable: true
          },
          {
            key: 'calendarDate',
            label: 'Date',
            sortable: true
          },
          {
            key: 'semester',
            sortable: true
          },
          {
            key: 'quarter',
            sortable: true
          },
          {
            key: 'membershipValue',
            label: 'Membership Value',
            sortable: true
          }
        ]
      },
      districtSummaryTableOptions: {
        perPage: 10,
        currentPage: 1,
        filter: null,
        filteredRowCount: null,
        fields: [
          {
            key: 'districtLEA',
            label: 'District LEA',
            sortable: true
          },
          {
            key: 'dayCount',
            label: 'AMI Day Count',
            sortable: true
          },
          {
            key: 'amiDaysAllowed',
            label: 'AMI Days Allowed',
            sortable: true
          },
          {
            key: 'indicator',
            label: 'Monitoring Indicator',
            sortable: false,
            thClass: 'text-center'
          }
        ]
      },
      buildingSummaryTableOptions: {
        perPage: 10,
        currentPage: 1,
        filter: null,
        filteredRowCount: null,
        fields: [
          {
            key: 'districtLEA',
            label: 'District LEA',
            sortable: true
          },
          {
            key: 'schoolLEA',
            label: 'School LEA',
            sortable: true
          },
          {
            key: 'dayCount',
            label: 'AMI Day Count',
            sortable: true
          },
          {
            key: 'amiDaysAllowed',
            label: 'AMI Days Allowed',
            sortable: true
          },
          {
            key: 'indicator',
            label: 'Monitoring Indicator',
            sortable: false,
            thClass: 'text-center'
          }
        ]
      }
    }
  },
  components: {
    AlternateMethodOfIntructionDaysCountChart,
    AlternateMethodOfIntructionDaysSchoolChart,
    AMIApprovalsTable,
    TableControlWidget,
    TablePaginationWidget,
    LeaTooltip,
    CustomTooltip
  },
  computed: {
    ...mapState('academicsModule', ['amiDayData']),
    overusedAMIDays() {
      if (
        this.districtLevelSummary &&
        this.districtLevelSummary[0] &&
        this.districtLevelSummary[0].amiDaysAllowed &&
        this.districtLevelSummary[0].amiDaysAllowed <
          this.districtLevelSummary[0].dayCount
      ) {
        return true
      }
      return false
    },
    amiDays() {
      let days = [...this.$store.state.academicsModule.amiDayData.days]
      if (this.selectedDistrict)
        days = days.filter(b => b.districtLEA == this.selectedDistrict)
      if (this.selectedSchool)
        days = days.filter(b => b.lea == this.selectedSchool)

      return days
    },
    detailRowCount() {
      return this.amiDays ? this.amiDays.length : 0
    },
    districtLevelSummary() {
      let districtLevelSummary = this.amiDayData
        ? this.amiDayData.districtLevelSummary
        : []

      return districtLevelSummary?.map(obj => ({
        ...obj,
        indicator: obj.amiDaysAllowed - obj.dayCount >= 0
      }))
    },
    districtLevelSummaryRowCount() {
      return this.districtLevelSummary ? this.districtLevelSummary.length : 0
    },
    buildingLevelSummary() {
      let buildingLevelSummary = this.amiDayData
        ? this.amiDayData.buildingLevelSummary
        : []
      if (this.selectedDistrict)
        buildingLevelSummary = buildingLevelSummary.filter(
          b => b.districtLEA == this.selectedDistrict
        )

      return buildingLevelSummary?.map(obj => ({
        ...obj,
        indicator: obj.amiDaysAllowed - obj.dayCount >= 0
      }))
    },
    buildingLevelSummaryRowCount() {
      return this.buildingLevelSummary ? this.buildingLevelSummary.length : 0
    },
    amiDayCounts() {
      return this.$store.state.academicsModule.amiDayData.dayCounts
    },
    amiDayAverages() {
      return this.$store.state.academicsModule.amiDayData.dayAverages
    },
    amiApprovals() {
      let amiApprovals = this.$store.state.academicsModule.amiDayData.approvals
      if (this.selectedDistrict)
        amiApprovals = amiApprovals.filter(b => b.lea == this.selectedDistrict)

      return amiApprovals
    },
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    },
    isSchoolOrDistrict() {
      return [LeaTypes.District, LeaTypes.School].includes(
        this.selectedLea.type
      )
    },
    latestCycle() {
      return this.$store.state.academicsModule.AMIFiscalYears.filter(
        y => y.value == this.schoolYear
      )[0]?.cycle
    }
  },
  props: {
    //amiDayData: Array,
    schoolYear: Number
  },
  methods: {
    onDetailsFiltered(e1, e2) {
      this.detailsTableOptions.filteredRowCount = e2
    },
    onDistrictSummaryFiltered(e1, e2) {
      this.districtSummaryTableOptions.filteredRowCount = e2
    },
    onBuildingSummaryFiltered(e1, e2) {
      this.buildingSummaryTableOptions.filteredRowCount = e2
    },
    onDistrictSelected(item) {
      if (item.length > 0) {
        this.selectedDistrict = item[0].districtLEA
        if (this.selectedSchool) {
          this.selectedSchool = null
        }
        // const self = this
        // setTimeout(function() {
        //   self.$scrollTo('#building-level-summary', 10, scrolltToOptions)
        // }, 1000)
      }
    },
    excelExportData() {
      return [
        {
          name: 'Building Level Summary',
          items: this.buildingLevelSummary
        },
        {
          name: 'AMI Days',
          items: this.amiDays
        }
      ]
    },

    onSchoolSelected(item) {
      if (item.length > 0) {
        this.selectedSchool = item[0].schoolLEA
        // const self = this
        // setTimeout(function() {
        //   self.$scrollTo('#ami-day-details', 10, scrolltToOptions)
        // }, 1200)
      }
    },
    leaFormatter(lea) {
      if (this.leaDictionary[lea])
        return `${lea} – ${this.leaDictionary[lea].name}`

      return lea
    },
    goToLea(leaNumber) {
      const lea = this.leaDictionary[leaNumber]
      window.scrollTo(0, 0)
      this.$store.dispatch('globalModule/changeSelectedLea', lea)
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.amiDaysAllowed < item.dayCount) return 'table-warning'
    },
    getData(lea, fy) {
      if (fy && lea && lea.number) {
        this.loadData = this.$store.dispatch(
          'academicsModule/getAMIDaysInformation',
          {
            lea: lea.number,
            schoolYear: fy
          }
        )
      }
    }
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        this.selectedDistrict = null
        this.selectedSchool = null
        this.getData(newLea, this.schoolYear)
        if (newLea.type == LeaTypes.District) {
          this.buildingSummaryTableOptions.fields = this.buildingSummaryTableOptions.fields.filter(
            f => f.key != 'districtLEA'
          )
          this.detailsTableOptions.fields = this.detailsTableOptions.fields.filter(
            f => f.key != 'districtLEA'
          )
        } else if (
          this.buildingSummaryTableOptions.fields.some(
            b => b.key == 'districtLEA'
          )
        ) {
          this.buildingSummaryTableOptions.fields.push({
            key: 'districtLEA',
            label: 'District LEA',
            sortable: true
          })
          this.detailsTableOptions.fields.push({
            key: 'districtLEA',
            label: 'District LEA',
            sortable: true
          })
        }
      }
    },
    schoolYear: {
      handler(newYear) {
        this.getData(this.selectedLea, newYear)
      }
    }
  }
}
</script>
<template>
  <div>
    <b-form>
      <b-form-row>
        <b-col cols="3">
          <b-form-group label="Academic Year">
            <b-form-select v-model="filter.academicYear" :options="ayOptions" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group class="pt-4">
            <b-btn variant="primary" class="m-1" @click="showFilters = !showFilters">
              <i class="fa fa-filter fa-fw" /> {{ showFilters ? 'Hide' : 'Show' }} Filters
            </b-btn>
            <b-btn variant="primary" class="m-1" @click="applyFilter" v-if="!showFilters">
              Load Data
            </b-btn>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-collapse id="filter-pane" v-model="showFilters">
            <b-card>
              <b-form-row>
                <b-col cols="3">
                  <filter-item 
                    v-model="filter.grade"
                    label="Grade"
                    :options="careerCoachGrades" 
                  />
                </b-col>
                <b-col cols="3">
                  <filter-item 
                    v-model="filter.initialContact"
                    label="Initial Contact (in Date Range)"
                    :options="[{value: 'exists', text: 'Students with an Initial Contact'}, {value: 'none', text: 'Students with no Initial Contact'}]" 
                  />
                </b-col>
                <b-col cols="3">
                  <filter-item 
                    v-model="filter.careerAssessment"
                    label="Career Assessment"
                    :options="[{value: 'none', text: 'Students with no Career Assessments at all'}, {value: 'no-grade-level', text: 'Students with no grade-level Career Assessments'}]" 
                  />
                </b-col>
                <b-col cols="3">
                  <filter-item 
                    v-model="filter.careerPlan"
                    label="Career Plan"
                    :options="[{value: 'none', text: 'Students with no Career Plan at all'}, {value: 'no-grade-level', text: 'Students with no grade-level Career Plan'}]" 
                  />
                </b-col>                
                <b-col cols="3">
                  <filter-item 
                    v-model="filter.completedFAFSA"
                    label="FAFSA"
                    :options="[{value: 1, text: 'Students that have completed FAFSA'}, {value: 0, text: 'Students that have NOT completed FAFSA' }]"
                  />
                </b-col>
                <b-col cols="3">
                  <filter-item 
                    v-model="filter.completedYOUniversal"
                    label="YOUniversal"
                    :options="[{value: 1, text: 'Students that have completed a YOUniversal Application'}, {value: 0, text: 'Students that have NOT completed a YOUniversal Application' }]" 
                  />
                </b-col>
                <b-col cols="3">
                  <filter-item 
                    v-model="filter.appliedToColleges"
                    label="College Application"
                    :options="[{value: 1, text: 'Students that have applied to College'}, {value: 0, text: 'Students that have NOT applied to College' }]"
                  />
                </b-col>
                <b-col cols="3">
                  <filter-item 
                    v-model="filter.attendingColleges"
                    label="College Attendance"
                    :options="[{value: 1, text: 'Students attending college'}, {value: 0, text: 'Students NOT attending college' }]"
                  />
                </b-col>
                <b-col cols="3">
                  <filter-item v-model="filter.postSecondaryPlan" label="Post Secondary Plan" :options="staticMetadata.postSecondaryPlanning.overallPlan" />
                </b-col>
                <b-col cols="3">
                  <filter-item 
                    v-model="filter.jobFamily"
                    label="Job Family"
                    :options="[{value: 'exists', text: 'Students with Job Family selected'}, {value: 'none', text: 'Students with no Job Family selected'}, {value: 'no-grade-level', text: 'Students with no grade-level Job Family selected'}]" 
                  />
                </b-col>
                <b-col cols="3">
                  <filter-item 
                    v-model="filter.occupation"
                    label="Occupation"
                    :options="[{value: 'exists', text: 'Students with Occupation selected'}, {value: 'none', text: 'Students with no Occupation selected'}, {value: 'no-grade-level', text: 'Students with no grade-level Occupation selected'}]" 
                  />
                </b-col>
                <b-col cols="3">
                  <filter-item 
                    v-model="filter.wblExperience"
                    label="Workbased Learning"
                    :options="[{value: 'exists', text: 'Students with any Workbased Learning Experience'}, {value: 'none', text: 'Students with no Workbased Learning Experience'}, {value: 'no-grade-level', text: 'Students with no grade-level Workbased Learning Experience'}]" 
                  />
                </b-col>                
                <b-col cols="3">
                  <filter-item 
                    v-model="filter.certification"
                    label="Certifications"
                    :options="[{value: 'exists', text: 'Students with any Certifications'}, {value: 'none', text: 'Students with no Certifications'}, {value: 'no-grade-level', text: 'Students with no grade-level Certifications'}]" 
                  />
                </b-col>                
              </b-form-row>
              <b-form-row>
                <b-col class="text-center">
                  <b-btn variant="primary" @click="applyFilter">
                    Apply Filters
                  </b-btn>
                </b-col>
              </b-form-row>
            </b-card>
          </b-collapse>
        </b-col>
      </b-form-row>
    </b-form>
  </div>
</template>

<script>
import FilterItem from './FilterItem.vue'
import { gradeSpans } from '@/helpers/gradeSpans'
import { mapState } from 'vuex'
import { metadata as staticMetadata } from '@/modules/cte/stores/careercoach/metadata'

export default {
  components: { FilterItem },
  data() {
    return {
      topic: null,
      academicYear: null,
      showFilters: false,
      staticMetadata,
      filter: {
      }
    }
  },
  computed: {
    ...mapState('cte/careercoach/reporting', ['rosterDates']),
    careerCoachGrades() {
      var grades = gradeSpans.filter(g=>g.value >= 7 && g.value <= 12);
      grades.push({value: 'G', text: 'Graduates / Previous AY - 12th Grade Students'})

      return grades
    }
  }, 
  props: {
    ayOptions: Array
  },
  methods: {
    applyFilter() {
      this.$emit('apply-filter', this.filter)
    }
  },
  mounted() {
    this.filter.academicYear = this.ay
  }
}
</script>
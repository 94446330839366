<template>
  <div>
    <div class="card card-primary">
      <div class="card-header">
        <h3>Inspections on Mobile Devices</h3>
      </div>
      <div class="card-body">
        <p>
          The Mobile Inspection feature provides convenient access to all current-year inspections that have not yet been sent to district administrators. This allows inspectors to complete the majority of their
          inspection via the mobile device of their choice.
        </p>
        <p>As inspections are sent to districts, they will be removed from the mobile inspection pages because there are no further tasks to complete that benefit from mobile interactions.</p>
        <p>To alleviate the need to enter long, complex passwords each time you login with a mobile device, you are allowed to create a temporary URL that only requires a short PIN or pass-phrase to access.</p>
        <p>
          This will generate a temporary link that you can access to easily login to Insights from your smart-phone. You may e-mail the link to yourself for quick access from your your e-mails.
          For security purposes, each link expires in 8 hours.
        </p>

        <b-form id="linkGenerate" class="mt-4">
          <b-form-row>
            <b-col md="3">
              <label>PIN / Pass-phrase</label>
            </b-col>
            <b-col md="9">
              <b-input-group class="mb-3" prepend="PIN">
                <b-form-input
                  v-model="pin"
                  placeholder="PIN / Pass-phrase for the link"
                  :state="formValid"
                  aria-describedby="pin-input-feedback"
                />
                <b-button
                  size="sm"
                  text="Button"
                  variant="primary"
                  @click="encode"
                  :disabled="!formValid"
                >
                  Generate Link
                </b-button>
                <b-form-invalid-feedback
                  id="pin-input-feedback"
                >
                  * A PIN or pass-phrase with a minimum length of 4 characters is required
                </b-form-invalid-feedback>
              </b-input-group>
            </b-col>
          </b-form-row>

          <div v-if="encoded">
            <b-form-group>
              <label>Mobile Link</label>
              <b-form-input id="url" v-model="url" readonly />
            </b-form-group>

            <b-form-row>
              <b-col md="9" class="text-right offset-3">
                <b-button
                  variant="primary"
                  title="Copy link to clipboard"
                  class="mr-2"
                  @click="copyToClipboard"
                >
                  <i class="fa fa-copy fa-fw" /> Copy Link
                </b-button>
                <b-button variant="primary" :title="`Email link to ${userContext.emailAddress}`">
                  <i class="fa fa-envelope fa-fw" /> Email Link
                </b-button>
              </b-col>
            </b-form-row>
          </div>
        </b-form>
      </div>
    </div>

    <!-- <input type="text" readonly v-model="url" class="form-control" />
    <div>Area: {{ this.dpsaftArea }}</div>
    <router-link :to="url">Mobile Login</router-link>-->
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'

import { mapState } from 'vuex'
import moment from 'moment'
export default {
  data() {
    return {
      encoded: '',
      decoded: '',
      pin: null
    }
  },
  mixins: [validationMixin],
  computed: {
    ...mapState('globalModule', ['userContext']),
    expirationDate() {
      return moment().add(8, 'hours')
    },
    url() {
      return `${location.protocol}//${location.host}/Account/MobileLogin?encodedToken=${this.encoded}`
    },
    targetUrl() {
      return `${this.dpsaftArea}/inspect`
    },
    formValid() {
      return !this.$v.$invalid
    }
  },
  methods: {
    encode() {
      this.$v.$touch()

      this.encoded = btoa(
        JSON.stringify({
          userId: this.userContext.userId,
          expires: this.expirationDate,
          email: this.userContext.emailAddress,
          pin: this.pin,
          targetUrl: this.targetUrl
        })
      )
    },
    decode() {
      this.decoded = atob(this.encoded)
    },
    copyToClipboard() {
      var copyText = document.getElementById('url')

      /* Select the text field */
      copyText.select()
      copyText.setSelectionRange(0, 99999) /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand('copy')

      /* Alert the copied text */
      alert('Copied the text: ' + copyText.value)
    },
    mounted() {
    }
  },
  props: {
    dpsaftArea: String
  },
  validations: {
    pin: {
      required,
      minLength: minLength(4)
    }
  }
}
</script>
<template>
  <div class="mt-3 pb-0">
    <b-table responsive striped hover small :items="aggregatedExpenses" :fields="fields" sort-by="Program" head-row-variant="secondary" class="small">
      <template #cell(remaining)="data">
        <div>
          <span :class="{ 'text-danger': data.value < 0 }">{{ data.value | currency }}</span>
        </div>
      </template>
      <template #cell(Progress)="data">
        <div v-if="data.item.Budgeted > 0">
          <b-progress min="0" :max="data.item.Budgeted" :variant="data.item.Remaining < 0 ? 'danger' : 'success'" style="height: 1.5rem;">
            <b-progress-bar :value="data.item.Actual" />
          </b-progress>
          <div class="progress-overlay">
            {{ ((data.item.Actual / data.item.Budgeted) * 100).toFixed(2) }}%
          </div>
        </div>
        <span v-else class="font-italic">
          (Over budget by {{ Math.abs(data.item.Remaining) | currency }})
        </span>
      </template>
      <template #cell(Type)="data">
        <div>
          <b-tooltip :target="'#' + data.item.TypeCodes + data.item.Program">
            <span>
              Object Code(s) <strong>{{ data.item.TypeCodes }}</strong>
            </span>
          </b-tooltip>
          {{ data.value }} <i :id="data.item.TypeCodes + data.item.Program" class="text-info fas fa-fw fa-info-circle ml-1" />
        </div>
      </template>
      <template v-slot:custom-foot>
        <b-tr variant="secondary">
          <b-td class="font-weight-bold" colspan="4">
            TOTAL
          </b-td>
          <b-td class="font-weight-bold">
            {{ totalBudgeted | currency }}
          </b-td>
          <b-td class="font-weight-bold">
            {{ totalActual | currency }}
          </b-td>
          <b-td class="font-weight-bold" :class="{ 'text-danger': totalRemaining < 0 }">
            {{ totalRemaining | currency }}
          </b-td>
          <b-td>
            <b-progress v-if="totalBudgeted > 0" min="0" :max="totalBudgeted" :variant="totalRemaining < 0 ? 'danger' : 'success'" style="height: 1.5rem;">
              <b-progress-bar :value="totalActual" />
            </b-progress>
            <div class="progress-overlay">
              {{ ((totalActual / totalBudgeted) * 100).toFixed(2) }}%
            </div>
          </b-td>
        </b-tr>
      </template>
    </b-table>
  </div>
</template>

<script>
import { Types } from '@/modules/esa/stores/'
import { mapState } from 'vuex'
import groupBy from 'lodash.groupby'
import sumBy from 'lodash.sumby'
import { expandCodes } from '../helpers.js'
import { LeaTypes } from '@/helpers/leaTypes'

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.planExpenses, Types.state.plan, Types.state.districtAllocations]),
    fields() {
      return [
        {
          key: 'Category',
          label: 'Category Name',
          sortable: true
        },
        {
          key: 'Description',
          label: 'Description',
          sortable: true,
          tdClass: 'max-width-150 truncate-column'
        },
        {
          key: 'Program',
          label: 'Program Code',
          sortable: true
        },
        {
          key: 'Type',
          label: 'Expense Type',
          sortable: true
        },
        {
          key: 'Budgeted',
          label: 'Budgeted Amount',
          sortable: true,
          formatter: (value) => { return value ? value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }): value }
        },
        {
          key: 'Actual',
          label: 'Actual Amount',
          sortable: true,
          formatter: (value) => { return value ? value.toLocaleString('en-US', { style: 'currency', currency: 'USD' }): value }
        },
        {
          key: 'Remaining',
          label: 'Remaining',
          sortable: true,
        },
        {
          key: 'Progress',
          label: 'Utilized',
          sortable: false,
          thStyle: 'width: 12.5%;'
        },
      ]
    },
    filteredExpenses() {
      if (this.currentCategory)
        return this.planExpenses.filter(expense => this.currentCategory.programCode.includes(expense.program))
      return this.planExpenses
    },
    aggregatedExpenses() {
      const data = groupBy(this.filteredExpenses, exp => JSON.stringify({ program: exp.program, type: this.getType(exp.program, exp.account).key }))
      return Object.keys(data).map(key => {
        var budgeted = sumBy(data[key], 'budgeted')
        var actual = sumBy(data[key], 'actual')
        var program = JSON.parse(key).program
        var type = JSON.parse(key).type
        var category = this.getCategory(program)
        var typeCodes = (category.types.find(t => t.key == JSON.parse(key).type) || { codes: []}).codes.join(', ')
        
        return {
          'Category': category.name,
          'Description': category?.description,
          'Type': this.translateTypeCode(type),
          'TypeCodes': typeCodes,
          'Program': program,
          'Budgeted': budgeted,
          'Actual': actual,
          'Remaining': budgeted - actual,
        }
      })
    },
    currentCategory() {
      return this.categories.find(category => category.name == this.drilldown)
    },
    totalBudgeted() {
      return sumBy(this.aggregatedExpenses, 'Budgeted')
    },
    totalActual() {
      return sumBy(this.aggregatedExpenses, 'Actual')
    },
    totalRemaining() {
      return this.totalBudgeted - this.totalActual
    },
    allocations() {
      if (this.selectedLea.type == LeaTypes.Coop) {
        const districtLeas = this.leaDictionary[this.selectedLea.number].ownedLeas.flatMap(o => o.number)
        return this.districtAllocations.filter(d => districtLeas.includes(d.lea))
      } else if (this.selectedLea.type == LeaTypes.District) {
        return this.districtAllocations.filter(d => d.lea == this.selectedLea.number)
      }
      return this.districtAllocations
    },
  },
  methods: {
    getCategory(program) {
      return this.categories.find(category => category.programCode.includes(program)) || {}
    },
    getType(program, account) {
      var category = this.categories.find(category => category.programCode.includes(program)) || {}
      //console.log(program, category)
      var data = category.types?.find(type => expandCodes(type.codes).includes(account)) || {}
      return data
    },
    excelExportData() {
      return {
        name: 'Table',
        items: this.aggregatedExpenses,
      }
    },
    translateTypeCode: (value) => {
      switch (value) {
        case 1: return 'Personnel'
        case 2: return 'Programs'
        case 3: return 'Materials, Supplies, and Equipment'
        case 4: return 'Purchased Services'
        default: return 'Other'
      }
    }
  },
  props: {
    drilldown: {
      type: String
    },
    categories: {
      type: Array,
      required: true
    },
  }
}
</script>

<style>
.max-width-150 {
  max-width: 150px;
}
.truncate-column {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.truncate-column:hover {
    text-overflow: clip;
    white-space: normal;
    /*word-break: break-all;*/
}
</style>
<template lang="html">
  <div class="card-block text-center py-4">
    <i :class="iconClass" />
    <p class="lead text-secondary">
      {{ message }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'card-icon-state',
  props: { 
    message: String, 
    iconClass: String
  }
}
</script>

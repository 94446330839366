const Subpopulations = [
  { name: 'American Indian', altName: 'Native American / Alaska Native', field: 'americanIndian', color: '#9c56b8', value: 2 },
  { name: 'Asian', altName: '', field: 'asian', color: '#00bd9c', value: 3 },
  { name: 'Black / African American', altName: '', field: 'blackAfricanAmerican', color: '#2c97de', value: 4 },
  { name: 'Hawaiian / Pacific Islander', altName: 'Pacific Islander / Native Hawaiian', field: 'hawaiianPacificIslander', color: '#f2c500', value: 5 },
  { name: 'Hispanic / Latino', altName: 'Hispanic', field: 'hispanicLatino', color: '#90ed7d', value: 1 },
  { name: 'Two or More Races', altName: 'Multiethnic', field: 'twoOrMoreRaces', color: '#e94b35', value: 7 },
  { name: 'White', altName: '', field: 'white', color: '#e87e04', value: 6 }
]

const OtherSubpopulations = [
  { name: 'Economically Disadvantaged', altName: '', field: 'economicDisadvantage', color: '#9c56b8' },
  { name: 'Special Education', altName: '', field: 'sped', color: '#2c97de' },
  { name: 'Migrant', altName: '', field: 'migrant', color: '#90ed7d' },
  { name: 'Limited English Proficiency', altName: '', field: 'lep', color: '#e87e04'},
  { name: 'Targeted Achievement Gap Group', altName: '', field: 'tagg', color: '#e94b35'},
  { name: 'Homeless', altName: '', field: 'homeless', color: '#f2c500'},
  { name: 'Gifted and Talented', altName: '', field: 'giftedTalented', color: '#00bd9c'},
  { name: 'Military Dependent', altName: '', field: 'militaryDependent'},
  { name: 'Foster Care', altName: '', field: 'fosterCare'}
]

const DemographicSubpopulations = [
  { name: 'Special Education', altName: '', field: 'specialEducation', color: '#2c97de', enrollmentCollection: 'bySped', value: 'Y' },
  { name: 'Migrant', altName: '', field: 'migrant', color: '#90ed7d', enrollmentCollection: 'byMigrant', value: 'Y' },
  { name: 'Limited English Proficiency', altName: '', field: 'limitedEnglishProficiency', color: '#e87e04', enrollmentCollection: 'byLEP', value: 'Y' },
  { name: 'Foster Care', altName: '', field: 'foster', enrollmentCollection: 'byFoster', value: 'Y' },
  { name: 'Students with Disabilities', altName: '', field: 'handicapped', enrollmentCollection: 'byHandicap', value: 'Y' },
  { name: 'Orphan', altName: '', field: 'orphan', enrollmentCollection: 'byOrphan', value: 'Y' },
  { name: 'Married', altName: '', field: 'married', enrollmentCollection: 'byMarital', value: 'M' },
  { name: 'Single', altName: '', field: 'single', visible: false, enrollmentCollection: 'byMarital', value: 'S' }
]

const SubpopulationsFactory= () => {
  return [
    { name: 'American Indian', altName: 'Native American / Alaska Native', field: 'americanIndian', color: '#9c56b8' },
    { name: 'Asian', altName: '', field: 'asian', color: '#00bd9c' },
    { name: 'Black / African American', altName: '', field: 'blackAfricanAmerican', color: '#2c97de' },
    { name: 'Hawaiian / Pacific Islander', altName: 'Pacific Islander / Native Hawaiian', field: 'hawaiianPacificIslander', color: '#f2c500' },
    { name: 'Hispanic / Latino', altName: 'Hispanic', field: 'hispanicLatino', color: '#90ed7d' },
    { name: 'Two or More Races', altName: 'Multiethnic', field: 'twoOrMoreRaces', color: '#e94b35' },
    { name: 'White', altName: '', field: 'white', color: '#e87e04'}
  ];
}

const SubpopulationCategories = ['All Students', 'Black or African American', 'Hispanic/Latino', 'White', 'Economically Disadvantaged', 'English Learners', 'Students with Disabilities']
export { Subpopulations, OtherSubpopulations, SubpopulationCategories, SubpopulationsFactory, DemographicSubpopulations }
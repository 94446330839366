<template>
  <div>
    <div class="card">
      <div class="card-header">
        Master Toggles
      </div>
      <div class="card-body">
        <!-- create a table with three columns:  the toggle, last updated date, last updated by -->
        <table class="table table-bordered">
          <tr>
            <th style="width: 50%" />
            <th style="width: 25%">
              Last Updated
            </th>
            <th style="width: 25%">
              Updated By
            </th>
          </tr>
          <tr>
            <td>
              <b-form-group>
                <b-form-checkbox v-model="teachersToggle" switch @change="toggleChanged(1, !teachersToggle)">
                  Teachers
                </b-form-checkbox>
              </b-form-group>
            </td>
            <td>{{ formatDate(getToggle(1).lastUpdated) }}</td>
            <td>{{ getToggle(1).updatedBy }}</td>
          </tr>
          <tr>
            <td>
              <b-form-group>
                <b-form-checkbox v-model="principalsToggle" switch @change="toggleChanged(2, !principalsToggle)">
                  Principals
                </b-form-checkbox>
              </b-form-group>
            </td>
            <td>{{ formatDate(getToggle(2).lastUpdated) }}</td>
            <td>{{ getToggle(2).updatedBy }}</td>
          </tr>
          <tr>
            <td>
              <b-form-group>
                <b-form-checkbox v-model="superintendentsToggle" switch @change="toggleChanged(3, !superintendentsToggle)">
                  Superintendents
                </b-form-checkbox>
              </b-form-group>
            </td>
            <td>{{ formatDate(getToggle(3).lastUpdated) }}</td>
            <td>{{ getToggle(3).updatedBy }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-header">
        Download Nightly Export Files
      </div>
      <div class="card-body">
        <b-list-group>
          <b-list-group-item v-for="file in nightlyExportFiles" :key="file.name">
            <a :href="getDownloadUrl(file.name, file.size, file.date)">{{ file.name }}</a><span class="float-right">{{ formatDate(file.date) }} | {{ file.size | fileSize }}</span>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      teachersToggle: false,
      principalsToggle: false,
      superintendentsToggle: false
    }
  },
  components: {
    
  },
  computed: {
    ...mapState('rvsModule', ['toggles', 'nightlyExportFiles']),
    downloadUrl() {
      return '/api/rvs/admin/export'
    }
  },
  async mounted() {
    await this.refreshData()
  },
  methods: {
    async refreshData() {
      await this.$store.dispatch('rvsModule/getToggles')
      this.teachersToggle = this.toggles.find(t => t.toggleType == 1).isEnabled
      this.principalsToggle = this.toggles.find(t => t.toggleType == 2).isEnabled
      this.superintendentsToggle = this.toggles.find(t => t.toggleType == 3).isEnabled
      await this.$store.dispatch('rvsModule/getNightlyExportFiles')
    }, 
    async toggleChanged(toggleType, isEnabled) {
      await this.$store.dispatch('rvsModule/updateToggle', { toggleType, isEnabled })
      await this.refreshData()
    },
    getToggle(toggleType) {
      return this.toggles.find(t => t.toggleType == toggleType) || { lastUpdated: null, updatedBy: null }
    },
    formatDate(date) {
      if (!date) return ''
      return new Date(date).toLocaleString()
    },
    getDownloadUrl(name, size, date) {
      const hash = btoa(`${name}|${size}|${date}`)
      return `/api/rvs/admin/nightly/file/${hash}`
    }
  },
}
</script>
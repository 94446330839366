<template>
  <div>
    <waiver-page-header :edit-mode="false" :waiver-request-snapshot-id="waiverRequestSnapshotId" :lea="resolvedLea" :current-status="currentStatus" :waiver-request-title="snapshotTitle" :request="request" :back="backToRequestList" :context="Context.Snapshot" />
    <Promised :promise="loadData" v-show="loadData">
      <template v-slot:pending>
        <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
          <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
        </div>
      </template>
      <template v-slot>
        <div v-if="request && waiverRequestSnapshotId" class="row">
          <div class="col-md-9">
            <b-alert variant="success" v-if="approvalChangeLog" show>
              <i class="fa fa-check-circle" />
              {{ approvalChangeLog.comment }} &ndash; {{ approvalChangeLog.createdDate | date }}
            </b-alert>
            <digital-learning-waiver-content :edit="false" v-if="request" v-model="request" />
          </div>
        </div>
      </template>
      <template v-slot:rejected="error" class="align-items-center justify-content-center">
        <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
      </template>
    </Promised>
  </div>
</template>

<script>
import { Promised } from 'vue-promised'
import CardStateIcon from '@/components/CardStateIcon.vue'
import { PageMixin } from '@/components/PageMixin'
import { LeaTypes } from '@/helpers/leaTypes'
import { formatDate } from '@/helpers/formatters'
import { Types, Context, WaiverTypes } from '@/stores/digitalLearning'
import { DigitalLearningWaiverMixin } from './DigitalLearningWaiverMixin'
import WaiverPageHeader from './details/WaiverPageHeader.vue'
// import ProgramOptionsWidget from './details/ProgramOptionsWidget.vue'

export default {
  data() {
    return {
      Context
    }
  },
  computed: {
    canEdit() {
      return false
    },
    approvalChangeLog() {
      const approvalChangeLog = this.request.changeLogs.find(c => c.type == "Status Change" && c.toStatus == 101)
      return this.isDigitalLearning && approvalChangeLog ? approvalChangeLog : null
    },
    snapshotTitle() {
      if (!this.request || !this.request.snapshotDate) return ''
      const title = this.request.waiverType == WaiverTypes.AMI.value ? WaiverTypes.AMI.title : WaiverTypes.DigitalLearning.title
      if (this.userContext) {
        return `${title} Snapshot ${formatDate(this.request.snapshotDate, 'short-date')}`
      }
      return title
    },
    waiverRequestSnapshotId() {
      return (this.request || {}).waiverRequestSnapshotId
    }
  },
  mixins: [PageMixin, DigitalLearningWaiverMixin],
  methods: {
    backToRequestList() {
      this.$router.go(-1)
    },
  },
   watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if (newLea && newLea.number) {
          if (newLea.type == LeaTypes.School) {
            this.resolvedLea = { ...this.leaDictionary[newLea.number].parentLea }
          }
          else this.resolvedLea = {...newLea}
          this.loadData = this.$store.dispatch(Types.actions.getWaiverRequestSnapshot, 
            { lea: this.resolvedLea.number, waiverRequestSnapshotId: this.$route.query.waiverRequestSnapshotId })
        }
      }
    },
  },
  components: {
    Promised,
    CardStateIcon,
    WaiverPageHeader,
    // ProgramOptionsWidget,
  }
}
</script>

<style>

</style>
<template>
  <div>
    <div class="card mb-2">
      <div class="card-header" :class="headerClass">
        <div class="row">
          <div class="col-md-11 mt-2 font-weight-bold">
            {{ title }}
          </div>
          <div class="col-md-1 text-right" v-if="options.allowRemove">
            <b-button variant="danger" @click="removeItem()">
              <i class="fa fw fa-small fa-trash fa-sm" title="Remove item" />
            </b-button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'request-item',
  data() {
    return {
      options: {
        allowRemove: true
      }
    }
  },
  components: {},
  mounted() {
    this.options = Object.assign(this.options, this.defaultOptions)
  },
  computed: {},
  methods: {
    removeItem() {
      this.$emit('remove', this.item.key)
    }
  },
  props: {
    item: Object,
    title: String,
    headerClass: String,
    defaultOptions: Object
  }
}
</script>

import Vue from 'vue'
import { ChartMixin } from '../ChartMixin'

const DisableModulesList = Vue.extend({
    name: 'disable-modules-list',
    data() {
        return {
            loadModules: null,
            restrictionOptions: [
                {
                    text: "All Users",
                    value: 0
                },
                {
                    text: "District Users",
                    value: 1
                },
                {
                    text: "ADE Viewers",
                    value: 2
                },
                {
                    text: "ADE Leadership",
                    value: 3
                },
                {
                    text: "OIT Admins",
                    value: 4
                },
                {
                    text: "System Admins",
                    value: 5
                }
            ],
            districtRestrictionTypes:[
                {
                    text: "Exclude",
                    value: 1
                },
                {
                    text: "Include",
                    value: 0
                },
            ],
            selectedLeas: [],
            moduleEditing: {}
        }
    },
    /* html */
    template: `
    <div class='card bg-light mt-3'>
        <div class="card-header">Modules</div>
        <loadable-chart-card v-bind:load-data="loadModules" v-bind:enableDataToggle="false" card-style="">
            <div class="col-md-12 col-sm-12 p-2">
                <b-list-group v-if="modules">
                    <b-list-group-item v-for="module in modules" v-bind:key="module.moduleId">      
                        <div class="d-flex justify-content-between align-items-center">    
                            <span class="lead d-inline">{{ module.moduleName }}</span>
                            <div class="pull-right">
                                <b-form-checkbox v-model="module.enabled" v-on:change="changeEnabled($event, module.moduleId)" switch inline>
                                Enabled
                                </b-form-checkbox>
                                <b-form-group label='* Minimum Role Required to view' class='d-inline'>
                                    <b-form-radio-group v-model="module.viewRestriction.restrictedTo" @change="changeRestrictedTo($event, module.moduleId)" :options="restrictionOptions">
                                    </b-form-radio-group>
                                </b-form-group> 
                            </div>
                        </div>
                        <div v-if="module.viewRestriction.restrictedTo == 1" class="d-flex pull-right align-items-center">
                            <b-form-group label='District User Restriction: ' inline >
                                <b-form-radio-group v-model="module.viewRestriction.districtUserRestriction" @change="changeLeaRestrictionType($event, module.moduleId)" :options="districtRestrictionTypes" >
                                </b-form-radio-group>
                            </b-form-group>
                            <b-button @click="changeRestrictedTo(1, module.moduleId)">Add or Remove Districts from view restriction</b-button>
                        </div>
                        <div v-if="module.viewRestriction.restrictedTo == 1 && module.viewRestriction.leAs.length == 0" class="text-danger">There are no LEAs selected for the view restriction. Please select at least one LEA by clicking the button to the right.</div>
                    </b-list-group-item>
                    <b-modal scrollable size='xl' :id="'leaModal'" title="Select Districts" @ok="changeLeaRestriction">
                        <b-container fluid>
                            <b-form-checkbox-group v-model="selectedLeas">
                                <b-form-row>
                                    <b-col md="6" v-for="district in districts" :key="district.LEA">                                                
                                        <b-form-checkbox
                                        :key="district.LEA"
                                        :value="district.LEA"
                                        >
                                        {{ district.LEA }} - {{ district.Name }}
                                        </b-form-checkbox>
                                    </b-col>
                                </b-form-row>
                            </b-form-checkbox-group>
                        </b-container fluid>
                    </b-modal>
                </b-list-group>
            </div>
            * Please note this setting will not grant a user access to a module for which they would not normally have access. Users will need to be added to a role which has access to a module or granted access to a module in the "Grant Access" screen for the module to be visible for that user. 
        </loadable-chart-card>
    </div>
    `,
    mixins: [ChartMixin],
    computed: {
        modules() {
            return this.$store.state.adminModule.modules
        },
        districts() {
            return Object.values(this.$store.state.globalModule.leaContext.LeaDictionary).filter(l => l.type == 2).map(l => { return { Name: l.name, LEA: l.number} })
        }
    },
    methods: {
        changeEnabled(enabled, moduleId) {
            this.$store.dispatch('adminModule/toggleModule', { enabled, moduleId })
        },
        changeRestrictedTo(restrictedTo, moduleId){
            if(restrictedTo == 1){
                var module = this.modules.find(m => m.moduleId == moduleId)
                module.viewRestriction.districtUserRestriction = module.viewRestriction.districtUserRestriction || 0
                this.moduleEditing = module
                this.selectedLeas = this.moduleEditing.viewRestriction.leAs
                
                this.$bvModal.show('leaModal')
            }
            else{
                this.$store.dispatch('adminModule/updateRestrictedTo', { restrictedTo , moduleId })
            }
        },
        changeLeaRestriction(){
            this.$store.dispatch('adminModule/updateRestrictedTo', { restrictedTo: this.moduleEditing.viewRestriction.restrictedTo, moduleId: this.moduleEditing.moduleId, leas: this.selectedLeas, districtUserRestriction: this.moduleEditing.viewRestriction.districtUserRestriction })
            console.log(module)
        },
        changeLeaRestrictionType(type, moduleId){
            var module = this.modules.find(m => m.moduleId == moduleId)
            module.viewRestriction.districtUserRestriction = type
            this.selectedLeas = module.viewRestriction.leAs
            this.moduleEditing = module
            this.changeLeaRestriction()
        }
    },
    created() {
        this.loadModules = this.$store.dispatch('adminModule/getModules')
    }
})

export { DisableModulesList }
export class UserContext {

  hasRole(role) { 
    return this.roles && this.roles.indexOf(role) >= 0
  }

  hasAnyRole(roles) { 
    return this.roles && this.roles.some(r => roles.includes(r))
  }

  hasCoordinatorCode(code) {
    return this.CoordinatorCodes && this.CoordinatorCodes.includes(code)
  }

  hasCoordinatorRoleForLEA(code, lea) {
    return this.CoordinatorRoles && this.CoordinatorRoles.some(c=>c.CoordinatorCode == code && c.SchoolLEA == lea)
  }
}
/* eslint-disable no-unused-vars */
import ApiService from '../../services/ApiService'

export class Admin {

  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
    this._fiscalYear = parseInt(this._configElement.getAttribute('data-fiscal-year'))
  }

  namespaced = true

  state = {
    areas: [],
    coops: [],
    districts: []
  }

  mutations = {
    setCoops: (state, coops) => state.coops = coops,
    setDistricts: (state, districts) => state.districts = districts
  }

  getters = {}

  actions = {
    addArea: ({ dispatch }, payload) => {
      return this._apiService.Put(`${this._baseUrl}api/dpsaft/admin/area`, payload)
    },
    getCoops: ({ commit }) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/admin/areas/coops`))
        .then(data => commit('setCoops', data))
    },
    getDistricts: ({ commit }) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/dpsaft/admin/areas/districts`))
        .then(data => commit('setDistricts', data))
    },
    updateArea: ({ dispatch }, payload) => {
      return this._apiService.Post(`${this._baseUrl}api/dpsaft/admin/area/${payload.areaId}`, payload)
    },
    assignAreaCoop: ({ dispatch }, payload) => {
      return this._apiService.Post(`${this._baseUrl}api/dpsaft/admin/areas/coops`, payload).then(() => {
        dispatch('getCoops')
      })
    },
    assignDistrictArea: ({ dispatch }, payload) => {
      return this._apiService.Post(`${this._baseUrl}api/dpsaft/admin/areas/district/`, payload)
        .then(() => {
          dispatch('getDistricts')
        })
    },
    inactivateArea: ({
      commit
    }, areaId) => {
      return this._apiService.Post(`${this._baseUrl}api/dpsaft/admin/area/${areaId}/deactivate`)
    },
    addFacilitiesInspectionItem: ({
      commit
    }, payload) => {
      return this._apiService.Post(`${this._baseUrl}api/dpsaft/admin/facilities/inspection-item`, payload)
    },
    updateFacilitiesInspectionItem: ({commit}, payload) => {
      return this._apiService.Put(`${this._baseUrl}api/dpsaft/admin/facilities/inspection-item/${payload.inspectionItemId}`, payload)
    },
    inactivateFacilitiesInspectionItem: ({commit}, itemId) => {
      return this._apiService.Put(`${this._baseUrl}api/dpsaft/admin/facilities/inspection-item/${itemId}/deactivate`)
    },
    addTransportationInspectionItem: ({commit}, payload) => {
      return this._apiService.Post(`${this._baseUrl}api/dpsaft/admin/transportation/inspection-item`, payload)
    },
    updateTransportationInspectionItem: ({commit}, payload) => {
      return this._apiService.Put(`${this._baseUrl}api/dpsaft/admin/transportation/inspection-item/${payload.inspectionItemId}`, payload)
    },
    updateDistrictTransportationStaff: ({commit}, payload) => {
      return this._apiService.Put(`${this._baseUrl}api/dpsaft/admin/transportation/district-transportation-staff`, payload)
    },
    inactivateTransportationInspectionItem: ({commit}, itemId) => {
      return this._apiService.Put(`${this._baseUrl}api/dpsaft/admin/transportation/inspection-item/${itemId}/deactivate`)
    }
  }
}
<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
  import { mapState } from 'vuex';
  import { ChartMixin } from '@/components/ChartMixin'
  import { credits } from '@/helpers/credits'
  import { LeaTypes } from '@/helpers/leaTypes'

  export default {
    mixins: [ChartMixin],
    data() {
      return {
      }
    },
    computed: {
      ...mapState('hcptModule', ['workforceStabilityIndex2']),
      ...mapState('globalModule', ['leaContext']),
      chartOptions() {
        return {
          credits: credits.MSI,
          title: {
            text: 'Percent Retention State Distribution'
          },
          xAxis: [
            {
              title: {
                text: 'Percent of Teachers Retained'
              },
              min: 0,
              max: 100,
              plotLines: this.plotLines
            }
          ],
          yAxis: {
            title: {
              text: ''
            },
            // hide y axis
            labels: {
              enabled: false
            },
          },
          tooltip: {
            enabled: false            
          },
          chart: {
            marginTop: 70,
            marginRight: 20,
            marginBottom: 70,
            marginLeft: 20
          },
          series: [{
            name: 'Percent',
            type: 'areaspline',
            pointPadding: 0,
            groupPadding: 0,
            marker: {
              enabled: false
            },
            states: { hover: { enabled: false } },
            pointPlacement: 'between',
            data: this.histogram(this.workforceStabilityIndex2.percentRetention, 2),
            showInLegend: false
          }]
        }   
      },
      plotLines() {
        const stateAverage = this.workforceStabilityIndex2.percentRetention.reduce((acc, val) => acc + val, 0) / this.workforceStabilityIndex2.percentRetention.length

        const schoolLines = this.selectedLea.type == LeaTypes.District || this.selectedLea.type == LeaTypes.School ? [this.workforceStabilityIndex2.leaValues[0]].map((leaRow) => {
          const lea = this.leaContext.LeaDictionary[leaRow.lea]
          const leaName = `${lea.name} (${lea.number})`
          return {
            color: lea.type == LeaTypes.District ? '#0000FF' : '#FF0000',
            width: 2,
            value: leaRow.percent_Retention * 100.0,
            dashStyle: 'shortdot',
            label: {
              text: leaName,
              align: 'left',
              x: 2,
              y: 0
            },
            zIndex: 5
          }
        }) : [] 

        return [{
          color: '#008000',
          width: 2,
          value: stateAverage * 100.0,
          dashStyle: 'shortdot',
          label: {
            text: 'State Average',
            align: 'left',
            x: 2,
            y: 0
          },
          zIndex: 5
        }, ...schoolLines]
      }
    },
    methods: {
      dataItems() {
        return this.plotLines.map((plotLine) => {
          return {
            'LEA': plotLine.label.text,
            '% Retention': plotLine.value.toFixed(1) + '%'
          }
        })
      },
      histogram(data, step) {
        const min = Math.min(...data) * 100.0
        const max = Math.max(...data) * 100.0
        const bins = []
        for (let i = min; i <= max; i += step) {
          bins.push({
            count: data.filter(d => d * 100.0 >= i && d * 100.0 < i + step).length,
            x: Math.floor((i + i + step) / 2.0),
            y: 0
          })
        }
        const total = bins.reduce((acc, bin) => acc + bin.count, 0)
        bins.forEach(bin => bin.y = (bin.count / total) * 100.0)
        return bins
      },
    }
  }
</script>
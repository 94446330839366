<template>
  <div class="dpsaft-container">
    <router-view />
  </div>
</template>

<script>
import { PageMixin } from '../../PageMixin'
import { router } from '../../../routes'
import { mapState, mapGetters } from 'vuex'
import { AreaAdmin } from './areas'
import { FacilitiesAdmin } from './facilities'
import { TransportationAdmin } from './transportation'

router.addRoutes([
  {
    path: '/administration/dpsaft/areas',
    component: AreaAdmin.Home,
    children: [
      {
        name: 'areas-default',
        path: '',
        redirect: { name: 'areas' }
      },
      {
        name: 'coops',
        path: 'coops',
        component: AreaAdmin.Coops
      },
      {
        name: 'areas',
        path: 'areas',
        component: AreaAdmin.Areas
      },
      {
        name: 'districts',
        path: 'districts',
        component: AreaAdmin.Districts
      }
    ]
  },
  {
    path: '/administration/dpsaft/facilities',
    component: FacilitiesAdmin.Home,
    name: 'facilities-admin',
    children: [
       {
        name: 'facilities-default',
        path: '',
        redirect: { name: 'inspection-items' }
      },
      {
        name: 'inspection-items',
        path: 'inspection-items',
        component: FacilitiesAdmin.InspectionItems
      },
      {
        name: 'buildings',
        path: 'buildings',
        component: FacilitiesAdmin.Buildings
      },
      {
        name: 'import-buildings',
        path: 'buildings/import',
        component: FacilitiesAdmin.BuildingsImport
      }
    ]
  },
  {
    path: '/administration/dpsaft/transportation',
    component: TransportationAdmin.Home,
    name: 'transportation-admin',
    children: [
       {
        name: 'transportation-default',
        path: '',
        redirect: { name: 'trans-inspection-items' }
      },
      {
        name: 'trans-inspection-items',
        path: 'trans-inspection-items',
        component: TransportationAdmin.TransInspectionItems
      },
      {
        name: 'buses',
        path: 'buses',
        component: TransportationAdmin.Buses
      },
      {
        name: 'staff',
        path: 'staff',
        component: TransportationAdmin.Staff
      }
    ]
  },
  {
    path: '/administration/dpsaft',
    redirect: '/administration/dpsaft/areas'
  }
])

export default {
  name: 'dpsaft-admin',
  data() {
    return {
      displayLea: this.selectedLea
    }
  },
  mixins: [PageMixin],
  components: {
  },
  computed: {
    ...mapState('globalModule', ['userContext', 'leaDictionary']),
    ...mapGetters('globalModule', ['leaDictionary']),
    userLea() {
      return this.leaDictionary[this.userContext.districtLEA]
    }
  },
  methods: {},
  watch: {
    selectedLea: {
      immediate: true,
      handler() {}
    }
  }
}
</script>

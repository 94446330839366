<template>
  <div>
    <buildings :show-action-column="true" :hide-columns="hideColumns" :criteria-visible="criteriaVisible" :allow-state-level="allowStateLevel">
      <template v-slot:action-column-content="data">
        <router-link :to="{name: 'Facilities/Building/InspectionHistory', params: {districtLea: data.districtLEA, schoolLea: data.schoolNumber, buildingNumber: data.buildingNumber }}" title="Inspection History">
          <i class="fa fa-clock" />
        </router-link>
      </template>
    </buildings>
  </div>
</template>

<script>
import Buildings from './Buildings'
import { mapState } from 'vuex'

export default {
  name: 'building-inventory',
  data() {
    return {
    }
  },
  computed: {    
    ...mapState('globalModule', ['userContext']),
    allowStateLevel(){
      return this.userContext.isADEUser
    }
  },
  components: {
    Buildings
  },
  props: {
    hideColumns: Array,
    criteriaVisible: String
  }
}
</script>

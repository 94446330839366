<template>
  <div>
    <p class="font-weight-bold mt-3">
      {{ question.text }}
    </p>
    <ul class="list-group list-group-flush" v-if="!editMode">
      <li class="list-group-item bg-transparent borderless font-italic">
        {{ value || '(no response provided)' }}
      </li>
    </ul> 
            
    <span v-if="editMode">
      <b-form-textarea rows="1" max-rows="10" type="text" class="d-inline font-italic" v-model.trim="$v.survey[question.id].$model" />
    </span>
  </div>
</template>

<script>

/// Display a textarea for free text entry.

export default {
  inject: ['$v'],
  name: 'question-free-text',
  computed: {
    value() {
      return this.$v.survey[this.question.id].$model
    }
  },
  props: {
    question: {
      type: Object,
      default: () => {}
    },
    editMode: {
      type: Boolean,
      default: () => false
    }
  },
}
</script>
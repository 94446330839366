<template>
  <div>
    <div v-if="SelectedBatch">
      <b-button-group class="my-2" style="display:flex;">
        <b-button variant="primary" @click="statusFilter=null">
          All <span class="badge badge-light text-primary">{{ aggregates.all }}</span>
        </b-button>
        <b-button variant="secondary" @click="statusFilter='skip'">
          Skip <span class="badge badge-light text-secondary">{{ aggregates.skip }}</span>
        </b-button>
        <b-button variant="warning" @click="statusFilter='todo'">
          ToDo <span class="badge badge-dark text-warning">{{ aggregates.todo }}</span>
        </b-button>
        <b-button variant="success" @click="statusFilter='done'">
          Done <span class="badge badge-light text-success">{{ aggregates.done }}</span>
        </b-button>
        <b-button variant="danger" @click="statusFilter='ground'">
          Ground <span class="badge badge-light text-danger">{{ aggregates.ground }}</span>
        </b-button>
      </b-button-group>
    </div>
    <div class="d-flex flex-row" v-if="SelectedBatch">
      <a href="javascript:history.go(-1)" class="pt-1">
        <i class="fas fa-arrow-alt-circle-left fa-2x" />
      </a>
      <b-input-group class="mx-2">
        <b-form-input placeholder="Search Bus #, Sticker, or VIN..." v-model="textFilter" />
        <b-input-group-append>
          <b-button variant="info" @click="textFilter=null">
            Clear
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <a href="javascript:history.go(1)" class="pt-1">
        <i class="fas fa-arrow-alt-circle-right fa-2x" />
      </a>
    </div>
    <h3 class="mt-2">
      {{ SelectedBatch.districtName }} - {{ SelectedBatch.districtLea }}
    </h3>
    <h4 class="mt-2 text-secondary">
      {{ SelectedBatch.description }}
    </h4>
    <div class="alert alert-warning text-center" v-if="filteredInspections.length == 0">
      No buses found that match the filter
    </div>
    <b-alert v-if="SelectedBatch.duplicateBusNumbers && SelectedBatch.duplicateBusNumbers.length > 0" variant="danger" show>
      The following bus number(s) are assigned to multiple buses:
      <ul class="mb-0">
        <li v-for="dupe in SelectedBatch.duplicateBusNumbers" :key="dupe">
          {{ dupe }}
        </li>
      </ul>
    </b-alert>
    <inspection-bus-card
      v-for="(i) in filteredInspections"
      :key="i.inspectionId"
      :batch-id="batchId"
      :inspection="i"
      :is-dupe="SelectedBatch.duplicateBusNumbers && SelectedBatch.duplicateBusNumbers.includes(i.busNumber)"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import InspectionBusCard from './InspectionBusCard'
import { InspectionStatuses, InspectionBatchStatus } from '../../common/Enums'
import { Types, isBusGrounded } from '../../../../stores/dpsaft/trans'

function completed(inspection) {
  return inspection.status == InspectionStatuses.Completed
}
function skipped(inspection) {
  return inspection.status == InspectionStatuses.Skipped
}
function todo(inspection) {
  return inspection.status == InspectionStatuses.NotStarted || 
    inspection.status == InspectionStatuses.InProgress ||
    inspection.status == InspectionStatuses.Paused
}
export default {
  name: 'inspection-buses',
  data() {
    return {
      statusFilter: null,
      textFilter: null
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext', 'isOnline']),
    ...mapState('DPSAFT/Trans', [Types.state.SelectedBatch, Types.state.inspectionBatches]),
    aggregates() {
      return {
        all: this.SelectedBatch.inspections.length,
        done: this.SelectedBatch.inspections.filter(completed).length,
        todo: this.SelectedBatch.inspections.filter(todo).length,
        skip: this.SelectedBatch.inspections.filter(skipped).length,
        ground: this.SelectedBatch.inspections.filter(isBusGrounded).length
      }
    },
    filteredInspections() {

      var lambda = () => true

      switch(this.statusFilter) {
        case 'skip': 
          lambda = skipped
          break
        case 'ground': 
          lambda = isBusGrounded
          break
        case 'done':
          lambda = completed
          break
        case 'todo':
          lambda = todo
          break
      }

      const statusFiltered = this.SelectedBatch.inspections.filter(lambda)

      if (this.textFilter) {
        var regex = new RegExp(this.textFilter, "i")
        return statusFiltered.filter((i) => {
          const busString = (i.busNumber || '') + '|' + (i.stickerNumber || '') + '|' + (i.vin || '')
          return regex.test(busString)
        })
      } else {
        return statusFiltered
      }
    }
  },
  components: {
    InspectionBusCard
  },
  props: {
    batchId: Number
  },
  watch: {
    batchId: {
      immediate: true,
      handler(newBatchId) {
        if (this.isOnline) {
          const self = this
          this.$store.dispatch(Types.actions.getBatch, { batchId: newBatchId }).then(function() {
            if(self.SelectedBatch.status == InspectionBatchStatus.NotStarted) {
              self.$store.dispatch(Types.actions.updateBatchStatus, { batchId: newBatchId, status: InspectionBatchStatus.InProgress })
            }
          })
        } 
        else {
          if (!this.SelectedBatch || this.SelectedBatch.batchId != newBatchId) {
            this.$store.commit(Types.mutations.setSelectedBatch, this.inspectionBatches.find(b => b.batchId == newBatchId))
          }
        }
      }
    }
  }
}
</script>

const ClosureReasons = [
  { value: 0, label: 'Not Set' }, 
  { value: 1, label: 'Inclement Weather' },
  { value: 2, label: 'Tornado Damage' },
  { value: 3, label: 'Power Outage' },
  { value: 4, label: 'Plumbing Issue' },
  { value: 5, label: 'HVAC Issue' },
  { value: 6, label: 'Fire Damage' },
  { value: 7, label: 'Contagious Disease' },
  { value: 8, label: 'Bomb Threat' },
  { value: 9, label: 'Gun Shooting' },
  { value: 10, label: 'Other Emergency' },
  { value: 11, label: 'Other Non-Emergency' }
]


export { ClosureReasons }
<template>
  <div class="coteachers-page">
    <b-card v-if="(coTeacherList || []).length > 0">
      <co-teacher-list :hide-lea="false" :teacher-list="coTeacherList" :verifiable="true" />
    </b-card>
    <b-card v-else>
      <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
        <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import CoTeacherList from '../common/CoTeacherList.vue'
import CardStateIcon from '@/components/CardStateIcon.vue'

export default {
  components: {
    CoTeacherList,
    CardStateIcon
  },
  computed: {
    ...mapState('rvsModule', ['coTeacherList', 'fiscalYear']),
  },
  methods: {
    async reloadData() {
      if (this.fiscalYear)
        this.loading = this.$store.dispatch('rvsModule/getCoTeacherList', this.selectedLea.number)
    },
  },
  watch: {
    fiscalYear: {
      handler() {
        this.reloadData()
      }
    },
    selectedLea: {
      immediate: true,
      handler() {
        this.reloadData()
      }
    }
  }
}
</script>
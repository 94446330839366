import * as moment from 'moment'
import { round } from '@/helpers/mathHelpers'

function toPercentage (value, decimals, appendPercentSign) {
  return round(value * 100, decimals || 0) + (appendPercentSign ? '%' : '')
}

function formatAsLEA(e) {
  return String(e).substring(0, 7)
}

function formatAY(ay, format) {
  if (typeof(ay)!=='number') ay = Number(ay)
  return formatFY(ay+10, format)
}

function formatFY(fy, format) {

  if (!fy) return null
  
  if ((format || 'long') == 'long') {
    return `${fy+1989}/${fy-10}`
  } else {
    return `${fy-11}/${fy-10}`
  }
}

function formatCurrency(value) {
  if (value != null && typeof(value) !== 'undefined') {
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    })
  }        
  return value
}

function formatDate(value, format) {
  if (value) {
    switch(format) {
        case 'short-date': return moment(value).format("MM/DD/YYYY")
        case 'time': return moment(value).format("h:mm a")
        default: return moment(value).format(format || "MM/DD/YYYY h:mm a")
    }
  } 
}

function formatYN(value) {
  switch (value) {
    case null: return '';
    case 'Y': return 'Yes';
    case 'N': return 'No';
  }
}

// function formatSchoolYear(value) {
//   if (value && !isNaN(value)) {
//     return `${Number(value)+1999}/${value}`
//   } 
// }

//usage JSON.parse(string, toCamelCase)
// input: '{ PropertyOne: value, PropertyTwo: value }'
// output: '{ propertyOne: value, propertyTwo: value }'
function toCamelCase(key, value) {
  if (value && typeof value === 'object'){
    for (var k in value) {
      if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
        value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
        delete value[k];
      }
    }
  }
  return value;
}


function formatPhone(phone) {
  if (!phone) return null

  var ext = phone.split('+')[1]
  var formatted = phone.replace(/[^0-9]/g, '')
          .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  return ext ? `${formatted} ext. ${ext}`: formatted
}

export { formatAsLEA, formatCurrency, formatFY, formatDate, formatYN, toPercentage, toCamelCase, formatAY, formatPhone }

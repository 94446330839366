<template>
  <div>
    <!-- UNCOMMENT BELOW TO REVEAL SUPER POWER OF GENERATING ESA SNAPSHOTS FOR ALL DISTRICTS -->
    <b-button size="sm" variant="primary" @click="snapshotAllDistricts()" class="mx-1" v-if="isSuperAdmin">
      <i class="fa fa-fw fa-file-pdf" title="Snapshot Plans" />Take Snapshots
    </b-button>

    <div class="my-2 d-print-none">
      <b-select :options="statusOptions" v-model="selectedStatus" class="w-25" />
      <b-form-checkbox v-model="selectedUtilization" class="w-25 d-inline mx-2 mt-1" switch> 
        Only 100% Budget Utilization
      </b-form-checkbox>
      <excel-button title="Enhanced Student Achievement Plans" />
    </div>
    <paginated-table :items="filteredPlans" :fields="fields" sort-by="createDate" :sort-desc="true">
      <template v-slot:cell(districtLea)="data">
        <div>
          {{ leaFormatter(data.value) }} 
        </div>
      </template>
      <template v-slot:cell(percentUtilized)="data">
        <b-progress min="0" :max="100" style="height: 1.5rem;">
          <b-progress-bar :value="data.value" />
        </b-progress>
        <div class="progress-overlay progress-overlay-1-point-6">
          {{ data.value }}%
        </div>
      </template>
      <template v-slot:cell(percentAllocation)="data">
        <b-progress min="0" :max="100" style="height: 1.5rem;">
          <b-progress-bar :value="data.value" />
        </b-progress>
        <div class="progress-overlay progress-overlay-1-point-6">
          {{ data.value.toFixed(2) }}%
        </div>
      </template>
      <template v-slot:cell(updatedDate)="data">
        {{ data.value | date }}
      </template>
      <template v-slot:cell(superintendent)="data">
        <a :href="`mailto:${data.item.email}`">
          {{ data.value }}
        </a>
      </template>
      <template v-slot:cell(phone)="data">
        <a :href="`tel:${data.item.phone}`">
          {{ data.value }}
        </a>
      </template>
      <template v-slot:cell(snapshotYears)="data">
        <table class="w-100 table table-sm table-borderless p-0 m-0">
          <thead> 
            <th v-for="snapshotYear in fiscalYears(data.value || [])" :key="snapshotYear" class="text-center text-secondary">
              {{ formatSchoolYear(snapshotYear-1) }}-{{ formatSchoolYear(snapshotYear) }} Plan
            </th>
          </thead>
          <tbody>
            <td v-for="snapshotYear in fiscalYears(data.value || [])" :key="snapshotYear" class="text-center">
              <b-button 
                v-for="snapshot in (data.value || []).filter(s => s.fiscalYear == snapshotYear)"
                :key="snapshot.fiscalYear + snapshot.month"
                size="sm" 
                variant="link" 
                :href="`/api/esa/${data.item.districtLea}/plan/snapshot/${snapshot.fiscalYear}/${snapshot.month}`">
                <span class="text-secondary fas fa-file-pdf" /> {{ formatSnapshotYear(snapshot) }}
              </b-button>
            </td>
          </tbody>
        </table>
      </template>
      <template v-slot:cell(status)="data">
        <workflow-status-badge :state-map="StateMap" :status="data.value" :show-label="false" :document="data.item" />
      </template>
      <template v-slot:cell(actionColumn)="data">
        <b-button size="sm" variant="primary" :to="{name: 'esa-plan-district', query: { lea: data.item.districtLea, id: data.item.leaPlanId }}" class="mr-1">
          <i class="fa fa-fw fa-eye" title="View Plan" />
        </b-button>
        <b-button size="sm" variant="primary" @click="snapshotPdf(data.item.districtLea, data.item.fiscalYear)" class="mx-1" v-if="isSuperAdmin">
          <i class="fa fa-fw fa-file-pdf" title="Snapshot Plan" />Take Snapshot
        </b-button>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PaginatedTable from '@/components/PaginatedTable'
import WorkflowStatusBadge from '@/components/workflow/WorkflowStatusBadge.vue'
import ExcelButton from '@/components/ExcelButton.vue'
import { Types, StateMap } from '@/modules/esa/stores/'
import groupBy from 'lodash.groupby'

export default {
  data() {
    return {
      StateMap,
      selectedStatus: null,
      selectedUtilization: false,
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.plans, Types.state.districtBudgets, Types.state.districtAllocations]),
    ...mapState('globalModule', ['userContext']),
    fields() { 
      let fields = [
        { key: 'districtLea', label: 'District', sortable: true, },
        { key: 'superintendent' },
        { key: 'phone' },
        { key: 'updatedDate', label: 'Latest Modified', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'percentUtilized', label: 'Budget Utilized', sortable: true },
        { key: 'percentAllocation', label: 'Percent of Allocation Budget', sortable: true },
        { key: 'snapshotYears', label: 'Snapshots', class: 'text-center nowrap p-0' },
        { key: 'actionColumn', class: 'text-right nowrap', label: '' },
      ]
      return fields
    },
    filteredPlans() {
      const self = this
      let plans = this.plans.filter(p => this.selectedStatus != null ? p.status == this.selectedStatus : true)
        .filter(p => {
          if (!this.selectedUtilization) return true
          return self.districtBudgets.find(b => b.lea == p.districtLea)?.percentUtilized >= 99
        })
        .map(plan => {
          const district = this.leaDictionary[plan.districtLea]
          const districtBudget = self.districtBudgets.find(b => b.lea == plan.districtLea)
          const budgeted = districtBudget?.budgeted || 0
          const allocation = self.districtAllocations.find(b => b.lea == plan.districtLea)?.total || 0

          return {
            ...plan,
            superintendent: district ? `${district.adminFirstName} ${district.adminLastName}` : '',
            phone: district ? district.adminPhoneNumber : '',
            email: district ? district.adminEmailAddress : '',
            percentUtilized: districtBudget?.percentUtilized || 0,
            percentAllocation: allocation > 0 ? ((budgeted / allocation) * 100.0) : 0
          }
        })
      return plans
    },
    statusOptions() {
      // eslint-disable-next-line no-unused-vars
      const statuses = Array.from(StateMap, ([_name, value]) => ({ text: typeof value.status == 'function' ? value.status() : value.status, value: value.code }))
      return [{ text: 'All Statuses', value: null }].concat(statuses.filter(s => s.value <= 11))
    },
    isSuperAdmin() {
      const user = (this.userContext || { emailAddress: ''})
      return user.isInsightsAdmin && (user.emailAddress || '').toLowerCase().includes('@mainstream-tech.com')
    }
  },
  methods: {
    excelExportData() {
      const self = this
      return {
        name: 'District ESA Plans',
        items: this.filteredPlans.map(plan => {
          const district = this.leaDictionary[plan.districtLea]
          return {
            'District LEA': plan.districtLea,
            'District Name': district?.name,
            'Status': self.statusOptions.find(s => s.value == plan.status)?.text,
            'Superintendent': plan.superintendent,
            'Phone': plan.phone,
            'Email': plan.email,
          }
        })
      }
    },
    fiscalYears(snapshots) {
      return Object.keys(groupBy(snapshots, snapshot => snapshot.fiscalYear))
    },
    formatSchoolYear(year) {
      return `${parseInt(year) + 1990}`
    },
    formatSnapshotYear(snapshot) {
      if (snapshot.month == 'Oct')
        return `${snapshot.fiscalYear + 1989} ${snapshot.month}`

      return `${snapshot.fiscalYear + 1990} ${snapshot.month}`
    },
    async snapshotAllDistricts() {
      for (const plan of this.plans) {
        console.log('Processing : ', plan.districtLea, ' FY', plan.fiscalYear)
        try {
          await this.snapshotPdf(plan.districtLea, plan.fiscalYear)
        } catch (e) {
          console.log('Error: ', e)
        }

        console.log('Processed : ', plan.districtLea, ' FY', plan.fiscalYear)
      }
    },
    async snapshotPdf(lea, fiscalYear) {
      var debug = window.pdfdebug ? '&pdfdebug=true' : ''
      return await this.$store.dispatch('globalModule/downloadPdf', {
        url: this.url || (document.location.protocol + "//" + document.location.hostname + ':' + document.location.port
             + document.location.pathname + `?response-format=pdf&lea=${lea}&fiscalYear=${fiscalYear}&esaSnapshot=true${debug}`),
      })
    }
  },
  components: {
    PaginatedTable,
    WorkflowStatusBadge,
    ExcelButton,
  },
  props: {
    districtLea: String,
  },
}
</script>

<style>
  .nowrap {
    white-space: nowrap;
  }
  .progress-overlay-1-point-6 {
    top: -1.6rem !important;
  }
</style>
<template>
  <div>
    <b-card header="Facilities Inbox" title="The inspection batches below have been routed to you and are awaiting your attention.">
      <p>
        Open the batch by clicking the 
        <i class="far fa-folder-open" style="color: rgb(9, 123, 255)" v-if="userContext.hasRole(Roles.SPM_M) || userContext.hasRole(Roles.APM_M)" /> 
        <i class="far fa-eye" style="color: rgb(9, 123, 255)" v-if="userContext.hasCoordinatorCode(Roles.DistrictFacilitesCoordinatorCode)" /> 
        icon.  Once you are finished with your task you can remove the item from your Inbox by closing the route from the Inspection Batch screen or by clicking the <i class="fa fa-times fa-fw text-danger" /> icon.
      </p>
      <paginated-table :items="items" :fields="fields">
        <template v-slot:cell(reviewStatus)="data">
          <span class="badge" :class="data.item.rowBadge.badgeClass">{{ data.item.rowBadge.text }}</span>
        </template>
        <template v-slot:cell(routedBy)="data">
          <span v-if="data.item.routedByUserInfo" :title="`Phone: ${data.item.routedByUserInfo.telephoneNumber}`"><a :href="`mailto:${data.item.routedByUserInfo.emailAddress}`">{{ data.item.routedByUserInfo.fullName }}</a></span>
          <span v-else>{{ data.value }}</span>
        </template>
        <template v-slot:cell(routedDate)="data">
          {{ data.value | date }}
        </template>
        <template v-slot:head(action)>
          Action
        </template>
        <template v-slot:cell(action)="data">
          <router-link v-if="userContext.hasRole(Roles.SPM_M) || userContext.hasRole(Roles.APM_M)" :to="{name: 'Facilities/Batch/Edit', params: { batchId: data.item.batchId }, query: {routeId: data.item.id }}" title="Open Batch">
            <i class="far fa-folder-open" />
          </router-link>
          <router-link v-if="userContext.hasCoordinatorCode(Roles.DistrictFacilitesCoordinatorCode)" :to="{name: 'Facilities/Batch/View', params: { batchId: data.item.batchId }, query: {routeId: data.item.id }}" title="View Batch">
            <i class="far fa-eye" />
          </router-link>
          <b-link title="Close Route" @click="closeRoute(data.item.id)">
            <i class="fa fa-times fa-fw text-danger" />
          </b-link>
        </template>
      </paginated-table>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Types, Roles } from '../../../stores/dpsaft/facilities'
import { FacilitiesBatchReviewStatus } from '../../dpsaft/common/Enums'
import PaginatedTable from '../../PaginatedTable'
export default {
  data() {
    return {
      Roles,
      FacilitiesBatchReviewStatus,
      fields: [
        { key: "routedBy", sortable: true },
        { key: "routedDate", sortable: true },
        { key: "batchId", sortable: true },
        { key: "reviewStatus", sortable: true },
        { key: "comment", sortable: false },
        { key: "action", sortable: false, class: "text-right" },
      ]
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState(Types.path, [Types.state.ModuleWorkQueue]),
    items() {
      return this.ModuleWorkQueue.filter(q=>q.routeClosedDate == null).map((i)=>Object.assign({...i}, {action: null, rowBadge: this.FacilitiesBatchReviewStatus.DisplayOptions(i.reviewStatus)}))
    }
  },
  methods: {
    closeRoute(routeId) {
      this.$bvModal.msgBoxConfirm('This will remove the route from your Inbox. Please confirm.')
      .then(result => {
        if (result) {
          this.$store.dispatch(Types.actions.closeRoute, { routeId: routeId })
        }
      }) 
    }   
  },
  components: {
    PaginatedTable
  }
}
</script>

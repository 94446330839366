export default [
  { value: "1520000", text: "Arch Ford" },
  { value: "9999999", text: "Arkansas Public School Resource Center" },
  { value: "3520000", text: "Arkansas River" },
  { value: "5620000", text: "Crowley's Ridge" },
  { value: "1020000", text: "Dawson" },
  { value: "6720000", text: "Dequeen Mena" },
  { value: "5420000", text: "Great Rivers" },
  { value: "2420000", text: "Guy Fenter" },
  { value: "3320000", text: "North Central" },
  { value: "3820000", text: "Northeast" },
  { value: "7221000", text: "Northwest" },
  { value: "0520000", text: "Ozark Unlimited Resource (O.U.R.)" },
  { value: "9999000", text: "Pulaski County (Little Rock, PCSSD, North Little Rock)" },
  { value: "5220000", text: "South Central" },
  { value: "2220000", text: "Southeast" },
  { value: "2920000", text: "Southwest" },
  { value: "7320000", text: "Wilbur D. Mills" }
]
import Vue from 'vue'
import sortBy from 'lodash/sortBy'

const JobGroupEditor = Vue.extend({
    name: 'job-group-editor',
    template:
        /* html */
        `<div>
        <b-modal id="job-group-editor-modal" ref="job-group-editor-modal" title="Course Group Administration">
            <ul v-if="errors.length">
                <li class='text-danger' v-for="e in errors">{{ e }}</li>
            </ul>
            <b-form v-if="value">
                <b-form-group label="Group Name">
                    <b-form-input placeholder="Enter group name" v-model=value.groupName></b-form-input>
                </b-form-group>
                
                <b-form-group label="Current Child Groups">
                <span v-if="childGroups.length === 0">None</span>
                <ul v-if="value.id">
                        <li v-for="child in childGroups">{{ child.groupName }}</li>
                    </ul>
                </b-form-group>
                <b-form-group label="Select Parent">
                    <b-form-select v-bind:options='parentGroups' text-field='groupName' value-field="id" v-model="value.parentGroupId">
                    </b-form-select>
                </b-form-group>
                <span v-show="value.parentGroupId > 0">Only one level of hierarchy is supported. If you select a parent group, then child groups will be removed.</span>
                <b-form-group label="Course Codes">
                    <b-form-textarea
                    id="textarea"
                    v-model="value.codeString"
                    placeholder="Enter course codes..."
                    rows="3"
                    max-rows="6"
                ></b-form-textarea>
                </b-form-group>
            </b-form>
            <template v-slot:modal-footer>
                <div class="w-100">
               
                <b-button
                    variant="secondary"
                    class="float-right "
                    @click="onEditingCancelled"
                >
                    Cancel
                </b-button>
                <b-button
                variant="outline-primary"
                class="float-right mr-2"
                @click="onEditingComplete"
            >
                Ok
            </b-button>
                <b-button
                v-if="value.id > 0"
                variant="outline-danger"
                class="float-left"
                @click="onDeleteGroup"
            >
                Delete
            </b-button>
                </div>
            </template>
        </b-modal>
    </div>
    `,
    data() {
        return {
            codeString: '',
            selectedParentId: null,
            errors: []
        }
    },
    methods: {
        onEditingCancelled() {
            this.errors = []
            this.$refs['job-group-editor-modal'].hide();
        },
        onDeleteGroup() {

            console.log('delete');

            this.$bvModal.msgBoxConfirm("Are you sure you want to delete this group?")
                .then(result => {
                    if (result) {
                        this.$store.dispatch('shortageReportAdminModule/deleteJobGroup', {
                                id: this.value.id
                            })
                            .then(() => {
                                return this.$store.dispatch('shortageReportAdminModule/getJobGroups', {
                                    fiscalYear: this.fy
                                })
                            })
                            .then(() => {
                                this.$bvModal.hide('job-group-editor-modal')
                                this.$emit('item-deleted')
                            })

                    }
                })
        },
        formValid() {
            this.errors = [];

            if (!this.value.groupName) {
                this.errors.push('Group Name is required');
            }

            return this.errors.length === 0;
        },
        onEditingComplete() {

            console.log('complete');

            if (this.formValid()) {
                var regx = /\s+|,|;/
                var codes = this.value.codeString ? this.value.codeString.split(regx).map((item) => {
                    return {
                        jobOrCourseCode: item.trim()
                    }
                }) : [];

                const payload = {
                    id: this.value.id,
                    parentGroupId: this.value.parentGroupId,
                    groupName: this.value.groupName,
                    fy: this.fy,
                    jobCodes: codes
                }

                this.$store.dispatch('shortageReportAdminModule/saveJobGroupChanges', payload)
                    .then(() => {
                        this.$bvModal.hide('job-group-editor-modal')
                        this.$emit('item-updated')
                    })
            } else {
                console.log('validation failed');
            }
        }
    },
    computed: {
        parentGroups() {
            return sortBy(this.$store.state.shortageReportAdminModule.reportGroups.filter(f => f.parentGroupId == null), "groupName")
        },

        childGroups() {
            return this.$store.state.shortageReportAdminModule.reportGroups.filter(f => f.parentGroupId == this.value.id)
        }
    },
    watch: {},
    props: {
        value: Object,
        fy: Number
    }
})

export {
    JobGroupEditor
}
<template>
  <div v-if="currentStudent.studentInformation">
    <student-information-toolbar v-if="!currentStudent.isReadOnly" :can-save="isValid()" :save-method="save.bind(null, 'PostsecondaryPlanningResponse')" @cancel="cancel" />

    <div class="row">
      <b-form-group label="Overall Plan" class="col-md-6 col-sm-12">
        <b-form-select :disabled="currentStudent.isReadOnly" :options="staticMetadata.postSecondaryPlanning.overallPlan" v-model="local.overallPlan" />
      </b-form-group>
    </div>

    <div v-if="local.overallPlan == 'college'">
      <b-row class="mb-2">
        <b-col class="text-right">
          <b-button v-if="!currentStudent.isReadOnly" @click="addCollege" variant="primary">
            <i class="fa fa-plus-circle fa-fw" />
            Add College
          </b-button>
        </b-col>
      </b-row>

      <h2 class="caption mb-4">
        Colleges
      </h2> 

      <div v-if="local.colleges.length">
        <college
          v-for="(c, idx) in local.colleges" 
          :sequence="idx+1"
          :key="idx"
          v-model="local.colleges[idx]"
          class="mb-4"
          @remove="removeCollege(c)"
          :readonly="currentStudent.isReadOnly"
        />
      </div>
      <div v-else>
        <b-alert show variant="info">
          No colleges have been added for this student.
        </b-alert>
      </div>

      <b-form>
        <b-form-group label="Has student completed their FAFSA?" label-cols="6" v-if="['12','GR'].includes(currentStudent.grade)">
          <b-form-select :disabled="currentStudent.isReadOnly" :options="staticMetadata.common.yesNo" v-model="local.completedFAFSA" />
        </b-form-group>
        <b-form-group label="Has student completed the YOUniversal Scholarship Application?" label-cols="6" v-if="['12','GR'].includes(currentStudent.grade)">
          <b-form-select :disabled="currentStudent.isReadOnly" :options="staticMetadata.postSecondaryPlanning.yesNoPending" v-model="local.completedYOUniversal" />
        </b-form-group>
        <b-form-group label="Has student applied for scholarships not offered by the schools they have applied to?" label-cols="6">
          <b-form-select :disabled="currentStudent.isReadOnly" :options="staticMetadata.common.yesNo" v-model="local.appliedForOtherScholarships" />
        </b-form-group>
      </b-form>

      <scholarships v-model="local.otherScholarships" :readonly="currentStudent.isReadOnly" />
    </div>

    <h2 class="caption">
      Additional Notes
    </h2>
    <b-form-textarea :disabled="currentStudent.isReadOnly" v-model="local.additionalNotes" />
  </div>
</template>

<script>
import { StudentComponentMixin } from './StudentComponentMixin'
import College from './College'
import Scholarships from './Scholarships'
import { metadata as staticMetadata } from '@/modules/cte/stores/careercoach/metadata'

export default {
  components: {
    College,
    Scholarships
  },
  data() {
    return {
      supportingData: {},
      staticMetadata,
    }
  },
  mixins: [StudentComponentMixin],
  mounted() {
    this.supportingData = {}
  },
  methods: {
    isValid() {
      const colleges = ([...this.local.colleges] || [])
      return this.validation.$dirty && colleges.filter(c => !c.dateApplied).length < 1
    },
    addCollege() {
      if (!this.local.colleges) this.local.colleges = []

      this.local.colleges.push({
        attending: false,
        college: null,
        degreeType: null,
        major: null,
        dateApplied: null,
        collegeAcceptanceStatus: null,
        dateAccepted: null,
        appliedForInstitutionalScholarships: 'No',
        scholarshipStatus: null,
        scholarshipAmount: null,
        scheduleVisit: false })
    },
    removeCollege(college) {
      this.local.colleges = this.local.colleges.filter(c => c != college)
    },
    preSave() {
      // if overall plan is not 'college', let's clear out any college values
      if (this.local.overallPlan != 'college')
      {
        this.local.colleges = []
        this.local.completedFAFSA = null
        this.local.completedYOUniversal = null
        this.local.appliedForOtherScholarships = null
        this.local.otherScholarships = []
      }
    }
  }
}
</script>
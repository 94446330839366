<template lang="html">
  <div v-if="showFilter" class="growth-filter py-2">
    <b-form inline>
      <b-form-group label="School Year" class="d-none">
        <b-form-select />
      </b-form-group>
      <b-form-group label="Grade Span" class="d-none">
        <b-form-select />
      </b-form-group>
      <b-form-group label="Subject">
        <b-form-select v-model="subject" :options="subjects" />
      </b-form-group> 
      <b-form-group label="Grade Level" class="mx-1">
        <b-form-select v-model="grade" :options="grades" />
      </b-form-group>
      <b-form-group label="Demographic Group" v-if="demographicsFilterEnabled">
        <b-form-select v-model="subgroup" :options="demographicGroups" />
      </b-form-group>
    </b-form>
    <!-- <div>{{ subject }} / {{ grade }} / {{ subgroup }}</div> -->
  </div>
</template>

<script>
import { DemographicGroups, Subjects } from './FilterOptions'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'growth-filter',
  data() {
    return {
      demographicGroups: DemographicGroups,
      subjects: Subjects
    }
  },
  props: {
    demographicsFilterEnabled: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('academicsModule/growth', ['showFilter']),
    ...mapState('academicsModule/growth', ['grades']),
    subject: {
      set(subject) {
        this.$store.commit('academicsModule/growth/setSubject', subject)
      },
      get() {
        return this.$store.state.academicsModule.growth.subject
      }
    },
    subgroup: {
      set(subgroup) {
        this.$store.commit('academicsModule/growth/setSubgroup', subgroup)
      },
      get() {
        return this.$store.state.academicsModule.growth.subgroup
      }
    }, 
    grade: {
      set(grade) {
        this.$store.commit('academicsModule/growth/setGrade', grade)
      },
      get() {
        return this.$store.state.academicsModule.growth.grade
      }
    }
  }
}
</script>


<template>
  <div class="card bg-light p-2">
    <div class="row">
      <div class="col-12">
        <div class="row" style="margin-left:auto; margin-right:auto;">
          <div class="col-md-11">
            <chart-data-toggle v-model="chartMode" @input="chartMode = $event" />
          </div>  
        </div>
        <div class="chartElem" v-show="chartMode">
          <highcharts class="chart" :options="chartOptions" />
        </div>
        <div v-show="!chartMode">
          <chart-data-table :items="dataItems()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ChartMixin } from '../ChartMixin'
import ChartDataToggle from '../ChartDataToggle.vue'

function sum(prev, next){
  return prev + next;
}

export default {
  components: {
    ChartDataToggle
  },
  props: {
    inputData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'pie',
          plotBackgroundColor: '#00000000',
          plotShadow: false,
        },
        title: {
          text: 'Use DESE provided statewide digital content?'
        },          
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: <br/>{point.y} ({point.percentage:.1f}%)'
            },
            showInLegend: true
          }
        }
      },
      chartMode: true
    }
  },
  mixins: [ChartMixin],
  methods: {
    dataItems() {
      return [
        { Response: 'Yes', Count: this.inputData.map(r => r.deseContentResponse).reduce(sum) },
        { Response: 'No', Count: this.inputData.map(r => r.deseContentResponse == 0 && r.submitDate != null ? 1 : 0).reduce(sum) },
        { Response: 'No Response', Count: this.inputData.map(r => r.deseContentResponse == 0 && r.submitDate == null ? 1 : 0).reduce(sum) }
      ]
    }
  },
  watch: {
    inputData: {
      immediate: true,
      handler(newData) {
        this.chartOptions.series = [{
          data: [
            { name: 'Yes', y: newData.map(r => r.deseContentResponse).reduce(sum), color: '#00FF00'},
            { name: 'No', y: newData.map(r => r.deseContentResponse == 0 && r.submitDate != null ? 1 : 0).reduce(sum), color: '#FF0000' },
            { name: 'No Response', y: newData.map(r => r.deseContentResponse == 0 && r.submitDate == null ? 1 : 0).reduce(sum), color: '#CCCCCC' }
          ],
          name: 'Use DESE Content Response',
          colorByPoint: true
        }]
      }
    }
  }
}
</script>

<style>

</style>
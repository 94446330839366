<template>
  <b-card no-body>
    <b-tabs card>
      <b-tab title="AP">
        <paginated-table :items="courseCategories.filter(item => item.designation == 1)" :fields="fields" sort-by="courseCode" :sort-asc="true" :per-page="50" striped hover :load-data="loading" />
      </b-tab>
      <b-tab title="IB">
        <paginated-table :items="courseCategories.filter(item => item.designation == 2)" :fields="fields" sort-by="courseCode" :sort-asc="true" :per-page="50" striped hover :load-data="loading" />
      </b-tab>
      <b-tab title="Concurrent">
        <paginated-table :items="courseCategories.filter(item => item.designation == 3)" :fields="fields" sort-by="courseCode" :sort-asc="true" :per-page="50" striped hover :load-data="loading" />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { mapState } from 'vuex';
import PaginatedTable from '@/components/PaginatedTable'

export default {
  data() {
    return {
      fields: [
        { key: 'courseCode', sortable: true },
        { key: 'courseName', sortable: true },
      ],
      loading: null
    }
  },  
  components: {
    PaginatedTable
  },
  computed: {
    ...mapState('rvsModule', ['courseCategories', 'fiscalYear']),
  },
  props: {
    
  },
  methods: {
    async reloadData() {
      if (this.fiscalYear)
        this.loading = this.$store.dispatch('rvsModule/getCourseCategories')
    }
  },
  watch: {
    fiscalYear: {
      immediate: true,
      handler() {
        this.reloadData()
      }
    },
  }
}
</script>
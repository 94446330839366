<template>
  <div class="card bg-light mt-3">
    <a class="anchor" id="teachers" />
    <div class="card-header">
      Teachers
    </div>
    <div class="card-body">
      <div class="card-group">
        <educator-totals-chart :load-data="loadData" :selected-lea="selectedLea" />
        <educators-by-race-chart :load-data="loadData" :selected-lea="selectedLea" />
      </div>
      <div class="card-group">
        <age-count-chart :load-data="loadData" :selected-lea="selectedLea" :fiscal-year="employeeDetailsFiscalYear" />
      </div>
      <div class="card-group">
        <demographics-experience-chart :load-data="loadData" :selected-lea="selectedLea" :fiscal-year="employeeDetailsFiscalYear" />
        <demographics-count-chart :load-data="loadData" :selected-lea="selectedLea" :fiscal-year="employeeDetailsFiscalYear" />
      </div>
    </div>
  </div>
</template>

<script>
import DemographicsExperienceChart from './DemographicsExperienceChart.vue'
import DemographicsCountChart from './DemographicsCountsChart.vue'
import EducatorsByRaceChart from './EducatorsByRaceChart.vue'
import EducatorTotalsChart from './EducatorTotalsChart.vue'
import AgeCountChart from './AgeCountChart.vue'
import { HCPTHelper } from '../../../helpers/hcptHelper'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      loadData: null,
    }
  },
  computed: {
    ...mapGetters('hcptModule', ['employeeDetailsFiscalYear']),
    selectedLea() {
      var contextLeaNumber = this.$store.state.globalModule.leaContext.SelectedLea ? this.$store.state.globalModule.leaContext.SelectedLea.number : null;

      if (contextLeaNumber) {
        return this.$store.getters['globalModule/leaDictionary'][contextLeaNumber]
      }

      return null
    },
    overviewData() {
      return this.$store.state.hcptModule.overviewData
    }
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.number)
        {
          const leas = []

          leas.push({
            leaNumber: newVal.number,
            level: HCPTHelper.convertLeaTypeToHCPTLevel(newVal.type),
            groupBy: HCPTHelper.convertLeaTypeToHCPTLevel(newVal.type)
          })

          this.loadData = Promise.all([this.$store.dispatch('hcptModule/getOverviewStatistics', {
            leaScopes: leas,
            fiscalYear: this.employeeDetailsFiscalYear
          }), this.$store.dispatch('hcptModule/getDemographicsHistory', { lea: newVal.number })])
        }
      }
    }
  },
  components: {
    DemographicsExperienceChart,
    DemographicsCountChart,
    AgeCountChart,
    EducatorsByRaceChart,
    EducatorTotalsChart
  }
}
</script>
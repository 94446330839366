
<template lang="html">
  <li class="nav-item dropdown" :title="fullName" v-if="isAuthenticated">
    <a class="nav-link dropdown-toggle btn-sm" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i class="fa fa-user-circle" />&nbsp;
      <span class="d-hide-narrow">{{ fullName }}</span>
    </a>
    <div class="dropdown-menu dropdown-menu-right mt-1" aria-labelledby="navbarDropdown">
      <a href="#" class="dropdown-item" v-if="canContextSwitch" @click="changeContext()"><i class="fas fa-exchange-alt fa-fw" />&nbsp;Change Context</a>
      <a href="#" class="dropdown-item" v-if="isContextSwitched" @click="resetContext()"><i class="fas fa-undo fa-fw" />&nbsp;Reset Context</a>
      <a class="dropdown-item" :href="logoffUrl" @click="clearCaches"><i class="fas fa-power-off fa-fw" />&nbsp;Log out</a>
    </div>
  </li>
  <li class="nav-item" v-else-if="displayLoginButton">
    <a class="nav-link btn-sm" :href="loginUrl"><i class="fas fa-user-lock" />&nbsp;Log in</a>
  </li>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  props: {
    loginUrl: String,
    logoffUrl: String
  },

  computed: {
    ...mapGetters('globalModule', ['fullName', 'isAuthenticated', 'canContextSwitch', 'isContextSwitched']),
    ...mapState('globalModule', ['displayLoginButton']),
  },
  methods: {
    ...mapActions('globalModule', ['changeContext', 'resetContext']),
    clearCaches() {
      if (navigator.onLine) {
        window.localStorage.clear()
        window.sessionStorage.clear()

        if (caches) {
          caches.keys().then(k => k.map(l => caches.delete(l)))
        }
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.getRegistrations().then(function(registrations) {
            for(let registration of registrations) {
              registration.unregister()
            } 
          })
        }
      }
    }
  }
}
</script>

<template>
  <b-form-group :label="label">
    <b-input-group>
      <b-form-select 
        v-model="selectedValue"
        @input="onInput($event)"
        :options="filterOptions"
      />
      <template #append>
        <b-btn @click="reset" :variant="selectedValue != null ? 'warning' : 'secondary'">
          <i class="fa fa-undo" />
        </b-btn>
      </template> 
    </b-input-group>
  </b-form-group> 
</template>

<script>
export default {
  props: {
    label: String,
    options: Array,
    value: {
      type: [String, Number]
    } 
  },
  data() {
    return {
      selectedValue: null
    }
  },
  computed: {
    filterOptions() {
      return [{value: null, text: '(no filter)'}, ...this.options]
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },
    reset() {
      this.selectedValue = null;
      this.onInput(null)
    }
  },
  watch: {
    value() {
      this.selectedValue = this.value
    }
  }
}
</script>
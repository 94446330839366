import ApiService from '../../services/ApiService'
import { DistrictClosures } from './districtClosures'
import { TeacherSurvey } from './teacherSurvey'

export class Surveys {

  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
  }

  namespaced = true

  state = {
  
  }

  mutations = {
  }

  getters = {
  
  }

  actions = {
  
  }

  modules = {
    districtClosures: new DistrictClosures(),
    teacherSurvey: new TeacherSurvey()
  }
}
<template>
  <div class="card my-3 bg-light">
    <a class="anchor d-print-none" />
    <div class="card-header">
      <a href="#" class="text-white" v-b-toggle.collapsedashboard>
        <span class="when-opened">
          <i class="fa fa-chevron-down" />
        </span>
        <span class="when-closed">
          <i class="fa fa-chevron-right" />
        </span>
        Inspection Statistics
      </a>
    </div>
    <b-collapse id="collapsedashboard" visible>
      <div class="card-body">
        <div class="card-group" v-if="isStateBusSummary">
          <state-bus-inspection-summary-chart :selected-lea="selectedLea" :load-data="loadData" />
          <state-bus-inspection-summary-chart :selected-lea="selectedLea" :status-breakdown="true" :load-data="loadData" />
        </div>
        <div class="card-group" v-else>
          <inspected-not-inspected-chart :selected-lea="selectedLea" :mode="mode" :load-data="loadData" :is-state-view="isStateView" />
          <inspected-not-inspected-chart :selected-lea="selectedLea" :mode="mode" :status-breakdown="true" :load-data="loadData" :is-state-view="isStateView" />
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import InspectedNotInspectedChart from './InspectedNotInspectedChart.vue'
import StateBusInspectionSummaryChart from './StateBusInspectionSummaryChart.vue'

export default {
  data() {
    return { 
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    selectedLea() { 
      return this.$store.state.globalModule.leaContext.SelectedLea
    },
    isStateBusSummary() {
      return this.isStateView && this.mode == "transportation"
    },
  },
  props: {
    mode: String,
    loadData: Promise,
    isStateView: Boolean
  },
  components: {
    InspectedNotInspectedChart,
    StateBusInspectionSummaryChart,
  },
  watch: {
  }
}
</script>

<style scoped>
  .collapsed > .when-opened,
  :not(.collapsed) > .when-closed {
      display: none;
  }
</style>
<template>
  <b-form>
    <b-form-row>
      <label class="col-md-3 mb-3 mt-1">Grade:</label>
      <div class="col-md-9 mb-3 mt-1 p-0">
        <b-form-select :options="careerCoachGrades" :value="selectedGrade" @change="filterChanged($event)" :disabled="currentBulkVisit != undefined" />
      </div>
    </b-form-row>
  </b-form>
</template>

<script>
import { gradeSpans } from '@/helpers/gradeSpans'
import { mapState } from 'vuex'
import { Types } from '@/modules/cte/stores/careercoach'

export default {
  data() {
    return {
      selectedGrade: null
    }
  },
  mounted() {
    this.selectedGrade = this.value.graduationYear ? `G|${this.value.graduationYear}` : `${this.value.grade}`
  },
  computed: {
    ...mapState(Types.path, [Types.state.currentBulkVisit, Types.state.graduationYears]),
    careerCoachGrades() {   
      if (this.selectedLea.gradesServed && this.selectedLea.gradesServed.includes('-')) {
        var leaGrades = this.selectedLea.gradesServed.split('-');
        if (isNaN(leaGrades[0])) leaGrades[0] = 0
        var grades = gradeSpans.filter(g=>g.value >= Math.max(7,leaGrades[0]) && g.value <= leaGrades[1]).map(g =>{ return {value: `0${g.value}`.slice(-2), text: g.text}});
        if(leaGrades.includes('12')){
          this.graduationYears.forEach(y => {
            grades.push({value: `G|${y}`, text: `${y} Graduates`})        
          });
        }
        grades.splice(0, 0, {value: null, text: 'All Grades'})
        return grades
      } else {
        return []
      }
    }
  },
  methods: {
    filterChanged(event) {
      let payload = {
        grade: null,
        graduationYear: null
      }

      if (event) {
        if (isNaN(event)) {
          payload.grade = 'G'
          payload.graduationYear = Number(event.substring(2))
        } else {
          payload.grade = Number(event)
        }
      }

      this.$emit('input', payload)
    }
  },
  props:{
    ay: {
      type: Number
    },
    value:{
      type: Object,
      default: null
    }
  },
  components: {}
}
</script>
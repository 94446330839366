<template>
  <div style="width: 100%" v-if="medians">
    <table class="table table-bordered" style="width: 100%">
      <thead>
        <tr>
          <th rowspan="2" />
          <th colspan="2" class="text-center">
            Experience
          </th>
          <th colspan="2" class="text-center">
            Attainment
          </th>
          <th colspan="2" class="text-center">
            Licensure
          </th>
          <th colspan="2" class="text-center">
            Attrition
          </th>
        </tr>
        <tr>
          <th class="text-center">
            Avg. Years of Experience
          </th>
          <th class="text-center">
            % More than 1-year Experience
          </th>
          <th class="text-center">
            % with Master's Degrees
          </th>
          <th class="text-center">
            % NBC Teachers
          </th>
          <th class="text-center">
            % w/o AWL Exception
          </th>
          <th class="text-center">
            % with No Exception
          </th>
          <th class="text-center">
            % Retention
          </th>
        </tr>
      </thead>
      <tbody>
        <tr id="state">
          <th>State Median</th>
          <td class="text-center">
            {{ roundPercent(medians.teacher_average_years_of_experience / 100.0) }}
          </td>
          <td class="text-center">
            {{ roundPercent(medians.percent_of_Teachers_with_1_or_more_years_experience) }}%
          </td>
          <td class="text-center">
            {{ roundPercent(medians.percent_with_Masters_Degrees) }}%
          </td>
          <td class="text-center">
            {{ roundPercent(medians.percent_NBCT) }}%
          </td>
          <td class="text-center">
            {{ roundPercent(medians.percent_without_AWL_Act_1240_exception) }}%
          </td>
          <td class="text-center">
            {{ roundPercent(medians.percent_with_no_exceptions) }}%
          </td>
          <td class="text-center">
            {{ roundPercent(medians.percent_Retention) }}%
          </td>
        </tr>
        <tr v-for="lea in leaValues" :key="lea.lea">
          <td>{{ formatLea(lea.lea) }}</td>
          <td class="text-center" :class="getBackgroundClass(lea, 'teacher_average_years_of_experience')">
            {{ roundPercent(lea.teacher_average_years_of_experience / 100.0) }}
          </td>
          <td class="text-center" :class="getBackgroundClass(lea, 'percent_of_Teachers_with_1_or_more_years_experience')">
            {{ roundPercent(lea.percent_of_Teachers_with_1_or_more_years_experience) }}%
          </td>
          <td class="text-center" :class="getBackgroundClass(lea, 'percent_with_Masters_Degrees')">
            {{ roundPercent(lea.percent_with_Masters_Degrees) }}%
          </td>
          <td class="text-center" :class="getBackgroundClass(lea, 'percent_NBCT')">
            {{ roundPercent(lea.percent_NBCT) }}%
          </td>
          <td class="text-center" :class="getBackgroundClass(lea, 'percent_without_AWL_Act_1240_exception')">
            {{ roundPercent(lea.percent_without_AWL_Act_1240_exception) }}%
          </td>
          <td class="text-center" :class="getBackgroundClass(lea, 'percent_with_no_exceptions')">
            {{ roundPercent(lea.percent_with_no_exceptions) }}%
          </td>
          <td class="text-center" :class="getBackgroundClass(lea, 'percent_Retention')">
            {{ roundPercent(lea.percent_Retention) }}%
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

  export default {
    computed: {
      ...mapState('hcptModule', ['workforceStabilityIndex2']),
      ...mapState('globalModule', ['leaContext']),
      medians() {
        return this.workforceStabilityIndex2.stateMedians;
      },
      leaValues() {
        return this.workforceStabilityIndex2.leaValues;
      }
    },
    methods: {
      roundPercent(value) {
        if (value === null || value === undefined) {
          return '';
        }
        return (value * 100.0).toFixed(1)
      },
      formatLea(leaNumber) {
        var lea = this.leaContext.LeaDictionary[leaNumber]
        return `${lea.name} (${lea.number})`
      },
      getBackgroundClass(lea, property) {
        const value = lea[property]
        const lower = this.workforceStabilityIndex2.stateLower[property]
        const upper = this.workforceStabilityIndex2.stateUpper[property]
        
        if (value === null || value === undefined) {
          return '';
        }
        else if (value === 0.0) {
          return 'bg-bad'
        }
        else {
          return value < lower ? 'bg-bad' : value > upper ? 'bg-good' : '';
        }
      },
    }
  }
</script>

<style>
  .bg-bad {
    background-color: #f8d7da;
  }
  .bg-good {
    background-color: #d4edda;
  }
</style>
<template>
  <div>
    <div v-if="employees.length > 0">
      <b-form-select v-model="tableOptions.pageLength" :options="tableOptions.pageLengths" required />
      <b-table :items="employees" :fields="fields" :per-page="tableOptions.pageLength" :current-page="tableOptions.currentPage">
        <template v-slot:cell(schoolLea)="data">
          <div>
            <div class="d-inline">
              <button type="button" class="btn btn-link" @click="goToLea(data.value)" :id="data.index + '-' + data.value">
                {{ leaFormatter(data.value) }}
              </button>
              &nbsp;
              <lea-tooltip :target-selector="data.index + '-' + data.value" :lea-number="data.value" />
            </div>
          </div>
        </template>
        <template v-slot:cell(caseId)="data">
          <aels-case-id-link :case-id="data.value.toString()" />
        </template>
        <template v-slot:cell(districtLea)="data">
          <div>
            <div class="d-inline">
              <button type="button" class="btn btn-link" @click="goToLea(data.value)" :id="data.index + '-' + data.value">
                {{ leaFormatter(data.value) }}
              </button>
              &nbsp;
              <lea-tooltip :target-selector="data.index + '-' + data.value" :lea-number="data.value" />
            </div>
          </div>
        </template>
      </b-table>
      <table-pagination-widget 
        :total-rows-count="employees.length" 
        :page-length="tableOptions.pageLength" 
        @page-changed="tableOptions.currentPage=$event" />
    </div>
    <div v-else>
      <h4 class="text-center">
        No employees found
      </h4>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { TableMixin } from '../../TableMixin'

export default {
  data() {
    return {
      tableOptions: {
        currentPage: 1,
        pageLength: 100,
        pageLengths: [10, 20, 50, 100]
      },
      fields: [
        {
          key: 'districtLea',
          label: 'District LEA',
          sortable: true
        },
        {
          key: 'schoolLea',
          label: 'Employee LEA',
          sortable: true
        },
        {
          key: 'firstName',
          sortable: true
        },
        {
          key: 'middleName',
          sortable: true
        },
        {
          key: 'lastName',
          sortable: true
        },
        {
          key: 'uniqueEmployeeId',
          label: 'State ID',
          sortable: true
        },
        {
          key: 'caseId',
          label: 'Case ID',
          sortable: true
        }
      ]
    }
  },
  mixins: [TableMixin],
  computed: {
    ...mapState('hcptModule', ['employees']),
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    }
  },
  methods: {
    goToLea(leaNumber) {
      const lea = this.leaDictionary[leaNumber]
      window.scrollTo(0,0)
      this.$store.dispatch('globalModule/changeSelectedLea', lea)
    },
    leaFormatter(lea) {
      if (this.leaDictionary[lea])
        return `${lea} – ${this.leaDictionary[lea].name}`

      return lea
    }
  }
}
</script>
<template>
  <div>
    <div class="card my-2">
      <b-card>
        <template v-slot:header>
          <b-row>
            <b-col cols="9" lg="10">
              <h4 class="mb-0">
                {{ `${(inspection.building.buildingName || 'Building Name Missing')}` }}
              </h4>
              <h6 class="mb-0">
                Building #: {{ inspection.building.buildingNumber }}
              </h6>
            </b-col>
            <b-col cols="1" lg="2" class="text-right">
              <span class="fas fa-stopwatch fa-2x text-info rounded-lg p-2 bg-white" v-if="inspection.status == InspectionStatuses.InProgress" title="In-Progress" />
              <span class="fas fa-check fa-2x text-warning rounded-lg p-2 bg-white" v-else-if="inspection && inspection.status == InspectionStatuses.Completed && inspection.components.some(c => c.rating == RatingTypes.Fix)" title="Completed / Fix" />
              <span class="fas fa-check fa-2x text-success rounded-lg p-2 bg-white" v-else-if="inspection && inspection.status == InspectionStatuses.Completed" title="Completed" />
              <span class="fas fa-clock fa-2x text-primary rounded-lg p-2 bg-white" v-else title="Pending" />
            </b-col>
          </b-row>
        </template>
        <b-form>
          <b-form-row>
            <label class="col-form-label col-8 col-md-2">Components Checked</label>
            <label class="col-form-label col-4 col-md-1 text-right">{{ aggregateInfo.checked }}/{{ aggregateInfo.total }}</label>
          </b-form-row>
          <b-form-row>
            <label class="col-form-label col-8 col-md-2">Action Items</label>
            <label class="col-form-label col-4 col-md-1 text-right">{{ aggregateInfo.actionItems }}</label>
          </b-form-row>
        </b-form>
        <div class="row no-gutter">
          <div class="col-12 text-right">
            <router-link :to="{name: 'mobile-inspection-page', params: {batchId: batchId, inspectionId: inspection.inspectionId}}" exact>
              <i class="far fa-play-circle fa-2x" />
            </router-link>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { InspectionStatuses, RatingTypes } from '../../../../components/dpsaft/common/Enums'
import sumBy from 'lodash.sumby'

export default {
  name: 'inspection-building-card',
  data(){
    return {
      InspectionStatuses,
      RatingTypes
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    aggregateInfo() {
      return {
        total: this.inspection.components.length,
        checked: sumBy(this.inspection.components, (c) => {
          return c.rating > 0 ? 1 : 0
        }),
        actionItems: sumBy(this.inspection.components, (c) => {
          return c.rating == 2 ? 1 : 0
        })
      }
    }
  },
  props: {
    batchId: Number,
    inspection: Object
  }
}
</script>

<template>
  <span v-if="admin.email"><a :href="`mailto:${admin.email}`">{{ admin.name }}</a></span>
  <span v-else-if="admin.name">{{ admin.name }}</span>
</template>
<script>
export default {
  props: {
    admin: Object
  }
}
</script>
<template>
  <div>
    <pdf-button v-if="selectedLea.type == LeaTypes.District && !isListRoute" :additional-query-string-params="`&fiscalYear=${fiscalYear+1}`" />
    <b-button @click="back" class="float-right mr-2 d-print-none" v-if="!isListRoute">
      <i class="fas fa-chevron-left" /> Back to List
    </b-button>
    <h1>
      <object type="image/svg+xml" data="/Content/icons/AC-ESA.svg" class="title-icon" />
      Enhanced Student Achievement (ESA)
    </h1>
    <h3><lea-display :lea="selectedLea" v-if="!isListRoute" /></h3>
    <div v-if="selectedLea.type == LeaTypes.District || isListRoute">
      <router-view />
    </div>
    <div v-else class="alert alert-warning">
      Enhanced Student Achievement plan is only available at the District level.
    </div>
  </div>
</template>
<script>
// import PlanWidget from './Plan/PlanWidget'
import PublicPlanListWidget from './Public/PublicPlanListWidget'
import PublicPlanWidget from './Public/PublicPlanWidget'
import EsaPublicPdfPage from './EsaPublicPdfPage'

import { PageMixin } from '@/components/PageMixin'
import { router } from '@/routes'
import { Types } from '@/modules/esa/stores/'

import { mapState } from 'vuex'

router.addRoutes([
 { path: '/Academics/Esa/Public', redirect: to => {
    if (window.printMode)
    {
      return { 
        name: 'esa-pdf-report-public', 
        query: { ...to.query } 
      }
    }

    return { 
      name: 'esa-plan-public-list', 
      query: { ...to.query } 
    }
  }, name: 'esa-public-home' },
  { path: '/Academics/Esa/Public/List', component: PublicPlanListWidget, name: 'esa-plan-public-list' },
  { path: '/Academics/Esa/Public/Plan', component: PublicPlanWidget, name: 'esa-plan-public' },
  { path: '/Academics/Esa/Public', component: EsaPublicPdfPage, name: 'esa-pdf-report-public' },
])
export default {
  mixins: [PageMixin],
  components: {
  },
  computed: {
    ...mapState(Types.path, [Types.state.plan, Types.state.esaFiscalYear]),
    fiscalYear() {
      return (this.plan && this.plan.fiscalYear) ? this.plan.fiscalYear : this.esaFiscalYear 
    },
    isListRoute() {
      return this.$route.name == 'esa-plan-public-list'
    },
  },
  methods: {
    back() {
      this.$router.push({ name: 'esa-plan-public-list' })
    },
  },
  props: {
    ay: {
      type: Number,
      required: true
    }
  },
  mounted() {
    this.$store.commit(Types.mutations.setESAFiscalYear, this.ay)
  }
}
</script>

<style scoped>
.title-icon {
  height: 2.5rem;
  width: 2.5rem;
  top: -0.25em;
  position: relative;
  display: inline-flex;
  align-self: center;
  vertical-align: bottom;
  fill: #6c757d;
}
</style>
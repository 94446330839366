<template>
  <div>
    <paginated-table :items="unreadComments" :fields="fields" :load-data="loadData" :per-page="0" :hide-controls="true" show-empty empty-text="There are no unread comments at this time">
      <template v-slot:cell(id)="data">
        <div v-if="data.item.type == 'Proposal'">
          <router-link :to="{ name: 'CTE/PerkinsV/Application/Proposal/Edit', params: { ...data.item.routeParams} }">
            Proposal #{{ data.value }}
          </router-link>
        </div>
        <div v-if="data.item.type == 'Cover page'">
          <router-link :to="{ name: 'CTE/PerkinsV/Application/CoverPage/Edit', params: { ...data.item.routeParams} }">
            Cover Page #{{ data.value }}
          </router-link>
        </div>
      </template>
      <template v-slot:cell(status)="data">
        <div v-if="data.item.type == 'Proposal' && data.value">
          <workflow-status-badge :status="parseInt(data.value)" :state-map="ProposalStateMap" :show-label="false" />
        </div>
        <div v-if="data.item.type == 'Cover page'">
          <workflow-status-badge :status="parseInt(data.value || 0)" :state-map="CoverPageStateMap" :show-label="false" />
        </div>
      </template>
      <template v-slot:cell(action)="data">
        <b-button size="sm" variant="primary" class="mr-1" @click="loadComments(data.item.routeParams)">
          <i class="fa fa-fw fa-eye" title="View Comments" />
        </b-button>
      </template>
    </paginated-table>
  </div>
</template>
<script>
import { Types } from '../../stores/perkinsv'
import { ProposalStateMap, CoverPageStateMap } from '../../stores/perkinsvStateMap'
import PaginatedTable from '@/components/PaginatedTable'
import WorkflowStatusBadge from '@/components/workflow/WorkflowStatusBadge.vue'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      ProposalStateMap,
      CoverPageStateMap,
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState(Types.path, [Types.state.unreadComments]),
    fields() {
      const optionalColumns = (this.userContext.districtLEA || '').length == 7 ? [] : [{ key: 'applicant' }]
      return [
        { key: 'id', label: 'Item' },
        ...optionalColumns,
        { key: 'status' },
        { key: 'action' },
      ]
    },
  },
  props: {
    loadData: Promise
  },
  methods: {
    loadComments(routeParams) {
      const actions = [
        this.$store.dispatch(Types.actions.getApplication, routeParams),
      ]
      if (routeParams.proposalId)
        actions.push(this.$store.dispatch(Types.actions.getProposal, routeParams))
      else
        actions.push(this.$store.dispatch(Types.actions.getComments, routeParams))
      return Promise.all(actions)
    }
  },
  components: {
    PaginatedTable,
    WorkflowStatusBadge
  }
}

</script>

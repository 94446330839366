import LeaAssignmentPage from './leaAssignmentPage'
import LeaAssignmentSidebar from './leaAssignmentSidebar'

const LeaAssignmentRoles = {
  CharterOfficeAdmin: "Insights_CharterSchoolOffice_Administrators",
  SMSAdmin: "Insights_RT-SMS_Administrators"
}

const RequestOperationPurpose = {
  General: 1,
  DistrictReconfiguration: 2,
  ChangeBuildingLEA: 4,
  BuildingAnnexation: 5,
  BuildingCombination: 6,
  NewDistrict: 9,
  ChangeDistrict: 10,
  NonRequestCode: 255
}

const requestOperationFormatter = (operation) => {
  switch (operation) {
    case RequestOperationPurpose.DistrictReconfiguration:
      return "District Reconfiguration"
    case RequestOperationPurpose.BuildingAnnexation:
      return "Building Annexation"
    case RequestOperationPurpose.BuildingCombination:
      return "Building Consolidation"
    case RequestOperationPurpose.ChangeBuildingLEA:
      return "Changing LEA for Existing Building"
    case RequestOperationPurpose.NewDistrict:
      return "New District"
    case RequestOperationPurpose.ChangeDistrict:
      return "Change District"
    case RequestOperationPurpose.General:
      return "Other General Changes not resulting from District Reconfiguration"
    case RequestOperationPurpose.NonRequestCode:
      return "Non-Request Code"
  }
}

const RequestSource  = {
  NotSet: 0,
  District: 100,
  CharterSchoolOffice: 500
}

const ReconfigurationCodePurpose = {
  NewSchool: 1,
  EditSchool: 2,
  InactivateSchool: 3,
  EditDistrict: 4,
  NewDistrict: 5,
  InformationalStatusCode: 6
}
const RequestStatus = {
  Draft: 0,
  ReturnedForInformation: 1,
  PendingSuperintendent: 2,
  DistrictInformationProvided: 3,
  PendingADELeaCoordinator: 10,
  PendingPSAReview: 11,
  PSAReviewCompleted: 12,
  Reopen: 13,
  Approved: 100,
  Rejected: 101,
  Canceled: 255
}

const RequestType = {
  LEAChangeRequest: 1,
  DistrictCreationRequestType: 2
}

const LeaAssignmentModule = {
  LeaAssignmentPage,
  LeaAssignmentSidebar
}



const AccountabilityStatus = new Map()
AccountabilityStatus.set('8A', 'Schools Exempt from Ratings (Letter Grades)')
AccountabilityStatus.set('8B', 'ALE or AE with Unique LEA#')

const operationBadgeByOperationTypeCode = (operation) => {
  switch (operation) {
    case ReconfigurationCodePurpose.NewSchool:
      return {label: "New Building", class: 'badge-success'}
    case ReconfigurationCodePurpose.EditSchool:
      return  {label:"Edit Building", class: 'badge-primary'}
    case ReconfigurationCodePurpose.InactivateSchool:
      return  {label:"Inactivate Building", class:'badge-warning'}
    case ReconfigurationCodePurpose.EditDistrict:
      return {label: "Edit District", class: 'badge-primary'}
    case ReconfigurationCodePurpose.NewDistrict:
      return  {label:"New District", class: 'badge-success'}
  }

  return null
}

const operationTypeTextFormatter = (item) => {
  switch (item.itemType) {
    case 'edit-school':
      return `Change Building (${item.reconfigurationCode})`
    case 'edit-district':
      return `Change District (${item.reconfigurationCode})`
    case 'new':
      return `New Building (${item.reconfigurationCode})`
    case 'new-district':
      return `New District (${item.reconfigurationCode})`
    case 'inactivate':
      return `Inactivate Building (${item.reconfigurationCode})`
    default:
      return `Other`
  }
}

export {
  LeaAssignmentModule,
  RequestType,
  ReconfigurationCodePurpose,
  RequestStatus,
  LeaAssignmentRoles,
  RequestOperationPurpose,
  requestOperationFormatter,
  operationBadgeByOperationTypeCode,
  operationTypeTextFormatter,
  AccountabilityStatus,
  RequestSource
}
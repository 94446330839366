<template>
  <div>
    <div class="card my-2">
      <b-card>
        <template v-slot:header>
          <b-row>
            <b-col cols="9" lg="10">
              <h4 class="mb-0">
                {{ `Bus #: ${(inspection.busNumber || 'Bus # Missing')} , Sticker: ${(inspection.stickerNumber || 'No Sticker Number')}` }}
              </h4>
              <h6 class="mb-0">
                {{ inspection.vin }}
              </h6>
            </b-col>
            <b-col cols="1" lg="2" class="text-right">
              <span class="fas fa-stopwatch fa-2x text-info rounded-lg p-2 bg-white" v-if="inspection.status == InspectionStatuses.InProgress" title="In-Progress" />
              <span class="fas fa-check fa-2x text-danger rounded-lg p-2 bg-white" v-else-if="inspection && inspection.status == InspectionStatuses.Completed && isBusGrounded(inspection)" title="Completed / Grounded" />
              <span class="fas fa-check fa-2x text-warning rounded-lg p-2 bg-white" v-else-if="inspection && inspection.status == InspectionStatuses.Completed && inspection.components.some(c => c.rating == RatingTypes.Fix)" title="Completed / Fix" />
              <span class="fas fa-check fa-2x text-success rounded-lg p-2 bg-white" v-else-if="inspection && inspection.status == InspectionStatuses.Completed" title="Completed" />
              <span class="fas fa-pause fa-2x rounded-lg p-2 bg-white" v-else-if="inspection && inspection.status == InspectionStatuses.Paused" title="Paused" :class="{'text-danger': isBusGrounded(inspection), 'text-warning': !isBusGrounded(inspection) }" />
              <span class="fas fa-eye-slash fa-2x text-light rounded-lg p-2 bg-dark" v-else-if="inspection && inspection.status == InspectionStatuses.Skipped" title="Skipped" />
              <span class="fas fa-clock fa-2x text-primary rounded-lg p-2 bg-white" v-else-if="inspection && inspection.status == InspectionStatuses.NotStarted" title="Pending" />
            </b-col>
          </b-row>
        </template>
        <b-form>
          <b-form-row>
            <label class="col-form-label col-8 col-md-2 bg-danger text-white" v-if="isDupe">
              <span class="fas fa-exclamation-circle" />
              Duplicated Bus Number
            </label>
          </b-form-row>
          <b-form-row>
            <label class="col-form-label col-8 col-md-2">Components Checked</label>
            <label class="col-form-label col-4 col-md-1 text-right">{{ aggregateInfo.checked }}/{{ aggregateInfo.total }}</label>
          </b-form-row>
          <b-form-row>
            <label class="col-form-label col-8 col-md-2">Minor Issues</label>
            <label class="col-form-label col-4 col-md-1 text-right">{{ aggregateInfo.minorIssues }}</label>
          </b-form-row>
          <b-form-row>
            <label class="col-form-label col-8 col-md-2">Major Issues</label>
            <label class="col-form-label col-4 col-md-1 text-right">{{ aggregateInfo.majorIssues }}</label>
          </b-form-row>
        </b-form>

        <div class="row no-gutter">
          <div class="col-8">
            <b-link href="#" v-if="!skipped" @click="setSkipStatus(true)">
              <i class="far fa-eye-slash fa-2x text-secondary" title="Skip this Bus" />
            </b-link>
            <b-link href="#" v-if="skipped" @click="setSkipStatus(false)">
              <i class="far fa-eye-slash fa-2x text-danger" title="Inspect this Bus" />
            </b-link>
          </div>
          <div class="col-4 text-right">
            <router-link
              :to="{name: 'trans-mobile-inspection-page', params: {batchId: batchId, inspectionId: inspection.inspectionId}}" exact>
              <i class="far fa-play-circle fa-2x" />
            </router-link>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>

import { InspectionStatuses, RatingTypes } from '../../../../components/dpsaft/common/Enums'
import { isBusGrounded } from '../../../../stores/dpsaft/trans'
import { mapState } from 'vuex'
import sumBy from 'lodash.sumby'

export default {
  name: 'inspection-bus-card',
  data() {
    return {
      skipped: false,
      InspectionStatuses,
      RatingTypes
    }
  },
  mounted() {
    this.skipped = this.inspection && (this.inspection.status == InspectionStatuses.Skipped)
  },
  methods: {
    isBusGrounded(inspection) {
      return isBusGrounded(inspection)
    },
    setSkipStatus(skipped) {
      const model = this;
      this.$store.dispatch('DPSAFT/Trans/setInspectionSkipStatus', { batchId: this.batchId, inspectionId: this.inspection.inspectionId, skipValue: skipped ? "Skipped" : "InProgress" })
      .then(function() {
        model.skipped = skipped
      })
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    aggregateInfo() {
      return {
        total: this.inspection.components.length,
        checked: sumBy(this.inspection.components, (c)=> {
          return c.rating > 0 ? 1 : 0
        }),
        minorIssues: sumBy(this.inspection.components, item => Number(item.minorPoints || 0)) || 0,
        majorIssues: sumBy(this.inspection.components, item => Number(item.majorPoints || 0)) || 0,
        grounded: isBusGrounded(this.inspection).length
      }
    }
  },
  props: {
    batchId: Number,
    inspection: Object,
    isDupe: Boolean
  }
}
</script>

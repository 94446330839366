<template>
  <div>
    <h1>Change your password</h1>

    <b-card header="Account Activation" header-bg-variant="primary">
      <b-card-text>
        <div v-if="passwordResetError" class="alert bg-danger text-light">
          <i class="fa fa-exclamation-circle fa-fw fa-lg" />
          An error occured while setting new password
        </div>
        <b-form>
          <div>
            <dl class="lead">
              <dt class="font-weight-bold">
                Username:
              </dt>
              <dd>{{ $route.query.userName }}</dd>
            </dl>
            <p>Please set a new password and click <strong>Continue</strong>.</p>
               
            <b-form-group label="Enter your a password">
              <b-input type="password" v-model="password" :state="isPasswordValid" />
              <b-form-invalid-feedback id="password-input" class="d-print-none" :state="isPasswordValid">
                <strong>Password must be 8 characters or more</strong>
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group label="Confirm password">
              <b-input type="password" v-model="confirmPassword" :state="passwordsMatch" />
              <b-form-invalid-feedback id="confirmPassword-input" class="d-print-none" :state="passwordsMatch">
                <strong>Does not match password</strong>
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-row class="mt-2">
              <b-col class="text-right">
                <b-button :disabled="!isValid" variant="primary" @click="resetPassword">
                  Continue
                </b-button>
              </b-col>
            </b-form-row>
          </div>
        </b-form>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService'

const apiService = new ApiService()

export default {
  data() {
    return {
      password: '',
      confirmPassword: '',
      passwordResetError: null,
    }
  },
  computed: {
    passwordsMatch() {
      return this.confirmPassword == this.password
    },
    isPasswordValid() {
      return this.password.length > 7
    },
    isValid() {
      return this.isPasswordValid && this.passwordsMatch
    }
  },
  methods: {
    resetPassword() {
      const self = this
      apiService.Post(`/api/external-auth/reset-password?userId=${this.$route.query.userId}&passwordResetToken=${this.$route.query.passwordResetToken}`, { password: this.password })
        .then(() => {
          self.$bvModal.msgBoxOk('Password reset complete', {
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            centered: true
          })
          .then(() => {
            window.location = '/Account/Login'
          })
        })
        .catch(error => {
          this.passwordResetError = error
        })
    }
  },
}
</script>

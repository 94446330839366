import startcase from 'lodash.startcase'

const ItemValidationMixin = {
  data() {
    return {
      type: '',
      errors: [],
      requiredFields: []
    }
  },
  computed: {
    errorsToastId() {
      return `${this.item.key}-errors-toast`
    },
    formId() {
      return `${this.item.key}-form`
    }
  },
  methods: {
    validate() {
      var self = this
      self.errors = []
      self.requiredFields.forEach(field => {
        if (typeof self.item[field] === 'undefined' || self.item[field] === '') {
          self.errors.push({ type: self.type, error: `${startcase(field)} field is required` })
        }
      })
      if (self.errors.length > 0) {
        this.$bvToast.show(this.errorsToastId)
        document.forms[this.formId].reportValidity()
      }
      return self.errors
    }
  },
  props: {
    item: Object
  }
}

export { ItemValidationMixin }
<template>
  <div class="card bg-light mt-3">
    <a class="anchor" id="licenses" />
    <div class="card-header">
      License Types and Exceptions
    </div>
    <div class="card-body">
      <div class="card-group">
        <license-types-issued-chart :load-data="loadData" :selected-lea="selectedLea" />
        <new-licenses-issued-chart :load-data="loadData" :selected-lea="selectedLea" />
      </div>
      <div class="card-group">
        <license-exceptions-chart :load-data="loadData" :selected-lea="selectedLea" />
        <employee-alps-count-chart :load-data="loadData" :selected-lea="selectedLea" />
      </div>
    </div>
  </div>
</template>
<script>
  import LicenseExceptionsChart from './LicenseExceptionsChart.vue'
  import EmployeeAlpsCountChart from './EmployeeAlpsCountChart.vue'
  import LicenseTypesIssuedChart from './LicenseTypesIssuedChart.vue'
  import NewLicensesIssuedChart from './NewLicensesIssuedChart.vue'

  export default {
    data() {
      return { loadData: null }
    },
    computed: {
    },
    components: {
      LicenseExceptionsChart,
      EmployeeAlpsCountChart,
      LicenseTypesIssuedChart,
      NewLicensesIssuedChart
    },
    watch: {
      selectedLea: {
        immediate: true,
        handler(newLea) {
          if (newLea && newLea.number) {
            this.loadData = Promise.all([
              this.$store.dispatch('hcptModule/getLicenseExceptionStatistics', { lea: newLea.number }),
              this.$store.dispatch('hcptModule/getLicenseIssuedStatistics', { lea: newLea.number }),
              this.$store.dispatch('hcptModule/getEmployeeALPs', { lea: newLea.number }),
            ])
          }
        }
      }
    }
  }
</script>

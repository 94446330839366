<template>
  <div>
    <div class="row">
      <div class="col-6 offset-3">
        <b-card header="Arkansas Ready for Learning Planning Survey" header-bg-variant="primary">
          <b-card-text>
            <div v-if="registered" class="alert bg-success text-light">
              <i class="fa fa-check-circle fa-fw fa-lg" />
              Thank you for registering. An e-mail containing the link to your district's survey will be sent to {{ this.email }}
            </div>
            <div v-if="registrationError" class="alert bg-danger text-light">
              <i class="fa fa-exclamation-circle fa-fw fa-lg" />
              {{ this.registrationError }}
            </div>
            <b-form v-if="!registered">
              <div v-if="confirmationFailure" class="text-light bg-danger p-2 rounded text-center">
                {{ failureMessage }}
              </div>
              <div id="step1" v-if="!accessConfirmed && !confirmationFailure">
                <p>
                  Please confirm your district's LEA number and your email address to proceed and update the existing survey for your district.
                </p>
                <b-form-group label="Enter your District's 7-digit LEA Number" class="mt-3">
                  <b-input-group>
                    <template v-slot:append>
                      <b-input-group-text>
                        <i v-if="district" class="fa fa-check-circle text-success" />
                        <i v-if="!district" class="fa fa-question-circle text-primary" />
                      </b-input-group-text>
                    </template>
                    <b-input type="text" v-model="districtLea" />
                  </b-input-group>

                  <span class="text-danger" v-if="$v.districtLea.$dirty">A 7 digit LEA is required</span>
                  <h3
                    class="bg-success text-light p-2 rounded-bottom"
                    v-if="district">
                    {{ district.name }}
                  </h3>
                </b-form-group>
                <b-form-group class="text-right" v-if="mode==='register'">
                  <b-button
                    @click="prevalidateUpdate"
                    :disabled="!(district)"
                    variant="primary">
                    Update an existing survey
                  </b-button>
                </b-form-group>
              </div>

              <div id="continue" v-if="mode==='update'">
                <b-form-row class="mt-2">
                  <b-col>
                    <label>Email</label>
                    <b-input v-model="email" placeholder="Email address" />
                    <span class="text-danger" v-if="!$v.email.required">Email is required</span>
                    <span class="text-danger" v-if="!$v.email.email">Email address is not valid</span>
                  </b-col>
                </b-form-row>
                <b-form-row class="mt-2">
                  <b-col class="text-right">
                    <b-button :disabled="!(district && email)" variant="primary" @click="editSurvey">
                      Continue to survey
                    </b-button>
                  </b-col>
                </b-form-row>
              </div>

              <div id="register" v-if="mode==='register' && accessConfirmed">
                <b-form-row>
                  <b-col>
                    <label>First Name</label>
                    <b-input v-model="fname" placeholder="First Name" />
                    <span class="text-danger" v-if="!$v.fname.required">First Name is required</span>
                  </b-col>
                  <b-col>
                    <label>Middle Name</label>
                    <b-input v-model="mname" placeholder="Middle Name" />
                  </b-col>
                  <b-col>
                    <label>Last Name</label>
                    <b-input v-model="lname" placeholder="Last Name" />
                    <span class="text-danger" v-if="!$v.lname.required">Last Name is required</span>
                  </b-col>
                </b-form-row>

                <b-form-row class="mt-2">
                  <b-col>
                    <label>Job Title</label>
                    <b-input v-model="jobTitle" placeholder="Job Title" />
                    <span class="text-danger" v-if="!$v.jobTitle.required">Job Title is required</span>
                  </b-col>
                  <b-col>
                    <label>Email</label>
                    <b-input v-model="email" placeholder="Email address" />
                    <span class="text-danger" v-if="!$v.email.required">Email is required</span>
                    <span class="text-danger" v-if="!$v.email.email">Email address is not valid</span>
                  </b-col>
                  <b-col>
                    <label>Phone #</label>
                    <b-input v-model="phone" placeholder="Phone number" />
                    <span class="text-danger" v-if="!$v.phone.required">Phone is required</span>
                  </b-col>
                </b-form-row>

                <b-form-group class="text-center mt-2">
                  <b-button @click.once="register" variant="primary">
                    <i class="fa fa-link fa-fw" />Request Survey Link
                  </b-button>
                </b-form-group>
              </div>
            </b-form>
          </b-card-text>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
// import { Promised } from 'vue-promised'
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import { required, minLength, maxLength, email, numeric } from 'vuelidate/lib/validators'
import { router } from '../../../routes'

Vue.use(Vuelidate)

router.addRoutes([{  name: 'edit-arrfl-survey', path: '/BlendedLearningReadiness/ARReadyForLearning' }])

export default {
  name: 'arrfl-registration',
  data() {
    return {
      mode: 'update',
      loadData: Promise,
      districtLea: null,
      email: null,
      fname: null,
      mname: null,
      lname: null,
      jobTitle: null,
      phone: null,
      accessConfirmed: false,
      confirmationFailure: false,
      failureMessage: 'Unspecified error',
      registered: false,
      registrationError: null
    }
  },
  validations: {
    districtLea: {
      required,
      numeric,
      minLength: minLength(7),
      maxLength: maxLength(7)
    },
    email: {
      required,
      email
    },
    fname: {
      required
    },
    lname: {
      required
    },
    jobTitle: {
      required
    },
    phone: {
      required
    }
  },
  computed: {
    ...mapGetters('globalModule', ['leaDictionary', 'leaList', 'coops']),
    ...mapState('arkansasReadyForLearningModule', ['surveys']),
    coopOptions() {
      return this.coops.map(c => {
        return { value: c.number, text: c.name }
      })
    },
    district() {
      var lea = this.leaDictionary[this.districtLea]
      if (lea && lea.type == 2) return lea
      else return null
    },
    coop() {
      return this.leaDictionary[this.coopLea]
    }
  },
  methods: {
    confirmAccess() {
      this.$store
        .dispatch('arkansasReadyForLearningModule/getSurveys', { lea: this.district.number })
        .then(() => {
          if (this.surveys.length > 0)
            this.registrationError =
              'A survey has already been started for this district.  Please click "Update an exising survey" if you would like to edit the survey.'
          else this.accessConfirmed = true
        })
        .catch(() => {
          //this is actually where we want to wind up b/c it means no one else has already started the survey
          this.accessConfirmed = true
        })
    },
    prevalidateUpdate() {
      if (this.district.parentLea.number === this.coop.number) {
        this.mode = 'update'
      } else {
        this.failureMessage = "The specified district is not in the selected co-op.  You are not authorized to proceed at this time."
        this.confirmationFailure = true
      }
    },
    editSurvey() {
      const self = this
      this.$store
        .dispatch('arkansasReadyForLearningModule/getSurveys', { lea: this.district.number })
        .then(() => {
          if (self.surveys.length > 0 && self.district.adminEmailAddress.toUpperCase().trim() == self.email.toUpperCase().trim()) {
            //debugger
            window.location = `/BlendedLearningReadiness/ARReadyForLearning?id=${this.surveys[0].id}&lea=${this.surveys[0].districtLEA}&initial=true`
            //this.$router.push({name: 'edit-arrfl-survey', query: {lea: this.surveys[0].districtLEA, id: this.surveys[0].id, initial: 'true'}})
          } else {
            this.confirmationFailure = true
            this.failureMessage = "That e-mail address is not associated with the requested district's survey."
          }
        })
        .catch((error) => {
          this.failureMessage = error
        })
    },
    register() {
      this.loading = true

      this.$v.$touch()

      this.$store
        .dispatch('arkansasReadyForLearningModule/register', {
          districtLEA: this.districtLea,
          fName: this.fname,
          lName: this.lname,
          mName: this.mname,
          phone: this.phone,
          email: this.email,
          jobTitle: this.jobTitle
        })
        .then(() => (this.registered = true))
        .catch(error => (this.registrationError = error.response.data.message))
      //if (this.$v.$invalid)
    }
  },
  components: {
    //Promised
  },
  watch: {
    district() {
      this.registrationError = null
    }
  }
}
</script>

<template>
  <div>
    <b-button-group
      v-if="(availableStates.advanceStates || []).length + (availableStates.returnStates || []).length > 0"
    >
      <b-dropdown right text="Change Status..." variant="primary">
        <b-dropdown-item
          v-for="s in filteredAdvanceStates"
          :key="s"
          @click="onSetState(s)"
        >
          {{ stateMap.get(s).action }}
        </b-dropdown-item>
        <b-dropdown-item
          v-for="s in availableStates.returnStates"
          :key="s"
          @click="onSetState(s)"
        >
          {{ stateMap.get(s).action }}
        </b-dropdown-item>
      </b-dropdown>
    </b-button-group>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'available-states',
  data() {
    return {
      currentState: null
    }
  },
  computed: {
    ...mapState('requestModule', ['availableStates']),
    ...mapState('toolsModule/leaAssignment', ['stateMap', 'currentRequest']),
    ...mapGetters('toolsModule/leaAssignment', ['canCurrentRequestBeApproved', 'canCurrentRequestBeSentToPSA', 'allItemsAssignedReconfigurationCodes']),
    
    filteredAdvanceStates() {
      var states = this.availableStates.advanceStates

      if (!this.canCurrentRequestBeApproved) states = states.filter(r => r != 100)
      if (!this.allItemsAssignedReconfigurationCodes) states = states.filter(r => r != 12)
      if (!this.canCurrentRequestBeSentToPSA) states = states.filter(r => r != 11)

      return states
    }
  },
  methods: {
    onSetState(newState) {
      this.currentState = newState
      this.$emit('set-state', this.stateMap.get(this.currentState))
    }
  }
}
</script>

<template>
  <div class="my-2">
    <div class="row">
      <div class="col-12">
        <div class="card bg-light">
          <div class="card-body">
            <b-form inline>
              <b-form-group label="Cluster" class="w-21 mx-1 font-weight-bold">
                <b-form-select v-model="clusterId" class="w-100" :options="clusters" @input="clusterChanged" />
              </b-form-group>
              <b-form-group label="Pathway" class="w-21 mx-1 font-weight-bold">
                <b-form-select v-model="pathwayId" class="w-100" :options="pathways" @input="pathwayChanged" />
              </b-form-group>
              <b-form-group label="Program of Study" class="w-21 mx-1 font-weight-bold">
                <b-form-select v-model="posId" class="w-100" :options="programsOfStudy" @input="posChanged" />
              </b-form-group>
              <b-form-group class="mx-1 mt-4">
                <b-button-group>
                  <b-button @click="clear" variant="warning" title="Clear Filter">
                    <i class="fa fa-undo" aria-hidden="true" />
                  </b-button>
                </b-button-group>
              </b-form-group>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Types as CommonTypes } from '@/modules/cte/stores'
import { mapState, mapGetters } from 'vuex'
import orderBy from 'lodash.orderby'

export default {
   data() {
    return {
      userAreas: [],
      clusterId: null,
      pathwayId: null,
      posId: null
    }
  },

  computed: {
    ...mapState(CommonTypes.path, [CommonTypes.state.currentOAFilter]),
    ...mapGetters(CommonTypes.path, [CommonTypes.getters.occupationalAreaDrilldown]),
    filter() {
      return { cluster: this.clusterId, pathway: this.pathwayId, pos: this.posId }
    },
    clusters() {
      var clusters = [{ value: null, text: '(All Clusters)' }]
      clusters = clusters.concat(this.occupationalAreaDrilldown.flatMap(i => i.children).map(i => { return { value: i.id, text: i.name }}))
      return orderBy(clusters, [i=>i.text.toLowerCase()])
    },
    pathways() {
      var pathways = [{ value: null, text: '(All Pathways)' }]
      if (this.cluster) pathways = pathways.concat(this.cluster.children.map(i => { return { value: i.id, text: i.name }}))
      return orderBy(pathways, [i=>i.text.toLowerCase()])
    },
    programsOfStudy() {
      var pos = [{ value: null, text: '(All Programs of Study)' }]
      if (this.pathway) pos = pos.concat(this.pathway.children.map(i => { return { value: i.id, text: i.name }}))
      return orderBy(pos, [i=>i.text.toLowerCase()])
    },
    cluster() {
      return this.occupationalAreaDrilldown.flatMap(i => i.children).find(i => i.id == this.clusterId)
    },
    pathway() {
      if (!this.cluster) return null
      return this.cluster.children.find(i => i.id == this.pathwayId)
    }
  },
  methods: {
    applyFilter() {
      this.$store.dispatch(CommonTypes.actions.setOAFilter, this.filter)
      this.$emit('on-filter-changed', this.filter)
    },
    clear() {
      this.clusterId = null
      this.pathwayId = null
      this.posId = null
      this.$store.dispatch(CommonTypes.actions.setOAFilter, { cluster: null, pathway: null, pos: null })
      this.$emit('on-filter-cleared', this.filter)
    },
    clusterChanged() {
      this.pathwayId = null
      this.pathwayChanged()
    },
    pathwayChanged() {
      this.posId = null
      this.posChanged()
    },
    posChanged() {
      this.$store.dispatch(CommonTypes.actions.setOAFilter, this.filter)
      this.$emit('on-filter-changed', this.filter)
    }
  },
  mounted() {
    this.$emit('on-filter-changed', this.filter)
  },
  watch: {
    currentOAFilter: {
      immediate: true,
      handler(filter) {
        const clone = Object.assign({}, filter)
        this.clusterId = clone.cluster
        this.pathwayId = clone.pathway
        this.posId = clone.pos
      }
    }
  }
}
</script>

<style>
.w-20 {
  width: 20%;
}
</style>
const PlanCategoryTypes = [
  { key: 1, icon: 'fas fa-id-badge' },
  { key: 2, icon: 'fas fa-stamp' },
  { key: 3, icon: 'fas fa-box' },
  { key: 4, icon: 'fas fa-handshake' }
]

const DataLevels = {
  State: { id: 3, name: 'State of Arkansas', color: '#007a66' },
  District: { id: 2, name: 'District', color: '#2c97de' },
  School: { id: 1, name: 'School', color: '#e87e04' }
}
const Roles = {
  Reviewer: 'Insights_ESA_Reviewers',
  Admin: 'Insights_ESA_Admins',
  Viewer: 'Insights_ESA_Observers',
  SystemAdministrator: 'Insights_System_Administrators',
}

export { PlanCategoryTypes, DataLevels, Roles }
<template>
  <div>
    <paginated-table small :items="items" :fields="fields">
      <template v-slot:cell(completedFAFSA)="data">
        <pretty-checkbox :value="data.value==='Yes'" />
      </template>
      <template v-slot:cell(student)="data">
        <div class="text-nowrap">
          <router-link :to="{name: 'CTE/CareerCoach/StudentPage', params: { studentId: data.item.studentId }, query: {lea: data.item.schoolLEA }}">
            {{ data.value }}
          </router-link>
        </div>
        <b-badge variant="secondary">
          {{ data.item.studentId }}
        </b-badge>
      </template>
      <template v-slot:cell(completedYOUniversal)="data">
        <pretty-checkbox :value="data.value==='Yes'" />
      </template>
      <template #cell(schoolLEA)="data">
        <div>
          <div class="d-inline">
            <button
              type="button"
              class="btn btn-link"
              @click="goToLea(data.value)"
              :id="data.index + '-' + data.value">
              {{ data.value }}
            </button>
                &nbsp;
            <lea-tooltip
              :target-selector="data.index + '-' + data.value"
              :lea-number="data.value"
              :label="data.label" />
          </div>
        </div>
      </template>
      <template #cell(districtLEA)="data">
        <div>
          <div class="d-inline">
            <button
              type="button"
              class="btn btn-link"
              @click="goToLea(data.value)"
              :id="data.index + '-' + data.value">
              {{ data.value }}
            </button>
                &nbsp;
            <lea-tooltip
              :target-selector="data.index + '-' + data.value"
              :lea-number="data.value"
              :label="data.label" />
          </div>
        </div>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import PaginatedTable from '@/components/PaginatedTable.vue'
import PrettyCheckbox from '@/components/PrettyCheckbox.vue'
import LeaTooltip from '@/components/LeaTooltip.vue'

export default {
  components: { PaginatedTable, PrettyCheckbox, LeaTooltip },
  data() {
    return {
      loadData: null,
    }
  },
  props: {
    items: {
      type: Array
    },
    fields: Array
  }
}
</script>
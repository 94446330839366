import ApiService from '@/services/ApiService'
import removeNamespace from '@/stores/removeNamespace'
import { createGetTransaction } from '@/stores/VuexTransaction'

export const Types = {
  state: {
    showInfo: 'showInfo',
    elaPercentReadyOrExceeds: 'elaPercentReadyOrExceeds',
    mathPercentReadyOrExceeds: 'mathPercentReadyOrExceeds',
    sqssScores: 'sqssScores',
    sqssScoresReading: 'sqssScoresReading',
    fourYearGraduationRates: 'fourYearGraduationRates',
    fiveYearGraduationRates: 'fiveYearGraduationRates',
    elaGrowth: 'elaGrowth',
    mathGrowth: 'mathGrowth',
    avgClassSize: 'avgClassSize',
    teacherDiversity: 'teacherDiversity',
    studentDiversity: 'studentDiversity',
    teacherExperience: 'teacherExperience',
    licenseExceptions: 'licenseExceptions',
    specialEducation: 'specialEducation',
    giftedAndTalented: 'giftedAndTalented',
    englishLearners: 'englishLearners',
    collegeGoingRate: 'collegeGoingRate',
    collegeCreditAccumulationRate: 'collegeCreditAccumulationRate',
    remediationRate: 'remediationRate',
    expenditurePerStudent: 'expenditurePerStudent',
    chronicAbsence: 'chronicAbsence',
    thirdQuarterAvgDailyMembership: 'thirdQuarterAvgDailyMembership',
    foundationFunding: 'foundationFunding',
    studentTeacherRatio: 'studentTeacherRatio',
    attendanceRate: 'attendanceRate',
    dislexia: 'dislexia',
    apMetrics: 'apMetrics',
    nbct: 'nbct',
    attritionRate: 'attritionRate',
    readingPercentInNeedOfSupport: 'readingPercentInNeedOfSupport',
    mathPercentInNeedOfSupport: 'mathPercentInNeedOfSupport',
    elaPercentInNeedOfSupport: 'elaPercentInNeedOfSupport',
  },
  actions: {
    getReadingPercentInNeedOfSupport: 'ESA/survey/getReadingPercentInNeedOfSupport',
    getELAPercentInNeedOfSupport: 'ESA/survey/getELAPercentInNeedOfSupport',
    getMathPercentInNeedOfSupport: 'ESA/survey/getMathPercentInNeedOfSupport',
    getMathPercentReadyOrExceeds: 'ESA/survey/getMathPercentReadyOrExceeds',
    getElaPercentReadyOrExceeds: 'ESA/survey/getElaPercentReadyOrExceeds',
    getSqssScores: 'ESA/survey/getSqssScores',
    getSqssScoresReading: 'ESA/survey/getSqssScoresReading',
    getFourYearGraduationRates: 'ESA/survey/getFourYearGraduationRates',
    getFiveYearGraduationRates: 'ESA/survey/getFiveYearGraduationRates',
    getElaGrowth: 'ESA/survey/getElaGrowth',
    getMathGrowth: 'ESA/survey/getMathGrowth',
    getAvgClassSize: 'ESA/survey/getAvgClassSize',
    getTeacherDiversity: 'ESA/survey/getTeacherDiversity',
    getStudentDiversity: 'ESA/survey/getStudentDiversity',
    getTeacherExperience: 'ESA/survey/getTeacherExperience',
    getLicenseExceptions: 'ESA/survey/getLicenseExceptions',
    getSpecialEducation: 'ESA/survey/getSpecialEducation',
    getGiftedAndTalented: 'ESA/survey/getGiftedAndTalented',
    getEnglishLearners: 'ESA/survey/getEnglishLearners',
    getCollegeGoingRate: 'ESA/survey/getCollegeGoingRate',
    getCollegeCreditAccumulationRate: 'ESA/survey/getCollegeCreditAccumulationRate',
    getRememediationRate: 'ESA/survey/getRememediationRate',
    getExpenditurePerStudent: 'ESA/survey/getExpenditurePerStudent',
    getChronicAbsence: 'ESA/survey/getChronicAbsence',
    getThirdQuarterAvgDailyMembership: 'ESA/survey/getThirdQuarterAvgDailyMembership',
    getFoundationFunding: 'ESA/survey/getFoundationFunding',
    getStudentTeacherRatio: 'ESA/survey/getStudentTeacherRatio',
    getAttendanceRate: 'ESA/survey/getAttendanceRate',
    getDislexia: 'ESA/survey/getDislexia',
    getApMetrics: 'ESA/survey/getApMetrics',
    getNBCT: 'ESA/survey/getNBCT',
    getAttritionRate: 'ESA/survey/getAttritionRate',
  },
  mutations: {
    setShowInfo: 'ESA/survey/setShowInfo',
    setReadingPercentInNeedOfSupport: 'ESA/survey/setReadingPercentInNeedOfSupport',
    setMathPercentInNeedOfSupport: 'ESA/survey/setMathPercentInNeedOfSupport',
    setELAPercentInNeedOfSupport: 'ESA/survey/setELAPercentInNeedOfSupport',
    setElaPercentReadyOrExceeds: 'ESA/survey/setElaPercentReadyOrExceeds',
    setMathPercentReadyOrExceeds: 'ESA/survey/setMathPercentReadyOrExceeds',
    setSqssScores: 'ESA/survey/setSqssScores',
    setSqssScoresReading: 'ESA/survey/setSqssScoresReading',
    setFourYearGraduationRates: 'ESA/survey/setFourYearGraduationRates',
    setFiveYearGraduationRates: 'ESA/survey/setFiveYearGraduationRates',
    setElaGrowth: 'ESA/survey/setElaGrowth',
    setMathGrowth: 'ESA/survey/setMathGrowth',
    setAvgClassSize: 'ESA/survey/setAvgClassSize',
    setTeacherDiversity: 'ESA/survey/setTeacherDiversity',
    setStudentDiversity: 'ESA/survey/setStudentDiversity',
    setTeacherExperience: 'ESA/survey/setTeacherExperience',
    setLicenseExceptions: 'ESSA/survey/setLicenseExceptions',
    setSpecialEducation: 'ESA/survey/setSpecialEducation',
    setGiftedAndTalented: 'ESA/survey/setGiftedAndTalented',
    setEnglishLearners: 'ESA/survey/setEnglishLearners',
    setCollegeGoingRate: 'ESA/survey/setCollegeGoingRate',
    setCollegeCreditAccumulationRate: 'ESA/survey/setCollegeCreditAccumulationRate',
    setRememediationRate: 'ESA/survey/setRememediationRate',
    setExpenditurePerStudent: 'ESA/survey/setExpenditurePerStudent',
    setChronicAbsence: 'ESA/survey/setChronicAbsence',
    setThirdQuarterAvgDailyMembership: 'ESA/survey/setThirdQuarterAvgDailyMembership',
    setFoundationFunding: 'ESA/survey/setFoundationFunding',
    setStudentTeacherRatio: 'ESA/survey/setStudentTeacherRatio',
    setAttendanceRate: 'ESA/survey/setAttendanceRate',
    setDislexia: 'ESA/survey/setDislexia',
    setApMetrics: 'ESA/survey/setApMetrics',
    setNBCT: 'ESA/survey/setNBCT',
    setAttritionRate: 'ESA/survey/setAttritionRate',
  },
  getters: {
  },
  path: 'ESA/survey'
}
const _types = removeNamespace('ESA/survey/', Types)

export class Survey {
  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
    this.createGetTransaction = createGetTransaction

    this.createGetTransaction(_types.state.readingPercentInNeedOfSupport,
      _types.mutations.setReadingPercentInNeedOfSupport,
      _types.actions.getReadingPercentInNeedOfSupport,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/achievement/InNeedOfSupport/Reading`
    )
    this.createGetTransaction(_types.state.mathPercentInNeedOfSupport,
      _types.mutations.setMathPercentInNeedOfSupport,
      _types.actions.getMathPercentInNeedOfSupport,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/achievement/InNeedOfSupport/Mathematics`
    )
    this.createGetTransaction(_types.state.elaPercentInNeedOfSupport,
      _types.mutations.setELAPercentInNeedOfSupport,
      _types.actions.getELAPercentInNeedOfSupport,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/achievement/InNeedOfSupport/ELA`
    )
    this.createGetTransaction(_types.state.elaPercentReadyOrExceeds,
      _types.mutations.setElaPercentReadyOrExceeds,
      _types.actions.getElaPercentReadyOrExceeds,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/achievement/percentReadyOrExceeds/ELA`
    )
    this.createGetTransaction(_types.state.mathPercentReadyOrExceeds,
      _types.mutations.setMathPercentReadyOrExceeds,
      _types.actions.getMathPercentReadyOrExceeds,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/achievement/percentReadyOrExceeds/Mathematics`
    )
    this.createGetTransaction(_types.state.fourYearGraduationRates,
      _types.mutations.setFourYearGraduationRates,
      _types.actions.getFourYearGraduationRates,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/achievement/fourYearGraduationRates`
    )
    this.createGetTransaction(_types.state.fiveYearGraduationRates,
      _types.mutations.setFiveYearGraduationRates,
      _types.actions.getFiveYearGraduationRates,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/achievement/fiveYearGraduationRates`
    )
    this.createGetTransaction(_types.state.elaGrowth,
      _types.mutations.setElaGrowth,
      _types.actions.getElaGrowth,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/academicProgress/studentGrowth/ELA`
    )
    this.createGetTransaction(_types.state.mathGrowth,
      _types.mutations.setMathGrowth,
      _types.actions.getMathGrowth,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/academicProgress/studentGrowth/Math`
    )
    this.createGetTransaction(_types.state.sqssScores,
      _types.mutations.setSqssScores,
      _types.actions.getSqssScores,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/achievement/sqss`
    )
    this.createGetTransaction(_types.state.sqssScoresReading,
      _types.mutations.setSqssScoresReading,
      _types.actions.getSqssScoresReading,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/achievement/sqss/reading`
    )
    this.createGetTransaction(_types.state.avgClassSize,
      _types.mutations.setAvgClassSize,
      _types.actions.getAvgClassSize,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/staffing/avgClassSize`
    )
    this.createGetTransaction(_types.state.studentDiversity,
      _types.mutations.setStudentDiversity,
      _types.actions.getStudentDiversity,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/staffing/studentDiversity`
    )
    this.createGetTransaction(_types.state.teacherDiversity,
      _types.mutations.setTeacherDiversity,
      _types.actions.getTeacherDiversity,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/staffing/teacherDiversity`
    )
    this.createGetTransaction(_types.state.teacherExperience,
      _types.mutations.setTeacherExperience,
      _types.actions.getTeacherExperience,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/staffing/teacherExperience`
    )
    this.createGetTransaction(_types.state.licenseExceptions,
      _types.mutations.setLicenseExceptions,
      _types.actions.getLicenseExceptions,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/staffing/licenseExceptions`
    )
    this.createGetTransaction(_types.state.specialEducation,
      _types.mutations.setSpecialEducation,
      _types.actions.getSpecialEducation,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/studentSupports/specialEducation`
    )
    this.createGetTransaction(_types.state.englishLearners,
      _types.mutations.setEnglishLearners,
      _types.actions.getEnglishLearners,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/studentSupports/esol`
    )
    this.createGetTransaction(_types.state.englishLearners,
      _types.mutations.setEnglishLearners,
      _types.actions.getEnglishLearners,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/studentSupports/esol`
    )
    this.createGetTransaction(_types.state.expenditurePerStudent,
      _types.mutations.setExpenditurePerStudent,
      _types.actions.getExpenditurePerStudent,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/studentSupports/expenditurePerStudent`
    )
    this.createGetTransaction(_types.state.chronicAbsence,
      _types.mutations.setChronicAbsence,
      _types.actions.getChronicAbsence,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/studentSupports/chronicAbsence`
    )
    this.createGetTransaction(_types.state.collegeGoingRate,
      _types.mutations.setCollegeGoingRate,
      _types.actions.getCollegeGoingRate,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/postSecondary/collegeGoingRate`
    )
    this.createGetTransaction(_types.state.collegeCreditAccumulationRate,
      _types.mutations.setCollegeCreditAccumulationRate,
      _types.actions.getCollegeCreditAccumulationRate,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/postSecondary/collegeCreditAccumulationrate`
    )
    this.createGetTransaction(_types.state.remediationRate,
      _types.mutations.setRememediationRate,
      _types.actions.getRememediationRate,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/postSecondary/remediationRate`
    ),
    this.createGetTransaction(_types.state.giftedAndTalented,
      _types.mutations.setGiftedAndTalented,
      _types.actions.getGiftedAndTalented,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/studentSupports/gt`)
    this.createGetTransaction(_types.state.thirdQuarterAvgDailyMembership,
      _types.mutations.setThirdQuarterAvgDailyMembership,
      _types.actions.getThirdQuarterAvgDailyMembership,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/studentSupports/3rdQuarterAvgDailyMembership`)
    this.createGetTransaction(_types.state.foundationFunding,
      _types.mutations.setFoundationFunding,
      _types.actions.getFoundationFunding,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/studentSupports/foundationFunding`)
    this.createGetTransaction(_types.state.studentTeacherRatio,
      _types.mutations.setStudentTeacherRatio,
      _types.actions.getStudentTeacherRatio,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/staffing/studentTeacherRatio`)
    this.createGetTransaction(_types.state.attendanceRate,
      _types.mutations.setAttendanceRate,
      _types.actions.getAttendanceRate,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/studentSupports/attendanceRate`)
    this.createGetTransaction(_types.state.dislexia,
      _types.mutations.setDislexia,
      _types.actions.getDislexia,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/studentSupports/dislexia`)
    this.createGetTransaction(_types.state.apMetrics,
      _types.mutations.setApMetrics,
      _types.actions.getApMetrics,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/studentSupports/apMetrics`)
    this.createGetTransaction(_types.state.nbct,
      _types.mutations.setNBCT,
      _types.actions.getNBCT,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/staffing/nbct`)
    this.createGetTransaction(_types.state.attritionRate,
      _types.mutations.setAttritionRate,
      _types.actions.getAttritionRate,
      (payload) => `${this._baseUrl}api/esa/${payload.lea}/staffing/attritionRate`)
  }
  

  namespaced = true

  state = {
    [Types.state.showInfo]: true,
  }

  mutations = {
    [_types.mutations.setShowInfo]: (state, data) => state.showInfo = data,
  }

  actions = {
  }
}
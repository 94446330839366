<template>
  <div>
    <b-alert show variant="warning" v-if="selectedLea.type > 2">
      Due to volume of data, student level reports are only available at the District or School level. Please select an appropriate LEA from the LEA Context Picker above.
    </b-alert>
    <div v-else>
      <div v-if="reportData" class="report-output">
        <b-input-group prepend="Student Filter" class="my-2">
          <b-form-input v-model="filter" placeholder="Enter student name or Student Id" />
          <b-input-group-append>
            <b-button variant="outline-warning" @click="filter=''">
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-table-simple sticky-header="500px" class="table table-sm small">
          <b-thead class="border-left border-right" head-variant="light">
            <b-tr>
              <th colspan="2">
                &nbsp;
              </th>
              <!-- <th rowspan="2" style="width: 120px;">
                Student
              </th> -->
              <!-- <th rowspan="2" class="border-right" style="width: 60px;">
                Grade
              </th> -->
              <th colspan="8" class="text-center border-right">
                Initial Contact Date
              </th>
              <th colspan="10" class="text-center">
                Duplicate Contact(s)
              </th>
            </b-tr>
            <b-tr>
              <th style="width: 120px;">
                Student
              </th>
              <th class="border-right" style="width: 60px;">
                Grade
              </th>
              <th title="Career Plan" style="width: 65px;" class="text-center">
                CP
              </th>
              <th title="Financial Aid" style="width: 65px;" class="text-center">
                FA
              </th>
              <th title="Career Assessment" style="width: 65px;" class="text-center">
                CA
              </th>
              <th title="College Admissions" style="width: 65px;" class="text-center">
                CO
              </th>
              <th title="Academic Concurrent Credit" style="width: 65px;" class="text-center">
                ACC
              </th>
              <th title="CTE Concurrent Credit" style="width: 65px;" class="text-center">
                CTECC
              </th>
              <th title="Apprenticeship" style="width: 65px;" class="text-center">
                APP
              </th>
              <th class="border-right text-center" title="Workbase Learning" style="width: 65px;">
                WBL
              </th>

              <th title="Duplicate Contacts" style="width: 65px;" class="text-center">
                Total<br>Duplicate<br>Contacts
              </th>

              <th title="Career Plan" style="width: 65px;" class="text-center">
                CP
              </th>
              <th title="Financial Aid" style="width: 65px;" class="text-center">
                FA
              </th>
              <th title="Career Assessment" style="width: 65px;" class="text-center">
                CA
              </th>
              <th title="College Admissions" style="width: 65px;" class="text-center">
                CO
              </th>
              <th title="Academic Concurrent Credit" style="width: 65px;" class="text-center">
                ACC
              </th>
              <th title="CTE Concurrent Credit" style="width: 65px;" class="text-center">
                CTECC
              </th>
              <th title="Apprenticeship" style="width: 65px;" class="text-center">
                APP
              </th>
              <th class="border-right text-center" title="Workbase Learning" style="width: 65px;">
                WBL
              </th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="s in filteredData" :key="s.studentId" class="border-left">
              <td>
                <div class="text-nowrap">
                  <router-link :to="{name: 'CTE/CareerCoach/StudentPage', params: { studentId: s.studentId }, query: { lea: $route.query.lea }}">
                    {{ s.lastName }}, {{ s.firstName }}
                  </router-link>
                </div>
                <b-badge variant="secondary">
                  {{ s.studentId }}
                </b-badge>
              </td>
              <td class="border-right">
                {{ s.grade }}
              </td>
              <td>
                {{ s.initialCareerPlan | date('short-date') }}
              </td>
              <td>
                {{ s.initialFinancialAid | date('short-date') }}
              </td>
              <td>
                {{ s.initialCareerAssessment | date('short-date') }}
              </td>
              <td>
                {{ s.initialCollegeAdmissions | date('short-date') }}
              </td>
              <td>
                {{ s.initialAcademicConcurrentCredit | date('short-date') }}
              </td>
              <td>
                {{ s.initialCTEConcurrentCredit | date('short-date') }}
              </td>
              <td>
                {{ s.initialApprenticeship | date('short-date') }}
              </td>
              <td class="border-right">
                {{ s.initialWorkbasedLearning | date('short-date') }}
              </td>
              <td class="text-center border-right">
                {{ s.totalDuplicates }}
              </td>
            
              <td class="text-center">
                {{ dupCount(s.dupCareerPlan) }}
              </td>
              <td class="text-center">
                {{ dupCount(s.dupFinancialAid) }}
              </td>
              <td class="text-center">
                {{ dupCount(s.dupCareerAssessment) }}
              </td>
              <td class="text-center">
                {{ dupCount(s.dupCollegeAdmissions) }}
              </td>
              <td class="text-center">
                {{ dupCount(s.dupAcademicConcurrentCredit) }}
              </td>
              <td class="text-center">
                {{ dupCount(s.dupCTEConcurrentCredit) }}
              </td>
              <td class="text-center">
                {{ dupCount(s.dupApprenticeship) }}
              </td>
              <td class="text-center border-right">
                {{ dupCount(s.dupWorkbasedLearning) }}
              </td>
            </b-tr>
          </b-tbody>
          <b-tfoot class="bg-light">
            <b-tr class="font-weight-bold border-bottom border-left border-right">
              <td>
                Totals
              </td>
              <td>
                {{ filteredData.length }}
              </td>
              <td class="text-center">
                {{ this.countOfInitial('initialCareerPlan') }}
              </td>
              <td class="text-center">
                {{ this.countOfInitial('initialFinancialAid') }}
              </td>
              <td class="text-center">
                {{ this.countOfInitial('initialCareerAssessment') }}
              </td>
              <td class="text-center">
                {{ this.countOfInitial('initialCollegeAdmissions') }}
              </td>
              <td class="text-center">
                {{ this.countOfInitial('initialAcademicConcurrentCredit') }}
              </td>
              <td class="text-center">
                {{ this.countOfInitial('initialCTEConcurrentCredit') }}
              </td>
              <td class="text-center">
                {{ this.countOfInitial('initialApprenticeship') }}
              </td>
              <td class="text-center border-right">
                {{ this.countOfInitial('initialWorkbasedLearning') }}
              </td>


              <td class="text-center border-right">
                {{ this.totalDuplicates('totalDuplicates') }}
              </td>
              <td class="text-center">
                {{ this.totalDuplicates('dupCareerPlan') }}
              </td>
              <td class="text-center">
                {{ this.totalDuplicates('dupFinancialAid') }}
              </td>
              <td class="text-center">
                {{ this.totalDuplicates('dupCareerAssessment') }}
              </td>
              <td class="text-center">
                {{ this.totalDuplicates('dupCollegeAdmissions') }}
              </td>
              <td class="text-center">
                {{ this.totalDuplicates('dupAcademicConcurrentCredit') }}
              </td>
              <td class="text-center">
                {{ this.totalDuplicates('dupCTEConcurrentCredit') }}
              </td>
              <td class="text-center">
                {{ this.totalDuplicates('dupApprenticeship') }}
              </td>
              <td class="text-center border-right">
                {{ this.totalDuplicates('dupWorkbasedLearning') }}
              </td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </div>      
    </div>
  </div>
</template>

<script>
import { VisitTypes } from '@/modules/cte/stores/careercoach/reporting'
import { formatDate } from '@/helpers/formatters'
import sumBy from 'lodash.sumby'

export default {
  data() {
    return {
      filter: null,
      VisitTypes,
      fields: [
        {key: 'studentId', label: 'Student' },
        'grade',
        { key: 'initialCareerPlan', formatter: (v) => formatDate(v, 'short-date') },
        { key: 'initialFinancialAid', formatter: (v) => formatDate(v, 'short-date') },
        { key: 'initialCareerAssessment', formatter: (v) => formatDate(v, 'short-date') },
        { key: 'initialCollegeAdmissions', formatter: (v) => formatDate(v, 'short-date') },
        { key: 'initialAcademicConcurrentCredit', formatter: (v) => formatDate(v, 'short-date') },
        { key: 'initialCTEConcurrentCredit', formatter: (v) => formatDate(v, 'short-date') },
        { key: 'initialApprenticeship', formatter: (v) => formatDate(v, 'short-date') },
        { key: 'initialWorkbasedLearning', formatter: (v) => formatDate(v, 'short-date') },
        { key: 'numCareerPlan', formatter: (v) => { return v && v > 1 ? v - 1 : null } },
        { key: 'numFinancialAid', formatter: (v) => { return v && v > 1 ? v - 1 : null } },
        { key: 'numCareerAssessment', formatter: (v) => { return v && v > 1 ? v - 1 : null } },
        { key: 'numCollegeAdmissions', formatter: (v) => { return v && v > 1 ? v - 1 : null } },
        { key: 'numAcademicConcurrentCredit', formatter: (v) => { return v && v > 1 ? v - 1 : null } },
        { key: 'numCTEConcurrentCredit', formatter:(v) => { return v && v > 1 ? v - 1 : null } },
        { key: 'numApprenticeship', formatter: (v) => { return v && v > 1 ? v - 1 : null } },
        { key: 'numWorkbasedLearning', formatter: (v) => { return v && v > 1 ? v - 1 : null } },
      ]
    }
  },
  computed: {
    filteredData() {
      let re = new RegExp ('.*' + (this.filter || '') + '.*', 'i')
      return this.reportData.filter(s => re.test(s.lastName + '|' + s.firstName + '|' + s.studentId))
    }
  },
  methods: {
    dupCount(count) {
      return count // && count > 1 ? count - 1 : null
    },
    totalDuplicates(property) {
      return sumBy(this.filteredData, s=>s[property])
    },
    countOfInitial(property) {
      return sumBy(this.filteredData, s=>s[property] ? 1 : 0)
    }
  },
  props: {
    reportData: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style>

</style>
<template>
  <div style="height: 100%;">
    <div class="sidebar-scroll">
      <ul class="nav nav-pills flex-column ml-2 mr-2">
        <li class="nav-item">
          <a class="nav-link font-weight-bold" href="#current">
            Current Actual / Budget
          </a>
        </li>
        <div v-for="(category, index) in categories" :key="category">
          <div v-if="index%2 == 0">
            <li class="nav-item">
              <a class="nav-link" :href="'#trend-' + index">
                {{ category }} / 
                <span v-if="index+1 < categories.length">
                  {{ categories[index + 1] }}
                </span>
              </a>
            </li>
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
  import { Categories } from './Categories'

  export default {
    data() {
      return {
      }
    },
    computed: {
      categories() {
        return Categories
      },
    },
  }
</script>

import Vue from 'vue'
import Vuelidate from 'vuelidate'
import DigitalLearningWaiverContent from './details/DigitalLearningWaiverContent.vue'
import ReopenWaiverRequestWidget from './workflow/ReopenWaiverRequestWidget.vue'
import { LeaTypes } from '@/helpers/leaTypes'
import { Types, StateMap, WaiverTypes } from '@/stores/digitalLearning'
import { mapState } from 'vuex'
import { deepClone } from '@/helpers/globalHelpers'
import { DigitalLearningWaiverStatuses } from './enums'

Vue.use(Vuelidate)

const DigitalLearningWaiverMixin = Vue.extend({
  data() {
    return {
      loadData: Promise.resolve(),
      resolvedLea: {},
      editMode: false,
      request: null,
      StateMap
    }
  },
  computed: {
    ...mapState(Types.path, [
      Types.state.waiverRequests, 
      Types.state.selectedWaiverRequest, 
      Types.state.availableStates,
      Types.state.newDLWaiverRequestsLockedStatus,
      Types.state.newAMIApplicationsLockedStatus,
      Types.state.snapshots,
    ]),
    isCharter() {
      if (!this.selectedWaiverRequest)
        return false
      return this.leaDictionary[this.selectedWaiverRequest.districtLEA].isCharter
    },
    waiverRequestTitle() {
      if (!this.selectedWaiverRequest || !this.selectedWaiverRequest.waiverType || !this.waiverRequestId)
        return 'Digital Learning District Summary'

      if (this.selectedWaiverRequest.waiverType == WaiverTypes.AMI.value) 
        return WaiverTypes.AMI.title
      
      return WaiverTypes.DigitalLearning.title
    },
    currentStatus() {
      return this.selectedWaiverRequest ? StateMap.get(this.selectedWaiverRequest.status) : null
    },
    waiverRequestId() {
      return this.$route.query.waiverRequestId
    },
    isDistrict() {
      return this.resolvedLea.type == LeaTypes.District
    },
    isValid() {
      return !this.$v.$invalid
    },
    withdrawalRequest() {
      return this.request.changeLogs.find(c => c.type == 'Withdrawal Request')
    },
    showWithdrawalRequest() {
      return this.request.withdrawalRequested && this.request.status == 11
    },
    isDigitalLearning() {
      return (this.selectedWaiverRequest || {}).waiverType == WaiverTypes.DigitalLearning.value
    },
    isAMI() {
      return (this.selectedWaiverRequest || {}).waiverType == WaiverTypes.AMI.value
    },
    canReopen() {
      const states = this.availableStates || { returnStates: [] }
      return (states.returnStates || []).includes(DigitalLearningWaiverStatuses.ReOpened)
    },
  },
  methods: {
    save() {
      this.editMode = false
      this.$v.$reset()
      return this.$store.dispatch(Types.actions.updateWaiverRequest, this.request)
        .then(() => { this.$v.$reset() })
    },
    cancel() {
      this.editMode = false
      this.initRequest()
    },
    initRequest() {
      if (this.selectedWaiverRequest) 
        this.request = deepClone(this.selectedWaiverRequest)
      else 
        this.request = null
      this.$v.$reset()
    },
  },
  watch: {
    waiverRequestId: {
      immediate: true,
      handler(id) {
        if (id) {
          this.loadData = this.$store.dispatch(Types.actions.getWaiverRequest, { lea: this.resolvedLea.number, id })
        }
      }
    },
    selectedWaiverRequest: {
      immediate: true,
      deep: true,
      handler() {
        this.initRequest()
      }
    }
  },
  provide() {
    return {
      $v: this.$v
    }
  },
  validations: {
    request: {
      districtLEA: {},
      superintendent: {},
      email: {},
      phone: {},
      durationSchoolYears: {},
      programAffectedLeasResponse: { hasAtLeastOneAffectedLea },
      waiverTopicsResponse: {},
      digitalModelResponse: {},
      amiResponse: { hasAMIDays }
    }
  },
  components: {
    DigitalLearningWaiverContent,
    ReopenWaiverRequestWidget,
  }
})

function hasAMIDays(value) {
  if (this.request.waiverType == WaiverTypes.AMI.value) {
    return Boolean(value && value.requestedAMIDays)
  }
  else
    return true // not AMI
}

function hasAtLeastOneAffectedLea(value) {
  if (this.request && this.request.waiverType == WaiverTypes.DigitalLearning.value) {
    return Boolean(value && value.length > 0)
  }
  else
    return true // not Digital Learning
}


export { DigitalLearningWaiverMixin }
<template lang="html">
  <div :class="containerClass" :style="cardStyle" style="overflow-y: hidden; overflow-x: hidden;">
    <div :class="cardClass">
      <Promised :promise="loadData">
        <template v-slot:pending>
          <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
            <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
          </div>
        </template>
        <template v-slot="data" :class="centerContent ? 'align-items-center justify-content-center' : ''">
          <div class="row" style="margin-left:auto; margin-right:auto;">
            <span class="d-none">
              {{ data }}
            </span>
            <div class="col-md-11">
              <chart-data-toggle v-model="chartMode" v-show="enableDataToggle" @input="chartToggled" />
            </div>  
            <div class="col-md-1 align-middle">
              <custom-tooltip v-if="showTooltip" :content="tooltipContent" :element-id="tooltipId" :icon-class="'text-info fa-lg'" />
            </div>
          </div>
          <slot :chartMode="chartMode" />
        </template>
        <template v-slot:rejected="error" class="align-items-center justify-content-center">
          <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
        </template>
      </Promised>
    </div>
  </div>
</template>

<script>
import CardStateIcon from './CardStateIcon.vue'
import ChartDataToggle from './ChartDataToggle.vue'
import { Promised } from 'vue-promised'
import CustomTooltip from './CustomTooltip'

export default {
  name: 'loadable-chart-card',
  data() {
    return {
      chartMode: this.initialChartMode
    }
  },
  props: {
    loadData: Promise,
    cardStyle: {
      type: String,
      default: 'height: 27em;'
    },
    centerContent: {
      type: Boolean,
      default: true
    },
    enableDataToggle: {
      type: Boolean,
      default: true
    },
    showTooltip:{
      type: Boolean,
      default:false
    },
    tooltipContent: String,
    tooltipId: {
      type: String,
      default: 'tooltip1'
    },
    cardClass: {
      type: String,
      default: 'p-1 inner-card justify-content-center'
    },
    containerClass: {
      type: String,
      default: 'card d-flex print-full-width'
    },
    initialChartMode:{
      type: Boolean,
      default: true
    }
  },
  components: {
    Promised,
    ChartDataToggle,
    CardStateIcon,
    CustomTooltip
  },
  methods:{
    chartToggled(chartMode) {
      this.chartMode = chartMode
      this.$emit('chart-toggled', chartMode)
    }
  }
}
</script>
<template lang="html">
  <div class="card-body">
    <loadable-chart-card :load-data="loadData" card-class="p-1 inner-card justify-content-center" card-style="">
      <template v-slot:default="slotProps">
        <div class="chartElem" align="center" v-show="slotProps.chartMode">
          <highcharts ref="highcharts" class="chart" :options="chartOptions" />
          <div class="alert alert-info d-print-none" style="width: 700px;">
            <b>
              Chart Zooming: 
            </b>Select an area to zoom by clicking and dragging.
            <br> 
            Click "Reset zoom" to return to default view.
          </div>
        </div>
        <div v-show="!slotProps.chartMode" class="m-3">
          <chart-data-table :items="dataItems()" />
        </div>
      </template>
    </loadable-chart-card>
  </div>
</template>

<script>
import LoadableChartCard from '../../../LoadableChartCard.vue'
import ChartDataTable from '../../../ChartDataTable.vue'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'lea-growth',
  data() {
    return {
      chartOptions: {
        chart: {
          height: 400,
          width: 700,
          type: 'scatter',
          backgroundColor: 'rgba(255,255,255,0.002)',
          style: {
            fontFamily: 'Muli, Helvetica, Arial, sans-serif'
          },
          zoomType: 'xy',
          events: {
            load: function() {
              var chart = this,
                renderer = chart.renderer
                renderer.label('Higher Achievement<br />Lower Growth', chart.plotLeft, chart.plotTop)
                  .css({ color: '#434348' })
                  .add()

                renderer.label('Lower Achievement<br />Lower Growth', chart.plotLeft, chart.plotTop + chart.plotHeight - 35)
                  .css({ color: '#434348' })
                  .add()

                renderer.label('Higher Achievement<br />Higher Growth', chart.plotLeft + chart.plotWidth - 10, chart.plotTop)
                  .css({ color: '#434348', 'text-anchor': 'end' })
                  .add()

                renderer.label('Lower Achievement<br />Higher Growth', chart.plotLeft + chart.plotWidth - 10, chart.plotTop + chart.plotHeight - 35)
                  .css({ color: '#434348', 'text-anchor': 'end' })
                  .add()
            }
          }
        },
        //colors: [color],
        title: false,
        legend: false,
        credits: {
          enabled: false
        },
        navigation: {
          buttonOptions: {
            enabled: false
          }
        },
        xAxis: {
          title: {
            text: 'Value-Added School Growth Score'
          },
          min: 60,
          max: 100,
          tickInterval: 5,
          plotLines: [
            {
              color: '#434348',
              value: 80,
              width: 1,
              zIndex: 5
            }
          ]
        },
        yAxis: {
          title: {
            text: 'Weighted Achivement Score'
          },
          min: 0,
          max: 140,
          tickInterval: 20,
          gridLineColor: 'transparent',
          plotLines: [
            {
              color: '#434348',
              value: 70,
              width: 1,
              zIndex: 5
            }
          ]
        },
        plotOptions: {
          scatter: {
            tooltip: {
              headerFormat: '',
              pointFormat: `<b>{point.schoolName} ({point.lea})</b><br />Value-Added Growth Score: {point.x}<br />Weighted Achievement Score: {point.y}`,
              useHTML: true,
              shadow: false,
              shared: true
            },
            minSize: 10,
            maxSize: 10
          }
        },
        series: [
          {
            name: 'Distribution',
            id: 'primary',
            animation: false,
            data: this.growthVsAchievement,
            marker: {
              symbol: 'square'
            },
            turboThreshold: 2000
          }
        ]
      }
    }
  },
  components: {
    LoadableChartCard,
    ChartDataTable
  },
  computed: {
    ...mapState('academicsModule/growth', [
      'grade',
      'subject',
      'subgroup',
      'growthVsAchievement'
    ]),
    ...mapGetters('globalModule', ['leaDictionary'])
  },
  props: {
    loadData: Promise
  },
  methods: {
    getSchoolName(lea) {
      return this.leaDictionary[lea]?.name || 'Unknown'
    },
    populateGrowthVsAchievementChart() {
      this.chartOptions.series = []
      this.chartOptions.series.push({
        name: 'Other Schools',
        color: '#007bff',
        marker: {
          fillcolor: '#007bff',
          symbol: 'square'
        },
        data: this.growthVsAchievement
          .filter(g => g.lea != this.selectedLea.number)
          .map(d => {
            return {
              lea: d.lea,
              schoolName: this.getSchoolName(d.lea),
              x: d.growth,
              y: d.achievement
            }
          })
      })
      this.chartOptions.series.push({
        name: 'This School',
        marker: {
          color: '#000000',
          symbol: 'diamond',
          fillColor: '#000000',
          radius: 12
        },
        data: this.growthVsAchievement
          .filter(g => g.lea == this.selectedLea.number)
          .map(d => {
            return {
              lea: d.lea,
              schoolName: this.getSchoolName(d.lea),
              x: d.growth,
              y: d.achievement
            }
          })
      })
    },
    dataItems() {
      return this.growthVsAchievement
        .map(d => {
          return {
            LEA: d.lea,
            SchoolName: this.getSchoolName(d.lea),
            ValueAddedGrowthScore: d.growth,
            WeightedAchievementScore: d.achievement
          }
        })
    },
    excelExportData() {
      return {
        name: 'LEA Growth Comparison',
        items: this.dataItems()
      }
    }
  },
  mounted() {
    this.populateGrowthVsAchievementChart()
  },
  watch: {
    growthVsAchievement() {
      this.populateGrowthVsAchievementChart()
    }
  }
}
</script>
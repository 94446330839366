<template>
  <div class="command-buttons text-right" style="width: 100%;">
    <b-button size="sm" v-if="options.showAdd && !operationInProgress" @click="mode='add'">
      Add
    </b-button>
    <b-button
      size="sm"
      v-if="options.showEdit && !operationInProgress"
      @click="onEdit"
      variant="primary"
    >
      <i class="fa fa-fw fa-pen" />
    </b-button>
    <b-button
      size="sm"
      v-if="options.showDelete && !operationInProgress"
      @click="onDelete"
      variant="danger"
    >
      <i class="fa fa-fw fa-times" />
    </b-button>
    <b-button size="sm" v-if="operationInProgress" @click="onSave">
      Save
    </b-button>
    <b-button size="sm" v-if="operationInProgress" @click="onCancel">
      Cancel
    </b-button>
  </div>
</template>

<style lang="scss">
  div.command-buttons > button {
    margin-right: 3px;
  }
</style>
<script>
export default {
  name: 'command-buttons',
  data() {
    return {
      mode: this.options.operation
    }
  },
  methods: {
    onEdit() {
      this.mode = 'edit'
      this.$emit('on-edit')
    },
    onSave() {
      this.mode = 'view'
      this.$emit('on-save')
    },
    onDelete() {
      this.mode = 'view'
      this.$emit('on-delete')
    },
    onCancel() {
      console.log('canceling')
      this.mode = 'view'
      this.$emit('on-cancel')
    }
  },
  computed: {
    operationInProgress() {
      return this.mode == 'add' || this.mode == 'edit'
    }
  },
  mounted() {
    this.mode = this.options.operation
  },
  props: {
    options: {
      type: Object,
      default() {
        return {
          showAdd: false,
          showEdit: true,
          showDelete: true,
          operation: 'view'
        }
      }
    }
  }
}
</script>
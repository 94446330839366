<template>
  <div>
    <paginated-table hover :items="filteredNarratives" :fields="fields" :sort-by="'createdDate'" :sort-desc="true">
      <template v-slot:cell(lea)="data">
        <div>
          {{ formatLea(data.value) }}
        </div>
      </template>
      <template v-slot:cell(range)="data">
        <div>
          {{ formatNarrativeRange(data.value, data.item.reportName) }}
        </div>
      </template>
      <template v-slot:cell(createdDate)="data">
        <div>
          {{ formatDate(data.value) }}
        </div>
      </template>
      <template v-slot:cell(action)="data">
        <div class="text-right">
          <div v-if="data.item.isReadOnly">
            <b-button size="sm" variant="info" @click="onViewItem(data.item)" class="mr-2" v-b-tooltip.hover title="View">
              <i class="far fa-eye fa-fw text-white" />
            </b-button>
          </div>
          <div v-else>
            <b-button size="sm" variant="primary" @click="onEditItem(data.item)" class="mr-2" v-b-tooltip.hover title="Edit">
              <i class="fa fa-edit fa-fw text-white" />
            </b-button>
            <b-button size="sm" variant="danger" @click="onRemoveItem(data.item)" v-b-tooltip.hover title="Delete">
              <i class="fa fa-trash fa-fw text-white" />
            </b-button>
          </div>
        </div>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import { Types } from '../../../../stores/careercoach/index.js'
import { ReportTypes } from '../../../common/ReportTypes.js'
import { mapState } from 'vuex'
import PaginatedTable from '@/components/PaginatedTable.vue'
import moment from 'moment'

export default {
  data() {
    return {
      fields: [
        {
          key: 'reportName',
          sortable: true,
          formatter: value => {
            return ReportTypes[value].name
          }
        },
        {
          key: 'lea',
          label: 'LEA',
          sortable: true
        },
        {
          key: 'range'
        },
        {
          key: 'createdDate',
          label: 'Created',
          sortable: true
        },
        {
          key: 'createdBy',
          label: 'Created By'
        },
        {
          key: 'action',
          thClass: 'text-right'
        }
      ]
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.narratives]),
    ...mapState('globalModule', ['leaContext']),
    filteredNarratives() {
      return (this.narratives || []).filter(n => this.filterUser == null || n.createdBy == this.filterUser)
    },
 },
  components: {
    PaginatedTable
  },
  props: {
    filterUser: {
      type: String,
      default: () => null
    }
  },
  methods: {
    formatLea(leaNumber) {
      var lea = this.leaContext.LeaDictionary[leaNumber]
      return `${lea.name} (${lea.number})`
    },
    formatNarrativeRange(range, reportName) {
      return ReportTypes[reportName].rangeFormatter(range)
    },
    formatDate(date) {
      return moment(date).format('MM/DD/YYYY h:mm a')
    },
    onViewItem(item) {
      this.$router.push({
        name: 'CTE/CareerCoach/Narratives/View',
        params: { narrativeId: item.narrativeId }
      })
    },
    onEditItem(item) {
      this.$router.push({
        name: 'CTE/CareerCoach/Narratives/Edit',
        params: { narrativeId: item.narrativeId }
      })
    },
    onRemoveItem(item) {
      var self = this
      this.$bvModal.msgBoxConfirm(`Are you sure you want to delete this narrative?`).then(result => {
        if (result) {
          self.$store.dispatch(Types.actions.deleteNarrative, item.narrativeId).then(() => {
            self.$emit('update-list')
          })
        }
      })
    }
  }
}
</script>

<style>
</style>
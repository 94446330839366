<template>
  <b-card header="ACT Average Scores">
    <!-- CTE Goal: Increase the state average ACT score -->
    <!-- Bar chart of average ACT score per subject are &amp; composite for LEA. This can be obtained from MSI/SRC for the most recently loaded ACT dataset -->
    <highcharts class="chart" ref="chartRef" :callback="chartLoad" :options="chartOptions" v-if="!showError" />
    <div v-else style="height: 280px;">
      <b-alert show variant="info">
        <i class="fa fa-exclamation-triangle mr-2" />Select a School, District, or the State of Arkansas to view this chart.
      </b-alert>
    </div>
  </b-card>
</template>

<script>
const loadingSpinner = '<i class="fas fa-spinner fa-spin"></i> Loading...'
const noData = '<span style="font-size: .8em">No ACT Data was found for this LEA.<br>This is not uncommon for Middle and/or Junior High Schools<br>If this is a <i>High School</i> under a new LEA, ACT data <i>may</i> be available under the prior (closed) LEA</span>.'

import { Types } from '../../../../stores/careercoach/index.js'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      loadingSpinner: loadingSpinner
    }
  },
  props: {
    showError: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.dashboardACT]),
    seriesData() {
      if (this.dashboardACT.length > 0) {
        return [
          this.dashboardACT[0].avgComposite,
          this.dashboardACT[0].avgEnglish,
          this.dashboardACT[0].avgMath,
          this.dashboardACT[0].avgReading,
          this.dashboardACT[0].avgScience
        ]
      }
      else return []
    },
    year() {
      var fy = ((this.dashboardACT[0] || {}).schoolYear)
      if (fy) return `(${fy + 1990})`
      else return ''
    },
    chartOptions() {
      return {
        chart: {
          type: 'column',
          height: 280,
          spacing: [20, 20, 20, 20],
          backgroundColor: '#fff',
          plotBackgroundColor: '#eee',
          inverted: true
        },
        loading: {
          style: {
            position: 'absolute',
            backgroundColor: '#ffffff',
            opacity: 0.8,
            textAlign: 'center'
          }
        },
        title: {
          text: `Average ACT scores ${this.year}`.trim()
        },
        exporting: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: ['Composite', 'English', 'Mathematics', 'Reading', 'Science']
        },
        yAxis: {
          min: 1,
          max: 36,
          title: {
            text: 'Score'
          }
        },
        plotOptions: {
          column: {
            minPointLength: 2
          }
        },
        series: [
          {
            name: 'Score',
            data: this.seriesData,
            colorByPoint: true,
            dataLabels: {
              enabled: true
            }
          }
        ]
      }
    }
  },
  watch: {
    dashboardACT: {
      immediate: true,
      handler() {
        this.chartLoad()  
      }
    }
  },
  methods: {
    chartLoad(chart) {
      chart = chart || this.$refs["chartRef"]?.chart

      if (chart) {
        chart.showLoading(loadingSpinner)

        if (this.dashboardACT.length == 0) {
          chart.showLoading(noData)
        } else {
          chart.hideLoading()
        }
      }
    }
  }
}
</script>
<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import LoadableChartCard from '../../LoadableChartCard'
import { ChartMixin } from '../../ChartMixin'

export default {
  data: function () {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'column'
        },
        plotOptions: {
          series: {
            minPointLength: 3
          },
          column: {
            dataLabels: {
              enabled: true
            }
          }
        },
        credits: 'AR Ready for Learning Survey',
        title: {
          text: this.question.text
        },
        series: [],
        xAxis: {
          labels: {
            enabled: true
          },
          categories: null
        },
        yAxis: {
          title: {
            text: "Districts"
          },
          endOnTick: true
        },
        legend: false
      }
    }

  },
  name: 'question-multiple-selection-chart',
  props: {
    question: {
      type: Object,
      default: () => {}
    },
    aggregatedData: {
      type: Array,
      default: () => []
    },
    loadData: Promise
  },
  components: {
    LoadableChartCard
  },
  methods: {
   dataItems() {
      return this.aggregatedData[0].details.map (d=>{ return {coopLea: d.coop.number, coopName: d.coop.name, surveyResult: d.surveyResponse}})
    }
  },
  computed: {
  },
  mixins: [ChartMixin],
  watch: {
    aggregatedData: {
      immediate: true,
      handler(newData) {
        delete newData[0].choices['null\u200B']

        this.chartOptions.xAxis.categories = Object.keys(newData[0].choices).map(c => c.replace('\u200B', ''))

        this.chartOptions.series = [
          {
            name: this.question.text,
            data: Object.keys(newData[0].choices).map(k => newData[0].choices[k]),
            colorByPoint: true
          }
        ]
      }
    }
  }
}
</script>

<style>

</style>
<template>
  <div>
    <b-tabs card content-class="mt-3" v-if="selectedApplication && selectedApplication.applicationId">
      <b-tab title="Cover Page" active>
        <p>
          <workflow-status-badge :state-map="CoverPageStateMap" :status="coverPage.status" :show-label="true" />
        </p>
        <div class="m-3" v-if="coverPage.status > CoverPageStates.Draft || canSubmit">
          <state-change-widget 
            :status="coverPage.status"
            :available-states="coverPageAvailableStates"
            :state-map="CoverPageStateMap"
            :transition-action="transitionAction"
            advance-button-class="text-left"
            :identifiers="{ applicationId: $route.params.applicationId }"
            :can-withdraw="canWithdraw" />
        </div>
        <b-card :title="`Annual Local Application for AY ${selectedApplication.ay-1}/${selectedApplication.ay} (July 1, ${selectedApplication.ay+1999} - June 30, ${selectedApplication.ay +2000})`" sub-title="Application for Federal Funds Under Title I of the Carl D. Perkins Career and Technical Education Act for 2006 (CFDA #84.048)" header="Cover Page Information" class="mb-2">
          <b-card-text>
            <b-form>
              <b-form-row>
                <b-col>(July 1, {{ selectedApplication.ay+1999 }} - June 30, {{ selectedApplication.ay +2000 }})</b-col>
              </b-form-row>
              <b-form-group label-cols="1" content-cols="5" label="FAIN" label-class="font-weight-bold" class="mb-0">
                <legend class="col-form-label">
                  {{ `V048A${selectedApplication.ay - 1 }004` }}
                </legend>
              </b-form-group>
              <b-form-row class="mb-0">
                <b-col>
                  <b-form-group label-cols="7" content-cols="4" :label="`Amount awarded to ${leaDictionary[selectedApplication.applicantId].name}`" label-class="font-weight-bold" class="mb-0">
                    <legend class="col-form-label">
                      {{ applicantFundingLimit.fundingLimit | currency }}
                    </legend>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label-cols="4" content-cols="6" label="Total federal award to Arkansas" label-class="font-weight-bold" class="mb-0">
                    <legend class="col-form-label">
                      {{ fundingTotal | currency }}
                    </legend>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-form>
          </b-card-text>
        </b-card>
        <b-card header="General Information" class="mb-2">
          <b-card-text>
            <b-form>
              <b-form-row>
                <b-col>
                  <b-form-group label-cols="4" content-cols="6" label="Applicant:" label-class="font-weight-bold" class="mb-0">
                    <legend class="col-form-label">
                      {{ leaDictionary[selectedApplication.applicantId].name }}
                    </legend>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label-cols="4" content-cols="6" label="Applicant ID:" label-class="font-weight-bold" class="mb-0">
                    <legend class="col-form-label">
                      {{ selectedApplication.applicantId }}
                    </legend>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row v-if="selectedApplicationCoordinator">
                <b-col>
                  <b-form-group label-cols="2" content-cols="10" label="Perkins Coordinator:" label-class="font-weight-bold" class="mb-0">
                    <legend class="col-form-label">
                      {{ selectedApplicationCoordinator.fullName }}
                    </legend>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row v-if="selectedApplicationCoordinator">
                <b-col>
                  <b-form-group label-cols="4" content-cols="6" label="Coordinator Phone:" label-class="font-weight-bold" class="mb-0">
                    <legend class="col-form-label">
                      {{ selectedApplicationCoordinator.phone }}
                    </legend>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label-cols="4" content-cols="6" label="Coordinator Email:" label-class="font-weight-bold" class="mb-0">
                    <legend class="col-form-label">
                      <a :href="`mailto:${selectedApplicationCoordinator.emailAddress}`"> {{ selectedApplicationCoordinator.emailAddress }}</a>
                    </legend>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-form>
          </b-card-text>
        </b-card>
        <b-card header="Programs of Study" class="mb-2" v-if="selectedApplication.ay > 23">
          <b-form>
            <table class="table table-striped table-responsive">
              <thead class="d-print-none">
                <tr style="min-height: 12em;">
                  <td class="col-md-2">
                    <b-form-group label="Cluster">
                      <b-form-select :disabled="readonly" :options="clusters" v-model="clusterId" />
                    </b-form-group>
                  </td>
                  <td class="col-md-2">
                    <b-form-group label="Pathway">
                      <b-form-select :disabled="readonly" :options="pathways" v-model="pathwayId" />
                    </b-form-group>
                  </td>
                  <td class="col-md-3">
                    <b-form-group label="Program of Study">
                      <b-form-select :disabled="readonly" :options="programsOfStudy" v-model="programOfStudyId" />
                    </b-form-group>
                  </td>
                  <td class="col-md-3">
                    <b-form-group label="LEA(s)">
                      <v-select multiple v-model="leaIds" :reduce="lea => lea.code" :options="districtOptions" placeholder="Select one or more LEAs" />
                    </b-form-group> 
                  </td>
                  <td class="col-md-1 nowrap" style="padding-top: 43px;">
                    <button type="button" class="btn btn-sm btn-success" @click="addRow()" :disabled="clusterId == null || pathwayId == null || programOfStudyId == null">
                      <i class="fas fa-fw fa-plus" /> Add
                    </button>
                  </td>
                </tr>
                <tr />
              </thead>
              <tbody>
                <tr v-for="coverPageData in selectedApplication.coverPage.applicationCoverPageClusters" :key="coverPageData.id">
                  <td class="col-md-2">
                    {{ getClusterDesc(coverPageData.clusterId) }}
                  </td>
                  <td class="col-md-2">
                    {{ getPathwayDesc(coverPageData.pathwayId) }}
                  </td>
                  <td class="col-md-3">
                    {{ getProgramOfStudyDesc(coverPageData.programOfStudyId) }}
                  </td>
                  <td class="col-md-3">
                    <li v-for="item in coverPageData.leAs" :key="item" style="list-style: none">
                      <span class="badge badge-secondary">{{ getLeasDesc(item) }}</span>
                    </li>
                  </td>
                  <td class="col-md-2 d-print-none">
                    <button type="button" class="btn btn-sm btn-danger" v-if="!coverPageData.inUseOnAProposal" @click="removeRow(coverPageData.id)">
                      <i class="far fa-fw fa-times-circle" /> Remove
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-form>
        </b-card>
        <b-card header="Local Plan Changes" class="mb-2">
          <b-card-text>
            <b-form>
              <b-form-group label="Please describe any changes needed for the recipient's five-year local plan.">
                <b-form-textarea v-model="localPlanChanges" :disabled="!canEdit" />
                <span class="text-danger" v-if="$v.localPlanChanges.$invalid">*Local Plan Changes required to submit</span>
              </b-form-group>
            </b-form>
          </b-card-text>
        </b-card>
        <b-card header="Assurance of Compliance">
          <b-card-text>
            <b-form>
              <b-form-row>
                <b-col>                  
                  <b-form-checkbox v-model="assuranceOfComplianceAccepted" :disabled="$v.localPlanChanges.$invalid || !canEdit">
                    Please check here indicating that you agree.
                  </b-form-checkbox>
                  <span class="text-danger" v-if="!assuranceOfComplianceAccepted">*Assurance of Compliance is required to save</span>
                </b-col>
                <b-col v-if="canEdit">
                  <animated-button class="btn btn-primary" :promise-resolver="saveCoverPage" :disabled="$v.localPlanChanges.$invalid || !assuranceOfComplianceAccepted || !canEdit">
                    Save
                  </animated-button>
                </b-col>
              </b-form-row>
            </b-form>
          </b-card-text>
        </b-card>
        <b-card header="Comments / Questions">
          <comment-question-widget :identifiers="{ applicationId: $route.params.applicationId }" :comments="selectedComments" />
        </b-card>
      </b-tab>
      <b-tab title="Perkins Indicators">
        <perkins-indicators-widget v-model="indicatorsLocal" :is-readonly="true" :show-focus="false" />
      </b-tab>
      <b-tab title="Cover Page History">
        <change-log-history-table :change-logs="coverPage.changeLogs || []" :state-map="CoverPageStateMap" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Vue from 'vue'
import store from '@/stores'
import { mapGetters, mapState } from 'vuex'
import { Types } from '../../../stores/perkinsv'
import { CoverPageStateMap, CoverPageStates } from '../../../stores/perkinsvStateMap'
import sumBy from 'lodash.sumby'
import Vuelidate from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import PerkinsIndicatorsWidget from '../common/PerkinsIndicatorsWidget.vue'
import StateChangeWidget from '@/components/workflow/StateChangeWidget.vue'
import WorkflowStatusBadge from '@/components/workflow/WorkflowStatusBadge.vue'
import ChangeLogHistoryTable from '@/components/workflow/ChangeLogHistoryTable.vue'
import AnimatedButton from '@/components/AnimatedButton.vue'
import CommentQuestionWidget from './proposal/workflow/CommentQuestionWidget.vue'
import { Types as CommonTypes } from '@/modules/cte/stores'
import { LeaTypes } from '@/helpers/leaTypes'

Vue.use(Vuelidate)

export default {
  data() {
    return {
      localPlanChanges: null,
      assuranceOfComplianceAccepted: null,
      CoverPageStateMap,
      CoverPageStates,
      indicatorsLocal: [],
      clusterId: null,
      pathwayId: null,
      programOfStudyId: null,
      leaIds: []
    }
  },
  computed:{
    ...mapGetters(CommonTypes.path, [CommonTypes.getters.occupationalAreaDrilldownForAY]),
    ...mapState('globalModule', ['userContext']),
    ...mapState(Types.path, [
      Types.state.selectedApplication, 
      Types.state.applicantFundingLimit, 
      Types.state.applicantFundingLimits, 
      Types.state.selectedApplicationCoordinator,
      Types.state.coverPageAvailableStates,
      Types.state.applicantPerkinsIndicatorPerformances,
      Types.state.selectedComments, 
      Types.state.consortiumLeas,
      Types.state.applications,
    ]),
    clusters() { return [{value: null, text: '(All Clusters)'}, ...(this.occupationalAreaDrilldownForAY(this.selectedApplication.ay).flatMap(i => i.children)).map(ch => {return { value: ch.id, text: ch.name}})]},
    cluster() { return this.occupationalAreaDrilldownForAY(this.selectedApplication.ay).flatMap(i => i.children).find(ch => ch.id == this.clusterId) },
    pathways() { return [{value: null, text: '(All Pathways)'}, ...(this.cluster?.children || []).map(ch => { return { value: ch.id, text: ch.name}})]},
    pathway() { return this.cluster ? this.cluster.children.find(ch => ch.id == this.pathwayId) : null},
    programsOfStudy() { return  [{value: null, text: '(All Programs of Study)'}, ...(this.pathway?.children || []).map(ch => {return {value: ch.id, text: ch.name}})]},
    programOfStudy() { return this.pathway ? this.pathway.children.find(ch => ch.id == this.programOfStudyId) : null },

    districtOptions() {
      const consortium = this.leaDictionary[this.selectedApplication.applicantId]
      const leaConsortia = this.consortiumLeas.map(c => c.lea).concat(this.applications.map(s => s.applicantId))
      const districts = this.leaDictionary[this.selectedApplication.applicantId].ownedLeas.filter(d => {
        return !leaConsortia.includes(d.number)
      })
      
      if (consortium.type == LeaTypes.Coop) {
        return districts
          .flatMap(d => d.ownedLeas)
          
          .map(school => {
            return { code: school.number, label: `${school.number} - ${school.name}` }
          }).concat(districts.map(district  => {
              return { code: district.number, label: `${district.number} - ${district.name}` }
            }))
      }  
      
      else{
        return districts
          .map(school => {
            return { code: school.number, label: `${school.number} - ${school.name}` }
          }).concat(districts.map(district  => {
            return { code: district.number, label: `${district.number} - ${district.name}` }
          }))
      }
    },
    fundingTotal() {
      return sumBy(this.applicantFundingLimits, (l) => {
        return l.fundingLimit || 0
      })
    },
    coverPage() {
      return (this.selectedApplication.coverPage || {})
    },
    canEdit() {
      return [CoverPageStates.Draft, CoverPageStates.ReturnedToApplicant, CoverPageStates.Revising].includes(this.coverPage.status)
       && (this.coverPageAvailableStates.advanceStates || []).length == 1
    },
    transitionAction: () => Types.actions.transitionCoverPageToState,
    isDraftWithAdvanceStates() {
      return (this.coverPageAvailableStates.advanceStates || []).length == 1 && 
        this.coverPage.status == CoverPageStates.Draft
    },
    canSubmit() {
      return this.coverPage.localPlanChanges && 
        this.coverPage.assuranceOfComplianceAccepted &&
        this.isDraftWithAdvanceStates
    },
    isApplicant() {
      const applicantId = (this.selectedApplication || {}).applicantId
      return (this.userContext.CoordinatorRoles || []).some(c => c.SchoolLEA == applicantId)
    },
    canWithdraw() {
      return this.isApplicant && (this.coverPageAvailableStates.returnStates || []).length == 1
    },
  },
  props: {
    value: Object,
    readonly: Boolean
  },
  validations:{
    localPlanChanges:{
      required
    },
    assuranceOfComplianceAccepted:{
      required
    }
  },
  components: {
    PerkinsIndicatorsWidget,
    StateChangeWidget,
    AnimatedButton,
    WorkflowStatusBadge,
    ChangeLogHistoryTable,
    CommentQuestionWidget,
  },
  watch: {
    value: {
      deep: true,
      handler(newData) {
        this.$emit('input', newData)
      } 
    },
    applicantPerkinsIndicatorPerformances: {
      immediate: true,
      handler(perkinsIndicators) {
        if (perkinsIndicators) {
          this.indicatorsLocal =  [...perkinsIndicators]
        }
      },
    }
  },
  methods:{
    saveCoverPage() {
      return this.$store.dispatch(Types.actions.saveCoverPage, { applicationId: this.selectedApplication.applicationId, coverPage: { localPlanChanges: this.localPlanChanges, assuranceOfComplianceAccepted: this.assuranceOfComplianceAccepted }})
    },
    getClusterDesc(id) {
      var clustersArr = this.occupationalAreaDrilldownForAY(this.selectedApplication.ay).flatMap(i => i.children)
      return clustersArr.find(i => i.id == id)?.name;
    },
    getPathwayDesc(id) {
      var pathwaysArr = this.occupationalAreaDrilldownForAY(this.selectedApplication.ay).flatMap(i => i.children).flatMap(i => i.children)
      return pathwaysArr.find(i => i.id == id)?.name;
    },
    getProgramOfStudyDesc(id) {
      var programsOfStudyArr = this.occupationalAreaDrilldownForAY(this.selectedApplication.ay).flatMap(i => i.children).flatMap(i => i.children).flatMap(i => i.children)
      return programsOfStudyArr.find(i => i.id == id)?.name;
    },
    getLeasDesc(lea) {
      if (lea) {
        var desc = (this.districtOptions.find(i => i.code == lea)?.label) || lea
        return desc
      }
      return lea
    },
    addRow(){
      this.$store.dispatch(CommonTypes.actions.saveProgramAreaToCoverPage, {
        clusterId: this.clusterId,
        pathwayId: this.pathwayId,
        programOfStudyId: this.programOfStudyId,
        applicationId: this.selectedApplication.applicationId,
        leas: this.leaIds
      })
      .then(() => {
        this.$store.dispatch(Types.actions.getApplication, { applicationId : this.selectedApplication.applicationId })
        this.programOfStudyId = null;
        this.leaIds = null;
      })
      
    },
    removeRow(id){
      this.$store.dispatch(CommonTypes.actions.removeProgramAreaCoverPage, { applicationId: this.selectedApplication.applicationId, clusterId: id })
      .then(() => {
        this.$store.dispatch(Types.actions.getApplication, { applicationId : this.selectedApplication.applicationId })
      })
    }
  },
  
  mounted() {
    this.localPlanChanges = this.selectedApplication.coverPage.localPlanChanges
    this.assuranceOfComplianceAccepted = this.selectedApplication.coverPage.assuranceOfComplianceAccepted
  },
  beforeRouteEnter: (to, from, next) => {
    if (!store.getters[Types.getters.activeAcademicYear]) {
      store.dispatch(Types.actions.getAcademicYears)
    }
    store.dispatch(Types.actions.getComments, { applicationId: to.params.applicationId })
    store.dispatch(Types.actions.getApplication, { applicationId: to.params.applicationId })
      .then(() => {
        next(vm => {
          vm.$store.dispatch(Types.actions.getApplicantFundingLimits, { ay: vm.$store.getters[`${Types.path}/${Types.getters.activeAcademicYear}`] })
          vm.$store.dispatch(CommonTypes.actions.getOAMetadata, { ay: vm.$store.state.cte.perkinsv.selectedApplication.ay })
        })
      })
  }
}
</script>

<template>
  <div class="bg-print">
    <Promised :promise="loadData">
      <template v-slot:pending>
        <loading-spinner />
      </template>
      <template v-slot>
        <div class="row">
          <div class="col-12"> 
            <b-tabs card content-class="mx-0 bg-light border" nav-class="purple">
              <b-tab no-body title="Summary" active>
                <div class="card card-body">
                  <div>
                    <table class="table my-2">
                      <thead>
                        <tr class="display-print-only">
                          <th colspan="6">
                            <div class="text-center">
                              <h2>Budget Summary</h2>
                              <h4><lea-display :lea="selectedLea" /></h4>             
                            </div>
                          </th>
                        </tr>
                        <tr>
                          <th>
                            <span />
                          </th>
                          <th class="bg-green text-center">
                            {{ 'FY' + (currentFY() - 3) }}
                          </th>
                          <th class="bg-lightgreen text-center">
                            {{ 'FY' + (currentFY() - 2) }}
                          </th>
                          <th class="bg-orange text-center">
                            {{ 'FY' + (currentFY() - 1) }}
                          </th>
                          <th class="bg-blue text-center">
                            {{ 'FY' + (currentFY()) }} Budget
                          </th>
                          <th class="bg-blue text-center">
                            {{ 'FY' + (currentFY()) }} YTD
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="bg-purple font-weight-bold">
                            Beginning Balance
                          </td>
                          <td class="text-right">
                            {{ budgetSummary(currentFY() - 3).beginningBalance | currency }}
                          </td>
                          <td class="text-right">
                            {{ budgetSummary(currentFY() - 2).beginningBalance | currency }}
                          </td>
                          <td class="text-right">
                            {{ budgetSummary(currentFY() - 1).beginningBalance | currency }}
                          </td>
                          <td class="text-right">
                            {{ budgetSummary(currentFY()).beginningBalance | currency }}
                          </td>
                          <td class="text-right">
                            {{ budgetSummary(currentFY()).beginningBalance | currency }}
                          </td>
                        </tr>
                        <tr>
                          <td class="bg-purple font-weight-bold">
                            Revenue
                          </td>
                          <td class="text-right">
                            {{ budgetSummary(currentFY() - 3).revenue | currency }}
                          </td>
                          <td class="text-right">
                            {{ budgetSummary(currentFY() - 2).revenue | currency }}
                          </td>
                          <td class="text-right">
                            {{ budgetSummary(currentFY() - 1).revenue | currency }}
                          </td>
                          <td class="text-right">
                            {{ totalCurrentRevenueBudget(revenue) | currency }}
                          </td>
                          <td class="text-right">
                            {{ budgetSummary(currentFY()).revenue | currency }}
                          </td>
                        </tr>
                        <tr>
                          <td class="bg-purple font-weight-bold">
                            Expenditures
                          </td>
                          <td class="text-right">
                            {{ budgetSummary(currentFY() - 3).expenditures | currency }}
                          </td>
                          <td class="text-right">
                            {{ budgetSummary(currentFY() - 2).expenditures | currency }}
                          </td>
                          <td class="text-right">
                            {{ budgetSummary(currentFY() - 1).expenditures | currency }}
                          </td>
                          <td class="text-right">
                            {{ totalCurrentBudget(expenditure) | currency }}
                          </td>
                          <td class="text-right">
                            {{ budgetSummary(currentFY()).expenditures | currency }}
                          </td>
                        </tr>
                        <tr>
                          <td class="bg-purple font-weight-bold">
                            Ending Balance
                          </td>
                          <td class="text-right">
                            {{ (budgetSummary(currentFY() - 3).beginningBalance + budgetSummary(currentFY() - 3).revenue) - budgetSummary(currentFY() - 3).expenditures | currency }}
                          </td>
                          <td class="text-right">
                            {{ (budgetSummary(currentFY() - 2).beginningBalance + budgetSummary(currentFY() - 2).revenue) - budgetSummary(currentFY() - 2).expenditures | currency }}
                          </td>
                          <td class="text-right">
                            {{ (budgetSummary(currentFY() - 1).beginningBalance + budgetSummary(currentFY() - 1).revenue) - budgetSummary(currentFY() - 1).expenditures | currency }}
                          </td>
                          <td class="text-right">
                            {{ (budgetSummary(currentFY()).beginningBalance + totalCurrentRevenueBudget(revenue)) - totalCurrentBudget(expenditure) | currency }}
                          </td>
                          <td class="text-right">
                            {{ (budgetSummary(currentFY()).beginningBalance + budgetSummary(currentFY()).revenue) - budgetSummary(currentFY()).expenditures | currency }}
                          </td>
                        </tr>
                        <tr>
                          <td class="bg-purple font-weight-bold">
                            Ending Balance % of Revenue
                          </td>
                          <td class="text-right" v-if="!isNaN(((budgetSummary(currentFY() - 3).beginningBalance + budgetSummary(currentFY() - 3).revenue) - budgetSummary(currentFY() - 3).expenditures)/ budgetSummary(currentFY() - 3).revenue)">
                            {{ ((budgetSummary(currentFY() - 3).beginningBalance + budgetSummary(currentFY() - 3).revenue) - budgetSummary(currentFY() - 3).expenditures)/ budgetSummary(currentFY() - 3).revenue | toPercentage(2, '%') }}
                          </td>
                          <td v-else class="text-right">
                            0.0%
                          </td>
                          <td class="text-right">
                            {{ ((budgetSummary(currentFY() - 2).beginningBalance + budgetSummary(currentFY() - 2).revenue) - budgetSummary(currentFY() - 2).expenditures)/ budgetSummary(currentFY() - 2).revenue | toPercentage(2, '%') }}
                          </td>
                          <td class="text-right">
                            {{ ((budgetSummary(currentFY() - 1).beginningBalance + budgetSummary(currentFY() - 1).revenue) - budgetSummary(currentFY() - 1).expenditures)/ budgetSummary(currentFY() - 1).revenue | toPercentage(2, '%') }}
                          </td>
                          <td class="text-right">
                            {{ ((budgetSummary(currentFY()).beginningBalance + totalCurrentRevenueBudget(revenue)) - totalCurrentBudget(expenditure))/totalCurrentRevenueBudget(revenue) | toPercentage(2, '%') }}
                          </td>
                          <td class="text-right">
                            {{ ((budgetSummary(currentFY()).beginningBalance + budgetSummary(currentFY()).revenue) - budgetSummary(currentFY()).expenditures)/budgetSummary(currentFY()).revenue | toPercentage(2, '%') }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="mt-4">
                      <h4>Unrestricted Funds:</h4>
                      <p>
                        Fund/SOF 1000-1200|1204|1206:1211|1213:1222|1224:1274|1277:1280|1282:1292|1294:1319|1321:1322|1324:1390|1392:1400|1405:1999
                      </p>
                      <p>
                        Fund/SOF 2000-2200|2204|2206:2211|2213:2222|2224:2274|2277:2280|2282:2292|2294:2319|2321:2322|2324:2390|2392:2400|2405:2999
                      </p>
                      <p>
                        Fund/SOF 4000:4999            
                      </p>

                      <h4>Revenue:</h4>
                      <p>
                        Object Detail for Account                  
                        10000:51999|52300-52399|52500:59999      
                      </p>
                      <h4>Expenditure:</h4>
                      <p>
                        Object Detail for Account
                        61000:69299|69330:69339|69350:69999
                      </p>
                    </div>
                  <!--  <table class="table my-2" v-for="summary in budgetSum" :key="summary.fiscalYear">
                      <thead>
                        <th colspan="4" class="bg-secondary text-light">
                          <h4 class="text-center">
                            <div v-if="summary.fiscalYear - 10 == currentFY()">
                              Fiscal Year {{ summary.fiscalYear - 10 }} as of {{ yesterday() }}
                            </div>
                            <div v-else>
                              Fiscal Year {{ summary.fiscalYear - 10 }}
                            </div>
                          </h4>
                        </th>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="border-bottom font-weight-bold">
                            Beginning Balance
                          </td>
                          <td class="border-bottom font-weight-bold text-center">
                            Revenue
                          </td>
                          <td class="border-bottom font-weight-bold text-center">
                            Expenditures
                          </td>
                          <td class="border-bottom text-right font-weight-bold">
                            Ending Balance
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-bold">
                            07/01/20{{ summary.fiscalYear - 11 }}
                          </td>
                          <td colspan="2">
                            &nbsp;
                          </td>
                          <td class="font-weight-bold text-right">
                            <p v-if="summary.fiscalYear - 10 == currentFY()">
                              {{ yesterday() }}
                            </p>
                            <p v-else>
                              06/30/20{{ summary.fiscalYear - 10 }}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {{ formatCurrency(summary.beginningBalance) }}
                          </td>
                          <td class="text-center">
                            {{ formatCurrency(summary.revenue) }}
                          </td>
                          <td class="text-center">
                            {{ formatCurrency(summary.expenditures) }}
                          </td>
                          <td class="text-right">
                            {{ formatCurrency(summary.endingBalance) }}
                          </td>
                        </tr>
                      </tbody>
                    </table> -->
                  </div>
                </div>
              </b-tab>
              <b-tab no-body title="Revenue" class="page-break-before">
                <div class="card card-body border-0">
                  <paginated-table hover selectable select-mode="single" :show-filter="true" :hide-paging="true" @pt:row-selected="onSelected" :items="revenue" :fields="tab2fields">
                    <template v-slot:thead-top>
                      <b-tr class="display-print-only">
                        <b-th colspan="8">
                          <div class="text-center">
                            <h2>Revenue</h2>
                            <h4><lea-display :lea="selectedLea" /></h4>             
                          </div>
                        </b-th>
                      </b-tr>
                    </template>
                    <template v-slot:head(fY_1_Revenue)>
                      {{ 'FY' + (currentFY() - 3) }}
                    </template>
                    <template v-slot:head(fY_2_Revenue)>
                      {{ 'FY' + (currentFY() - 2) }}
                    </template>
                    <template v-slot:head(fY_3_Revenue)>
                      {{ 'FY' + (currentFY() - 1) }}
                    </template>
                    <template v-slot:head(current_Revenue)>
                      {{ 'FY' + currentFY() + ' YTD' }} as of {{ yesterday() }}
                    </template>
                    <template v-slot:head(current_Budget)>
                      {{ 'FY' + currentFY() + '  Budget' }}
                    </template>
                    <template v-slot:custom-foot>
                      <b-tr class="bg-blue">
                        <b-th colspan="2">
                          <div>
                            <strong>Tax Collections Totals</strong>
                            <custom-tooltip content="Accounts: 10000-12999" :icon-class="'text-info fa fa-question-circle fa-fw mx-1'" />
                          </div>
                        </b-th>
                        <b-th class="text-right">
                          {{ totalRevenue_FY1(taxCollections) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          {{ totalRevenue_FY2(taxCollections) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          {{ totalRevenue_FY3(taxCollections) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          {{ totalCurrentRevenue(taxCollections) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          {{ totalCurrentRevenueBudget(taxCollections) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          <span :class="(totalCurrentRevenue(taxCollections) < totalCurrentRevenueBudget(taxCollections) ? 'text-danger' : 'text-dark')">{{ totalRevenueVariance(taxCollections) | currency }}</span>
                        </b-th>
                      </b-tr>
                      <b-tr>
                        <b-th colspan="2">
                          <strong>Totals</strong>
                        </b-th>
                        <b-th class="text-right">
                          {{ totalRevenue_FY1(revenue) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          {{ totalRevenue_FY2(revenue) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          {{ totalRevenue_FY3(revenue) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          {{ totalCurrentRevenue(revenue) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          {{ totalCurrentRevenueBudget(revenue) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          <span :class="(totalCurrentRevenue(revenue) < totalCurrentRevenueBudget(revenue) ? 'text-danger' : 'text-black')">{{ totalRevenueVariance(revenue) | currency }}</span>
                        </b-th>
                      </b-tr>
                    </template>
                  </paginated-table>
                </div>
              </b-tab>
              <b-tab no-body title="Expenditures" class="page-break-before">
                <div class="card card-body border-0">
                  <!-- <div class="d-print-none py-1">
                    <b-form inline>
                      <b-form-group label="Category">
                        <b-form-select v-model="selectedCategory" :options="expenditureCategories" />
                      </b-form-group>
                      <b-form-group label="Account" class="mx-2">
                        <b-form-select v-model="selectedExpenditureAccount" :options="expenditureAccountDescriptionOptions" />
                      </b-form-group>
                    </b-form>
                  </div> -->
                  <paginated-table hover selectable select-mode="single" @pt:row-selected="onSelected" :items="expenditure" :fields="tab3fields" :hide-paging="true">
                    <template v-slot:thead-top>
                      <b-tr class="display-print-only">
                        <b-th colspan="9">
                          <div class="text-center">
                            <h2>Expenditures</h2>
                            <h4><lea-display :lea="selectedLea" /></h4>             
                          </div>
                        </b-th>
                      </b-tr>
                    </template>
                    <template v-slot:head(fY_1_Expenditures)>
                      {{ 'FY' + (currentFY() - 3) }}
                    </template>
                    <template v-slot:head(fY_2_Expenditures)>
                      {{ 'FY' + (currentFY() - 2) }}
                    </template>
                    <template v-slot:head(fY_3_Expenditures)>
                      {{ 'FY' + (currentFY() - 1) }}
                    </template>
                    <template v-slot:head(current_Expenditures)>
                      {{ 'FY' + currentFY() + ' YTD' }} as of {{ yesterday() }}
                    </template>
                    <template v-slot:head(current_Budget)>
                      {{ 'FY' + currentFY() + '  Budget' }}
                    </template>
                    <template v-slot:head(variance)>
                      <span class="text-success">Under Budget</span> / <span class="text-danger">Over Budget</span>
                    </template>
                    <template v-slot:cell(variance)="data">
                      {{ absolute(data.value) | currency }}
                    </template>
                    <template v-slot:custom-foot>
                      <b-tr class="bg-blue">
                        <b-th colspan="3">
                          <strong>Salaries & Benefits Totals:</strong>
                        </b-th>
                        <b-th class="text-right">
                          {{ totalExpenture_FY1(salariesAndBenefits) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          {{ totalExpenture_FY2(salariesAndBenefits) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          {{ totalExpenture_FY3(salariesAndBenefits) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          {{ totalCurrentExpenture(salariesAndBenefits) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          {{ totalCurrentBudget(salariesAndBenefits) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          <div>
                            <span v-if="totalCurrentVariance(salariesAndBenefits) == 0">{{ totalCurrentVariance(salariesAndBenefits) | currency }}</span>
                            <span v-else :class="(totalCurrentVariance(salariesAndBenefits) < 0 ? 'text-danger' : 'text-success')">{{ totalCurrentVariance(salariesAndBenefits) | currency }}</span>
                          </div>
                        </b-th>
                      </b-tr>
                      <b-tr class="bg-gray">
                        <b-th colspan="3">
                          <strong>Other Expenditure Totals:</strong>
                        </b-th>
                        <b-th class="text-right">
                          {{ totalExpenture_FY1(otherExpenditures) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          {{ totalExpenture_FY2(otherExpenditures) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          {{ totalExpenture_FY3(otherExpenditures) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          {{ totalCurrentExpenture(otherExpenditures) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          {{ totalCurrentBudget(otherExpenditures) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          <div>
                            <span v-if="totalCurrentVariance(otherExpenditures) == 0">{{ totalCurrentVariance(otherExpenditures) | currency }}</span>
                            <span v-else :class="(totalCurrentVariance(otherExpenditures) < 0 ? 'text-danger' : 'text-success')">{{ totalCurrentVariance(otherExpenditures) | currency }}</span>
                          </div>
                        </b-th>
                      </b-tr>
                      <b-tr>
                        <b-th colspan="3">
                          <strong>Totals:</strong>
                        </b-th>
                        <b-th class="text-right">
                          {{ totalExpenture_FY1(expenditure) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          {{ totalExpenture_FY2(expenditure) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          {{ totalExpenture_FY3(expenditure) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          {{ totalCurrentExpenture(expenditure) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          {{ totalCurrentBudget(expenditure) | currency }}
                        </b-th>
                        <b-th class="text-right">
                          <div>
                            <span v-if="totalCurrentVariance(expenditure) == 0">{{ totalCurrentVariance(expenditure) | currency }}</span>
                            <span v-else :class="(totalCurrentVariance(expenditure) < 0 ? 'text-danger' : 'text-success')">{{ totalCurrentVariance(expenditure) | currency }}</span>
                          </div>
                        </b-th>
                      </b-tr>
                    </template>
                  </paginated-table>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </template>
    </Promised>
  </div>
</template>

<script>
import { Promised } from 'vue-promised'
import { formatCurrency, toPercentage } from '@/helpers/formatters'
import { Types } from '@/modules/purple/stores'
import { mapState } from 'vuex'
import PaginatedTable from '@/components/PaginatedTable.vue'
import sumBy from 'lodash.sumby'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import LeaDisplay from '@/components/LeaDisplay'
import CustomTooltip from '@/components/CustomTooltip'

function getTextClass(value) {
  if (value == 0) {
    return 'text-right'
  } else if (value < 0) {
    return 'text-danger text-right'
  }
  return 'text-success text-right'
}

// function getVarianceClass(value, key, item) {
//   if (item.current_Budget == item.current_Revenue) {
//     return 'text-right'
//   } else if (item.current_Revenue < item.current_Budget) {
//     return 'text-danger text-right'
//   }
//   return 'text-success text-right'
// }

export default {
  data() {
    return {
      loadData: null,
      tab2fields: [
        { key: 'account', sortable: true, thClass: 'bg-purple' },
        { key: 'description', sortable: true, thClass: 'bg-purple' },
        { key: 'fY_1_Revenue', formatter: formatCurrency, sortable: true, thClass: 'bg-green text-center', tdClass: 'text-right' },
        { key: 'fY_2_Revenue', formatter: formatCurrency, sortable: true, thClass: 'bg-lightgreen text-center', tdClass: 'text-right' },
        { key: 'fY_3_Revenue', formatter: formatCurrency, sortable: true, thClass: 'bg-orange text-center', tdClass: 'text-right' },
        { key: 'current_Revenue', formatter: formatCurrency, sortable: true, thClass: 'bg-blue text-center', tdClass: 'text-right' },
        { key: 'current_Budget', formatter: formatCurrency, sortable: true, thClass: 'bg-blue text-center', tdClass: 'text-right' },
        { key: 'variance', formatter: formatCurrency, sortable: true, tdClass: value => (value < 0 ? 'text-danger text-right' : 'text-dark text-right'), thClass: 'bg-blue text-center' }
      ],
      tab3fields: [  
        { key: 'category', sortable: true, thClass: 'bg-purple' },
        { key: 'account', sortable: true, thClass: 'bg-purple' },
        { key: 'description', sortable: true, thClass: 'bg-purple' },
        { key: 'fY_1_Expenditures', formatter: formatCurrency, sortable: true, thClass: 'bg-green text-center', tdClass: 'text-right' },
        { key: 'fY_2_Expenditures', formatter: formatCurrency, sortable: true, thClass: 'bg-lightgreen text-center', tdClass: 'text-right' },
        { key: 'fY_3_Expenditures', formatter: formatCurrency, sortable: true, thClass: 'bg-orange text-center', tdClass: 'text-right' },
        { key: 'current_Expenditures', formatter: formatCurrency, sortable: true, thClass: 'bg-blue text-center', tdClass: 'text-right' },
        { key: 'current_Budget', formatter: formatCurrency, sortable: true, thClass: 'bg-blue text-center', tdClass: 'text-right' },
        { key: 'variance', sortable: true, tdClass: value => getTextClass(value), thClass: 'bg-blue text-center' }
      ],
      selectedRevenueAccount: null,
      selectedExpenditureAccount: null,
      selectedCategory: null,
    }
  },
  computed: {
    ...mapState(Types.path, [
      Types.state.revenueAccountDescriptions,
      Types.state.expenseAccountDescriptions,
      Types.state.budgetSummaries,
      Types.state.revenueSummary,
      Types.state.expenditureSummary
    ]),
    currentFiscalYear() {
      if (this.budgetSummaries.length > 0) {
        return Math.max(...this.budgetSummaries.map(b => b.fiscalYear))
      }
      return 0
    },
    budgetSum() {
      return this.budgetSummaries
    },
    revenue() {
      return this.revenueSummary
        .filter(r => !this.selectedRevenueAccount || r.account == this.selectedRevenueAccount)
        .map(item => {
          return { ...item, description: this.getDescription(item.account, 'r') || '' }
        })
    },
    revenueAccountDescriptionOptions() {
      return [
        { value: null, text: 'Select an account' },
        ...this.revenueAccountDescriptions.map(a => {
          return { text: `${a.account} - ${a.description}`, value: a.account }
        })
      ]
    },
    taxCollections() {
      return this.revenue.filter(e => (e.account || '').toString().startsWith('10') || 
        (e.account || '').toString().startsWith('11') || 
        (e.account || '').toString().startsWith('12'))
    },
    expenditureAccountDescriptionOptions() {
      return [
        { value: null, text: 'Select an account' },
        ...this.expenseAccountDescriptions.map(a => {
          return { text: `${a.account} - ${a.description}`, value: a.account }
        })
      ]
    },
    salariesAndBenefits() {
      return (this.expenditure || []).filter(e => (e.category || '').toString().startsWith('S'))
    },
    otherExpenditures() {
      return (this.expenditure || []).filter(e => (e.category || '').toString().startsWith('O'))
    },
    expenditure() {
      let expenditures = this.expenditureSummary || []
      if (this.selectedCategory == 's')
        expenditures = expenditures.filter(e => (e.account || '').toString().startsWith('61') || (e.account || '').toString().startsWith('62'))
      else if (this.selectedCategory == 'o')
        expenditures = expenditures.filter(e => (e.account || '').toString().startsWith('61') == false && (e.account || '').toString().startsWith('62') == false)

      return expenditures
        .filter(r => !this.selectedExpenditureAccount || r.account == this.selectedExpenditureAccount)
        .map(item => {
          return { 
            ...item, 
            category: ((item.account || '').toString().startsWith('61') || (item.account || '').toString().startsWith('62')) ? 'Salaries & Benefits' : 'Other Expenditures', 
            description: this.getDescription(item.account, 'e') || '' 
          }
        })
    },
    expenditureCategories() {
      return [{ text: 'All Expenditures', value: null }, { text: 'Salaries & Benefits', value: 's' }, { text: 'Other Expenditures', value: 'o' }]
    },
  },
  methods: {
    onSelected(selected) {
      console.log(selected)
    },
    absolute(value) {
      if (value < 0) {
        return Math.abs(value)
      }
      return value
    },
    getDescription(account, type) {
      let desc
      if (type === 'e') {
        desc = this.expenseAccountDescriptions.find(a => a.account == account) || ''
      } else {
        desc = this.revenueAccountDescriptions.find(a => a.account == account) || ''
      }
      return desc.description
    },
    currentFY() {
      if (this.budgetSummaries.length > 0) {
        return Math.max(...this.budgetSummaries.map(b => b.fiscalYear)) - 10
      }
      return 0
    },
    yesterday() {
      let result = new Date()
      result.setDate(result.getDate() + -1)
      return result.toLocaleDateString()
    },
    formatCurrency,
    toPercentage, 
    budgetSummary(fiscalYear) {
      return this.budgetSummaries.find(b => (b.fiscalYear-10) == fiscalYear) || {}
    },
    totalRevenue_FY1(revenue) {
      return sumBy(revenue, (e) => e.fY_1_Revenue)
    },
    totalRevenue_FY2(revenue) {
      return sumBy(revenue, (e) => e.fY_2_Revenue)
    },
    totalRevenue_FY3(revenue) {
      return sumBy(revenue, (e) => e.fY_3_Revenue)
    },
    totalCurrentRevenue(revenue) {
      return sumBy(revenue, (e) => e.current_Revenue)
    },
    totalCurrentRevenueBudget(revenue) {
      return sumBy(revenue, (e) => e.current_Budget)
    },
    totalRevenueVariance(revenue) {
      return this.totalCurrentRevenueBudget(revenue) - this.totalCurrentRevenue(revenue)
    },
    totalExpenture_FY1(expenditures) {
      return sumBy(expenditures, (e) => e.fY_1_Expenditures)
    },
    totalExpenture_FY2(expenditures) {
      return sumBy(expenditures, (e) => e.fY_2_Expenditures)
    },
    totalExpenture_FY3(expenditures) {
      return sumBy(expenditures, (e) => e.fY_3_Expenditures)
    },
    totalCurrentExpenture(expenditures) {
      return sumBy(expenditures, (e) => e.current_Expenditures)
    },
    totalCurrentBudget(expenditures) {
      return sumBy(expenditures, (e) => e.current_Budget)
    },
    totalCurrentVariance(expenditures) {
      return this.totalCurrentBudget(expenditures) - this.totalCurrentExpenture(expenditures)
    },
    excelExportData() {
      return [
        {
          name: 'Budget Summary',
          items: this.budgetSummaries.map(i => {
            return {
              Year: i.fiscalYear + 1990,
              'Beginning Balance': i.beginningBalance,
              Revenue: i.revenue,
              Expenditures: i.expenditures,
              'Ending Balance': i.endingBalance
            }
          })
        },
        {
          name: 'Revenue',
          items: this.revenue.map(i => {
            return {
              Account: i.account,
              Description: i.description,
              [`FY ${this.currentFiscalYear - 3} Revenue`]: i.fY_1_Revenue,
              [`FY ${this.currentFiscalYear - 2} Revenue`]: i.fY_2_Revenue,
              [`FY ${this.currentFiscalYear - 1} Revenue`]: i.fY_3_Revenue,
              'Current Revenue': i.current_Revenue,
              Variance: i.variance
            }
          })
        },
        {
          name: 'Expenditures',
          items: this.expenditure.map(i => {
            return {
              Category: i.category,
              Account: i.account,
              Description: i.description,
              [`FY ${this.currentFiscalYear - 3} Expenditures`]: i.fY_1_Expenditures,
              [`FY ${this.currentFiscalYear - 2} Expenditures`]: i.fY_2_Expenditures,
              [`FY ${this.currentFiscalYear - 1} Expenditures`]: i.fY_3_Expenditures,
              [`Current Budget`]: i.current_Expenditures,
              'Current Expenditures': i.current_Expenditures,
              Variance: i.variance
            }
          })
        }
      ]
    }
  },
  components: {
    PaginatedTable,
    Promised,
    LoadingSpinner,
    LeaDisplay,
    CustomTooltip,
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if (newLea && newLea.number) {
          this.loadData = Promise.all([
            this.$store.dispatch(Types.actions.getRevenueAccountDescriptions, {}),
            this.$store.dispatch(Types.actions.getExpenseAccountDescriptions, {}),
            this.$store.dispatch(Types.actions.getBalances, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getBudgetSummaries, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getRevenueSummary, { lea: newLea.number }),
            this.$store.dispatch(Types.actions.getExpenditureSummary, { lea: newLea.number })
          ])
        }
      }
    }
  }
}
</script>

<style>
.nav-tabs.purple > .nav-item {
  font-size: 1.5rem;
}
.nav-tabs.purple > .nav-item > a {
  padding-right: 2em;
  padding-left: 2em;
}
.bg-purple {
  background-color: #CCC0DA !important;
}
.bg-green {
  background-color: #C4D79B !important;
}
.bg-lightgreen {
  background-color: #EBF0AC !important;
}
.bg-orange {
  background-color: #FABF8F !important;
}
.bg-blue {
  background-color: #B7DEE8 !important;
}
.bg-gray {
  background-color: #c5c7ca !important;
}
@media screen {
    .display-print-only {
      display: none !important;
    }
  }

@media print {
  .section-heading { 
      position: fixed; 
      top: 12em; 
      left: 0; 
      right: 0; 
      /* height: 50px;  */
  } 
  table td.bg-purple {
    background-color: #CCC0DA !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  table th.bg-purple {
    background-color: #CCC0DA !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  table th.bg-green {
    background-color: #C4D79B !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  table th.bg-lightgreen {
    background-color: #EBF0AC !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  table th.bg-orange {
    background-color: #FABF8F !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  table th.bg-blue {
    background-color: #B7DEE8 !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  table td.bg-blue {
    background-color: #B7DEE8 !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  table tfoot tr.bg-blue th {
    background-color: #B7DEE8 !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  table tfoot tr.bg-gray th {
    background-color: #c5c7ca !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  table tfoot tr.bg-gray th {
    background-color: #c5c7ca !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  table td.text-danger {
    color: #dc3545 !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  table tfoot th.text-danger {
    color: #dc3545 !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  table tfoot th.text-success {
    color: #28a745 !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  table td.text-danger {
    color: #dc3545 !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  table td.text-success {
    color: #28a745 !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  tfoot {display: table-row-group;}
}

</style>

<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode" :key="selectedLea.number">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { RatingTypes } from '../common/Enums'
import { isBusGrounded } from '../../../stores/dpsaft/trans'
import { mapGetters } from 'vuex'
import sumBy from 'lodash.sumby'
import moment from 'moment'
const currentDate = moment()
const currentSchoolYear = currentDate.isSameOrAfter(moment(`${currentDate.year()}-8-1`)) ? currentDate.year()+1 : currentDate.year()

export default {
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: this.chartType()
        },
        title: {
          text: this.title()
        },
        legend: {
          enabled: true
        },
        credits: {
          text: ''
        },
        plotOptions: {
          series: {
            minPointLength: 4
          },
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.percentage:.1f} %'
            }
          }
        },
        series: this.series(),
        xAxis: {
          title: {
            text: ''
          },
          categories: ['Inspection Result'],
          tickInterval: 1
        },
        tooltip: {
          enabled: true
        },
        yAxis: {
          title: {
            text: 'Counts'
          },
          endOnTick: false
        }
      }
    }
  },
  mixins: [ChartMixin],
  computed: {
    ...mapGetters('globalModule', ['leaDictionary']),
    inspections() {
      return this.mode == 'facilities' ? this.$store.state.DPSAFT.buildings : this.$store.state.DPSAFT.buses
    },
    coopsAndBuildings() {
      return this.$store.state.DPSAFT.coopsAndBuildings
    }
  },
  watch: {
    inspections: {
      immediate: true,
      handler(newInspections) {
        let stats = {
          total: newInspections.length,
          inspected: sumBy(newInspections, (inspection) => {
            return currentSchoolYear == inspection.schoolYearLastCompletedInspection
          })
        }
        const completedInspections = newInspections.filter(inspection => {
          return currentSchoolYear == inspection.schoolYearLastCompletedInspection
        })
      
        if (!this.statusBreakdown) {
          this.chartOptions.series[0].data = [{
            name: 'Inspected',
            y: stats.inspected
          },
          {
            name: 'Uninspected',
            y: stats.total - stats.inspected
          }]
        } else if (this.mode == 'facilities') {
          stats.hasIssues = completedInspections.filter(inspection => {
            return (inspection.fixComponents > 0)
          }).length
          stats.Ok = completedInspections.filter(inspection => {
            return (inspection.fixComponents == 0)
          }).length
          this.chartOptions.series[0].data = [{
            y: stats.Ok
          }]
          this.chartOptions.series[1].data = [{
            y: stats.hasIssues
          }]
        } else {
          stats.fixOnly = completedInspections.filter(inspection => {
            return !isBusGrounded(inspection.latestInspection) && 
              (inspection.latestInspection.components || []).some(c => c.rating == RatingTypes.Fix)
          }).length
          stats.ground = completedInspections.map(i => i.latestInspection).filter(isBusGrounded).length
          this.chartOptions.series[0].data = [{
            y: completedInspections.filter(i => !isBusGrounded(i.latestInspection) 
            && !(i.latestInspection.components || []).some(c => c.rating == RatingTypes.Fix )).length
          }]
          this.chartOptions.series[1].data = [{
            y: stats.fixOnly
          }]
          this.chartOptions.series[2].data = [{
            y: stats.ground
          }]

          this.chartOptions.series[0].color = "#28a745"
          this.chartOptions.series[1].color = "#ffc107"
          this.chartOptions.series[2].color = "#dc3545"
        }
      }
    },
    selectedLea() {
    }
  },
  props: {
    mode: String,
    statusBreakdown: Boolean,
    isStateView: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    chartType() {
      return this.statusBreakdown ? 'column' : 'pie'
    },
    series() {
      if (!this.statusBreakdown) {
        return [
          { name: 'Count' }
        ]
      } else if (this.mode == 'facilities') {
        return [
          { name: 'OK' },
          { name: 'Has Issues' }
        ]
      } else {
        return [
          { name: 'OK' },
          { name: 'Fix' },
          { name: 'Ground' }
        ]
      }
    },
    title() {
      if (!this.statusBreakdown) {
        return `Inspected / Uninspected ${this.mode == 'facilities' ? 'Buildings' : 'Buses'} this School Year`
      } else {
        return `Current School Year Inspection Results`
      }
    },
    dataItems() {
      if (!this.isStateView) {
        if (!this.statusBreakdown) {
          return [{
            Status: 'Inspected',
            Count: this.chartOptions.series[0].data[0].y
          },
          {
            Status: 'Uninspected',
            Count: this.chartOptions.series[0].data[1].y
          }]
        } else {
          return this.chartOptions.series.map(s => {
            return {
              Status: s.name,
              Count: (s.data || []).length == 1 ? s.data[0].y : 0
            }
          })
        }
      }
      else{
        console.log(this.coopsAndBuildings)
        if(this.statusBreakdown){
          return this.coopsAndBuildings.map((coop) => {
            return {
              CoopName: this.leaDictionary[coop.coopLea].name,
              OkCount: coop.okBuildings,
              FixCount: coop.issueBuildings
            }
          })
        }
        else {
          return this.coopsAndBuildings.map((coop) => { return {
              CoopName: this.leaDictionary[coop.coopLea].name,
              InspectedCount: coop.inspected,
              UninspectedCount: coop.notInspected
            } 
          })
        }
      }
    }
  }
}
</script>

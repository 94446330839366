import ApiService from '../services/ApiService'
import throttle from 'lodash.throttle'
import { router } from '../routes'

export class AttritionReportModule {

    constructor() {
        this._apiService = new ApiService()
        this._configElement = document.getElementById('application-config')
        this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
    }

    namespaced = true

    state = {
        attritionStatistics: [],
        trendLevel: "4",
        position: '',
        loadData: null,
        trendLevels: [],
        positions: [],
        chartData: [],
        pageStateReady: false,
        minYear: Number.MAX_SAFE_INTEGER,
        maxYear: Number.MIN_SAFE_INTEGER
    }
    mutations = {
        setAttritionStatistics: (state, data) => state.attritionStatistics = data,
        setChartData: (state, data) => state.chartData = data,
        setTrendLevel: (state, trendLevel) => state.trendLevel = trendLevel,
        setPosition: (state, position) => state.position = position,
        setPageState: (state, pageState) => {
          //console.log('setting page state');
          state.position = pageState.position
          state.trendLevel = pageState.trendLevel
          state.pageStateReady = true
        }
    }
    getters = {
      effectiveReportInfo(_state, getters, _rootState, rootGetters) {
        const dictionary = rootGetters['globalModule/leaDictionary']

        const lea = Object.assign({}, getters.contextLea ? getters.contextLea : dictionary['AR'])
        //delete lea.ownedLeas
        var level = 0
        switch (lea.type) {
          case 10:
            level = 3;
          break; //map data level type 10 to attrition level 3
          case 3:
            level = 4;
          break; //map data level type 3 (state) to attrition level 4
          default:
            level = lea.type;
        }

        const info = {
          lea: lea,
          level: level
        }
        return info
      },
      leaDictionary(_state, _getters, _rootState, rootGetters) {
        return rootGetters['globalModule/leaDictionary']
      },
      trendLevel(state) { //, getters) {
        if (typeof state.trendLevel !== Number) state.trendLevel = parseInt(state.trendLevel)

        // fix bad selection
        // var levelsAllowed = getters.trendLevelsAllowed
        // if (!levelsAllowed.includes(state.trendLevel)) {
        //     console.log('nuking trend level');
        //     state.trendLevel = levelsAllowed[0]
        // }

        return state.trendLevel
      },
      position(state){ //, getters) {
          // fix bad selection
          //var positionsAllowed = getters.positionsAllowed
          //if (!positionsAllowed.includes(state.position)) state.position = positionsAllowed[0]
        return state.position
      },
      contextLea(_state, _getters, _rootState, rootGetters) {
        var contextLeaNumber = rootGetters['globalModule/leaContext'].SelectedLea ? rootGetters['globalModule/leaContext'].SelectedLea.number : router.currentRoute.query.lea
        
        if (contextLeaNumber && rootGetters['globalModule/leaDictionary']) {
          return rootGetters['globalModule/leaDictionary'][contextLeaNumber]
        }
        return null
      },
      trendLevels(_state, getters) {
        return [
          {
            text: 'Trend by State',
            value: '4',
            disabled: !getters.trendLevelsAllowed.includes(4)
          },
          {
            text: 'Trend by Co-Op',
            value: '3',
            disabled: !getters.trendLevelsAllowed.includes(3)
          },
          {
            text: 'Trend by District',
            value: '2',
            disabled: !getters.trendLevelsAllowed.includes(2)
          },
          {
            text: 'Trend by School',
            value: '1',
            disabled: !getters.trendLevelsAllowed.includes(1)
          }
        ]
      },
      positions(_state, getters) {
        return [
          {
            text: 'Superintendent',
            value: 'superintendent',
            disabled: !getters.positionsAllowed.includes('superintendent')
          },
          {
            text: 'Principal',
            value: 'principal',
            disabled: !getters.positionsAllowed.includes('principal')
          },
          {
            text: 'Teacher',
            value: 'teacher',
            disabled: !getters.positionsAllowed.includes('teacher')
          }
        ]
      },
      trendLevelsAllowed(state, getters) {
        var level = getters.effectiveReportInfo.level
        var value = []
        switch (level) {
          case 4:
            value = [4, 3]
          break;
          case 3:
            value = [3, 2, 1]
          break;
          case 2:
            value = [2, 1]
          break;
          case 1:
            value = [1]
          break;
          default:
            value = []
          break
        }
        // remove trend by school if superintendent is selected
        if (state.position == 'superintendent') {
          var index = value.indexOf(1);
          if (index > -1) {
            value.splice(index, 1);
          }
        }

          // if (level == 1) {
          //     console.log('forcing trend level to 1');
          //     state.trendLevel = 1
          // }

        return value
      },
      positionsAllowed(state, getters) {
        var level = getters.effectiveReportInfo.level
        var value = []
        if (level == 1) value = ['principal', 'teacher']
        else value = ['superintendent', 'principal', 'teacher']
        // remove superintendent if trend by school is selected
        if (state.trendLevel == 1) {
          var index = value.indexOf('superintendent');
          if (index > -1) {
            value.splice(index, 1);
          }
        }
        return value
      },
      getLeaName: (state, getters) => lea => {
        var stateIgnored = state
        if (lea && getters.leaDictionary) return (getters.leaDictionary[lea] || {}).name
        return ""
      },
      yearsArray(state) {
        var data = []
        for (var i = state.minYear; i < state.maxYear; ++i)
          data.push(i)
        return data
      }
    }
    actions = {
        getAttritionStatistics: ({
            commit,
            state,
            getters
        }, payload) => {
          return this._apiService.Post(`${this._baseUrl}api/attritionreport/GetAttritionReport`, payload)
            .then(attritionData => {
                commit('setAttritionStatistics', attritionData)

                let grouped = attritionData.reduce((r, a) => {
                    var leaName = getters.getLeaName(a.scopeLEA)
                    r[a.scopeLEA] = r[a.scopeLEA] || {
                        name: leaName,
                        data: [],
                        lineWidth: 2,
                        dashStyle: 'Dash'
                    };
                    r[a.scopeLEA].data.push({
                        x: (a.fy % 1990) + 1990,
                        y: a.attritionRate * 100.0,

                        scope: a.scope,
                        numberDepartedNextFY: a.numberDepartedNextFY,
                        numberReturnedNextFY: a.numberReturnedNextFY,
                        totalEmployed: a.totalEmployed
                    });
                    if (a.scopeLEA == getters.contextLea.number) {
                        r[a.scopeLEA].dashStyle = 'Solid'
                    }
                    if ((a.fy % 1990) + 1990 < state.minYear) state.minYear = (a.fy % 1990) + 1990
                    if ((a.fy % 1990) + 1990 > state.maxYear) state.maxYear = (a.fy % 1990) + 1990
                    return r;
                }, new Map())

                var dataChart = [];
                for (var k in grouped) {
                    grouped[k].data.pop()
                    dataChart.push({
                        name: `${grouped[k].name} (${k})`,
                        data: grouped[k].data,
                        lineWidth: grouped[k].lineWidth || 2,
                        dashStyle: grouped[k].dashStyle || 'Dash'
                    })
                }
                var chartData = dataChart.filter(s => !s.name.includes("undefined")).filter(s => s.data.length > 0);
                chartData.sort((a, b) => (a.data[0].scope < b.data[0].scope) ? -1 : (a.data[0].scope > b.data[0].scope) ? 1 : a.name.localeCompare(b.name))

                commit('setChartData', chartData)
            })
        },
        refresh: throttle(function ({ dispatch, getters, state }) {
            //console.log('refresh attrition data')
            //debugger;
            var reportInfo = getters.effectiveReportInfo
            var trendLevel = state.trendLevel
            var leas = [{
              leaNumber: reportInfo.lea.number,
              level: reportInfo.level,
              groupBy: trendLevel
            }]

            dispatch('globalModule/addDebugMessage', `Refreshing using trendLevel: ${trendLevel}, lea info type: ${reportInfo.lea.type}`, {root: true});

            //if (state.position == '') state.position = getters.positionsAllowed[0]
            if (reportInfo.lea.type == 10) {
              // coop
              if (trendLevel == 3) {
                  // coop, trended by coop, add state
                  leas.push({
                      leaNumber: 'AR',
                      level: 4,
                      groupBy: 4
                  })
              } else if (trendLevel == 2) {
                  // coop, trended by district, add coop and state
                  leas.push({
                      leaNumber: reportInfo.lea.number,
                      level: 3,
                      groupBy: 3
                  })
                  leas.push({
                      leaNumber: 'AR',
                      level: 4,
                      groupBy: 4
                  })
              } else if (trendLevel == 1) {
                  // coop, trended by school, add coop and state
                  leas.push({
                      leaNumber: reportInfo.lea.number,
                      level: 3,
                      groupBy: 3
                  })
                  leas.push({
                      leaNumber: 'AR',
                      level: 4,
                      groupBy: 4
                  })
              }
          } else if (reportInfo.lea.type == 2) {
              // district
              if (trendLevel == 2) {
                  // district, trended by district, add coop and state
                  leas.push({
                      leaNumber: reportInfo.lea.parentLea.number,
                      level: 3,
                      groupBy: 3
                  })
                  leas.push({
                      leaNumber: 'AR',
                      level: 4,
                      groupBy: 4
                  })
              } else if (trendLevel == 1) {
                  // coop, trended by school, add coop and state
                  leas.push({
                      leaNumber: reportInfo.lea.number,
                      level: 2,
                      groupBy: 2
                  })
                  leas.push({
                      leaNumber: reportInfo.lea.parentLea.number,
                      level: 3,
                      groupBy: 3
                  })
                  leas.push({
                      leaNumber: 'AR',
                      level: 4,
                      groupBy: 4
                  })
              }
          } else if (reportInfo.lea.type == 1) {
            leas.push({
                leaNumber: reportInfo.lea.parentLea.number,
                level: 2,
                groupBy: 2
            })
            leas.push({
                leaNumber: 'AR',
                level: 4,
                groupBy: 4
            })
        }

          state.loadData = this.dispatch('attritionReportModule/getAttritionStatistics', {
            leaScopes: leas,
            position: state.position
          })
        }, 
        100, {
          leading: true,
          trailing: false
      })
    }
}
<template>
  <div class="teachers-page">
    <b-card no-body v-if="(teacherList || []).length > 0">
      <b-tabs card>
        <b-tab no-body active>
          <template v-slot:title>
            Teachers with Verifiable Classrooms <b-badge pill>
              {{ (teachersWithVerifiableCourses || []).length }}
            </b-badge>
          </template>
          <div class="card-body card-body-secondary">
            <teacher-list :hide-lea="false" :teacher-list="teachersWithVerifiableCourses" :verifiable="true" />
          </div>
        </b-tab>
        <b-tab no-body>
          <template v-slot:title>
            Teachers with no Verifiable Classrooms <b-badge pill>
              {{ (teachersWithoutVerifiableCourses || []).length }}
            </b-badge>
          </template>
          <div class="card-body card-body-secondary">
            <teacher-list :hide-lea="false" :teacher-list="teachersWithoutVerifiableCourses" :verifiable="false" />
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-card v-else>
      <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
        <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';

import TeacherList from '../common/TeacherList.vue'
import CardStateIcon from '@/components/CardStateIcon.vue'

export default {
  components: {
    TeacherList,
    CardStateIcon
  },
  computed: {
    ...mapGetters('rvsModule', ['completeTeachers', 'totalTeachers']),
    ...mapState('rvsModule', ['teacherList', 'fiscalYear']),
    teachersWithVerifiableCourses() {
      return this.teacherList.filter(teacher => (teacher.subjects || []).length > 0 && teacher.totalVerifiableStudents > 0)
    },
    teachersWithoutVerifiableCourses() {
      return this.teacherList.filter(teacher => (teacher.subjects || []).length === 0 || teacher.totalVerifiableStudents <= 0)
    },
  },
  methods: {
    async reloadData() {
      if (this.fiscalYear)
        this.loading = this.$store.dispatch('rvsModule/getTeacherList', this.selectedLea.number)
    },
  },
  watch: {
    fiscalYear: {
      handler() {
        this.reloadData()
      }
    },
    selectedLea: {
      immediate: true,
      handler() {
        this.reloadData()
      }
    }
  }
}
</script>
<template>
  <div class="d-print-none" v-if="canEdit">
    <div v-if="!edit" class="mt-3">
      <b-button @click="$emit('on-edit')" variant="primary" :disabled="!canEdit">
        <i class="fas fa-edit" /> Edit
      </b-button>
      <b-button v-if="!hideSubmit" variant="success" class="ml-2 float-right" @click="$emit('on-submit')" :disabled="!canSubmit">
        <i class="fas fa-paper-plane" /> Submit to ADE-DESE
      </b-button>
    </div>
    <div v-else>
      <b-button variant="info" @click="$emit('on-save')" id="saveButton" :disabled="!canSave">
        <i class="fas fa-save" /> {{ saveLabel }}
      </b-button>
      <b-button variant="danger" class="ml-2" @click="$emit('on-cancel')">
        <i class="fas fa-trash" /> Discard Changes
      </b-button>
      <b-button v-if="!hideSubmit" variant="success" class="ml-2 float-right" @click="$emit('on-submit')" :disabled="!canSubmit">
        <i class="fas fa-paper-plane" /> Submit to ADE-DESE
      </b-button>
    </div>
  </div>
</template>

<script>
import { Types } from '@/stores/digitalLearning'
import { mapState } from 'vuex'

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.availableStates, Types.state.selectedWaiverRequest]),
    canSubmit() {
      return this.availableStates && (this.availableStates.advanceStates || []).length > 0 && this.isValid 
    }
  },
  props: {
    edit: {
      type: Boolean
    },
    canSave: {
      type: Boolean
    },
    isValid: {
      type: Boolean
    },
    canEdit: {
      type: Boolean
    },
    hideSubmit: {
      type: Boolean
    },
    saveLabel: {
      type: String
    },
    request: {
      type: Object
    }
  }
}
</script>

<style>

</style>
import ApiService from '@/services/ApiService'
import Vue from 'vue'
import removeNamespace from '@/stores/removeNamespace'
import { createGetTransaction, createInsertTransaction, createDeleteTransaction, createUpdateTransaction } from '@/stores/VuexTransaction'
import { Survey } from './survey'
import { Admin } from './admin'
import axios from 'axios'

export const Types = {
  state: {
    planCategories: 'planCategories',
    esaFiscalYear: 'esaFiscalYear',
    planResponses: 'planResponses',
    planExpenses: 'planExpenses',
    plan: 'plan',
    plans: 'plans',
    availableStates: 'availableStates',
    districtUsers: 'districtUsers',
    lockedStatus: 'lockedStatus',
    dataLockedStatus: 'dataLockedStatus',
    districtBudgets: 'districtBudgets',
    districtAllocations: 'districtAllocations',
  },
  actions: {
    getPlanCategories: 'ESA/getPlanCategories',
    savePlanResponse: 'ESA/savePlanResponse',
    getPlanResponses: 'ESA/getPlanResponses',
    getPlanExpenses: 'ESA/getPlanExpenses',
    getPlan: 'ESA/getPlan',
    createPlan: 'ESA/createPlan',
    getPlans: 'ESA/getPlans',
    getAvailableStates: 'ESA/getAvailableStates',
    transitionToState: 'ESA/transitionToState',
    getDistrictUsers: 'ESA/getDistrictUsers',
    assignOrComment: 'ESA/assignOrComment',
    uploadPlanFile: 'ESA/uploadPlanFile',
    deletePlanFile: 'ESA/deletePlanFile',
    downloadPlanFile: 'ESA/downloadPlanFile',
    getLockedStatus: 'ESA/getLockedStatus',
    toggleLockedStatus: 'ESA/toggleLockedStatus',
    toggleLeaPlanCategory: 'ESA/toggleLeaPlanCategory',
    toggleDataLockedStatus: 'ESA/toggleDataLockedStatus',
    getDataLockedStatus: 'ESA/getDataLockedStatus',
    getDistrictBudgets: 'ESA/getDistrictBudgets',
    getDistrictAllocations: 'ESA/getDistrictAllocations',
  },
  mutations: {
    setPlanCategories: 'ESA/setPlanCategories',
    setESAFiscalYear: 'ESA/setESAFiscalYear',
    setPlanResponses: 'ESA/setPlanResponses',
    setPlanExpenses: 'ESA/setPlanExpenses',
    updatePlanResponses: 'ESA/updatePlanResponses',
    setPlan: 'ESA/setPlan',
    setPlans: 'ESA/setPlans',
    setAvailableStates: 'ESA/setAvailableStates',
    setDistrictUsers: 'ESA/setDistrictUsers',
    setPlanChangeLogs: 'ESA/setPlanChangeLogs',
    setLockedStatus: 'ESA/setLockedStatus',
    setDataLockedStatus: 'ESA/setDataLockedStatus',
    setDistrictBudgets: 'ESA/setDistrictBudgets',
    setDistrictAllocations: 'ESA/setDistrictAllocations',
  },
  getters: {
  },
  path: 'ESA'
}
const _types = removeNamespace('ESA/', Types)

function setPlanState(data, commit, dispatch) {
  if (data && data.leaPlanId) {
    commit(_types.mutations.setPlan, data)
    return window.userContext.isAuthenticated ? dispatch(_types.actions.getAvailableStates, { id: data.leaPlanId, lea: data.districtLea }) : Promise.resolve()
  }
  else {
    commit(_types.mutations.setPlan, data)
  }
  return data
}

export class ESA {
  constructor() {
    const store = this
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''

    this.createGetTransaction = createGetTransaction
    this.createInsertTransaction = createInsertTransaction
    this.createDeleteTransaction = createDeleteTransaction
    this.createUpdateTransaction = createUpdateTransaction
    
    const esaBaseUrl = window.userContext.isAuthenticated ? `${this._baseUrl}api/esa` : `${this._baseUrl}api/esa/public`

    this.createGetTransaction(_types.state.planCategories,
      _types.mutations.setPlanCategories,
      _types.actions.getPlanCategories,
      (payload) => `${esaBaseUrl}/plancategory/${payload.fy}`
    )
    this.createGetTransaction(_types.state.planResponses,
      _types.mutations.setPlanResponses,
      _types.actions.getPlanResponses,
      (payload) => `${esaBaseUrl}/${payload.lea}/planresponse/${payload.fy}`
    )
    this.createGetTransaction(_types.state.planExpenses,
      _types.mutations.setPlanExpenses,
      _types.actions.getPlanExpenses,
      (payload) => `${esaBaseUrl}/${payload.lea}/planexpense/${payload.fy}`
    )
    this.createInsertTransaction(_types.state.planResponses,
      null,
      _types.actions.savePlanResponse,
      (payload) => `${esaBaseUrl}/${payload.DistrictLEA}/planresponse`,
      (payload) => payload
    )
    this.createGetTransaction(_types.state.plan,
      _types.mutations.setPlan,
      {
        [_types.actions.getPlan]: ({ commit, dispatch }, payload) => {
          const apiPromise = payload.id ? 
            this._apiService.Get(`${esaBaseUrl}/${payload.lea}/plan/${payload.id}`) :
            this._apiService.Get(`${esaBaseUrl}/${payload.lea}/plan?fiscalYear=${payload.fy}`)
          return apiPromise
            .then(data => setPlanState(data, commit, dispatch))
        },
      },
      null
    )
    this.createInsertTransaction(_types.state.plan,
      _types.mutations.setPlan,
      {
        [_types.actions.createPlan]: ({ commit, dispatch }, payload) => {
          return this._apiService.Post(`${esaBaseUrl}/${payload.lea}/plan?fiscalYear=${payload.fy}`)
            .then(data => setPlanState(data, commit, dispatch))
        },
      },
      null
    )
    this.createGetTransaction(_types.state.availableStates,
      _types.mutations.setAvailableStates,
      _types.actions.getAvailableStates,
      (payload) => `${esaBaseUrl}/${payload.lea}/plan/${payload.id}/state`, 
      {}
    )
    this.createInsertTransaction(_types.state.availableStates,
      _types.mutations.setAvailableStates,
      {
        [_types.actions.transitionToState]: ({ dispatch, state }, payload) => {
          return this._apiService.Post(`${esaBaseUrl}/${payload.lea}/plan/${payload.id}/state`, payload).then(() =>  {
            return dispatch(_types.actions.getPlan, { ...payload, fy: state.esaFiscalYear})
          })
        }
      },
      (payload) => `${esaBaseUrl}/${payload.lea}/plan/${payload.id}/state`, 
      {}
    )
    this.createGetTransaction(_types.state.districtUsers,
      _types.mutations.setDistrictUsers,
      {
        [_types.actions.getDistrictUsers]: ({ commit }, payload) => {
          return this._apiService.Get(`${store._baseUrl}api/leauser/lea/${payload.lea}`).then(data => {
            const districtUsers =  [{
              value: null,
              text: 'Select a district user'
            }].concat(data.map(user => { 
              return { value: { assignToName: user.fullName, assignToUserId: `APSCN\\${user.userId}` }, text: `${user.fullName} (${user.userId})` }
            }))
            commit(_types.mutations.setDistrictUsers, districtUsers)
          })
        },
      }
    )
    this.createInsertTransaction(_types.state.plan,
      {
        [_types.mutations.setPlanChangeLogs]: (state, data) => {
          if (data && data.changeLogs) {
            Vue.set(state.plan, 'changeLogs', data.changeLogs)
          }
        },
      },
      {
        [_types.actions.assignOrComment]: ({ commit }, payload) => {
          return this._apiService.Post(`${esaBaseUrl}/${payload.lea}/plan/${payload.id}/comment?lea=${payload.lea}`, {
            AssignedToName: payload.assignToName,
            AssignedToUserId: payload.assignToUserId,
            Comment: payload.comment
          }).then(data =>  {
            commit(_types.mutations.setPlanChangeLogs, data)
            return data
          })
        },
      }
    )
    this.createGetTransaction(_types.state.plans,
      _types.mutations.setPlans,
      _types.actions.getPlans,
      (payload) => `${esaBaseUrl}/${payload.lea}/plan/list?fiscalYear=${payload.fy}`
    )
    this.createInsertTransaction(_types.state.plan,
      _types.mutations.setPlan,
      {
        [_types.actions.uploadPlanFile]: ({ commit, dispatch }, payload) => {
          this._apiService.Post(`${esaBaseUrl}/${payload.lea}/plan/${payload.id}/file?fileName=${payload.fileName}`, payload)
            .then(data => setPlanState(data, commit, dispatch))
        },
      },
      null
    )
    this.createDeleteTransaction(_types.state.plan,
      _types.mutations.setPlan,
      {
        [_types.actions.deletePlanFile]: ({ commit, dispatch }, payload) => {
          this._apiService.Delete(`${esaBaseUrl}/${payload.lea}/plan/${payload.id}/file?fileName=${payload.fileName}`, payload)
            .then(data => setPlanState(data, commit, dispatch))
        },
      },
      null
    )
    this.createGetTransaction(_types.state.lockedStatus,
      _types.mutations.setLockedStatus,
      _types.actions.getLockedStatus,
      (payload) => `${esaBaseUrl}/${payload.lea}/plan/locked`,
      false
    )
    this.createGetTransaction(_types.state.getDataLockedStatus,
      _types.mutations.setDataLockedStatus,
      _types.actions.getDataLockedStatus,
      (payload) => `${esaBaseUrl}/${payload.lea}/plan/locked/data`,
      false
    )
    this.createInsertTransaction(_types.state.dataLockedStatus,
      _types.mutations.setDataLockedStatus,
      _types.actions.toggleDataLockedStatus,
      (payload) => `${esaBaseUrl}/${payload.lea}/plan/locked/data`,
      (payload) => payload,
      false
    )
    this.createInsertTransaction(_types.state.lockedStatus,
      _types.mutations.setLockedStatus,
      _types.actions.toggleLockedStatus,
      (payload) => `${esaBaseUrl}/${payload.lea}/plan/locked`,
      (payload) => payload,
      false
    )
    this.createUpdateTransaction(_types.state.plan,
      _types.mutations.setPlan,
      {
        [_types.actions.toggleLeaPlanCategory]: ({ commit, dispatch }, payload) => {
          this._apiService.Put(`${esaBaseUrl}/${payload.lea}/plan/${payload.id}/toggle/${payload.planCategoryId}?enabled=${payload.enabled}`, null)
            .then(data => setPlanState(data, commit, dispatch))
        },
      },
      null
    )
    this.createGetTransaction(_types.state.districtBudgets,
      _types.mutations.setDistrictBudgets,
      _types.actions.getDistrictBudgets,
      (payload) => `${esaBaseUrl}/${payload.lea}/plan/budget?fiscalYear=${payload.fy}`,
      (payload) => payload
    )
    this.createGetTransaction(_types.state.districtBudgets,
      _types.mutations.setDistrictBudgets,
      _types.actions.getDistrictBudgets,
      (payload) => `${esaBaseUrl}/${payload.lea}/plan/budget?fiscalYear=${payload.fy}`,
      (payload) => payload
    )
    this.createGetTransaction(_types.state.districtAllocations,
      _types.mutations.setDistrictAllocations,
      _types.actions.getDistrictAllocations,
      (payload) => `${esaBaseUrl}/${payload.lea}/plan/allocation?fiscalYear=${payload.fy}`,
      (payload) => payload
    )
  }

  namespaced = true
  
  modules = {
    survey: new Survey(),
  }

  state = {
    [Types.state.esaFiscalYear]: null,
  }

  mutations = {
    [_types.mutations.setESAFiscalYear]: (state, data) => state.esaFiscalYear = data,
  }

  actions = {
    [_types.actions.downloadPlanFile]: (_, payload) => {
      const url = encodeURI(`/api/esa/${payload.lea}/plan/${payload.id}/file?fileName=${payload.fileName}`)
      return axios.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: payload.mimeType })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = payload.fileName
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },
  }

  modules = {
    admin: new Admin(),
    survey: new Survey()
  }
}

export const StateMap = new Map();
StateMap.set(0, {
  code: 0,
  status: 'Draft',
  action: 'Return to Draft',
  supportsAssignment: true,
  requiresComment: false,
  variant: 'secondary'
})
StateMap.set(1, {
  code: 1,
  status: 'Information or Changes Requested',
  action: (plan, userContext) =>  {
    if ((userContext.districtLEA || '').length == 7) {
      return 'Withdraw for Changes'
    }
    return (plan || {}).status > 11 ? 'Unlock for District Changes' : 'Return to Applicant'
  },
  supportsAssignment: true,
  requiresComment: true,
  variant: 'warning'
})
// StateMap.set(10, {
//   code: 10,
//   action: 'Submit',
//   status: 'Submitted',
//   variant: 'primary'
// })
StateMap.set(11, {
  code: 11,
  action: 'Submit to ADE DESE',
  status: 'Submitted to ADE DESE',
  requiresComment: false,
  variant: 'primary',
})
StateMap.set(100, {
  code: 100,
  action: 'Approve',
  status: 'Reviewed',
  requiresComment: false,
  variant: 'info'
})
StateMap.set(101, {
  code: 101,
  action: 'Approve',
  status: 'Approved',
  requiresComment: true,
  variant: 'success'
})
StateMap.set(255, {
  code: 255,
  action: 'Cancel',
  status: 'Canceled',
  requiresComment: true,
  variant: 'warning'
})
import Home from './home'
import InspectionItems from './inspectionItems.vue'
import Buildings from './buildings.vue'
import BuildingsImport from './buildingsImport'
const FacilitiesAdmin = {
  Home,
  InspectionItems,
  Buildings,
  BuildingsImport
}

export {FacilitiesAdmin }
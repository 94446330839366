<template>
  <div class="staff-listing-page">
    <div class="mb-2">
      <excel-button title="Download" :url="downloadUrl" :no-float-right="true" />
    </div>
    <b-card no-body v-if="staffListing">
      <b-tabs card>
        <b-tab no-body active>
          <template v-slot:title>
            Teachers of Record <b-badge pill>
              {{ (staffListing.teachersOfRecord || []).length }}
            </b-badge>
          </template>
          <div class="card-body card-body-secondary">
            <paginated-table :items="staffListing.teachersOfRecord" :fields="teachersOfRecordFields" :sort-asc="true" :per-page="20" striped hover>
              <template v-slot:cell(outDistrict)="data">
                <span v-if="data.value == 'Y'" class="badge badge-lg badge-warning">Yes</span>
                <span v-else-if="data.value == 'N'" class="badge badge-lg badge-secondary">No</span>
                <span v-else />
              </template>
              <template v-slot:cell(subject)="data">
                <subject-tag :subject="data.value" />
              </template>
            </paginated-table>
          </div>
        </b-tab>
        <b-tab no-body>
          <template v-slot:title>
            Potential Contributors <b-badge pill>
              {{ (staffListing.potentialContributors || []).length }}
            </b-badge>
          </template>
          <div class="card-body card-body-secondary">
            <paginated-table :items="staffListing.potentialContributors" :fields="potentialContributorsFields" :sort-asc="true" :per-page="20" striped hover>
              <template v-slot:cell(outDistrict)="data">
                <span v-if="data.value == 'Y'" class="badge badge-lg badge-warning">Yes</span>
                <span v-else-if="data.value == 'N'" class="badge badge-lg badge-secondary">No</span>
                <span v-else />
              </template>
            </paginated-table>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-card v-else>
      <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
        <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CardStateIcon from '@/components/CardStateIcon.vue';
import PaginatedTable from '@/components/PaginatedTable'
import SubjectTag from '@/modules/rvs/components/common/SubjectTag.vue'
import ExcelButton from '@/components/ExcelButton.vue'

export default {
  components: {
    CardStateIcon,
    PaginatedTable,
    SubjectTag,
    ExcelButton,
  },
  data() {
    return {
      teachersOfRecordFields: [
        { key: 'districtLEA', label: 'District', sortable: true, formatter: this.leaFormatter },
        { key: 'schoolLEA', label: 'School', sortable: true, formatter: this.leaFormatter },
        { key: 'uniqueEmployeeID', label: 'Unique Employee ID', sortable: true },
        { key: 'lastName', label: 'Last Name', sortable: true },
        { key: 'firstName', label: 'First Name', sortable: true },
        { key: 'outDistrict', label: 'Out District', sortable: true },
        { key: 'subject', label: 'Subject', sortable: true },
        { key: 'roleIdentification', label: 'Role Identification', sortable: true, formatter: this.roleIdentificationFormatter },
        { key: 'includeForVAM', label: 'Provided Instruction in Subject Area', sortable: true },
      ],
      potentialContributorsFields: [
        { key: 'districtLEA', label: 'District', sortable: true, formatter: this.leaFormatter },
        { key: 'schoolLEA', label: 'School', sortable: true, formatter: this.leaFormatter },
        { key: 'uniqueEmployeeID', label: 'Unique Employee ID', sortable: true },
        { key: 'lastName', label: 'Last Name', sortable: true },
        { key: 'firstName', label: 'First Name', sortable: true },
        { key: 'outDistrict', label: 'Out District', sortable: true },
        { key: 'includeForVAMELA', label: 'Provided Instruction in ELA', sortable: true },
        { key: 'includeForVAMMath', label: 'Provided Instruction in Math', sortable: true },
        { key: 'includeForVAMScience', label: 'Provided Instruction in Science', sortable: true },
      ],
      loading: false,
    }
  },
  computed: {
    ...mapState('rvsModule', ['staffListing', 'fiscalYear']),
    downloadUrl() {
      return `/api/rvs/${this.fiscalYear}/${this.selectedLea.number}/export`
    }
  },
  methods: {
    async reloadData() {
      if (this.fiscalYear)
        this.loading = this.$store.dispatch('rvsModule/getStaffListing', this.selectedLea.number)
    },
    roleIdentificationFormatter(value) {
      return value === 1 ? 'Primary Teacher of Record' : value === 2 ? 'Secondary Teacher of Record' : 'Unknown'
    },
  },
  watch: {
    fiscalYear: {
      handler() {
        this.reloadData()
      }
    },
    selectedLea: {
      immediate: true,
      handler() {
        this.reloadData()
      }
    }
  }
}
</script>
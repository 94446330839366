import { initializeJSONResponse } from '@/helpers/globalHelpers'

const grades = [7,8,9,10,11,12]

// function certification(schoolYear, certificationId, grade, date, pass, score) {
//   return { id: shortid.generate(), schoolYear: schoolYear, certificationId: certificationId, grade: grade, date: date, pass: pass, score: score, notes: null }
// }

export class Student {
  constructor(data) {
    if (data) {
      initializeJSONResponse(data.studentInformation, 'studentInformationResponse')

      // initialize county of residence with eSchool information if it has not already been set
      if ( (!data.studentInformation.studentInformationResponse.countyResidence) && data.countyResidence) {
        data.studentInformation.studentInformationResponse.countyResidence = data.countyResidence
      }

      initializeJSONResponse(data.studentInformation, 'initialNeedsAssessmentResponse', { 
        workshopAndActivities: { 
          selectedActivities: []
        }, 
        plansAfterHighSchool: {},
      })
      initializeJSONResponse(data.studentInformation, 'academicOutcomesResponse', { 
        otherAssessments: grades.map((g)=> {
            return {
              grade: g,
              assessmentName: '',
              math: null,
              science: null,
              english: null,
              reading: null,
              writing: null,
              composite: null
            }
          })
        }        
      )
      initializeJSONResponse(data.studentInformation, 'certificationsResponse', {
        certifications: []
      })
      initializeJSONResponse(data.studentInformation, 'careerPlanningResponse', {
        careerAssessments: grades.map((g)=> {return { grade: g, dateTaken: null, assessmentName: '', notes: null }}),
        careerPlans: grades.map((g)=> {return { grade: g, createdOrModified: null, date: null, notes: null }}),
        programOfStudy: grades.map((g)=> {return { grade: g, areaId: null, clusterId: null, pathwayId: null, posId: null, jobFamilyId: null, desiredOccupationId: null, notes: null }}),
        exposureToNonTraditionalCareers: grades.map((g)=> {return { grade: g, nonTradProgramOfStudy: null, date: null, notes: null }})
      })
      initializeJSONResponse(data.studentInformation, 'workExperienceResponse', {
        paid: grades.map((g)=> {return { grade: g, notes: '' }}),
        volunteer: grades.map((g)=> {return { grade: g, notes: '' }}),
        workBasedLearning: grades.map((g)=> {return { grade: g, type: null, notes: '' }})
      })
      initializeJSONResponse(data.studentInformation, 'postsecondaryPlanningResponse', {
        colleges: [],
        otherScholarships: []
      })
    }

    this.visits = []

    this.academicOutcomes = {
      ACTAspireAssessments: [],
      ACTAssessments: []
    }

    // this.certifications = [
    //   new certification(2021, 1, 10, null, true, 98),
    //   new certification(2021, 5, 10, null, false, 55)
    // ]
    Object.assign(this, data)
  }

  fullName() {
    return `${this.firstName} ${this.lastName}`
  }
  
  removeVisit(id) {
    var index = this.visits.findIndex(v=>v.id == id)
    if (index >= 0) this.visits.splice(index, 1)
  }

  addVisit(item) {
    this.visits.push(item);
  }
}
<template>
  <div>
    <div class="card mt-3 bg-light">
      <a class="anchor d-print-none" id="instructional-options" />
      <div class="card-header">
        Instructional Options
      </div>
      <div class="card-body">
        <div class="card-group">
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="totals"
            :stacking="true"
            chart-title="Enrollment by Instructional Option"
            :sub-populations="InstructionalOptions" />
          <instructional-options-pie-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="percentages"
            :stacking="true"
            chart-title="Enrollment by Instructional Option (Cycle 2)"
            :fields="getCycleFields(2)" />
          <instructional-options-pie-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="percentages"
            :stacking="true"
            chart-title="Enrollment by Instructional Option (Cycle 7)"
            :fields="getCycleFields(7)" />
        </div>
        <div class="card-group">
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="onsite"
            :stacking="true"
            chart-title="On-site Students By Gender"
            :sub-populations="Genders" />
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="virtual"
            :stacking="true"
            chart-title="Virtual Students By Gender"
            :sub-populations="Genders" />
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="hybrid"
            :stacking="true"
            chart-title="Hybrid Students By Gender"
            :sub-populations="Genders" />
        </div>
        <div class="card-group">
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="onsite" 
            chart-title="On-site Students By Race"
            :sub-populations="Races" />
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="virtual" 
            chart-title="Virtual Students By Race"
            :sub-populations="Races" />
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="hybrid" 
            chart-title="Hybrid Students By Race"
            :sub-populations="Races" />
        </div>
        <div class="card-group">
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="onsite" 
            chart-title="On-site Students By Subpopulation"
            :sub-populations="Subpopulations" />
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="virtual" 
            chart-title="Virtual Students By Subpopulation"
            :sub-populations="Subpopulations" />
          <instructional-options-chart 
            :load-data="loadData" 
            :selected-lea="selectedLea" 
            :instructional-options="hybrid" 
            chart-title="Hybrid Students By Subpopulation"
            :sub-populations="Subpopulations" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import InstructionalOptionsChart from './InstructionalOptionsChart.vue'
import InstructionalOptionsPieChart from './InstructionalOptionsPieChart.vue'
import { EnrollmentSubpops as Genders } from '../enrollment/EnrollmentSubpops'
import { unique } from '@/helpers/globalHelpers'

const InstructionalOptions = [
  { name: 'On-site', altName: '', field: 'totalOnsite' },
  { name: 'Virtual', altName: '', field: 'totalVirtual' },
  { name: 'Hybrid', altName: '', field: 'totalHybrid' },
]
const Races =  [
  { name: 'American Indian', altName: 'Native American / Alaska Native', field: 'nativeAmerican', color: '#9c56b8', value: 2 },
  { name: 'Asian', altName: '', field: 'asian', color: '#00bd9c', value: 3 },
  { name: 'Black / African American', altName: '', field: 'black', color: '#2c97de', value: 4 },
  { name: 'Hawaiian / Pacific Islander', altName: 'Pacific Islander / Native Hawaiian', field: 'hawaiian', color: '#f2c500', value: 5 },
  { name: 'Hispanic / Latino', altName: 'Hispanic', field: 'hispanic', color: '#90ed7d', value: 1 },
  { name: 'Two or More Races', altName: 'Multiethnic', field: 'twoOrMore', color: '#e94b35', value: 7 },
  { name: 'White', altName: '', field: 'white', color: '#e87e04', value: 6 }
]
const Subpopulations = [
  { name: 'Migrant', altName: '', field: 'migrant', color: '#90ed7d', enrollmentCollection: 'byMigrant', value: 'Y' },
  { name: 'Foster Care', altName: '', field: 'foster', enrollmentCollection: 'byFoster', value: 'Y' },
  { name: 'Homeless', altName: '', field: 'homeless', color: '#f2c500'},
  { name: 'Students with Disabilities', altName: '', field: 'disabled', enrollmentCollection: 'byHandicap', value: 'Y' },
  { name: 'English Learners', altName: '', field: 'englishLearners', color: '#e87e04' },
  { name: 'Low Income', altName: '', field: 'lowIncome' },
]

export default {
  data() {
    return {
      loadData: null,
      Genders,
      Races,
      Subpopulations,
      InstructionalOptions,
    }
  },
  computed: {
    ...mapState('overviewModule', ['instructionalOptions']),
    onsite() {
      return this.instructionalOptions.onsite
    },
    virtual() {
      return this.instructionalOptions.virtual
    },
    hybrid() {
      return this.instructionalOptions.hybrid
    },
    percentages() {
      return this.instructionalOptions.percentages
    },
    totals() {
      const fiscalYears = [...this.onsite.map(i => i.fiscalYear), ...this.virtual.map(i => i.fiscalYear), ...this.hybrid.map(i => i.fiscalYear)].filter(unique)
      return fiscalYears.map(fy => { 
        return { 
          fiscalYear: fy, 
          totalOnsite: (this.onsite.find(o => o.fiscalYear == fy) || {}).total || 0,
          totalVirtual: (this.virtual.find(o => o.fiscalYear == fy) || {}).total || 0,
          totalHybrid: (this.hybrid.find(o => o.fiscalYear == fy) || {}).total || 0,
        }
      })
    },
  },
  methods: {
    getCycleFields: (cycle) => [
      { name: 'On-site', altName: '', field: `onSiteCycle${cycle}` },
      { name: 'Virtual', altName: '', field: `virtualCycle${cycle}` },
      { name: 'Hybrid', altName: '', field: `hybridCycle${cycle}` },
    ]
  },
  components: {
    InstructionalOptionsChart,
    InstructionalOptionsPieChart,
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        this.loadData = this.$store.dispatch('overviewModule/getInstructionalOptions', {
          lea: newLea.number
        })
      }
    }
  }
}
</script>

// import Vue from 'vue'
// import dpsaftAdmin from './dpsaftAdmin.vue'
// import sidebar from './sidebar.vue'
// import { PageMixin } from '../../PageMixin'

// const dpsaftAdminPage = Vue.extend({
//     name: 'dpsaft-admin-page',
//     mixins: [PageMixin],
//     data() {
//         return {
//         }
//     },
//     components: {
//       dpsaftAdmin,
//       sidebar
//     }
// })

// export { dpsaftAdminPage }


import dpsaftAdmin from './dpsaftAdmin.vue'
import sidebar from './sidebar.vue'

const DPSAFTAdminModule = {
  dpsaftAdmin,
  sidebar
}

export { DPSAFTAdminModule }
<template>
  <div>
    <district-closures-filter v-model="filter" :initial-district="districtLea" />    
    <district-closures-list :district-lea="districtLea" :mode="mode" :load-data="loadData" />
  </div>
</template>

<script>
import districtClosuresFilter from './districtClosuresFilter'
import districtClosuresList from './districtClosuresList'
import { PageMixin } from '../../PageMixin'
import moment from 'moment'
import store from '../../../stores'

export default {
  data() {
    return {
      filter: { 
        lea: null,
        startDate: null,
        endDate: null
      },
      loadData: null
    }
  },
  mixins:[PageMixin],
  mounted() {
    this.filter.lea = this.districtLea;

    if (this.mode === 'admin') {
      this.filter.lea = 'AR'
    }
    if (this.$route.query.startDate) this.filter.startDate = moment.unix(this.$route.query.startDate).toDate()
      else this.filter.startDate = moment().startOf('week').toDate()
    if (this.$route.query.endDate) this.filter.endDate = moment.unix(this.$route.query.endDate).toDate()
      else this.filter.endDate = moment().endOf('week').toDate()
    this.getData(this.filter)
  },
  props: {
    districtLea: String,
    mode: {
      type: String,
      default: 'district'
    }
  },
  methods: {
    getData(filter) {
      this.loadData = this.$store.dispatch('core/sctClosures/getDistrictClosures', {
        lea: filter.lea,
        startDate: filter.startDate,
        endDate: filter.endDate
      })
    }
  },
  watch: {
    filter: {
      deep: true,
      handler(newFilter) {
        if (newFilter && newFilter.lea) {
          this.$router.replace({ name: this.$route.name, query: {
            startDate: moment(newFilter.startDate).unix(),
            endDate: moment(newFilter.endDate).unix()
          }})

          this.getData(newFilter)
        }
      }
    }
  },
  components: {
    districtClosuresFilter,
    districtClosuresList
  },
  beforeRouteEnter(to, from, next) {   
    if (to.name === 'closure-list' && store.state.globalModule.userContext.hasAnyRole(['Insights_System_Administrators', 'Insights_ADE_Survey'])) {
      //redirect to the admin version of the closure list
      next({name: 'admin-closure-list'})
    } else {
      next()
    }
  }
}
</script>

<style>

</style>
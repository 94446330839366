<template>
  <div>
    <table-control-widget
      :per-page="tableControl.pageLength"
      @filter-changed="tableControl.filter = $event"
      @page-length-changed="tableControl.pageLength = $event" />
    <b-table
      striped
      hover
      selectable
      select-mode="single"
      :items="approvals"
      :per-page="tableControl.pageLength"
      :current-page="tableControl.currentPage"
      :filter="tableControl.filter"
      :fields="tableFields">
      <template v-slot:cell(lea)="data">
        <div>
          <div class="d-inline">
            <button type="button" class="btn btn-link" @click="goToLea(data.value)" :id="data.index + '-' + data.value">
              {{ leaFormatter(data.value) }}
            </button>
            &nbsp;
            <lea-tooltip :target-selector="data.index + '-' + data.value" :lea-number="data.value" />
          </div>
        </div>
      </template>
    </b-table>

    <table-pagination-widget
      :total-rows-count="rows"
      :page-length="tableControl.pageLength"
      @page-changed="tableControl.currentPage = $event" />
  </div>
</template>

<script>
import { TableMixin } from '../../TableMixin'

export default {
  data() {
    return {
      tableControl: {
        filter: null,
        pageLength: 50,
        currentPage: 1
      },
      tableFields: [
        {
          key: 'lea',
          label: 'Distict LEA',
          sortable: true,
          sortDirection: 'asc'
        },
        {
          key: 'amiDaysAllowed',
          label: 'AMI Days Allowed',
          sortable: true
        }
      ]
    }
  },
  mixins: [TableMixin],
  computed: {
    rows() {
      return this.approvals.length
    },
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    }
  },
  methods: {
    leaFormatter(lea) {
      if (this.leaDictionary[lea])
        return `${lea} – ${this.leaDictionary[lea].name}`

      return lea
    },
    goToLea(leaNumber) {
      const lea = this.leaDictionary[leaNumber]
      window.scrollTo(0,0)
      this.$store.dispatch('globalModule/changeSelectedLea', lea)
    }
  },
  props: {
    approvals: Array
  }
}
</script>

<template>
  <div>
    <step-container :academic-year="Number($route.params.ay)" task-code="PIPDU" title="Perkins Indicator Performance Upload">
      <div>
        <b-row>
          <b-col class="text-right">
            <a href="/content/cte/perkins-v/PerkinsIndicatorPerformanceTemplate.xlsx" download class="font-weight-bold text-info"><i class="fa fa-download fa-fw" /> Download blank Perkins V Indicator Performance template</a>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <import-excel-widget :upload-endpoint="uploadEndpoint" @imported="getApplicantPerkinsIndicatorPerformance" worksheet="PERKINS PERFORMANCE" :columns="worksheetColumns">
              <paginated-table :items="applicantPerkinsIndicatorPerformances" :fields="fields" :load-data="loadData" sort-by="applicantId" :sort-desc="true" />
            </import-excel-widget>
          </b-col>
        </b-row>
      </div>
    </step-container>
  </div>
</template>

<script>
import { Types } from '@/modules/cte/stores/perkinsv'
import StepContainer from '../StepContainer.vue'
import PaginatedTable from '@/components/PaginatedTable.vue'
import ImportExcelWidget from '../../../common/ImportExcelWidget.vue'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      completionInfo: {
        completed: false, 
        completedBy: null,
        completedDate: null
      },
      loadData: Promise.resolve(true)
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.applicantPerkinsIndicatorPerformances]),
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    },
    uploadEndpoint() {
      return `/api/cte/perkinsv/admin/perkinsIndicatorPerformance/${this.$route.params.ay}`
    },
    worksheetColumns() {
      return ['AY', 'APPLICANT_ID', 'PI_1S1_ACTUAL', 'PI_1S1_TARGET', 'IS_1S1', 'PI_1S2_ACTUAL', 'PI_1S2_TARGET', 'IS_1S2', 
        'PI_2S1_ACTUAL', 'PI_2S1_TARGET', 'IS_2S1', 'PI_2S2_ACTUAL', 'PI_2S2_TARGET', 'IS_2S2', 'PI_2S3_ACTUAL', 'PI_2S3_TARGET', 'IS_2S3',
        'PI_3S1_ACTUAL', 'PI_3S1_TARGET', 'IS_3S1', 'PI_4S1_ACTUAL', 'PI_4S1_TARGET', 'IS_4S1', 'PI_5S1_ACTUAL', 'PI_5S1_TARGET', 'IS_5S1']
    },
    fields() {
      return  [
        // { key: 'ay', label: 'Academic Year', sortable: true },
        { key: 'applicantId', label: 'Applicant', sortable: true, formatter: this.leaFormatter },
        { key: 'perkinsIndicator.indicatorCode', label: 'Perkins Indicator Code', sortable: true },
        { key: 'priorAchievement', label: 'Prior Achievement', sortable: true },
        { key: 'currentTarget', label: 'Current Target', sortable: true },
      ]
    }
  },
  methods: {
    getApplicantPerkinsIndicatorPerformance() {
      this.loadData = this.$store.dispatch(Types.actions.getApplicantPerkinsIndicatorPerformance, { ay: this.$route.params.ay })
    },
    leaFormatter(lea) {
      if (this.leaDictionary[lea])
        return `${lea} - ${this.leaDictionary[lea].name}`
      return lea
    }
  },
  mounted() {
    this.getApplicantPerkinsIndicatorPerformance()
  },
  components: {
    ImportExcelWidget,
    PaginatedTable,
    StepContainer
  }
}
</script>

<template>
  <div class="mt-3">
    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center" :class="{ 'bg-warning text-dark': propertyHasChangedFromSnapshot('amiResponse') }">
        AMI (Alternate Method of Instruction)
      </div>
      <div class="card-body">
        <p class="p-3">
          As per Arkansas Code Annotated § 6-10-127 a district may apply for up to 10 days of AMI for the use of alternative methods of instruction, including
          [remote] learning, on days when the district is closed due to exceptional or emergency circumstances such as:
          <ul>
            <li>contagious disease outbreak</li>
            <li>inclement weather</li>
            <li>other acts of God</li>
            <li>utility outage</li>
          </ul>
        </p>
        <table class="table table-striped table-sm table-condensed mt-3">
          <tbody>
            <tr :class="valid ? '' : 'border border-danger'">
              <td class="w-40 p-3">
                Number of AMI days requested by the district. (Please note that all AMI days utilized must be noted in eSchool.  Sporting events, funerals, other school events, lack of substitutes, or lack of bus drivers do not qualify as exceptional circumstances.)
              </td>
              <td class="w-60">
                <b-input type="number" v-model="local.requestedAMIDays" number :plaintext="!edit" :state="valid" id="amidays-input" />
                <b-form-invalid-feedback id="amidays-input" class="d-print-none" :state="valid">
                  <strong>You must specify the number of requested days for the AMI</strong>
                </b-form-invalid-feedback>
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Provide a description of the AMI assignments and materials used at each school within the district to effectively facilitate teaching and support learning for the benefit of the students.  Please include information if the district will utilize technology-based assignments or paper packets.  (AMI packets must be available on-line or have been sent home prior to the designation of an AMI day)
              </td>
              <td class="w-60">
                <b-textarea max-rows="200" v-model="local.descriptionOfAssignmentsAndMaterials" rows="3" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Describe how student attendance will be determined for an approved AMI day.  (All attendance should be determined and reported in eSchool within 5 days of the AMI day.)  May include a link (URL) to the attendance policy for digital learning students if applicable to all students during AMI days.
              </td>
              <td class="w-60">
                <b-textarea max-rows="200" v-model="local.descriptionOfHowAttendanceDetermined" rows="3" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Describe how the district will ensure implementation of Individual Education Programs (IEPs) for students with disabilities on AMI days.  Include how the district will involve the special education teacher in the provision of special education services during the AMI day to meet the individual needs of each student.
              </td>
              <td class="w-60">
                <b-textarea max-rows="200" v-model="local.descriptionOfIEP" rows="3" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Describe how accommodations and related services will be provided.
              </td>
              <td class="w-60">
                <b-textarea max-rows="200" v-model="local.descriptionOfAccommodations" rows="3" :plaintext="!edit" />
              </td>
            </tr>
            <tr>
              <td class="w-40 p-3">
                Please include the link (URL) to the webpage or social media where information will be posted to announce the date of the AMI day and reason for utilizing the AMI day - (this should match the AMI dates entered into eSchool).  (Example:  December 1 will be an AMI day due to inclement weather)
              </td>
              <td class="w-60">
                <b-textarea max-rows="200" type="url" v-model="local.announcementUrl" rows="3" :plaintext="!edit" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { JsonResponseMixin } from '@/components/JsonResponseMixin'
import DetailMixin from './DetailMixin'

export default {
  mixins: [JsonResponseMixin, DetailMixin]
}
</script>

<style>
.w-40 {
    width: 40%!important;
}
.w-60 {
    width: 60%!important;
}
</style>
<template>
  <div class="mt-3">
    <b-form inline class="my-2">
      <b-form-group label="Role">
        <b-form-select v-model="selectedRole" :options="roleOptions" />
      </b-form-group>
    </b-form>
    <paginated-table hover :items="filteredItems" :fields="fields">
      <template v-slot:cell(roles)="data">
        <div>
          <span v-for="role in (data.value || [])" :key="role">
            {{ PerkinsVRolesDisplay[role] }}
          </span>
        </div>
      </template>
      <template v-slot:cell(clusters)="data">
        <div>
          <b-badge variant="primary" v-for="cluster in (data.value || [])" :key="cluster + data.item.userId" class="d-block m-1" v-if="getCluster(cluster)">
            {{ getCluster(cluster) }}
          </b-badge>
        </div>
      </template>
      <template v-slot:cell(leas)="data">
        <div>
          <span v-for="lea in (data.value || [])" :key="lea">
            {{ leaFormatter(lea) }}
          </span>
        </div>
      </template>
      <template v-slot:cell(action)="data">
        <div v-if="supportsExtendedClaims(data.item)">
          <router-link :to="{ name: 'CTE/PerkinsV/Admin/User/Edit', query: { lea: selectedLea.number, userId: data.item.userId }, params: { ...data.item} }">
            <i class="fas fa-edit" />
          </router-link>
        </div>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PaginatedTable from '@/components/PaginatedTable'
import { PerkinsVRolesDisplay, PerkinsVRoles } from '@/modules/cte/components/common/Roles'
import { Types as CommonTypes } from '@/modules/cte/stores'

export default {
  data() {
    return {
      PerkinsVRolesDisplay,
      selectedRole: null
    }
  },
  props: {
    items: Array,
  },
  computed: {
    ...mapState(CommonTypes.path, [CommonTypes.state.occupationalAreaMetadata]),
    clusters() {
      return this.occupationalAreaMetadata.clusters || []
    },
    filteredItems() {
      if (this.selectedRole) {
        return this.items.filter(i => i.roles.some(r => r == this.selectedRole))
      }
      return this.items
    },
    fields() {
      let fields = [
        { key: 'fullName', label: 'Name', sortable: true },
        { key: 'userId', label: 'Username', sortable: true },
        { key: 'emailAddress', label: 'Email', sortable: true },
        { key: 'telephoneNumber', label: 'Phone', sortable: true },
        { key: 'roles', label: 'Role(s)' },
      ]
      if (this.selectedRole == 'Insights_PerkinsV_Application_Coordinators') {
        fields.push({ key: 'leas', label: 'LEA(s)' })
      }
      if (this.selectedRole == PerkinsVRoles['Program Advisor'] || this.selectedRole == PerkinsVRoles['Program Coordinator']) {
        fields.push({ key: 'clusters', label: 'Clusters' })
      }

      return [...fields, { key: 'action', label: '' }]
    },
    roleOptions() {
      return [{ text: 'All roles', value: null }, ...Object.keys(PerkinsVRolesDisplay).map(key => { 
        return {
          text: PerkinsVRolesDisplay[key],
          value: key
        }
      })]
    } 
  },
  methods: {
    getCluster(id) {
      return (this.clusters.find(oa => oa.id == id) || { name: null }).name
    },
    supportsExtendedClaims(user) {
      return user.roles.some(role => role == PerkinsVRoles['Program Advisor'] || role == PerkinsVRoles['Program Coordinator'])
    },
  },
  components: {
    PaginatedTable
  }
}
</script>

<template>
  <div>
    <proposal-info-widget v-model="proposal" mode="add" v-if="selectedApplication.ay > 23" />
    <proposal-info-widget-old v-model="proposal" mode="add" v-else />
    <b-button variant="primary" @click="addProposal" :disabled="!valid">
      <i class="fa fa-save fa-fw" />
      Save Proposal
    </b-button>
    <b-button type="reset" variant="danger" class="ml-3" @click="backToApplication">
      Cancel
    </b-button>
  </div>  
</template>

<script>
import ProposalInfoWidget from './ProposalInfoWidget.vue'
import ProposalInfoWidgetOld from './ProposalInfoWidgetOld.vue'
import { ProposalTypeValues } from '../../enums'
import { Types } from '@/modules/cte/stores/perkinsv'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      proposal: { proposalType: 0, applicationId: this.applicationId },
    }
  },
  watch: {
  },
  computed: {
    ...mapState(Types.path, [Types.state.selectedProposal, Types.state.selectedApplication]),
    valid() {
      if (this.proposal.proposalType == 0) return false
      
      if (this.selectedApplication.ay > 23)
      {
        return (this.proposal.proposalType == ProposalTypeValues.AllProgramAreas || this.proposal.proposalType == ProposalTypeValues.DeficientAllProgramAreas) || 
        this.proposal.clusterId > 0
      }
      else return (this.proposal.proposalType == ProposalTypeValues.AllProgramAreas || this.proposal.proposalType == ProposalTypeValues.DeficientAllProgramAreas) || 
        this.proposal.occupationalAreaId > 0
    },
  },
  components: {
    ProposalInfoWidget,
    ProposalInfoWidgetOld
  },
  props: {
    applicationId: String,
  },
  mounted() {
    if (Object.keys(this.selectedApplication).length < 1) {
      this.$store.dispatch(Types.actions.getApplication, { applicationId: this.applicationId }).then(() => {
      })
    }
  },
  methods: {
    backToApplication() {
      this.$store.commit(Types.mutations.setSelectedProposal, {})
      this.$router.push({ name: 'CTE/PerkinsV/Application', params: { applicationId: this.applicationId }})
    },
    addProposal() {
      return this.$store.dispatch(Types.actions.createProposal, { proposal: this.proposal, applicationId: this.applicationId })
        .then((proposalId) => {
          this.$router.push({ name: 'CTE/PerkinsV/Application/Proposal/Edit', params: { applicationId: this.applicationId, proposalId: proposalId }})
        })
    }
  }
}
</script>
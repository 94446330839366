
<template>
  <div>
    <h2 class="caption">
      Applicant Funding Details
    </h2>
    <table class="table table-sm table-borderless">
      <tr>
        <th><custom-tooltip element-id="th-amount-awarded" content="The amount of Perkins-V funds awarded to applicant(s)" /> Awarded to Applicant(s)</th>
        <td class="text-right">
          {{ formatCurrency(amountAwarded) }}
        </td>
      </tr>
      <tr>
        <th><custom-tooltip element-id="th-amount-approved" content="The amount of Perkins-V funds allocated to proposals that have been approved" /> Amount Budgeted (Approved)</th>
        <td class="text-right">
          {{ formatCurrency(budgetedApproved) }}
        </td>
      </tr>
      <tr>
        <th><custom-tooltip element-id="th-amount-pending" content="The amount of Perkins-V funds allocated to proposals that are pending approval" /> Amount Budgeted (Pending)</th>
        <td class="text-right">
          {{ formatCurrency(budgetedPending) }}
        </td>
      </tr>
      <tr>
        <th><custom-tooltip element-id="th-amount-total" content="The total amount of Perkins-V funds allocated to both approved AND pending proposals" /> Total Amount Budgeted</th>
        <td class="text-right">
          {{ formatCurrency(budgetedPending + budgetedApproved) }}
        </td>
      </tr>
      <tr class="border-dark border-top">
        <th><custom-tooltip element-id="th-amount-remaining" content="The amount of Perkins-V funds remaing to allocate. [Awarded - Total Amount Budgeted]" /> Amount Remaining</th>
        <td class="text-right">
          {{ formatCurrency(amountRemaining) }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>

import { formatCurrency } from '@/helpers/formatters'
import { round } from '@/helpers/mathHelpers'
import CustomTooltip from '@/components/CustomTooltip'
import { Types } from '@/modules/cte/stores/perkinsv'
import { mapState } from 'vuex'
import sumBy from 'lodash.sumby'

export default {
  data() {
    return {
      formatCurrency
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.applications]),
    amountAwarded() {return this.applications.length > 0 ? round(sumBy(this.applications, (a)=>a.amountAwarded), 2) : 0 },
    budgetedPending() {return  this.applications.length > 0 ? round(sumBy(this.applications, (a)=>a.amountBudgetedPending), 2) : 0 },
    budgetedApproved() {return  this.applications.length > 0 ? round(sumBy(this.applications, (a)=>a.amountBudgetedApproved), 2) : 0 },
    amountRemaining() {
      return this.amountAwarded - round(this.budgetedPending + this.budgetedApproved, 2) 
    }
  },
  components: {
    CustomTooltip
  }
}
</script>
<template> 
  <div>
    <Promised :promise="loadData">
      <template v-slot:pending>
        <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
          <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
        </div>
      </template>
      <template v-slot>
        <plan-expenses-widget />
      </template>
      <template v-slot:rejected="error" class="align-items-center justify-content-center">
        <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
      </template>
    </Promised>
  </div>
</template>

<script>

import { Types } from '@/modules/esa/stores/'
import { mapState } from 'vuex'
import CardStateIcon from '@/components/CardStateIcon.vue'
import { Promised } from 'vue-promised'
import PlanExpensesWidget from './PlanExpensesWidget'
import { LeaTypes } from '@/helpers/leaTypes'

export default {
  data() {
    return {
      loadData: null
    }
  },
  computed: {
    ...mapState(Types.path, [
      Types.state.plans,
      Types.state.esaFiscalYear,
    ]),
  },
  components: {
    Promised,
    CardStateIcon,
    PlanExpensesWidget
  },
  methods: {
    getData() {
      if (this.selectedLea && this.esaFiscalYear) {
        let actions = [
          this.$store.dispatch(Types.actions.getPlanExpenses, { fy: this.esaFiscalYear, lea: this.selectedLea.number }),
          this.$store.dispatch(Types.actions.getPlanCategories, { fy: this.esaFiscalYear }),
          this.$store.dispatch(Types.actions.getDistrictAllocations, { fy: this.esaFiscalYear, lea: this.selectedLea.number }),
        ];
        if (this.selectedLea && this.selectedLea.type == LeaTypes.District) {
          this.$store.dispatch(Types.actions.getPlan, { fy: this.esaFiscalYear, lea: this.selectedLea.number })
        }
        this.loadData = Promise.all(actions)
      }
    },
  },
  watch: {
    esaFiscalYear: {
      immediate: true,
      handler() {
        this.getData()
      }      
    },
    selectedLea: {
      immediate: true,
      handler() {
        this.getData()
      }
    }
  },
}

</script>

<template>  
  <div class="card bg-light mt-3">
    <a class="anchor" id="checks" />
    <div class="card-header">
      Background Checks
    </div>
    <div class="card-body">
      <div class="card-group">
        <background-check-chart :load-data="loadData" />
        <background-check-chart :load-data="loadData" type="sfa" />
      </div>
    </div>
  </div>
</template>
<script>

import BackgroundCheckChart from './BackgroundCheckChart.vue'

export default {
  name: 'employability-background-checks-widget',
  props: {
    loadData: Promise
  },
  methods:{
    rangeSelected(selectedRange, currentColor){
      this.$emit('range-selection-changed', selectedRange, currentColor)
    }
  },
  components: {
    BackgroundCheckChart
  }
}
</script>
<template>
  <div>
    <pdf-button />
    <excel-button title="Teacher Unqualified Courses" />
    <h1>
      <custom-tooltip element-id="teacher-label" :content="'Source: <a href=' + credits.SFA.href + '>' + credits.SFA.text + '</a>'" /> 
      Teacher Unqualified Courses
    </h1>
    <h2><lea-display :lea="selectedLea" /></h2>
    <div>
      <violation-filter label="Unqualified Course Teacher" />
    </div>
    <div class="alert alert-warning mt-4" v-if="leaType != LeaTypes.School && leaType != LeaTypes.District">
      Select a district or school to view the Data Quality.
    </div>
    <div v-else>
      <teacher-unqualified-course-widget />
    </div>
    <div>
      <span class="pull-right">
        Source: <a :href="credits.SFA.href">{{ credits.SFA.text }}</a>
      </span>
    </div>
  </div>
</template>

<script>
import TeacherUnqualifiedCourseWidget from './TeacherUnqualifiedCourseWidget.vue'
import { PageMixin } from '../../PageMixin'
import { credits } from '../../../helpers/credits'
import { LeaTypes } from '../../../helpers/leaTypes'
import ViolationFilter from '../ViolationFilter'

export default {
  name: 'data-quality-page',
  data() {
    return {
      credits,
      LeaTypes
    }
  },
  mixins: [PageMixin],
  components: {
    TeacherUnqualifiedCourseWidget,
    ViolationFilter
  },
  computed: {
  },
  methods: {
  },
  watch: {
  }
}
</script>
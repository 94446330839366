<template>
  <div class="card mt-3 bg-light">
    <a class="anchor d-print-none" id="current" />
    <div class="card-header">
      Annual Statistical Report
      <a v-if="msiUrl" class="btn btn-sm btn-light text-dark pull-right" target="_new" :href="msiUrl">
        <i class="fas fa-external-link-alt" /> Open in MSI
      </a>
    </div>
    <div class="card-body">
      <div class="card-group">
        <totals-chart :load-data="loadData" :selected-lea="selectedLea" />
      </div>
      <div v-for="(category, index) in categories" :key="category">
        <div class="card-group" v-if="index%2 == 0">
          <a class="anchor d-print-none" :id="getTrendAnchor(index)" />
          <trend-chart :load-data="loadData" :selected-lea="selectedLea" :category="category" :key="'trend-' + index" />
          <trend-chart :load-data="loadData" :selected-lea="selectedLea" :category="categories[index+1]" :key="'trend-' + index + 1" v-if="(index+1) < categories.length" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { LeaTypes } from '../../../helpers/leaTypes'
import { Types } from '../../../stores/financeModule'
import { Categories } from './Categories'
import TotalsChart from './TotalsChart.vue'
import TrendChart from './TrendChart.vue'

export default {
  data() {
    return {
      loadData: null
    }
  },
  computed: {
    ...mapGetters(Types.path, [Types.getters.asrFiscalYear]),
    categories() {
      return Categories
    },
    selectedLea() { 
      return this.$store.state.globalModule.leaContext.SelectedLea 
    },
    msiUrl() {
      if (this.selectedLea.type == LeaTypes.State)
        return `https://myschoolinfo.arkansas.gov/State/Detail?openreport=asr&tab=reports&fy=${this.asrFiscalYear}`
      else if (this.selectedLea.type == LeaTypes.District)
        return `https://myschoolinfo.arkansas.gov/Districts/Detail/${this.selectedLea.number}?openreport=asr&tab=reports&fy=${this.asrFiscalYear}`

      return null
    }
  },
  methods: {
    getTrendAnchor(index) {
      return `trend-${index}`
    },
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if (newLea && newLea.number) {
          this.loadData = this.$store.dispatch(Types.actions.getOverview, {
            lea: newLea.number
          })
        }
      }
    }
  },
  components: {
    TotalsChart,
    TrendChart
  },
}
</script>

import Vue from 'vue'
import { TableMixin } from '../../TableMixin'

const ReportCodeGroupAssignmentTable = Vue.extend({
    name: 'report-code-group-assignment-table',
    mixins: [TableMixin],
    /* html */
    template: `
    <div>
        <table-control-widget v-bind:per-page="tableControl.pageLength" 
            v-on:filter-changed="tableControl.filter=$event" 
            v-on:page-length-changed="tableControl.pageLength=$event">
        </table-control-widget>

        <b-table striped hover selectable select-mode='single' 
            v-bind:items="reportCodeGroupAssignments" 
            v-bind:fields="['courseCode','courseTitle','gradeRange']"
            v-bind:per-page="tableControl.pageLength" 
            v-bind:current-page="tableControl.currentPage"
            v-bind:filter="tableControl.filter" >
        </b-table>

        <table-pagination-widget 
            v-bind:total-rows="rows" 
            v-bind:page-length="tableControl.pageLength" 
            v-on:page-changed="tableControl.currentPage=$event">
        </table-pagination-widget>
    </div>`,
    data() {
        return {
            tableControl: {
                filter: null,
                pageLength: 50,
                currentPage: 1
            },
            tableFields: [{
                    key: 'groupName',
                    sortable: true,
                    sortDirection: 'asc'
                },
                {
                    key: 'actions',
                    label: 'Actions',
                    class: 'text-right'
                }
            ],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            selectedItem: {
                groupName: '(not selected)'
            },
            editingItem: {
                id: 0,
                fy: 0,
                groupName: '(not selected}'
            }
        }
    },

    methods: {
        rowSelected(items) {
            Object.assign(this.editingItem, items[0]);
        },
        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        }
    },
    computed: {
        reportCodeGroupAssignments() {

            const all =this.$store.state.shortageReportAdminModule.reportCodeGroupAssignments;
            var rv = [];

            if (!this.selectedReportGroup) {
                rv = all.filter(c => c.reportGroupId == 0);
            } else {
                rv = all.filter(c => c.reportGroupId == this.selectedReportGroup.id);
            }
            return rv;
        },
        rows() {
            return this.$store.state.shortageReportAdminModule.reportCodeGroupAssignments.length
        }
    },
    props: {
        selectedReportGroup: Object,
    }
})

export { ReportCodeGroupAssignmentTable }
<template>
  <div class="border-bottom py-2 px-2 mb-2 rounded" :style="{'background-color': bgColor}">
    <div class="form-row">
      <div class="col-12 col-md-6">
        {{ component.itemName }}
      </div>
      <div class="col-12 col-md-6 text-right">
        <b-form-radio-group :options="options" v-model="componentChanges.rating" @change="save" :disabled="readonly" />
      </div>
    </div>
    <div class="form-row" v-if="hasActionItems">
      <div class="col-md-8">
        Action Item(s):
        <b-form-textarea v-model="componentChanges.comments" />
      </div>
      <div class="col-md-2" v-if="mode != 'facilities'">
        Minor:
        <b-form-input type="number" v-model="componentChanges.minorPoints" :disabled="readonly" />
      </div>
      <div class="col-md-2" v-if="mode != 'facilities'">
        Major:
        <b-form-input type="number" v-model="componentChanges.majorPoints" :disabled="readonly" />
      </div>
    </div>
    <div class="form-row" v-if="hasActionItems && isDirty">
      <div class="col-md-12">
        <div class="pt-2 pull-right">
          <b-btn variant="success" size="sm" @click="save()" :disabled="readonly || (!componentChanges.comments || componentChanges.comments.length < 1)">
            Save
          </b-btn>
          <b-btn variant="danger" size="sm" class="mx-1" @click="cancel">
            Cancel
          </b-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isEquivalent } from '../../../helpers/globalHelpers'
import { Facilities } from '../../../stores/dpsaft/facilities'
import { Trans } from '../../../stores/dpsaft/trans'
import { RatingTypes } from './Enums'
const facilitiesStore = new Facilities()
const transportationStore = new Trans()

export default {
  data() {
    return {
      RatingTypes,
      defaultOptions: [
        { text: 'N/C', value: 0 },
        { text: 'OK', value: 1 },
        { text: 'Fix', value: 2 }
      ],
      componentChanges: {...this.component}
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    bgColor() {
      return this.componentChanges.rating == 3
        ? 'rgba(255, 105, 115, 0.5)'
        : this.componentChanges.rating == 2
        ? 'rgba(217, 211, 108, 0.5)'
        : 'white'
    },
    options() {
      return this.mode == 'facilities' ? 
        [...this.defaultOptions, ...[{ text: 'N/A', value: 255 }]] : 
        [...this.defaultOptions, ...[{ text: 'Ground', value: 3 }, { text: 'N/A', value: 255 }]]
    },
    isDirty() {
      const propertiesToCompare = Object.getOwnPropertyNames({...this.componentChanges})
      return !isEquivalent({...this.componentChanges}, {...this.component}, propertiesToCompare)
    },
    batchId() {
      var selectedBatch = this.mode == 'facilities' ? this.$store.state.DPSAFT.Facilities.SelectedBatch : this.$store.state.DPSAFT.Trans.SelectedBatch
      return selectedBatch.batchId
    },
    inspectionId() {
      return this.mode == 'facilities' ? this.componentChanges.facilitiesInpectionId : this.componentChanges.transportationInpectionId
    },
    hasActionItems() {
      return [RatingTypes.Fix, RatingTypes.Ground].includes(this.componentChanges.rating)
    },
    saveAction() {
      const module = this.mode == 'facilities' ? facilitiesStore.path : transportationStore.path
      const actionName = this.mode == 'facilities' ? 'saveInspectionComponent' : 'saveInspectionComponent'
      return `${module}/${actionName}`
    }
  },
  methods: {
    save(rating) {
      // If the user selected an action item required rating, short circuit and make them save manually
      if ([RatingTypes.Fix, RatingTypes.Ground].includes(rating)){
         return
      }
      else if([RatingTypes.OK, RatingTypes.NotApplicable, RatingTypes.NotChecked].includes(rating)) {
        this.componentChanges.comments = null
        this.componentChanges.minorPoints = null
        this.componentChanges.majorPoints = null
      }
      if (Object.keys(RatingTypes).map(type => RatingTypes[type]).includes(rating)) {
        this.componentChanges.rating = rating
      }
      this.$store.dispatch(this.saveAction, { batchId: this.batchId, inspectionId: this.inspectionId, id: this.componentChanges.id, update: this.componentChanges })
    },
    cancel() {
      this.componentChanges = {...this.component}
    }
  },
  props:{
    component: Object,
    mode: String,
    readonly: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <loadable-chart-card :load-data="loadData" :enable-data-toggle="false" card-style="">
    <academics-overview-table :fy="fy" :display-years="yearsToDisplay" :initial-filter="tableFilters" @filter-updated="onFilterUpdated" />
  </loadable-chart-card>
</template>

<script>
import AcademicsOverviewTable from './AcademicsOverviewTable.vue'
import LoadableChartCard from '../../LoadableChartCard.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'academics-overview-widget',
  data() {
    return {
      fy: 29,
      yearsToDisplay: 3,
      loadData: null,
      tableFilters: {
        gradeSpan: this.initialGradeSpan,
        letterGrade: this.initialLetterGrade,
        name: this.initialName
      }
    }
  },
  components: {
    AcademicsOverviewTable,
    LoadableChartCard
  },
  computed: {
    ...mapGetters('academicsModule/essa', ['overviewFilters'])
  },
  props: {
    initialGradeSpan: String,
    initialLetterGrade: String,
    initialName: String
  },
  methods: {
    onFilterUpdated(filters) {
      this.$emit('filter-updated', filters)
    }
  },
  mounted() {
    const pageState = {
      gradeSpan: this.tableFilters.gradeSpan || null,
      letterGrade: this.tableFilters.letterGrade || null,
      name: this.tableFilters.name || null
    }
    this.$store.commit('academicsModule/essa/setOverviewFilterState', pageState)
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if (newLea && newLea.number)
          this.loadData = this.$store
            .dispatch('academicsModule/essa/getEssaAcademicsInformation', {
              lea: newLea.number,
              fy: this.fy,
              filters: this.tableFilters
            })
            .then(() => {
              return this.$store.dispatch('globalModule/addDebugMessage', 'Data loaded in watcher')
            })
      }
    }
  }
}
</script>

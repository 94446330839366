<template>
  <div class="card bg-light mb-3" v-if="!isReadonly">
    <div class="card-body p-1">
      <b-button class="btn btn-danger" :disabled="!canSave" @click="$emit('cancel')">
        <i class="fas fa-trash" />&nbsp; Discard Changes
      </b-button>
      <animated-button button-class="btn btn-success float-right" type="button" :promise-resolver="saveMethod" :disabled="!canSave || !isValid">
        <i class="fas fa-save" />&nbsp; Save
      </animated-button>
    </div>
  </div>
</template>

<script>
import AnimatedButton from '@/components/AnimatedButton.vue'

export default {
  data() {
    return {}
  },
  props: {
    canSave: {
      type: Boolean,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    saveMethod: {
      type: Function,
      default: () => {}
    },
    isReadonly: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    AnimatedButton
  }
}
</script>
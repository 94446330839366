import { GrantAccessPage } from './GrantAccessPage'
import { ModuleRestrictionPage } from './ModuleRestrictionPage'
import JobPage from './jobs/JobPage.vue'
import { DPSAFTAdminModule } from './dpsaft'
import DistrictExtractMonitorPage from './extractMonitor/DistrictExtractMonitorPage.vue'
import NotificationsPage from './notifications/NotificationsPage.vue'

const AdministrationArea = {
    GrantAccessPage,
    ModuleRestrictionPage,
    JobPage,
    DPSAFTAdminModule,
    DistrictExtractMonitorPage,
    NotificationsPage,
}

export { AdministrationArea }
<template>
  <div>
    <page-header :icon="$route.meta.icon" :label="$route.meta.label" :ay="ay" />
    <router-view :ay="ay" />
  </div>
</template>

<script>
import PageHeader from '../common/PageHeader'

export default {
  components:{
    PageHeader
  },
  mounted() {
    this.$store.dispatch('cte/getOAMetadata', { ay: this.ay })
    this.$store.dispatch('cte/careercoach/getMetadata')
  },
  props: {
    ay: Number
  }
}
</script>

<template>
  <div>
    <b-card no-body>
      <b-tabs card fill pills content-class="mt-3" active-nav-item-class="font-weight-bold" nav-wrapper-class="bg-secondary">
        <b-tab title="General" active>
          <b-form>
            <div class="form-row" v-if="isDirty && !readonly">
              <div class="col-md-12">
                <div class="pt-2 pull-right">
                  <b-btn variant="success" size="sm" @click="save">
                    Save
                  </b-btn>
                  <b-btn variant="danger" size="sm" class="mx-1" @click="cancel">
                    Cancel
                  </b-btn>
                </div>
              </div>
            </div>
            <b-form-row class="d-none d-md-flex">
              <div class="col-md-6">
                <b-form-group label-class="font-weight-bold" label="District">
                  <span>{{ leaFormatter(inspectionChanges.districtLEA) }}</span>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label-class="font-weight-bold" label="Inspection Type">
                  <span>{{ inspectionChanges.isFollowup ? 'Follow up Inspection' : 'Original Inspection' }}</span>
                </b-form-group>
              </div>
            </b-form-row>

            <b-form-row>
              <div class="col-md-6">
                <b-form-group label-class="font-weight-bold" label="Building (facility)">
                  <span>{{ inspectionChanges.building.buildingNumber }} &ndash; {{ inspectionChanges.building.buildingName }}</span>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label-class="font-weight-bold" label="LEA #">
                  <span>{{ inspectionChanges.building.schoolNumber }}</span>
                </b-form-group>
              </div>
            </b-form-row>

            <b-form-row class="">
              <div class="col-md-6">
                <b-form-group label-class="font-weight-bold" label="Grade Configuration">
                  <b-form-input v-model="inspectionChanges.gradeConfiguration" :disabled="readonly" />
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label-class="font-weight-bold" label="Academic / Non-Academic">
                  <span>{{ inspectionChanges.building.isBuildingAcademic ? 'Academic' : 'Non-Academic' }}</span>
                </b-form-group>
              </div>
            </b-form-row>

            <b-form-row>
              <div class="col-md-6">
                <b-form-group label-class="font-weight-bold" label="Walk-through of facility conducted">
                  <b-form-checkbox v-model="inspectionChanges.wasWalkthroughConducted" :disabled="readonly" />
                </b-form-group>
                <b-form-group label-class="font-weight-bold" label="Roof">
                  <b-form-select v-model="inspectionChanges.roofType" :options="RoofTypes" :disabled="readonly" />
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label-class="font-weight-bold" label="GPS">
                  <b-btn variant="primary" size="sm" @click="getLocation" v-if="!readonly">
                    Load GPS From Device&nbsp;<i class="fas fa-crosshairs" />
                  </b-btn>
                  <b-form-input v-model="inspectionChanges.gpsNorth" placeholder="North" :disabled="readonly" />
                  <b-form-input v-model="inspectionChanges.gpsWest" placeholder="West" :disabled="readonly" />
                </b-form-group>
              </div>
            </b-form-row>
            <b-row class="text-center" v-if="!readonly">
              <b-col>
                <b-btn variant="primary" :disabled="!canComplete" @click="complete">
                  <span>{{ statusButtonText }}</span>
                </b-btn>
                <b-btn variant="danger" @click="remove" v-if="isOnline">
                  <span>Remove Inspection</span>
                </b-btn>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>
        <b-tab>
          <template v-slot:title>
            <span class="d-none d-md-block">Preventative Maintenance</span>
            <span class="'d-block d-md-none">PM</span>
          </template>
          <div>
            <inspection-component mode="facilities" :component="c" v-for="c in preventativeComponents" :key="c.id" :readonly="readonly" />
          </div>
        </b-tab>
        <b-tab title="Custodial">
          <inspection-component mode="facilities" :component="c" v-for="c in custodialComponents" :key="c.id" :readonly="readonly" />          
          <b-form-textarea v-model="inspectionChanges.additionalComments" rows="5" placeholder="Enter additional comments..." />
          <div class="form-row" v-if="isDirty && !readonly">
            <div class="col-md-12">
              <div class="pt-2 pull-right">
                <b-btn variant="success" size="sm" @click="save">
                  Save
                </b-btn>
                <b-btn variant="danger" size="sm" class="mx-1" @click="cancel">
                  Cancel
                </b-btn>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isEquivalent } from '../../../../helpers/globalHelpers'
import InspectionComponent from '../../common/InspectionComponent'
import { InspectionComponentCategories, InspectionStatuses, RatingTypes } from '../../common/Enums'
import { Types } from '../../../../stores/dpsaft/facilities'
import sortBy from 'lodash.sortby'

export default {
  data() {
    return {
      RoofTypes: [
        'Asphalt shingle',
        'Built-up',
        'Membrane',
        'Metal',
        'Metal & Membrane'
      ],
      inspectionChanges: { ...this.inspection }
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext', 'isOnline']),
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    },
    safeComponents() {
      return sortBy(this.inspection.components || [], 'orderBy')
    },
    preventativeComponents() {
      return this.safeComponents.filter(c => c.category == InspectionComponentCategories.CorrectiveMaintenance)
    },
    custodialComponents() {
      return this.safeComponents.filter(c => c.category == InspectionComponentCategories.Custodial)
    },
    isDirty() {
      const propertiesToCompare = ['gradeConfiguration', 'isBuildingAcademic', 'roofType', 'wasWalkthroughConducted', 'gpsNorth', 'gpsWest', 'additionalComments']
      return !isEquivalent({...this.inspectionChanges}, {...this.inspection}, propertiesToCompare)
    },
    batchId() {
      return this.$store.state.DPSAFT.Facilities.SelectedBatch.batchId
    },
    statusButtonText() {
      if (this.inspection.status == InspectionStatuses.Completed) 
        return 'Reopen Inspection'

      return 'Complete Inspection'
    },
    canComplete() {
      return this.inspection.status == InspectionStatuses.Completed || (
        this.inspection.gradeConfiguration
        && this.inspection.roofType
        && this.inspection.gpsNorth
        && this.inspection.gpsWest
        && [...(this.inspection.components || [])].every(c => {
          return c.rating > RatingTypes.NotRecorded
        })
      ) 
    }      
  },
  components: {
    InspectionComponent
  },
  methods: {
    getLocation() {
      this.$getLocation({
        enableHighAccuracy: false
      })
      .then(coordinates => {
        console.log(coordinates)
        this.$set(this.inspectionChanges, 'gpsNorth', Math.round(coordinates.lat * 10**7) / 10**7)
        this.$set(this.inspectionChanges, 'gpsWest',  Math.round(coordinates.lng  * 10**7) / 10**7)
      })
    },
    leaFormatter(lea) {
      if (this.leaDictionary[lea])
        return `${this.leaDictionary[lea].name} (${lea})`

      return lea
    },
    save() {
      let changes = {...this.inspectionChanges}
      delete changes.status
      delete changes.components
      this.$store.dispatch(Types.actions.saveInspection, { batchId: this.batchId, inspectionId: this.inspectionChanges.inspectionId, update: this.inspectionChanges })
    },
    cancel() {
      this.inspectionChanges = { ...this.inspection }
    },
    complete() {
      var status = InspectionStatuses.NotStarted
      switch(this.inspection.status) {
        case InspectionStatuses.NotStarted:
        case InspectionStatuses.InProgress:
          status = InspectionStatuses.Completed
          break
        case InspectionStatuses.Completed:
          status = InspectionStatuses.NotStarted
          break
      }
      this.$store.dispatch(Types.actions.setInspectionStatus, { batchId: this.batchId, inspectionId: this.inspectionChanges.inspectionId, status: status })
    },
    async remove(){
      if(confirm('Are you sure you want to remove the selected inspection from the inspection batch?')) {
        await this.$store.dispatch(Types.actions.deleteInspection, {batchId: this.batchId, inspectionId: this.inspectionChanges.inspectionId })
        if (this.$route.name == "mobile-inspection-page") window.history.go(-1)
      }
    }
  },
  watch: {
    inspection(newInspection) {
      this.inspectionChanges = {...newInspection}
    }
  },
  props: {
    showNavigation: {
      type: Boolean,
      default: false
    },
    inspection: {
      type: Object
    },
    readonly:{
      type: Boolean,
      default: false
    }
  }
}
</script>
<template>
  <div>
    <pdf-button :payload-factory="overviewFilters" />
    <excel-button title="AcademicsOverview" />
    <h1>Academics Overview</h1>
    <h2><lea-display :lea="selectedLea" /></h2>
    <academics-overview-widget
      @filter-updated="onFilterUpdated"
      :initial-grade-span="initialGradeSpan"
      :initial-letter-grade="initialLetterGrade"
      :initial-name="initialName"
    />
  </div>
</template>

<script>
import { PageMixin } from '../../PageMixin'
import AcademicsOverviewWidget from './AcademicsOverviewWidget.vue'

export default {
  name: 'academics-overview-page',
  data() {
    return {
      tableFilters: {}
    }
  },
  components: {
    AcademicsOverviewWidget
  },
  props: {
    initialGradeSpan: String,
    initialLetterGrade: String,
    initialName: String
  },
  methods: {
    overviewFilters() {
      return this.tableFilters
    },
    onFilterUpdated(newFilter) {
      this.tableFilters = newFilter
    }
  },
  mixins: [PageMixin]
}
</script>

<template>
  <div class="mt-3">
    <h4><i class="fas fa-file-import fa-fw mr-1" />Import Buildings</h4>
    <div v-if="!busy">
      This page is used to update the LEA Insights Building Inventory to match the CMMS Building List.  The CMMS Building List must be exported from CMMS and imported using this tool. 
      <p>The following requirements must be met for a successful import:</p>
      <ul>
        <li>Only Excel 2007 or later version files (*.xlsx) are supported. If your file is an *.xls file, you must first open it in Excel and save it as an *.xslx file.</li>
        <li>Building Numbers <i>are required</i>. Records with missing/blank Building Numbers will not be processed.</li>
        <li>Building Numbers <i>must be unique</i> within a School LEA. If two buildings have the same Building Number under the same School LEA, only one building will be inserted/updated and the information from the other will be lost. It is the user's responsibility to ensure that no two buildings under the same School LEA have the same Building Number.</li>
      </ul>
    </div>
    <div v-if="!busy">
      <b-row>
        <b-col :cols="file ? 10 : 12">
          <b-form-file inline v-model="file" :state="Boolean(file)" accept=".xlsx" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
        </b-col>
        <b-col v-if="file">    
          <b-button inline @click="addDocument">
            Upload file
          </b-button>
        </b-col>
      </b-row>      
      <b-alert v-model="showError" variant="danger" dismissible>
        {{ errorMessage }}
      </b-alert>
    </div>
    <br>
    <div>
      <b-alert variant="info" :dismissible="result" v-model="busy">
        <ul>
          <li v-if="!uploadDone">
            Uploading file {{ filename }}...
          </li>
          <li v-if="uploadDone && !result">
            Processing Rows... 
          </li>
        </ul>
        <div class="text-center" v-if="uploadDone && !result">
          <i class="fas fa-spinner fa-pulse fa-5x" />
        </div>
        <div v-if="result">
          <div>Total Rows Processed: {{ result.rowsProcessed }}</div>
          <div>New Rows Inserted: {{ result.insertedRows }}</div>
          <div>Rows Updated: {{ result.updateRows }}</div>
          <div>Bad Rows, not inserted: {{ result.errorRows }}</div>
        </div>
        <div v-if="result && result.badRows" class="mt-3 table-responsive">
          <h5>Rows that could not be inserted:</h5>
          <table class="table table-condensed">
            <tr v-for="(row, index) in result.badRows" :key="index">
              <td v-for="(cell, cellIndex) in row" :key="cellIndex">
                {{ cell }}
              </td>
            </tr>
          </table>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'buildings-import',
  data() {
    return {
      file: null,
      busy: false,
      uploadDone: false,
      result: null,
      filename: '',
      showError: false,
      errorMessage: null,
    }
  },
  methods: { 
    addDocument() {
      if (this.file) {
        this.result = null
        this.busy = true
        this.filename = this.file.name

        var formData = new FormData()
        formData.append('file', this.file, this.file.name)
      
        axios.post(`/api/dpsaft/admin/facilities/upload-buildings`, formData,
          {
            headers: { 
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
              let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
              if (percentCompleted >= 100) this.uploadDone = true
            }
          }
        ).then((response) => {
            this.result = response.data            
        }).catch(err =>{
          if(err.response){
            this.errorMessage = err.response.data
            this.showError = true
            this.file = null
          }
          else if(err.request){
            this.errorMessage = 'An error occurred sending your request to the server'
            this.showError = true
          }          
          this.busy = false
          this.uploadDone = false
        }).finally(() => this.file = null )
      }
    }   
  }
}
</script>
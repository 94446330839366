<template>
  <div class="overflow-auto">
    <table-control-widget :per-page="perPage" @filter-changed="filter = $event" @page-length-changed="perPage = $event" />
    <b-table striped
             hover
             selectable
             select-mode="single"
             :items="items"
             :filter="filter"
             @filtered="onFiltered"
             :fields="tableColumns"
             :per-page="perPage"
             :current-page="currentPage"
             :busy="parameters.isLoading">
      <div slot="table-busy" class="text-center text-danger my-2">
        <b-spinner class="align-middle" />
        <strong>Loading...</strong>
      </div>
      <div slot="coopName" slot-scope="data">
        <span v-if="data.item.currentlyUnemployed">Currently Unemployed</span>
        <span v-else>{{ data.value }}</span>
      </div>
      <div slot="districtName" slot-scope="data">
        <span v-if="data.item.currentlyUnemployed">Currently Unemployed</span>
        <span v-else>{{ data.value }}</span>
      </div>
      <div slot="schoolName" slot-scope="data">
        <span v-if="data.item.currentlyUnemployed">Currently Unemployed</span>
        <span v-else>{{ data.value }}</span>
      </div>
      <template v-slot:head(veteranTeachers)="data">
        <custom-tooltip :element-id="'veteranTeacherTooltip' + area" :label="data.label">
          A teacher is considered a Veteran Teacher if:
          <ul class="list-unstyled">
            <li>
              The sum of their age plus years of service >= 84, or
            </li>
            <li>
              They are retired, or
            </li>
            <li>
              They are identified as a Working Retiree
            </li>
          </ul>
        </custom-tooltip>
      </template>
      <template v-slot:head(retiredThisFY)="data">
        <custom-tooltip :element-id="'retireThisFYTooltip' + area" :label="data.label">
          Fiscal year is considered July 1 - June 30
        </custom-tooltip>
      </template>
      <template v-slot:head(onALP)="data">
        <custom-tooltip :element-id="'onALPTooltip' + area" :label="data.label">
          Sourced from AELS
        </custom-tooltip>
      </template>
      <template v-slot:head(newLicenses)="data">
        <custom-tooltip :element-id="'newLicensesTooltip' + area" :label="data.label">
          Educators whose licenses were first attained this school year
        </custom-tooltip>
      </template>
    </b-table>
    <table-pagination-widget :total-rows-count="rows" :filtered-rows-count="filteredRowCount" :page-length="perPage" @page-changed="currentPage = $event" />
  </div>
</template>

<script>
import { TableMixin } from '../../TableMixin'
import CustomTooltip from '../../CustomTooltip'
export default {
  data() {
    return {
      perPage: 20,
      pageLengths: [10, 20, 50, 100],
      filter: null,
      filteredRowCount: null,
      currentPage: 1
    }
  },
  props: {
    scopeLea: Object,
    scopeLevel: Number,
    items: Array,
    parameters: Object,
    minDataLevel: Number,
    showUnemployedColumn: Boolean,
    area: String
  },
  computed: {
    rows() {
      return this.items ? this.items.length : 0
    },
    fields() {
      return [
        {
          key: 'groupName',
          label: 'Group',
          sortable: true
        },
        {
          key: 'subgroupName',
          label: 'Subgroup',
          sortable: true,
          tdClass: 'subgroup',
          formatter: value => {
            return value === '|SELF|' ? null : value
          }
        },
        {
          key: 'coopLEA',
          label: 'CoOp LEA',
          sortable: true,
          dataLevel: 10
        },
        {
          key: 'coopName',
          label: 'Co-op Name',
          sortable: true,
          dataLevel: 10
        },
        {
          key: 'districtLEA',
          label: 'District LEA',
          sortable: true,
          dataLevel: 2
        },
        {
          key: 'districtName',
          label: 'District Name',
          sortable: true,
          dataLevel: 2
        },
        {
          key: 'schoolLEA',
          label: 'School LEA',
          sortable: true,
          dataLevel: 1
        },
        {
          key: 'schoolName',
          label: 'School Name',
          sortable: true,
          dataLevel: 1
        },
        {
          key: 'licenseCode',
          label: 'License Code',
          tdClass: 'licenses',
          sortable: true
        },
        {
          key: 'educatorCount',
          label: '# of Educators',
          sortable: true
        },
        {
          key: 'retiredThisFY',
          label: 'Retired this FY',
          sortable: true
        },
        {
          key: 'onALP',
          label: 'On ALP',
          sortable: true
        },
        {
          key: 'newLicenses',
          label: 'New Licenses',
          sortable: true
        },
        {
          key: 'veteranTeachers',
          label: 'Veteran Teachers',
          sortable: true
        },
        {
          key: 'avgServiceYears',
          label: 'Avg. Service Years',
          sortable: true
        },
        {
          key: 'currentlyUnemployed',
          sortable: true,
          tdClass: this.showUnemployedColumn ? '' : 'd-none',
          thClass: this.showUnemployedColumn ? '' : 'd-none'
        }
      ]
    },
    tableColumns() {
      return this.fields.filter(f => {
        if (f.key === 'subgroupName') return this.parameters.groupBySubgroup
        if (f.key === 'licenseCode') return this.parameters.groupByJobOrLicenseCode

        if (f.dataLevel) {
          return f.dataLevel >= this.minDataLevel
        }

        return true
      })
    }
  },
  mixins: [TableMixin],
  components: {
    CustomTooltip
  },
  watch: {
    scopeLea: {
      immediate: true,
      handler() {
        //console.log(`scope lea changed to ${newData ? newData.number : '<not set>'}`);
      }
    }
  },
  methods: {
    onFiltered(items, count) {
      this.filteredRowCount = count
    }
  }
}
</script>

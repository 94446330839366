import Highcharts from 'highcharts'
import { deepClone } from '@/helpers/globalHelpers'

const Races =  [
  { name: 'American Indian', altName: 'Native American / Alaska Native', field: 'nativeAmerican', color: '#9c56b8', value: 2 },
  { name: 'Asian', altName: '', field: 'asian', color: '#00bd9c', value: 3 },
  { name: 'Black / African American', altName: '', field: 'black', color: '#2c97de', value: 4 },
  { name: 'Hawaiian / Pacific Islander', altName: 'Pacific Islander / Native Hawaiian', field: 'hawaiian', color: '#f2c500', value: 5 },
  { name: 'Hispanic / Latino', altName: 'Hispanic', field: 'hispanic', color: '#90ed7d', value: 1 },
  { name: 'Two or More Races', altName: 'Multiethnic', field: 'twoOrMore', color: '#e94b35', value: 7 },
  { name: 'White', altName: '', field: 'white', color: '#e87e04', value: 6 },
]
const Subpopulations = [
  { name: 'Migrant', altName: '', field: 'migrant', color: '#90ed7d', enrollmentCollection: 'byMigrant', value: 'Y' },
  { name: 'Foster Care', altName: '', field: 'foster', enrollmentCollection: 'byFoster', value: 'Y' },
  { name: 'Homeless', altName: '', field: 'homeless', color: '#f2c500'},
  { name: 'Students with Disabilities', altName: '', field: 'disabled', enrollmentCollection: 'byHandicap', value: 'Y' },
  { name: 'English Learners', altName: '', field: 'englishLearners', color: '#e87e04' },
  { name: 'Low Income', altName: '', field: 'lowIncome' },
]
const AllDemographics = [...Races, ...Subpopulations]

function calculatePercentages(row) {
  let calced = { ...row }
  AllDemographics.map(demo => {
    if (row.total === 0) {
      calced[`${demo.field}_percent`] = null
    }
    else {
      calced[`${demo.field}_percent`] = Highcharts.numberFormat((row[demo.field] / row.total) * 100, 2)
    }
  })
  return calced
}

const RacePercentages = deepClone(Races).map(d => Object.assign(d, { field: `${d.field}_percent` })) 
const SubpopulationPercentages = deepClone(Subpopulations).map(d => Object.assign(d, { field: `${d.field}_percent` })) 

export { Races, Subpopulations, AllDemographics, calculatePercentages, RacePercentages, SubpopulationPercentages }
<template>
  <b-form>
    <div class="pull-right">
      <b-button @click="save" variant="primary">
        <span class="fas fa-save" />
        Save
      </b-button>
      <b-button @click="cancel" variant="danger" class="m-1">
        <span class="fas fa-times" />
        Cancel
      </b-button>  
    </div>
    <div style="clear: both">
      <b-form-group label="Name">
        <b-form-input v-model="$v.editPlanCategory.name.$model" placeholder="Category Name" />
      </b-form-group>
      <b-form-group label="Description">
        <b-form-input v-model="$v.editPlanCategory.description.$model" placeholder="Category Description" />
      </b-form-group>
      <b-form-group label="Program Code">
        <b-form-input v-model="$v.editPlanCategory.programCode.$model" placeholder="Program Code" />
      </b-form-group>
      <b-form-group label="Sort Order">
        <b-form-input v-model="$v.editPlanCategory.sortOrder.$model" placeholder="Sort Order" number />
      </b-form-group>
    </div>
    <plan-category-type v-model="$v.editPlanCategory.personnel.$model" @input="$v.editPlanCategory.personnel.$touch()" />
    <!-- <plan-category-type v-model="$v.editPlanCategory.programs.$model" @input="$v.editPlanCategory.programs.$touch()" /> -->
    <plan-category-type v-model="$v.editPlanCategory.materials.$model" @input="$v.editPlanCategory.materials.$touch()" />
    <plan-category-type v-model="$v.editPlanCategory.services.$model" @input="$v.editPlanCategory.services.$touch()" />
  </b-form>
</template>

<script>
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import { AdminTypes } from '@/modules/esa/stores/admin.js'
import { Types } from '@/modules/esa/stores'
import { mapState } from 'vuex'
import { deepClone } from '@/helpers/globalHelpers'
import PlanCategoryType from './PlanCategoryType.vue'

Vue.use(Vuelidate)

export default {
  data() {
    return {
      editPlanCategory: {
        name: null,
        description:null,
        sortOrder: null,
        programCode: null,
        personnel: {
          description: "Personnel",
          enabled: false,
          items:[],
          codes: []
        },
        materials: {
          description: "Materials",
          enabled: false,
          items:[],
          codes: []
        },
        services: {
          description: "Services",
          enabled: false,
          items:[],
          codes: []
        }
      }
    }
  },
  validations: {
      editPlanCategory: {
        sortOrder: {},
        name: {},
        description: {},
        programCode: {},
        personnel: {
          items:{},
          codes: {}
        },
        materials: {
          items:{},
          codes: {}
        },
        services: {
          items:{},
          codes: {}
        }
      }
  },
  mounted() {
    window.addEventListener('beforeunload', this.unload);
  },
  beforeRouteLeave (to, from, next) {
    if (Object.keys(to.query) < 1 && this.$v.$anyDirty) {
      const answer = window.confirm('Do you really want to leave? You have unsaved changes.')
      if (answer) {
        window.removeEventListener('beforeunload', this.unload)
        next()
      } else {
        next(false)
      }
    }
    else {
      next()
    }
  },
  computed:{
    ...mapState(AdminTypes.path, [AdminTypes.state.selectedPlanCategory]),
    ...mapState(Types.path, [Types.state.esaFiscalYear])
  },
  methods:{
    save(){
      var promise = null
      if(this.mode == 'edit') {
        promise = this.$store.dispatch(AdminTypes.actions.updatePlanCategory,{ planCategory: {
            id: this.editPlanCategory.id,
            fiscalYear: this.editPlanCategory.fiscalYear,
            name: this.editPlanCategory.name,
            programCode: this.editPlanCategory.programCode,
            description: this.editPlanCategory.description,
            sortOrder: this.editPlanCategory.sortOrder,
            types: [this.editPlanCategory.personnel, this.editPlanCategory.materials, this.editPlanCategory.services]
          }
        })
      }
      else {
        promise = this.$store.dispatch(AdminTypes.actions.createPlanCategory, { planCategory: {
            fiscalYear: this.esaFiscalYear,
            name: this.editPlanCategory.name,
            programCode: this.editPlanCategory.programCode,
            description: this.editPlanCategory.description,
            sortOrder: this.editPlanCategory.sortOrder,
            types: [this.editPlanCategory.personnel, this.editPlanCategory.materials, this.editPlanCategory.services]
          }
        })
      }
      promise.then(() => {
        this.$v.$reset()        
        this.cancel()
      })
    },
    cancel(){
      this.$store.commit(AdminTypes.mutations.setSelectedPlanCategory, null)
      this.$router.push({name: 'plan-category-list'})
      .catch((err) => console.log(err))
    },
    unload(event) {
      if (this.$v.$dirty) {
        event.preventDefault();
        event.returnValue = '';
      }
    }
  },
  watch: {
    selectedPlanCategory: {
      immediate: true,
      deep: true,
      handler() {
        if (this.selectedPlanCategory)
          this.editPlanCategory = deepClone(this.selectedPlanCategory)
      }
    }
  },
  components: {
    PlanCategoryType
  },
  props: {
    mode: {
      type: String,
      default: 'new'
    }
  }
}
</script>
<template>
  <b-tabs card class="p-0 bg-light">
    <b-tab title="Latest Extract Status" active>
      <b-alert :show="newJobId && dismissCountdown" variant="success" dismissible @dismiss-count-down="countdownChanged">
        Build for {{ newJobId }} Enqueued
      </b-alert>
      <district-extract-monitor-widget :load-data="loadData" @days-changed="reloadProcesses" @build-queued="delayReload" />  
    </b-tab>
    <b-tab title="Extract Table Counts">
      <district-extract-table-count-widget />
    </b-tab>
  </b-tabs>
</template>

<script>
import { mapState } from 'vuex'
import { PageMixin } from '../../PageMixin'
import DistrictExtractMonitorWidget from './DistrictExtractMonitorWidget.vue'
import DistrictExtractTableCountWidget from './DistrictExtractTableCountWidget'

export default {
  mixins: [PageMixin],
  components: {
    DistrictExtractMonitorWidget,
    DistrictExtractTableCountWidget
  },
  data(){
    return {
      loadData: null,
      days: 1,
      dismissSecs: 10,
      dismissCountdown: 0
    }
  },
  computed: {
    ...mapState('adminModule', ['recentBatchProcesses','newJobId'])
  },
  mounted(){
    this.loadData = Promise.all([
      this.$store.dispatch('adminModule/getRecentBatches', { days: this.days }),
      this.$store.dispatch('adminModule/getDistrictDataTableCounts')
    ])
  },
  methods:{
    reloadProcesses(days){
      if(days){
        this.loadData = this.$store.dispatch('adminModule/getRecentBatches', { days: days })
          .then(() =>this.dismissCountdown = this.dismissSecs)
      }
    },
    delayReload(){
      var count = [...this.recentBatchProcesses].length
      var attempts = 0
      var delay = setInterval(function(){
        this.reloadProcesses(this.days)
        attempts++
        if(count != this.recentBatchProcesses.length || attempts >= 10){
          clearInterval(delay)
        }
      }.bind(this), 3000)
    },
    countdownChanged(dismissCountdown){
      this.dismissCountdown = dismissCountdown
    }
  }
}
</script>
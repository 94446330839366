<template>
  <div>
    <form>
      <reconfiguration-code-selector :item="item" :purpose="ReconfigurationCodePurpose.EditDistrict" :operation="operation" />

      <div class="form-group row mb-0">
        <label class="col-md-2 col-form-label">LEA:</label>
        <div class="col-md-10">
          <span class="form-control-plaintext">{{ item.selectedLea }} - {{ item.currentValues.leaName }}</span>
        </div>
      </div>
      <div class="form-group row mb-0">
        <label class="col-md-2 col-form-label">Additional Comments:</label>
        <div class="col-md-10">
          <div class="form-control-plaintext">
            {{ item.additionalComments }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              Current Values
            </div>
            <div class="card-body">
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">Name:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.currentValues.leaName }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">Address:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.currentValues.address }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">City:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.currentValues.city }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">State:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.currentValues.state }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">Zip:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.currentValues.zip }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">
              New Values
            </div>
            <div class="card-body">
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">Name:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.leaName }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">Address:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.address }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">City:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.city }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">State:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.state }}</span>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-3 col-form-label">Zip:</label>
                <div class="col-md-9">
                  <span class="form-control-plaintext">{{ item.zip }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { RequestItemMixin } from '../RequestItemMixIn'

export default {
  name: 'change-district-lea-item',
  data() {
    return {
    }
  },
  components: {},
  mounted() {},
  computed: {
    ...mapState('toolsModule/leaAssignment', ['schools']),
    selectedLeaObject() {
      return this.schools.find(l => l.leaNumber == this.item.selectedLea) || {}
    }
  },
  methods: {},
  props: {
    item: Object
  },
  mixins: [RequestItemMixin]
}
</script>

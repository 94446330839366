<template>
  <div>
    <h3>
      Documents
    </h3>
    <document-list :request-id="requestId" :display-only="displayOnly || !allowEdit" />
    <br>
    <div v-if="!displayOnly && allowEdit">
      <new-document :request-id="requestId" />
    </div>
  </div>
</template>

<script>
import DocumentList from './DocumentList.vue'
import NewDocument from './NewDocument.vue'

const editableStates = [0,1]

export default {
  props: {
    requestId: {
      type: String,
      required: true
    },
    displayOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    allowEdit() {
      const currentStatus = this.$store.state.toolsModule.leaAssignment.currentRequest.requestStatus
      return editableStates.includes(currentStatus)
    }
  },
  components: {
    DocumentList,
    NewDocument
  }
}
</script>
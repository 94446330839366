<template lang="html">
  <div class="d-inline">
    <button v-b-modal.share-report-modal class="btn btn-primary float-right mr-1 d-print-none" v-bind="$attrs">
      <i class="fas fa-share-alt" />&nbsp;Share
    </button>
    <div>
      <b-modal id="share-report-modal" size="lg" hide-footer>
        <template slot="modal-title">
          Share Report
        </template>
        <div class="text-center justify-content-center">
          <b-form-group label="Shared Report Type" label-class="font-weight-bold">
            <b-button-group>
              <b-button v-if="outputTypes.includes(OutputTypes.Link)" :pressed="reportOptions.outputType == OutputTypes.Link" variant="outline-primary" @click="$set(reportOptions, 'outputType', OutputTypes.Link)">
                <i class="fa fas fa-link mr-1" />Link
              </b-button>
              <b-button v-if="outputTypes.includes(OutputTypes.Pdf)" :pressed="reportOptions.outputType == OutputTypes.Pdf" variant="outline-info" @click="$set(reportOptions, 'outputType', OutputTypes.Pdf)">
                <i class="far fa-file-pdf mr-1" />PDF
              </b-button>
              <b-button v-if="outputTypes.includes(OutputTypes.Excel)" :pressed="reportOptions.outputType == OutputTypes.Excel" variant="outline-success" @click="$set(reportOptions, 'outputType', OutputTypes.Excel)">
                <i class="far fa-file-excel mr-1" />Excel
              </b-button>
            </b-button-group>
          </b-form-group>
          <b-form-group label="Report Name" label-class="font-weight-bold">
            <b-form-input v-model="reportOptions.reportName" placeholder="Enter a report name" />
          </b-form-group>
          <b-form-group label="Receipient(s)" label-class="font-weight-bold">
            <span class="text-muted text-small">Multiple email addresses can be provided by using a semi-colon to separate them</span>
            <b-form-input v-model="reportOptions.recipients" placeholder="Enter recipient email address" />
          </b-form-group>
          <b-form-group label="Message for Receipient(s)" label-class="font-weight-bold">
            <b-form-textarea v-model="reportOptions.message" placeholder="Enter a message for the recipient (optional)" rows="3" />
          </b-form-group>
          <div>
            <animated-button :promise-resolver="shareReport" class="btn btn-primary d-print-none mx-1" :disabled="!reportOptions.recipients || !reportOptions.reportName">
              <i class="fas fa-paper-plane" />&nbsp;Share
            </animated-button>
            <b-button variant="warning" @click="$bvModal.hide('share-report-modal')">
              Cancel
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import AnimatedButton from '@/components/AnimatedButton.vue'
import ApiService from '@/services/ApiService'
import ExcelService from '@/services/ExcelService'

const apiService = new ApiService()

export const OutputTypes = {
  Link: 0,
  Pdf: 1,
  Excel: 2
}

export default {
  data() {
    return {
      actionPromise: null,
      reportOptions: {
        reportName: null,
        message: null,
        recipients: null,
        additionalData: null,
        outputType: OutputTypes.Link
      },
      OutputTypes,
    }
  },
  components: {
    AnimatedButton,
  },
  computed: {
    sharedReport() {
      return {
        ...this.reportOptions,
        reportType: this.reportType,
        lea: this.lea || this.selectedLea.number,
        originalUrl: window.location.href,
      }
    }
  },
  methods: {
    shareReport() {
      const report = this.sharedReport
      const self = this
      if (report.outputType == OutputTypes.Excel && !self.excelUrl) {
        return self.getExcel().then(content => {
          report.excelContent = content
          self.saveSharedReport(report)
        })
      }
      else {
        return self.saveSharedReport(report)
      }
    },
    saveSharedReport(report) {
      const self = this
      return apiService.Post('/api/sharedreport', report).then(share => {
        if (share.outputType == OutputTypes.Pdf)
          return self.getPdf(share.sharedReportId)
        else if (share.outputType == OutputTypes.Excel && self.excelUrl)
          return self.getExcel(share.sharedReportId)
      }).then(() => {
        self.$bvModal.hide('share-report-modal')
        return self.$bvToast.toast('Shared', {
          title: `Report shared`,
          variant: 'success',
          solid: true
        })
      })
    },
    async getExcel(sharedReportId) {
      const excelService = new ExcelService()
      if (this.excelUrl) {
        return apiService.Post(`${this.excelUrl}?sharedReportId=${sharedReportId}`, null)
      }
      else {
        const lea = this.$store.state.globalModule.leaContext.SelectedLea.number || ''
        const workbookName = `${this.reportType}-${lea}`
        var workbook = await excelService.createWorkbook(workbookName)

        var worksheetData = []

        //get any exportable data from the parent control
        this.getTableData(this.$parent, worksheetData)

        this.$parent.$children.forEach(child => {
          this.getTableData(child, worksheetData)
          this.traverseChildren(child, worksheetData)
        })
        worksheetData.forEach(async sheet => {
          await excelService.addWorksheet(workbook, sheet.name.replace('/', '').substr(0, 31), sheet.items)
        })
        return excelService.getBase64String(workbook)
      }
    },
    getPdf(sharedReportId) {
      var payload = null
      if (this.payloadFactory) {
        payload = this.payloadFactory()
      }
      var sharedReportIdQuery = `&sharedReportId=${sharedReportId}`
      const resolvedLea = this.lea || this.selectedLea
      //if we have a payload, then we will POST to our URL with the payload
      const url = this.pdfUrl ? 
        `${this.pdfUrl}${sharedReportIdQuery}` : 
        document.location.protocol + "//" + document.location.hostname + ':' + document.location.port
        + document.location.pathname + `?response-format=pdf&lea=${resolvedLea.number}${sharedReportIdQuery}${this.additionalQueryStringParams}`;
      return apiService.Post(url, payload)
    }
  },
  props: {
    payloadFactory: Function,
    lea: {
      type: Object,
      default: null,
    },
    additionalQueryStringParams: {
      type: String,
      default: '',
    },
    outputTypes: {
      type: Array,
      default: () => [OutputTypes.Link, OutputTypes.Pdf, OutputTypes.Excel]
    },
    reportType: {
      type: String,
    },
    excelUrl: {
      type: String,
    },
    pdfUrl: {
      type: String,
    },
  }
}
</script>

<template>
  <div>
    <table-control-widget :per-page="tableOptions.perPage" :allow-length-change="false" @filter-changed="tableOptions.filter=$event" @page-length-changed="tableOptions.perPage=$event" placeholder-text="Filter..." />
    <b-table sticky-header responsive small striped hover borderless :items="items" :fields="columns" :per-page="tableOptions.perPage" :current-page="tableOptions.currentPage" :filter="tableOptions.filter" @filtered="tableOptions.filteredRowCount = $event.length">
      <template v-for="column in columns" v-slot:[`head(${column.key})`]="data">
        <slot :name="`head(${column.key})`" v-bind="data">
          {{ data.label }}
        </slot>
      </template>
      <template v-for="column in columns" v-slot:[`cell(${column.key})`]="data">
        <slot :name="`cell(${column.key})`" v-bind="data">
          <div :key="column.key">
            <span v-if="column.key.includes('HTML')" v-html="data.value" />
            <span v-else>{{ data.value }}</span>
          </div>
        </slot>
      </template>
    </b-table>
    <table-pagination-widget class="small" :page-length="tableOptions.perPage" :filter="tableOptions.filter" :total-rows-count="rowCount" :filtered-rows-count="tableOptions.filteredRowCount" @page-changed="tableOptions.currentPage=$event" />
  </div>
</template>

<script>
import TableControlWidget from './shared/TableControlWidget'
import TablePaginationWidget from './shared/TablePaginationWidget'

export default {
  data() {
    return {
      tableOptions: {
        perPage: this.perPage || this.firstColumnSticky ? 7 : 8,
        filter: null,
        currentPage: 1,
        filteredRowCount: null
      }
    }
  },
  computed: {
    rowCount() {
      return this.items.length
    },
    columns() {
      var cols = this.rowCount ? Object.keys(this.items[0]).map((item) => { return { key: item, sortable: true, thClass: "nowrap" }}) : []
      if (this.firstColumnSticky && cols[0]) {
        cols[0].stickyColumn = true
        cols[0].tdClass = "nowrap"
      }      
      cols.forEach(c => {
        if (c.key.includes('HTML')) {
          c.label = ''
          c.html = true
        }
      })
      return cols
    }
  },
  props: {
    perPage: {
      type: Number,
      default: null
    },
    items: {
      type: Array
    },
    title: {
      type: String
    },
    firstColumnSticky: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TableControlWidget,
    TablePaginationWidget
  }
}
</script>
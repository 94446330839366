<template>
  <div class="row d-print-none" v-if="allOptions.length > 0">
    <div :class="`col-md-12 ${borderClass} p-2`">
      <b-form-group label="Comment:" v-if="anyRequireComment"> 
        <b-form-textarea v-model="comment" placeholder="Please provide a comment..." />
      </b-form-group>
      <div>
        <animated-button 
          :button-class="`btn btn-${getVariant(state)} mr-1 float-left`" 
          :promise-resolver="transitionDispatch.bind(null, state.code)" 
          :disabled="state.requiresComment && !comment" 
          data-toggle="tooltip" data-placement="top" title="Comment is required"
          v-for="state in returnStateOptions" 
          :key="state.code">
          {{ getActionText(state) }}
        </animated-button>
        <animated-button 
          :button-class="`btn btn-${getVariant(state)} ml-1 ${advanceButtonClass}`" 
          :promise-resolver="transitionDispatch.bind(null, state.code)" 
          :disabled="state.requiresComment && !comment" 
          v-for="state in advanceStateOptionsWithoutAssignment" 
          :key="state.code">
          {{ getActionText(state) }}
        </animated-button>
        <b-dropdown :variant="getVariant(state)" :class="advanceButtonClass" :text="getActionText(state)" v-for="state in advanceStateOptionsSupportingAssignment" :key="'assign' + state.code">
          <b-dropdown-item class="pl-0" @click="transitionDispatch(state.code, { assignedToUserId: user.userId, assignedToName: user.fullName })" v-for="user in availableStates.assignableUsers[state.code]" :key="user.userId">
            <i class="fa-fw fas fa-user" /> {{ user.fullName }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import AnimatedButton from '@/components/AnimatedButton'

export default {
  data() {
    return {
      comment: null,
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    anyRequireComment() {
      return this.allOptions.some(s => s.requiresComment)
    },
    allOptions() {
      return this.returnStateOptions.concat(this.advanceStateOptions)
    },
    returnStateOptions() {
      return (this.availableStates.returnStates || []).map(code => this.stateMap.get(code))
    },
    advanceStateOptions() {
      return (this.availableStates.advanceStates || []).map(code => this.stateMap.get(code))
    },
    advanceStateOptionsWithoutAssignment() {
      return this.advanceStateOptions.filter(state => !this.advanceStateOptionsSupportingAssignment.map(s => s.code).includes(state.code))
    },
    advanceStateOptionsSupportingAssignment() {
      const statesWithUsers = Object.keys(this.availableStates.assignableUsers || {})
      return (this.availableStates.advanceStates || [])
        .map(code => this.stateMap.get(code))
        .filter(state => statesWithUsers.includes(state.code.toString()))
    },
    borderClass() {
      return (this.anyRequireComment && this.useCard) ? 'card' : ''
    }
  },
  props: {
    document: {
      type: Object,
      default: () => { return {} }
    },
    status: {
      type: Number,
      required: true,
    },
    availableStates: {
      type: Object,
      required: true,
    },
    stateMap: {
      type: Map,
      required: true,
    },
    identifiers: {
      type: Object,
      required: true,
    },
    transitionAction: {
      type: String,
      required: true,
    },
    advanceButtonClass: {
      type: String,
      default: 'float-right'
    },
    canWithdraw: {
      type: Boolean,
      default: false
    },
    useCard: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    transitionDispatch(targetState, additionalData = {}) {
      const self = this
      return this.$store.dispatch(this.transitionAction, { ...this.identifiers, state: targetState, comment: this.comment, ...additionalData })
        .then(() => {
          self.comment = null
        })
    },
    getActionText(state) {
      if (state && typeof state.action === 'function') {
        return state.action(this.document, this.userContext)
      }
      return state.action
    },
    getVariant(state) {
      if (state && typeof state.variant === 'function') {
        return state.variant(this.document, this.userContext)
      }
      return state.variant
    },
  },
  components: {
    AnimatedButton,
  }
}
</script>

<template>
  <div v-bind="$attrs" id="plan-files">
    <b-alert v-model="showError" variant="danger" dismissible>
      {{ errorMessage }}
    </b-alert>
    <b-table responsive small striped hover :items="files" :fields="columns" show-empty empty-text="No files uploaded">
      <template v-slot:head(action)>
        <div class="text-right" v-if="!readonly">
          <b-button variant="secondary" @click="onAddItem()" :disabled="busy">
            <i class="fas fa-file-upload fa-fw text-white" /> Upload a File
          </b-button>
        </div>
      </template>
      <template v-slot:cell(categoryId)="data">
        <b-link v-if="data.item.categoryId" :href="`#category-${data.item.categoryId}`">
          {{ categoryOptions.find(c => c.id == data.item.categoryId).name }}
        </b-link>
        <span v-else>
          {{ categoryOptions.find(c => c.id == data.item.categoryId).name }}
        </span>
      </template>
      <template v-slot:cell(filename)="data">
        <b-button variant="link" class="p-0" @click="onDownloadItem(data.item)">
          {{ data.value }}
        </b-button>
      </template>
      <template v-slot:cell(action)="data">
        <div class="text-right">
          <b-button size="sm" variant="info" class="mr-2" title="Download" :disabled="downloading" @click="onDownloadItem(data.item)">
            <i class="fa fa-download fa-fw text-white" />
          </b-button>
          <b-button size="sm" variant="danger" @click="onRemoveItem(data)" title="Delete" v-if="!readonly">
            <i class="fa fa-trash fa-fw text-white" />
          </b-button>
        </div>
      </template>
    </b-table>
    <b-alert variant="info" :show="downloading">
      File download in progress
    </b-alert>
    <b-modal id="uploadModal" title="Upload Plan File" size="lg" @ok="uploadFile">
      <b-alert variant="info small" class="2" show>
        <i class="fas fa-info-circle" /> Supporting files must be in PDF, Word, or Excel format.
      </b-alert>
      <b-input-group prepend="Select File">
        <b-form-file inline v-model="file" accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" :state="Boolean(file)" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
      </b-input-group>
      <br>
      <b-input-group prepend="File Category">
        <b-form-select :options="categoryOptions" :state="Boolean(fileCategory != null)" v-model="fileCategory" text-field="name" value-field="id" />
      </b-input-group>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { Types } from '../../stores/index'
import moment from 'moment'
import prettyBytes from 'pretty-bytes'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      columns: [
        {
          label: 'Category',
          key: 'categoryId',
          sortable: true,
        },
        {
          label: 'Name',
          key: 'filename',
          sortable: true,
        },
        {
          label: 'Type',
          key: 'mimeType',
          formatter: (_1, _2, row) => {
            return row.filename.split('.').pop().toUpperCase()
          },
          sortable: true,
        },
        {
          label: 'Size',
          key: 'size',
          sortable: true,
          formatter: (value) => { return prettyBytes(value) }
        },
        { 
          label: 'Upload Time',
          key: 'createdDate',
          sortable: true,
          formatter: (value) => { return moment(value).format("MM/DD/YYYY h:mm a") }
        },
        {
          key: 'action',
          thClass: 'text-right'
        },
      ],
      busy: false,
      uploadDone: false,
      result: null,
      file: null,
      filename: '',
      fileCategory: null,
      showError: false,
      errorMessage: null,
      localFiles: this.files,
      downloading: false
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.planCategories]),
    categoryOptions() {
      return [{ id: null, name: "-- Select File Category --" }, { id: 0, name: "Other" }, ...this.planCategories]
    }
  },
  methods: {
    onRemoveItem(item) {
      var self = this
      this.$bvModal.msgBoxConfirm(`Are you sure you want to delete this file?`)
        .then((result) => {
          if (result) {
            this.$store.dispatch(Types.actions.deletePlanFile, { lea: self.selectedLea.number, id: self.id, fileName: item.item.filename })
          }
        })
    },
    onAddItem() {
      this.$bvModal.show('uploadModal')
    },
    onDownloadItem(item) {
      this.downloading = true
      this.$store.dispatch(Types.actions.downloadPlanFile, { lea: this.selectedLea.number, id: this.id, fileName: item.filename })
        .then(() => this.downloading = false)
    },
    uploadFile(bvModalEvt) {      
      bvModalEvt.preventDefault()
      if (this.file && this.fileCategory != null) {
        var self = this
        this.result = null
        this.busy = true
        this.filename = this.file.name

        var formData = new FormData()
        formData.append('file', this.file, this.file.name)
        formData.append('fileCategory', this.fileCategory)
        axios.post(`/api/esa/${this.selectedLea.number}/plan/${this.id}/file?fileName=${this.filename}`, formData,
          {
            headers: { 
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
              let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
              if (percentCompleted >= 100) this.uploadDone = true
            }
          }).then(plan => {
            this.$store.commit(Types.mutations.setPlan, plan.data)
            this.$bvModal.hide('uploadModal')
            self.uploadDone = true
          }).catch(err => {
            if(err.response) {
              self.errorMessage = err.response.data
              self.showError = true
              this.$bvModal.hide('uploadModal')
            }
            else if(err.request) {
              self.errorMessage = 'An error occurred sending your request to the server'
              self.showError = true
            }
            self.uploadDone = false
          })        
        self.busy = false
      }
    }
  },
  props: {
    files: {
      type: Array,
      default: () => []
    },
    id: {
      type: Number,
      required: true
    },
    readonly: {
      type: Boolean,
      default: () => true
    }
  }
}
</script>

<style>
#plan-files{
  scroll-margin-top: 200px;
}
</style>
<template>
  <div>
    <h4>
      Add LEA Pair
    </h4>
    <div class="form">
      <div class="form-group">
        <label>Select Feeder (7A) School</label>
        <lea-selector v-model="feederLea" :source-leas="feederOptions" />
      </div>
      <div class="form-group">
        <label>Grade Range</label>
        <b-form-input type="text" readonly v-model="feederGradeRange" />
      </div>
    </div>
    <div class="form-group">
      <label>Select Paired (7B) School</label>
      <lea-selector v-model="pairedLea" :source-leas="pairedOptions" />
    </div>
    <div class="form-group text-right mt-4">
      <b-button variant="primary" class="mr-2" @click="save">
        Ok
      </b-button>
      <b-button variant="secondary" @click="$router.go(-1)">
        Cancel
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LeaSelector from '../../../shared/LeaSelector'
export default {
  name: 'add-feeder-pair',
  data() {
    return {
      feederLea: null,
      pairedLea: null
    }
  },
  computed: {
    ...mapState('globalModule', ['leaContext']),
    feederGradeRange() {
      if (this.feederLea) {
        return this.leaContext.LeaDictionary[this.feederLea].gradesServed
      }

      return null
    },
    feederOptions() {
      var primarySchools = new Array()
      Object.keys(this.leaContext.LeaDictionary).forEach(k => {
        var i = this.leaContext.LeaDictionary[k]

        if (i.type === 1) {
          primarySchools.push({ leaNumber: i.number, name: i.name })
        }
      })

      return primarySchools
    },
    pairedOptions() {
      var secondary = new Array()
      if (this.feederLea) {
        Object.keys(this.leaContext.LeaDictionary).forEach(i => {
          var item = this.leaContext.LeaDictionary[i]
          if (item && item.type === 1 && i !== this.feederLea && item.number.substr(0, 4) === this.feederLea.substr(0, 4)) {
            secondary.push({ leaNumber: item.number, name: item.name })
          }
        })
      }

      return secondary
    }
  },
  methods: {
    save() {
      return this.$store
        .dispatch('toolsModule/leaAssignment/saveFeederPair', {
          feederLeaNumber: this.feederLea,
          pairedLeaNumber: this.pairedLea,
          feederGradeRange: this.feederGradeRange
        })
        .then(() => this.$router.go(-1))
    }
  },
  components: {
    LeaSelector
  }
}
</script>
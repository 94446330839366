import Vue from "vue";

const SummaryExportOptionsWidget = Vue.extend({
    name: "summary-export-options-widget",
    data() {
        return {
            exportSettings: {
                dataOptions: [
                    {value: 0, text: "(Select type of Shortage data to export)"},
                    {value: 1, text: "Area Shortages"},
                    {value: 2, text: "Job Shortages"},
                    {value: 3, text: "Both Area & Job Shortages"}
                ],
                groupingOptions: [
                    {value: 1, text: "Group by Subgroup"},
                    {value: 2, text: "Group by Job/License Code"}
                ]
            },
        }
    },
    /* html */
    template: `
    <div class='card mb-2'>
        <div class='card-header'>
        <strong><b-form-checkbox v-model="value.include">{{title}}</b-form-checkbox></strong>
        </div>
        <div class='card-body'>
            <b-form-select class="mb-2" v-model="value.dataType" :disabled="dataTypeDisabled" :options="exportSettings.dataOptions"></b-form-select>
            <p class='validation-error' v-if="dataTypeError">You must select a data option</p>
            <b-form-checkbox v-model="value.groupBySubgroup" :disabled="groupingDisabled" inline>Group by Subgroup</b-form-checkbox>
            <b-form-checkbox v-model="value.groupByLicenseOrJobCode" :disabled="groupingDisabled" inline>Group by License/Course Code</b-form-checkbox>
            <b-form-checkbox v-model="value.combineGroupsOnSameSheet" :disabled="combineDisabled" inline>Combine groupings on same sheet</b-form-checkbox>
        </div>
    </div>
     `,
    props: {
        title: String,
        value: Object,
        lea: Object
    },
    methods: {

    },
    computed: {
        combineDisabled() {
            return !(this.value.include && (this.value.groupBySubgroup && this.value.groupByLicenseOrJobCode))
        },
        groupingDisabled() {
            return !(this.value.include && this.value.dataType > 0)
        },
        dataTypeDisabled() {
            return !(this.value.include)
        },
        dataTypeError() {
            return this.value.include && this.value.dataType == 0
        }
    },
    components: {},
    watch: {}
});

export {
    SummaryExportOptionsWidget
};
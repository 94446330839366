<template>
  <div>
    <b-row>
      <b-col cols="8">
        <h3>{{ fiscalYear+1989 }}/{{ fiscalYear - 10 }} Accountability administration </h3>
      </b-col>
      <b-col cols="4" class="text-right">
        <b-button-group>
          <b-button :href="'/tools/lea-assignment/lea-crosswalk/' + fiscalYear" variant="primary">
            <i class="far fa-file-word fa-fw" />LEA Crosswalk Document
          </b-button>
          <!-- <b-button variant="outline-primary" v-b-modal.email><i class="far fa-envelope fa-fw" /></b-button> -->
        </b-button-group>
      </b-col>
    </b-row>
    <b-tabs card>
      <b-tab title="Feeder / Paired Schools" active>
        <feeder-pairs />
      </b-tab>
      <b-tab title="School Accountability Status">
        <lea-accountability-status />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>

import FeederPairs from './feederPairs.vue'
import LeaAccountabilityStatus from './leaAccountabilityStatus.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'accountability-administration',
  data() {
    return {
     
    }
  },
  computed: {
    ...mapGetters('toolsModule/leams', ['fiscalYear'])
  },
  mounted() {
    
    return this.$store.dispatch('toolsModule/leams/getLEAMSFiscalYears')
    .then(()=>this.$store.dispatch('toolsModule/leams/getLEAMSRecords', { fy: this.fiscalYear }))
    .then(()=>this.$store.dispatch('toolsModule/leaAssignment/getFeederPairs'))
    .then(()=> this.$store.dispatch('toolsModule/leaAssignment/getLeaAccountabilityStatusRecords'))
  },
  components: {
    FeederPairs,
    LeaAccountabilityStatus
  }
}

</script>
<template>
  <b-form>
    <b-form-group id="input-group-1" label="District:" label-for="input-1">
      <span>{{ districtLEA }} - {{ districtName }}</span>
    </b-form-group>

    <b-form-group id="input-group-3" label="Inspector:" label-for="input-3">
      <b-form-select id="input-inspectorId" placeholder="Select Inspector" v-model="inspectorId" value-field="userId" text-field="fullName" :options="transportationStaff.inspectors">
        <template v-slot:first>
          <b-form-select-option :value="null">
            Unassigned
          </b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>

    <b-form-group id="input-group-4" label="Trainer:" label-for="input-4">
      <b-form-select id="input-trainerId" placeholder="Select Trainer" v-model="trainerId" value-field="userId" text-field="fullName" :options="transportationStaff.trainers">
        <template v-slot:first>
          <b-form-select-option :value="null">
            Unassigned
          </b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>

    <b-button variant="primary" :disabled="!isValid" @click="save">
      Save
    </b-button>
    <b-button variant="danger" class="mx-1" @click="$emit('on-cancel')">
      Cancel
    </b-button>
  </b-form>
</template>


<script>
import { mapState } from 'vuex'

export default {
  name: 'edit-staff',
  data() {
    return {
      districtLEA: null,
      inspectorId: null,
      trainerId: null
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState('DPSAFT', ['transportationStaff']),
    isValid() {
      return true
    },
    districtName() {
      return this.$store.getters['globalModule/leaDictionary'][this.districtLEA]?.name
    },
  },
  methods: {
    save() {
      this.$store.dispatch('DPSAFT/Admin/updateDistrictTransportationStaff', {
          districtLEA: this.districtLEA,
          inspectorId: this.inspectorId,
          trainerId: this.trainerId
        })
        .then(() => this.$emit('on-save'))
    }
  },
  props: {
    item: Object
  },
  watch: {
    item: {
      immediate: true,
      handler(newItem) {
        this.districtLEA = newItem.districtLEA
        this.inspectorId = newItem.inspectorId || null
        this.trainerId = newItem.trainerId || null
      }
    }
  }
}
</script>

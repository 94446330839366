import Vue from 'vue'
import ExpiringLicenseWidget from './ExpiringLicenseWidget.vue'
import { PageMixin } from '../../PageMixin'

const ExpiringLicensesPage = Vue.extend({
    name: 'expiring-licenses-page',
    mixins: [PageMixin],
    data() {
        return {
        }
    },
    components: {
        ExpiringLicenseWidget
    }
})

export { ExpiringLicensesPage }
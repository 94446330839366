<template>
  <div>
    <info-panel />
    <div v-if="leaType != LeaTypes.Coop" class="card outer-card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <growth-filter />
          </div>
        </div>
        <student-growth :load-data="loadData" />
      </div>        
    </div>
    <div v-else class="alert alert-warning">
      No data available at Coop level. Please select a school or district to view report
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { PageMixin } from '../../../PageMixin'
import InfoPanel from './InfoPanel.vue'
import GrowthFilter from '../GrowthFilter.vue'
import StudentGrowth from './StudentGrowth.vue'

export default {
  name: 'question2',
  mixins: [PageMixin],
  data() {
    return {
      loadData: Promise.resolve(false)
    }
  },
  components: {
    GrowthFilter,
    StudentGrowth,
    InfoPanel
  },
  computed: {
    ...mapState('academicsModule/growth', ['grade', 'subject', 'subgroup']),
    fiscalYear() {
      return this.$store.state.academicsModule.growth.fiscalYear
    }
  },
  methods: {
    loadGrowth(lea) {
      this.loadData = Promise.all([
        this.$store.dispatch('academicsModule/growth/getLeaGrowthSummary', {
          lea: lea.number
        }),
        this.$store.dispatch('academicsModule/growth/getStudentGrowthCurrent', {
          lea: lea.number
        })
      ])
    }
  },
  watch: {
    grade() {
      this.loadGrowth(this.selectedLea)
    },
    subject() {
      this.loadGrowth(this.selectedLea)
    },
    subgroup() {
      this.loadGrowth(this.selectedLea)
    },
    selectedLea: {
      immediate: true,
      handler(newLea) {
        this.loadGrowth(newLea)
      }
    },
    fiscalYear() {
      this.loadGrowth(this.selectedLea)
    }
  }
}
</script>

<style>
.card-body {
  background-color: inherit;
}
</style>
<template lang="html">
  <div>
    <div>
      <pdf-button v-if="isSchoolOrDistrict" />
      <excel-button title="Class Size" v-if="isSchoolOrDistrict" />
      <h1>
        <custom-tooltip element-id="teacher-label" :content="'Source: <a href=' + credits.SFA.href + '>' + credits.SFA.text + '</a>'" /> 
        Class Size
      </h1>
      <h2>
        <lea-display :lea="selectedLea" />
      </h2>
    </div>
    <div>
      <violation-filter label="Class Size" />
    </div>
    <div class="card bg-light mt-3">
      <a class="anchor" id="classsize" />
      <div class="card-header">
        Class Size
      </div>
      <div class="card-body">
        <div class="card-group">
          <div v-if="isSchoolOrDistrict" class="w-100">
            <class-size-table :load-data="loadData" :selected-lea="selectedLea" />
          </div>
          <div class="alert alert-warning mt-4 w-100" v-else>
            Select a district or school to view Class Sizes.
          </div>
        </div>
      </div>
    </div>
    <div>
      <span class="pull-right">
        Source: <a :href="credits.SFA.href">{{ credits.SFA.text }}</a>
      </span>
    </div>
  </div>
</template>

<script>
import { PageMixin } from '../../PageMixin'
import ClassSizeTable from './ClassSizeTable.vue'
import { LeaTypes } from '../../../helpers/leaTypes'
import { credits } from '../../../helpers/credits'
import ViolationFilter from '../ViolationFilter.vue'

export default {
  mixins: [PageMixin],
  data() {
    return {
      credits,
      loadData: null
    }
  },
  computed: {
    selectedLea() { return this.$store.state.globalModule.leaContext.SelectedLea },
    isSchoolOrDistrict() {
      return this.selectedLea && (this.selectedLea.type == LeaTypes.School || this.selectedLea.type == LeaTypes.District)
    }
  },
  components: {
    ClassSizeTable,
    ViolationFilter
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler() {
        if (this.isSchoolOrDistrict) {
          this.loadData = this.$store.dispatch('scheduleModule/getClassSizes', { lea: this.selectedLea.number })
        }
      }
    }
  }
}
</script>

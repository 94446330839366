<template>
  <div>
    <b-container>
      <b-row :no-gutters="true">
        <b-col>
          <label class="font-weight-bold">District</label>
        </b-col>
        <b-col cols="5">
          <span>{{ districtName }}</span>
        </b-col>
        <b-col>
          <label class="font-weight-bold">Date:</label>
        </b-col>
        <b-col cols="3">
          <span>{{ SelectedBatchSummary.date }}</span>
        </b-col>
        <hr class="w-100">
        <b-col cols="2">
          <label class="font-weight-bold">Type</label>
        </b-col>
        <b-col>
          <label class="font-weight-bold">Lift</label>
        </b-col>
        <b-col cols="2">
          <label class="font-weight-bold">Fuel</label>
        </b-col>
        <b-col cols="2">
          <label class="font-weight-bold">Use</label>
        </b-col>
        <b-col cols="5">
          <label class="font-weight-bold">Body</label>
        </b-col>
        <div class="w-100" />
        <b-col>
          <label class="font-weight-bold">A</label>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.typeACount }}</span>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.typeALiftCount }}</span>
        </b-col>
        <b-col>
          <label class="font-weight-bold">Diesel</label>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.fuelDieselCount }}</span>
        </b-col>
        <b-col>
          <label class="font-weight-bold">Route</label>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.useRouteCount }}</span>
        </b-col>
        <b-col cols="2">
          <label class="font-weight-bold">Blue Bird</label>
        </b-col>
        <b-col cols="3">
          <span>{{ SelectedBatchSummary.bodyBlueBirdCount }}</span>
        </b-col>
        <div class="w-100" />
        <b-col>
          <label class="font-weight-bold">B</label>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.typeBCount }}</span>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.typeBLiftCount }}</span>
        </b-col>
        <b-col>
          <label class="font-weight-bold">Gas</label>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.fuelGasCount }}</span>
        </b-col>
        <b-col>
          <label class="font-weight-bold">Spare</label>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.useSpareCount }}</span>
        </b-col>
        <b-col cols="2">
          <label class="font-weight-bold">Thomas</label>
        </b-col>
        <b-col cols="3">
          <span>{{ SelectedBatchSummary.bodyThomasCount }}</span>
        </b-col>
        <div class="w-100" />
        <b-col>
          <label class="font-weight-bold">C</label>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.typeCCount }}</span>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.typeCLiftCount }}</span>
        </b-col>
        <b-col>
          <label class="font-weight-bold">Other</label>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.fuelOtherCount }}</span>
        </b-col>
        <b-col>
          <label class="font-weight-bold">Activity</label>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.useActivityCount }}</span>
        </b-col>
        <b-col cols="2">
          <label class="font-weight-bold">AmTran</label>
        </b-col>
        <b-col cols="3">
          <span>{{ SelectedBatchSummary.bodyAmTranCount }}</span>
        </b-col>
        <div class="w-100" />
        <b-col>
          <label class="font-weight-bold">D</label>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.typeDCount }}</span>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.typeDLiftCount }}</span>
        </b-col>
        <b-col cols="2" offset="4">
          <label class="font-weight-bold">International</label>
        </b-col>
        <b-col cols="3">
          <span>{{ SelectedBatchSummary.bodyInternationalCount }}</span>
        </b-col>
        <div class="w-100" />
        <b-col cols="2" offset="7">
          <label class="font-weight-bold">Ward</label>
        </b-col>
        <b-col cols="3">
          <span>{{ SelectedBatchSummary.bodyWardCount }}</span>
        </b-col>
        <div class="w-100" />
        <b-col cols="2">
          <label class="font-weight-bold">Transmission</label>
        </b-col>
        <b-col cols="2" offset="1">
          <label class="font-weight-bold">Brakes</label>
        </b-col>
        <b-col cols="2" offset="2">
          <label class="font-weight-bold">Other</label>
        </b-col>
        <b-col cols="3">
          <span>{{ SelectedBatchSummary.bodyOtherCount }}</span>
        </b-col>
        <div class="w-100" />
        <b-col>
          <label class="font-weight-bold">Auto</label>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.transmissionAutoCount }}</span>
        </b-col>
        <b-col offset="1">
          <label class="font-weight-bold">Air</label>
        </b-col>
        <b-col cols="8">
          <span>{{ SelectedBatchSummary.brakesAirCount }}</span>
        </b-col>
        <div class="w-100" />
        <b-col>
          <label class="font-weight-bold">Standard</label>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.transmissionStandardCount }}</span>
        </b-col>
        <b-col offset="1">
          <label class="font-weight-bold">Hydraulic</label>
        </b-col>
        <b-col cols="8">
          <span>{{ SelectedBatchSummary.brakesHydraulicCount }}</span>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="2">
          <label class="font-weight-bold">Buses in Fleet</label>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.busesInFleet }}</span>
        </b-col>
        <b-col cols="3">
          <label class="font-weight-bold">Beginning Sticker Number</label>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.beginningStickerNumber }}</span>
        </b-col>
        <b-col cols="2" class="text-success">
          <label class="font-weight-bold">No Issue</label>
        </b-col>
        <b-col cols="3" class="text-success">
          <span>{{ SelectedBatchSummary.totalPoints.noIssueBuses }}</span>
        </b-col>
        <div class="w-100" />
        <b-col cols="2">
          <label class="font-weight-bold">Buses in Batch</label>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.busesInBatch }}</span>
        </b-col>
        <b-col cols="3">
          <label class="font-weight-bold">Ending Sticker Number</label>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.endStickerNumber }}</span>
        </b-col>
        <b-col cols="2" class="text-warning">
          <label class="font-weight-bold">Total Minors</label>
        </b-col>
        <b-col cols="3" class="text-warning">
          <span>{{ SelectedBatchSummary.totalPoints.minorPoints }} points on {{ SelectedBatchSummary.totalPoints.minorIssueBuses }} buses.</span>
        </b-col>
        <b-col cols="2">
          <label class="font-weight-bold">Buses Completed</label>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.busesCompleted }}</span>
        </b-col>

        <b-col cols="3">
          <label class="font-weight-bold">Total Stickers Used</label>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.stickersUsed }}</span>
        </b-col>
        <b-col cols="2" class="text-warning">
          <label class="font-weight-bold">Total Majors</label>
        </b-col>
        <b-col cols="3" class="text-warning">
          <span>{{ SelectedBatchSummary.totalPoints.majorPoints }} points on {{ SelectedBatchSummary.totalPoints.majorIssueBuses }} buses.</span>
        </b-col>  
        <b-col cols="2">
          <label class="font-weight-bold">Buses Skipped</label>
        </b-col>
        <b-col>
          <span>{{ SelectedBatchSummary.busesSkipped }}</span>
        </b-col>
        <b-col cols="2" offset="4" class="text-danger">
          <label class="font-weight-bold">Grounded</label>
        </b-col>
        <b-col cols="3" class="text-danger">
          <span>{{ SelectedBatchSummary.totalPoints.groundedBuses }}</span>
        </b-col>
      </b-row>
      <hr class="w-100">
      <batch-component-point-list :components="SelectedBatchSummary.insideComponentPoints" category="Inside" category-letter="A" />
      <hr class="w-100">
      <batch-component-point-list :components="SelectedBatchSummary.outsideComponentPoints" category="Outside" category-letter="B" />
      <hr class="w-100">
      <batch-component-point-list :components="SelectedBatchSummary.engineCompartmentPoints" category="Engine Compartment" category-letter="C" />
      <hr class="w-100">
      <batch-component-point-list :components="SelectedBatchSummary.underneathPoints" category="Underneath" category-letter="D" /> 
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BatchComponentPointList from './BatchComponentPointList'

export default {
  data() {
    return {}
  },
  methods: {  
    totalPoints(components){
      return components.map(c => c.value).reduce(function(total,c) { 
        return {item1: c.item1 + total.item1, item2: c.item2 + total.item2} 
      })
    }},
  props: {},
  computed: {
    ...mapState('globalModule', ['userContext', 'leaContext']),
    ...mapState('DPSAFT/Trans', ['SelectedBatchSummary']),
    districtName() {
      if (this.SelectedBatchSummary && this.SelectedBatchSummary.districtLea) {
        return this.leaContext.LeaDictionary[this.SelectedBatchSummary.districtLea].name
      }
      return 'N/A'
    }
  },
  components: {
    BatchComponentPointList
  }
}
</script>

import dlpList from './dlpList'

export const surveyTemplate = {
  sections: [
    {
      name: 'District Ready for Learning Planning',
      target: 'readyForLearningPlanning',
      icon: '/Content/icons/committee.png',
      faq: '<b>Form District COVID-19 Ready for Learning Committee</b> with membership from district, each building including licensed or classified, medical personnel and community.  <b>Communication plan</b> for work of Guiding Coalition with staff/parents/community, and involve members of the <b>medical community</b> to the fullest extent possible.',
      sections: [
        {
          name: 'Ready for Learning Committee',
          target: 'readyForLearningCommittee',
          questions: [
            {
              id: 'hasCommittee',
              text: 'Has your district formed a Ready For Learning commitee?',
              component: 'QuestionYesno'
            },

            {
              id: 'committeeComposition',
              text: 'My district\'s committee is comprised of: (check all that apply)',
              component: 'QuestionMultipleSelection',
              choices: ['Medical Personnel', 'Licensed Staff', 'Classified Staff', 'Community Member', 'Parents', 'District Administrator', 'Other'],
              allowOther: true
            },
            {
              id: 'committeeHasMetTimes',
              text: 'To date, how many times did the committee meet?',
              component: 'QuestionSingleSelection',
              choices: ['At least one time', '1-3 meetings', '4-5 meetings', 'More than 5 meetings']
            },
            {
              id: 'committeeDecisionsCommunicated',
              text: 'Committee decisions are communicated with the public in the following manner: (check all that apply)',
              component: 'QuestionMultipleSelection',
              choices: ['Email', 'Meetings are live-streamed', 'Updates shared during monthly board meetings', 'Local Media coverage', 'Social Media', 'Facebook Live', 'School App', 'School Website', 'Videos', 'Other'],
              allowOther: true
            }
          ]
        },
        {
          name: 'Ready for Learning Re-Entry Plan',
          target: 'readyForLearningReEntryPlan',
          questions: [
            {
              id: 'hasDistrictPublishedRFLOperationalPlan',
              text: 'Has your district published a Ready for Learning Operational Plan (Re-Entry)?',
              component: 'QuestionYesnoDetails',
              textDetails: 'If yes, provide the hyperlink/URL for the Ready for Learning Operational Plan (Re-Entry).',
              indeterminateAnswer: '<i class="fas fa-tasks fa-fw"></i> In Progress',
              indeterminateAnswerText: 'In progress',
              detailsOnValues: [ 1 ]
            },
            {
              id: 'planApprovedByLocalSchoolBoard',
              text: 'Has the plan been approved by your district\'s local school board?',
              indeterminateAnswer: '<i class="fas fa-tasks fa-fw"></i> Not Yet',
              indeterminateAnswerText: 'Not yet',
              component: 'QuestionYesno'
            }
            // {
            //   id: 'planApprovedByLocalSchoolBoard',
            //   text: 'Has the plan been approved by your district\'s local school board?',
            //   component: 'QuestionSingleSelection',
            //   choices: ["Yes", "No", "Not Yet"]
            // }
          ]
        }
      ]      
    },
    {
      name: 'District Support Plan Items',
      target: 'districtSupportPlan',
      icon: '/Content/icons/support-plan.png',
      faq: 'District Support Plan Resource: <a target="_blank" href="https://docs.google.com/document/d/1uGaLVwSyvwD8S9VAFGEN7Ss1LVODPoSBoyqtA9owqhA/edit?usp=sharing">https://docs.google.com/document/d/1uGaLVwSyvwD8S9VAFGEN7Ss1LVODPoSBoyqtA9owqhA/edit?usp=sharing</a>',
      sections: [
        {
          name: 'Guaranteed and Viable Curriculum',
          target: 'guaranteedAndViableCurriculum',
          questions: [
            {
              id: 'willUseApprovedDigitalProvider',
              text: 'My district will utilize an approved digital provider for digital content.',
              component: 'QuestionYesno'
            },
            {
              id: 'identifyDigitalContentProvider',
              text: 'Identify digital content provider:',
              component: 'QuestionTagSelection',
              choices: dlpList,
              tagPrompt: 'Select digital content providers...'
            },
            {
              id: 'districtWillCreateLMSContent',
              text: 'My district will create content for the LMS.',
              component: 'QuestionYesno'
            }
          ]
        },
        {
          name: 'Unfinished Learning',
          target: 'unifinishedLearning',
          questions: [
            {
              id: 'teachersWillUtilizePlaybook',
              text: 'My district\'s teachers will utilize the ADE Playbook to address unfinished learning.',
              component: 'QuestionYesno'
            },
            {
              id: 'districtHasExpandedUseOfDiagnosticAssessments',
              text: 'My district has expanded the use of diagnostic assessments grades K-8.',
              component: 'QuestionYesno',
            },
            {
              id: 'enterAssessments',
              text: 'If yes, choose assessments:',
              component: 'QuestionMultipleSelection',
              choices: ['Curriculum Associates: i-Ready', 'Istation: ISIP', 'NWEA: MAP Growth', 'Renaissance: Star', 'Other'],
              allowOther: true,
              otherText: 'Describe Other:'
            },
            {
              id: 'bringTeachersTogetherForPlanning',
              text: 'Will you bring teachers together by grade/content for planning?',
              component: 'QuestionYesnoDetails',
              textDetails: 'If yes, when is that scheduled?',
              detailsOnValues: [1]
            }
          ]
        },
        {
          name: 'Learning Management System',
          target: 'learningManagementSystem',
          questions: [
            {
              id: 'districtLMS',
              text: 'My district has selected a primary LMS: (check all that apply)',
              component: 'QuestionMultipleSelection',
              choices: ['Schoology', 'Edmodo', 'Canvas', 'Buzz/Lincoln Learning', 'Google Classroom', 'SeeSaw', 'Other'],
              allowOther: true
            }
          ]
        },
        {
          name: 'Training on use of LMS',
          target: 'trainingOnUseOfLMS',
          questions: [
            {
              id: 'districtHasProvidedTeacherLMSTraining',
              text: 'My district has provided training to teachers on the use of the LMS.',
              component: 'QuestionYesno',
            },
            {
              id: 'districtWillProvideTeacherLMSTrainingDates',
              text: 'My district will provide LMS training to teachers on the following dates: ',
              component: 'QuestionFreeText'
            },
            {
              id: 'districtHasProvideParentLMSTraining',
              text: 'My district has provided training to parents/students on the use of the LMS.',
              component: 'QuestionYesno',
            },
            {
              id: 'districtHasProvideParentLMSTrainingHow',
              text: 'My district has provided training to parents/students on the use of the LMS using:',
              component: 'QuestionMultipleSelection',
              choices: ['Zoom', 'Teams', 'Skype', 'Recorded Lessons', 'Other Virtual Delivery'],
              allowOther: true
            },
            {
              id: 'districtWillProvideParentLMSTrainingDates',
              text: 'My district will provide LMS training to parents/students on the following dates: ',
              component: 'QuestionFreeText'
            }
          ]
        },
        {
          name: 'Training on Blended Learning',
          target: 'trainingOnBlendedLearning',
          questions: [
            {
              id: 'districtHasProvidedTeacherBLTraining',
              text: 'My district has provided training to teachers for Blended Learning.',
              component: 'QuestionYesno',
            },
            {
              id: 'districtWillProvideTeacherBLTrainingDates',
              text: 'My district will provide Blended Learning training to teachers on the following dates: ',
              component: 'QuestionFreeText'
            },
            {
              id: 'districtHasProvideParentBLTraining',
              text: 'My district has provided training to parents/students for Blended Learning.',
              component: 'QuestionYesno',
            },
            {
              id: 'districtHasProvideParentBLTrainingHow',
              text: 'My district has provided training to parents/students for Blended Learning using:',
              component: 'QuestionMultipleSelection',
              choices: ['Zoom', 'Teams', 'Skype', 'Recorded Lessons', 'Other Virtual Delivery'],
              allowOther: true
            },
            {
              id: 'districtWillProvideParentBLTrainingDates',
              text: 'My district will provide Blended Learning training to parents/students on the following dates: ',
              component: 'QuestionFreeText'
            },
            {
              id: 'blendedLearningTrainingProvider',
              text: 'Enter your Blended Learning training provider.',
              component: 'QuestionFreeText'
            }
          ]
        },
        {
          name: 'Communication',
          target: 'communication',
          questions: [
            {
              id: 'haveDesignatedMethodOfCommunication',
              text: 'Do you have a designated method of communication for staff, parents, and students?',
              component: 'QuestionYesno',
            },
            {
              id: 'howAreYouCommunicating',
              text: 'How are you communicating changes in routines and procedures to parents, students, and the community? (check all that apply)',
              component: 'QuestionMultipleSelection',
              choices: ['Email', 'Phone', 'Flyer', 'Website', 'Social Media', 'Facebook Live', 'School App', 'School Website', 'Videos', 'Other'],
              allowOther: true,
            },
            {
              id: 'whatInstructionOptions',
              text: 'What options will be provided for parents in addition to onsite instruction?',
              component: 'QuestionMultipleSelection',
              choices: ['5-days per week onsite classes', 'Virtual', 'Hybrid/Split Schedule', 'Other'],
              allowOther: true
            },
            {
              id: 'whatPercentNotFullTime',
              text: 'What percent of students have chosen an option that is not full time onsite instruction? (please choose only one)',
              component: 'QuestionMultipleSelection',
              choices: ['0-5%', '5-10%', '10-20%', 'More than 20%'],
              allowOther: true,
              otherText: 'Identify percentage if more than 20%:'
            },
            {
              id: 'whatPercentNotFullTimeIsElementary',
              text: 'Regarding the total percent, what percent represents elementary students? (K-6)',
              component: 'QuestionSingleSelection',
              choices: ['0-5%', '5-10%', '10-20%', 'More than 20%']
            },
            {
              id: 'whatPercentNotFullTimeIsSecondary',
              text: 'Regarding the total percent, what percent represents secondary students? (7-12)',
              component: 'QuestionSingleSelection',
              choices: ['0-5%', '5-10%', '10-20%', 'More than 20%']
            },
            {
              id: 'methodsToSolicitFeedback',
              text: 'What methods are being used to solicit parent and community feedback on school reentry issues?',
              component: 'QuestionMultipleSelection',
              choices: ['Surveys', 'Group Meetings', 'Presentations', 'Other'],
              allowOther: true
            }
          ]
        }
      ]
    },
    {
      name: 'Items for Consideration in Planning for Re-Entry',
      target: 'planningForReEntry',
      icon: '/Content/icons/re-entry.png',
      questions: [
        {
          id: 'haveRevisedCalendar',
          text: 'Have you revised your calendar to reflect the new start date of school?',
          component: 'QuestionYesno',
          hidden: true
        },
        {
          id: 'teacherContractStartDates',
          text: 'Have you determined teacher contract start dates?',
          textDetails: 'What are your teacher contract start dates?',
          detailsOnValues: [1],
          component: 'QuestionYesnoDetails',
          hidden: true
        },
        {
          id: 'areLookingToMovePDDays',
          text: 'Are you looking to move any PD days that were scheduled during the year to before students begin?',
          component: 'QuestionYesno',
          hidden: true
        },
        {
          id: 'areLookingToProvideAdditionalContractDays',
          text: 'Are you looking to provide teachers additional contract days to prepare curriculum, lessons, learning the LMS and other tools, and strategies for blended learning?',
          component: 'QuestionYesno',
          hidden: true
        },
        {
          id: 'areLookingToRepurposeHolidays',
          text: 'Are you looking to repurpose previously scheduled holidays to virtual learning days?',
          component: 'QuestionYesno',
          hidden: true
        }
      ],
      sections: [
        {
          name: 'Student Connectivity',
          target: 'studentConnectivity',
          questions: [
            {
              id: 'identifiedStudentsWithNoInternetAccess',
              text: 'Do you know which students do not have internet access?',
              component: 'QuestionYesno'
            },
            {
              id: 'internetAccessOptions',
              text: 'For students for which home connectivity is not possible, what are options for accessing the internet? How are you going to distribute work to them? (check all that apply)',
              component: 'QuestionMultipleSelection',
              choices: ['Community hot spots', 'Flash drives', 'Pick and Drop Paper Print-outs', 'Hot Spot Devices', 'Other'],
              allowOther: true
            }
          ]
        },
        {
          name: 'Masks',
          target: 'masks',
          questions: [
            {
              id: 'developedMaskPolicies',
              text: 'Have you developed policies that require students to wear masks?',
              component: 'QuestionYesno'
            }
          ],
          hidden: true
        },
        {
          name: 'Substitutes',
          target: 'substitutes',
          questions: [
            {
              id: 'substituteApproach',
              text: 'Will you approach securing substitutes differently than in the past?',
              textDetails: 'Explain:',
              detailsOnValues: [1],
              component: 'QuestionYesnoDetails',
            }
          ],
          hidden: true
        },
        {
          name: 'Continuity of Operations',
          target: 'continuityOfOperations',
          questions: [
            {
              id: 'orderOfSuccession',
              text: 'What is your order of succession if several on the administrative team become ill?',
              component: 'QuestionFreeText'
            },
            {
              id: 'crossTraining',
              text: 'Have you cross trained key employees within the business office?',
              component: 'QuestionYesno'
            },
            {
              id: 'techCoordinatorDocumented',
              text: 'Has your technology coordinator documented information regarding passwords, etc?',
              component: 'QuestionYesno'
            }
          ],
          hidden: true
        },
        {
          name: 'Entering and Existing Buildings',
          target: 'enteringAndExitingBuildings',
          questions: [
            {
              id: 'lessenCongregatingBuildings',
              text: 'Have you made changes to lessen the likelihood of congregating when entering and exiting buildings?',
              component: 'QuestionYesno'
            }
          ],
          hidden: true
        },
        {
          name: 'Restroom Procedures',
          target: 'restroomProcedures',
          questions: [
            {
              id: 'lessenCongregatingRestrooms',
              text: 'Are you structuring use of the restroom to provide for less congregating?',
              component: 'QuestionYesno'
            }
          ],
          hidden: true
        },
        {
          name: 'Needed Technology',
          target: 'neededTechnology',
          questions: [
            {
              id: 'sufficientDevices',
              text: 'Do you have sufficient devices to start school on August 24, 25, or 26 in order to meet the Ready for Learning Plan (Re-Entry)?',
              component: 'QuestionYesno'
            },
            {
              id: 'haveInternetAccessSchool',
              text: 'Do students at your district have the required access to internet (connectivity) at School?',
              component: 'QuestionYesno'
            },
            {
              id: 'haveInternetAccessHome',
              text: 'Do students at your district have the required access to internet (connectivity) at Home?',
              component: 'QuestionYesno'
            },
            {
              id: 'percentOfStudentsLackingRequiredAccess',
              text: 'What percentage of students DO NOT have internet access (connectivity) at Home?',
              component: 'QuestionSingleSelection',
              choices: ['0-10%', '10-20%', '20-30%', '30-40%', 'More than 40%', 'Not Applicable']
            },
            {
              id: 'useESSERFundsTechnology',
              text: 'Did you use ESSER funds to purchase additional technology for the 2020-2021 SY?',
              component: 'QuestionSingleSelection',
              choices: ['No', 'Yes: $0-$50,000', 'Yes: $50,000-$100,000', 'Yes: $100,000-$250,000', 'Yes: $250,000-$500,000', 'Yes: more than $500,000']
            }
          ]
        },
        {
          name: 'Personal Protective Equipment (PPE)',
          target: 'ppe',
          questions: [
            {
              id: 'adequateMasks',
              text: 'Do you have adequate masks to begin school on August 24, 25, or 26?',
              component: 'QuestionSingleSelection',
              choices: ['Yes', 'No', 'Not Sure']
            },
            {
              id: 'adequateGloves',
              text: 'Do you have adequate gloves to begin school on August 24, 25, or 26?',
              component: 'QuestionSingleSelection',
              choices: ['Yes', 'No', 'Not Sure']
            },
            {
              id: 'adequateHandSanitizer',
              text: 'Do you have adequate hand sanitizer to begin school on August 24, 25, or 26?',
              component: 'QuestionSingleSelection',
              choices: ['Yes', 'No', 'Not Sure']
            },
            {
              id: 'adequateCleaningSanitizer',
              text: 'Do you have adequate cleaning sanitizer to begin school on August 24, 25, or 26?',
              component: 'QuestionSingleSelection',
              choices: ['Yes', 'No', 'Not Sure']
            },
            {
              id: 'adequateFoggers',
              text: 'Do you have adequate foggers to begin school on August 24, 25, or 26?',
              component: 'QuestionSingleSelection',
              choices: ['Yes', 'No', 'Not Sure']
            },
            {
              id: 'adequatePPESemester',
              text: 'Do you have adequate Personal Protective Equipment to complete the first semester?',
              component: 'QuestionSingleSelection',
              choices: ['Yes', 'No', 'Not Sure']
            },
            {
              id: 'adequatePPENotSureExplanation',
              text: 'If you answered "Not Sure" to any of the above questions, please explain.',
              component: 'QuestionFreeText'
            },
            {
              id: 'describeFaceCoveringsPlan',
              text: 'Describe plans for supplying face coverings. (check all that apply)',
              component: 'QuestionMultipleSelection',
              choices: ['District provided', 'Student provided', 'Teacher provided'],
              allowOther: false
            },
            {
              id: 'useESSERFundsPPE',
              text: 'Did you use purchase Personal Protective Equipment with ESSER funds?',
              component: 'QuestionSingleSelection',
              choices: ['No', 'Yes: $0-$50,000', 'Yes: $50,000-$100,000', 'Yes: $100,000-$250,000', 'Yes: $250,000-$500,000', 'Yes: more than $500,000']
            }
          ]
        },
        {
          name: 'School Nurse',
          target: 'schoolNurse',
          questions: [
            {
              id: 'nursePPE',
              text: 'Does the school have the additional Personal Protective Equipment for nurses? (Gown, shield, etc)',
              component: 'QuestionYesno'
            },
            {
              id: 'handleSickStudents',
              text: 'How are you going to handle sick students? Where will they stay until picked up?',
              component: 'QuestionFreeText'
            }
          ]
        },
        {
          name: 'Screening Procedures',
          target: 'screeningProcedures',
          questions: [
            {
              id: 'screeningAdults',
              text: 'Are you screening adults as they arrive?',
              component: 'QuestionYesno'
            },
            {
              id: 'screeningStudents',
              text: 'Are you screening students as they arrive?',
              component: 'QuestionYesno'
            }
          ]
        },
        {
          name: 'Student Presence on Campus',
          target: 'studentPresenceOnCampus',
          hidden: true
        },
        {
          name: 'Virtual Schooling Option',
          target: 'virtualSchoolingOption',
          hidden: true
        },
        {
          name: 'Blended Learning Option',
          target: 'blendedLearningOption',
          hidden: true
        },
        {
          name: 'Remote Schooling Option',
          target: 'remoteSchoolingOption',
          hidden: true
        },
        {
          name: 'Special Education',
          target: 'specialEducation',
          hidden: true
        },
        {
          name: 'English Language Learners',
          target: 'englishLanguageLearners',
          hidden: true
        },
        {
          name: 'Students Receiving Dyslexia Services',
          target: 'studentsReceivingDyslexiaServices',
          hidden: true
        },
        {
          name: 'Mental Health Support Services',
          target: 'mental Health Support Services',
          hidden: true
        },
        {
          name: 'Employee Quarantine',
          target: 'employeeQuarantine',
          hidden: true
        },
        {
          name: 'Visitor Policy',
          target: 'visitorPolicy',
          hidden: true
        },
        {
          name: 'Parent Pick Up Procedure',
          target: 'parentPickupProcedure',
          hidden: true
        },
        {
          name: 'Plexiglass in Offices',
          target: 'plexiglassInOffices',
          hidden: true
        },
        {
          name: 'Scheduling',
          target: 'scheduling',
          hidden: true
        },
        {
          name: 'Student Arrival',
          target: 'studentArrival',
          questions: [
            {
              id: 'whereEatingBreakfast',
              text: 'Where are students eating breakfast? (check all that apply)',
              component: 'QuestionMultipleSelection',
              choices: ['Cafeteria', 'Classrooms'],
              allowOther: false
            }
          ],
          hidden: true
        },
        {
          name: 'Dismissal',
          target: 'dismissal',
          hidden: true
        },
        {
          name: 'Resource, Materials, and Supplies Management',
          target: 'resourceMaterialsSuppliesManagement',
          hidden: true
        },
        {
          name: 'Cafeteria',
          target: 'cafeteria',
          hidden: true
        },
        {
          name: 'Buses',
          target: 'buses',
          hidden: true
        },
        {
          name: 'Classrooms',
          target: 'classrooms',
          hidden: true
        },
        {
          name: 'Playground',
          target: 'playground',
          hidden: true
        },
      ]
    },
    {
      name: 'Survey Phase 2',
      target: 'phase2',
      icon: '/Content/icons/guidance.png',
      questions: [
        {
          id: 'phase2howOftenCommitteeHasMet',
          text: 'How have you engaged the district’s Ready for Learning Committee since the start of school?',
          component: 'QuestionSingleSelection',
          choices: ['Committee meets regularly', 'Committee meets periodically', 'Committee has met once', 'Committee is informed of updates']
        },
        {
          id: 'phase2hasDistrictUpdatedR4LPlan',
          text: 'Has your district updated it’s Ready for Learning Plan since the start of school?',
          component: 'QuestionYesno'
        },
        {
          id: 'phase2hasDistrictUpdatedR4LPlanWasFeedbackUsed',
          text: 'If yes, how was student and parent feedback utilized in the planning? (Mark all that apply)',
          component: 'QuestionMultipleSelection',
          choices: [
            'Parents are members of the Ready for Learning Committee',
            'Students are members of the Ready for Learning Committee',
            'Parent feedback was collected and analyzed',
            'Student feedback was collected and analyzed'
          ]
        },
        {
          id: 'phase2hasDistrictModifiedSchedulesOrOnsiteOperations',
          text: 'Besides virtual pivots in response to COVID-19 outbreaks, has your district modified daily schedules or onsite operations?',
          component: 'QuestionYesno'
        },
        {
          id: 'phase2howHasDistrictModifiedSchedulesOrOnsiteOperations',
          text: 'If yes, how has your district modified daily schedules or onsite operations?',
          component: 'QuestionMultipleSelection',
          choices: ['Early dismissal', 'Virtual Day option', 'Cohort Scheduling', 'Virtual hubs or pods created onsite', 'Flexible schedules for onsite students to learn both in-person and remotely',
                    'Changes to virtual options in response to student engagement', 'Schedule modifications allowing fewer courses taken in more concentrated amounts of time (4½ week blocks as opposed to 9 weeks)',
                    'Other (describe)'],
          allowOther: true,
          otherText: 'Identify other modifications:'
        },
        {
          id: 'phase2howAreYouCommunicatingChangesToTheCommunity',
          text: 'What has been the most effective means for communicating changes in routines and procedures to parents, students, and the community?',
          component: 'QuestionSingleSelection',
          choices: ['Newsletters', 'Social Media', 'Minutes from meetings', 'Local media coverage', 'Direct outreach to parents']
        },
        {
          id: 'phase2identifyPrimaryDigitalContentProvider',
          text: 'Identify your districts primary digital content provider.',
          component: 'QuestionTagSelection',
          choices: dlpList,
          tagPrompt: 'Select primary digital content provider...',
          limit: 1
        },
        {
          id: 'phase2ratePrimaryDigitalContentProviderSatisfaction',
          text: 'Please rate your level of satisfaction with your primary digital content provider.',
          component: 'QuestionSingleSelection',
          choices: ['Very satisfied', 'Somewhat satisfied', 'Not satisfied']
        },
        {
          id: 'phase2identifyOtherContentProviders',
          text: 'Identify any other digital content providers you use in addition to the primary.',
          component: 'QuestionTagSelection',
          choices: dlpList,
          tagPrompt: 'Select digital content providers...'
        },
        {
          id: 'phase2identifyPrimaryLMS',
          text: 'Identify your districts primary LMS.',
          component: 'QuestionSingleSelection',
          choices: ['Schoology', 'Edmodo', 'Canvas', 'Buzz/Lincoln Learning', 'Google Classroom', 'SeeSaw', 'Other']
        },
        {
          id: 'phase2ratePrimaryLMSProviderSatisfaction',
          text: 'Please rate your level of satisfaction with your primary LMS.',
          component: 'QuestionSingleSelection',
          choices: ['Very satisfied', 'Somewhat satisfied', 'Not satisfied']
        },
        {
          id: 'phase2identifyOtherLMS',
          text: 'Identify any other LMS you use in addition to the primary.',
          component: 'QuestionMultipleSelection',
          choices: ['Schoology', 'Edmodo', 'Canvas', 'Buzz/Lincoln Learning', 'Google Classroom', 'SeeSaw', 'Other']
        },
        {
          id: 'phase2percentOfStudentsNotOnsite',
          text: 'What percent of students have are currently participating in an option that is not full-time onsite instruction?',
          component: 'QuestionFreeText'
        },
        {
          id: 'phase2percentVirtualAtRiskBasedOnNotEngaging',
          text: 'What percent of virtual learners would be identified as "at risk" based on not engaging in virtual learning as expected (not logging in or virtually attending at least 20%, based on your district’s attendance policy) of the time in the first nine weeks?',
          component: 'QuestionFreeText'
        },
        {
          id: 'phase2percentVirtualAtRiskBasedOnNotCompletingAssignments',
          text: 'What percent of virtual learners would be identified as "at risk" based on not completing assignments or submitting poor quality work?',
          component: 'QuestionFreeText'
        },
        {
          id: 'phase2percentVirtualAtRiskBasedOnLackOfParentalEngagement',
          text: 'What percent of virtual learners would be identified as "at risk" based on lack of parent engagement?',
          component: 'QuestionFreeText'
        },
        {
          id: 'phase2percentVirtualElementary',
          text: 'What percent of virtual learners are in elementary grades?',
          component: 'QuestionFreeText'
        },
        {
          id: 'phase2percentVirtualElementaryAtRisk',
          text: 'What percent of virtual learners in elementary grades are "at risk"?',
          component: 'QuestionFreeText'
        },
                {
          id: 'phase2percentVirtualSecondary',
          text: 'What percent of virtual learners are in secondary grades?',
          component: 'QuestionFreeText'
        },
        {
          id: 'phase2percentVirtualSecondaryAtRisk',
          text: 'What percent of virtual learners in secondary grades are "at risk"?',
          component: 'QuestionFreeText'
        },
        {
          id: 'phase2whatBestDescribesRemoteLearningOption',
          text: 'Which best describes your virtual option for students?',
          component: 'QuestionSingleSelection',
          choices: [
            'Students are expected to be in class virtually for synchronous instruction based on a traditional daily school schedule with a virtual only teacher.',
            'Students are expected to be in class virtually for synchronous instruction based on a traditional daily school schedule with a teacher who is teaching virtual and in-person students.',
            'Synchronous instruction taught at specified times during the week that is less structured.',
            'Asynchronous instruction with scheduled face-to-face meetings weekly.',
            'Asynchronous instruction that is mostly student self-paced with check-ins as needed.'
          ]
        },
        {
          id: 'phase2areVirtualStudentsTaughtWithSameContent',
          text: 'Are virtual students are taught using the same content as onsite students?',
          component: 'QuestionYesno'
        },
        {
          id: 'phase2areVirtualStudentsTaughtUsingDedicated',
          text: 'Are virtual students are taught using dedicated online, digital content and LMS?',
          component: 'QuestionYesno'
        },
        {
          id: 'phase2teacherExpectations',
          text: 'Which statement best describes district expectiations for teachers?',
          component: 'QuestionSingleSelection',
          choices: [
            'Teachers teach both in-person and virtual-remote students. In-person and remote students are taught at same time.',
            'Teachers teach both in-person and virtual-remote students. In-person teaching is recorded and uploaded for virtual/remote students.',
            'Teachers teach both in-person and virtual-remote students. Daily time is dedicated for teaching Virtual-remote students when not interacting with on-site learners.',
            'Dedicated teachers teach virtual-remote students only. Virtual teachers meet with or virtually interact with each student daily.',
            'Dedicated teachers teach virtual-remote students only. Virtual teachers meet with or virtually interact with each student at least 3 times weekly.',
            'Dedicated teachers teach virtual-remote students only. Virtual teachers meet with or virtually interact with each student once weekly.'
          ]
        },
        {
          id: 'phase2whatPercentOfTeachersTeachOnsiteOnly',
          text: 'What percent of your district’s teachers teach in-person for onsite students only?',
          component: 'QuestionFreeText'
        },
        {
          id: 'phase2whatPercentOfTeachersTeachOnsiteAndVirtual',
          text: 'What percent of your district’s teachers teach in-person and remotely?',
          component: 'QuestionFreeText'
        },
        {
          id: 'phase2whatPercentOfTeachersTeachVirtualOnly',
          text: 'What percent of your district’s teachers teach virtual only?',
          component: 'QuestionFreeText'
        },
        {
          id: 'phase2describeEngagementTraditionalAttendance',
          text: 'Using attendance as an indicator of engagement, at the end of the first 9 weeks, describe the level of engagement for traditional/onsite students.',
          component: 'QuestionSingleSelection',
          choices: ['Low', 'Moderate', 'High']
        },
        {
          id: 'phase2describeEngagementHybridAttendance',
          text: 'Using attendance as an indicator of engagement, at the end of the first 9 weeks, describe the level of engagement for hybrid students.',
          component: 'QuestionSingleSelection',
          choices: ['Low', 'Moderate', 'High']
        },
        {
          id: 'phase2describeEngagementVirtualAttendance',
          text: 'Using attendance as an indicator of engagement, at the end of the first 9 weeks, describe the level of engagement for virtual students.',
          component: 'QuestionSingleSelection',
          choices: ['Low', 'Moderate', 'High']
        },
        {
          id: 'phase2describeEngagementTraditionalMastery',
          text: 'Using mastery of learning objectives as an indicator of engagement, at the end of the first 9 weeks, describe the level of engagement for traditional/onsite students.',
          component: 'QuestionSingleSelection',
          choices: ['Low', 'Moderate', 'High']
        },
        {
          id: 'phase2describeEngagementHybridMastery',
          text: 'Using mastery of learning objectives as an indicator of engagement, at the end of the first 9 weeks, describe the level of engagement for hybrid students.',
          component: 'QuestionSingleSelection',
          choices: ['Low', 'Moderate', 'High']
        },
        {
          id: 'phase2describeEngagementVirtualMastery',
          text: 'Using mastery of learning objectives as an indicator of engagement, at the end of the first 9 weeks, describe the level of engagement for virtual students.',
          component: 'QuestionSingleSelection',
          choices: ['Low', 'Moderate', 'High']
        },
        {
          id: 'phase2typesOfEvidenceEngagement',
          text: 'What types of evidence will you use to inform decisions about how to improve student engagement and support additional modifications through the end of the semester?',
          component: 'QuestionMultipleSelection',
          choices: ['Student grades', 'Daily attendance', 'Completion of assignments', 'Participation in assessments',
            'Tracking student logins to the online system', 'Participation in synchronous online classes', 'Participation in individual coaching or check-ins']
        },
        {
          id: 'phase2typesOfExpectationsEngagement',
          text: 'What types of expectations has the district identified that can improve engagement for virtual students? Choose all that apply:',
          component: 'QuestionMultipleSelection',
          choices: [
            'No modifications needed.',
            'Requirements for virtual students to meet minimum standards to continue a remote learning option: Minimum attendance',
            'Requirements for virtual students to meet minimum standards to continue a remote learning option: Minimum performance on assignments or exams',
            'Requirements for virtual students to meet minimum standards to continue a remote learning option: Minimum time requirements spent interacting with teachers or working within the LMS',
            'Requirements for virtual students to meet minimum standards to continue a remote learning option: Other',
            'Requirements for virtual students to check-in onsite: Weekly',
            'Requirements for virtual students to check-in onsite: Monthly',
            'Requirements for virtual students to check-in onsite: Quarterly',
            'Requirements for parental engagement of virtual students: Weekly',
            'Requirements for parental engagement of virtual students: Monthly',
            'Requirements for parental engagement of virtual students: Quarterly',
            'Requirements for parental engagement of virtual students: As needed when concerns arise and parents contacted',
          ]
        },
        {
          id: 'phase2EnrollmentTrend',
          text: 'Which statement best describes your district’s enrollment trend from last year?',
          component: 'QuestionSingleSelection',
          choices: [
            'Enrollment is consistent',
            'Enrollment decreased, but decrease was expected',
            'Enrollment decreased significantly more than expected',
            'Enrollment increased, but increase was expected',
            'Enrollment increased significantly more than expected'
          ]
        },
        {
          id: 'phase2districtHasSufficientDevices',
          text: 'Does your district have sufficient devices for 1:1?',
          component: 'QuestionYesnoDetails',
          textDetails: 'If no, is your district awaiting delivery of back-ordered devices?',
          detailsOnValues: [0]
        },
        {
          id: 'phase2extentOfOptionUseAssignedDevices',
          text: 'Identify the extent the following options are used to continue teaching and learning when students or staff are required to be quarantined: Students use assigned devices to access instruction and assignments',
          component: 'QuestionSingleSelection',
          choices: ['Preferred option', 'Occasional option', 'Only when necessary']
        },
        {
          id: 'phase2extentOfOptionDevicesAvailableForCheckOut',
          text: 'Identify the extent the following options are used to continue teaching and learning when students or staff are required to be quarantined: Devices available for student check-out',
          component: 'QuestionSingleSelection',
          choices: ['Preferred option', 'Occasional option', 'Only when necessary']
        },
        {
          id: 'phase2extentOfOptionDevicesSetup',
          text: 'Identify the extent the following options are used to continue teaching and learning when students or staff are required to be quarantined: Devices and setup available for all teachers to teach from home and continue interaction with students',
          component: 'QuestionSingleSelection',
          choices: ['Preferred option', 'Occasional option', 'Only when necessary']
        },
        {
          id: 'phase2extentOfOptionSubstitutes',
          text: 'Identify the extent the following options are used to continue teaching and learning when students or staff are required to be quarantined: Substitutes are available to fill in on-site',
          component: 'QuestionSingleSelection',
          choices: ['Preferred option', 'Occasional option', 'Only when necessary']
        },
        {
          id: 'phase2extentOfOptionPaperPackets',
          text: 'Identify the extent the following options are used to continue teaching and learning when students or staff are required to be quarantined: Students are given paper packets to complete during time of quarantine',
          component: 'QuestionSingleSelection',
          choices: ['Preferred option', 'Occasional option', 'Only when necessary']
        },
        {
          id: 'phase2extentOfOptionMakeUpWork',
          text: 'Identify the extent the following options are used to continue teaching and learning when students or staff are required to be quarantined: Students are given make-up work when they return and required to complete work for credit',
          component: 'QuestionSingleSelection',
          choices: ['Preferred option', 'Occasional option', 'Only when necessary']
        },
        {
          id: 'phase2providingExtraCOVIDdata',
          text: 'Are you providing COVID data by school and/or district beyond what is reported by the Arkansas Department of Health?',
          component: 'QuestionYesnoDetails',
          textDetails: 'If so, how are you reporting that data?',
          detailsOnValues: [1]
        },
        {
          id: 'phase2frequencyOfDeepCleaning',
          text: 'How frequently do you deep clean facilities and buses?',
          component: 'QuestionFreeText'
        },
        {
          id: 'phase2DaysPerWeekRunAllBusRoutes',
          text: 'How many days per week do you run all of your bus routes?',
          component: 'QuestionFreeText'
        },
        {
          text: 'In anticipation of Federal CARES Act Reporting, please answer the below questions:',
          component: 'QuestionHeader'
        },
        {
          id: 'phase2StudentParticipationAndEngagementElementary',
          text: '(Elementary Grade Levels) If the LEA used CARES Act funds to develop, initiate and/or implement remote learning, please denote all methods used to document student participation and engagement during remote learning (check all that apply for 50% of more of the students within the grade level in the LEA).',
          component: 'QuestionMultipleSelection',
          choices: [
            'Submission of assignments',
            'Participation in assessments',
            'Tracking student logins to online learning platforms',
            'Participation in individual coaching or checkins',
            'Participation in help lines or hot lines for help with remote learning',
            'Participaion in synchronous online classes',
            'Other'
          ]
        },
        {
          id: 'phase2StudentParticipationAndEngagementSecondary',
          text: '(Secondary Grade Levels) If the LEA used CARES Act funds to develop, initiate and/or implement remote learning, please denote all methods used to document student participation and engagement during remote learning (check all that apply for 50% of more of the students within the grade level in the LEA).',
          component: 'QuestionMultipleSelection',
          choices: [
            'Submission of assignments',
            'Participation in assessments',
            'Tracking student logins to online learning platforms',
            'Participation in individual coaching or checkins',
            'Participation in help lines or hot lines for help with remote learning',
            'Participaion in synchronous online classes',
            'Other'
          ]
        },
        {
          id: 'phase2StudentInternetAccess',
          text: 'Did the LEA use CARES Act funds to provide home Internet access for any students?  If yes, what types of home Internet services were provided by the district using CARES Act funds?',
          component: 'QuestionMultipleSelection',
          choices: [
            'Mobile hotspots with paid data plans',
            'Internet connected devices with paid data plans',
            'District pays for the cost of home Internet subscription for student',
            'Other (specify)'
          ],
          allowOther: true,
          otherText: 'Identify other use of funds:'
        },
        {
          text: 'Among students enrolled on the last day of the reporting period (date), what proportion of students by district had a dedicated LEA-provided device for the following grade bands?  For the purposes of this survey, include desktop, laptop, and tablet computers (including Chromebooks and iPads).  Do not include smartphone devices.  Report for all LEAs that received any CARES funds (including GEER funds).',
          component: 'QuestionHeader'
        },
        {
          id: 'phase2StudentElementaryCaresActDevices',
          text: 'Did the LEA use CARES Act funds to provide dedicated learning devices to any Elementary students?',
          component: 'QuestionYesno'
        },
        {
          id: 'phase2StudentElementaryCaresActDedicatedDeviceCount',
          text: 'How many Elementary students have a dedicated device provided by the LEA?',
          component: 'QuestionFreeText'
        },
        {
          id: 'phase2StudentElementaryCaresActStudentEnrollment',
          text: 'How many Elementary students are enrolled?',
          component: 'QuestionFreeText'
        },
        {
          id: 'phase2StudentSecondaryCaresActDevices',
          text: 'Did the LEA use CARES Act funds to provide dedicated learning devices to any Secondary students?',
          component: 'QuestionYesno'
        },
        {
          id: 'phase2StudentSecondaryCaresActDedicatedDeviceCount',
          text: 'How many Secondary students have a dedicated device provided by the LEA?',
          component: 'QuestionFreeText'
        },
        {
          id: 'phase2StudentSecondaryCaresActStudentEnrollment',
          text: 'How many Secondary students are enrolled?',
          component: 'QuestionFreeText'
        }
      ],
      sections: []
    }
  ]
}
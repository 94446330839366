<template>
  <div style="display: inline">
    <span
      v-if="batch.districtNotifiedDate && !batch.districtAcknowledgedDate"
      title="The district has been notified"
    >
      <i class="fas fa-envelope text-warning fa-fw" />
    </span>
    <span
      v-if="batch.districtAcknowledgedDate"
      title="The district has acknowledged the inspections"
    >
      <i class="fas fa-envelope-open text-primary fa-fw" />
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    batch: Object
  }
}
</script>

<template>
  <b-card header="Student Contact Progress">
    <b-row v-if="dashboard.currentRoster.length == 0">
      <b-col class="text-center font-weight-bold">
        No Grade 8-12 students found for this LEA
      </b-col>
    </b-row>
    <b-row no-gutters v-else>
      <b-col cols="3">
        <highcharts class="chart dashboard-gauge" ref="progress1" :options="getChartOptions('Grade 8-9', gradeData[0].reached, gradeData[0].total, 80)" />
      </b-col>
      <b-col cols="3">
        <highcharts class="chart dashboard-gauge" ref="progress2" :options="getChartOptions('Grade 10', gradeData[1].reached, gradeData[1].total, 85)" />
      </b-col>
      <b-col cols="3">
        <highcharts class="chart dashboard-gauge" ref="progress3" :options="getChartOptions('Grade 11', gradeData[2].reached, gradeData[2].total, 90)" />
      </b-col>
      <b-col cols="3">
        <highcharts class="chart dashboard-gauge" ref="progress4" :options="getChartOptions('Grade 12', gradeData[3].reached, gradeData[3].total, 100)" />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
const loadingSpinner = '<i class="fas fa-spinner fa-spin"></i> Loading...'
const noData = "<span>No Data</span>"
import { Types } from '../../../../stores/careercoach/index.js'
import { mapState } from 'vuex'
import Highcharts from 'highcharts'
import solidGaugeInit from 'highcharts/modules/solid-gauge'

solidGaugeInit(Highcharts)

export default {
  data() {
    return {
      loadingSpinner: loadingSpinner
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.dashboard]),
    visits() {
      return this.dashboard['visitsByGrade'] || []
    },
    gradeData() {
      var grade8 = this.visits.find(v => v['grade'] == '08') || {}
      var grade9 = this.visits.find(v => v['grade'] == '09') || {}
      var grade10 = this.visits.find(v => v['grade'] == '10') || {}
      var grade11 = this.visits.find(v => v['grade'] == '11') || {}
      var grade12 = this.visits.find(v => v['grade'] == '12') || {}

      return [
        { grade: '8-9', reached: (grade8['inPersonVisits'] || 0) + (grade9['inPersonVisits'] || 0), total: (grade8['totalEnrollment'] || 0) + (grade9['totalEnrollment'] || 0) },
        { grade: '10', reached: grade10['inPersonVisits'] || 0, total: grade10['totalEnrollment'] || 0 },
        { grade: '11', reached: grade11['inPersonVisits'] || 0, total: grade11['totalEnrollment'] || 0 },
        { grade: '12', reached: grade12['inPersonVisits'] || 0, total: grade12['totalEnrollment'] || 0 }
      ]
    }
  },
  watch: {
    dashboard: {
      immediate: true,
      handler() {
        this.chartLoad()  
      }
    }
  },
  methods: {
    chartLoad() {
      const charts = [
        this.$refs["progress1"]?.chart,
        this.$refs["progress2"]?.chart,
        this.$refs["progress3"]?.chart,
        this.$refs["progress4"]?.chart,
      ]

      charts.forEach(c => {
        if (c) {
          c.showLoading(loadingSpinner)

          if (this.dashboard.currentRoster.length > 0) {
            c.hideLoading()
          } else {
            c.showLoading(noData)
          }
        }
      })
    },
    getChartOptions(title, studentsReached, totalStudents, goalPercentage) {
      var percentageReached = Math.round((studentsReached / totalStudents) * 100)
      var yAxisTitle = `${studentsReached.toLocaleString()} of ${totalStudents.toLocaleString()} students reached<br>Goal ${goalPercentage}%`

      return {
        chart: {
          type: 'solidgauge',
          margin: 0,
          height: 125,
          spacing: [0, 0, 0, 0],
          backgroundColor: '#f8f9fa'
        },
        loading: {
          style: {
            position: 'absolute',
            backgroundColor: '#ffffff',
            opacity: 0.8,
            textAlign: 'center'
          }
        },
        title: {
          text: title
        },
        pane: {
          center: ['50%', '60%'],
          //size: '80%',
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc'
          }
        },
        exporting: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              y: -30,
              borderWidth: 0,
              useHTML: true
            }
          }
        },
        yAxis: {
          stops: [
            [0, '#DF5353'], // red
            [goalPercentage / 200, '#DDDF0D'], // yellow
            [goalPercentage / 100 - 0.001, '#55BF3B'] // green
          ],
          lineWidth: 0,
          tickWidth: 0,
          minorTickInterval: null,
          tickAmount: 2,
          title: {
            y: 50,
            text: yAxisTitle
          },
          labels: {
            enabled: false
          },
          min: 0,
          max: 100
        },
        credits: {
          enabled: false
        },
        series: [
          {
            name: 'Students Reached',
            data: [percentageReached],
            dataLabels: {
              format: '<div style="text-align:center">' + '<span style="font-size:20px">{y}%</span><br/>' + '</div>'
            }
          },
          {
            data: [goalPercentage],
            type: 'gauge',
            dial: {
              baseLength: '100%',
              radius: '100%',
              rearLength: '-60%',
              baseWidth: 1
            },
            dataLabels: {
              enabled: false
            },
            animation: false
          }
        ]
      }
    }
  }
}
</script>

<style>
.dashboard-gauge {
  min-width: 100px;
}
.highcharts-yaxis-grid .highcharts-grid-line,
.highcharts-pivot {
  display: none;
}
</style>
<template>
  <div>
    <div class="row">
      <div class="col-lg-4 col-md-12 mb-2">
        <proposal-costs-type-chart :applications="applications" :clusters="clusters" :key="selectedAy" v-if="selectedAy > 23" />
        <proposal-costs-type-chart-old :applications="applications" :occupational-areas="occupationalAreas" :key="selectedAy" v-else />
      </div>
      <div class="col-lg-4 col-md-12">
        <applications-budget-chart :applications="applications" />
      </div>
      <div class="col-lg-4 col-md-12">
        <div class="row mb-2">
          <div class="col-12">
            <excel-button title="Applications" />
            <b-form inline class="float-right">
              <label class="mr-2">Academic Year:</label>
              <b-form-select class="mr-2" style="width: 8em" v-model="selectedAy" :options="academicYearOptions" value-field="ay" text-field="schoolYear" />
            </b-form>
          </div>
        </div>
        <application-list-header />
      </div>
    </div>
    <paginated-table :items="applications" :fields="fields" :load-data="loadData" sort-by="applicantId" :sort-asc="true" :tbody-tr-class="rowClass">
      <template v-slot:cell(draftProposals)="data">
        <b-badge variant="secondary">
          {{ data.item.draftProposals }}
        </b-badge>
      </template>
      <template v-slot:cell(submittedProposals)="data">
        <b-badge variant="secondary" v-if="!data.item.submittedProposals">
          {{ data.item.submittedProposals }}
        </b-badge>
        <b-badge variant="warning" v-if="data.item.pendingProposals" class="ml-1">
          Pending: {{ data.item.pendingProposals }}
        </b-badge>
        <b-badge variant="success" v-if="data.item.approvedProposals" class="ml-1">
          Approved: {{ data.item.approvedProposals }}
        </b-badge>
        <b-badge variant="danger" v-if="data.item.rejectedProposals" class="ml-1">
          Returned: {{ data.item.rejectedProposals }}
        </b-badge>
      </template>
      <template v-slot:cell(action)="data">
        <b-link
          :to="{ name: 'CTE/PerkinsV/Application', params: { applicationId: data.item.applicationId } }"
          title="Edit Item"
          @click="$emit('on-edit-initiated')">
          <i class="far fa-fw fa-folder-open" />
        </b-link>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import { Types } from '@/modules/cte/stores/perkinsv'
import { Types as CommonTypes } from '@/modules/cte/stores'
import { mapState, mapGetters } from 'vuex'
import PaginatedTable from '@/components/PaginatedTable'
import { formatCurrency } from '@/helpers/formatters'
import ApplicationsBudgetChart from './ApplicationsBudgetChart'
import ProposalCostsTypeChart from './ProposalCostsTypeChart'
import ProposalCostsTypeChartOld from './ProposalCostsTypeChartOld'
import ApplicationListHeader from './ApplicationListHeader'
import ExcelButton from '@/components/ExcelButton'
import sumBy from 'lodash.sumby'

export default {
  data() {
    return {
      fields: [
        { key: 'applicationId', label: 'Id' },
        { key: 'applicantId', sortable: true, label: 'Applicant', formatter: this.leaFormat, sortByFormatted: true },
        { key: 'amountAwarded', label: 'Amount Awarded', sortable: true, formatter: formatCurrency, export: row => row.amountAwarded },
        { key: 'amountBudgetedApproved', sortable: true, label: 'Amount Budgeted (Approved)', formatter: formatCurrency, export: row => row.amountBudgetedApproved },
        { key: 'amountBudgetedPending', sortable: true, label: 'Amount Budgeted (Pending)', formatter: formatCurrency, export: row => row.amountBudgetedPending },
        { key: 'amountRemaining', label: 'Amount Remaining', sortable: true, formatter: formatCurrency, export: row => row.amountRemaining },
        { key: 'draftProposals', label: 'Draft Proposals' },
        { 
          key: 'submittedProposals',
          label: 'Submitted Proposals',
          export: (row) => {
            return `Submitted ${row.submittedProposals || 0} 
            Pending: ${row.pendingProposals || 0} 
            Approved: ${row.approvedProposals || 0} 
            Returned: ${row.rejectedProposals || 0} 
            `
          } 
        },
        { key: 'action', class: 'text-right' },
      ],
      loadData: this.$route.params.loading,
      selectedAy: 0
    }
  },
  methods: {
    leaFormat(lea) {
       if (this.leaDictionary[lea])
        return `${this.leaDictionary[lea].name} (${lea})`
  
      return lea
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.amountBudgetedPending > 0) return 'table-warning'
    },
    getApplications(ay) {
      return this.$store.dispatch(Types.actions.getApplications, { ay: ay })
    },
    populateAcademicYears() {
      if (this.activeAcademicYear > 0) {
        this.selectedAy = this.activeAcademicYear
        this.populateOAMetadata()
      }
      else {
        this.loadData = this.$store.dispatch(Types.actions.getAcademicYears).then(() => {
          this.selectedAy = this.activeAcademicYear
          this.populateOAMetadata()
        })
      }
    },
    populateOAMetadata() {
      if (!(this.occupationalAreaMetadata || {}).occupationalAreas) {
        this.$store.dispatch(CommonTypes.actions.getOAMetadata, { ay: this.activeAcademicYear })
      }
    },
    excelExportData() {
      return {
        name: 'Applications',
        items: [...this.applications.map(application => {
          let row = {}
          this.fields.filter(field => field.key != 'action' && field.key != 'submittedProposals').forEach(field => {
            if (field.export && typeof field.export === 'function')
              row[field.label] = field.export(application)
            else if (field.formatter && typeof field.formatter === 'function')
              row[field.label] = field.formatter(application[field.key])
            else
              row[field.label] = application[field.key]
          })
          return row
        }), {
          'Id': '',
          'Applicant': 'Totals',
          'Amount Awarded': sumBy(this.applications, 'amountAwarded'),
          'Amount Budgeted (Approved)': sumBy(this.applications, 'amountBudgetedApproved'),
          'Amount Budgeted (Pending)': sumBy(this.applications, 'amountBudgetedPending'),
          'Amount Remaining': sumBy(this.applications, 'amountRemaining'),
        }]
      }
    },
  },
  computed: {
    ...mapState(Types.path, [Types.state.applications, Types.state.academicYears]),
    ...mapGetters(Types.path, [Types.getters.activeAcademicYear]),
    ...mapState(CommonTypes.path, [CommonTypes.state.occupationalAreaMetadata]),
    occupationalAreas() {
      return (this.occupationalAreaMetadata || { occupationalAreas: [] }).occupationalAreas
    },
    clusters() {
      return (this.occupationalAreaMetadata || { clusters: [] }).clusters
    },
    academicYearOptions() {
      const sorted = [...this.academicYears].sort((a,b) => (a.ay > b.ay ? -1 : 1))
      return sorted.slice(0, [...sorted].reverse().findIndex(ay => ay.openForSubmissions == true) + 1)
    }
  },
  watch: {
    selectedAy(ay) {
      this.loadData = this.getApplications(ay)
    }
  },
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      vm.populateAcademicYears()
    })
  },
  components: {
    PaginatedTable,
    ApplicationListHeader,
    ExcelButton,
    ApplicationsBudgetChart,
    ProposalCostsTypeChart,
    ProposalCostsTypeChartOld,
  }
}
</script>

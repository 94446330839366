<template>
  <span class="badge" :class="statusDisplay.badgeClass"><i class="fa-fw" :class="statusDisplay.iconClass" />{{ statusDisplay.text }}</span>
</template>

<script>
import { InspectionBatchStatus } from './Enums'

export default {
  data() {
    return {

    }
  },
  computed: {
    statusDisplay() {
      return InspectionBatchStatus.DisplayOptions(this.status)
    }
  },
  props: {
    status: Number
  }
}
</script>

const LicenseExceptionTypes = [
  { field: null, name: "-- Filter by Exception Type --"},
  { field: 'ALP', name: 'Approved Additional Licensure Plan' },
  { field: 'AWL', name: 'Act 1240 Approval to Waive Licensure' },
  { field: 'CWL', name: 'Charter School Approval to Waiver Licensure' },
  { field: 'ETL', name: 'Effective Teacher Licensure' },
  { field: 'ETP', name: 'Emergency Teaching Permit' },
  { field: 'LTS', name: 'Approved Long-Term Substitute' },
  { field: 'SOI', name: 'Schools of Innovation' },
  { field: 'DLP', name: 'Digital Learning Provider' }
]
    
export { LicenseExceptionTypes }
import ApiService from '@/services/ApiService'

export class RVSModule {

  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
  }

  namespaced = true

  state = {
    loadedYears: [],
    fiscalYear: null,
    teacherList: null,
    coTeacherList: null,
    courseList: [],
    timelineDates: [],
    schoolList: [],
    districtDetail: null,
    schoolDetail: null,
    districtList: [],
    subjectGradeCrossTab: [],
    courseDesignationCrossTab: [],
    teacherDetail: null,
    classroomDetail: null,
    contributorDetail: null,
    feedback: [],
    verificationCourses: [],
    excludedCourses: [],
    courseCategories: [],
    roleIdentification: [],
    toggles: [],
    teacherStates: {},
    schoolStates: {},
    districtStates: {},
    students: [],
    staffListing: null,
    nightlyExportFiles: []
  }
  getters = {
    totalTeachersByDistrict: (state, getters) => {
      return getters.districtInfo.length === 0 ? 0 : getters.districtInfo.map(d => d.verifiableTeachers).reduce((a, b) => a + b)
    },
    totalSchoolsByDistrict: (state, getters) => {
      return getters.districtInfo.length === 0 ? 0 : getters.districtInfo.map(d => d.totalSchools).reduce((a, b) => a + b)
    },
    totalTeachersBySchool: state => {
      return state.schoolList.length === 0 ? 0 : state.schoolList.map(d => d.verifiableTeachers).reduce((a, b) => a + b)
    },
    completeTeachersByDistrict: (state, getters) => {
      return getters.districtInfo.length === 0 ? 0 : getters.districtInfo.map(d => d.completeTeachers).reduce((a, b) => a + b)
    },
    completeSchoolsByDistrict: (state, getters) => {
      return getters.districtInfo.length === 0 ? 0 : getters.districtInfo.map(d => d.completeSchools).reduce((a, b) => a + b)
    },
    completeTeachersBySchool: state => {
      return state.schoolList.length === 0 ? 0 : state.schoolList.map(d => d.completeTeachers).reduce((a, b) => a + b)
    },
    completeSchools: state => {
      return state.schoolList.length === 0 ? 0 : state.schoolList.filter(d => d.status == 101).length
    },
    totalSchools: state => {
      return state.schoolList.length
    },
    completeTeachers: state => {
      return !state.teacherList || state.teacherList.length === 0 ? 0 : state.teacherList.filter(d => d.verificationStatus == 101 && (d.subjects || []).length > 0 && d.totalVerifiableStudents > 0).length
    },
    totalTeachers: state => {
      return !state.teacherList || state.teacherList.length === 0 ? 0 : state.teacherList.filter(d => d.totalStudents > 0 && (d.subjects || []).length > 0 && d.totalVerifiableStudents > 0 && d.verificationStatus != 999).length
    },
    completeDistricts: (state, getters) => {
      return (getters.districtInfo).length === 0 ? 0 : getters.districtInfo.filter(d => d.status == 100).length
    },
    totalDistricts: (state, getters) => {
      return getters.districtInfo.length
    },
    districtInfo: state => {
      return ((state.districtList || []).length == 0) ? [] : state.districtList.reduce((acc, school) => {
        const district = acc.find(d => d.districtLea === school.districtLea)
        if (district) {
          district.totalTeachers += school.totalTeachers
          district.verifiableTeachers += school.verifiableTeachers
          district.completeTeachers += school.completeTeachers
          district.completeStudents += school.completeStudents
          district.totalVerifiableStudents += school.totalVerifiableStudents
          district.completeSchools += school.schoolStatus == 101 ? 1 : 0
          district.totalSchools++
        } else {
          acc.push({
            districtLea: school.districtLea,
            verifiableTeachers: school.verifiableTeachers,
            completeTeachers: school.completeTeachers,
            completeStudents:school.completeStudents,
            totalVerifiableStudents: school.totalVerifiableStudents,
            status: school.districtStatus,
            completeSchools: school.schoolStatus == 101 ? 1 : 0,
            totalContributors: school.totalContributors ?? null,
            totalSchools: 1,
          })
        }
        return acc
      }, []).map(district => {
        return {
          ...district,
          verificationProgress: (district.completeSchools / district.totalSchools) * 100
        }
      })      
    }
  }
  mutations = {
    setLoadedYears: (state, payload) => {
      state.loadedYears = payload
    },
    setFiscalYear: (state, payload) => {
      state.fiscalYear = payload
    },
    setTeacherList: (state, payload) => {
      state.teacherList = payload
    },
    setCoTeacherList: (state, payload) => {
      state.coTeacherList = payload
    },
    setCourseList: (state, payload) => {
      state.courseList = payload
    },
    setTimelineDates: (state, payload) => {
      state.timelineDates = payload
    },
    setDistrictList: (state, payload) => {
      state.districtList = payload
    },
    setSchoolList: (state, payload) => {
      state.schoolList = payload
    },
    setDistrictDetail: (state, payload) => {
      state.districtDetail = payload
    },
    setSchoolDetail: (state, payload) => {
      state.schoolDetail = payload
    },
    setTeacherDetail: (state, payload) => {
      state.teacherDetail = payload
    },
    setSubjectGradeCrossTab: (state, payload) => {
      state.subjectGradeCrossTab = payload
    },
    setCourseDesignationCrossTab: (state, payload) => {
      state.courseDesignationCrossTab = payload
    },
    setClassroomDetail: (state, payload) => {
      state.classroomDetail = payload
    },
    setContributorDetail: (state, payload) => {
      state.contributorDetail = payload
    },
    setFeedback: (state, payload) => {
      payload.forEach(item => {
        item._showDetails = true
      })
      state.feedback = payload
    },
    setVerificationCourses: (state, payload) => {
      state.verificationCourses = payload
    },
    setExcludedCourses: (state, payload) => {
      state.excludedCourses = payload
    },
    setCourseCategories: (state, payload) => {
      state.courseCategories = payload
    },
    setRoleIdentification: (state, payload) => {
      state.roleIdentification = payload
    },
    setToggles: (state, payload) => {
      state.toggles = payload
    },
    setTeacherStates: (state, payload) => {
      state.teacherStates = payload
    },
    setSchoolStates: (state, payload) => {
      state.schoolStates = payload
    },
    setDistrictStates: (state, payload) => {
      state.districtStates = payload
    },
    setStudents: (state, payload) => {
      state.students = payload
    },
    setStaffListing: (state, payload) => {
      state.staffListing = payload
    },
    setNightlyExportFiles: (state, payload) => {
      state.nightlyExportFiles = payload
    }
  }

  actions = {
    getFiscalYears: ({ commit }) => {
      return this._apiService.Get(`${this._baseUrl}api/rvs/fiscalYears`)
        .then(data => commit('setLoadedYears', data))
    },
    getTeacherList: async ({ commit, state }, payload) => {
      await commit('setTeacherList', null)
      return this._apiService.Get(`${this._baseUrl}api/rvs/${state.fiscalYear}/${payload}/teacher`)
        .then(data => commit('setTeacherList', data))
    },
    getCoTeacherList: async ({ commit, state }, payload) => {
      await commit('setCoTeacherList', null)
      return this._apiService.Get(`${this._baseUrl}api/rvs/${state.fiscalYear}/${payload}/coteacher`)
        .then(data => commit('setCoTeacherList', data))
    },
    getCourseList: async ({ commit, state }, payload) => {
      await commit('setCourseList', [])
      return this._apiService.Get(`${this._baseUrl}api/rvs/${state.fiscalYear}/${payload}/course`)
        .then(data => commit('setCourseList', data))
    },
    getTimelineDates: async ({ commit },) => {
      await commit('setTimelineDates', [])
      return this._apiService.Get(`${this._baseUrl}api/rvs/timeline`)
        .then(data => commit('setTimelineDates', data))
    },
    getDistrictList: async ({ commit, state }, payload) => {
      await commit('setDistrictList', [])
      return this._apiService.Get(`${this._baseUrl}api/rvs/${state.fiscalYear}/${payload}/district`)
        .then(data => commit('setDistrictList', data))
    },
    getSchoolList: async ({ commit, state }, payload) => {
      await commit('setSchoolList', [])
      return this._apiService.Get(`${this._baseUrl}api/rvs/${state.fiscalYear}/${payload}/school`)
        .then(data => commit('setSchoolList', data))
    },
    getDistrictDetail: async({ commit, state }, payload) => {
      await commit('setDistrictDetail', null)
      return this._apiService.Get(`${this._baseUrl}api/rvs/${state.fiscalYear}/${payload}/district/detail`)
        .then(data => commit('setDistrictDetail', data))
    },
    getSchoolDetail: async({ commit, state }, payload) => {
      await commit('setSchoolDetail', null)
      return this._apiService.Get(`${this._baseUrl}api/rvs/${state.fiscalYear}/${payload}/school/detail`)
        .then(data => commit('setSchoolDetail', data))
    },
    getTeacherDetail: async ({ commit, state }, payload) => {
      await commit('setTeacherDetail', null)
      return this._apiService.Get(`${this._baseUrl}api/rvs/${state.fiscalYear}/${payload.lea}/teacher/${payload.id}`)
        .then(data => commit('setTeacherDetail', data))
    },
    getClassroomDetail: async ({ commit, state }, payload) => {
      if (!payload.noClear) await commit('setClassroomDetail', null)
      return this._apiService.Get(`${this._baseUrl}api/rvs/${state.fiscalYear}/${payload.lea}/teacher/${payload.teacherId}/classroom/${payload.id}/${payload.section}/${payload.teacherType}?sectionKey=${payload.sectionKey ? payload.sectionKey : ''}`)
        .then(data => commit('setClassroomDetail', data))
    },
    getContributorDetail: async ({ commit, state }, payload) => {
      if (payload.clear) await commit('setContributorDetail', null)
      return this._apiService.Get(`${this._baseUrl}api/rvs/${state.fiscalYear}/${payload.lea}/teacher/${payload.id}/contributor`)
        .then(data => commit('setContributorDetail', data))
    },
    submitFeedback: async (_, payload) => {
      return this._apiService.Put(`${this._baseUrl}api/rvs/feedback`, payload)
    },
    getFeedback: async ({ commit }, payload) => {
      await commit('setFeedback', [])
      return this._apiService.Get(`${this._baseUrl}api/rvs/admin/feedback?start=${payload.start}&end=${payload.end}`)
        .then(data => commit('setFeedback', data))
    },
    getVerificationCourses: async ({ commit, state }) => {
      await commit('setVerificationCourses', [])
      return this._apiService.Get(`${this._baseUrl}api/rvs/${state.fiscalYear}/course`)
        .then(data => commit('setVerificationCourses', data))
    },
    getExcludedCourses: async ({ commit }) => {
      await commit('setExcludedCourses', [])
      return this._apiService.Get(`${this._baseUrl}api/rvs/excludedcourse`)
        .then(data => commit('setExcludedCourses', data))
    },
    getCourseCategories: async ({ commit, state }) => {
      await commit('setCourseCategories', [])
      return this._apiService.Get(`${this._baseUrl}api/rvs/${state.fiscalYear}/coursecategories`)
        .then(data => commit('setCourseCategories', data))
    },
    getSubjectGradeCrossTab: async ({ commit, state }, payload) => {
      await commit('setSubjectGradeCrossTab', null)
      return this._apiService.Get(`${this._baseUrl}api/rvs/${state.fiscalYear}/${payload.lea}/crosstab/subjectgrade`)
        .then(data => commit('setSubjectGradeCrossTab', data))
    },
    getCourseDesignationCrossTab: async ({ commit, state }, payload) => {
      await commit('setCourseDesignationCrossTab', null)
      return this._apiService.Get(`${this._baseUrl}api/rvs/${state.fiscalYear}/${payload.lea}/crosstab/coursedesignation`)
        .then(data => commit('setCourseDesignationCrossTab', data))
    },
    getRoleIdentification: async ({ commit},) => {
      return this._apiService.Get(`${this._baseUrl}api/rvs/roleidentification`)
        .then(data => commit('setRoleIdentification', data))
    },
    getToggles: async ({ commit }) => {
      return this._apiService.Get(`${this._baseUrl}api/rvs/toggle`)
        .then(data => commit('setToggles', data))
    },
    updateToggle: async (_, payload) => {
      return this._apiService.Put(`${this._baseUrl}api/rvs/admin/toggle/${payload.toggleType}?value=${payload.isEnabled}`)
    },
    getNightlyExportFiles: async ({ commit }) => {
      return this._apiService.Get(`${this._baseUrl}api/rvs/admin/nightly/files`)
        .then(data => commit('setNightlyExportFiles', data))
    },
    getTeacherStates: async ({ commit, state }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/rvs/${state.fiscalYear}/${payload.lea}/teacher/${payload.id}/state`)
        .then(data => commit('setTeacherStates', data))
    },
    transitionTeacher: async ({ state, dispatch }, payload) => {
      return this._apiService.Put(`${this._baseUrl}api/rvs/${state.fiscalYear}/${payload.lea}/teacher/${payload.id}/transition`, {state: payload.state, comment: payload.comment })
        .then(() => {
          dispatch('getTeacherStates', { lea: payload.lea, id: payload.id })
          dispatch('getTeacherDetail', { lea: payload.lea, id: payload.id })
        })   
    },
    getSchoolStates: async ({ commit, state }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/rvs/${state.fiscalYear}/${payload}/school/state`)
        .then(data => commit('setSchoolStates', data))
    },
    getDistrictStates: async ({ commit, state }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/rvs/${state.fiscalYear}/${payload}/district/state`)
        .then(data => commit('setDistrictStates', data))
    },
    transitionLea: async ({ state, dispatch }, payload) => {
      return this._apiService.Put(`${this._baseUrl}api/rvs/${state.fiscalYear}/${payload.lea}/transition`, {state: payload.state, comment: payload.comment })
        .then(() => {
          if (payload.isDistrict) {
            dispatch('getDistrictDetail', payload.lea)
            dispatch('getDistrictStates', payload.lea)
          }
          else // is school
          {
            dispatch('getSchoolDetail', payload.lea)
            dispatch('getSchoolStates', payload.lea)
          }
        })   
    },
    getStudents: async ({ commit, state }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/rvs/${state.fiscalYear}/${payload.lea}/students`)
        .then(data => commit('setStudents', data))
    },
    addContributorStudents: async ({ state, commit }, payload) => {     
      return this._apiService.Post(`${this._baseUrl}api/rvs/${state.fiscalYear}/${payload.lea}/teacher/${payload.teacherId}/contributor/students`, payload.studentIds)
        .then(data => commit('setContributorDetail', data))
    },
    removeContributorStudents: async ({ state, commit }, payload) => {    
      return this._apiService.Post(`${this._baseUrl}api/rvs/${state.fiscalYear}/${payload.lea}/teacher/${payload.teacherId}/contributor/students/delete`, payload.snapshotIds)
        .then(data => commit('setContributorDetail', data))
    },
    updateStudentValues: async (_, payload) => {
      return this._apiService.Put(`${this._baseUrl}api/rvs/students`, { Data: { key: payload.key, value: payload.value }, SnapshotIds: payload.snapshotIds })
    },
    updateContributorStudent: async (_, payload) => {
      return this._apiService.Put(`${this._baseUrl}api/rvs/contributor/students`, { Data: { key: payload.key, value: payload.value }, SnapshotIds: payload.snapshotIds })
    },
    getStaffListing: async ({ state, commit }, payload) => {
      commit('setStaffListing', null)
      return this._apiService.Get(`${this._baseUrl}api/rvs/${state.fiscalYear}/${payload}/export`)
        .then(data => commit('setStaffListing', data))
    }
  }
}
import ApiService from '../services/ApiService'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
const moment = extendMoment(Moment)
import { groupBy } from '../helpers/globalHelpers'
const groupByFiscalYear = groupBy('fiscalYear')

const expirationRanges = {
  expiredAlready: moment.range(null, moment()),
  endOfThisYear: moment.range(moment().add(90, 'days'), `${moment().year()}-12-31`),
  ninetyDays: moment.range(moment().add(30, 'days'), moment().add(90, 'days')),
  thirtyDays: moment.range(moment().add(7, 'days'), moment().add(30, 'days')),
  sevenDays: moment.range(moment().add(1, 'days'), moment().add(7, 'days')),
  oneDay: moment.range(moment(), moment().add(1, 'days'))
}

export class HCPTModule {

  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
    this._fiscalYear = this._configElement.getAttribute('data-fiscal-year')
    this._employeeDetailsFiscalYear = this._configElement.getAttribute('data-hcpt-employees-fiscal-year')
  }

  namespaced = true

  state = {
    reportGroups: [],
    currentFiscalYear: 0,
    attritionStatistics: [],
    overviewData: {},
    expiringLicenses: [],
    expiringLicenseNotifications: [],
    expiringLicenseProcessDates: [],
    licenseExceptionStatistics: [],
    licenseIssuedStatistics: {},
    licenseExceptionIndividuals: [],
    licenseExceptionIndividualsWithALP: [],
    licensedEducatorStatistics: [],
    licensedEducatorList: [],
    employeeALPs: [],
    workforceStabilityIndex: [],
    workforceStabilityIndex2: {},
    nbctCertifiedTeachers: [],
    demographicsHistory: [],
    dataIntegrity: {
      classesWithoutCaseId: [],
      staffWithNoAelsRecord: [],
      caseIdCrosslink: []
    },
    additionalReporting: {
      staffDemographics: {},
      staffDetails: []
    },
    // nbct: {
    //     trendData: [],
    //     systemCounts: {},
    //     detailData: []
    // },
    nbctTrendData: [],
    nbctDetailData: [],
    nbctSystemCounts: {},
    nbctConfirms: [],
    employeeLeave: [],
    employeeLeaveDays: [],
    employeeLeaveDayDetails: [],
    employeeLeaveDayFilterDate: null,
    employabilityBackgroundChecks: [],
    selectedEmployeeClassification: 'all',
    employees: [],
    newTeachersByRace: [],
    noviceTeachersByRace: [],
    ethicsViolations: [],
  }
  getters = {
    employeeDetailsFiscalYear: () => parseInt(this._employeeDetailsFiscalYear),
    fiscalYear: () => {
      return this._fiscalYear
    },
    expiredLicensesByRange(state) {
      var licenseRanges = {}
      if (state.expiringLicenses) {
        Object.keys(expirationRanges).forEach(key => {
          licenseRanges[key] = state.expiringLicenses.filter(l => moment(l.expirationDate).within(expirationRanges[key]))
        })
      }
      return licenseRanges
    },
    expiringLicenseNotifications(state) {
      return state.expiringLicenseNotifications
    },
    expiringLicenseProcessDates(state) {
      return state.expiringLicenseProcessDates
    },
    nbctCertifiedTeachersByFiscalYear(state) {
      return groupByFiscalYear(state.nbctCertifiedTeachers)
    },
    nbctTrendData(state) {
      return state.nbctTrendData
    },
    nbctDetailData(state) {
      return state.nbctDetailData
    },
    nbctSystemCounts(state) {
      return state.nbctSystemCounts
    },
    nbctConfirms(state) {
      return state.nbctConfirms
    },
    currentNbctCertifiedTeachers(state) {
      //const currentFiscalYear = Math.max(Object.keys(getters.nbctCertifiedTeachersByFiscalYear))
      //.filter(t => t.fiscalYear == currentFiscalYear)
      return state.nbctCertifiedTeachers
    },
    classesWithoutCaseId(state) {
      return state.dataIntegrity.classesWithoutCaseId
    },
    caseIdCrosslink(state) {
      return state.dataIntegrity.caseIdCrosslink
    },
    filteredEmployabilityBackgroundChecks(state) {
      if (state.selectedEmployeeClassification == 'all') {
        return [...state.employabilityBackgroundChecks]
      }
      return [...state.employabilityBackgroundChecks.filter(e => e[state.selectedEmployeeClassification])]
    }
  }
  mutations = {
    setFiscalYear: (state, fiscalYear) => state.currentFiscalYear = fiscalYear,
    setReportGroups: (state, reportGroups) => state.reportGroups = reportGroups,
    setHCPTOverviewData: (state, overviewData) => state.overviewData = overviewData,
    setEmployeeALPs: (state, employeeALPs) => state.employeeALPs = employeeALPs,
    setLicenseExceptionStatistics: (state, statistics) => state.licenseExceptionStatistics = statistics,
    setLicenseIssuedStatistics: (state, statistics) => state.licenseIssuedStatistics = statistics,
    setReportGroupInformation(state, reportGroup) {
      const item = state.reportGroups.find(i => i.id == reportGroup.id)

      if (item) {
        //update the item in the store
        item.groupName = reportGroup.groupName
      } else {
        state.reportGroups.push(reportGroup)
        //add the item to the store
      }
    },
    setExpiringLicenses(state, expiringLicenses) {
      state.expiringLicenses = expiringLicenses.map(l => {
        l.expirationDate = moment(l.expirationDate).format('l')
        l.leas = [...new Set(l.licenses.map(e => e.lea))]

        return l
      })
    },
    setLicensedEducatorStatistics(state, licensedEducatorStatistics) {
      state.licensedEducatorStatistics = licensedEducatorStatistics
    },
    setExpiringLicenseNotifications: (state, expiringLicenseNotifications) => state.expiringLicenseNotifications = expiringLicenseNotifications,
    setExpiringLicenseProcessDates: (state, expiringLicenseProcessDates) => state.expiringLicenseProcessDates = expiringLicenseProcessDates,
    setWorkforceStabilityIndex: (state, workforceStabilityIndex) => state.workforceStabilityIndex = workforceStabilityIndex,
    setWorkforceStabilityIndex2: (state, workforceStabilityIndex2) => state.workforceStabilityIndex2 = workforceStabilityIndex2,
    setNbctCertifiedTeachers: (state, nbctCertifiedTeachers) => state.nbctCertifiedTeachers = nbctCertifiedTeachers,
    //setNbctData: (state, nbctData) => state.nbct = nbctData,
    setNbctTrendData: (state, data) => state.nbctTrendData = data,
    setNbctDetailData: (state, data) => state.nbctDetailData = data,
    setNbctSystemCounts: (state, data) => state.nbctSystemCounts = data,
    setNbctConfirms: (state, data) => state.nbctConfirms = data,
    setClassesWithoutCaseIds: (state, classesWithoutCaseId) => state.dataIntegrity.classesWithoutCaseId = classesWithoutCaseId,
    setStaffWithNoAelsRecords: (state, staffWithNoAelsRecord) => state.dataIntegrity.staffWithNoAelsRecord = staffWithNoAelsRecord,
    setCaseIdCrosslink: (state, caseIdCrosslink) => state.dataIntegrity.caseIdCrosslink = caseIdCrosslink,
    setPersonnelStats: (state, personnelStats) => state.additionalReporting.staffDemographics = personnelStats,
    setEmployeeLeave: (state, employeeLeave) => state.employeeLeave = employeeLeave,
    setEmployeeLeaveDays: (state, employeeLeaveDays) => state.employeeLeaveDays = employeeLeaveDays,
    setEmployeeLeaveDayDetails: (state, employeeLeaveDayDetails) => state.employeeLeaveDayDetails = employeeLeaveDayDetails,
    setEmployeeLeaveDayFilterDate: (state, employeeLeaveDayFilterDate) => state.employeeLeaveDayFilterDate = employeeLeaveDayFilterDate,
    setLicensedEducatorList(state, licensedEducatorList) {
      state.licensedEducatorList = licensedEducatorList.map(l => {
        l.expirationDate = moment(l.expirationDate).format('l')
        l.leas = [...new Set(l.licenses.map(e => e.lea))]

        return l
      })
    },
    setDemographicsHistory: (state, demographicsHistory) => state.demographicsHistory = demographicsHistory,
    setStaffDetails: (state, staffDetails) => state.additionalReporting.staffDetails = staffDetails,
    setLicenseExceptionIndividuals: (state, licenseExceptionIndividuals) => state.licenseExceptionIndividuals = licenseExceptionIndividuals,
    setLicenseExceptionIndividualsWithALP: (state, licenseExceptionIndividualsWithALP) => state.licenseExceptionIndividualsWithALP = licenseExceptionIndividualsWithALP,
    setEmployabilityBackgroundChecks: (state, employabilityBackgroundChecks) => state.employabilityBackgroundChecks = employabilityBackgroundChecks,
    setSelectedEmployeeClassification: (state, selectedEmployeeClassification) => state.selectedEmployeeClassification = selectedEmployeeClassification,
    setEmployees: (state, employees) => state.employees = employees,
    setNewTeachersByRace: (state, data) => state.newTeachersByRace = data,
    setNoviceTeachersByRace: (state, data) => state.noviceTeachersByRace = data,
    setEthicsViolations: (state, data) => state.ethicsViolations = data,
  }

  actions = {
    getOverviewStatistics: ({ commit }, payload) => {

      console.log(`Overview: ${this._baseUrl}`)

      return this._apiService.Post(`${this._baseUrl}api/hcpt/overview`, payload)
        .then(overviewData => commit('setHCPTOverviewData', overviewData))
    },
    getExpiringLicenses: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/expiringlicenses`)
        .then(data => commit('setExpiringLicenses', data))
    },
    getExpiringLicenseNotifications: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/expiringlicensenotifications/${payload.sendDate}`)
        .then(data => commit('setExpiringLicenseNotifications', data))
    },
    getExpiringLicenseNotificationProcessDates: ({ commit }) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/expiringlicensenotificationProcessDates`)
        .then(data => commit('setExpiringLicenseProcessDates', data.map(d => new Date(d))))
    },
    getLicenseExceptionStatistics: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/licenseExceptionStatistics`)
        .then(data => commit('setLicenseExceptionStatistics', data))
    },
    getLicenseIssuedStatistics: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/license-issue-statistics`)
        .then(data => commit('setLicenseIssuedStatistics', data))
    },
    getLicensedEducatorStatistics: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/licensedEducator/statistics`)
        .then(data => commit('setLicensedEducatorStatistics', data))
    },
    getWorkforceStabilityIndex: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/workforceStabilityIndex`)
        .then(data => commit('setWorkforceStabilityIndex', data))
    },
    getWorkforceStabilityIndex2: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/workforceStabilityIndex2`)
        .then(data => commit('setWorkforceStabilityIndex2', data))
    },
    // getNbctCertifiedTeachers: ({
    //     commit
    // }, payload) => {
    //     return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/nbctCertifiedTeachers`)
    //         .then(data => commit('setNbctCertifiedTeachers', data))
    // },
    getDemographicsHistory: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/public-educators/demographicsHistory`)
        .then(data => commit('setDemographicsHistory', data))
    },
    getClassesMissingCaseId: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/data-integrity/classes-missing-case-id`)
        .then(data => commit('setClassesWithoutCaseIds', data))
    },
    getStaffWithNoAelsRecords: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/data-integrity/staff-not-in-aels`)
        .then(data => commit('setStaffWithNoAelsRecords', data))
    },
    getCaseIdCrosslink: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/data-integrity/case-id-crosslink`)
        .then(data => commit('setCaseIdCrosslink', data))
    },
    getEmployeeALPs: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/employeeALPs`)
        .then(data => commit('setEmployeeALPs', data))
    },
    getStaffDemographics: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/additional-reporting/personnel-statistics`)
        .then(data => {
          commit('setPersonnelStats', data)
        })
    },
    getEmployeeLeave: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/employee-leave/${payload.leaveViewType}/${payload.leaveType}/${payload.stateView}`)
        .then(data => {
          if (data.includes('Not authorized'))
            commit('setEmployeeLeave', [])
          else
            commit('setEmployeeLeave', data)
        })
        .catch(reason => {
          if (reason.response.status == 403) {
            return Promise.reject({
              message: 'Not allowed to view educators for this LEA'
            })
          }
        })
    },
    getEmployeeLeaveDays: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/employee-leave-days/${payload.stateView || 0}/${payload.leaveType}`)
        .then(data => Promise.all([ 
          commit('setEmployeeLeaveDays', data.days),
          commit('setEmployeeLeaveDayDetails', data.details)
        ])
      )
    },
    getLicensedEducatorList: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/licensedEducator/list`, true)
        .then(data => {
          commit('setLicensedEducatorList', data)
        })
        .catch(reason => {
          //JSON.parse(reason.response.headers['x-accessibleleas'])
          if (reason.response.status == 403) {
            return Promise.reject({
              message: 'Not allowed to view educators for this LEA'
            })
          }
        })
    },
    getStaffDetails: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/additional-reporting/staff-details`, true)
        .then(data => {
          commit('setStaffDetails', data.details)
        })
        .catch(reason => {
          //JSON.parse(reason.response.headers['x-accessibleleas'])
          if (reason.response.status == 403) {
            return Promise.reject({
              message: 'Not allowed to view educators for this LEA'
            })
          }
        })
    },
    getLicenseExceptionIndividuals: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/licenseExceptionIndividuals`, true)
        .then(licenseExceptionIndividuals => commit('setLicenseExceptionIndividuals', licenseExceptionIndividuals))
        .catch(reason => {
          //JSON.parse(reason.response.headers['x-accessibleleas'])
          if (reason.response.status == 403) {
            return Promise.reject({
              message: 'Not allowed to view license exception individiuals for this LEA'
            })
          }
        })
    },
    getLicenseExceptionIndividualsWithALP: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/licenseExceptionIndividualsWithALPInfo`, true)
        .then(licenseExceptionIndividualsWithALP => commit('setLicenseExceptionIndividualsWithALP', licenseExceptionIndividualsWithALP))
        .catch(reason => {
          //JSON.parse(reason.response.headers['x-accessibleleas'])
          if (reason.response.status == 403) {
            return Promise.reject({
              message: 'Not allowed to view license exception individiuals for this LEA'
            })
          }
        })
    },
    getNBCTData: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/nbctData`, true)
        .then(data => {
          commit('setNbctTrendData', data.trendData)
          commit('setNbctSystemCounts', data.systemCounts)
          commit('setNbctConfirms', data.confirms)
        })
        .catch(reason => {
          if (reason.response.status == 403) {
            return Promise.reject({
              message: 'Not allowed to view NBCT details for this LEA'
            })
          }
        })
    },
    getNBCTDetailData: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/nbct-details?showDiscrepanciesOnly=${payload.showDiscrepanciesOnly}`, true)
        .then(data => {
          commit('setNbctDetailData', data.detailData)
        })
        .catch(reason => {
          if (reason.response.status == 403) {
            return Promise.reject({
              message: 'Not allowed to view NBCT details for this LEA'
            })
          }
        })
    },
    getPublicEducatorDetails: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/public-educators/details`)
        .then(data => {
          commit('setStaffDetails', data.details)
        })
        .catch(reason => {
          //JSON.parse(reason.response.headers['x-accessibleleas'])
          if (reason.response.status == 403) {
            return Promise.reject({
              message: 'Not allowed to view educators for this LEA'
            })
          }
        })
    },
    getEmployabilityBackgroundChecks: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/employabilityChecks/statistics`)
        .then(data => {
          commit('setEmployabilityBackgroundChecks', data)
        })
    },
    getEthicsViolations: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/ethicsViolations`)
        .then(data => {
          commit('setEthicsViolations', data)
        })
    },
    searchEmployees: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/employeeSearch?searchTerm=${payload.searchTerm}`)
        .then(data => {
          commit('setEmployees', data)
        })
    },
    getNewTeachersByRace: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/overview/newTeachersByRace`)
        .then(data => {
          commit('setNewTeachersByRace', data)
        })
    },
    getNoviceTeachersByRace: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/hcpt/${payload.lea}/overview/noviceTeachersByRace`)
        .then(data => {
          commit('setNoviceTeachersByRace', data)
        })
    },
  }
}
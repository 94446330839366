<template>
  <div>
    <paginated-table :items="transformedAccountabilityStatusRecords" :fields="fields">
      <template v-slot:head(actionColumn)>
        <b-button variant="primary" :to="{name: 'add-status-record'}">
          Add Status
        </b-button>
      </template>
      <template v-slot:cell(statusCode)="data">
        {{ data.value }} - {{ AccountabilityStatus.get(data.value) }}
      </template>
      <template v-slot:cell(actionColumn)="data">
        <b-button
          variant="link"
          size="xs"
          @click="onDeleteItem(data.item)"
          class="text-decoration-none text-danger"
          title="Remove Pair"
        >
          <span class="fas fa-times" title="Remove Status" />
        </b-button>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PaginatedTable from '../../../PaginatedTable'
import { AccountabilityStatus } from '../../leaAssignment'

export default {
  name: 'lea-accountability-status',
  data() {
    return {
      AccountabilityStatus,
      fields: [
        { key: 'lea', sortable: true },
        { key: 'schoolName', sortable: true },
        { key: 'gradeRange', sortable: true, class: 'text-center' },
        { key: 'statusCode', sortable: true, class: 'text-center' },
        { key: 'actionColumn', class: 'text-right' }
      ]
    }
  },
  computed: {
    ...mapState('toolsModule/leaAssignment', ['leaAccountabilityStatusRecords']),
    ...mapState('globalModule', ['leaContext']),
    transformedAccountabilityStatusRecords() {
      return this.leaAccountabilityStatusRecords.map(i => {
        var s = this.leaContext.LeaDictionary[i.lea]
        return {
          ...i,
          schoolName: s ? s.name : '(Invalid LEA)'
        }
      })
    }
  },
  methods: {
    onDeleteItem(item) {
      this.$bvModal.msgBoxConfirm(`Are you sure you want to delete the code for ${item.lea}?`).then(result => {
        if (result)
          this.$store.dispatch('toolsModule/leaAssignment/deleteAccountabilityStatusRecord', {
            id: item.id
          })
      })
    }
  },
  components: {
    PaginatedTable
  }
}
</script>
<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <b-table-lite small :items="local" :fields="fields">
          <template v-slot:cell(current)="data">
            <b-form-input v-model.number="data.item.current" type="number" class="text-right" :disabled="isReadonly" />
          </template>
        </b-table-lite>
      </div>
    </div>
  </div>
</template>
<script>
import { JsonResponseMixin } from '@/components/JsonResponseMixin'

export default {
  mixins: [JsonResponseMixin],
  data() {
    return {
      fields: [
        { key: 'type'},
        { key: 'current', thClass: 'text-center', thStyle: 'width: 125px;'},
        //{ key: 'previous' },
      ]
    }
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: false,
    },
  }
}
</script>
<template>
  <div class="card bg-light p-2">
    <div class="row">
      <div class="col-12">
        <div class="row" style="margin-left:auto; margin-right:auto;">
          <div class="col-md-11">
            <chart-data-toggle v-model="chartMode" @input="chartMode = $event" />
          </div>  
        </div>
        <div class="chartElem" v-show="chartMode">
          <highcharts class="chart" :options="chartOptions" />
        </div>
        <div v-show="!chartMode">
          <chart-data-table :items="dataItems()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ChartMixin } from '../ChartMixin'
import ChartDataToggle from '../ChartDataToggle.vue'

function sum(prev, next){
  return prev + next;
}

const typeCategories = ['School provided internet access', 'Home/Self-provided intenet access', 'Other']

export default {
  components: {
    ChartDataToggle
  },
  props: {
    inputData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'bar'
        },
        title: {
          text: 'Internet Access Types'
        },
        plotOptions: {
          bar: {
            minPointLength: 1
          }
        },          
        yAxis: {
          title: {
            text: 'Count'
          },
          allowDecimals: false
        },
        legend: {
          enabled: false
        },
        xAxis: {
          categories: typeCategories
        },
        credits: {
          enabled: false
        }
      },
      chartMode: true
    }
  },
  mixins: [ChartMixin],
  methods: {
    dataItems() {
      return [
        { System: 'School provided internet access', Count: this.inputData.map(r => r.educatorBroadbandProvidedSchool).reduce(sum) },
        { System: 'Home/Self-provided internet access', Count: this.inputData.map(r => r.educatorBroadbandProvidedSelf).reduce(sum) },
        { System: 'Other', Count: this.inputData.map(r => r.educatorBroadbandProvidedOther != null ? 1 : 0).reduce(sum) }
      ]
    }
  },
  watch: {
    inputData: {
      immediate: true,
      handler(newData) {
        this.chartOptions.series = [{
          data: [
            newData.map(r => r.educatorBroadbandProvidedSchool).reduce(sum),
            newData.map(r => r.educatorBroadbandProvidedSelf).reduce(sum),
            newData.map(r => r.educatorBroadbandProvidedOther != null ? 1 : 0).reduce(sum)
          ],
          name: 'Educator Internet Access Type Counts',
          colorByPoint: true
        }]
      }
    }
  }
}
</script>

<style>

</style>
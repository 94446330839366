<template>    
  <loadable-chart-card :load-data="loadData" :enable-data-toggle="false" card-style="">
    <span v-if="selectedRange" :style="{backgroundColor: currentColor}" class="badge text-white">
      {{ BackgroundCheckRanges.find(r => r.field == selectedRange || r.sfaField == selectedRange).name }}
      <i class="far fa-times-circle cursor-pointer" @click="clearCurrentRange" />
    </span>
    <background-check-filter @filter-updated="onFilterUpdated" />
    <table-control-widget :per-page="perPage" @filter-changed="filter=$event" @page-length-changed="perPage=$event" />
    <div class="w-100 p-2">
      <b-table id="employee-checks-table"
               :items="selectedBackgroundChecks"
               :fields="fields"
               :per-page="perPage"
               :current-page="currentPage"
               :filter="filter"
               @filtered="onFiltered"
               small striped>
        <template v-slot:cell(mostRecentBgcDate)="data">
          {{ data.value | date("MM/DD/YYYY") }}
          <span v-if="data.item.mostRecentBgcYears">&ndash; {{ data.item.mostRecentBgcYears }} year(s)</span>
        </template>
        <template v-slot:cell(caseId)="data">
          <aels-case-id-link :case-id="data.value.toString()" />
        </template>
        <template v-slot:cell(mostRecentProximitySatisfyingBgcGroupDate)="data">
          {{ data.value | date("MM/DD/YYYY") }}
          <span v-if="data.item.mostRecentProximitySatisfyingBgcGroupYears">&ndash; {{ data.item.mostRecentProximitySatisfyingBgcGroupYears }} year(s)</span>
        </template>
        <template v-slot:cell(leas)="data">
          <div class="d-inline">
            <div class="d-inline" v-for="lea in data.value" :key="lea">
              <a href="#" @click="goToLea(lea)" 
                 :id="data.index + '-' + lea">{{ lea }}</a>
                            &nbsp;
              <lea-tooltip :target-selector="data.index + '-' + lea"
                           :lea-number="lea" />
            </div>
          </div>
        </template>
        <template slot="empty">
          <h4>No employees found</h4>
        </template>
      </b-table>
      <table-pagination-widget 
        :total-rows-count="rowCount" 
        :page-length="perPage" 
        @page-changed="currentPage=$event" />
    </div>
  </loadable-chart-card>
</template>

<script>

import { BackgroundCheckRanges } from './BackgroundCheckRanges'
import { ChartMixin } from '../../ChartMixin'
import { TableMixin } from '../../TableMixin'
import { mapGetters } from 'vuex'
import BackgroundCheckFilter from './BackgroundCheckFilter'

export default {
    name: 'employee-checks-table',
    data() {
        return {selected: null,
            BackgroundCheckRanges,
            selectedRange: 'fullBgcNever',
            perPage: this.$printMode ? Number.MAX_SAFE_INTEGER : 10,
            currentPage: 1,
            filter: null,
            rowCount: 1,
            animationDuration: 1000,
            fields: [
                { key: 'caseId', sortable: true },
                { key: 'fullName', sortable: true },
                { key: 'mostRecentBgcDate', label: 'Most Recent BGC', sortable: true },
                { key: 'mostRecentProximitySatisfyingBgcGroupDate', label: 'Most Recent SFA BGC', sortable: true },
                { key: 'leas', label: 'LEAs', sortable: false }
            ]
        }
    },
    mixins: [ChartMixin, TableMixin],
    components: {
        BackgroundCheckFilter
    },
    computed: {
        leaDictionary() {
            return this.$store.state.globalModule.leaContext.LeaDictionary
        },
        selectedBackgroundChecks(){
            const backgroundChecks = this.filteredEmployabilityBackgroundChecks
            const flattened = this.selectedRange != '' ? backgroundChecks.filter(c => c[this.selectedRange] == 1) : backgroundChecks
            this.onFiltered(flattened)
            return [...new Set(flattened)]
        },
        
        ...mapGetters('hcptModule', ['filteredEmployabilityBackgroundChecks'])
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.rowCount = filteredItems.length
            this.currentPage = 1
        },
        goToLea(leaNumber) {
            const lea = this.leaDictionary[leaNumber]
            window.scrollTo(0,0)
            this.$store.dispatch('globalModule/changeSelectedLea', lea)
        },
        clearCurrentRange(){
            this.selectedRange = ''
        },
        onFilterUpdated(newRange){
          if(newRange){
            this.selectedRange = newRange
          }
          else{
            this.clearCurrentRange()
          }
        }
    },
    props:{
        currentRange: String,
        currentColor: String
    },
    watch: {
        filteredEmployabilityBackgroundChecks: {
            immediate: true,
            handler(individuals) {
                this.rowCount = individuals.length
                this.currentPage = 1
            }
        },
        currentRange: {
            handler(newRange) {
                this.selectedRange = newRange
            }
        }
    }
}
</script>
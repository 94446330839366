<template>
  <div>
    <div class="card mt-3 bg-light">
      <div class="card-header">
        Users
      </div>
      <div class="card-body">
        <loadable-chart-card :load-data="loadData" :enable-data-toggle="false" card-style="overflow-y: hidden">
          <perkins-v-user-table :items="users" />
        </loadable-chart-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PerkinsVUserTable from './PerkinsVUserTable.vue'
import { Types } from '@/modules/cte/stores/perkinsv'
import { Types as CommonTypes } from '@/modules/cte/stores'
import LoadableChartCard from '@/components/LoadableChartCard.vue'

export default {
  data() {
    return {
      loadData: Promise.resolve(true)
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.users]),
    ...mapGetters(Types.path, [Types.getters.activeAcademicYear]),
  },
  components: {
    PerkinsVUserTable,
    LoadableChartCard,
  },
  mounted() {
    const self = this
    var loadData = self.activeAcademicYear > 0 ? 
      this.$store.dispatch(CommonTypes.actions.getOAMetadata, { ay: self.activeAcademicYear }) : 
      this.$store.dispatch(Types.actions.getAcademicYears).then(() =>{
        return this.$store.dispatch(CommonTypes.actions.getOAMetadata, { ay: self.activeAcademicYear })
      })
    this.loadData = loadData.then(() => this.$store.dispatch(Types.actions.getUsers, {}))
  }
}
</script>

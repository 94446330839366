<template lang="html">
  <div>
    <div class="readiness-parent">
      <div class="readiness-chart" v-for="(value, propName) in groupedReadinessLevels" :key="propName" :class="{'col-md-12 px-0' : subject != null, 'col-md-4': subject == null, 'px-0' : propName == 'ELA' || propName == 'SCI'}">
        <readiness-level-chart :subject="propName" :scores="value" :selected-lea="selectedLea" />   
      </div>
      <div class="alert alert-warning" v-if="Object.keys(groupedReadinessLevels).length === 0">
        No Data available for Selected Options. Please select a different Grade Level or Subject.
      </div>
      <div style="clear:both;" />
    </div>
    <div class="mt-3">
      <b-table-lite :items="readinessLevelsForTable" :fields="tableFields">
        <template v-slot:cell(readinessLevel)="data">
          {{ ReadinessLevelHash[data.value] }}
        </template>
        <template v-slot:cell(avgGrowthScore)="data">
          <span>{{ data.value }} <sign-indicator :value="data.value" /></span>
        </template>
      </b-table-lite>
    </div>
  </div>
</template>

<script>
import ReadinessLevelChart from './ReadinessLevelChart'
import { mapGetters } from 'vuex'
import { ReadinessLevelFormatter, ReadinessLevelHash } from './readiness'
import SignIndicator from '../../../SignIndicator'
import orderBy from 'lodash.orderby'

export default {
  name: 'readiness-levels',
  data() {
    return { ReadinessLevelHash }
  },
  components: {
    ReadinessLevelChart,
    SignIndicator
  },
  computed: {
    ...mapGetters('academicsModule/growth', ['readinessSummary']),
    readinessLevelsForTable() {
      return orderBy(this.readinessSummary, ['group', 'subject', 'readinessLevel'], ['asc', 'asc', 'desc'])
    },
    groupedReadinessLevels() {
      const grouped = this.readinessSummary.reduce((r, item) => {
        r[item.subject] = r[item.subject] || []
        r[item.subject].push(item)
        return r
      }, Object.create(null))

      return grouped
    },
    tableFields() {
      var columns = new Array()

      if (this.readinessSummary && this.readinessSummary.length > 0) {
        for (const prop in this.readinessSummary[0]) {
          columns.push(prop)
        }
      }

      if (!this.grade) {
        var idx = columns.indexOf("grade")
        columns.splice(idx, 1)
      }

      columns.splice(columns.indexOf("lowerConfidenceLimit"), 1)
      columns.splice(columns.indexOf("upperConfidenceLimit"), 1)

      return columns
    },
    fiscalYear() {
      return this.$store.state.academicsModule.growth.fiscalYear
    }
  },
  methods: {
    readinessLevelFormatter(readinessLevel) {
      return ReadinessLevelFormatter(readinessLevel)
    },
    refresh() {
      this.loadData = this.$store.dispatch('academicsModule/growth/getReadinessSummary').then(() => {
        setTimeout(window.dispatchEvent(new Event('resize')), 500)
      })
    },
    dataItems() {
      return this.readinessSummary.map(g => {
        return {
          Subject: g.subject,
          Subgroup: g.subgroup,
          ReadinessLevel: g.readinessLevel,
          NumberSampled: g.numberSampled,
          AverageGrowthScore: g.avgGrowthScore,
          GrowthStdDev: g.standardDeviation,
          MinGrowthScore: g.minGrowthScore,
          MaxGrowthScore: g.maxGrowthScore,
          LowerConfidenceLimit: g.lowerConfidenceLimit,
          UpperConfidenceLimit: g.upperConfidenceLimit
        } 
      })
    },
    excelExportData() {
      return {
        name: 'Readiness Level Summary',
        items: this.dataItems()
      }
    }
  },
  mounted() {
    this.refresh()
  },
  props: {
    subject: String,
    grade: String
  },
  watch: {
    grade() {
      this.refresh()
    },
    subject() {
      this.refresh()
    },
    selectedLea() {
      this.refresh()
    },
    fiscalYear() {
      this.refresh()
    }
  }
}
</script>
<template>
  <form :id="formId">
    <div v-if="item">
      <div class="form-group row mb-0">
        <b-toast
          :id="errorsToastId"
          title="LEA Change Issues"
          solid
          header-class="bg-primary text-white"
          no-auto-hide
        >
          <b-list-group>
            <b-list-group-item
              v-for="error in errors.flatMap(e => e)"
              :key="JSON.stringify(error)"
            >
              {{ error.error }}
            </b-list-group-item>
          </b-list-group>
        </b-toast>
      </div>

      <div class="form-group row mb-0 mt-3 mb-1">
        <div class="col-md-12">
          <h5 class="font-weight-bold">
            Changes Requested
          </h5>
        </div>
      </div>

      <div class="form-group row mb-0" v-if="item.currentValues">
        <div class="col-md-3 mb-3 mt-1">
          Current Values:
        </div>
        <div class="col-md-9 mb-3 mt-1">
          <ul>
            <li>Name: {{ item.currentValues.leaName }}</li>
            <li>Address: {{ `${item.currentValues.address}, ${item.currentValues.city}, ${item.currentValues.state},${item.currentValues.zip}` }} </li>
          </ul>
        </div>
      </div>

      <div class="form-group row mb-0">
        <label class="col-md-3 mb-3 mt-1">Name:</label>
        <b-form-input
          required
          placeholder="Enter Requested LEA Name"
          class="col-md-9 mb-2"
          v-model="item.leaName"
        />
      </div>

      <div class="form-group row mb-0">
        <label class="col-md-3 mb-3 mt-1">Mailing Address:</label>
        <b-form-input
          required
          placeholder="Enter Requested LEA Mailing Address"
          class="col-md-9 mb-2"
          v-model="item.address"
        />
      </div>

      <div class="form-group row mb-0">
        <label class="col-md-3 mb-3 mt-1">City:</label>
        <b-form-input
          required
          placeholder="City"
          class="col-md-9 mb-2"
          v-model="item.city"
        />
      </div>

      <div class="form-group row mb-0">
        <label class="col-md-3 mb-3 mt-1">State:</label>
        <b-form-input
          required
          placeholder="State"
          class="col-md-9 mb-2"
          v-model="item.state"
        />
      </div>

      <div class="form-group row mb-0">
        <label class="col-md-3 mb-3 mt-1">Zip:</label>
        <b-form-input
          required
          placeholder="Zip"
          class="col-md-9 mb-2"
          v-model="item.zip"
        />
      </div>

      <div class="form-group row mb-0">
        <label class="col-md-3 mb-3 mt-1">Zip+4:</label>
        <b-form-input
          required
          placeholder="Zip 4 (optional)"
          class="col-md-9 mb-2"
          v-model="item.zip4"
        />
      </div>

      <div class="form-group row mb-0">
        <label class="col-md-3 mb-3 mt-1">Additional Comments:</label>
        <textarea class="form-control col-md-9" v-model="item.additionalComments">
          Enter any additional instructions or comments here...
        </textarea>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex'
import { RequestItemMixin } from '../RequestItemMixIn'
import { ItemValidationMixin } from './ItemValidationMixin'

export default {
  name: 'change-district-lea-item',
  data() {
    return {
      type: 'District LEA Change Request',
      requiredFields: ['leaName']
    }
  },
  props: {
    item: Object
  },
  computed: {
    ...mapState('toolsModule/leaAssignment', ['district']),
  },
  methods: {
  },
  mixins: [RequestItemMixin, ItemValidationMixin]
}
</script>

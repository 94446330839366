<template>
  <div>
    <nt-pos-list :ay="$attrs.ay" />
  </div>
</template>

<script>
import NtPosList from './NtPosList.vue'

export default {
  components: { NtPosList },
  data() {
    return {
      }
  }
}
</script>
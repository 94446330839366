function initState(store, stateName, defaultValue) {
  const value = defaultValue || []
  store.state[stateName] = value
}

function initMutation(store, stateName, mutation) {
  if (!mutation) return null
  else if (typeof mutation === 'object') {
    store.mutations = { ...store.mutations, ...mutation }
    return Object.keys(mutation)[0]
  }
  else if (typeof mutation === 'string') {
    store.mutations[mutation] = (state, data) => state[stateName] = data
    return mutation
  }
}

function initAction(store, action, defaultAction) {
  if (typeof action === 'object') {
    store.actions = { ...store.actions, ...action }
  }
  store.actions[action] = defaultAction
}

function createGetTransaction(stateName, mutation = null, action, urlBuilder, defaultValue = null) {
  initState(this, stateName, defaultValue)
  const mutationName = initMutation(this, stateName, mutation)
  
  initAction(this, action, ({ commit }, payload) => {
    const url = urlBuilder(payload)
    return this._apiService.Get(url)
      .then(data => {
        if (mutationName) {
          commit(mutationName, data)
        }
        return data
      })
  })
}

function createInsertTransaction(stateName, mutation = null, action, urlBuilder, payloadBuilder, defaultValue = null) {
  initState(this, stateName, defaultValue)
  const mutationName = initMutation(this, stateName, mutation)

  initAction(this, action, ({ commit }, payload) => {
    const url = urlBuilder(payload)
    const postPayload = payloadBuilder(payload)
    return this._apiService.Post(url, postPayload)
      .then(data => {
        if (mutationName) {
          commit(mutationName, data)
        }
        return data
      })
  })
}

function createUpdateTransaction(stateName, mutation = null, action, urlBuilder, payloadBuilder, defaultValue = null) {
  initState(this, stateName, defaultValue)
  const mutationName = initMutation(this, stateName, mutation)

  initAction(this, action, ({ commit }, payload) => {
    const url = urlBuilder(payload)
    const postPayload = payloadBuilder(payload)
    return this._apiService.Put(url, postPayload)
      .then(data => {
        if (mutationName) {
          commit(mutationName, data)
        }
        return data
      })
  })
}

function createDeleteTransaction(stateName, mutation = null, action, urlBuilder, payloadBuilder, defaultValue = null) {
  initState(this, stateName, defaultValue)
  const mutationName = initMutation(this, stateName, mutation)

  initAction(this, action, ({ commit }, payload) => {
    const url = urlBuilder(payload)
    const postPayload = payloadBuilder(payload)
    return this._apiService.Delete(url, postPayload)
      .then(data => {
        if (mutationName) {
          commit(mutationName, data)
        }
        return data
      })
  })
}
  

export { createGetTransaction, createInsertTransaction, createUpdateTransaction, createDeleteTransaction }
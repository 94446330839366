<template>
  <loadable-chart-card :load-data="loadData" :enable-data-toggle="false">
    <div class="p-3">
      <h5>Board Members ({{ maxyear }})</h5>
      <table class="table table-sm table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Training Hours</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="boardmember of currentboard" :key="boardmember.firstName+boardmember.lastName">
            <td>{{ boardmember.firstName }} {{ boardmember.lastName }}</td>
            <td>{{ boardmember.hoursOfTraining }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </loadable-chart-card>
</template>

<script>
import LoadableChartCard from '../../LoadableChartCard.vue'

export default {
  data() {
    return {}
  },
  props: {
    loadData: Promise
  },
  computed: {
    schoolboard() {
      return this.$store.state.overviewModule.schoolboard.schoolboard
    },
    maxyear() {
      return (this.$store.state.overviewModule.schoolboard.maxyear % 1990) + 1990
    },
    currentboard() {
      return (this.schoolboard || []).filter(b => (b.schoolYear % 1990) + 1990 == this.maxyear)
    }
  },
  components: {
    LoadableChartCard
  },
  watch: {
    selectedLea() {}
  }
}
</script>

function deepClone(source) {
  return JSON.parse(JSON.stringify(source))
}

function debounce(fn, delay) {
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}

function unique(value, index, self) {
  return self.indexOf(value) === index
}

const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    delete obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const isParentedBy = (lea, parentFilter) => {
  if (lea.parentLea) {
    if (lea.parentLea.number === parentFilter.number && lea.parentLea.type === parentFilter.type)
      return true
    else {
      return isParentedBy(lea.parentLea, parentFilter)
    }

  }
  return false
}

const getDPSAFTfilterQueryString = (payload) => {
  var qs = ''
  if (payload.areaId)
    qs += `&areaId=${payload.areaId}`
  if (payload.districtLea)
    qs += `&districtLea=${payload.districtLea}`
  if (payload.coopLea)
    qs += `&coopLea=${payload.coopLea}`
  if (payload.building)
    qs += `&buildingFilter=${payload.building}`
  if (payload.batchStatus)
    qs += `&batchStatus=${payload.batchStatus}`
  if (typeof(payload.districtNotificationStatus) !== 'undefined')
    qs += `&districtNotificationStatus=${payload.districtNotificationStatus}`
  if (typeof(payload.districtAcknowledgementStatus) !== 'undefined')
    qs += `&districtAcknowledgementStatus=${payload.districtAcknowledgementStatus}`   
    if(payload.fy){
      qs+= `&fy=${payload.fy}`
    } 


  if (!(payload.includeLatestInspection == null && payload.includeLatestInspection == undefined))
    qs += `&includeLatestInspection=${payload.includeLatestInspection}`

  return qs
}

const nameof = (obj, expression) => {
  var res = {}
  Object.keys(obj).map(k => { res[k] = () => k })
  return expression(res)()
}

function isFloat(num) {
  return typeof num === 'number' && !Number.isInteger(num);
}

const isEquivalent = (a, b, propertiesToCompare) => {
  var aProps = propertiesToCompare || Object.getOwnPropertyNames(a)
  
  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i]

    if (Array.isArray(a[propName])) {
      if (!a[propName].every((itemA, index) => isEquivalent(itemA, b[propName][index]) )) {
        return false
      }
    }
    else if (isFloat(a[propName])) {
      if (Math.round(parseFloat(a[propName])*10000000) != Math.round(parseFloat(b[propName])*10000000)) {
        return false
      }
    }
    else if (a[propName] != b[propName]) {
      return false
    }
  }
  return true
}

function initializeJSONResponse(parent, responseName, defaultValue) {
  if (parent && (typeof parent[responseName] == 'string' || !parent[responseName])) {
    parent[responseName] = JSON.parse(parent[responseName] || JSON.stringify(defaultValue || {}))
  }
}

export {
  deepClone,
  debounce,
  groupBy,
  isParentedBy,
  unique,
  getDPSAFTfilterQueryString,
  nameof,
  isEquivalent,
  initializeJSONResponse
}
export const buildingTypes = [{
    value: 'ES',
    text: 'Elementary School'
  },
  {
    value: 'MS',
    text: 'Middle School'
  },
  {
    value: 'HS',
    text: 'High School'
  },
]

export function buildingTypeFormatter(buildingType) {
  var type = buildingTypes.find(t => t.value === buildingType)
  return type ? type.text : "N/A"
}

// export function districtTypeFormatter(districtType) {
//   var type = districtTypes.find(t => t.value === districtType)
//   return type ? type.text : "N/A"
// }
<template>
  <div>
    <div class="card-group">
      <loadable-chart-card :load-data="loadData">
        <template v-slot:default="slotProps">
          <div class="chartElem" v-show="slotProps.chartMode">
            <highcharts class="chart" :options="chartOptions" />
          </div>
          <div v-show="!slotProps.chartMode">
            <chart-data-table :items="dataItems()" />
          </div>
        </template>
      </loadable-chart-card>
    </div>
    <div class="card-group">
      <loadable-chart-card :load-data="loadData" :enable-data-toggle="false" card-style="">
        <div class="w-100 p-2">
          <span v-if="currentRange" :style="{ backgroundColor: currentColor }" class="badge text-white">{{ ExpirationRanges.find(r => r.field == currentRange).name }} <i class="far fa-times-circle cursor-pointer" @click="clearCurrentRange" /></span>
          <paginated-table small striped
                           id="expiring-license-table"
                           :items="selectedLicenses"
                           :per-page="perPage"
                           :fields="fields"
                           @filtered="onFiltered">
            <template v-slot:cell(leas)="data">
              <div class="d-inline">
                <div class="d-inline" v-for="lea in data.value" :key="lea">
                  <a href="#" @click="goToLea(lea)" 
                     :id="data.index + '-' + lea">{{ lea }}</a>
                                    &nbsp;
                  <lea-tooltip :target-selector="data.index + '-' + lea"
                               :lea-number="lea" />
                </div>
              </div>
            </template>
            <template v-slot:cell(caseId)="data">
              <aels-case-id-link :case-id="data.value.toString()" />
            </template>
            <template v-slot:cell(fullName)="data">
              <a :href="'mailto:' + data.item.emailAddress 
                + '?subject=AELS License Expiration Notice'
                + '&body=' + data.item.fullName
                + ', this notice is to inform you that your license will be expiring on ' + data.item.expirationDate
                + '.'">{{ data.value }}</a>
            </template>
            <template v-slot:cell(licenses)="data">
              <ul style="list-style:none; padding-left: 0;">
                <li v-for="license in getUniqueLicenses(data.value).slice(0, 3)" :key="license.code">
                  {{ license.code }} &ndash; {{ license.areaName }}
                </li>
              </ul>
              <a v-if="data.value.length > 3" class="text-secondary cursor-pointer" v-b-modal="'modal-' + data.item.caseId">
                <i class="fas fa-ellipsis-h" />
              </a>
              <b-modal :id="'modal-' + data.item.caseId">
                <template slot="modal-title">
                  {{ data.item.fullName }} &ndash; Expiration: {{ data.item.expirationDate }}
                </template>
                <ul style="list-style:none;">
                  <li v-for="license in getUniqueLicenses(data.value)" :key="license.code">
                    {{ license.code }} &ndash; {{ license.areaName }}
                  </li>
                </ul>
              </b-modal>
            </template>
            <template slot="empty">
              <h4>No expired licenses selected</h4>
            </template>
          </paginated-table>
        </div>
      </loadable-chart-card>
    </div>
  </div>
</template>

<script>
import PaginatedTable from '../../PaginatedTable.vue'
import LeaTooltip from '../../LeaTooltip.vue'
import { ExpirationRanges } from './ExpirationRanges'
import { ChartMixin } from '../../ChartMixin'
import { TableMixin } from '../../TableMixin'
import { mapGetters } from 'vuex'
import { credits } from '../../../helpers/credits'

function createPlotOptions(vm) {
  return {
    series: {
      cursor: 'pointer',
      events: {
        click() {
          vm.setCurrentRange(this.userOptions.field, this.color)
        }
      },
      marker: {
        enabled: true
      },
      minPointLength: 3
    }
  }
}
export default {
    name: 'expiring-license-chart',
    data() {
      return {
        ExpirationRanges,
        currentRange: '',
        currentColor: '',
        perPage: 10,
        selectedItemsCount: 1,
        animationDuration: 1000,
        fields: [
          { key: 'caseId', sortable: true },
          { key: 'leas', label: 'LEAs', sortable: false },
          { key: 'fullName', sortable: true },
          { key: 'licenses', sortable: false },
          { key: 'expirationDate', sortable: true }
        ],
        chartOptions: {
          chart: {
            type: 'column'
          },
          credits: credits.SISandAELS,
          title: {
            text: 'Expiring / Expired Licenses'
          },
          series: ExpirationRanges,
          plotOptions: createPlotOptions(this),
          xAxis: {
            title: {
              text: 'Expiring On'
            },
            type: 'category'
          },
          yAxis: {
            title: {
              text: 'Number of Licenses'
            },
            endOnTick: false
          }
        }
      }
    },
    mixins: [ChartMixin, TableMixin],
    components: {
      PaginatedTable,
      LeaTooltip
    },
    computed: {
      leaDictionary() {
        return this.$store.state.globalModule.leaContext.LeaDictionary
      },
      selectedLicenses() {
        const licenses = this.expiredLicensesByRange
        const flattened = licenses[this.currentRange] || Object.keys(licenses).flatMap(function(k) { return licenses[k] })
        return [...new Set(flattened)]
      },
      ...mapGetters('hcptModule', ['expiredLicensesByRange'])
    },
    methods: {
      getUniqueLicenses(licenses) {
        return licenses.map(l => 
        { 
          return { code: l.code, areaName: l.areaName }
        }).filter((value, index, self) => {
          return self.findIndex(l => l.code == value.code) == index 
        })
      },
      setCurrentRange(selectedRange, currentColor) {
        this.currentRange = selectedRange
        this.currentColor = currentColor
      },
      clearCurrentRange() {
        this.currentRange = ''
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.selectedItemsCount = filteredItems.length
      },
      dataItems() {
        return ExpirationRanges.map((range, i) => {
          return { Range: range.name, NumberExpiring: this.chartOptions.series[i].data[0].y }
        })
      },
      goToLea(leaNumber) {
        const lea = this.leaDictionary[leaNumber]
        window.scrollTo(0,0)
        this.$store.dispatch('globalModule/changeSelectedLea', lea)
      },
      excelExportData() {
        const licenses = ExpirationRanges.flatMap(range => this.expiredLicensesByRange[range.field])
        return {
          name: 'Expiring Licenses',
          items: licenses.flatMap(item => {
            return item.licenses.map(license => {
              return { 
                CaseId: item.caseId,
                StateId: item.stateId,
                FullName: item.fullName,
                EmailAddress: item.emailAddress,
                Code: license.code,
                AreaName: license.areaName,
                ExpirationDate: item.expirationDate
              }
            })
          })
        }
      }
    },
    watch: {
        expiredLicensesByRange(newData) {
            ExpirationRanges.forEach((range, i) => {
                this.chartOptions.series[i] = { name: range.name, field: range.field, data: [{name: "Number Expiring", y: newData[range.field].length}]}
                this.chartOptions.series[i].stack = "Number Expiring"
            })
        },
        selectedLicenses(licenses) {
            this.selectedItemsCount = licenses.length
        }
    }
}
</script>
import teacherSurveyPage from './teacherSurvey/teacherSurveyPage'
import teacherSurveySidebar from './teacherSurvey/teacherSurveySidebar'
import teacherSurveyThankYou from './teacherSurvey/teacherSurveyThankYou'
import teacherSurveyAdminPage from './teacherSurvey/teacherSurveyAdminPage'
import DigitalLearningPage from './digitalLearning/DigitalLearningPage'
import DigitalLearningAdminPage from './digitalLearning/DigitalLearningAdminPage'
import DigitalLearningPublicPage from './digitalLearning/DigitalLearningPublicPage'
import DigitalLearningWaiverSnapshotPage from './digitalLearning/DigitalLearningWaiverSnapshotPage'


const SurveysArea = {
  teacherSurveyPage,
  teacherSurveySidebar,
  teacherSurveyThankYou,
  teacherSurveyAdminPage,
  DigitalLearningPage,
  DigitalLearningAdminPage,
  DigitalLearningPublicPage,
  DigitalLearningWaiverSnapshotPage,
}

export {
  SurveysArea
}


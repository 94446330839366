<template>
  <b-row>
    <b-col>
      <b-form class="border-bottom border-primary">
        <b-button v-if="!readonly" size="sm" variant="danger" class="float-right" @click="$emit('remove')">
          <i class="fas fa-times" />
        </b-button>
        <b-row>
          <b-form-group :label="`College ${sequence}`" class="col-md-3" label-class="font-weight-bold">
            <b-form-checkbox :disabled="readonly" v-model="value.isAttending">
              Attending
            </b-form-checkbox>
          </b-form-group>
          <b-form-group label="College" class="col-md-3">
            <b-form-select :disabled="readonly" :options="collegeOptions" v-model="value.college" @change="$set(value, 'degree', null); $set(value, 'major', null)" />
          </b-form-group>
          <b-form-group label="Degree Type" class="col-md-3">
            <b-form-select :disabled="readonly" :options="degreeOptions" v-model="value.degree" @change="$set(value, 'major', null)" />
          </b-form-group>
          <b-form-group label="Major" class="col-md-3">
            <b-form-select :disabled="readonly" :options="majorOptions" v-model="value.major" />
          </b-form-group>
        </b-row>
        <b-row>
          <b-form-group label="Date Applied to College" class="col-md-4">
            <b-datepicker :disabled="readonly" v-model="value.dateApplied" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" />
            <span class="text-danger" v-if="dateAppliedInvalid">*Date Applied is required.</span>
          </b-form-group>
          <b-form-group label="College Acceptance Status" class="col-md-4">
            <b-form-select :disabled="readonly" :options="staticMetadata.postSecondaryPlanning.collegeAcceptanceStatus" v-model="value.collegeAcceptanceStatus" />
          </b-form-group>
          <b-form-group label="Date Accepted to College" class="col-md-4">
            <b-datepicker :disabled="readonly" v-model="value.dateAccepted" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" />
          </b-form-group>
        </b-row>
        <b-row>
          <b-form-group label="Applied for Institutional Scholarships" class="col-md-3">
            <b-form-select :disabled="readonly" :options="staticMetadata.common.yesNo" v-model="value.appliedForInstitutionalScholarships" />
          </b-form-group>
          <b-form-group label="Scholarship Status" class="col-md-3">
            <b-form-select :disabled="readonly" :options="staticMetadata.postSecondaryPlanning.scholarshipAcceptanceStatus" v-model="value.scholarshipStatus" />
          </b-form-group>
          <b-form-group label="Scholarship Amount" class="col-md-3">
            <b-input-group prepend="$" append=".00">
              <b-form-input :readonly="readonly" v-model="value.scholarshipAmount" />
            </b-input-group>
          </b-form-group>
          <b-form-group label="Want to Schedule Visit" class="col-md-3">
            <b-form-select :disabled="readonly" :options="staticMetadata.common.yesNo" v-model="value.wantToScheduleVisit" />
          </b-form-group>
        </b-row>
      </b-form>  
    </b-col>
  </b-row>
</template>

<script>
import { gradeMap } from '@/helpers/gradeSpans'
import { metadata as staticMetadata } from '@/modules/cte/stores/careercoach/metadata'
import { mapState } from 'vuex'
import { Types } from '@/modules/cte/stores/careercoach'

export default {
  props: {
    value: Object,
    sequence: Number,
    readonly: Boolean
  },
  data() {
    return {
      gradeMap,
      staticMetadata
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.metadata]),
    collegeOptions() {
      return (this.metadata.collegesAndDegrees.colleges || []).map(c => { return { value: c.id, text: c.name }})
    },
    degreeOptions() {
      return (this.metadata.collegesAndDegrees.degrees || []).filter(c => c.parentId == this.value.college).map(c => { return { value: c.id, text: c.name }})
    },
    majorOptions() {
      return (this.metadata.collegesAndDegrees.majors || []).filter(c => c.parentId == this.value.degree).map(c => { return { value: c.id, text: c.name }})
    },
    dateAppliedInvalid() {
      if (this.readonly)
        return false;
      
      if (!this.value.dateApplied)
        return true;

      return false;
    }
  },
  watch: {
    value: {
      deep: true,
      handler(newData) {
        this.$emit('input', newData)
      } 
    }
  }
}
</script>
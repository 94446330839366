<template>
  <b-card>
    <b-form-row class="d-none d-md-flex">
      <div class="col-md-6">
        <b-form-group label-class="font-weight-bold" label="District">
          <span>{{ (leaContext.LeaDictionary[inspectionHeader.districtLEA] || {}).name || 'District' }}</span>
        </b-form-group>
        <b-form-group label-class="font-weight-bold" label="Building (facility)">
          <span>{{ inspectionHeader.buildingNumber }} &ndash; {{ inspectionHeader.buildingName }}</span>
        </b-form-group>
        <b-form-group label-class="font-weight-bold" label="LEA #">
          <span>{{ inspectionHeader.schoolNumber }}</span>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group label-class="font-weight-bold" label="Dep. Sq. Ft.">
          <span>{{ inspectionHeader.schoolDepSqFt }}</span>
        </b-form-group>        
        <b-form-group label-class="font-weight-bold" label="Depreciated Building Value">
          <span>{{ inspectionHeader.schoolDepValue }}</span>
        </b-form-group>
        <b-form-group label-class="font-weight-bold" label="Academic / Non-Academic">
          <span>{{ inspectionHeader.academicSpace ? 'Academic' : 'Non-Academic' }}</span>
        </b-form-group>
      </div>
    </b-form-row>
    <b-table 
      hover
      :items="inspections"
      :fields="fields"
      :load-data="loadData">
      <template v-slot:cell(createdDate)="data">
        <div>
          <span v-if="data.value">{{ formatDate(data.value) }}</span>
          <span v-else>N/A</span>
        </div>
      </template>
      <template v-slot:cell(completionDate)="data">
        <div>
          <span v-if="data.value">{{ formatDate(data.value) }}</span>
          <span v-else>N/A</span>
        </div>
      </template>
      <template v-slot:cell(status)="data">
        <div>
          <i class="text-secondary fas fa-clock" v-if="data.value == InspectionStatuses.NotStarted" title="Not Started" />
          <i class="text-primary fas fa-stopwatch" v-else-if="data.value == InspectionStatuses.InProgress" title="In Progress" />
          <i class="text-success fas fa-check" v-else-if="data.value == InspectionStatuses.Completed" title="Completed" />
          <i class="text-secondary fas fa-forward" v-else-if="data.value == InspectionStatuses.Skipped" title="Skipped" />
          <span v-else>N/A</span>
          &nbsp;
          <b-badge variant="secondary" v-if="data.value == InspectionStatuses.NotStarted">
            Not Started
          </b-badge>
          <b-badge variant="primary" v-if="data.value == InspectionStatuses.InProgress">
            In Progress
          </b-badge>
          <b-badge variant="warning" v-if="data.value == InspectionStatuses.Completed && hasFixComponents(data.item)">
            1+ Issues
          </b-badge>
          <b-badge variant="success" v-if="data.value == InspectionStatuses.Completed && !hasFixComponents(data.item)">
            No Issues
          </b-badge>
        </div>
      </template>
      <template v-slot:cell(showDetails)="data">  
        <b-button size="sm" @click="data.toggleDetails" class="mr-2">
          {{ data.detailsShowing ? 'Hide' : 'Show' }} Details
        </b-button>
      </template>
      <template v-slot:row-details="data">
        <inspection :inspection="data.item" :readonly="true" />
      </template>
    </b-table>
  </b-card>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import Inspection from './inspect/Inspection'
import { InspectionStatuses, RatingTypes } from '../common/Enums'

export default {
  name: 'building-inspection-history',
  data() {
    return {
      InspectionStatuses, 
      RatingTypes,
      loadData: null,
      fields: [
        {
          key: "isFollowup",
          label: 'Followup',
          formatter(value){
            return value ? "Yes" : "No"
          }
        },
        {
          key: 'wasWalkthroughConducted',
          label: "Walkthrough",          
          formatter(value){
            return value ? "Yes" : "No"
          }
        },
        {
          key: 'createdDate',
          label: 'Created'
        },
        {
          key: 'completionDate',
          label: 'Completed'
        },
        {
          key: 'status',
          label: 'Status'
        },
        {
          key: 'showDetails'
        }
      ]
    }
  },
  computed: {
    ...mapState('DPSAFT/Facilities', ['InspectionHistory']),
    ...mapState('globalModule', ['leaContext']),
    safeInspectionHistory(){
      return this.InspectionHistory ? this.InspectionHistory : { item1: {}, item2: [] }
    },
    inspectionHeader() {
      return this.safeInspectionHistory.item1
    },
    inspections(){
      return this.safeInspectionHistory.item2
    }
  },
  methods:{
    formatDate(date) {
      return moment(date).format('MM/DD/YYYY')
    },
    hasFixComponents(inspection) {
      return inspection.components.some(c => c.rating == RatingTypes.Fix)
    }
  },
  mounted() {
    this.loadData = this.$store.dispatch("DPSAFT/Facilities/getInspectionHistory", {buildingNumber: this.$route.params.buildingNumber, schoolNumber: this.$route.params.schoolLea})
  },
  components: {
    Inspection
  }
}
</script>

<template>
  <div class="card bg-light">
    <div class="card-body">
      <table class="table table-bordered">
        <tbody>
          <tr><td><p><strong>Contributing Professional </strong> </p></td><td><p>"Contributing professional" means an individual who has been assigned the responsibility to provide additional services that support and increase a student’s learning and/or access to learning. “Contributing professional” includes Classroom teacher, other than the teacher of record, who is engaged directly in instruction with students in a classroom setting; Guidance counselor; Library media specialist; Instructional facilitator or instructional coach; Teacher employed by an education service cooperative who instructs public school students; Speech language pathologist; Gifted and talented coordinator; School psychologist; English language learner instructor; Lead Professional Educator; or Person in another position identified by the Department.</p></td></tr>
          <tr><td><p><strong>Teacher of Record</strong> </p></td><td><p>"Teacher of Record" means an individual (or individuals in co-teaching assignments) who has been assigned the lead responsibility for a student’s learning in a subject/course with aligned performance measures.  A teacher of record plans instruction, delivers instruction, assesses student learning, and assigns grades. Teacher of Record--classroom or course teacher identified in eSchoolPLUS.</p></td></tr>
        </tbody>
      </table>
      <table class="table table-bordered">
        <tbody>
          <tr><td><p><strong>Role</strong> </p></td><td><p><strong>Job Code</strong> </p></td></tr>
          <tr><td><p><strong>Primary Teacher of record for tested course</strong> </p></td><td><p>Listed as primary staff (CL0060) for a tested course</p></td></tr>
          <tr><td><p><strong>Secondary Teacher of record for tested course</strong> </p></td><td><p>Listed as secondary staff (CL0070) for a tested course</p></td></tr>
        </tbody>
      </table>
      <table class="table table-bordered mt-2" v-if="roleIdentificationGroup1.length > 0">
        <tr>
          <th>
            Certified Job Code Title
          </th>
          <th>
            Rubric
          </th>
          <th>
            Course Code
          </th>
          <th>
            Job Code
          </th>
          <th>
            Coordinator Code
          </th>
        </tr>
        <tr v-for="role in roleIdentificationGroup1" :key="role.id">
          <td>
            {{ role.title || 'None' }}
          </td>
          <td>
            {{ role.rubric || 'None' }}
          </td>
          <td>
            {{ role.courseCode || 'None' }}
          </td>
          <td>
            {{ role.jobCodes || 'None' }}
          </td>
          <td>
            {{ role.coordinatorCodes || 'None' }}
          </td>
        </tr>
        <tr>
          <td colspan="5" />
        </tr>
        <tr>
          <th>Not under TESS/LEADS Requirements other than as evaluator</th>
          <td />
          <td />
          <td />
          <td />
        </tr>
        <tr v-for="role in roleIdentificationGroup2" :key="role.id">
          <td>
            {{ role.title || 'None' }}
          </td>
          <td>
            {{ role.rubric || 'None' }}
          </td>
          <td>
            {{ role.courseCode || 'None' }}
          </td>
          <td>
            {{ role.jobCodes || 'None' }}
          </td>
          <td>
            {{ role.coordinatorCodes || 'None' }}
          </td>
        </tr>
      </table>
      <div v-else>
        <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
          <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CardStateIcon from '@/components/CardStateIcon.vue'

export default {
  components:{
    CardStateIcon
  },
  mounted() {
    this.$store.dispatch('rvsModule/getRoleIdentification')
  },
  computed: {
    ...mapState('rvsModule', ['roleIdentification']),
    roleIdentificationGroup1() {
      return this.roleIdentification.filter(role => !['U', 'X'].includes(role.coordinatorCodes))
    },
    roleIdentificationGroup2() {
      return this.roleIdentification.filter(role => ['U', 'X'].includes(role.coordinatorCodes))
    }
  }
}
</script>
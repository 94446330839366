<template>
  <b-card class="mb-2 outer-card">
    <div v-if="leaType == LeaTypes.School || leaType == LeaTypes.District">
      <growth-filter />
      <student-table :subject="subject" :show-trends="true" :load-data="loadData" v-if="subject != 'elp'" />
      <div class="alert alert-warning" v-else>
        No Data available for ELP. Please select a different Subject.
      </div>
    </div>
    <div v-else class="alert alert-warning">
      No data available for selected LEA. Please select a school to view report
    </div>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import { PageMixin } from '../../../PageMixin'
import StudentTable from '../StudentTable.vue'
import GrowthFilter from '../GrowthFilter'

export default {
  name: 'student-trend-lines',
  data() {
    return {
      loadData: null
    }
  },
  components: {
    StudentTable,
    GrowthFilter
  },
  mixins: [PageMixin],
  computed: {
    ...mapState('academicsModule/growth', [
      'tab',
      'grade',
      'subject',
      'subgroup'
    ]),
    fiscalYear() {
      return this.$store.state.academicsModule.growth.fiscalYear
    }
  },
  methods: {
    refresh() {
      this.loadData = this.$store.dispatch(
        'academicsModule/growth/getStudentGrowth',
        { lea: this.selectedLea.number }
      )
    }
  },
  mounted() {
    this.refresh()
  },
  watch: {
    grade() {
      this.refresh()
    },
    subgroup() {
      this.refresh()
    },
    subject() {
      this.refresh()
      window.dispatchEvent(new Event('resize'))
    },
    selectedLea() {
      this.refresh()
    },
    fiscalYear() {
      this.refresh()
    }
  }
}
</script>
<template>
  <div>
    <p class="font-weight-bold mt-3">
      {{ question.text }}
    </p>

    <ul class="list-group list-group-flush" v-if="!editMode">
      <li class="list-group-item bg-transparent borderless" v-for="c in question.choices" :key="c">
        <pretty-checkbox :value="getValue(c)" /><span class="font-italic"> {{ c }}</span>
      </li>
    </ul>
    
    <b-form-group v-if="editMode">
      <b-form-radio-group 
        :id="'question_' + question.id"
        v-model="$v.survey[question.id].$model"
        :options="options"
        :name="'question_' + question.id"
        stacked
        class="font-italic"
      />
    </b-form-group>
  </div>
</template>

<script>

/// Display the question and checkboxes for a single selection.

import PrettyCheckbox from '../../../PrettyCheckbox'

export default {
  inject: ['$v'],
  name: 'question-single-selection',
  computed: {
    options() {
      return [ this.question.choices.map(c => { return { text: c, value: c }}), { html: "<i class='fa-fw fas fa-question text-warning'></i> No answer", value: null } ].flat(1)
    },
    value() {
      return this.$v.survey[this.question.id].$model
    }
  },
  methods: {
    getValue(c) {
      return this.value === c
    }
  },
  props: {
    question: {
      type: Object,
      default: () => {}
    },
    editMode: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    PrettyCheckbox
  }
}
</script>
<template lang="html">
  <div>
    <div>
      <pdf-button v-if="isSchoolCoopOrDistrict" />
      <excel-button title="Teacher Load" v-if="isSchoolCoopOrDistrict" />
      <h1>
        <custom-tooltip element-id="teacher-label" :content="'Source: <a href=' + credits.SFA.href + '>' + credits.SFA.text + '</a>'" /> 
        Teacher Load
      </h1>
      <h2>
        <lea-display :lea="selectedLea" />
      </h2>
    </div>
    <div>
      <violation-filter label="Teacher Load" />
    </div>
    <div class="card bg-light mt-3">
      <a class="anchor" id="teacherload" />
      <div class="card-header">
        Teacher Load
      </div>
      <div class="card-body">
        <div class="card-group">
          <div v-if="isSchoolCoopOrDistrict" class="w-100">
            <teacher-load-table :load-data="loadData" :selected-lea="selectedLea" />
          </div>
          <div class="alert alert-warning mt-4 w-100" v-else>
            Select a coop, district, or school to view Teacher Load Details.
          </div>
        </div>
      </div>
    </div>
    <div>
      <span class="pull-right">
        Source: <a :href="credits.SFA.href">{{ credits.SFA.text }}</a>
      </span>
    </div>
  </div>
</template>

<script>
import { PageMixin } from '../../PageMixin'
import { credits } from '../../../helpers/credits'
import { LeaTypes } from '../../../helpers/leaTypes'
import TeacherLoadTable from './TeacherLoadTable.vue'
import ViolationFilter from '../ViolationFilter.vue'

export default {
  mixins: [PageMixin],
  data() {
    return { 
      credits,
      loadData: null
    }
  },
  computed: {
    selectedLea() { return this.$store.state.globalModule.leaContext.SelectedLea },
    isSchoolCoopOrDistrict() {
      return this.selectedLea && 
      (this.selectedLea.type == LeaTypes.School || this.selectedLea.type == LeaTypes.District || this.selectedLea.type == LeaTypes.Coop)
    },
    showOnlyViolations() {
      return this.$store.state.scheduleModule.showOnlyViolations 
    }
  },
  components: {
    TeacherLoadTable,
    ViolationFilter
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler() {
        if (this.isSchoolCoopOrDistrict) {
          this.loadData = this.$store.dispatch('scheduleModule/getTeacherLoadDetails', { lea: this.selectedLea.number })
        }
      }
    }
  }
}
</script>

<template>
  <div> 
    <paginated-table :items="filteredWaiverRequests" :fields="fields" sort-by="createDate" :sort-desc="true" :filter-function="applyFilter">
      <template v-slot:cell(districtLEA)="data">
        <div>
          {{ leaFormatter(data.value) }} 
        </div>
      </template>
      <template v-slot:cell(createdDate)="data">
        {{ data.value | date }}
      </template>
      <template v-slot:cell(updatedDate)="data">
        {{ data.value | date }}
      </template>
      <template v-slot:cell(superintendent)="data">
        <a :href="`mailto:${data.item.email}`">
          {{ data.value }}
        </a>
      </template>
      <template v-slot:cell(phone)="data">
        <a :href="`tel:${data.item.phone}`">
          {{ data.value }}
        </a>
      </template>
      <template v-slot:cell(status)="data">
        <workflow-status-badge :state-map="StateMap" :status="data.value" :show-label="false" :document="data.item" />
      </template>
      <template v-slot:cell(actionColumn)="data">
        <b-button v-if="context == Context.Review" size="sm" variant="info" :to="{name: 'admin-waiver-request-review', query: { lea: data.item.districtLEA, waiverRequestId: data.item.waiverRequestId, }, params: { lea: data.item.districtLEA }}" class="mr-1">
          <i class="fa fa-fw fa-search" title="Review Request" />
        </b-button>
        <b-button v-else-if="context == Context.Public && data.item.waiverRequestSnapshotId > 0" size="sm" variant="primary" :to="{name: 'waiver-request-snapshot', query: { lea: data.item.districtLEA, waiverRequestSnapshotId: data.item.waiverRequestSnapshotId, }, params: { lea: data.item.districtLEA }}" class="mr-1">
          <i class="fa fa-fw fa-camera" title="View Request Snapshot" />
        </b-button>
        <b-button v-if="context == Context.Public && !data.item.waiverRequestSnapshotId" size="sm" variant="primary" :to="{name: 'public-waiver-request-view', query: { lea: data.item.districtLEA, waiverRequestId: data.item.waiverRequestId, }, params: { lea: data.item.districtLEA }}" class="mr-1">
          <i class="fa fa-fw fa-eye" title="View Request" />
        </b-button>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PaginatedTable from '@/components/PaginatedTable'
import WorkflowStatusBadge from '@/components/workflow/WorkflowStatusBadge.vue'
import { Types, StateMap, Context, isReopened, isExpired } from '@/stores/digitalLearning'
import { LeaTypes } from '@/helpers/leaTypes'
import { formatDate } from '@/helpers/formatters'

export default {
  data() {
    return {
      StateMap,
      Context
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.waiverRequests]),
    ...mapState('globalModule', ['userContext']),
    fields() { 
      let fields = [
        { key: 'fiscalYear', label: 'School Year', sortable: true, formatter: this.schoolYearFormat },
        { key: 'districtLEA', label: 'District', sortable: true },
        { key: 'superintendent' },
        { key: 'phone' },
        { key: 'createdDate', sortable: true },
        { key: 'updatedDate', label: 'Latest Modified Date', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'actionColumn', class: 'text-right nowrap', label: '' }
      ]

      return fields
    },
    filteredWaiverRequests() {
      let waiverRequests = this.waiverRequests.filter(r => r.waiverType == this.waiverType.value)

      if (this.isPublic && this.selectedLea.type == LeaTypes.Coop) {
        return waiverRequests.filter(w => this.selectedLea.ownedLeas.some(d => d.number == w.districtLEA))
      }
      if (this.filter.leaStartsWith) {
        waiverRequests = waiverRequests.filter(d => d.districtLEA[0] == this.filter.leaStartsWith || (this.leaDictionary[d.districtLEA] || { name: '' }).name[0] == this.filter.leaStartsWith)
      }
      if (this.filter.leaContains) {
        waiverRequests = waiverRequests.filter(d => d.districtLEA.includes(this.filter.leaContains) || (this.leaDictionary[d.districtLEA] || { name: '' }).name.includes(this.filter.leaContains))
      }
      if (this.filter.status === 0 || this.filter.status > 0) {
        waiverRequests = waiverRequests.filter(d => d.status == this.filter.status)
      }
      if (this.filter.status == 'Re-opened') {
        waiverRequests = waiverRequests.filter(isReopened)
      }
      if (this.filter.status == 'Expired') {
        waiverRequests = waiverRequests.filter(isExpired)
      }
      if (this.filter.fiscalYear) {
        waiverRequests = waiverRequests.filter(d => d.fiscalYear == this.filter.fiscalYear)
      }

      return waiverRequests
    },
  },
  methods: {
    schoolYearFormat(value) {
      return `${value + 1990}-${value + 1991}` 
    },
    applyFilter(row, filter) {
      // Ensure that all rows pass for empty filter
      if (!filter) return true

      if (this.leaFormatter(row.districtLEA).includes(filter)) 
        return true
      if (row.superintendent.includes(filter)) 
        return true
      if (row.phone.includes(filter)) 
        return true
      if (formatDate(row.createdDate).includes(filter))
        return true
      if (formatDate(row.updatedDate).includes(filter))
        return true
      if(typeof StateMap.get(row.status).status === 'function' && StateMap.get(row.status).status(row).includes(filter))
        return true
      if(typeof StateMap.get(row.status).status === 'string' && StateMap.get(row.status).status.includes(filter))
        return true
      if (filter.fiscalYear)
        return row.fiscalYear == filter.fiscalYear

      return false
    },
  },
  components: {
    PaginatedTable,
    WorkflowStatusBadge,
  },
  props: {
    districtLea: String,
    context: {
      type: Number,
      required: true
    },
    isPublic: {
      type: Boolean,
      default() {
        return false
      }
    },
    filter: {
      type: Object
    },
    waiverType: {
      type: Object
    }
  },
}
</script>

<style>
  .nowrap {
    white-space: nowrap;
  }
</style>
<template>
  <div class="overflow-auto">
    <table-control-widget :per-page="perPage" @filter-changed="debounceInput" @page-length-changed="perPage = $event" />
    <b-table ref="shortageReportJobStaffTable"
             striped
             hover
             selectable
             select-mode="single"
             :items="getJobEducators"
             :fields="tableColumns"
             :per-page="perPage"
             :current-page="currentPage"
             :filter="filter"
             :busy="isLoading">
      <div slot="table-busy" class="text-center text-danger my-2">
        <b-spinner class="align-middle" />
        <strong>Loading...</strong>
      </div>
      <template v-slot:cell(retireDropDate)="data">
        {{ data.value | date("MM/DD/YYYY") }}
      </template>
      <template v-slot:head(veteranTeacher)="data">
        <custom-tooltip :element-id="'veteranTeacherDetailsTooltip' + area" :label="data.label">
          A teacher is considered a Veteran Teacher if:
          <ul class="list-unstyled">
            <li>
              The sum of their age plus years of service >= 84, or
            </li>
            <li>
              They are retired, or
            </li>
            <li>
              They are identified as a Working Retiree
            </li>
          </ul>
        </custom-tooltip>
      </template>
    </b-table>
    <table-pagination-widget
      :total-rows-count="totalRowCount"
      :filtered-rows-count="filteredRowCount"
      :page-length="perPage"
      @page-changed="currentPage = $event"
    />
  </div>
</template>

<script>
import { TableMixin } from '../../TableMixin'
import { debounce } from '../../../helpers/globalHelpers'
import CustomTooltip from '../../CustomTooltip'

export default {
  mixins: [TableMixin],
  components: {
    CustomTooltip
  },
  data() {
    return {
      isLoading: false,
      perPage: 100,
      currentPage: 1,
      totalRowCount: 0,
      filteredRowCount: 0,
      filter: null,
      fields: [
        {
          key: 'groupName',
          label: 'Group Name',
          sortable: true
        },
        {
          key: 'subgroupName',
          label: 'Subgroup',
          sortable: true,
          tdClass: 'subgroup',
          formatter: d => {
            return d === '|SELF|' ? null : d
          }
        },
        {
          key: 'teacherName',
          label: 'Teacher Name',
          sortable: true
        },
        {
          key: 'schoolLEA',
          label: 'School LEA',
          sortable: true
        },
        {
          key: 'schoolName',
          label: 'School Name',
          sortable: true 
        },
        {
          key: 'districtLEA',
          label: 'District LEA',
          sortable: true
        },
        {
          key: 'districtName',
          label: 'District Name',
          sortable: true
        },
        {
          key: 'coopLEA',
          label: 'Coop LEA',
          sortable: true
        },
        {
          key: 'coopName',
          label: 'Coop Name',
          sortable: true
        },
        {
          key: 'jobCode',
          label: 'Course Code',
          tdClass: 'licenses',
          sortable: true
        },
        {
          key: 'serviceYears',
          label: 'Service Years',
          sortable: true
        },
        {
          key: 'veteranTeacher',
          label: 'Veteran Teacher',
          sortable: true,
          formatter: function(d) {
            return d ? 'Yes' : 'No'
          }
        },
        {
          key: 'age',
          label: 'Age',
          sortable: true
        },
        {
          key: 'serviceYears',
          label: 'Service Years',
          sortable: true
        },
        {
          key: 'retireDropDate',
          label: 'Retirement Date',
          sortable: true
        }
      ]
    }
  },
  props: {
    scopeLea: Object,
    scopeLevel: Number,
    grouping: String,
    parameters: Object,
    restrictToRetirees: Boolean,
    area: String
  },
  computed: {
    selectFilterJobGroup() {
      return this.$store.state.shortageReportModule.filter.jobGroup
    },
    selectFilterJobSubgroup() {
      return this.$store.state.shortageReportModule.filter.jobSubgroup
    },
    selectFilterJob() {
      return this.$store.state.shortageReportModule.filter.job
    },
    tableColumns() {
      return this.fields.filter(f => {
        if (f.key === 'subgroupName') return this.parameters.groupBySubgroup
        if (f.key === 'jobCode') return this.parameters.groupByJobOrLicenseCode

        if (f.dataLevel) {
          return f.dataLevel >= this.minDataLevel
        }

        return true
      })
    }
  },
  watch: {
    scopeLea() {
      this.$refs.shortageReportJobStaffTable.refresh()
    },
    grouping() {
      this.$refs.shortageReportJobStaffTable.refresh()
    },
    selectFilter() {
      this.$refs.shortageReportJobStaffTable.refresh()
    },
    restrictToRetirees(){
      this.$refs.shortageReportJobStaffTable.refresh()
    }
  },
  methods: {
    filterJobs(item) {
      let matches = true
      if (matches && this.selectFilterJobGroup)
        matches = item.groupName == this.selectFilterJobGroup
      if (matches && this.selectFilterJobSubgroup)
        matches = item.subgroupName == this.selectFilterJobSubgroup
      if (matches && this.selectFilterJob)
        matches = item.jobCode == this.selectFilterJob
        
      return matches
    },
    getJobEducators(context) {
      const params = Object.assign(
        {
          FY: 29,
          scopeLea: this.$store.state.globalModule.leaContext.SelectedLea ? this.$store.state.globalModule.leaContext.SelectedLea.number : null,
          scopeLevel: this.$store.state.globalModule.leaContext.SelectedLea ? this.$store.state.globalModule.leaContext.SelectedLea.type : null,
          groupBySubgroup: this.grouping == 1 || this.grouping == 3,
          groupByJobOrLicenseCode: this.grouping == 2 || this.grouping == 3
        },
        context
      )

      return this.$store.dispatch('shortageReportModule/getJobEducators', {...params, retiredPersonnelOnly: this.restrictToRetirees}).then(response => {
        this.totalRowCount = response.totalRowsAvailable
        this.filteredRowCount = response.totalRowsFiltered
        return response.data.filter(this.filterJobs)
      })
    },
    debounceInput: debounce(function(e) {
      this.filter = e
    }, 750)
  }
}
</script>

<template>
  <div class="teacher-list">
    <paginated-table :items="teacherList" :fields="fields" sort-by="schoolLEA" :sort-asc="true" :per-page="20" striped hover>
      <template v-slot:cell(action)="data">
        <router-link class="btn btn-sm btn-primary" title="View" :to="{ name: 'Schedule/RVS/Teachers/Teacher', params: { id: data.item.uniqueEmployeeId, lea: data.item.schoolLEA }}">
          <i class="fas fa-eye" />
        </router-link>
      </template>
      <template v-slot:cell(schoolLEA)="data">
        <a @click="goToLea(data.item.schoolLEA)" class="cursor-pointer">{{ data.value }}</a>
      </template>
      <template v-slot:cell(subjects)="data">
        <subject-tag :subject="s" v-for="s in data.value" :key="s" />
      </template>
      <template v-slot:cell(verificationStatus)="data">
        <!-- a badge with a calculated class and the text inside is a calculated string -->
        <span class="badge badge-lg" :class="getStatus(data.value).class">{{ getStatus(data.value).description }}</span>
      </template>
      <template v-slot:cell(verificationProgress)="data">
        <div v-if="data.item.totalVerifiableStudents > 0 && data.item.verificationStatus != 999">
          <b-progress min="0" :max="100" style="height: 1rem;">
            <b-progress-bar :value="data.value" />
          </b-progress>
          <div class="progress-overlay">
            {{ data.value.toFixed(0) }}%
          </div>
        </div>
      </template>
      <template v-slot:cell(outDistrict)="data">
        <span v-if="data.value == 'Y'" class="badge badge-lg badge-warning">Yes</span>
        <span v-else-if="data.value == 'N'" class="badge badge-lg badge-secondary">No</span>
        <span v-else />
      </template>
    </paginated-table>
  </div>
</template>

<script>
import PaginatedTable from '@/components/PaginatedTable'
import SubjectTag from './SubjectTag.vue'

export default {
  data() {
    return {
      fields: [
        { key: 'action', label: '', sortable: false },
        { key: 'schoolLEA', sortable: true, formatter: this.leaFormatter },
        { key: 'lastName', sortable: true },
        { key: 'firstName', sortable: true },
        { key: 'subjects', sortable: true },
        { key: 'outDistrict', sortable: true, label: 'Out of District' },
        { key: 'numCourses', sortable: true, label: 'Number of Classrooms' },
        { key: 'verificationStatus', sortable: true, label: 'Status' },
        { key: 'verificationProgress', sortByFormatted: true, tdClass: 'progress-bar-cell', label: 'Verification Progress (Students)', sortable: true, formatter: (value, key, item) => (item.completeStudents / item.totalVerifiableStudents) * 100 }
      ]
    }
  },  
  components: {
    PaginatedTable,
    SubjectTag,
  },
  computed: {
  },
  props: {
    hideLea: {
      type: Boolean,
      default: false
    },
    teacherList: {
      type: Array,
      default: () => []
    },
    verifiable: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.hideLea) {
      this.fields = this.fields.filter(f => f.key !== 'schoolLEA')
    }
    if (!this.verifiable) {
      this.fields = this.fields.filter(f => f.key !== 'verificationStatus' && f.key !== 'verificationProgress' && f.key !== 'subjects')
    }
  },
  methods: {
    getStatus(status) {
      switch(status) {
        case 0:
          return { class: 'badge-secondary', description: 'Draft' }
        case 1:
          return { class: 'badge-warning', description: 'Returned to Teacher' }
        case 100:
          return { class: 'badge-primary', description: 'Submitted' }
        case 101:
          return { class: 'badge-success', description: 'Accepted' }
        case 999:
          return { class: 'badge-danger', description: 'Exempted' }
        default:
          return { class: 'badge-secondary', description: 'Unknown' }
      }
    },
  },  
}
</script>
<template>
  <div class="card bg-light mt-3 page-break-before">
    <a class="anchor" id="schoolboard" />
    <div class="card-header">
      School Board
    </div>
    <div class="card-body">
      <div class="card-group">
        <school-board-list :load-data="loadData" :selected-lea="selectedLea" />
        <school-board-training-chart :load-data="loadData" :selected-lea="selectedLea" />
      </div>
    </div>
  </div>
</template>

<script>
import SchoolBoardList from './SchoolBoardList.vue'
import SchoolBoardTrainingChart from './SchoolBoardTrainingChart.vue'

export default {
  data() {
    return { 
      loadData: null 
    }
  },
  computed: {
    schoolboard() {
      return this.$store.state.overviewModule.schoolboard
    }
  },
  components: {
    SchoolBoardList,
    SchoolBoardTrainingChart
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        this.loadData = this.$store.dispatch('overviewModule/getSchoolBoard', {
          lea: newLea.number
        })
      }
    }
  }
}
</script>

<template>
  <highcharts class="chart dashboard-gauge" :callback="chartLoad" :options="getChartOptions()" />
</template>

<script>
const loadingSpinner = '<i class="fas fa-spinner fa-spin"></i> Loading...'

//import { mapState } from 'vuex'
import Highcharts from 'highcharts'
import solidGaugeInit from 'highcharts/modules/solid-gauge'

solidGaugeInit(Highcharts)

export default {
  data() {
    return {
      loadingSpinner: loadingSpinner,
    }
  },
  computed: {
    
  },
  watch: {
    
  },
  props: {
    total: {
      type: Number,
      default: 0
    },
    complete: {
      type: Number,
      default: 0
    },
    units: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
  },
  methods: {
    chartLoad() {

    },
    // chartLoad(chart) {
    //   if (chart) {
    //     if (this.dashboard.currentRoster.length == 0) {
    //       chart.showLoading(loadingSpinner)
    //     }
    //   }
    //   else if (this.$refs["progress1"]) {
    //     if (this.dashboard.currentRoster.length > 0) {
    //       this.$refs["progress1"].chart.hideLoading()
    //       this.$refs["progress2"].chart.hideLoading()
    //       this.$refs["progress3"].chart.hideLoading()
    //       this.$refs["progress4"].chart.hideLoading()
    //     }
    //     else
    //     {
    //       this.$refs["progress1"].chart.showLoading(loadingSpinner)
    //       this.$refs["progress2"].chart.showLoading(loadingSpinner)
    //       this.$refs["progress3"].chart.showLoading(loadingSpinner)
    //       this.$refs["progress4"].chart.showLoading(loadingSpinner)
    //     }
    //   }
    // },
    getChartOptions() {
      return {
        chart: {
          type: 'solidgauge',
          margin: -10,
          height: 150,
          spacing: [0, 0, 0, 20],
          backgroundColor: '#f8f9fa'
        },
        loading: {
          style: {
            position: 'absolute',
            backgroundColor: '#ffffff',
            opacity: 0.8,
            textAlign: 'center'
          }
        },
        title: {
          text: this.title,
        },
        pane: {
          center: ['50%', '60%'],
          //size: '80%',
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc'
          }
        },
        exporting: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              y: -30,
              borderWidth: 0,
              useHTML: true
            }
          }
        },
        yAxis: {
          // stops: [
          //   [0, '#DF5353'], // red
          //   [goalPercentage / 200, '#DDDF0D'], // yellow
          //   [goalPercentage / 100 - 0.001, '#55BF3B'] // green
          // ],
          lineWidth: 0,
          tickWidth: 0,
          minorTickInterval: null,
          tickAmount: 2,
          title: {
            y: 50,
            text: `${this.complete.toLocaleString()} of ${this.total.toLocaleString()} ${this.units}`
          },
          labels: {
            enabled: false
          },
          min: 0,
          max: 100
        },
        credits: {
          enabled: false
        },
        series: [
          {
            name: 'Progres',
            data: [Math.round((this.complete / this.total * 100))],
            dataLabels: {
              format: '<div style="text-align:center">' + '<span style="font-size:20px">{y}%</span><br/>' + '</div>'
            }
          },
          // {
          //   data: [goalPercentage],
          //   type: 'gauge',
          //   dial: {
          //     baseLength: '100%',
          //     radius: '100%',
          //     rearLength: '-60%',
          //     baseWidth: 1
          //   },
          //   dataLabels: {
          //     enabled: false
          //   },
          //   animation: false
          // }
        ]
      }
    }
  }
}
</script>

<style>
.dashboard-gauge {
  min-width: 100px;
}
.highcharts-yaxis-grid .highcharts-grid-line,
.highcharts-pivot {
  display: none;
}
</style>
<template>
  <div>
    <waiver-page-header :edit-mode="editMode" :waiver-request-id="waiverRequestId" 
                        :lea="resolvedLea" :current-status="currentStatus" :waiver-request-title="waiverRequestTitle"
                        :request="request" :back="backToSummary" :context="Context.District" />
    <p class="d-print-none">
      <a href="/Content/digitallearning/Guidebook%20for%20Digital%20Learning%20-%20Interactive%20PDF.pdf" target="_new" class="font-weight-bold text-dark"><i class="fa fa-file-pdf" /> Download the Digital Learning Guidebook</a>
    </p>

    <Promised :promise="loadData" v-show="loadData">
      <template v-slot:pending>
        <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
          <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
        </div>
      </template>
      <template v-slot>
        <div v-if="isDistrict">
          <div v-if="request && waiverRequestId" class="row">
            <div class="col-md-9">
              <b-alert variant="warning" show v-if="showWithdrawalRequest">
                <i class="fas fa-exclamation-triangle mr-2" />
                <strong class="mr-2">Submission withdrawal requested:</strong>{{ withdrawalRequest.comment }} &ndash; {{ withdrawalRequest.createdBy }}
              </b-alert>
              <digital-learning-action-toolbar
                v-if="request"
                :request="request"
                :edit="editMode"
                :can-edit="canEdit"
                @on-edit="editMode = true"
                @on-save="save()"
                @on-submit="submit()"
                @on-cancel="cancel()"
                :can-save="$v.$anyDirty"
                :is-valid="isValid"
                :save-label="'Save as Draft'"
              />

              <digital-learning-waiver-content :edit="editMode" v-if="request" />
            </div>
            <div class="col-md-3 ml-0 pt-1 pl-0 d-print-none">
              <div v-if="currentStatus" class="mb-4">
                <workflow-status-badge :state-map="StateMap" :status="selectedWaiverRequest.status" :document="selectedWaiverRequest" />
              </div>
              <div class="mt-3" v-if="currentStatus && currentAssignment">
                <b-alert show variant="info">
                  Assigned to:<br><span class="font-weight-bold">{{ currentAssignment }}</span>
                </b-alert>
              </div>
              <request-submission-withdrawal-widget :request="request" v-if="canRequestWithdrawal" />
              <reopen-waiver-request-widget :request="request" v-if="canReopen" />
              <assign-comment-widget :request="request" v-if="canEdit" />
              <change-log-list-widget :request="request" />
            </div>
          </div>
          <div v-else>
            <b-alert variant="warning" v-if="newDLWaiverRequestsLockedStatus && isDigitalLearning" show>
              <i class="fa fa-exclamation-circle" />
              New Digital Learning Waiver requests can not be created after May 1st. Existing drafts and revisions will continue to be reviewed.
            </b-alert>
            <program-options-widget :lea="resolvedLea" @on-create="editMode = true" :context="Context.District" />
          </div>
        </div>
        <div v-else>
          <b-alert show variant="danger">
            A district must be selected to continue.
          </b-alert>
        </div>
      </template>
      <template v-slot:rejected="error" class="align-items-center justify-content-center">
        <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
      </template>
    </Promised>
  </div>
</template>

<script>
// Form: https://docs.google.com/document/d/1dPLtkqRcjXAy6oFFRPLl5AKVL79iAEiG3MxZd7LFydE/edit

import { Promised } from 'vue-promised'
import CardStateIcon from '@/components/CardStateIcon.vue'
import { PageMixin } from '@/components/PageMixin'
import { LeaTypes } from '@/helpers/leaTypes'
import ChangeLogListWidget from './workflow/ChangeLogListWidget'
import AssignCommentWidget from './workflow/AssignWaiverRequestWidget'
import DigitalLearningActionToolbar from './workflow/DigitalLearningActionToolbar'
import RequestSubmissionWithdrawalWidget from './workflow/RequestSubmissionWithdrawalWidget'
import WorkflowStatusBadge from '@/components/workflow/WorkflowStatusBadge.vue'
import ProgramOptionsWidget from './details/ProgramOptionsWidget.vue'
import { Types, StateMap, Context } from '@/stores/digitalLearning'
import { mapState } from 'vuex'
import { DigitalLearningWaiverMixin } from './DigitalLearningWaiverMixin'
import WaiverPageHeader from './details/WaiverPageHeader.vue'

export default {
  data() {
    return {
      Context,
      StateMap,
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    currentAssignment() {
      if (this.selectedWaiverRequest && this.selectedWaiverRequest.status < 10 && (this.selectedWaiverRequest.changeLogs || []).some(l => l.assignedToName && l.assignedToName.length > 0)) {
        let changeLogs = [...this.selectedWaiverRequest.changeLogs].reverse()
        return changeLogs.find(l => l.assignedToName && l.assignedToName.length > 0).assignedToName
      }
      return null
    },
    hasAvailableStates() {
      return this.availableStates && this.availableStates.advanceStates && this.availableStates.advanceStates.length > 0
    },
    currentAssignedUserId() {
      if (this.selectedWaiverRequest && (this.selectedWaiverRequest.changeLogs || []).some(l => l.assignedToUserId && l.assignedToUserId.length > 0)) {
        let changeLogs = [...this.selectedWaiverRequest.changeLogs].reverse()
        return changeLogs.find(l => l.assignedToUserId && l.assignedToUserId.length > 0).assignedToUserId
      }
      return null
    },
    canRequestWithdrawal() {
      return !this.request.withdrawalRequested 
        && this.request.status == 11
        && this.request.createdBy == this.userId
    },
    usersLoading() {
      return this.districtUsers.length < 1
    },
    userId() {
      return ((this.userContext || {}).userId || '')
    },
    isAssignedToCurrentUser() {
      return this.currentAssignedUserId && this.currentAssignedUserId.includes(this.userId)
    },
    canEdit() {
      if (!this.selectedWaiverRequest) 
        return false
      
      return this.selectedWaiverRequest.status < 10 && (this.hasAvailableStates || this.isAssignedToCurrentUser)
    },
  },
  mixins: [PageMixin, DigitalLearningWaiverMixin],
  methods: {
    submit() {
      return this.save().then(() => {
        return this.$store.dispatch(Types.actions.transitionToState, {
          id: this.request.waiverRequestId,
          lea: this.request.districtLEA,
          State: this.availableStates.advanceStates[0],
          Comment: StateMap.get(this.availableStates.advanceStates[0]).status
        })
      })
    },
    backToSummary() {
      const self = this
      this.loadData = this.$store.dispatch(Types.actions.getWaiverRequests, { lea: this.resolvedLea.number }).then(() => {
        self.$router.push({ ...this.$router.currentRoute, query: { lea: this.resolvedLea.number } })
      })
    },
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if (newLea && newLea.number) {
          var userLEA = (this.$store.state.globalModule.userContext || {}).districtLEA
          if (userLEA && userLEA != "AR" && userLEA != newLea.number) {
            this.goToLea(userLEA)
            return
          }
          
          if (newLea.type == LeaTypes.School) {
            this.resolvedLea = this.leaDictionary[newLea.number].parentLea
          }
          else {
            this.resolvedLea = newLea
          }
          this.loadData = this.$store.dispatch(Types.actions.getWaiverRequests, { lea: this.resolvedLea.number })
        }
      }
    },
  },
  components: {
    Promised,
    CardStateIcon,
    ChangeLogListWidget,
    AssignCommentWidget,
    DigitalLearningActionToolbar,
    ProgramOptionsWidget,
    WaiverPageHeader,
    RequestSubmissionWithdrawalWidget,
    WorkflowStatusBadge,
  }
}

</script>

<style>

</style>
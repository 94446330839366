<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import LoadableChartCard from '../../../LoadableChartCard'
import { ChartMixin } from '../../../ChartMixin'

export default {
  data: function () {
    return {
     animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'pie',
          plotBackgroundColor: '#f8f9fa',
          plotShadow: false,
        },
        title: {
          text: this.question.text
        },          
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: <br/>{point.y} ({point.percentage:.1f}%)'
            },
            showInLegend: true
          }
        }
      }
    }
  },
  name: 'question-yesno-chart',
  props: {
    question: {
      type: Object,
      default: () => {}
    },
    aggregatedData: {
      type: Array,
      default: () => []
    },
    loadData: Promise
  },
  components: {
    LoadableChartCard
  },
  methods: {
    dataItems() {
      return this.aggregatedData[0].details.map (d=> {
        return {
          districtLEA: d.district.number,
          districtName: d.district.name,
          surveyResult: d.surveyResponse == "Indeterminate" ? this.question.indeterminateAnswerText : d.surveyResponse
       }
      })
    }
  },
  computed: {
    indeterminateAnswer() {
      return this.question.indeterminateAnswerText
    }
  },
  mixins: [ChartMixin],
  watch: {
    aggregatedData: {
      immediate: true,
      handler() {
        this.chartOptions.series = [{
          data: [
            { name: 'Yes', y: this.aggregatedData[0].choices['Yes'], color: '#28a745'},
            { name: 'No', y: this.aggregatedData[0].choices['No'], color: '#dc3545' },
            { name: 'No answer', y: this.aggregatedData[0].choices['No answer'], color: '#17a2b8' },
            this.indeterminateAnswer ? { name: this.indeterminateAnswer, y: this.aggregatedData[0].choices['Indeterminate'], color: '#ffc107' } : null
          ],         
          name: this.question.text,
          colorByPoint: true
        }]        
      }
    }
  }
}
</script>

<style>

</style>
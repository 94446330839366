<template>
  <div class="h-100 w-100">
    <highcharts class="chart plan-expenses-rollup" ref="chartElem" :options="chartOptions" />
  </div>
</template>

<script>
import { Types } from '@/modules/esa/stores/'
import { mapState } from 'vuex'
import { ChartMixin } from '@/components/ChartMixin'
import sumBy from 'lodash.sumby'
import Highcharts from 'highcharts'
import { expandCodes } from '../helpers.js'

export default {
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'pie',
          margin: [30, 0, 50, 0],
          height: 300,
          spacing: [0, 0, 0, 0],
          plotBackgroundColor: "rgb(248, 249, 250)",
          plotBorderWidth: 0,
          plotShadow: false,
          events: {
            drilldown: this.onDrillDown,
            drillup: this.onDrillUp
          }
        },
        plotOptions: {
          pie: {
            cursor: 'pointer',
            // size: '70%',
            dataLabels: {
              enabled: true,
              alignTo: 'connectors',
              crop: false 
            },
            borderColor: '#6c757d',
            center: ['50%, 50%'],
            //innerSize: '50%'
          },
        },
        title: {
          text: this.title,
          floating: false,
          //verticalAlign: 'middle'
        },
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        lang: {
          thousandsSep: ','
        },
        series: [{
          name: this.title,
          colorByPoint: true
        }],
        drilldown: {},
        xAxis: {
          title: {
            text: 'Amount'
          },
          type: 'category'
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          formatter: function(e) {
            const description = this.point.description ? `<br/>(${this.point.description})` : ''
            if (e.chart.ddDupes && e.chart.ddDupes.length > 0) 
              return `${this.series.name}${description}<br/>${this.key}: <b>$${Highcharts.numberFormat(this.y, 2).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} (${this.percentage.toFixed(2)}%)</b>`
            else
              return `${this.key}${description}<br/>${this.series.name}: <b>$${Highcharts.numberFormat(this.y, 2).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} (${this.percentage.toFixed(2)}%)</b>`
          }
        },
        yAxis: {
          title: {
            text: this.yAxisLabel
          },
          endOnTick: false,
          labels: {
            formatter: this.yAxisFormatter
          }
        },
      }
    }
  },
  props: {
    field: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    drilldown: {
      type: String
    }
  },
  computed: {
    ...mapState(Types.path, [ Types.state.planExpenses ]),
  },
  mixins: [ChartMixin],
  methods: {
    onDrillDown: function(e) {
      this.$emit('drill-down', e.seriesOptions.name)
    },
    onDrillUp: function() {
      this.$emit('drill-up')
    },
    reset() {
      this.chartOptions.series = [{}]
      this.chartOptions.drilldown = {
        series: []
      }
    },
    populateDrilldown(category) {
      this.chartOptions.drilldown.series.push({
        name: category.name,
        description: category.description,
        id: category.id,
        colorByPoint: true,
        data: category.types.map(type => {
          var filtered = this.planExpenses.filter(expense => category.programCode.includes(expense.program) && expandCodes(type.codes).includes(expense.account))
          var y = sumBy(filtered, this.field)
          return {
            name: type.description,
            y: y
          }
        })
      })
    },
    populate() {
      this.reset()

      this.chartOptions.series = [{
        name: this.title,
        colorByPoint: true,
        data: this.categories.map(category => {
          var y = sumBy(this.planExpenses.filter(expense => category.programCode.includes(expense.program)), this.field)
          return {
            name: category.name,
            description: category.description,
            y: y,
            drilldown: category.id
          }        
        })
      }]      
      this.categories.forEach(category => {
        this.populateDrilldown(category)
      })
    },
  },
  watch: {
    planExpenses: {
      immediate: true,
      handler() {
        this.populate()
      }
    },
    drilldown: {
      handler(newData) {
        if (newData && newData != '') {
          // $refs.chartElem.chart.series[0].data[0].doDrilldown()
          var point = this.$refs.chartElem.chart.series[0].data.find(point => point.name == newData)
          if (point) 
            point.doDrilldown()
        }
        else {
          this.$refs.chartElem.chart.drillUp()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.plan-expenses-rollup ::v-deep .highcharts-drillup-button {
  display: none
}
</style>
import ApiService from '@/services/ApiService'

export const VisitTypes = [
  { key: 'careerPlan', label: 'Career Planning' },
  { key: 'apprenticeship', label: 'Apprenticeship' },
  { key: 'collegeAdmissions', label: 'College Admission' },
  { key: 'careerAssessment', label: 'Career Assessment' },
  { key: 'academicConcurrentCredit', label: 'Academic Concurrent Credit' },
  { key: 'cteConcurrentCredit', label: 'CTE Concurrent Credit' },
  { key: 'workbasedLearning', label: 'Workbased Learning' },
  { key: 'financialAid', label: 'Financial Aid' }]

export class Reporting {

  constructor() {
    //console.log('Reporting ctor')
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
  }

  namespaced = true

  state = {
    rosterDates: {
      schoolYear: null,
      month: null
    }
  }

  mutations = {
    setRosterDates(state, data) {
      state.rosterDates.schoolYear = data.fy+1990
      state.rosterDates.month = data.month
    }
  }

  actions = {
    // eslint-disable-next-line
    getMonthlyCumulativeSummary: ({ commit }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/cc-reports/${payload.lea}/monthly-cumulative-summary?academicYear=${payload.academicYear}&calendarYear=${payload.calendarYear}&month=${payload.month}`))
    },

    // eslint-disable-next-line
    getAcademicOutcomes: ({ commit }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/cc-reports/${payload.lea}/academic-outcomes?academicYear=${payload.academicYear}`))
    },

    // eslint-disable-next-line
    serviceCoverageReport: ({ commit }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/cc-reports/${payload.lea}/service-coverage-report?grade=${payload.grade || ''}&topic=${payload.topic || ''}&academicYear=${payload.academicYear}`))
    },

     // eslint-disable-next-line
    studentServiceDetailsReport: ({ commit }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/cc-reports/${payload.lea}/student-service-details-report?grade=${payload.grade || ''}&ay=${payload.academicYear}`))
    },
    // eslint-disable-next-line
    contactDetailsReport: ({ commit }, payload) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/cc-reports/${payload.lea}/contact-details-report?academicYear=${payload.academicYear}`))
    },

    // eslint-disable-next-line
    studentListingReport: ({ commit }, payload) => {
      return this._apiService.Post(encodeURI(`${this._baseUrl}api/cte/cc-reports/${payload.lea}/student-listing`), payload)
    },

    getRosterDates: ({ commit }) => {
      return this._apiService.Get(encodeURI(`${this._baseUrl}api/cte/cc-reports/roster-dates`))
      .then(d=>commit('setRosterDates', d))
    }
  }
}
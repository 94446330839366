<template>
  <div v-show="render">
    <div>
      <h4 class="text-dark">         
        National Board Certification<i v-b-toggle.collapse-info class="ml-2 fas fa-info-circle fa-sm fa-fw text-primary info-icon d-print-none" title="Show More Information" />
      </h4>

      <b-collapse id="collapse-info" class="mb-3">
        <b-card-header class="mb-0 d-print-none">
          <b-card-title v-b-toggle.collapse-info class="mb-0">
            <b-icon-x />Information
          </b-card-title>
        </b-card-header>
        <b-card class="mb-2">
          <b-card-text>
            <h5 class="font-italic">
              National Board Certification Analysis
            </h5>
            <p>The percentage of NBC teachers assigned to courses linked to ELA, math, and science valued-added growth scores ranges from 4.28 to 5.0 percent as indicated in <a href="#table-7">Table 7: Count and Percentage of Teachers without/with NBC Status with Student Value-Added Growth Scores</a>. To analyze the potential association of national board certification with teacher value-added growth scores we conducted a factorial analysis of variance for the main effect of NBC status. ELA growth scores are linked to 482 NBC teachers compared to 386 NBC teachers linked to math growth scores and 266 NBC teachers linked to science growth scores.</p>
            <h5 class="font-italic">
              Results
            </h5>
            <p>Differences in NBC teachers' and non-NBC teachers' mean value-added scores varied across grade and subject. ELA had the most significant differences at the elementary level (Grades 3-6 and all grades together). In math, only Grade 4 and all grades together demonstrated significantly different teacher value-added growth means. In science, only Grade 5 and all grades together showed significant differences in teacher value-added growth means. The R2 measures of association for the factorial analysis (values ranging from 0.000 to 0.005) indicate that NBC status explains less than one percent of the variation in mean teacher value-added growth scores.</p>
          </b-card-text>
        </b-card>
      </b-collapse>
    </div>

    <div v-if="teacherVAMData">
      <count-and-percentage-teachers-by-n-b-c :render="render" />
      <mean-teacher-value-added-growth-scores-by-n-b-c :render="render" />
      
      <div>
        <div class="figure-caption">
          Figure 5: Distribution of Teacher Value-Added Growth Scores by NBC Certification Status
        </div>
        <distribution-teacher-value-added-growth-scores-by-n-b-c subject="undefined" :render="render" :figure="0 + 5" />
      </div>
    </div>
    <div v-else>
      <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
        <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
      </div>
    </div>
  </div>
</template>

<script>
import CountAndPercentageTeachersByNBC from '../tables/CountAndPercentageTeachersByNBC.vue'
import MeanTeacherValueAddedGrowthScoresByNBC from '../tables/MeanTeacherValueAddedGrowthScoresByNBC.vue'
import DistributionTeacherValueAddedGrowthScoresByNBC from '../charts/DistributionTeacherValueAddedGrowthScoresByNBC.vue'
import { mapState } from 'vuex';
import CardStateIcon from '../../../CardStateIcon.vue'
import { subjectCase } from '../helpers.js'

export default {
  data() {
    return {
      subjectCase
    }
  },
  components: {
    CountAndPercentageTeachersByNBC,
    MeanTeacherValueAddedGrowthScoresByNBC,
    DistributionTeacherValueAddedGrowthScoresByNBC,
    CardStateIcon
  },
  props: {
    render: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('teacherVAMModule', ['teacherVAMData']),
  }
}
</script>
<template>
  <div>
    <b-card :header="headerText" :title="`${mode} Bus Inspection Notifications`">
      <p v-if="mode=='Unacknowledged'">
        Inspection notifications appear in the list below when they are first sent to your district by DPSAFT staff. You can download the associated report by clicking the <i class="fa fa-download text-primary" /> button. 
        When the report is downloaded by the district's Bus Maintenance Coordinator, Insights records receipt and acknowledgement by the district. At that point, the notification will no longer appear in this list, but will be 
        located in the 
        <router-link :to="{name: 'Transportation/Inspections/District/Notifications/Acknowledged', params: { districtLea: $route.query.lea }}">
          Acknowledged Notifications
        </router-link> list.
      </p>
      <p v-else>
        The Inspection Notifications below have been previously acknowledged by the district's Bus Maintenance Coordinator.
      </p>

      
      <paginated-table :items="items" :fields="fields">
        <template v-slot:cell(notificationType)="data">
          <b-badge :variant="data.item.notificationType == TransportationNotificationTypes.IncrementalReport ? 'warning' : 'primary'">
            {{ displayNotificationtype(data.value) }}
          </b-badge>
        </template>
        <template v-slot:cell(createdDate)="data">
          <span v-if="data.value">
            {{ data.value | date }}
          </span>
          <span v-else>
            Pending
          </span>
        </template>
        <template v-slot:cell(districtAcknowledgedBy)="data">
          {{ data.value }}
        </template>
        <template v-slot:cell(districtAcknowledgedDate)="data">
          {{ data.value | date }}
        </template>
        <template v-slot:cell(reportLink)="data">
          <a :href="`/dpsaft/notification/${data.item.id}/download`"><i class="fa fa-download" title="Download Report" /></a>
        </template>
      </paginated-table>
    </b-card>
  </div>
</template>

<script>
import { Types } from '../../../../stores/dpsaft/trans'
import { mapState } from 'vuex'
import PaginatedTable from '../../../PaginatedTable.vue'
import { TransportationNotificationTypes } from '../../common/Enums'

export default {
  data() {
    return {
       TransportationNotificationTypes,
       fields: [
        { key: 'notificationType' },
        { key: 'createdBy' },
        { key: 'createdDate' },
        { key: 'createdDate' },
        { key: 'districtAcknowledgedDate' },
        { key: 'districtAcknowledgedBy' },
        { key: 'reportLink', label: '', class: 'text-right' },
      ]
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.DistrictNotifications]),
    headerText() {
      return `${this.mode} Inspection Notifications`
    },
    items() {
      if (this.mode == "Acknowledged") {
        return this.DistrictNotifications.filter(i=>i.districtAcknowledgedBy != null)
      } else {
        return this.DistrictNotifications.filter(i=>i.districtAcknowledgedBy == null)
      }
    },
  },
  components: {
    PaginatedTable
  },
  methods: {
    displayNotificationtype(type) {
      if (type == TransportationNotificationTypes.IncrementalReport) 
        return 'Incremental Report'
      else if (type == TransportationNotificationTypes.FinalReport) 
        return 'Final Report'
    }
  },
  mounted() {
    console.log('mounted')
  },
  props: {
    mode: String
  },
  watch: {
    mode: {
      immediate: true,
      handler() {
        this.$store.dispatch(Types.actions.getDistrictNotifications, {districtLea: this.$route.params.districtLea})
      }
    }
  }
}
</script>
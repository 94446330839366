export const TeacherStateMap = new Map();
TeacherStateMap.set(0, {
  code: 0,
  status: 'Draft',
  action: 'Return to Draft',
  requiresComment: false,
  variant: 'secondary'
})
TeacherStateMap.set(1, {
  code: 1,
  action: 'Return to Teacher',
  status: 'Returned to Teacher',
  requiresComment: true,
  variant: 'warning'
})
TeacherStateMap.set(100, {
  code: 100,
  action: (verification) => {
    if (verification && verification.status == TeacherStates.Accepted) {
      return 'Unlock for Revisions'
    }
    return 'Submit to Principal'
  },
  status: 'Pending Review',
  requiresComment: false,
  variant: 'primary'
})
TeacherStateMap.set(101, {
  code: 101,
  action: 'Accept',
  status: 'Accepted',
  requiresComment: false,
  variant: 'success'
})
TeacherStateMap.set(999, {
  code: 999,
  status: 'Exempted from Verification',
  action: 'Exempt from Verification',
  requiresComment: true,
  variant: 'danger'
})

export const TeacherStates = {
  Draft: 0,
  ReturnedToTeacher: 1,
  Submitted: 100,
  Accepted: 101,
  Exempted: 999
}

export const SchoolStateMap = new Map();
SchoolStateMap.set(0, {
  code: 0,
  status: 'Draft',
  action: 'Return to Draft',
  requiresComment: false,
  variant: 'secondary'
})
SchoolStateMap.set(1, {
  code: 1,
  action: 'Return to Principal',
  status: 'Returned to Principal',
  requiresComment: true,
  variant: 'warning'
})
SchoolStateMap.set(100, {
  code: 100,
  action: (verification) => {
    if (verification && verification.status == SchoolStates.Accepted) {
      return 'Unlock for Revisions'
    }
    return 'Submit to Superintendent'
  },
  status: 'Pending Review',
  requiresComment: true,
  variant: 'primary'
})
SchoolStateMap.set(101, {
  code: 101,
  action: 'Accept',
  status: 'Accepted',
  requiresComment: false,
  variant: 'success'
})

export const SchoolStates = {
  Draft: 0,
  ReturnedToPrincipal: 1,
  Submitted: 100,
  Accepted: 101,
}

export const DistrictStateMap = new Map();
DistrictStateMap.set(0, {
  code: 0,
  status: 'Draft',
  action: 'Return to Draft',
  requiresComment: true,
  variant: 'secondary'
})
DistrictStateMap.set(100, {
  code: 100,
  action: (verification) => {
    if (verification && verification.status == DistrictStates.Finalized) {
      return 'Unlock for Revisions'
    }
    return 'Mark as Final'
  },
  status: 'Finalized',
  requiresComment: false,
  variant: 'success'
})

export const DistrictStates = {
  Draft: 0,
  Finalized: 100
}
import Vue from 'vue'
import {
    AttritionReportChart
} from './AttritionReportChart'
import {
    AttritionReportTable
} from './AttritionReportTable'
import {
    PageMixin
} from '../../PageMixin'
import {
    mapState
} from 'vuex'

const AttritionReport = Vue.extend({
    name: 'attrition-report',
    mixins: [PageMixin],
    components: {
        AttritionReportChart,
        AttritionReportTable
    },
    data() {
        return {
            loadData: null
        }
    },
    computed: {
        ...mapState('attritionReportModule', ['position', 'trendLevel'])
    },
    methods: {
        constructExportPayload() {
            return {
                lea: this.selectedLea.lea,
                trendLevel: this.trendLevel,
                position: this.position
            };
        }
    }
})

export {
    AttritionReport
}
import Router from 'vue-router'
import Vue from 'vue'
import store from './stores'

Vue.use(Router)

var router = new Router({ routes: [], mode: 'history' })

router.beforeEach((to, from, next) => {
  store.dispatch('globalModule/initialize').then(() => {
    console.log('Route lea ' + to.query.lea)
    console.log('Vuex store lea ' + store.state.globalModule.leaContext.SelectedLea.number)
    
    var targetLea = to.query.lea || store.state.globalModule.leaContext.SelectedLea.number

    // LEA is not present from URL or Vuex store, set to AR
    if (!to.query.lea && !store.state.globalModule.leaContext.SelectedLea.number)
    {
      targetLea = 'AR'
      store.commit('globalModule/setSelectedLea', store.state.globalModule.leaContext.LeaDictionary[targetLea])
      console.log('Setting lea from URL (default AR)')
      next({ path: to.path, params: to.params, query: Object.assign(to.query, { lea: targetLea }) })
    }
    // LEA from URL and Vuex state do not match
    else if (to.query.lea != store.state.globalModule.leaContext.SelectedLea.number) {
      store.commit('globalModule/setSelectedLea', store.state.globalModule.leaContext.LeaDictionary[targetLea])
      console.log('Setting lea (URL and Vuex state do not match)')
      // Correct url with the LEA number from the Vuex state
      if (!to.query.lea) 
        next({ path: to.path, params: to.params, query: Object.assign(to.query, { lea: targetLea }) })
      else 
        next()
    }
    else {
      next()
    }
  })
})

router.afterEach((to, from) => {
  store.commit('globalModule/setPageLabel', null)

  if (window.global.default.VueApp.$printMode) {
    console.log(`Route To: ${JSON.stringify(to, ["name", "path", "props", "params", "query", "hash"])}`)
    console.log(`Route From: ${JSON.stringify(from, ["name", "path", "props", "params", "query", "hash"])}`)
  }
})

export { router }
<template>
  <div class="h-100 w-100">
    <highcharts class="chart" :options="chartOptions" />
  </div>
</template>

<script>
import { ChartMixin } from '@/components/ChartMixin'
import Highcharts from 'highcharts'
import sumBy from 'lodash.sumby'
import groupBy from 'lodash.groupby'
import { ProposalTypes } from '@/modules/cte/components/perkinsV/enums'

export default {
  data() {
    return {
      proposalsByType: {},
      alreadyDrawn: false,
      animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'pie',
          margin: 0,
          height: 300,
          spacing: [0, 0, 0, 0],
        },
        plotOptions: {
          pie: {
            cursor: 'pointer',
            // size: '70%',
            dataLabels: {
              enabled: true,
              // alignTo: 'connectors',
              formatter: function() {
                return `<b>${this.point.name}</b>:<br>${Highcharts.numberFormat(this.point.percentage)} %<br>Total: $${Highcharts.numberFormat(this.point.y, 2)}`
              }
            },
            borderColor: '#6c757d'
          },
        },
        title: {
          text: null
        },
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        lang: {
          thousandsSep: ','
        },
        series: [{
          name: 'Proposal Costs by Type',
          colorByPoint: true,
        }],
        drilldown: {},
        xAxis: {
          title: {
            text: 'Amount'
          },
          type: 'category'
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          formatter: function() {
            return `<b style="color:${this.point.color}">${this.point.name}</b>:<br>${Highcharts.numberFormat(this.point.percentage)} %<br>Total: $${Highcharts.numberFormat(this.point.y, 2)}`
          }
        },
        yAxis: {
          title: {
            text: this.yAxisLabel
          },
          endOnTick: false,
          labels: {
            formatter: this.yAxisFormatter
          }
        },
      }
    }
  },
  mixins: [ChartMixin],
  watch: {
    applications: {
      handler(applications) {
        if (!this.alreadyDrawn) this.populate(applications, this.clusters)
      }
    },
    clusters: {
      handler(clusters) {
        if (!this.alreadyDrawn) this.populate(this.applications, clusters)
      }
    }
  },
  props: {
    yAxisLabel: {
      type: String,
      default: 'Amount'
    },
    applications: Array,
    clusters: Array,
  },
  methods: {
    reset() {
      this.chartOptions.series = []
      this.chartOptions.series = [{
      }]
      this.chartOptions.drilldown = {
        series: []
      }
    },
    populateDrilldown(clusters, proposalsByType, type) {
       const byProgramArea = groupBy(proposalsByType[type], (proposal) => {
        const cluster = clusters.find(t => proposal.clusterId == t.id)
        return (cluster || { name: 'Unknown'}).name
      })
      this.chartOptions.drilldown.series.push({
        name: type,
        id: type,
        colorByPoint: true,
        data: Object.keys(byProgramArea).map(cluster => {
          return {
            name: cluster, y: sumBy(byProgramArea[cluster], 'amountBudgeted')
          }
        })
      })
    },
    populate(applications, clusters) {
      if ((applications || []).length == 0 || (clusters || []).length == 0)
        return;
      this.reset()
      const proposals = [...applications].flatMap(a => a.proposals)
      const proposalsByType = groupBy(proposals, (proposal) => {
        return ProposalTypes.find(t => proposal.proposalType == t.value).text
      })
      this.chartOptions.series = [{
        name: 'Proposal Costs by Type',
        colorByPoint: true,
        data: Object.keys(proposalsByType).map(proposalType => {
          return {
            name: proposalType,
            y: sumBy(proposalsByType[proposalType], 'amountBudgeted'),
            drilldown: proposalType.includes('Single Program Area') ? proposalType : null
          }
        })
      }]
      this.proposalsByType = proposalsByType
      this.populateDrilldown(clusters, proposalsByType, 'Deficient Single Program Area')
      this.populateDrilldown(clusters, proposalsByType, 'Single Program Area')
      this.alreadyDrawn = true
    },
  }
}
</script>

<template>
  <div>
    <pdf-button v-if="selectedLea.type == LeaTypes.District && !isAdminRoute && !isResourceRoute && !isAuthRoute" :additional-query-string-params="`&fiscalYear=${fiscalYear+1}`" />
    <excel-button title="Enhanced Student Achievement" v-if="(selectedLea.type == LeaTypes.District && !isAdminRoute && !isAuthRoute) || isResourceRoute" />
    <h1 v-if="!isAuthRoute">
      <object type="image/svg+xml" data="/Content/icons/AC-ESA.svg" class="title-icon" />
      Enhanced Student Achievement (ESA)
    </h1>
    <h3><lea-display :lea="selectedLea" v-if="!isAdminRoute && !isResourceRoute && !isAuthRoute" /></h3>
    <div v-if="selectedLea.type == LeaTypes.District || isAdminRoute || (isDashboardRoute && selectedLea.type >= LeaTypes.District) || isResourceRoute || isAuthRoute">
      <b-alert :show="showInfo && isNeedsAssessmentRoute" variant="info" class="d-print-none ">
        <i class="float-right fa fa-times cursor-pointer" @click="hideInfo" />
        Districts will develop a 3-year ESA plan and conduct an annual review of progress based on data from multiple sources, including, but not limited to, the data contained in the DESE provided data set.
        The data will help districts to identify where the district's schools have gaps in student performance, progress/growth, access to experienced, fully certified educators, and student supports.
        DESE will continuously update the data to identify significant differences in district data when compared to the state averages, gaps within districts that meet/exceed certain markers, or have an identified gap from the standard set or from the overall state average.
        Districts should examine data for all students and also for student subgroups at each school within the district.
        The ESA plan requires a written narrative to describe the expenditure decision, the data justification, and the expected outcomes/measures of success.
        Districts are also encouraged to upload local data to support decisions for expenditure categories and expenditure types.
      </b-alert>
      <router-view />
    </div>
    <div v-else class="alert alert-warning">
      Enhanced Student Achievement plan is only available at the District level.
    </div>
  </div>
</template>
<script>
import PlanWidget from './Plan/PlanWidget'
import PlanDashboardWidget from './Plan/Dashboard/PlanDashboardWidget'
import AcademicAchievementCard from './SurveyNeedsAssessment/AcademicAchievement/AcademicAchievementCard'
import AcademicProgressCard from './SurveyNeedsAssessment/AcademicProgress/AcademicProgressCard'
import StaffingCard from './SurveyNeedsAssessment/Staffing/StaffingCard'
import StudentSupportsCard from './SurveyNeedsAssessment/StudentSupports/StudentSupportsCard'
import PostSecondaryReadinessCard from './SurveyNeedsAssessment/Post-secondaryReadiness/PostSecondaryReadinessCard'
import EsaPdfPage from './EsaPdfPage'
import { PageMixin } from '@/components/PageMixin'
import PlanCategoryList from './Pages/Admin/PlanCategory/PlanCategoryList.vue'
import PlanCategoryEdit from './Pages/Admin/PlanCategory/PlanCategoryEdit.vue'
import AdminPlanListWidget from './Plan/Admin/AdminPlanListWidget.vue'
import EsaSuperintendentAlternativeLogin from './Plan/Auth/EsaSuperintendentAlternativeLogin.vue'
import ProgramCodesPage from './Pages/Resources/ProgramCodesPage.vue'
import { router } from '@/routes'
import { Types } from '@/modules/esa/stores/'
import { Types as SurveyTypes } from '@/modules/esa/stores/survey'

import { mapState } from 'vuex'

router.addRoutes([
  { path: '/Academics/Esa/Admin/Plans', component: AdminPlanListWidget, name: 'esa-plan-list' },
  { path: '/Academics/Esa/Admin/PlanCategories/Edit', component: PlanCategoryEdit, name: 'plan-category-edit', props: { mode: 'edit' } },
  { path: '/Academics/Esa/Admin/PlanCategories/New', component: PlanCategoryEdit, name: 'new-plan-category', props: { mode: 'new' } },
  { path: '/Academics/Esa', redirect: to => {
    if ((to.query['response-format'] || '').length > 0)
    {
      return { 
        name: 'esa-pdf-report', 
        query: { ...to.query } 
      }
    }
    const userContextLea = (window.userContext || {}).districtLEA
    if (!userContextLea) {
      return { 
        name: 'esa-auth', 
        query: { ...to.query } 
      }
    }
    else if (userContextLea == 'AR') {
      return { 
        name: 'esa-plan-dashboard', 
        query: { ...to.query } 
      }
    }
    else {
      return { 
        name: 'esa-plan-dashboard', 
        query: { ...to.query } 
      }
    }
  }, name: 'esa-home' },
  { path: '/Academics/Esa', component: EsaPdfPage, name: 'esa-pdf-report' },
  { path: '/Academics/Esa/Survey/AcademicAchievement', component: AcademicAchievementCard, name: 'esa-survey-academicachievement' },
  { path: '/Academics/Esa/Survey/AcademicProgress', component: AcademicProgressCard, name: 'esa-survey-academicprogress' },
  { path: '/Academics/Esa/Survey/Staffing', component: StaffingCard, name: 'esa-survey-staffing' },
  { path: '/Academics/Esa/Survey/StudentSupports', component: StudentSupportsCard, name: 'esa-survey-studentsupports' },
  { path: '/Academics/Esa/Survey/PostSecondaryReadiness', component: PostSecondaryReadinessCard, name: 'esa-survey-postsecondaryreadiness' },
  { path: '/Academics/Esa/Plan/', component: PlanWidget, name: 'esa-plan-district' },
  { path: '/Academics/Esa/Resources/ProgramCodes', component: ProgramCodesPage, name: 'esa-resources-program-codes' },
  { path: '/Academics/Esa/Resources/PlanCategories', component: PlanCategoryList, name: 'plan-category-list' },
  { path: '/Academics/Esa/Dashboard', component: PlanDashboardWidget, name: 'esa-plan-dashboard' },
  { path: '/Academics/Esa/Authenticate', component: EsaSuperintendentAlternativeLogin, name: 'esa-auth' },
])
export default {
  mixins: [PageMixin],
  components: {
  },
  computed: {
    ...mapState(Types.path, [Types.state.plan, Types.state.esaFiscalYear]),
    ...mapState(SurveyTypes.path, [SurveyTypes.state.showInfo]),
    fiscalYear() {
      return (this.plan && this.plan.fiscalYear) ? this.plan.fiscalYear : this.esaFiscalYear 
    },
    isAdminRoute() {
      return this.$route.path.toLowerCase().includes('/admin/')
    }, 
    isDashboardRoute() {
      return this.$route.path.toLowerCase().includes('/dashboard')
    },
    isResourceRoute() {
      return this.$route.path.toLowerCase().includes('/resources/')
    },
    isNeedsAssessmentRoute() {
      return this.$route.path.toLowerCase().includes('/survey/')
    },
    isAuthRoute() {
      return this.$route.path.toLowerCase().includes('/auth')
    },
  },
  methods: {
    hideInfo() {
      this.$store.commit(SurveyTypes.mutations.setShowInfo, false)
    },
    expandInfo() {
      this.$store.commit(SurveyTypes.mutations.setShowInfo, true)
    },
  },
  props: {
    ay: {
      type: Number,
      required: true
    }
  },
  mounted() {
    this.$store.commit(Types.mutations.setESAFiscalYear, this.ay)
  }
}
</script>

<style scoped>
.title-icon {
  height: 2.5rem;
  width: 2.5rem;
  top: -0.25em;
  position: relative;
  display: inline-flex;
  align-self: center;
  vertical-align: bottom;
  fill: #6c757d;
}
</style>
<template>
  <div>  
    <b-button @click="startEdit" v-if="userCanEdit && !editMode" variant="primary">
      <i class="fas fa-edit" /> Edit Hotspot Counts
    </b-button>
    <div class="card bg-light p-2">
      <div class="row" v-if="!this.editMode">
        <div class="col-12">
          <div class="row" style="margin-left:auto; margin-right:auto;">
            <div class="col-md-11">
              <chart-data-toggle v-model="chartMode" @input="chartMode = $event" />
            </div>  
          </div>
          <div class="chartElem" v-show="chartMode">
            <highcharts class="chart" :options="chartOptions" ref="myChart" />
          </div>
          <div v-show="!chartMode">
            <chart-data-table :items="dataItems()" />
          </div>
        </div>
      </div>
      <div class="row" v-else>
        <b-col cols="3">
          <label>AT&T:</label>
          <b-input v-model="hotspotList[0].att" type="number" :number="true" />
        </b-col>
        <b-col cols="3">
          <label>T-Mobile:</label>
          <b-input v-model="hotspotList[0].tMobile" type="number" :number="true" />
        </b-col>
        <b-col cols="3">
          <label>Verizon:</label>
          <b-input v-model="hotspotList[0].verizon" type="number" :number="true" />
        </b-col>
        <b-col align-self="center">
          <b-button @click="saveHotspotCounts" size="sm">
            Save Hotspot Counts
          </b-button>
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
import { ChartMixin } from '../ChartMixin'
import { mapState } from 'vuex'
import ChartDataToggle from '../ChartDataToggle.vue'

function sum(prev, next){
  return prev + next;
}

const providers = ['AT&T', 'T-Mobile', 'Verizon', 'Total']

export default {
  components: {
    ChartDataToggle
  },
  computed:{
    ...mapState('blendedLearningReadinessModule', ['hotspotList'])
  },
  props: {
    inputData: {
      type: Array,
      default: () => [{ att: 0, tmobile: 0, verizon: 0, total: 0 }]
    },
    userCanEdit: Boolean
  },
  data() {
    return {
      animationDuration: 1000,
      editMode: false,
      chartOptions: {
        chart: {
          type: 'bar'
        },
        title: {
          text: 'WiFi Hotspot Providers'
        },
        plotOptions: {
          bar: {
            minPointLength: 1
          }
        },          
        yAxis: {
          title: {
            text: 'Count'
          },
          allowDecimals: false
        },
        legend: {
          enabled: false
        },
        xAxis: {
          categories: providers
        },
        credits: {
          enabled: false
        },
        series: [{
          name: 'WiFi Hotspot Providers',
          colorByPoint: true,
          data: [0,0,0,0]
          }
        ]
      },
      chartMode: true
    }
  },
  mixins: [ChartMixin],
  methods: {
    dataItems() {
      return [
        { Provider: 'AT&T', Count: this.hotspotList.map(r => r.att).reduce(sum, 0) },
        { Provider: 'T-Mobile', Count: this.hotspotList.map(r => r.tMobile).reduce(sum, 0) },
        { Provider: 'Verizon', Count: this.hotspotList.map(r => r.verizon).reduce(sum, 0) },
        { Provider: 'Total', Count: this.hotspotList.map(r => r.total).reduce(sum, 0) }
      ]
    },
    saveHotspotCounts(){
      this.$store.dispatch('blendedLearningReadinessModule/saveHotspotCounts', this.hotspotList[0])
        .then(() => this.editMode = false )
    },
    startEdit(){
      this.editMode = true
    }
  },
  watch: {
    hotspotList: {
      immediate: true,
      deep: true,
      handler(newData) {
        this.chartOptions.series[0].data = [
          newData.map(r => r.att).reduce(sum, 0),
          newData.map(r => r.tMobile).reduce(sum, 0),
          newData.map(r => r.verizon).reduce(sum, 0),
          newData.map(r => r.total).reduce(sum, 0)
        ]
      }
    }
  }
}
</script>
<template>
  <div class="card bg-light mt-3">
    <a class="anchor" id="essa" />
    <div class="card-header">
      ESSA School Index
      <a
        style="float: right"
        class="btn btn-sm btn-light text-dark"
        target="_new"
        :href="'https://myschoolinfo.arkansas.gov/Schools/Detail/' + selectedLea.number + '?openreport=ESSASchoolIndex&tab=reports'"
      >
        <i class="fas fa-external-link-alt" />
        Open in MSI
      </a>
    </div>
    <div class="card-body">
      <div class="card-group">
        <essa-scores-chart :load-data="loadData" :selected-lea="selectedLea" />
        <essa-letter-grade-chart :load-data="loadData" :selected-lea="selectedLea" />
      </div>
    </div>
  </div>
</template>

<script>
import EssaScoresChart from './EssaScoresChart.vue'
import EssaLetterGradeChart from './EssaLetterGradeChart.vue'

export default {
  name: 'essa-dashboard-widget',
  data() {
    return { loadData: null }
  },
  props: {
    //selectedLea: Object
  },
  computed: {
    essaindex() {
      return this.$store.state.overviewModule.essaindex
    }
  },
  components: {
    EssaScoresChart,
    EssaLetterGradeChart
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        this.loadData = this.$store.dispatch('overviewModule/getEssaSchoolIndex', {
          lea: newLea.number
        })
      }
    }
  }
}
</script>
<template>
  <b-modal id="uploadModal" title="Upload File" size="lg" @ok="$emit('upload', file)" @show="onShow()">
    Click to select a file, then click the upload button
    <b-form-file inline v-model="file" :state="Boolean(file)" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      file: null
    }
  },
  methods: {
    onShow() {
      if (this) this.file = null
    }
  }
}
</script>

<style>

</style>
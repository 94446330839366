<template>
  <b-card header="Remedial Course Requirements">
    <!-- CTE Goal: Decrease the percent of first-time college students who enroll in a remedial course -->
    <!-- Bar chart of % of seniors who are accepted to college by have ACT scores requiring remedial courses; need to include all ACT subjects -->
    <highcharts class="chart" ref="chartRef" :callback="chartLoad" :options="chartOptions" />
  </b-card>
</template>

<script>
const loadingSpinner = '<i class="fas fa-spinner fa-spin"></i> Loading...'

import { Types } from '../../../../stores/careercoach/index.js'
import { mapState } from 'vuex'

//import Highcharts from 'highcharts'

export default {
  data() {
    return {
      loadingSpinner: loadingSpinner
    }
  },  
  computed: {
    ...mapState(Types.path, [Types.state.dashboard]),
    attendingCollege() {
      return this.dashboard['attendingCollege'] || []
    },
    remediation() {
      return this.attendingCollege.find(ac => ac['attendingCollege'] == 'No' && ac['tookACT'] == 'Yes') || {}
    },
    seriesData() {
      return [
        {
          y: (this.remediation['mathRemediationNeeded'] / this.remediation['totalNumberOfStudents']) * 100.0,
          needed: this.remediation['mathRemediationNeeded'],
          total: this.remediation['totalNumberOfStudents']
        },
        {
          y: (this.remediation['englishRemediationNeeded'] / this.remediation['totalNumberOfStudents']) * 100.0,
          needed: this.remediation['englishRemediationNeeded'],
          total: this.remediation['totalNumberOfStudents']
        },
        {
          y: (this.remediation['scienceRemediationNeeded'] / this.remediation['totalNumberOfStudents']) * 100.0,
          needed: this.remediation['scienceRemediationNeeded'],
          total: this.remediation['totalNumberOfStudents']
        },
        {
          y: (this.remediation['readingRemediationNeeded'] / this.remediation['totalNumberOfStudents']) * 100.0,
          needed: this.remediation['readingRemediationNeeded'],
          total: this.remediation['totalNumberOfStudents']
        }
      ]
    },
    chartOptions() {
      return {
        chart: {
          type: 'column',
          height: 280,
          spacing: [20, 20, 20, 20],
          backgroundColor: '#fff',
          plotBackgroundColor: '#eee',
          inverted: true
        },
        loading: {
          style: {
            position: 'absolute',
            backgroundColor: '#ffffff',
            opacity: 0.8,
            textAlign: 'center'
          }
        },        
        title: {
          text: 'Needed Remedial Courses for College-Enrolled Seniors'
        },
        exporting: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: ['Math', 'English', 'Science', 'Reading']
        },
        yAxis: {
          min: 0,
          max: 100,
          title: {
            text: 'Percent of College-Enrolled Seniors that took ACT'
          }
        },
        series: [
          {
            data: this.seriesData,
            colorByPoint: true,
            dataLabels: {
              enabled: true,
              formatter() {
                return `${Math.round(this.point.y)}% (${this.point.needed}/${this.point.total})`
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    dashboard: {
      immediate: true,
      handler() {
        this.chartLoad()  
      }
    }
  },
  methods: {
    chartLoad(chart) {
      if (chart) {
        if (this.dashboard.currentRoster.length == 0) {
          chart.showLoading(loadingSpinner)
        }
      }
      else if (this.$refs["chartRef"]) {
        if (this.dashboard.currentRoster.length > 0) {
          this.$refs["chartRef"].chart.hideLoading()
        }
        else
        {
          this.$refs["chartRef"].chart.showLoading(loadingSpinner)
        }
      }
    }
  }
}
</script>
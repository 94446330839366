import Vue from 'vue'
import { Promised } from "vue-promised"
import { mapState, mapGetters } from 'vuex'


const AttritionReportSidebar = Vue.extend({
    name: 'attrition-report-sidebar',
    /* html */
    template: `
    <Promised :promise=loadData v-slot:combined="{ isPending, isDelayOver, data, error }">
      <b-form-group class="ml-3 mr-3">
        <template slot="label">
          <h3>Trending</h3>
        </template>
        <b-form-radio-group style="width: 100%"
                            id="trendLevel"
                            v-model="trendLevel"
                            :options="trendLevels"
                            button-variant='outline-primary'
                            buttons
                            stacked
                            name="trendLevel"
                            :disabled="isPending">
        </b-form-radio-group>
      </b-form-group>
      <b-form-group class="ml-3 mr-3">
        <template slot="label">
          <h3>Job Position</h3>
        </template>
        <b-form-radio-group style="width: 100%"
                            id="position"
                            v-model="position"
                            :options="positions"
                            button-variant='outline-primary'
                            buttons
                            stacked
                            name="position"
                            :disabled="isPending">
        </b-form-radio-group>
      </b-form-group>
    </Promised>`,
    data() {
      return {
      }
    },
    components: {
      Promised
    },
    computed: {
      ...mapState('attritionReportModule', ['pageStateReady']),
      ...mapGetters('attritionReportModule', ['effectiveReportInfo']),
      dictionaryReady() {
        return this.$store.state.globalModule.leaContext.dictionaryReady
      },
      trendLevel: {
        get() {
          return this.$store.getters['attritionReportModule/trendLevel']
        },
        set(newValue) {
          if (this.$printMode) return

          this.$store.commit('globalModule/setAddDebugMessage', `Setting trend level to ${newValue}`)
          this.$store.commit('attritionReportModule/setTrendLevel', newValue)
          setTimeout(this.refresh, 500)
        }
      },
      position: {
        get() {
          return this.$store.getters['attritionReportModule/position']
        },
        set(newValue) {
          if (this.$printMode) return

          this.$store.commit('globalModule/setAddDebugMessage', `Setting position to ${newValue}`)
          this.$store.commit('attritionReportModule/setPosition', newValue)
          setTimeout(this.refresh, 500)
        }
      },
      selectedLea() {
        return this.$store.state.globalModule.leaContext.SelectedLea
      },
      loadData() {
        return this.$store.state.attritionReportModule.loadData
      },
      trendLevels() {
        return this.$store.getters['attritionReportModule/trendLevels']
      },
      positions() {
        return this.$store.getters['attritionReportModule/positions']
      }
    },
    methods: {
      refresh() {
        if (this.$printMode && !this.dictionaryReady) return

        // eslint-disable-next-line
        if (this.pageStateReady && this.selectedLea && this.selectedLea.number) {
          this.$store.commit('globalModule/setAddDebugMessage', 'Sidebar - Refresh Data')
          this.$store.dispatch('attritionReportModule/refresh')
        } else {
          this.$store.commit('globalModule/setAddDebugMessage', 'Sidebar - Deferring refresh')
        }
      }
    },
    watch: {
      selectedLea: {
        immediate: true,
        handler() {
          if (!this.initialTrendLevel && this.selectedLea && this.selectedLea.type) {
            this.trendLevel = this.effectiveReportInfo.level
            if (this.selectedLea.type == 1) {
              this.position = 'principal'
              return
            }
          }
          setTimeout(this.refresh, 500)
        }
      },
      pageStateReady() {
        this.refresh()
      },
      dictionaryReady() {
        this.refresh()
      }
    },
    props: {
      initialPosition: String,
      initialTrendLevel: String
    },
    mounted() {
      this.$store.commit('globalModule/setAddDebugMessage', `Sidebar mounted with trendLevel ${this.initialTrendLevel} and position ${this.initialPosition}`)

      const pageState = {
        trendLevel: this.initialTrendLevel || this.effectiveReportInfo.level,
        position: this.initialPosition || this.selectedLea.type == 1 ? 'principal' : 'superintendent'
      }
      this.$store.commit('attritionReportModule/setPageState', pageState)
    }
})
export { AttritionReportSidebar }
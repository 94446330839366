<template>
  <div class="card-deck mt-3">
    <div class="card bg-white">
      <div class="card-header row" :id="`category-${category.id}`" :class="{ 'bg-dark': planCategoryEnabled, 'bg-secondary': !planCategoryEnabled }">        
        <div class="col-9 align-middle"> 
          <span class="category-heading" :class="{ 'cursor-hand': planCategoryEnabled }" @click="toggleCollapsed">{{ category.name }}</span>
          <i v-if="!planCategoryEnabled && programExpense.budgeted > 0" class="mx-2 fas fa-exclamation-triangle text-warning" title="Disabled program has a budgeted amount" />
          <i v-if="planCategoryEnabled && programExpense.budgeted == 0" class="mx-2 fas fa-exclamation-triangle text-warning" title="Enabled program does not have a budgeted amount" />
          <b-form-checkbox v-model="planCategoryEnabled" v-if="canEdit" switch> 
            {{ planCategoryEnabled ? 'Enabled' : 'Disabled' }}
          </b-form-checkbox>
        </div>
        <div class="col-3 px-0"> 
          <div class="pull-right pt-1 ml-3">
            <i v-if="planCategoryEnabled" :class="collapsedChevron" @click="toggleCollapsed" />
            <i v-else class="fas fa-chevron-up text-secondary" />
          </div>
          <div class="pull-right pt-3">
            <img v-if="!planCategoryEnabled && programExpense.budgeted > 0" :src="USDIcon" class="svg-icon svg-icon-category" title="Category (program) is NOT enabled on plan but program code has budget amount(s)">
            <img v-if="planCategoryEnabled && programExpense.budgeted > 0" :src="InvoiceIcon" class="svg-icon svg-icon-category svg-icon-invert" title="Category (program) is enabled on plan and program code has budget amount(s)">
            <img v-if="planCategoryEnabled && programExpense.budgeted <= 0" :src="PageIcon" class="svg-icon svg-icon-category" title="Category (program) is enabled on plan but program code DOES NOT have budget amount(s)">
          </div>
          <div class="text-right pull-right mx-2">
            Budgeted: {{ programExpense.budgeted.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}
            <br>
            Actual: {{ programExpense.actual.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}
          </div>
        </div>
      </div>
      <div class="card-body" v-if="planCategoryEnabled" :class="{ 'collapsed-category': collapsed }">
        <!-- <h4 class="card-title text-primary" size="lg" >
        </h4> -->
        <div class="pull-right d-print-none">
          <a class="btn btn-link" size="sm" href="#plan-files">
            <i class="fas fa-chevron-down" /> Supporting Files
          </a>
        </div>
        <h5 class="card-title mb-0" v-if="category.description">
          {{ category.description }}
        </h5>
        
        <div class="my-1 font-italic">
          Legend -
          <span class="badge bg-med text-dark mx-1">
            Draft with no plan input
          </span>
          <span class="badge badge-primary text-white mx-1">
            Has plan input
          </span>
          <span class="badge badge-success text-white mx-1">
            Submitted
          </span>
          <span class="badge badge-danger text-white">
            Returned for changes
          </span>
          <span class="badge badge-warning text-dark mx-1">
            Not Enabled
          </span>
        </div>
        <div class="card-deck">
          <plan-category-type v-for="type in enabledTypes" :type="type" :category="category" :key="type.key" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sumBy from 'lodash.sumby'
import { mapState } from 'vuex'
import { Types } from '@/modules/esa/stores/'
import PlanCategoryType from './PlanCategoryType.vue'
import USDIcon from './usd-icon.svg'
import PageIcon from './page-icon.svg'
import InvoiceIcon from './invoice-icon.svg'
import { PlanMixin } from './PlanMixin'

export default {
  data() {
    return {
      USDIcon,
      PageIcon,
      InvoiceIcon,
      collapsed: !this.$printMode
    }
  },
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  mixins: [PlanMixin],
  computed: {
    ...mapState(Types.path, [Types.state.planResponses, Types.state.planExpenses, Types.state.plan]),
    enabledTypes() {
      return this.category.types//.filter(t => t.enabled)
    },
    disabledCategories() {
      if (!this.plan)
        return []
      const response = JSON.parse(this.plan.response || "{ \"disabledCategories\": [] }")
      return response.disabledCategories || []
    },
    planCategoryEnabled: {
      get() {
        return !(this.disabledCategories).includes(this.category.id)
      },
      set(enabled) {
        this.$store.dispatch(Types.actions.toggleLeaPlanCategory, { lea: this.plan.districtLea, id: this.plan.leaPlanId, planCategoryId: this.category.id, enabled })
        if (enabled) this.collapsed = false
      }
    },
    programExpense() {
      const expense = this.planExpenses.filter(e => this.category.programCode.includes(e.program))
      return expense ? { budgeted: sumBy(expense, 'budgeted'), actual: sumBy(expense, 'actual') } : { budgeted: 0, actual: 0 }
    },
    collapsedChevron() {
      return !this.collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up'
    },
  },
  methods: {
    toggleCollapsed() {
      this.collapsed = !this.collapsed
    }
  },
  components: {
    PlanCategoryType
  },
  watch: {
  }
}

</script>

<style>
.category-type-card {
  padding: 10px 10px 0px 10px;
}
.category-heading {
  scroll-margin-top: 160px;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.5rem;
  vertical-align: middle;
}
.card-header {
  margin-left: initial;
  margin-right: initial;
}
.svg-icon-category {
  height: 2em !important;
  width: 2em !important;
}
.svg-icon-invert {
  filter: brightness(500%)
}
.collapsed-category {
  max-height: 0;
  height: 0;
  overflow: hidden;
  padding: 0px;
  border: 0px;
  margin: 0px;
}
.cursor-hand {
  cursor: pointer;
}
    
</style>

<template>
  <div>
    <h5 v-if="documents.length == 0">
      Request has no documents
    </h5>
    <b-list-group v-else>
      <b-list-group-item v-for="document in documents" :key="document.requestDocumentId">
        <span>
          <a :href="getUrl(document.requestDocumentId)">{{ document.name }}</a>
        </span>
        <span class="pull-right" v-if="!displayOnly">
          <i class="fas fa-times" style="cursor: pointer" @click="remove(document.requestDocumentId)" />
        </span>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  props: {
    requestId: {
      type: String,
      required: true
    },
    displayOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    documents() {
      return this.$store.state.toolsModule.leaAssignment.currentRequest.requestDocuments || []
    }
  },
  methods: {
    getUrl(requestDocumentId) {
      return `/api/request/${this.requestId}/document/${requestDocumentId}`
    },
    remove(requestDocumentId) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this document?')
        .then(value => {
          if (value) {
            this.$store.dispatch('toolsModule/leaAssignment/removeRequestDocument', { requestId: this.requestId, requestDocumentId })
          }
        })
    }
  }
}
</script>
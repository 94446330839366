<template>
  <b-card :title="`Perkins V Administration for ${formatAY($route.params.ay)} Academic Year`">
    <b-table-lite :items="selectedYear.startupTasks" :fields="fields" small class="mt-2">
      <template v-slot:head(taskCode)>
        Startup Task
      </template>
      <template v-slot:cell(taskCode)="data">
        <router-link :to="{name: taskInfo(data.item.taskCode).routeName}" title="Perform task" v-if="canPerform(data.item.taskCode)">
          {{ taskInfo(data.value).name }} 
        </router-link> 
        <span v-else class="text-muted">
          {{ taskInfo(data.value).name }} 
        </span>
      </template>
      <template v-slot:cell(dependsOn)="data">
        <ul class="list-unstyled small">
          <li v-for="t in taskInfo(data.item.taskCode).dependsOn" :key="t">
            <i class="fas" :class="{'fa-check-circle text-success': taskComplete(t), 'fa-times-circle text-danger': !taskComplete(t)}" /> {{ taskInfo(t).name }}
          </li>
        </ul>
      </template>
      <template v-slot:cell(completed)="data">
        <pretty-checkbox :value="data.value" />
      </template>
      <template v-slot:cell(action)="data">
        <router-link :to="{name: taskInfo(data.item.taskCode).routeName}" title="Perform task" v-if="canPerform(data.item.taskCode)">
          <i class="fas fa-play" />
        </router-link>      
      </template>
    </b-table-lite>
  </b-card>
</template>

<script>
import PrettyCheckbox from '@/components/PrettyCheckbox'
import { Types } from '@/modules/cte/stores/perkinsv'
import { mapState } from 'vuex'
import { formatAY } from '@/helpers/formatters'

const TaskMap = {
  CM: { name: "Consortia Maintenance", routeName: "CTE/PerkinsV/Admin/AcademicYear/Consortia" },
  PIM: { name: "Perkins Indicator Maintenance", routeName: "Admin/Indicators" },
  OAM : { name: "Cluster Management", routeName: "CTE/PerkinsV/Admin/AcademicYear/Clusters" },
  CTM: { name: "Cost Type Maintenance", routeName: "Admin/CostTypes" },
  FLDU: { name: "Perkins V Funding Limits Dataset Upload", routeName: "CTE/PerkinsV/Admin/AcademicYear/FundingLimits", dependsOn: ['CM'] },
  PIPDU: { name: "Perkins V Performance Dataset Upload", routeName: "CTE/PerkinsV/Admin/AcademicYear/IndicatorPerformance", dependsOn: ['CM', 'PIM'] },
  AYENABLE : { name: "Enable Academic Year", routeName: "CTE/PerkinsV/Admin/AcademicYear/Enable", dependsOn: ['CM', 'PIM', 'OAM', 'CTM', 'FLDU', 'PIPDU'] },
}

export default {
  data() {
    return {
      formatAY,
      TaskMap,
      fields: [
        {key: 'action', class: "text-center", label: ''},
        {key: 'taskCode', class: "font-weight-bold", tdClass: 'text-primary'},
        {key: 'dependsOn'},
        {key: 'completed', class: "text-center"},
      ]
    }
  },
  mounted() {
    this.$store.dispatch(Types.actions.getAcademicYear, { academicYear : this.$route.params.ay })
  },
  methods: {
    taskInfo(taskCode) {
      return this.TaskMap[taskCode] || { name: `${taskCode} not defined`, routeName: null }
    },
    taskComplete(taskCode) {
      return this.selectedYear.startupTasks.filter(t=>t.taskCode === taskCode)[0].completed
    },
    canPerform(taskCode) {
      var canPerform = true

      if (this.TaskMap[taskCode] && this.TaskMap[taskCode].dependsOn) {
          this.TaskMap[taskCode].dependsOn.forEach(element => {
          if (!this.taskComplete(element)) canPerform = false
        });
      }

      return canPerform
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.selectedYear])
  },
  components: {
    PrettyCheckbox
  }
}
</script>

import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line
import { actionLogger } from 'vuex-trace'
import createPersistedState from 'vuex-persistedstate'
import globalModule from './globalModule'
import { OfflineModule, Types as OfflineTypes } from './offlineModule'
import { HCPTModule } from './hcptModule'
import { HCPTAdminModule } from './hcptAdminModule'
import { AttritionReportModule } from './attritionReportModule'
import { ShortageReportModule } from './shortageReportModule'
import { OverviewModule } from './overviewModule'
import { AdminModule } from './adminModule'
import { ShortageReportAdminModule } from './shortageReportAdministration'
import { ScheduleModule } from './scheduleModule'
import { AcademicsModule } from './academics'
import { RequestModule } from './request/request'
import { ToolsModule } from './tools'
import { DPSAFT } from './dpsaft'
import { BlendedLearningReadinessModule } from './blendedLearningReadinessModule'
import { ArkansasReadyForLearningModule } from './arkansasReadyForLearningModule'
import { FinanceModule } from './financeModule'
import { OnDemandModule } from './onDemandModule'
import { Surveys } from './surveys'
import { CTE } from '@/modules/cte/stores'
import { DigitalLearning } from './digitalLearning'
import { ESA } from '@/modules/esa/stores'
import { Purple } from '@/modules/purple/stores'
import { Core } from './core'
import { TeacherVAMModule } from './teacherVAMModule'
import { ParaprofessionalModule } from './paraprofessionalModule'
import { RVSModule } from './rvsModule'
import { RVSTroubleshootingModule } from './rvsTroubleshooting'

Vue.use(Vuex)

const persistedStateLeas = createPersistedState({
  key: 'lea-insights-leas',
  storage: sessionStorage,
  reducer: state => ({ globalModule: { leaContext: { source: state.globalModule.leaContext.source, Leas: state.globalModule.leaContext.Leas, LeaDictionary: state.globalModule.leaContext.LeaDictionary }}}),
  filter: mutation => ['globalModule/setLeas'].indexOf(mutation.type) !== -1,
  arrayMerger: (store, saved) => Object.assign({}, store, saved)
})

const persistedStateTimestamp = createPersistedState({
  key: 'lea-insights-leas-timestamp',
  storage: localStorage,
  reducer: state => ({ globalModule: { CacheTimestamp: state.globalModule.CacheTimestamp }}),
  arrayMerger: (store, saved) => Object.assign({}, store, saved)
})

const persistedStateDPSAFTFilters = createPersistedState({
  key: 'lea-insights-dpsaft-filters',
  storage: sessionStorage,
  reducer: state => ({ 
    DPSAFT: { 
      filter: { 
        area: state.DPSAFT.filter.area, 
        coop: state.DPSAFT.filter.coop, 
        district: (window.userContext.districtLEA && window.userContext.districtLEA.trim().length == 7) ? window.userContext.districtLEA.trim() : state.DPSAFT.filter.district,
        building: state.DPSAFT.filter.building
      }
    }
  }),
  filter: mutation => ['DPSAFT/setFilterArea', 'DPSAFT/setFilterCoop', 'DPSAFT/setFilterDistrict', 'DPSAFT/setFilterBuilding'].every(m => !m.includes(mutation.type)),
  arrayMerger: (store, saved) => Object.assign({}, store, saved)
})

const persistedStateDPSAFTFacilities = createPersistedState({
  key: 'lea-insights-dpsaft-facilties',
  storage: localStorage,
  reducer: state => ({ 
    DPSAFT: { 
      Facilities: { 
        InspectionItems: state.DPSAFT.Facilities.InspectionItems,
        inspectionBatches: state.DPSAFT.Facilities.inspectionBatches,
        BatchMaintenance: state.DPSAFT.Facilities.BatchMaintenance,
        SelectedBatch: state.DPSAFT.Facilities.SelectedBatch,
        SelectedInspection: state.DPSAFT.Facilities.SelectedInspection,
        InspectionHistory: state.DPSAFT.Facilities.InspectionHistory
      }
    }
  }),
  filter: () => true, // mutation => ['globalModule/setSelectedLea'].indexOf(mutation.type) !== -1,
  ///arrayMerger: (store, saved) => Object.assign({}, store, saved)
})

const persistedStateOffline = createPersistedState({
  key: 'lea-insights-offline',
  storage: localStorage,
  reducer: state => ({ 
    offline: { 
      queuedActions: state.offline.queuedActions
    }
  }),
  filter: () => true,
  //arrayMerger: (store, saved) => Object.assign([], store, saved)
})

const persistedStateSelectedLea = createPersistedState({
  key: 'lea-insights-selected-lea',
  storage: sessionStorage,
  reducer: (state) => ({ globalModule: { leaContext: { SelectedLea: state.globalModule.leaContext.SelectedLea }}}),
  filter: mutation => ['globalModule/setSelectedLea'].indexOf(mutation.type) !== -1,
  arrayMerger: (store, saved) => Object.assign({}, store, saved)
})

const persistedCareerCoachRosterTableOptions = createPersistedState({
  key: 'career-coach-roster-filters',
  storage: sessionStorage,
  reducer: state => ({
    cte: {
      careercoach: {
        rosterGradeFilter: state.cte.careercoach.rosterGradeFilter,
        rosterSortOption: state.cte.careercoach.rosterSortOption
      }
    }
  }),
  filter: mutation => ['CTE/CareerCoach/setRosterGradeFilter', 'CTE/CareerCoach/setRosterSortOption'].every(m => !m.includes(mutation.type)),
  arrayMerger: (store, saved) => Object.assign({}, store, saved)
})

function updateConnectionStatus() {
  console.log(navigator.onLine ? 'Back online' : 'Working offline')
  window.global.default.VueApp.$store.commit('globalModule/setIsOnline', navigator.onLine)
  if (navigator.onLine) {
    window.global.default.VueApp.$store.dispatch(OfflineTypes.actions.processQueuedActions, {})
  }
}

window.addEventListener('online', updateConnectionStatus)
window.addEventListener('offline', updateConnectionStatus)

function clearExpiredCaches() {
  if (!navigator.onLine) return
  console.log('clearing expired caches')
  var offset = 25 * 60 * 60 * 1000
  var timestamp = JSON.parse(window.localStorage.getItem('lea-insights-leas-timestamp'))?.globalModule?.CacheTimestamp
  if (!timestamp || Date.now() - timestamp >= offset) {
    window.sessionStorage.removeItem('lea-insights-leas')
  }
}

clearExpiredCaches()

export default new Vuex.Store({
  plugins: [
    persistedStateLeas, 
    persistedStateTimestamp,
    persistedStateSelectedLea, 
    persistedStateDPSAFTFilters, 
    persistedStateDPSAFTFacilities,
    persistedStateOffline,
    persistedCareerCoachRosterTableOptions
  ],
  modules: {
    globalModule: new globalModule(),
    offline: new OfflineModule(),
    financeModule: new FinanceModule(),
    onDemandModule: new OnDemandModule(),
    hcptModule: new HCPTModule(),
    hcptAdminModule: new HCPTAdminModule(),
    overviewModule: new OverviewModule(),
    attritionReportModule: new AttritionReportModule(),
    shortageReportModule: new ShortageReportModule(),
    adminModule: new AdminModule(),
    shortageReportAdminModule: new ShortageReportAdminModule(),
    scheduleModule: new ScheduleModule(),
    academicsModule: new AcademicsModule(),
    requestModule: new RequestModule(),
    toolsModule: new ToolsModule(),
    DPSAFT: new DPSAFT(),
    blendedLearningReadinessModule: new BlendedLearningReadinessModule(),
    arkansasReadyForLearningModule: new ArkansasReadyForLearningModule(),
    surveys: new Surveys(),
    cte: new CTE(),
    digitalLearning: new DigitalLearning(),
    ESA: new ESA(),
    Purple: new Purple(),
    core: new Core(),
    teacherVAMModule: new TeacherVAMModule(),
    paraprofessional: new ParaprofessionalModule(),
    rvsModule: new RVSModule(),
    rvsTroubleshooting: new RVSTroubleshootingModule()
  },
  state: {},
  mutations: { },
  actions: {}
})

export const VisibilityDirectives = {
  hideOnSurvey: 1,
  hideOnAdmin: 2,
  hideOnExport: 4,
  get hideOnAll() {return this.hideOnSurvey | this.hideOnAdmin | this.hideOnExport}
}

export const surveyTemplate = {
  sections: [
    {
      name: 'General Questions',
      target: 'generalQuestions',
      questions: [
        {
          id: 'currentGradeLevel',
          text: 'Select your current grade level.  If multiple grade levels are taught, choose the grade band that is most applicable.',
          component: 'QuestionSingleSelection',
          choices: ['PK-5', '6-8', '9-12']
        },
        {
          id: 'yearsBeenTeaching',
          text: 'How many years have you been teaching?',
          component: 'QuestionSingleSelection',
          choices: ['Less than 5', '5-10', '11-20', 'More than 20']
        },
        {
          id: 'describeTeachingResponsibility',
          text: 'Which of the following best describes your teaching responsibility?',
          component: 'QuestionSingleSelection',
          choices: ['I teach remote students only.', 'I teach face-to-face students only.', 'I teach remote students and face-to-face-students.']
        },
        {
          id: 'professionalChallenges',
          text: 'Select up to 3 professional challenges that have you personally experienced due to COVID.',
          component: 'QuestionTagSelection',
          tagPrompt: 'Select up to 3...',
          limit: 3,
          choices: [
            'Student Engagement',
            'Virtual Instruction',
            'Absences due to isolation/quarantine',
            'Access to resources',
            'Teacher and Staff Well-Being/Health',
            'Student Well-Being/Health',
            'Access to Technology',
            'Comfort Level with new technology platforms',
            'Implementing COVID-19 safety precautions',
            'Communication with students/parents',
            'Other'
          ]
        },
        {
          id: 'professionalChallengesOther',
          text: 'If you answered "Other" to the last question, professional challenges, please describe.',
          component: 'QuestionFreeText'
        },
        {
          id: 'professionalAdvantages',
          text: 'Select up to 3 professional advantages that have you personally experienced due to COVID.',
          component: 'QuestionTagSelection',
          tagPrompt: 'Select up to 3...',
          limit: 3,
          choices: [
            'Student Engagement',
            'Virtual Instruction',
            'Personalized Learning',
            'Instructional/Educator Growth',
            'Access to technology/digital tools',
            'Increased Teaming, Peer Support, and/or Collaboration',
            'The flexibility of digital learning' ,
            'Respect, appreciation, and support from parents and the public at large',
            'Relationship-building with students and parents',
            'Access to resources',
            'Communication with students/parents',
            'Other'
          ]
        },
        {
          id: 'professionalAdvantagesOther',
          text: 'If you answered "Other" to the last question, professional advantages, please describe.',
          component: 'QuestionFreeText'
        },
      ]    
    },
    {
      name: 'Learning Management System/Digital Content',
      target: 'lms',
      questions: [
        {
          id: 'whatDescribesUseOfDigitalContent',
          text: 'Which of the following best describes your use of digital content:',
          component: 'QuestionSingleSelection',
          choices: ['Create my own', 'District created content', 'Use a content provider', 'Mixture of all of the above']
        },
        {
          id: 'preparedToCreateDigitalContent',
          text: 'How comfortable are you in using quality digital content?',
          component: 'QuestionSingleSelection',
          choices: ['1 (not comfortable at all)', '2', '3 (comfortable)', '4', '5 (very comfortable)']
        },
        {
          id: 'preparedToIncorporateDigitalContent',
          text: 'How comfortable are you in using your learning management system?',
          component: 'QuestionSingleSelection',
          choices: ['1 (not comfortable at all)', '2', '3 (comfortable)', '4', '5 (very comfortable)']
        }
      ]
    },
    {
      name: 'COVID-19 State Provided Resources',
      target: 'stateProvidedResources',
      questions: [
        {
          id: 'useOfLincolnLearning',
          text: 'If your district is partnering with Lincoln Learning for digital content would you recommend the state continue its investment in Lincoln Learning?',
          component: 'QuestionSingleSelection',
          choices: ['Yes', 'No', 'Not applicable', 'Not sure'],
          hidden: VisibilityDirectives.hideOnSurvey | VisibilityDirectives.hideOnAdmin
        },
        {
          id: 'utilizationOfBackToSchoolPlaybook',
          text: 'In preparation for returning to school, how would you characterize your utilization of the Back to School Playbook:',
          component: 'QuestionSingleSelection',
          choices: ['Often', 'Sometimes', 'Rarely', 'Not at all', 'Unaware of the Playbook']
        }
      ]
    },
    {
      name: 'Social Emotional Support',
      target: 'socialEmotionalSupport',
      questions: [
        {
          id: 'emotionalSupportTime',
          text: 'Is time provided to support students’ social-emotional learning such as advisory, homeroom, etc.?',
          component: 'QuestionSingleSelection',
          choices: ['Yes', 'No', 'Not Sure']
        },
        {
          id: 'emotionalSupportAccess',
          text: 'Do you and your students have access to curriculum or resources that support student social-emotional learning?',
          component: 'QuestionSingleSelection',
          choices: ['Yes', 'No', 'Not Sure']
        }
      ]
    },
    {
      name: 'Student Engagement',
      target: 'studentEngagement',
      questions: [
        {
          id: 'ableToProvideEngagingInstructionInPerson',
          text: 'I am able to provide engaging instruction and quality assessments for students who are in-person this year.',
          component: 'QuestionSingleSelection',
          choices: ['1 (Not confident at all)', '2', '3 (Somewhat confident)', '4', '5 (Completely confident)']
        },
        {
          id: 'ableToProvideEngagingInstructionRemotely',
          text: 'I am able to provide engaging instruction and quality assessments for students who are learning remotely or virtually.',
          component: 'QuestionSingleSelection',
          choices: ['1 (Not confident at all)', '2', '3 (Somewhat confident)', '4', '5 (Completely confident)']
        },
        {
          id: 'percentageVirtualRegularlyCommunicating',
          text: 'What percentage of your virtual/remote students are regularly communicating and/or submitting assignments/assessments?',
          component: 'QuestionSingleSelection',
          choices: [
            '0-25%',
            '26-50%',
            '51-75%',
            '76-100%'
          ]
        }
      ]
    },
    {
      name: 'Teacher Support',
      target: 'teacherSupport',
      questions: [
        {
          id: 'timeSpentOutsideContractHours',
          text: 'Compared with the previous year, is the time spent outside of contract hours this year:',
          component: 'QuestionSingleSelection',
          choices: [
            'Less than the previous year',
            'About the same as the previous year',
            '1-4 hours more than the previous year',
            '5-10 hours more than the previous year',
            '10+ more than the previous year'
          ]
        },
        {
          id: 'districtHasSystemForInput',
          text: 'My school/district has a system for me to provide input as it relates to curriculum and instruction.',
          component: 'QuestionSingleSelection',
          choices: ['Agree', 'Disagree', 'I\'m not sure']
        },
        {
          id: 'districtMadeModificationsToRFLPlan',
          text: 'Has your district made modifications to its original Ready for Learning plan in response to providing relief to the stresses you may feel in this COVID teaching and learning environment? (Choose all that apply)',
          component: 'QuestionMultipleSelection',
          choices: [
            'Early Dismissal',
            'Additional Planning Time',
            'Weekly Time (a day)',
            'Monthly Time (a day or two)',
            'No Changes Needed',
            'No Changes at this time'
          ]
        },
        {
          id: 'needBlendedLearningSupport',
          text: 'Choose up to 3 additional supports for implementing digital learning and/or remote learning.',
          component: 'QuestionTagSelection',
          limit: 3,
          choices: [
            'Using video-conferencing systems (Zoom, Google Meet, etc.)',
            'Operating the features of the learning management systems',
            'Designing lessons that engage both virtual and face-to-face learners',
            'Providing interventions and small-group instruction',
            'Building relationships with students and families',
            'Communicating with students and families',
            'Learning about different classroom and school models of blended learning',
            'Setting up a blended classroom',
            'Classroom management for blended learning classrooms',
            'Connecting with remote learners',
            'Meeting the needs of students with special needs',
            'Time management'
          ]
        },
        {
          id: 'rankAdditionalSupport',
          text: 'Rank order (1-best supportive to 4-least supportive) I need additional support in the following areas to support the social and emotional needs of learners in a in-person and/or remote learning environment.',
          component: 'QuestionRanking',
          choices: [
            'Strategies to incorporate social and emotional learning in classroom practice',
            'Strategies to incorporate culturally responsive teaching in classroom practice',
            'Understanding trauma-informed teaching practices',
            'Ways to connect effectively with students and families in a virtual setting'
          ]
        },
        {
          id: 'rankDigitalLearningComfortLevel',
          text: 'Rank order (1-best supportive to 9-least supportive) Which of the following strategies would best support an increased confidence level and degree of preparedness with digital teaching and learning?',
          component: 'QuestionRanking',
          choices: [
            'Training in how to design my own digital content',
            'Protected planning time to provide feedback to students',
            'Access to hardware, software, and connectivity for myself',
            'Access to hardware, software, and connectivity for my students',
            'Strong partnership with families',
            'Time to set up my digital learning spaces',
            'One-on-one coaching in digital learning',
            'Small group (grade level or content level) training in digital learning',
            'Independent time to spend learning about the components of digital learning (LMS, digital content, etc.)'
          ]
        }
      ]
    },
    {
      name: 'Additional Comments',
      target: 'additionalComments',
      hidden: VisibilityDirectives.hideOnAdmin,
      questions: [
        {
          id: 'anyAdditionalComments',
          text: 'Share any additional comments:',
          component: 'QuestionFreeText'
        }
      ]
    }
  ]
}
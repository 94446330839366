<template lang="html">
  <li class="nav-item">
    <button v-if="selectedLeaName" id="show-lea-sidebar" @click="toggleLeaSidebar" class="nav-link btn btn-sm btn-secondary">
      <i class="fas fa-map-marker-alt" /> {{ selectedLeaName }}
    </button>
    <nav id="lea-sidebar-wrapper" class="lea-sidebar-wrapper">
      <div id="lea-sidebar-content" class="lea-sidebar-content card bg-light">
        <div class="lea-sidebar-brand card-header sticky-top">
          Select an LEA to Change Context
          <div id="close-sidebar">
            <i class="fas fa-times" @click="toggleLeaSidebar" />
          </div>
          <div class="input-group">
            <input type="text" class="form-control search-menu" :value="searchTerm" @input="debounceInput" placeholder="Start typing to search...">
            <div class="input-group-append">
              <span class="input-group-text">
                <i class="fa fa-search" aria-hidden="true" />
              </span>
            </div>
          </div>
        </div>
        <div class="lea-sidebar-search">
          <div class="sticky-top" />
        </div>
        <div>
          <ul>
            <lea-template v-if="leaList" :lea="leaList" :search-term="searchTerm" />
          </ul>
        </div>
      </div>
    </nav>
  </li>
</template>

<script>
import { mapGetters } from 'vuex'
import LeaTemplate from './LeaTemplate.vue'
import { deepClone, debounce } from '../helpers/globalHelpers'
import { LeaTypes } from '../helpers/leaTypes'

function replaceOwnedLeas(lea, newOwnedLeas) {
  return Object.assign(lea, { ownedLeas: newOwnedLeas })
}

var scrolltToOptions = {
  container: '#lea-sidebar-content',
  easing: 'ease-in',
  offset: -100,
  force: true,
  cancelable: true,
  x: false,
  y: true
}
export default {
  data() {
    return {
      toggled: false,
      searchTerm: '',
      searchTermLowered: ''
    } 
  },
  components: {
    LeaTemplate
  },
  computed: {
    ...mapGetters('globalModule', ['selectedLeaName']),
    leaList() {
      let list = this.$store.getters[this.getter]
      if (this.excludeSchools) {
        list = replaceOwnedLeas(list, list.ownedLeas.map(coop => replaceOwnedLeas(coop, coop.ownedLeas.map(district => replaceOwnedLeas(district, [])))))
      }
      if (this.searchTerm.length > 2) {
        return { 
          name: 'State of Arkansas',
          number: 'AR',
          type: LeaTypes.State,
          ownedLeas: this.search(deepClone(list.ownedLeas))
        }
      }
      return list
    },
    selectedLea() {
      return this.$store.state.globalModule.leaContext.SelectedLea
    }
  },
  methods: {
      toggleLeaSidebar() {
        this.$root.$data.leaSidebarActive = !this.$root.$data.leaSidebarActive
        this.$data.toggled = !this.$data.toggled
      },
      matches(lea) {
        return lea.name.toLowerCase().indexOf(this.searchTermLowered) > -1 || lea.number.indexOf(this.searchTermLowered) > -1
      },
      search(leas) {
        return leas.filter(coopLea => {
          coopLea.ownedLeas = coopLea.ownedLeas.filter(disLea => {
            disLea.ownedLeas = disLea.ownedLeas.filter(this.matches)
            return disLea.ownedLeas.length > 0 || this.matches(disLea)
          })
          return coopLea.ownedLeas.length > 0 || this.matches(coopLea)
        })
      },
      closeLeaSidebar()
      {
        this.searchTerm = ''
        this.$root.$data.leaSidebarActive = false
        this.$data.toggled = false
        document.querySelector(".lea-sidebar-content").scrollTop = 0
      },
      debounceInput: debounce(function (e) {
        this.searchTerm = e.target.value
        this.searchTermLowered = this.searchTerm.toLowerCase()
      }, 300)
  },
  props: {
    getter: {
      type: String,
      default: 'default'
    },
    excludeSchools: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        var self = this
        this.$data.searchTerm = ''
        if (newLea && newLea.number) {
          setTimeout(function() {
            self.$scrollTo(`#lea-${newLea.number}`, 10, scrolltToOptions)
          }, 300)
        }
      }
    }
  }
}
</script>

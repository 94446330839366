<template>
  <div class="d-print-none">
    <div class="mb-2 card" v-if="request && currentStatus">
      <div class="card-header">
        Approval Workflow
      </div>
      <div class="card-body p-3">
        <workflow-status-badge :state-map="stateMap" :status="selectedWaiverRequest.status" :document="request" />
        <div class="row mt-2" v-if="currentStatus && potentialStateCount">
          <div class="col-md-12">
            <Promised :promise="actionPromise">
              <template v-slot:default>
                <b-button block class="mt-2 disabled" disabled="disabled">
                  <i class="fas fa-spinner fa-spin" />
                  &nbsp;Processing...
                </b-button>
              </template>

              <template v-slot:pending>
                <b-button block class="mt-2 disabled" disabled="disabled">
                  <i class="fas fa-spinner fa-spin" />
                  &nbsp;Processing...
                </b-button>
              </template>

              <template v-slot:rejected="error">
                <button @click="confirmStatusChange" class="btn btn-info float-right d-print-none">
                  <i class="fas fa-exclamation-circle" />
                  &nbsp;{{ getActionText(currentStatus) }}
                  <span class="d-none">{{ error }}</span>
                </button>
              </template>
            </Promised>
            <div class="row">
              <div :class="stateActionContainerClass" v-for="availableState in states" :key="availableState" v-show="actionPromise == null">
                <div v-if="!stateMap.get(availableState).hide">
                  <div v-if="stateMap.get(availableState).requiresComment">
                    <hr>
                    <div class="mt-2">
                      <textarea v-model="commentText" class="form-control" :placeholder="`You must provide a comment to ${getActionText(availableState)}...`" />
                    </div>
                    <b-button block
                              class="mt-1"
                              :disabled="commentText.length == 0"
                              @click="confirmStatusChange(stateMap.get(availableState))"
                              :variant="stateMap.get(availableState).variant || 'primary'">
                      {{ getActionText(availableState) }}
                    </b-button>
                  </div>
                  <div v-else>
                    <b-button block
                              @click="confirmStatusChange(stateMap.get(availableState))"
                              :variant="stateMap.get(availableState).variant || 'primary'">
                      {{ getActionText(availableState) }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import WorkflowStatusBadge from '@/components/workflow/WorkflowStatusBadge.vue'
import { Promised } from 'vue-promised'
import { Types, StateMap } from '@/stores/digitalLearning'

export default {
  data() {
    return {
      commentText: '',
      actionPromise: null,
      stateMap: StateMap,
    }
  },
  components: {
    Promised,
    WorkflowStatusBadge,
  },
  computed: {
    ...mapState(Types.path, [Types.state.availableStates, Types.state.selectedWaiverRequest]),
    potentialStateCount() {
      return this.states.length
    },
    states() {
      return (this.availableStates.advanceStates || []).concat(this.availableStates.returnStates || [])
    },
    stateActionContainerClass() {
      return 'col-md-12'
    },
    currentStatus() {
      return this.selectedWaiverRequest ? StateMap.get(this.selectedWaiverRequest.status) : null
    }
  },
  methods: {
    getActionText(availableState) {
      if (availableState && typeof StateMap.get(availableState).action === 'function') {
        return StateMap.get(availableState).action(this.selectedWaiverRequest)
      }
      return StateMap.get(availableState).action
    },
    confirmStatusChange(newState) {
      this.$bvModal.msgBoxConfirm(`Are you sure you want to ${this.getActionText(newState.code)}?`)
        .then(result => {
          if (result) {
              this.actionPromise = this.$store.dispatch(Types.actions.transitionToState, {
              id: this.request.waiverRequestId,
              lea: this.request.districtLEA,
              State: newState.code,
              Comment: newState.requiresComment ? this.commentText : newState.action
            })
            this.commentText = ''
          }
          this.actionPromise = null
        })
    },
  },
  props: {
    request: Object,
  }
}
</script>

<template>
  <div>
    <animated-button 
      v-if="canLock && loaded" 
      class="mx-1 float-right btn btn-sm" :class="{ 'btn-success': lockedStatus, 'btn-danger': !lockedStatus }" :promise-resolver="toggleLockedStatus">
      {{ lockedStatus ? 'Enable ESA Plan Submission' : 'Disable ESA Plan Submission' }}
      <i class="ml-1 fas" :class="{ 'fa-unlock': lockedStatus, 'fa-lock': !lockedStatus }" /> 
    </animated-button>
    <animated-button 
      v-if="canLock && loaded" 
      class="mx-1 float-right btn btn-sm" :class="{ 'btn-success': dataLockedStatus, 'btn-danger': !dataLockedStatus }" :promise-resolver="toggleDataLockedStatus">
      {{ dataLockedStatus ? 'Enable ESA Data Entry' : 'Disable ESA Data Entry' }}
      <i class="ml-1 fas" :class="{ 'fa-unlock': dataLockedStatus, 'fa-lock': !dataLockedStatus }" /> 
    </animated-button>
    <h2>
      District ESA Plans
    </h2>
    <Promised :promise="loadData">
      <template v-slot:pending>
        <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
          <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
        </div>
      </template>
      <template v-slot>
        <div class="chart">
          <div class="row">
            <div class="col-6">
              <plans-by-status-chart />
            </div>
            <div class="col-6">
              <plans-by-budget-utilization-chart />
            </div>
          </div>
          <plan-list :load-data="loadData" />
        </div>
      </template>
      <template v-slot:rejected="error" class="align-items-center justify-content-center">
        <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
      </template>
    </Promised>
  </div>
</template>

<script>

import { Types } from '@/modules/esa/stores/'
import { mapState } from 'vuex'
import CardStateIcon from '@/components/CardStateIcon.vue'
import AnimatedButton from '@/components/AnimatedButton'
import PlanList from './PlanList.vue'
import PlansByStatusChart from './PlansByStatusChart.vue'
import PlansByBudgetUtilizationChart from './PlansByBudgetUtilizationChart.vue'
import { Promised } from 'vue-promised'

export default {
  data() {
    return {
      loadData: null,
      loaded: false,
    }
  },
  computed: {
    ...mapState(Types.path, [
      Types.state.plans,
      Types.state.esaFiscalYear,
      Types.state.lockedStatus,
      Types.state.dataLockedStatus,
      Types.state.districtBudgets
    ]),
    ...mapState('globalModule', ['userContext']),
    canLock() {
      const roles = (this.userContext.roles || [])
      return roles.includes('Insights_System_Administrators') || roles.includes('Insights_OIT_Administrators')
    },
  },
  components: {
    Promised,
    CardStateIcon,
    AnimatedButton,
    PlanList,
    PlansByStatusChart,
    PlansByBudgetUtilizationChart,
  },
  methods: {
    getData() {
      if (this.selectedLea && this.esaFiscalYear) {
        this.loadData = Promise.all([
          this.$store.dispatch(Types.actions.getLockedStatus, { lea: this.selectedLea.number }),
          this.$store.dispatch(Types.actions.getDataLockedStatus, { lea: this.selectedLea.number }),
          this.$store.dispatch(Types.actions.getPlans, { fy: this.esaFiscalYear, lea: this.selectedLea.number }),
          this.$store.dispatch(Types.actions.getDistrictBudgets, { fy: this.esaFiscalYear, lea: this.selectedLea.number }),
          this.$store.dispatch(Types.actions.getDistrictAllocations, { fy: this.esaFiscalYear, lea: this.selectedLea.number }),
        ]).then(() => this.loaded = true)
      }
    },
    toggleDataLockedStatus() {
      const self = this
      return this.$bvModal.msgBoxConfirm(`Are you sure you want to ${this.dataLockedStatus ? 'enable' : 'disable'} data entry for ESA plans?`)
        .then(result => { 
          if (result) {
            this.$store.dispatch(Types.actions.toggleDataLockedStatus, { lea: self.selectedLea.number })
          }
        })
    },
    toggleLockedStatus() {
      const self = this
      return this.$bvModal.msgBoxConfirm(`Are you sure you want to ${this.lockedStatus ? 'enable' : 'disable'} submission of ESA plans?`)
        .then(result => { 
          if (result) {
            this.$store.dispatch(Types.actions.toggleLockedStatus, { lea: self.selectedLea.number })
          }
        })
    },
  },
  watch: {
    esaFiscalYear: {
      immediate: true,
      handler() {
        this.getData()       
      }      
    },
    selectedLea: {
      immediate: true,
      handler() {
        this.getData()
      }
    }
  },
}

</script>

<template>
  <b-card class="mt-3" no-body header-class="header-padding-small">
    <template #header>
      <b-row no-gutters>
        <b-col class="header-padding-normal">
          Contributing Teacher
        </b-col>
        <b-col cols="auto" style="padding-top: 0.25rem; padding-right: 0.75rem;" v-if="canEdit">
          <b-dropdown right size="sm" class="m-1" variant="light">
            <template #button-content>
              Actions
            </template>
            <b-dropdown-item href="#" @click="setIncludeForVAMForAll('ELA')">
              Set <b>Provided Instruction in ELA</b> for all Students
            </b-dropdown-item>
            <b-dropdown-item href="#" @click="setIncludeForVAMForAll('Math')">
              Set <b>Provided Instruction in Math</b> for all Students
            </b-dropdown-item>
            <b-dropdown-item href="#" @click="setIncludeForVAMForAll('Science')">
              Set <b>Provided Instruction in Science</b> for all Students
            </b-dropdown-item>
          </b-dropdown>
        </b-col>        
      </b-row>
    </template>
    <div class="card-body card-body-secondary">
      <div class="mb-3">
        <b-button class="btn btn-success" @click="$bvModal.show('findStudent')" v-if="canEdit">
          <i class="fas fa-plus" /> Add Student
        </b-button>
        <b-button class="btn btn-danger ml-2" @click="removeStudents" v-if="canEdit && selectedResultsStudentsCount > 0">
          <i class="fas fa-minus" /> Remove {{ selectedResultsStudentsCount }} Student{{ selectedResultsStudentsCount > 1 ? 's' : '' }}
        </b-button>
        <b-button class="btn btn-primary ml-2" @click="selectAllResults" v-if="canEdit && contributorStudents.length > 0">
          Select All
        </b-button>
        <b-button class="btn btn-primary ml-2" @click="unselectAllResults" v-if="canEdit && contributorStudents.length > 0">
          Unselect All
        </b-button>
      </div>      

      <b-modal id="findStudent" size="xl" scrollable @shown="searchStudents()" @hide="clearStudents()">
        <template #modal-title>
          Add Students
        </template>
        <template #default>
          <div class="col-6 mb-2">
            Filter by Grade Level:
            <b-select v-model="gradeFilter" :options="gradeLevels">
              <template #first>
                <b-form-select-option :value="null">
                  (View All Grades)
                </b-form-select-option>
              </template>
            </b-select>
          </div> 
          <div class="col-12 mb-2">
            <b-button class="btn btn-primary" @click="selectAll" v-if="studentSearchFilteredByGrade.length > 0">
              Select All
            </b-button>
            <b-button class="btn btn-primary ml-2" @click="unselectAll" v-if="studentSearchFilteredByGrade.length > 0">
              Unselect All
            </b-button>
          </div>
          <div class="col-12">
            <paginated-table :items="studentSearchFilteredByGrade" :fields="searchFields" :sort-asc="true" :per-page="10" hover small ref="searchTable" primary-key="studentId">
              <template v-slot:cell(action)="row">
                <b-form-checkbox
                  v-model="row.item.checked"
                />
              </template>
              <template v-slot:cell(gt)="row">
                <span v-if="row.item.gt == 1">
                  <i class="fas fa-check text-success" title="True" />
                </span>
                <span v-else>
                  <i class="fas fa-times text-secondary" title="False" />
                </span>
              </template>
              <template v-slot:cell(sped)="row">
                <span v-if="row.item.sped == 1">
                  <i class="fas fa-check text-success" title="True" />
                </span>
                <span v-else>
                  <i class="fas fa-times text-secondary" title="False" />
                </span>
              </template>
              <template v-slot:cell(handicap)="row">
                <span v-if="row.item.handicap == 1">
                  <i class="fas fa-check text-success" title="True" />
                </span>
                <span v-else>
                  <i class="fas fa-times text-secondary" title="False" />
                </span>
              </template>
            </paginated-table>
          </div>         
        </template>
        <template #modal-footer>
          <b-button variant="success" @click="addStudents" v-if="selectedStudentsCount > 0">
            Add {{ selectedStudentsCount }} Student{{ selectedStudentsCount > 1 ? 's' : '' }}
          </b-button>
          <b-button variant="secondary" @click="$bvModal.hide('findStudent')">
            Cancel
          </b-button>
        </template>
      </b-modal>
      
      <paginated-table v-if="contributorDetail" :items="contributorStudents" :fields="displayFields" :sort-asc="true" :per-page="20" hover small ref="resultsTable" primary-key="studentId">
        <template v-slot:cell(action)="row" v-if="canEdit">
          <b-form-checkbox
            v-model="row.item.checked"
          />
        </template>
        <template v-slot:cell(gt)="row">
          <span v-if="row.item.gt == 1">
            <i class="fas fa-check text-success" title="True" />
          </span>
          <span v-else>
            <i class="fas fa-times text-secondary" title="False" />
          </span>
        </template>
        <template v-slot:cell(sped)="row">
          <span v-if="row.item.sped == 1">
            <i class="fas fa-check text-success" title="True" />
          </span>
          <span v-else>
            <i class="fas fa-times text-secondary" title="False" />
          </span>
        </template>
        <template v-slot:cell(handicap)="row">
          <span v-if="row.item.handicap == 1">
            <i class="fas fa-check text-success" title="True" />
          </span>
          <span v-else>
            <i class="fas fa-times text-secondary" title="False" />
          </span>
        </template>
        <template v-slot:cell(includeForVAMELA)="row">
          <b-form-select
            v-if="canEdit"
            v-model="row.item.includeForVAMELA"
            :options="trueFalseOptions"
            @change="valueChanged('includeForVAMELA', row.item)" />
          <span v-else>
            {{ getTrueFalseValue(row.item.includeForVAMELA) }}
          </span>
        </template>
        <template v-slot:cell(includeForVAMMath)="row">
          <b-form-select
            v-if="canEdit"
            v-model="row.item.includeForVAMMath"
            :options="trueFalseOptions"
            @change="valueChanged('includeForVAMMath', row.item)" />
          <span v-else>
            {{ getTrueFalseValue(row.item.includeForVAMMath) }}
          </span>
        </template>
        <template v-slot:cell(includeForVAMScience)="row">
          <b-form-select
            v-if="canEdit"
            v-model="row.item.includeForVAMScience"
            :options="trueFalseOptions"
            @change="valueChanged('includeForVAMScience', row.item)" />
          <span v-else>
            {{ getTrueFalseValue(row.item.includeForVAMScience) }}
          </span>
        </template>
        <template v-slot:cell(notes)="row">
          <b-textarea
            v-if="canEdit"
            v-model="row.item.notes"
            @update="notesChanged(row.item)" />
          <span v-else>
            {{ row.item.notes }}
          </span>
        </template>
      </paginated-table>
      <div v-else>
        <i class="fas fa-spinner fa-spin" /> Loading...
      </div>
    </div>
    <b-modal id="setIncludeForVAM" @shown="$refs.includeForVAM.focus()" @ok="doSetIncludeForVAMForAll">
      <template #modal-title>
        Set <b>Provided Instruction in Subject Area</b> for All Students
      </template>
      <template #default>
        <div class="d-block text-center">
          <div class="mb-3">
            <b-form-select
              ref="includeForVAM"
              v-model="modalIncludeForVAM"
              :options="trueFalseOptions"
            />
          </div>
        </div>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import { mapState } from 'vuex';
import PaginatedTable from '@/components/PaginatedTable'
import { parseRaceData, parseInstructionalChoice } from '@/modules/rvs/helper.js'
import { debounce } from '@/helpers/globalHelpers'

async function conotesChangedDebounced(item) {
  this.$nextTick().then(async () => {
    await this.$store.dispatch('rvsModule/updateContributorStudent', { snapshotIds: [item.snapshotId], key: 'notes', value: item.notes })
  })
}

export default {
  data() {
    return {
      showFindStudent: false,
      adding: null,
      removing: null,
      contributorStudents: [],
      searchFields: [
        { key: 'action', label: '', sortable: false, tdClass: 'nowrap' },
        { key: 'studentId', label: 'Student ID', stickyColumn: true },
        { key: 'lastName', label: 'Last', sortable: true, stickyColumn: true },
        { key: 'firstName', label: 'First', sortable: true, stickyColumn: true },
        { key: 'middleName', label: 'Middle' },
        { key: 'gradeLevel', label: 'Grade Level' },
        { key: 'raceEthnicity', label: 'Race/Ethnicity', formatter: (value) => parseRaceData(value) },
        { key: 'instructionalChoice', label: 'Instructional Choice', sortable: true, formatter: (value) => parseInstructionalChoice(value) },
        // { key: 'gt', label: 'GT', sortable: true },
        // { key: 'sped', label: 'SPED', sortable: true },
        // { key: 'handicap', label: '504', sortable: true },
      ],
      displayFields: [
        { key: 'action', label: '', sortable: false, tdClass: 'nowrap' },
        { key: 'studentId', label: 'Student ID', stickyColumn: true },
        { key: 'lastName', label: 'Last', sortable: true, stickyColumn: true },
        { key: 'firstName', label: 'First', sortable: true, stickyColumn: true },
        { key: 'middleName', label: 'Middle' },
        { key: 'gradeLevel', label: 'Grade Level' },
        { key: 'raceEthnicity', label: 'Race/Ethnicity', formatter: (value) => parseRaceData(value) },
        { key: 'instructionalChoice', label: 'Instructional Choice', sortable: true, formatter: (value) => parseInstructionalChoice(value) },
        // { key: 'gt', label: 'GT', sortable: true },
        // { key: 'sped', label: 'SPED', sortable: true },
        // { key: 'handicap', label: '504', sortable: true },
        // { key: 'roleIdentification', label: 'Role Assignment Identification', sortable: true },
        // { key: 'percentageOfInstruction', label: 'Percentage of Instruction', sortable: true },
        { key: 'includeForVAMELA', label: 'Provided Instruction in ELA', sortable: true },
        { key: 'includeForVAMMath', label: 'Provided Instruction in Math', sortable: true },
        { key: 'includeForVAMScience', label: 'Provided Instruction in Science', sortable: true },
        { key: 'notes', label: 'Notes' }
      ],
      trueFalseOptions: [
        { value: null, text: '(no value)' },
        { value: false, text: 'No' },
        { value: true, text: 'Yes' }
      ],
      modalIncludeForVAM: null,
      modalVAMSubject: null,
      gradeFilter: null,
      studentSearchFilteredByGrade: [],
      refreshKey: 0
    };
  },
  components: {
    PaginatedTable,
  },
  computed: {
    ...mapState('rvsModule', ['students', 'contributorDetail']),
    studentsSearch() {
      return this.students.filter(s => !this.contributorDetail.students.some(cs => cs.studentId === s.studentId))
    },
    selectedStudentsCount() {
      this.refreshKey
      return this.studentSearchFilteredByGrade.filter(s => s.checked).length
    },
    selectedResultsStudentsCount() {
      this.refreshKey
      return this.contributorStudents.filter(s => s.checked).length
    },
    gradeLevels() {
      return this.studentsSearch.map(s => s.gradeLevel).filter((v, i, a) => a.indexOf(v) === i).sort()
    }
  },
  props: {
    lea: {
      type: String,
      required: true
    },
    teacher: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    unselectAll() {
      this.studentSearchFilteredByGrade.forEach(s => s.checked = false)
      this.studentSearchFilteredByGrade = JSON.parse(JSON.stringify(this.studentSearchFilteredByGrade))
      this.$refs.searchTable.refresh()
      this.refreshKey++
    },
    selectAll() {
      this.studentSearchFilteredByGrade.forEach(s => s.checked = true)
      this.$refs.searchTable.refresh()
      this.refreshKey++
    },
    unselectAllResults() {
      this.contributorStudents.forEach(s => s.checked = false)
      this.$refs.resultsTable.refresh()
      this.refreshKey++
    },
    selectAllResults() {
      this.contributorStudents.forEach(s => s.checked = true)
      this.$refs.resultsTable.refresh()
      this.refreshKey++
    },
    searchStudents() {
      this.loading = this.$store.dispatch('rvsModule/getStudents', { lea: this.lea })
    },
    clearStudents() {
      this.$store.commit('rvsModule/setStudents', [])
    },
    async addStudents() {
      const studentIds = this.studentSearchFilteredByGrade.filter(s => s.checked).map(s => s.studentId)
      this.$bvModal.hide('findStudent')
      await this.$store.dispatch('rvsModule/addContributorStudents', { lea: this.lea, studentIds: studentIds, teacherId: this.teacher.summary.uniqueEmployeeId })
    },
    async removeStudents() {
      const snapshotIds = this.contributorStudents.filter(s => s.checked).map(s => s.snapshotId)
      await this.$store.dispatch('rvsModule/removeContributorStudents', { fy: this.fiscalYear, lea: this.lea, snapshotIds: snapshotIds, teacherId: this.teacher.summary.uniqueEmployeeId })
    },
    getTrueFalseValue(value) {
      switch(value) {
        case false:
        case 0:
          return 'No'
        case true:
        case 1:
          return 'Yes'
        default:
          return '(no value)'
      }
    },
    async valueChanged(key, item) {
      this.$nextTick().then(async () => {
        await this.$store.dispatch('rvsModule/updateContributorStudent', { snapshotIds: [item.snapshotId], key: key, value: item[key] })
      })
    },
    notesChanged: debounce(conotesChangedDebounced, 500),
    setIncludeForVAMForAll(subject) {
      this.modalIncludeForVAM = null
      this.modalVAMSubject = subject
      this.$bvModal.show('setIncludeForVAM')
    },
    async doSetIncludeForVAMForAll() {
      var key = `includeForVAM${this.modalVAMSubject}`
      await this.$store.dispatch('rvsModule/updateContributorStudent', { snapshotIds: this.contributorStudents.map(s => s.snapshotId), key: key, value: this.modalIncludeForVAM })
      this.contributorStudents.forEach(async student => {
        student[key] = this.modalIncludeForVAM
      })
    },
  },
  watch: {
    contributorDetail: {
      immediate: true,
      handler() {
        if (this.contributorDetail) {
          debugger
          this.contributorStudents = this.contributorDetail.students.map(student => {
            return {
              ...student,
              percentageOfInstruction: student.percentageOfInstruction || null,
              roleIdentification: student.roleIdentification || null,
              includeForVAMELA: student.includeForVAMELA === undefined ? null : student.includeForVAMELA,
              includeForVAMMath: student.includeForVAMMath === undefined ? null : student.includeForVAMMath,
              includeForVAMScience: student.includeForVAMScience === undefined ? null : student.includeForVAMScience,
            }
          })
        }
      }
    },
    gradeFilter: {
      immediate: true,
      handler() {
        this.studentSearchFilteredByGrade = this.gradeFilter ?  this.studentsSearch.filter(s => s.gradeLevel === this.gradeFilter) : this.studentsSearch
      }
    },
    studentsSearch: {
      immediate: true,
      handler() {
        this.studentSearchFilteredByGrade = this.gradeFilter ?  this.studentsSearch.filter(s => s.gradeLevel === this.gradeFilter) : this.studentsSearch
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header-padding-small {
  padding: 0rem;
}
.header-padding-normal {
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
}
</style>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import ShortageReportAreaTable from './ShortageReportAreaTable.vue'
import ShortageReportAreaStaffTable from './ShortageReportAreaStaffTable.vue'
import ShortageReportJobTable from './ShortageReportJobTable.vue'
import ShortageReportJobStaffTable from './ShortageReportJobStaffTable.vue'
import { SummaryExportOptionsWidget } from './SummaryExportOptionsWidget'
import { IndividualExportOptionsWidget } from './IndividualExportOptionsWidget'
import CustomTooltip from '../../CustomTooltip.vue'

function summaryDataExportOptions() {
  return {
    include: false,
    dataType: 0,
    groupingDirective: 0,
    combineGroupsOnSameSheet: false,
    groupBySubgroup: false,
    groupByLicenseOrJobCode: false
  }
}

function individualDataExportOptions() {
  return {
    includeJobIndividuals: false,
    includeAreaIndividuals: false,
    groupBySubgroup: false,
    groupByLicenseOrJobCode: false
  }
}

const ShortageReport = Vue.extend({
  components: {
    ShortageReportAreaTable,
    ShortageReportAreaStaffTable,
    ShortageReportJobTable,
    ShortageReportJobStaffTable,
    SummaryExportOptionsWidget,
    IndividualExportOptionsWidget,
    CustomTooltip
  },
  data() {
    return {
      activeTab: null,
      parameters: {
        groupBySubgroup: false,
        groupByJobOrLicenseCode: false,
        isLoading: false,
        scopeLevel: 0,
        restrictToRetirees: this.restrictToRetirees
      },
      grouping: '0',
      restrictToRetirees: false,
      exportParameters: {
        coopParameter: new summaryDataExportOptions(),
        districtParameter: new summaryDataExportOptions(),
        schoolParameter: new summaryDataExportOptions(),
        individualParameter: new individualDataExportOptions(),
        includeUngroupedTotals: false
      }
    }
  },

  methods: {
    exportToExcel() {
      const params = {
        scopeLea: this.$store.state.globalModule.leaContext.SelectedLea ? this.$store.state.globalModule.leaContext.SelectedLea.number : null,
        scopeLevel: this.$store.state.globalModule.leaContext.SelectedLea ? this.$store.state.globalModule.leaContext.SelectedLea.type : null,
        coopParameter: this.exportParameters.coopParameter,
        districtParameter: this.exportParameters.districtParameter,
        schoolParameter: this.exportParameters.schoolParameter,
        individualParameter: this.exportParameters.individualParameter,
        includeUngroupedTotals: this.exportParameters.includeUngroupedTotals,
        retiredPersonnelOnly: this.restrictToRetirees
      }

      return this.$store.dispatch('shortageReportModule/exportToExcel', params)
    },
    refresh() {
      const params = {
        scopeLea: this.$store.state.globalModule.leaContext.SelectedLea ? this.$store.state.globalModule.leaContext.SelectedLea.number : null,
        scopeLevel: this.$store.state.globalModule.leaContext.SelectedLea ? this.$store.state.globalModule.leaContext.SelectedLea.type : null,
        groupBySubgroup: this.grouping == 1 || this.grouping == 3,
        groupByJobOrLicenseCode: this.grouping == 2 || this.grouping == 3,
        retiredPersonnelOnly: this.restrictToRetirees
      }

      let form = this
      form.parameters.isLoading = true

      return Promise.all([
        this.$store.dispatch('shortageReportModule/getShortageStatisticsByJob', params),
        this.$store.dispatch('shortageReportModule/getShortageStatisticsByArea', params)
      ]).then(function() {
        form.parameters.groupBySubgroup = params.groupBySubgroup
        form.parameters.groupByJobOrLicenseCode = params.groupByJobOrLicenseCode
        form.parameters.isLoading = false
      })
    },
    isVisible(dataLevel) {
      const type = this.contextLea ? this.contextLea.type : 0

      if (type == 3) return true

      return type > dataLevel
    }
  },

  computed: {
    ...mapGetters('shortageReportModule', [
      'shortageReportJobStatisticsFiltered', 
      'shortageReportAreaStatisticsFiltered', 
      'areaGroupList',
      'jobGroupList', 
      'areaSubgroupList',
      'jobSubgroupList',
      'licenseCodeList',
      'jobCourseCodeList'
    ]),
    ...mapState('shortageReportModule', ['exporting', 'filter']),
    areaGroup: {
      set(group) { return this.$store.commit('shortageReportModule/setFilterAreaGroup', group) },
      get() { return this.filter.areaGroup } 
    },
    areaSubgroup: {
      set(subgroup) { return this.$store.commit('shortageReportModule/setFilterAreaSubgroup', subgroup) },
      get() { return this.filter.areaSubgroup }
    },
    jobGroup: {
      set(group) { return this.$store.commit('shortageReportModule/setFilterJobGroup', group) },
      get() { return this.filter.jobGroup } 
    },
    jobSubgroup: {
      set(subgroup) { return this.$store.commit('shortageReportModule/setFilterJobSubgroup', subgroup) },
      get() { return this.filter.jobSubgroup }
    },
    license: {
      set(license) { return this.$store.commit('shortageReportModule/setFilterLicense', license) },
      get() { return this.filter.license }
    },
    job: {
      set(job) { return this.$store.commit('shortageReportModule/setFilterJob', job) },
      get() { return this.filter.job }
    },
    contextLea() {
      var contextLeaNumber = this.$store.state.globalModule.leaContext.SelectedLea ? this.$store.state.globalModule.leaContext.SelectedLea.number : null
      if (contextLeaNumber) 
        return this.$store.getters['globalModule/leaDictionary'][contextLeaNumber]

      return null
    },
    showUnemployedColumn() {
      return this.contextLea ? this.contextLea.type == 3 : false
    },
    currentSelections() {
      const school = this.selectedSchoolLea ? this.$store.getters.leaDictionary[this.selectedSchoolLea] : null
      const district = this.$store.getters.leaDictionary[school ? school.parentLea.number : this.selectedDistrictLea]
      const coop = this.$store.getters.leaDictionary[district ? district.parentLea.number : this.selectedCoOpLea]

      return {
        school: school,
        district: district,
        coop: coop
      }
    },
    groupBySubgroup() {
      return this.parameterCache.groupBySubgroup
    },
    groupByJobOrLicenseCode() {
      return this.parameterCache.groupByJobOrLicenseCode
    }
  },
  watch: {
    contextLea: {
      immediate: true,
      handler() {
        return this.refresh()
      }
    },
    grouping: {
      handler(newGrouping) {
        if (newGrouping != 1 && newGrouping != 3) {
          this.areaSubgroup = null
          this.jobSubgroup = null
        }
        if (newGrouping != 2 && newGrouping != 3) {
          this.license = null
          this.job = null
        }
        return this.refresh()
      }
    },
    restrictToRetirees:{
      handler(){
        return this.refresh()
      }
    }
  }
})

export { ShortageReport }

<template>
  <h5 class="p-2 bg-dark rounded text-light font-weight-bold">
    <i class="fas fa-fw mr-2" :class="icon" />{{ labelText }}
  </h5>
</template>
<script>
export default {
  computed: {
    labelText() {
      if (typeof this.label === 'string') return this.label
      if (this.label) {
        return this.label.call(this.$route.params)
      }
      return ''
    }
  },
  props: {
    icon: String,
    label: [String, Function]
  }
}
</script>
<template>
  <tbody>
    <tr>
      <th>
        {{ Object.entries(MeasurementTypes).find(mt => mt[1].value == this.measurement.type)[0].replace(/([A-Z])/g, ' $1').trim() }}
      </th>
      <td>
        <b-form-input v-model="measurementChanges.left" type="number" @input="input" min="1" :max="maxValue" :state="measurementLeftValid" :disabled="readonly" />
      </td>      
      <td>
        <b-form-input v-model="measurementChanges.right" type="number" @input="input" min="1" :max="maxValue" :state="measurementRightValid" :disabled="readonly" />
      </td>
      <td v-if="isDirty" class="align-middle" colspan="3">
        <b-btn variant="warning" block size="sm" @click="cancel">
          <span class="fas fa-undo" />
        </b-btn>
      </td>
    </tr>
  </tbody>
</template>

<style lang="scss" scoped>
  ::v-deep .form-control.is-invalid {
    background-image: none !important;
    color: red;
    padding-right: 10px
  }
  ::v-deep .form-control.is-valid {
    background-image: none !important;
    padding-right: 10px
  }
</style>

<script>
import { mapState } from 'vuex'
import { isEquivalent } from '../../../../helpers/globalHelpers'
import { MeasurementTypes } from '../../common/Enums'

export default {
  data() {
    return {
      measurementChanges: {...this.measurement},
      MeasurementTypes
    }
  },
  computed:{
    isDirty() {
      const propertiesToCompare = ['left', 'right']
      return !isEquivalent({...this.measurementChanges}, {...this.measurement}, propertiesToCompare)
    },
    maxValue() {
      return (this.measurementChanges.type == MeasurementTypes.FrontBrakes.value || this.measurementChanges.type == MeasurementTypes.RearBrakes.value) ? 101 : 33
    },
    measurementLeftValid() {
      return this.isMeasurementValid(this.measurementChanges.left || 0)
    },
    measurementRightValid() {
      return this.isMeasurementValid(this.measurementChanges.right || 0)
    },
    brakePadThreshold() {
      return parseInt(document.getElementById('application-config').getAttribute('data-dpsaft-brakepadthreshold'))
    },
    frontTiretreadthreshold() {
      return parseInt(document.getElementById('application-config').getAttribute('data-dpsaft-fronttiretreadthreshold'))
    },
    rearTiretreadthreshold() {
      return parseInt(document.getElementById('application-config').getAttribute('data-dpsaft-reartiretreadthreshold'))
    },
    ...mapState('DPSAFT/Trans', ['SelectedBatch', 'SelectedInspection']),
  },
  methods: {
    isMeasurementValid(value) {
      if (value > this.maxValue) 
        return false
      else if(this.measurementChanges.type == MeasurementTypes.FrontBrakes.value || this.measurementChanges.type == MeasurementTypes.RearBrakes.value)
        return value > this.brakePadThreshold
      else if(this.measurementChanges.type == MeasurementTypes.FrontTires.value)
        return value > this.frontTiretreadthreshold
      else if(this.measurementChanges.type == MeasurementTypes.RearInsideTires.value || this.measurementChanges.type == MeasurementTypes.RearOutsideTires.value)
        return value > this.rearTiretreadthreshold
      return true
    },
    cancel() {
      this.measurementChanges = {...this.measurement}
      this.input()
    },
    input() {
      this.$emit('input', Object.assign(this.measurementChanges, { isDirty: this.isDirty }))
    }
  },  
  watch: {
    measurement(newMeasurement) {
      this.measurementChanges = {...newMeasurement}
    }
  },
  props:{
    measurement: Object,
    readonly: Boolean
  }
}
</script>

<template>
  <div>
    <excel-button title="Paraprofessionals Qualification System" :url="excelUrl" :payload="filterPayloadBody" />
    <animated-button :promise-resolver="takeSnapshot" v-if="isSystemAdmin" button-class="btn btn-info float-right mx-1">
      <i class="fa fa-fw fa-camera mr-1" />Take Snapshot
    </animated-button>
    <h1>
      Paraprofessionals Qualification System
    </h1>
    <h3><lea-display :lea="selectedLea" /></h3>
    <div class="row">
      <div class="col-12">
        <Promised :promise="loadData">
          <template v-slot:pending>
            <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
              <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
            </div>
          </template>
          <template v-slot="dataPromise" :class="centerContent ? 'align-items-center justify-content-center' : ''">
            <pre v-if="false">{{ dataPromise }}</pre>
            <div class="row">
              <div class="col-12 my-2">
                <strong>Snapshot</strong>
                <select class="form-control" style="width:10rem" v-model="selectedSnapshotDate">
                  <option value="null">
                    Nightly data
                  </option>
                  <option v-for="snapshotDate in snapshotDates" :key="snapshotDate">
                    {{ snapshotDate | date('short-date') }}
                  </option>
                </select>
                <paraprofessional-filter />
              </div>
            </div>
            <div>
              <paginated-table striped hover small responsive bordered :items="filteredParaprofessionals" :fields="fields" sort-by="Program" head-row-variant="secondary" :per-page="20">
                <template #thead-top>
                  <b-tr>
                    <b-th variant="primary text-center" colspan="3" class="border-right">
                      eSchoolPLUS
                    </b-th>
                    <b-th variant="success text-center" colspan="8" class="border-right">
                      eFinancePLUS
                    </b-th>
                    <b-th variant="info text-center" colspan="5" class="border-right">
                      AELS
                    </b-th>
                    <b-th variant="dark text-center" colspan="5">
                      Determination Criteria
                    </b-th>
                  </b-tr>
                </template>
                <template v-slot:head(schoolFederalProgramStatus)="data">
                  <custom-tooltip element-id="schoolFederalProgramStatus" :label="data.label">
                    School Federal Program Status
                  </custom-tooltip>
                </template>
                <template v-slot:cell(criteria1)="data">
                  <i :class="{ 'fas fa-check text-success': data.value, 'fas fa-times text-danger': !data.value }" />
                </template>
                <template v-slot:cell(criteria2)="data">
                  <i :class="{ 'fas fa-check text-success': data.value, 'fas fa-times text-danger': !data.value }" />
                </template>
                <template v-slot:cell(criteria3)="data">
                  <i :class="{ 'fas fa-check text-success': data.value, 'fas fa-times text-danger': !data.value }" />
                </template>
                <template v-slot:cell(criteria4)="data">
                  <i :class="{ 'fas fa-check text-success': data.value, 'fas fa-times text-danger': !data.value }" />
                </template>
                <template v-slot:cell(criteria5)="data">
                  <i :class="{ 'fas fa-check text-success': data.value, 'fas fa-times text-danger': !data.value }" />
                </template>
                <template v-slot:head(criteria1)="data">
                  <custom-tooltip element-id="criteria1" :label="data.label">
                    Criteria 1:
                    <ul class="list-unstyled">
                      <li>
                        If School Federal Program Status is Title 1 School-Wide
                      </li>
                      <li>
                        And Job Code is 758,759, 762, or 763
                      </li>
                      <li>
                        And received a highschool diploma
                      </li>
                      <li>
                        And is considered Highly Qualified
                      </li>
                    </ul>
                  </custom-tooltip>
                </template>
                <template v-slot:head(praxisQualifyingScore)="data">
                  <custom-tooltip element-id="praxisQualifyingScore" :label="data.label">
                    Paraprofessional Praxis (Praxis ParaPro 1755) score report of 457
                  </custom-tooltip>
                </template>
                <template v-slot:head(criteria2)="data">
                  <custom-tooltip element-id="criteria2" :label="data.label">
                    Criteria 2:
                    <ul class="list-unstyled">
                      <li>
                        If School Federal Program Status is Title 1 School-Wide
                      </li>
                      <li>
                        And Job Code is 758,759, 762, or 763
                      </li>
                      <li>
                        And received a highschool diploma
                      </li>
                      <li>
                        And is NOT considered Highly Qualified
                      </li>
                    </ul>
                  </custom-tooltip>
                </template>
                <template v-slot:head(criteria3)="data">
                  <custom-tooltip element-id="criteria3" :label="data.label">
                    Criteria 3:
                    <ul class="list-unstyled">
                      <li>
                        If School Federal Program Status is Title 1 Targeted
                      </li>
                      <li>
                        And Job Code is 758,759, 762, or 763
                      </li>
                      <li>
                        And received a highschool diploma
                      </li>
                      <li>
                        And has an Associate Degree (or higher), has a Paraprofessional Praxis (Praxis ParaPro 1755) score
                        report of 457, or College transcripts >= 60 hours
                      </li>
                    </ul>
                  </custom-tooltip>
                </template>
                <template v-slot:head(criteria4)="data">
                  <custom-tooltip element-id="criteria4" :label="data.label">
                    Criteria 4:
                    <ul class="list-unstyled">
                      <li>
                        If School Federal Program Status is Title 1 Targeted
                      </li>
                      <li>
                        And Job Code is 758,759, 762, or 763
                      </li>
                      <li>
                        And received a highschool diploma
                      </li>
                      <li>
                        And is NOT considered Highly Qualified
                      </li>
                    </ul>
                  </custom-tooltip>
                </template>
                <template v-slot:head(criteria5)="data">
                  <custom-tooltip element-id="criteria5" :label="data.label">
                    Criteria 5:
                    <ul class="list-unstyled">
                      <li>
                        If School Federal Program Status is not Title 1
                      </li>
                    </ul>
                  </custom-tooltip>
                </template>
                <template v-slot:cell(districtLea)="data">
                  <a href="#" @click="goToLea(data.value)" :id="data.index + '-' + data.value">
                    {{ data.value }} {{ (leaDictionary[data.value] || {}).name }}
                  </a>
                </template>
                <template v-slot:cell(schoolFederalProgramStatus)="data">
                  {{ (data.value == 1 ? 'SW' : data.value == 2 ? 'TA' : 'NA') }}
                  <custom-tooltip 
                    :element-id="data.index + '-' + data.value" :label="data.label">
                    {{ schoolFederalProgramStatusFormatter(data.value) }}
                  </custom-tooltip>
                </template>
                <template v-slot:cell(schoolLea)="data">
                  <a href="#" @click="goToLea(data.value)" :id="data.index + '-' + data.value">
                    {{ data.value }} {{ (leaDictionary[data.value] || {}).name }}
                  </a>
                </template>
                <template v-slot:cell(caseId)="data">
                  <aels-case-id-link :case-id="data.value.toString()" />
                </template>
                <template v-slot:cell(highschoolDiploma)="data">
                  <i :class="{ 'fas fa-check text-success': data.value, 'fas fa-times text-danger': !data.value }" />
                </template>
                <template v-slot:cell(highlyQualified)="data">
                  <i :class="{ 'fas fa-check text-success': data.value, 'fas fa-times text-danger': !data.value }" />
                </template>
                <template v-slot:cell(praxisQualifyingScore)="data">
                  <i :class="{ 'fas fa-check text-success': data.value, 'fas fa-times text-danger': !data.value }" />
                </template>
                <template v-slot:cell(earnedCollegeCreditHours)="data">
                  <i :class="{ 'fas fa-check text-success': data.value, 'fas fa-times text-danger': !data.value }" />
                </template>
              </paginated-table>
              <span class="table-warning" v-if="selectedSnapshotDate == null || selectedSnapshotDate == 'null'">
                * Indicates paraprofessional hired after the latest Snapshot
              </span>
            </div>
          </template>
          <template v-slot:rejected="error" class="align-items-center justify-content-center">
            <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
          </template>
        </Promised>
      </div>
    </div>
    <!-- <div v-else class="alert alert-warning">
    </div> -->
  </div>
</template>

<script>
import { PageMixin } from '@/components/PageMixin'
import PaginatedTable from '@/components/PaginatedTable'
import { Promised } from 'vue-promised'
import CardStateIcon from '@/components/CardStateIcon.vue'
import AelsCaseIdLink from '@/components/AelsCaseIdLink'
import AnimatedButton from '@/components/AnimatedButton.vue'

import ApiService from '@/services/ApiService'
const apiService = new ApiService()

import { Types } from '@/stores/paraprofessionalModule.js'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import ParaprofessionalFilter from './ParaprofessionalFilter.vue'

export default {
  mixins: [PageMixin],
  components: {
    PaginatedTable,
    Promised,
    CardStateIcon,
    AelsCaseIdLink,
    AnimatedButton,
    ParaprofessionalFilter,
  },
  data() {
    return {
      loadData: Promise.resolve(true),
      selectedSnapshotDate: null,
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState(Types.path, [Types.state.paraprofessionals, Types.state.snapshotDates, Types.state.filter]),
    ...mapGetters(Types.path, [Types.getters.filteredParaprofessionals]),
    filterPayloadBody() {
      return this.filter
    },
    fields() {
      return [
        { key: 'districtLea', label: 'District', sortable: true, tdClass: 'text-nowrap' },
        { key: 'schoolLea', label: 'School', sortable: true, tdClass: 'text-nowrap', stickyColumn: true },
        { key: 'schoolFederalProgramStatus', label: 'SFP Status', sortable: true, tdClass: 'text-center' },
        { key: 'firstName', label: 'First Name', sortable: true, tdClass: 'text-nowrap' },
        { key: 'middleName', label: 'Middle Name', sortable: true, tdClass: 'text-nowrap'  },
        { key: 'lastName', label: 'Last Name', sortable: true, tdClass: 'text-nowrap'  },
        { key: 'ssn', label: 'Last 4-SSN' },
        { key: 'dateOfBirth', label: 'DOB', formatter: data => (data ? moment(data).format('MM/DD/YYYY') : 'N/A') },
        { key: 'jobCode', label: 'Job Code', formatter: data => this.jobCodeFormatter(data), sortable: true, tdClass: 'text-nowrap' },
        { key: 'qualification', label: 'Parapro. Qualification', formatter: data => this.qualificationFormatter(data), sortable: true, tdClass: 'text-nowrap' },
        { key: 'salaryFund', label: 'Source of Funds', formatter: data => this.salaryFundFormatter(data), sortable: true, tdClass: 'text-nowrap' },
        // { key: 'aelsSsn', label: 'SSN' },
        // { key: 'aelsDateOfBirth', label: 'Date of Birth' },
        { key: 'caseId', label: 'Case ID' },
        { key: 'highschoolDiploma', label: 'Highschool Diploma', tdClass: 'text-center' },
        { key: 'highlyQualified', label: 'Associate or Higher', tdClass: 'text-center' },
        { key: 'praxisQualifyingScore', label: 'Praxis', tdClass: 'text-center' },
        { key: 'earnedCollegeCreditHours', label: 'College Credit Hours > 60', tdClass: 'text-center' },
        { key: 'criteria1', label: '1', sortable: false, tdClass: 'text-center' },
        { key: 'criteria2', label: '2', sortable: false, tdClass: 'text-center' },
        { key: 'criteria3', label: '3', sortable: false, tdClass: 'text-center' },
        { key: 'criteria4', label: '4', sortable: false, tdClass: 'text-center' },
        { key: 'criteria5', label: '5', sortable: false, tdClass: 'text-center' },
      ].map(field => {
        return {...field, thClass: 'text-nowrap' }
      })
    },
    excelUrl() {
      if (!this.selectedLea && !this.selectedLea.number)
        return ''

      return `/api/paraprofessional/${this.selectedLea.number}/export?snapshotDate=${this.selectedSnapshotDate}`
    },
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    },
    isSystemAdmin() {
      return this.userContext.roles.includes('Insights_System_Administrators')
    }
  },
  methods: {
    salaryFundFormatter(code) {
      switch(code)
      {
        case 0: return "Other"
        case 1: return "Teacher Salary"
        case 2: return "Operating"
        case 3: return "Building"
        case 6: return "Federal"
        case 7: return "Activity"
        case 8: return "Food Service"
        default: return code
      }
    },
    qualificationFormatter(code) {
      switch(code)
      {
        case 1: return 'Praxis Examination'
        case 2: return '60 college hours'
        case 3: return 'Associate Degree'
        case 4: return 'Bachelor Degree'
        case 5: return 'Not a highly qualified paraprofessional'
        case 6: return 'In the process of becoming a highly qualified paraprofessional'
        case 7: return 'CDA only works in preschool'
        case 8: return 'Certificate of Proficiency'
        default: return 'N/A'
      }
    },
    jobCodeFormatter(code) {
      switch(code)
      {
        case 758: return '758: Parapro Instr Title I SW'
        case 759: return '759: Parapro Instr Title I TA'
        case 760: return '760: Parapro Non Instructional'
        case 761: return '761: Personal Care Aide'
        case 762: return '762: Parapro Instr Special EDU'
        case 763: return '763: Parapro Instr No Title I'
      }
    },
    schoolFederalProgramStatusFormatter(code) {
      if(code == 1)
      {
        return 'Title I School Wide'
      } else if(code == 2)
      {
        return 'Title I Target Assisted'
      }
      else return 'Not Applicable'
    },
    takeSnapshot() {
      return apiService.Post(`/api/paraprofessional/snapshot`)
        .then(() => {
          this.$bvToast.toast('Snapshot Created', {
            title: `Snapshot Saved`,
            variant: 'success',
            solid: true
          })
          this.loadData = this.$store.dispatch(Types.actions.getSnapshotDates)
        })
    },
  },
  created() {
    this.loadData = this.$store.dispatch(Types.actions.getSnapshotDates)
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(lea) {
        if (lea && lea.number) {
          this.loadData = this.$store.dispatch(Types.actions.getParaprofessionals, { lea: lea.number, snapshotDate: this.selectedSnapshotDate })
        }
      }
    },
    selectedSnapshotDate: {
      handler(snapshotDate) {
        if (this.selectedLea && this.selectedLea.number) {
          this.loadData = this.$store.dispatch(Types.actions.getParaprofessionals, { lea: this.selectedLea.number, snapshotDate: snapshotDate })
        }
      }
    }
  }
}
</script>
import Vue from 'vue'
import { PageMixin } from '../../PageMixin'
import { EmployeeClassificationSelector }from './EmployeeClassificationSelector'
import EmployabilityBackgroundChecksWidget from './EmployabilityBackgroundChecksWidget.vue'
import { EmployabilityBackgroundChecksDetailsWidget } from './EmployabilityBackgroundChecksDetailsWidget'

const EmployabilityCheckPage = Vue.extend({
    name: 'employability-check-page',
    mixins: [PageMixin],
    components: {
        EmployeeClassificationSelector,
        EmployabilityBackgroundChecksWidget,
        EmployabilityBackgroundChecksDetailsWidget
    },
    data() {
        return {            
            currentRange: '',
            currentColor: '',
            loadData: null 
        }
    },
    methods:{
        rangeSelected(selectedRange, currentColor){
            this.currentRange = selectedRange
            this.currentColor = currentColor
        }
    },
    watch: {
        selectedLea: {
            immediate: true,
            handler(newLea) {
            if (newLea && newLea.number)
                this.loadData = this.$store.dispatch('hcptModule/getEmployabilityBackgroundChecks', { lea: newLea.number })
            }
        }
    }
})
export { EmployabilityCheckPage }
const DigitalLearningWaiverStatuses =
{
  Draft: 0,
  ReturnedToApplicant: 1,
  ReOpened: 2,
  PendingADEApproval: 11,
  Reviewed: 100,
  Approved: 101,
  Canceled: 255,
}

export { DigitalLearningWaiverStatuses }
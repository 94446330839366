
import ApiService from '../../services/ApiService'
import { aggregateResponseResults } from '../../helpers/ctAggregator'
export class CurriculumTransparency {
  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
  }

  namespaced = true

  state = {
    surveys: [],
    surveyProgress: []
  }

  mutations = {
    setSurveys: (state, data) => state.surveys = data,
    setSurveyProgress: (state, data) => state.surveyProgress = data,
  }

  getters = {
    aggregateData(state, getters, rootState, rootGetters) {
      
      return aggregateResponseResults(state.surveys.map(r=>{
        var lea = rootGetters['globalModule/leaDictionary'][r.districtLEA]
          if (lea)
          {
            return { 
            district: {number: lea.number, name: lea.name},
            responseData: r.responseData }
          }
        }).filter(r=>r))
    }
  }

  actions = {
    // eslint-disable-next-line no-unused-vars
    getSurveys: ({ commit }, payload) => {     
      return this._apiService.Get(`${this._baseUrl}api/core/ct/${payload.lea}/survey`)
        .then(data => {
          if (!data) throw new Error("The district has not yet completed the survey")

          data.forEach(d=>{
            d.responseData = JSON.parse(d.responseData)
          })
          
          commit('setSurveys', data)
        })
      
    },
    saveSurvey: ({ dispatch }, payload) => {
      var saveUrl = `${this._baseUrl}api/core/ct/${payload.districtLea}/survey`
      return this._apiService.Post(saveUrl, { ResponseData: JSON.stringify(payload.response), FormComplete: payload.formComplete })
        .then(() => dispatch('getSurveys', { lea: payload.districtLea }))
    },

    // eslint-disable-next-line
    // register: ({ dispatch }, payload) => {
    //   var saveUrl = `${this._baseUrl}api/core/ct/register`
    //   return this._apiService.Put(saveUrl, payload)
    // },

    getSurveyProgress: ({ commit }, payload) => {     
      return this._apiService.Get(`${this._baseUrl}api/core/ct/${payload.lea}/survey/progress`)
        .then(data => commit('setSurveyProgress', data))
    },
  }
}

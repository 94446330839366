<template>
  <loadable-chart-card :load-data="loadData" card-style="overflow-y: auto">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <div class="m-3">
          <b-form-checkbox v-model="showOverlay" name="check-button" switch>
            Overlay budget amounts on chart
          </b-form-checkbox>
        </div>
        <highcharts class="chart" :options="actualChartOptions" />
        <highcharts class="chart" :options="budgetChartOptions" v-if="!showOverlay" />
      </div>
      <div v-show="!slotProps.chartMode">
        <statistical-report-data />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { credits } from '../../../helpers/credits'
import { Categories } from './Categories'
import { mapGetters } from 'vuex'
import { Types } from '../../../stores/financeModule'
import Highcharts from 'highcharts'
import StatisticalReportData from './StatisticalReportData.vue'

export default {
  data() {
    return {
      showOverlay: false,
      animationDuration: 1000,
      budgetChartOptions: {
        chart: {
          type: 'column',
          inverted: true
        },
        credits: credits.MSI,
        title: {
          text: ''
        },
        legend: {
          enabled: true
        },
        yAxis: [
          {
            endOnTick: false,
            max: null,
            labels: {},
            tickPositions: null,
            index: 0
          }
        ],
        xAxis: [{
          categories: Categories,
          index: 0
        }],
        series: [],
        exporting: {
          showTable: true,
          enabled: true
        }
      },
      actualChartOptions: {
        chart: {
          type: 'column',
          inverted: true
        },
        credits: credits.MSI,
        title: {
          text: ''
        },
        legend: {
          enabled: true
        },
        yAxis: [
          {
            endOnTick: false,
            max: null,
            labels: {},
            tickPositions: null,
            index: 0
          }
        ],
        xAxis: [{
          categories: Categories,
          index: 0
        }],
        series: [],
        exporting: {
          showTable: true,
          enabled: true
        }
      }
    }
  },
  computed: {
    ...mapGetters(Types.path, [Types.getters.asrFiscalYear, Types.getters.asrTotals]),
    leaDictionary() {
      return this.$store.state.globalModule.leaContext.LeaDictionary
    },
    title() {
      return `Totals – ${this.leaFormatter(this.selectedLea.number)}`
    }
  },
  mixins: [ChartMixin],
  components: {
    StatisticalReportData
  },
  watch: {
    asrTotals: {
      immediate: true,
      handler(totals) {
        this.populate(totals)
        this.setTitles(this.selectedLea)
      }
    },
    showOverlay() {
      this.populate(this.asrTotals)
      this.setTitles(this.selectedLea)
    },
    selectedLea: {
      immediate: true,
      handler(lea) {
        this.setTitles(lea)
      }
    }
  },
  methods: {
    setTitles(lea) {
      if (lea && lea.number) {
        if (this.showOverlay)
          this.actualChartOptions.title.text = `Totals - ${this.leaFormatter(this.selectedLea.number)}`
        else {
          this.actualChartOptions.title.text = this.actualName()
          this.budgetChartOptions.title.text = this.budgetName()
        }
      }
    },
    populate(totals) {
      this.actualChartOptions.series = []
      this.budgetChartOptions.series = []

      this.actualChartOptions.series.push(this.getSeries(this.actualName(), totals))

      if (this.showOverlay) {
        this.actualChartOptions.series.push(this.getSeries(this.budgetName(), totals.map(b => {
          return { name: b.name, y: b.budgetValue }
        }), '#F2C500'))
      }
      else {
        this.budgetChartOptions.series.push(this.getSeries(this.budgetName(), totals.map(b => {
          return { name: b.name, y: b.budgetValue }
        })))
      }
    },
    getSeries(name, data, color) {
      return {
        name: name,
        data: data,
        color: color,
        dataLabels: {
          formatter: function () { return '$' + Highcharts.numberFormat(this.y, 0) }
        }
      }
    },
    leaFormatter(lea) {
      if (this.leaDictionary[lea])
        return `(${lea}) ${this.leaDictionary[lea].name}`

      return lea
    },
    actualName() {
      return `${this.asrFiscalYear + 1989} / ${this.asrFiscalYear + 1990} Actual`
    },
    budgetName() {
      return `${this.asrFiscalYear + 1990} / ${this.asrFiscalYear + 1991} Budget`
    }
  }
}
</script>

<template>
  <div>
    <b-table fixed :fields="fields" caption="Scholarships" :caption-top="true" :items="value">
      <template #head(action)>
        <b-btn v-if="!readonly" variant="primary" @click="addNew">
          <i class="fa fa-plus-circle fa-fw" />
          New Scholarship
        </b-btn>
      </template>
      <template #cell(action)="data">
        <b-btn
          v-if="data.item.locked && !readonly"
          size="sm"
          variant="primary"
          title="Edit Scholarship"
          class="mr-1"
          @click="data.item.locked=false"
        >
          <i class="far fa-edit fa-fw" />
        </b-btn>

        <b-btn
          v-if="data.item.locked && !readonly"
          size="sm"
          variant="danger"
          title="Remove Scholarship"
          @click="removeScholarship(data)"
        >
          <i class="far fa-times-circle fa-fw" />
        </b-btn>
        <b-btn
          v-else-if="!readonly"
          size="sm"
          variant="primary"
          title="Save Changes"
          @click="data.item.locked=true"
        >
          <i class="far fa-save fa-fw" />
        </b-btn>            
      </template>
      <template #cell(name)="data">
        <div>
          <span v-if="data.item.locked">{{ data.value }}</span>
          <b-form-input v-else v-model="data.item.name" />
        </div>
      </template>
      <template #cell(status)="data">
        <div>
          <b-badge v-if="data.item.locked">
            {{ data.value }}
          </b-badge>
          <b-form-select v-else v-model="data.item.status" :options="staticMetadata.postSecondaryPlanning.otherScholarshipAcceptanceStatus" />
        </div>
      </template>
      <template #cell(amount)="data">
        <div>
          <span v-if="data.item.locked">{{ data.value | currency }}</span>
          <b-input-group v-else prepend="$" append=".00">
            <b-form-input v-model.number="data.item.amount" class="text-right" type="number" />
          </b-input-group>          
        </div>
      </template>
    </b-table>

    <b-table :fields="summaryFields" caption="Scholarship Summary" :caption-top="true" :items="summary" />
  </div>
</template>

<script>
import shortid from 'shortid'
import _ from 'lodash'
import { formatCurrency } from '@/helpers/formatters'
import { metadata as staticMetadata } from '@/modules/cte/stores/careercoach/metadata'

export default {
  computed: {
    summary() {
      return [{
        numberApplied: this.value.filter(s=>s.status=='Applied' ||s.status=='Offered' || s.status=='Accepted').length,
        numberOffered: this.value.filter(s=>s.status=='Offered' || s.status=='Accepted').length,
        numberAccepted: this.value.filter(s=>s.status=='Accepted').length,
        appliedAmount: _.sumBy(this.value.filter(s=>s.status=='Applied' ||s.status=='Offered' || s.status=='Accepted'), (o) => o.amount),
        offeredAmount: _.sumBy(this.value.filter(s=>s.status=='Offered' || s.status=='Accepted'), (o) => o.amount),
        acceptedAmount: _.sumBy(this.value.filter(s=>s.status=='Accepted'), (o) => o.amount)
      }]
    }
  },
  data() {
    return {
       fields: [
        { key: 'name' },
        { key: 'status', class: 'text-center' },
        { key: 'amount', label: 'Scholarship Amount', class: 'text-right', thStyle: 'width: 200px;'},
        { key: 'action', class: 'text-right', thStyle: 'width: 200px;' }
      ],
      summaryFields: [
        { key: 'numberApplied', label: 'Number of Scholarships Applied for', class:'text-center'},
        { key: 'appliedAmount', label: 'Applied Scholarship Amount', class: 'text-center', formatter: (v) => {return formatCurrency(v)}},
        { key: 'numberOffered', label: 'Number of Scholarships Offered', class: 'text-center'},
        { key: 'offeredAmount', label: 'Offered Scholarship Amount', class:'text-center', formatter: (v) => {return formatCurrency(v)}},
        { key: 'numberAccepted', label: 'Number of Scholarships Accepted', class: 'text-center'},
        { key: 'acceptedAmount', label: 'Accepted Scholarship Amount', class: 'text-center', formatter: (v) => {return formatCurrency(v)}}
      ],
      staticMetadata
    }
  },
  methods: {
    addNew() {
      this.addScholarship()
    },
    removeScholarship(dataItem) {
      this.removeScholarshipById(dataItem.item.id)
    },
    addScholarship() {
      this.value.push({id: shortid.generate(), name: null, amount: 0, status: null, locked: false})
    },
    removeScholarshipById(id) {
      var index = this.value.findIndex(v=>v.id == id)
      if (index >= 0) this.value.splice(index, 1)
    }
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    readonly: Boolean
  },
  watch: {
    value: {
      deep: true,
      handler(newData) {
        this.$emit('input', newData)
      } 
    }
  }
}

</script>
import ApiService from '@/services/ApiService'
import removeNamespace from '@/stores/removeNamespace'
import { createGetTransaction, createInsertTransaction, createDeleteTransaction, createUpdateTransaction } from '@/stores/VuexTransaction'

export const Types = {
  state: {
    paraprofessionals: 'paraprofessionals',
    snapshotDates: 'snapshotDates',
    filter: 'filter',
  },
  actions: {
    getParaprofessionals: 'paraprofessional/getParaprofessionals',
    getSnapshotDates: 'paraprofessional/getSnapshotDates',
  },
  mutations: {
    setParaprofessionals: 'paraprofessional/setParaprofessionals',
    setSnapshotDates: 'paraprofessional/setSnapshotDates',
    setFilter: 'paraprofessional/setFilter',
  },
  getters: {
    filteredParaprofessionals: 'filteredParaprofessionals'
  },
  path: 'paraprofessional'
}
const _types = removeNamespace('paraprofessional/', Types)


export class ParaprofessionalModule {
  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''

    this.createGetTransaction = createGetTransaction
    this.createInsertTransaction = createInsertTransaction
    this.createDeleteTransaction = createDeleteTransaction
    this.createUpdateTransaction = createUpdateTransaction
    
    const ppUrl = `${this._baseUrl}api/paraprofessional` 

    this.createGetTransaction(_types.state.paraprofessionals,
      _types.mutations.setParaprofessionals,
      _types.actions.getParaprofessionals,
      (payload) => `${ppUrl}/${payload.lea}/employees?snapshotDate=${payload.snapshotDate}`
    )
    this.createGetTransaction(_types.state.snapshotDates,
      _types.mutations.setSnapshotDates,
      _types.actions.getSnapshotDates,
      () => `${ppUrl}/snapshot`
    )
  }

  namespaced = true

  state = {
    [_types.state.filter]: {
      selectedStatus: null,
      selectedFundSource: null,
      selectedQualification: null,
      selectedJobCode: null,
      criteria: [],
    },
  }

  getters = {
    [_types.getters.filteredParaprofessionals]: (state) => {
      let filteredParaprofessionals = [...state.paraprofessionals]

      if (state.filter.selectedStatus != null) {
        filteredParaprofessionals = filteredParaprofessionals.filter(p => p.schoolFederalProgramStatus == state.filter.selectedStatus)
      }
      if (state.filter.selectedFundSource != null) {
        filteredParaprofessionals = filteredParaprofessionals.filter(p => p.salaryFund == state.filter.selectedFundSource)
      } 
      if (state.filter.selectedQualification != null) {
        filteredParaprofessionals = filteredParaprofessionals.filter(p => p.qualification == state.filter.selectedQualification)
      }
      if(state.filter.selectedJobCode != null) {
        filteredParaprofessionals = filteredParaprofessionals.filter(p => p.jobCode == state.filter.selectedJobCode)
      }
      //debugger
      if (state.filter.criteria && state.filter.criteria.includes('1')) {
        filteredParaprofessionals = filteredParaprofessionals.filter(p => p.criteria1 == true)
      }
      if (state.filter.criteria && state.filter.criteria.includes('2')) {
        filteredParaprofessionals = filteredParaprofessionals.filter(p => p.criteria2 == true)
      }
      if (state.filter.criteria && state.filter.criteria.includes('3')) {
        filteredParaprofessionals = filteredParaprofessionals.filter(p => p.criteria3 == true)
      }
      if (state.filter.criteria && state.filter.criteria.includes('4')) {
        filteredParaprofessionals = filteredParaprofessionals.filter(p => p.criteria4 == true)
      }
      if (state.filter.criteria && state.filter.criteria.includes('5')) {
        filteredParaprofessionals = filteredParaprofessionals.filter(p => p.criteria5 == true)
      }

      const latestSnapshot = (state.snapshotDates || []).length > 0 ? state.snapshotDates[0] : new Date()
      console.log(latestSnapshot)
      return filteredParaprofessionals.map(p => {
        return { ...p, _rowVariant: (p.originalHireDate > latestSnapshot) ? 'warning' : '' }
      })
    },
  }

  mutations = {
    [_types.mutations.setFilter]: (state, data) => {
      state.filter = data
    },
  }

  actions = {
  }
}

<template lang="html">
  <div>
    <!-- <b-alert show variant="warning" v-if="selectedLea.type === 3">
      Career Coach Reports are only available at the Co-Op level and below. Please select an appropriate LEA from the LEA Context Picker above.
    </b-alert> -->
    <b-tabs card v-if="this.selectedLea.number != 'MYLEAS'">
      <b-tab title="Student Information" v-if="!isCTECoordinator">
        <student-list-container :ay="ay" />
      </b-tab>
      <b-tab title="Service Coverage" v-if="!isCTECoordinator">
        <service-coverage-container :ay="ay" />
      </b-tab>
      <b-tab title="Student Contacts" v-if="!isCTECoordinator">
        <student-service-details :ay="ay" />
      </b-tab>
      <b-tab title="Contact Details" v-if="!isCTECoordinator">
        <contact-details-container :ay="ay" />
      </b-tab>
      <b-tab title="Monthly Cumulative Summary" v-if="!isCTECoordinator">
        <monthly-cumulative-summary :ay="ay" />
      </b-tab>
      <b-tab title="Academic Outcomes" v-if="!isCTECoordinator">
        <academic-outcomes-container :ay="ay" />
      </b-tab>
      <b-tab title="Certifications">
        <certifications-report :ay="ay" />
      </b-tab>
    </b-tabs>
    <b-alert v-else show class="text-center" variant="warning">
      Reports are available for District and School LEAs. Please change LEA selection to generate reports.
    </b-alert>
  </div>
</template>

<script>
import MonthlyCumulativeSummary from './components/monthlyCumulativeSummary/Container.vue'
import ContactDetailsContainer from './components/contactDetails/Container.vue'
import StudentListContainer from './components/studentListing/StudentListContainer.vue'
import ServiceCoverageContainer from './components/serviceCoverage/Container.vue'
import StudentServiceDetails from './components/studentContacts/Container.vue'
import AcademicOutcomesContainer from './components/academicOutcomes/Container.vue'
import CertificationsReport from './components/certifications/CertificationsReport.vue'
import { mapState } from 'vuex'

import store from '@/stores'

export default {
  components:{
    ServiceCoverageContainer,
    MonthlyCumulativeSummary,
    StudentListContainer,
    ContactDetailsContainer,
    StudentServiceDetails,
    AcademicOutcomesContainer,
    CertificationsReport,
  },
  props: {
    ay: Number
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    isCTECoordinator() {
      return this.userContext.isCTECoordinator//(this.userContext.CoordinatorCodes || '').includes('Z')
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('cte/careercoach/reporting/getRosterDates')
    next()
  }
}
</script>

<style lang="scss" scoped>
  $insights-standard-blue: #4785c8;

  .report-container {
    padding: 10px;
    border-radius: 5px;
    background-color: #dadada;

    & ::v-deep .report-output {
      // background-color: #c34393;
      & table {
        margin-bottom: 20px;
      }
    }

    & ::v-deep table > caption {
      background-color: $insights-standard-blue;
      padding-left: 20px;
      color: darken($insights-standard-blue, 40%)
    }

  }
</style>
import Vue from 'vue'
import { Types as CommonTypes } from '@/modules/cte/stores'
import { mapState } from 'vuex'

const clustersEditMixin = Vue.extend({
  data() {
    return {
      name: null
    }
  },
  computed: {
    ...mapState(CommonTypes.path, [CommonTypes.state.currentDataItem])
  },
  methods: {
    save() {

      let payload = {
        set: this.set,
        item: {
          name: this.name
        }  
      }

      if (typeof(this.preparePayload) === 'function') {
        console.log('payload overridde')
        payload = this.preparePayload()
      }

      if (this.mode == 'add') {
        payload.item.startAY = this.$route.params.ay
        this.$store.dispatch(CommonTypes.actions.addClusterItem, payload)
      } else if (this.mode == 'edit') {
        payload.item.id = this.currentDataItem.item.id
        this.$store.dispatch(CommonTypes.actions.updateClusterItem, payload)
      }
      this.$emit('on-action-complete')
    },
    cancel() {
      this.$emit('on-action-complete')
    }
  },
  watch: {
    currentDataItem: {
      immediate: true,
      handler(newItem) {
        this.name = newItem?.item?.name
      }
    }
  },
  props: {
    mode: {
      type: String,
      required:true
    },
    set: {
      type: String,
      required: true
    }
  }
})
export { clustersEditMixin }

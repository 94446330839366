<template>
  <div>
    <b-form>
      <b-form-group label="Cost Type Code:" label-for="" description="" :disabled="mode == 'edit'">
        <b-form-input v-model="editItem.code" />
      </b-form-group>
      <b-form-group label="Description:" label-for="" description="">
        <b-form-input v-model="editItem.description" />
      </b-form-group>
      <b-form-group label="Explanation" label-for="" description="">
        <b-form-textarea v-model="editItem.explanation" />
      </b-form-group>
      <b-form-group label="Category:" label-for="" description="">
        <b-form-select v-model="editItem.category" :options="costTypeCategories" />
      </b-form-group>
      <b-form-group label="Inventoriable:" label-for="" description="">
        <b-form-checkbox v-model="editItem.inventoriable" />
      </b-form-group>
      <b-form-group label="Enabled:" label-for="" description="">
        <b-form-checkbox v-model="editItem.enabled" switch />
      </b-form-group>
      <b-button type="button" @click.prevent="save" variant="primary">
        Save
      </b-button>
      <b-button type="button" @click.prevent="cancel" variant="danger">
        Cancel
      </b-button>
    </b-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Types } from '@/modules/cte/stores/perkinsv'
import { CostTypeCategories } from '../../enums'

export default {
  data(){
    return {
      costTypeCategories: CostTypeCategories
    }
  },
  computed:{
    ...mapState(Types.path, [Types.state.costTypes]),
    editItem() {
      return this.editItemId ? 
        this.costTypes.filter(t => t.costTypeId == this.editItemId)[0] : 
        {
          academicYear: 0,
          code: null,
          name: null,
          description: null,
          explanation: null,
          category: null,
          inventoriable: false,
          enabled: true
        }
    }
  },
  props:{
    editItemId: {
      type: String,
      default: null
    },
    mode:{
      type: String,
      default: 'edit'
    }
  },
  methods:{
    save(){
      if(this.mode == 'edit'){
        this.$store.dispatch(Types.actions.updateCostType, { costTypeId: this.editItemId, editItem: this.editItem })
      }
      else{
        this.$store.dispatch(Types.actions.createCostType, { editItem: this.editItem })
      }
      this.$router.go(-1)
    },
    cancel(){
      this.$router.go(-1)
    }
  }
}
</script>
import Home from './home'
import Coops from './coops'
import Areas from './areas'
import Districts from './districts'
const AreaAdmin = {
  Home,
  Areas,
  Coops,
  Districts
}

export { AreaAdmin }
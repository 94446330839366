<template>
  <div class="card-deck">
    <div class="card waiver-card bg-light" v-if="!isCharter">
      <div class="card-body">
        <h5 class="card-title">
          {{ WaiverTypes.DigitalLearning.title }}
        </h5>
        <p class="card-text" v-if="digitalLearningWaiver">
          <workflow-status-badge :state-map="StateMap" :status="digitalLearningWaiver.status" :document="digitalLearningWaiver" />
          <span class="d-block" v-if="currentAssignment(digitalLearningWaiver)">
            Assigned to: <span class="font-weight-bold">{{ currentAssignment(digitalLearningWaiver) }}</span>
          </span>
          <span class="d-block">
            Last updated: <span>{{ digitalLearningWaiver.updatedDate | date }}</span>
          </span>
          <b-button class="mt-3" @click="openRequest(digitalLearningWaiver.waiverRequestId)" variant="primary">
            Open Waiver Request
          </b-button>
        </p>
        <div v-else>
          <div v-if="context == Context.District && canCreateDL">
            <p class="card-text">
              Apply for a Digital Learning Waiver
            </p>
            <b-button @click="createRequest(WaiverTypes.DigitalLearning)" variant="success">
              Create New Waiver Request
            </b-button>
          </div>
          <div v-else>
            <b-alert show variant="info">
              This district does not have an Digital Learning Waiver Request. A superintendent may create one.
            </b-alert>
          </div>
        </div>
      </div>
    </div>
    <div class="card waiver-card bg-light">
      <div class="card-body">
        <h5 class="card-title">
          {{ WaiverTypes.AMI.title }}
        </h5>
        <p class="card-text" v-if="ami">
          <workflow-status-badge :state-map="StateMap" :status="ami.status" :document="ami" />
          <span class="d-block" v-if="currentAssignment(ami)">
            Assigned to: <span class="font-weight-bold">{{ currentAssignment(ami) }}</span>
          </span>
          <span class="d-block">
            Last updated: <span>{{ ami.updatedDate | date }}</span>
          </span>
          <b-button class="mt-3" @click="openRequest(ami.waiverRequestId)" variant="primary">
            Open AMI Application
          </b-button> 
        </p>
        <div v-else>
          <div v-if="context == Context.District && canCreateAMI">
            <p class="card-text">
              Apply for Alternative Methods of Instruction program
            </p>                       
            <b-button @click="createRequest(WaiverTypes.AMI)" variant="success">
              Create New AMI Application
            </b-button>
          </div>
          <div v-else>
            <b-alert show variant="info">
              This district does not have an AMI application. A superintendent may create one.
            </b-alert>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Types, WaiverTypes, StateMap, Context } from '@/stores/digitalLearning'
import WorkflowStatusBadge from '@/components/workflow/WorkflowStatusBadge.vue'

export default {
  components: {
    WorkflowStatusBadge,
  },
  data() {
    return {
      WaiverTypes,
      Context,
      StateMap
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState(Types.path, [Types.state.waiverRequests, Types.state.newDLWaiverRequestsLockedStatus, Types.state.newAMIApplicationsLockedStatus]),
    districtWaiverRequests() {
      return this.waiverRequests.filter(r => r.districtLEA == this.lea.number)
    },
    digitalLearningWaiver() {
      return this.districtWaiverRequests.find(d => d.waiverType == WaiverTypes.DigitalLearning.value)
    },
    ami() {
      return this.districtWaiverRequests.find(d => d.waiverType == WaiverTypes.AMI.value)
    },
    isCharter() {
      return this.leaDictionary[this.lea.number].isCharter
    },
    isSuperintendent() {
      return this.userContext.isSuperintendent || this.userContext.isAlternativeLoginSuperintendent
    },
    canCreateDL() {
      return this.isSuperintendent && !this.newDLWaiverRequestsLockedStatus
    },
    canCreateAMI() {
      return this.isSuperintendent && !this.newAMIApplicationsLockedStatus
    },
  },
  methods: {
    createRequest(waiverType) {
      const self = this
      this.loadData = this.$store.dispatch(Types.actions.createWaiverRequest, { lea: this.lea.number, waiverType: waiverType.value }).then(waiverRequest => {
        return self.$router.push({ ...this.$router.currentRoute, query: { waiverRequestId: waiverRequest.waiverRequestId, lea: this.lea.number } }, () => self.$emit('on-create'))
      })
    },
    openRequest(waiverRequestId) {
      this.$router.push({ ...this.$router.currentRoute, query: { waiverRequestId: waiverRequestId, lea: this.lea.number } })
    },
    currentStatus(waiverRequest) {
      return waiverRequest ? StateMap.get(waiverRequest.status) : null
    },
    currentAssignment(waiverRequest) {
      if (waiverRequest && waiverRequest.status < 10 && (waiverRequest.changeLogs || []).some(l => l.assignedToName && l.assignedToName.length > 0)) {
        let changeLogs = [...waiverRequest.changeLogs].reverse()
        return changeLogs.find(l => l.assignedToName && l.assignedToName.length > 0).assignedToName
      }
      return null
    },
  },
  props: {
    lea: Object,
    context: Number
  },
}
</script>

<style>
.waiver-card {
  height: 13.5rem;
  max-width: 30rem;
}
</style>
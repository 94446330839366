<template>
  <div v-if="render">
    <a name="table-2" class="jumptarget" />
    <table id="subject-table" class="table table-striped table-bordered" style="width:100%">
      <caption>Table 2: Count of Teachers in Category</caption>
      <thead>
        <tr>
          <th class="text-right">
            Not high-impact
          </th>
          <th class="text-right">
            High Impact
          </th>
          <th class="text-right">
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-right">
            {{ detailsBySubject[undefined].filter(d => d.rating == 1).length.toLocaleString('US') }}
          </td>
          <td class="text-right">
            {{ detailsBySubject[undefined].filter(d => d.rating == 2).length.toLocaleString('US') }}
          </td>
          <td class="text-right">
            {{ detailsBySubject[undefined].length.toLocaleString('US') }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { subjectCase } from '../helpers.js';
import { effectivenessLevels } from '../enums.js';

export default {
  data() {
    return {
      subjectCase,
      effectivenessLevels
    }
  },
  props: {
    render: {
      type: Boolean,
      default: true
    }
  },
  methods: {
  },
  computed: {
    ...mapGetters('teacherVAMModule', ['detailsBySubject']),
  }
}
</script>
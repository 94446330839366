<template>
  <div>
    <excel-button title="Building Security Checks" />
    <router-view />
    <h1>Building Security Checks</h1>
    <div>
      <facilities-filter @on-filter-changed="onFilterChanged" :enable-building-filter="false" :criteria-visible="criteriaVisible" @on-filter-cleared="onFilterCleared" />
      <div class="card card-body">
        <paginated-table hover
                         selectable
                         select-mode="single"
                         @pt:row-selected="onSelected"
                         :items="buildingSecurityChecks"
                         :fields="fields"
                         :load-data="loadData">
          <template v-slot:cell(perimeterDoorsSecured)="data">
            <div v-if="data.item.buildingSecurityCheckId > 0" class="text-center">
              <span class="fa" :class="{'text-success fa-check-circle': data.value, 'text-danger fa-times-circle': !data.value}" />
            </div>
          </template>
          <template v-slot:cell(interiorDoorsSecured)="data">
            <div v-if="data.item.buildingSecurityCheckId > 0" class="text-center">
              <span class="fa" :class="{'text-success fa-check-circle': data.value, 'text-danger fa-times-circle': !data.value}" />
            </div>
          </template>
          <template v-slot:cell(staffWearingBadges)="data">
            <div v-if="data.item.buildingSecurityCheckId > 0" class="text-center">
              <span class="fa" :class="{'text-success fa-check-circle': data.value, 'text-danger fa-times-circle': !data.value}" />
            </div>
          </template>
          <template v-slot:cell(visitorSecurity)="data">
            <div v-if="data.item.buildingSecurityCheckId > 0" class="text-center">
              <span class="fa" :class="{'text-success fa-check-circle': data.value, 'text-danger fa-times-circle': !data.value}" />
            </div>
          </template>
          <template v-slot:cell(drillsCompleted)="data">
            <div v-if="data.item.buildingSecurityCheckId > 0" class="text-center">
              <span class="fa" :class="{'text-success fa-check-circle': data.value, 'text-danger fa-times-circle': !data.value}" />
            </div>
          </template>
          <template v-slot:cell(securityCheckDate)="data">
            <div v-if="data.item.buildingSecurityCheckId > 0" class="text-center">
              {{ data.value | date('short-date') }}
            </div>
          </template>
          <template v-slot:cell(Action)="data">
            <b-button v-if="data.item.buildingSecurityCheckId > 0" @click="onSelected(data.item)" class="btn btn-primary float-right mr-1 d-print-none" v-bind="$attrs">
              Edit
            </b-button>
            <b-button v-if="data.item.buildingSecurityCheckId == 0" @click="onSelected(data.item)" class="btn btn-primary float-right mr-1 d-print-none" v-bind="$attrs">
              New
            </b-button>
          </template>
        </paginated-table>
      </div>
    </div>
    <building-security-check-editor :security-check="selectedBuildingSecurityCheck" @saved="fetchData" />
  </div>
</template>

<script>
import ApiService from '@/services/ApiService'
import { PageMixin } from '../../PageMixin'
import { mapState } from 'vuex'
import FacilitiesFilter from '../facilities/FacilitiesFilter.vue'
import PaginatedTable from '@/components/PaginatedTable.vue'
import BuildingSecurityCheckEditor from './securityCheck/BuildingSecurityCheckEditor.vue'

export default {
  mixins: [PageMixin],
  components: {
    FacilitiesFilter,
    PaginatedTable,
    BuildingSecurityCheckEditor
  },
  data() {
    return {
      loadData: null,
      filtered: false,
      buildingSecurityChecks: [],
      fields: [
        { key: "schoolNumber", sortable: true },
        { key: "buildingNumber", sortable: true },
        { key: "districtLEA", sortable: true },
        { key: "buildingName", sortable: true },
        { key: "perimeterDoorsSecured", sortable: true, class: "text-center" },    
        { key: "interiorDoorsSecured", sortable: true, class: "text-center" },
        { key: "staffWearingBadges", sortable: true, class: "text-center" },    
        { key: "visitorSecurity", sortable: true, class: "text-center" },
        { key: "drillsCompleted", sortable: true, class: "text-center" },
        { key: "securityCheckDate", sortable: true, class: "text-center"},
        { key: "updatedBy", sortable: true, class: "text-center"},
        { key: "Action", sortable: false, class: "text-right"},
      ],
      selectedBuildingSecurityCheck: { response: {} }
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState('DPSAFT', ['buildings', 'filter']),
    ...mapState('DPSAFT/Facilities', ['BatchMaintenance']),
    ...mapState('DPSAFT/Facilities/Security', ['BatchMaintenance']),
    selectedBuildings: {
      set(buildings) {
        this.$store.commit('DPSAFT/Facilities/setSelectedBuildings', buildings)
      },
      get() {
        return this.BatchMaintenance.SelectedBuildings
      }
    },
    items() {
      if (this.showTable) {
        return this.buildingSecurityChecks
      }
      return []
    },
    showTable() {
      return (this.filtered && (this.filter.area || this.filter.district)) || this.allowStateLevel
    },
    canFilter() {
      return (this.filter.area || this.filter.district) || this.allowStateLevel
    },
    isStateView() {
      return this.allowStateLevel && (!this.filter.area && !this.filter.district)
    }
  },
  props: {
    districtLea: String,
    showActionColumn: {
      type: Boolean,
      default: false
    },
    allowItemCheck: {
      type: Boolean,
      default: false
    },
    criteriaVisible: {
      type: String,
      default: "adb"
    },
    allowStateLevel: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onFilterChanged(newFilter) {
      console.log(newFilter)
      this.filtered = true
      this.loadData = this.fetchData()
    },
    onFilterCleared() {
      if (this.allowStateLevel) {
        this.loadData = this.fetchData()
      }
      this.filtered = false
    },
    onSelected(selected) {
      this.selectedBuildingSecurityCheck = { 
        buildingSecurityCheckId: selected.buildingSecurityCheckId,
        districtLEA: selected.districtLEA,
        fiscalYear: selected.fiscalYear,
        schoolNumber: selected.schoolNumber,
        buildingNumber: selected.buildingNumber,
        buildingName : selected.buildingName,
        securityCheckDate: selected.securityCheckDate,
        createdBy: selected.createdBy,
        createdDate: selected.createdDate,
        updatedBy: selected.updatedBy,
        updatedDate: selected.updatedDate,
        response: {
          perimeterDoorsSecured: selected.perimeterDoorsSecured,
          interiorDoorsSecured: selected.interiorDoorsSecured,
          staffWearingBadges: selected.staffWearingBadges,
          visitorSecurity: selected.visitorSecurity,
          drillsCompleted: selected.drillsCompleted,
        }
      }
      this.$bvModal.show('security-check-editor-modal');
    },
    onItemChecked(checkedItems) {
      this.selectedBuildings = checkedItems
    },
    onAddItems(payload) {
      this.$emit('add-items', payload)
    },
    fetchData() {
      return new ApiService().Get(`/api/dpsaft/facilities/security/buildings?districtLea=${this.filter.district}`).then(data => {
        this.filtered = true
        this.buildingSecurityChecks = data
      })
    },
    excelExportData() {
      return {
        name: 'Buildings',
        items: this.buildingSecurityChecks.map(d => {
          return {
            SchoolNumber: d.schoolNumber,
            BuildingNumber: d.buildingNumber,
            DistrictLEA: d.districtLEA,
            BuildingName: d.buildingName,
            PerimeterDoorsSecured: d.buildingSecurityCheckId ? d.perimeterDoorsSecured : '', 
            InteriorDoorsSecured: d.buildingSecurityCheckId ? d.interiorDoorsSecured : '', 
            StaffWearingBadges: d.buildingSecurityCheckId ? d.staffWearingBadges : '', 
            VisitorSecurity: d.buildingSecurityCheckId ? d.visitorSecurity : '',  
            SecurityCheckDate: d.buildingSecurityCheckId ? d.securityCheckDate : '',
          }
        })
      }
    },
  },
  mounted() {
    this.filtered = false

    var promises = [this.$store.dispatch('DPSAFT/getAreas')]

    if (this.canFilter) {
      if (this.allowStateLevel) {
        promises.push(
          this.$store.dispatch('DPSAFT/getCoopsAndBuildings')
        )
      }
      promises.push(
        this.fetchData()
      )
    }

    this.loadData = Promise.all(promises)
  }
}
</script>

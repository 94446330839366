<template>
  <b-form>
    <b-form-group id="input-group-1" label="Area Name:" label-for="input-1">
      <b-form-input id="input-name" v-model="name" />
    </b-form-group>

    <b-form-group id="input-group-2" label="Facilites Planning Manager:" label-for="input-2">
      <b-form-select id="input-facilityPlanMgrId" placeholder="Select Manager" v-model="facilityPlanMgrId" value-field="userId" text-field="fullName" :options="areaManagers.facilitiesPlanningAndConstructionManagers">
        <template v-slot:first>
          <b-form-select-option :value="null">
            Unassigned
          </b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>
    <b-form-group id="input-group-3" label="Facilites Maintenance Manager:" label-for="input-3">
      <b-form-select id="input-facilityMaintMgrId" placeholder="Select Manager" v-model="facilityMaintMgrId" value-field="userId" text-field="fullName" :options="areaManagers.facilitiesMaintenanceManagers">
        <template v-slot:first>
          <b-form-select-option :value="null">
            Unassigned
          </b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>
    
    <b-button variant="primary" :disabled="!isValid" @click="save">
      Save
    </b-button>
    <b-button variant="danger" class="mx-1" @click="$emit('on-cancel')">
      Cancel
    </b-button>
  </b-form>
</template>


<script>
import { mapState } from 'vuex'

export default {
  name: 'edit-area',
  data() {
    return {
      areaId: null,
      name: null,
      facilityPlanMgrId: null,
      facilityMaintMgrId: null
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState('DPSAFT', ['areas', 'areaManagers']),
    isValid() {
      return this.name
    }
  },
  methods: {
    save() {
      // this.$store.dispatch('DPSAFT/Admin/updateArea',
      this.$emit('on-save', {
           areaId: this.areaId,
           name: this.name,
           facilityPlanMgrId: this.facilityPlanMgrId,
           facilityMaintMgrId: this.facilityMaintMgrId
       })
    }
  },
  props: {
    item: Object
  },
  watch: {
    item: {
      immediate: true,
      handler(newItem) {
        this.areaId = newItem.areaId
        this.name = newItem.name
        this.facilityMaintMgrId = newItem.facilityMaintMgrId || null
        this.facilityPlanMgrId = newItem.facilityPlanMgrId || null
      }
    }
  }
}
</script>

<template>
  <div>
    <b-form-file
      v-model="file"
      :state="Boolean(file)"
      placeholder="Choose a file or drop it here..."
      drop-placeholder="Drop file here..." />
    <div class="mt-3">
      Selected file: {{ file ? file.name : '' }}
    </div>
    <div v-if="file && file.name">
      <b-button @click="addDocument">
        Add Document
      </b-button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      file: []
    }
  },
  props: {
    requestId: {
      type: String,
      required: true
    }
  },
  methods: {
    addDocument() {
      if (this.file) {
        var formData = new FormData()
        formData.append('file', this.file, this.file.name)
        
        axios.post(`/api/request/${this.requestId}/document`,
          formData,
          {
            headers: { 
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          }
        ).then(() => {
          //console.log('uploaded')
          this.file = {}
          this.$store.dispatch('toolsModule/leaAssignment/getRequestDocuments', { requestId: this.requestId })
        })
      }
    }
  }
}
</script>
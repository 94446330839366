import Vue from 'vue'
import { PageMixin } from '../PageMixin'
import { ModulePermissionsTable } from './ModulePermissionsTable'

const GrantAccessPage = Vue.extend({
    name: 'grant-access-page',
    mixins: [PageMixin],
    data() {
        return {
            loadAllModulePermissions: null
        }
    },
    components: {
        ModulePermissionsTable
    },
    computed: {
        selectedModule() {
            return this.$store.state.adminModule.selectedModule
        }
    },
    methods: {
        
    },
    watch: {
        selectedLea: {
            immediate: true,
            handler() {
                Promise.all([
                    this.$store.dispatch('adminModule/getPageModules', this.selectedLea.number),
                    this.$store.dispatch('adminModule/getAllowableUsers', this.selectedLea.number)
                ])
                .then(() => {
                    this.loadAllModulePermissions = this.$store.dispatch('adminModule/getAllModulePermissions', { lea: this.selectedLea.number })
                });
            }
        }
    }
})
export { GrantAccessPage }
<template>
  <div>
    <b-card variant="primary" no-body>
      <b-tabs content-class="mt-3" tabs card>
        <b-tab title="Ledger">
          <general-ledger-table :items="filteredGeneralLedger" :fields="generalLedgerFields" name="Ledger" />
        </b-tab>
        <b-tab title="Expenditures">
          <div class="overflow-auto">
            <table-control-widget :per-page="expPageSize" @filter-changed="debounceInput($event)" @page-length-changed="expPageSize = $event" />
            <b-table ref="generalLedger" striped hover selectable select-mode="single" :items="getExpenditures" :fields="expenditureFields" :per-page="expPageSize"
                     :filter="expFilter" :current-page="expCurrentPage" :busy="isExpLoading">
              <div slot="table-busy" class="text-center text-danger my-2">
                <b-spinner class="align-middle" />
                <strong>Loading...</strong>
              </div>
            </b-table>
            <table-pagination-widget
              :total-rows-count="generalLedgerExpendituresCount"
              :filtered-rows-count="generalLedgerExpendituresCount"
              :page-length="expPageSize"
              @page-changed="expCurrentPage = $event" />            
          </div>
        </b-tab>
        <b-tab title="Revenue">
          <general-ledger-table :items="filteredGeneralLedgerRevenues" :fields="revenueFields" name="Revenue" />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { Types } from '../../../stores/financeModule'
import { TableMixin } from '../../TableMixin'
import { debounce } from '../../../helpers/globalHelpers'
import GeneralLedgerTable from './GeneralLedgerTable.vue'
const baseFields = [
  { key: 'lea', label: 'LEA', sortable: true },
  { key: 'fiscalYear', sortable: true },
  { key: 'fund', sortable: true },
  { key: 'account', sortable: true }
]
export default {
  mixins:[TableMixin],
  data() {
    return {
      loadData: null,
      expPageSize: 100,
      expCurrentPage: 1,
      expTotalRowCount: 0,
      expFilteredRowCount: 0,
      expFilter: '',
      isExpLoading: false,      
      generalLedgerFields: [
        { key: 'districtLEA', label: 'LEA', sortable: true },
        { key: 'fiscalYear', sortable: true },
        { key: 'fundId', label: 'Fund', sortable: true },
        { key: 'objectDetail', sortable: true },
        { key: 'budget', sortable: true, formatter: this.formatCurrency },
        { key: 'amount', sortable: true, formatter: this.formatCurrency }
      ],
      expenditureFields: [
        ...baseFields,
        { key: 'sourceOfFunds', sortable: true },
        { key: 'function', sortable: true },
        { key: 'location', sortable: true },
        { key: 'program', sortable: true },
        { key: 'locationName', sortable: true },
        { key: 'budgeted', sortable: true, formatter: this.formatCurrency },
        { key: 'actual', sortable: true, formatter: this.formatCurrency }
      ],
      revenueFields: [
        ...baseFields,
        { key: 'budgeted', sortable: true, formatter: this.formatCurrency },
        { key: 'actual', sortable: true, formatter: this.formatCurrency },
      ]
    }
  },
  props: {
    fund: String,
    account: String
  },
  computed: {
    ...mapGetters(Types.path, [Types.getters.fiscalYear]),
    ...mapState(Types.path, [
      Types.state.generalLedger, 
      Types.state.generalLedgerRevenues, 
      Types.state.generalLedgerExpenditures,
      Types.state.generalLedgerExpendituresCount,
      Types.state.fundsAndAccounts
    ]),
    selectedLea() { 
      return this.$store.state.globalModule.leaContext.SelectedLea 
    },
    filteredGeneralLedger() {
      return this.generalLedger
    },
    filteredGeneralLedgerRevenues() {
      let filtered = this.generalLedgerRevenues
      if (this.fund)
        filtered = filtered.filter(r => r.fund == this.fund)
      if (this.account)
        filtered = filtered.filter(r => r.account == this.account)

      return filtered
    },
    filteredGeneralLedgerExpenditures() {
      let filtered = this.generalLedgerExpenditures
      if (this.fund)
        filtered = filtered.filter(r => r.fund == this.fund)
      if (this.account)
        filtered = filtered.filter(r => r.account == this.account)
        
      return filtered
    }
  },
  components: {
    GeneralLedgerTable
  },
  watch: {
    selectedLea: {
      immediate: true,
      handler(newLea) {
        if(newLea && newLea.number){
          const params = {
            lea: newLea.number
          }
          this.loadData = Promise.all([
            //this.$store.dispatch(Types.actions.getGeneralLedgerExpenditures, params), 
            this.$store.dispatch(Types.actions.getGeneralLedgerRevenues, params), 
            this.$store.dispatch(Types.actions.getGeneralLedger, params)
          ])
        }
      }
    }
  },
  methods: {
    formatCurrency(value) {
      if (value) {
        return value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        })
      }        
      return value
    },
    debounceInput: debounce(function(e) {
      this.filter = e
    }, 750),
    getExpenditures(){
      this.isExpLoading = true
      const params = {
        lea: this.selectedLea.number,
        pageSize: this.expPageSize,
        page: this.expCurrentPage
      }
      return this.$store.dispatch(Types.actions.getGeneralLedgerExpenditures, params)
        .then(() => { 
          this.isExpLoading = false
          this.expTotalRowCount = this.generalLedgerExpendituresCount
          return this.generalLedgerExpenditures
        })
    }
  }
}
</script>

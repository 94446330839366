const LicenseTypes = {
  0:{
    name: 'All License Types',
    value: null
  },
  1:{
    name: 'Teacher Certification',
    value: 1,
    short: 'T'
  },
  2:{
    name: 'Admin License',
    value: 2,
    short: 'L'
  },
  3:{
    name: 'Technical Permit',
    value: 3,
    short: 'P'
  },
  4:{
    name: 'Ancillary',
    value: 4,
    short: 'A'
  }
}

export { LicenseTypes }
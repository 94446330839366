<template>
  <div>
    <b-alert variant="danger" v-if="errorMessage" show>
      {{ errorMessage }}
    </b-alert>
    <div v-if="userContext.isAuthenticated">
      <h3>
        {{ district.name }} ({{ district.number }})
      </h3>
      <p>{{ district.adminFirstName }} {{ district.adminLastName }} - <a :href="`mailto:${district.adminEmailAddress}`">{{ district.adminEmailAddress }}</a></p>
    </div>
    <b-card :header="title" header-bg-variant="primary">
      <b-form>
        <b-form-group label="Start Date">
          <div class="row">
            <div class="col-6">
              <b-form-datepicker v-model="startDate" :disabled="isViewer" :class="selectClass(startDateValid)" />
            </div>
            <div class="col-6">
              <b-form-timepicker placeholder="Choose a time or leave blank for all day" v-model="startTime" :disabled="isViewer" reset-button />        
            </div>
          </div>
        </b-form-group>
        <b-form-group label="End Date">
          <div class="row">
            <div class="col-6">
              <b-form-datepicker v-model="endDate" :disabled="isViewer" :class="selectClass(endDateValid)" />
            </div>
            <div class="col-6">
              <b-form-timepicker placeholder="Choose a time or leave blank for all day" v-model="endTime" :disabled="isViewer" reset-button />        
            </div>
          </div>
        </b-form-group>
        <b-form-group label="Closure Type" id="reasonTypeFormGroup">
          <v-select v-model="reasonType" :options="reasonOptions" :clearable="false" :disabled="isViewer" placeholder="(select closure reason)" :class="selectClass(reasonTypeValid)" />
        </b-form-group>
        <b-form-group label="Select schools(s) affected">
          <v-select multiple v-model="selectedSchools" :options="schools" :disabled="isViewer" placeholder="(one or more schools is required)" :class="selectClass(selectedSchoolsValid)" />
        </b-form-group>
        <b-form-group label="Select Grade Level(s) affected">
          <v-select multiple v-model="grades" :options="gradeOptions" :disabled="isViewer" placeholder="(one or more grade levels is required)" :class="selectClass(gradesValid)" />
        </b-form-group>
        <b-form-group label="Number Students Affected">
          <b-form-input type="number" v-model="numberStudentsAffected" :class="selectClass(numberStudentsValid)" :disabled="isViewer" />
        </b-form-group>
        <b-form-group label="Number Staff Affected">
          <b-form-input type="number" v-model="numberStaffAffected" :class="selectClass(numberStaffValid)" :disabled="isViewer" />
        </b-form-group>
        <b-form-group label="Is this closure marked as an AMI Day?">
          <b-form-radio-group v-model="isAMIDay" :disabled="isViewer">
            <b-form-radio :value="true">
              Yes
            </b-form-radio>
            <b-form-radio :value="false">
              No
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-form-group label="If not an AMI Day will the day(s) be made up at a later date?" v-if="!isAMIDay">
          <b-form-radio-group v-model="willBeMadeUp" :disabled="isViewer">
            <b-form-radio :value="true">
              Yes
            </b-form-radio>
            <b-form-radio :value="false">
              No
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-form-group>
          <b-form-group label="District Phone Number">
            <b-form-input type="tel" v-model="phoneNum" :disabled="isViewer" />
          </b-form-group>
          <b-form-group>
            <template v-slot:label>
              Additional Information and Comments<i> (Any information added here may be subject to FOIA)</i>
            </template>
            <b-form-textarea v-model="superintendentComments" rows="3" max-rows="3" :disabled="isViewer" />
          </b-form-group>
          <b-form-group label="ADE Comments">
            <b-alert variant="info small" class="p-2" :show="isAdminMode">
              <i class="fas fa-info-circle" /> Enter your Comments and click Submit. The Submit acts as a "Save" and will also trigger an automatic email to the Superintendent of the LEA with your comments.
            </b-alert>
            <b-form-textarea v-model="adeExternalComments" rows="3" max-rows="3" :readonly="!isAdminMode" :disabled="isViewer" />
          </b-form-group>
          <b-form-group v-if="isAdminMode && !isViewer" label="ADE Internal Knowledge Base Information">
            <b-alert variant="info small" class="p-2" show>
              <i class="fas fa-info-circle" /> This is for internal ADE use for keeping up with any additional information. Entering comments here will NOT trigger an automatic email to the Superintendent of the LEA.
            </b-alert>
            <b-form-textarea v-model="adeInternalComments" rows="3" max-rows="3" />
          </b-form-group>
          <b-form-row>
            <b-col class="text-right" v-if="!isViewer">
              <button id="submitClosure" :class="buttonValid" :disabled="!valid" @click="save" type="button">
                Submit
              </button>
              <b-button variant="secondary" :to="this.isAdminMode ? { name: 'sct-admin-closure-list' } : {name: 'sct-closure-list', params: {districtLea: districtLea}}">
                Cancel
              </b-button>
            </b-col>
          </b-form-row>
          <b-form-row>
            <p v-if="currentClosure != null">
              <span v-if="(currentClosure.createdBy || '').length > 0">Created by {{ currentClosure.createdBy }} on {{ currentClosure.createdDate | date }}</span><br>
              <span v-if="(currentClosure.updatedBy || '').length > 0">Last updated by {{ currentClosure.updatedBy }} on {{ currentClosure.updatedDate | date }}</span>
            </p>
          </b-form-row>
        </b-form-group>
      </b-form>  
    </b-card>
  </div>
</template>

<script>

import { ClosureReasons } from './enums'
import { gradeSpans } from '@/helpers/gradeSpans'
import Vue from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { mapState } from 'vuex'
import moment from 'moment'

function convertToSelectItem (key, value) {
  return { code: key, label: value }
}

Vue.component('v-select', vSelect)
export default {
  data() {
    return {
      selectedSchools: [],
      reasonType: null,
      districtPhone: null,
      superintendentComments: null,
      adeExternalComments: null,
      adeInternalComments: null,
      grades: [],
      numberStudentsAffected: null,
      numberStaffAffected: null,
      errorMessage: null,
      startDate: null,
      startTime: null,
      endDate: null,
      endTime: null,
      isAMIDay: false,
      willBeMadeUp: false
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState('core/sctClosures', ['currentClosure']),
    isAdminMode() {
      return this.userContext.isADESurvey || this.userContext.isInsightsAdmin
    },
    reasonOptions() {
      return ClosureReasons.filter(r=>r.value !== 0).map(ct => convertToSelectItem(ct.value, ct.label)).sort((a, b) => a.label.localeCompare(b.label))
    },
    gradeOptions() {
      const grades = gradeSpans.map(g => convertToSelectItem(g.value, g.text))
      return [{ code: 255, label: '(All Grades)'}, ...grades]
    },
    district() { 
      return this.leaDictionary[this.districtLea] 
    },
    schools() {
      const schools = this.district.ownedLeas.map(s => convertToSelectItem(s.number, `${s.name} (${s.number})`))
      return [{ code: 'ALL', label: '(Entire District)' }, ...schools]
    },
    title() {
      return this.id ? 'Edit Closure' : 'New Closure'
    },
    startDateValid() {
      return (this.startDate != null && this.startDate !== '')
    },
    endDateValid() {
      return (this.endDate != null && this.endDate !== '' && this.endDate >= this.startDate)
    },
    gradesValid() {
      return (this.grades && this.grades.length > 0)
    },
    selectedSchoolsValid() {
      return (this.selectedSchools && this.selectedSchools.length > 0)
    },
    reasonTypeValid() {
      return (this.reasonType != null && this.reasonType.code >= 0)
    },
    numberStudentsValid() {
      return (this.numberStudentsAffected != null && this.numberStudentsAffected !== '' && !isNaN(this.numberStudentsAffected))
    },
    numberStaffValid() {
      return (this.numberStaffAffected != null && this.numberStaffAffected !== '' && !isNaN(this.numberStaffAffected))
    },
    phoneNum: {
      get: function() {
        return this.districtPhone
      },
      set: function(newValue) {
        this.districtPhone = this.formatPhone(newValue)
      }
    },
    isViewer() {
      return this.userContext.isSurveyViewer
    },
    coopDirector() {
      return this.userContext.coopDirector
    },
    buttonValid() {
      if (this.valid) {
        return 'btn btn-primary mr-1'
      }
      return 'btn btn-primary disabled border rounded-lg border-danger mr-1'
    },
    valid() {
      return this.reasonTypeValid && this.selectedSchoolsValid && this.gradesValid && this.numberStudentsValid && this.numberStaffValid && this.startDateValid && this.endDateValid
    },
  },
  methods: {
    selectClass: (valid) => {
      if (valid) {
        return 'border rounded-lg border-success'
      } else if (!valid) {
        return 'border rounded-lg border-danger'
      } 
      return ''
    },
    save() {
      if (!this.valid) {
        this.$scrollTo('#reasonTypeFormGroup', {
            easing: 'ease-in',
            offset: -150,
            force: true,
            cancelable: true,
            x: false,
            y: true
        })
      }
      else {
        const startTime = this.startTime ? this.startTime : '00:00'
        const endTime = this.endTime ? this.endTime : '00:00'

        return this.$store.dispatch('core/sctClosures/saveClosure', {
          schoolLeasArr: this.selectedSchools.map(s => s.code).join(';'), 
          gradeLevelsArr: this.grades.map(s => s.code).join(';'), 
          closureType: this.reasonType.code,
          districtPhone: this.districtPhone,
          superintendentComments: this.superintendentComments,
          id: this.id,
          numberStudentsAffected: this.numberStudentsAffected,
          numberStaffAffected: this.numberStaffAffected,
          adeExternalComments: this.adeExternalComments,
          adeInternalComments: this.adeInternalComments,
          districtLea: this.district.number,
          districtName: this.district.name,
          superintendentName: `${this.district.adminFirstName} ${this.district.adminLastName}`,
          superintendentEmail: this.district.adminEmailAddress,
          entireDistrict: this.selectedSchools.map(s => s.code).includes('ALL'),
          startDate: new moment(this.startDate + ' ' + startTime, 'YYYY-MM-DD HH:mm').toISOString(),
          endDate: new moment(this.endDate + ' ' + endTime, 'YYYY-MM-DD HH:mm').toISOString(),
          isAMIDay: this.isAMIDay,
          willBeMadeUp: this.willBeMadeUp
        })
        .then(() => {
          if (this.isAdminMode)
            this.$router.push({ name: 'sct-admin-closure-list' })
          else
            this.$router.push({ name: 'sct-closure-list', params: { districtLea: this.district.number }})
        }).catch(error => {
          this.errorMessage = (error || { response: { data: '' } }).response.data
          window.scrollTo(0,0)
        })
      }
    },
    formatPhone(value) {
      if (!value) return value
      const numbers = value.replace(/\D/g, '')
      const numberLength = numbers.length
      if (numberLength < 1) return ''
      if (numberLength < 4) return numbers
      if (numberLength < 7) return `(${numbers.slice(0, 3)}) ${numbers.slice(3)}`
      if (numberLength < 11) return `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(6, 10)}`
      return `(${numbers.slice(0, 3)}) ${numbers.slice(3, 6)}-${numbers.slice(6, 10)} x${numbers.slice(10, 15)}`
    }
  },
  props: {
    districtLea: String,
    id: String
  },
  watch: {
    district: {
      immediate: true,
      handler(newDistrict) {
        if (this.coopDirector && !this.leaDictionary[this.coopDirector].ownedLeas.some(d => d.number == this.districtLea)) {
          this.$router.push({ name: 'sct-closure-list' })
        }
        else {
          if (this.id) this.$store.dispatch('core/sctClosures/getDistrictClosure', {
            id: this.id
          })
          .then(() => {
            this.reasonType = this.reasonOptions.find(co => co.code == this.currentClosure.closureType)
            this.selectedSchools = (this.currentClosure.schoolLeas || []).map(s => this.schools.find(sc => sc.code == s))
            this.grades = (this.currentClosure.gradeLevels || []).map(s => this.gradeOptions.find(go => go.code == s))
            this.intendedClosureDuration = this.currentClosure.intendedClosureLength
            this.districtPhone = this.currentClosure.districtPhone.length > 0 ? this.currentClosure.districtPhone : newDistrict.adminPhoneNumber
            this.superintendentComments = this.currentClosure.superintendentComments
            this.adeExternalComments = this.currentClosure.adeExternalComments
            this.adeInternalComments = this.currentClosure.adeInternalComments
            this.numberStudentsAffected = this.currentClosure.numberStudentsAffected,
            this.numberStaffAffected = this.currentClosure.numberStaffAffected,
            this.isAMIDay = this.currentClosure.isAMIDay,
            this.willBeMadeUp = this.currentClosure.willBeMadeUp
            this.startDate = new moment(this.currentClosure.startDate + 'Z').format('YYYY-MM-DD')
            this.startTime = new moment(this.currentClosure.startDate + 'Z').format('HH:mm')
            this.endDate = new moment(this.currentClosure.endDate + 'Z').format('YYYY-MM-DD')
            this.endTime = new moment(this.currentClosure.endDate + 'Z').format('HH:mm')

            if (this.startTime == '00:00') this.startTime = null
            if (this.endTime == '00:00') this.endTime = null
          }) 
          else {
            this.phoneNum = newDistrict.adminPhoneNumber
          }       
        }
      }
    },
  }
}
</script>

<style>
  .col-form-label {
    font-weight: bold;
  }
</style>
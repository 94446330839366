import Vue from 'vue'
import {
    ChartMixin
} from '../../ChartMixin'
import {
    credits
} from '../../../helpers/credits'

const NbctPieChart = Vue.extend({
    name: 'nbct-pie-chart',
    data() {
        return {
            animationDuration: 1000,
            chartOptions: {
                chart: {
                    type: 'pie'
                },
                credits: credits.SISandAELS,
                title: {
                    text: `Number of NBCT Certified vs. Not NBCT Certified Teachers (${this.system})`
                },
                legend: {
                    enabled: true
                },
                series: [{
                    name: 'NBCT Status',
                    data: [{
                            name: 'Certified',
                            y: 1,
                            color: '#28a745'
                        },
                        {
                            name: 'Not Certified',
                            y: 2,
                            color: '#878787'
                        }
                    ]
                }]
            }
        }
    },
    /* html */
    template: `
        <loadable-chart-card card-style='' v-bind:load-data="loadData" :enableDataToggle='false'>
            <highcharts class="chart" :options="chartOptions" :callback="chartCallback"></highcharts>            
        </loadable-chart-card>`,
    mixins: [ChartMixin],
    methods: {
        chartCallback(chart) {
            chart.renderer.label('Certified Count: ' + this.nbcCount ,100, 100).add();
            chart.renderer.label('Certified %: ' + this.nbcPercentage,100, 120).add();
            if(this.nbctConfirms && this.nbctConfirms.length == 1){
                chart.renderer.label(`<div class='${this.nbctConfirms[0].isConfirmed ? '': 'text-secondary'}'><span class='fa fa-check-circle ${this.nbctConfirms[0].isConfirmed ? 'text-success': 'text-secondary'}'></span> District Confirms NBCT Data</div>`, 30,chart.chartHeight - 30,null,null,null,true).add();
            }
        }
    },
    watch: {
        totalTeachers(newData) {
            console.log(newData);
            this.chartOptions.series[0].data[0].y = this.nbcCount
            this.chartOptions.series[0].data[1].y = this.totalTeachers - this.nbcCount
        },
        selectedLea() {
            this.chartOptions.series[0].name = this.selectedLea.name
        }
    },
    props: {
        system: String,
        nbcCount: Number,
        nbcPercentage: Number,
        totalTeachers: Number,
        nbctConfirms: Array
    }
})

export {
    NbctPieChart
}
<template>
  <button class="btn btn-link" @click="scrollTop">
    <i class="fas fa-chevron-up" /> Back to Top
  </button>
</template>

<script>
export default {
  methods: {
    scrollTop() {
      document.documentElement.scrollTop = 0
    }
  }
}
</script>
import { LeaTypes } from '@/helpers/leaTypes'
import sumBy from 'lodash.sumby'
import groupBy from 'lodash.groupby'

export function getInstructionOptionSheets() {
  var items = []
  var totals = {}
  var rows = {}

  this.instructionalOptions.forEach(row => {
    var choice = row.instructionalChoice || "0"
    rows[row.lea] = { ...rows[row.lea] }   
    if (!rows[row.lea][choice]) rows[row.lea][choice] = 0
    if (!rows[row.lea]['ALL']) rows[row.lea]['ALL'] = 0
    rows[row.lea][choice] += row.studentCount
    rows[row.lea]['ALL'] += row.studentCount
  })

  switch (this.selectedLea.type)
  {
    case LeaTypes.State: 
      items = Object.keys(rows).sort().map(k => {
        var district = this.leaDictionary[k]?.parentLea
        var coop = district ? this.leaDictionary[district.number]?.parentLea : {}
        return {
          'CoOp LEA': coop?.number || '',
          'CoOp Name': coop?.name || 'Unknown CoOp',
          'District LEA': district?.number || '',
          'District Name': district?.name || 'Unknown District',
          'School LEA': k,
          'School Name': this.leaName(k),
          'Onsite': rows[k]["1"] || 0,
          'Virtual': rows[k]["2"] || 0,
          'Hybrid': rows[k]["3"] || 0,
          'N/A': rows[k]["4"] || 0,
          'No value': rows[k]["0"] || 0,
          'Total': rows[k]['ALL'] || 0
        }
      })
      totals = {
        'CoOp LEA': '',
        'CoOp Name': '',
        'District LEA': '',
        'District Name': '',
        'School LEA': '',
        'School Name': 'State of Arkansas TOTAL',
        'Onsite': sumBy(items, 'Onsite'),
        'Virtual': sumBy(items, 'Virtual'),
        'Hybrid': sumBy(items, 'Hybrid'),
        'N/A': sumBy(items, 'N/A'),
        'No value': sumBy(items, 'No value'),
        'Total': sumBy(items, 'Total')
      }
      var districts = groupBy(items, (i) => { return i['District LEA'] })
      var itemsDistrict = Object.keys(districts).sort((a,b) => a-b).map(districtLEA => {
        var districtRows = districts[districtLEA]
        var district = this.leaDictionary[districtLEA]
        var coop = district ? district.parentLea : {}
        return {
          'CoOp LEA': coop?.number || '',
          'CoOp Name': coop?.name || 'Unknown CoOp',
          'District LEA': district?.number || '',
          'District Name': district?.name || 'Unknown District',
          'Onsite': sumBy(districtRows, "Onsite") || 0,
          'Virtual': sumBy(districtRows, "Virtual") || 0,
          'Hybrid': sumBy(districtRows, "Hybrid") || 0,
          'N/A': sumBy(districtRows, "N/A") || 0,
          'No value': sumBy(districtRows, "No value") || 0,
          'Total': sumBy(districtRows, "Total") || 0,
        }
      })
      var totalsDistrict = {
        'CoOp LEA': '',
        'CoOp Name': '',
        'District LEA': '',
        'District Name': 'State of Arkansas TOTAL',
        'Onsite': sumBy(items, 'Onsite'),
        'Virtual': sumBy(items, 'Virtual'),
        'Hybrid': sumBy(items, 'Hybrid'),
        'N/A': sumBy(items, 'N/A'),
        'No value': sumBy(items, 'No value'),
        'Total': sumBy(items, 'Total')
      }
      var coops = groupBy(items, (i) => { return i['CoOp LEA'] })
      var itemsCoop = Object.keys(coops).sort((a,b) => a-b).map(coopLEA => {
        var coopRows = coops[coopLEA]
        var coop = this.leaDictionary[coopLEA]
        return {
          'CoOp LEA': coop?.number || '',
          'CoOp Name': coop?.name || 'Unknown CoOp',
          'Onsite': sumBy(coopRows, "Onsite") || 0,
          'Virtual': sumBy(coopRows, "Virtual") || 0,
          'Hybrid': sumBy(coopRows, "Hybrid") || 0,
          'N/A': sumBy(coopRows, "N/A") || 0,
          'No value': sumBy(coopRows, "No value") || 0,
          'Total': sumBy(coopRows, "Total") || 0,
        }
      })
      var totalsCoop = {
        'CoOp LEA': '',
        'CoOp Name': 'State of Arkansas TOTAL',
        'Onsite': sumBy(items, 'Onsite'),
        'Virtual': sumBy(items, 'Virtual'),
        'Hybrid': sumBy(items, 'Hybrid'),
        'N/A': sumBy(items, 'N/A'),
        'No value': sumBy(items, 'No value'),
        'Total': sumBy(items, 'Total')
      }
      return [
        { name: 'Instruction Option School', items, totals },
        { name: 'Instruction Option District', items: itemsDistrict, totals: totalsDistrict },
        { name: 'Instruction Option CoOp', items: itemsCoop, totals: totalsCoop }
      ]
    case LeaTypes.Coop:
      items = Object.keys(rows).sort().map(k => {
        var district = this.leaDictionary[k]?.parentLea
        return {
          'District LEA': district?.number || '',
          'District Name': district?.name || '',
          'School LEA': k,
          'School Name': this.leaName(k),
          'Onsite': rows[k]["1"] || 0,
          'Virtual': rows[k]["2"] || 0,
          'Hybrid': rows[k]["3"] || 0,
          'N/A': rows[k]["4"] || 0,
          'No value': rows[k]["0"] || 0,
          'Total': rows[k]['ALL'] || 0
        }
      })
      totals = {
        'District LEA': '',
        'District Name': '',
        'School LEA': '',
        'School Name': `${this.selectedLea.name} TOTAL`,
        'Onsite': sumBy(items, 'Onsite'),
        'Virtual': sumBy(items, 'Virtual'),
        'Hybrid': sumBy(items, 'Hybrid'),
        'N/A': sumBy(items, 'N/A'),
        'No value': sumBy(items, 'No value'),
        'Total': sumBy(items, 'Total')
      }
      districts = groupBy(items, (i) => { return i['District LEA'] })
      itemsDistrict = Object.keys(districts).sort((a,b) => a-b).map(districtLEA => {
        var districtRows = districts[districtLEA]
        var district = this.leaDictionary[districtLEA]
        var coop = district ? district.parentLea : {}
        return {
          'CoOp LEA': coop?.number || '',
          'CoOp Name': coop?.name || 'Unknown CoOp',
          'District LEA': district?.number || '',
          'District Name': district?.name || 'Unknown District',
          'Onsite': sumBy(districtRows, "Onsite") || 0,
          'Virtual': sumBy(districtRows, "Virtual") || 0,
          'Hybrid': sumBy(districtRows, "Hybrid") || 0,
          'N/A': sumBy(districtRows, "N/A") || 0,
          'No value': sumBy(districtRows, "No value") || 0,
          'Total': sumBy(districtRows, "Total") || 0,
        }
      })
      totalsDistrict = {
        'CoOp LEA': '',
        'CoOp Name': '',
        'District LEA': '',
        'District Name': `${this.selectedLea.name} TOTAL`,
        'Onsite': sumBy(items, 'Onsite'),
        'Virtual': sumBy(items, 'Virtual'),
        'Hybrid': sumBy(items, 'Hybrid'),
        'N/A': sumBy(items, 'N/A'),
        'No value': sumBy(items, 'No value'),
        'Total': sumBy(items, 'Total')
      }
      return [
        { name: 'Instruction Option School', items, totals },
        { name: 'Instruction Option District', items: itemsDistrict, totals: totalsDistrict },
      ]
    case LeaTypes.District:
      items = Object.keys(rows).sort().map(k => {
        return {
          'School LEA': k,
          'School Name': this.leaName(k),
          'Onsite': rows[k]["1"] || 0,
          'Virtual': rows[k]["2"] || 0,
          'Hybrid': rows[k]["3"] || 0,
          'N/A': rows[k]["4"] || 0,
          'No value': rows[k]["0"] || 0,
          'Total': rows[k]['ALL'] || 0
        }
      })
      totals = {
        'School LEA': '',
        'School Name': `${this.selectedLea.name} TOTAL`,
        'Onsite': sumBy(items, 'Onsite'),
        'Virtual': sumBy(items, 'Virtual'),
        'Hybrid': sumBy(items, 'Hybrid'),
        'N/A': sumBy(items, 'N/A'),
        'No value': sumBy(items, 'No value'),
        'Total': sumBy(items, 'Total')
      }
      return [{ name: 'Instruction Option School', items, totals }]
    case LeaTypes.School:
      items = Object.keys(rows).sort().map(k => {
        return {
          'School LEA': k,
          'School Name': this.leaName(k),
          'Onsite': rows[k]["1"] || 0,
          'Virtual': rows[k]["2"] || 0,
          'Hybrid': rows[k]["3"] || 0,
          'N/A': rows[k]["4"] || 0,
          'No value': rows[k]["0"] || 0,
          'Total': rows[k]['ALL'] || 0
        }
      })
      return [{ name: 'Instruction Option School', items }]      
  }
  return []
}
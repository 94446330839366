import Vue from 'vue'
import { TableMixin } from '../../TableMixin'
import CustomTooltip from '../../CustomTooltip.vue'

const CrossLinkedCaseIds = Vue.extend({
    name: 'cross-linked-case-ids',
    /* html */
    template: `
    <div class="chart">
        <table-control-widget :per-page='tableOptions.perPage' v-on:filter-changed='tableOptions.filter=$event' v-on:page-length-changed='tableOptions.perPage=$event'>
        </table-control-widget>
        <b-table 
        :fields='tableOptions.fields' 
        :per-page='tableOptions.perPage' 
        :items='crossLinkedCaseIds'
        v-on:filtered='onFiltered'
        :current-page='tableOptions.currentPage' :filter='tableOptions.filter' :busy='tableOptions.isLoading'>
            <template v-slot:cell(lea)='data'>
                <a href='#' v-on:click='goToLea(data.value)' v-bind:id="data.index + '-' + data.value">{{ data.value }}</a>
                <lea-tooltip v-bind:target-selector="data.index + '-' + data.value"
                    v-bind:lea-number='data.value'>
                </lea-tooltip>
            </template>
            <template v-slot:cell(classSectionCaseId)="data">
              <aels-case-id-link :case-id="data.value.toString()" />
            </template>
            <template v-slot:cell(linkedApplicantId)="data">
              <aels-case-id-link :case-id="data.value.toString()" />
            </template>
            <template v-slot:cell(aelsApplicantIdBySSN)="data">
              <aels-case-id-link :case-id="data.value.toString()" />
            </template>
            <template v-slot:head(classSectionCaseId)="data">
                <custom-tooltip content="The Case Id associated with the class section in eSchool" element-id="caseIdCrosslinkeSchoolTooltip" :label="data.label" />
            </template>
            <template v-slot:head(aelsApplicantIdBySSN)="data">
                <custom-tooltip content="The Case Id for the AELS record found by cross-referencing AELS and eFinance by SSN" element-id="caseIdCrosslinkAELSTooltip" :label="data.label" />
            </template>
            <template v-slot:head(linkedApplicantId)="data">
                <custom-tooltip content="The Case Id in AELS that matches the Case Id associated with the class section in eSchool" element-id="caseIdCrosslinkLinkedCaseIdTooltip" :label="data.label" />
            </template>
            <div slot='table-busy' class='text-center text-danger my-2'>
                <b-spinner class='align-middle'></b-spinner>
                <strong>Loading...</strong>
            </div>
        </b-table>
        <table-pagination-widget :total-rows-count='totalRowCount' :filtered-rows-count='tableOptions.filteredRowCount' 
        :page-length='tableOptions.perPage' v-on:page-changed='tableOptions.currentPage=$event'></table-pagination-widget>
    </div>`,
    data() {
        return {
            tableOptions: {
                currentPage: 1,
                perPage: this.$printMode ? this.MAX_SAFE_INTEGER : 50,
                filter: null,
                fields: [
                    {
                        key: 'lea',
                        label: 'LEA',
                        sortable: true,
                        class: 'col-bg'
                    },
                    {
                        key: 'eSchoolName',
                        label: 'eSchool Teacher Name',
                        sortable: true,
                        class: 'col-bg'
                    },
                    {
                        key: 'ssn',
                        label: 'SSN',
                        sortable: true,
                        class: 'col-bg'
                    },
                    {
                        key: 'courseNumber',
                        label: 'Course Number',
                        sortable: true,
                        class: 'col-bg'
                    },
                    {
                        key: 'courseSection',
                        label: 'Section Number',
                        sortable: true,
                        class: 'col-bg'
                    },
                    {
                        key: 'markingPeriod',
                        label: 'Marking Period',
                        sortable: true,
                        class: 'col-bg'
                    },
                    {
                        key: 'classSectionCaseId',
                        label: 'Class Case Id',
                        sortable: true,
                        class: 'col-bg'
                    },
                    {
                        key: 'licenseException',
                        label: 'License Exception',
                        sortable: true,
                        class: 'col-bg'
                    },
                    {
                        key: 'aelsApplicantIdBySSN',
                        label: 'AELS Case Id by SSN',
                        sortable: true,
                        class: 'col-bg alt'
                    },
                    {
                        key: 'aelsName',
                        label: 'AELS Name',
                        sortable: true,
                        class: 'col-bg alt'
                    },
                    {
                        key: 'linkedApplicantId',
                        label: 'Linked Case Id',
                        sortable: true,
                        class: 'col-bg alt'
                    },
                    {
                        key: 'linkedApplicantName',
                        label: 'Linked Applicant Name',
                        sortable: true,
                        class: 'col-bg alt'
                    }
                ],
                filteredRowCount: null,
                isLoading: false
            }
        }
    },
    mixins: [TableMixin],
    components:{
        CustomTooltip
    },
    computed: {
        crossLinkedCaseIds() {
            return this.$store.state.hcptModule.dataIntegrity.caseIdCrosslink
        },
        totalRowCount(){
            return this.$store.state.hcptModule.dataIntegrity.caseIdCrosslink.length
        }
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.tableOptions.filteredRowCount = filteredItems.length
            this.currentPage = 1
        },
        goToLea(leaNumber) {
            const lea = this.$store.getters['globalModule/leaDictionary'][leaNumber]
            window.scrollTo(0,0)
            this.$store.dispatch('globalModule/changeSelectedLea', lea)
        },
        excelExportData() {
            return {
                name: 'Crosslinked Case Ids',
                items: this.$store.state.hcptModule.dataIntegrity.caseIdCrosslink.map(item => {
                    return {
                        DistrictLEA: item.districtLEA,
                        LEA: item.lea,
                        SSN: item.ssn,
                        eSchoolName: item.eSchoolName,
                        CourseNumber: item.courseNumber,
                        SectionNumber: item.courseSection,
                        ClassCaseId: item.classSectionCaseId,
                        LicenseException: item.licenseException,
                        AELSApplicantID: item.aelsApplicantIdBySSN,
                        AELSName: item.aelsName,
                        LinkedApplicantId: item.linkedApplicantId || 'Not Found',
                        LinkedApplicantName: item.linkedApplicantName || 'Not Found'
                    }
                })
            }
        }
    },
    props: {
        selectedLea: Object
    }
})
export { CrossLinkedCaseIds }
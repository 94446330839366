<template>
  <div>
    <div class="alert alert-warning text-center m-4" v-if="!detailsVisible">
      Staff Details are only available in the context of a District or School
    </div>
    <template v-if="detailsVisible">
      <b-form inline class="mb-3 d-print-none">
        <label class="ml-3 mr-sm-2">Race: </label>
        <b-form-select v-model="raceSelect" :options="raceOptions" value-field="field" text-field="name" size="sm" class="mr-sm-3 mt-sm-1" />
        <label class="m-3 mr-sm-2">Category: </label>
        <b-form-select v-model="staffTypeSelect" :options="staffTypeOptions" size="sm" class="mr-sm-3 mt-sm-1" />
        <label class="mr-sm-2">Experience Level (Teachers only): </label>
        <b-form-select v-model="experienceSelect" :options="experienceOptions" size="sm" class="mr-sm-3 mt-sm-1" :disabled="experienceDisabled" />
        <label class="mr-sm-2">Job Code: </label>
        <b-form-select v-model="jobCodeSelect" :options="jobCodeOptions" size="sm" class="mr-sm-3 mt-sm-1" />
      </b-form>

      <Promised :promise="staffDetailLoaded">
        <template v-slot:pending>
          <div class="align-items-center justify-content-center loading-card" style="height: 100%; width: 100%">
            <card-state-icon icon-class="fa-4x fas fa-spinner fa-spin text-secondary" message="Loading..." />
          </div>
        </template>
        <template v-slot="">
          <slot>
            <staff-details-table :staff-details="details" :race-filter="raceSelect" :staff-filter="staffTypeSelect" :experience-filter="experienceSelect" :job-code-filter="jobCodeSelect" />
          </slot>
        </template>
        <template v-slot:rejected="error" class="align-items-center justify-content-center">
          <card-state-icon icon-class="fa-4x fas fa-exclamation-triangle text-danger" :message="'Error: ' + error.message" />
        </template>
      </Promised>
    </template>
  </div>
</template>

<script>
import CardStateIcon from '../../CardStateIcon.vue'
import { Promised } from 'vue-promised'
// import { StaffDetailsTable } from './StaffDetailsTable'
import StaffDetailsTable from './StaffDetailsTable.vue'

import { Subpopulations } from '../../subpopulations'

export default {
    data() {
      var raceOpts = Subpopulations
      return {
        raceSelect: null,
        raceOptions: raceOpts,
        staffTypeSelect: null,
        staffTypeOptions: [ 
          { text: "(All Staff Types)", value: null},
          { text: "Certified - Teaching Staff", value: "certified-teaching"},
          { text: "Certified - Non-Teaching Staff", value: "certified"},
          { text: "Classified Staff", value: "classified"},
        ],
        experienceSelect: null,
        experienceOptions: [
          { text: "(Novice and Experienced)", value: null},
          { text: "Novice Teachers", value: "novice"},
          { text: "Experienced Teachers", value: "experienced"},
        ],
        jobCodeSelect: null
      }
    },

    components: {
      StaffDetailsTable,
      Promised,
      CardStateIcon
    },
    props: {
      details: Array,
      detailsVisible: Boolean,
      staffDetailLoaded: Promise
    },
    computed: {
      experienceDisabled() {
        return (!this.staffTypeSelect) || this.staffTypeSelect.indexOf("teach") < 0;
      },
      jobCodeOptions(){
        var optionsArray = [{ value: null, text: "All Job Codes"}, { value: -1, text: 'Not Specified' }]          

        return optionsArray.concat(
          this.details.filter(d => d.jobCode)
            .map(({jobCode,jobTitle}) => ({ value: parseInt(jobCode), text: `${jobCode} - ${jobTitle}` }) )
            .sort((a,b) => a.value-b.value))
            .filter((obj, pos, arr) => {
              return arr.map(mapObj => mapObj['value']).indexOf(obj['value']) === pos
            })
      }
    },
    watch: {
      staffTypeSelect() {
        this.experienceSelect = null
      }
    }
  }
</script>
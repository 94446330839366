import removeNamespace from './removeNamespace'
import ApiService from '../services/ApiService'

export const Types = {
  path: 'financeModule',
  state: {
    overview: 'overview',
    expenditures: 'expenditures',
    generalLedger: 'generalLedger',
    generalLedgerRevenues: 'generalLedgerRevenues',
    generalLedgerExpenditures: 'generalLedgerExpenditures',
    generalLedgerExpendituresCount: 'generalLedgerExpendituresCount',
    fundsAndAccounts: 'fundsAndAccounts'
  },
  getters: {
    fiscalYear: 'fiscalYear',
    asrFiscalYear: 'asrFiscalYear',
    asrTotals: 'asrTotals',
    asrTrendRows: 'asrTrendRows',
    funds: 'funds',
    accounts: 'accounts',
    schoolFinancesfiscalYear: 'schoolFinancesfiscalYear'
  },
  mutations: {
    setOverview: 'financeModule/setOverview',
    setExpenditures: 'financeModule/setExpenditures',
    setGeneralLedger: 'financeModule/setGeneralLedger',
    setGeneralLedgerRevenues: 'financeModule/setGeneralLedgerRevenues',
    setGeneralLedgerExpenditures: 'financeModule/generalLedgerExpenditures',
    setFundsAndAccounts: 'financeModule/setFundsAndAccounts'
  },
  actions: {
    getOverview: 'financeModule/getOverview',
    getExpenditures: 'financeModule/getExpenditures',
    getGeneralLedger: 'financeModule/getGeneralLedger',
    getGeneralLedgerRevenues: 'financeModule/getGeneralLedgerRevenues',
    getGeneralLedgerExpenditures: 'financeModule/getGeneralLedgerExpenditures',
    getFundsAndAccounts: 'financeModule/getFundsAndAccounts'
  }
}
const _types = removeNamespace('financeModule/', Types)

export class FinanceModule {
  constructor() {
    this._apiService = new ApiService()
    this._configElement = document.getElementById('application-config')
    this._baseUrl = this._configElement == null ? '/' : this._configElement.getAttribute('data-base-url') || ''
    this._fiscalYear = parseInt(this._configElement.getAttribute('data-finance-asr-fiscal-year'))
  }

  namespaced = true

  state = {
    [Types.state.overview]: [],
    [Types.state.expenditures]: [],
    [Types.state.generalLedger]: [],
    [Types.state.generalLedgerExpenditures]: [],
    [Types.state.generalLedgerExpendituresCount]: 0,
    [Types.state.generalLedgerRevenues]: [],
    [Types.state.fundsAndAccounts]: {}
  }

  getters = {
    [Types.getters.fiscalYear]: () => this._fiscalYear,
    [Types.getters.asrFiscalYear]: (state) => Math.max(...(state.overview || [30]).map(f => f.fiscalYear)),
    [Types.getters.schoolFinancesfiscalYear]: (state) => Math.max(...state.expenditures.flatMap(lea => lea.fiscalYearRecords.map(f => f.fiscalYear))),
    [Types.getters.asrTrendRows]: (state) => state.overview
      .filter(f => f.metadata.some(m => m.dataGroupName == 'ASR Actual Totals'))
      .map(f => {
        return {
          name: f.metadata.find(m => m.dataGroupName == 'ASR Actual Totals').label,
          y: f.value,
          budgetValue: f.budgetValue,
          fiscalYear: f.fiscalYear
        }
      }),
    [Types.getters.asrTotals]: (_state, getters) => getters.asrTrendRows.filter(f => f.fiscalYear == getters.asrFiscalYear),
    [Types.getters.funds]: (state) => [{ value: null, text: 'All Funds' }]
      .concat((state.fundsAndAccounts.funds || []) || []),
    [Types.getters.accounts]: (state) => [{ value: null, text: 'All Accounts' }]
      .concat((state.fundsAndAccounts.accounts || []) || [])
  }

  mutations = {
    [_types.mutations.setOverview]: (state, overview) => state.overview = overview,
    [_types.mutations.setExpenditures]: (state, expenditures) => state.expenditures = expenditures,
    [_types.mutations.setGeneralLedger]: (state, generalLedger) => state.generalLedger = generalLedger,
    [_types.mutations.setGeneralLedgerExpenditures]: (state, expenditures) => { state.generalLedgerExpendituresCount = expenditures.count, state.generalLedgerExpenditures = expenditures.data},
    [_types.mutations.setGeneralLedgerRevenues]: (state, revenues) => state.generalLedgerRevenues = revenues,
    [_types.mutations.setFundsAndAccounts]: (state, fundsAndAccounts) => state.fundsAndAccounts = fundsAndAccounts,
  }

  actions = {
    [_types.actions.getOverview]: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/finance/overview/${payload.lea}/${this._fiscalYear}`)
        .then(data => commit(_types.mutations.setOverview, data))
    },
    [_types.actions.getExpenditures]: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/finance/schoolFinances/${payload.lea}/${this._fiscalYear}`)
        .then(data => commit(_types.mutations.setExpenditures, data))
    },
    [_types.actions.getGeneralLedger]: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/finance/generalLedger/${payload.lea}/${this._fiscalYear}`)
        .then(data => commit(_types.mutations.setGeneralLedger, data))
    },
    [_types.actions.getGeneralLedgerExpenditures]: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/finance/expenditures/${payload.lea}/${this._fiscalYear}?pageSize=${payload.pageSize}&page=${payload.page}`)
        .then(data => commit(_types.mutations.setGeneralLedgerExpenditures, data))
    },
    [_types.actions.getGeneralLedgerRevenues]: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/finance/revenues/${payload.lea}/${this._fiscalYear}`)
        .then(data => commit(_types.mutations.setGeneralLedgerRevenues, data))
    },
    [_types.actions.getFundsAndAccounts]: ({ commit }, payload) => {
      return this._apiService.Get(`${this._baseUrl}api/finance/fundsAccounts/${payload.lea}/${this._fiscalYear}`)
        .then(data => commit(_types.mutations.setFundsAndAccounts, data))
    }
  }
}
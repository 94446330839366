<template>
  <div class="d-print-none">
    <div class="mb-2 card" v-if="request">
      <div class="card-header bg-info">
        Comment
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 p-3">
            <b-form-group label="Comment:">
              <b-form-textarea v-model="comment" placeholder="Please provide a comment..." style="min-width: 20rem;" />
            </b-form-group>
            <animated-button button-class="btn btn-sm btn-primary" :promise-resolver="addComment" :disabled="!comment">
              <i class="fas fa-user" />&nbsp; Add Comment
            </animated-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Types, StateMap } from '../../stores/'
import AnimatedButton from '@/components/AnimatedButton'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      currentState: null,
      comment: null,
      StateMap
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    ...mapState(Types.path, [Types.state.availableStates]),
  },
  props: {
    request: Object
  },
  watch: {
    // request: {
    //   immediate: true,
    //   handler(newRequest) {
    //   }
    // }
  },
  methods: {
    addComment() {
      const self = this
      return this.$store.dispatch(Types.actions.assignOrComment, {
        id: this.request.leaPlanId,
        comment: this.comment
      }).then(() => {
        self.comment = null
      })
    },
  },
  components: {
    AnimatedButton,
  }
}
</script>

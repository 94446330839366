<template>
  <loadable-chart-card :load-data="loadData" :enable-data-toggle="false" :initial-chart-mode="false" card-style="">
    <template v-slot:default="slotProps">
      <div v-show="slotProps.chartMode" />
      <div v-show="!slotProps.chartMode">
        <paginated-table :items="filteredItems" :fields="tableOptions.fields" sort-by="districtName" :sort-desc="false">
          <template v-slot:cell(processStatus)="data">
            <span class="badge" :class="ProcessStatuses[data.value].badgeClass">{{ ProcessStatuses[data.value].name }}</span>
          </template>
          <template v-slot:cell(hangfireJobId)="data">
            <a href="#" @click="viewLogs(data.item)">
              {{ data.value }}
            </a>
          </template>
        </paginated-table>        
        <b-modal id="modal" size="lg" :title="(selectedJob.job || {}).jobName">
          <h4>Logs:</h4>
          <div class="card card-body bg-light">
            <pre>
              <span v-for="log in selectedJob.jobLogs" :key="JSON.stringify(log)">
                {{ log.text }}
              </span>
            </pre>
          </div>
        </b-modal>
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import PaginatedTable from '../../PaginatedTable'
import { mapState } from 'vuex'
import moment from 'moment'
import { ProcessStatuses } from './extractEnums'

export default {
  data(){
    return {
      ProcessStatuses: ProcessStatuses,
      tableOptions:{
        perPage: 100,
        fields:[
          {
            key: 'lea',
            label: 'LEA',
            sortable: true
          },
          {
            key: 'districtName',
            label: 'District Name',
            sortable: true
          },
          {
            key: 'processStatus',
            label: 'Status',
            sortable: true
          },
          {
            key: 'hangfireJobId',
            label: 'Hangfire Job Id',
            sortable: true
          },
          {
            key: 'batchId',
            label: 'Batch Id',
            sortable: true
          },
          {
            key: 'startTime',
            label: 'Start Time',
            sortable: true,            
            formatter: value => {
              return moment(value).format('llll')
            }
          }
        ]     
      }
    }
  },
  computed:{
    ...mapState('adminModule', ['recentBatchProcesses', 'selectedJob']),
    ...mapState('globalModule', ['leaContext']),
    districts() {
      return this.recentBatchProcesses
    },
    //fields() { return Object.keys(this.districts[0]).map((item) => { return { key: item, sortable: true, thClass: "nowrap" }}) || [] },     
    filteredItems(){
        var filteredDistricts = this.districts
        if(this.selectedStatus != null){
          filteredDistricts = filteredDistricts.filter(d => d.processStatus == this.selectedStatus)
        }
        return filteredDistricts.map(d => {
          return {
          lea: d.lea,
          districtName: this.leaContext.LeaDictionary[d.lea]?.name || "Unknown",
          processStatus: d.processStatus,
          hangfireJobId: d.hangfireJobId,
          batchId: d.batchId,
          startTime: d.startTime          
          }
        })
    },
  },
  methods: {
    viewLogs(job) {
      this.$store.dispatch('adminModule/getJobLogs', {jobId: job.hangfireJobId})
      this.$bvModal.show('modal')
    }
  },
  mixins: [ChartMixin],
  components:{
    PaginatedTable
  },
  props:{
    loadData: Promise,
    selectedStatus: Number
  }
}
</script>
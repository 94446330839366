<template>
  <loadable-chart-card :load-data="loadData">
    <template v-slot:default="slotProps">
      <div class="chartElem" v-show="slotProps.chartMode">
        <highcharts class="chart" :options="chartOptions" />
      </div>
      <div v-show="!slotProps.chartMode">
        <chart-data-table :items="dataItems()" />
      </div>
    </template>
  </loadable-chart-card>
</template>

<script>
import { ChartMixin } from '../../ChartMixin'
import { MealStatuses } from './MealStatus'
import { EnrollmentChartMixin, defaultOptionMatcher } from './EnrollmentChartMixin'
import sumBy from 'lodash.sumby'
import Highcharts from 'highcharts'
import { credits } from '../../../helpers/credits'

export default {
  data() {
    return {
     animationDuration: 1000,
      chartOptions: {
        chart: {
          type: 'pie'
        },
        title: {
          text: 'Enrollment By Meal Status'
        },          
        legend: {
          enabled: true
        },
        credits: credits.InsightsNightlyDataPulls,
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: true,
              formatter: function() {
                if (this.y > 0) {
                  return `${this.point.name}: ${this.point.y} (${Highcharts.numberFormat(this.point.percentage, 1)}%)`
                }
              }
            },
            
            showInLegend: true
          },
          column: {
            minPointLength: 3
          },
          series: {
            stacking: 'normal'
          }
        },
        xAxis: {
          labels: {
            enabled: false
          }
        }
      }
    }
  },
  mixins: [ChartMixin, EnrollmentChartMixin],
  watch: {
    enrollmentCounts: {
      immediate: true,
      handler(newData) {
        if (newData && newData.byMealStatus && this.mode == 'current') {
          this.chartOptions.series = []
          this.chartOptions.xAxis.categories = MealStatuses.map(m => m.name)

          this.chartOptions.series = [{
            data: MealStatuses.map(option => {
              return {
                name: option.name,
                y: sumBy(newData.byMealStatus, defaultOptionMatcher.bind(option))
              }
            }),         
            name: 'Meal Status',
          }]      
        }
      }
    },
    metricSnapshots: {
      immediate: true,
      handler(newData) {
        if (newData && newData.byMealStatus && this.mode == 'trend') {
          this.chartOptions.chart = { type: 'column' }
          this.setTrendedSeries(newData.byMealStatus, MealStatuses)
        }
      }
    }
  },
  methods: {
    dataItems() {
      if (this.mode == 'current') 
        return this.pivotCurrent(this.enrollmentCounts.byMealStatus, MealStatuses)
      else
        return this.pivotTrended(this.metricSnapshots.byMealStatus, MealStatuses)
    }
  }
}
</script>
<template>
  <div class="card category-type-card" :class="{ 'card-fullscreen': editMode }">
    <div :class="'card-header card-header d-flex justify-content-between align-items-center category-type-card-header ' + statusBgClass">
      <span class="title-no-overflow">
        <plan-category-type-icon :type="type.key" /> {{ type.description }} <i class="text-white fa fa-info-circle" :id="typeCategoryKey(type.key, category.id)" />
        <b-tooltip :target="'#' + typeCategoryKey(type.key, category.id)">
          <span v-if="codes && codes.length > 0">
            Object Code(s) <strong>{{ codes }}</strong>
          </span>
          <span v-else>
            All codes except 61000-62999, 66000-66999, 67340-67390, 63000-65999.
          </span>
        </b-tooltip>
      </span>
      <div v-if="type.enabled && canEdit" class="d-print-none">
        <b-button size="sm" @click="editMode = true" v-if="!editMode">
          Edit
        </b-button>
      </div>
      <b-button size="sm" @click="save" variant="success" v-if="editMode && canEdit" :disabled="saving" class="nowrap" :title="incompleteDataWarning">
        <i class="fas fa-fw fa-spinner fa-spin mr-1" v-if="saving" />
        <i class="fas fa-fw fa-save mr-1" v-if="hasRequiredData && !saving" />
        <i class="fas fa-fw fa-exclamation-triangle mr-1 text-warning" v-if="!hasRequiredData && !saving" />
        Save
      </b-button>
      <b-button size="sm" @click="cancel" variant="danger" v-if="editMode && canEdit" :disabled="saving" class="ml-1 nowrap">
        <i class="fas fa-fw fa-ban mr-1" />Cancel
      </b-button>
    </div>
    <div class="card-body bg-light" style="overflow-y: auto">
      <div v-if="expenses" class="categorytype-budget bg-light">
        <div :class="'lighten-bg ' + statusBgClass" />
        <div>
          Budgeted: {{ expenses.budgeted.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}<br>
          Actual: {{ expenses.actual.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}
        </div>
      </div>
      <div v-if="editMode">
        <!-- edit mode -->
        <div v-if="isTransfer">
          <h4>
            Justification:
          </h4> 
          <rich-text-editor v-model="otherText" />
        </div>
        <div v-else>
          <div>
            <h4>
              Plan:
            </h4> 
            <p class="font-weight-light">
              What is the planned use of funds? <i v-if="!isOtherType">(Check all that apply or check other and describe)</i>
            </p>
            <b-form-group class="ml-3" v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                v-if="!isOtherType"
                v-model="reasons"
                :options="items"
                :aria-describedby="ariaDescribedby"
                name="reasons"
                stacked />

              <rich-text-editor v-model="otherText" />
            </b-form-group>
          </div>

          <h4>
            Do:
          </h4>
          <p class="font-weight-light">
            Narrative - What is the expected outcome? <i v-if="!isOtherType">(Check all that apply or check other and describe)</i>
          </p>
          <b-form-group class="ml-3" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              v-if="!isOtherType"
              v-model="expectedOutcomes"
              :options="expectedOutcomeOptions"
              :aria-describedby="ariaDescribedby"
              name="expectedOutcomes"
              stacked />
            <rich-text-editor v-model="narrative" />
          </b-form-group>
          
          <div v-if="showEvaluation" class="mt-2">
            <h4>
              Check:
            </h4>
            <p class="font-weight-light">
              Evaluation - How will success be measured? (Using what metrics and how often?)
            </p>
            <div class="ml-3">
              <rich-text-editor v-model="evaluation" />
            </div>
          </div>
        </div>
      </div>
      <div class="bg-light" v-else>
        <!-- view mode -->
        <div v-if="isTransfer">
          <a id="transfers-section" />
          <h4>
            Justification:
          </h4>
          <div v-html="otherText" class="mx-4" />
        </div>
        <div v-else>
          <h4>
            Plan:
          </h4> 
          <p class="font-weight-light">
            What is the planned use of funds?
          </p>
          <div v-if="(reasons && reasons.length > 0) || isOtherType">
            <ul v-if="!isOtherType">
              <li v-for="reason in reasons" :key="reason">
                {{ reason }}
              </li>
            </ul>
            <div v-html="otherText" class="mx-4" />
          </div>
          <span v-else class="mx-4">None</span>
          
          <div v-if="!(reasons && reasons.length > 0)" class="mt-2" />
          <h4>
            Do:
          </h4>
          <p class="font-weight-light">
            Narrative - What is the expected outcome?
          </p>
          <div v-if="(expectedOutcomes && expectedOutcomes.length > 0) || isOtherType">
            <ul v-if="!isOtherType">
              <li v-for="expectedOutcome in expectedOutcomes" :key="expectedOutcome">
                {{ expectedOutcome }}
              </li>
            </ul>
            <div v-html="narrative" class="mx-4" />
          </div>
          
          <div v-if="showEvaluation" class="mt-2">
            <h4>
              Check:
            </h4>
            <p class="font-weight-light">
              Evaluation - How will success be measured? (Using what metrics and how often?)
            </p>
            <div v-html="evaluation" class="mx-4" />
          </div>
        </div>
      </div>
      <div class="text-muted small border-top d-print-none" :class="{ 'mt-5': editMode }" v-if="updatedBy">
        Last saved on {{ updatedDate }} by {{ updatedBy }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Types } from '@/modules/esa/stores/'
import PlanCategoryTypeIcon from './PlanCategoryTypeIcon.vue'
import { PlanMixin } from './PlanMixin'
import sumBy from 'lodash.sumby'
import RichTextEditor from '@/components/shared/RichTextEditor'
import { expandCodes } from './helpers.js'

export default {
  data() {
    return {
      editMode: false,
      reasons: [],
      otherText: null,
      expectedOutcomes: [],
      narrative: '',
      evaluation: '',
      saving: false
    }
  },
  mixins: [PlanMixin],
  props: {
    category: {
      type: Object,
      required: true
    },
    type: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(Types.path, [Types.state.planResponses, Types.state.planExpenses, Types.state.plan]),
    isTransfer() {
      return this.category.name.includes('Transfer')
    },
    isOtherType() {
      return this.type.key == 5
    },
    showEvaluation() {
      return true//this.plan && this.plan.status >= 10
    },
    items() {
      return (this.type.items || []).concat([ 'Other' ])
    },
    expectedOutcomeOptions() {
      return [
        'Improved Student Achievement',
        'Reduced gaps among subgroups',
        'Improved conditions to support student learning with expected changes in student outcomes (e.g., Behavior, Engagement, Academic, Social and Emotional)',
        'Other',
      ]
    },
    initialResponse() {
      return this.planResponses.find(r => r.categoryId == this.category.id && r.expenditureTypeId == this.type.key) || null
    },
    expenses() {
      let exp = this.planExpenses.filter(e => this.category.programCode.includes(e.program))
      if (this.isOtherType)
        exp = exp.filter(e => !expandCodes(this.category.types.flatMap(t => t.codes)).includes(e.account) || (this.isTransfer && e.account =='69320')) 
      else 
        exp = exp.filter(e => expandCodes(this.type.codes).includes(e.account))

      if (exp && exp.length > 0)
        return { budgeted: sumBy(exp, 'budgeted'), actual: sumBy(exp, 'actual') }
      else return null
    },
    codes() {
      return this.type.codes.join(', ')
    },
    hasRequiredData() {
      return ((this.expectedOutcomes && this.expectedOutcomes.length > 0) || (this.narrative && this.narrative.length > 0) )
        && (this.evaluation && this.evaluation.length > 0)
        && (this.reasons && this.reasons.length > 0) 
    },
    hasData() {
      return (this.reasons && this.reasons.length > 0) 
        || (this.narrative && this.narrative.length > 0) 
        || (this.expectedOutcomes && this.expectedOutcomes.length > 0)
        || (this.evaluation && this.evaluation.length > 0)
    },
    incompleteDataWarning() {
      return !this.hasRequiredData ? 'Please fill out Plan, Do, and Check sections before saving' : ''
    },
    statusBgClass() {
      if (!this.type.enabled)
        return 'bg-warning text-dark'
      else if (this.plan.status >= 10)
        return 'bg-success text-white'
      else if (this.plan.status == 1)
        return 'bg-danger text-white'
      else if (this.hasData)
        return 'bg-primary text-white'

      return 'bg-med text-dark'
    },
    updatedDate() {
      return new Date(this.initialResponse?.updatedDate).toLocaleString()
    },
    updatedBy() {
      return this.initialResponse?.updatedBy
    }
  },
  methods: {
    typeCategoryKey(categoryKey, typeKey) {
      return `${typeKey}${categoryKey}`
    },
    save() {
      this.saving = true
      this.$store.dispatch(Types.actions.savePlanResponse, {
        FiscalYear: this.category.fiscalYear,
        DistrictLEA: this.selectedLea.number,
        CategoryId: this.category.id,
        ExpenditureTypeId: this.type.key,
        Response: JSON.stringify({
          items: this.reasons,
          other: this.otherText,
          expectedOutcomes: this.expectedOutcomes,
          narrative: this.narrative,
          evaluation: this.evaluation,
        })
      }).then(() => {
          this.$store.dispatch(Types.actions.getPlanResponses, { fy: this.category.fiscalYear, lea: this.selectedLea.number })
          this.editMode = false
          this.saving = false
        }).catch((err) => {
          alert(err)
          this.saving = false
        })
    },
    cancel() {
      this.editMode = false
      this.initResponseData()
    },
    initResponseData() {
      this.reasons = []

      if (this.initialResponse) {
        var response = JSON.parse(this.initialResponse.response)
        this.reasons = response.items
        this.otherText = response.other
        this.expectedOutcomes = response.expectedOutcomes,
        this.narrative = response.narrative
        this.evaluation = response.evaluation
      }
    }
  },
  components: {
    PlanCategoryTypeIcon,
    RichTextEditor,
  },
  watch: {
    planResponses: {
      immediate: true,
      deep: true,
      handler() {
        this.initResponseData()
      }
    },
    editMode: {
      immediate: true,
      handler() {
        if(this.editMode){
          document.documentElement.style.overflow = 'hidden'
          return
        }
        document.documentElement.style.overflow = 'auto'
     }
    }
  },
  mounted() {
    // if (this.initialResponse) {
    //   var response = JSON.parse(this.initialResponse.response)
    //   this.reasons = response.items
    //   this.otherText = response.other
    // }
  }
}
</script>

<style>
@media screen {
  .category-type-card {
    padding: 0px !important;
    max-height: 400px;
  }
}

.category-type-card-header {
  padding: 4px 15px 4px 15px;
}

.title-no-overflow {
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

.card-fullscreen {
  position: fixed!important;
    height: 98%!important;
    max-height: initial!important;
    width: 98%!important;
    top: .5%!important;
    left: .25%!important;
    z-index: 1060!important;
    box-shadow: 0 0 2.5em #000!important;
}

.categorytype-budget {
  text-align: right;
  margin: -20px -20px 10px -20px;
  padding: 10px;
  position: sticky;
  top: -20px;
  z-index: 1000;
  font-weight: bold;
}

.lighten-bg {
  margin: -10px;
  padding: 0px;
  filter: opacity(.1);
  position: absolute;
  width: 100%;
  height: 100%;
  color: black !important;
  background-color: lightgray;
  border-bottom: 1px solid black;
}

@media print {
  .card-body {
    padding: 20px !important;
  }
}

</style>
import Vue from 'vue'
import moment from 'moment'
import { mapState } from 'vuex'
import { InspectionStatuses, RatingTypes } from '../common/Enums'
import PaginatedTable from '../../PaginatedTable'

const DpsaftListMixin = Vue.extend({
  data() {
    return {
      InspectionStatuses,
      RatingTypes,
      checkableItems: [],
      listItemType: '',
    }
  },
  components: {
    PaginatedTable
  },
  methods: {
    onSelected(rows) {
      this.$emit('row-selected', rows)
    },
    selectAll() {
      this.checkableItems = this.items.map(item => Object.assign({ checked: true }, item))
    },
    selectNone() {
      this.checkableItems = this.items.map(item => Object.assign({ checked: false }, item))
    },
    inspectionStatusDisplayOptions(status) {
      return InspectionStatuses.DisplayOptions(status)
    },
    hasNoComponentsChecked(inspection) {
      return inspection.components.every(c => c.rating == RatingTypes.NotChecked)
    },
    formatDate(date) {
      return moment(date).format('MM/DD/YYYY')
    }
  },
  computed: {
    ...mapState('globalModule', ['userContext']),
    checkedItems() {
      return this.checkableItems.filter(i => i.checked)
    },
    isDistrictUser() {
      return this.userContext.hasCoordinatorCode("U") || this.userContext.hasCoordinatorCode("X")
    },
    inspectionInfo() {
      const info = 'Inspection information is based'
      if (this.showBatchStatus)
        return `${info} solely on the inspection associated with this batch`
      else if (this.isDistrictUser)
        return `${info} on the most recent completed inspection`
      else 
        return `${info} on the most recent inspection associated with the ${this.listItemType}, regardless of completion status`
    }
  },
  props: {
    items: Array,
    loadData: Promise,
    allowItemCheck: {
      type: Boolean,
      default: false
    },
    showBatchStatus: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    checkedItems(items) {
      this.$emit('item-checked', items)
    }
  }
})
export { DpsaftListMixin }